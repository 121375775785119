/*
 * :file description:
 * :name: \low-code-platform\src\components\FormGenerator\settings\presetFormSettings\order.js
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-11 20:32:36
 * :last editor: hyw
 * :date last edited: 2022-04-21 17:18:51
 */

export default [
  {
    text: '订单编号',
    name: 'seqNumber',
    icon: 'icon-zhaiyao',
    hiddenBlock: ['copy'],
    max: 1,
    fieldId: 'seqNumber_DQ9XS81GDD40',
    schema: {
      title: '订单编号',
      type: 'string',
      widget: 'seqNumber',
      bizAlias: 'order_no',
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        hidden: '{{true}}',
      },
      generationRules: {
        title: '',
        type: 'array',
        items: {
          type: 'object',
          properties: {},
        },
        widget: 'generationRules',
        default: [
          {
            type: 'date',
            value: 'YYYYMMDD',
          },
          {
            type: 'counter',
            value: {
              start: 1,
              length: 8,
              reset: 'year',
            },
          },
          {
            type: 'common',
            value: '',
          },
          {
            type: 'field',
            value: '',
          },
        ],
      },
    },
  },
  {
    text: '客户',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_NVDI5UD3JPS0',
    extraText: '关联表单',
    schema: {
      title: '客户',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      fields: {},
      bizAlias: 'order_related_customer',
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
        },
      },
      relateSource: [
        {
          bizType: 'crm_customer',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'SWAPP-4C2F4B2B84BE49001EADD10DD19FADC6',
              bizType: 'crm_customer',
              formCode: 'PROC-FD2AC559-36B1-4A51-9E9F-0B0FC904D31C',
              appType: 1,
            },
          },
        },
        {
          bizType: 'crm_customer_personal',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'SWAPP-4C2F4B2B84BE49001EADD10DD19FADC6',
              bizType: 'crm_customer_personal',
              formCode: 'PROC-8CFF1FE5-EB3A-4C78-B069-169058E235EB',
              appType: 1,
            },
          },
        },
      ],
    },
    setting: {
      fields: {
        title: '关联字段',
        type: 'object',
        widget: 'relationSetting',
      },
      // sortFields: {
      //   title: '',
      //   widget: 'sortFields',
      //   type: 'array',
      //   items: {
      //     type: 'object',
      //     properties: {},
      //   },
      //   default: [
      //     {
      //       bizType: 'crm_customer_personal',
      //       fields: [],
      //     },
      //     {
      //       bizType: 'crm_customer',
      //       fields: [],
      //     },
      //   ],
      // },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      choice: {
        title: '关联模式',
        type: 'string',
        default: '0',
        enum: ['0', '1'],
        className: 'my-radioName',
        enumNames: ['引用模式', '拷贝模式'],
        widget: 'radio',
        hidden: '{{true}}',
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        title: '标题',
        type: 'string',
      },
      filters: {
        title: '数据过滤',
        type: 'array',
        widget: 'dataFilters',
      },
      dataFillingRules: {
        title: "数据填充",
        type: "array",
        widget: "dataFill"
      }
    },
  },
  {
    text: '线索',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_followRecordClue',
    extraText: '关联表单',
    schema: {
      title: '线索',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      bizAlias: 'relation_followRecordClue',
      fields: {},
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
          formCode: '',
        },
      },
      relateSource: [
        {
          bizType: 'clue_engine',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'TJAPP-MjAyMjA5MjMwMA==',
              bizType: 'clue_engine',
              formCode: 'xxxxx',
              appType: 1,
              status: 'PUBLISHED',
            },
          },
        },
      ],
    },
    setting: {
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        title: '标题',
        type: 'string',
        // hidden: '{{true}}',
      },
    },
  },
  {
    text: '产品明细',
    name: 'table',
    icon: 'icon-liebiaoshitu',
    schema: {
      title: '产品明细',
      type: 'array',
      widget: 'table',
      widgetPrefix: 'table',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      actionName: {
        title: '动作名称',
        type: 'string',
        widget: 'input',
        default: '添加',
      },
      required: {
        title: '必填',
        type: 'boolean',
        hidden: '{{true}}',
      },

    },
  },
  {
    text: '产品明细,产品',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_QLSFPGDVI2O0',
    extraText: '关联表单',
    schema: {
      title: '产品明细,产品',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      bizAlias: 'order_related_product',
      fields: {},
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
        },
      },
    },
    setting: {
      fields: {
        title: '关联字段',
        type: 'object',
        widget: 'relationSetting',
      },
      // sortFields: {
      //   title: '',
      //   widget: 'sortFields',
      //   type: 'array',
      //   items: {
      //     type: 'object',
      //     properties: {},
      //   },
      // },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      choice: {
        title: '关联模式',
        type: 'string',
        default: '0',
        enum: ['0', '1'],
        className: 'my-radioName',
        enumNames: ['引用模式', '拷贝模式'],
        widget: 'radio',
        hidden: '{{true}}',
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      filters: {
        title: '数据过滤',
        type: 'array',
        widget: 'dataFilters',
      },
      dataFillingRules: {
        title: '数据填充',
        type: 'array',
        widget: 'dataFill',
      },
    },
  },
  {
    text: '产品明细,单价（元）',
    name: 'money',
    icon: 'icon-a-jine2x',
    fieldId: 'money_ST2PL9YQPY80',
    schema: {
      title: '产品明细,单价（元）',
      type: 'number',
      widget: 'money',
      bizAlias: 'order_related_product_price',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '产品明细,单价折扣',
    icon: 'icon-a-shuzishurukuang2x',
    name: 'number',
    schema: {
      title: '产品明细,单价折扣',
      type: 'number',
      widget: 'number',
      required: true,
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入数字',
        default: '请输入数字',
        max: 100,
        order: 10,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 40,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '产品明细,折后单价（元）',
    name: 'money',
    icon: 'icon-a-jine2x',
    schema: {
      title: '产品明细,折后单价（元）',
      type: 'number',
      widget: 'money',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '产品明细,数量',
    icon: 'icon-a-shuzishurukuang2x',
    name: 'number',
    fieldId: 'number_2HAV5WC1O5I0',
    schema: {
      title: '产品明细,数量',
      type: 'number',
      widget: 'number',
      bizAlias: 'order_related_product_num',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入数字',
        default: '请输入数字',
        max: 100,
        order: 10,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 40,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '产品明细,产品小计',
    name: 'money',
    icon: 'icon-a-jine2x',
    fieldId: 'calculate_204WFQFPSWU80',
    schema: {
      title: '产品明细,产品小计',
      type: 'number',
      widget: 'money',
      required: true,
      bizAlias: 'order_related_product_total_price',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '整单折扣',
    icon: 'icon-a-shuzishurukuang2x',
    name: 'number',
    schema: {
      title: '整单折扣',
      type: 'number',
      widget: 'number',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入数字',
        default: '请输入数字',
        max: 100,
        order: 10,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 40,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '优惠金额（元）',
    name: 'money',
    icon: 'icon-a-jine2x',
    schema: {
      title: '优惠金额（元）',
      type: 'number',
      widget: 'money',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '订单总额',
    name: 'money',
    icon: 'icon-a-jine2x',
    fieldId: 'calculate_1BB1T5DRDOXS0',
    schema: {
      title: '订单总额',
      type: 'number',
      widget: 'money',
      bizAlias: 'order_amount',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '订单中已回款金额',
    name: 'money',
    icon: 'icon-a-jine2x',
    schema: {
      title: '订单中已回款金额',
      type: 'number',
      widget: 'money',
      bizAlias: 'biz_order_paid_amount',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '订单中未回款金额',
    name: 'money',
    icon: 'icon-a-jine2x',
    schema: {
      title: '订单中未回款金额',
      type: 'number',
      widget: 'money',
      bizAlias: 'biz_order_unpaid_amount',
      default: 0,
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '订单中已开票金额',
    name: 'money',
    icon: 'icon-a-jine2x',
    schema: {
      title: '订单中已开票金额',
      type: 'number',
      widget: 'money',
      bizAlias: 'biz_order_invoiced_amount',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '订单中未开票金额',
    name: 'money',
    icon: 'icon-a-jine2x',
    schema: {
      title: '订单中未开票金额',
      type: 'number',
      widget: 'money',
      bizAlias: 'biz_order_uninvoiced_amount',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '订单总额',
    name: 'money',
    icon: 'icon-a-jine2x',
    fieldId: 'calculate_1BB1T5DRDOXS0',
    schema: {
      title: '订单总额',
      type: 'number',
      widget: 'money',
      bizAlias: 'order_amount',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '合同',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_20TF6095MMAO0',
    extraText: '关联表单',
    schema: {
      title: '合同',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      fields: {},
      bizAlias: 'contract',
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
          formCode: 'PROC-7250A7F9-34EA-4CB1-B5EC-180012AFDEDD',
        },
      },
    },
    setting: {
      fields: {
        title: '关联字段',
        type: 'object',
        widget: 'relationSetting',
      },
      sortFields: {
        title: '',
        widget: 'sortFields',
        type: 'array',
        items: {
          type: 'object',
          properties: {},
        },
      },
      // required: {
      //   title: '必填',
      //   type: 'boolean',
      //   widget: 'switch',
      // },
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
      },
      choice: {
        title: '关联模式',
        type: 'string',
        default: '0',
        enum: ['0', '1'],
        className: 'my-radioName',
        enumNames: ['引用模式', '拷贝模式'],
        widget: 'radio',
        hidden: '{{true}}',
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      filters: {
        title: '数据过滤',
        type: 'array',
        widget: 'dataFilters',
      },
    },
  },
];
