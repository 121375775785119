/*
 * :file description:节点
 * :name: \low-code-platform\src\pages\generator\process\components\FlowV3\components\Node.tsx
 * :author: Quan
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-13 15:20:04
 * :last editor: hyw
 * :date last edited: 2022-02-25 11:03:09
 */
import React, { useMemo, useEffect, useState, useRef } from 'react';
import { Popover } from 'antd';
import {
  COMMON_NODE_TYPE_MAP,
  CONDITION_CONTAINER_NODE,
  INCLUSIVE_CONDITION_CONTAINER_NODE,
  INCLUSIVE_CONDITION_CONTAINER_NODE_END,
  DEFAULT_CONFIG,
} from '../constants';
import Generator from './Generator';
import styles from '../index.less';
import _ from 'lodash';
import addAudit from '@/images/addAudit.png';
import addCopySend from '@/images/addCopySend.png';
import addBranch from '@/images/addBranch.png';
import close from '@/images/close.png';
import { getNodeUuid, getUrlParam } from '@/utils';
import IconFont from '@/components/IconFont';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useGray } from '@/pages/generator/relation/hooks';
interface IProps {
  data: any;
  currentBranch: any;
  currentIndex: number;
  branchIndex?: number;
  branchesLength?: number;
  endNodeId?: string;
  flowData: any; //流程数据
  setFlowData: (value: any) => void;
  handleNodeClick: (value: any) => void;
  handleNodeActiveStatus: (value: any) => void;
  extData?: any;
}

function Node(props: IProps) {
  const {
    data,
    currentBranch,
    currentIndex,
    endNodeId,
    flowData,
    setFlowData,
    branchIndex,
    branchesLength,
    handleNodeClick,
    handleNodeActiveStatus,
    extData = {},
  } = props;
  // console.log('currentIndex', currentIndex, branchIndex);

  const currentFlowData = useRef<any>(flowData);
  const stylesMap = {
    [COMMON_NODE_TYPE_MAP.APPLY_NODE]: styles.applyNode,
    [COMMON_NODE_TYPE_MAP.APPROVAL_NODE]: styles.approvalNode,
    [COMMON_NODE_TYPE_MAP.CARBON_NODE]: styles.carbonNode,
    [COMMON_NODE_TYPE_MAP.CONDITION_NODE]: styles.conditionNode,
    [COMMON_NODE_TYPE_MAP.INCLUSIVE_CONDITION_NODE]:
      styles.InclusiveConditionNode,
  };

  // 隐藏条件分支判断
  const hiddenConditionNode = getUrlParam('hiddenConditionNode');
  // 隐藏并行分支判断
  const hiddenInclusiveConditionNode = getUrlParam(
    'hiddenInclusiveConditionNode',
  );

  const { versionValue, isShowInclusiveConditionNode } = extData;

  const nextNode = useMemo(() => {
    const node = currentBranch[currentIndex + 1] || null;
    return node;
  }, [currentBranch]);

  useEffect(() => {
    currentFlowData.current = flowData;
  }, [flowData]);

  const content = () => (
    <div className={styles.flowType}>
      <div
        onClick={() => {
          addFlowNode(COMMON_NODE_TYPE_MAP.APPROVAL_NODE);
        }}
        className={styles.item}
      >
        <div className={styles.auditDiv}>
          <img src={addAudit} className={styles.img} />
        </div>
        审核人
      </div>
      <div
        onClick={() => {
          addFlowNode(COMMON_NODE_TYPE_MAP.CARBON_NODE);
        }}
        className={styles.item}
      >
        <div className={styles.copySendDiv}>
          <img src={addCopySend} className={styles.img} />
        </div>
        抄送通知
      </div>
      {!hiddenConditionNode && (
        <div
          onClick={() => {
            addFlowNode(CONDITION_CONTAINER_NODE);
          }}
          className={styles.item}
        >
          <div className={styles.branchDiv}>
            <img src={addBranch} className={styles.img} />
          </div>
          条件分支
        </div>
      )}
      {([2, 3].includes(Number(versionValue)) ||
        isShowInclusiveConditionNode) && (
        <div
          onClick={() => {
            addFlowNode(INCLUSIVE_CONDITION_CONTAINER_NODE);
          }}
          className={styles.item}
        >
          <div className={styles.branchDiv}>
            <img src={addBranch} className={styles.img} />
          </div>
          并行分支
        </div>
      )}
    </div>
  );

  const renderOp = useMemo(
    () =>
      (nextNode: boolean = true, isEndNode: boolean = false) => {
        return (
          <React.Fragment>
            <div className={styles.addBtn}>
              <div
                className={`${styles.optionWrapper} ${
                  !nextNode ? styles.notNextNode : ''
                } ${isEndNode ? styles.isEndNode : ''} `}
              >
                <Popover
                  placement="bottom"
                  title={null}
                  content={content()}
                  trigger="click"
                  style={{
                    padding: 0,
                  }}
                  overlayClassName={styles.popover}
                >
                  {/* <img src={add} alt="" className={styles.addIcon} /> */}
                  <div className={styles.addIcon}>
                    <IconFont type="icon-tianjia1"></IconFont>
                  </div>
                </Popover>
              </div>
            </div>
            {isEndNode && <div className={styles.endNode}>流程结束</div>}
          </React.Fragment>
        );
      },
    [currentBranch],
  );

  const nodeClick = () => {
    console.log('currentBranch', currentBranch);
    console.log('branchIndex', branchIndex);
    const key = 'children';

    let clues = findIndexList(flowData.toJS(), data.id);

    clues = mapClue(clues, key);
    const param = {
      data: data,
      clues,
    };
    handleNodeClick(param);
  };

  // 普通节点：APPLY_NODE，APPROVAL_NODE，CARBON_NODE
  const CommonNode = () => {
    const isEndNode = data?.id === endNodeId;
    const newListUser =
      (data?.props?.approverRules?.approvals?.length > 0
        ? data?.props?.approverRules?.approvals
        : data?.props?.approverRules?.selfSelect) ?? [];
    // console.log('data.componentName', data.componentName);
    const renderContent = () => {
      switch (data.componentName) {
        case COMMON_NODE_TYPE_MAP.APPLY_NODE:
          return <div className={styles.contentText}>发起人</div>;
        case COMMON_NODE_TYPE_MAP.APPROVAL_NODE:
          return (
            <div className={styles.contentText}>
              {newListUser.length ? (
                newListUser.map((item: any) => item.label).join('，')
              ) : (
                <span className={styles.disabled}>请选择审核人</span>
              )}
            </div>
          );
        case COMMON_NODE_TYPE_MAP.CARBON_NODE:
          return (
            <div className={styles.contentText}>
              {newListUser.length ? (
                newListUser.map((item: any) => item.label).join('，')
              ) : (
                <span className={styles.disabled}>请选择抄送人</span>
              )}
            </div>
          );
        case COMMON_NODE_TYPE_MAP.INCLUSIVE_CONDITION_CONTAINER_NODE_END:
          return <div className={styles.contentText}>并行分支汇聚节点</div>;
        default:
          return null;
      }
    };

    const ICON_MAP = {
      [COMMON_NODE_TYPE_MAP.APPLY_NODE]: 'icon-wode1',
      [COMMON_NODE_TYPE_MAP.APPROVAL_NODE]: 'icon-shenpi2',
      [COMMON_NODE_TYPE_MAP.CARBON_NODE]: 'icon-shenpi1',
    };

    return (
      <React.Fragment>
        <div
          className={`${stylesMap[data.componentName]} ${styles.commonNode} ${
            data.error ? styles.error : ''
          } ${data.isActive ? styles.isActive : ''}`}
          onClick={nodeClick}
        >
          {/* <div className={styles.errIcon}>
            <ExclamationCircleOutlined />
          </div> */}
          {/* <div className={styles.title}>{titleMap[data.componentName]}</div> */}
          <div className={styles.title}>
            <IconFont
              className={styles.nodeIcon}
              type={ICON_MAP[data.componentName]}
            ></IconFont>
            <span>{data?.props.name}</span>
            {data.componentName !== COMMON_NODE_TYPE_MAP.APPLY_NODE && (
              <div className={styles.delete} onClick={deleteCommonNode}>
                {/* <img src={close} alt="" className={styles.icon} /> */}
                <IconFont className={styles.icon} type="icon-Close"></IconFont>
              </div>
            )}
          </div>
          <div className={styles.content}>
            <React.Fragment>{renderContent()}</React.Fragment>
            <IconFont className={styles.icon} type="icon-fanhui"></IconFont>
          </div>
        </div>

        {renderOp(!!nextNode, isEndNode)}
      </React.Fragment>
    );
  };

  // 条件节点: CONDITION_NODE
  const ConditionNode = () => {
    const withNextNode = data.children.length;

    const renderContent = (conditionData: any) => {
      const CONDITION_TYPE_MAP = {
        department: 'department',
        money: 'money',
        number: 'number',
        innerContact: 'innerContact',
        initiator: 'initiator',
        select: 'select',
        multiSelect: 'multiSelect',
      };

      const KEY_NAME_MAP: any = {
        gte: {
          name: '大于等于',
          relativeField: conditionData?.value?.after?.min ?? '',
        },
        gt: {
          name: '大于',
          relativeField: conditionData?.value?.after?.min ?? '',
        },
        eq: {
          name: '等于',
          relativeField: conditionData?.value?.after?.min ?? '',
        },
        lt: {
          name: '小于',
          relativeField: conditionData?.value?.after?.min ?? '',
        },
        lte: {
          name: '小于等于',
          relativeField: conditionData?.value?.after?.min ?? '',
        },
        bt: {
          name: '介于',
          relativeField: `${conditionData?.value?.after?.min}-${conditionData?.value?.after?.max}`,
        },
      };
      // console.log('conditionData----------', conditionData);

      const keyName = KEY_NAME_MAP?.[conditionData?.value?.before]?.name || '';
      const keyValue: string =
        KEY_NAME_MAP?.[conditionData?.value?.before]?.relativeField || '';
      // const keyValue = conditionData?.[relativeField];
      switch (conditionData?.widget) {
        case CONDITION_TYPE_MAP.department:
          return (
            <div className={styles.contentText}>
              {`${conditionData.title}:${
                conditionData?.value
                  ? conditionData?.value.map((item: any) => item.name).join(',')
                  : ''
              }`}
            </div>
          );
        case CONDITION_TYPE_MAP.money:
          return (
            <div className={styles.contentText}>{`${conditionData.title}:${
              keyName + keyValue
            }`}</div>
          );
        case CONDITION_TYPE_MAP.number:
          return (
            <div className={styles.contentText}>{`${conditionData.title}:${
              keyName + keyValue
            }`}</div>
          );
        case CONDITION_TYPE_MAP.innerContact:
          return (
            <div className={styles.contentText}>
              {`${conditionData.title}:${
                conditionData?.value
                  ? conditionData?.value.map((item: any) => item.name).join(',')
                  : ''
              }`}
            </div>
          );
        case CONDITION_TYPE_MAP.initiator:
          return (
            <div className={styles.contentText}>
              {`${conditionData.title}:${
                conditionData?.value
                  ? conditionData?.value.map((item: any) => item.name).join(',')
                  : ''
              }`}
            </div>
          );
        case CONDITION_TYPE_MAP.select:
          const { enumNames, enum: enumArr } = conditionData;
          let nameArr: any = [];
          conditionData?.value &&
            conditionData?.value?.forEach((val) => {
              if (enumArr?.indexOf(val) > -1) {
                nameArr.push(enumNames[enumArr.indexOf(val)]);
              }
            });
          return (
            <div className={styles.contentText}>
              {`${conditionData.title}:${nameArr.join(',')}`}
            </div>
          );
        case CONDITION_TYPE_MAP.multiSelect:
          const { enumNames: enumNames1, enum: enumArr1 } = conditionData;
          let nameArr1: any = [];
          conditionData?.value?.after &&
            conditionData?.value?.after.forEach((val) => {
              if (enumArr1.indexOf(val) > -1) {
                nameArr1.push(enumNames1[enumArr1.indexOf(val)]);
              }
            });
          return (
            <div className={styles.contentText}>
              {`${conditionData.title}:${nameArr1.join(',')}`}
            </div>
          );
      }
    };

    const renderDefaultTips = () => {
      if (branchIndex === (branchesLength as number) - 1) {
        return '其他条件进入此流程';
      }
      return '请设置条件';
    };

    const conditions = _.flattenDeep(data?.props?.conditions);

    return (
      <React.Fragment>
        <div
          className={`${styles.conditionNode} ${
            data.error ? styles.error : ''
          } ${data.isActive ? styles.isActive : ''} ${
            branchIndex === (branchesLength as number) - 1
              ? styles.lastBranch
              : ''
          }`}
          onClick={nodeClick}
        >
          {/* <div className={styles.errIcon}>
            <ExclamationCircleOutlined />
          </div> */}

          <div className={styles.title}>
            <span className={styles.conditionTitle}>{data?.props?.name}</span>
            <span className={styles.conditionPriority}>
              {`优先级${(branchIndex as number) + 1}`}
            </span>
            {(branchIndex as number) < (branchesLength as number) - 1 && (
              <div className={styles.optionBox}>
                <div className={styles.copy} onClick={copyBranch}>
                  <IconFont
                    className={styles.icon}
                    type="icon-fuzhi"
                  ></IconFont>
                </div>
                <div className={styles.delete} onClick={deleteBranch}>
                  <IconFont
                    className={styles.icon}
                    type="icon-Close"
                  ></IconFont>
                </div>
              </div>
            )}
            {/* {(branchIndex as number) == (branchesLength as number) - 1 &&
              extData?.nodeName === 'InclusiveConditionContainerNode' && (
                <div className={styles.optionBox}>
                  <div className={styles.copy} onClick={copyBranch}>
                    <IconFont
                      className={styles.icon}
                      type="icon-fuzhi"
                    ></IconFont>
                  </div>
                  <div className={styles.delete} onClick={deleteBranch}>
                    <IconFont
                      className={styles.icon}
                      type="icon-Close"
                    ></IconFont>
                  </div>
                </div>
              )} */}
          </div>
          <div className={styles.content}>
            {conditions.length
              ? renderContent(conditions[0])
              : renderDefaultTips()}
            <IconFont className={styles.icon} type="icon-fanhui"></IconFont>
          </div>
        </div>
        {/* <div className={styles.content}>条件节点{branchIndex}</div> */}
        {renderOp(withNextNode)}
      </React.Fragment>
    );
  };

  // 并行条件节点: INCLUSIVE_CONDITION_NODE
  const InclusiveConditionNode = () => {
    const withNextNode = data.children.length;

    const renderContent = (conditionData: any) => {
      const CONDITION_TYPE_MAP = {
        department: 'department',
        money: 'money',
        number: 'number',
        innerContact: 'innerContact',
        initiator: 'initiator',
        select: 'select',
        multiSelect: 'multiSelect',
      };

      const KEY_NAME_MAP: any = {
        gte: {
          name: '大于等于',
          relativeField: conditionData?.value?.after?.min ?? '',
        },
        gt: {
          name: '大于',
          relativeField: conditionData?.value?.after?.min ?? '',
        },
        eq: {
          name: '等于',
          relativeField: conditionData?.value?.after?.min ?? '',
        },
        lt: {
          name: '小于',
          relativeField: conditionData?.value?.after?.min ?? '',
        },
        lte: {
          name: '小于等于',
          relativeField: conditionData?.value?.after?.min ?? '',
        },
        bt: {
          name: '介于',
          relativeField: `${conditionData?.value?.after?.min}-${conditionData?.value?.after?.max}`,
        },
      };
      // console.log('conditionData----------', conditionData);

      const keyName = KEY_NAME_MAP?.[conditionData?.value?.before]?.name || '';
      const keyValue: string =
        KEY_NAME_MAP?.[conditionData?.value?.before]?.relativeField || '';
      // const keyValue = conditionData?.[relativeField];
      switch (conditionData?.widget) {
        case CONDITION_TYPE_MAP.department:
          return (
            <div className={styles.contentText}>
              {`${conditionData.title}:${
                conditionData?.value
                  ? conditionData?.value.map((item: any) => item.name).join(',')
                  : ''
              }`}
            </div>
          );
        case CONDITION_TYPE_MAP.money:
          return (
            <div className={styles.contentText}>{`${conditionData.title}:${
              keyName + keyValue
            }`}</div>
          );
        case CONDITION_TYPE_MAP.number:
          return (
            <div className={styles.contentText}>{`${conditionData.title}:${
              keyName + keyValue
            }`}</div>
          );
        case CONDITION_TYPE_MAP.innerContact:
          return (
            <div className={styles.contentText}>
              {`${conditionData.title}:${
                conditionData?.value
                  ? conditionData?.value.map((item: any) => item.name).join(',')
                  : ''
              }`}
            </div>
          );
        case CONDITION_TYPE_MAP.initiator:
          return (
            <div className={styles.contentText}>
              {`${conditionData.title}:${
                conditionData?.value
                  ? conditionData?.value.map((item: any) => item.name).join(',')
                  : ''
              }`}
            </div>
          );
        case CONDITION_TYPE_MAP.select:
          const { enumNames, enum: enumArr } = conditionData;
          let nameArr: any = [];
          conditionData?.value &&
            conditionData?.value?.forEach((val) => {
              if (enumArr?.indexOf(val) > -1) {
                nameArr.push(enumNames[enumArr.indexOf(val)]);
              }
            });
          return (
            <div className={styles.contentText}>
              {`${conditionData.title}:${nameArr.join(',')}`}
            </div>
          );
        case CONDITION_TYPE_MAP.multiSelect:
          const { enumNames: enumNames1, enum: enumArr1 } = conditionData;
          let nameArr1: any = [];
          conditionData?.value?.after &&
            conditionData?.value?.after.forEach((val) => {
              if (enumArr1.indexOf(val) > -1) {
                nameArr1.push(enumNames1[enumArr1.indexOf(val)]);
              }
            });
          return (
            <div className={styles.contentText}>
              {`${conditionData.title}:${nameArr1.join(',')}`}
            </div>
          );
      }
    };

    const renderDefaultTips = () => {
      if (branchIndex === (branchesLength as number) - 1) {
        return '都不满足条件进入该分支';
      }
      return '请设置条件';
    };

    const conditions = _.flattenDeep(data?.props?.conditions);

    return (
      <React.Fragment>
        <div
          className={`${styles.conditionNode} ${
            data.error ? styles.error : ''
          } ${data.isActive ? styles.isActive : ''} ${
            branchIndex === (branchesLength as number) - 1
              ? styles.lastBranch
              : ''
          }`}
          onClick={nodeClick}
        >
          {/* <div className={styles.errIcon}>
            <ExclamationCircleOutlined />
          </div> */}

          <div className={styles.title}>
            <span className={styles.conditionTitle}>{data?.props?.name}</span>
            {(branchIndex as number) < (branchesLength as number) - 1 && (
              <div className={styles.optionBox}>
                <div className={styles.copy} onClick={copyBranch}>
                  <IconFont
                    className={styles.icon}
                    type="icon-fuzhi"
                  ></IconFont>
                </div>
                <div className={styles.delete} onClick={deleteBranch}>
                  <IconFont
                    className={styles.icon}
                    type="icon-Close"
                  ></IconFont>
                </div>
              </div>
            )}
            {/* {(branchIndex as number) == (branchesLength as number) - 1 &&
              extData?.nodeName === 'InclusiveConditionContainerNode' && (
                <div className={styles.optionBox}>
                  <div className={styles.copy} onClick={copyBranch}>
                    <IconFont
                      className={styles.icon}
                      type="icon-fuzhi"
                    ></IconFont>
                  </div>
                  <div className={styles.delete} onClick={deleteBranch}>
                    <IconFont
                      className={styles.icon}
                      type="icon-Close"
                    ></IconFont>
                  </div>
                </div>
              )} */}
          </div>
          <div className={styles.content}>
            {conditions.length
              ? renderContent(conditions[0])
              : renderDefaultTips()}
            <IconFont className={styles.icon} type="icon-fanhui"></IconFont>
          </div>
        </div>
        {/* <div className={styles.content}>条件节点{branchIndex}</div> */}
        {renderOp(withNextNode)}
      </React.Fragment>
    );
  };

  // 添加节点方法
  const addFlowNode = (type: string) => {
    console.log(123456789);
    const isInclusiveConditionContainerNode =
      type === 'InclusiveConditionContainerNode';
    const defaultConfig: any = DEFAULT_CONFIG;
    const key = 'children';
    let clues = findIndexList(flowData.toJS(), data.id);
    let newFlowData = _.cloneDeep(currentFlowData.current);
    const nodeDefaultData = defaultConfig()[type];
    // 判断当前分支currentBranch是否一级分支上，若是，则直接覆盖即可,若不是，则更新流程数据上对应的分支数据
    if (clues.length === 1) {
      const newBranchData = [...currentBranch];
      newBranchData.splice(currentIndex + 1, 0, nodeDefaultData);
      if (isInclusiveConditionContainerNode) {
        newBranchData.splice(
          currentIndex + 2,
          0,
          defaultConfig()[INCLUSIVE_CONDITION_CONTAINER_NODE_END],
        );
      }
      handleNodeActiveStatus(newBranchData);
      setFlowData(newBranchData);
    } else if (clues.length > 1) {
      clues = mapClue(clues, key);
      // 判断当前分支是否条件节点，若是，则更新条件节点下的children
      if (
        data.componentName === COMMON_NODE_TYPE_MAP.CONDITION_NODE ||
        data.componentName === COMMON_NODE_TYPE_MAP.INCLUSIVE_CONDITION_NODE
      ) {
        clues.push(key);
        const newBranchData = [...currentBranch.children];
        newBranchData.splice(currentIndex, 0, nodeDefaultData);
        if (isInclusiveConditionContainerNode) {
          newBranchData.splice(
            currentIndex + 1,
            0,
            defaultConfig()[INCLUSIVE_CONDITION_CONTAINER_NODE_END],
          );
        }
        newFlowData = newFlowData.setIn(clues, newBranchData).toJS();
        handleNodeActiveStatus(newFlowData);
        setFlowData(newFlowData);
      } else {
        clues.pop();
        const newBranchData = [...currentBranch];
        newBranchData.splice(currentIndex + 1, 0, nodeDefaultData);
        if (isInclusiveConditionContainerNode) {
          newBranchData.splice(
            currentIndex + 2,
            0,
            defaultConfig()[INCLUSIVE_CONDITION_CONTAINER_NODE_END],
          );
        }
        newFlowData = newFlowData.setIn(clues, newBranchData);
        handleNodeActiveStatus(newFlowData.toJS());
        setFlowData(newFlowData.toJS());
      }
    }
  };

  // 删除条件节点方法
  const deleteBranch = (e: any) => {
    e.stopPropagation();
    let newFlowData = _.cloneDeep(currentFlowData.current);
    const key = 'children';
    let nodeClues = findIndexList(flowData.toJS(), data.id);
    const branchClues = mapClue(nodeClues, key);
    branchClues.pop();
    const branches = newFlowData.getIn(branchClues).toJS();
    // 判断是否为并行分支
    const isInclusiveConditionNode =
      branches?.[0]?.componentName === 'InclusiveConditionNode';
    if (branches.length > 2) {
      branches.splice(branchIndex, 1);
      // 更新branch的排序
      branches.forEach((item, index) => {
        if (index === branches.length - 1 && isInclusiveConditionNode) {
          item.props.name = '默认条件';
          item.props.priority = index + 1;
        } else {
          item.props.name = '条件' + (index + 1);
          item.props.priority = index + 1;
        }
      });
      newFlowData = newFlowData.setIn(branchClues, branches).toJS();
    } else if (branches.length === 2) {
      //只有两条分支的情况下
      const newBranchClues = mapClue(nodeClues, key);
      const containerIndex = newBranchClues[newBranchClues.length - 3];
      if (nodeClues.length === 2) {
        // 判断当前网关节点在主流程分支上，则直接根据网关节点下标删除节点
        newFlowData = newFlowData.toJS();
        newFlowData.splice(containerIndex, 1);
        // 判断并行分支  删除聚合节点
        if (isInclusiveConditionNode) {
          newFlowData.splice(containerIndex, 1);
        }
      } else {
        newBranchClues.splice(newBranchClues.length - 3);
        const newBranchData = newFlowData.getIn(newBranchClues).toJS();
        newBranchData.splice(containerIndex, 1);
        // 判断并行分支  删除聚合节点
        if (isInclusiveConditionNode) {
          newBranchData.splice(containerIndex, 1);
        }
        newFlowData = newFlowData.setIn(newBranchClues, newBranchData).toJS();
      }

      // 找到当前条件节点所在的网关节点，再找到此网关节点所在分支，重置所在分支的数据
    }
    setFlowData(newFlowData);
    handleNodeActiveStatus(newFlowData);
  };

  // 复制条件节点方法
  const copyBranch = (e: any) => {
    e.stopPropagation();
    //重新生成拷贝后分支下所有节点的唯一id
    const deepChangeId = (node: any) => {
      const newNodeData = _.cloneDeep(node);
      const changeId = (currentItem: any) => {
        currentItem.id = getNodeUuid();
        if (
          currentItem?.children?.length &&
          Array.isArray(currentItem?.children)
        ) {
          currentItem?.children?.forEach((item: any) => {
            item.id = getNodeUuid();
            if (item?.children?.length && Array.isArray(item?.children)) {
              changeId(item);
            } else {
              return;
            }
          });
        }
      };
      changeId(newNodeData);
      return newNodeData;
    };

    const key = 'children';
    let newFlowData = _.cloneDeep(currentFlowData.current);
    let nodeClues = findIndexList(flowData.toJS(), data.id);

    const branchClues = mapClue(nodeClues, key);
    branchClues.pop(); //返回条件列表下标

    const newBranchData = newFlowData.getIn(branchClues).toJS();
    if (newBranchData.length >= 20) {
      return;
    }

    newBranchData.splice((branchIndex as number) + 1, 0, deepChangeId(data));
    newFlowData = newFlowData.setIn(branchClues, newBranchData).toJS();
    setFlowData(newFlowData);
  };

  //

  // 删除普通节点方法
  const deleteCommonNode = (e: any) => {
    e.stopPropagation();
    const key = 'children';
    let clues = findIndexList(flowData.toJS(), data.id);
    let newFlowData = _.cloneDeep(currentFlowData.current);

    // 判断当前分支currentBranch是否一级分支上，若是，则直接覆盖即可,若不是，则更新流程数据上对应的分支数据
    if (clues.length === 1) {
      const newBranchData = [...currentBranch];
      newBranchData.splice(currentIndex, 1);
      setFlowData(newBranchData);
      handleNodeActiveStatus(newBranchData);
    } else if (clues.length > 1) {
      clues = mapClue(clues, key);
      clues.pop();
      const newBranchData = [...currentBranch];
      newBranchData.splice(currentIndex, 1);
      newFlowData = newFlowData.setIn(clues, newBranchData).toJS();
      setFlowData(newFlowData);
      handleNodeActiveStatus(newFlowData);
    }
  };

  // 根据id查找节点所在数据层级的list
  const findIndexList = (data: any[], targetId: string) => {
    let parentNode = {
      children: [...data],
    };
    let mapIndexList: any = [];

    // 查找当前层级是否存在所需id，是：返回下标,否返回0
    const findCurIndex = (parentNode: any) => {
      // 遍历父节点，查看子节点集合是否含有目标id,如有，则停止递归到子节点
      if (parentNode?.children?.length && Array.isArray(parentNode?.children)) {
        parentNode?.children.forEach((childNode: any, index: number) => {
          childNode.parentNode = parentNode; // 子节点绑定父节点关系
          if (childNode.id === targetId) {
            findParenIndex(childNode);
            return;
          } else {
            findCurIndex(childNode);
          }
        });
      }
    };

    // 根据当前节点倒推所有父节点的下把
    const findParenIndex = (childNode: any) => {
      if (childNode.parentNode) {
        const childIndex = childNode.parentNode.children.findIndex(
          (child: any) => child.id === childNode.id,
        );

        mapIndexList.unshift(childIndex);
        findParenIndex(childNode.parentNode);
      } else {
        return;
      }
    };

    findCurIndex(parentNode);
    return mapIndexList;
  };

  // 获取修改数据的indexList
  const mapClue = (data: any[], key: string) => {
    const newData = data.join(',' + key + ',');

    return newData.split(',');
  };

  return (
    <React.Fragment>
      <div className={`${styles.nodeBox}  ${data.error ? styles.error : ''}`}>
        <div className={styles.errIcon}>
          <ExclamationCircleOutlined />
        </div>

        {data.componentName === COMMON_NODE_TYPE_MAP.CONDITION_NODE ? (
          <ConditionNode></ConditionNode>
        ) : data.componentName ===
          COMMON_NODE_TYPE_MAP.INCLUSIVE_CONDITION_NODE ? (
          <InclusiveConditionNode></InclusiveConditionNode>
        ) : (
          <CommonNode></CommonNode>
        )}
      </div>
      {nextNode && (
        <Generator
          currentNode={nextNode}
          currentBranch={currentBranch}
          currentIndex={currentIndex + 1}
          endNodeId={endNodeId}
          flowData={flowData}
          setFlowData={setFlowData}
          handleNodeActiveStatus={handleNodeActiveStatus}
          handleNodeClick={handleNodeClick}
          extData={extData}
        ></Generator>
      )}
    </React.Fragment>
  );
}

export default Node;
