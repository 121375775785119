/*
 * :file description:
 * :name: \ui-renderd:\progree\low-code-platform\tg-renderer\packages\form-render\src\widgets\DefaultValueSelector\components\DataFilters\FilterModal\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-11 17:48:13
 * :last editor: hyw
 * :date last edited: 2022-08-25 15:04:58
 */
import { useEffect, useState } from 'react';
import { Modal, Form, Select, Input, message } from 'antd';
import IconFont from '@/components/IconFont';
import _ from 'lodash';
import { useLocation } from 'umi';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import { getFormSchema } from '@/services/form-generator';
import FixedSelect from './FixedSelect';
import FixedDateTime from './FixedDateTime';
import FixedInnerContact from './FixedInnerContact';
import FixedInnerDepartment from './FixedInnerDepartment';
import { customJP } from '../../../../../utils';
import Style from './index.less';
import { getVisibleFormList } from '@/services/form-generator';

const FILTER_TYPES = [
  {
    value: 'LT',
    label: '小于',
  },
  {
    value: 'EQ',
    label: '等于',
  },
  {
    value: 'GT',
    label: '大于',
  },
  {
    value: 'LTE',
    label: '小于等于',
  },
  {
    value: 'GTE',
    label: '大于等于',
  },
  {
    value: 'SEARCH',
    label: '搜索',
  },
  {
    value: 'BETWEEN',
    label: '范围区间',
  },
  {
    value: 'IN',
    label: '包含',
  },
];

// 默认值等于 不支持控件
const NO_SUPPORT_WIDGETS_DEFAULT = [
  'dateRange',
  'textNote',
  'attachment',
  'tableList',
];
// 自定义时 是 select组件
const CUSTOM_FIXED_SUPPORT_OBJECT_TYPE = [
  'multiSelect',
  'select',
  'lastProcessStatus',
  'lastProcessResult',
];
const CUSTOM_FIXED_SUPPORT_ARRAY_TYPE = ['department', 'innerContact'];

const NO_SUPPORT_WIDGETS = [
  // 'multiSelect',
  'dateRange',
  'textNote',
  'picture',
  'attachment',
  'tableList',
  // 'table'
];

const createUserId = {
  id: 'createUserId',
  title: '创建人',
  widget: 'createUserId',
};

const gmtCreate = {
  id: 'gmtCreate',
  title: '创建时间',
  widget: 'gmtCreate',
};

export default (props) => {
  const { visible, onCancel, onChange, association } = props;
  console.log(association, 'association');
  const { flatten, selected } = useStore();
  const wrapFields = Object.values(flatten);

  const [relationList, setRelationList] = useState<any>([]);
  const [schameList, setSchameList] = useState<any>([]);
  const [sameTypeList, setSameTypeList] = useState<any>([]);
  const currentSchema = flatten[selected]?.schema;

  const location = useLocation();
  const getFormCode = () => {
    const { formCode } = location?.query || {};
    return formCode;
  };

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const list = values.list.map((item) => {
      if (item?.valueType === 'current') {
        const standbyValue = item.value?.split('/');
        const _item = { ...item };
        delete _item.currentFormOptions;
        return {
          ..._item,
          standbyValue:
            standbyValue?.length >= 2
              ? standbyValue[standbyValue.length - 1]
              : standbyValue,
        };
      } else if (item?.valueType === 'fixed') {
        const widgetName = item?.fieldId?.split('_')?.[0];
        // 判断是否为单选的组件  单选组件value为object
        if (CUSTOM_FIXED_SUPPORT_OBJECT_TYPE.includes(widgetName)) {
          const _item = { ...item };
          delete _item.currentFormOptions;
          return {
            ..._item,
            standbyValue: item.value,
          };
        } else if (CUSTOM_FIXED_SUPPORT_ARRAY_TYPE.includes(widgetName)) {
          const _item = { ...item };
          delete _item.currentFormOptions;
          return {
            ..._item,
            standbyValue: [...item?.value?.map((i) => i.key)],
          };
        } else {
          const standbyValue = item.value?.split('/');
          const _item = { ...item };
          delete _item.currentFormOptions;
          return {
            ..._item,
            standbyValue:
              standbyValue?.length >= 2
                ? standbyValue[standbyValue.length - 1]
                : standbyValue,
          };
        }
      }
    });
    // 为了兼容 单选、多选 关联其他表单数据功能
    // 需要带上对应的选项，否则无法正常显示
    const extValues: {
      enum?: string[];
      enumNames?: string[];
    } = {};
    if (values.defaultFieldId.indexOf('elect') > -1) {
      sameTypeList?.some((item: any) => {
        if (item.id === values.defaultFieldId) {
          extValues.enum = item.enum;
          extValues.enumNames = item.enumNames;
          return true;
        }
      });
    }
    onChange(
      values
        ? {
            ...values,
            list,
            ...extValues,
          }
        : {},
    );
  };

  const getSchemaData = async (
    formCode: string,
    isChangeRelation?: boolean,
  ) => {
    const res = await getFormSchema({ formCode });
    const schema = customJP(res?.data?.content);
    const properties = schema?.properties || {};
    let fieldList = Object.values(properties).filter(
      (item) => !NO_SUPPORT_WIDGETS.includes(item?.widget),
    );
    // 所有表单都有的创建人、创建时间
    const finallyData: any[] = [];
    fieldList?.forEach((ele: any) => {
      if ((!ele.hideControls && ele?.hidden) || ele?.deleted) {
        return;
      }

      // 补充 设置关联时  关联表单的关联子表单隐藏
      if (ele?.widget !== 'table' && ele?.widget !== 'relation') {
        finallyData.push(ele);
      } else if (ele?.widget !== 'relation') {
        let properties = ele?.items?.properties;
        let clonePropertiesData = _.cloneDeep(properties);
        const children = Object.values(clonePropertiesData || {});
        children?.forEach((v: any) => {
          if (!NO_SUPPORT_WIDGETS.includes(v?.widget)) {
            v.title = `${ele.title}.${v.title}`;
            finallyData.push(v);
          }
        });
      }
    });
    finallyData.push(createUserId);
    finallyData.push(gmtCreate);
    // 过滤掉关联表单

    console.log(finallyData, 'finallyData');
    setSchameList(finallyData || []);

    // 相同类型默认值
    // crm2.4 需要包含 多选 和 图片
    const defaultfieldList = Object.values(properties).filter(
      (item) => !NO_SUPPORT_WIDGETS_DEFAULT.includes(item?.widget),
    );
    const defaultFinallyData: any[] = [];
    defaultfieldList?.forEach((ele: any) => {
      if (ele?.widget !== 'table') {
        defaultFinallyData.push(ele);
      } else {
        let properties = ele?.items?.properties;
        let clonePropertiesData = _.cloneDeep(properties);
        const children = Object.values(clonePropertiesData || {});
        children?.forEach((v: any) => {
          if (!NO_SUPPORT_WIDGETS_DEFAULT.includes(v?.widget)) {
            v.title = `${ele.title}.${v.title}`;
            defaultFinallyData.push(v);
          }
        });
      }
    });
    defaultFinallyData.push(createUserId);
    defaultFinallyData.push(gmtCreate);
    const defaultList = defaultFinallyData.filter(
      (v) => v?.widget == currentSchema?.widget,
    );
    setSameTypeList(defaultList || []);

    association?.filters?.forEach((item) => {
      const innerItem = finallyData.find((val) => val.id == item.fieldId);
      const innerFieldWidget = innerItem?.widget;
      item.currentFormOptions = getCurrentFormOptions(innerFieldWidget);

      // 对initValue的数据做处理  主要是处理历史数据的不兼容  或者 对数据进行必要封装
      if (
        CUSTOM_FIXED_SUPPORT_OBJECT_TYPE.includes(
          item.fieldId?.split('_')?.[0],
        ) &&
        item.valueType === 'fixed'
      ) {
        item.filterType = 'IN';
        item.value =
          item.value instanceof Array ? item.value : [item.value?.key];
      }
    });

    // 已存在关联设置时去判断默认值是否存在
    if (association.defaultFieldId) {
      // 判断默认值控件是否被删除掉
      const isHasDefaultFieldId = defaultList.find(
        (item) => item.id === association.defaultFieldId,
      );
      if (!isHasDefaultFieldId) {
        form.setFieldsValue({
          defaultFieldId: '字段已被删除',
        });
        // 当默认值字段被删除了时自动校验
        form.validateFields(['defaultFieldId']);
      } else {
        form.setFieldsValue({
          defaultFieldId: association.defaultFieldId,
        });
      }
    }
    // 已存在关联设置时去判断关联目标表单的字段是否存在
    if (association?.filters?.length > 0) {
      const filters = _.cloneDeep(association?.filters);
      filters?.forEach((item) => {
        // 判断关联表单字段是否删除
        const isHasRelationFieldId = finallyData.find(
          (val) => val.id === item.fieldId,
        );
        if (!isHasRelationFieldId) {
          item.fieldId = '字段已被删除';
          item.filterType = '';
          item.value = '';
        } else {
          // 判断当前表单字段是否删除
          const isHasCurrentFormFieldId = item.currentFormOptions.find(
            (text) => text.schema.$id.replace(/^#\/?/, '') === item.value,
          );
          // 加一个判断  需要判断valueType是否为fixed
          if (!isHasCurrentFormFieldId && item?.valueType !== 'fixed') {
            item.value = '字段已被删除';
          }
        }
      });
      form.setFieldsValue({
        list: filters,
      });
      // 当默认值字段被删除了时自动校验
      !isChangeRelation &&
        setTimeout(() => {
          form.validateFields();
        }, 500);
    } else {
      form.setFieldsValue({
        list: [{ valueType: 'current' }],
      });
    }
    if (isChangeRelation) {
      form.setFieldsValue({
        defaultFieldId: null,
        list: [{ valueType: 'current' }],
      });
    }
  };

  useEffect(() => {
    if (visible) {
      // 获取关联表单
      getRelationFormData(association.relationFormCode);
      // 回显
      if (association.relationFormCode) {
        form.setFieldsValue({
          relationFormCode: association.relationFormCode,
          // defaultFieldId: association.defaultFieldId,
          // list:
          //   association?.filters?.length > 0
          //     ? association.filters
          //     : [{ valueType: 'current' }],
        });
      } else {
        form.setFieldsValue({
          list: [{ valueType: 'current' }],
        });
      }
    } else {
      form.resetFields();
    }
  }, [visible]);

  const getCurrentField = ({ fields, widget }) => {
    console.log('---fields');
    console.log(fields);
    console.log('---widget');
    console.log(widget);
    let types: any[];

    if (!widget || !fields?.length) {
      return [];
    }

    switch (widget) {
      case 'input':
      case 'textarea':
      case 'seqNumber':
      case 'phone':
        types = ['phone', 'input', 'textarea', 'number', 'money', 'seqNumber'];
        break;
      case 'number':
      case 'money':
        types = ['number', 'money'];
        break;
      case 'multiSelect':
      case 'select':
      case 'lastProcessStatus':
      case 'lastProcessResult':
        types = ['input'];
        break;
      case 'date':
      case 'gmtCreate':
        types = ['date', 'gmtCreate'];
        break;
      case 'innerContact':
      case 'createUserId':
        types = ['innerContact', 'createUserId'];
        break;
      case 'department':
        types = ['department'];
        break;
      case 'address':
        types = ['address'];
        break;
      case 'address':
        types = ['address'];
        break;
      case 'relation':
        types = ['relation'];
        break;
      case 'email':
        types = ['email', 'input'];
        break;
    }
    return fields.filter((field) => types?.includes(field?.schema?.widget));
  };

  const getFieldTypesValues = (widget) => {
    if (!widget) {
      return [];
    }

    let types = [];

    switch (widget) {
      case 'input':
      case 'textarea':
      case 'seqNumber':
      case 'email':
        types = ['EQ', 'SEARCH'];
        break;
      case 'number':
      case 'money':
        types = ['LT', 'LTE', 'GT', 'GTE', 'EQ'];
        break;
      case 'multiSelect':
      case 'select':
      case 'lastProcessStatus':
      case 'lastProcessResult':
      case 'productStatus':
        types = ['IN'];
        break;
      case 'date':
      case 'gmtCreate':
        return ['LT', 'GT', 'BETWEEN'];
      case 'innerContact':
      case 'createUserId':
        types = ['EQ', 'IN'];
        break;
      case 'department':
        types = ['EQ', 'IN'];
        break;
      case 'phone':
        types = ['EQ'];
        break;
      case 'address':
        types = ['EQ'];
        break;
      case 'relation':
        types = ['EQ'];
        break;
      default:
        types = ['EQ'];
    }

    return types;
  };

  const getFieldTypes = ({ widget }) => {
    const types = getFieldTypesValues(widget);

    if (widget == 'date' || widget == 'gmtCreate') {
      return [
        {
          value: 'LT',
          label: '早于',
        },
        {
          value: 'GT',
          label: '晚于',
        },
        {
          value: 'BETWEEN',
          label: '范围之间',
        },
      ];
    }

    return FILTER_TYPES.filter((item) => types?.includes(item.value));
  };

  const getFixedFormItem = ({ field, filterType }) => {
    const widget = field?.widget;
    if (!widget) {
      return null;
    }

    switch (widget) {
      case 'input':
      case 'textarea':
      case 'seqNumber':
        return <Input placeholder="请输入" />;
      case 'number':
      case 'money':
        return <Input placeholder="请输入" />;
      case 'multiSelect':
      case 'select':
      case 'lastProcessStatus':
      case 'lastProcessResult':
      case 'productStatus':
        return <FixedSelect enum={field.enum} enumNames={field.enumNames} />;
      case 'date':
      case 'gmtCreate':
        return <FixedDateTime filterType={filterType} />;
      case 'innerContact':
      case 'createUserId':
        return <FixedInnerContact filterType={filterType} />;
      case 'department':
        return <FixedInnerDepartment filterType={filterType} />;
      case 'phone':
        return <Input placeholder="请输入" />;
      case 'address':
        return <Input placeholder="请输入" />;
      case 'email':
        return <Input placeholder="请输入" />;
    }
    return null;
  };
  const getRelationFormData = async (
    relationFormCode: string | undefined | null,
  ) => {
    let res = await getVisibleFormList();
    const formCode = getFormCode();
    const { data } = res;
    // source 分组：2 客户管理，1 探迹CRM，0 自定义表单，4 OA审批
    // 关联表单排除自己
    const crmData = data?.filter(
      (ele) => ele?.source !== 4 && ele?.formCode !== formCode,
    );

    setRelationList(crmData || []);
    if (relationFormCode) {
      getSchemaData(relationFormCode);
    }
  };

  const getCurrentFormOptions = (widget: any) => {
    // 过滤当前对象的值
    const curWrapFields = wrapFields.filter(
      (item: any) =>
        item.schema.id !== currentSchema.id &&
        !NO_SUPPORT_WIDGETS.includes(item?.schema?.widget),
    );

    const finalData: any[] = [];
    let _cloneData = _.cloneDeep(curWrapFields);

    _cloneData?.forEach((ele: any) => {
      const schema = ele.schema;
      if (!['table', 'relation'].includes(schema?.widget)) {
        finalData.push(ele);
      }
    });

    const currentOptions = getCurrentField({
      fields: finalData,
      widget: widget,
    });

    return currentOptions;
  };

  return (
    <Modal
      title="关联设置"
      visible={visible}
      width={800}
      onCancel={onCancel}
      onOk={form.submit}
      wrapClassName={Style.addModal}
      closeIcon={<IconFont type="icon-Close" />}
      bodyStyle={{ overflowY: 'auto' }}
    >
      <div className={Style.scrollContent}>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            name="relationFormCode"
            fieldKey="relationFormCode"
            rules={[
              {
                validateTrigger: 'onChange',
                validator: (rule: any, value: string) => {
                  if (!value) {
                    return Promise.reject('请选择表单');
                  }
                  if (
                    relationList.findIndex((i) => i.formCode === value) ===
                      -1 ||
                    value === '表单已被停用'
                  ) {
                    return Promise.reject('该表单已被停用，请重新选择');
                  }
                  return Promise.resolve(value);
                },
              },
            ]}
            label="关联目标表单"
          >
            <Select
              onChange={(value) => {
                // form.setFieldsValue({
                //   defaultFieldId: null,
                //   list: [{ valueType: 'current' }],
                // });
                getSchemaData(value, true);
              }}
              showSearch
              placeholder="请选择关联表单"
              filterOption={(input, option) => {
                return (option!.children as unknown as string)
                  .toLowerCase()
                  ?.includes(input.toLowerCase());
              }}
              virtual={false}
            >
              {relationList.map((item, index) => {
                return (
                  <Select.Option
                    value={`${item.formCode}`}
                    key={`${item.formCode}${index}`}
                  >
                    {item.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <div className={Style.desc}>当满足以下条件时</div>
          <Form.List name="list">
            {(fields, { add, remove }) => (
              <>
                <div>
                  {fields.map((field, index) => {
                    return (
                      <div key={field.name} className={Style.row}>
                        <div className={Style.item}>
                          {/* 关联表单字段 */}
                          <Form.Item noStyle shouldUpdate={() => true}>
                            {({ setFieldsValue, getFieldValue }) => {
                              return (
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'fieldId']}
                                  fieldKey={[field.fieldKey, 'fieldId']}
                                  rules={[
                                    {
                                      required: true,
                                      message: '必填项',
                                    },
                                    {
                                      validator: (rule, values) => {
                                        console.log(values, 'values');
                                        if (values === '字段已被删除') {
                                          return Promise.reject(
                                            '字段已被删除，请重新选择',
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                    },
                                  ]}
                                >
                                  <Select
                                    onChange={(e) => {
                                      const list = getFieldValue(`list`);
                                      const rowValue = list[field.name];

                                      const fieldId = rowValue?.fieldId;
                                      const innerItem = schameList.find(
                                        (item) => item.id == fieldId,
                                      );
                                      const innerFieldWidget =
                                        innerItem?.widget;
                                      const types =
                                        getFieldTypesValues(innerFieldWidget);
                                      list[field.name] = {
                                        ...list[field.name],
                                        // filterType: types?.[0],
                                        filterType: '',
                                        valueType: 'current',
                                        value: '',
                                        currentFormOptions:
                                          getCurrentFormOptions(
                                            innerFieldWidget,
                                          ),
                                      };

                                      setFieldsValue({
                                        list: [...list],
                                      });
                                    }}
                                    placeholder="关联表单字段"
                                  >
                                    {schameList.map((item, index) => {
                                      return (
                                        <Select.Option
                                          value={item.id}
                                          key={`${item.id}${index}`}
                                        >
                                          {item.title}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </div>
                        <div className={Style.item}>
                          <Form.Item noStyle shouldUpdate={() => true}>
                            {({ setFieldsValue, getFieldValue }) => {
                              const list = getFieldValue(`list`);
                              const rowValue = list[field.name];
                              const fieldId = rowValue?.fieldId;
                              const innerItem = schameList.find(
                                (item) => item.id == fieldId,
                              );
                              const innerFieldWidget = innerItem?.widget;
                              const filterTypes = getFieldTypes({
                                widget: innerFieldWidget,
                              });

                              return (
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'filterType']}
                                  fieldKey={[field.fieldKey, 'filterType']}
                                  rules={[
                                    {
                                      required: true,
                                      message: '必填项',
                                    },
                                  ]}
                                >
                                  <Select
                                    onChange={(val) => {
                                      list[field.name] = {
                                        ...list[field.name],
                                        valueType: 'current',
                                        filterType: val,
                                        value: '',
                                      };

                                      setFieldsValue({
                                        list: [...list],
                                      });
                                    }}
                                    placeholder="请选择"
                                  >
                                    {filterTypes.map((item) => {
                                      return (
                                        <Select.Option
                                          value={item.value}
                                          key={item.value}
                                        >
                                          {item.label}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </div>
                        <div className={Style.item}>
                          <Form.Item noStyle shouldUpdate={() => true}>
                            {({ getFieldValue, setFieldsValue }) => {
                              const list = getFieldValue(`list`);
                              const rowValue = list[field.name];
                              const fieldId = rowValue?.fieldId;
                              const innerItem = schameList.find(
                                (item) => item.id == fieldId,
                              );
                              const innerFieldWidget = innerItem?.widget;
                              const VALUE_TYPES = [
                                {
                                  value: 'current',
                                  label: '当前表单字段',
                                },
                              ];

                              if (innerFieldWidget !== 'relation') {
                                VALUE_TYPES.push({
                                  value: 'fixed',
                                  label: '自定义',
                                });
                              }

                              return (
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'valueType']}
                                  fieldKey={[field.fieldKey, 'valueType']}
                                  rules={[
                                    {
                                      required: true,
                                      message: '必填项',
                                    },
                                  ]}
                                >
                                  <Select
                                    onChange={(valueType) => {
                                      list[field.name] = {
                                        ...list[field.name],
                                        valueType,
                                        value:
                                          valueType === 'fixed' &&
                                          CUSTOM_FIXED_SUPPORT_OBJECT_TYPE.includes(
                                            list[field.name]?.fieldId?.split(
                                              '_',
                                            )[0],
                                          )
                                            ? []
                                            : '',
                                      };
                                      setFieldsValue({
                                        list: [...list],
                                      });
                                    }}
                                    placeholder="请选择"
                                  >
                                    {VALUE_TYPES.map((item) => {
                                      return (
                                        <Select.Option
                                          value={item.value}
                                          key={item.value}
                                        >
                                          {item.label}
                                        </Select.Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </div>
                        <div
                          className={Style.item}
                          // style={{ minWidth: 220, maxWidth: 220 }}
                        >
                          <Form.Item
                            noStyle
                            shouldUpdate={() => true}
                            className={Style.item}
                          >
                            {({ getFieldValue }) => {
                              const list = getFieldValue(`list`);
                              const rowValue = list[field.name];
                              const fieldId = rowValue?.fieldId;
                              const valueType = rowValue?.valueType;
                              const filterType = rowValue?.filterType;

                              const innerItem = schameList.find(
                                (item) => item.id == fieldId,
                              );

                              let element;
                              if (valueType === 'current') {
                                // 从当前表单中选
                                element = (
                                  <Select placeholder="请选择">
                                    {rowValue?.currentFormOptions?.map(
                                      (item) => {
                                        if (
                                          (item.schema?.hidden &&
                                            !item.schema?.hideControls) ||
                                          item.schema?.deleted
                                        ) {
                                          return null;
                                        }
                                        return (
                                          <Select.Option
                                            value={item.schema.$id.replace(
                                              /^#\/?/,
                                              '',
                                            )}
                                            key={item.schema.$id.replace(
                                              /^#\/?/,
                                              '',
                                            )}
                                            title={
                                              (item.parentTitle
                                                ? `${item.parentTitle
                                                    .replace(/^\/?/, '')
                                                    .replaceAll('/', '.')}.`
                                                : '') + item.schema.title
                                            }
                                          >
                                            {item.parentTitle
                                              ? `${item.parentTitle
                                                  .replace(/^\/?/, '')
                                                  .replaceAll('/', '.')}.`
                                              : ''}
                                            {item.schema.title}
                                          </Select.Option>
                                        );
                                      },
                                    )}
                                  </Select>
                                );
                              } else {
                                // 自定义 需要做各种判断
                                element = getFixedFormItem({
                                  field: innerItem,
                                  filterType,
                                });
                              }

                              return (
                                <Form.Item
                                  {...field}
                                  name={[field.name, 'value']}
                                  fieldKey={[field.fieldKey, 'value']}
                                  rules={[
                                    {
                                      required: true,
                                      message: '必填项',
                                    },
                                    {
                                      validator: (rule, values) => {
                                        console.log(values, 'values');
                                        if (values === '字段已被删除') {
                                          return Promise.reject(
                                            '字段已被删除，请重新选择',
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                    },
                                  ]}
                                >
                                  {element}
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </div>
                        <div className={Style['op-icon']}>
                          {(form.getFieldValue('list') || []).length - 1 ==
                          index ? (
                            <IconFont
                              type="icon-add-line"
                              className={Style['add-icon']}
                              onClick={() =>
                                add({
                                  valueType: 'current',
                                })
                              }
                            />
                          ) : null}
                          {(form.getFieldValue('list') || []).length != 1 ? (
                            <IconFont
                              type="icon-shanchu1"
                              className={Style['remove-icon']}
                              onClick={() => {
                                const list = form.getFieldValue('list') || [];
                                if (list?.length == 1) {
                                  message.warning('最少保留一项');
                                  return;
                                }
                                remove(field.name);
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </Form.List>
          <Form.Item
            name="defaultFieldId"
            fieldKey="defaultFieldId"
            rules={[
              {
                required: true,
                message: '必填项',
              },
              {
                validator: (rule, values) => {
                  if (values === '字段已被删除') {
                    return Promise.reject('字段已被删除，请重新选择');
                  }
                  return Promise.resolve();
                },
              },
            ]}
            label="默认值等于"
          >
            <Select
              showSearch
              placeholder="关联表单字段"
              filterOption={(input, option) => {
                return (option!.children as unknown as string)
                  .toLowerCase()
                  ?.includes(input.toLowerCase());
              }}
            >
              {sameTypeList.map((item) => {
                return (
                  <Select.Option value={item.id} key={item.id}>
                    {item.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
