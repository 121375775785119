/*
 * :file description:
 * :name: \low-code-platform\src\pages\form\constant.ts
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-22 11:19:36
 * :last editor: gaoch
 * :date last edited: 2021-10-22 11:24:12
 */

export enum RenderType {
  SEARCH = 'search',
  CREATE = 'create',
}
