/*
 * @Description:
 * @FilePath: \low-code-platform\src\pages\form\formDetail.tsx
 * author: 段文君
 * copyright: (c) 2022, Tungee
 * @Date: 2022-01-04 19:54:24
 * @LastEditors: 段文君
 * @LastEditTime: 2022-01-07 11:49:53
 */
import React, { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import { getHashParam } from '@/utils';
import FormDetail from '../../../tg-renderer/packages/form-render/src/form-render-core/src/FormDetail';

const FormDetaiPage = () => {
  const formCode = getHashParam('formCode'); // 'PROC-4F8C3C0E-3C82-43B0-BC8A-3C860561D83F';
  const instanceId = getHashParam('instanceId'); //'5d6e7b2f-c005-4535-b1ae-59b2b1383ac5';

  const [detailKey, setDetailKey] = useState(nanoid(12));

  useEffect(() => {
    setDetailKey(nanoid(12));
  }, [formCode, instanceId]);

  return (
    <div
      style={{ padding: '16px 24px', backgroundColor: '#fff', height: '100vh' }}
    >
      <FormDetail
        key={detailKey}
        formcode={formCode}
        instanceId={instanceId}
        renderFormat="page"
      />
    </div>
  );
};

export default FormDetaiPage;
