/*
 * :file description:
 * :name: \low-code-platform\src\services\form-generator\index.ts
 * :author: 宋海兵
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-26 15:30:19
 * :last editor: 宋海兵
 * :date last edited: 2021-10-26 16:28:54
 */
import { postRequest, getRequest } from '@/common/http';

interface IGetDepartmentUser {
  departmentId?: string;
  offset?: number;
  limit?: number;
}
// 组织架构-获取右侧用户
export async function getDepartmentUser(data: IGetDepartmentUser) {
  return postRequest(`/api/org/department/page/user`, data);
}

// 组织架构-获取组织架构树
export async function getTreeCompany() {
  return postRequest(`/api/org/department/treeCompany`);
}
