/*
 * :file description:
 * :name: /low-code-platform/tg-renderer/packages/form-render/src/widgets/DataSummary/DataSummarySetting/index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-12-14 18:04:45
 * :last editor: 肖国同
 * :date last edited: 2023-02-15 11:06:51
 */
import { useEffect, useState } from 'react';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import { useModel } from '@@/plugin-model/useModel';
import { Button, Checkbox, CheckboxOptionType, Modal } from 'antd';
import {
  BIZ_FILTER_SUMMARY,
  BIZ_SUMMARY_LIST,
} from '~@/packages/form-render/src/constant';

import c from './index.less';

interface IOptions {
  id: string;
  value: string;
  label: string;
}

export default function FilterOptionsSetting(props: { [key: string]: any }) {
  const { visible, onCancel, onOk, initValue } = props;
  const [value, setValue] = useState<string[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [allOptions, setAllOptions] = useState<CheckboxOptionType[]>([]);
  const [commonOptions, setCommonOptions] = useState<CheckboxOptionType[]>([]);
  const model = useModel('useGenerator', (model) => model);
  const { selected, flatten } = useStore();
  const { schemaRef, relatedFormRef } = model;
  const properties = schemaRef.current.properties || {};
  // const uuid = schemaRef.current.appUuid || '';
  const relatedFormInfos = relatedFormRef.current || [];

  // 合同和回款里面  未开票 已开票 未回款 已回款  不能被隐藏
  // 可能还要加一个逻辑判断 uuid
  // 中文匹配改成 id匹配
  const showPreFields = [
    'money_KfPQCxtDqDpD', // 已回款金额 合同表单
    'money_UwSNkmkTaVqX', // 未回款金额 合同表单
    'money_ZZSj2QHjXRVD', // 已开票金额 合同表单
    'money_8RgYknu4B4cd', // 未开票金额 合同表单
    'money_Iq0R96ejO6Hf', // 已回款金额 订单表单
    'money_jSBwe3RZ4npN', // 未回款金额 订单表单
    'money_CAKuF0zkrF4g', // 已开票金额 订单表单
    'money_0ssDIAe2ZlWd', // 未开票金额 订单表单
  ];
  const omitHiddenFields = (list = []) => {
    return list.filter((v) => {
      return (
        !flatten?.[`#/${v.id}`]?.schema?.hidden ||
        (flatten?.[`#/${v.id}`]?.schema?.widget === 'money' &&
          showPreFields.includes(flatten?.[`#/${v.id}`]?.schema?.id))
      );
    });
  };

  useEffect(() => {
    if (visible) {
      const allOptionsTemp = [] as IOptions[];
      // let list = ['contract_amount'] as string[];
      // BIZ_SUMMARY_LIST.forEach((key) => {
      //   if (!!relatedFormInfos.filter((item) => item.bizType === key).length) {
      //     list = list.concat(BIZ_FILTER_SUMMARY[key]);
      //   }
      // });
      const _allOptionsMap = new Map();
      Object.keys(properties)?.forEach((key) => {
        _allOptionsMap.set(key, properties[key]);
        const item = properties[key];
        // if (item.widget === 'money' && list.includes(item.bizAlias)) {

        if (['money', 'number'].includes(item.widget)) {
          allOptionsTemp.push({
            id: key,
            value: key,
            label: item.title,
          });
        } else if (item.widget === 'table') {
          Object.values(item?.items?.properties ?? {}).forEach(
            (itemChild: any) => {
              _allOptionsMap.set(itemChild.id, itemChild);
              if (['money', 'number'].includes(itemChild.widget)) {
                allOptionsTemp.push({
                  id: `${key}/${itemChild.id}`,
                  value: `${key}/${itemChild.id}`,
                  label: itemChild.title,
                });
              }
            },
          );
        }
      });
      setAllOptions([...omitHiddenFields(allOptionsTemp)]);
      const checkValue = (initValue?.propsValue?.fields || []).map(
        (item) => item.id,
      );
      setValue(checkValue);
    }
  }, [visible, initValue.propsValue, selected]);

  const onChange = (checkedValues: any[]) => {
    setCommonOptions(allOptions.filter((v) => checkedValues.includes(v.value)));
    setValue(checkedValues);
  };

  const onSelectAll = (e) => {
    if (!checkAll) {
      setCommonOptions(allOptions);
      setValue(allOptions.map((item) => item.id));
    } else {
      setCommonOptions([]);
      setValue([]);
    }
  };

  const handleOk = () => {
    onOk(commonOptions);
  };

  useEffect(() => {
    setCheckAll(commonOptions.length === allOptions.length);
  }, [commonOptions, allOptions]);

  useEffect(() => {
    setCommonOptions(allOptions.filter((v) => value.includes(v.value)));
  }, [value]);

  return (
    <Modal
      width={400}
      title="数据统计"
      visible={visible}
      onCancel={onCancel}
      wrapClassName={c['summary-modal-wrap']}
      footer={
        <>
          <Checkbox checked={checkAll} onClick={onSelectAll}>
            全选
          </Checkbox>
          <div>
            <Button onClick={onCancel}>取消</Button>
            <Button type="primary" onClick={handleOk}>
              确定
            </Button>
          </div>
        </>
      }
    >
      <div className={c.filter_options_container}>
        <div className={c.filter_title}>请选择需要汇总的字段</div>
        <Checkbox.Group
          value={value}
          options={allOptions}
          onChange={onChange}
        ></Checkbox.Group>
      </div>
    </Modal>
  );
}
