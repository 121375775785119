/*
 * :file description:
 * :name: \low-code-platform\src\models\useSider.ts
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-26 16:57:53
 * :last editor: 黄艺
 * :date last edited: 2021-10-28 15:56:50
 */
import { useState, useCallback } from 'react';

export interface ICallback {
  callback: () => void;
}

export default function useGenerator() {
  const [reloadMenus, setReloadMenus] = useState<ICallback>();

  const listnerReloadMenus = useCallback((callback) => {
    setReloadMenus({ callback });
  }, []);

  return {
    reloadMenus,
    listnerReloadMenus,
  };
}
