/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DefaultValueSelector\components\formula-editor\EditArea.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-03 15:36:32
 * :last editor: 黄艺
 * :date last edited: 2022-02-09 18:26:03
 */
require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');
require('codemirror/theme/neat.css');
require('codemirror/mode/xml/xml.js');
require('codemirror/mode/javascript/javascript.js');
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint';
import { useContext } from 'react';
import CodeMirror from 'codemirror';
import { Controlled as ReactCodeMirror } from 'react-codemirror2';
import './fomula-hint.js';
import { EditorContext } from './index';
import '../../index.less';
import c from '../../index.less';
import {
  Backspace,
  getLabelMap,
  insertBarcket,
  setValue as setDefaultValue,
} from './common/formulaEditor';
import { idToSchema } from '@/components/FormGenerator/utils';

const options = {
  keywords: [],
  textWrapping: !0,
  lineWrapping: !0,
  lineNumbers: !1,
  matchBrackets: !0,
  // autofocus: true,
  specialChars: /[\u0000-\u001f\u007f\u00ad\u200c-\u200f\u2028\u2029\ufeff]/,
  mode: 'formula',
};

const EditArea = ({}) => {
  const {
    setEditorInstance,
    flatten,
    widgetProps,
    updateContent,
    setUpdateContent,
    setErrorField,
  } = useContext(EditorContext);
  // const formData = widgetProps.addons.getValues();
  // const currentFlatten = flatten[formData.$id];
  // const { rely } = currentFlatten?.schema || {};

  const handleChange = (cm, event) => {
    if (event.origin != 'complete') {
      //未选中
      cm.showHint({
        hint: CodeMirror.hint.formula,
        completeSingle: false,
        shown: function () {
          console.log('显示了');
        },
        select: function (cpt, ele) {
          console.log(cpt, ele);
        },
        pick: function (item) {
          console.log(item);
        },
      });
    } else {
      insertBarcket(cm);
    }
  };

  const setEditorValue = (editor: any) => {
    const schema = idToSchema(flatten);
    const labelMap = getLabelMap(schema.properties);
    const formData = widgetProps.addons.getValues();
    const currentFlatten = flatten[formData.$id];
    const { rely } = currentFlatten?.schema || {};
    setTimeout(() => {
      const invalid = setDefaultValue(rely?.formula, labelMap, editor);
      setErrorField && setErrorField(invalid);
    }, 200);
  };

  const onEditorMount = (editor) => {
    setEditorInstance(editor);
    setEditorValue(editor);
    editor.addKeyMap({
      Backspace,
    });
  };

  // useEffect(() => {
  // const formData = widgetProps.addons.getValues();
  //   const currentFlatten = flatten[formData.$id];
  //   const { rely } = currentFlatten?.schema || {};
  //   setValue(rely || '');
  // }, [JSON.stringify(flatten)]);

  return (
    <ReactCodeMirror
      className={c.codemirror}
      editorDidMount={(editor) => {
        onEditorMount(editor);
      }}
      value={updateContent}
      options={options}
      onBeforeChange={(editor, data, value) => {
        setUpdateContent(value);
      }}
      onChange={(editor, data, value) => {
        handleChange(editor, data);
      }}
    />
  );
};

export default EditArea;
