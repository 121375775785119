export const PsiBiztype = [
  'psi_supplier', //供应商
  'psi_purchase_order',
  'psi_purchase_storage',
  'psi_purchase_return',
  'psi_sale_outbound',
  'psi_sale_return', //
  'psi_stock_inquiry', // 查看 导出
  'psi_inventory', // 查看 新建 导入 导出
  'psi_rest_storage', //
  'psi_rest_outbound',
];

export const SYSTEM_FIELDS = [
  {
    id: 'gmtCreate',
    value: 'gmtCreate',
    label: '创建时间',
  },
  {
    id: 'createUserName',
    value: 'createUserName',
    label: '创建人',
  },
];

export const PROCESS_FORM_SYSTEM_FIELDS = [
  {
    id: 'lastProcessStatus',
    value: 'lastProcessStatus',
    label: '审批状态',
  },
  {
    id: 'lastProcessResult',
    value: 'lastProcessResult',
    label: '审批结果',
  },
  {
    id: 'lastProcessDate',
    value: 'lastProcessDate',
    label: '审批结束时间',
  },
  {
    id: 'currentNode',
    value: 'currentNode',
    label: '当前节点',
  },
  {
    id: 'currentApproveUser',
    value: 'currentApproveUser',
    label: '当前审核人',
  },
];

export const CUSTOMER_FORM_SYSTEM_FIELDS = [
  {
    id: 'allTags',
    value: 'allTags',
    label: '客户标签',
  },
];

export const CUSTOMER_BIZ_TYPES = ['crm_customer', 'crm_customer_personal'];
