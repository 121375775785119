import React, { useContext, useEffect, useMemo, useState } from 'react';
import c from '../../business/ModalRule.less';
import FormulaEditorModal from '../FormulaEditorModal';
import CoreContext from '../../context';
import { getUrlParam } from '@/utils';
import { utils, idToSchema } from '../FormulaEditorModal/common/utils';
import {
  getLabelMap,
  stringToUnicode,
} from '../FormulaEditorModal/common/formulaEditor';
import { Tooltip } from 'antd';

interface IProps {
  onChange?: (val: IFormulaValue) => void;
  value?: IFormulaValue;
  isCreate?: boolean;
  isEdit?: boolean;
  isDelete?: boolean;
  isAdvancedFunctions?: boolean;
  originRule?: IFormulaValue;
  form?: string;
}

export default (props: IProps) => {
  const {
    onChange,
    value,
    isEdit = false,
    isCreate = false,
    isDelete = false,
    isAdvancedFunctions,
    originRule,
    form,
  } = props;
  const [formulaVisible, setFormulaVisible] = useState(false);
  const { schemaList } = useContext(CoreContext);
  const formCode = getUrlParam('formCode');

  const onFormulaVisibleChange = () => {
    setFormulaVisible(!formulaVisible);
  };

  const ruleStr = useMemo(() => {
    const { displayRule } = value ?? {};
    if (!displayRule) {
      return '';
    }
    const currentFlatten = schemaList.filter(
      (item) => item['#'].formCode === formCode,
    );
    const otherFlatten = isAdvancedFunctions
      ? schemaList.filter((item) => item['#'].formCode !== formCode)
      : [];
    const flattens = {
      currentFlatten,
      otherFlatten,
    };
    const newSchema = { $id: '#', id: '#', properties: {} };
    const parentFlatten: Array<any> = [];
    utils?.forEach(flattens, (key, flatten) => {
      utils?.forEach(flatten, (index, val) => {
        if (val) {
          const schema = idToSchema(val);
          newSchema.properties = Object.assign(
            newSchema.properties,
            schema.properties,
          );
          parentFlatten.push({
            title: val['#'].$title,
            formCode: val['#'].formCode,
            $id: val['#'].schema.$id.split('#')[1],
          });
        }
      });
    });
    const labelMap = getLabelMap(newSchema.properties);
    const d: string[] = [],
      e: { [key: string]: any }[] = [];

    var f = displayRule.split('\n');
    utils?.forEach(f, function (index: number, item: string) {
      var f = '',
        g = item.split(/(\$[\w\.#@]+)/g);
      utils?.forEach(g, function (b: number, str: string) {
        if (/^\$(_widget_|_formula_|ext)/.test(str)) {
          let target;
          str = str.replace('_old', '');
          utils.startWith(str, '$ext')
            ? (target = '扩展字段')
            : labelMap &&
              (target =
                labelMap[str.split('true')[0]] ||
                (!str?.includes('.')
                  ? {
                      ...parentFlatten.find((parent) => {
                        if (str?.includes('_old'))
                          return (
                            str?.includes(
                              `${stringToUnicode(parent.formCode)}`,
                            ) && parent.$id === '_old'
                          );
                        else
                          return (
                            str?.includes(
                              `${stringToUnicode(parent.formCode)}`,
                            ) && parent.$id !== '_old'
                          );
                      }),
                      isParent: true,
                    }
                  : null));
          let h = !1;
          // utils.isNull(target) &&
          //   ((target = '字段已被删除'), (h = !0));
          var i = str.replace('$', '').split('#'),
            j = i[0],
            k = i[1];
          //   l = CodeMirror.Pos(index, f.length);
          // TOFIXED 只显示当前表单字段被删除
          if (!target?.title) {
            if (target && target.isParent) {
              f += '表单已被删除';
            } else {
              f += '字段已被删除';
            }
          } else {
            f += '' + target?.title + '';
          }
          // var m = CodeMirror.Pos(index, f.length);
          e.push(target?.title || target);
        } else {
          f += str;
        }
      }),
        d.push(f);
    });
    return d.join('');
  }, [value, schemaList]);

  return (
    <>
      <div className={c.rule}>
        {value?.displayRule ? (
          <>
            <span className={c.rule_pray}>已设置关联公式</span>
            <span onClick={onFormulaVisibleChange} className={c.rule_edit}>
              修改
            </span>
          </>
        ) : (
          <span onClick={onFormulaVisibleChange} className={c.rule_blue}>
            设置关联公式
          </span>
        )}
      </div>
      {!!ruleStr && (
        <div className={c.rule_preview}>
          {/* <Tooltip
            title={ruleStr}
            overlayStyle={{ width: 426, maxWidth: 426 }}
            overlayInnerStyle={{ borderRadius: 6 }}
          >
          </Tooltip> */}
          <div className={c.rule_preview_str}>{ruleStr}</div>
        </div>
      )}
      <FormulaEditorModal
        visible={formulaVisible}
        schemaList={schemaList}
        isCreate={isCreate}
        isEdit={isEdit}
        isDelete={isDelete}
        fillValue={value}
        isAdvancedFunctions={isAdvancedFunctions}
        form={form}
        originRule={originRule}
        onChange={onChange}
        onClose={onFormulaVisibleChange}
      />
    </>
  );
};
