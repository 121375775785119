/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\table-render\src\utils.js
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-22 13:52:56
 * :last editor: hyw
 * :date last edited: 2022-04-02 16:51:28
 */
import moment from 'moment';
import { Tag, Tooltip } from 'antd';
import { keys, map, split, trim } from 'lodash-es';
import { getFileIcon } from '../../form-render/src/widgets/EditAttachment/utils';

// 支持排序的组件
const sortedSupportWidgets = {
  date: {
    sortable: true,
    sorter: () => {},
  },
  number: {
    sortable: true,
    sorter: () => {},
  },
  money: {
    sortable: true,
    sorter: () => {},
  },
  input: {
    sortable: true,
    sorter: () => {},
  },
  seqNumber: {
    sortable: true,
    sorter: () => {},
  },
};

export const customJP = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    console.log('pp: ', data, e);
    return data;
  }
};

export const customJS = (data) => {
  try {
    return JSON.stringify(data);
  } catch (e) {
    console.log(e);
    return data;
  }
};

function stringContains(str, text) {
  return str.indexOf(text) > -1;
}

export const isObj = (a) =>
  stringContains(Object.prototype.toString.call(a), 'Object');

const isApiString = (str) => {
  if (typeof str !== 'string') return false;
  if (str.indexOf('$api.') === 0 || str.indexOf('$func.') === 0) return true;
  return false;
};

const getApiName = (str) => {
  if (str.indexOf('$api.') === 0) return str.substring(5);
  if (str.indexOf('$func.') === 0) return str.substring(6);
  return '';
};

export const buildSchema = (schema, api) => {
  if (typeof schema !== 'object' || schema === null) {
    if (isApiString(schema)) {
      const apiName = getApiName(schema);
      if (api && api[apiName] && typeof api[apiName] === 'function') {
        return api[apiName];
      } else {
        return () => {
          console.error('没有找到匹配的函数');
        };
      }
    }
    return schema;
  }
  if (Array.isArray(schema)) {
    const result = [...schema];
    return result.map((item) => buildSchema(item, api));
  }
  // 剩下是 result 是对象的情况
  const result = { ...schema };
  const keys = Object.keys(result);
  keys?.forEach((key) => {
    result[key] = buildSchema(result[key], api);
  });
  return result;
};

export const getDateTime = (time) => moment(time).format('YYYY-MM-DD HH:mm');
export const getDate = (time) => moment(time).format('YYYY-MM-DD');

// 格式化千分符
export const getMoneyType = (num) =>
  `¥${num}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

// 如果是函数，则解析，如果不是，直接返回值
export const parseFunctionValue = (value, params, cb) => {
  let _value = value;
  if (typeof _value === 'function') {
    _value = _value(params);
  } else {
    cb && typeof cb === 'function' && cb();
  }
  return _value;
};

// 自定义 table-cell 展示
// TODO
const customRender = (type, text, record, index) => {
  let colRender = null;
  switch (type) {
    // case 'dateRange':
    //   if (!text) return null;
    //   const [start, end] = text.split('~') ?? []
    //   const format = 'dateTime';
    //   return `${transformDateToString(start, format)} ~ ${transformDateToString(end, format)}`;
    case 'relation':
      colRender = text ? (
        <Tag
          style={{
            padding: '4px 8px',
            cursor: 'pointer',
          }}
          onClick={(e) => {
            console.log(e);
          }}
        >
          {customJP(text)?.title}
        </Tag>
      ) : null;
      break;
    case 'phone':
      colRender = (
        <span>
          {typeof text === 'string' && text.includes('{')
            ? customJP(text)?.value
            : text}
        </span>
      );
      break;
    case 'picture':
      colRender = (
        <>
          {text?.length
            ? (map(split(text, ','), trim) ?? [])?.map((img, idx) => (
                <img
                  key={`${img}_${idx}`}
                  width="30"
                  height="30"
                  style={{
                    borderRadius: '4px',
                    marginRight: '5px',
                    cursor: 'pointer',
                  }}
                  src={img}
                />
              ))
            : null}
        </>
      );
      break;
    case 'attachment':
      colRender = (
        <>
          {text?.length
            ? text?.map((img, idx) => (
                <img
                  key={`${img}_${idx}`}
                  width="28"
                  height="32"
                  style={{
                    borderRadius: '4px',
                    marginRight: '5px',
                    cursor: 'pointer',
                  }}
                  src={getFileIcon(img?.fileType)}
                />
              ))
            : null}
        </>
      );
      break;
    case 'nodeOfTable':
      colRender = (
        <>
          {text && Array.isArray(text)
            ? text.map((c, idx) =>
                c ? (
                  <div key={`${c}_${idx}`}>{c}</div>
                ) : (
                  <div key={`${c}_${idx}`} style={{ height: '22px' }}>
                    --
                  </div>
                ),
              )
            : null}
        </>
      );
      break;
    default:
      colRender = text ? (
        <Tooltip
          placement="top"
          color="white"
          overlayInnerStyle={{ color: '#000' }}
          title={text}
        >
          <div
            style={{
              width: '162px',
              maxWidth: '162px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ) : null;
  }
  return colRender;
};

// 生成 table columns
export const genTableColumns = (response) => {
  if (!response) return;
  console.log('response: ', response);
  const cols = response.formHeaderFieldList.filter((col) => {
    // table中不展示说明文字和流水号
    return !['textNote'].includes(col.widget);
  });
  console.log('cols: ', cols);
  const ComponentArray = cols.map((item) => {
    const { fieldId, order, title, widget } = item;
    return {
      key: fieldId,
      title,
      dataIndex: fieldId,
      showSorterTooltip: false,
      // sorter: sortedSupportWidgets[widget]?.sortable,
      // fixed: !!staticField,
      order,
      width: 162,
      ellipsis: true,
      render: (text, record, index) =>
        customRender(widget, text, record, index),
      children:
        widget === 'table'
          ? item.childFormHeaderFields.map((s) => {
              const { fieldId, title, widget } = s;
              const realWidget = ['picture', 'attachment'].includes(widget)
                ? widget
                : 'nodeOfTable';
              return {
                key: fieldId,
                width: 162,
                ellipsis: true,
                title,
                dataIndex: fieldId,
                widget: ['picture', 'attachment'].includes(widget)
                  ? widget
                  : 'nodeOfTable',
                render: (text, record, index) =>
                  customRender(realWidget, text, record, index),
              };
            })
          : undefined,
    };
  });
  console.log('table列 ', ComponentArray);
  return ComponentArray;
};

// 生成 table data
export const genTableData = (response) => {
  if (!response) return;
  console.log('response: ', response);
  let listData = response.instanceDataList.map((item, idx) => {
    let row = {
      key: item.instanceId,
      instanceId: item.instanceId,
      // staff_id: creator,
      // gmt_create: dateFormat(createTime),
      // gmt_modified: dateFormat(modifyTime),
    };
    item.itemList?.forEach((v) => {
      switch (v.widget) {
        // case 'picture':
        //   row[v.fieldId] = v.value ? map(split(v.value, ','), trim) : [];
        //   break;
        case 'attachment':
          row[v.fieldId] = v.value ? customJP(v.value) : [];
          // row[v.fieldId] = v.value
          //   ? map(split(v.value, '~'), (item) => {
          //       const matchResult = item.match(
          //         /instance(.*)((?!\.{1})(.*))\?Expires/,
          //       );
          //       return {
          //         type: matchResult?.[1]?.split('.')?.[1],
          //         uri: item,
          //       };
          //     })
          //   : [];
          break;
        case 'table':
          const source = customJP(v.value) ?? [];
          if (source.length) {
            const mapping = source[0].reduce((acc, cur) => {
              acc[cur.key] = [cur.value];
              return acc;
            }, {});
            source.slice(1)?.forEach((v) => {
              v?.forEach((t) => {
                mapping[t.key]?.push(t.value);
              });
            });
            console.log('@@1： ', item);
            console.log('@@2： ', mapping);

            row[v.fieldId] = {
              type: 'innerTable',
              value: keys(mapping).map((k) => ({
                fieldId: k,
                value: mapping[k],
              })),
            };
          }
          break;
        default:
          row[v.fieldId] = v.value;
          break;
      }
    });
    // dataArray?.forEach((v) => {
    //   const { key, value, extendValue, componentType } = v;
    //   row[key] = formatColValue(componentType, value);
    //   row.extendValue = extendValue;
    //   row.componentType = componentType;
    // });
    return row;
  });
  listData = listData.map((t) => {
    keys(t)?.forEach((kk) => {
      if (t[kk]?.type === 'innerTable') {
        t[kk]?.value?.forEach((u) => {
          t[u.fieldId] = u.value;
        });
        delete t[kk];
      }
    });
    return t;
  });
  console.log('listData~~', listData);
  return listData;
};
