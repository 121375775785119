/*
 * @Description:
 * @FilePath: /low-code-platform/src/pages/generator/relation/shareData/WidgetModal/WechatCustomerInfoColumn/index.tsx
 * :author: wu.shangru
 * :copyright: (c) 2023, Tungee
 * @Date: 2023-12-25 19:25:33
 * @LastEditors: wu.shangru
 * @LastEditTime: 2023-12-26 13:45:32
 */
import React from 'react';
import Avatar from '@/assets/form/image.png';
import Styles from './index.less';

interface IProps {
  avatar: string;
  corpName: string;
  customerName: string;
  nickname: string;
  customerType: string;
}

const WechatCustomerInfoColumn: React.FC<IProps> = ({
  avatar,
  corpName,
  customerName,
  nickname,
  customerType,
}) => {
  return (
    <div className={Styles.wrapper}>
      <Avatar
        src={avatar}
        name={customerName ?? ''}
        className={Styles.avatar}
      />
      <div>
        <div className={Styles.row}>
          <span className={Styles.blue}>{customerName}</span>
          {customerType === '企业微信用户' && (
            <span className={Styles.orange}>{corpName}</span>
          )}
          {customerType === '微信用户' && (
            <span className={Styles.green}>@微信</span>
          )}
        </div>
        {nickname && <div className={Styles.pray}>微信昵称：{nickname}</div>}
      </div>
    </div>
  );
};

export default WechatCustomerInfoColumn;
