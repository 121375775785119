import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'umi';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import tgRender from '../../../../tg-renderer/packages/table-render/src/renderer/index.tsx';

import FormHeader from '../components/FormHeader';
import FormFilter from '../components/FormFilter';
import FormTable from '../components/FormTable';
import FormDetail from '../../../../tg-renderer/packages/form-render/src/form-render-core/src/FormDetail';
import { getFormCode } from '@/utils';
import formApi from '@/services/form';

import c from './index.less';

export const FormConfigCtx = React.createContext('FormConfigCtx');

function Form() {
  const headRef = useRef();
  const filterRef = useRef();
  const tableRef = useRef();
  const detailRef = useRef();
  const formCode = getFormCode();
  const [formConfig, setFormConfig] = useState<any>();
  const [instanceId, setInstanceId] = useState<string>();
  const [formcode, setFormcode] = useState<string>();
  const [detailKey, setDetailKey] = useState(nanoid(12));

  const params = useParams();

  useEffect(() => {
    getAllFields();
  }, [params.id]);

  const getAllFields = () => {
    formApi
      .getAllField({
        formCode,
      })
      .then((res) => {
        if (!res?.data) return;
        const { content } = res.data;
        if (!content) return;
        const contentJSON = JSON.parse(content);

        const temp = {
          type: 'object',
          properties: {
            ...contentJSON.properties,
            createUserId: {
              format: 'personSelector',
              title: '创建人',
              type: 'string',
              widget: 'personSelector',
            },
            gmtCreate: {
              format: 'date',
              title: '创建时间',
              type: 'string',
              widget: 'date',
            },
            gmtModified: {
              format: 'date',
              title: '更新时间',
              type: 'string',
              widget: 'date',
            },
          },
        };
        setFormConfig({ ...res.data, content: temp });
      });
  };

  const showModal = (
    instanceId: string,
    formcode?: string | undefined,
    uniqueId?: string | undefined,
  ) => {
    setInstanceId(instanceId);
    setFormcode(formcode);
    uniqueId && detailRef.current.toggleDetail(false);
    setDetailKey(uniqueId);
    _.delay(() => {
      detailRef.current.toggleDetail(true);
    }, 0);
  };

  const hideModal = () => {
    detailRef.current.toggleDetail(false);
  };

  const search = (payload) => {
    tableRef.current.search(payload);
  };

  return (
    <>
      {tgRender({
        formCode: params.id ?? 'PROC-20950C1E-3743-4951-A00D-394DB07D910B',
        search: {},
        table: {
          onRow: (record) => {
            return {
              onClick: () => {
                console.log(record);
              },
            };
          },
        },
      })}
    </>
  );

  return (
    <div className={c.form_manage}>
      <FormConfigCtx.Provider
        value={{ ...formConfig, search, hideModal, showModal }}
      >
        <FormHeader ref={headRef} />
        <FormFilter ref={filterRef} />
        <FormTable ref={tableRef} showModal={showModal} />
        <FormDetail
          key={detailKey}
          ref={detailRef}
          formcode={formcode}
          renderFormat="drawer"
          instanceId={instanceId}
        />
      </FormConfigCtx.Provider>
    </div>
  );
}

Form.wrappers = ['@/wrappers/pageAuth'];

export default Form;
