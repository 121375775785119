/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DataTitle\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-13 14:08:12
 * :last editor: hyw
 * :date last edited: 2022-02-23 17:49:10
 */
import React from 'react';
import IconFont from '@/components/IconFont';
import { Select, Tooltip, Radio } from 'antd';
import _ from 'lodash';
import { FC } from 'react';
import { WidgetProps } from 'typings';
import TitleDemo from './components/TitleDemo';
import TitleSelector from './components/TitleSelector';
import c from './index.less';

const { Option } = Select;

export enum SettingType {
  // 0 系统默认
  DEFAULT,
  // 1 自定义
  CUSTOM,
}

export interface IValue {
  propsKey: string;
  propsValue: string;
  settingType: SettingType;
}

const DataTitle: FC<WidgetProps> = (props) => {
  const { value, onChange } = props;

  const handleChange = (type: SettingType) => {
    let propsValue = '';
    if (type === SettingType.DEFAULT) {
      propsValue = '#{createUserName}#{formTitle}';
    }
    onChange({
      ...value,
      propsValue,
      propsKey: 'dataTitle',
      settingType: type,
    });
  };

  const handleModifyChange = (e: Event) => {
    // 找不到更改数据源  先暂时解决下
    sessionStorage.setItem(`effectiveRangeAfterChange`, e.target.value);
    onChange({
      ...value,
      effectiveRangeAfterChange: e.target.value,
    });
  };

  return (
    <div className={c.container}>
      <div className={c.title}>
        <span>数据标题</span>
        <Tooltip
          placement="top"
          title={'通过设置数据的显示标题，快速识别一条数据'}
        >
          <IconFont className={c.tip_icon} type="icon-shuoming"></IconFont>
        </Tooltip>
      </div>
      <Select
        style={{ width: '100%' }}
        value={value?.settingType}
        defaultValue={SettingType.DEFAULT}
        onChange={(e) => handleChange(e)}
      >
        <Option value={SettingType.DEFAULT}>默认（提交人+表单名称）</Option>
        <Option value={SettingType.CUSTOM}>自定义</Option>
      </Select>
      {value?.settingType !== SettingType.CUSTOM ? (
        <TitleDemo />
      ) : (
        <TitleSelector onChange={onChange} value={value} />
      )}
      <div className={c.modify}>
        <Radio.Group
          value={value.effectiveRangeAfterChange}
          onChange={(e) => handleModifyChange(e)}
        >
          <Radio value={0}>修改数据标题后仅对新数据生效</Radio>
          <Radio value={1}>
            修改数据标题后对所有数据生效
            <Tooltip placement="left" title={'修改为此规则后，预计次日7点生效'}>
              <IconFont className={c.tip_icon2} type="icon-shuoming"></IconFont>
            </Tooltip>
          </Radio>
        </Radio.Group>
      </div>
    </div>
  );
};

export default DataTitle;
