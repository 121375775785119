import Input from '../../../../../tg-renderer/packages/form-render/src/widgets/Sift_Input';
import InputNumberRange from '../../../../../tg-renderer/packages/form-render/src/widgets/Sift_InputNumberRange';
import RangePicker from '../../../../../tg-renderer/packages/form-render/src/widgets/Sift_DateRange';
import Select from '../../../../../tg-renderer/packages/form-render/src/widgets/Sift_Select';
import MultipleSelect from '../../../../../tg-renderer/packages/form-render/src/widgets/Sift_MultipleSelect';
import Relation from '../../../../../tg-renderer/packages/form-render/src/widgets/Sift_AssociationSelector';
import PersonSelector from '../../../../../tg-renderer/packages/form-render/src/widgets/Sift_PersonSelector';

const widgets = {
  // 基础控件
  input: Input,
  textarea: Input,
  number: InputNumberRange,
  select: Select,
  multiSelect: MultipleSelect,
  date: RangePicker,
  money: InputNumberRange,
  phone: Input,
  relation: Relation,
  personSelector: PersonSelector,

  // 增强控件
  innerContact: PersonSelector,
  department: PersonSelector,
  seqNumber: Input,
  address: Input,
  idCard: Input,
  email: Input,

  // 系统控件
  createUserId: PersonSelector,
  gmtCreate: RangePicker,
  gmtModified: RangePicker,
  // 流程控件
  processStatus: Select,
  processResult: Select,
};

export default widgets;

// 系统字段：创建人、创建时间、更新时间     审批状态、审批结果（流程已开启）
// PersonSelector： 成员、部门
// Input： 地址、流水号、单行输入框、多行输入框、电话
// InputNumberRange： 数字、金额
// Select：单选
// MultipleSelect：多选
// DatePicker：日期
// Relation： 关联表单
