/*
 * :file description:
 * :name: \low-code-platform\src\components\FormGenerator\settings\presetFormSettings\returnMoney.js
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-11 20:32:17
 * :last editor: 黄艺
 * :date last edited: 2022-04-12 16:47:15
 */

export default [
  {
    text: '回款编号',
    name: 'seqNumber',
    icon: 'icon-zhaiyao',
    fieldId: 'seqNumber_1DIBHRJPYRTS0',
    schema: {
      title: '回款编号',
      type: 'string',
      widget: 'seqNumber',
      bizAlias: 'payment_no',
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        hidden: '{{true}}',
      },
      generationRules: {
        title: '',
        type: 'array',
        items: {
          type: 'object',
          properties: {},
        },
        widget: 'generationRules',
        default: [
          {
            type: 'date',
            value: 'YYYYMMDD',
          },
          {
            type: 'counter',
            value: {
              start: 1,
              length: 8,
              reset: 'year',
            },
          },
          {
            type: 'common',
            value: '',
          },
          {
            type: 'field',
            value: '',
          },
        ],
      },
    },
  },
  {
    text: '关联合同&订单（套件）',
    name: 'productStatus',
    icon: 'icon-shuoming',
    extraText: '关联表单',
    schema: {
      title: '关联合同&订单（套件）',
      type: 'string',
      widget: 'bizSuite',
      relatedMode: '',
      // hidden: true,
      properties: {
        relation_1X5809TY6LI80: {
          quote: 1,
          hidden: false,
          rely: {
            formula: '',
            fields: {},
            type: 'rely',
          },
          bizAlias: 'payment_related_contract',
          id: 'relation_1X5809TY6LI80',
          isRelateForm: true,
          title: '合同',
          fields: {},
          type: 'object',
          widget: 'relation',
          dataSource: {
            params: {
              filters: [],
            },
            target: {
              appUuid: 'SWAPP-B000517C311210E6A14FE35858A67512',
              bizType: 'crm_contract',
              formCode: 'PROC-0B5E2F23-5294-4D60-BA39-BE09ADAAFC7B',
              appType: 0,
            },
          },
          required: true,
        },
        relation_1R5RENUV3TFK0: {
          quote: 1,
          hidden: false,
          bizAlias: 'payment_related_order',
          isRelateForm: true,
          title: '订单',
          id: 'relation_1R5RENUV3TFK0',
          fields: {},
          type: 'object',
          widget: 'relation',
          dataSource: {
            params: {
              filters: [],
            },
            target: {
              appUuid: 'SWAPP-B000517C311210E6A14FE35858A67512',
              bizType: 'crm_order',
              formCode: 'PROC-5B5D9292-B09C-4DC7-BDFA-43B21A7A9155',
              appType: 0,
            },
          },
          required: true,
        },
      },
      isPayment: false,
      bizType: 'contract_order_kit',
      extract: true,
      widget: 'bizSuite',
      contarct: [],
      bizAlias: 'contract_order_kit',
    },
    setting: {
      props: {
        type: 'string',
        widget: 'billingSetting',
      },
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        type: 'string',
        hidden: '{{true}}',
      },
    },
  },
  {
    text: '回款名称',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_DBAUIWQ0J140',
    schema: {
      title: '回款名称',
      type: 'string',
      widget: 'input',
      bizAlias: 'payment_name',
      max: 100,
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '回款金额',
    name: 'money',
    icon: 'icon-a-jine2x',
    fieldId: 'number_VL990EGRB740',
    schema: {
      title: '回款金额',
      type: 'number',
      widget: 'money',
      bizAlias: 'payment_amount',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
  },
  {
    text: '发生时间',
    name: 'date',
    icon: 'icon-riqi',
    schema: {
      title: '发生时间',
      type: 'string',
      format: 'date',
      widget: 'date',
      required: true,
    },
    setting: {
      format: {
        title: '日期类型',
        type: 'string',
        enum: ['dateTime', 'date'],
        enumNames: ['年-月-日 时:分:秒', '年-月-日'],
        widget: 'baseSelect',
        order: 5,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '经手人',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    schema: {
      title: '经手人',
      type: 'string',
      widget: 'input',
      required: true,
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '支付方式',
    name: 'select',
    icon: 'icon-danxuankuang1',
    schema: {
      title: '支付方式',
      type: 'object',
      enum: ['option_0', 'option_1', 'option_2', 'option_3'],
      enumNames: ['现金', '支票', '银行转账', '其他'],
      widget: 'select',
      required: true,
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1', 'option_2', 'option_3'],
        enumNames: ['现金', '支票', '银行转账', '其他'],
        widget: 'selectOptions',
        order: 12,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '备注',
    name: 'textarea',
    icon: 'icon-duohangshurukuang',
    schema: {
      title: '备注',
      type: 'string',
      format: 'textarea',
      widget: 'textarea',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
];
