/*
 * @Description:
 * @FilePath: /low-code-platform/tg-renderer/packages/form-render/src/widgets/SelectTreeOptions/index.tsx
 * :author: wu.shangru
 * :copyright: (c) 2023, Tungee
 * @Date: 2023-11-29 21:12:24
 * @LastEditors: wu.shangru
 * @LastEditTime: 2023-12-14 11:48:41
 */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Switch, Tooltip, Modal, Popover, message } from 'antd';
import IconFont from '@/components/IconFont';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Styles from './index.less';
import SortManagement from './Modal';

const { confirm } = Modal;

const SelectTreeOptions = ({ value = '', ...rest }) => {
  console.log(value, rest, '1DDD');
  const { onChange, hiddenEditableFreeze = false, addons = {} } = rest || {};
  const {
    setValues,
    setValueByPath,
    getValues,
    setSchemaByPath,
    formData = {},
  } = addons;
  const { enumTree, enumOptionId } = formData;
  const { hiddenEditableFreeze: hiddenEditableFreezeInSchema } = rest?.schema;
  const [modalVisible, setModalVisible] = useState(false);

  const childRef = useRef(null);

  const onCancel = () => {
    setModalVisible(false);
  };
  const onOk = () => {
    (childRef?.current as any)?.setTreeData();
  };

  return (
    <div className={Styles.wrapper}>
      <div
        className={Styles.operaBtn}
        onClick={() => {
          if (hiddenEditableFreezeInSchema) {
            message.warning('组件已经被禁止编辑');
            return;
          }
          setModalVisible(true);
        }}
      >
        {value ? '已设置选项' : '设置选项'}
      </div>
      <Modal
        title="设置选项"
        visible={modalVisible}
        width={800}
        bodyStyle={{ padding: '16px 20px' }}
        onCancel={onCancel}
        onOk={onOk}
        wrapClassName={Styles.addModal}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <SortManagement
          cRef={childRef}
          setValues={setValues}
          setSchemaByPath={setSchemaByPath}
          setModalVisible={setModalVisible}
          treeData={value}
          getValues={getValues}
          enumOptionId={enumOptionId}
        />
      </Modal>
    </div>
  );
};

export default SelectTreeOptions;
