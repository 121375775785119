/*
 * :file description:
 * :name: /low-code-platform/tg-renderer/packages/form-render/src/widgets/JumpLink/index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-31 14:07:27
 * :last editor: 周亚港
 * :date last edited: 2023-12-12 20:12:42
 */
import { Alert, Button, message } from 'antd';
import { baseFormBizTypes, getUrlParam } from '@/utils';
import './index.less';
import * as dd from 'dingtalk-jsapi';
import { useMemo } from 'react';
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';
import { getToken, notInDD } from '@/utils';

/**
 * 通过source判断是什么平台
 * CloudPlatform  云平台   1688  1688平台
 */
const JumpLink = (props) => {
  console.log('🚀 ~ file: index.tsx ~ line 14 ~ JumpLink ~ props', props);
  // 去设置
  const toSettting = () => {
    // 销售阶段 opportunity_sale_step 合同状态 contract_status 产品状态 product_status
    // 产品分类  product_type
    // 客户跟进状态 customer_follow_up_status
    let bizAlias = props?.addons?.formData?.bizAlias;
    let jumpUrl = '';
    /**
     * 判断是否在探迹云平台上打开
     */
    const isERP = getUrlParam?.('source') === 'CloudPlatform';
    const source = getUrlParam?.('source') || '';
    const erpUrl: any = {
      contract_status: `${ERP_COMMON_DOMAIN}/settings/newcrm/management#/businessStatus`,
      product_type: `${ERP_COMMON_DOMAIN}/settings/newcrm/management#/productType`,
      product_status: `${ERP_COMMON_DOMAIN}/settings/newcrm/management#/businessStatus`,
      select_projectStage: `${ERP_COMMON_DOMAIN}/settings/newcrm/management#/businessStatus`,
    };
    console.log(bizAlias, 'bizAlias');
    switch (bizAlias) {
      case 'opportunity_sale_step':
        if (source == '1688') {
          jumpUrl = `${DOMAIN_QRCODE_1688}/tungee/management/?corpId=${
            window.__corpId
          }&source=${source}&tungeeToken=${encodeURIComponent(
            getToken(),
          )}#/businessStatus?bizType=crm_opportunity`;
        } else if (notInDD()) {
          jumpUrl = `${DOMAIN_QRCODE_CRM}/settings/ddcrm/management#/saleStage?code=setting_sale_stage&bizType=crm_opportunity`;
        } else {
          jumpUrl = `${DOMAIN_QRCODE}/tungee/management/?corpId=${window.__corpId}&source=${source}&subscribeId=${window.__subscribeId}#/saleStage?code=setting_sale_stage&bizType=crm_opportunity`;
        }
        break;
      case 'contract_status':
        if (isERP) {
          jumpUrl = erpUrl[bizAlias];
        } else if (source == '1688') {
          jumpUrl = `${DOMAIN_QRCODE_1688}/tungee/management/?corpId=${
            window.__corpId
          }&source=${source}&tungeeToken=${encodeURIComponent(
            getToken(),
          )}#/businessStatus?bizType=crm_contract`;
        } else if (notInDD()) {
          jumpUrl = `${DOMAIN_QRCODE_CRM}/settings/ddcrm/management#/contractStage?code=setting_contract_stage&bizType=crm_contract`;
        } else {
          jumpUrl = `${DOMAIN_QRCODE}/tungee/management/?corpId=${window.__corpId}&source=${source}&subscribeId=${window.__subscribeId}#/contractStage?code=setting_contract_stage&bizType=crm_contract`;
        }
        break;
      case 'product_status':
        if (isERP) {
          jumpUrl = erpUrl[bizAlias];
        } else if (source == '1688') {
          jumpUrl = `${DOMAIN_QRCODE_1688}/tungee/management/?corpId=${
            window.__corpId
          }&source=${source}&tungeeToken=${encodeURIComponent(
            getToken(),
          )}#/businessStatus?bizType=crm_product`;
        } else if (notInDD()) {
          jumpUrl = `${DOMAIN_QRCODE_CRM}/settings/ddcrm/management#/productStatus?code=setting_product_status&bizType=crm_product`;
        } else {
          jumpUrl = `${DOMAIN_QRCODE}/tungee/management/?corpId=${window.__corpId}&source=${source}&subscribeId=${window.__subscribeId}#/productStatus?code=setting_product_status&bizType=crm_product`;
        }
        break;
      case 'product_type':
        if (isERP) {
          jumpUrl = erpUrl[bizAlias];
        } else if (source == '1688') {
          jumpUrl = `${DOMAIN_QRCODE_1688}/tungee/management/?corpId=${
            window.__corpId
          }&source=${source}&tungeeToken=${encodeURIComponent(
            getToken(),
          )}#/productType?type=2`;
        } else if (notInDD()) {
          jumpUrl = `${DOMAIN_QRCODE_CRM}/settings/ddcrm/management#/productType?type=2`;
        } else {
          jumpUrl = `${DOMAIN_QRCODE}/tungee/management/?corpId=${window.__corpId}&source=${source}&subscribeId=${window.__subscribeId}#/productType?type=2`;
        }
        break;
      case 'customer_follow_up_status':
        const CRMIndependence = getUrlParam('CRMIndependence');
        if (CRMIndependence === 'true') {
          if (source == '1688') {
            jumpUrl = `${DOMAIN_QRCODE_1688}/tungee/management/?corpId=${window.__corpId}&source=${source}#/businessStatus?bizType=customer_all`;
          } else if (notInDD()) {
            jumpUrl = `${DOMAIN_QRCODE_CRM}/settings/ddcrm/management#/customerFollowStatus?code=setting_customer_follow_status&bizType=customer_all`;
          } else {
            jumpUrl = `${DOMAIN_QRCODE}/tungee/management/?corpId=${window.__corpId}&source=${source}&subscribeId=${window.__subscribeId}#/customerFollowStatus?code=setting_customer_follow_status&bizType=customer_all`;
          }
        } else {
          jumpUrl = `https://aflow.dingtalk.com/dingtalk/pc/query/pccrmapp.htm?corpid=${
            window.__corpId
          }&source=${source}&swfrom=workboard&noCorp=true&ddtab=true#/crmApp/toManageLast?sourceType=setting&hash=${encodeURIComponent(
            '#/crmApp/tj?source=customer',
          )}`;
        }
        break;
      case 'select_projectStage':
        if (isERP) {
          jumpUrl = erpUrl[bizAlias];
        } else if (source == '1688') {
          jumpUrl = `${DOMAIN_QRCODE_1688}/tungee/management/?corpId=${
            window.__corpId
          }&source=${source}&tungeeToken=${encodeURIComponent(
            getToken(),
          )}#/businessStatus?bizType=crm_product`;
        } else if (notInDD()) {
          jumpUrl = `${DOMAIN_QRCODE_CRM}/settings/ddcrm/management#/projectStage?code=setting_project_stage&bizType=pms_project`;
        } else {
          jumpUrl = `${DOMAIN_QRCODE}/tungee/management/?corpId=${window.__corpId}&source=${source}&subscribeId=${window.__subscribeId}#/projectStage?code=setting_project_stage&bizType=pms_project`;
        }
        break;
      case 'relates_info':
        if (source == '1688') {
          jumpUrl = `${DOMAIN_QRCODE_1688}/tungee/management/?corpId=${
            window.__corpId
          }&source=${source}&tungeeToken=${encodeURIComponent(
            getToken(),
          )}&ddtab=true#/customerBaseSetting`;
        } else if (notInDD()) {
          jumpUrl = `${DOMAIN_QRCODE_CRM}/settings/ddcrm/management#/customerBaseSetting`;
        } else {
          jumpUrl = `${DOMAIN_QRCODE}/tungee/management/?corpId=${window.__corpId}&source=${source}&subscribeId=${window.__subscribeId}&ddtab=true#/customerBaseSetting`;
        }
        break;
      case 'customer_tags':
        if (source == '1688') {
          jumpUrl = `${DOMAIN_QRCODE_1688}/tungee/management/?corpId=${
            window.__corpId
          }&source=${source}&tungeeToken=${encodeURIComponent(
            getToken(),
          )}&ddtab=true#/tagManagement`;
        } else if (notInDD()) {
          jumpUrl = `${DOMAIN_QRCODE_CRM}/settings/ddcrm/management#/tagManagement`;
        } else {
          jumpUrl = `${DOMAIN_QRCODE}/tungee/management/?corpId=${window.__corpId}&source=${source}&subscribeId=${window.__subscribeId}&ddtab=true#/tagManagement`;
        }
        break;
      default:
        message.warning('跳转类型url不匹配');
        break;
    }

    jumpUrl && newtab(jumpUrl);
  };

  const description = useMemo(() => {
    const bizType = getUrlParam('bizType');
    return baseFormBizTypes.includes(bizType)
      ? props.description.replace('客户管理', '探迹CRM')
      : props.description;
  }, [props]);

  const newtab = (url = '') => {
    if (url) {
      // FIXME 判断跳转链接是否是相对路径
      let reg = /^(http|ftp|https|dingtalk):\/\/?/;
      const absolute = reg.test(url);
      let finalUrl = absolute ? url : location.origin + url;
      // FIXME 手工处理url,考虑场景较少
      let needNetTab = finalUrl?.includes('ddtab') ? '' : 'ddtab=true';
      if (needNetTab) {
        finalUrl += (finalUrl?.includes('?') ? '&' : '?') + needNetTab;
      }
      if (dd?.pc) {
        dd.biz.util.openLink({
          url: finalUrl, //要打开链接的地址
          onSuccess: function (result) {
            console.log('使用钉钉新窗口打开页面');
          },
          onFail: function (err) {
            console.log(err);
          },
        });
      } else if (isOpenInTripartitePlatform('1688')) {
        // 1688 暂定使用 iframe 方案，因此使用 location.href 打开
        window.location.href = finalUrl;
      } else {
        window.open(finalUrl);
      }
    }
  };

  return (
    <div className="settingAlert">
      <Alert {...props} description={description} />
      <div>
        设置链接：
        <Button type="link" onClick={toSettting}>
          去设置
        </Button>
      </div>
    </div>
  );
};

export default JumpLink;
