/*
 * :file description:
 * :name: /low-code-platform/src/components/FormGenerator/components/Settings/GlobalSettings.jsx
 * :author: 肖国同
 * :copyright: (c) 2023, Tungee
 * :date created: 2023-02-03 15:26:19
 * :last editor: 肖国同
 * :date last edited: 2023-02-13 15:26:51
 */
import React, { useState, useEffect, useMemo } from 'react';
import FormRender, { useForm } from 'form-render';
import {
  FormPropsSettings,
  propsSettingsSummary,
  propsSettingsDataCard,
} from '../../settings';
import { useStore } from '../../utils/hooks';
import IconFont from '@/components/IconFont';
import { useLocation, useModel } from 'umi';
import { newtab } from '@/utils';
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';

let set = new WeakSet();

export const titleKey = [''];

export default function GlobalSettings({ widgets }) {
  const form = useForm();
  const location = useLocation();
  const { bizType } = location?.query || {};
  const [innerUpdate, setInnerUpdate] = useState(false);
  const {
    widgets: globalWidgets,
    frPropsSetting,
    userProps = {},
    onFromPropsSettingChange,
  } = useStore();
  const schema = useMemo(() => {
    // const globalSettings = userProps.globalSettings || defaultGlobalSettings;
    const globalSettings = userProps.GlobalSettings || FormPropsSettings;
    // CRM2.2 临时方案，扩展能力较差，后续新功能建议后端传递给前端处理

    // if (!('dataSummary' in Object.keys(globalSettings.properties))) {
    //   globalSettings.properties = Object.assign(
    //     globalSettings.properties,
    //     propsSettingsSummary,
    //   );
    // }

    if (!('dataAggregation' in Object.keys(globalSettings.properties))) {
      globalSettings.properties = Object.assign(
        globalSettings.properties,
        propsSettingsSummary,
      );
    }
    if (!('dataCard' in Object.keys(globalSettings.properties))) {
      globalSettings.properties = Object.assign(
        globalSettings.properties,
        propsSettingsDataCard,
      );
    }
    return globalSettings;
  }, [userProps.GlobalSettings]);

  const onDataChange = (value) => {
    if (!Object.keys(value).length) {
      return;
    }
    setInnerUpdate(true);
    onFromPropsSettingChange({
      formPropsSetting: Object.values(value).filter(
        (item) => item !== '#' && item,
      ),
    });
  };

  useEffect(() => {
    const formObj = {};
    if (innerUpdate) {
      setInnerUpdate(false);
    } else {
      frPropsSetting?.forEach((item) => {
        formObj[item?.propsKey] = item;
      });
      form.setValues(formObj);
    }
  }, [frPropsSetting]);

  const watchDataChange = (v) => {
    onDataChange(v);
  };

  // 去设置
  const goSetting = () => {
    const url = `${
      isOpenInTripartitePlatform('1688') ? DOMAIN_QRCODE_1688 : DOMAIN_QRCODE
    }/tungee/management/?subscribeId=${
      window.__subscribeId
    }#/outsideOffice?corpId=${window.__corpId}`;
    newtab(url);
  };

  return (
    <>
      <header>
        <IconFont type="icon-shezhi_1" style={{ fontSize: 16 }}></IconFont>{' '}
        表单属性设置
      </header>
      <div className="render-wrap">
        {bizType === 'crm_legwork' && (
          <div style={{ lineHeight: '25px', paddingBottom: 20 }}>
            <div
              style={{
                padding: '9px 16px',
                backgroundColor: 'rgba(0, 137, 255, 0.12)',
                borderRadius: '6px',
              }}
            >
              <div>
                {
                  '当前表单内所有字段均需在外勤记录>适用人员>配置外勤类型后生效，否则无法使用外勤功能'
                }
              </div>
              <div style={{ paddingTop: 5 }}>
                <a onClick={goSetting}>去设置</a>
              </div>
            </div>
          </div>
        )}

        <FormRender
          form={form}
          schema={schema}
          watch={{
            '#': (v) => setTimeout(() => onDataChange(v), 0),
          }}
          widgets={{ ...globalWidgets, ...widgets }}
        />
      </div>
    </>
  );
}
