/*
 * :file description:
 * :name: \low-code-platform\src\pages\form\components\AdvancedFilter\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-26 17:27:34
 * :last editor: hyw
 * :date last edited: 2022-02-24 11:17:16
 */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Modal, CheckboxOptionType, message, Button } from 'antd';
import FormRender, { useForm } from 'form-render';
import { omitBy, keys, compact } from 'lodash-es';
import { customJP, isJSON } from '@/utils/index';
import { genSearchParams } from '../../utils/parse_schema';
import widgets from './widgets-mapping';
import _ from 'lodash';
import IconFont from '@/components/IconFont';
import formApi from '@/services/form';
import { SUPPORTED_NOW, debarComponents } from '../../utils/parse_schema.js';
import Filter from '../../beta/filter';
import c from './index.less';
import Iconfilter from '@/images/icon_filter_close.svg';

interface IProps {
  [key: string]: any;
}

const SYSTEM_FIELDS = {
  createUserId: {
    format: 'personSelector',
    title: '创建人',
    type: 'string',
    widget: 'personSelector',
  },
  gmtCreate: {
    format: 'date',
    title: '创建时间',
    type: 'string',
    widget: 'date',
  },
  gmtModified: {
    format: 'date',
    title: '更新时间',
    type: 'string',
    widget: 'date',
  },
};

const SYSTEM_FLOW_FIELDS = {
  lastProcessResult: {
    enum: ['1', '2', '3'],
    enumNames: ['已同意', '已撤回', '已拒绝'],
    items: {
      type: 'string',
    },
    placeholder: '请选择',
    title: '审批结果',
    type: 'array',
    widget: 'select',
  },
  lastProcessStatus: {
    enum: ['1', '2', '3'],
    enumNames: ['审批中', '审批完成', '审批终止'],
    items: {
      type: 'string',
    },
    placeholder: '请选择',
    title: '审批状态',
    type: 'array',
    widget: 'select',
  },
};

const appendSystemField = (sourceSchema: {}, isFlow: boolean = false) => {
  sourceSchema.properties = {
    ...sourceSchema.properties,
    ...SYSTEM_FIELDS,
    ...(isFlow ? SYSTEM_FLOW_FIELDS : {}),
  };
  return sourceSchema;
};

export default function AdvancedFilter(props: IProps) {
  const { filterSchema = {}, search, isFlowChart } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const form = useForm();

  const schema = appendSystemField(filterSchema, isFlowChart);

  // 过滤掉不支持搜索的field
  const eliminateNonsupportField = (initialSchema: { [key: string]: any }) => {
    if (!initialSchema?.properties) return;
    return {
      ...initialSchema,
      properties: omitBy(initialSchema.properties, (value) => {
        return [
          // 'textarea',
          'dateRange',
          'picture',
          // 'money',
          'attachment',
          'table',
          'textNote',
        ].includes(value?.widget);
      }),
    };
  };

  // 去掉必填校验
  const eliminateRequiredProp = (initialSchema: { [key: string]: any }) => {
    if (!initialSchema?.properties) return;
    keys(initialSchema.properties)?.forEach((item) => {
      initialSchema.properties[item].required = false;
      initialSchema.properties[item].type = 'string';
    });
    return initialSchema;
  };

  const onFinish = (data, errors) => {
    console.log('payload ', data);
    const queryConditionList = keys(data).map((key) => {
      const val = data[key] ? customJP(data[key]) : undefined;
      const widget = schema?.properties[key]?.widget;
      if (!val) return;
      const isPersonSelector = [
        'innerContact',
        'department',
        'personSelector',
      ].includes(widget);
      const searchType = isJSON(data[key])
        ? JSON.parse(data[key]).searchType
        : undefined;
      return genSearchParams(key, widget, val.value, searchType);
    });
    const payload = {
      pageIndex: 1,
      pageSize: 10,
      queryConditionList: compact(queryConditionList),
      type: 'filter',
    };
    search(payload);
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = (e) => {
    if (e.currentTarget.className === 'ant-btn') {
      form.resetFields();
    } else {
      setIsModalVisible(false);
    }
  };

  useEffect(() => {
    if (!isModalVisible) return;
  }, [isModalVisible]);

  return (
    <div className={c.filter_options_setting}>
      <Button onClick={() => setIsModalVisible(true)} className={c.filter_btn}>
        <img src={Iconfilter} className={c.filterIcon} alt="" />
        <span>高级筛选</span>
      </Button>

      <Modal
        width={600}
        title="高级筛选"
        visible={isModalVisible}
        okText="筛选"
        cancelText="重置"
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <div className={c.filter_options_container}>
          <FormRender
            displayType="row"
            form={form}
            widgets={{ ...widgets }}
            schema={eliminateRequiredProp(eliminateNonsupportField(schema))}
            onFinish={onFinish}
          />
        </div>
      </Modal>
    </div>
  );
}
