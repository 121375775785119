/*
 * :file description:
 * :name: \low-code-platform\src\pages\generator\process\components\ProcessDesign\utils.ts
 * :author: 何志权
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-04-16 15:38:34
 * :last editor: hyw
 * :date last edited: 2022-01-21 17:31:11
 */

import { getNodeUuid } from '@/utils';
import {
  NODE_TYPES_MAP,
  GATEWAY_TYPES_MAP,
  defaultUserOrDepOrRole,
} from './constants';
import { edgeType } from '../FlowV2/types';
import _cloneDeep from 'lodash/cloneDeep';

export const createNode = (nodeType = NODE_TYPES_MAP.other, config?: any) => {
  return {
    _id: getNodeUuid(),
    node_type: nodeType,
    type: 'node',
    ...(nodeType === NODE_TYPES_MAP.other
      ? {
          name: '',
          node_handler_type: 'charger',
          node_deadline_time_num: null,
          node_deadline_time_unit: 'hour',
          node_is_overtime: false,
          node_overtime_num: 0,
          node_overtime_unit: 'hour',
          node_attachments: [],
        }
      : {}),
    ...config,
  };
};

export const createBranch = (config: any) => {
  return {
    _id: getNodeUuid(),
    name: '',
    node_type: 'other',
    type: 'branch',
    branch_filter_condition: null,
    ...config,
  };
};

// 获取上一个唯一节点
export const getPreNodeId = (nodeId: string, edges: edgeType[]) => {
  const lastEdge = edges.find((_edge) => _edge.target === nodeId);
  return lastEdge && lastEdge.source;
};

// 获取下一个唯一节点
export const getNextNodeId = (nodeId: string, edges: edgeType[]) => {
  const nextEdge = edges.find((_edge) => _edge.source === nodeId);
  return nextEdge && nextEdge.target;
};

// 获取下一层节点
export const getNextNodeIds = (nodeId: string, edges: edgeType[]) => {
  const nextEdges = edges.filter((_edge) => _edge.source === nodeId);
  return nextEdges.map((edge) => edge.target);
};

/**
 * 获取一个分支内所有节点，从branch节点直到网关结束节点中间所有的nodes
 * @param {string} branchNodeId 条件节点id
 * @param {[{source: string, target: string}]} edges 流程图所有连线数组
 * @param {string} gatewayId 网关id
 * @param {object} flowMap 流程图节点映射成的map对象
 */
export const getBranchNodes = (
  branchNodeId: string,
  edges: edgeType[],
  gatewayId: string,
  flowMap: any,
) => {
  const nodes = [branchNodeId];
  for (let i = 0; i < nodes.length; i++) {
    const nodeId = nodes[i];
    const nextNodes = getNextNodeIds(nodeId, edges);
    nextNodes?.forEach((nextNodeId) => {
      const nextNode = flowMap[nextNodeId];
      if (
        nextNode.gateway_id !== gatewayId ||
        nextNode.gateway_type !== GATEWAY_TYPES_MAP.end
      ) {
        if (!nodes.includes(nextNodeId)) {
          nodes.push(nextNodeId);
        }
      }
    });
  }
  return nodes;
};

// 数据转换成 list
export const computeData = (data: any) => {
  let showArr: Array<string> = [];
  let json: Object = _cloneDeep(data) || _cloneDeep(defaultUserOrDepOrRole);

  Object.entries(json)?.forEach((ele) => {
    ele[1]?.forEach?.((e: any) => {
      if (ele[0] === 'departments') e.type = 'department';
      if (ele[0] === 'users') e.type = 'employee';
      if (ele[0] === 'roles') e.type = 'role';
      showArr.push(e);
    });
  });
  return showArr;
};

// array转换 object
export const sortUserOrDept = (approvals: any) => {
  // 回填当前节点的表单信息
  let departArr: any[] = [],
    userArr: any[] = [],
    roleArr: any[] = [],
    dynamicPerson: any[] = [];
  approvals?.forEach((ele: any) => {
    ele.key = ele.id;
    ele.name = ele.label ?? ele.title;

    switch (ele.type) {
      case 'department':
        departArr.push(ele);
        break;
      case 'employee':
        userArr.push(ele);
        break;
      case 'role':
        roleArr.push(ele);
        break;
      case 'member_field':
        dynamicPerson.push(ele);
        break;
      case 'initiator':
      case 'principal':
      case 'manager':
      case 'direct_manager':
        dynamicPerson.push(ele);
        break;
    }
  });
  let userOrDepOrRole = {
    departments: departArr,
    users: userArr,
    roles: roleArr,
    dynamicPerson: dynamicPerson,
  };
  return userOrDepOrRole;
};
