/*
 * :file description:
 * :name: \low-code-platform\src\pages\generator\relation\business\components\FormulaEditorModal\Variable.tsx
 * :author: 杨海涛
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-08-08 15:35:40
 * :last editor: 杨海涛
 * :date last edited: 2022-08-08 19:58:22
 */

import { useContext, useEffect, useState } from 'react';
import { Card, Collapse } from 'antd';
import { EditorContext } from './index';
import { insertField } from './common/formulaEditor';
import classNames from 'classnames';
import CardTitle from './CardTitle';
import { formFieldObjToList, Searcher } from './common/utils';
import React from 'react';

import c from './index.less';
import { CaretRightOutlined } from '@ant-design/icons';
import { getUrlParam, getUuid } from '@/utils';

const { Panel } = Collapse;

interface IFlatten {
  children: string[];
  data: unknown;
  parent: string;
  formCode: string;
  $title: string;
  formName: string;
  parentTitle: string;
  schema: typeof IObj;
}

const Variable = () => {
  const currentFormCode = getUrlParam('formCode');
  const currentBizType = getUrlParam('bizType'); // 20221213 crm_contract crm_order  合同、订单表单  隐藏已回款金额 未回款金额 已开票金额 未开票金额
  const { editorInstance, flatten, isEdit } = useContext(EditorContext);
  const [usage, setUsage] = useState('');
  const { currentFlatten, beforeCurrent, otherFlatten } = flatten;
  const [result, setResult] = useState<IFlatten[]>([]);
  const [isSearch, setIsSearch] = useState(false);
  const [currentFormFieldList, setCurrentFormFieldList] = useState<
    Array<IFlatten[]>
  >([]);
  const [beforeCurrentFormFieldList, setBeforeCurrentFieldList] = useState<
    Array<IFlatten[]>
  >([]);
  const [otherFormFieldList, setOtherFormFieldList] = useState<
    Array<IFlatten[]>
  >([]);
  const [totalList, setTotalList] = useState<IFlatten[]>([]);

  const handleClick = (item: IFlatten) => {
    setTimeout(() => {
      const { schema, formCode, $title } = item;
      const a = {
        id: null,
        name: `${schema.$id.replace(/^#\.?/, '')}`,
        text: $title,
        formCode,
      };
      insertField(a, currentFormCode !== formCode, editorInstance, isEdit);
    }, 0);
  };

  const getFormFieldList = (flattenList: any[], isCurrent = true) => {
    return flattenList.map((item) => {
      const list = formFieldObjToList(item['#']?.children, item) || [];
      !isCurrent && list.unshift(item['#']);
      return list;
    });
  };

  const filterHiddenField = (flattenList: Array<any>) => {
    const _dataList = [];
    flattenList?.forEach((item) => {
      const noHiddenFields = item?.filter(
        (x) => x.schema?.hideControls || !x.schema?.hidden,
      );
      _dataList.push(noHiddenFields);
    });
    return _dataList;
  };

  useEffect(() => {
    let bcf: Array<IFlatten[]> = [],
      cf: Array<IFlatten[]>,
      of: Array<IFlatten[]>;
    if (isEdit) {
      if (beforeCurrent)
        bcf = filterHiddenField(getFormFieldList(beforeCurrent));
    }
    cf = filterHiddenField(getFormFieldList(currentFlatten));
    of = filterHiddenField(getFormFieldList(otherFlatten, false));
    setBeforeCurrentFieldList(bcf);
    setCurrentFormFieldList(cf);
    setOtherFormFieldList(of);
    setTotalList(
      bcf
        .concat(cf, of)
        .reduce((prev, cur) => prev.concat(cur), [] as IFlatten[]),
    );
  }, [isEdit, beforeCurrent, currentFlatten, otherFlatten]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const searchList = Searcher.search(totalList, value);
    const filterSearchList = searchList
      .map((item: any) => {
        const willHideFields = [
          'money_KfPQCxtDqDpD', // 已回款金额 合同表单
          'money_UwSNkmkTaVqX', // 未回款金额 合同表单
          'money_ZZSj2QHjXRVD', // 已开票金额 合同表单
          'money_8RgYknu4B4cd', // 未开票金额 合同表单
          'money_Iq0R96ejO6Hf', // 已回款金额 订单表单
          'money_jSBwe3RZ4npN', // 未回款金额 订单表单
          'money_CAKuF0zkrF4g', // 已开票金额 订单表单
          'money_0ssDIAe2ZlWd', // 未开票金额 订单表单
        ];
        if (willHideFields?.includes(item.schema.id)) {
          return null;
        }
        return item;
      })
      .filter((v) => v);
    setResult(filterSearchList);
    setIsSearch(value.length > 0);
  };

  const renderFormFieldList = (formFieldList: IFlatten[]) => (
    <ul className={c.list}>
      {formFieldList.map((item) => {
        if (!item) {
          return null;
        }
        // crm_contract', 'crm_order
        // 合同订单  搜索 金额过滤掉 当前 金额过滤掉 其他 金额过滤掉
        // 自定义    搜索 金额过滤掉 当前 金额过滤掉 其他 金额过滤掉
        const willHideFields = [
          'money_KfPQCxtDqDpD', // 已回款金额 合同表单
          'money_UwSNkmkTaVqX', // 未回款金额 合同表单
          'money_ZZSj2QHjXRVD', // 已开票金额 合同表单
          'money_8RgYknu4B4cd', // 未开票金额 合同表单
          'money_Iq0R96ejO6Hf', // 已回款金额 订单表单
          'money_jSBwe3RZ4npN', // 未回款金额 订单表单
          'money_CAKuF0zkrF4g', // 已开票金额 订单表单
          'money_0ssDIAe2ZlWd', // 未开票金额 订单表单
        ];
        if (willHideFields?.includes(item.schema.id)) {
          return null;
        }
        return (
          <li
            className={c.list_item}
            key={getUuid()}
            onClick={() => handleClick(item)}
          >
            <span
              className={c.title_field}
              title={
                (item.parentTitle ? item.parentTitle + '.' : '') +
                item?.schema.title
              }
            >
              {item.parentTitle && item.parentTitle + '.'}
              {item?.schema.title}
            </span>
            {item?.schema.id === '#' && <span className={c.tag}>表单</span>}
          </li>
        );
      })}
    </ul>
  );

  const renderFormPanel = (
    formFieldList: Array<IFlatten[]>,
    isOther: boolean,
  ) => {
    return isOther ? (
      <Collapse
        ghost
        className={c.list_wrap}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            style={{ fontSize: '12px' }}
            rotate={isActive ? 90 : 0}
          />
        )}
      >
        {formFieldList.map((item, index) => {
          const parentForm = item.filter((i) => !i?.parent)[0];
          return (
            <Panel header={parentForm?.schema.title} key={index}>
              <ul className={c.list}>{renderFormFieldList(item)}</ul>
            </Panel>
          );
        })}
      </Collapse>
    ) : (
      renderFormFieldList(formFieldList[0])
    );
  };

  const renderEditPanel = (
    formFieldList: IFlatten[][],
    beforeFormFieldList: IFlatten[][],
    isOther = false,
  ) => {
    return isEdit && !isOther ? (
      <Collapse
        ghost
        className={c.list_wrap}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined
            style={{ fontSize: '12px' }}
            rotate={isActive ? 90 : 0}
          />
        )}
      >
        <Panel header="编辑前" key="1">
          <ul className={c.list}>
            {renderFormPanel(beforeFormFieldList, isOther)}
          </ul>
        </Panel>
        <Panel header="编辑后" key="2">
          <ul className={c.list}>{renderFormPanel(formFieldList, isOther)}</ul>
        </Panel>
      </Collapse>
    ) : (
      renderFormPanel(formFieldList, isOther)
    );
  };

  const renderPanel = () => (
    <>
      {currentFormFieldList.length > 0 && (
        <Panel header="当前表单字段" key="1">
          {renderEditPanel(currentFormFieldList, beforeCurrentFormFieldList)}
        </Panel>
      )}
      {otherFormFieldList.length > 0 && (
        <Panel header="其他表单字段" key="2">
          {renderEditPanel(otherFormFieldList, [], true)}
        </Panel>
      )}
    </>
  );

  const handleMouse = (usage: string) => {
    setUsage(usage);
  };

  return (
    <div className={classNames(c.variable_container, c.item_container)}>
      <div className={c.title}>可用变量</div>
      <div className={c.field_list_wrap}>
        <Card
          size="small"
          title={<CardTitle title="搜索变量" onChange={handleChange} />}
        >
          {!isSearch && (
            <Collapse
              ghost
              className={c.list_wrap}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined
                  style={{ fontSize: '12px' }}
                  rotate={isActive ? 90 : 0}
                />
              )}
            >
              {renderPanel()}
            </Collapse>
          )}
          {isSearch && (
            <ul className={c.list}>
              {result?.map((child) => {
                const html = child.value.replace(
                  /\((.*?)\)/g,
                  `<span style="color:#007fff" className="high-light">$1</span>`,
                );
                return (
                  <li
                    key={getUuid()}
                    className={classNames(
                      c.list_item,
                      child.schema.id === usage && c.select_item,
                    )}
                    onMouseOver={() => handleMouse(child.schema.id)}
                    onMouseLeave={() => handleMouse(child.schema.id)}
                  >
                    <div
                      onClick={() => handleClick(child)}
                      className={c.content}
                      dangerouslySetInnerHTML={{ __html: html }}
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </Card>
      </div>
    </div>
  );
};

export default Variable;
