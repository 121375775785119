/*
 * @Author: “wu.shangru” “roywu@tungee.com”
 * @Date: 2023-04-23 19:00:08
 * @LastEditors: wu.shangru
 * @LastEditTime: 2024-02-27 17:27:09
 * @FilePath: /low-code-platform/src/pages/generator/relation/components/FormulaEditorModal/common/constant.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * :file description:
 * :name: \low-code-platform\src\widgets\DefaultValueSelector\components\formula-editor\common\constant.ts
 * :author: 杨海涛
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-08-10 09:57:35
 * :last editor: 杨海涛
 * :date last edited: 2022-08-10 20:57:11
 */
export const NAME_FILED_CLS = 'cm-field-name';
export const VALUE_FIELD_CLS = 'cm-field-value';
export const INVALID_FIELD_CLS = 'cm-field-invalid';
export const DEPRECATE_FIELD_CLS = 'cm-deprecate';
export const STATIC = {
  _st: new Date().getTime(),
  _ct: new Date().getTime(),
  zIndex: 8e3,
  Language: 'zh',
  num: 0,
  IDBase: new Date() - 0,
  CSRF: $('meta[name="csrf-token"]').attr('content'),
  site: window.location.protocol + '//' + window.location.hostname,
};
// 支持选择的字段 流水号、单行文本、多行文本、数字、单选、金额、表格、级联控件
// 进销存1.3 扩充2个组件 图片 关联表单本身
export const FORMULA_WIDGET_LIST = [
  'seqNumber',
  'input',
  'textarea',
  'number',
  'select',
  'money',
  'date',
  'cascade',
  'cascadeV2',
  'email',
  'picture',
  'relation',
  'department',
  'principal',
  'cooperator',
  'innerContact',
  'multiSelect',
  'multiCascade',
  'customerTags',
];
