/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\RelationSelector\components\SelectorModal\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-21 10:39:09
 * :last editor: hyw
 * :date last edited: 2022-04-21 19:29:31
 */
import React, { useEffect, useState, useCallback, useContext, FC } from 'react';
import { Modal, Input, Table, Button, message, Tabs, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { ColumnType } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import { debounce } from 'lodash-es';
import formApi from '../../../../services/api';
import { isJSON, customJP } from '../../../../utils';
import c from './index.less';
import {
  genTableColumns,
  genTableData,
} from '~@/packages/table-render/src/utils';
import IconFont from '@/components/IconFont';
import { customerTitleMap } from '../../../DataFilters';
import ColumnDisplayControl from '~@/packages/table-render/src/components/ColumnDisplayControl';
import { WidgetProps } from 'typings';

const { TabPane } = Tabs;
const DEFAULT_BIZ_TYPE = 'crm_customer';

const SelectorModal: FC<WidgetProps> = (props) => {
  const {
    title = '',
    schema,
    addons,
    children,
    visible,
    setVisible,
    setSelectedRows,
    handleConfirm,
    type,
    value,
  } = props;
  const { dependValues } = addons;
  const { getValues } = addons;
  const [keyword, setKeyword] = useState<string | undefined>();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // 关联表单数据
  const [relationSchema, setRelationSchema] = useState();
  const [columns, setColumns] = useState<ColumnType<object>[]>([]);
  const [dataSource, setDataSource] = useState([]);
  const [bizType, setBizType] = useState(DEFAULT_BIZ_TYPE);
  const [pageParam, setPageParam] = useState({
    pageSize: 10,
  });

  const getRelationFormSchema = async () => {
    const formCode = getFormCode();
    if (!formCode) return;
    try {
      const schema = await formApi.getFormSchema({ formCode });
      const relationSchema = isJSON(schema?.data.content)
        ? customJP(schema?.data.content)
        : undefined;
      setRelationSchema(relationSchema);
      await handleSearch();
    } catch (error) {}
  };

  const getDataSource = () => {
    const { relateSource } = schema;
    let targetSchema = _.cloneDeep(schema);
    if (relateSource) {
      targetSchema = relateSource.find(
        (item: Record<string, any>) => item.bizType === bizType,
      );
    }
    return targetSchema?.dataSource;
  };

  const getFormCode = () => {
    const dataSource = getDataSource();
    const { formCode } = dataSource?.target;
    return formCode;
  };

  const getFilters = () => {
    const dataSource = getDataSource();
    const { filters = [] } = dataSource?.params;
    return filters || [];
  };

  const getSchemaByPath = (fieldId: string) => {
    return relationSchema?.properties[fieldId] || {};
  };

  const getQueryConditionList = () => {
    const filters = getFilters();
    const result = filters.map((item) => {
      const { fieldId, filterType, value, valueType } = item;
      const isCurrentField = valueType === 'current';
      const itemSchema = getSchemaByPath(fieldId) || {};
      const { widget } = itemSchema;
      const values = getValues() || {};
      let itemValue = isCurrentField ? values[value] : value;
      let itemValues;
      if (!itemValue) {
        return;
      }

      if (filterType === 'BETWEEN' || filterType === 'IN') {
        switch (widget) {
          case 'date':
          case 'gmtCreate':
            itemValues = itemValue;
            itemValue = undefined;
            break;
          case 'innerContact':
          case 'createUserId':
            itemValues = (itemValue || []).map((item) => item.key);
            itemValue = undefined;
            break;
          case 'department':
            itemValues = (itemValue || []).map((item) => `dept_${item.key}`);
            itemValue = undefined;
            break;
        }
      } else {
        switch (widget) {
          case 'select':
            itemValue = itemValue?.key;
            break;
          case 'innerContact':
          case 'createUserId':
            itemValue = itemValue?.[0]?.key;
            break;
          case 'department':
            itemValue = itemValue?.[0]?.key;
            break;
          case 'phone':
            itemValue = isCurrentField ? itemValue?.value : value;
            break;
          case 'relation':
            itemValue = isCurrentField
              ? itemValue?.instanceId
              : value?.instanceId;
            break;
          case 'address':
            const address = (itemValue?.address || []).join('');
            const detail = itemValue?.detail || '';
            itemValue = isCurrentField ? `${address}${detail}` : value;
            break;
        }
      }

      // 特殊类型判断
      if (fieldId === 'lastProcessStatus' || fieldId === 'lastProcessResult') {
        itemValue = itemValue?.key || itemValue;
      }
      if (fieldId === 'createUserId') {
        if (filterType === 'IN') {
          itemValues = (itemValue || []).map((item) => item.key);
          itemValue = undefined;
        } else {
          itemValue = itemValue?.[0]?.key;
        }
      }

      return {
        ...item,
        value: itemValues ? undefined : itemValue,
        itemValues: itemValues || undefined,
      };
    });
    return result.filter((item) => !!item);
  };

  const handleSearch = async ({ pageIndex = 1, pageSize = 10 } = {}) => {
    // 弹窗没有打开时不请求
    // if (!visible) return;
    const queryConditionList = getQueryConditionList();
    const formCode = getFormCode();
    console.log('queryConditionList:', queryConditionList);
    const res = await formApi.queryFormDataList({
      formCode,
      keyword: keyword ? keyword : undefined,
      pageNum: pageIndex,
      pageSize,
      showTitle: true,
      queryConditionList,
      needFilter: true,
    });
    setPageParam({
      pageSize: res?.data?.size ?? 10,
      total: res?.data?.total,
    });
    const dataSource = genTableData(res.data);
    setColumns(genTableColumns(res.data));
    setDataSource(dataSource);
  };

  const handleOk = (defaultSelectedRow?: Record<string, any>[]) => {
    const formCode = getFormCode();
    handleConfirm(formCode, defaultSelectedRow);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSelect = () => {
    const { showSelectorVerify } = schema;
    if (showSelectorVerify && !showSelectorVerify.rule) {
      message.error(showSelectorVerify.message);
      return;
    }
    setVisible(true);
  };

  const setDefaultRelation = async () => {
    try {
      await getRelationFormSchema();
      const defaultSelectedRow = dataSource.splice(0, 1);
      setSelectedRows(defaultSelectedRow);
      handleOk(defaultSelectedRow);
    } catch (error) {
      setSelectedRows([]);
    } finally {
    }
  };

  const debounceSearch = useCallback(debounce(handleSearch, 200), [keyword]);

  useEffect(() => {
    if (!visible) {
      setKeyword(undefined);
    } else {
      getRelationFormSchema();
    }
  }, [visible, bizType]);

  useEffect(() => {
    visible && debounceSearch();
  }, [keyword]);

  useEffect(() => {
    setDefaultRelation();
  }, [JSON.stringify(dependValues)]);

  const pagination = {
    total: dataSource?.length,
    showTotal: () => (
      <div
        style={{
          marginRight: '10px',
        }}
      >
        共 {dataSource?.length} 项
      </div>
    ),
    showSizeChanger: true,
    pageSizeOptions: [5, 10],
    onChange: (pageIndex: number, pageSize: number) => {
      console.log(pageIndex, pageSize);
      handleSearch({ pageIndex, pageSize });
    },
  };

  const rowSelection = {
    type: !value && type === 'tableSelector' ? 'checkbox' : 'radio',
    fixed: true,
    selectedRowKeys: selectedRowKeys,
    onChange: (
      selectedRowKeys: React.SetStateAction<never[]>,
      selectedRows: React.SetStateAction<never[]>,
    ) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
  };

  return (
    <div className={c.t_association_form_selector}>
      <div onClick={handleSelect}>{children}</div>
      <Modal
        width={800}
        title={title}
        visible={visible}
        okText="确定"
        cancelText="取消"
        onOk={() => handleOk()}
        onCancel={handleCancel}
        destroyOnClose
        closeIcon={<IconFont type="icon-Close" />}
      >
        <Tabs
          defaultActiveKey="1"
          onChange={(e) => {
            setBizType(e);
          }}
        >
          {schema?.relateSource?.map((item) => {
            return (
              <TabPane
                tab={customerTitleMap.get(item.bizType)}
                key={item.bizType}
              ></TabPane>
            );
          })}
        </Tabs>

        <div className={c.tool_wrap}>
          <Space style={{ marginBottom: 12 }}>
            <Input
              value={keyword}
              prefix={<SearchOutlined />}
              placeholder="请输入"
              allowClear
              onChange={(e) => setKeyword(e.target.value)}
            />

            {/* <Button type="primary" icon={<PlusOutlined />}>
              新建
            </Button> */}
          </Space>

          <ColumnDisplayControl
            formCode={getFormCode()}
            cb={() => {
              getRelationFormSchema();
            }}
          />
        </div>

        <Table
          rowKey="instanceId"
          ellipsis
          scroll={{ x: 'max-content', y: false }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataSource}
          pagination={{
            ...pagination,
            ...pageParam,
          }}
        />
      </Modal>
    </div>
  );
};

export default SelectorModal;
