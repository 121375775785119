import React, { useState, useEffect } from 'react';
import { useLocation } from 'umi';
import Header from '../components/Header';
import {
  Button,
  Card,
  Popconfirm,
  Table,
  Typography,
  Modal,
  message,
} from 'antd';
import Style from './index.less';
import IconFont from '@/components/IconFont';
import DrawerForm from './Drawer';
import DrawerLog from './DrawerLog';
import {
  getConnectorList,
  deleteConnector,
  disabledConnector,
  enabledConnector,
} from '@/services/extension-setting/index';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { SorterResult } from 'antd/es/table/interface';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { jumpDataConectorFConfig } from '@/utils/index';

interface DataType {
  id: string;
  title: string;
  isEnabled: number;
  updateUserId: string;
  gmtModified: string;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

interface ILocation {
  formCode: string;
  type: string;
}

export default () => {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [editable, setEditable] = useState<Boolean>(false);
  const [showLog, setShowLog] = useState<Boolean>(false);
  const [currentConnectorId, setCurrentConnectorId] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const { confirm } = Modal;
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 10,
      showTotal: (total) => `总共 ${total} 项`,
      showQuickJumper: true,
    },
  });

  const location = useLocation<ILocation>();
  const { formCode, type, menu } = location?.query || {};

  const edit = (key: string) => {
    setDrawerVisible(true);
    setEditable(true);
    setCurrentConnectorId(key);
  };

  const fechData = () => {
    setLoading(true);
    const info = {
      order: [],
      pageNum: tableParams.pagination?.current || 1,
      pageSize: tableParams.pagination?.pageSize || 10,
      sort: [],
      formCode,
    };
    getConnectorList(info)
      .then((res: any) => {
        setDataSource(res.data.records);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.total,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
      });
  };

  const handleDelete = (key: string) => {
    const newData = dataSource.filter((item) => item.id !== key);

    deleteConnector(key).then(() => {
      fechData();
    });
    setDataSource(newData);
  };

  const showConfirm = (key: string, isEnabled: number) => {
    if (isEnabled) {
      message.warning('已启用的数据推送不允许删除');
    } else {
      confirm({
        centered: true,
        title: '确定删除连接器',
        icon: <ExclamationCircleOutlined />,
        content: ' 删除后，运行日志相关数据将无法找回，确定删除？',
        cancelText: '取消',
        okText: '确定',
        bodyStyle: {
          padding: '32px 24px 24px',
        },
        onOk() {
          handleDelete(key);
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  };

  const handleOnShowLog = (key: string) => {
    setShowLog(true);
    setCurrentConnectorId(key);
  };

  const handleIsEnabledChange = (record: DataType) => {
    if (record.isEnabled) {
      disabledConnector({
        id: record.id!,
        needThrowExWhenNotFount: true,
      })
        .then((res) => {
          message.success('禁用成功!');
          fechData();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      enabledConnector({
        id: record.id!,
        needThrowExWhenNotFount: true,
      })
        .then((res) => {
          message.success('启用成功!');
          fechData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: '规则名称',
      dataIndex: 'title',
      key: 'title',
      width: 200,
      fixed: 'left',
    },
    {
      title: '状态',
      dataIndex: 'isEnabled',
      key: 'isEnabled',
      // 已启用 未启用
      render: (text, record, index) => {
        return text ? (
          <div>
            <span
              style={{ background: '#00B042' }}
              className={Style.round}
            ></span>
            已启用
          </div>
        ) : (
          <span>
            <span
              style={{ background: 'rgba(23,26,29,0.24)' }}
              className={Style.round}
            ></span>
            未启用
          </span>
        );
      },
    },
    {
      title: '修改人',
      dataIndex: 'updateUserName',
      key: 'updateUserName',
    },
    {
      title: '最后更新时间',
      dataIndex: 'gmtModified',
      key: 'gmtModified',
    },
    {
      title: '创建时间',
      dataIndex: 'gmtCreate',
      key: 'gmtCreate',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: any) => {
        return (
          <span>
            <Typography.Link
              onClick={() => edit(record.id)}
              style={{ marginRight: 8 }}
            >
              编辑
            </Typography.Link>
            <Typography.Link
              style={{ marginRight: 16 }}
              onClick={() => {
                handleIsEnabledChange(record);
              }}
            >
              {record.isEnabled ? '禁用' : '启用'}
            </Typography.Link>
            <Typography.Link
              onClick={() => handleOnShowLog(record.id)}
              style={{ marginRight: 8 }}
            >
              运行日志
            </Typography.Link>
            <Typography.Link
              onClick={() => showConfirm(record.id, record.isEnabled)}
              style={{ marginRight: 8 }}
            >
              删除
            </Typography.Link>
          </span>
        );
      },
    },
  ];

  const handleTableChange = (
    pagination: TablePaginationConfig,
    sorter: SorterResult<DataType>,
  ) => {
    setTableParams({
      pagination: { ...tableParams.pagination, ...pagination },
      // ...sorter,
    });
  };

  useEffect(() => {
    fechData();
  }, [JSON.stringify(tableParams), drawerVisible]);

  return (
    <>
      <Header>
        <div>
          <Button
            type="link"
            onClick={() => {
              jumpDataConectorFConfig({});
            }}
          >
            管理推送接口
          </Button>
          <Button
            type="primary"
            icon={<IconFont type="icon-tianjia1" />}
            onClick={() => {
              setDrawerVisible(true);
              setEditable(false);
            }}
          >
            新建数据推送
          </Button>
        </div>
      </Header>
      <div className={Style.content}>
        <Table
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
          pagination={tableParams.pagination}
          onChange={handleTableChange}
          loading={loading}
          scroll={{ x: 1300 }}
        />
      </div>
      <DrawerForm
        editable={editable}
        width={800}
        visible={drawerVisible}
        setDrawerVisible={setDrawerVisible}
        currentConnectorId={currentConnectorId}
      />
      <DrawerLog
        width={700}
        showLog={showLog}
        setShowLog={setShowLog}
        currentConnectorId={currentConnectorId}
      />
    </>
  );
};
