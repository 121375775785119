/*
 * :file description:
 * :name: \low-code-platform\src\widgets\DataTitle\common\fomula-hint.js
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-04 09:16:18
 * :last editor: 黄艺
 * :date last edited: 2021-12-14 11:18:38
 */
import CodeMirror from 'codemirror';

CodeMirror.defineMode('label', function () {
  function i(t, e) {
    if (t.eatSpace()) return null;
    var i = t.next();
    return null;
  }
  function t(t, e) {
    var i = false,
      a;
    while ((a = t.next()) != null) {
      if (a === e && !i) return false;
      i = !i && a === '\\';
    }
    return i;
  }
  function a(t, e) {
    return (e.tokens[0] || i)(t, e);
  }
  return {
    startState: function () {
      return {
        tokens: [],
      };
    },
    token: function (t, e) {
      return a(t, e);
    },
    fold: 'brace',
  };
}),
  CodeMirror.defineMIME('text/fx-label', 'label');
