/*
 * :file description:
 * :name: \low-code-platform\src\pages\generator\relation\business\Index\index.tsx
 * :author: 杨海涛
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-08-08 11:33:56
 * :last editor: 杨海涛
 * :date last edited: 2022-08-08 11:33:14
 */
import React, { useEffect, useRef, useState } from 'react';
import EditArea from './EditArea';
import Variable from './Variable';
import Formula from './Formula';
import { useDebounceFn } from 'ahooks';
import { Editor } from 'codemirror';
import { getUrlParam } from '@/utils';
import { utils } from './common/utils';
import IconFont from '@/components/IconFont';
import { Col, message, Modal, Row, Checkbox, Button } from 'antd';
import { validateFormRule } from '@/services/extension-setting';
import { getValue, setDefaultValue } from './common/formulaEditor';
import classnames from 'classnames';

import c from './index.less';
import { values } from 'lodash';

interface IProps {
  visible: boolean;
  fillValue?: IFormulaValue;
  schemaList: any[];
  onClose: () => unknown;
  onChange?: (value: IFormulaValue) => unknown;
  isCreate?: boolean;
  isDelete?: boolean;
  isEdit?: boolean;
  isAdvancedFunctions?: boolean;
  originRule?: IFormulaValue;
  form?: string;
}

export const EditorContext = React.createContext('EditorContext');

const FormulaEditorModal: React.FC<IProps> = (props) => {
  const {
    visible,
    schemaList,
    onClose,
    isCreate = false,
    isEdit = false,
    isDelete = false,
    isAdvancedFunctions = false,
    fillValue,
    originRule,
    onChange,
    form,
  } = props;

  // 公式文案提示
  const [showFormularCotent, setShowFormularCotent] = useState({});

  const [editorInstance, setEditorInstance] = useState<Editor | undefined>();
  const [showRestore, setShowRestore] = useState(false);
  const [currentFlatten, setCurrentFlatten] = useState<
    { [key: string]: any }[]
  >([]);
  const [beforeCurrent, setBeforeCurrent] = useState<{ [key: string]: any }[]>(
    [],
  );
  const [otherFlatten, setOtherFlatten] = useState<{ [key: string]: any }[]>(
    [],
  );
  const [updateContent, setUpdateContent] = useState('');
  const [checkRuleLoading, setCheckRuleLoading] = useState(false);
  const [errorField, setErrorField] = useState(false);
  const [ruleTrigger, setRuleTrigger] = useState(false);
  const contentRef = useRef('');
  const formCode = getUrlParam('formCode');

  const onReset = () => {
    setUpdateContent('');
    contentRef.current = '';
    setShowRestore(false);
    onClose();
  };

  const handleOk = async () => {
    const value: IFormulaValue = getValue(editorInstance);
    if (isEdit || isCreate || isDelete) {
      value.ruleTrigger = ruleTrigger;
    }
    if (isAdvancedFunctions) {
      // if (
      //   ['INSERT(', 'UPSERT(', 'UPDATE(', 'DELETE('].filter((item) =>
      //     value.rule.includes(item),
      //   ).length <= 0
      // ) {
      //   message.warning('高级函数规则未设置正确，请重新编辑');
      //   return;
      // }
      setCheckRuleLoading(true);
      try {
        if (!!value.rule) {
          const { data } = await validateFormRule(
            { formRule: value.rule },
            formCode,
          );
          if (!data.result) {
            message.warning(data.message);
            return;
          }
        }
        onChange?.(value);
        onReset();
      } catch (e) {
        console.error(e);
      } finally {
        setCheckRuleLoading(false);
      }
    } else {
      onChange?.(value);
      onReset();
    }
  };

  const getFlatten = (flatten: { [key: string]: any }) => {
    const newFlatten = {} as { [key: string]: any };
    utils?.forEach(flatten, (key: string, value: any) => {
      const newKey = key === '#' ? key : `${key}_old`;
      newFlatten[newKey] = {
        ...value,
        children: value.children.map((item) => `${item}_old`),
        schema: { ...value.schema, $id: `${value.schema.$id}_old` },
      };
    });
    return newFlatten;
  };
  // 给schema加上停用字段
  const addStatusInSchema = (arr: any) => {
    arr?.forEach((item: any) => {
      const keyArr = Object.keys(item);
      keyArr?.forEach((itemI: any) => {
        if (
          item[itemI]?.schema?.hidden &&
          item[itemI]?.$title?.indexOf('(已停用)') === -1
        ) {
          item[itemI].$title += '(已停用)';
        }
      });
    });
    return arr;
  };

  const usageHtml = (name = '', content = '') => {
    // /[\(|\,]([^(\(|\)|\,)]+)([\)|\,])*/g
    // /[\(|\,]([^(\(|\)|\,)]+)/g
    return content
      .replace(name, `<span class="formula-name"> ${name} </span>`)
      .replace(/\{(.*?)\}/g, ` <span class="formula-field">$1</span> `);
  };
  const usageHtml2 = (name = '', content = '') => {
    // /[\(|\,]([^(\(|\)|\,)]+)([\)|\,])*/g
    // /[\(|\,]([^(\(|\)|\,)]+)/g
    return content
      .replace(name, `<span class="formula-name"> ${name} </span>`)
      .replace(/\{(.*?)\}/g, ` <span class="formula-field">$1</span> `)
      .replace(/[\(|\,]([^(\(|\)|\,)]+)/g, (s) => {});
  };

  useEffect(() => {
    setRuleTrigger(fillValue?.ruleTrigger ?? false);
  }, [fillValue?.ruleTrigger]);

  useEffect(() => {
    if (isEdit) {
      const beforeSchemaList = schemaList.map((item) => getFlatten(item));
      setBeforeCurrent(
        beforeSchemaList.filter((item) => item['#']?.formCode === formCode),
      );
    }
    const newData = addStatusInSchema(
      schemaList.filter((item) => item['#']?.formCode === formCode),
    );
    setCurrentFlatten(newData);
    setOtherFlatten(
      isAdvancedFunctions
        ? schemaList.filter((item) => item['#']?.formCode !== formCode)
        : [],
    );
  }, [isEdit, schemaList]);

  useEffect(() => {
    // 判断是否显示恢复按钮
    if (
      !showRestore &&
      contentRef.current !== updateContent &&
      fillValue &&
      fillValue.displayRule !==
        (editorInstance ? getValue(editorInstance).displayRule : '')
    ) {
      if (errorField) return;
      setShowRestore(true);
    }
    contentRef.current = updateContent;
  }, [errorField, fillValue, updateContent]);

  useEffect(() => {
    if (errorField) {
      message.warning('当前公式存在无效字段');
      setShowRestore(false);
    }
  }, [errorField]);

  const onRuleTriggerTypeChange = (e: any) => {
    setRuleTrigger(e.target.checked);
  };

  const restoreFormula = () => {
    editorInstance &&
      setDefaultValue(
        fillValue,
        {
          currentFlatten,
          beforeCurrent,
          otherFlatten,
        },
        editorInstance,
        isEdit,
      );
  };

  const restoreOriginFormula = () => {
    if (!!originRule && !!editorInstance) {
      setDefaultValue(
        {
          ...fillValue,
          ...originRule,
        },
        {
          currentFlatten,
          beforeCurrent,
          otherFlatten,
        },
        editorInstance,
        isEdit,
      );
    }
  };

  return (
    <EditorContext.Provider
      value={{
        flatten: {
          currentFlatten,
          beforeCurrent,
          otherFlatten,
        },
        editorInstance,
        setEditorInstance,
        updateContent,
        setUpdateContent,
        isEdit,
        isAdvancedFunctions,
        fillValue,
        setErrorField,
      }}
    >
      <Modal
        className={c.modal_wrap}
        visible={visible}
        width={window.innerWidth}
        bodyStyle={{
          height: window.innerHeight,
          padding: '0',
          overflowX: 'hidden',
        }}
        closable={true}
        title={
          <div className={c.title_wrapper}>
            <div className={c.modal_title}>
              <span className={c.title}>公式编辑</span>
              <span className={c.desc}>使用数学运算符编辑公式</span>
            </div>
            <IconFont type="guanbi" onClick={onReset} />
          </div>
        }
        footer={
          <div className={c.customFooter}>
            {(isEdit || isCreate) && form === 'relation' && (
              <div className={c.triggerCheckbox}>
                <Checkbox
                  checked={ruleTrigger}
                  onChange={onRuleTriggerTypeChange}
                >
                  当表单需审批时，审批通过后才能触发
                </Checkbox>
              </div>
            )}
            {/* 进销存定制 */}
            {isDelete && (
              <div className={c.triggerCheckbox}>
                <Checkbox
                  checked={ruleTrigger}
                  onChange={onRuleTriggerTypeChange}
                >
                  审批通过的表单触发规则
                </Checkbox>
              </div>
            )}
            <div className={c.operaBtns}>
              <Button onClick={onReset}>取消</Button>
              <Button type="primary" onClick={handleOk}>
                确定
              </Button>
            </div>
          </div>
        }
        maskClosable={false}
        confirmLoading={checkRuleLoading}
        onCancel={onReset}
        destroyOnClose
        closeIcon={<IconFont type="icon-Close" />}
      >
        <div className={c.containBody}>
          <div className={c.leftDiv}>
            <div className={c.edit_wrap}>
              <div className={c.title2}>编辑器</div>
              <div className={c.title}>
                {!!originRule && (
                  <a onClick={restoreOriginFormula}>恢复系统预置公式</a>
                )}
                {showRestore && <a onClick={restoreFormula}>恢复上一次公式</a>}
              </div>
              <EditArea />
            </div>
            <div className={c.info_wrap}>
              {showFormularCotent?.name && (
                <>
                  <div className={c.info_wrap_title}>
                    {showFormularCotent?.name}
                    <span>{showFormularCotent?.intro}</span>
                  </div>
                  {showFormularCotent?.usage && (
                    <div
                      className={c.info_wrap_text}
                      dangerouslySetInnerHTML={{
                        __html: usageHtml(
                          showFormularCotent?.name,
                          showFormularCotent?.usage,
                        ),
                      }}
                    ></div>
                  )}
                  <div
                    className={classnames(c.info_wrap_text, c.info_wrap_text2)}
                    dangerouslySetInnerHTML={{
                      __html: usageHtml(
                        showFormularCotent?.name,
                        showFormularCotent?.example,
                      ),
                    }}
                  ></div>
                </>
              )}
            </div>
          </div>
          <div className={c.midDiv}>
            <Variable />
          </div>
          <div className={c.rightDiv}>
            <Formula setShowFormularCotent={setShowFormularCotent} />
          </div>
        </div>
        {/* <Row gutter={10}>
          <Col span={24} className={c.edit_box}>
            <div className={c.edit_wrap}>
              <div className={c.title}>
                {!!originRule && (
                  <a onClick={restoreOriginFormula}>恢复系统预置公式</a>
                )}
                {showRestore && <a onClick={restoreFormula}>恢复上一次公式</a>}
              </div>
              <EditArea />
            </div>
          </Col>
        </Row> */}
        {/* <Row gutter={10}>
          <span className={c.edit_desc}>
            支持英文模式下运算符（+、-、*、/、&gt;、&lt;、==、!=、&lt;=、&gt;=）
          </span>
        </Row> */}
        {/* <Row gutter={10}>
          <span className={c.edit_desc}>
            参考场景：根据输入的数量和单价，自动计算出金额，则可将计算公式设置为：数量*单价
          </span>
        </Row> */}
        {/* <Row gutter={10} className={c.footer}>
          <Col flex="252px">
            <Variable />
          </Col>
          <Col flex="1">
            <Formula />
          </Col>
        </Row> */}
      </Modal>
    </EditorContext.Provider>
  );
};

export default FormulaEditorModal;
