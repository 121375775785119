/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/relation/duplicated/components/CombinedRule/index.tsx
 * :author: xiaoguotong
 * :copyright: (c) 2024, Tungee
 * :date created: 2024-01-03 17:37:56
 * :last editor: KyleLiang
 * :date last edited: 2024-08-05 15:39:03
 */
import React, { FC, useState, useEffect, useMemo } from 'react';
import {
  Button,
  Table,
  Select,
  Switch,
  Popconfirm,
  message,
  Space,
} from 'antd';
import { useLocation } from 'umi';
import CombinedRuleModal from '../CombinedRuleModal';
import styles from './index.less';
import {
  getFormUnionDuplicationRuleList,
  updateFormUnionDuplicationRuleStatus,
  delFormUnionDuplicationRule,
} from '@/services/duplicate';

const CombinedRule: FC = ({
  formName,
  fieldsList,
  isPersonalCustomerCombined,
}: any) => {
  const location = useLocation();
  const formCode = location?.query?.formCode;

  const [modelVisible, setModelVisble] = useState(false);
  const [rules, setRules] = useState([]);

  const [editForm, setEditForm] = useState();

  const [savingId, setSavingId] = useState();
  const [switchLoading, setSwitchLoading] = useState(false);

  //删除规则
  const handleDelete = (id: string) => {
    delFormUnionDuplicationRule(id, formCode).then((res) => {
      message.success('删除成功');
      getData();
    });
  };

  //增加规则
  const handleAdd = () => {
    setModelVisble(true);
    setEditForm(undefined);
  };
  //获取规则列表
  const getData = () => {
    getFormUnionDuplicationRuleList(formCode).then((res) => {
      const data = res.data ?? [];
      setRules(data);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  //是否启用状态修改
  const handleStatusChange = (checked: boolean, id: string) => {
    setSwitchLoading(true);
    setSavingId(id);
    updateFormUnionDuplicationRuleStatus(id, formCode, +checked)
      .then((res) => {
        message.success('修改成功');
        getData();
      })
      .finally(() => {
        setTimeout(() => {
          setSwitchLoading(false);
          setSavingId(undefined);
        }, 400);
      });
  };

  const handleEdit = (r: any) => {
    setEditForm(r);
    setModelVisble(true);
  };

  const Columns = [
    {
      title: '联合查重表单',
      dataIndex: 'targetFormTitle',
      key: 'targetFormTitle',
      width: 200,
    },
    {
      title: '查重规则预览',
      dataIndex: 'duplicationRule',
      key: 'duplicationRule',
      render: (v: any, record: any, index: number) => {
        const { duplicationRule, targetFormTitle } = record;

        const txtList = duplicationRule?.manyQueryConditionList
          ?.map((c) => c[0])
          ?.filter((c) => {
            return c.fieldId && c.status == 1;
          })
          ?.map((s) => {
            return `【${formName}】的“${s.title}”和【${targetFormTitle}】的“${s.targetFieldTitle}”重复`;
          });

        return txtList?.length > 0
          ? `若${txtList.join(' 或')}，则判定为重复`
          : '--';
      },
    },
    {
      title: '是否启用',
      dataIndex: 'enable',
      key: 'enable',
      width: 120,
      render: (text: number, record: any, index: number) => {
        const { status, id } = record; //是否启用：0-关闭，1-启用
        return (
          <Switch
            loading={switchLoading && id === savingId}
            checked={!!status}
            onChange={(c) => handleStatusChange(c, id)}
          />
        );
      },
    },
    {
      title: '操作',
      width: 130,
      render: (text: any, record: any) => {
        console.log('record', record);
        const { id } = record;
        return (
          <Space size={32}>
            <Button
              onClick={() => {
                handleEdit(record);
              }}
              style={{ padding: 0 }}
              type="link"
            >
              编辑
            </Button>
            <Popconfirm
              title="你确定要删除吗？"
              onConfirm={() => {
                handleDelete(id!);
              }}
              overlayClassName={styles['popconfirm-overlay']}
            >
              <a>删除</a>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  return (
    <>
      <div className={styles['combined-rule']}>
        <div className={styles['combined-rule-info']}>
          {isPersonalCustomerCombined
            ? '新建/编辑时（包括手动录入、批量导入），同时在已启用联合查重的表单中校验，若重复则不允许保存'
            : '新建/编辑时，同时在被设置为联合查重表单中进行查重，若重复则不允许保存；新建场景包括手动录入、批量导入、其他应用（广告线索、智能获客、探迹拓客等）、API接口传入。'}
        </div>
        <div className={styles['combined-rule-table']}>
          <div className={styles['combined-rule-table-header']}>
            <Button type="primary" onClick={handleAdd}>
              新建联合查重规则
            </Button>
          </div>
          <Table rowKey={'id'} columns={Columns} dataSource={rules} />
        </div>
      </div>
      <CombinedRuleModal
        onCancel={() => {
          setModelVisble(false);
          setEditForm(undefined);
        }}
        onOk={() => {
          setModelVisble(false);
          setEditForm(undefined);
          getData();
        }}
        formCode={formCode}
        formName={formName}
        visible={modelVisible}
        fieldsList={fieldsList}
        editForm={editForm}
        isPersonalCustomerCombined={isPersonalCustomerCombined}
      />
    </>
  );
};

export default CombinedRule;
