/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/relation/duplicated/index.tsx
 * :author: KyleLiang
 * :copyright: (c) 2024, Tungee
 * :date created: 2024-05-06 13:50:00
 * :last editor: KyleLiang
 * :date last edited: 2024-08-05 18:44:18
 */
/*
 * @Description:
 * @FilePath: /low-code-platform/src/pages/generator/relation/duplicated/index.tsx
 * :author: wu.shangru
 * :copyright: (c) 2023, Tungee
 * @Date: 2023-07-30 18:37:15
 * @LastEditors: wu.shangru
 * @LastEditTime: 2024-03-19 10:37:44
 */
import React, { FC, useState, useEffect, useMemo } from 'react';
import './style.less';
import NewDplTable from './components/DplTable';
import Header from '../components/Header';
import { getUrlParam } from '@/utils';
import { history, useModel } from 'umi';
import {
  SUPPORT_DUPLICAT_WIDGETS,
  HIDDEN_IN_DUPLICATED_WIDGETID,
  PROCESS_FORM_FIELDS,
  TABLE_SUPPORT_DUPLICAT_WIDGETS,
  EXTRA_SUPPORT_DUPLICAT_WIDGETS,
} from './constants';
import { getFormSchemaForDesignStatus } from '@/pages/generator/form/services/form-generator';
import _, { cloneDeep } from 'lodash';
import { useGray } from '../hooks';
import { formType } from '@/layouts/components/GeneratorHeader';

export interface IDuplicateFieldItem {
  id: string;
  title: string;
  bizAlias: string;
}

interface IFormSchema {
  current: {
    properties: {
      [key: string]: IDuplicateFieldItem;
    };
  };
  formType: formType;
}

const DuplicateDetection: FC = () => {
  const formCode = getUrlParam('formCode');
  const [formSchema, setFormSchema] = useState<IFormSchema>({});
  const [formName, setFormName] = useState('');
  const [processForm, setProcessForm] = useState(1);
  const model = useModel('useGenerator', (model) => model);
  const { schemaRef, profile } = model;
  const { bizType: _bizType } = location?.query || {};
  const bizType = _bizType || profile?.bizType;
  const addressWidgetGray = useGray('duplicate_check_address_range');
  const duplicateCheckTableGray = useGray('duplicate_check_table');
  const customerUnionRepeatedGray = useGray('customer_union_repeated');

  useEffect(() => {
    initSchema();
  }, [history.location.pathname]);

  const personalCustomerCombined = () => {
    return (
      customerUnionRepeatedGray && ['crm_customer_personal'].includes(bizType)
    );
  };

  const fieldsList = useMemo(() => {
    let _list = Object.values(formSchema.current?.properties || {}) || [];
    _list = _list
      .filter((item) => {
        let isSupportItem = true;
        let supportList = SUPPORT_DUPLICAT_WIDGETS;
        if (duplicateCheckTableGray && item.widget === 'table') {
          supportList = [
            ...SUPPORT_DUPLICAT_WIDGETS,
            ...EXTRA_SUPPORT_DUPLICAT_WIDGETS,
          ];
          let tableItem = item?.items?.properties;
          if (tableItem) {
            Object.keys(tableItem).forEach((i) => {
              if (
                !TABLE_SUPPORT_DUPLICAT_WIDGETS.includes(tableItem[i]?.widget)
              ) {
                delete tableItem[i];
              } else {
                tableItem[i]['tableId'] = item?.id;
                tableItem[i][
                  'tableTitle'
                ] = `${item?.title}_${tableItem[i]?.title}`;
              }
            });
          }
          if (!tableItem || JSON.stringify(tableItem) === '{}') {
            isSupportItem = false;
          }
        }
        return (
          isSupportItem &&
          supportList.includes(item.widget) &&
          !item.hidden &&
          !item?.hideControls
        );
      })
      ?.filter(
        (i) =>
          !(
            i?.hideInDuplicated ||
            HIDDEN_IN_DUPLICATED_WIDGETID?.includes(i?.id)
          ),
      );
    if (formSchema.formType === formType.PROCESS_FORM) {
      _list = _list.concat(PROCESS_FORM_FIELDS);
    }
    return _list;
  }, [formSchema, duplicateCheckTableGray]);

  const initSchema = () => {
    if (!formCode) {
      return;
    }
    getFormSchemaForDesignStatus({
      formCode,
    }).then((res) => {
      const data = res?.data || {};
      const content = JSON.parse(data.content);
      setFormSchema({
        current: content,
        formType: data?.formType,
      });
      setFormName(data.title);
    });
  };
  return (
    <div className="crm-duplicate-detection">
      <Header />
      <NewDplTable
        formCode={formCode}
        formName={formName}
        fieldsList={fieldsList}
        addressWidgetGray={addressWidgetGray}
        isPersonalCustomerCombined={personalCustomerCombined()}
      />
    </div>
  );
};

export default DuplicateDetection;
