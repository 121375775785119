/*
 * :file description:
 * :name: /low-code-platform/tg-renderer/packages/form-render/src/form-render-core/src/widgets.ts
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-27 20:02:45
 * :last editor: 肖国同
 * :date last edited: 2023-02-06 14:06:14
 */
import { Alert, Select as AntdSelect } from 'antd';
import RelationSetting from '../../widgets/RelationSetting';
import Money from '../../widgets/Money';
import Picture from '../../widgets/TUpload';
import Switch from '../../widgets/Switch';
import LimitDot from '../../widgets/LimitDot';
import SelectOptions from '../../widgets/SelectOptions';
import InputNumber from '../../widgets/InputNumber';
import TableListInstance from '../../widgets/TableListInstance';
import RelationSelector from '../../widgets/RelationSelector';
import TextNote from '../../widgets/TextNote';
import Address from '../../widgets/EditAddress';
import Phone from '../../widgets/Phone';
import EditInnerContact from '../../widgets/EditInnerContact';
import EditDepartment from '../../widgets/EditDepartment';
import EditAttachment from '../../widgets/EditAttachment';
import EditSeqNumber from '../../widgets/EditSeqNumber';
import Input from '../../widgets/Input';
import TextArea from '../../widgets/TextArea';
import DatePicker from '../../widgets/DatePicker';
import DateRange from '../../widgets/DateRange';
import Select from '../../widgets/Select';
import MultiSelect from '../../widgets/MultiSelect';
import BizSuite from '../../widgets/BizSuite';
import BillingSetting from '../../widgets/BillingSetting';
import SortField from '../../widgets/SortField';
import MultipleRelationSelector from '../../widgets/MultipleRelationSelector';
import EditBizSuite from '../../widgets/EditBizSuite';

import ShowTipsText from '../../widgets/ShowTipsText';
import StarRate from '../../widgets/Rate';
import Divider from '../../widgets/Divider';
import IdCard from '../../widgets/IdCard';
import Email from '../../widgets/Email';
import Validation from '../../widgets/Validation';

export const widgets = {
  input: Input,
  textarea: TextArea,
  date: DatePicker,
  dateRange: DateRange,
  select: Select,
  baseSelect: AntdSelect,
  multiSelect: MultiSelect,
  relation: RelationSelector,
  // relation: AssociationSelector,
  tableRelation: RelationSelector,
  relationSetting: RelationSetting,
  money: Money,
  phone: Phone,
  picture: Picture,
  switch: Switch,
  limitDot: LimitDot,
  alert: Alert,
  selectOptions: SelectOptions,
  number: InputNumber,
  table: TableListInstance,
  textNote: TextNote,
  address: Address,
  innerContact: EditInnerContact,
  department: EditDepartment,
  attachment: EditAttachment,
  seqNumber: EditSeqNumber,
  contractStatus: Select,
  bizSuite: EditBizSuite,
  billingSetting: BillingSetting,
  sortFields: SortField,
  starRating: StarRate,
  ocrIdCard: ShowTipsText,
  externalContact: ShowTipsText,
  timeAndLocation: ShowTipsText,
  // starRating: ShowTipsText,
  recipientAccount: ShowTipsText,
  relate: ShowTipsText,
  columnLayout: ShowTipsText,
  signature: ShowTipsText,
  invoice: ShowTipsText,
  ocrText: ShowTipsText,
  // 身份证：idCard
  // 身份证识别：ocrIdCard
  // 外部联系人：externalContact
  // 地点/定位：timeAndLocation
  // 评分：starRating
  // 收款账户：recipientAccount
  // 关联审批单：relate
  // 分栏：columnLayout
  // 手写签名：signature
  // 电子发票：invoice
  // 通用文字识别：ocrText

  divider: Divider,
  idCard: IdCard,
  email: Email,
  validation: Validation,
};
