/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\RelationSelector\components\BaseSelector\relation_temp.js
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-08 10:30:40
 * :last editor: 黄艺
 * :date last edited: 2022-03-21 18:33:28
 */
import React, { useContext } from 'react';
import { pick, keys, isArray } from 'lodash-es';
import { customJP } from '../../../../utils';
import { getFileIcon } from '../../../EditAttachment/utils';
import {
  BIZ_CONTRACT_UNPAID_AMOUNT,
  BIZ_ORDER_UNPAID_AMOUNT,
} from '~@/packages/form-render/src/constant';

const genImgAry = (val) => {
  if (typeof val === 'string') {
    let isArrayString = false;
    let urlArr = [];
    try {
      urlArr = JSON.parse(val);
      isArrayString = true;
    } catch (error) {
      isArrayString = false;
    }
    return isArrayString ? urlArr : val.split(',');
  } else if (Array.isArray(val)) {
    return val;
  }
  return [];
};

const filterRelationInfo = (obj, fields) => {
  return pick(
    obj,
    keys(fields).filter((k) => !k.includes('table_')),
  );
};

// 对订单和合同的特殊处理
const contractOrder = (v, schema, selectedRows, addons) => {
  const bizAlias = schema?.fields?.[v].bizAlias;
  const relationFormData = selectedRows[0];
  const currentformData = addons.getValues();
  switch (bizAlias) {
    case BIZ_CONTRACT_UNPAID_AMOUNT:
      return (
        relationFormData.money_KI5RLXW0 - currentformData.number_VL990EGRB740
      );
    case BIZ_ORDER_UNPAID_AMOUNT:
      return (
        relationFormData.calculate_1BB1T5DRDOXS0 -
        currentformData.number_VL990EGRB740
      );
    default:
      return relationFormData[v];
  }
};

export const customCompDetail = (v, schema, selectedRows, addons) => {
  const widget = schema?.fields?.[v]?.widget;
  const value = selectedRows[0][v];
  if (widget === 'table') {
    return null;
  } else if (widget === 'picture') {
    return (
      <div>
        {genImgAry(value)?.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                display: 'inline-block',
                margin: '0 16px 6px 0',
                border: '1px solid #7E868E',
                borderRadius: '6px',
              }}
            >
              <img src={item} width="48" height="48" />
            </div>
          );
        })}
      </div>
    );
  } else if (widget === 'relation') {
    return customJS(value);
  } else if (widget === 'textNote') {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: schema?.properties?.[v.id]?.content,
        }}
      ></div>
    );
  } else if (widget === 'phone') {
    if (typeof value === 'object') {
      return value?.value;
    }
    return value && value?.includes('{') ? customJP(value)?.value : value;
  } else if (widget === 'address') {
    const { address = [], street = '', detail = '' } = value || {};
    return address?.join('') + street + detail;
  } else if (widget === 'attachment') {
    return (value || []).map((item, idx) => {
      const fileIcon = getFileIcon(item.fileType);
      return (
        <div
          key={idx}
          style={{
            display: 'inline-block',
            width: '28px',
            marginRight: '6px',
            borderRadius: '4px',
          }}
        >
          <img style={{ width: '100%' }} src={fileIcon} />
        </div>
      );
    });
  } else if (
    widget === 'department' ||
    widget === 'innerContact' ||
    widget === 'multiSelect'
  ) {
    return Object.values(value || {})
      ?.map((item) => item.value)
      .join(',');
  } else if (widget === 'select') {
    return value?.value;
  } else if (widget === 'money') {
    return contractOrder(v, schema, selectedRows, addons);
    // return value;
  } else if (typeof value === 'string' || typeof value === 'number') {
    return value;
  } else if (Array.isArray(value)) {
    if (['date', 'dateRange'].includes(widget)) {
      return value.join('~');
    } else {
      return value.join('，');
    }
  } else {
    return null;
  }
};

export const genRealtionDom = (payload = {}) => {
  const { schema, fields, relationDetail } = payload;
  console.log('<--payload-->', payload);
  if (
    !keys(schema).length ||
    !keys(relationDetail).length ||
    !keys(fields).length
  )
    return;
  let pickedInfo = filterRelationInfo(relationDetail, fields);
  return (
    <div
      style={{
        padding: '12px 16px',
        background: 'rgba(23, 26, 29, 0.04)',
        borderRadius: '6px',
      }}
    >
      {Object.keys(pickedInfo).map((v) => (
        <div>
          <div
            style={{
              fontFamily: 'PingFangSC-Regular',
              fontSize: '12px',
              color: 'rgba(23, 26, 29, 0.6)',
              lineHeight: '20px',
              fontWeight: 400,
            }}
          >
            {schema?.properties?.[v]?.title}
          </div>
          <div
            style={{
              margin: '4px 0 8px',
              fontFamily: 'PingFangSC-Regular',
              fontSize: '14px',
              color: '#171a1d',
              lineHeight: '22px',
              fontWeight: 400,
            }}
          >
            {customCompDetail({ id: v, value: pickedInfo[v] }, schema)}
          </div>
        </div>
      ))}
    </div>
  );
};
