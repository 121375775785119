/*
 * :file description:
 * :name: \low-code-platform\src\components\FormGenerator\utils\constant.ts
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-06 11:51:57
 * :last editor: 黄艺
 * :date last edited: 2022-04-14 20:43:11
 */

export const ALPHABET =
  '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
export const NANOID_LENGTH = 12;

// 0:探迹低代码平台,1:探迹CRM高级版,2:钉钉客户关系平台,3:钉钉原客户管理自定义表单,4:钉钉OA审批表单

export enum sourceType {
  TUNGEE_LOW_CODE_PLATFORM,
  TUNGEE_LOW_CODE_PLATFORM_PRO,
  DING_DING_CUSTOMER,
  DING_DING_CUSTOMER_CUSTOM,
  DING_DING_OA,
}

export const sourceTitleMap = new Map([
  [sourceType.TUNGEE_LOW_CODE_PLATFORM, '探迹低代码平台'],
  [sourceType.TUNGEE_LOW_CODE_PLATFORM_PRO, '探迹CRM高级版'],
  [sourceType.DING_DING_CUSTOMER, '钉钉客户关系平台'],
  [sourceType.DING_DING_CUSTOMER_CUSTOM, '钉钉原客户管理自定义表单'],
  [sourceType.DING_DING_OA, '钉钉OA审批表单'],
]);
