import { Select } from 'antd';
import { get } from 'lodash-es';
import { FC } from 'react';
import { WidgetProps } from 'typings';
import c from './index.less';

const { Option } = Select;

const SelectWidget: FC<WidgetProps<number>> = (props) => {
  const { value, onChange = () => {}, schema = {} } = props;

  return (
    <Select
      value={value}
      style={{ width: '100%' }}
      placeholder="请选择"
      onChange={(_value) => {
        onChange(_value);
      }}
      defaultValue={1}
    >
      <Option key={1} value={1}>
        <div className={c.line1}>
          <div></div>
        </div>
      </Option>
      <Option key={2} value={2}>
        <div className={c.line2}>
          <div></div>
        </div>
      </Option>
    </Select>
  );
};

export default SelectWidget;
