import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Radio, message } from 'antd';
import IconFont from '@/components/IconFont';
import FormFormula from '../components/FormFormula';
import c from './Modal.less';

interface IProps {
  visible: boolean;
  onClose: () => void;
  currentRule: ISubmitRules | undefined;
  onChange: (data: ISubmitRules) => Promise<any>;
}

const { TextArea } = Input;

export default (props: IProps) => {
  const { visible, onClose, currentRule = {}, onChange } = props;
  const [form] = Form.useForm();
  const curStatus =
    typeof currentRule === 'object' && Object.keys(currentRule).length > 0;

  const hideModal = () => {
    onClose?.();
  };

  useEffect(() => {
    form.setFieldsValue({
      name: currentRule?.name,
      list: {
        insertRule: currentRule?.onCreate,
        updateRule: currentRule?.onUpdate,
        deleteRule: currentRule?.onDelete,
      },
      message: currentRule?.message,
    });
  }, [currentRule]);

  const onFinish = async () => {
    await form.validateFields();
    const formData = form.getFieldsValue();
    const { list, name, message: tipMessage } = formData;
    const { insertRule, updateRule, deleteRule } = list;
    await onChange({
      ruleId: currentRule?.ruleId || new Date().getTime(),
      name: name?.trim(),
      onCreate:
        insertRule && insertRule.displayRule !== '' && insertRule.rule !== ''
          ? {
              displayRule: insertRule?.displayRule,
              rule: insertRule?.rule,
            }
          : null,
      onUpdate:
        updateRule && updateRule.displayRule !== '' && updateRule.rule !== ''
          ? {
              displayRule: updateRule?.displayRule,
              rule: updateRule?.rule,
            }
          : null,
      onDelete:
        deleteRule && deleteRule.displayRule !== '' && deleteRule.rule !== ''
          ? {
              displayRule: deleteRule?.displayRule,
              rule: deleteRule?.rule,
            }
          : null,
      message: tipMessage?.trim(),
    });
    message.success('保存成功！');
    hideModal();
  };

  const validator = [
    {
      validator: (rule: any, value: string) => {
        if (!value?.trim()) {
          return Promise.reject('请输入内容');
        }
        return Promise.resolve(value);
      },
    },
  ];

  return (
    <Modal
      title={curStatus ? '编辑规则' : '添加规则'}
      visible={visible}
      onOk={onFinish}
      onCancel={hideModal}
      okText="确认"
      width={480}
      cancelText="取消"
      destroyOnClose
      closeIcon={<IconFont type="icon-Close" />}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label="规则名称" required name="name" rules={validator}>
          <Input placeholder="请输入" maxLength={20} showCount />
        </Form.Item>
        <Form.List
          name="list"
          rules={[
            {
              validator: (rule, names, callback) => {
                console.log(names, 'zzz');
                if (
                  !names?.insertRule?.displayRule &&
                  !names?.updateRule?.displayRule &&
                  !names?.deleteRule?.displayRule
                ) {
                  return Promise.reject(new Error('请至少设置一条关联规则'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {(fields, {}, { errors }) => (
            <>
              <div className={c.form_title}>满足条件可执行</div>
              <Form.Item label="新建表单数据" name="insertRule">
                <FormFormula isCreate={true} form="submit" />
              </Form.Item>
              <Form.Item label="编辑表单数据" name="updateRule">
                <FormFormula isEdit={true} form="submit" />
              </Form.Item>
              <Form.Item label="删除表单数据" name="deleteRule">
                <FormFormula />
              </Form.Item>
              <Form.ErrorList errors={errors} />
            </>
          )}
        </Form.List>
        <Form.Item
          label="不满足该校验条件时的提示文案"
          name="message"
          rules={validator}
          required
        >
          <TextArea
            placeholder="请输入"
            style={{ height: 70 }}
            showCount
            maxLength={30}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
