/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/relation/duplicated/components/FieldSettingModal/RenderDupText.tsx
 * :author: KyleLiang
 * :copyright: (c) 2024, Tungee
 * :date created: 2024-05-06 13:50:00
 * :last editor: KyleLiang
 * :date last edited: 2024-08-03 14:59:05
 */
import React from 'react';

const RenderDupText = (props) => {
  const { conditionList = [], formName } = props;
  const text = conditionList
    .map((group) => {
      return `“${group
        ?.map((x) =>
          x?.tableFieldId ? `${x?.tableFieldTitle}_${x?.title}` : x.title,
        )
        .join('、')}”重复`;
    })
    .join('，或');

  if (conditionList.length === 0) {
    return null;
  }

  return (
    <div className="crm-duplicate-fields-group-preview">
      <h4 style={{ fontWeight: 'bold' }}>查重规则预览</h4>
      <div>{`若【${formName}】的${text}，则判定为重复`}</div>
    </div>
  );
};

export default RenderDupText;
