import React, { useState } from 'react';
import { Collapse, List } from 'antd';
import './Wrapper.less';
import IconFont from '@/components/IconFont';
import {
  jumpLowCodeDesign,
  jumpLowCodeExtensionDesign,
} from '../../../../../utils/index';

const { Panel } = Collapse;

const CollapseCom = (props) => {
  const {
    associationRuleRelations,
    dataAssociations,
    dataFillingRelations,
    versionValue,
  } = props;
  const [activeKey, setActiveKey] = useState(['1', '2', '3']);

  return (
    <Collapse
      activeKey={activeKey}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (
        <IconFont
          type="icon-down-line"
          style={{
            fontSize: '16px',
            color: 'rgba(23,26,29,0.60)',
            transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
            position: 'absolute',
            top: '15px',
          }}
        />
      )}
      bordered={false}
      onChange={(key) => setActiveKey(key)}
      className="collapseWrap"
    >
      {dataFillingRelations.length ? (
        <Panel
          header={
            <div className="panelTile">
              数据填充<span>（被以下表单用于数据填充）</span>
            </div>
          }
          key="1"
        >
          <List
            size="small"
            header={null}
            footer={null}
            bordered={false}
            dataSource={dataFillingRelations}
            renderItem={(item) => (
              <List.Item>
                <div className="listItemWrap">
                  <div className="listItemLeft">
                    <div className="listItemImg">
                      <IconFont type={item?.icon} className="formIcon" />
                    </div>
                    <div className="listItemName">{item?.formName}</div>
                    <div className="listItemStatus">
                      {/* 表单状态(PUBLISHED(启用) INVALID(停用) SAVED(草稿)) */}
                      {/* {item?.status === 'PUBLISHED'
                        ? '启用'
                        : item?.status === 'INVALID'
                        ? '停用'
                        : '草稿'} */}
                      {item?.status === 'INVALID' ? '已停用' : null}
                    </div>
                  </div>
                  <div className="listItemCenter">{item?.fieldTitle}</div>
                  {[-1, 0, 1].includes(Number(versionValue)) ? null : (
                    <a
                      onClick={() => {
                        jumpLowCodeDesign({
                          formCode: item.formCode,
                          versionValue,
                        });
                      }}
                    >
                      设置
                    </a>
                  )}
                </div>
              </List.Item>
            )}
          />
        </Panel>
      ) : null}
      {dataAssociations.length ? (
        <Panel
          header={
            <div className="panelTile">
              关联其他表单数据<span>（被以下表单用于数据关联）</span>
            </div>
          }
          key="2"
        >
          <List
            size="small"
            header={null}
            footer={null}
            bordered={false}
            dataSource={dataAssociations}
            renderItem={(item) => (
              <List.Item>
                <div className="listItemWrap">
                  <div className="listItemLeft">
                    <div className="listItemImg">
                      <IconFont type={item?.icon} className="formIcon" />
                    </div>
                    <div className="listItemName">{item?.formName}</div>
                    <div className="listItemStatus">
                      {/* 表单状态(PUBLISHED(启用) INVALID(停用) SAVED(草稿)) */}
                      {/* {item?.status === 'PUBLISHED'
                        ? '启用'
                        : item?.status === 'INVALID'
                        ? '停用'
                        : '草稿'} */}
                      {item?.status === 'INVALID' ? '已停用' : null}
                    </div>
                  </div>
                  <div className="listItemCenter">{item?.fieldTitle}</div>
                  <a
                    onClick={() => {
                      jumpLowCodeDesign({
                        formCode: item.formCode,
                        versionValue,
                      });
                    }}
                  >
                    设置
                  </a>
                </div>
              </List.Item>
            )}
          />
        </Panel>
      ) : null}
      {associationRuleRelations.length ? (
        <Panel
          header={
            <div className="panelTile">
              业务关联<span>（被以下表单进行业务关联）</span>
            </div>
          }
          key="3"
        >
          <List
            size="small"
            header={null}
            footer={null}
            bordered={false}
            dataSource={associationRuleRelations}
            renderItem={(item) => (
              <List.Item>
                <div className="listItemWrap">
                  <div className="listItemLeft">
                    <div className="listItemImg">
                      <IconFont type={item?.icon} className="formIcon" />
                    </div>
                    <div className="listItemName">{item?.formName}</div>
                    <div className="listItemStatus">
                      {/* 表单状态(PUBLISHED(启用) INVALID(停用) SAVED(草稿)) */}
                      {/* {item?.status === 'PUBLISHED'
                        ? '启用'
                        : item?.status === 'INVALID'
                        ? '停用'
                        : '草稿'} */}
                      {item?.status === 'INVALID' ? '已停用' : null}
                    </div>
                  </div>
                  <div className="listItemCenter">{item?.ruleName}</div>
                  {/* 试用版 隐藏【业务关联】模块的“设置”入口 */}
                  {[-1, 0, 1].includes(Number(versionValue)) ? null : (
                    <a
                      onClick={() => {
                        jumpLowCodeExtensionDesign({
                          formCode: item.formCode,
                          versionValue,
                        });
                      }}
                    >
                      设置
                    </a>
                  )}
                </div>
              </List.Item>
            )}
          />
        </Panel>
      ) : null}
    </Collapse>
  );
};

export default CollapseCom;
