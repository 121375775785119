/*
 * :file description: page process
 * :name: \low-code-platform\src\pages\generator\process\components\ConditionalForm\index.tsx
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-12 14:01:14
 * :last editor: hyw
 * :date last edited: 2022-02-23 11:21:42
 */

import c from './index.less';
import React, { useEffect, useState, useMemo, Fragment } from 'react';
import {
  Form,
  Input,
  Select,
  message,
  Modal,
  Tooltip,
  Checkbox,
  Button,
  Popover,
  Alert,
} from 'antd';
import { useModel, useLocation } from 'umi';

import { FormInstance } from 'antd/es/form';
import _cloneDeep from 'lodash/cloneDeep';
import formApi from '@/services/form';
import cs from 'classnames';
import IconFont from '@/components/IconFont';
import ConditionItem from './components/ConditionItem';
import moreConTip from '@/images/moreConTip.png';

const { Group } = Checkbox;
const { Option } = Select;
interface ConditionalProps {
  callBackConData: Function;
  data: any;
  currentNodeClues: Array<any>;
  isLastNode: Boolean;
}

const Conditional: React.FC<ConditionalProps> = ({
  callBackConData,
  data,
  currentNodeClues,
  isLastNode = false,
}) => {
  // 当前自选数据
  const [form] = Form.useForm();
  const { getFieldValue, setFieldsValue, getFieldsValue } = form;

  const formRef = React.createRef<FormInstance>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [checkValue, setCheckValue] = useState<any[]>([]);
  // 保存条件，为了方便回填
  const [saveCondition, setSaveCondition] = useState<any[]>([]);

  const [num, setNum] = useState(0);

  const [priorityArr, setPriorityArr] = useState<any[]>([]);
  // 选择条件
  const [options, setOptions] = useState<any>([]);
  // 条件组
  const [conditions, setConditions] = useState<any>([[]]);
  const [currentIndex, setCurrentIndex] = useState(1);
  const { formCode } = useModel('useGenerator', (model) => model);
  const { query } = useLocation();

  const initData = useMemo(() => {
    const optionsMap = new Map();
    options?.forEach((item) => {
      optionsMap.set(item.id, item);
    });
    const conditions =
      data.conditions.length == 0
        ? [[]]
        : data.conditions.map((item) =>
            item.map((childItem) => {
              // 用于获取最新schema选项
              if (childItem.widget === 'select') {
                const curProperty = optionsMap.get(childItem.id);
                if (curProperty) {
                  const curEnum = curProperty.enum;
                  const curEnumNames = curProperty.enumNames;
                  return {
                    ...childItem,
                    enum: curEnum,
                    enumNames: curEnumNames,
                  };
                }
              }
              return childItem;
            }),
          );
    return {
      conditions,
      name: data.name,
      priority: String(
        Number(currentNodeClues[currentNodeClues.length - 1]) + 1,
      ),
    };
  }, [data, options]);

  const defaultInitiator = [
    {
      title: '发起人',
      id: 'startUserId',
      widget: 'initiator',
    },
  ];
  // 根据类型返回基本表单配置
  useEffect(() => {
    formApi
      .getFormSchemas({
        formCode: formCode || query?.formCode,
      })
      .then((res: any) => {
        const {
          data: { content },
        } = res;
        let parseData = JSON.parse(content);
        const { properties } = parseData;
        // 依次对应---部门，金额，数字，组织成员，单选，多选，
        let effectConditions = [
          'department',
          'money',
          'number',
          'innerContact',
          'select',
          'multiSelect',
        ];
        let effectiveArr: any[] = [];
        Object.entries(properties || {})?.forEach((ele: any) => {
          // 如果匹配以上数据则收录起来
          if (effectConditions.includes(ele[1].widget)) {
            effectiveArr.push(ele[1]);
          }
        });
        setOptions([...defaultInitiator, ...(effectiveArr || [])]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // 根据类型返回基本表单配置
  useEffect(() => {
    setFieldsValue({
      name: initData.name,
      priority: `${initData.priority}`,
      //...TODO:其他需要回填的节点信息
    });
    setPriorityArr([
      {
        name: `优先级${initData.priority}`,
        value: `${initData.priority}`,
      },
    ]);
    setConditions([...initData.conditions]);
  }, [initData]);

  useEffect(() => {
    setNum(checkValue.length || 0);
  }, [checkValue]);

  const onFinish = (values: any) => {
    console.log('Received values of form:', values);
  };

  // 人员选择器确定操作
  const handleOk = () => {
    if (checkValue.length === 0) {
      message.info('请选择条件');
      return;
    }
    let result = options.filter((val) => checkValue.includes(val.id));
    // 拿之前保存的条件，替换原始数值
    let mapResult = result.map((val) => {
      if (saveCondition.some((e) => e.id == val.id)) {
        let fiterOption = saveCondition.filter((ele) => ele.id == val.id);
        return fiterOption[0];
      } else {
        return val;
      }
    });

    conditions[currentIndex] = _cloneDeep(mapResult);
    setConditions([...conditions]);
    setIsModalVisible(false);
    callBackConData({
      name: getFieldValue('name'),
      priority: getFieldValue('priority'),
      conditions: conditions,
    });
  };

  // 关闭人员选择器
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // 展示条件
  const showConditional = (arr: Array<string>, i: number) => {
    setCurrentIndex(i);
    let checkValue = arr.map((val: any) => val.id);

    setSaveCondition([...arr]);
    setCheckValue(checkValue);
    setIsModalVisible(true);
  };

  // 展示条件组
  const addConditionalGroup = () => {
    if (conditions.length === 3) {
      message.info('最多添加3个条件组');
      return;
    }
    conditions.push([]);
    setConditions([...conditions]);
    callBackConData({
      name: getFieldValue('name'),
      priority: getFieldValue('priority'),
      conditions: conditions,
    });
  };

  // 删除条件组

  const removeGroup = (index: number) => {
    conditions.splice(index, 1);
    setConditions([...conditions]);
    callBackConData({
      name: getFieldValue('name'),
      priority: getFieldValue('priority'),
      conditions: conditions,
    });
  };

  const removeCon = (index: number, i) => {
    conditions[index].splice(i, 1);
    setConditions([...conditions]);
    callBackConData({
      name: getFieldValue('name'),
      priority: getFieldValue('priority'),
      conditions: conditions,
    });
  };

  // 改变数据
  const onChange = (val: any) => {
    if (val.length > 5) {
      message.info('最多选择5个条件');
      return;
    }
    setCheckValue(val);
  };

  //条件名字和条件优先级改变时
  const handleChange = (e: any, key: any) => {
    console.log(44444, e);
    callBackConData({
      name: getFieldValue('name'),
      priority: getFieldValue('priority'),
      conditions: conditions,
    });
    if (key === 'priority') {
    } else {
    }
  };
  // 更新条件数据
  const upConData = (index, i, data, id) => {
    conditions[index][i].value = data;
    setConditions([...conditions]);
    console.log('conditions', conditions);

    callBackConData({
      name: getFieldValue('name'),
      priority: getFieldValue('priority'),
      conditions: conditions,
    });
  };

  const content = () => (
    <div className={c.addConTip}>
      <img src={moreConTip} className={c.img} />
      <p>前往表单设计，添加字段</p>
    </div>
  );
  return (
    <div className={c.conditionalForm}>
      <Modal
        maskClosable={false}
        title="选择条件"
        width={480}
        visible={isModalVisible}
        onOk={handleOk}
        okText="确定"
        cancelText="取消"
        onCancel={handleCancel}
        destroyOnClose={true}
        wrapClassName={c.wrapClassName}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <div>
          <p>
            请选择用来区分审批流程的条件字段，已选<strong>{num}</strong>个
          </p>
          <div className={c.showArea}>
            <Group value={checkValue} onChange={onChange}>
              {options.map((val: any) => {
                return (
                  <Checkbox key={val.id} value={val.id}>
                    {val.title}
                  </Checkbox>
                );
              })}
            </Group>
          </div>
        </div>
      </Modal>

      <div className={c.settingArea}>
        {isLastNode && (
          <div className={c.noEdit}>
            <Alert
              message="默认其他条件进入此流程（该分支不可编辑和删除）"
              type="info"
              showIcon
            />
          </div>
        )}
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          ref={formRef}
          className={c.baseSetting}
        >
          <Form.Item
            label="条件名称"
            name="name"
            labelCol={{ span: 23 }}
            wrapperCol={{ span: 24 }}
          >
            <Input
              onChange={(e) => handleChange(e, 'name')}
              placeholder="请输入条件名称"
              maxLength={100}
              // disabled={ }
            />
          </Form.Item>
          {data?.nodeName !== 'InclusiveConditionNode' && (
            <Form.Item
              label={
                <div>
                  条件优先级
                  <Tooltip
                    placement="topLeft"
                    title="首先匹配“优先级1”的条件。如果匹配成功则审批单发送给该条件下的审批人和抄送人；如果匹配不成功则继续进行匹配下一优先级的条件"
                  >
                    {' '}
                    <IconFont type="icon-shuoming" className={c.tipImg} />
                  </Tooltip>
                </div>
              }
              name="priority"
              labelCol={{ span: 23 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                style={{ width: '100%' }}
                onChange={(e) => handleChange(e, 'priority')}
                placeholder="请选择优先级"
                disabled
                className={c.diableSelect}
              >
                {priorityArr.map((val, i) => {
                  return (
                    <Option value={val.value} key={val.value}>
                      {val.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
        </Form>
        {!isLastNode && (
          <div className={cs(c.commonStyle, c.conditionGroup)}>
            {conditions.map((ele: any, index: number) => {
              return (
                <Fragment key={index}>
                  <div
                    className={conditions.length === 1 ? c.single : c.multiple}
                  >
                    {conditions.length !== 1 && (
                      <div className={c.title}>
                        <span>条件组{index + 1}</span>{' '}
                        <IconFont
                          onClick={() => removeGroup(index)}
                          className={c.icon}
                          type="icon-shanchu"
                        />
                      </div>
                    )}
                    <div className={c.content}>
                      {ele &&
                        ele.map((val: any, i: number) => {
                          return (
                            <Fragment key={val.id + index + i}>
                              <ConditionItem
                                data={val}
                                deleteCon={() => {
                                  removeCon(index, i);
                                }}
                                onChangeValue={(data, id) => {
                                  upConData(index, i, data, id);
                                }}
                              />
                              {i !== ele.length - 1 && <p>且</p>}
                            </Fragment>
                          );
                        })}
                      <p className={cs(ele.length !== 0 && c.addCon)}>
                        <Button
                          shape="round"
                          icon={<IconFont type="icon-tianjia1"></IconFont>}
                          onClick={() => showConditional(ele, index)}
                        >
                          添加条件
                        </Button>
                        <span className={c.subTips}>
                          还可以添加<strong>{5 - ele.length}</strong>个条件
                        </span>
                      </p>
                    </div>
                  </div>
                  {index !== conditions.length - 1 && <p>或</p>}
                </Fragment>
              );
            })}
          </div>
        )}

        {!isLastNode && (
          <div className={c.commonStyle}>
            <p className={c.addGrounpBtn}>
              <Button
                type="primary"
                shape="round"
                onClick={addConditionalGroup}
                icon={<IconFont type="icon-tianjia1"></IconFont>}
              >
                添加条件组
              </Button>
              <Popover
                placement="top"
                title={null}
                content={content()}
                trigger="hover"
                overlayClassName={c.popover}
              >
                <Button type="link" className={c.tip}>
                  如何添加更多条件 ？
                </Button>
              </Popover>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Conditional;
