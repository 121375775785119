/*
 * @Description:
 * @FilePath: /low-code-platform/src/pages/generator/relation/shareData/WidgetModal/index.tsx
 * :author: wu.shangru
 * :copyright: (c) 2023, Tungee
 * @Date: 2023-12-25 17:57:38
 * @LastEditors: wu.shangru
 * @LastEditTime: 2023-12-26 14:52:53
 */

import React, { useEffect, useState, FC, useMemo, useRef } from 'react';
import {
  Modal,
  Input,
  Table,
  message,
  Tabs,
  Space,
  ConfigProvider,
  Button,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { ColumnType } from 'antd/lib/table/interface';
import { WidgetProps } from 'typings';
import zhCN from 'antd/es/locale/zh_CN';
import {
  queryOaFormDataList,
  queryFormDataList,
  bookbabaQueryFormDataList,
  queryCustomerList,
  senheChildCustomerList,
  senheContractChildCustomerList,
  getOaFormSchema,
  getFormSchema,
  processList,
} from './api';
import { isJSON, customJP } from '@/utils';
import c from './index.less';
import {
  genTableColumns,
  genTableData,
  TABLE_CELL_BIZALIAS,
  useDebounce,
  CUSTOMER_BIZ_TYPE,
  CRM_FOUR_BASE_BIZTYPE,
  CONTRACT_CUSTOMER,
  CUSTOMER,
  isSenhe,
} from './utils';
import IconFont from '@/components/IconFont';
import Pagination from './Pagination';
// import noData from '@/assets/form/empty.png';
// import AdvancedFilter from '../../../../../Table/components/AdvancedFilter';
// import { useFormOperationAuth } from '../../../../../utils/hooks';
// import ResizableTable from '../../../../../Table/components/ResizableTable';
// import { getColumnsStorage } from '../../../../../Table/components/ResizableTable/utils';
// import { handleAdvanceFilterSchema } from '../../../../../Table/components/AdvancedFilter/util';

const columnsFixedInFieldIdJSON =
  localStorage.getItem(`tungee_table_columns_fixed_id`) ?? '{}';
const columnsFixedInFieldId = JSON.parse(columnsFixedInFieldIdJSON);
const APPROVAL_FIELDID_LIST = [
  'lastProcessStatus',
  'lastProcessResult',
  'createUserId',
];

const { TabPane } = Tabs;
const DEFAULT_BIZ_TYPE = 'crm_customer';
export const customerTitleMap = new Map([
  ['crm_customer', '企业客户'],
  ['crm_customer_personal', '个人客户'],
]);

const empty = (height?: number) => {
  return (
    <div className={c.empty} style={{ height: height ? height - 40 : 'auto' }}>
      {/* <img src={noData} alt="" className={c.empty_img} /> */}
      <p>暂无数据</p>
    </div>
  );
};

const SelectorModal: FC<WidgetProps> = (props) => {
  const {
    id,
    title = '',
    schema,
    addons,
    children,
    visible,
    setVisible,
    selectedRows,
    setSelectedRows,
    handleConfirm,
    type,
    value,
    relationSchemaRef,
    parentCustomerInstanceId, // 森合：当前选中的【上级客户】
    fromSource,
  } = props;
  const tableRef = useRef<HTMLDivElement>();
  const { getValues, dependValues, formData, dataPath } = addons ?? {};
  const [keyword, setKeyword] = useState<string | undefined>();
  const [hasMore, setHasMore] = useState(true);
  const [currentToken, setCurrentToken] = useState(null);
  const [nextToken, setNextToken] = useState(null);
  const [tokenHistory, setTokenHistory] = useState([]);
  const [innerSelectRows, setInnerSelectRows] = useState<any[]>([]);
  const [first, setFirst] = useState(true);
  // 关联表单数据
  const [columns, setColumns] = useState<ColumnType<object>[]>([]);
  const [dataSource, setDataSource] = useState([]);
  const [bizType, setBizType] = useState(DEFAULT_BIZ_TYPE);
  const [relateSourceAuth, setRelateSourceAuth] = useState([]);
  const [pageParam, setPageParam] = useState({
    pageSize: 10,
    total: 0,
    count: 0,
  });

  // 高筛参数
  const [AFQuery, setAFQuery] = useState<any>({
    queryConditionList: [],
  });
  const [fixColumns, setFixColumns] = useState<any[]>();
  const [fixColumnIdSet, setFixColumnIdSet] = useState<Set<string>>(
    new Set(columnsFixedInFieldId[id]),
  );
  const { isOaRelation } = schema;
  const source = schema?.dataSource?.target?.source;

  const IS_OA_FORM = isOaRelation || source === 4;

  // table 高度
  const tableHeight = useMemo(() => {
    let res = 500;
    if (Array.isArray(relateSourceAuth) && relateSourceAuth.length) {
      // 关联表单tab
      res -= 62;
    } else {
      res -= 16;
    }
    if (!IS_OA_FORM) {
      // 搜素框、高筛、显示列
      res -= 44;
    } else {
      // 底部分页
      res -= 116;
    }
    return res;
  }, [relateSourceAuth, IS_OA_FORM]);

  // table body 高度 总高度 减去 header pagination
  const tableListHeight =
    tableHeight -
    (tableRef?.current?.getElementsByClassName('ant-table-thead')[0]
      ?.clientHeight ?? 0) -
    (!IS_OA_FORM ? 48 : 0);

  useEffect(() => {
    const relateAuth = schema?.relateSource?.filter(
      (val) => val?.dataSource?.target?.status === 'PUBLISHED',
    );
    setRelateSourceAuth(relateAuth);

    if (relateAuth?.[0]) {
      setBizType(relateAuth?.[0]?.bizType);
    }
  }, []);

  const getDataSource = () => {
    const { relateSource } = schema;
    let targetSchema = _.cloneDeep(schema);
    if (relateSource) {
      targetSchema = relateSource.find(
        (item: Record<string, any>) => item.bizType === bizType,
      );
    }
    return targetSchema?.dataSource;
  };

  const getFormCode = () => {
    const dataSource = getDataSource();
    const { formCode } = dataSource?.target || {};
    return formCode;
  };

  //   const formOperationAuth = useFormOperationAuth({
  //     formCode: getFormCode(),
  //   });

  // 关联对象新建权限处理：对于非独立客户，四张基础表单(企业客户, 个人客户, 联系人和跟进记录) 放开新建权限
  const OPEN_PERMISSION_BIZTYPES = [
    'crm_customer', // 企业客户
    'crm_customer_personal', // 个人客户
    'crm_contact', // 联系人
    'crm_follow_record', // 跟进记录
  ];

  //   if (
  //     !window.isGrayForCRMIndependence
  //     && OPEN_PERMISSION_BIZTYPES.includes(
  //       // 要区分(个人/企业)客户表与其他表，bizType 只为客户表时准确,不能直接取 bizType
  //       getDataSource()?.target?.bizType,
  //     )) {
  //     formOperationAuth.add('ADD');
  //   }

  const getFilters = () => {
    const dataSource = getDataSource();
    const { filters = [] } = dataSource?.params;
    return filters || [];
  };

  const getSchemaByPath = (fieldId: string) => {
    const relationSchema = relationSchemaRef.current;
    return relationSchema?.properties[fieldId] || {};
  };

  const getQueryConditionList = () => {
    const filters = getFilters();
    const result = filters.map((item) => {
      const { fieldId, filterType, value, valueType } = item;
      const isCurrentField = valueType === 'current';
      const itemSchema = getSchemaByPath(fieldId) || {};
      const { widget } = itemSchema;
      let itemValue = isCurrentField ? getValues?.()?.[value] : value;
      let itemValues;
      if (!itemValue || (!APPROVAL_FIELDID_LIST.includes(fieldId) && !widget)) {
        return;
      }

      if (filterType === 'BETWEEN' || filterType === 'IN') {
        switch (widget) {
          case 'date':
          case 'gmtCreate':
            itemValues = itemValue;
            itemValue = undefined;
            break;
          case 'innerContact':
          case 'createUserId':
            itemValues = (itemValue || []).map((item) => item.key);
            itemValue = undefined;
            break;
          case 'department':
            itemValues = (itemValue || []).map((item) => `dept_${item.key}`);
            itemValue = undefined;
            break;
          case 'select':
          case 'multiSelect':
            itemValues = itemValue;
            itemValue = undefined;
            break;
        }
      } else {
        switch (widget) {
          case 'select':
            itemValue = itemValue?.key;
            break;
          case 'innerContact':
          case 'createUserId':
            itemValue = itemValue?.[0]?.key;
            break;
          case 'department':
            itemValue = itemValue?.[0]?.key;
            break;
          case 'phone':
            itemValue = isCurrentField ? itemValue?.value : value;
            break;

          case 'relation':
            itemValue = isCurrentField
              ? itemValue?.instanceId
              : value?.instanceId;
            break;
          case 'address':
            const address = (itemValue?.address || []).join('');
            const detail = itemValue?.detail || '';
            itemValue = isCurrentField ? `${address}${detail}` : value;
            break;
        }
      }
      // 特殊类型判断
      if (fieldId === 'lastProcessStatus' || fieldId === 'lastProcessResult') {
        // 兼容老的单选规则
        if (Array.isArray(itemValue)) {
          itemValues = itemValue;
          itemValue = undefined;
        } else {
          itemValue = itemValue?.key || itemValue;
        }
      }
      if (fieldId === 'createUserId') {
        if (filterType === 'IN') {
          itemValues = (itemValue || []).map((item) => item.key);
          itemValue = undefined;
        } else {
          itemValue = itemValue?.[0]?.key;
        }
      }
      return {
        ...item,
        value: itemValues ? undefined : itemValue,
        values: itemValues || undefined,
      };
    });
    return result
      .concat(
        ...(AFQuery?.queryConditionList.map((item) => {
          const val = { ...item };
          if (val.values != null && val.value != null) {
            val.value = val.values;
            delete val.values;
          }
          return val;
        }) ?? []),
      )
      .filter((item) => !!item);
  };

  // 森合定制：【企业客户】【合同】表单的【下级客户】不允许重复添加，添加“已被选择”提示
  const selectedData = (data, isShowMessage = false) => {
    if (!isSenhe() || !getValues) return { hasRepeat: false };

    let id = '';
    let idItem = '';

    if (schema?.bizAlias === CUSTOMER.SUBORDINATE.BIZALIAS) {
      id = CUSTOMER.SUBORDINATE.ID;
      idItem = CUSTOMER.SUBORDINATE.ITEM_ID;
    } else if (schema?.bizAlias === CONTRACT_CUSTOMER.SUBORDINATE.BIZALIAS) {
      id = CONTRACT_CUSTOMER.SUBORDINATE.ID;
      idItem = CONTRACT_CUSTOMER.SUBORDINATE.ITEM_ID;
    }

    const selectedCustomers = getValues()?.[id];
    const repeatCustomers = data.reduce(
      (repeatCustomers, theData) => {
        if (
          selectedCustomers?.find(
            (selectedCustomer) =>
              selectedCustomer?.[idItem]?.instanceId === theData?.instanceId,
          )
        ) {
          repeatCustomers.titles = repeatCustomers.titles
            ? `${repeatCustomers.titles}、${theData?.title}`
            : theData?.title;
          repeatCustomers.data.push(theData);
        }

        return repeatCustomers;
      },
      {
        titles: '', // 已添加数据的 title
        data: [], // 已添加数据
      },
    );

    const hasRepeat = !!repeatCustomers.data.length;

    if (hasRepeat && isShowMessage) {
      message.warning({
        content: `${
          repeatCustomers?.titles || ''
        }已经被添加到下级客户，不能重复添加。`,
        style: { marginTop: '30px' },
      });
    }

    return {
      ...repeatCustomers,
      hasRepeat,
    };
  };
  const addTipWhenrepeated = (tableData, columns) => {
    const { hasRepeat, data: repeatedData } = selectedData(tableData);

    if (hasRepeat) {
      columns.forEach((column) => {
        if (column.key === 'title')
          column.render = (text, record) => {
            if (record.isSelected)
              return (
                <>
                  <div>{text}</div>
                  <div style={{ color: '#888' }}>（已被选择）</div>
                </>
              );

            return <span>{text}</span>;
          };
      });
      tableData.forEach((tableItem) => {
        if (
          repeatedData.find(
            (repeatItem) => repeatItem.instanceId === tableItem.instanceId,
          )
        )
          tableItem.isSelected = true;
      });
    }
  };

  const handleSearch = async ({
    pageIndex = 1,
    pageSize = 10,
    sort,
    order,
    offset,
  } = {}) => {
    const queryConditionList = getQueryConditionList();
    const formCode = getFormCode();
    if (!IS_OA_FORM) {
      let customerbizType;

      if (schema?.bizAlias === 'parent_customer') {
        customerbizType = props.bizType;
      }

      schema?.relateSource?.some((item) => {
        if (item?.dataSource?.target?.formCode === formCode) {
          customerbizType = item.bizType;
          return true;
        }
      });

      customerbizType &&
        queryConditionList.push({
          fieldId: 'biz_customer_biz_scene',
          filterType: 'EQ',
          value: customerbizType,
        });
    }
    // 关联产品表单 筛选上架产品
    if (schema?.bizAlias?.indexOf('_related_product') > -1) {
      queryConditionList?.push({
        fieldId: 'select_1PRDP7MO7RPC0',
        filterType: 'NEQ',
        value: 'off_the_shelves',
      });
    }
    let res = null;
    if (IS_OA_FORM) {
      setCurrentToken(offset ?? null);
      res = await queryOaFormDataList({
        formCode,
        nextToken: offset,
        maxResults: pageSize,
        sort,
        order,
        showTitle: true,
        queryConditionList,
        needFilter: true,
      });
    } else {
      let reqfunc = queryFormDataList;
      const specificParams = {};
      if (window.__siteId && window.__dd.env.platform === 'notInDingTalk') {
        reqfunc = bookbabaQueryFormDataList;
      } else if (schema?.bizAlias === 'parent_customer') {
        // 客户上下级 单独调用其他接口
        reqfunc = queryCustomerList;
      } else if (
        isSenhe() &&
        schema?.bizAlias === CUSTOMER.SUBORDINATE.BIZALIAS
      ) {
        // 森合【客户表单】【下级客户】接口
        reqfunc = senheChildCustomerList;
        specificParams.bizType = getDataSource()?.target?.bizType;
      } else if (
        isSenhe() &&
        schema?.bizAlias === CONTRACT_CUSTOMER.SUBORDINATE.BIZALIAS
      ) {
        // 森合【合同表单】【下级客户】接口
        reqfunc = senheContractChildCustomerList;
        specificParams.childBizType = getDataSource()?.target?.bizType;
        specificParams.parentCustomerId = parentCustomerInstanceId;
      }

      res = await reqfunc({
        formCode,
        keyword: keyword || undefined,
        pageNum: pageIndex,
        pageSize,
        sort,
        order,
        showTitle: true,
        queryConditionList,
        needFilter: true,
        openCustomer: CUSTOMER_BIZ_TYPE.includes(bizType) ? false : undefined,
        sceneType:
          bizType === 'crm_follow_record' ? 'related_customer' : undefined,
        type: type === 'tableSelector' ? 'child' : 'parent', // queryCustomerList 上级接口type区分
        customerId: props?.instanceId || '', // 客户上下级 添加的customerId参数，做客户数据过滤
        ...specificParams,
      });
    }

    res.data = res.data || res.result;

    setPageParam({
      pageSize: res?.data?.size ?? 10,
      total: res?.data?.total,
      count: res?.data?.count,
    });
    setHasMore(res?.data?.hasMore);
    setNextToken(res?.data?.nextToken);
    const tableData = genTableData(res.data);
    const _column = genTableColumns(res.data, {
      sorter: IS_OA_FORM ? false : undefined,
    });
    // 本地存储的该form的table列宽
    // const columnsStorage = getColumnsStorage(id + (bizType ?? ''));
    // if (Array.isArray(columnsStorage)) {
    //   _column.forEach(v => {
    //     // 使用本地缓存的列宽替换该form各字段的列宽
    //     const matchCol = columnsStorage.find(item => item.dataIndex === v.dataIndex);
    //     v.width = matchCol?.width ?? v.width;
    //     if (matchCol?.dataIndex?.startsWith('table_')) {
    //       if (Array.isArray(matchCol.children) && Array.isArray(v.children)) {
    //         v.children.forEach(child => {
    //           const m = matchCol.children.find(p => p.dataIndex === child.dataIndex);
    //           child.width = m?.width ?? child.width;
    //         });
    //       }
    //     }
    //   });
    // }
    addTipWhenrepeated(tableData, _column);
    setColumns(_column);
    setFixColumns(
      _column
        .map((item, index) => {
          const fixed = fixColumnIdSet.has(item.key);
          return {
            index,
            ...item,
            children: item.children?.map((child) => ({ ...child, fixed })),
            fixed,
          };
        })
        .sort((a, b) => {
          if (a.fixed && b.fixed) {
            return a.index - b.index;
          }
          if (a.fixed) {
            return -1;
          }
          return 1;
        }),
    );
    setDataSource(tableData);
    return tableData;
  };

  const getRelationFormSchema = async () => {
    const formCode = getFormCode();
    if (!formCode) return;
    try {
      const API_NAME = IS_OA_FORM ? getOaFormSchema : getFormSchema;
      const schemaData = await API_NAME({
        formCode,
        sceneType:
          bizType === 'crm_follow_record' ? 'related_customer' : undefined,
      });
      let relationSchema = isJSON(schemaData?.data.content)
        ? customJP(schemaData?.data.content)
        : undefined;

      // 判断如果开启了流程，查询流程表单特殊字段
      if (schemaData?.data?.processId) {
        const processFields = await processList({ formCode: [formCode] });
        const processContent = JSON.parse(
          processFields?.data[0]?.processContent ?? '{}',
        );
        relationSchema = {
          ...relationSchema,
          properties: { ...relationSchema?.properties, ...processContent },
        };
      }

      relationSchemaRef.current = relationSchema;
      const res = await handleSearch();
      return res;
    } catch (error) {
      message.error(error?.data?.msg);
    }
  };

  const handleOk = () => {
    if (selectedData(innerSelectRows, true)?.hasRepeat) return;

    const formCode = getFormCode();
    // 当是客户时要区分个人客户和企业客户
    if (schema?.relateSource?.length) {
      handleConfirm(formCode, innerSelectRows, bizType);
    } else {
      handleConfirm(formCode, innerSelectRows);
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleSelect = () => {
    const { showSelectorVerify } = schema;
    if (showSelectorVerify && !showSelectorVerify.rule) {
      message.warning(showSelectorVerify.message);
      return;
    }
    setVisible(true);
  };

  const getDataBySorter = (pagination, sorter) => {
    const { current: pageIndex, pageSize } = pagination;
    const order = [];
    const sort = [];
    // 存在排序时
    if (sorter?.order) {
      order.push(sorter?.order === 'descend' ? 1 : 0);
      sort.push(
        TABLE_CELL_BIZALIAS.includes(sorter?.field)
          ? sorter?.columnKey
          : sorter?.field,
      );
    }
    const params = {
      pageIndex,
      pageSize,
      order: sorter?.order ? order : undefined,
      sort: sorter?.order ? sort : undefined,
    };
    handleSearch(params);
  };

  const setDefaultRelation = async () => {
    if (dependValues?.length > 0 && !first) {
      try {
        const curRelData = formData[dataPath];
        const res = await getRelationFormSchema();
        // 如果在详情状态下 不能默认取一个数据 会导致被覆盖
        const selectedData = res.find(
          (item) => item.instanceId === curRelData.instanceId,
        );
        if (selectedData) {
          handleOk(selectedData);
        } else {
          const defaultSelectedRow = res.splice(0, 1);
          handleOk(defaultSelectedRow);
        }
        if (!dependValues.toString()) {
          handleOk([]);
        }
      } catch (error) {
        handleOk([]);
      }
    }
  };

  const debounceSearch = useDebounce(handleSearch, 500);

  useEffect(() => {
    if (!visible) {
      setInnerSelectRows([]);
      setKeyword(undefined);
      setAFQuery({
        queryConditionList: [],
      });
    }
    if (visible) {
      getRelationFormSchema();
    }
  }, [visible, bizType]);

  useEffect(() => {
    setDefaultRelation();
  }, [JSON.stringify(dependValues)]);

  useEffect(() => {
    if (visible) {
      debounceSearch();
    }
  }, [keyword, AFQuery]);

  useEffect(() => {
    if (bizType) {
      setAFQuery({
        queryConditionList: [],
      });
    }
  }, [bizType]);

  useEffect(() => {
    setFirst(false);
  }, []);

  const pagination = {
    total: pageParam?.total ?? pageParam.count * pageParam.pageSize,
    showTotal: () => (
      <div
        style={{
          marginRight: '10px',
        }}
      >
        共 {pageParam?.total} 项
      </div>
    ),
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 50, 100],
  };

  const rowSelection = {
    type: !value && type === 'tableSelector' ? 'checkbox' : 'radio',
    fixed: true,
    selectedRowKeys: innerSelectRows.map((item) => item.instanceId),
    preserveSelectedRowKeys: true,
    onChange: (
      selectedRowKeys: React.SetStateAction<never[]>,
      selectedRows: React.SetStateAction<never[]>,
    ) => {
      setInnerSelectRows(selectedRows);
    },
  };

  const createRelatedObj = () => {
    const dataSourceTarget = getDataSource()?.target;
    const theBizType = dataSourceTarget?.bizType;
    const theFormCode = getFormCode();

    if (
      CRM_FOUR_BASE_BIZTYPE.includes(theBizType) &&
      !window.isGrayForCRMIndependence
    ) {
      window.openDingDingUrl({
        source: dataSourceTarget?.source,
        formCode: theFormCode,
        bizType: theBizType,
        appType: dataSourceTarget?.appType,
        formName: schema?.title,
        type: 'create',
        isPublic: false,
        ddSlidePanelClose: () => {
          setTimeout(() => {
            handleSearch();
          }, 1500);
        },
      });
    } else {
      let senheProps = {};
      // 森合：新建合同-->新建关联【下级个人客户】时，提交【个人客户】表单需要提供【合同表单】中的【上级客户】信息，以关联该新建的【个人客户】与【上级客户】
      if (
        isSenhe() &&
        props.isContract &&
        getDataSource()?.target?.bizType === 'crm_customer_personal' &&
        props?.parentCustomerId
      )
        senheProps = {
          contractParentCustomer: {
            ...(addons?.getValue(props?.parentCustomerId) || {}),
            id: props?.parentCustomerId,
            isContract: props.isContract,
          },
        };

      window.__openCreateDrawer({
        formCode: theFormCode,
        bizType: theBizType,
        type: 'create',
        source: dataSourceTarget?.source,
        appType: dataSourceTarget?.appType,
        formName: schema?.title,
        fromSource,
        ...senheProps,
      });
    }
  };

  const onFixColumnChange = (columnIdSet: Set<string>) => {
    setFixColumnIdSet(columnIdSet);
    columnsFixedInFieldId[id] = Array.from(columnIdSet);
    localStorage.setItem(
      `tungee_table_columns_fixed_id`,
      JSON.stringify(columnsFixedInFieldId),
    );
    setFixColumns(
      columns
        .map((item, index) => {
          const fixed = columnIdSet.has(item.key);
          return {
            ...item,
            index,
            children: item.children?.map((child) => ({ ...child, fixed })),
            fixed,
          };
        })
        .sort((a, b) => {
          if (a.fixed && b.fixed) {
            return a.index - b.index;
          }
          if (a.fixed) {
            return -1;
          }
          return 1;
        }),
    );
  };

  return (
    <div className={c.t_association_form_selector}>
      <div onClick={handleSelect}>{children}</div>
      <Modal
        title={title}
        visible={visible}
        okText="确定"
        className="selector_modal"
        cancelText="取消"
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<IconFont type="icon-Close" />}
        bodyStyle={{ height: '500px', overflowY: 'auto' }}
      >
        <Tabs defaultActiveKey="1" onChange={setBizType}>
          {relateSourceAuth?.map((item) => {
            return (
              <TabPane
                tab={customerTitleMap.get(item.bizType)}
                key={item.bizType}
              />
            );
          })}
        </Tabs>

        {!IS_OA_FORM && (
          <div className={c.tool_wrap}>
            <Space>
              <Input
                value={keyword}
                prefix={<SearchOutlined />}
                placeholder="请输入"
                allowClear
                onChange={(e) => setKeyword(e.target.value)}
              />
            </Space>
            {/* <div className={c.tool_wrap_right}>
            <AdvancedFilter
              value={AFQuery.queryConditionList}
              schema={handleAdvanceFilterSchema({
                schema: relationSchemaRef.current,
                bizType,
              })}
              onChange={(queryConditionList) => {
                setAFQuery({
                  ...AFQuery,
                  queryConditionList,
                  source: 'filter',
                });
              }}
            />
           
          </div> */}
          </div>
        )}
        <div style={{ height: tableHeight, overflow: 'hidden' }} ref={tableRef}>
          <ConfigProvider locale={zhCN}>
            <Table
              rowKey="instanceId"
              ellipsis
              code={id + (bizType ?? '')}
              scroll={{ x: '100%', y: tableListHeight }}
              rowSelection={rowSelection}
              columns={fixColumns ?? columns}
              dataSource={dataSource}
              //   rowClassName={window.__isInSideCrm__ ? c.tableRow : ''}
              pagination={
                IS_OA_FORM
                  ? false
                  : {
                      size: 'small',
                      ...pagination,
                      // ...pageParam,
                    }
              }
              onChange={(pagination, filters, sorter) => {
                getDataBySorter(pagination, sorter);
              }}
              onRow={(record, a) => {
                return {
                  onClick: (event) => {
                    const checkType =
                      !value && type === 'tableSelector' ? 'checkbox' : 'radio';
                    if (checkType === 'radio') {
                      setInnerSelectRows([record]);
                    } else {
                      const index = innerSelectRows.findIndex(
                        (item) => item.instanceId === record.key,
                      );
                      const _selectedRows = [...innerSelectRows];
                      if (index === -1) {
                        _selectedRows.push(record);
                      } else {
                        _selectedRows.splice(index, 1);
                      }
                      setInnerSelectRows(_selectedRows);
                    }
                  },
                };
              }}
              locale={{ emptyText: empty(tableListHeight) }}
            />
          </ConfigProvider>
        </div>
        {IS_OA_FORM && (
          <Pagination
            hasMore={hasMore}
            currentToken={currentToken}
            nextToken={nextToken}
            tokenHistory={tokenHistory}
            setTokenHistory={setTokenHistory}
            search={({ offset, pageSize }) =>
              handleSearch({ offset, pageSize })
            }
          />
        )}
      </Modal>
    </div>
  );
};

export default SelectorModal;
