/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\Sift_DateRange\index.tsx
 * author: gaoch
 * copyright: (c) 2022, Tungee
 * :date created: 2022-01-07 19:20:33
 * :last editor: gaoch
 * :date last edited: 2022-02-15 15:31:42
 */
import React, { useState, useEffect } from 'react';
import { Input, Select, DatePicker } from 'antd';
import {
  isJSON,
  transferToMoment,
  dropSearchTypeProp,
  customJP,
  transformDateToString,
  transformDateToMoment,
} from '../../utils';
import { SearchTypeValue, SEARCH_TYPE_OPTIONS_OF_B_A_R } from '../../constant';
import { IFormCompProps } from '../../typings';
import './index.less';

type WidgetProps = IFormCompProps;

const { Option } = Select;
const { RangePicker } = DatePicker;

const DateWidget = (props: WidgetProps) => {
  const { value, onChange = () => {}, schema = {} } = props;
  let { searchType: initialSearchType, value: initialValue } = isJSON(value)
    ? customJP(value)
    : { searchType: undefined, value: undefined };
  const { placeholder = '请选择', hideSearchType = false, format } = schema;
  const defaultValue = SearchTypeValue.RANGE;

  const [searchType, setSearchType] = useState(
    initialSearchType || defaultValue,
  );

  const isRange = () => {
    return searchType === SearchTypeValue.RANGE;
  };

  if (isRange() && initialValue) {
    let [s, e] = initialValue;
    initialValue = [transferToMoment(s), transferToMoment(e)];
  } else {
    initialValue = transferToMoment(initialValue);
  }

  const [selectedDate, setSelectedDate] = useState(initialValue);
  const [dateRange, setDateRange] = useState(initialValue);

  const handleSearchTypeChange = (e) => {
    setDateRange(undefined);
    setSelectedDate(undefined);
    setSearchType(e);
  };

  useEffect(() => {
    const payload = {
      searchType,
      value: isRange() ? dateRange : selectedDate,
    };
    if (isRange()) {
      onChange(
        dateRange && dateRange.length
          ? JSON.stringify(dropSearchTypeProp(payload, hideSearchType))
          : '',
      );
    } else {
      onChange(
        selectedDate
          ? JSON.stringify(dropSearchTypeProp(payload, hideSearchType))
          : '',
      );
    }
  }, [searchType, dateRange, selectedDate]);

  useEffect(() => {
    if (isJSON(value)) {
      const valueObj = customJP(value);
      setSearchType(valueObj.searchType);
      if (isRange()) {
        let [s, e] = valueObj.value;
        setDateRange([transferToMoment(s), transferToMoment(e)]);
      } else {
        setSelectedDate(valueObj.value);
      }
    } else {
      setDateRange(undefined);
      setSelectedDate(undefined);
    }
  }, [value]);

  return (
    <Input.Group compact className="fr-group">
      {!hideSearchType && (
        <Select
          style={{ width: '30%' }}
          // allowClear
          value={searchType}
          onChange={handleSearchTypeChange}
        >
          {SEARCH_TYPE_OPTIONS_OF_B_A_R.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      )}
      {isRange() ? (
        <RangePicker
          style={{ width: '70%' }}
          allowClear
          value={dateRange}
          placeholder={['开始日期', '结束日期']}
          onChange={(dates, dateStrings) => setDateRange(dates)}
        />
      ) : (
        <DatePicker
          style={{ width: '70%' }}
          allowClear
          value={transformDateToMoment(selectedDate)}
          placeholder={placeholder}
          onChange={(e) => {
            setSelectedDate(transformDateToString(e, format));
          }}
        />
      )}
    </Input.Group>
  );
};

export default DateWidget;
