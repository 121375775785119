import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Modal, Table, Form, Select, TreeSelect } from 'antd';
import deepClone from 'lodash/cloneDeep';
import get from 'lodash/get';
import IconFont from '@/components/IconFont';
import styles from './index.less';
import { useStore } from '@/components/FormGenerator/utils/hooks';
const OptionsAssociatedModal = ({
  visible,
  setVisible,
  value,
  onChange,
  info = {},
}) => {
  const { displaySchema, selected, voiceDoctorTableRelatedOption } = useStore();
  const { value: optionList, addons = {} } = info as any;
  const { formData = {} } = addons;
  const { widget, parentWidget, $id } = formData;
  const formDataCache = useRef(null);
  console.log(parentWidget, '3sss');
  const curWidgetId = selected?.slice(2);
  const [form] = Form.useForm();
  const columns = [
    {
      title: '当选择以下选项时',
      dataIndex: 'trigger',
      key: 'trigger',
      width: 220,
    },
    {
      title: '显示以下字段',
      dataIndex: 'fields',
      key: 'fields',
      render: (text, record, index) => {
        return (
          <Form.Item name={record.value}>
            <TreeSelect
              showSearch
              allowClear
              multiple
              maxTagCount="responsive"
              virtual={false}
              style={{ width: '100%' }}
              value={value}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="请选择"
              treeDefaultExpandAll
              onChange={(value, label, extra) => {
                changeOptionValue(value, record);
              }}
              treeData={getOptions()}
            />
          </Form.Item>
        );
      },
    },
  ];
  const genDataSource = () => {
    return deepClone(optionList || []).map((v) => ({
      ...v,
      trigger: v.label,
      fields: v?.fields || [],
    }));
  };

  // 寻找上级节点id数组
  const getUpperFields = (arr) => {
    const upperFields: string[] = [];
    upperFields.push(curWidgetId);
    const pathArr = curWidgetId?.split('/');
    if (pathArr?.length > 1) {
      upperFields.push(pathArr?.[0]);
    }
    recursiveFind(arr, upperFields, curWidgetId);
    return upperFields;
  };

  const recursiveFind = (arr, upperArr, id) => {
    arr.map((propValue: any) => {
      if (['select', 'multiSelect'].includes(propValue?.widget)) {
        if (Array.isArray(propValue?.enumAssociatedFields)) {
          propValue?.enumAssociatedFields?.forEach((v: any) => {
            console.log(v, id, '2sss');
            if (v.includes(id)) {
              upperArr.push(propValue?.id);
              recursiveFind(arr, upperArr, propValue?.id);
            }
          });
        }
      }
    });
  };

  const getOptions = () => {
    if (!schemaArr?.length) return [];
    const upperFieldList = getUpperFields(schemaArr) || [];
    const widgetObj = displaySchema?.properties;
    //表格内部 只能选择同一表格内部字段
    let targetObj = widgetObj;
    if (parentWidget === 'table') {
      let parentWidgetId = $id?.split('/')?.[1];
      targetObj = {
        [parentWidgetId]: widgetObj?.[parentWidgetId],
      };
    }
    return Object.entries(targetObj).map(([prop, propValue]) => {
      if (propValue?.widget !== 'table') {
        return {
          label: propValue?.title ?? '说明文字',
          value: propValue?.id,
          disabled:
            propValue?.id === curWidgetId ||
            upperFieldList?.includes(propValue?.id),
        };
      } else {
        return {
          label: propValue?.title ?? '说明文字',
          value: propValue?.id,
          disabled:
            propValue?.id === curWidgetId ||
            upperFieldList?.includes(propValue?.id),
          children: !voiceDoctorTableRelatedOption
            ? []
            : Object.values(propValue?.items?.properties ?? {})?.map(
                (itemJ) => {
                  return {
                    label: propValue?.title + '.' + itemJ?.title ?? '说明文字',
                    value: propValue?.id + '/' + itemJ?.id,
                    disabled:
                      itemJ?.id?.includes(curWidgetId) ||
                      upperFieldList?.includes(propValue?.id + '/' + itemJ?.id),
                  };
                },
              ),
        };
      }
    });
  };
  const changeOptionValue = (arr, formItem) => {
    const key = formItem.value;
    const preArr = formDataCache?.current?.[key] ?? [];
    if (preArr && arr && preArr?.length > arr.length) {
      const minusValue = preArr?.filter((i) => !arr?.includes(i));
      const pathArr = minusValue?.[0]?.split('/');
      if (pathArr?.[0]?.indexOf('table') > -1 && pathArr?.length === 1) {
        const trees = getOptions()?.filter((i) => i.value === pathArr?.[0]);
        const filterTreeChildren = trees?.[0]?.children?.map((i) => i?.value);
        const resArr = arr?.filter((i) => !filterTreeChildren?.includes(i));
        form.setFieldValue(key, [...resArr]);
        formDataCache.current = {
          ...formDataCache?.current,
          [key]: [...resArr],
        };
      } else {
        form.setFieldValue(key, [...arr]);
        formDataCache.current = {
          ...formDataCache?.current,
          [key]: [...arr],
        };
      }
    } else {
      const addValue = arr?.filter((i) => !preArr?.includes(i));
      const pathArr = addValue?.[0]?.split('/');
      let addArr = [];
      if (pathArr?.length == 2) {
        addArr = addValue?.concat([pathArr?.[0]]);
      } else if (pathArr?.[0]?.indexOf('table') > -1) {
        const trees = getOptions()?.filter((i) => i.value === pathArr?.[0]);
        addArr = addValue?.concat(trees?.[0]?.children?.map((i) => i?.value));
      }
      const countArr = [...arr, ...addArr];
      form.setFieldValue(key, Array.from(new Set(countArr)));
      formDataCache.current = {
        ...formDataCache?.current,
        [key]: Array.from(new Set(countArr)),
      };
    }
  };
  const handleOk = () => {
    onChange(form.getFieldsValue(true));
    setVisible(false);
  };

  const schemaArr = useMemo(() => {
    const arr = Object.values(displaySchema?.properties ?? {});
    const res = [];
    arr?.forEach((i) => {
      res.push(i);
      if (i?.widget === 'table') {
        Object.keys(i?.items?.properties ?? {})?.forEach((j) => {
          res.push({
            ...i?.items?.properties?.[j],
            title: i?.title + '.' + i?.items?.properties?.[j]?.title,
            id: i?.id + '/' + j,
          });
        });
      }
    });
    return res;
  }, [displaySchema?.properties, selected]);

  useEffect(() => {
    if (!Array.isArray(optionList)) return;
    const formData = optionList.reduce((acc, cur) => {
      acc[cur.value] = cur.enumAssociatedFields;
      return acc;
    }, {});
    formDataCache.current = formData;
    form.setFieldsValue(formData);
  }, [optionList]);

  return (
    <Modal
      width={800}
      className={styles.modal}
      title={`${get(
        displaySchema,
        `properties[${curWidgetId?.split('/')?.[0]}].title`,
      )}-选项关联`}
      destroyOnClose
      closeIcon={<IconFont type="icon-Close" />}
      visible={visible}
      onOk={handleOk}
      onCancel={() => setVisible(false)}
    >
      <div className={styles.tip}>
        根据选择的选项，显示设置的对应字段（不可设置当前字段和上级字段）
      </div>
      <Form form={form} component={false}>
        <Table
          bordered={true}
          columns={columns}
          dataSource={genDataSource()}
          pagination={false}
        ></Table>
      </Form>
    </Modal>
  );
};

export default OptionsAssociatedModal;
