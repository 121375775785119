/*
 * :file description: page process
 * :name: \low-code-platform\src\pages\generator\process\components\ConditionalForm\components\ConditionItem.tsx
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-12 14:01:14
 * :last editor: hyw
 * :date last edited: 2022-02-23 15:32:03
 */

import c from './index.less';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { InputNumber, Select, message, Modal, Checkbox } from 'antd';
import _cloneDeep from 'lodash/cloneDeep';
import { CloseOutlined } from '@ant-design/icons';
import cs from 'classnames';
import IconFont from '@/components/IconFont';
import MultipleChoice from '@/components/MultipleChoice';

const { Group } = Checkbox;
const { Option } = Select;
interface ConditionItemProps {
  onChangeValue: Function;
  data: any;
  deleteCon: Function;
}

const ConditionItem: React.FC<ConditionItemProps> = ({
  onChangeValue,
  data,
  deleteCon,
}) => {
  // 当前自选数据

  const [openTabs, setOpenTabs] = useState(['部门']);

  const [openType, setOpenType] = useState(1);
  // const childRef = useRef: React.RefObject<React.MutableRefObject<any>>(null);
  const childRef = useRef(null);
  const [checkValue, setCheckValue] = useState<any[]>([]);
  const [options, setOptions] = useState<any>([]);
  // 条件组
  const [numOrMoneyType, setNumOrMoneyType] = useState('lt');
  const [multiSelectType, setMultiSelectType] = useState('1');
  const [multiple, setMultiple] = useState(false);
  const [userRoleDepartData, setUserRoleDepartData] = useState<any[]>([]);
  const [initiatorData, setInitiatorData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [endValue, setEndValue] = useState('');
  const [beginValue, setBeginValue] = useState('');
  const [currentOptionalData, setCurrentOptionalData] = useState({});
  const [modalTitle, setModalTitle] = useState('部门');

  const initData = useMemo(() => {
    return data;
  }, [data]);

  // 根据类型返回基本表单配置
  useEffect(() => {
    const {
      enumNames,
      enum: enumArr,
      default: defaultValue,
      widget,
      choice,
      multiple,
      value,
    } = initData;
    let options: Array<any> = [];
    enumNames &&
      enumNames.forEach((ele: any, i: number) => {
        options.push({ title: ele, value: enumArr[i] });
      });

    setOptions(options);
    setCheckValue(defaultValue);

    // 依次对应---部门，金额，数字，组织成员，单选，多选，
    // [
    //   'department',
    //   'money',
    //   'number',
    //   'innerContact',
    //   'select',
    //   'multiSelect',
    // ];

    // 特殊处理
    if (widget === 'select' && !value) {
      setCheckValue([defaultValue] || []);
    }

    if (widget === 'select' && value) {
      setCheckValue(value || []);
    }

    if (widget === 'multiSelect' && value) {
      setMultiSelectType(value?.before || 'lt');
      setCheckValue(value?.after || []);
    }
    // 金额和数字
    if ((widget === 'money' || widget === 'number') && value) {
      setNumOrMoneyType(value?.before || 'lt');
      setBeginValue(value?.after?.min);
      setEndValue(value?.after?.max);
    }

    if (widget === 'innerContact') {
      if (choice == '1') {
        setMultiple(true);
      }
      setUserRoleDepartData(value || []);
    }
    if (widget === 'initiator') {
      setMultiple(true);
      setInitiatorData(value || []);
    }

    if (widget === 'department') {
      if (multiple) {
        setMultiple(true);
      }
      setDepartmentData(value || []);
    }
  }, [initData]);

  // 数据回调
  const getDataBack = (data: any) => {
    if (openType === 1) {
      setDepartmentData(data?.departments || []);
      handleChange(data?.departments || []);
    } else {
      let arr: Array<any> = [];
      data?.users &&
        data?.users?.forEach((ele) => {
          ele.orgType = 1;
        });
      data?.departments &&
        data?.departments.forEach((ele) => {
          ele.orgType = 2;
        });
      data?.roles &&
        data?.roles.forEach((ele) => {
          ele.orgType = 3;
        });
      let concatArr: any = arr
        .concat(data?.users || [])
        .concat(data?.departments || [])
        .concat(data?.roles || []);
      if (openType === 2) {
        setUserRoleDepartData(concatArr);
      } else {
        setInitiatorData(concatArr);
      }
      handleChange(concatArr || []);
    }
  };

  // 改变数据
  const onChange = (val: any) => {
    if (val.length > 5) {
      message.info('最多选择5个条件');
      return;
    }
    setCheckValue(val);
  };

  // 改变数据
  const handleChange = (val: any, type?: string, minOrMax?: any) => {
    let sendValue = null;
    switch (type) {
      case 'numberOrMoney':
        if (minOrMax == 'min') {
          setBeginValue(val);
          sendValue = {
            before: numOrMoneyType,
            after: {
              [minOrMax]: val,
              max: endValue,
            },
          };
        } else {
          setEndValue(val);
          sendValue = {
            before: numOrMoneyType,
            after: {
              [minOrMax]: val,
              min: beginValue,
            },
          };
        }
        break;
      case 'multiSelect':
        sendValue = {
          before: multiSelectType,
          after: val,
        };
        setCheckValue(val);
        break;
      default:
        sendValue = val;
        break;
    }
    onChangeValue(sendValue, initData.id);
  };

  const handleSelect = (val: any, type: string) => {
    switch (type) {
      case 'numberOrMoney':
        setNumOrMoneyType(val);
        onChangeValue(
          {
            before: val,
            after: {
              min: '',
              max: '',
            },
          },
          initData.id,
        );
        setBeginValue('');
        setEndValue('');
        break;
      case 'multiSelect':
        setMultiSelectType(val);
        onChangeValue(
          {
            before: val,
            after: checkValue,
          },
          initData.id,
        );
        break;
    }
  };

  const openModel = (type: number, data: any) => {
    setOpenType(type);
    if (type == 1) {
      setOpenTabs(['部门']);
      setCurrentOptionalData({
        departments: data,
      });
      setModalTitle('部门');
    } else if (type === 2) {
      setOpenTabs(['人员']);
      const userData = data.filter((val) => val.orgType == 1);
      setCurrentOptionalData({
        users: userData,
      });
      setModalTitle('组织成员');
    } else if (type === 3) {
      setOpenTabs(['部门', '人员', '角色']);
      const userData = data.filter((val) => val.orgType == 1);
      const departmentsData = data.filter((val) => val.orgType == 2);
      const rolesData = data.filter((val) => val.orgType == 3);

      setCurrentOptionalData({
        users: userData,
        departments: departmentsData,
        roles: rolesData,
      });
      setModalTitle('发起人');
    }
    (childRef?.current as any)?.openModal();
  };
  const removeOne = (e: Event, params: any, type) => {
    if (type === 1) {
      const departments = departmentData.filter((ele) => params.id != ele.id);
      setDepartmentData([...departments]);
      handleChange([...departments] || []);
    } else if (type == 2) {
      const userData = userRoleDepartData.filter((ele) => params.id != ele.id);
      setUserRoleDepartData([...userData]);
      handleChange([...userData] || []);
    } else {
      const initiatorDatas = initiatorData.filter((ele) => params.id != ele.id);
      setInitiatorData([...initiatorDatas]);
      handleChange([...initiatorDatas] || []);
    }

    e.preventDefault();
    e.stopPropagation();
  };
  // 改变数据
  const renderMain = (data: any) => {
    const { widget } = data;
    // 依次对应---部门，金额，数字，组织成员，单选，多选，
    // [
    //   'department',
    //   'money',
    //   'number',
    //   'innerContact',
    //   'select',
    //   'multiSelect',
    // ];

    let dom = null;
    switch (widget) {
      case 'department':
        dom = (
          <div
            className={cs(
              c.department,
              departmentData.length == 0 ? c.empty : '',
            )}
            onClick={() => openModel(1, departmentData)}
          >
            {departmentData.length == 0
              ? '请选择具体部门'
              : departmentData.map((ele: any) => {
                  return (
                    <span className={c.users} key={ele.id}>
                      {ele.name}{' '}
                      <CloseOutlined
                        onClick={(e) => removeOne(e, ele, 1)}
                        className={c.icon}
                      />
                    </span>
                  );
                })}
          </div>
        );
        break;
      case 'money':
      case 'number':
        dom = (
          <div
            className={cs(
              numOrMoneyType != 'bt' ? c.flexlayout : c.noFlexlayout,
            )}
          >
            <Select
              value={numOrMoneyType}
              className={c.select}
              style={{ width: '100%', marginRight: 10 }}
              onChange={(e) => handleSelect(e, 'numberOrMoney')}
            >
              <Option value="lt">小于</Option>
              <Option value="gt">大于</Option>
              <Option value="lte">小于等于</Option>
              <Option value="eq">等于</Option>
              <Option value="gte">大于等于</Option>
              <Option value="bt">介于（两个数之间）</Option>
            </Select>
            {numOrMoneyType != 'bt' ? (
              <InputNumber
                placeholder="请输入数字"
                style={{ width: '100%' }}
                value={beginValue}
                onChange={(value) =>
                  handleChange(value, 'numberOrMoney', 'min')
                }
              />
            ) : (
              <div className={c.flexlayout}>
                <InputNumber
                  placeholder="请输入数字"
                  value={beginValue}
                  style={{ width: '100%' }}
                  onChange={(value) =>
                    handleChange(value, 'numberOrMoney', 'min')
                  }
                />
                <div className={c.line}>&nbsp;</div>
                <InputNumber
                  placeholder="请输入数字"
                  value={endValue}
                  style={{ width: '100%' }}
                  onChange={(value) =>
                    handleChange(value, 'numberOrMoney', 'max')
                  }
                />
              </div>
            )}
          </div>
        );
        break;
      case 'innerContact':
        dom = (
          <div
            className={cs(
              c.innerContact,
              userRoleDepartData.length == 0 ? c.empty : '',
            )}
            onClick={() => openModel(2, userRoleDepartData)}
          >
            {userRoleDepartData.length == 0
              ? '请选择具体人员'
              : userRoleDepartData.map((ele: any, index: number) => {
                  return (
                    <span className={c.users} key={index}>
                      {ele.name}{' '}
                      <CloseOutlined
                        onClick={(e) => removeOne(e, ele, 2)}
                        className={c.icon}
                      />
                    </span>
                  );
                })}
          </div>
        );
        break;
      case 'initiator':
        dom = (
          <div
            className={cs(c.member, initiatorData.length == 0 ? c.empty : '')}
            onClick={() => openModel(3, initiatorData)}
          >
            {initiatorData.length == 0
              ? '请选择具体人员/角色/部门'
              : initiatorData.map((ele: any) => {
                  return (
                    <span className={c.users} key={ele.id}>
                      {ele.name}{' '}
                      <CloseOutlined
                        onClick={(e) => removeOne(e, ele, 3)}
                        className={c.icon}
                      />
                    </span>
                  );
                })}
          </div>
        );
        break;
      case 'select':
        dom = (
          <Group
            value={checkValue}
            onChange={(e) => {
              handleChange(e, 'select');
              onChange(e);
            }}
          >
            {options.map((val: any) => {
              return (
                <Checkbox value={val.value} key={val.value}>
                  {val.title}
                </Checkbox>
              );
            })}
          </Group>
        );
        break;
      case 'multiSelect':
        dom = (
          <div className={c.flexlayout}>
            <Select
              defaultValue="1"
              style={{ width: '100%', marginRight: 10 }}
              value={multiSelectType}
              onChange={(e) => handleSelect(e, 'multiSelect')}
            >
              <Option value="1">等于</Option>
              <Option value="2">属于</Option>
            </Select>
            <Select
              mode="multiple"
              placeholder="请选择"
              // defaultValue={checkValue}
              value={checkValue ?? []}
              onChange={(e) => handleChange(e, 'multiSelect')}
              style={{ width: '100%' }}
              listHeight={options.length ? options.length * 36 : 256}
            >
              {options.map((val: any) => {
                return (
                  <Option value={val.value} key={val.value}>
                    {val.title}
                  </Option>
                );
              })}
            </Select>
          </div>
        );
        break;
      default:
        dom = <span>无匹配组件</span>;
        break;
    }
    return dom;
  };

  // 删除
  const removeConResult = () => {
    deleteCon();
  };

  return (
    <>
      <MultipleChoice
        cRef={childRef}
        multiple={true}
        getDataBack={getDataBack}
        initData={currentOptionalData}
        tabs={openTabs}
        title={`选择${modalTitle}`}
      />

      <div className={c.fromItem}>
        <div className={c.fromItemLeft}>{initData.title}</div>
        <div className={c.fromItemCenter}>{renderMain(initData)}</div>
        <div className={c.fromItemRight}>
          <IconFont
            onClick={() => removeConResult()}
            className={c.icon}
            type="icon-shanchu"
          />
        </div>
      </div>
    </>
  );
};

export default ConditionItem;
