/*
 * :file description:
 * :name: \commond:\progree\low-code-platform\src\pages\generator\process\constants.ts
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-04 11:04:33
 * :last editor: hyw
 * :date last edited: 2022-01-05 14:16:34
 */
const newVersionDefaultValue: any = {
  processConfig: {
    globalSetting: {
      actionFindNotAssignee: '1', //审核人为空时
      approvalType: '2', //多人审批时采用的审批方式
      isAutoPassWhenRepeated: false,
      isRevokeProcess: false,
      requiredComment: false,
      canAppendTask: false,
    },
    processDefinition: {
      children: [
        {
          children: null,
          componentName: 'ApplyNode',
          props: {
            approverRules: {
              // 发起人默认不修改
              approvals: [
                // {
                //   id: '',
                //   label: '发起人',
                //   type: 'initiator',
                // },
              ],
            },
            formConfig: {
              behaviorList: [],
            },
            name: '发起人',
            nodeName: 'ApplyNode',
          },
          title: '发起人',
        },
      ],
      processKey: '',
      processName: '',
    },
  },
};

export { newVersionDefaultValue };
