/*
 * :file description:
 * :name: /low-code-platform/src/components/FormGenerator/components/Sidebar/index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 21:09:05
 * :last editor: caihua
 * :date last edited: 2022-12-13 09:47:24
 */
import React, { useEffect, useState, useMemo } from 'react';
import { Radio, Spin, RadioChangeEvent, message, Input } from 'antd';
import { defaultSettings, relationSettings } from '../../settings';
import { useStore, useGlobal } from '../../utils/hooks';
import IconFont from '@/components/IconFont';
import deepClone from 'clone';
import {
  combinationRelation,
  combinationOaRelation,
  filterSettingsByTypes,
} from '../../utils';
import Element, { WidgetType } from './Element';
import './index.less';
import {
  getVisibleFormList,
  getOaSchemaList,
  formQuotaQuery,
  getIsIntegrationApp,
} from '@/services/form-generator';
import { useLocation, useModel } from 'umi';
import { editType } from '@/pages/generator/form';
import { userAgentObj, getUrlParam } from '@/utils';
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';
// 关联表单自己可见 灰度
import { useGray } from '@/pages/generator/relation/hooks';

const Sidebar = (props) => {
  // 关联表单自己可见 灰度
  const isShowFormRelationSelfView = useGray('form_relation_self_view');

  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { formCode, type } = location?.query || {};
  const { userProps = {}, widgetType } = useStore();
  const combineRelation = combinationRelation();
  const combineOaRelation = combinationOaRelation();
  const setGlobal = useGlobal();
  const { settings } = userProps;
  const source = location?.query?.source;
  let _settings = Array.isArray(settings) ? settings : defaultSettings;
  _settings = filterSettingsByTypes(
    _settings,
    source === 'CloudPlatform' ? 'CloudPlatform' : 'CRM',
  );

  const initialSettingMap = new Map([
    [WidgetType.Base, _settings],
    [WidgetType.Relation, []],
  ]);
  const [settingMap, setSettingMap] = useState(initialSettingMap);

  const [keyword, setKeyword] = useState('');

  const onChange = (e: RadioChangeEvent) => {
    setGlobal({ widgetType: e.target.value });
    setKeyword('');
  };

  const { updateFormList } = useModel('useGenerator', (model) => model);

  const getRelationSchema = async () => {
    try {
      setLoading(true);
      const data = await getVisibleFormList();
      if (data) {
        updateFormList(data.data);
        return data.data;
      }
    } catch (error) {
      // message.error('');
    } finally {
      setLoading(false);
    }
  };

  const getOaRelationSchema = async () => {
    try {
      setLoading(true);
      const data = await getOaSchemaList();
      if (data) {
        return data.data;
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  // type 不存在时 把个人表和企业表合成客户表 并 删除个人表和企业表
  // type 为 1 时 把个人表和企业表合成客户表 并 保留个人表和企业表
  const combieClient = (data, type?: number) => {
    const clientData =
      data.widgets &&
      data.widgets.filter(
        (val) =>
          val.bizType == 'crm_customer' ||
          val.bizType == 'crm_customer_personal',
      );

    let noClientData = data.widgets;

    if (!type) {
      noClientData =
        data.widgets &&
        data.widgets.filter(
          (val) =>
            !(
              val.bizType == 'crm_customer' ||
              val.bizType == 'crm_customer_personal'
            ),
        );
    }
    const client = {
      title: '客户',
      text: '客户',
      type: 'string',
      required: false,
      widget: 'relation',
      bizAlias: 'contract_related_customer',
      relateBizType: 'crm_customer_all',
      quote: 1,
      icon: 'icon-biaodan_baogao',
      iconColor: '#0089FF',
      name: 'relation',
      schema: {
        title: '客户',
        type: 'object',
        widget: 'relation',
        twinWidget: 'tableRelation',
        fields: {},
        relateSource: [],
      },
    };
    clientData &&
      clientData?.forEach((ele) => {
        if (ele.bizType === 'crm_customer') {
          console.log(ele, '8888');
          client.schema.relateSource.unshift({
            bizType: ele.bizType,
            dataSource: ele.schema.dataSource,
            fields: {},
          });
        } else {
          client.schema.relateSource.push({
            bizType: ele.bizType,
            dataSource: ele.schema.dataSource,
            fields: {},
          });
        }
      });

    return [
      {
        ...data,
        widgets: client.schema.relateSource?.length
          ? [client, ...noClientData]
          : [...noClientData],
      },
    ];
  };

  const getformQuotaQuery = () => {
    formQuotaQuery({
      corpId: window.__corpId,
    }).then((res: any) => {
      const formFieldLimit = res?.result?.formFieldLimit;
      setGlobal({ formMunberLimit: formFieldLimit });
    });
  };

  const initSetting = async () => {
    let userAgent = userAgentObj();
    // 先判断下是否为一方的crm
    let isDDApp;
    let CRMIndependence = getUrlParam('CRMIndependence');
    if (userAgent.isDingTalk && CRMIndependence !== 'true') {
      const res = await getIsIntegrationApp();
      isDDApp = res?.result;
    }
    let schema = await getRelationSchema();
    let oaSchema = null;
    let relationOaSettings = [];

    if (type === editType.EDIT && !isShowFormRelationSelfView) {
      // 关联表单排除自己
      schema = schema?.filter((item) => item.formCode !== formCode);
    }
    if (userAgent.isDingTalk) {
      oaSchema = await getOaRelationSchema();
      relationOaSettings = combineOaRelation(oaSchema);
      getformQuotaQuery();
    }

    // 自定义表单
    // const customizeData = schema?.filter((ele) => ele?.source === 0);

    const sourceHash = {
      erpData: {
        cloudPlatform: [0, 8],
        dingding: [],
      },
      crmData: {
        cloudPlatform: [1, 3],
        dingding: [0, 1, 3, 10],
      },
    };
    const platform =
      getUrlParam('source') === 'CloudPlatform' ? 'cloudPlatform' : 'dingding';

    // 探迹 ERP 0 为自定义表单，8 为ERP 定制表单
    const erpData = schema?.filter((ele) =>
      sourceHash.erpData[platform].includes(ele?.source),
    );

    // 探迹CRM (将自定义和探迹CRM放在一起)
    const crmData = schema?.filter((ele) =>
      sourceHash.crmData[platform].includes(ele?.source),
    );

    const invoicingData = schema?.filter((ele) => ele?.source === 6) || []; // 进销存类型表单
    const v1688Data = schema?.filter((ele) => ele?.source === 11) || []; // 1688表单

    erpData?.sort((a, b) => {
      if (a.source === 8) return -1;
      else return 1;
    });
    crmData?.sort((a, b) => {
      if (a.source === 1) {
        return -1;
      }
      if (a.source === 0) {
        return 0;
      }
      if (a.source === 3) {
        return 1;
      }
    });

    if (isOpenInTripartitePlatform('1688')) {
      crmData?.push(...v1688Data);
    }
    crmData?.push(...invoicingData);
    // 客户管理数据
    const clientData = schema?.filter((ele) => ele?.source === 2);

    // crm自定义数据

    // let combineCustomizeData = [],
    let combineCrmData = [],
      combineClientData = [],
      combineErpData = [];
    // source 分组：2 客户管理，1 探迹CRM，0 自定义表单，4 OA审批 5 探迹ERP
    // if (customizeData?.length) {
    //   combineCustomizeData = combineRelation(customizeData, 0);
    // }

    if (erpData?.length) {
      combineErpData = combineRelation(erpData, 5);
    }

    if (crmData?.length) {
      combineCrmData = combineRelation(crmData, isDDApp ? 2 : 1);
      if (isDDApp) {
        let _combineClientData = combineRelation(clientData, 2);
        _combineClientData = combieClient(_combineClientData[0]);
        combineCrmData = combineCrmData.map((item) => {
          if (item.title === '客户管理') {
            return {
              ...item,
              widgets: _combineClientData[0].widgets.concat(item.widgets),
            };
          }
        });
      }
    }
    if (clientData?.length && !isDDApp) {
      combineClientData = combineRelation(clientData, 2);
      // 将企业客户和个人客户的数据拿出来并且合并在一起
      combineClientData = combieClient(combineClientData[0]);
    }
    // const relationSettings = combineRelation(schema);

    if (combineCrmData?.length && !isDDApp) {
      // 将企业客户和个人客户的数据拿出来并且合并在一起
      combineCrmData = combieClient(combineCrmData[0], 1);
    }

    setGlobal({ relationSchema: schema });
    setSettingMap(
      new Map([
        [WidgetType.Base, _settings],
        [
          WidgetType.Relation,
          userAgent.isDingTalk
            ? [
                // ...combineCustomizeData,
                ...combineCrmData,
                ...combineClientData,
                ...relationOaSettings,
              ]
            : [
                // ...combineCustomizeData,
                ...combineErpData,
                ...combineCrmData,
                ...combineClientData,
              ],
        ],
      ]),
    );
  };

  useEffect(() => {
    initSetting();
  }, [widgetType]);

  return (
    <>
      {useMemo(() => {
        return (
          <div className="left-layout">
            <Radio.Group
              className="radio-wrap"
              onChange={onChange}
              defaultValue={WidgetType.Base}
            >
              <Radio.Button value={WidgetType.Base}>控件库</Radio.Button>
              <Radio.Button value={WidgetType.Relation}>关联表单</Radio.Button>
            </Radio.Group>
            <Spin spinning={widgetType === WidgetType.Relation && loading}>
              <div className="widget-wrap">
                {widgetType === WidgetType.Relation && (
                  <div className="widget-title">
                    <Input
                      className="widget-title-search"
                      prefix={
                        <IconFont
                          type="icon-sousuo"
                          className="widget-title-searchIcon"
                        />
                      }
                      placeholder="输入关键字"
                      allowClear
                      value={keyword}
                      onChange={(e) => setKeyword(e.target.value)}
                    />
                  </div>
                )}
                {console.log(
                  settingMap.get(widgetType),
                  'settingMap.get(widgetType)',
                )}
                {Array.isArray(settingMap.get(widgetType)) ? (
                  settingMap.get(widgetType).map((item, idx) => {
                    if (item && item.show === false) {
                      return null;
                    }
                    return (
                      <div className="widget-class-wrap" key={idx}>
                        <p className="f6 b title-row">{item.title}</p>
                        <ul className="pl0">
                          {Array.isArray(item.widgets) ? (
                            item.widgets.map((widget, idx) => {
                              if (
                                keyword &&
                                widget?.text?.indexOf(keyword) === -1
                              ) {
                                return null;
                              }
                              return (
                                <Element
                                  key={idx.toString()}
                                  {...props}
                                  {...widget}
                                />
                              );
                            })
                          ) : (
                            <div>无OA审批表单权限</div>
                          )}
                        </ul>
                      </div>
                    );
                  })
                ) : (
                  <div>配置错误：Setting不是数组</div>
                )}
              </div>
            </Spin>
          </div>
        );
      }, [loading, keyword, widgetType])}
    </>
  );
};

export default Sidebar;
