import { FC } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input/TextArea';

type IProps = TextAreaProps & { [key: string]: any };

const TextAreaWidget: FC<IProps> = (props: IProps) => {
  const { value, schema, onChange = () => {} } = props;

  const { placeholder } = schema;

  const handleChange = (e: string | number) => {
    onChange(e);
  };

  if (props.readOnly) {
    return <span className="showNoEditText">{props.value || ''}</span>;
  }

  return (
    <Input.TextArea
      style={{ width: '100%' }}
      value={value}
      onChange={(e) => handleChange(e)}
      placeholder={placeholder}
      maxLength={8000}
    />
  );
};

export default TextAreaWidget;
