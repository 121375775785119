/*
 * @Author: wu.shangru roywu@tungee.com
 * @Date: 2023-04-23 19:00:08
 * @LastEditors: wu.shangru roywu@tungee.com
 * @LastEditTime: 2023-09-12 14:29:27
 * @FilePath: \low-code-platform\src\hooks\formula.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * :file description:
 * :name: \low-code-platform\src\hooks\formula.ts
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-19 09:38:38
 * :last editor: 黄艺
 * :date last edited: 2022-03-21 18:17:42
 */

import { _calFormula } from '@/widgets/DefaultValueSelector/components/formula-editor/common/formulaEditor';
import { flattenSchema } from 'form-render/es/form-render-core/src/utils';

import { useEffect, useRef } from 'react';

const PREFIX = '_widget_';

// 通过判断flatten的key值是否含有"[]"来判断数组
const isArrayWidget = (flattenKey: string) => {
  return /\[\]/.test(flattenKey);
};

const pathToFlattenKey = (path: string) => {
  return path.replace(/\[(.*)\]/, '[]');
};

const pathNoIndex = (path: string) => {
  return path.replace(/\[(.*)\]/, '');
};

const flattenKeyToPath = (flattenKey: string, index: number) => {
  return flattenKey.replace(/\[\]/, `[${index}]`);
};

export function useFormula(form) {
  const ref = useRef<string>();

  const listenForm = (form) => {
    setTimeout(() => {
      if (Array.isArray(Object.values(ref.current || {})?.[0])) {
        return;
      }
      const formFlatten = (form.schema && flattenSchema(form.schema)) || {};

      Object.keys(formFlatten)?.forEach((child) => {
        const schema = form.getSchemaByPath(child);
        const { relyWidgets = [] } = schema?.rely || {};
        const { defaultType } = schema;
        const relyWidgetsNoPrefix = relyWidgets.map((item: string) =>
          item.replace(PREFIX, ''),
        );
        const currentWidget = pathNoIndex(
          Object.keys(ref.current || {}).pop() || '',
        );
        if (relyWidgetsNoPrefix.includes(currentWidget)) {
          // 只有当前方式为自定义时才使用公式更新
          if (defaultType == 'formula') {
            if (isArrayWidget(child)) {
              const key = formFlatten[child].parent.replace(/\[\]/, '');
              const tableData = form.formData?.[key] || [{ [child]: '' }];
              tableData?.forEach((_item: any, index: number) => {
                form.setValueByPath(
                  flattenKeyToPath(child, index),
                  _calFormula(schema?.rely.formula, undefined, form, index),
                );
              });
            } else {
              form.setValueByPath(
                child,
                _calFormula(schema?.rely.formula, undefined, form),
              );
            }
          }
        }

        // if (
        //   schema?.rely &&
        //   (!ref.current ||
        //     pathToFlattenKey(Object.keys(ref.current)[0]) !== schema.$id)
        // ) {
        // if()
        // if (isArrayWidget(child)) {
        //   const key = formFlatten[child].parent.replace(/\[\]/, '');
        //   const tableData = form.formData?.[key] || [{ [child]: '' }];
        //   tableData.forEach((_item, index) => {
        //     form.setValueByPath(
        //       flattenKeyToPath(child, index),
        //       _calFormula(schema?.rely.formula, undefined, form),
        //     );
        //   });
        // } else {
        //   form.setValueByPath(
        //     child,
        //     _calFormula(schema?.rely.formula, undefined, form),
        //   );
        // }
        // }
      });
    });
  };

  useEffect(() => {
    listenForm(form);
  }, [JSON.stringify(form.formData)]);

  return [ref];
}
