/*
 * :file description:
 * :name: \low-code-platform\src\widgets\DefaultValueSelector\components\formula-editor\CardTitle.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-09 17:35:44
 * :last editor: 黄艺
 * :date last edited: 2021-12-09 18:48:49
 */
import { Card, Row, Col, Collapse, Input } from 'antd';
import formulaUsage from './common/formulaUsage';
import c from '../../index.less';
import IconFont from '@/components/IconFont';
import { ChangeEventHandler, FC } from 'react';
import { IUsage } from './Formula';

export interface IResult {
  key: any;
  other: string;
  item: IUsage;
}

interface IProps {
  title: string;
  onChange: ChangeEventHandler | undefined;
}

const CardTitle: FC<IProps> = ({ title, onChange }) => {
  return (
    <Input
      className={c.search}
      prefix={<IconFont className={c.search_icon} type="icon-sousuo" />}
      placeholder={title}
      onChange={onChange}
      addonAfter={false}
      allowClear
    />
  );
};

export default CardTitle;
