/*
 * :file description:
 * :name: /low-code-platform/tg-renderer/packages/form-render/src/widgets/DataSummary/index.tsx
 * :author: 杨海涛
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-07-28 17:41:52
 * :last editor: 肖国同
 * :date last edited: 2023-02-16 16:18:36
 */
import { Popover, Select, message } from 'antd';
import IconFont from '@/components/IconFont';
import c from './index.less';
import { useEffect, useMemo, useState } from 'react';
import popoverImg from '../../assets/form/data-summary-popover.jpg';
import DataSummarySetting from './DataSummarySetting';
import { useModel } from 'umi';
import {
  BIZ_FILTER_SUMMARY,
  BIZ_SUMMARY_LIST,
} from '~@/packages/form-render/src/constant';

export enum SummaryType {
  // 0 当前页数据
  DEFAULT,
  // 1 所有数据
  CUSTOM,
}

const DataSummary = (props) => {
  const { onChange, value } = props;
  console.log('value11', value);
  const [visible, setVisible] = useState(false);
  const model = useModel('useGenerator', (model) => model);
  const { schemaRef, relatedFormRef } = model;
  const properties = schemaRef?.current?.properties || {};
  console.log('properties', properties);
  console.log('props', props);

  // const relatedFormInfos = relatedFormRef.current || [];

  // let list = ['contract_amount'] as string[];
  // // 数据关联需要在后端过滤，满足现有需求，暂定前端写死处理
  // BIZ_SUMMARY_LIST.forEach((key) => {
  //   if (!!relatedFormInfos.filter((item) => item.bizType === key).length) {
  //     list = list.concat(BIZ_FILTER_SUMMARY[key]);
  //   }
  // });

  // 使用范围扩充到table下面数字金额控件
  const allPropertiesMap = useMemo(() => {
    const map = new Map();
    Object.keys(properties)?.forEach((key) => {
      const item = properties?.[key];
      if (item.widget === 'table') {
        Object.values(item?.items.properties ?? {}).forEach(
          (childItem: any) => {
            if (['money', 'number'].includes(childItem.widget)) {
              map.set(`${key}/${childItem.id}`, childItem);
            }
          },
        );
      } else if (['money', 'number'].includes(item.widget)) {
        map.set(key, item);
      }
    });
    return map;
  }, [properties]);

  // const allProperties = {} as { [key: string]: any };
  // Object.keys(properties).forEach((key) => {
  //   const item = properties[key];
  //   if (['money', 'number'].includes(item.widget)) {
  //     allProperties[key] = item;
  //   }
  // });

  // console.log('---allProperties');
  // console.log(allProperties, 'allProperties');

  // 控件被删除，自动校验控件是否被选中，移除并触发onChange
  useEffect(() => {
    const collect = new Set();

    Object.keys(properties)?.forEach((key) => {
      if (properties[key].widget === 'table') {
        Object.keys(properties[key]?.items?.properties ?? {}).forEach(
          (childKey) => {
            collect.add(`${key}/${childKey}`);
          },
        );
      } else {
        collect.add(key);
      }
    });
    let hasDelete = false;
    const _propsValue: {
      id: string;
      operateType: string;
      title: string;
    }[] = [];

    value?.propsValue?.fields?.forEach(
      (item: { id: string; operateType: string; title: string }) => {
        if (!collect.has(item.id)) {
          hasDelete = true;
        } else {
          _propsValue.push(item);
        }
      },
    );
    if (hasDelete) {
      onChange({
        propsKey: 'dataAggregation',
        propsValue: value?.propsValue
          ? { ...value?.propsValue, fields: _propsValue }
          : {
              rangeType: SummaryType.DEFAULT,
              fields: _propsValue,
            },
        settingType: 1,
      });
    }
  }, [properties]);

  const content = () => {
    // CRM2.2需求，文案内容最好不要携带含业务相关文案，不利于以后扩展，目前汇总仅支持合同@邵博
    return (
      <div className={c.popover}>
        <div className={c['popover-desc']}>
          数据统计功能是为了方便阅读合同时，可以直接看到合同相关金额的汇总，若统计列表所有数据，最多统计前
          1 万条。示例如下：
        </div>
        <img src={popoverImg} alt="" />
      </div>
    );
  };

  const onOk = (values) => {
    console.log('values', values);
    if (
      SummaryType.CUSTOM === value?.propsValue?.rangeType &&
      values?.length > 20
    ) {
      message.warning('最多选 20 个指标');
      return;
    }
    const tempValue = (values || []).map((item) => ({
      id: item.id,
      operateType: 'SUM',
      title: item.label,
    }));
    onChange({
      propsKey: 'dataAggregation',
      propsValue: {
        rangeType: value?.propsValue?.rangeType,
        fields: tempValue,
      },
      settingType: 1,
    });
    setVisible(false);
  };

  const handleTypeChange = (type: SummaryType) => {
    console.log('value', value);
    onChange({
      propsKey: 'dataAggregation',
      propsValue: {
        rangeType: type,
        fields:
          type === SummaryType.CUSTOM ? [] : value?.propsValue?.fields || [],
      },
      settingType: 1,
    });
  };

  return (
    <div className={c.container}>
      <div className={c.title}>
        数据统计
        <Popover placement="bottom" content={content} trigger="hover">
          <IconFont type="icon-shuoming" className={c['info-icon']}></IconFont>
        </Popover>
      </div>

      <div
        className={c.wrap}
        onClick={() => {
          setVisible(true);
        }}
      >
        {!value?.propsValue?.fields?.length && (
          <div className={c.text}>请选择数据统计</div>
        )}
        {!!value?.propsValue?.fields?.length &&
          value.propsValue?.fields?.map((item) => {
            if (!allPropertiesMap.has(item.id)) {
              return null;
            }
            return (
              <div className={c.item} key={item.id}>
                {allPropertiesMap.get(item.id).title}
              </div>
            );
          })}
      </div>

      <Select
        style={{ width: '100%', marginTop: 16 }}
        value={value?.propsValue?.rangeType}
        defaultValue={SummaryType.DEFAULT}
        onChange={(e) => handleTypeChange(e)}
      >
        <Select.Option value={SummaryType.DEFAULT}>
          仅统计当前页数据
        </Select.Option>
        <Select.Option value={SummaryType.CUSTOM}>
          统计列表所有数据
        </Select.Option>
      </Select>

      <DataSummarySetting
        visible={visible}
        initValue={value}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={onOk}
      />
    </div>
  );
};

export default DataSummary;
