/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\TableListInstance\renderCol.js
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-13 15:11:40
 * :last editor: hyw
 * :date last edited: 2022-02-22 20:11:27
 */
import { Tag, Tooltip } from 'antd';
import { getFileIcon } from '../EditAttachment/utils';

export const customJP = (data) => {
  if (!data || typeof data !== 'string') {
    return data;
  }
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
};

export const customRender = (type, text, record, index) => {
  let colRender = null;
  let value = null;
  try {
    switch (type) {
      case 'relation':
        colRender = (
          <Tag
            style={{
              padding: '4px 8px',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              console.log(e);
            }}
          >
            {typeof text === 'string' ? customJP(text)?.[0] : text?.title}
          </Tag>
        );
        break;
      case 'phone':
        if (typeof text === 'object') {
          value = customJP(text);
        }
        colRender = (
          <span>
            {typeof text === 'object'
              ? value?.value
                ? `${value?.countryCode}-${value?.value}`
                : ''
              : text}
          </span>
        );
        break;
      case 'picture':
        colRender = (
          <>
            {text?.length
              ? text?.map((img, idx) => (
                  <img
                    key={`${img}_${idx}`}
                    width="30"
                    height="30"
                    style={{
                      borderRadius: '4px',
                      marginRight: '5px',
                      cursor: 'pointer',
                    }}
                    src={img}
                  />
                ))
              : null}
          </>
        );
        break;
      case 'attachment':
        colRender = (
          <>
            {text?.length
              ? text?.map((img, idx) => (
                  <img
                    key={`${img}_${idx}`}
                    width="28"
                    height="32"
                    style={{
                      borderRadius: '4px',
                      marginRight: '5px',
                      cursor: 'pointer',
                    }}
                    src={getFileIcon(img?.type)}
                  />
                ))
              : null}
          </>
        );
        break;
      case 'address':
        let address = null;
        if (typeof text === 'object') {
          value = customJP(text);
          address = value?.address?.join('');
        }
        colRender = (
          <>
            {address}
            {value?.street}
            {value?.detail}
          </>
        );
        break;

      case 'select':
        colRender = <>{text?.value}</>;
        break;
      case 'multiSelect':
      case 'innerContact':
      case 'department':
        let multiSelectValue = text && text.map((val) => val.value);
        colRender = <>{multiSelectValue?.join(',')}</>;
        break;
      case 'dateRange':
        colRender = <>{text?.join('-')}</>;
        break;
      // case 'nodeOfTable':
      //   colRender = (
      //     <>
      //       {text && Array.isArray(text)
      //         ? text.map((c, idx) => <div key={`${c}_${idx}`}>{c}</div>)
      //         : null}
      //     </>
      //   );
      //   break;
      default:
        colRender = text ? (
          <Tooltip
            placement="top"
            color="white"
            overlayInnerStyle={{ color: '#000' }}
            title={text}
          >
            <div
              style={{
                width: '162px',
                maxWidth: '162px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {text}
            </div>
          </Tooltip>
        ) : null;
    }
  } catch (error) {
    console.log(55554444, error);
  }
  return colRender;
};
