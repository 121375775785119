/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\Money\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 14:28:41
 * :last editor: hyw
 * :date last edited: 2022-02-24 11:34:55
 */
import { InputNumber } from 'antd';
import { FC } from 'react';
import { WidgetProps } from 'typings';
import c from './index.less';
const nzhcn = require('nzh/cn');

const MoneyWidget: FC<WidgetProps<number>> = (props) => {
  const { limitDot, upper, placeholder } = props?.schema;
  const { value, onChange, hideupper } = props;

  const limitDecimals = (value?: string | number): string => {
    const regStr = `/^(\\-)*(\\d+)\\.(\\d{0,${limitDot || 0}}).*$/`;
    const reg = eval(regStr);

    if (typeof value === 'string') {
      return !isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : '';
    } else if (typeof value === 'number') {
      return !isNaN(value) ? String(value).replace(reg, '$1$2.$3') : '';
    } else {
      return '';
    }
  };

  if (props.readOnly) {
    return <span className="showNoEditText">{props?.value || ''}</span>;
  }

  return (
    <div className={c.container}>
      <InputNumber
        style={{ width: '100%' }}
        value={value}
        onChange={(e) => {
          onChange && onChange(e);
        }}
        formatter={limitDecimals}
        parser={limitDecimals}
        placeholder={placeholder}
      />
      {upper && !hideupper && (
        <>
          <span className={c.big}>大写</span>
          <div>
            {value &&
              nzhcn.toMoney(Number(value), {
                outSymbol: false,
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default MoneyWidget;
