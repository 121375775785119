/*
 * @Author: 刘昕 xinlx@tungee.com
 * @Date: 2022-10-17 11:08:01
 * @LastEditors: wu.shangru
 * @LastEditTime: 2023-12-20 13:41:57
 * @FilePath: /low-code-platform/src/pages/generator/relation/constants.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { showDescriptionModal } from './components/Description';

export enum HeaderType {
  BUSINESS = '/generator/relation/business',
  SUBMIT = '/generator/relation/submit',
  DATACONNECTOR = '/generator/relation/dataConnector',
  PRINT = '/generator/relation/print',
  DUPLICATED = '/generator/relation/duplicated',
  SHAREDATA = '/generator/relation/shareData',
}

const Business = HeaderType.BUSINESS;
const Submit = HeaderType.SUBMIT;
const DataConnector = HeaderType.DATACONNECTOR;
const Print = HeaderType.PRINT;
const Duplicated = HeaderType.DUPLICATED;
const ShareData = HeaderType.SHAREDATA;

export const HEADER_DESCRIPTION = {
  [Business]: {
    title: '业务关联',
    subTitle: '实现一张表单新增或修改数据后，同步新增或修改另一张表单的数据',
    tooltip: () => showDescriptionModal('business'),
  },
  [Submit]: {
    title: '表单校验',
    subTitle: '实现提交表单时，满足校验规则的数据才允许提交',
    tooltip: () => showDescriptionModal('submit'),
  },
  [DataConnector]: {
    title: '数据推送',
    subTitle: 'CRM表单事件触发，将数据推送到三方系统',
    tooltip: () => null,
  },
  [Print]: {
    title: '打印模板',
    subTitle: ' ',
    tooltip: () => showDescriptionModal('print'),
  },
  [Duplicated]: {
    title: '查重设置',
    subTitle: '实现新建/编辑表单时，不与全公司已有数据重复的数据才允许保存',
    tooltip: () => null,
  },
  [ShareData]: {
    title: '团队数据共享',
    subTitle: '可以将部分符合条件的数据共享给业务协助团队',
    tooltip: () => null,
  },
};

export default [
  {
    title: '业务关联',
    icon: 'icon-liuzhuanjilu',
    key: 'business',
    path: '/generator/relation/business',
  },
  {
    title: '表单校验',
    icon: 'icon-renwuzhongxin',
    key: 'submit',
    path: '/generator/relation/submit',
  },
  {
    title: '打印模板',
    icon: 'dayin',
    key: 'print',
    path: '/generator/relation/print',
  },
  {
    title: '查重设置',
    icon: 'icon-search-line',
    key: 'duplicated',
    path: '/generator/relation/duplicated',
  },
  {
    title: '团队数据共享',
    icon: 'icon-group-icon',
    key: 'shareData',
    path: '/generator/relation/shareData',
  },
];
// {
//   title: '数据推送',
//   icon: 'guanlianzuzhi',
//   path: '/generator/relation/dataConnector',
// },

export const FREE_MENU_DATA = [
  'print',
  'duplicated',
  'business',
  'submit',
  'shareData',
];

export const BUSINESS_RELATION_MENUS = new Map([
  ['associationRules', 'business'],
  ['validators', 'submit'],
]);

export const MENU_DATA_MAP = {
  associationRules: 'business',
  duplicationRule: 'duplicated',
  validators: 'submit',
  dataSharing: 'shareData',
};

export const SYSTEM_FLOW_FIELDS = {
  lastProcessResult: {
    enum: ['1', '2', '3'],
    enumNames: ['已同意', '已撤回', '已拒绝'],
    items: {
      type: 'string',
    },
    placeholder: '请选择',
    title: '审批结果',
    type: 'string',
    widget: 'select',
  },
  lastProcessStatus: {
    enum: ['1', '2', '3'],
    enumNames: ['审批中', '审批完成', '审批终止'],
    items: {
      type: 'string',
    },
    placeholder: '请选择',
    title: '审批状态',
    type: 'string',
    widget: 'select',
  },
  // crm2.3 新增审批节点信息字段
  // historyApproveUser: {
  //   format: 'personSelector',
  //   title: '历史审核人',
  //   type: 'string',
  //   widget: 'personSelector',
  // },
  // currentApproveUser: {
  //   format: 'personSelector',
  //   title: '当前审核人',
  //   type: 'string',
  //   widget: 'personSelector',
  // },
  // currentNode: {
  //   title: '当前节点',
  //   type: 'string',
  //   widget: 'input',
  // },
};


/**
 * 版本信息
 */
export enum VERSION_TYPE {
  TRYOUT = -1, // 试用版
  FREE = 0, // 免费版
  STANDARD = 1, // 标准版
  PROFESSIONAL = 2, // 旗舰版
  ENTERPRISE = 3, // 企业版
}
