import React from 'react';
import Xss from 'xss';
import classnames from 'classnames';
import rangy from 'rangy';
import PropTypes from 'prop-types';
import c from './index.less';

// webkit browsers support 'plaintext-only'
const contentEditableValue = (() => {
  const div = document.createElement('div');
  div.setAttribute('contenteditable', 'PLAINTEXT-ONLY');
  return div.contentEditable === 'plaintext-only' ? 'plaintext-only' : true;
})();

interface IProps {
  className?: string;
  noAutoFocus?: boolean;
  value?: string;
  disableMention?: boolean;
}

export default class CommentEditor extends React.Component<IProps> {
  _bookmark = {};

  constructor(props: IProps) {
    super(props);
    this.state = {
      focus: true,
      // temporarily not supported to set initial value for caret problem
      // or just can be used as prop once
      value: props.value || '',
    };
  }

  componentDidMount() {
    rangy.init();
    let t = this;
    let editor = t.refs['editor'];
    let range = rangy.createRange();
    // save range position
    t._bookmark = range.getBookmark(editor);
    if (!t.props.noAutoFocus) {
      //新增输入框能力   页面初始时获取焦点
      let selection = rangy.getSelection();
      range.setStart(editor, 0);
      range.setEnd(editor, 0);
      selection.addRange(range);
      editor.focus();
    }
  }

  getValue() {
    return this.state.value || '';
  }

  onMention() {
    if (this.props.disableMention) {
      return;
    }
    let persons = [];

    window.__SelectPerson({
      title: '选择人员',
      axiosOptions: { baseURL: window.__TJ_CRM_DOMAIN },
      tabs: ['employee'],
      onOk: ({ selectedUsers }) => {
        console.log(selectedUsers);
        const data = selectedUsers.map((item) => ({
          id: item.dtalkUserId,
          emplId: item.dtalkUserId,
          name: item.name,
          avater: '',
        }));
        data?.forEach((item: any) => {
          item.id = item?.emplId;
          persons.push(item);
        });
        this.handleMentionAdd(persons);
        const { triggerMention } = this.props;
        triggerMention && triggerMention([...persons]);
      },
    });
  }

  _createMentionNode(persons) {
    let fragment = document.createDocumentFragment();
    persons = persons || [];
    persons.map((person) => {
      let mentionNode = document.createElement('input');
      mentionNode.setAttribute('type', 'button');
      mentionNode.setAttribute('data', JSON.stringify(person));
      mentionNode.setAttribute('tabindex', '-1');
      mentionNode.value = this.props.formatDisplay(person);
      fragment.appendChild(mentionNode);
    });
    fragment.appendChild(document.createTextNode(''));
    return fragment;
  }

  _setCaretToEnd() {
    let editor = this.refs.editor;
    let selection = rangy.getSelection();
    let range = rangy.createRange();
    range.selectNodeContents(editor);
    range.collapse(false);
    selection.removeAllRanges(); //remove any selections already made
    selection.addRange(range);
    return range;
  }

  handleMentionAdd = (persons) => {
    let range = rangy.createRange();

    // go to position to insert
    if (this._bookmark.containerNode.nodeType === Node.TEXT_NODE) {
      // only text element bookmark remember the right position
      range.moveToBookmark(this._bookmark);
    } else {
      // else to move caret to the end & use this range
      range = this._setCaretToEnd();
    }

    let mentionNodes = this._createMentionNode(persons);
    range.deleteContents(); // delete origin content in range like '@' or nothing
    let lastChild = mentionNodes.lastChild; // before insert
    range.insertNode(mentionNodes);

    // 1#reset _bookmark if not trigger blur
    // equal with range.setStartAfter(lastChild, 0);range.collapse(true);
    range.collapseAfter(lastChild);
    range.select();
    this._bookmark = range.getBookmark(range.commonAncestorContainer);
    //editor.blur(); // 1#trigger focus after select person in the extra modal
    this.emitChange();
  };

  emitChange = () => {
    let editor = this.refs.editor;

    let content = this.extractContents(editor);
    content = content.replace(/\n$/, '').replace(/\n\n/g, '\n'); //format
    this.setState({
      value: content,
    });
    this.props.onChange && this.props.onChange(content);

    // trigger @
    // in firfox 32 innerText is undefined
    let len = (editor.innerText || '').length;
    //let len = editor.innerHTML.length;
    let lastLen = this.totalLen;
    this.totalLen = len;
    if (lastLen && len < lastLen) {
      // delete action
      return;
    }
    let sel = rangy.getSelection();
    let range = sel.getRangeAt(0);
    if (range.commonAncestorContainer.nodeType === Node.TEXT_NODE) {
      range.setStart(range.commonAncestorContainer, 0);
      let originStr = range.toString();
      if (originStr.substr(-1, 1) === '@') {
        // set range's start position before choose contact
        range.setStart(range.commonAncestorContainer, originStr.length - 1);
        // save range position
        this._bookmark = range.getBookmark(range.commonAncestorContainer);
        this.onMention();
      }
    }
  };

  extractContents(editor) {
    editor = editor || this.refs.editor;
    let nodes = editor.childNodes;
    let content = '';
    if (nodes.length === 0) {
      return '';
    }
    for (let i = 0, len = nodes.length; i < len; i += 1) {
      let node = nodes[i];
      if (node.nodeType === Node.ELEMENT_NODE) {
        let tagName = node.tagName.toLowerCase();
        if (tagName === 'input') {
          // input element
          let item = JSON.parse(node.getAttribute('data'));
          content += this.props.formatValue(item);
        } else if (tagName === 'br') {
          content += '\n';
        } else {
          content += this.extractContents(node);
        }
      } else if (node.nodeType === Node.TEXT_NODE) {
        content += node.textContent || node.nodeValue;
      }
    }
    if (!content) {
      try {
        window.__WPO.log(['editor_content_null', editor.innerHTML]);
      } catch (e) {
        // do nothing
      }
    }
    return content;
  }

  /**
   * 清空文本框
   */
  clear = () => {
    const editor = this.refs.editor && this.refs.editor;
    if (!editor) {
      return;
    }
    editor.innerHTML = '';
    this.setState({
      value: '',
    });
  };

  /**
   * 聚焦文本框
   */
  focus = () => {
    this.setState({
      focus: true,
    });
    const editor = this.refs.editor && this.refs.editor;
    if (!editor) {
      return;
    }
    editor.focus();
  };

  onBlur = () => {
    this.setState({ focus: false });
    const { onBlur } = this.props;
    onBlur && onBlur();
  };

  onkeydown = (event) => {
    if (event.ctrlKey && event.keyCode == 13) {
      const { onCtlEnter } = this.props;
      onCtlEnter && onCtlEnter();
    }
  };

  render() {
    const _className = classnames({
      [c['commentEditor']]: true,
      [this.props.className]: !!this.props.className,
    });
    return (
      <div
        className={classnames(
          c['commentEditor'],
          c['mention-input'],
          c['needsclick'],
        )}
      >
        <div
          className={c['editor']}
          ref="editor"
          contentEditable={contentEditableValue}
          onClick={(e) => {
            e.target.focus();
          }}
          onKeyDown={this.onkeydown}
          style={{ height: this.props.height }}
          onInput={this.emitChange}
          onFocus={() => {
            this.setState({ focus: true });
            const { onFocus } = this.props;
            onFocus && onFocus();
          }}
          onBlur={this.onBlur}
        />
        {!this.state.value && (
          <div
            className={c['editorPlaceholder']}
            dangerouslySetInnerHTML={{ __html: Xss(this.props.placeholder) }}
          />
        )}
      </div>
    );
  }
}

CommentEditor.defaultProps = {
  formatDisplay: (person) => {
    return '@' + person.name;
  },
  formatValue: (person) => {
    return '[' + person.name + '](' + person.id + ')';
  },
  height: '36px',
};

// http://facebook.github.io/react/docs/reusable-components.html
CommentEditor.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  mentionText: PropTypes.string,
  onChange: PropTypes.func,
  formatDisplay: PropTypes.func,
  value: PropTypes.string,
  height: PropTypes.string,
};

CommentEditor.displayName = 'CommentEditor';
