import WechatCustomerInfoColumn from '@/pages/generator/relation/shareData/WidgetModal/WechatCustomerInfoColumn';
import TableCustomerTags from '@/pages/generator/relation/shareData/WidgetModal/TableCustomerTags';
import KpContact from '@/pages/generator/relation/shareData/WidgetModal/KpContact';
import { Button, Form, Tooltip } from 'antd';
import { compact, get, isArray, isString, keys, omit, omitBy } from 'lodash-es';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { isJSON, customJP } from '@/utils';
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';
import IconFont from '@/components/IconFont';
import { getCompanyId } from './api';
import fileExcel from '@/assets/form/file-excel.png';
import fileCommon from '@/assets/form/file-common.png';
import fileImg from '@/assets/form/file-img.png';
import filePpt from '@/assets/form/file-ppt.png';
import fileRar from '@/assets/form/file-rar.png';
import fileTxt from '@/assets/form/file-txt.png';
import fileVideo from '@/assets/form/file-video.png';
import fileWord from '@/assets/form/file-word.png';
import filePdf from '@/assets/form/file-txt.png';
import c from './index.less';

// 【合同表单】（预置的）
export const CONTRACT_CUSTOMER = {
  TAG: '经销',
  SUPERIOR: {
    // 上级客户
    BIZALIAS: 'contract_related_customer',
    ID: 'relation_K2U5O2WK',
  },
  SUBORDINATE: {
    // 关联下级
    BIZALIAS: 'contract_subordinate_customer',
    ID: 'table_contractRelationSubordinate',
    ITEM_ID: 'relation_subordinateCustomer', // 【客户名称】 id
  },
};

// 【企业客户】（预置的）
export const CUSTOMER = {
  TAG: '经销',
  SUBORDINATE: {
    // 下级客户
    BIZALIAS: 'subordinate_customer',
    ID: 'table_subordinateCustomer',
    ITEM_ID: 'relation_subordinateCustomer',
  },
};

const SENHE_GRAY_KRY = 'customer_relation_sub'; // 灰度key
let lock = false;
let _isSenhe = false;
export const isSenhe = () => {
  if (!lock) {
    lock = true;
    _isSenhe = window.__grayMap__?.get(SENHE_GRAY_KRY);
  }

  return _isSenhe;
};

export const CUSTOMER_BIZ_TYPE = ['crm_customer', 'crm_customer_personal'];
export const CRM_FOUR_BASE_BIZTYPE = [
  'crm_customer',
  'crm_customer_personal',
  'crm_follow_record',
  'crm_contact',
];
export function useDebounce(fn: Function, delay: number, dep = []) {
  const { current } = useRef({ fn, timer: null });
  useEffect(
    function () {
      current.fn = fn;
    },
    [fn],
  );

  return useCallback(function f(...args) {
    if (current.timer) {
      clearTimeout(current.timer);
    }
    current.timer = setTimeout(() => {
      current.fn.apply(undefined, args);
    }, delay);
  }, dep);
}
export const TABLE_CELL_BIZALIAS = ['customer_name', 'contact_name'];
export const PHONE_NAME_FIELD_BIZALIAS = {
  customer_phone: 'customer_name',
  contact_phone: 'contact_name',
};
// 支持排序的组件
const sortedSupportWidgets = {
  date: {
    sortable: true,
    sorter: () => {},
  },
  number: {
    sortable: true,
    sorter: () => {},
  },
  money: {
    sortable: true,
    sorter: () => {},
  },
  input: {
    sortable: true,
    sorter: () => {},
  },
  seqNumber: {
    sortable: true,
    sorter: () => {},
  },
  starRating: {
    sortable: true,
    sorter: () => {},
  },
};
export function getUuid() {
  /* jshint bitwise:false */
  let i;
  let random;
  let uuid = '';

  for (i = 0; i < 32; i++) {
    random = (Math.random() * 16) | 0; // eslint-disable-line
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '-';
    }
    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16); // eslint-disable-line
  }

  return uuid;
}
export const genImgAry = (val) => {
  if (typeof val === 'string') {
    let isArrayString = false;
    let urlArr = [];
    try {
      urlArr = JSON.parse(val);
      isArrayString = true;
    } catch (error) {
      isArrayString = false;
    }
    return isArrayString ? urlArr : val.split(',');
  }
  if (Array.isArray(val)) {
    return val;
  }
  return [];
};
export const getColorData = (obj: any) => {
  const arr = [];
  obj?.enum?.forEach((item, i) => {
    arr.push({
      key: item,
      value: obj?.enumNames[i],
      color: obj?.colorEnable
        ? obj?.enumAssociatedFieldsColors &&
          obj?.enumAssociatedFieldsColors?.[i]?.[0]
        : undefined,
    });
  });
  return arr;
};
/**
 * 判断是否在探迹云平台上打开
 */
export function isOpenInTungee() {
  return (
    window.__dd?.env.platform === 'notInDingTalk' &&
    getUrlParam('source') === 'CloudPlatform'
  );
}
/**
 * 判断是否开通拓客并且在可见范围内is_visible === true，在有效期内product_status === 1
 * @returns
 */
export const salesOpenOrVisible = () => {
  let productType = 1; // 独立版拓客为1，智能获客为41；
  const profile = JSON.parse(localStorage.getItem('cloud_profile') || '{}');
  if (isOpenInTungee()) {
    productType = 1;
  } else {
    productType = 41;
  }
  const { products } = profile;
  const salesInfo = (products || []).find(
    (c_m) => c_m.product_type === productType,
  ); // 是否存在拓客
  return salesInfo && salesInfo.is_visible && salesInfo.product_status === 1; // 是否存在拓客并在有效期内，且在可见范围内
};
/**
 * 返回详情页跳转地址
 * @param entityType 企业：enterprise；社会组织：npo；店铺：o2o；网店：eshop；
 * @param id 企业 id
 * @returns
 */
export const goSalesDetail = (entityType, id) => {
  let detailURL = '';
  const type = entityType || '';
  switch (type) {
    case 'tk_trans_customer_npo': {
      // 社会组织
      detailURL = `/npo-details/${id}/npo-information/basic-information`;
      break;
    }
    case 'tk_trans_customer_o2o': {
      // 店铺
      detailURL = `/shop-details/${id}/shop-information/basic-information`;
      break;
    }
    default:
      // 企业
      detailURL = `/enterprise-details/${id}/enterprise-information/basic-information`;
  }
  return detailURL;
};
export const newTab = (url = '') => {
  if (url) {
    // FIXME 判断跳转链接是否是相对路径
    const reg = /^(http|ftp|https|dingtalk):\/\/?/;
    const absolute = reg.test(url);
    let finalUrl = absolute ? url : location.origin + url;
    // FIXME 手工处理url,考虑场景较少
    const needNetTab = finalUrl?.includes('ddtab') ? '' : 'ddtab=true';
    if (needNetTab) {
      // finalUrl += finalUrl?.includes("?") ? "&" : "?" + needNetTab;
      finalUrl = `${finalUrl}${
        finalUrl?.includes('?') ? '&' : '?'
      }${needNetTab}`;
    }

    if (window.__dd?.pc) {
      // FIXME 解决百度收录的地址无法在钉钉中开tab跳转问题
      if (finalUrl?.includes('/link?url=')) {
        finalUrl += '&wd=';
      }
      window.__dd.biz.util.openLink({
        url: finalUrl, // 要打开链接的地址
      });
    } else {
      window.open(finalUrl);
    }
  }
};
/**
 * 企业详情跳转，独立版跳转pc拓客，自建客户跳转智能获客
 * @returns
 */
export const salesOpenLink = (companyId: string, type?: string) => {
  if (isOpenInTungee()) {
    const url = goSalesDetail(type, companyId);
    newTab(`${window.SALES_URL}${url}`);
  } else {
    localStorage.setItem('corpId', window.__corpId);
    localStorage.setItem('subscribeId', window.__subscribeId);
    newTab(
      `${window.IFRAME_URL}/tungee/marketing/enterprise-details/${companyId}/enterprise-information/basic-information`,
    );
  }
};
// 渲染企业详情图标
const RenderCompanyIcon = (props: any) => {
  const { record, bizAlias, config, name, bizType } = props;
  const { entityInfo, tk_input_intelligent_match_company_id } = record;
  const [companyId, setCompanyId] = useState('');
  const [loading, setLoading] = useState(false);

  const queryInfo = async () => {
    setLoading(true);
    const res = await getCompanyId(name);
    setLoading(false);
    setCompanyId(res.result ?? '');
  };

  useEffect(() => {
    if (
      tk_input_intelligent_match_company_id &&
      bizAlias === 'input_intelligent_match_company'
    ) {
      setCompanyId(tk_input_intelligent_match_company_id);
    }
  }, [tk_input_intelligent_match_company_id, bizAlias]);

  useEffect(() => {
    // 1688-智能匹配企业不需要去查询企业ID
    if (
      bizAlias === 'input_intelligent_match_company' &&
      isOpenInTripartitePlatform('1688')
    ) {
      return;
    }
    // 企业客户和联系人列表，对应bizAlias为客户/联系人才会触发，弹窗选客户联系人也不需要展示
    if (
      !entityInfo?.entityId &&
      ['crm_customer', 'crm_contact'].includes(bizType) &&
      ['customer_name', 'contact_related_customer'].includes(bizAlias)
    ) {
      queryInfo();
    }
  }, [bizAlias, entityInfo]);

  if (
    !['crm_customer', 'crm_contact'].includes(bizType) ||
    ![
      'customer_name',
      'contact_related_customer',
      'input_intelligent_match_company',
    ].includes(bizAlias) ||
    (!config?.setInformationVisible && !config?.setCompanyId) ||
    loading ||
    (!entityInfo?.entityId && !companyId)
  ) {
    return null;
  }

  return (
    <span
      style={{
        padding: '2px 6px',
        background: 'rgba(0,137,255,0.12)',
        color: '#007FFF',
        marginLeft: 6,
        borderRadius: 4,
        fontSize: 12,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
      }}
      onClick={async (e) => {
        if (
          bizAlias === 'input_intelligent_match_company' &&
          isOpenInTripartitePlatform('1688')
        ) {
          return;
        }
        e.stopPropagation();
        e.preventDefault();
        if (!salesOpenOrVisible()) {
          config?.setCompanyId(entityInfo?.entityId || companyId);
          config?.setInformationVisible?.(true);
        } else {
          salesOpenLink(entityInfo?.entityId || companyId, entityInfo?.type);
        }
      }}
    >
      <IconFont style={{ fontSize: 14 }} type="icon-businessinformation-line" />
      <span style={{ marginLeft: 4 }}>企业详情</span>
      <IconFont
        style={{ transform: 'rotate(90deg)' }}
        type="shang-la-jian-tou"
      />
    </span>
  );
};
// 根据附件类型获取附件icon
export const getFileIcon = (fileType: string) => {
  switch (fileType) {
    case 'pdf':
      return filePdf;
    case 'xls':
    case 'xlsx':
      return fileExcel;
    case 'bmp':
    case 'gif':
    case 'jpg':
    case 'jpeg':
    case 'pic':
    case 'png':
    case 'tif':
      return fileImg;
    case 'ppt':
    case 'pptx':
      return filePpt;
    case 'rar':
    case 'zip':
    case 'arj':
    case 'gz':
    case 'z':
      return fileRar;
    case 'txt':
      return fileTxt;
    case 'wmv':
    case 'asf':
    case 'asx':
    case 'rm':
    case 'rmvb':
    case 'mpg':
    case 'mpeg':
    case 'mpe':
    case 'avi':
    case 'mkv':
    case 'flv':
    case 'vob':
    case 'mp4':
    case 'm4v':
    case 'mov':
    case '3gp':
      return fileVideo;
    case 'doc':
    case 'docx':
      return fileWord;
    default:
      return fileCommon;
  }
};

const customRender = (
  type,
  text,
  record,
  index,
  fieldId: string,
  bizAlias: string,
  bizType: string,
  config: any,
) => {
  let colRender = null;
  const colorList = [
    { value: '未开始', color: 'rgba(23,26,29,0.24)' },
    { value: '进行中', color: '#0089FF' },
    { value: '已完成', color: '#00B042' },
    { value: '已逾期', color: '#FF5219' },
    { value: '待领取', color: 'rgba(23,26,29,0.24)' },
  ];
  switch (type) {
    case 'relation':
      colRender = Array.isArray(text) ? (
        text.map((c) =>
          c ? (
            <div
              style={{
                marginBottom: '6px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <span
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <IconFont type="icon-lianjie" style={{ marginRight: '4px' }} />
                {customJP(c)?.title}
              </span>
              <RenderCompanyIcon
                record={record}
                name={customJP(c)?.title}
                bizAlias={bizAlias}
                bizType={bizType}
                config={config}
              />
            </div>
          ) : (
            <div
              key={`${c}_${getUuid()}`}
              style={{ height: '22px', marginBottom: '6px' }}
            >
              --
            </div>
          ),
        )
      ) : typeof text === 'string' ? (
        customJP(text)?.title ? (
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              <IconFont
                type="icon-lianjie"
                style={{ marginRight: '4px', marginBottom: '6px' }}
              />
              {customJP(text)?.title}
            </span>
            <RenderCompanyIcon
              record={record}
              name={customJP(text)?.title}
              bizAlias={bizAlias}
              bizType={bizType}
              config={config}
            />
          </div>
        ) : (
          <div style={{ height: '22px', marginBottom: '6px' }}>--</div>
        )
      ) : (
        <span>{text?.title}</span>
      );
      break;
    case 'phone':
      if (bizAlias === 'customer_phone' || bizAlias === 'contact_phone') {
        const phoneValue =
          typeof text === 'string' && text.includes('{')
            ? customJP(text)?.value
            : text?.value ?? text;
        colRender = (
          <>
            <span dangerouslySetInnerHTML={{ __html: phoneValue }}>
              {/* {phoneValue} */}
            </span>
            {phoneValue && window.__grayMap__?.get('call_center') && (
              <img
                onClick={(e) => {
                  e.stopPropagation();
                  window.__openCallSDK?.('phone', {
                    contact: phoneValue,
                    instanceId: record.instanceId,
                    name: record[PHONE_NAME_FIELD_BIZALIAS?.[bizAlias]],
                    bizType,
                  });
                }}
                style={{ marginLeft: '6px', cursor: 'pointer' }}
                src={require('../../../../../assets/form/file-ppt.png')}
                alt=""
              />
            )}
          </>
        );
      } else {
        colRender = (
          <span>
            {typeof text === 'string' && text.includes('{')
              ? customJP(text)?.value
              : text?.value ?? text}
          </span>
        );
      }
      break;
    case 'picture':
      colRender = (
        <>
          {text?.length
            ? genImgAry(text)?.map((img) => (
                <img
                  key={`${img}_${getUuid()}`}
                  width="30"
                  height="30"
                  style={{
                    borderRadius: '4px',
                    marginRight: '5px',
                    cursor: 'pointer',
                    opacity: !img ? 0 : 1,
                  }}
                  src={img}
                  alt=""
                />
              ))
            : null}
        </>
      );
      break;
    case 'attachment':
      colRender = (
        <>
          {text?.length
            ? text?.map((img) => {
                // 过滤为空对象展示空白文件夹
                if (!img || !Object.keys(img).length) return null;
                return (
                  <img
                    key={`${img}_${getUuid()}`}
                    width="28"
                    height="32"
                    style={{
                      borderRadius: '4px',
                      marginRight: '5px',
                      cursor: 'pointer',
                    }}
                    src={getFileIcon(img?.type)}
                    alt=""
                  />
                );
              })
            : null}
        </>
      );
      break;
    case 'nodeOfTable':
      colRender = (
        <>
          {text && Array.isArray(text)
            ? text.map((c) =>
                c ? (
                  <div
                    key={`${c}_${getUuid()}`}
                    style={{ height: '22px', marginBottom: '6px' }}
                    dangerouslySetInnerHTML={{ __html: c }}
                  >
                    {/* {c} */}
                  </div>
                ) : (
                  <div
                    key={`${c}_${getUuid()}`}
                    style={{ height: '22px', marginBottom: '6px' }}
                  >
                    --
                  </div>
                ),
              )
            : null}
        </>
      );
      break;
    case 'nodeofTableMultiSelect':
      colRender = (
        <>
          {text?.map((_text, index) => {
            if (_text) {
              if (_text[0]?.colorEnable) {
                // 颜色开关开启了
                return (
                  <div key={index} className={c.colMultiSelect1}>
                    {_text?.slice(0, 3).map((val) => {
                      return (
                        <div
                          key={val?.key}
                          className={c.colMultiSelectvalue}
                          style={{
                            color: val?.color ?? 'rgba(23,26,29,0.60)',
                            border: val?.color
                              ? 'none'
                              : '1px solid rgba(23,26,29,0.24)',
                          }}
                        >
                          <div
                            className={c.colMultiSelectbkg}
                            style={{
                              background: val?.color ?? '#fff',
                            }}
                          />
                          <div
                            dangerouslySetInnerHTML={{ __html: val?.value }}
                          ></div>
                        </div>
                      );
                    })}
                    {_text?.length > 3 ? (
                      <Tooltip
                        placement="top"
                        color="white"
                        overlayInnerStyle={{
                          color: '#000',
                          padding: '8px 13px',
                          width: '306px',
                        }}
                        title={
                          <div className={c.colMultiSelect1}>
                            {_text?.map((val) => (
                              <div
                                key={val?.key}
                                className={c.colMultiSelectWrap}
                                style={{
                                  color: val?.color ?? 'rgba(23,26,29,0.60)',
                                  border: val?.color
                                    ? 'none'
                                    : '1px solid rgba(23,26,29,0.24)',
                                  borderRadius: '4px',
                                }}
                              >
                                <div
                                  className={c.colMultiSelectbkg}
                                  style={{
                                    background: val?.color ?? '#fff',
                                  }}
                                />
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: val?.value,
                                  }}
                                ></div>
                              </div>
                            ))}
                          </div>
                        }
                      >
                        <div className={c.omission}>...</div>
                      </Tooltip>
                    ) : null}
                  </div>
                );
              }
              // 颜色开关关闭了
              const value = _text.map((item) => item.value).join('，');
              return (
                <div className={c.nodeofTableSelectoff}>
                  <Tooltip
                    placement="top"
                    color="white"
                    overlayInnerStyle={{ color: '#000' }}
                    title={
                      <div dangerouslySetInnerHTML={{ __html: value }}></div>
                    }
                  >
                    <span dangerouslySetInnerHTML={{ __html: value }}></span>
                  </Tooltip>
                </div>
              );
            }
            return (
              <div style={{ height: '22px', marginBottom: '6px' }}>--</div>
            );
          })}
        </>
      );
      break;
    case 'nodeofTableSelect':
      colRender = (
        <>
          {text?.map((va) => {
            if (va && JSON.stringify(va) !== '{}') {
              if (va?.colorEnable) {
                // 颜色开关开启了
                return (
                  <>
                    <div
                      key={`${va?.value}_${getUuid()}`}
                      style={{ marginBottom: '6px' }}
                    >
                      <div
                        className={c.nodeofTableSelect}
                        style={{
                          color: va?.color ?? 'rgba(23,26,29,0.60)',
                          border: va?.color
                            ? 'none'
                            : '1px solid rgba(23,26,29,0.24)',
                        }}
                      >
                        <div
                          className={c.nodeofTableSelectbgk}
                          style={{
                            background: va?.color ?? '#fff',
                          }}
                        />
                        <div
                          dangerouslySetInnerHTML={{ __html: va?.value }}
                        ></div>
                      </div>
                    </div>
                  </>
                );
              }
              // 颜色开关关闭了
              return (
                <div className={c.nodeofTableSelectoff}>
                  <Tooltip
                    placement="top"
                    color="white"
                    overlayInnerStyle={{ color: '#000' }}
                    title={
                      <span
                        dangerouslySetInnerHTML={{ __html: va.value }}
                      ></span>
                    }
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: va?.value }}
                    ></span>
                  </Tooltip>
                </div>
              );
            }
            return (
              <div
                key={`${va?.value}_${getUuid()}`}
                style={{ height: '22px', marginBottom: '6px' }}
              >
                --
              </div>
            );
          })}
        </>
      );
      break;
    case 'allTags':
      let parseText;
      if (text && !Array.isArray(text)) {
        try {
          parseText = JSON.parse(text);
          if (typeof parseText[0] === 'string') {
            parseText = parseText.map((item) => ({
              defaultColor: '#0089ff',
              value: item.value,
              key: Math.random(),
            }));
          }
        } catch (err) {
          console.error(err);
        }
      }
      if (parseText) {
        colRender = <TableCustomerTags text={parseText} />;
      }
      break;
    case 'dateRange':
      // "2022-12-01 13:55:36~2022-12-11 13:55:38"
      // [["2022-12-01 13:55:36~2022-12-11 13:55:38"]]
      // console.log(text, "dateRange");
      if (!text) return null;
      if (Array.isArray(text) && text.length > 0) {
        const _text = text[0];
        text = _text[0];
        // const [start, end] = _text[0]?.split('~') ?? [];
        // const format = 'dateTime';
        // text = `${transformDateToString(start, format)} ~ ${transformDateToString(end, format)}`;
      }
    case 'select':
      if (text) {
        if (typeof text === 'string') {
          colRender = text ? (
            <div className={c.colSelect}>
              <Tooltip
                placement="top"
                color="white"
                overlayInnerStyle={{ color: '#000' }}
                title={<span dangerouslySetInnerHTML={{ __html: text }}></span>}
              >
                {fieldId && fieldId === 'biz_task_status' ? (
                  <span
                    style={{
                      backgroundColor:
                        colorList.find((v) => v.value === text)?.color ??
                        'rgba(23,26,29,0.24)',
                    }}
                  />
                ) : (
                  ''
                )}
                {fieldId && fieldId === 'select_VHpKSmC0D6JZ' ? (
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                      const url = `https://plan.xunxi.com/dispatchPlan/deliveryReplyManagement?currentPage=1&idSearch=${record?.seqNumber_1Y6T021SYH280}&orderType=SALE_OPPORTUNITY`;
                      window?.__dd?.biz.util.openLink({
                        url,
                      });
                    }}
                  >
                    <span dangerouslySetInnerHTML={{ __html: text }}></span>
                  </a>
                ) : (
                  <span dangerouslySetInnerHTML={{ __html: text }}></span>
                )}
              </Tooltip>
            </div>
          ) : null;
        } else {
          colRender = (
            <div
              className={c.colSelectContanier}
              style={{
                color: text[0]?.color ?? 'rgba(23,26,29,0.60)',
                border: text[0]?.color
                  ? 'none'
                  : '1px solid rgba(23,26,29,0.24)',
              }}
            >
              <div
                className={c.colSelectbkg}
                style={{
                  background: text[0]?.color ?? '#fff',
                }}
              />
              <div className={c.colSelectValue}>{text[0]?.value}</div>
            </div>
          );
        }
      } else {
        colRender = null;
      }
      break;
    case 'multiSelect':
      if (text) {
        if (typeof text === 'string') {
          colRender = text ? (
            <div className={c.colMultiSelect}>
              <Tooltip
                placement="top"
                color="white"
                overlayInnerStyle={{ color: '#000' }}
                title={<span dangerouslySetInnerHTML={{ __html: text }}></span>}
              >
                <span dangerouslySetInnerHTML={{ __html: text }}></span>
              </Tooltip>
            </div>
          ) : null;
        } else {
          colRender = (
            <div className={c.colMultiSelect1}>
              {text?.slice(0, 3).map((val) => (
                <div
                  key={val?.key}
                  className={c.colMultiSelectvalue}
                  style={{
                    color: val?.color ?? 'rgba(23,26,29,0.60)',
                    border: val?.color
                      ? 'none'
                      : '1px solid rgba(23,26,29,0.24)',
                  }}
                >
                  <div
                    className={c.colMultiSelectbkg}
                    style={{
                      background: val?.color ?? '#fff',
                    }}
                  />
                  <div>{val?.value}</div>
                </div>
              ))}
              {text?.length > 3 ? (
                <Tooltip
                  placement="bottom"
                  color="white"
                  overlayInnerStyle={{
                    color: '#000',
                    padding: '8px 13px',
                    width: '306px',
                  }}
                  title={
                    <div className={c.colMultiSelect1}>
                      {text?.map((val) => (
                        <div
                          key={val?.key}
                          className={c.colMultiSelectWrap}
                          style={{
                            color: val?.color ?? '#0089FF',
                          }}
                        >
                          <div
                            style={{
                              background: val?.color ?? '#0089FF',
                            }}
                            className={c.colMultiSelectbkg}
                          />
                          <div
                            dangerouslySetInnerHTML={{ __html: val?.value }}
                          ></div>
                        </div>
                      ))}
                    </div>
                  }
                >
                  <div className={c.omission}>...</div>
                </Tooltip>
              ) : null}
            </div>
          );
        }
      } else {
        colRender = null;
      }
      break;
    default:
      if (fieldId.includes('kpContact')) {
        // 关键联系人 bizAlias: "kp_contact"
        colRender = (
          <KpContact
            record={record}
            bizType={bizType}
            formCode={config?.formCode}
          />
        );
      } else {
        colRender = text ? (
          <Tooltip
            placement="top"
            color="white"
            overlayInnerStyle={{ color: '#000' }}
            title={<span dangerouslySetInnerHTML={{ __html: text }}></span>}
          >
            {typeof text === 'string' || typeof text === 'number' ? (
              <div
                style={{
                  width: '162px',
                  maxWidth: '162px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  color:
                    [
                      'customer_name',
                      'input_intelligent_match_company',
                    ].includes(bizAlias) && isOpenInTripartitePlatform('1688')
                      ? '#0089ff'
                      : 'rgba(0,0,0,.85)',
                  cursor:
                    [
                      'customer_name',
                      'input_intelligent_match_company',
                    ].includes(bizAlias) && isOpenInTripartitePlatform('1688')
                      ? 'pointer'
                      : 'inherit',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
                onClick={(e) => {
                  if (
                    bizAlias === 'input_intelligent_match_company' &&
                    isOpenInTripartitePlatform('1688')
                  ) {
                    e.stopPropagation();
                    e.preventDefault();
                    config?.setIntelligentMatchCompanyId(
                      record?.tk_input_intelligent_match_company_id,
                    );
                    config?.setIntelligentMatchVisible?.(true);
                    config?.setItemInstanceId(record?.instanceId);
                  }
                }}
              >
                <span
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  dangerouslySetInnerHTML={{ __html: text }}
                >
                  {/* {text + '1234'} */}
                </span>
                {/* 企业客户/联系人才会显示企业详情图标 */}
                <RenderCompanyIcon
                  record={record}
                  name={text}
                  bizAlias={bizAlias}
                  bizType={bizType}
                  config={config}
                />
              </div>
            ) : null}
          </Tooltip>
        ) : null;
      }
  }
  return colRender;
};

export const genTableColumns = (response, config = {}) => {
  if (!response || !response?.formHeaderFieldList) return [];
  if (
    response?.bizType === 'crm_siyu_customer' &&
    Array.isArray(response?.formHeaderFieldList)
  ) {
    response.formHeaderFieldList = resolueWechatCustomerInfoColumn(
      response?.formHeaderFieldList,
    );
  }
  const cols = response?.formHeaderFieldList?.filter((col) => {
    // table中不展示说明文字和流水号
    const widgetFit = !['textNote'].includes(col.widget);

    if (!!window.__siteId) {
      const idFit = ![
        'createUserId',
        'principal',
        'cooperator',
        'outgoingMember',
        'historyApproveUser',
        'currentApproveUser',
        'currentNode',
      ].includes(col?.fieldId);
      const widgetFit2 = col?.widget !== 'personSelector';
      return idFit && widgetFit && widgetFit2;
    }
    return widgetFit;
  });
  const ComponentArray = cols.map((item) => {
    const {
      fieldId,
      order,
      title,
      bizAlias,
      widget,
      render,
      fixed,
      width = 162,
    } = item;
    return {
      key: fieldId,
      title,
      dataIndex: TABLE_CELL_BIZALIAS.includes(bizAlias) ? bizAlias : fieldId,
      showSorterTooltip: false,
      sorter:
        config?.sorter ??
        (sortedSupportWidgets?.[widget]?.sortable ||
          [
            'gmtCreate',
            'gmtModified',
            'biz_customer_last_customer_distribute_date',
            'biz_customer_last_visit_date',
            'biz_customer_last_follow_up_date',
            'biz_customer_last_status_change_dateV2',
            'biz_customer_return_open_customer_date',
            'gmtModified',
          ].includes(fieldId)),
      fixed,
      order,
      width: bizAlias === 'kp_contact' ? 200 : width,
      ellipsis: true,
      render:
        render ||
        ((text, record, index) =>
          customRender(
            widget ?? fieldId,
            text,
            record,
            index,
            fieldId,
            bizAlias,
            response.bizType,
            config,
          )),
      children:
        widget === 'table'
          ? item.childFormHeaderFields.map((s) => {
              const { fieldId, title, widget } = s;
              let realWidget = 'nodeOfTable';
              // const realWidget = ['relation', 'picture', 'attachment'].includes(widget)
              //   ? widget
              //   : 'nodeOfTable';
              switch (widget) {
                case 'relation':
                case 'picture':
                case 'attachment':
                  realWidget = widget;
                  break;
                case 'multiSelect':
                  realWidget = 'nodeofTableMultiSelect';
                  break;
                case 'select':
                  realWidget = 'nodeofTableSelect';
                  break;
                default:
                  realWidget = realWidget;
              }
              return {
                key: fieldId,
                width: 162,
                ellipsis: true,
                title,
                dataIndex: fieldId,
                // widget: ['relation', 'picture', 'attachment'].includes(widget)
                //   ? widget
                //   : 'nodeOfTable',
                widget: realWidget,
                render: (text, record, index) =>
                  customRender(
                    realWidget,
                    text,
                    record,
                    index,
                    fieldId,
                    bizAlias,
                    response.bizType,
                    config,
                  ),
              };
            })
          : undefined,
    };
  });
  return ComponentArray;
};

export const genTableData = (response, formConfig?: any, config?: any) => {
  const properties = formConfig?.properties;
  const propertiesKeys = properties && Object.keys(properties);
  const propertiesValues = properties && Object.values(properties);
  const multiSelectKeys = propertiesKeys?.filter((val) =>
    val.includes('multiSelect'),
  );
  const selectKeys = propertiesKeys?.filter((val) => val.includes('select'));
  const tableKeys = propertiesKeys?.filter((val) => val.includes('table'));
  const multiSelectValues = propertiesValues?.filter((val) =>
    multiSelectKeys?.includes(val?.id),
  );
  const selectValues = propertiesValues?.filter((val) =>
    selectKeys?.includes(val?.id),
  );
  const tableValues = propertiesValues?.filter((val) =>
    tableKeys?.includes(val?.id),
  );
  if (!response || !response.instanceDataList) return [];

  let listData = response.instanceDataList.map((item, idx) => {
    let row = {
      key: item.instanceId,
      instanceId: item.instanceId,
      entityInfo: (config?.entityInfos ?? []).find(
        (enty) => enty.customerId === config?.contact_customers?.[idx],
      ),
      kpContactInfo: (config?.contactInfos ?? []).find(
        (enty) => enty.instanceId === item.instanceId,
      ),
      // staff_id: creator,
      // gmt_create: dateFormat(createTime),
      // gmt_modified: dateFormat(modifyTime),
    };
    try {
      const extendData = JSON.parse(item?.extendData ?? '{}');
      row = { ...row, ...extendData };
    } catch (err) {}

    item.itemList.forEach((v) => {
      switch (v.widget) {
        // case "picture":
        //   row[v.fieldId] = v.value ? map(split(v.value, ","), trim) : [];
        //   break;
        case 'attachment':
          row[v?.fieldId] = v.value ? customJP(v.value) : [];
          // row[v.fieldId] = v.value
          //   ? map(split(v.value, "~"), (item) => {
          //       const matchResult = item.match(
          //         /instance(.*)((?!\.{1})(.*))\?Expires/,
          //       );
          //       return {
          //         type: matchResult?.[1]?.split(".")?.[1],
          //         uri: item,
          //       };
          //     })
          //   : [];
          break;
        case 'table':
          {
            const source = customJP(v.value) ?? [];
            if (source.length) {
              const mapping = source[0].reduce((acc, cur) => {
                if (
                  cur.key.includes('select_') ||
                  cur.key.includes('multiSelect_')
                ) {
                  acc[cur.key] = [cur.extendValue];
                } else {
                  acc[cur.key] = [cur.value];
                }
                return acc;
              }, {});
              source.slice(1).forEach((v) => {
                v.forEach((t) => {
                  if (
                    t.key.includes('select_') ||
                    t.key.includes('multiSelect_')
                  ) {
                    mapping[t.key]?.push(t.extendValue);
                  } else {
                    mapping[t.key]?.push(t.value);
                  }
                });
              });
              const currentField =
                tableValues?.find((val1) => val1?.id === v.fieldId) || {};
              const currentProperties = currentField?.items?.properties || {};

              row[v?.fieldId] = {
                type: 'innerTable',
                value: keys(mapping).map((k) => {
                  let val = mapping[k];
                  // if (k.includes('dateRange_') && Array.isArray(mapping[k]) && mapping[k].length) {
                  //   val = dateRangeFormat(val);
                  // }
                  if (k.includes('multiSelect_')) {
                    if (
                      currentProperties[k] &&
                      currentProperties[k]?.colorEnable
                    ) {
                      // 当前多选控件开启了颜色开关
                      const currentMultiSelect = getColorData(
                        currentProperties[k],
                      );
                      val?.map((i) => {
                        i?.map((j) => {
                          j.colorEnable = currentProperties[k]?.colorEnable;
                          const obj = currentMultiSelect.find(
                            (m) => m.key === j?.key,
                          );
                          if (obj) {
                            j.color = obj.color;
                          } else {
                            j.color = undefined;
                          }
                        });
                      });
                    } else {
                      val?.map((i) => {
                        i?.map((j) => {
                          j.colorEnable = false;
                        });
                      });
                    }
                  }

                  if (k.includes('select_')) {
                    if (
                      currentProperties[k] &&
                      currentProperties[k]?.colorEnable
                    ) {
                      // 当前单选控件开启了颜色开关
                      const currentSelect = getColorData(currentProperties[k]);
                      val?.map((x) => {
                        if (x && JSON.stringify(x) !== '{}') {
                          x.colorEnable = currentProperties[k]?.colorEnable;
                          const obj = currentSelect.find(
                            (n) => n.key === x?.key,
                          );
                          if (obj) {
                            x.color = obj.color;
                          } else {
                            x.color = undefined;
                          }
                        }
                      });
                    } else {
                      val?.map((x) => {
                        if (x && JSON.stringify(x) !== '{}') {
                          x.colorEnable = false;
                        }
                      });
                    }
                  }
                  return {
                    fieldId: k,
                    value: val,
                  };
                }),
              };
            }
          }
          break;
        case 'multiSelect':
          {
            const currentField =
              multiSelectValues?.find((val1: any) => val1.id === v.fieldId) ||
              {};
            if (currentField && currentField?.colorEnable) {
              // 颜色开启了
              const data = getColorData(currentField);
              const newData = v?.extendValue?.map((i) => {
                const obj = data?.find((j) => j.key === i.key);
                if (obj) {
                  return { ...i, color: obj?.color };
                }
                return { ...i, color: undefined };
              });
              row[v.fieldId] = newData;
            } else {
              // 颜色关闭了、或者没有颜色设置入口
              row[v.fieldId] = v.value;
            }
          }
          break;
        case 'select':
          {
            const _currentField = selectValues?.find(
              (val3: any) => val3.id === v.fieldId,
            );
            if (
              v?.extendValue &&
              JSON.stringify(v?.extendValue) !== '{}' &&
              _currentField &&
              _currentField?.colorEnable
            ) {
              const _data = getColorData(_currentField);
              const obj = _data?.find(
                (val4) => v?.extendValue?.key === val4.key,
              );
              row[v.fieldId] = [
                { ...v?.extendValue, color: obj ? obj?.color : undefined },
              ];
            } else {
              row[v.fieldId] = v.value;
            }
            if (v.bizAlias === 'completion_status') {
              // 外勤记录需要完成状态
              row.completion_status = v.value;
            }
          }
          break;
        default:
          if (TABLE_CELL_BIZALIAS.includes(v.bizAlias)) {
            row[v.bizAlias] = v.value;
          } else {
            row[v.fieldId] = v.value;
          }
          break;
      }
      if (v.fieldId === 'allTags') {
        row[v.fieldId] = v.extendValue ?? v.value;
      }
    });
    // dataArray.forEach((v) => {
    //   const { key, value, extendValue, componentType } = v;
    //   row[key] = formatColValue(componentType, value);
    //   row.extendValue = extendValue;
    //   row.componentType = componentType;
    // });
    return row;
  });
  listData = listData.map((t) => {
    keys(t).forEach((kk) => {
      if (t[kk]?.type === 'innerTable') {
        t[kk]?.value?.forEach((u) => {
          t[u.fieldId] = u.value;
        });
        delete t[kk];
      }
    });
    return t;
  });
  return listData;
};

const resolueWechatCustomerInfoColumn = (arr: any[]) => {
  let customer_avatar_id = '';
  let corp_name_id = '';
  let customer_name_id = '';
  let customer_nickname_id = '';
  let customer_type_id = '';
  const newArr = arr.filter((item) => {
    switch (item.bizAlias) {
      case 'customer_avatar':
        customer_avatar_id = item.fieldId;
        return false;
      case 'corp_name':
        corp_name_id = item.fieldId;
        return false;
      case 'customer_name':
        customer_name_id = item.fieldId;
        return false;
      case 'customer_nickname':
        customer_nickname_id = item.fieldId;
        return false;
      case 'customer_type':
        customer_type_id = item.fieldId;
      default:
        return true;
    }
  });
  newArr.unshift({
    fieldId: 'wechatCustomerInfo',
    title: '客户信息',
    type: 'string',
    widget: 'wechatCustomerInfo',
    fixed: true,
    width: '260px',
    render: (_: any, record: any) => {
      return (
        <WechatCustomerInfoColumn
          avatar={record[customer_avatar_id]}
          corpName={record[corp_name_id]}
          customerName={record[customer_name_id]}
          nickname={record[customer_nickname_id]}
          customerType={record[customer_type_id]}
        />
      );
    },
  });
  return newArr;
};
