/*
 * :file description:
 * :name: /low-code-platform/src/components/IconFont/index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-23 11:43:21
 * :last editor: 肖国同
 * :date last edited: 2023-02-23 11:37:49
 */
import { createFromIconfontCN } from '@ant-design/icons';

const iconFontUrl = '//at.alicdn.com/t/font_2877883_vpaoailevxg.js';

const iconParkUrl =
  'https://lf1-cdn-tos.bytegoofy.com/obj/iconpark/svg_13577_457.a583ff702d01cdd708f6ee2909a73f4f.js';

const IconFont = createFromIconfontCN({
  // scriptUrl: '//at.alicdn.com/t/font_2877883_e753iofmtxm.js', // 在 iconfont.cn 上生成
  scriptUrl: [iconFontUrl, iconParkUrl], // 在 iconfont.cn 上生成
});

export default IconFont;
