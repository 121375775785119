import React from 'react';
import { Button } from 'antd';
import { Search, Table, useTable, withTable } from '../index';
import AdvancedFilter from '../components/AdvancedFilter';
import {
  SEARCH_CONFIG,
  TABLE_CONFIG,
  createWatch,
  genTitleRender,
} from './defaultConfig';

const TableWithSearch = (props) => {
  const { formCode, search: searchProps = {}, table: tableProps = {} } = props;
  const { refresh, tableState } = useTable();

  const { selectedRowKeys = [], columns = [] } = tableState;

  return (
    <div>
      <div className="table-render-search-toolbar">
        <AdvancedFilter />
      </div>
      <Search
        watch={createWatch(() => refresh(null, { extra: 1 }))}
        {...SEARCH_CONFIG}
        {...searchProps}
      />
      <Table
        formCode
        title={() => genTitleRender({ formCode, selectedRowKeys, refresh })}
        columns={columns}
        {...TABLE_CONFIG}
        {...tableProps}
      />
    </div>
  );
};

export default withTable(TableWithSearch);
