import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { Button, Table, message, Modal, Switch } from 'antd';
import c from './index.less';
import IconFont from '@/components/IconFont';
import ModalRule from './Modal';
import { getValidator, saveValidator } from '@/services/extension-setting';
import { getUrlParam } from '@/utils';
import _cloneDeep from 'lodash/cloneDeep';

export default () => {
  const [visible, setVisible] = useState(false);
  const formCode = getUrlParam('formCode');
  const [dataSource, setDataSource] = useState<ISubmitRules[]>([]);
  const [currentRule, setCurrentRule] = useState<ISubmitRules>();
  const [version, setVersion] = useState<number>();

  const columns = [
    {
      title: '规则名称',
      dataIndex: 'name',
      key: 'name',
      width: '60%',
    },
    {
      title: '是否启用',
      dataIndex: 'enable',
      key: 'enable',
      width: '100px',
      render: (data: string, record: ISubmitRules, index: any) => {
        return (
          <div className={c.actions}>
            <span>
              <Switch
                checked={record.status != 0}
                onChange={(val) => {
                  handleToggele(val, record, index);
                }}
              />
            </span>
          </div>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: '200px',
      render: (data: string, record: ISubmitRules, index: any) => {
        return (
          <div className={c.actions}>
            <span onClick={() => handleCopy(record)}>复制</span>
            <span onClick={() => handleEdit(record)}>编辑</span>
            <span onClick={() => handleDelete(record)}>删除</span>
          </div>
        );
      },
    },
  ];
  const handleToggele = async (
    val: boolean,
    data: ISubmitRules,
    index: any,
  ) => {
    const ruleItem = _cloneDeep(data);
    ruleItem.status = val ? 1 : 0;
    const newData = dataSource.slice();
    newData.splice(index, 1, ruleItem);
    setDataSource(newData);
    await saveRules(newData);
    message.success(val ? '开启成功！' : '关闭成功！');
  };
  const handleDelete = (data: ISubmitRules) => {
    Modal.confirm({
      title: '确认删除',
      content: '是否删除此条规则',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const newData = dataSource.filter(
          (item) => item.ruleId !== data.ruleId,
        );
        await saveRules(newData);
        message.success('删除成功！');
      },
    });
  };
  const handleCopy = async (record: ISubmitRules, index?: any) => {
    const ruleItem = _cloneDeep(record);
    ruleItem.ruleId = new Date().getTime();
    setVisible(true);
    setCurrentRule(ruleItem);
  };
  const handleEdit = (record: ISubmitRules) => {
    setCurrentRule(record ?? {});
    handleVisibleChange();
  };

  useEffect(() => {
    getValidatorList();
  }, []);

  const getValidatorList = () => {
    getValidator({ formCode }, formCode).then((res) => {
      setDataSource(res?.data?.validators ?? []);
      setVersion(res?.data?.version);
    });
  };

  const handleVisibleChange = () => {
    setVisible(!visible);
  };

  const saveRules = async (rules = dataSource) => {
    const data = {
      formCode,
      validators: rules,
      version,
    };
    await saveValidator(data);
    setCurrentRule(undefined);
    getValidatorList();
    return Promise.resolve();
  };

  const openAddModal = () => {
    if (dataSource.length >= 10) {
      Modal.warning({
        title: '提示',
        content: '最多可创建10条规则，你可以选择删除现有规则后再创建',
      });
      return;
    }
    setVisible(true);
  };

  return (
    <>
      <Header>
        <Button
          type="primary"
          icon={<IconFont type="icon-tianjia1" />}
          onClick={() => {
            openAddModal();
          }}
        >
          添加规则
        </Button>
      </Header>
      <div className={c.content}>
        <Table
          dataSource={dataSource}
          pagination={false}
          rowKey="ruleId"
          columns={columns}
        />
      </div>
      <ModalRule
        visible={visible}
        currentRule={currentRule}
        onClose={() => {
          handleVisibleChange();
          setCurrentRule(undefined);
        }}
        onChange={(data) => {
          const newData = dataSource.slice();
          const hasIndex = newData.some((item) => item.ruleId === data.ruleId);
          if (
            typeof currentRule === 'object' &&
            Object.keys(currentRule).length > 0 &&
            hasIndex
          ) {
            const index = newData.findIndex(
              (item) => item.ruleId === data.ruleId,
            );
            newData.splice(index, 1, data);
          } else {
            newData.push(data);
          }
          return saveRules(newData);
        }}
      />
    </>
  );
};
