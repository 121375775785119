/*
 * :file description:
 * :name: \low-code-platform\src\components\FormGenerator\components\Canvas\core\RenderField.jsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-02-16 17:37:40
 * :last editor: 黄艺
 * :date last edited: 2022-03-10 10:54:06
 */
import React, { Suspense } from 'react';
import { Tooltip } from 'antd';
import { useStore } from '../../../utils/hooks';
import {
  isLooselyNumber,
  isCssLength,
  getParentProps,
  transformProps,
} from '../../../utils';
import { getWidgetName } from '../../../utils/mapping';
import './Wrapper.less';

const RenderField = ({
  $id,
  item,
  labelClass,
  contentClass,
  isComplex,
  children,
}) => {
  const { schema, data } = item;

  const {
    onItemChange,
    displaySchema,
    flatten,
    widgets,
    mapping,
    frProps = {},
    userProps = {},
  } = useStore();
  const { transformer } = userProps;
  const { labelWidth, displayType, showValidate } = frProps;
  const { title, description, required } = schema;

  let widgetName = getWidgetName(schema, mapping);
  const customWidget = schema['widget'];
  if (customWidget && widgets[customWidget]) {
    widgetName = customWidget;
  }
  let Widget = widgets[widgetName];
  // 如果不存在，比如有外部的自定义组件名称，使用默认展示组件
  if (!Widget) {
    const defaultSchema = { ...schema };
    delete defaultSchema['widget'];
    widgetName = getWidgetName(defaultSchema, mapping);
    Widget = widgets[widgetName] || 'input';
  }
  // if (widgetName === 'multiSelect') {
  //   console.log(schema['widget'], customWidget, Widget);
  // }
  // 真正有效的label宽度需要从现在所在item开始一直往上回溯（设计成了继承关系），找到的第一个有值的 labelWidth
  const effectiveLabelWidth =
    getParentProps('labelWidth', $id, flatten) || labelWidth;
  const _labelWidth = isLooselyNumber(effectiveLabelWidth)
    ? Number(effectiveLabelWidth)
    : isCssLength(effectiveLabelWidth)
    ? effectiveLabelWidth
    : 'calc(100% - 80px)'; // 默认是 100% - 100px 的长度

  let labelStyle = { width: _labelWidth };
  if (widgetName === 'checkbox') {
    labelStyle = { flexGrow: 1 };
  } else if (isComplex || displayType === 'column') {
    labelStyle = { flexGrow: 1 };
  }

  const onChange = (value) => {
    const newItem = { ...item };
    if (item.schema.type === 'boolean' && item.schema.widget === 'checkbox') {
      newItem.data = !value;
    } else {
      newItem.data = value;
    }
    onItemChange($id, newItem, 'data');
  };

  let contentStyle = {};
  if (widgetName === 'checkbox' && displayType === 'row') {
    contentStyle.marginLeft = effectiveLabelWidth;
  }

  // TODO: useMemo
  // 改为直接使用form-render内部自带组件后不需要再包一层options
  // console.log(schema, 11111222);
  const usefulWidgetProps = transformProps({
    value: data || schema.default,
    checked: data,
    disabled: schema.disabled,
    readOnly: schema.readOnly,
    format: schema.format,
    placeholder: schema.placeholder,
    onChange,
    schema,
    ...schema['props'],
  });

  // 是否选择控件
  const isSelectTypeWidget = ['select', 'multiSelect'].includes(widgetName);
  // 是选择控件且有设置关联选项
  const hasAssociatedFields =
    isSelectTypeWidget &&
    Array.isArray(schema.enumAssociatedFields) &&
    schema.enumAssociatedFields.some((v) => !!v.length);
  // 是否被关联
  const isAssociated = () => {
    let flag = false;
    Object.entries(displaySchema?.properties).map(([prop, propValue]) => {
      if (['select', 'multiSelect'].includes(propValue.widget)) {
        if (Array.isArray(propValue.enumAssociatedFields)) {
          propValue.enumAssociatedFields.forEach((v) => {
            if (v.includes(schema.id)) {
              flag = true;
            }
          });
        }
      }
    });
    return flag;
  };

  const getAssociatedContent = () => {
    let contentAry = [];
    Object.entries(displaySchema?.properties).map(([prop, propValue]) => {
      if (['select', 'multiSelect'].includes(propValue.widget)) {
        if (Array.isArray(propValue.enumAssociatedFields)) {
          propValue.enumAssociatedFields.forEach((v, idx) => {
            if (v.includes(schema.id)) {
              contentAry.push([propValue.title, propValue.enumNames[idx]]);
            }
          });
        }
      }
    });
    return (
      '当' +
      contentAry
        .map(
          ([key, value], index) =>
            `${index !== 0 ? ' 或' : ''}【${key}】字段选择“${value}”`,
        )
        .join('') +
      ' 时显示本字段'
    );
  };
  return (
    <>
      {schema.title ? (
        <div className={labelClass} style={labelStyle}>
          <label
            className={`fr-label-title no-colon`} // checkbox不带冒号
            title={title}
          >
            {required && <span className="fr-label-required"> *</span>}
            <span
              className={`${isComplex ? 'b' : ''} ${
                displayType === 'column' ? 'flex-none' : ''
              }`}
            >
              {title}
            </span>
            {description && (
              <span className="fr-desc ml2">(&nbsp;{description}&nbsp;)</span>
            )}
            {displayType !== 'row' && showValidate && (
              <span className="fr-validate">validation</span>
            )}
          </label>
          <div>
            {hasAssociatedFields && (
              <span className="associate_parasitifer">已设置选项关联</span>
            )}
            {isAssociated() && (
              <Tooltip placement="top" title={getAssociatedContent()}>
                <span className="associate_host">
                  已被设为选项关联字段，默认隐藏
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      ) : isAssociated() ? (
        <Tooltip placement="top" title={getAssociatedContent()}>
          <span className="associate_host_no_title">
            已被设为选项关联字段，默认隐藏
          </span>
        </Tooltip>
      ) : null}
      <div className={contentClass} style={contentStyle}>
        <Suspense fallback={<div></div>}>
          <Widget {...usefulWidgetProps}>{children || null}</Widget>
        </Suspense>
      </div>
    </>
  );
};

export default RenderField;
