/*
 * :file description:
 * :name: \low-code-platform\src\services\form-generator\index.ts
 * :author: 宋海兵
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-26 15:30:19
 * :last editor: 宋海兵
 * :date last edited: 2021-10-28 16:28:54
 */
import { postRequest, getRequest } from '@/common/http';

interface IList {
  pageNum?: Number;
  pageSize?: number;
}

// 待办消息-待办任务列表
export async function getTodoList(data: IList) {
  return postRequest(`/api/processTask/todoList`, data);
}

// 待办消息-我发起的任务列表
export async function getStartList(data: IList) {
  return postRequest(`/api/processTask/startList`, data);
}

// 待办消息-已办任务列表
export async function getDoneList(data: IList) {
  return postRequest(`/api/processTask/doneList`, data);
}

// 待办消息-抄送给我的任务列表
export async function getCopyList(data: IList) {
  return postRequest(`/api/processTask/copyList`, data);
}
