/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/relation/duplicated/components/CombinedRuleModal/index.tsx
 * :author: xiaoguotong
 * :copyright: (c) 2023, Tungee
 * :date created: 2023-12-19 11:17:39
 * :last editor: KyleLiang
 * :date last edited: 2024-08-06 15:27:17
 */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Modal,
  Tabs,
  Button,
  Table,
  Select,
  Form,
  Switch,
  message,
} from 'antd';
import styles from './index.less';
import {
  getUnionDuplicationFormList,
  editFormUnionDuplicationRule,
  saveFormUnionDuplicationRule,
  getDuplicateFieldList,
} from '@/services/duplicate';
import { getFormSchemaForDesignStatus } from '../../../../form/services/form-generator';
import NoData from '@/assets/form/empty.png';
import {
  PERSONAL_CUSTOMER_SUPPORT_DUPLICAT_WIDGETS,
  SUPPORT_DUPLICAT_WIDGETS,
} from '../../constants';
import cx from 'classnames';
import { SortableList } from './Sortable';
import { cloneDeep } from 'lodash';

interface IConditionListItem {
  fieldId?: string;
  title?: string;
  targetFieldId?: string;
  targetFieldTitle?: string;
  status: number; //1, 启用， 0-没启用
  filterType: string;
}

interface IResultField {
  fieldId?: string;
  title?: string;
  hidden: boolean;
  editable: boolean;
}

enum Type {
  Fields = 'Fields', //查重字段
  Result = 'Result', //查重结果
}
interface IFormItem {
  formCode: string; //表单formCode
  title: string; //表单名称
}

const PersonalCustomerDefaultResultFields = [
  {
    fieldId: 'title',
    title: '数据标题',
    hidden: false,
    editable: true,
  },
  {
    fieldId: 'gmtCreate',
    title: '创建时间',
    hidden: false,
    editable: true,
  },
];

const DefaultResultFields = [
  {
    fieldId: 'title',
    title: '数据标题',
    hidden: false,
    editable: true,
  },
  {
    fieldId: 'gmtCreate',
    title: '创建时间',
    hidden: false,
    editable: true,
  },
  {
    fieldId: 'principal',
    title: '负责人',
    hidden: false,
    editable: true,
  },
  {
    fieldId: 'cooperator',
    title: '协同人',
    hidden: false,
    editable: true,
  },
];

const { TabPane } = Tabs;
const { Option } = Select;

export interface IProps {
  visible: boolean;
  onCancel: () => void;
  onOk: () => void;
  formCode: string;
  formName: string;
  editForm?: any; //编辑数据回显
  fieldsList?: any; //线索查重字段下拉框数据
  isPersonalCustomerCombined: boolean;
}

const CombinedRuleModal = (props: IProps) => {
  const {
    visible,
    onCancel,
    onOk,
    fieldsList,
    formCode,
    formName,
    editForm,
    isPersonalCustomerCombined,
  } = props;

  const [selectForm, setSelectForm] = useState<IFormItem>(); //选中目标表单

  const [appUuid, setAppUuid] = useState(); //appuid

  const [activeTabKey, setActiveTabKey] = useState(Type.Fields);

  const [formList, setFormList] = useState<IFormItem[]>([]); //查重表单列表数据

  const [conditionList, setConditionList] = useState<IConditionListItem[]>([]); //查重字段列表

  //可设置字段范围：数据标题+表单基本字段（不包含关联表单、表格）+系统字段：创建时间、负责人、协同人
  //默认显示字段：数据标题（不可关闭）；创建时间、负责人被设置为查重字段的默认开启，可手动关闭
  const [resultFields, setResultFields] = useState<IResultField[]>([]); //查重结果字段列表

  const [confirmLoading, setConfirmLoading] = useState(false);
  //修改是否启用状态
  const handleChangeStatus = (checked: boolean, index: number) => {
    try {
      conditionList[index] = {
        ...conditionList[index],
        status: +checked,
      };
      setConditionList([...conditionList]);
    } catch (error) {}
  };

  //选择线索查重字段
  const handleSelectFields = (o: any, index: number) => {
    try {
      conditionList[index] = {
        ...conditionList[index],
        fieldId: o?.value,
        title: o?.children,
      };

      setConditionList([...conditionList]);
    } catch (error) {}
  };

  //查重字段列
  const FieldsColumns = [
    {
      title: isPersonalCustomerCombined
        ? `${selectForm?.title}字段`
        : `${selectForm?.title}线索字段`,
      dataIndex: 'targetFieldTitle',
      key: 'targetFieldTitle',
    },
    {
      title: '是否启用',
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (text: any, record: any, index: number) => {
        const { status } = record;
        return (
          <Switch
            checked={!!status}
            onChange={(checked) => {
              handleChangeStatus(checked, index);
            }}
          />
        );
      },
    },
    {
      title: isPersonalCustomerCombined ? '个人客户查重字段' : '线索查重字段',
      dataIndex: 'title',
      key: 'title',
      render: (text: any, record: any, index: number) => {
        const { fieldId, widget } = record;
        return (
          <Select
            showSearch
            placeholder="请选择"
            allowClear
            disabled={record.status === 0}
            dropdownMatchSelectWidth={false}
            virtual={false}
            style={{ width: '176px' }}
            value={fieldId}
            onChange={(v, o) => {
              handleSelectFields(o, index);
            }}
            filterOption={(input, option) => {
              return (option?.children ?? '')
                ?.toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {fieldsList?.map((f: any) => {
              return (
                <Option
                  disabled={widget !== f?.widget}
                  key={f?.id}
                  value={f?.id}
                >
                  {f.title}
                </Option>
              );
            })}
          </Select>
        );
      },
    },
  ];

  const reset = () => {
    setSelectForm(undefined);
    setAppUuid(undefined);
    setActiveTabKey(Type.Fields);
    setFormList([]);
    setConditionList([]);
    setResultFields([]);
  };
  //关闭弹窗
  const onHandleCancel = () => {
    onCancel();
    reset();
  };
  //保存数据
  const onConfirm = () => {
    if (!conditionList.length || !resultFields.length) {
      message.warning('请选择联合查重的表单');
      return;
    }

    const resultVisiableFields = resultFields.filter(item => !item.hidden) || []
    if (resultVisiableFields.length === 0) {
      message.warning('至少启用1个查重结果显示字段');
      return;
    }
  
    const con = conditionList?.find((c) => c.status && !c.fieldId);
    if (con) {
      message.warning(`请选择${con?.targetFieldTitle}查重字段`);
      return;
    }
    setConfirmLoading(true);

    let params = {
      duplicationRule: {
        manyQueryConditionList: conditionList?.map((c) => [c]),
      },
      formCode: formCode,
      formDuplicationResultVisibleField: {
        visibleFieldList: resultFields,
      },
      targetFormCode: selectForm?.formCode,
      targetFormTitle: selectForm?.title,
      status: editForm ? editForm.status : 1,
      skipRuleSizeCheck: true,
      targetAppUuid: appUuid,
    };

    if (editForm) {
      editFormUnionDuplicationRule(editForm.id, params)
        .then((res) => {
          onOk && onOk();
          reset();
          message.success('保存成功');
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    } else {
      saveFormUnionDuplicationRule(params)
        .then((res) => {
          onOk && onOk();
          reset();
          message.success('保存成功');
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    }
  };

  //查重结果显示字段启用状态修改
  const handleResultFieldChange = (checked: boolean, index: number) => {
    try {
      resultFields[index] = {
        ...resultFields[index],
        hidden: !checked,
      };
      setResultFields([...resultFields]);
    } catch (error) {}
  };

  //查重结果字段列
  const ResultColumns = [
    {
      title: 'title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'editable',
      dataIndex: 'editable',
      key: 'editable',
      width: 80,
      render: (text: any, record: any, index: number) => {
        const { editable, fieldId, hidden } = record;
        return (
          <div style={{ textAlign: 'right' }}>
            <Switch
              disabled={fieldId === 'title' || !editable}
              checked={!hidden}
              onChange={(e) => {
                handleResultFieldChange(e, index);
              }}
            />
          </div>
        );
      },
    },
  ];

  //获取联合表单
  const getFormList = () => {
    getUnionDuplicationFormList(formCode).then((res) => {
      let duplicationFormList = res?.data || [];
      if (duplicationFormList?.length > 0 && isPersonalCustomerCombined) {
        duplicationFormList = duplicationFormList?.filter(
          (i) => i?.bizType === 'crm_contact',
        );
      }
      setFormList(duplicationFormList);
    });
  };

  useEffect(() => {
    visible && getFormList();
  }, [formCode, visible]);

  //查重字段列表
  const getFormSchemaForDesignStatusFunc = () => {
    getFormSchemaForDesignStatus({
      formCode: selectForm?.formCode!,
    }).then((res) => {
      const manyQueryConditionList =
        editForm?.duplicationRule?.manyQueryConditionList || [];
      const visibleFieldList =
        editForm?.formDuplicationResultVisibleField?.visibleFieldList || [];
      const defaultFields = isPersonalCustomerCombined
        ? PersonalCustomerDefaultResultFields
        : DefaultResultFields;
      const reFields = [...defaultFields.map((r) => r)].map((r) => {
        //结果列表编辑回显
        const obj = visibleFieldList?.find((v) => r?.fieldId === v?.fieldId);
        return obj ? { ...r, hidden: obj.hidden } : { ...r };
      });

      const data = res?.data || {};
      setAppUuid(data?.appUuid);
      const content = JSON.parse(data.content);
      const _list = Object.values(content?.properties || {}) || [];
    
      //不包含关联表单
      _list
        .filter((item) => {
          let supportList = SUPPORT_DUPLICAT_WIDGETS;
          if (isPersonalCustomerCombined) {
            return (
              supportList
                .includes(item?.widget) && !item?.hideControls
            );
          } else {
            return (
              supportList
                .filter((s) => s !== 'relation')
                .includes(item?.widget) && !item?.hideControls
            );
          }
        })
        .forEach((item) => {
          //编辑回显
          const rObj = visibleFieldList?.find((v) => item?.id === v?.fieldId);
          reFields.push({
            fieldId: item?.id,
            title: item?.title,
            hidden: rObj ? rObj.hidden : item?.hidden,
            editable: true,
          });
        });

      const cList = _list
        .filter((item) => {
          let supportList = isPersonalCustomerCombined
            ? PERSONAL_CUSTOMER_SUPPORT_DUPLICAT_WIDGETS
            : SUPPORT_DUPLICAT_WIDGETS;
          return (
            supportList.includes(item?.widget) &&
            !item?.hidden &&
            !item?.hideControls
          );
        })
        .map((item) => {
          //编辑回显
          const obj = manyQueryConditionList.find((m) => {
            return m[0]?.targetFieldId === item?.id;
          });

          return {
            fieldId: obj ? obj[0].fieldId : undefined,
            title: obj ? obj[0].title : undefined,
            targetFieldId: item?.id,
            targetFieldTitle: item?.title,
            status: obj ? obj[0].status : 0,
            filterType: 'EQ',
            widget: item?.widget,
            type: item?.type,
          };
        });

      setConditionList([...cList]); //查重字段列表
      setResultFields([...reFields]); //查重结果列表
    });
  };

  useEffect(() => {
    if (selectForm?.formCode) {
      getFormSchemaForDesignStatusFunc();
    }
  }, [selectForm]);

  const handleFormChange = (v?: IFormItem) => {
    setSelectForm(v);
  };

  useEffect(() => {
    if (editForm) {
      setSelectForm({
        formCode: editForm?.targetFormCode,
        title: editForm?.targetFormTitle,
      });
    }
  }, editForm);

  //规则预览文案
  const RuleText = useMemo(() => {
    const txtList = conditionList
      ?.filter((c) => {
        return c.fieldId && c.status == 1;
      })
      ?.map((s) => {
        return `【${formName}】的“${s.title}”和【${selectForm?.title}】的“${s.targetFieldTitle}”重复`;
      });

    return txtList?.length > 0
      ? `若${txtList.join(' 或')}，则判定为重复`
      : '--';
  }, [conditionList, selectForm]);

  //查看结果预览
  const selectedFormFields = useMemo(() => {
    return resultFields.filter((s) => {
      return s.editable && !s.hidden;
    });
  }, [resultFields]);

  const onChange = (checked: boolean, index: number) => {
    const _resultFields = cloneDeep(resultFields);
    _resultFields[index] = {
      ..._resultFields[index],
      hidden: !checked,
    };
    setResultFields(_resultFields);
  };

  const handleSortEnd = (ev: { oldIndex: number; newIndex: number }) => {
    const { oldIndex, newIndex } = ev;
    const _resultFields = cloneDeep(resultFields);
    _resultFields.splice(
      newIndex,
      1,
      ..._resultFields.splice(oldIndex, 1, _resultFields[newIndex]),
    );
    setResultFields(_resultFields);
  };
  return (
    <Modal
      visible={visible}
      onCancel={onHandleCancel}
      title={`${editForm ? '编辑' : '新建'}联合查重规则`}
      width={600}
      wrapClassName={styles['crm-duplicate-modal']}
      bodyStyle={{ padding: '0 24px' }}
      destroyOnClose
      maskClosable={false}
      footer={
        <div>
          <Button onClick={onHandleCancel}>取消</Button>
          <Button
            loading={confirmLoading}
            type="primary"
            onClick={() => onConfirm()}
          >
            确定
          </Button>
        </div>
      }
    >
      <>
        <div className={styles['select-form']}>
          <span>联合查重的表单</span>
          <Select
            style={{ width: 240, marginLeft: 12 }}
            virtual={false}
            placeholder="请选择"
            options={formList.map((item) => ({
              label: item?.title,
              value: item?.formCode,
            }))}
            onChange={(v, opt) => {
              handleFormChange(
                opt
                  ? {
                      formCode: opt.value,
                      title: opt.label,
                    }
                  : null,
              );
            }}
            value={selectForm?.formCode}
          />
        </div>
        <Tabs
          activeKey={activeTabKey}
          onTabClick={(e) => {
            setActiveTabKey(e);
          }}
        >
          <TabPane key={Type.Fields} tabKey={Type.Fields} tab="查重字段">
            {selectForm ? (
              <div className={styles['fields-wrap']}>
                <div className={styles['fields-info']}>
                  支持启用多组查重字段，任意一组精准匹配即算重复
                </div>
                <Table
                  className={styles['fields-table']}
                  dataSource={conditionList}
                  columns={FieldsColumns}
                  rowKey={'targetFieldId'}
                  scroll={{ y: 320 }}
                  pagination={false}
                />
                <div className={styles['fields-preview']}>
                  <div className={styles['fields-preview-title']}>
                    查重规则预览
                  </div>
                  <div className={styles['fields-preview-content']}>
                    {RuleText}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.empty}>
                <img src={NoData} />
                <div>请先选择联合查重表单</div>
              </div>
            )}
          </TabPane>

          <TabPane key={Type.Result} tabKey={Type.Result} tab="查重结果">
            {selectForm ? (
              <div className={styles['result-wrap']}>
                <div className={styles['result-info']}>查重结果显示字段</div>
                {isPersonalCustomerCombined ? (
                  <div className={styles['result-sortable']}>
                    <SortableList
                      onChange={onChange}
                      fields={[...resultFields]}
                      onSortEnd={(ev: {
                        oldIndex: number;
                        newIndex: number;
                      }) => {
                        handleSortEnd(ev);
                      }}
                    />
                  </div>
                ) : (
                  <Table
                    className={styles['result-table']}
                    dataSource={[...resultFields]}
                    columns={ResultColumns}
                    showHeader={false}
                    rowKey="fieldId"
                    scroll={{ y: 320 }}
                    pagination={false}
                    bordered={false}
                  />
                )}
                <div className={styles['result-preview']}>
                  <div className={styles['result-preview-title']}>
                    查重结果显示预览
                  </div>
                  <div
                    className={cx('crm-duplicate-fields-result-preview', {
                      'crm-duplicate-fields-result-preview-overflow':
                        selectedFormFields.length <= 5,
                    })}
                  >
                    {selectedFormFields.map((item) => (
                      <div key={item.fieldId}>{item.title}</div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles.empty}>
                <img src={NoData} />
                <div>请先选择联合查重表单</div>
              </div>
            )}
          </TabPane>
        </Tabs>
      </>
    </Modal>
  );
};

export default CombinedRuleModal;
