/*
 * :file description: page todoMessage
 * :name: \ui-renderd:\progree\low-code-platform\src\pages\todoMessage\index.tsx
 * :author: 宋海兵
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-28 16:14:19
 * :last editor: hyw
 * :date last edited: 2022-08-19 09:35:50
 */
import { useEffect, useRef, useState } from 'react';
import c from './index.less';
import _ from 'lodash';
import { Table, Card, message } from 'antd';
import FormDetail from '../../../tg-renderer/packages/form-render/src/form-render-core/src/FormDetail';
import {
  getCopyList,
  getDoneList,
  getStartList,
  getTodoList,
} from '@/services/todoMessage';
import React from 'react';
export const TodoMessageCtx = React.createContext('FormConfigCtx');

function TodoMessage() {
  const detailRef = useRef();
  const [tabKey, setTabKey] = useState('todoList');
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [initData, setInitData] = useState([]);
  const [instanceId, setInstanceId] = useState();
  const [fromCode, setFormCodeCode] = useState();
  const [processStatus, setProcessStatus] = useState();
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: '标题',
      key: 'instanceTitle',
      dataIndex: 'instanceTitle',
      render: (text: string, record: any) => <a>{text}</a>,
    },
    {
      title: '摘要',
      key: 'content',
      dataIndex: 'content',
      render: (text: string, record) => {
        const formData = JSON.parse(text||'{}');
        const schemaContent = JSON.parse(record.schemaContent);
        formData.createUserName = record.instanceCreateUserName;
        formData.gmtCreate = record.instanceGmtCreate;
        const dataSnippet = record?.propsSettings?.find(
          (item) => item.propsKey === 'dataSnippet',
        );
        const fieldStr = (key: string) => {
          const { widget } = schemaContent?.properties?.[key] || {};
          switch (widget) {
            case 'select':
            case 'phone':
              return formData?.[key]?.value;
            case 'multiSelect':
            case 'innerContact':
            case 'department':
              return formData?.[key]?.map((item) => item.value).join(',');
            case 'dateRange':
              return formData?.[key]?.join('~');
            case 'address':
              const { address, street, detail } = formData[key];
              return address.join('') + street + detail;
            default:
              return formData?.[key];
          }
        };

        return (
          <>
            {dataSnippet?.propsValue?.fields?.map((item) => (
              <span className={c.snippet} key={item.id}>
                {fieldStr(item.id)}
              </span>
            ))}
          </>
        );
      },
    },
    {
      title: '发起人',
      key: 'initiatorName',
      dataIndex: 'initiatorName',
      render: (text: string) => (
        <div>{text ? <div>由{text}提交</div> : '--'}</div>
      ),
    },
    {
      title: '发起时间',
      key: 'initiatorTime',
      dataIndex: 'initiatorTime',
      render: (text: string) => <div>{text}</div>,
    },
    {
      title: '完成时间',
      key: 'finishTime',
      dataIndex: 'finishTime',
      render: (text: string) => <div>{text ? text : '--'}</div>,
    },
    {
      title: '审批状态',
      key: 'processStatus',
      dataIndex: 'processStatus',
      render: (text: number) => (
        <a>
          {text === 1 ? '审批中' : null}
          {text === 2 ? '审批完成' : null}
          {text === 3 ? '审批终止' : null}
        </a>
      ),
    },
    {
      title: '审批结果',
      key: 'processResult',
      dataIndex: 'processResult',
      render: (text: any) => (
        <div>
          {text === 1 ? '已同意' : null}
          {text === 2 ? '已撤回' : null}
          {text === 3 ? '已拒绝' : null}
          {!text ? '--' : null}
        </div>
      ),
    },
  ];
  const tabListNoTitle = [
    {
      key: 'todoList',
      tab: '我的待办',
    },
    {
      key: 'startList',
      tab: '我发起的',
    },
    {
      key: 'doneList',
      tab: '我处理的',
    },
    {
      key: 'copyList',
      tab: '抄送我的',
    },
  ];

  // 页面改变时
  const changeTableData = async (current: number, pageSize: number) => {
    const params = { pageNum: current, pageSize: pageSize };
    commonFun(tabKey, params);
    setPageNum(current);
    setPageSize(pageSize);
  };

  // 查看运行态详情
  const showModal = (record) => {
    console.log(JSON.parse(record.content));
    setInstanceId(record.formInstanceId);
    setFormCodeCode(record.formCode);
    if (!record.formCode) {
      message.info('当前数据没有formCode，不可查看');
      return;
    }
    setProcessStatus(record.processStatus);
    _.delay(() => {
      detailRef.current.toggleDetail(true);
    }, 0);
  };

  // 切换不同tab
  const onTabChange = async (key: string) => {
    setPageNum(1);
    setPageSize(10);
    setTabKey(key);
    getList(key);
  };

  // 处理获取数据方式
  const commonFun = async (key: string, params: any) => {
    let res: any = null;
    setLoading(true);
    switch (key) {
      case 'todoList':
        res = await getTodoList(params);
        break;
      case 'startList':
        res = await getStartList(params);
        break;
      case 'doneList':
        res = await getDoneList(params);
        break;
      case 'copyList':
        res = await getCopyList(params);
        break;
      default:
        break;
    }
    setLoading(false);
    setInitData(res?.data || []);
  };

  // 获取列表数据
  const getList = async (key: string) => {
    let params = { pageNum: pageNum, pageSize: pageSize };
    commonFun(key, params);
  };

  useEffect(() => {
    getList(tabKey);
  }, []);

  const resetList = () => {
    getList(tabKey);
  };

  return (
    <div>
      <div className={c.orgTitle}>
        <div className={c.orgTitle_text}>待办消息</div>
      </div>
      <div className={c.authContain}>
        <Card
          activeTabKey={tabKey}
          tabList={tabListNoTitle}
          onTabChange={(key) => {
            onTabChange(key);
          }}
          style={{ width: '100%' }}
        >
          <Table
            columns={columns}
            dataSource={initData.records}
            loading={loading}
            pagination={{
              size: 'small',
              showSizeChanger: true, //设置每页显示数据条数
              showTotal: () => `共${initData.total}条`,
              pageSize: pageSize,
              total: initData.total, //数据的总的条数
              onChange: (current, pageSize) =>
                changeTableData(current, pageSize), //点击当前页码
              onShowSizeChange: (current, pageSize) => {
                changeTableData(current, pageSize);
              },
            }}
            onRow={(record) => {
              return {
                onClick: (event) => {
                  showModal(record);
                }, // 点击行
              };
            }}
            style={{ width: '100%' }}
          />
        </Card>
      </div>
      <TodoMessageCtx.Provider value={{ resetList, isTodo: true }}>
        {fromCode && (
          <FormDetail
            key={instanceId}
            ref={detailRef}
            instanceId={instanceId}
            formcode={fromCode}
            todoList="todoList"
            renderFormat="drawer"
            processStatus={processStatus}
          ></FormDetail>
        )}
      </TodoMessageCtx.Provider>
    </div>
  );
}

TodoMessage.wrappers = ['@/wrappers/pageAuth'];

export default TodoMessage;
