/*
 * @Description:
 * @FilePath: /low-code-platform/tg-renderer/packages/form-render/src/widgets/DefaultValueSelector/components/formula-editor/common/constant.ts
 * :author: wu.shangru
 * :copyright: (c) 2024, Tungee
 * @Date: 2024-01-08 12:05:06
 * @LastEditors: wu.shangru
 * @LastEditTime: 2024-01-14 12:13:22
 */
/*
 * :file description:
 * :name: \low-code-platform\src\widgets\DefaultValueSelector\components\formula-editor\common\constant.ts
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-04 09:57:35
 * :last editor: 黄艺
 * :date last edited: 2021-12-23 20:57:11
 */
export const NAME_FILED_CLS = 'cm-field-name';
export const VALUE_FIELD_CLS = 'cm-field-value';
export const INVALID_FIELD_CLS = 'cm-field-invalid';
export const DEPRECATE_FIELD_CLS = 'cm-deprecate';
export const dataTypeMap = new Map([
  ['string', '文本'],
  ['array', '数组'],
  ['number', '数字'],
]);
export const STATIC = {
  _st: new Date().getTime(),
  _ct: new Date().getTime(),
  zIndex: 8e3,
  Language: 'zh',
  num: 0,
  IDBase: new Date() - 0,
  CSRF: $('meta[name="csrf-token"]').attr('content'),
  site: window.location.protocol + '//' + window.location.hostname,
};

// 支持公式的字段 单行输入框、多行输入框、数字输入框、金额、日期
export const FORMULA_WIDGET_LIST = [
  'input',
  'textarea',
  'number',
  'money',
  'date',
  'department',
  'principal',
  'cooperator',
  'innerContact',
  'multiSelect',
  'multiCascade',
  'customerTags',
];
