/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/relation/shareData/constants.ts
 * :author: hyw
 * :copyright: (c) 2024, Tungee
 * :date created: 2024-01-23 09:34:54
 * :last editor: hyw
 * :date last edited: 2024-02-19 10:36:27
 */
export const SearchTypeOption = [
  {
    label: '精确查询',
    value: 'EQ',
  },
  {
    label: '模糊查询',
    value: 'SEARCH',
  },
];

export const SearchOption = [
  {
    label: '精确查询',
    value: 'EQ',
  },
];

export const CascaderOption = [
  {
    label: '精确查询',
    value: 'EQ',
  },
  {
    label: '范围',
    value: 'GEO_DISTANCE',
    children: [
      {
        label: '500m内',
        value: '0.5',
      },
      {
        label: '1km内',
        value: '1',
      },
      {
        label: '2km内',
        value: '2',
      },
      {
        label: '3km内',
        value: '3',
      },
    ],
  },
];

export const SUPPORT_SHAREDATA_WIDGETS = [
  'input',
  'textarea',
  'phone',
  'number',
  'select',
  'address',
  'seqNumber',
  'starRating',
  'multiSelect',
  'department',
  'date',
  'dateRange',
  'money',
  'innerContact',
  'relation',
  'principal',
  'cooperator',
  'email',
  'picture',
  'attachment',
  'multiTag',
  'salesStage',
  'cascade',
  'cascadeV2',
  'multiCascade',
];

export const SUPPORT_SHAREDATA_INPUT_WIDGETS = [
  'input',
  'textarea',
  'phone',
  'number',
  'email',
  'money',
  'picture',
  'attachment',
  'address',
  'seqNumber',
  'starRating',
];

export const SUPPORT_SHAREDATA_RELATION_WIDGETS = ['relation'];

export const SUPPORT_SHAREDATA_DIV_WIDGETS = [
  'department',
  'innerContact',
  'principal',
  'cooperator',
];

export const SUPPORT_SHAREDATA_DATE_WIDGETS = ['date', 'dateRange'];

export const SUPPORT_SHAREDATA_SELECT_WIDGETS = [
  'select',
  'multiSelect',
  'multiTag',
];

export const SUPPORT_SHAREDATA_CASCADE_WIDGETS = [
  'cascadeV2',
  'cascade',
  'multiCascade',
];

export const DOUBLE_SEARCHTYPE_WIDGETS = ['input', 'textarea'];

export const NOT_SUPPORT_WIDGETS = [
  'principal',
  'cooperator',
  'seqNumber',
  'multiSelect',
  'dateRange',
];

export const GMT_CREATE = {
  fieldId: 'gmtCreate',
  id: 'gmtCreate',
  title: '创建时间',
  hidden: false,
  editable: true,
};

// deprecated
// 运行态渲染类型
export enum RenderType {
  SEARCH = 'search',
  // 搜索条件
  CREATE = 'create',
  // 新建
}

export default {};

export enum SearchTypeLabel {
  EQUAL = '等于',
  NEQUAL = '不等于',
  SEARCH = '搜索',
  LT = '小于',
  LTE = '小于等于',
  GT = '大于',
  GTE = '大于等于',
  RANGE = '范围',
  BELONG = '属于',
  NBELONG = '不属于',
  BF = '早于',
  AF = '晚于',
  SORT = '排序',
  EXISTS = '不为空',
  NOTEXISTS = '为空',
}

export enum SearchTypeValue {
  EQUAL = 'EQ',
  NEQUAL = 'NEQ',
  SEARCH = 'SEARCH',
  LT = 'LT',
  LTE = 'LTE',
  GT = 'GT',
  GTE = 'GTE',
  RANGE = 'BETWEEN',
  BELONG = 'IN',
  NBELONG = 'NIN',
  BF = 'BF',
  AF = 'AF',
  SORT = 'SORT',
  EXISTS = 'EXISTS',
  NOTEXISTS = 'NOTEXISTS',
}

export const INTELLIGENT_TYPE_OPTIONS_OF_SBC = [
  {
    label: SearchTypeLabel.GTE,
    value: SearchTypeValue.GTE,
  },
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];

export const INTELLIGENT_TYPE_OPTIONS_OF_TC = [
  {
    label: SearchTypeLabel.LTE,
    value: SearchTypeValue.LTE,
  },
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];

//debugger
export const SEARCH_TYPE_OPTIONS_OF_E_S = [
  {
    label: SearchTypeLabel.BELONG,
    value: SearchTypeValue.BELONG,
  },
  {
    label: SearchTypeLabel.NBELONG,
    value: SearchTypeValue.NBELONG,
  },
  {
    label: SearchTypeLabel.EQUAL,
    value: SearchTypeValue.EQUAL,
  },
  {
    label: SearchTypeLabel.NEQUAL,
    value: SearchTypeValue.NEQUAL,
  },
  {
    label: SearchTypeLabel.SEARCH,
    value: SearchTypeValue.SEARCH,
  },
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];
export const SEARCH_TYPE_OPTIONS_OF_E_N = [
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_E_L_G_R = [
  {
    label: SearchTypeLabel.EQUAL,
    value: SearchTypeValue.EQUAL,
  },
  {
    label: SearchTypeLabel.NEQUAL,
    value: SearchTypeValue.NEQUAL,
  },
  {
    label: SearchTypeLabel.LT,
    value: SearchTypeValue.LT,
  },
  {
    label: SearchTypeLabel.GT,
    value: SearchTypeValue.GT,
  },
  {
    label: SearchTypeLabel.RANGE,
    value: SearchTypeValue.RANGE,
  },
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_E = [
  {
    label: SearchTypeLabel.EQUAL,
    value: SearchTypeValue.EQUAL,
  },
  {
    label: SearchTypeLabel.NEQUAL,
    value: SearchTypeValue.NEQUAL,
  },
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_E_P = [
  {
    label: SearchTypeLabel.BELONG,
    value: SearchTypeValue.BELONG,
  },
  {
    label: SearchTypeLabel.EQUAL,
    value: SearchTypeValue.EQUAL,
  },
  {
    label: SearchTypeLabel.NEQUAL,
    value: SearchTypeValue.NEQUAL,
  },
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_N = [
  {
    label: SearchTypeLabel.BELONG,
    value: SearchTypeValue.BELONG,
  },
  {
    label: SearchTypeLabel.NBELONG,
    value: SearchTypeValue.NBELONG,
  },
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];

export const SEARCH_TYPE_OPTIONS_E_N = [
  {
    label: SearchTypeLabel.EQUAL,
    value: SearchTypeValue.EQUAL,
  },
  {
    label: SearchTypeLabel.NEQUAL,
    value: SearchTypeValue.NEQUAL,
  },
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_B_NB_E_NE = [
  {
    label: SearchTypeLabel.BELONG,
    value: SearchTypeValue.BELONG,
  },
  {
    label: SearchTypeLabel.NBELONG,
    value: SearchTypeValue.NBELONG,
  },
  {
    label: SearchTypeLabel.EQUAL,
    value: SearchTypeValue.EQUAL,
  },
  {
    label: SearchTypeLabel.NEQUAL,
    value: SearchTypeValue.NEQUAL,
  },
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_B = [
  {
    label: SearchTypeLabel.BELONG,
    value: SearchTypeValue.BELONG,
  },
  {
    label: SearchTypeLabel.NBELONG,
    value: SearchTypeValue.NBELONG,
  },
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_B_E = [
  {
    label: SearchTypeLabel.BELONG,
    value: SearchTypeValue.BELONG,
  },
  {
    label: SearchTypeLabel.NBELONG,
    value: SearchTypeValue.NBELONG,
  },
  {
    label: SearchTypeLabel.EQUAL,
    value: SearchTypeValue.EQUAL,
  },
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_B_E_NE = [
  {
    label: SearchTypeLabel.BELONG,
    value: SearchTypeValue.BELONG,
  },
  {
    label: SearchTypeLabel.NBELONG,
    value: SearchTypeValue.NBELONG,
  },
  {
    label: SearchTypeLabel.EQUAL,
    value: SearchTypeValue.EQUAL,
  },
  {
    label: SearchTypeLabel.NEQUAL,
    value: SearchTypeValue.NEQUAL,
  },
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_B_A_R = [
  {
    label: SearchTypeLabel.BF,
    value: SearchTypeValue.LT,
  },
  {
    label: SearchTypeLabel.AF,
    value: SearchTypeValue.GT,
  },
  {
    label: SearchTypeLabel.NEQUAL,
    value: SearchTypeValue.NEQUAL,
  },
  {
    label: SearchTypeLabel.RANGE,
    value: SearchTypeValue.RANGE,
  },
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];
export const SEARCH_TYPE_OPTIONS_OF_TAG = [
  {
    label: SearchTypeLabel.BELONG,
    value: SearchTypeValue.BELONG,
  },
  {
    label: SearchTypeLabel.NBELONG,
    value: SearchTypeValue.NBELONG,
  },
  {
    label: SearchTypeLabel.EXISTS,
    value: SearchTypeValue.EXISTS,
  },
  {
    label: SearchTypeLabel.NOTEXISTS,
    value: SearchTypeValue.NOTEXISTS,
  },
];

export const notFormField = ['createUserName', 'gmtCreate', 'formTitle'];
export const BELONG_LIST = [SearchTypeValue.BELONG, SearchTypeValue.NBELONG];

// 进销存表单biztype
// 临时逻辑 后面让后端处理掉
// 隐藏进销存表单的导入功能
export const importPsiBizType = [
  // 'psi_supplier', 供应商 不需要隐藏 导入
  // 'psi_purchase_order', //采购单
  // 'psi_purchase_storage', //采购入库单
  // 'psi_purchase_return', //采购退货单
  // 'psi_sale_outbound', //销售出库单
  // 'psi_sale_return', //销售退货单
  'psi_stock_inquiry', // 库存查询
  // 'psi_inventory', // 盘点
  // 'psi_rest_storage', //其他入库单
  // 'psi_rest_outbound', //其他出库单
  'psi_stock_flow',
];

// TOFIX
export const importTemBizType = [
  // 'psi_supplier', 供应商 不需要隐藏 导入
  'psi_purchase_order', // 采购单
  'psi_purchase_storage', // 采购入库单
  'psi_purchase_return', // 采购退货单
  'psi_sale_outbound', // 销售出库单
  'psi_sale_return', // 销售退货单
  'psi_inventory', // 盘点
  'psi_rest_storage', // 其他入库单
  'psi_rest_outbound', // 其他出库单
];

// 隐藏编辑
export const editPsiBizType = [
  'psi_stock_inquiry',
  'psi_inventory',
  'psi_stock_flow',
  'crm_legwork',
  'bookbaba_distribution',
];

// 隐藏批量编辑
export const batchEditPsiBizType = [
  'psi_stock_inquiry',
  'psi_inventory',
  'psi_stock_flow',
  'pms_project',
  'pms_task',
];

// 隐藏新建
export const createPsiBizType = ['psi_stock_inquiry', 'psi_stock_flow'];

// 隐藏删除
export const delelePsiBizType = [
  'psi_stock_inquiry',
  'psi_inventory',
  'psi_stock_flow',
];

export const TABLE_CELL_BIZALIAS = ['customer_name', 'contact_name'];

export const PHONE_NAME_FIELD_BIZALIAS = {
  customer_phone: 'customer_name',
  contact_phone: 'contact_name',
};

export const EXISTS_TYPE = ['EXISTS', 'NOTEXISTS'];

export const SUPPORT_SHAREDATA_WIDGETS_TYPES = {
  seqNumber: SEARCH_TYPE_OPTIONS_OF_E_S,
  input: SEARCH_TYPE_OPTIONS_OF_E_S,
  textarea: SEARCH_TYPE_OPTIONS_OF_E_S,
  number: SEARCH_TYPE_OPTIONS_OF_E_L_G_R,
  money: SEARCH_TYPE_OPTIONS_OF_E_L_G_R,
  starRating: SEARCH_TYPE_OPTIONS_OF_E_L_G_R,
  select: SEARCH_TYPE_OPTIONS_OF_B_NB_E_NE,
  multiSelect: SEARCH_TYPE_OPTIONS_OF_B,
  date: SEARCH_TYPE_OPTIONS_OF_B_A_R,
  dateRange: SEARCH_TYPE_OPTIONS_OF_B_A_R,
  phone: SEARCH_TYPE_OPTIONS_OF_E_S,
  department: SEARCH_TYPE_OPTIONS_OF_B_E,
  innerContact: SEARCH_TYPE_OPTIONS_OF_B_E,
  principal: SEARCH_TYPE_OPTIONS_OF_B_E,
  cooperator: SEARCH_TYPE_OPTIONS_OF_B_E,
  address: SEARCH_TYPE_OPTIONS_OF_E_S,
  email: SEARCH_TYPE_OPTIONS_OF_E_S,
  picture: SEARCH_TYPE_OPTIONS_OF_E_N,
  attachment: SEARCH_TYPE_OPTIONS_OF_E_N,
  multiTag: SEARCH_TYPE_OPTIONS_OF_TAG, // 客户标签
  salesStage: SEARCH_TYPE_OPTIONS_OF_B_NB_E_NE, // 销售阶段
  cascade: SEARCH_TYPE_OPTIONS_OF_E_P, // 产品分类
  cascadeV2: SEARCH_TYPE_OPTIONS_OF_E, // 级联
  multiCascade: SEARCH_TYPE_OPTIONS_OF_N, // 级联
  relation: SEARCH_TYPE_OPTIONS_E_N, // 关联表单
};
