/*
 * @Description:
 * @FilePath: /low-code-platform/tg-renderer/packages/form-render/src/widgets/MultiCascade/index.tsx
 * :author: wu.shangru
 * :copyright: (c) 2023, Tungee
 * @Date: 2023-12-05 15:11:19
 * @LastEditors: wu.shangru
 * @LastEditTime: 2024-01-13 14:59:24
 */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  useMemo,
} from 'react';
import { Select, TreeSelect } from 'antd';
import { get } from 'lodash-es';
import { FC } from 'react';
import { WidgetProps } from 'typings';
import { IFormCompProps } from '../../typings';
import JumpLink from '../JumpLink';
import { useGlobal, useStore } from '@/components/FormGenerator/utils/hooks';

import './index.less';

interface IItem {
  key: string;
  value?: string;
}

const { Option } = Select;

const MultiCascadeWidget: FC<WidgetProps<IItem>> = (props) => {
  const { value, onChange = () => {}, schema = {}, addons = {} } = props;
  const {
    setValues,
    setValueByPath,
    getValues,
    setSchemaByPath,
    formData = {},
  } = addons;
  const { enumOptionId } = schema;
  const { placeholder } = schema;
  const multiple = useMemo(() => {
    return schema?.widget === 'multiCascade' ? true : false;
  }, [schema]);

  const [treeData, setTreeData] = useState([]);

  // 缓存级联选项
  const { flatten, cascadesOptions } = useStore();

  if (props.readOnly) {
    return <span className="showNoEditText">{props?.value?.value || ''}</span>;
  }

  const filterTree = (targetArr, tree, res = []) => {
    if (!targetArr || !targetArr?.length || !tree?.length) {
      return;
    }
    for (let i = 0; i < tree?.length; ++i) {
      if (targetArr?.includes(tree[i]?.id)) {
        res.push(tree?.[i]);
      }
      if (tree?.[i]?.children?.length) {
        filterTree(targetArr, tree[i]?.children, res);
      }
    }
    return res;
  };

  useEffect(() => {
    if (enumOptionId) {
      setTreeData(cascadesOptions?.[enumOptionId]?.cascadeOptions ?? []);
    } else {
      setTreeData([]);
    }
  }, [enumOptionId, cascadesOptions]);
  return (
    <TreeSelect
      multiple={multiple}
      showSearch
      style={{ width: '100%' }}
      value={value?.map((i) => i.id)}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder={placeholder}
      allowClear
      onChange={(value, label, extra) => {
        const reArr = filterTree(value, treeData, []);
        const targetValueArr = reArr?.map((i) => {
          return {
            id: i?.id,
            name: i?.name,
            code: i?.code,
            parentId: i?.parentId,
            fullIdPath: i?.fullIdPath?.split('/') ?? [],
            fullNamePath: i?.fullNamePath?.split('/') ?? [],
          };
        });
        onChange?.(targetValueArr);
      }}
      treeData={treeData}
      fieldNames={{ label: 'name', value: 'id', children: 'children' }}
    />
  );
};

export default MultiCascadeWidget;
