/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\Sift_AssociationSelector\index.tsx
 * author: gaoch
 * copyright: (c) 2022, Tungee
 * :date created: 2022-01-07 19:20:33
 * :last editor: gaoch
 * :date last edited: 2022-02-15 14:58:33
 */
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Modal, Input, Table, Button, Select, LabeledValue } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import { debounce, pick, get } from 'lodash-es';
import {
  genTableColumns,
  genTableData,
} from '../../../../table-render/src/utils';
import formApi from '../../services/api';
import { getSchema } from '../../hooks';
import { isJSON, dropSearchTypeProp, customJP } from '../../utils';
import {
  SearchTypeValue,
  SEARCH_TYPE_OPTIONS_OF_E,
  RenderType,
} from '../../constant';
import { IFormCompProps } from '../../typings';
import { genRealtionDom } from './relation_template.js';
import c from './index.less';
import IconFont from '@/components/IconFont';

type WidgetProps = IFormCompProps;

const { Option } = Select;

export default function AssociationSelector(props: WidgetProps) {
  const { value, onChange = () => {}, schema = {} } = props;
  const { searchType: initialSearchType, value: initialValue } = isJSON(value)
    ? customJP(value)
    : {
        searchType: undefined,
        value: undefined,
      };
  const {
    title: initialTitle,
    rendertype: initialRendertype,
    hideSearchType = false,
  } = schema;

  const formCode = schema.formCode || get(schema, 'dataSource.target.formCode');

  const defaultValue = SearchTypeValue.EQUAL;

  // TODO
  const [searchType, setSearchType] = useState(
    initialSearchType || defaultValue,
  );
  const [selected, setSelected] = useState(initialValue);

  const [keyword, setKeyword] = useState<string | undefined>();
  const [options, setOptions] = useState<LabeledValue[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [relationDetail, setRelationDetail] = useState();
  const [innerSchema, setInnerSchema] = useState();
  const [columns, setColumns] = useState<ColumnType<object>[]>([]);
  const [dataSource, setDataSource] = useState([]);
  const [pageParam, setPageParam] = useState({
    pageSize: 10,
  });

  const schemaData = getSchema();
  // 根据选择关联表单时选择的字段展示关联表单信息
  const filterRelationInfo = (row) => {
    const checkedKeys = Object.keys(
      schemaData?.content?.properties?.[schema.$id]?.fields || {},
    );
    return pick(row, checkedKeys);
  };

  const rowSelection = {
    type: 'radio',
    fixed: true,
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows) => {
      console.log(selected, selectedRows);
    },
  };

  const genImgAry = (val: unknown) => {
    if (typeof val === 'string') {
      return val.split(',');
    } else if (Array.isArray(val)) {
      return val;
    }
    return [];
  };

  const customCompDetail = (v) => {
    let widget =
      schemaData?.content?.properties?.[schema.$id]?.fields?.[v]?.widget;
    let val = selectedRows[0][v];
    if (widget === 'picture') {
      return (
        <div>
          {genImgAry(val)?.map((item, idx) => {
            return (
              <div
                key={idx}
                style={{
                  display: 'inline-block',
                  margin: '0 16px 6px 0',
                  border: '1px solid #7E868E',
                  borderRadius: '6px',
                }}
              >
                <img src={item} width="48" height="48" />
              </div>
            );
          })}
        </div>
      );
    } else if (widget === 'relation') {
      return JSON.stringify(val);
    } else if (widget === 'phone') {
      return isJSON(val) ? customJP(val)?.value : val;
    } else if (typeof val === 'string' || typeof val === 'number') {
      return val;
    } else if (Array.isArray(val)) {
      if (['date', 'dateRange'].includes(widget)) {
        return val.join('~');
      } else {
        return val.join('，');
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    console.log('isModalVisible: ', isModalVisible);
    if (!isModalVisible) {
      setKeyword(undefined);
    } else {
      handleSearch();
    }
  }, [isModalVisible]);

  useEffect(() => {
    isModalVisible && debounceSearch();
  }, [keyword]);

  useEffect(() => {
    if (selected && isJSON(selected)) {
      const valueObj = customJP(value);
      setSearchType(valueObj.searchType);
      setSelected(valueObj.value);
      getFormSchema(valueObj.value);
    }
  }, [selected]);

  const getFormSchema = async () => {
    if (!formCode) return;
    const res = await formApi.getFormSchema({ formCode });
    setInnerSchema(
      isJSON(res?.data?.content) ? customJP(res?.data?.content) : {},
    );
    await getRealationDetail();
  };

  const getRealationDetail = async () => {
    if (!isJSON(selected)) return;
    const { instanceId } = customJP(selected) || {};
    const res = await formApi.getFromInstanceDetail(
      {
        formCode,
        instanceId,
      },
      {
        headers: {
          formCode,
        },
      },
    );
    const contentObj = isJSON(res?.data?.content)
      ? customJP(res?.data?.content)
      : {};
    setRelationDetail(contentObj);
    console.log('@res: ', res);
  };

  const handleSearch = ({ pageIndex = 1, pageSize = 10 } = {}) => {
    console.log('查询');

    formApi
      .queryFormDataList({
        formCode,
        keyword: keyword ? keyword : undefined,
        pageNum: pageIndex,
        pageSize,
        showTitle: true,
        // order: [],
        // sort: [],
      })
      .then((res) => {
        // 前端生成实例标题
        // res.data?.formHeaderFieldList?.unshift({
        //   fieldId: "title",
        //   title: "标题",
        //   type: "string",
        //   widget: "input",
        // });
        // res.data?.instanceDataList?.map(item => {
        //   const genTitle = item.itemList.find(v => v.fieldId === 'createUserId')?.value + '提交的' + title;
        //   item.title = genTitle;
        //   return {
        //     ...item,
        //     itemList: item.itemList.unshift({
        //       fieldId: "title",
        //       title: "标题",
        //       type: "string",
        //       value: genTitle,
        //       widget: "input",
        //     })
        //   };
        // })
        setPageParam({ pageSize: res?.data?.size ?? 10 });
        setColumns(genTableColumns(res.data));
        setDataSource(genTableData(res.data));
      });
  };

  const debounceSearch = useCallback(debounce(handleSearch, 200), [keyword]);

  const handleConfirm = () => {
    if (!selectedRows?.length) {
      return;
    } else {
      setOptions([
        {
          ...selectedRows[0],
          label: selectedRows[0].title,
          value: selectedRows[0].instanceId,
        },
      ]);
      const payload = {
        searchType,
        value: {
          ...(selectedRows?.[0] as object),
          formCode,
        },
      };
      console.log('selectedRows???: ', selectedRows);
      setSelected(JSON.stringify(selectedRows?.[0]));
      onChange(
        selectedRows.length
          ? JSON.stringify(dropSearchTypeProp(payload, hideSearchType))
          : '',
      );
      // if (rendertype === RenderType.SEARCH) {
      //   onChange && onChange(selectedRows?.[0]?.instanceId);
      // } else {
      //   onChange && onChange(selectedRows?.[0]);
      // }
    }
  };

  const handleOk = () => {
    handleConfirm();
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSelect = () => {
    setIsModalVisible(true);
  };

  const handleReselect = () => {
    setIsModalVisible(true);
  };

  const handleClear = () => {
    setOptions([]);
    onChange(undefined);
  };

  const pagination = {
    total: dataSource?.length,
    showTotal: () => (
      <div
        style={{
          marginRight: '10px',
        }}
      >
        共 {dataSource?.length} 项
      </div>
    ),
    showSizeChanger: true,
    pageSizeOptions: [5, 10],
    onChange: (pageIndex, pageSize) => {
      console.log(pageIndex, pageSize);
      handleSearch({ pageIndex, pageSize });
    },
  };

  return (
    <div className={c.t_association_form_selector}>
      {initialRendertype === RenderType.SEARCH ? (
        <Input.Group compact className="fr-group">
          {!hideSearchType && (
            <Select
              style={{ width: '30%' }}
              // allowClear
              defaultValue={defaultValue}
              value={searchType}
              onChange={(e) => setSearchType(e)}
            >
              {SEARCH_TYPE_OPTIONS_OF_E.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          )}
          <Select
            style={{ width: '70%' }}
            allowClear
            value={isJSON(selected) ? customJP(selected)?.value?.title : ''}
            options={options}
            placeholder="请选择"
            onDropdownVisibleChange={handleSelect}
            onClear={handleClear}
          />
        </Input.Group>
      ) : !value && !options?.length ? (
        <Button onClick={handleSelect}>请选择</Button>
      ) : (
        <>
          <div>
            <Button type="text">
              {options?.[0]?.label || isJSON(value)
                ? customJP(value)?.value?.title
                : ''}
            </Button>
            <Button type="link" onClick={handleReselect}>
              重新选择
            </Button>
            <Button type="link" onClick={handleClear}>
              清除
            </Button>
          </div>
          <div>
            {selectedRows?.[0] ? (
              <div className={c.relation_zone}>
                {Object.keys(filterRelationInfo(selectedRows[0] ?? {})).map(
                  (v) => (
                    // 根据新建form时限定的字段展示
                    <>
                      <div className={c.label}>
                        {
                          schemaData?.content?.properties?.[schema.$id]
                            ?.fields?.[v]?.title
                        }
                      </div>
                      <div className={c.value}>{customCompDetail(v)}</div>
                    </>
                  ),
                )}
              </div>
            ) : null}
            {!selectedRows?.[0] &&
              isJSON(value) &&
              relationDetail &&
              innerSchema &&
              genRealtionDom({
                schema: innerSchema,
                extendValue: isJSON(value) ? customJP(value) : {},
                relationDetail: relationDetail ?? {},
              })}
          </div>
        </>
      )}
      <Modal
        width={1000}
        title={initialTitle}
        visible={isModalVisible}
        okText="确定"
        cancelText="取消"
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <Input
          value={keyword}
          prefix={<SearchOutlined />}
          placeholder="请输入"
          allowClear
          onChange={(e) => setKeyword(e.target.value)}
        />

        <Table
          rowKey="instanceId"
          ellipsis
          scroll={{ x: 'max-content', y: false }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataSource}
          pagination={{
            ...pagination,
            ...pageParam,
          }}
        />
      </Modal>
    </div>
  );
}
