/*
 * :file description:
 * :name: \low-code-platform\src\components\renderer\TNumberRange\index.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-11-01 20:57:05
 * :last editor: gaoch
 * :date last edited: 2021-11-19 15:21:47
 */

import React, { ReactNode, useState } from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import _ from 'lodash';
import c from './index.less';

const DEFAULT_CONFIG = {
  minPlaceholder: '最小值',
  maxPlaceholder: '最大值',
  allowClear: true,
  onChange: () => {},
};

const TNumberRange = (props: InputNumberProps & { [key: string]: any }) => {
  const component = (): ReactNode => {
    const { minPlaceholder, maxPlaceholder } = DEFAULT_CONFIG;
    const { onChange, value } = props;
    const [min, setMin] = useState('');
    const [max, setMax] = useState('');

    const isConsistent = (value, type) => {
      const maxima = type === 'max' ? value : max;
      const minima = type === 'min' ? value : min;
      return (_.isNumber(maxima) && _.isNumber(minima)) || (!maxima && !minima);
    };

    const handleChange = (value, type) => {
      if (type === 'min') {
        setMin(value);
        isConsistent(value, 'min') && onChange && onChange([value, max]);
      } else {
        setMax(value);
        isConsistent(value, 'max') && onChange && onChange([min, value]);
      }
    };
    return (
      <>
        <InputNumber
          style={{ width: 'calc(50% - 10px)' }}
          placeholder={minPlaceholder}
          {...props}
          onChange={(e) => handleChange(e, 'min')}
        />
        <span style={{ margin: '0 5px' }}>~</span>
        <InputNumber
          style={{ width: 'calc(50% - 10px)' }}
          placeholder={maxPlaceholder}
          {...props}
          onChange={(e) => handleChange(e, 'max')}
        />
      </>
    );
  };

  return <div className={c.t_number_range}>{component()}</div>;
};

export default TNumberRange;
