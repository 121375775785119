import React from 'react';
// import c from './index.less';
import { Checkbox, Tooltip } from 'antd';
import IconFont from '../IconFont';
import c from './Principal.less';

interface IProps {
  principal: any;
  onChange: (checked: any, ele: any) => void;
}

export default (props: IProps) => {
  const { onChange, principal = [] } = props;

  return (
    <div>
      {principal?.map((ele: any) => (
        <Checkbox
          className={c.checkBox}
          onChange={(e) => onChange(e, ele)}
          checked={ele.checked}
          key={ele.id}
        >
          <span className={c.text}>
            {ele.name}
            <Tooltip title="当前表单负责人，可能是多个">
              <IconFont type="icon-shuoming" className={c.tipImg} />
            </Tooltip>
          </span>
        </Checkbox>
      ))}
    </div>
  );
};
