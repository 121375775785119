/*
 * :file description:
 * :name: \commond:\progree\low-code-platform\src\pages\generator\process\components\FlowV3\mock.ts
 * :author: Quan
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-10 14:44:33
 * :last editor: hyw
 * :date last edited: 2021-12-28 19:02:50
 */
import { getNodeUuid } from '@/utils';
export const mockData = {
  formRelation: {
    appUuid: '123',
    corpId: 'ding8231c58ff1d0ae01a1320dcb25e91351',
    formCode: 'PROC-CE3971BB-95CE-4C32-B38F-C4E1CF25E013',
    userId: '27cc7d6769cea99aa9b6150f5083ab99',
  },
  processConfig: {
    globalSetting: {
      actionFindNotAssignee: 1,
      // approvalType: 2,
      isAutoPassWhenRepeated: false,
      isRevokeProcess: false,
      canAppendTask: false,
      // requiredComment: false,
    },
    processDefinition: {
      children: [
        {
          children: null,
          componentName: 'ApplyNode',
          id: getNodeUuid() || 'node_4629bec77c2847dda0bc9ee58226fbf8',
          props: {
            name: '发起人',
            nodeName: 'ApplyNode',
            approverRules: {
              approvals: [],
            },
          },
        },
        // {
        //   children: null,
        //   componentName: 'ApprovalNode',
        //   id: 'node_9ea64bc60ffc4dcb97cfcd31771ed7aa',
        //   title: '审核02',
        //   props: {
        //     name: '审核02',
        //     nodeName: 'ApprovalNode',
        //     approvalType: '2',
        //     requiredComment: false,
        //     approverRules: {
        //       approvals: [
        //         {
        //           id: 'de2754b8251424c818bf25dac9cab4a7',
        //           label: 'huajian',
        //           type: 'employee',
        //         },
        //         {
        //           id: '27cc7d6769cea99aa9b6150f5083ab99',
        //           label: 'huangyi',
        //           type: 'employee',
        //         },
        //       ],
        //     },
        //   },
        // },
        // {
        //   children: null,
        //   componentName: 'CarbonNode',
        //   id: 'node_60757600feed40c48a17bfd2900ab3c6',
        //   title: '抄送01',
        //   props: {
        //     name: '抄送01',
        //     nodeName: 'CarbonNode',
        //     approverRules: {
        //       approvals: [
        //         {
        //           id: 'de2754b8251424c818bf25dac9cab4a7',
        //           label: 'huajian',
        //           type: 'employee',
        //         },
        //       ],
        //     },
        //   },
        // },

        // {
        //   children: null,
        //   componentName: 'ApprovalNode',
        //   id: 'node_a5b05cac3f804bda9f88d94ef44cc3aa',
        //   title: '最后的审核结点',
        //   props: {
        //     name: '最后的审核结点',
        //     nodeName: 'ApprovalNode',
        //     approvalType: '1',
        //     requiredComment: true,
        //     approverRules: {
        //       approvals: [
        //         {
        //           id: 'de2754b8251424c818bf25dac9cab4a7',
        //           label: 'huajian',
        //           type: 'employee',
        //         },
        //         {
        //           id: '27cc7d6769cea99aa9b6150f5083ab99',
        //           label: 'huangyi',
        //           type: 'employee',
        //         },
        //       ],
        //     },
        //   },
        // },
      ],
      processKey: 'PROC-CE3971BB-95CE-4C32-B38F-C4E1CF25E013',
      processName: '',
    },
  },
};

export const demo = [
  {
    children: null,
    componentName: 'ApplyNode',
    id: 'node_4629bec77c2847dda0bc9ee58226fbf81',
    title: '申请',
  },
  // {
  //   children: null,
  //   componentName: 'ApprovalNode',
  //   id: 'node_9ea64bc60ffc4dcb97cfcd31771ed7aa2',
  //   title: '审核01',
  // },
  // {
  //   children: [
  //     {
  //       children: [
  //         {
  //           children: null,
  //           componentName: 'ApprovalNode',
  //           id: 'node_2aecf72b721743deba743e28a182e5e63',
  //           title: '条件节点221-审核01',
  //         },
  //       ],
  //       componentName: 'ConditionNode',
  //       id: 'node_2920d8bb0b5e41cfb8a9c8606ac086fd4',
  //       title: '条件节点221',
  //     },
  //     {
  //       children: [
  //         {
  //           children: null,
  //           componentName: 'ApprovalNode',
  //           id: 'node_9c1b9e4a16374c31ba8a31ecd5c261c35',
  //           title: '条件节点222-审核01',
  //         },
  //       ],
  //       componentName: 'ConditionNode',
  //       id: 'node_7fa47fa539fb4be1b58e324e8b510c1e6',
  //       title: '条件节点222',
  //     },
  //   ],
  //   componentName: 'ConditionContainerNode',
  //   id: 'node_02daa5f19f634a44ba01642a20ef0f867',
  //   title: '网关22',
  // },
  // {
  //   children: [
  //     {
  //       children: [
  //         {
  //           children: null,
  //           componentName: 'ApprovalNode',
  //           id: 'node_2aecf72b721743deba743e28a182e5e68',
  //           title: '条件节点221-审核01',
  //         },
  //       ],
  //       componentName: 'ConditionNode',
  //       id: 'node_2920d8bb0b5e41cfb8a9c8606ac086fd9',
  //       title: '条件节点221',
  //     },
  //     {
  //       children: [
  //         {
  //           children: null,
  //           componentName: 'ApprovalNode',
  //           id: 'node_9c1b9e4a16374c31ba8a31ecd5c261c310',
  //           title: '条件节点222-审核01',
  //         },
  //       ],
  //       componentName: 'ConditionNode',
  //       id: 'node_7fa47fa539fb4be1b58e324e8b510c1e11',
  //       title: '条件节点222',
  //     },
  //   ],
  //   componentName: 'ConditionContainerNode',
  //   id: 'node_02daa5f19f634a44ba01642a20ef0f8712',
  //   title: '网关22',
  // },
  // {
  //   children: null,
  //   componentName: 'CarbonNode',
  //   id: 'node_60757600feed40c48a17bfd2900ab3c613',
  //   title: '抄送01',
  // },
];

export const mockData1 = {
  formRelation: {
    appUuid: '123',
    corpId: 'ding8231c58ff1d0ae01a1320dcb25e91351',
    formCode: 'PROC-CE3971BB-95CE-4C32-B38F-C4E1CF25E013',
    userId: '27cc7d6769cea99aa9b6150f5083ab99',
  },
  processConfig: {
    globalSetting: {
      actionFindNotAssignee: 1,
      // approvalType: 2,
      isAutoPassWhenRepeated: false,
      isRevokeProcess: false,
      canAppendTask: false,
      // requiredComment: false,
    },
    processDefinition: {
      children: [
        {
          children: null,
          componentName: 'ApplyNode',
          id: 'node_4629bec77c2847dda0bc9ee58226fbf8',
          props: {
            name: '申请',
            nodeName: 'ApplyNode',
            approverRules: {
              approvals: [
                {
                  id: 'de2754b8251424c818bf25dac9cab4a7',
                  label: 'huajian',
                  type: 'employee',
                },
                {
                  id: '27cc7d6769cea99aa9b6150f5083ab99',
                  label: 'huangyi',
                  type: 'employee',
                },
              ],
            },
          },
        },
        {
          children: null,
          componentName: 'ApprovalNode',
          id: 'node_9ea64bc60ffc4dcb97cfcd31771ed7aa',
          title: '审核01',
          props: {
            name: '审核01',
            nodeName: 'ApprovalNode',
            approvalType: 2,
            approverRules: {
              approvals: [
                {
                  id: 'de2754b8251424c818bf25dac9cab4a7',
                  label: 'huajian',
                  type: 'employee',
                },
                {
                  id: '27cc7d6769cea99aa9b6150f5083ab99',
                  label: 'huangyi',
                  type: 'employee',
                },
              ],
            },
          },
        },
        {
          children: null,
          componentName: 'CarbonNode',
          id: 'node_60757600feed40c48a17bfd2900ab3c6',
          title: '抄送01',
          props: {
            name: '抄送01',
            nodeName: 'CarbonNode',
            approverRules: {
              approvals: [
                {
                  id: 'de2754b8251424c818bf25dac9cab4a7',
                  label: 'huajian',
                  type: 'employee',
                },
              ],
            },
          },
        },
        {
          children: [
            {
              children: [
                {
                  children: null,
                  componentName: 'ApprovalNode',
                  id: 'node_2aecf72b721743deba743e28a182e5e6',
                  title: '条件节点1-审核01',
                  props: {
                    name: '条件节点1-审核01',
                    nodeName: 'ApprovalNode',
                    approvalType: 2,
                    approverRules: {
                      approvals: [
                        {
                          id: 'de2754b8251424c818bf25dac9cab4a7',
                          label: 'huajian',
                          type: 'employee',
                        },
                        {
                          id: '27cc7d6769cea99aa9b6150f5083ab99',
                          label: 'huangyi',
                          type: 'employee',
                        },
                      ],
                    },
                  },
                },
                {
                  children: [
                    {
                      children: [
                        {
                          children: null,
                          componentName: 'ApprovalNode',
                          id: 'node_2aecf72b721743deba743e28a182e5e6',
                          title: '条件节点221-审核01',
                          props: {
                            name: '条件节点221-审核01',
                            nodeName: 'ApprovalNode',
                            approvalType: 2,
                            approverRules: {
                              approvals: [
                                {
                                  id: 'de2754b8251424c818bf25dac9cab4a7',
                                  label: 'huajian',
                                  type: 'employee',
                                },
                                {
                                  id: '27cc7d6769cea99aa9b6150f5083ab99',
                                  label: 'huangyi',
                                  type: 'employee',
                                },
                              ],
                            },
                          },
                        },
                      ],
                      componentName: 'ConditionNode',
                      id: 'node_2920d8bb0b5e41cfb8a9c8606ac086fd',
                      props: {
                        description: null,
                        isDefault: null,
                        name: '条件节点221-条件',
                        nodeName: 'ConditionNode',
                        priority: 1,
                        conditions: [
                          [
                            {
                              type: 'multi_component_condition',
                              paramKey: 'Keyword_A1VSJ_2YF1JZ',
                              paramLabel: '多选1',
                              matchType: '2',
                              paramValues: [
                                'option_1',
                                'option_2',
                                'option_3',
                                'option_4',
                              ],
                            },
                            {
                              type: 'single_component_condition',
                              paramKey: 'Keyword_rPdXS4pyQ3um',
                              paramLabel: '单选2',
                              paramValues: ['option_1', 'option_2', 'option_4'],
                            },
                          ],
                          [
                            {
                              type: 'multi_component_condition',
                              paramKey: 'Keyword_A1VSJ_2YF1JZ',
                              paramLabel: '多选1',
                              matchType: '2',
                              paramValues: ['option_1', 'option_2'],
                            },
                            {
                              type: 'single_component_condition',
                              paramKey: 'Keyword_rPdXS4pyQ3um',
                              paramLabel: '单选2',
                              paramValues: ['option_1', 'option_2', 'option_4'],
                            },
                          ],
                        ],
                      },
                    },
                    {
                      children: [
                        {
                          children: null,
                          componentName: 'ApprovalNode',
                          id: 'node_9c1b9e4a16374c31ba8a31ecd5c261c3',
                          title: '条件节点222-审核01',
                          props: {
                            name: '条件节点222-审核01',
                            nodeName: 'ApprovalNode',
                            approvalType: 2,
                            approverRules: {
                              approvals: [
                                {
                                  id: 'de2754b8251424c818bf25dac9cab4a7',
                                  label: 'huajian',
                                  type: 'employee',
                                },
                                {
                                  id: '27cc7d6769cea99aa9b6150f5083ab99',
                                  label: 'huangyi',
                                  type: 'employee',
                                },
                              ],
                            },
                          },
                        },
                      ],
                      componentName: 'ConditionNode',
                      id: 'node_7fa47fa539fb4be1b58e324e8b510c1e',
                      title: '条件节点222',
                      props: {
                        description: null,
                        isDefault: null,
                        name: '条件节点222-条件',
                        nodeName: 'ConditionNode',
                        priority: 1,
                        conditions: [
                          [
                            {
                              type: 'multi_component_condition',
                              paramKey: 'Keyword_A1VSJ_2YF1JZ',
                              paramLabel: '多选1',
                              matchType: '2',
                              paramValues: ['option_1', 'option_2'],
                            },
                            {
                              type: 'single_component_condition',
                              paramKey: 'Keyword_rPdXS4pyQ3um',
                              paramLabel: '单选2',
                              paramValues: ['option_1', 'option_2', 'option_6'],
                            },
                          ],
                        ],
                      },
                    },
                  ],
                  componentName: 'ConditionContainerNode',
                  id: 'node_02daa5f19f634a44ba01642a20ef0f86',
                  title: '网关22',
                },
              ],
              componentName: 'ConditionNode',
              id: 'node_2920d8bb0b5e41cfb8a9c8606ac086fd',
              title: '条件节点1',
              props: {
                description: null,
                isDefault: null,
                name: '条件节点1-条件',
                nodeName: 'ConditionNode',
                priority: 1,
                conditions: [
                  [
                    {
                      conditionId: 'abc',
                      key: 'equal',
                      type: 'range_condition',
                      paramKey: 'Double_mzQQncuxS3F5',
                      paramLabel: '数字1',
                      boundEqual: '89',
                    },
                    {
                      key: 'equal',
                      type: 'range_condition',
                      paramKey: 'Double_TwWRWwF57fDm',
                      paramLabel: '数字2',
                      boundEqual: '78',
                    },
                  ],
                ],
              },
            },
            {
              children: [
                {
                  children: null,
                  componentName: 'ApprovalNode',
                  id: 'node_2aecf72b721743deba743e28a182e5e6',
                  title: '条件节点1-审核01',
                  props: {
                    name: '条件节点1-审核01',
                    nodeName: 'ApprovalNode',
                    approvalType: 2,
                    approverRules: {
                      approvals: [
                        {
                          id: 'de2754b8251424c818bf25dac9cab4a7',
                          label: 'huajian',
                          type: 'employee',
                        },
                        {
                          id: '27cc7d6769cea99aa9b6150f5083ab99',
                          label: 'huangyi',
                          type: 'employee',
                        },
                      ],
                    },
                  },
                },
                {
                  children: [
                    {
                      children: [
                        {
                          children: null,
                          componentName: 'ApprovalNode',
                          id: 'node_2aecf72b721743deba743e28a182e5e6',
                          title: '条件节点221-审核01',
                          props: {
                            name: '条件节点221-审核01',
                            nodeName: 'ApprovalNode',
                            approvalType: 2,
                            approverRules: {
                              approvals: [
                                {
                                  id: 'de2754b8251424c818bf25dac9cab4a7',
                                  label: 'huajian',
                                  type: 'employee',
                                },
                                {
                                  id: '27cc7d6769cea99aa9b6150f5083ab99',
                                  label: 'huangyi',
                                  type: 'employee',
                                },
                              ],
                            },
                          },
                        },
                      ],
                      componentName: 'ConditionNode',
                      id: 'node_2920d8bb0b5e41cfb8a9c8606ac086fd',
                      title: '条件节点221',
                      props: {
                        description: null,
                        isDefault: null,
                        name: '条件节点221-条件',
                        props: {
                          name: '条件节点221-条件',
                          nodeName: 'ConditionNode',
                        },
                        nodeName: 'ConditionNode',
                        priority: 1,
                        conditions: [
                          [
                            {
                              type: 'multi_component_condition',
                              paramKey: 'Keyword_A1VSJ_2YF1JZ',
                              paramLabel: '多选1',
                              matchType: '2',
                              paramValues: ['option_1', 'option_2'],
                            },
                            {
                              type: 'single_component_condition',
                              paramKey: 'Keyword_rPdXS4pyQ3um',
                              paramLabel: '单选2',
                              paramValues: ['option_1', 'option_2', 'option_4'],
                            },
                          ],
                        ],
                      },
                    },
                    {
                      children: [
                        {
                          children: null,
                          componentName: 'ApprovalNode',
                          id: 'node_9c1b9e4a16374c31ba8a31ecd5c261c3',
                          title: '条件节点222-审核01',
                          props: {
                            name: '条件节点222-审核01',
                            nodeName: 'ApprovalNode',
                            approvalType: 2,
                            approverRules: {
                              approvals: [
                                {
                                  id: 'de2754b8251424c818bf25dac9cab4a7',
                                  label: 'huajian',
                                  type: 'employee',
                                },
                                {
                                  id: '27cc7d6769cea99aa9b6150f5083ab99',
                                  label: 'huangyi',
                                  type: 'employee',
                                },
                              ],
                            },
                          },
                        },
                      ],
                      componentName: 'ConditionNode',
                      id: 'node_7fa47fa539fb4be1b58e324e8b510c1e',
                      title: '条件节点222',
                      props: {
                        description: null,
                        isDefault: null,
                        name: '条件节点222-条件',
                        nodeName: 'ConditionNode',
                        priority: 1,
                        conditions: [
                          [
                            {
                              type: 'multi_component_condition',
                              paramKey: 'Keyword_A1VSJ_2YF1JZ',
                              paramLabel: '多选1',
                              matchType: '2',
                              paramValues: ['option_1', 'option_2'],
                            },
                            {
                              type: 'single_component_condition',
                              paramKey: 'Keyword_rPdXS4pyQ3um',
                              paramLabel: '单选2',
                              paramValues: ['option_1', 'option_2', 'option_6'],
                            },
                          ],
                        ],
                      },
                    },
                  ],
                  componentName: 'ConditionContainerNode',
                  id: 'node_02daa5f19f634a44ba01642a20ef0f86',
                  title: '网关22',
                },
              ],
              componentName: 'ConditionNode',
              id: 'node_2920d8bb0b5e41cfb8a9c8606ac086fd',
              title: '条件节点1',
              props: {
                description: null,
                isDefault: null,
                name: '条件节点1-条件',
                nodeName: 'ConditionNode',
                priority: 1,
                conditions: [
                  [
                    {
                      conditionId: 'abc',
                      key: 'equal',
                      type: 'range_condition',
                      paramKey: 'Double_mzQQncuxS3F5',
                      paramLabel: '数字1',
                      boundEqual: '89',
                    },
                    {
                      key: 'equal',
                      type: 'range_condition',
                      paramKey: 'Double_TwWRWwF57fDm',
                      paramLabel: '数字2',
                      boundEqual: '78',
                    },
                  ],
                ],
              },
            },
            {
              children: [
                {
                  children: null,
                  componentName: 'ApprovalNode',
                  id: 'node_9c1b9e4a16374c31ba8a31ecd5c261c3',
                  title: '条件节点2-审核01',
                  props: {
                    name: '条件 节点2-审核01',
                    nodeName: 'ApprovalNode',
                    approvalType: 2,
                    approverRules: {
                      approvals: [
                        {
                          id: 'de2754b8251424c818bf25dac9cab4a7',
                          label: 'huajian',
                          type: 'employee',
                        },
                        {
                          id: '27cc7d6769cea99aa9b6150f5083ab99',
                          label: 'huangyi',
                          type: 'employee',
                        },
                      ],
                    },
                  },
                },
              ],
              componentName: 'ConditionNode',
              id: 'node_7fa47fa539fb4be1b58e324e8b510c1e',
              title: '条件节点2',
              props: {
                description: null,
                isDefault: null,
                name: '条件节点2-条件',
                nodeName: 'ConditionNode',
                priority: 1,
                conditions: [
                  [
                    {
                      key: 'g',
                      type: 'range_condition',
                      paramKey: 'Double_mzQQncuxS3F5',
                      paramLabel: '数字1',
                      lowerBound: '7',
                    },
                    {
                      key: 'ge',
                      type: 'range_condition',
                      paramKey: 'Double_TwWRWwF57fDm',
                      paramLabel: '数字2',
                      lowerBoundEqual: '4',
                    },
                  ],
                ],
              },
            },
            {
              children: [
                {
                  children: null,
                  componentName: 'ApprovalNode',
                  id: 'node_9c1b9e4a16374c31ba8a31ecd5c261c3',
                  title: '条件节点2-审核01',
                  props: {
                    name: '条件 节点2-审核01',
                    nodeName: 'ApprovalNode',
                    approvalType: 2,
                    approverRules: {
                      approvals: [
                        {
                          id: 'de2754b8251424c818bf25dac9cab4a7',
                          label: 'huajian',
                          type: 'employee',
                        },
                        {
                          id: '27cc7d6769cea99aa9b6150f5083ab99',
                          label: 'huangyi',
                          type: 'employee',
                        },
                      ],
                    },
                  },
                },
              ],
              componentName: 'ConditionNode',
              id: 'node_7fa47fa539fb4be1b58e324e8b510c1e',
              title: '条件节点2',
              props: {
                description: null,
                isDefault: null,
                name: '条件节点2-条件',
                nodeName: 'ConditionNode',
                priority: 1,
                conditions: [
                  [
                    {
                      key: 'g',
                      type: 'range_condition',
                      paramKey: 'Double_mzQQncuxS3F5',
                      paramLabel: '数字1',
                      lowerBound: '7',
                    },
                    {
                      key: 'ge',
                      type: 'range_condition',
                      paramKey: 'Double_TwWRWwF57fDm',
                      paramLabel: '数字2',
                      lowerBoundEqual: '4',
                    },
                  ],
                ],
              },
            },
          ],
          componentName: 'ConditionContainerNode',
          id: 'node_02daa5f19f634a44ba01642a20ef0f86',
          title: '网关1',
          props: {
            name: '网关1',
            nodeName: 'ConditionContainerNode',
          },
        },
        {
          children: null,
          componentName: 'ApprovalNode',
          id: 'node_a5b05cac3f804bda9f88d94ef44cc3aa',
          title: '最后的审核结点',
          props: {
            name: '最后的审核结点',
            nodeName: 'ApprovalNode',
            approvalType: 2,
            approverRules: {
              approvals: [
                {
                  id: 'de2754b8251424c818bf25dac9cab4a7',
                  label: 'huajian',
                  type: 'employee',
                },
                {
                  id: '27cc7d6769cea99aa9b6150f5083ab99',
                  label: 'huangyi',
                  type: 'employee',
                },
              ],
            },
          },
        },
      ],
      processKey: 'PROC-CE3971BB-95CE-4C32-B38F-C4E1CF25E013',
      processName: '',
    },
  },
};

export const demo1 = [
  {
    children: null,
    componentName: 'ApplyNode',
    id: 'node_4629bec77c2847dda0bc9ee58226fbf81',
    title: '申请',
  },
  {
    children: null,
    componentName: 'ApprovalNode',
    id: 'node_9ea64bc60ffc4dcb97cfcd31771ed7aa2',
    title: '审核01',
    props: {
      name: '审核01',
      nodeName: 'ApprovalNode',
      approverRules: {
        approvals: [],
      },
    },
  },
  {
    children: [
      {
        children: [
          {
            children: null,
            componentName: 'ApprovalNode',
            id: 'node_2aecf72b721743deba743e28a182e5e63',
            title: '条件节点221-审核01',
          },
        ],
        componentName: 'ConditionNode',
        id: 'node_2920d8bb0b5e41cfb8a9c8606ac086fd4',
        title: '条件节点221',
      },
      {
        children: [
          {
            children: null,
            componentName: 'ApprovalNode',
            id: 'node_9c1b9e4a16374c31ba8a31ecd5c261c35',
            title: '条件节点222-审核01',
          },
        ],
        componentName: 'ConditionNode',
        id: 'node_7fa47fa539fb4be1b58e324e8b510c1e6',
        title: '条件节点222',
      },
    ],
    componentName: 'ConditionContainerNode',
    id: 'node_02daa5f19f634a44ba01642a20ef0f867',
    title: '网关22',
  },
  {
    children: [
      {
        children: [
          {
            children: null,
            componentName: 'ApprovalNode',
            id: 'node_2aecf72b721743deba743e28a182e5e68',
            title: '条件节点221-审核01',
          },
        ],
        componentName: 'ConditionNode',
        id: 'node_2920d8bb0b5e41cfb8a9c8606ac086fd9',
        title: '条件节点221',
      },
      {
        children: [
          {
            children: null,
            componentName: 'ApprovalNode',
            id: 'node_9c1b9e4a16374c31ba8a31ecd5c261c310',
            title: '条件节点222-审核01',
          },
        ],
        componentName: 'ConditionNode',
        id: 'node_7fa47fa539fb4be1b58e324e8b510c1e11',
        title: '条件节点222',
      },
    ],
    componentName: 'ConditionContainerNode',
    id: 'node_02daa5f19f634a44ba01642a20ef0f8712',
    title: '网关22',
  },
  {
    children: null,
    componentName: 'CarbonNode',
    id: 'node_60757600feed40c48a17bfd2900ab3c613',
    title: '抄送01',
  },
];

export const mockData2 = {
  formRelation: {
    appUuid: '123',
    corpId: 'ding8231c58ff1d0ae01a1320dcb25e91351',
    formCode: 'PROC-CE3971BB-95CE-4C32-B38F-C4E1CF25E013',
    userId: '27cc7d6769cea99aa9b6150f5083ab99',
  },
  processConfig: {
    globalSetting: {
      actionFindNotAssignee: 1,
      // approvalType: 2,
      isAutoPassWhenRepeated: false,
      isRevokeProcess: false,
      canAppendTask: false,
      // requiredComment: false,
    },
    processDefinition: {
      children: [
        {
          children: null,
          componentName: 'ApplyNode',
          id: 'node_4629bec77c2847dda0bc9ee58226fbf8',
          props: {
            name: '申请',
            nodeName: 'ApplyNode',
            approverRules: {
              approvals: [
                {
                  id: 'de2754b8251424c818bf25dac9cab4a7',
                  label: 'huajian',
                  type: 'employee',
                },
                {
                  id: '27cc7d6769cea99aa9b6150f5083ab99',
                  label: 'huangyi',
                  type: 'employee',
                },
              ],
            },
          },
        },
        {
          children: null,
          componentName: 'ApprovalNode',
          id: 'node_9ea64bc60ffc4dcb97cfcd31771ed7aa',
          title: '审核01',
          props: {
            name: '审核01',
            nodeName: 'ApprovalNode',
            approvalType: 2,
            approverRules: {
              approvals: [
                {
                  id: 'de2754b8251424c818bf25dac9cab4a7',
                  label: 'huajian',
                  type: 'employee',
                },
                {
                  id: '27cc7d6769cea99aa9b6150f5083ab99',
                  label: 'huangyi',
                  type: 'employee',
                },
              ],
            },
          },
        },
        {
          children: null,
          componentName: 'CarbonNode',
          id: 'node_60757600feed40c48a17bfd2900ab3c6',
          title: '抄送01',
          props: {
            name: '抄送01',
            nodeName: 'CarbonNode',
            approverRules: {
              approvals: [
                {
                  id: 'de2754b8251424c818bf25dac9cab4a7',
                  label: 'huajian',
                  type: 'employee',
                },
              ],
            },
          },
        },
        {
          children: [
            {
              children: [
                {
                  children: null,
                  componentName: 'ApprovalNode',
                  id: 'node_2aecf72b721743deba743e28a182e5e6',
                  title: '条件节点1-审核01',
                  props: {
                    name: '条件节点1-审核01',
                    nodeName: 'ApprovalNode',
                    approvalType: 2,
                    approverRules: {
                      approvals: [
                        {
                          id: 'de2754b8251424c818bf25dac9cab4a7',
                          label: 'huajian',
                          type: 'employee',
                        },
                        {
                          id: '27cc7d6769cea99aa9b6150f5083ab99',
                          label: 'huangyi',
                          type: 'employee',
                        },
                      ],
                    },
                  },
                },
                {
                  children: [
                    {
                      children: [
                        {
                          children: null,
                          componentName: 'ApprovalNode',
                          id: 'node_2aecf72b721743deba743e28a182e5e6',
                          title: '条件节点221-审核01',
                          props: {
                            name: '条件节点221-审核01',
                            nodeName: 'ApprovalNode',
                            approvalType: 2,
                            approverRules: {
                              approvals: [
                                {
                                  id: 'de2754b8251424c818bf25dac9cab4a7',
                                  label: 'huajian',
                                  type: 'employee',
                                },
                                {
                                  id: '27cc7d6769cea99aa9b6150f5083ab99',
                                  label: 'huangyi',
                                  type: 'employee',
                                },
                              ],
                            },
                          },
                        },
                      ],
                      componentName: 'ConditionNode',
                      id: 'node_2920d8bb0b5e41cfb8a9c8606ac086fd',
                      props: {
                        description: null,
                        isDefault: null,
                        name: '条件节点221-条件',
                        nodeName: 'ConditionNode',
                        priority: 1,
                        conditions: [
                          [
                            {
                              type: 'multi_component_condition',
                              paramKey: 'Keyword_A1VSJ_2YF1JZ',
                              paramLabel: '多选1',
                              matchType: '2',
                              paramValues: ['option_1', 'option_2'],
                            },
                            {
                              type: 'single_component_condition',
                              paramKey: 'Keyword_rPdXS4pyQ3um',
                              paramLabel: '单选2',
                              paramValues: ['option_1', 'option_2', 'option_4'],
                            },
                          ],
                          [
                            {
                              type: 'multi_component_condition',
                              paramKey: 'Keyword_A1VSJ_2YF1JZ',
                              paramLabel: '多选1',
                              matchType: '2',
                              paramValues: ['option_1', 'option_2'],
                            },
                            {
                              type: 'single_component_condition',
                              paramKey: 'Keyword_rPdXS4pyQ3um',
                              paramLabel: '单选2',
                              paramValues: ['option_1', 'option_2', 'option_4'],
                            },
                          ],
                        ],
                      },
                    },
                    {
                      children: [
                        {
                          children: null,
                          componentName: 'ApprovalNode',
                          id: 'node_9c1b9e4a16374c31ba8a31ecd5c261c3',
                          title: '条件节点222-审核01',
                          props: {
                            name: '条件节点222-审核01',
                            nodeName: 'ApprovalNode',
                            approvalType: 2,
                            approverRules: {
                              approvals: [
                                {
                                  id: 'de2754b8251424c818bf25dac9cab4a7',
                                  label: 'huajian',
                                  type: 'employee',
                                },
                                {
                                  id: '27cc7d6769cea99aa9b6150f5083ab99',
                                  label: 'huangyi',
                                  type: 'employee',
                                },
                              ],
                            },
                          },
                        },
                      ],
                      componentName: 'ConditionNode',
                      id: 'node_7fa47fa539fb4be1b58e324e8b510c1e',
                      title: '条件节点222',
                      props: {
                        description: null,
                        isDefault: null,
                        name: '条件节点222-条件',
                        nodeName: 'ConditionNode',
                        priority: 1,
                        conditions: [
                          [
                            {
                              type: 'multi_component_condition',
                              paramKey: 'Keyword_A1VSJ_2YF1JZ',
                              paramLabel: '多选1',
                              matchType: '2',
                              paramValues: ['option_1', 'option_2'],
                            },
                            {
                              type: 'single_component_condition',
                              paramKey: 'Keyword_rPdXS4pyQ3um',
                              paramLabel: '单选2',
                              paramValues: ['option_1', 'option_2', 'option_6'],
                            },
                          ],
                        ],
                      },
                    },
                  ],
                  componentName: 'ConditionContainerNode',
                  id: 'node_02daa5f19f634a44ba01642a20ef0f86',
                  title: '网关22',
                },
              ],
              componentName: 'ConditionNode',
              id: 'node_2920d8bb0b5e41cfb8a9c8606ac086fd',
              title: '条件节点1',
              props: {
                description: null,
                isDefault: null,
                name: '条件节点1-条件',
                nodeName: 'ConditionNode',
                priority: 1,
                conditions: [
                  [
                    {
                      conditionId: 'abc',
                      key: 'equal',
                      type: 'range_condition',
                      paramKey: 'Double_mzQQncuxS3F5',
                      paramLabel: '数字1',
                      boundEqual: '89',
                    },
                    {
                      key: 'equal',
                      type: 'range_condition',
                      paramKey: 'Double_TwWRWwF57fDm',
                      paramLabel: '数字2',
                      boundEqual: '78',
                    },
                  ],
                ],
              },
            },
            {
              children: [
                {
                  children: null,
                  componentName: 'ApprovalNode',
                  id: 'node_2aecf72b721743deba743e28a182e5e6',
                  title: '条件节点1-审核01',
                  props: {
                    name: '条件节点1-审核01',
                    nodeName: 'ApprovalNode',
                    approvalType: 2,
                    approverRules: {
                      approvals: [
                        {
                          id: 'de2754b8251424c818bf25dac9cab4a7',
                          label: 'huajian',
                          type: 'employee',
                        },
                        {
                          id: '27cc7d6769cea99aa9b6150f5083ab99',
                          label: 'huangyi',
                          type: 'employee',
                        },
                      ],
                    },
                  },
                },
                {
                  children: [
                    {
                      children: [
                        {
                          children: null,
                          componentName: 'ApprovalNode',
                          id: 'node_2aecf72b721743deba743e28a182e5e6',
                          title: '条件节点221-审核01',
                          props: {
                            name: '条件节点221-审核01',
                            nodeName: 'ApprovalNode',
                            approvalType: 2,
                            approverRules: {
                              approvals: [
                                {
                                  id: 'de2754b8251424c818bf25dac9cab4a7',
                                  label: 'huajian',
                                  type: 'employee',
                                },
                                {
                                  id: '27cc7d6769cea99aa9b6150f5083ab99',
                                  label: 'huangyi',
                                  type: 'employee',
                                },
                              ],
                            },
                          },
                        },
                      ],
                      componentName: 'ConditionNode',
                      id: 'node_2920d8bb0b5e41cfb8a9c8606ac086fd',
                      title: '条件节点221',
                      props: {
                        description: null,
                        isDefault: null,
                        name: '条件节点221-条件',
                        props: {
                          name: '条件节点221-条件',
                          nodeName: 'ConditionNode',
                        },
                        nodeName: 'ConditionNode',
                        priority: 1,
                        conditions: [
                          [
                            {
                              type: 'multi_component_condition',
                              paramKey: 'Keyword_A1VSJ_2YF1JZ',
                              paramLabel: '多选1',
                              matchType: '2',
                              paramValues: ['option_1', 'option_2'],
                            },
                            {
                              type: 'single_component_condition',
                              paramKey: 'Keyword_rPdXS4pyQ3um',
                              paramLabel: '单选2',
                              paramValues: ['option_1', 'option_2', 'option_4'],
                            },
                          ],
                        ],
                      },
                    },
                    {
                      children: [
                        {
                          children: null,
                          componentName: 'ApprovalNode',
                          id: 'node_9c1b9e4a16374c31ba8a31ecd5c261c3',
                          title: '条件节点222-审核01',
                          props: {
                            name: '条件节点222-审核01',
                            nodeName: 'ApprovalNode',
                            approvalType: 2,
                            approverRules: {
                              approvals: [
                                {
                                  id: 'de2754b8251424c818bf25dac9cab4a7',
                                  label: 'huajian',
                                  type: 'employee',
                                },
                                {
                                  id: '27cc7d6769cea99aa9b6150f5083ab99',
                                  label: 'huangyi',
                                  type: 'employee',
                                },
                              ],
                            },
                          },
                        },
                      ],
                      componentName: 'ConditionNode',
                      id: 'node_7fa47fa539fb4be1b58e324e8b510c1e',
                      title: '条件节点222',
                      props: {
                        description: null,
                        isDefault: null,
                        name: '条件节点222-条件',
                        nodeName: 'ConditionNode',
                        priority: 1,
                        conditions: [
                          [
                            {
                              type: 'multi_component_condition',
                              paramKey: 'Keyword_A1VSJ_2YF1JZ',
                              paramLabel: '多选1',
                              matchType: '2',
                              paramValues: ['option_1', 'option_2'],
                            },
                            {
                              type: 'single_component_condition',
                              paramKey: 'Keyword_rPdXS4pyQ3um',
                              paramLabel: '单选2',
                              paramValues: ['option_1', 'option_2', 'option_6'],
                            },
                          ],
                        ],
                      },
                    },
                  ],
                  componentName: 'ConditionContainerNode',
                  id: 'node_02daa5f19f634a44ba01642a20ef0f86',
                  title: '网关22',
                },
              ],
              componentName: 'ConditionNode',
              id: 'node_2920d8bb0b5e41cfb8a9c8606ac086fd',
              title: '条件节点1',
              props: {
                description: null,
                isDefault: null,
                name: '条件节点1-条件',
                nodeName: 'ConditionNode',
                priority: 1,
                conditions: [
                  [
                    {
                      conditionId: 'abc',
                      key: 'equal',
                      type: 'range_condition',
                      paramKey: 'Double_mzQQncuxS3F5',
                      paramLabel: '数字1',
                      boundEqual: '89',
                    },
                    {
                      key: 'equal',
                      type: 'range_condition',
                      paramKey: 'Double_TwWRWwF57fDm',
                      paramLabel: '数字2',
                      boundEqual: '78',
                    },
                  ],
                ],
              },
            },
            {
              children: [
                {
                  children: null,
                  componentName: 'ApprovalNode',
                  id: 'node_9c1b9e4a16374c31ba8a31ecd5c261c3',
                  title: '条件节点2-审核01',
                  props: {
                    name: '条件 节点2-审核01',
                    nodeName: 'ApprovalNode',
                    approvalType: 2,
                    approverRules: {
                      approvals: [
                        {
                          id: 'de2754b8251424c818bf25dac9cab4a7',
                          label: 'huajian',
                          type: 'employee',
                        },
                        {
                          id: '27cc7d6769cea99aa9b6150f5083ab99',
                          label: 'huangyi',
                          type: 'employee',
                        },
                      ],
                    },
                  },
                },
              ],
              componentName: 'ConditionNode',
              id: 'node_7fa47fa539fb4be1b58e324e8b510c1e',
              title: '条件节点2',
              props: {
                description: null,
                isDefault: null,
                name: '条件节点2-条件',
                nodeName: 'ConditionNode',
                priority: 1,
                conditions: [
                  [
                    {
                      key: 'g',
                      type: 'range_condition',
                      paramKey: 'Double_mzQQncuxS3F5',
                      paramLabel: '数字1',
                      lowerBound: '7',
                    },
                    {
                      key: 'ge',
                      type: 'range_condition',
                      paramKey: 'Double_TwWRWwF57fDm',
                      paramLabel: '数字2',
                      lowerBoundEqual: '4',
                    },
                  ],
                ],
              },
            },
            {
              children: [
                {
                  children: null,
                  componentName: 'ApprovalNode',
                  id: 'node_9c1b9e4a16374c31ba8a31ecd5c261c3',
                  title: '条件节点2-审核01',
                  props: {
                    name: '条件 节点2-审核01',
                    nodeName: 'ApprovalNode',
                    approvalType: 2,
                    approverRules: {
                      approvals: [
                        {
                          id: 'de2754b8251424c818bf25dac9cab4a7',
                          label: 'huajian',
                          type: 'employee',
                        },
                        {
                          id: '27cc7d6769cea99aa9b6150f5083ab99',
                          label: 'huangyi',
                          type: 'employee',
                        },
                      ],
                    },
                  },
                },
              ],
              componentName: 'ConditionNode',
              id: 'node_7fa47fa539fb4be1b58e324e8b510c1e',
              title: '条件节点2',
              props: {
                description: null,
                isDefault: null,
                name: '条件节点2-条件',
                nodeName: 'ConditionNode',
                priority: 1,
                conditions: [
                  [
                    {
                      key: 'g',
                      type: 'range_condition',
                      paramKey: 'Double_mzQQncuxS3F5',
                      paramLabel: '数字1',
                      lowerBound: '7',
                    },
                    {
                      key: 'ge',
                      type: 'range_condition',
                      paramKey: 'Double_TwWRWwF57fDm',
                      paramLabel: '数字2',
                      lowerBoundEqual: '4',
                    },
                  ],
                ],
              },
            },
          ],
          componentName: 'ConditionContainerNode',
          id: 'node_02daa5f19f634a44ba01642a20ef0f86',
          title: '网关1',
          props: {
            name: '网关1',
            nodeName: 'ConditionContainerNode',
          },
        },
        {
          children: null,
          componentName: 'ApprovalNode',
          id: 'node_a5b05cac3f804bda9f88d94ef44cc3aa',
          title: '最后的审核结点',
          props: {
            name: '最后的审核结点',
            nodeName: 'ApprovalNode',
            approvalType: 2,
            approverRules: {
              approvals: [
                {
                  id: 'de2754b8251424c818bf25dac9cab4a7',
                  label: 'huajian',
                  type: 'employee',
                },
                {
                  id: '27cc7d6769cea99aa9b6150f5083ab99',
                  label: 'huangyi',
                  type: 'employee',
                },
              ],
            },
          },
        },
      ],
      processKey: 'PROC-CE3971BB-95CE-4C32-B38F-C4E1CF25E013',
      processName: '',
    },
  },
};
