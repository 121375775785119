/*
 * :file description:
 * :name: \low-code-platform\src\components\FormGenerator\settings\presetFormSettings\product.js
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-11 20:32:48
 * :last editor: hyw
 * :date last edited: 2022-04-20 10:46:46
 */

import { toDingDingSettingLink } from '../../utils';

export default [
  {
    text: '产品编号',
    name: 'seqNumber',
    icon: 'icon-zhaiyao',
    hiddenBlock: ['copy'],
    max: 1,
    fieldId: 'seqNumber_17KJ1YD7TO9S0',
    schema: {
      title: '产品编号',
      type: 'string',
      widget: 'seqNumber',
      bizAlias: 'product_number',
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        hidden: '{{true}}',
      },
      generationRules: {
        title: '',
        type: 'array',
        items: {
          type: 'object',
          properties: {},
        },
        widget: 'generationRules',
        default: [
          {
            type: 'date',
            value: 'YYYYMMDD',
          },
          {
            type: 'counter',
            value: {
              start: 1,
              length: 8,
              reset: 'year',
            },
          },
          {
            type: 'common',
            value: '',
          },
          {
            type: 'field',
            value: '',
          },
        ],
      },
    },
  },
  {
    text: '产品名称',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_1DOKZ4YHN69S0',
    schema: {
      title: '产品名称',
      type: 'string',
      widget: 'input',
      bizAlias: 'product_name',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '产品分类',
    name: 'cascade',
    icon: 'icon-shuoming',
    fieldId: 'cascade_1TII0OPU163K0',
    schema: {
      title: '产品分类',
      type: 'string',
      widget: 'cascade',
      bizAlias: 'product_type',
      required: true,
    },
    setting: {
      info: {
        type: 'string',
        widget: 'jumplink',
        props: {
          message: '',
          src: toDingDingSettingLink('crm_product'),
          description:
            '产品分类是客户管理特殊组件，支持多级分类，如需对分类进行设置请点击下方链接',
          type: 'info',
          showIcon: true,
          hide: 1234,
        },
        // hidden: '{{true}}',
      },
      bizAlias: {
        hidden: '{{true}}',
      },
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        type: 'string',
        hidden: '{{true}}',
      },
    },
  },
  {
    text: '产品状态',
    name: 'productStatus',
    icon: 'icon-shuoming',
    fieldId: 'select_1PRDP7MO7RPC0',
    schema: {
      title: '产品状态',
      type: 'string',
      widget: 'select',
      bizAlias: 'product_status',
      placeholder: '请选择',
    },
    setting: {
      info: {
        type: 'string',
        widget: 'jumplink',
        props: {
          message: '',
          description:
            '产品状态是客户管理特殊组件，“下架”的产品表示不允许继续售卖，如需对其选项进行设置请点击下方链接。',
          type: 'info',
          showIcon: true,
          src: toDingDingSettingLink('crm_product'),
        },
        // hidden: '{{true}}',
      },

      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      title: {
        type: 'string',
        hidden: '{{true}}',
      },
    },
  },
  {
    text: '规格型号',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    schema: {
      title: '规格型号',
      type: 'string',
      widget: 'input',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '成本价（元）',
    name: 'money',
    icon: 'icon-a-jine2x',
    schema: {
      title: '成本价（元）',
      type: 'number',
      widget: 'money',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '销售价格（元）',
    name: 'money',
    icon: 'icon-a-jine2x',
    fieldId: 'money_1851J8VAQ4000',
    schema: {
      title: '销售价格（元）',
      type: 'number',
      widget: 'money',
      bizAlias: 'product_price',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
  {
    text: '计量单位',
    name: 'select',
    icon: 'icon-danxuankuang1',
    schema: {
      title: '计量单位',
      type: 'object',
      enum: ['option_0', 'option_1', 'option_2'],
      enumNames: ['选项1', '选项2', '选项3'],
      widget: 'select',
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['选项1', '选项2', '选项3'],
        widget: 'selectOptions',
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '产品详情',
    name: 'textarea',
    icon: 'icon-duohangshurukuang',
    schema: {
      title: '产品详情',
      type: 'string',
      format: 'textarea',
      widget: 'textarea',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '产品图片',
    name: 'picture',
    icon: 'icon-tupian',
    schema: {
      title: '产品图片',
      type: 'array',
      widget: 'picture',
      items: {
        type: 'string',
      },
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
    },
  },
  {
    text: '期初库存',
    icon: 'icon-a-shuzishurukuang2x',
    name: 'number',
    schema: {
      title: '数字输入框',
      type: 'number',
      widget: 'number',
      bizAlias: 'number_originalStorage',
    },
    setting: {
      format: {
        title: '显示格式',
        type: 'string',
        enum: ['number', 'percentage'],
        className: 'my-radioName',
        enumNames: ['数值', '百分比'],
        widget: 'radio',
        default: 'number',
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 40,
      },
    },
    specialSetting: {
      isSenior: true,
    },
  },
];
