import { useState } from 'react';
import Style from './index.less';
import { getDateLabelByValue, getCounterLabelByValue } from './constant';
import { useModel } from 'umi';
import Config from './Config';

const GenerationRules = (props) => {
  const { value, onChange } = props;
  const [visible, setVisible] = useState(false);
  const model = useModel('useGenerator', (model) => model);
  const { schemaRef } = model;
  const properties = schemaRef.current.properties || {};
  const fieldList = Object.keys(properties)
    .map((key) => {
      return {
        ...properties[key],
        id: key,
      };
    })
    .filter((item) => {
      // 单行输入框、数字输入框、金额、单选项、电话、日期、地址
      return [
        'input',
        'number',
        'money',
        'select',
        'phone',
        'date',
        'address',
      ].includes(item.widget);
    });

  return (
    <div className={Style.wrap}>
      {value?.map((item, index) => {
        const { type, value } = item;
        let title = '';
        let description = '';
        switch (type) {
          case 'date':
            title = '提交日期';
            description = getDateLabelByValue(value);
            break;
          case 'counter':
            const { start, length, reset } = value;
            title = '自增位数';
            description = `${length}位, ${getCounterLabelByValue(reset)}`;
            break;
          case 'common':
            title = '固定字符';
            description = value;
            break;
          case 'field':
            title = '表单控件';
            description = fieldList.find((item) => item.id === value)?.title;
            break;
        }

        return (
          <div className={Style.item} key={index}>
            <div className={Style['item-key']}>{title}：</div>
            <div className={Style['item-value']}>{description}</div>
            {index == 0 && (
              <div
                className={Style['item-config']}
                onClick={() => setVisible(true)}
              >
                设置
              </div>
            )}
          </div>
        );
      })}
      {visible && (
        <Config
          visible={visible}
          value={value}
          onChange={(value) => {
            setVisible(false);
            onChange(value);
          }}
          onCancel={() => setVisible(false)}
          fieldList={fieldList}
        />
      )}
    </div>
  );
};

export default GenerationRules;
