import { toDingDingSettingLink } from '../../utils';

export default [
  {
    text: '项目编号',
    name: 'seqNumber',
    icon: 'icon-zhaiyao',
    fieldId: 'seqNumber_projectNo',
    schema: {
      title: '项目编号',
      type: 'string',
      widget: 'seqNumber',
      generationRules: [
        {
          type: 'date',
          value: 'YYYYMMDD',
        },
        {
          type: 'counter',
          value: {
            start: 1,
            length: 8,
            reset: 'year',
          },
        },
        {
          type: 'common',
          value: '',
        },
        {
          type: 'field',
          value: '',
        },
      ],
      // bizAlias: 'project_no',
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        hidden: '{{true}}',
      },
      generationRules: {
        title: '',
        type: 'array',
        items: {
          type: 'object',
          properties: {},
        },
        widget: 'generationRules',
        default: [
          {
            type: 'date',
            value: 'YYYYMMDD',
          },
          {
            type: 'counter',
            value: {
              start: 1,
              length: 8,
              reset: 'year',
            },
          },
          {
            type: 'common',
            value: '',
          },
          {
            type: 'field',
            value: '',
          },
        ],
      },
    },
  },
  {
    text: '项目名称',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_projectName',
    schema: {
      title: '项目名称',
      type: 'string',
      widget: 'input',
      bizAlias: 'input_projectName',
      banDelete: true,
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      title: {
        title: '标题',
        description: '最多100字',
        required: true,
        type: 'string',
        max: 100,
        order: 1,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        order: 20,
        disabled: true,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '项目标签',
    name: 'multiSelect',
    icon: 'icon-duoxuankuang',
    fieldId: 'multiSelect_projectTag',
    schema: {
      title: '项目标签',
      type: 'array',
      bizAlias: 'multiSelect_projectTag',
      items: {
        type: 'string',
      },
      enum: ['option_0', 'option_1', 'option_2'],
      enumNames: ['一类项目', '二类项目', '三类项目'],
      widget: 'multiSelect',
      colorEnable: true,
      extension: [
        {
          option_0: {
            color: '#ffccc7',
          },
        },
        {
          option_1: {
            color: '#ffe7ba',
          },
        },
        {
          option_2: {
            color: '#fffdcf',
          },
        },
      ],
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        widget: 'selectOptions',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['一类项目', '二类项目', '三类项目'],
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
      },
    },
  },
  {
    text: '客户',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_projectCustomer',
    schema: {
      title: '客户',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      fields: {
        input_K2U5DHAA: {
          bizAlias: 'customer_name',
          id: 'input_K2U5DHAA',
          required: true,
          title: '客户名称',
          type: 'string',
          widget: 'input',
        },
      },
      bizAlias: 'relation_projectCustomer',
      relateSource: [
        {
          bizType: 'crm_customer',
          dataSource: {
            type: 'form',
            params: {},
            target: {
              appUuid: 'TJAPP-MjAyMTEyMDEwMQ==',
              bizType: 'crm_customer',
              formCode: 'PROC-0FCFC5D3-B316-48ED-8104-FC5312A8D1E8',
              appType: 1,
              source: 2,
              status: 'PUBLISHED',
            },
          },
          fields: {
            input_K2U5DHAA: {
              bizAlias: 'customer_name',
              id: 'input_K2U5DHAA',
              placeholder: '请输入组织全称',
              required: true,
              title: '客户名称',
              type: 'string',
              widget: 'input',
            },
          },
        },
        {
          bizType: 'crm_customer_personal',
          dataSource: {
            type: 'form',
            params: {},
            target: {
              appUuid: 'TJAPP-MjAyMTEyMDEwMQ==',
              bizType: 'crm_customer_personal',
              formCode: 'PROC-0A8F6BAB-8528-41C0-BFBC-1AD62418B5AF',
              appType: 1,
              source: 2,
              status: 'PUBLISHED',
            },
          },
          fields: {
            input_K2U5DHAA: {
              bizAlias: 'customer_name',
              id: 'input_K2U5DHAA',
              placeholder: '请输入客户姓名',
              required: true,
              title: '客户名称',
              type: 'string',
              widget: 'input',
            },
          },
        },
      ],
    },
    setting: {
      fields: {
        title: '关联字段',
        type: 'object',
        widget: 'relationSetting',
      },
      // sortFields: {
      //   title: '',
      //   widget: 'sortFields',
      //   type: 'array',
      //   items: {
      //     type: 'object',
      //     properties: {},
      //   },
      //   default: [
      //     {
      //       bizType: 'crm_customer_personal',
      //       fields: [],
      //     },
      //     {
      //       bizType: 'crm_customer',
      //       fields: [],
      //     },
      //   ],
      // },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
      },
      choice: {
        title: '关联模式',
        type: 'string',
        default: '0',
        enum: ['0', '1'],
        className: 'my-radioName',
        enumNames: ['引用模式', '拷贝模式'],
        widget: 'radio',
        hidden: '{{true}}',
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      // title: {
      //   title: '标题',
      //   type: 'string',
      //   hidden: '{{true}}',
      // },
      filters: {
        title: '数据过滤',
        type: 'array',
        widget: 'dataFilters',
      },
    },
  },
  {
    text: '线索',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_followRecordClue',
    extraText: '关联表单',
    schema: {
      title: '线索',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      bizAlias: 'relation_followRecordClue',
      fields: {},
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
          formCode: '',
        },
      },
      relateSource: [
        {
          bizType: 'clue_engine',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'TJAPP-MjAyMjA5MjMwMA==',
              bizType: 'clue_engine',
              formCode: 'xxxxx',
              appType: 1,
              status: 'PUBLISHED',
            },
          },
        },
      ],
    },
    setting: {
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        title: '标题',
        type: 'string',
        // hidden: '{{true}}',
      },
    },
  },
  {
    text: '项目阶段',
    name: 'projectStage',
    icon: 'icon-shuoming',
    fieldId: 'select_projectStage',
    schema: {
      title: '项目阶段',
      type: 'object',
      widget: 'select',
      bizAlias: 'select_projectStage',
      placeholder: '请选择',
      extension: [
        {
          option_project_record: {
            editFreeze: false,
            deleteFreeze: true,
            sortFreeze: true,
          },
        },
        {
          option_1: {
            editFreeze: false,
            deleteFreeze: false,
            sortFreeze: false,
          },
        },
        {
          option_2: {
            editFreeze: false,
            deleteFreeze: false,
            sortFreeze: false,
          },
        },
        {
          option_3: {
            editFreeze: false,
            deleteFreeze: false,
            sortFreeze: false,
          },
        },
        {
          option_4: {
            editFreeze: false,
            deleteFreeze: false,
            sortFreeze: false,
          },
        },
        {
          option_5: {
            editFreeze: false,
            deleteFreeze: false,
            sortFreeze: false,
          },
        },
        {
          option_project_win_bid: {
            editFreeze: false,
            deleteFreeze: true,
            sortFreeze: true,
          },
        },
        {
          option_project_lose_bid: {
            editFreeze: false,
            deleteFreeze: true,
            sortFreeze: true,
          },
        },
      ],
    },
    setting: {
      info: {
        type: 'string',
        widget: 'jumplink',
        props: {
          message: '',
          description:
            '当前控件是客户管理特殊组件，如需对其选项进行设置请点击下方链接',
          type: 'info',
          showIcon: true,
          src: toDingDingSettingLink('pms_project'),
        },
        // hidden: '{{true}}',
      },

      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
        hidden: '{{true}}',
      },
      // enumList: {
      //   title: '选项',
      //   type: 'array',
      //   enum: [
      //     'option_project_record',
      //     'option_1',
      //     'option_2',
      //     'option_3',
      //     'option_4',
      //     'option_5',
      //     'option_project_win_bid',
      //     'option_project_lose_bid',
      //   ],
      //   enumNames: [
      //     '项目备案',
      //     '立项评估',
      //     '标前引导',
      //     '方案报价',
      //     '商务确认',
      //     '投标',
      //     '项目中标',
      //     '项目落标',
      //   ],
      //   widget: 'selectOptions',
      //   order: 12,
      // },
    },
  },
  {
    text: '开始日期',
    name: 'date',
    icon: 'icon-riqi',
    fieldId: 'date_projectStartTime',
    schema: {
      title: '开始日期',
      type: 'string',
      format: 'date',
      widget: 'date',
      bizAlias: 'date_projectStartTime',
    },
    setting: {
      format: {
        title: '日期类型',
        type: 'string',
        enum: ['date', 'dateTime'],
        enumNames: ['年-月-日', '年-月-日 时:分:秒'],
        widget: 'baseSelect',
        order: 5,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '结束日期',
    name: 'date',
    icon: 'icon-riqi',
    fieldId: 'date_projectEndTime',
    schema: {
      title: '结束日期',
      type: 'string',
      format: 'date',
      widget: 'date',
      bizAlias: 'date_projectEndTime',
    },
    setting: {
      format: {
        title: '日期类型',
        type: 'string',
        enum: ['date', 'dateTime'],
        enumNames: ['年-月-日', '年-月-日 时:分:秒'],
        widget: 'baseSelect',
        order: 5,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
];
