/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/relation/shareData/index.tsx
 * :author: hyw
 * :copyright: (c) 2024, Tungee
 * :date created: 2024-02-19 10:48:10
 * :last editor: hyw
 * :date last edited: 2024-03-06 16:02:07
 */
import Header from '../components/Header';
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { history, useLocation, useModel, useParams } from 'umi';
import { Button, Switch, Table, Tooltip, Modal, message } from 'antd';
import IconFont from '@/components/IconFont';
import c from './index.less';
import ModalDataShareRule from './ModalDataShareRule';
import { getFormSchemaForDesignStatus } from '@/pages/generator/form/services/form-generator';
import { getCategoryTree } from '@/services/form-generator';
import { MenuOutlined } from '@ant-design/icons';
import { getUrlParam } from '@/utils';
import { useDebounceFn } from 'ahooks';
import {
  getListDataSharingRule,
  saveListDataSharingRule,
  deleteDataSharingRule,
} from '@/services/extension-setting';
import { getCascadeOptionList } from '@/services/form-generator';
import _cloneDeep from 'lodash/cloneDeep';
import { SUPPORT_SHAREDATA_WIDGETS } from './constants';
import { CASCADE_ID } from '@/pages/generator/form/constant';

export interface IDuplicateFieldItem {
  id: string;
  title: string;
  bizAlias: string;
}

interface IFormSchema {
  current: {
    properties: {
      [key: string]: IDuplicateFieldItem;
    };
  };
}

const MAX_NUMBER = 50;

export default () => {
  const location = useLocation();
  const { source = '' } = location?.query || {};
  const formCode = getUrlParam('formCode');
  const [visible, setVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [currentRule, setCurrentRule] = useState({});

  const [formSchema, setFormSchema] = useState<IFormSchema>({});
  const [cascadeTree, setCascadeTree] = useState(); // 产品分类的数据
  const [cascadeTreeV2, setCascadeTreeV2] = useState({}); // 所有级联的tree
  const model = useModel('useGenerator', (model) => model);
  const { schemaRef, profile } = model;

  const getCascadeTree = async () => {
    const re = await getCategoryTree({
      corpId:
        getUrlParam('corpId') ||
        window.__corpId ||
        'ding6e37764efa4e5ce0f5bf40eda33b7ba0',
    });
    setCascadeTree(re?.result ?? []);
  };

  // /** 获取所有级联选项 */
  const getCascadesOptionsList = async (ids: any) => {
    getCascadeOptionList({
      ids: ids,
    }).then((res) => {
      const obj = res?.data?.reduce((pre, cur) => {
        pre[cur?.id] = cur;
        return pre;
      }, {});
      setCascadeTreeV2(obj);
    });
  };

  const fieldsList = useMemo(() => {
    console.log(formSchema.current?.properties, '====properties');
    let _list = [];
    const properties = formSchema.current?.properties || {};
    Object.keys(properties)?.forEach((widgetId) => {
      if (!widgetId?.includes('table')) {
        _list.push({
          ...properties?.[widgetId],
        });
      } else if (
        widgetId?.includes('table') &&
        (!properties?.[widgetId]?.hidden ||
          (properties?.[widgetId]?.hidden &&
            properties?.[widgetId]?.hideControls))
      ) {
        Object.values(properties?.[widgetId]?.items?.properties ?? {})?.forEach(
          (i) => {
            _list.push({
              ...i,
              title: properties?.[widgetId]?.title + '_' + i?.title,
            });
          },
        );
      }
    });

    return _list.filter(
      (item) =>
        SUPPORT_SHAREDATA_WIDGETS.includes(item?.widget) &&
        (!item?.hidden || (item?.hidden && item?.hideControls)),
    );
  }, [formSchema]);

  const { run: copyDebounce } = useDebounceFn(
    (record, index) => {
      handleCopy(record, index);
    },
    {
      wait: 500,
    },
  );

  const dealContent = (content) => {
    let keys = Object.keys(content?.properties);
    if (!keys?.length) return {};
    keys?.forEach((key) => {
      // 处理cascadeV2
      // 历史数据 id=cascade_1TII0OPU163K0 为产品分类  已占用cascade
      // 当widget 为cascade 并且 id不为 cascade_1TII0OPU163K0为 cascadeV2
      if (
        key.includes('cascade_') &&
        !CASCADE_ID?.includes(content?.properties?.[key]?.id)
      ) {
        content.properties[key].widget = 'cascadeV2';
      }
    });
    return content;
  };

  const initSchema = () => {
    if (!formCode) {
      return;
    }
    getFormSchemaForDesignStatus({
      formCode,
    }).then((res) => {
      const data = res?.data || {};
      let content = JSON.parse(data.content);
      content = dealContent(content);
      setFormSchema({
        current: content,
      });
    });
  };

  const columns = [
    {
      title: '规则名称',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ellipsis: true,
    },
    {
      title: '共享给',
      dataIndex: 'applicableMember',
      key: 'applicableMember',
      width: '20%',
      render: (data: string, record: any, index: any) => {
        const keys = Object.keys(record?.applicableMember ?? {});
        const resData = keys?.reduce((total, cur) => {
          if (record?.applicableMember?.[cur]?.length) {
            total = total.concat(record?.applicableMember?.[cur]);
          }
          return total;
        }, []);
        return (
          <div>
            <Tooltip title={resData?.map((i) => i?.name)?.join(',')}>
              <div className={c.ellipType}>
                {resData?.map((i) => i?.name)?.join(',')}
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: '权限',
      dataIndex: 'permissions',
      key: 'permissions',
      width: '20%',
      ellipsis: true,
      render: (data: string, record: any, index: any) => {
        return (
          <div className={c.ellipType}>
            {record?.permissions?.[0] === 'read'
              ? '只读'
              : record?.permissions?.[0] === 'write'
              ? '可操作'
              : ''}
          </div>
        );
      },
    },
    {
      title: '启用状态',
      dataIndex: 'status',
      width: '100px',
      key: 'status',
      render: (data: string, record: any, index: any) => {
        return (
          <div className={c.actions}>
            <span>
              <Switch
                checked={record.status != 0}
                onChange={(val) => {
                  handleToggele(val, record, index);
                }}
              />
            </span>
          </div>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: '240px',
      render: (data: string, record: any, index: any) => {
        return (
          <div className={c.actions}>
            <span
              onClick={() => {
                copyDebounce(record, index);
              }}
            >
              复制
            </span>
            <span
              onClick={() => {
                handleEdit(record);
              }}
            >
              编辑
            </span>
            <span onClick={() => handleDetete(record)}>删除</span>
          </div>
        );
      },
    },
  ];
  const handleToggele = async (val: boolean, data: any, index: any) => {
    const ruleItem = _cloneDeep(data);
    ruleItem.status = val ? 1 : 0;
    ruleItem.formCode = formCode;
    await saveRules(ruleItem, 1);
    message.success(val ? '开启成功！' : '关闭成功！');
  };
  const handleCopy = async (data: any, index: any) => {
    if (dataSource.length >= MAX_NUMBER) {
      Modal.warning({
        title: '提示',
        content: `单张表最多支持${MAX_NUMBER}条共享规则，你可以选择删除现有规则后再创建`,
      });
      return;
    }
    const ruleItem = _cloneDeep(data);
    ruleItem.formCode = formCode;
    delete ruleItem?.id;
    ruleItem.filterConditionGroupList = ruleItem?.filterConditionGroupList?.map(
      (i) => {
        return i?.map((j) => {
          return {
            fieldId: j?.fieldId,
            filterType: j?.filterType,
            value: j?.value,
            values: j?.values,
            extendValue: j?.extendValue,
            member: j?.member,
          };
        });
      },
    );
    await saveRules(ruleItem);
  };
  const handleEdit = (data: any) => {
    setVisible(true);
    setCurrentRule(data);
  };
  const handleDetete = (data: any) => {
    Modal.confirm({
      title: '确认删除',
      content: '是否删除此条规则',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const newData = {
          formCode: formCode,
          id: data?.id,
        };
        deleteRules(newData);
      },
    });
  };

  const getFormRuleList = () => {
    getListDataSharingRule({ formCode }).then((res) => {
      const data = res?.data;
      // 处理规则的status状态 为了兼容 status不存在
      if (data && data.length) {
        data.forEach((item) => {
          item.status = item.status == 0 ? 0 : 1;
        });
      }
      setDataSource(data ?? []);
    });
  };

  const onOpenModal = () => {
    if (dataSource.length >= MAX_NUMBER) {
      Modal.warning({
        title: '提示',
        content: `单张表最多支持${MAX_NUMBER}条共享规则，你可以选择删除现有规则后再创建`,
        okText: '知道了',
      });
      return;
    }
    setCurrentRule({});
    setVisible(true);
  };

  const saveRules = async (associationRules: any, isShowMessage?: any) => {
    const data = {
      ...associationRules,
    };
    const res = await saveListDataSharingRule(data).then();
    if (!isShowMessage) {
      message.success('保存成功！');
    }
    setCurrentRule({});
    getFormRuleList();
    setVisible(false);
  };

  const deleteRules = async (param: any) => {
    const res = await deleteDataSharingRule(param).then();
    message.success('删除成功！');
    setCurrentRule({});
    getFormRuleList();
  };

  useEffect(() => {
    getFormRuleList();
  }, []);

  useEffect(() => {
    if (Object.keys(schemaRef.current?.properties || {}).length === 0) {
      initSchema();
    } else {
      setFormSchema(schemaRef);
    }
  }, [schemaRef]);

  // 拉取不存放在schema结构中的组成部分
  // 现在主要有产品分类 widget为 cascade 和 级联  widget为 cascadeV2
  useEffect(() => {
    if (
      !formSchema?.current?.properties ||
      !Object.keys(formSchema?.current?.properties)?.length
    )
      return;
    let hasCascade = false;
    let hasCascadeV2 = [];
    Object.keys(formSchema?.current?.properties)?.forEach((i) => {
      const item = formSchema?.current?.properties?.[i];
      if (item?.widget === 'cascade') {
        hasCascade = true;
      } else if (
        (item?.widget === 'cascadeV2' || item?.widget === 'multiCascade') &&
        item?.enumOptionId
      ) {
        hasCascadeV2?.push(item?.enumOptionId);
      }
    });
    if (hasCascade) {
      getCascadeTree();
    }
    if (hasCascadeV2?.length) {
      getCascadesOptionsList(hasCascadeV2);
    }
  }, [formSchema]);

  // 动态Y轴表格高度
  const scrollHeight =
    (window.innerHeight || document.documentElement.clientHeight) - 292;
  return (
    <div>
      <Header>
        <Button
          type="primary"
          icon={<IconFont type="icon-tianjia1" />}
          onClick={onOpenModal}
        >
          添加规则
        </Button>
      </Header>
      <div className={c.content + ' ' + c.dataShareContain}>
        <div className={c.title}>
          <div className={c.titleDesc}>
            <IconFont
              className={c.tip_icon}
              type="icon-warning-fill"
            ></IconFont>
          </div>
          <div className={c.titleContent}>
            <div>
              1.共享范围的员工，若权限为只读则不可修改、删除任何信息。若权限为可操作，操作权限、字段权限由所在权限组决定。
            </div>
            <div>2.被共享的数据仅支持查看/编辑，暂不支持导入匹配/导出。</div>
          </div>
        </div>
        <Table
          dataSource={dataSource}
          rowKey="ruleId"
          columns={columns}
          pagination={false}
          // scroll={{
          //   y: scrollHeight,
          // }}
        />
        <ModalDataShareRule
          visible={visible}
          currentRule={currentRule}
          fieldsList={fieldsList}
          cascadeTree={cascadeTree}
          cascadeTreeV2={cascadeTreeV2}
          onChange={saveRules}
          onClose={() => {
            setVisible(false);
          }}
        />
      </div>
    </div>
  );
};
