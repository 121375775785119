/*
 * :file description:
 * :name: \low-code-platform\src\pages\generator\relation\business\components\FormulaEditorModal\common\formulaEditor.tsx
 * :author: 杨海涛
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-08-08 15:18:19
 * :last editor: 杨海涛
 * :date last edited: 2022-08-09 18:24:45
 */

import CodeMirror, { Editor } from 'codemirror';
import _ from 'lodash';
import {
  DEPRECATE_FIELD_CLS,
  INVALID_FIELD_CLS,
  NAME_FILED_CLS,
  VALUE_FIELD_CLS,
} from './constant';
import formula from './formula';
import { idToSchema, utils } from './utils';

const _markField = (t, editor) => {
  var i = '',
    n = {
      'data-field': t.field,
      'data-formCode': t.formCode,
    };
  t.invalid
    ? (i = INVALID_FIELD_CLS)
    : t.entry
    ? ((i = NAME_FILED_CLS), (n['data-entry'] = t.entry))
    : (i = VALUE_FIELD_CLS),
    $(
      editor.markText(t.from, t.to, {
        handleMouseEvents: !0,
        atomic: !0,
        replacedWith: $(
          '<span class="cm-field ' +
            i +
            '">' +
            `${
              t.isEdit && !t.entry
                ? t.field?.includes('_old')
                  ? '编辑前.'
                  : '编辑后.'
                : ''
            }` +
            t.text +
            '</span>',
        )[0],
      }).widgetNode,
    )
      .attr(n)
      .addClass(i);
};

export const insertField = (a, b, editor, isEdit) => {
  var c = editor.getCursor();
  editor.replaceSelection('' + a.text + '');
  var d = editor.getCursor(),
    e = {
      from: c,
      to: d,
      field: a.name, //工作项ID
      entry: b, //工作项所属的表单id
      text: a.text,
      isEdit,
      formCode: a.formCode,
    };
  _markField(e, editor), editor.focus();
};

export const insertBarcket = (editor) => {
  var c = editor.getCursor();
  editor.replaceSelection('(');
  var d = editor.getCursor();
  $(
    editor.markText(c, d, {
      handleMouseEvents: !0,
      atomic: !0,
      replaceWith: $(
        '<span class="cm-bracket CodeMirror-matchingbracket">(</span>',
      )[0],
    }).widgetNode,
  );
  var c1 = editor.getCursor();
  editor.replaceSelection(')');
  var d1 = editor.getCursor();
  $(
    editor.markText(c1, d1, {
      handleMouseEvents: !0,
      atomic: !0,
      replaceWith: $(
        '<span class="cm-bracket CodeMirror-matchingbracket">)</span>',
      )[0],
    }).widgetNode,
  );
  editor.setCursor(d);
  editor.focus();
};

export const insertFormulaitem = (text, editor) => {
  var c = editor.getCursor();
  editor.replaceSelection('' + text + '');
  var d = editor.getCursor();
  $(
    editor.markText(c, d, {
      handleMouseEvents: !0,
      atomic: !0,
      replaceWith: $('<span class="cm-keyword">' + text + '</span>')[0],
    }).widgetNode,
  );
  insertBarcket(editor);
};

export const Backspace = (a) => {
  var b = a.getTokenAt(a.getCursor());
  if ('field' == b.type) {
    var c = a.getCursor().line;
    a.setSelection(
      new CodeMirror.Pos(c, b.start),
      new CodeMirror.Pos(c, b.end),
    ),
      a.replaceSelection('', null, '+delete');
  } else a.execCommand('delCharBefore');
};

export const stringToUnicode = (str: string) => {
  if (!str) return '';
  return str
    .split('')
    .map((s) => s.charCodeAt(0))
    .join('_');
};

export const getValue = (editor) => {
  var b = [],
    c = {
      NAME_FILED_CLS,
      VALUE_FIELD_CLS,
      INVALID_FIELD_CLS,
      DEPRECATE_FIELD_CLS,
    },
    rules = [],
    e = $(editor.display.lineDiv).find('pre:first>span');
  return (
    utils.forEach(e, function (e, f) {
      const g: string[] = [],
        rule: string[] = [];
      utils.forEach($(f).children('span'), function (b, e) {
        var f = $(e).attr('data-field'),
          h = $(e).attr('data-entry'),
          formCode = $(e).attr('data-formCode');
        const displayRule = `$${
          f?.includes('_widget_') ? '' : '_widget_'
        }${stringToUnicode(formCode)}${f && f !== '_old' ? '.' : ''}${f}#`;
        if ($(e).hasClass(c.NAME_FILED_CLS)) {
          g.push(displayRule + h);
          rule.push(
            '"' +
              `${formCode}${f && f !== '_old' ? '.' : ''}` +
              (f !== '_old' ? f : '') +
              '"',
          );
        } else if ($(e).hasClass(c.VALUE_FIELD_CLS)) {
          g.push(displayRule);
          rule.push('#{' + (f !== '_old' ? f : '') + '}');
        } else {
          if (
            $(e).hasClass(c.DEPRECATE_FIELD_CLS) ||
            $(e).hasClass(c.INVALID_FIELD_CLS)
          )
            return;
          g.push($(e).text());
          rule.push($(e).text());
        }
      });
      const h = g
        .join('')
        .replace(/\u200b/g, '')
        .replace(/\u00a0/g, ' ');
      const r = rule
        .join('')
        .replace(/\u200b/g, '')
        .replace(/\u00a0/g, ' ');
      b.push(h);
      rules.push(r);
    }),
    {
      source: b.join('\n'),
      displayRule: b.join('\n'),
      rule: rules.join(''),
    }
  );
};

const _getFieldLabelMap = (a) => {
  var t,
    n = {};
  return (
    ('array' === a.type && a.widget === 'table') || a.widget === 'relation'
      ? utils.forEach(
          a.widget === 'relation' ? a.properties : a.items.properties,
          function (t, e) {
            // 关联表单要显示本身
            if(a.widget === 'relation') {
              const i = [
                '$',
                '_widget_',
                stringToUnicode(e.formCode),
                '.',
                a.id,
                '#',
              ].join('');
              n[i] = { title: a.title, formCode: a.formCode };
            }

            const i = [
              '$',
              '_widget_',
              stringToUnicode(e.formCode),
              '.',
              e.id,
              '#',
            ].join('');

            n[i] = { title: e.title, formCode: a.formCode };
            const labelMap =
              'array' === e.type || e.widget === 'relation'
                ? getLabelMap(
                    e.widget === 'relation' ? e.properties : e.items.properties,
                  )
                : {};
            utils.forEach(labelMap, (key, value) => {
              n[key] = value;
            });
          },
        )
      : // : a.widget === 'bizSuite'
        // ? utils.forEach(a.properties, function (t, e) {
        //     const i = [
        //       '$',
        //       '_widget_',
        //       stringToUnicode(e.formCode),
        //       '.',
        //       e.id,
        //       '#',
        //     ].join('');
        //     n[i] = { title: e.title, formCode: a.formCode };
        //     const labelMap =
        //       'array' === e.type || e.widget === 'relation'
        //         ? getLabelMap(
        //             e.widget === 'relation' ? e.properties : e.items.properties,
        //           )
        //         : {};
        //     utils.forEach(labelMap, (key, value) => {
        //       n[key] = value;
        //     });
        //   })
        ((t = [
          '$',
          '_widget_',
          stringToUnicode(a.formCode),
          '.',
          a.id,
          '#',
        ].join('')),
        (n[t] = { title: a.title, formCode: a.formCode })),
    n
  );
};

export const getLabelMap = (fields) => {
  const n = {};
  utils.forEach(fields, function (t, e) {
    $.extend(n, _getFieldLabelMap(e));
  }),
    !1;
  return n;
};

export const setValue = (
  displayRule: string,
  labelMap: { [key: string]: ILabelMap },
  editor: any,
  isEdit,
  parentFlatten: Array<any>,
) => {
  let invalid = false;
  const d: string[] = [],
    e: { [key: string]: any }[] = [];
  if (displayRule) {
    var f = displayRule.split('\n');
    utils.forEach(f, function (index: number, item: string) {
      var f = '',
        g = item.split(/(\$[\w\.#@]+)/g);
      utils.forEach(g, function (b: number, str: string) {
        if (/^\$(_widget_|_formula_|ext)/.test(str)) {
          let target;
          utils.startWith(str, '$ext')
            ? (target = '扩展字段')
            : labelMap &&
              (target =
                labelMap[str.split('true')[0]] ||
                (!str?.includes('.')
                  ? {
                      ...parentFlatten.find((parent) => {
                        if (str?.includes('_old'))
                          return (
                            str?.includes(
                              `${stringToUnicode(parent.formCode)}`,
                            ) && parent.$id === '_old'
                          );
                        else
                          return (
                            str?.includes(
                              `${stringToUnicode(parent.formCode)}`,
                            ) && parent.$id !== '_old'
                          );
                      }),
                      isParent: true,
                    }
                  : null));
          let h = !1;
          // TOFIXED 只显示当前表单字段被删除
          utils.isNull(target) &&
            ((target = '字段已被删除'), (h = !0), (invalid = true));
          if (
            target &&
            typeof target !== 'string' &&
            !target.title &&
            target.isParent
          ) {
            target = '表单已被删除';
          }
          var i = str.replace('$', '').split('#'),
            j = i[0],
            k = i[1],
            l = CodeMirror.Pos(index, f.length);
          f += '' + target?.title + '';
          var m = CodeMirror.Pos(index, f.length);
          e.push({
            from: l,
            to: m,
            field: !target
              ? null
              : target.isParent
              ? `${target.$id}`
              : j.split(`_widget_${stringToUnicode(target.formCode)}.`)[1],
            entry: k,
            invalid: h,
            text: target?.title || target,
            isEdit,
            formCode: target?.formCode || '',
          });
        } else f += str;
      }),
        d.push(f);
    });
  }
  editor.setValue(d.join('\n')),
    utils.forEach(e, function (a, c) {
      _markField(c, editor);
    });
  return invalid;
};

export const setDefaultValue = (
  fillValue: IFormulaValue | undefined,
  flattenList: { [key: string]: any[] },
  editor: Editor,
  isEdit: boolean,
  setInvalid?: (arg: boolean) => unknown,
) => {
  if (!fillValue) {
    return;
  }
  const newSchema = { $id: '#', id: '#', properties: {} };
  const parentFlatten: Array<any> = [];
  utils.forEach(flattenList, (key, flatten) => {
    utils.forEach(flatten, (index, val) => {
      if (val) {
        const schema = idToSchema(val);
        newSchema.properties = Object.assign(
          newSchema.properties,
          schema.properties,
        );
        parentFlatten.push({
          title: val['#']?.$title,
          formCode: val['#']?.formCode,
          $id: val['#']?.schema.$id.split('#')[1],
        });
      }
    });
  });
  const labelMap = getLabelMap(newSchema.properties);
  const { displayRule } = fillValue;
  setTimeout(() => {
    const invalid = setValue(
      displayRule,
      labelMap,
      editor,
      isEdit,
      parentFlatten,
    );
    setInvalid && setInvalid(invalid);
  }, 200);
};

export const _calFormula = (a, b, form) => {
  var d = a.split(/(\$[0-9a-zA-Z\._#@]+)/g),
    e = [];
  utils.forEach(d, function (a, d) {
    if (utils.startWith(d, '$_widget_') || utils.startWith(d, '$ext')) {
      var f = d.replace('$_widget_', '').split('#'),
        g = f[0],
        h = f[1];
      if (utils.isEmpty(h)) {
        var i;
        if (b) i = b[g];
        else {
          const formData = form.getValues();
          const pathArr = g.split('.');
          var j = pathArr[0];
          Array.isArray(formData[j])
            ? ((i = []),
              formData[j].forEach((a, b) => {
                i.push(_.get(formData, [j, b, pathArr[1]]));
              }))
            : j && (i = _.get(formData, pathArr));
        }
        var k = JSON.stringify(i) + '';
        !isNaN(i) && i < 0 && (k = '(' + k + ')'), e.push(k);
      } else e.push('"' + d + '"');
    } else e.push(d);
  });
  var f;
  try {
    f = utils.evalFormula(e.join(''), formula);
  } catch (a) {
    f = '';
  }
  return f;
};
