/*
 * :file description:
 * :name: \low-code-platform\src\common\http\index.ts
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-15 10:06:22
 * :last editor: gaoch
 * :date last edited: 2021-11-17 17:55:56
 */
//1、引入axios
import axios from 'axios';
import { message } from 'antd';
import { history, useLocation } from 'umi';
import { getToken, getUrlParam, isInDingtalk } from '@/utils';
import { fitDomain } from '@/utils/tungee';

type methodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
interface IIntecConfig {
  isCustomValidate?: boolean;
  isSurportPageSize?: boolean;
  [key: string]: any;
}

//2、创建axios的实例
// 1688 单独部署   修改改变domain
const source = getUrlParam('source');
const baseURL = source === '1688' ? DOMAIN_1688 : DOMAIN;
const httpService = axios.create({
  baseURL, // TODO:具体的配置可以根据项目情况而来
  timeout: 50000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});

//3、axios的拦截--request
httpService.interceptors.request.use(
  (config: any) => {
    // 请求成功处理
    const token = getToken();
    if (token) {
      //判断浏览器中的cookie中是否存在项目的token
      config.headers.Authorization = token;
    }
    return config;
  },
  (err) => {
    Promise.reject(err); // 请求错误处理
  },
);

httpService.interceptors.request.use(
  (req: any) => {
    if (req.url.includes('login')) return req;
    req.headers['Authorization'] = getToken();
    if (isInDingtalk()) {
      req.headers['appName'] = 'dingding_tungee_crm';
      req.headers['corpId'] = window.__corpId ?? getUrlParam('corpId');
      req.headers['token'] = getToken();
    }

    if (getUrlParam('appName')) {
      req.headers['appName'] = getUrlParam('appName');
      req.url = fitDomain(getUrlParam('appName'), req.url);
    }

    return req;
  },
  (err) => {},
);
//4、axios的拦截--response
httpService.interceptors.response.use(
  (res: any) => {
    // TODO:具体的code对应的处理可继续添加修改
    if (res?.data?.code === 200) {
      // message.info('请求成功');
    }

    // TODO:此处预留无权限提示和数据结构返回
    // if (res.data.code === XXX) {
    //     message.info('无权限');
    //     // 此处可以对数据结构重新定义返回 具体可根据实际情况编写
    //     // res.data.data = {

    //     // }
    // }

    //其他情况的文字提示
    if (res?.data?.code !== 200) {
      const { msg } = res?.data ?? {};
      if (res?.data?.code === 31005) {
        window.localStorage.removeItem('token');
        history.push('/login');
      } else {
        message.warning(msg);
      }
      throw msg;
    }
    return res;
  },
  (err) => {
    // TODO:具体的code对应的处理可继续添加修改
    if (err?.response?.code === 301) {
      console.log('登录过期');
    }
    if (err?.response?.code === 500) {
      console.log('请联系管理员');
    }
    const msg = err.response?.data?.msg;
    message.warning(msg);
    return Promise.reject(err);
  },
);

export function request<T>(
  url: string,
  method: methodType = 'GET',
  params = {},
  intecConfig?: IIntecConfig,
  config?: { showLoading: boolean; [key: string]: any },
) {
  const paramType = method === 'GET' ? 'params' : 'data';
  return new Promise<T>((resolve, reject) => {
    config?.showLoading && message.loading('加载中', 0);
    httpService
      .request<T>({
        url,
        method,
        [paramType]: params,
        headers: config,
        ...intecConfig,
      })
      .then((res: any) => {
        resolve(res?.data);
        config?.showLoading && message.destroy();
      })
      .catch((err) => {
        setTimeout(() => {
          message.destroy();
        }, 3000);
        reject(err);
      });
  });
}
export function getRequest<T>(
  url: string,
  params?: object,
  intecConfig?: IIntecConfig,
) {
  return request<T>(url, 'GET', params, intecConfig);
}
export function postRequest<T>(
  url: string,
  data?: object,
  intecConfig?: IIntecConfig,
) {
  return request<T>(url, 'POST', data, intecConfig);
}
export function putRequest<T>(
  url: string,
  data?: object,
  intecConfig?: IIntecConfig,
) {
  return request<T>(url, 'PUT', data, intecConfig);
}
export function deleteRequest<T>(
  url: string,
  data?: object,
  intecConfig?: IIntecConfig,
) {
  return request<T>(url, 'DELETE', data, intecConfig);
}
