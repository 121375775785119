/*
 * :file description: page process
 * :name: \low-code-platform\src\pages\generator\process\index.tsx
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-12 14:01:14
 * :last editor: hyw
 * :date last edited: 2022-02-23 11:13:42
 */
import { useEffect, useState, useRef } from 'react';
import UnOpened from './components/UnOpened';
import ProcessDesign from './components/ProcessDesign';
import { useModel, useLocation } from 'umi';
import { isCreateProcessByFormCode } from './api';

const Process = () => {
  const { formCode } = useModel('useGenerator', (model) => model);
  const [step, setStep] = useState(1);
  const openProcess = () => {
    setStep(2);
  };
  const { query } = useLocation<any>();
  useEffect(() => {
    isCreateProcessByFormCode({ formCode: formCode || query?.formCode })
      .then((res: any) => {
        const { data } = res;
        if (data) {
          setStep(2);
        } else {
          setStep(1);
        }
      })
      .catch(() => {});
  }, []);

  return (
    <>
      {step === 1 && <UnOpened openProcess={openProcess} />}
      {step === 2 && <ProcessDesign />}
    </>
  );
};

Process.wrappers = ['@/wrappers/pageAuth'];

export default Process;
