/*
 * @Description:
 * @FilePath: \low-code-platform\tg-renderer\packages\form-render\src\components\Comment\BottomComment.tsx
 * author: 名字
 * copyright: (c) 2021, Tungee
 * @Date: 2021-12-29 21:50:00
 * @LastEditors: 段文君
 * @LastEditTime: 2022-01-10 10:25:36
 */
import React, { useState, useEffect, useImperativeHandle, useRef } from 'react';
import { message, Button, Mentions, Input } from 'antd';
import c from './index.less';
import cx from 'classnames';
import { createPortal } from 'react-dom';
import CommentInput from '../CommentInput';
import { getHashParam } from '@/utils';

interface IFormDetailCommentProps {
  onComment?: () => void;
  commentReplyData?: any;
  showInput: boolean;
  onShow: () => void;
  onBlur: () => void;
  instanceId: string;
}

interface IMentionUser {
  avatar: string;
  emplId: string;
  id: string;
  name: string;
}

const BottomComment: React.FC<IFormDetailCommentProps> = (props, ref: any) => {
  const commentInputRef = React.useRef<any>();
  const [mentionUsers, setMentionUsers] = useState<IMentionUser[]>([]);
  const [typingComment, setTypingComment] = useState('');
  const [sendToChat, setSendToChat] = useState(false);

  const {
    showInput,
    commentReplyData,
    onShow,
    onBlur,
    onComment,
    instanceId,
  } = props;

  const formCode = getHashParam('formCode');
  const urlInstanceId = getHashParam('instanceId');

  useEffect(() => {
    if (showInput) {
      commentInputRef?.current?.focus();
    }
  }, []);

  const creatorInfo = {
    isDelete: commentReplyData?.isDelete,
    avatar: commentReplyData?.avatar,
    emplId: commentReplyData?.commentator,
    id: commentReplyData?.commentator,
    name: commentReplyData?.commentatorName,
  };

  /**
   * 收起输入框
   */
  const onFold = () => {
    onBlur();
  };

  /**
   * 当at了某个人的时候
   */
  const triggerCommentMention = (users: IMentionUser[]) => {
    setMentionUsers(users);
  };

  const endTyping = () => {
    onBlur();
  };

  const changeComment = (text: string) => {
    setTypingComment(text);
  };

  /**
   * 提交评论
   */
  const submitComment = async () => {
    if (!typingComment) {
      return;
    }
    const formData =
      sessionStorage.getItem(`${window.__corpId}_tungee_form`) ?? '{}';
    const user =
      sessionStorage.getItem(`tungee_userinfo_${window.__corpId}`) ?? '{}';
    let form, userInfo;
    try {
      form = JSON.parse(formData);
      userInfo = JSON.parse(user);
    } catch (e) {
      console.log(e, '解析发生错误');
    }
    if (commentReplyData && commentReplyData.commentatorName) {
      mentionUsers.push(creatorInfo);
    }
    let prefix = '';
    if (commentReplyData?.id) {
      prefix = `回复 [${commentReplyData?.commentatorName}](${commentReplyData?.commentator})`;
    }
    const params: any = {
      corpId: window.__corpId,
      formCode: form?.bizId ?? formCode,
      formInstanceId: instanceId ?? urlInstanceId,
      commentator: userInfo?.dtalkUserId,
      commentatorName: userInfo?.name,
      comment: prefix + typingComment,
    };
    if (commentReplyData?.id) {
      params.replyId = commentReplyData?.id;
      params.notifyUserIds = [creatorInfo, ...(mentionUsers || [])];
    } else {
      params.notifyUserIds = mentionUsers || [];
    }
    const notifyUserIds: string[] = params.notifyUserIds
      .map((user) => {
        if (typeof user === 'string') {
          return user;
        }
        return user?.emplId;
      })
      .filter((item) => !!item);
    // 去重过的
    params.notifyUserIds = Array.from(new Set(notifyUserIds));
    await window?.__createComment(params);
    message.success('评论成功!');
    commentInputRef?.current?.clear();
    onFold();
    onComment?.();
  };

  /**
   * 改变是否发送给对话的状态
   */
  const onChangeSendToChat = (value: boolean) => {
    setSendToChat(value);
  };

  return (
    <footer className={c.bottom_btns}>
      {createPortal(
        <CommentInput
          ref={commentInputRef}
          onFold={onFold}
          mentionUsers={
            commentReplyData && commentReplyData.commentatorName
              ? [creatorInfo, ...mentionUsers]
              : mentionUsers
          }
          className={cx(c['gapp-entity-detail-comment-input'], {
            [c['hideInput']]: !showInput, // 兼容改造前改动, 做成hide
          })}
          onMention={triggerCommentMention}
          onChange={changeComment}
          onSubmit={submitComment}
          onCtlEnter={submitComment}
          onBlur={endTyping}
          sendToChat={sendToChat}
          onChangeSendToChat={onChangeSendToChat}
          placeholder={
            commentReplyData?.commentatorName
              ? `回复 ${commentReplyData?.commentatorName}: `
              : undefined
          }
        />,
        document.getElementById('form_detail_drawer')?.children[1] ||
          document.body,
      )}
    </footer>
  );
};

export default BottomComment;
