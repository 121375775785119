/*
 * :file description: component GeneratorHeader
 * :name: /low-code-platform/src/layouts/components/GeneratorHeader/index.tsx
 * :author: ershing
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-11 20:26:20
 * :last editor: KyleLiang
 * :date last edited: 2024-08-02 14:43:11
 */
import React, { useEffect, useState } from 'react';
import { history, useLocation, useModel } from 'umi';
import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import c from './index.less';
import './index.less';
import cx from 'classnames';
import IconFont from '@/components/IconFont';
import { DEFAULT_SCHEMA, editType } from '@/pages/generator/form';
import {
  DEFAULT_ICON,
  DEFAULT_ICON_COLOR,
} from '@/components/CustomFormProfile/IconSetting';
import { baseFormBizTypes, getUrlParam, isInDingtalk } from '@/utils';
import { DEFAULT_SNAPSHOT } from '@/models/useGenerator';
import { PsiBiztype } from '../../constant';
import { getFormWidgetPropertyLimit } from '@/pages/generator/form/services/form-generator';
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';
import { useGray } from '@/pages/generator/relation/hooks';

export const routeMap = {
  1: '/generator/form',
  2: '/generator/process',
  '/generator/form': 1,
  '/generator/process': 2,
};

export enum formType {
  // 0:流程表单
  PROCESS_FORM,
  // 1:非流程表单
  NON_PROCESS_FORM,
}

export default function GeneratorHeader() {
  const location = useLocation();
  const isGray = useGray('base_form_process');
  const { formCode, type, versionValue, source = '' } = location?.query || {};

  const [formProperty, setFormProperty] = useState({
    formProperties: [],
    formVersions: [],
    widgetProperties: {},
  });

  const {
    profile,
    returnCallback,
    handleFormCode,
    updateProfile,
    schemaRef,
    snapshotRef,
    formPropsSettingRef,
    isSubmitRef,
    ifEdit,
  } = useModel('useGenerator', (model) => model);

  const isRelationRoute = /\/generator\/relation\/.*/.test(location.pathname);

  const initGeneratorModal = () => {
    if (type === editType.EDIT) {
      handleFormCode({ formCode });
    } else {
      handleFormCode({ formCode: null });
    }
    // updateProfile({
    //   iconColor: DEFAULT_ICON_COLOR,
    //   icon: DEFAULT_ICON,
    // });
    schemaRef.current = DEFAULT_SCHEMA;
    snapshotRef.current = DEFAULT_SNAPSHOT;
    isSubmitRef.current = false;
  };

  const comeBack = () => {
    const hasEdit = returnCallback?.callback();
    const nextPath =
      type === editType.EDIT ? `/form/${formCode}` : '/todoMessage';
    if (hasEdit || ifEdit) {
      Modal.confirm({
        className: c.leave,
        title: (
          <span className={c.content}>有修改的内容未保存，确认离开吗？</span>
        ),
        icon: <ExclamationCircleOutlined />,
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          formPropsSettingRef.current = undefined;
          schemaRef.current = DEFAULT_SCHEMA;
          history.push(nextPath);
        },
      });
      return;
    }
    formPropsSettingRef.current = undefined;
    schemaRef.current = DEFAULT_SCHEMA;
    history.push(nextPath);
  };

  const handleTab = (pathname: string) => {
    const hasEdit = returnCallback?.callback();
    const isHasSchema = Object.keys(schemaRef.current.properties).length !== 0;
    const path = location.pathname;
    if (path === '/generator/form' && (hasEdit || !isHasSchema)) {
      Modal.warning({
        className: c.leave,
        title: <span className={c.content}>离开前请先保存表单设计</span>,
        icon: <ExclamationCircleOutlined />,
        okText: '我知道了',
        maskClosable: false,
        onOk: () => {
          // history.push(`/generator/form${location?.search}`);
        },
      });
    } else if (path === '/generator/process' && ifEdit) {
      Modal.warning({
        className: c.leave,
        title: <span className={c.content}>离开前请先保存流程设计</span>,
        icon: <ExclamationCircleOutlined />,
        okText: '我知道了',
        maskClosable: false,
        onOk: () => {
          // history.push(`/generator/process${location?.search}`);
        },
      });
    } else {
      history.push(pathname);
    }
    // if (
    //   pathname === `/generator/process${location?.search}` &&
    //   (hasEdit || !isHasSchema)
    // ) {
    //   Modal.warning({
    //     className: c.leave,
    //     title: <span className={c.content}>离开前请先保存表单设计</span>,
    //     icon: <ExclamationCircleOutlined />,
    //     okText: '我知道了',
    //     maskClosable: false,
    //     onOk: () => {
    //       history.push(`/generator/form${location?.search}`);
    //     },
    //   });
    // } else if (pathname === `/generator/form${location?.search}` && ifEdit) {
    //   Modal.warning({
    //     className: c.leave,
    //     title: <span className={c.content}>离开前请先保存流程设计</span>,
    //     icon: <ExclamationCircleOutlined />,
    //     okText: '我知道了',
    //     maskClosable: false,
    //     onOk: () => {
    //       history.push(`/generator/process${location?.search}`);
    //     },
    //   });
    // } else {
    //   history.push(pathname);
    // }
  };

  const getPropertyLimit = async () => {
    const res = await getFormWidgetPropertyLimit();
    // console.log(res, '123444');
    setFormProperty(res?.data ?? {});
  };

  useEffect(() => {
    history.replace(location);
  }, [location.pathname]);

  useEffect(() => {
    initGeneratorModal();
    getPropertyLimit();
  }, []);

  // 扩展设置跳转
  const jumpToExtendedSetting = () => {
    if (
      ([2, 3].includes(Number(versionValue)) || source === 'CloudPlatform') &&
      formProperty?.formProperties?.length > 0
    ) {
      handleTab(`/generator/relation/business${location?.search}`);
    } else if (Number(versionValue) === -1) {
      handleTab(`/generator/relation/business${location?.search}`);
    } else {
      handleTab(`/generator/relation/print${location?.search}`);
    }
  };

  // const isShowProcessDesign = !(
  //   profile.bizType && profile.formType === formType.NON_PROCESS_FORM
  // );

  //  profile.formType === formType.NON_PROCESS_FORM  添加，因资金管理需求
  // erp云产品默认展示
  const isShowProcessDesign =
    (baseFormBizTypes.includes(profile?.bizType ?? '') ? isGray : true) &&
    (!profile.bizType ||
      PsiBiztype.includes(profile.bizType) ||
      profile.formType === formType.PROCESS_FORM ||
      profile.formType === formType.NON_PROCESS_FORM ||
      source === 'CloudPlatform') &&
    source !== 'dataPush';

  const isShowFormDesign = source !== 'dataPush';

  const handleLinkBackstage = () => {
    const tungeeToken = getUrlParam('tungeeToken');
    const subscribeId = getUrlParam('subscribeId');

    const managementUrl = `${
      isOpenInTripartitePlatform('1688') ? DOMAIN_QRCODE_1688 : DOMAIN_QRCODE
    }/tungee/management/?corpId=${window.__corpId}&source=${
      source || 1688
    }&tungeeToken=${encodeURIComponent(
      tungeeToken,
    )}&subscribeId=${subscribeId}&appUuid=${window.__appUuid}`;

    window.location.href = `${managementUrl}#/formSettings`;
  };

  return (
    <div className={c.header}>
      <section className={c.left}>
        {isOpenInTripartitePlatform('1688') && (
          <IconFont
            onClick={handleLinkBackstage}
            className={c.leftarrowline}
            type="icon-leftarrowline"
            size={16}
          />
        )}
        {!isInDingtalk() && (
          <span className={c.backIcon} onClick={comeBack}>
            <IconFont type="icon-fanhui"></IconFont>
          </span>
        )}
        <span
          style={{ backgroundColor: profile?.iconColor }}
          className={c.logo}
        >
          <IconFont type={profile?.icon}></IconFont>
        </span>
        <span className={c.title} title={profile?.title}>
          {profile?.title}
        </span>
      </section>
      <section className={c.mid}>
        {isShowFormDesign && (
          <span
            className={cx(c.btnTab, {
              [c.select]: location.pathname === `/generator/form`,
            })}
            onClick={() => handleTab(`/generator/form${location?.search}`)}
          >
            表单设计
          </span>
        )}
        {isShowProcessDesign && (
          <span
            className={cx(c.btnTab, {
              [c.select]: location.pathname === `/generator/process`,
            })}
            onClick={() => handleTab(`/generator/process${location?.search}`)}
          >
            流程设计
          </span>
        )}

        <span
          className={cx(c.btnTab, {
            // [c.select]: location.pathname === `/generator/relation`,
            [c.select]: isRelationRoute,
          })}
          onClick={jumpToExtendedSetting}
        >
          扩展设置
        </span>
        {/* <span
          className={cx(c.tabUnderline, {
            [c.type2]:
              location.pathname === `/generator/process` ||
              (isShowProcessDesign && isRelationRoute && isShowFormDesign),
            [c.type3]:
              isShowFormDesign && isShowProcessDesign && isRelationRoute,
          })}
        ></span> */}
      </section>
      <section className={c.right}></section>
    </div>
  );
}
