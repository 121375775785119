/*
 * :file description:
 * :name: \low-code-platform\src\services\form\index.ts
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-27 14:43:49
 * :last editor: hyw
 * :date last edited: 2022-03-22 10:43:29
 */

import {
  request,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '@/common/http';
import {
  getTemplateParams,
  uploadFileParams,
  importPreviewParams,
  importStartParams,
  taskResultParams,
  exportParams,
} from './data';

// 删除表单
function deleteForm(data: any) {
  return postRequest('/api/form/schema/deleteFormSchema', data);
}

// 获取所有搜索字段选项
function getAllField(data: any) {
  return postRequest('/api/formSearch/getAllField', data);
}

// 新增和修改常用筛选项字段
function updateFilterField(data: any) {
  return postRequest('/api/formSearch/addOrUpdateFrequentlyField', data);
}

// 关键词全局搜索
function queryFormDataList(data: any) {
  return postRequest('/api/formSearch/queryFormDataList', data);
}

// 查询表单列表数据
function getFormDataList(data: any) {
  return postRequest('/api/formSearch/queryFormDataList', data);
}

// 表单运行态-新建表单实例数据提交
function createFromInstance(data: any, config) {
  return postRequest('/api/formInstance/submitInstance', data, config);
}

// 表单运行态-详情编辑后保存
function editFromInstance(data: any, config) {
  return postRequest('/api/formInstance/resaveInstance', data, config);
}

// 表单运行态-根据实例id获取实例数据
function getFromInstanceDetail(data: any, config) {
  return postRequest('/api/formInstance/getDataByInstanceid', data, config);
}

// 待办消息-根据实例id获取实例数据
function getDataForTodoList(data: any, config) {
  return postRequest('/api/formInstance/getDataForTodoList', data, config);
}

// 表单运行态-单个-批量删除实例
function deleteFormBasedInstances(data: any, config) {
  return postRequest('/api/formInstance/deleteInstance', data, config);
}

// 撤销流程
function repealFlow(data: any) {
  return postRequest('/api/processTask/revokeTask', data);
}

// 退回流程
function returnFlow(data: any) {
  return postRequest('/api/processTask/returnTask', data);
}

// 审批同意
function approveAgree(data: any) {
  return postRequest('/api/processTask/agreeTask', data);
}

// 审批拒绝
function approveRefuse(data: any) {
  return postRequest('/api/processTask/refuseTask', data);
}

// 转交
function transferFlow(data: any) {
  return postRequest('/api/processTask/transferTask', data);
}

// 查看流程图
function viewProcessDiagram(data: any) {
  return postRequest('/api/processInstance/viewProcessDiagram', data);
}

// 判断是否有设置流程条件分支
function hasGateWay(data: any) {
  return getRequest(
    '/api/processDefinition/needDynamicViewProcessDiagram',
    data,
  );
}

// 新建表单 预测流程图走向
function expectProcessDiagram(data: any) {
  return postRequest('/api/processInstance/expectProcessDiagram', data);
}

// 获取节点表单配置
function getFormConfig(data: any) {
  return getRequest('/api/processDefinition/getFormConfig', data);
}

// 获取配置
function getGlobalSetting(data: any) {
  return getRequest('/api/processDefinition/getGlobalSetting', data);
}

// 表单运行态 - 根据实例id获取实例数据
function getDataByInstanceid(data: any, config) {
  return postRequest('/api/formInstance/getDataByInstanceid', data, config);
}

// 查询表单schema
function getFormSchema(data: any) {
  return postRequest('/api/form/schema/getFormSchema', data);
}

// 查询表单schema
function getCurrentTaskByProcInsId(data: any) {
  return postRequest('/api/processTask/getCurrentTaskByProcInsId', data);
}

// 可退回的节点
function returnableNodes(data: any) {
  return getRequest('/api/processTask/returnableNodes', data);
}

// 查询流程节点信息列表(已过滤发起节点)
function getNodeList(data: any) {
  return getRequest('/api/processInstance/getActivateNodeList', data);
}

// 重新激活流程
function recreateProcessInstance(data: any) {
  return postRequest('/api/processInstance/recreateProcessInstance', data);
}

// 上传图片
function uploadImages(data: any, other) {
  return postRequest('/api/common/upload/uploadFile', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: function (progressEvent) {
      //原生获取上传进度的事件
      if (progressEvent.lengthComputable) {
        const percent = (progressEvent.loaded / progressEvent.total) * 100;
        other?.progressCallback && other?.progressCallback(percent);
      }
    },
  });
}

// 获取导入模板
function getTemplate(data: getTemplateParams) {
  return postRequest('/api/formInstance/excel/getTemplate', data, {
    headers: { formCode: data.formCode },
  });
}

// 上传Excel文件到oss
function uploadFile(data: uploadFileParams) {
  return postRequest('/api/formInstance/excel/uploadFile', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      formCode: data.get('formCode'),
    },
  });
}

// 上传Excel的预览数据
function importPreview(data: importPreviewParams) {
  return postRequest('/api/formInstance/excel/import/preview', data, {
    headers: { formCode: data.formCode },
  });
}

// 开导
function importStart(data: importStartParams) {
  return postRequest('/api/formInstance/excel/import/start', data, {
    headers: { formCode: data.formCode },
  });
}

// 轮询接口，获取导入、导出任务的状态
function getTaskResult(data: taskResultParams) {
  return postRequest('/api/formInstance/excel/task/result', data);
}

// 导出表单实力数据
function exportData(data: exportParams) {
  return postRequest('/api/formInstance/excel/exportData', data, {
    headers: { formCode: data.formCode },
  });
}
// 获取省份列表
function getProvinceList() {
  return postRequest('/api/formInstance/provinceList');
}

// 获取城市列表
function getCityList(provinceCode: string) {
  return postRequest(`/api/formInstance/cityList?provinceCode=${provinceCode}`);
}

// 获取区级列表
function getAreaList(cityCode: string) {
  return postRequest(`/api/formInstance/areaList?cityCode=${cityCode}`);
}

// 获取街道列表
function getStreetList(areaCode: string) {
  return postRequest(`/api/formInstance/streetList?areaCode=${areaCode}`);
}

// 获取被关联实例列表
function getRelatedDataList(data: any, conf: any) {
  return postRequest('/api/formInstance/relatedDataList', data, conf);
}

// 获取表单操作属性
function getFormSchemas(data: any) {
  return postRequest(`/api/form/schema/getFormSchema`, data);
}

// 获取具体流程详情
function getProcessDefinitionInfo(data: any) {
  return getRequest(`/api/processDefinition/getProcessDefinitionInfo`, data);
}

// 获取支持导入的表单字段
function listSupportFields(data: any) {
  return postRequest(`/api/formInstance/excel/import/listSupportFields`, data, {
    headers: { formCode: data.formCode },
  });
}

export default {
  viewProcessDiagram,
  deleteForm,
  getAllField,
  updateFilterField,
  queryFormDataList,
  getFormDataList,
  createFromInstance,
  editFromInstance,
  getFromInstanceDetail,
  deleteFormBasedInstances,
  repealFlow,
  returnFlow,
  approveAgree,
  approveRefuse,
  transferFlow,
  getGlobalSetting,
  getDataByInstanceid,
  getFormSchema,
  getProcessDefinitionInfo,
  getCurrentTaskByProcInsId,
  returnableNodes,
  hasGateWay,
  expectProcessDiagram,
  getFormConfig,
  getNodeList,
  recreateProcessInstance,
  getDataForTodoList,
  uploadImages,
  getTemplate,
  uploadFile,
  importPreview,
  importStart,
  getTaskResult,
  exportData,
  getProvinceList,
  getCityList,
  getAreaList,
  getStreetList,
  getRelatedDataList,
  getFormSchemas,
  listSupportFields,
};
