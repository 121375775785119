/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\EditBizSuite\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-24 11:14:07
 * :last editor: 黄艺
 * :date last edited: 2022-03-24 15:21:28
 */

import { FC } from 'react';
import { WidgetProps } from 'typings';
import c from './index.less';

const BizSuite: FC<WidgetProps> = (props) => {
  const { addons } = props;

  addons.setSchemaByPath(addons.dataPath, { hidden: true });
  return <div></div>;
};

export default BizSuite;
