/*
 * :file description: page process
 * :name: \commond:\progree\low-code-platform\src\pages\generator\process\components\UnOpened\index.tsx
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-12 14:01:14
 * :last editor: hyw
 * :date last edited: 2021-11-11 20:14:22
 */

import c from './index.less';
import React from 'react';
import { message, Button } from 'antd';
import { useModel } from 'umi';
import flow from '@/images/flow.png';
import { addProcess } from '../../api';
import { newVersionDefaultValue } from '../../constants';

interface UnOpenedProps {
  openProcess: Function;
}
const UnOpened: React.FC<UnOpenedProps> = ({ openProcess }) => {
  const { formCode } = useModel('useGenerator', (model) => model);

  //开启流程
  const start = () => {
    if (!formCode) {
      message.warning('没有获取到关联表单的formCode');
      return;
    }
    // 开始流程
    addProcess({
      formRelation: {
        formCode: formCode,
      },
      ...newVersionDefaultValue,
    })
      .then((res: any) => {
        const { code, msg } = res;
        if (code === 200) {
          // 触发顶部组件列表数据的回调
          // message.success('开启成功');
          openProcess();
        } else {
          message.warning('开启失败');
        }
      })
      .catch((err) => {
        message.warning('开启失败');
      });
  };
  return (
    <div className={c.unOpened}>
      <img src={flow} />
      <p>流程可实现需要更多人流转的业务场景</p>
      <Button type="primary" onClick={() => start()}>
        {' '}
        开启流程
      </Button>
    </div>
  );
};

export default UnOpened;
