import { Cascader } from 'antd';
import cities from './bsList.json';

const Area = (props) => {
  const { onChange, value } = props;

  return (
    <div>
      <Cascader
        options={cities}
        onChange={onChange}
        style={{
          width: '100%',
        }}
        value={value?.address}
      />
    </div>
  );
};

export default Area;
