import React from 'react';
import { Button } from 'antd';
import Iconfont from '@/components/IconFont';
import style from './index.less';

const processRelation = () => {
  return (
    <>
      <Button
        icon={<Iconfont type="icon-tianjia1" />}
        onClick={() => {}}
        className={style.btn}
      >
        请选择
      </Button>
    </>
  );
};

export default processRelation;
