import React, { useState, useEffect } from 'react';
import { Input, DatePicker } from 'antd';
import { isJSON, transferToMoment, customJP } from '../../utils';
import { IFormCompProps } from '../../typings';
import './index.less';

type WidgetProps = IFormCompProps;

const DateWidget = (props: WidgetProps) => {
  const { value, onChange = () => {}, schema = {} } = props;
  let { value: initialValue } = isJSON(value)
    ? customJP(value)
    : { value: undefined };
  const { placeholder = '请选择' } = schema;

  initialValue = transferToMoment(initialValue);

  const [date, setDate] = useState(initialValue);

  useEffect(() => {
    const payload = {
      value: date,
    };
    onChange(date ? JSON.stringify(payload) : '');
  }, [date]);

  useEffect(() => {
    if (isJSON(value)) {
      const valueObj = customJP(value);
      setDate(transferToMoment(valueObj.value));
    } else {
      setDate(undefined);
    }
  }, [value]);

  return (
    <Input.Group compact className="fr-group">
      <DatePicker
        style={{ width: '100%' }}
        allowClear
        value={date}
        placeholder={placeholder}
        onChange={(dates, dateStrings) => setDate(dates)}
      />
    </Input.Group>
  );
};

export default DateWidget;
