/*
 * @Author: wu.shangru roywu@tungee.com
 * @Date: 2023-04-23 19:00:08
 * @LastEditors: wu.shangru
 * @LastEditTime: 2024-01-03 11:31:05
 * @FilePath: /low-code-platform/tg-renderer/packages/form-render/src/widgets/FillObject/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import IconFont from '@/components/IconFont';
import React, { useEffect, useMemo, useState } from 'react';
import Styles from './index.less';
import { Popover, Modal, Button, TreeSelect, Form } from 'antd';
import { customAlphabet } from 'nanoid';
import {
  ALPHABET,
  NANOID_LENGTH,
} from '@/components/FormGenerator/utils/constant';
import data_fill_intro from '@/assets/data_fill_intro.png';
import fillObjectDemo from '@/assets/fillObjectDemo.png';
import FillModal from './FillModal';

export const nanoid = customAlphabet(ALPHABET, NANOID_LENGTH);

const content: React.FC = () => {
  return (
    <div>
      <div className={Styles.contentDes}>
        负责人、协同人、成员组件支持数据填充至「手机号」、「邮箱」，若有多个人员，则默认填第一个成员的信息。
      </div>
      {/* <div className={Styles.contentDemoText}>
        示例
        <br />
        【负责人】组件设置了填充【电话】，设置后，可以将该负责人的电话填充到当前表单的对应电话组件中
      </div> */}
      <div className={Styles.contentDemoImg}>
        <img src={fillObjectDemo} alt="" />
      </div>
    </div>
  );
};

const FillObject: React.FC = (props: any) => {
  const { addons = {} } = props;
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <div className={Styles.wrapper}>
      <Popover
        placement="bottomLeft"
        arrowPointAtCenter
        overlayClassName={Styles.popover}
        content={content}
      >
        <div className={Styles.popoverIcon}>
          <IconFont type="icon-shuoming"></IconFont>
        </div>
      </Popover>
      <div
        className={Styles.btn}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        {props?.value?.length ? '已设置填充规则' : '设置填充规则'}
      </div>
      <FillModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        {...props}
      ></FillModal>
    </div>
  );
};

export default FillObject;
