import React, { useState } from 'react';
import { Button } from 'antd';

const Pagination = (props: any) => {
  const {
    search,
    currentToken,
    nextToken,
    tokenHistory,
    setTokenHistory,
    hasMore,
  } = props;
  const [pageSize] = useState(props.pageSize || 10);
  const handlePageIndexChange = (type: string) => {
    if (type === 'prev') {
      if (tokenHistory.length <= 0) return;
      search({ offset: tokenHistory[tokenHistory.length - 1], pageSize });
      setTokenHistory(tokenHistory.slice(0, tokenHistory.length - 1));
    } else {
      setTokenHistory(tokenHistory.concat(currentToken));
      search({ offset: nextToken, pageSize });
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
      }}
    >
      <Button
        disabled={tokenHistory.length <= 0}
        onClick={() => handlePageIndexChange('prev')}
      >
        上一页
      </Button>
      <Button
        style={{
          marginLeft: '10px',
        }}
        disabled={!hasMore}
        onClick={() => handlePageIndexChange('next')}
      >
        下一页
      </Button>
    </div>
  );
};

export default Pagination;
