/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\EditInnerContact\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-27 20:28:03
 * :last editor: hyw
 * :date last edited: 2022-02-23 16:08:24
 */
import React, { useRef } from 'react';
import { Button, Tag } from 'antd';
import Iconfont from '@/components/IconFont';
import MultipleChoice from '@/components/MultipleChoice';
import Style from './index.less';
import cs from 'classnames';

const EditInnerContact = (props) => {
  const { onChange, value, schema } = props;
  const { choice } = schema;
  const multiple = choice == 1;
  const childRef = useRef(null);

  const getDataBack = (data: any) => {
    const users = data?.users || [];
    const dynamicMember = data?.dynamicMember || [];
    const _users = users.map((user) => {
      return {
        key: user.userId || user.key,
        value: user.name,
        avatar: user.avatar,
        type: 'static',
      };
    });
    const _dynamicMember = dynamicMember.map((val) => {
      return {
        key: val.id,
        value: val.name,
        avatar: '',
        type: 'dynamic',
      };
    });
    onChange([..._users, ..._dynamicMember]);
  };

  const onClose = (index) => {
    value.splice(index, 1);
    onChange(value);
  };

  const users = (value || [])
    .filter?.((val) => val.type === 'static')
    .map((item) => {
      return {
        key: item.key,
        name: item.value,
        avatar: item.avatar,
      };
    });

  const dynamicMember = (value || [])
    .filter?.((val) => val.type === 'dynamic')
    .map((item) => {
      return {
        id: item.key,
        name: item.value,
      };
    });

  const isAdd =
    (!multiple && users.length == 0) || (multiple && users.length <= 100);

  return (
    <div className={Style.field}>
      {isAdd && !props.readOnly && (
        <Button
          icon={<Iconfont type="icon-tianjia1" />}
          onClick={() => (childRef?.current as any)?.openModal()}
          className={cs(Style.btn, Style.mgr)}
        >
          添加
        </Button>
      )}
      {!isAdd && !props.readOnly && (
        <Button
          icon={<Iconfont type="icon-bianji" />}
          onClick={() => (childRef?.current as any)?.openModal()}
          className={Style.mgr}
        >
          修改
        </Button>
      )}
      <div>
        {(value || []).map((item, index) => {
          return (
            <Tag
              {...{ closable: !props.readOnly }}
              onClose={() => {
                onClose(index);
              }}
              style={{ borderRadius: '4px', display: 'inline-block' }}
            >
              {!!item.avatar && (
                <img src={item.avatar} className={Style['tag-img']} />
              )}{' '}
              {item.value}
            </Tag>
          );
        })}
      </div>

      <div onClick={(e) => e.stopPropagation()}>
        <MultipleChoice
          cRef={childRef}
          initData={{
            users,
            dynamicMember,
          }}
          getDataBack={getDataBack}
          tabs={['人员', '动态成员']}
          multiple={multiple}
          title="选择人员"
        />
      </div>
    </div>
  );
};

export default EditInnerContact;
