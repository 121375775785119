/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\components\FormFlow\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-08 09:26:33
 * :last editor: hyw
 * :date last edited: 2022-01-26 18:17:51
 */
import React, { useEffect, useState } from 'react';
import c from './index.less';
import passImg from '../../assets/images/pass.svg';
import approveImg from '../../assets/images/approve.svg';
import forwardImg from '../../assets/images/forward.svg';
import revokeImg from '../../assets/images/revoke.png';
import shenpiImg from '../../assets/images/shenpi.svg';
import returnImg from '../../assets/images/return.svg';
import refuseImg from '../../assets/images/refuse.svg';
import refusePng from '../../assets/images/refuse.png';
import formApi from '../../services/api';
import cc from '../../assets/images/cc.svg';
import bigPassImg from '~@/packages/form-render/src/assets/images/bigPass.png';
import zhankaiImg from '../../assets/images/zhankai.svg';
import defaultAvatar from '../../assets/images/defaultAvatar.png';
import IconFont from '../IconFont';

const FlowItem = (props: any) => {
  const { instanceId, formcode, todoList } = props;
  const [processData, setProcessData] = useState([]);
  const [resultImage, setImage] = useState();
  const [isUnfold, setIsUnfold] = useState(false);
  const date = new Date().getFullYear();

  const image = {
    agree: passImg,
    forward: forwardImg,
    refuse: refuseImg,
    return: returnImg,
    withdraw: 5,
  };
  const getFormDetail = async () => {
    if (todoList === 'todoList') {
      formApi
        .getDataForTodoList(
          {
            formCode: formcode,
            instanceId: instanceId,
          },
          {
            headers: {
              formCode: formcode,
            },
          },
        )
        .then((res: any) => {
          if (!res?.data?.processId) return;
          formApi
            .viewProcessDiagram({
              processId: res?.data?.processId,
              formInstanceId: instanceId,
            })
            .then((resp: any) => {
              if (resp.data.processResult === 1) {
                setImage(bigPassImg);
              } else if (resp.data.processResult === 2) {
                setImage(revokeImg);
              } else if (resp.data.processResult === 3) {
                setImage(refusePng);
              }
              setProcessData(resp.data);
            });
        });
    } else {
      formApi
        .getDataByInstanceid(
          {
            formCode: formcode,
            instanceId: instanceId,
          },
          {
            headers: {
              formCode: formcode,
            },
          },
        )
        .then((res: any) => {
          if (!res?.data?.processId) return;
          formApi
            .viewProcessDiagram({
              processId: res?.data?.processId,
              formInstanceId: instanceId,
            })
            .then((resp: any) => {
              if (resp.data.processResult === 1) {
                setImage(bigPassImg);
              } else if (resp.data.processResult === 2) {
                setImage(revokeImg);
              } else if (resp.data.processResult === 3) {
                setImage(refusePng);
              }
              setProcessData(resp.data);
            });
        });
    }
  };
  const unfold = () => {
    setIsUnfold(!isUnfold);
  };
  useEffect(() => {
    getFormDetail();
  }, []);
  return (
    <div>
      {/*{resultImage ? <img className={c.bigPassImg} src={resultImage} /> : null}*/}
      {processData?.results?.map((item) => {
        return (
          <div>
            {item?.nodes?.map((t, index) => {
              return (
                <div>
                  <div>
                    <div>{t.approvalTime}</div>
                    {t.nodeBusinessName === '激活流程' &&
                    t.nodeName === 'ApplyNode' ? (
                      <div className={c.activation_wire}></div>
                    ) : null}
                    <div className={c.flow_item}>
                      {t?.users &&
                      t?.users?.length == 1 &&
                      t.nodeName !== 'CarbonNode' ? (
                        <div>
                          <div style={{ display: 'flex' }}>
                            {t.isEmptyApprover ? (
                              t.nodeName === 'CarbonNode' ? null : (
                                <IconFont
                                  type="icon-zhuanjiao"
                                  className={c.forward_img}
                                ></IconFont>
                              )
                            ) : t.nodeName === 'CarbonNode' ? null : t?.users[0]
                                .approvalResult ? (
                              <img
                                className={c.flow_img}
                                src={image[t?.users[0].approvalResult]}
                              />
                            ) : (
                              <img className={c.flow_img} src={approveImg} />
                            )}
                            {t?.users[0].approvalResult ? (
                              <img
                                src={t?.users[0].userAvatar}
                                className={c.flow_avatar}
                              ></img>
                            ) : (
                              // <img src={shenpiImg} className={c.flow_avatar_app}></img>
                              <div className={c.flow_avatar_cc1}>
                                <img
                                  className={c.flow_avatar_img}
                                  src={shenpiImg}
                                />
                              </div>
                            )}
                            <div className={c.flow_item_container}>
                              <div className={c.flow_container}>
                                <span className={c.flow_start_text}>
                                  {t.nodeBusinessName}
                                </span>
                                <span className={c.flow_time}>
                                  {/*{*/}
                                  {/*  date === t?.users[0].approvalTime.substring(0,4) ? null:t?.users[0].approvalTime*/}
                                  {/*}*/}
                                  {date ==
                                  t?.users[0]?.approvalTime?.substr(0, 4)
                                    ? t?.users[0]?.approvalTime?.substr(5)
                                    : t?.users[0]?.approvalTime}
                                </span>
                              </div>
                              <div className={c.flow_name}>
                                {t?.users[0].userName}
                                {t?.users[0].approvalResult &&
                                t?.users[0].approvalResult === 'agree'
                                  ? '（已同意）'
                                  : null}
                                {t?.users[0].approvalResult &&
                                t?.users[0].approvalResult === 'refuse'
                                  ? '（已拒绝）'
                                  : null}
                                {t?.users[0].approvalResult &&
                                t?.users[0].approvalResult === 'forward'
                                  ? '（已转交）'
                                  : null}
                                {t?.users[0].approvalResult &&
                                t?.users[0].approvalResult === 'return'
                                  ? '退回至审批人'
                                  : null}
                                {t?.users[0].approvalResult &&
                                t?.users[0].approvalResult === 'withdraw'
                                  ? '（已撤销）'
                                  : null}
                                {!t?.users[0].approvalResult
                                  ? '（审批中）'
                                  : null}
                              </div>
                            </div>
                          </div>
                          {t?.users[0].comment ? (
                            <div className={c.flow_bar}>
                              <div className={c.flow_bar_text}>
                                {t?.users[0].comment}
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      {t.nodeName === 'CarbonNode' ? (
                        <div>
                          <div className={c.flow_item}>
                            <div className={c.flow_avatar_cc}>
                              <img className={c.flow_avatar_img} src={cc} />
                            </div>
                            <div className={c.flow_item_container}>
                              <div className={c.flow_container}>
                                <div className={c.flow_start_text}>
                                  {t.nodeBusinessName}
                                </div>
                              </div>
                              {t?.users && t?.users?.length !== 0 ? (
                                <div>
                                  {t.approveStatus === 0 ? (
                                    <div className={c.flow_name}>
                                      抄送{t?.users?.length}人
                                    </div>
                                  ) : null}
                                  {t.approveStatus === 1 ? (
                                    <div className={c.flow_name}>
                                      正在抄送中
                                    </div>
                                  ) : null}
                                  {t.approveStatus === 2 ? (
                                    <div className={c.flow_name}>
                                      已抄送{t?.users?.length}人
                                    </div>
                                  ) : null}
                                </div>
                              ) : null}
                              {t.remarks ? (
                                <div className={c.approve_single_container}>
                                  <span className={c.approve_wrapper_text}>
                                    {t.remarks}
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start',
                            }}
                          >
                            <div>
                              {t?.users?.length > 10 ? (
                                !isUnfold ? (
                                  <div
                                    className={
                                      !isUnfold ? c.cc_flex : c.cc_flex2
                                    }
                                  >
                                    {t?.users &&
                                      t?.users.slice(0, 10).map((item: any) => {
                                        return (
                                          <div
                                            className={c.cc_container}
                                            key={item.userId}
                                          >
                                            {item.userAvatar ? (
                                              <img
                                                className={c.cc_img}
                                                src={item.userAvatar}
                                              ></img>
                                            ) : (
                                              <img
                                                className={c.cc_img}
                                                src={defaultAvatar}
                                              ></img>
                                            )}
                                            <div className={c.cc_text}>
                                              {item.userName}
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                ) : (
                                  <div
                                    className={
                                      !isUnfold ? c.cc_flex : c.cc_flex2
                                    }
                                  >
                                    {t?.users &&
                                      t?.users.map((item: any) => {
                                        return (
                                          <div
                                            className={c.cc_container}
                                            key={item.userId}
                                          >
                                            {item.userAvatar ? (
                                              <img
                                                className={c.cc_img}
                                                src={item.userAvatar}
                                              ></img>
                                            ) : (
                                              <img
                                                className={c.cc_img}
                                                src={defaultAvatar}
                                              ></img>
                                            )}
                                            <div className={c.cc_text}>
                                              {item.userName}
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                )
                              ) : (
                                <div className={c.cc_flex}>
                                  {t?.users &&
                                    t?.users.slice(0, 10).map((item: any) => {
                                      return (
                                        <div
                                          className={c.cc_container}
                                          key={item.userId}
                                        >
                                          {item.userAvatar ? (
                                            <img
                                              className={c.cc_img}
                                              src={item.userAvatar}
                                            ></img>
                                          ) : (
                                            <img
                                              className={c.cc_img}
                                              src={defaultAvatar}
                                            ></img>
                                          )}
                                          <div className={c.cc_text}>
                                            {item.userName}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              )}
                            </div>
                            {t?.users && t?.users?.length > 10 ? (
                              <div className={c.zhan_contain}>
                                {!isUnfold ? (
                                  <img
                                    src={zhankaiImg}
                                    className={c.zhankai_img}
                                    style={{ width: '12px' }}
                                    onClick={unfold}
                                  ></img>
                                ) : (
                                  <img
                                    src={zhankaiImg}
                                    className={c.zhankai_img1}
                                    style={{ width: '12px' }}
                                    onClick={unfold}
                                  ></img>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      {t?.users &&
                      t?.users?.length > 1 &&
                      t.nodeName === 'ApprovalNode' ? (
                        <div>
                          <div className={c.flow_item}>
                            <img className={c.flow_img} src={approveImg} />
                            <div className={c.flow_avatar_cc1}>
                              <img className={c.flow_avatar_img} src={cc} />
                            </div>
                            <div className={c.flow_item_container}>
                              <div className={c.flow_container}>
                                <div className={c.flow_start_text}>
                                  {t.nodeBusinessName}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={c.cc_flex1}>
                            {t?.users.map((item: any) => {
                              return (
                                <div className={c.cc_container}>
                                  {item.userAvatar ? (
                                    <img
                                      className={c.cc_img}
                                      src={item.userAvatar}
                                    ></img>
                                  ) : (
                                    <img
                                      className={c.cc_img}
                                      src={defaultAvatar}
                                    ></img>
                                  )}
                                  <div className={c.cc_text}>
                                    {item.userName}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : null}
                      {t.isEmptyApprover &&
                      t.approvalExtension &&
                      t.approvalExtension.transferPersons &&
                      t.approvalExtension.transferPersons.length !== 0 ? (
                        <div className={c.approve_container}>
                          {t.isEmptyApprover ? (
                            t.nodeName === 'CarbonNode' ? null : (
                              <IconFont
                                type="icon-zhuanjiao"
                                className={c.forward_img}
                              ></IconFont>
                            )
                          ) : t.nodeName === 'CarbonNode' ? null : (
                            <img
                              className={c.flow_img}
                              src={image[t?.users[0].approvalResult]}
                            />
                          )}
                          <div>{t.nodeBusinessName}</div>
                          <div className={c.approve_single_container}>
                            <span className={c.approve_wrapper}>
                              找不到审批人
                            </span>
                            <span className={c.approve_wrapper_text}>
                              已自动转交给管理员
                            </span>
                            {t.approvalExtension.transferPersons.map(
                              (item: any) => {
                                return (
                                  <span className={c.approve_wrapper_text}>
                                    {item.name}
                                  </span>
                                );
                              },
                            )}
                          </div>
                        </div>
                      ) : t.isEmptyApprover && t.nodeName !== 'CarbonNode' ? (
                        <div className={c.approve_container}>
                          {t.isEmptyApprover ? (
                            t.nodeName === 'CarbonNode' ? null : (
                              <IconFont
                                type="icon-zhuanjiao"
                                className={c.forward_img}
                              ></IconFont>
                            )
                          ) : t.nodeName === 'CarbonNode' ? null : (
                            <img
                              className={c.flow_img}
                              src={image[t?.users[0].approvalResult]}
                            />
                          )}
                          <div>{t.nodeBusinessName}</div>
                          <div className={c.approve_single_container}>
                            <span className={c.approve_wrapper}>
                              找不到审批人
                            </span>
                            <span className={c.approve_wrapper_text}>
                              自动通过
                            </span>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {item.nodes.length === index + 1 ? null : (
                      <div>
                        {t.isEmptyApprover || t.nodeName === 'CarbonNode' ? (
                          <div className={c.flow_wire_container}>
                            <div className={c.flow_wire_height}></div>
                          </div>
                        ) : t?.users?.length == 1 && t?.users[0].comment ? (
                          <div className={c.flow_wire_container}>
                            <div className={c.flow_wire_height}></div>
                          </div>
                        ) : t?.users?.length > 1 ? (
                          <div className={c.flow_wire_container}>
                            <div className={c.flow_wire_height}></div>
                          </div>
                        ) : (
                          <div className={c.flow_wire}></div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
const FormFlow = (props: { [key: string]: any }) => {
  const { instanceId, formcode, todoList } = props;
  return (
    <div>
      {/*{[1,2].map((item) => {*/}
      {/*  return <FlowItem />;*/}
      {/*})}*/}
      <FlowItem
        instanceId={instanceId}
        formcode={formcode}
        todoList={todoList}
      />
    </div>
  );
};

export default FormFlow;
