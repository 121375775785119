import React, { useState, useEffect } from 'react';
import { InputNumber } from 'antd';
import { isNumber } from 'lodash-es';
import { isJSON, customJP } from '../../utils';
import { IFormCompProps } from '../../typings';
import c from './index.less';

const nzhcn = require('nzh/cn');
type WidgetProps = IFormCompProps;

const MoneyWidget = (props: WidgetProps) => {
  const { value, onChange = () => {}, schema = {} } = props;
  const { value: initialValue } = isJSON(value)
    ? customJP(value)
    : { value: undefined };
  const { placeholder = '请输入金额', limitDot, upper } = schema;
  const [num, setNum] = useState(initialValue);

  const limitDecimals = (val: string | number | undefined): string => {
    const regStr = `/^(\\-)*(\\d+)\\.(\\d{0,${limitDot || 0}}).*$/`;
    const reg = eval(regStr);

    if (typeof val === 'string') {
      return !isNaN(Number(val)) ? val.replace(reg, '$1$2.$3') : '';
    } else if (typeof val === 'number') {
      return !isNaN(val) ? String(val).replace(reg, '$1$2.$3') : '';
    } else {
      return '';
    }
  };

  useEffect(() => {
    const payload = {
      value: num,
    };
    onChange(isNumber(num) ? JSON.stringify(payload) : '');
  }, [num]);

  useEffect(() => {
    if (value && isJSON(value)) {
      const valueObj = customJP(value);
      setNum(valueObj.value);
    }
  }, [value]);

  return (
    <div className={c.container}>
      <InputNumber
        style={{ width: '100%' }}
        value={num}
        onChange={(e) => {
          setNum(e);
        }}
        formatter={limitDecimals}
        parser={limitDecimals}
        placeholder={placeholder}
      />
      {upper && (
        <>
          <span className={c.big}>大写</span>
          <div>
            {num &&
              nzhcn.toMoney(Number(num), {
                outSymbol: false,
              })}
          </div>
        </>
      )}
    </div>
  );
};

export default MoneyWidget;
