/*
 * @Description:
 * @FilePath: \low-code-platform\tg-renderer\packages\form-render\src\components\Avatar\index.tsx
 * author: 段文君
 * copyright: (c) 2022, Tungee
 * @Date: 2022-01-10 21:13:39
 * @LastEditors: 段文君
 * @LastEditTime: 2022-01-10 21:27:33
 */
import React from 'react';
import c from './index.less';
import cx from 'classnames';

interface IProps {
  src: string;
  name: string;
  className?: string;
}

const Avatar: React.FC<IProps> = (props) => {
  const { src, name, className } = props;
  return (
    <div className={cx(c.avatar_con, className)}>
      {src ? (
        <img src={src} alt="" className={c.avatar} />
      ) : (
        <div className={c.default_avatar}>{name.slice(-2)}</div>
      )}
    </div>
  );
};

export default Avatar;
