/*
 * :file description:
 * :name: \low-code-platform\src\components\renderer\TUpload\index.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-11-06 11:38:10
 * :last editor: gaoch
 * :date last edited: 2021-11-08 22:00:50
 */

import React, { useState, useRef, useEffect } from 'react';
import { Upload, UploadProps, Modal, Button } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import formApi from '@/services/form';
import defaultGraph from '@/assets/form/image.png';
import c from './index.less';
import './index.less';
import IconFont from '@/components/IconFont';

const DEFAULT_UPLOAD_CONFIG: UploadProps = {
  accept: 'image/*',
  multiple: true,
  maxCount: 9,
  listType: 'picture',
};

const TUpload = (props: UploadProps & { [key: string]: any }) => {
  const uploadRef = useRef();
  const btnRef = useRef();
  const { onChange = () => {} } = props;
  const maxCount = DEFAULT_UPLOAD_CONFIG.maxCount || props.maxCount;
  const [visible, setVisible] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [modalKey, setModalKey] = useState<string>();
  const [files, setFiles] = useState([]);

  const customRequest = (e) => {
    const file = e.file;
    const name = file.name;
    let formdata = new FormData();
    formdata.append('file', e.file);
    formdata.append('keyPrefix', 'crm-foundation-form/instance/');
    formApi.uploadImages(formdata).then(
      (res) => {
        e.onSuccess(
          {
            name,
            url: res?.data,
          },
          file,
        );
        getSuccessCount();
      },
      (res) => {
        e.onError(res);
      },
    );
  };

  const handleBeforeUpload = (file: UploadFile, fileList: UploadFile[]) => {
    setVisible(true);
    setAttemptCount(fileList?.length);
    return true;
  };

  const handleOk = () => {
    setFiles(files.concat(uploadRef?.current?.fileList || []));
    setVisible(false);
    console.log('uploadRef ', uploadRef);
  };

  const getSuccessCount = () => {
    setSuccessCount(
      uploadRef?.current?.fileList?.filter((v) => v.status === 'done')
        ?.length ?? 0,
    );
  };

  const deleteImg = (img) => {
    setFiles(files.filter((v) => v.response.url !== img.response.url));
  };

  useEffect(() => {
    setAttemptCount(0);
    setSuccessCount(0);
    if (visible) {
      setModalKey(nanoid(12));
    }
  }, [visible]);

  useEffect(() => {
    onChange &&
      onChange(
        files?.filter((v) => v.status === 'done').map((v) => v.response.url),
      );
  }, [files?.length]);

  return (
    <div className={c.t_image}>
      <span
        className={c.boot_text}
        onClick={() => {
          setVisible(true);
          _.delay(() => {
            btnRef?.current?.click();
          }, 0);
        }}
      >
        <span>添加图片</span>（最多选择{maxCount}张）
      </span>
      <div className={c.thumbnail}>
        {!visible &&
          files?.map((item, idx) => {
            return (
              <div key={idx}>
                <span onClick={() => deleteImg(item)}>×</span>
                <img
                  src={item?.response?.url || defaultGraph}
                  width="48"
                  height="48"
                />
              </div>
            );
          })}
      </div>
      <Modal
        key={modalKey}
        title="上传图片"
        className="upload_images"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button type="primary" onClick={handleOk}>
            确定
          </Button>,
        ]}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <div style={{ marginBottom: '-10px' }}>
          本次选择{attemptCount}个文件，可以发送
          {successCount}个
        </div>
        {visible ? (
          <Upload
            ref={uploadRef}
            {...DEFAULT_UPLOAD_CONFIG}
            customRequest={customRequest}
            beforeUpload={handleBeforeUpload}
          >
            <Button ref={btnRef}></Button>
          </Upload>
        ) : null}
      </Modal>
    </div>
  );
};

export default TUpload;
