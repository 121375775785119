import * as React from 'react';
import c from './index.less';

interface IProps {
  text: {
    key: string;
    value: string;
    defaultColor: string;
  }[];
}

const TableCustomerTags: React.FC<IProps> = ({ text }) => {
  return (
    <div className={c.wrap}>
      {text.map((item) => (
        <div className={c.tag} key={item.key}>
          <div className={c.text} style={{ color: item.defaultColor }}>
            {item.value}
          </div>
          <div
            className={c.bg}
            style={{ backgroundColor: item.defaultColor }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default TableCustomerTags;
