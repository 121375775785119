/*
 * :file description:
 * :name: \crmd:\progree\low-code-platform\tg-renderer\packages\form-render\src\widgets\MultiSelect\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-27 10:44:02
 * :last editor: hyw
 * :date last edited: 2022-01-12 19:44:07
 */
import { FC } from 'react';
import { Select } from 'antd';
import { get } from 'lodash-es';
import './index.less';
import { WidgetProps } from 'typings';

const { Option } = Select;

interface IValueItem {
  key: string;
  value: string;
}

const MultipleSelect: FC<WidgetProps<IValueItem[]>> = (props) => {
  const { value, onChange = () => {}, schema = {} } = props;
  const { placeholder } = schema;

  if (props.readOnly) {
    const names = props?.value?.map((ele) => ele.value);
    return <span className="showNoEditText">{names?.join(',')}</span>;
  }
  return (
    <Select
      mode="multiple"
      allowClear
      value={(value || []).map?.((v) => v.key)}
      style={{ width: '100%' }}
      placeholder={placeholder}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.children
          .toLowerCase()
          .localeCompare(optionB.children.toLowerCase())
      }
      onChange={(_value, option) => {
        const items = option.map((item) => {
          const { key, children } = item;
          return {
            key,
            value: children,
          };
        });
        onChange(items);
      }}
    >
      {get(props, 'schema.enum', []).map((item: string, idx: number) => (
        <Option key={item} value={item}>
          {get(props, 'schema.enumNames', [])[idx]}
        </Option>
      ))}
    </Select>
  );
};

export default MultipleSelect;
