/*
 * :file description:
 * :name: \ui-renderd:\progree\low-code-platform\tg-renderer\packages\form-render\src\widgets\InputNumber\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 14:28:41
 * :last editor: hyw
 * :date last edited: 2022-08-19 13:59:23
 */
import { FC } from 'react';
import { InputNumber } from 'antd';
import { WidgetProps } from 'typings';

type Value = string | number | undefined;

const InputNumberWidget: FC<WidgetProps<Value>> = (props) => {
  const { value, schema, onChange } = props;
  const { limitDot, placeholder } = schema;
  const limitDecimals = (value?: string | number): string => {
    const regStr = `/^(\\-)*(\\d+)\\.(\\d{0,${limitDot || 0}}).*$/`;
    const reg = eval(regStr);

    if (typeof value === 'string') {
      return !isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : '';
    } else if (typeof value === 'number') {
      return !isNaN(value) ? String(value).replace(reg, '$1$2.$3') : '';
    } else {
      return '';
    }
  };

  const handleChange = (e: string | number) => {
    onChange(e);
  };

  if (props.readOnly) {
    return <span className="showNoEditText">{props.value || ''}</span>;
  }

  return (
    <InputNumber
      style={{ width: '100%' }}
      value={value}
      formatter={limitDecimals}
      parser={limitDecimals}
      onChange={(e) => handleChange(e)}
      placeholder={placeholder}
      // disabled={schema.fieldStatus && !schema.fieldStatus.includes('editable')}
    />
  );
};

export default InputNumberWidget;
