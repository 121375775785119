export default [
  {
    countryKey: 'AF',
    flag: 'A',
    countryCode: '+93',
    flagPy: 'A',
    countryNameZh: '阿富汗',
    countryName: 'Afghanistan',
    countryNamePy: 'AFUHAN',
  },
  {
    countryKey: 'AL',

    flag: 'A',

    countryCode: '+355',

    flagPy: 'A',

    countryNameZh: '阿尔巴尼亚',

    countryName: 'Albania',

    countryNamePy: 'AERBANIYA',
  },
  {
    countryKey: 'DZ',

    flag: 'A',

    countryCode: '+213',

    flagPy: 'A',

    countryNameZh: '阿尔及利亚',

    countryName: 'Algeria',

    countryNamePy: 'AERJILIYA',
  },
  {
    countryKey: 'AS',

    flag: 'A',

    countryCode: '+1684',

    flagPy: 'M',

    countryNameZh: '美属萨摩亚',

    countryName: 'American Samoa',

    countryNamePy: 'MEISHUSAMOYA',
  },
  {
    countryKey: 'AD',

    flag: 'A',

    countryCode: '+376',

    flagPy: 'A',

    countryNameZh: '安道尔',

    countryName: 'Andorra',

    countryNamePy: 'ANDAOER',
  },
  {
    countryKey: 'AO',

    flag: 'A',

    countryCode: '+244',

    flagPy: 'A',

    countryNameZh: '安哥拉',

    countryName: 'Angola',

    countryNamePy: 'ANGELA',
  },
  {
    countryKey: 'AI',

    flag: 'A',

    countryCode: '+1264',

    flagPy: 'A',

    countryNameZh: '安圭拉岛',

    countryName: 'Anguilla',

    countryNamePy: 'ANGUILADAO',
  },
  {
    countryKey: 'AQ',

    flag: 'A',

    countryCode: '+672',

    flagPy: 'N',

    countryNameZh: '南极洲',

    countryName: 'Antarctica',

    countryNamePy: 'NANJIZHOU',
  },
  {
    countryKey: 'AG',

    flag: 'A',

    countryCode: '+1268',

    flagPy: 'A',

    countryNameZh: '安提瓜岛和巴布达',

    countryName: 'Antigua and Barbuda',

    countryNamePy: 'ANTIGUADAOHEBABUDA',
  },
  {
    countryKey: 'AR',

    flag: 'A',

    countryCode: '+54',

    flagPy: 'A',

    countryNameZh: '阿根廷',

    countryName: 'Argentina',

    countryNamePy: 'AGENTING',
  },
  {
    countryKey: 'AM',

    flag: 'A',

    countryCode: '+374',

    flagPy: 'Y',

    countryNameZh: '亚美尼亚',

    countryName: 'Armenia',

    countryNamePy: 'YAMEINIYA',
  },
  {
    countryKey: 'AW',

    flag: 'A',

    countryCode: '+297',

    flagPy: 'A',

    countryNameZh: '阿鲁巴',

    countryName: 'Aruba',

    countryNamePy: 'ALUBA',
  },
  {
    countryKey: 'AU',

    flag: 'A',

    countryCode: '+61',

    flagPy: 'A',

    countryNameZh: '澳大利亚',

    countryName: 'Australia',

    countryNamePy: 'AODALIYA',
  },
  {
    countryKey: 'AT',

    flag: 'A',

    countryCode: '+43',

    flagPy: 'A',

    countryNameZh: '奥地利',

    countryName: 'Austria',

    countryNamePy: 'AODILI',
  },
  {
    countryKey: 'AZ',

    flag: 'A',

    countryCode: '+994',

    flagPy: 'A',

    countryNameZh: '阿塞拜疆',

    countryName: 'Azerbaijan',

    countryNamePy: 'ASAIBAIJIANG',
  },
  {
    countryKey: 'BS',

    flag: 'B',

    countryCode: '+1242',

    flagPy: 'B',

    countryNameZh: '巴哈马',

    countryName: 'Bahamas',

    countryNamePy: 'BAHAMA',
  },
  {
    countryKey: 'BH',

    flag: 'B',

    countryCode: '+973',

    flagPy: 'B',

    countryNameZh: '巴林',

    countryName: 'Bahrain',

    countryNamePy: 'BALIN',
  },
  {
    countryKey: 'BD',

    flag: 'B',

    countryCode: '+880',

    flagPy: 'M',

    countryNameZh: '孟加拉',

    countryName: 'Bangladesh',

    countryNamePy: 'MENGJIALA',
  },
  {
    countryKey: 'BB',

    flag: 'B',

    countryCode: '+1246',

    flagPy: 'B',

    countryNameZh: '巴巴多斯岛',

    countryName: 'Barbados',

    countryNamePy: 'BABADUOSIDAO',
  },
  {
    countryKey: 'BY',

    flag: 'B',

    countryCode: '+375',

    flagPy: 'B',

    countryNameZh: '白俄罗斯',

    countryName: 'Belarus',

    countryNamePy: 'BAIELUOSI',
  },
  {
    countryKey: 'BE',

    flag: 'B',

    countryCode: '+32',

    flagPy: 'B',

    countryNameZh: '比利时',

    countryName: 'Belgium',

    countryNamePy: 'BILISHI',
  },
  {
    countryKey: 'BZ',

    flag: 'B',

    countryCode: '+501',

    flagPy: 'B',

    countryNameZh: '伯利兹',

    countryName: 'Belize',

    countryNamePy: 'BOLIZI',
  },
  {
    countryKey: 'BJ',

    flag: 'B',

    countryCode: '+229',

    flagPy: 'B',

    countryNameZh: '贝宁',

    countryName: 'Benin',

    countryNamePy: 'BEINING',
  },
  {
    countryKey: 'BM',

    flag: 'B',

    countryCode: '+1441',

    flagPy: 'B',

    countryNameZh: '百慕大',

    countryName: 'Bermuda',

    countryNamePy: 'BAIMUDA',
  },
  {
    countryKey: 'BT',

    flag: 'B',

    countryCode: '+975',

    flagPy: 'B',

    countryNameZh: '不丹',

    countryName: 'Bhutan',

    countryNamePy: 'BUDAN',
  },
  {
    countryKey: 'BO',

    flag: 'B',

    countryCode: '+591',

    flagPy: 'B',

    countryNameZh: '玻利维亚',

    countryName: 'Bolivia',

    countryNamePy: 'BOLIWEIYA',
  },
  {
    countryKey: 'BA',

    flag: 'B',

    countryCode: '+387',

    flagPy: 'B',

    countryNameZh: '波斯尼亚和黑塞哥维那',

    countryName: 'Bosnia And Herzegovina',

    countryNamePy: 'BOSINIYAHEHEISAIGEWEINEI',
  },
  {
    countryKey: 'BW',

    flag: 'B',

    countryCode: '+267',

    flagPy: 'B',

    countryNameZh: '博茨瓦纳',

    countryName: 'Botswana',

    countryNamePy: 'BOCIWANA',
  },
  {
    countryKey: 'BR',

    flag: 'B',

    countryCode: '+55',

    flagPy: 'B',

    countryNameZh: '巴西',

    countryName: 'Brazil',

    countryNamePy: 'BAXI',
  },
  {
    countryKey: 'VG',

    flag: 'B',

    countryCode: '+1284',

    flagPy: 'Y',

    countryNameZh: '英属维尔京群岛',

    countryName: 'British Virgin Islands',

    countryNamePy: 'YINGSHUWEIERJINGQUNDAO',
  },
  {
    countryKey: 'BN',

    flag: 'B',

    countryCode: '+673',

    flagPy: 'W',

    countryNameZh: '文莱',

    countryName: 'Brunei',

    countryNamePy: 'WENLAI',
  },
  {
    countryKey: 'BG',

    flag: 'B',

    countryCode: '+359',

    flagPy: 'B',

    countryNameZh: '保加利亚',

    countryName: 'Bulgaria',

    countryNamePy: 'BAOJIALIYA',
  },
  {
    countryKey: 'BF',

    flag: 'B',

    countryCode: '+226',

    flagPy: 'B',

    countryNameZh: '布基纳法索',

    countryName: 'Burkina Faso',

    countryNamePy: 'BUJINAFASUO',
  },
  {
    countryKey: 'BI',

    flag: 'B',

    countryCode: '+257',

    flagPy: 'B',

    countryNameZh: '布隆迪',

    countryName: 'Burundi',

    countryNamePy: 'BULONGDI',
  },
  {
    countryKey: 'KH',

    flag: 'C',

    countryCode: '+855',

    flagPy: 'J',

    countryNameZh: '柬埔寨',

    countryName: 'Cambodia',

    countryNamePy: 'JIANPUZHAI',
  },
  {
    countryKey: 'CM',

    flag: 'C',

    countryCode: '+237',

    flagPy: 'K',

    countryNameZh: '喀麦隆',

    countryName: 'Cameroon',

    countryNamePy: 'KAMAILONG',
  },
  {
    countryKey: 'CA',

    flag: 'C',

    countryCode: '+1',

    flagPy: 'J',

    countryNameZh: '加拿大',

    countryName: 'Canada',

    countryNamePy: 'JIANADA',
  },
  {
    countryKey: 'CV',

    flag: 'C',

    countryCode: '+238',

    flagPy: 'F',

    countryNameZh: '佛得角',

    countryName: 'Cape verde',

    countryNamePy: 'FODEJIAO',
  },
  {
    countryKey: 'KY',

    flag: 'C',

    countryCode: '+1345',

    flagPy: 'K',

    countryNameZh: '开曼群岛',

    countryName: 'Cayman Islands',

    countryNamePy: 'KAIMANQUNDAO',
  },
  {
    countryKey: 'CF',

    flag: 'C',

    countryCode: '+236',

    flagPy: 'Z',

    countryNameZh: '中非共和国',

    countryName: 'Central African Republic',

    countryNamePy: 'ZHONGFEIGONGHEGUO',
  },
  {
    countryKey: 'TD',

    flag: 'C',

    countryCode: '+235',

    flagPy: 'Z',

    countryNameZh: '乍得',

    countryName: 'Chad',

    countryNamePy: 'ZHADE',
  },
  {
    countryKey: 'CL',

    flag: 'C',

    countryCode: '+56',

    flagPy: 'Z',

    countryNameZh: '智利',

    countryName: 'Chile',

    countryNamePy: 'ZHILI',
  },
  {
    countryKey: 'CN',

    flag: 'C',

    countryCode: '+86',

    flagPy: 'Z',

    countryNameZh: '中国',

    countryName: 'China',

    countryNamePy: 'ZHONGGUO',
  },
  {
    countryKey: 'CO',

    flag: 'C',

    countryCode: '+57',

    flagPy: 'G',

    countryNameZh: '哥伦比亚',

    countryName: 'Columbia',

    countryNamePy: 'GELUNBIYA',
  },
  {
    countryKey: 'KM',

    flag: 'C',

    countryCode: '+269',

    flagPy: 'K',

    countryNameZh: '科摩罗',

    countryName: 'Comoros',

    countryNamePy: 'KEMOLUO',
  },
  {
    countryKey: 'CG',

    flag: 'C',

    countryCode: '+242',

    flagPy: 'G',

    countryNameZh: '刚果民主共和国',

    countryName: 'Congo-Kinshasa',

    countryNamePy: 'GANGGUOMINZHUGONGHEGUO',
  },
  {
    countryKey: 'CD',

    flag: 'C',

    countryCode: '+243',

    flagPy: 'G',

    countryNameZh: '刚果共和国',

    countryName: 'Congo-Brazzaville',

    countryNamePy: 'GANGGUOGONGHEGUO',
  },
  {
    countryKey: 'CK',

    flag: 'C',

    countryCode: '+682',

    flagPy: 'K',

    countryNameZh: '库克群岛',

    countryName: 'Cook Islands',

    countryNamePy: 'KUKEQUNDAO',
  },
  {
    countryKey: 'CR',

    flag: 'C',

    countryCode: '+506',

    flagPy: 'G',

    countryNameZh: '哥斯达黎加',

    countryName: 'Costa Rica',

    countryNamePy: 'GESIDALIJIA',
  },
  {
    countryKey: 'CI',

    flag: 'C',

    countryCode: '+225',

    flagPy: 'K',

    countryNameZh: '科特迪瓦',

    countryName: "Cote d'lvoire",

    countryNamePy: 'KETEDIWA',
  },
  {
    countryKey: 'HR',

    flag: 'C',

    countryCode: '+385',

    flagPy: 'K',

    countryNameZh: '克罗地亚',

    countryName: 'Croatia',

    countryNamePy: 'KELUODIYA',
  },
  {
    countryKey: 'CU',

    flag: 'C',

    countryCode: '+53',

    flagPy: 'G',

    countryNameZh: '古巴',

    countryName: 'Cuba',

    countryNamePy: 'GUBA',
  },
  {
    countryKey: 'CY',

    flag: 'C',

    countryCode: '+357',

    flagPy: 'S',

    countryNameZh: '塞浦路斯',

    countryName: 'Cyprus',

    countryNamePy: 'SAIPULUSI',
  },
  {
    countryKey: 'CZ',

    flag: 'C',

    countryCode: '+420',

    flagPy: 'J',

    countryNameZh: '捷克共和国',

    countryName: 'Czech Republic',

    countryNamePy: 'JIEKEGONGHEGUO',
  },
  {
    countryKey: 'DK',

    flag: 'D',

    countryCode: '+45',

    flagPy: 'D',

    countryNameZh: '丹麦',

    countryName: 'Denmark',

    countryNamePy: 'DANMAI',
  },
  {
    countryKey: 'DJ',

    flag: 'D',

    countryCode: '+253',

    flagPy: 'J',

    countryNameZh: '吉布提',

    countryName: 'Djibouti',

    countryNamePy: 'JIBUTI',
  },
  {
    countryKey: 'DM',

    flag: 'D',

    countryCode: '+1767',

    flagPy: 'D',

    countryNameZh: '多米尼加',

    countryName: 'Domilica',

    countryNamePy: 'DUOMINIJIA',
  },
  {
    countryKey: 'DO',

    flag: 'D',

    countryCode: '+1809',

    flagPy: 'D',

    countryNameZh: '多米尼加共和国',

    countryName: 'Dominican Republic',

    countryNamePy: 'DUOMINIJIAGONGHEGUO',
  },
  {
    countryKey: 'EC',

    flag: 'E',

    countryCode: '+593',

    flagPy: 'E',

    countryNameZh: '厄瓜多尔',

    countryName: 'Ecuador',

    countryNamePy: 'EGUADUOER',
  },
  {
    countryKey: 'EG',

    flag: 'E',

    countryCode: '+20',

    flagPy: 'A',

    countryNameZh: '埃及',

    countryName: 'Egypt',

    countryNamePy: 'AIJI',
  },
  {
    countryKey: 'SV',

    flag: 'E',

    countryCode: '+503',

    flagPy: 'S',

    countryNameZh: '萨尔瓦多',

    countryName: 'EI Salvador',

    countryNamePy: 'SAERWADUO',
  },
  {
    countryKey: 'GQ',

    flag: 'E',

    countryCode: '+240',

    flagPy: 'C',

    countryNameZh: '赤道几内亚',

    countryName: 'Equatorial Guinea',

    countryNamePy: 'CHIDAOJINEIYA',
  },
  {
    countryKey: 'ER',

    flag: 'E',

    countryCode: '+291',

    flagPy: 'E',

    countryNameZh: '厄立特里亚',

    countryName: 'Eritrea',

    countryNamePy: 'ELITELIYA',
  },
  {
    countryKey: 'EE',

    flag: 'E',

    countryCode: '+372',

    flagPy: 'A',

    countryNameZh: '爱沙尼亚',

    countryName: 'Estonia',

    countryNamePy: 'AISHANIYA',
  },
  {
    countryKey: 'ET',

    flag: 'E',

    countryCode: '+251',

    flagPy: 'A',

    countryNameZh: '埃塞俄比亚',

    countryName: 'Ethiopia',

    countryNamePy: 'AISAIEBIYA',
  },
  {
    countryKey: 'FK',

    flag: 'F',

    countryCode: '+500',

    flagPy: 'F',

    countryNameZh: '福克兰群岛',

    countryName: 'Falkland Islands',

    countryNamePy: 'FUKELANQUNDAO',
  },
  {
    countryKey: 'FO',

    flag: 'F',

    countryCode: '+298',

    flagPy: 'F',

    countryNameZh: '法罗群岛',

    countryName: 'Faroe Islands',

    countryNamePy: 'FALUOQUNDAO',
  },
  {
    countryKey: 'FJ',

    flag: 'F',

    countryCode: '+679',

    flagPy: 'F',

    countryNameZh: '斐济',

    countryName: 'Fiji Islands',

    countryNamePy: 'FEIJI',
  },
  {
    countryKey: 'FI',

    flag: 'F',

    countryCode: '+358',

    flagPy: 'F',

    countryNameZh: '芬兰',

    countryName: 'Finland',

    countryNamePy: 'FENLAN',
  },
  {
    countryKey: 'FR',

    flag: 'F',

    countryCode: '+33',

    flagPy: 'F',

    countryNameZh: '法国',

    countryName: 'France',

    countryNamePy: 'FAGUO',
  },
  {
    countryKey: 'PF',

    flag: 'F',

    countryCode: '+689',

    flagPy: 'F',

    countryNameZh: '法属波利尼西亚',

    countryName: 'French Polynesia',

    countryNamePy: 'FASHUBOLINIXIYA',
  },
  {
    countryKey: 'GA',

    flag: 'G',

    countryCode: '+241',

    flagPy: 'J',

    countryNameZh: '加蓬',

    countryName: 'Gabon',

    countryNamePy: 'JIAPENG',
  },
  {
    countryKey: 'GM',

    flag: 'G',

    countryCode: '+220',

    flagPy: 'G',

    countryNameZh: '冈比亚',

    countryName: 'Gambia',

    countryNamePy: 'GANGBIYA',
  },
  {
    countryKey: 'GE',

    flag: 'G',

    countryCode: '+995',

    flagPy: 'Q',

    countryNameZh: '乔治亚',

    countryName: 'Georgia',

    countryNamePy: 'QIAOZHIYA',
  },
  {
    countryKey: 'DE',

    flag: 'G',

    countryCode: '+49',

    flagPy: 'D',

    countryNameZh: '德国',

    countryName: 'Germany',

    countryNamePy: 'DEGUO',
  },
  {
    countryKey: 'GH',

    flag: 'G',

    countryCode: '+233',

    flagPy: 'J',

    countryNameZh: '加纳',

    countryName: 'Ghana',

    countryNamePy: 'JIANA',
  },
  {
    countryKey: 'GI',

    flag: 'G',

    countryCode: '+350',

    flagPy: 'Z',

    countryNameZh: '直布罗陀',

    countryName: 'Gibraltar',

    countryNamePy: 'ZHIBULUOTUO',
  },
  {
    countryKey: 'GR',

    flag: 'G',

    countryCode: '+30',

    flagPy: 'X',

    countryNameZh: '希腊',

    countryName: 'Greece',

    countryNamePy: 'XILA',
  },
  {
    countryKey: 'GL',

    flag: 'G',

    countryCode: '+299',

    flagPy: 'G',

    countryNameZh: '格陵兰',

    countryName: 'Greenland',

    countryNamePy: 'GELINGLAN',
  },
  {
    countryKey: 'GD',

    flag: 'G',

    countryCode: '+1473',

    flagPy: 'G',

    countryNameZh: '格林纳达',

    countryName: 'Grenada',

    countryNamePy: 'GELINNADA',
  },
  {
    countryKey: 'GU',

    flag: 'G',

    countryCode: '+1671',

    flagPy: 'G',

    countryNameZh: '关岛',

    countryName: 'Guam',

    countryNamePy: 'GUANDAO',
  },
  {
    countryKey: 'GT',

    flag: 'G',

    countryCode: '+502',

    flagPy: 'W',

    countryNameZh: '危地马拉',

    countryName: 'Guatemala',

    countryNamePy: 'WEIDIMALA',
  },
  {
    countryKey: 'GG',

    flag: 'G',

    countryCode: '+44',

    flagPy: 'G',

    countryNameZh: '格恩西岛',

    countryName: 'Guernsey',

    countryNamePy: 'GEENXIDAO',
  },
  {
    countryKey: 'GN',

    flag: 'G',

    countryCode: '+224',

    flagPy: 'J',

    countryNameZh: '几内亚',

    countryName: 'Guinea',

    countryNamePy: 'JINEIYA',
  },
  {
    countryKey: 'GW',

    flag: 'G',

    countryCode: '+245',

    flagPy: 'J',

    countryNameZh: '几内亚比绍',

    countryName: 'Guinea-Bissau',

    countryNamePy: 'JINEIYABISHAO',
  },
  {
    countryKey: 'GY',

    flag: 'G',

    countryCode: '+592',

    flagPy: 'G',

    countryNameZh: '圭亚那',

    countryName: 'Guyana',

    countryNamePy: 'GUIYANEI',
  },
  {
    countryKey: 'HT',

    flag: 'H',

    countryCode: '+509',

    flagPy: 'H',

    countryNameZh: '海地',

    countryName: 'Haiti',

    countryNamePy: 'HAIDI',
  },
  {
    countryKey: 'HN',

    flag: 'H',

    countryCode: '+504',

    flagPy: 'H',

    countryNameZh: '洪都拉斯',

    countryName: 'Honduras',

    countryNamePy: 'HONGDOULASI',
  },
  {
    countryKey: 'HK',

    flag: 'H',

    countryCode: '+852',

    flagPy: 'Z',

    countryNameZh: '中国香港',

    countryName: 'Hong Kong',

    countryNamePy: 'ZHONGGUOXIANGGANG',
  },
  {
    countryKey: 'HU',

    flag: 'H',

    countryCode: '+36',

    flagPy: 'X',

    countryNameZh: '匈牙利',

    countryName: 'Hungary',

    countryNamePy: 'XIONGYALI',
  },
  {
    countryKey: 'IS',

    flag: 'I',

    countryCode: '+354',

    flagPy: 'B',

    countryNameZh: '冰岛',

    countryName: 'Iceland',

    countryNamePy: 'BINGDAO',
  },
  {
    countryKey: 'IN',

    flag: 'I',

    countryCode: '+91',

    flagPy: 'Y',

    countryNameZh: '印度',

    countryName: 'India',

    countryNamePy: 'YINDU',
  },
  {
    countryKey: 'ID',

    flag: 'I',

    countryCode: '+62',

    flagPy: 'Y',

    countryNameZh: '印度尼西亚',

    countryName: 'Indonesia',

    countryNamePy: 'YINDUNIXIYA',
  },
  {
    countryKey: 'IR',

    flag: 'I',

    countryCode: '+98',

    flagPy: 'Y',

    countryNameZh: '伊朗',

    countryName: 'Iran',

    countryNamePy: 'YILANG',
  },
  {
    countryKey: 'IQ',

    flag: 'I',

    countryCode: '+964',

    flagPy: 'Y',

    countryNameZh: '伊拉克',

    countryName: 'Iraq',

    countryNamePy: 'YILAKE',
  },
  {
    countryKey: 'IE',

    flag: 'I',

    countryCode: '+353',

    flagPy: 'A',

    countryNameZh: '爱尔兰',

    countryName: 'Ireland',

    countryNamePy: 'AIERLAN',
  },
  {
    countryKey: 'IM',

    flag: 'I',

    countryCode: '+44',

    flagPy: 'M',

    countryNameZh: '曼岛',

    countryName: 'Isle of Man',

    countryNamePy: 'MANDAO',
  },
  {
    countryKey: 'IL',

    flag: 'I',

    countryCode: '+972',

    flagPy: 'Y',

    countryNameZh: '以色列',

    countryName: 'Israel',

    countryNamePy: 'YISELIE',
  },
  {
    countryKey: 'IT',

    flag: 'I',

    countryCode: '+39',

    flagPy: 'Y',

    countryNameZh: '意大利',

    countryName: 'Italy',

    countryNamePy: 'YIDALI',
  },
  {
    countryKey: 'JM',

    flag: 'J',

    countryCode: '+1876',

    flagPy: 'Y',

    countryNameZh: '牙买加',

    countryName: 'Jamaica',

    countryNamePy: 'YAMAIJIA',
  },
  {
    countryKey: 'JP',

    flag: 'J',

    countryCode: '+81',

    flagPy: 'R',

    countryNameZh: '日本',

    countryName: 'Japan',

    countryNamePy: 'RIBEN',
  },
  {
    countryKey: 'JE',

    flag: 'J',

    countryCode: '+44',

    flagPy: 'Z',

    countryNameZh: '泽西岛',

    countryName: 'Jersey',

    countryNamePy: 'ZEXIDAO',
  },
  {
    countryKey: 'JO',

    flag: 'J',

    countryCode: '+962',

    flagPy: 'Y',

    countryNameZh: '约旦',

    countryName: 'Jordan',

    countryNamePy: 'YUEDAN',
  },
  {
    countryKey: 'KZ',

    flag: 'K',

    countryCode: '+7',

    flagPy: 'H',

    countryNameZh: '哈萨克斯坦',

    countryName: 'Kazakhstan',

    countryNamePy: 'HASAKESITAN',
  },
  {
    countryKey: 'KE',

    flag: 'K',

    countryCode: '+254',

    flagPy: 'K',

    countryNameZh: '肯尼亚',

    countryName: 'Kenya',

    countryNamePy: 'KENNIYA',
  },
  {
    countryKey: 'KI',

    flag: 'K',

    countryCode: '+686',

    flagPy: 'J',

    countryNameZh: '基里巴斯',

    countryName: 'Kiribati',

    countryNamePy: 'JILIBASI',
  },
  {
    countryKey: 'KR',

    flag: 'K',

    countryCode: '+82',

    flagPy: 'H',

    countryNameZh: '韩国',

    countryName: 'Korea',

    countryNamePy: 'HANGUO',
  },
  {
    countryKey: 'KW',

    flag: 'K',

    countryCode: '+965',

    flagPy: 'K',

    countryNameZh: '科威特',

    countryName: 'Kuwait',

    countryNamePy: 'KEWEITE',
  },
  {
    countryKey: 'KG',

    flag: 'K',

    countryCode: '+996',

    flagPy: 'J',

    countryNameZh: '吉尔吉斯斯坦',

    countryName: 'Kyrgyzstan',

    countryNamePy: 'JIERJISISITAN',
  },
  {
    countryKey: 'LA',

    flag: 'L',

    countryCode: '+856',

    flagPy: 'L',

    countryNameZh: '老挝',

    countryName: 'Laos',

    countryNamePy: 'LAOWO',
  },
  {
    countryKey: 'LV',

    flag: 'L',

    countryCode: '+371',

    flagPy: 'L',

    countryNameZh: '拉脱维亚',

    countryName: 'Latvia',

    countryNamePy: 'LATUOWEIYA',
  },
  {
    countryKey: 'LB',

    flag: 'L',

    countryCode: '+961',

    flagPy: 'L',

    countryNameZh: '黎巴嫩',

    countryName: 'Lebanon',

    countryNamePy: 'LIBANEN',
  },
  {
    countryKey: 'LS',

    flag: 'L',

    countryCode: '+266',

    flagPy: 'L',

    countryNameZh: '莱索托',

    countryName: 'Lesotho',

    countryNamePy: 'LAISUOTUO',
  },
  {
    countryKey: 'LR',

    flag: 'L',

    countryCode: '+231',

    flagPy: 'L',

    countryNameZh: '利比里亚',

    countryName: 'Liberia',

    countryNamePy: 'LIBILIYA',
  },
  {
    countryKey: 'LY',

    flag: 'L',

    countryCode: '+218',

    flagPy: 'L',

    countryNameZh: '利比亚',

    countryName: 'Libya',

    countryNamePy: 'LIBIYA',
  },
  {
    countryKey: 'LI',

    flag: 'L',

    countryCode: '+423',

    flagPy: 'L',

    countryNameZh: '列支敦士登',

    countryName: 'Liechtenstein',

    countryNamePy: 'LIEZHIDUNSHIDENG',
  },
  {
    countryKey: 'LT',

    flag: 'L',

    countryCode: '+370',

    flagPy: 'L',

    countryNameZh: '立陶宛',

    countryName: 'Lithuania',

    countryNamePy: 'LITAOWAN',
  },
  {
    countryKey: 'LU',

    flag: 'L',

    countryCode: '+352',

    flagPy: 'L',

    countryNameZh: '卢森堡',

    countryName: 'Luxembourg',

    countryNamePy: 'LUSENBAO',
  },
  {
    countryKey: 'MO',

    flag: 'M',

    countryCode: '+853',

    flagPy: 'Z',

    countryNameZh: '中国澳门',

    countryName: 'Macao',

    countryNamePy: 'ZHONGGUOAOMEN',
  },
  {
    countryKey: 'MK',

    flag: 'M',

    countryCode: '+389',

    flagPy: 'M',

    countryNameZh: '马其顿',

    countryName: 'Macedonia,Former Yugoslav Republic of',

    countryNamePy: 'MAQIDUN',
  },
  {
    countryKey: 'MG',

    flag: 'M',

    countryCode: '+261',

    flagPy: 'M',

    countryNameZh: '马达加斯加',

    countryName: 'Madagascar',

    countryNamePy: 'MADAJIASIJIA',
  },
  {
    countryKey: 'MW',

    flag: 'M',

    countryCode: '+265',

    flagPy: 'M',

    countryNameZh: '马拉维',

    countryName: 'Malawi',

    countryNamePy: 'MALAWEI',
  },
  {
    countryKey: 'MY',

    flag: 'M',

    countryCode: '+60',

    flagPy: 'M',

    countryNameZh: '马来西亚',

    countryName: 'Malaysia',

    countryNamePy: 'MALAIXIYA',
  },
  {
    countryKey: 'MV',

    flag: 'M',

    countryCode: '+960',

    flagPy: 'M',

    countryNameZh: '马尔代夫',

    countryName: 'Maldives',

    countryNamePy: 'MAERDAIFU',
  },
  {
    countryKey: 'ML',

    flag: 'M',

    countryCode: '+223',

    flagPy: 'M',

    countryNameZh: '马里',

    countryName: 'Mali',

    countryNamePy: 'MALI',
  },
  {
    countryKey: 'MT',

    flag: 'M',

    countryCode: '+356',

    flagPy: 'M',

    countryNameZh: '马耳他',

    countryName: 'Malta',

    countryNamePy: 'MAERTA',
  },
  {
    countryKey: 'MH',

    flag: 'M',

    countryCode: '+692',

    flagPy: 'M',

    countryNameZh: '马绍尔群岛',

    countryName: 'Marshall Islands',

    countryNamePy: 'MASHAOERQUNDAO',
  },
  {
    countryKey: 'MR',

    flag: 'M',

    countryCode: '+222',

    flagPy: 'M',

    countryNameZh: '毛里塔尼亚',

    countryName: 'Mauritania',

    countryNamePy: 'MAOLITANIYA',
  },
  {
    countryKey: 'MU',

    flag: 'M',

    countryCode: '+230',

    flagPy: 'M',

    countryNameZh: '毛里求斯',

    countryName: 'Mauritius',

    countryNamePy: 'MAOLIQIUSI',
  },
  {
    countryKey: 'YT',

    flag: 'M',

    countryCode: '+262',

    flagPy: 'M',

    countryNameZh: '马约特',

    countryName: 'Mayotte',

    countryNamePy: 'MAYUETE',
  },
  {
    countryKey: 'MX',

    flag: 'M',

    countryCode: '+52',

    flagPy: 'M',

    countryNameZh: '墨西哥',

    countryName: 'Mexico',

    countryNamePy: 'MOXIGE',
  },
  {
    countryKey: 'FM',

    flag: 'M',

    countryCode: '+691',

    flagPy: 'M',

    countryNameZh: '密克罗尼西亚联邦',

    countryName: 'Micronesia',

    countryNamePy: 'MIKELUONIXIYALIANBANG',
  },
  {
    countryKey: 'MD',

    flag: 'M',

    countryCode: '+373',

    flagPy: 'M',

    countryNameZh: '摩尔多瓦',

    countryName: 'Moldova',

    countryNamePy: 'MOERDUOWA',
  },
  {
    countryKey: 'MC',

    flag: 'M',

    countryCode: '+377',

    flagPy: 'M',

    countryNameZh: '摩纳哥',

    countryName: 'Monaco',

    countryNamePy: 'MONAGE',
  },
  {
    countryKey: 'MN',

    flag: 'M',

    countryCode: '+976',

    flagPy: 'M',

    countryNameZh: '蒙古',

    countryName: 'Mongolia',

    countryNamePy: 'MENGGU',
  },
  {
    countryKey: 'ME',

    flag: 'M',

    countryCode: '+382',

    flagPy: 'H',

    countryNameZh: '黑山共和国',

    countryName: 'Montenegro',

    countryNamePy: 'HEISHANGONGHEGUO',
  },
  {
    countryKey: 'MS',

    flag: 'M',

    countryCode: '+1664',

    flagPy: 'M',

    countryNameZh: '蒙塞拉特群岛',

    countryName: 'Montserrat',

    countryNamePy: 'MENGSAILATEQUNDAO',
  },
  {
    countryKey: 'MA',

    flag: 'M',

    countryCode: '+212',

    flagPy: 'M',

    countryNameZh: '摩洛哥',

    countryName: 'Morocco',

    countryNamePy: 'MOLUOGE',
  },
  {
    countryKey: 'MZ',

    flag: 'M',

    countryCode: '+258',

    flagPy: 'M',

    countryNameZh: '莫桑比克',

    countryName: 'Mozambique',

    countryNamePy: 'MOSANGBIKE',
  },
  {
    countryKey: 'MM',

    flag: 'M',

    countryCode: '+95',

    flagPy: 'M',

    countryNameZh: '缅甸',

    countryName: 'Myanmar',

    countryNamePy: 'MIANDIAN',
  },
  {
    countryKey: 'NA',

    flag: 'N',

    countryCode: '+264',

    flagPy: 'N',

    countryNameZh: '纳米比亚',

    countryName: 'Namibia',

    countryNamePy: 'NAMIBIYA',
  },
  {
    countryKey: 'NR',

    flag: 'N',

    countryCode: '+674',

    flagPy: 'N',

    countryNameZh: '瑙鲁',

    countryName: 'Nauru',

    countryNamePy: 'NAOLU',
  },
  {
    countryKey: 'NP',

    flag: 'N',

    countryCode: '+977',

    flagPy: 'N',

    countryNameZh: '尼泊尔',

    countryName: 'Nepal',

    countryNamePy: 'NIBOER',
  },
  {
    countryKey: 'NL',

    flag: 'N',

    countryCode: '+31',

    flagPy: 'H',

    countryNameZh: '荷兰',

    countryName: 'Netherlands',

    countryNamePy: 'HELAN',
  },
  {
    countryKey: 'AN',

    flag: 'N',

    countryCode: '+599',

    flagPy: 'H',

    countryNameZh: '荷属安的列斯群岛',

    countryName: 'Netherlands Antilles',

    countryNamePy: 'HESHUANDELIESIQUNDAO',
  },
  {
    countryKey: 'NC',

    flag: 'N',

    countryCode: '+687',

    flagPy: 'X',

    countryNameZh: '新喀里多尼亚',

    countryName: 'New Caledonia',

    countryNamePy: 'XINKALIDUONIYA',
  },
  {
    countryKey: 'NZ',

    flag: 'N',

    countryCode: '+64',

    flagPy: 'X',

    countryNameZh: '新西兰',

    countryName: 'New Zealand',

    countryNamePy: 'XINXILAN',
  },
  {
    countryKey: 'NI',

    flag: 'N',

    countryCode: '+505',

    flagPy: 'N',

    countryNameZh: '尼加拉瓜',

    countryName: 'Nicaragua',

    countryNamePy: 'NIJIALAGUA',
  },
  {
    countryKey: 'NE',

    flag: 'N',

    countryCode: '+227',

    flagPy: 'N',

    countryNameZh: '尼日尔',

    countryName: 'Niger',

    countryNamePy: 'NIRIER',
  },
  {
    countryKey: 'NG',

    flag: 'N',

    countryCode: '+234',

    flagPy: 'N',

    countryNameZh: '尼日利亚',

    countryName: 'Nigeria',

    countryNamePy: 'NIRILIYA',
  },
  {
    countryKey: 'NU',

    flag: 'N',

    countryCode: '+683',

    flagPy: 'N',

    countryNameZh: '纽埃',

    countryName: 'Niue',

    countryNamePy: 'NIUAI',
  },
  {
    countryKey: 'KP',

    flag: 'N',

    countryCode: '+850',

    flagPy: 'C',

    countryNameZh: '朝鲜',

    countryName: 'North Korea',

    countryNamePy: 'CHAOXIAN',
  },
  {
    countryKey: 'MP',

    flag: 'N',

    countryCode: '+1670',

    flagPy: 'B',

    countryNameZh: '北马里亚纳群岛',

    countryName: 'Northern Mariana Islands',

    countryNamePy: 'BEIMALIYANAQUNDAO',
  },
  {
    countryKey: 'NO',

    flag: 'N',

    countryCode: '+47',

    flagPy: 'N',

    countryNameZh: '挪威',

    countryName: 'Norway',

    countryNamePy: 'NUOWEI',
  },
  {
    countryKey: 'OM',

    flag: 'O',

    countryCode: '+968',

    flagPy: 'A',

    countryNameZh: '阿曼',

    countryName: 'Oman',

    countryNamePy: 'AMAN',
  },
  {
    countryKey: 'PK',

    flag: 'P',

    countryCode: '+92',

    flagPy: 'B',

    countryNameZh: '巴基斯坦',

    countryName: 'Pakistan',

    countryNamePy: 'BAJISITAN',
  },
  {
    countryKey: 'PW',

    flag: 'P',

    countryCode: '+680',

    flagPy: 'P',

    countryNameZh: '帕劳群岛',

    countryName: 'Palau',

    countryNamePy: 'PALAOQUNDAO',
  },
  {
    countryKey: 'PA',

    flag: 'P',

    countryCode: '+507',

    flagPy: 'B',

    countryNameZh: '巴拿马',

    countryName: 'Panama',

    countryNamePy: 'BANAMA',
  },
  {
    countryKey: 'PG',

    flag: 'P',

    countryCode: '+675',

    flagPy: 'B',

    countryNameZh: '巴布亚新几内亚',

    countryName: 'Papua New Guinea',

    countryNamePy: 'BABUYAXINJINEIYA',
  },
  {
    countryKey: 'PY',

    flag: 'P',

    countryCode: '+595',

    flagPy: 'B',

    countryNameZh: '巴拉圭',

    countryName: 'Paraguay',

    countryNamePy: 'BALAGUI',
  },
  {
    countryKey: 'PE',

    flag: 'P',

    countryCode: '+51',

    flagPy: 'M',

    countryNameZh: '秘鲁',

    countryName: 'Peru',

    countryNamePy: 'MILU',
  },
  {
    countryKey: 'PH',

    flag: 'P',

    countryCode: '+63',

    flagPy: 'F',

    countryNameZh: '菲律宾',

    countryName: 'Philippines',

    countryNamePy: 'FEILU:BIN',
  },
  {
    countryKey: 'PN',

    flag: 'P',

    countryCode: '+870',

    flagPy: 'P',

    countryNameZh: '皮特凯恩群岛',

    countryName: 'Pitcairn Islands',

    countryNamePy: 'PITEKAIENQUNDAO',
  },
  {
    countryKey: 'PL',

    flag: 'P',

    countryCode: '+48',

    flagPy: 'B',

    countryNameZh: '波兰',

    countryName: 'Poland',

    countryNamePy: 'BOLAN',
  },
  {
    countryKey: 'PT',

    flag: 'P',

    countryCode: '+351',

    flagPy: 'P',

    countryNameZh: '葡萄牙',

    countryName: 'Portugal',

    countryNamePy: 'PUTAOYA',
  },
  {
    countryKey: 'PR',

    flag: 'P',

    countryCode: '+1',

    flagPy: 'B',

    countryNameZh: '波多黎各',

    countryName: 'Puerto Rico',

    countryNamePy: 'BODUOLIGE',
  },
  {
    countryKey: 'QA',

    flag: 'Q',

    countryCode: '+974',

    flagPy: 'K',

    countryNameZh: '卡塔尔',

    countryName: 'Qatar',

    countryNamePy: 'KATAER',
  },
  {
    countryKey: 'RE',

    flag: 'R',

    countryCode: '+262',

    flagPy: 'L',

    countryNameZh: '留尼旺岛',

    countryName: 'Reunion',

    countryNamePy: 'LIUNIWANGDAO',
  },
  {
    countryKey: 'RO',

    flag: 'R',

    countryCode: '+40',

    flagPy: 'L',

    countryNameZh: '罗马尼亚',

    countryName: 'Romania',

    countryNamePy: 'LUOMANIYA',
  },
  {
    countryKey: 'RU',

    flag: 'R',

    countryCode: '+7',

    flagPy: 'E',

    countryNameZh: '俄罗斯',

    countryName: 'Russia',

    countryNamePy: 'ELUOSI',
  },
  {
    countryKey: 'RW',

    flag: 'R',

    countryCode: '+250',

    flagPy: 'L',

    countryNameZh: '卢旺达',

    countryName: 'Rwanda',

    countryNamePy: 'LUWANGDA',
  },
  {
    countryKey: 'BL',

    flag: 'S',

    countryCode: '+590',

    flagPy: 'S',

    countryNameZh: '圣巴泰勒米',

    countryName: 'Saint Barthelemy',

    countryNamePy: 'SHENGBATAILEMI',
  },
  {
    countryKey: 'LC',

    flag: 'S',

    countryCode: '+1758',

    flagPy: 'S',

    countryNameZh: '圣卢西亚',

    countryName: 'Saint Lucia',

    countryNamePy: 'SHENGLUXIYA',
  },
  {
    countryKey: 'MF',

    flag: 'S',

    countryCode: '+1599',

    flagPy: 'S',

    countryNameZh: '圣马丁',

    countryName: 'Saint Martin',

    countryNamePy: 'SHENGMADING',
  },
  {
    countryKey: 'PM',

    flag: 'S',

    countryCode: '+508',

    flagPy: 'S',

    countryNameZh: '圣皮埃尔和密克隆',

    countryName: 'Saint Pierre and Miquelon',

    countryNamePy: 'SHENGPIAIERHEMIKELONG',
  },
  {
    countryKey: 'VC',

    flag: 'S',

    countryCode: '+1784',

    flagPy: 'S',

    countryNameZh: '圣文森特河格林纳丁斯',

    countryName: 'Saint Vincent And the Grenadines',

    countryNamePy: 'SHENGWENSENTEHEGELINNADINGSI',
  },
  {
    countryKey: 'WS',

    flag: 'S',

    countryCode: '+685',

    flagPy: 'S',

    countryNameZh: '萨摩亚',

    countryName: 'Samoa',

    countryNamePy: 'SAMOYA',
  },
  {
    countryKey: 'SM',

    flag: 'S',

    countryCode: '+378',

    flagPy: 'S',

    countryNameZh: '圣马力诺',

    countryName: 'San Marino',

    countryNamePy: 'SHENGMALINUO',
  },
  {
    countryKey: 'ST',

    flag: 'S',

    countryCode: '+239',

    flagPy: 'S',

    countryNameZh: '圣多美和普林西比',

    countryName: 'Sao Tome and Principe',

    countryNamePy: 'SHENGDUOMEIHEPULINXIBI',
  },
  {
    countryKey: 'SA',

    flag: 'S',

    countryCode: '+966',

    flagPy: 'S',

    countryNameZh: '沙特阿拉伯',

    countryName: 'Saudi Arabia',

    countryNamePy: 'SHATEALABO',
  },
  {
    countryKey: 'SN',

    flag: 'S',

    countryCode: '+221',

    flagPy: 'S',

    countryNameZh: '塞内加尔',

    countryName: 'Senegal',

    countryNamePy: 'SAINEIJIAER',
  },
  {
    countryKey: 'RS',

    flag: 'S',

    countryCode: '+381',

    flagPy: 'S',

    countryNameZh: '塞尔维亚',

    countryName: 'Serbia',

    countryNamePy: 'SAIERWEIYA',
  },
  {
    countryKey: 'SC',

    flag: 'S',

    countryCode: '+248',

    flagPy: 'S',

    countryNameZh: '塞舌尔',

    countryName: 'Seychelles',

    countryNamePy: 'SAISHEER',
  },
  {
    countryKey: 'SL',

    flag: 'S',

    countryCode: '+232',

    flagPy: 'S',

    countryNameZh: '塞拉利昂',

    countryName: 'Sierra Leone',

    countryNamePy: 'SAILALIANG',
  },
  {
    countryKey: 'SG',

    flag: 'S',

    countryCode: '+65',

    flagPy: 'X',

    countryNameZh: '新加坡',

    countryName: 'Singapore',

    countryNamePy: 'XINJIAPO',
  },
  {
    countryKey: 'SK',

    flag: 'S',

    countryCode: '+421',

    flagPy: 'S',

    countryNameZh: '斯洛伐克',

    countryName: 'Slovakia',

    countryNamePy: 'SILUOFAKE',
  },
  {
    countryKey: 'SI',

    flag: 'S',

    countryCode: '+386',

    flagPy: 'S',

    countryNameZh: '斯洛文尼亚',

    countryName: 'Slovenia',

    countryNamePy: 'SILUOWENNIYA',
  },
  {
    countryKey: 'SB',

    flag: 'S',

    countryCode: '+677',

    flagPy: 'S',

    countryNameZh: '所罗门群岛',

    countryName: 'Solomon Islands',

    countryNamePy: 'SUOLUOMENQUNDAO',
  },
  {
    countryKey: 'SO',

    flag: 'S',

    countryCode: '+252',

    flagPy: 'S',

    countryNameZh: '索马里',

    countryName: 'Somalia',

    countryNamePy: 'SUOMALI',
  },
  {
    countryKey: 'ZA',

    flag: 'S',

    countryCode: '+27',

    flagPy: 'N',

    countryNameZh: '南非',

    countryName: 'South Africa',

    countryNamePy: 'NANFEI',
  },
  {
    countryKey: 'ES',

    flag: 'S',

    countryCode: '+34',

    flagPy: 'X',

    countryNameZh: '西班牙',

    countryName: 'Spain',

    countryNamePy: 'XIBANYA',
  },
  {
    countryKey: 'LK',

    flag: 'S',

    countryCode: '+94',

    flagPy: 'S',

    countryNameZh: '斯里兰卡',

    countryName: 'Sri Lanka',

    countryNamePy: 'SILILANKA',
  },
  {
    countryKey: 'KN',

    flag: 'S',

    countryCode: '+1869',

    flagPy: 'S',

    countryNameZh: '圣基茨和尼维斯',

    countryName: 'St.Kitts and Nevis',

    countryNamePy: 'SHENGJICIHENIWEISI',
  },
  {
    countryKey: 'SH',

    flag: 'S',

    countryCode: '+290',

    flagPy: 'S',

    countryNameZh: '圣赫勒拿',

    countryName: 'St. Helena',

    countryNamePy: 'SHENGHELENA',
  },
  {
    countryKey: 'SD',

    flag: 'S',

    countryCode: '+249',

    flagPy: 'S',

    countryNameZh: '苏丹',

    countryName: 'Sudan',

    countryNamePy: 'SUDAN',
  },
  {
    countryKey: 'SR',

    flag: 'S',

    countryCode: '+597',

    flagPy: 'S',

    countryNameZh: '苏里南',

    countryName: 'Surinam',

    countryNamePy: 'SULINAN',
  },
  {
    countryKey: 'SZ',

    flag: 'S',

    countryCode: '+268',

    flagPy: 'S',

    countryNameZh: '斯威士兰',

    countryName: 'Swaziland',

    countryNamePy: 'SIWEISHILAN',
  },
  {
    countryKey: 'SE',

    flag: 'S',

    countryCode: '+46',

    flagPy: 'R',

    countryNameZh: '瑞典',

    countryName: 'Sweden',

    countryNamePy: 'RUIDIAN',
  },
  {
    countryKey: 'CH',

    flag: 'S',

    countryCode: '+41',

    flagPy: 'R',

    countryNameZh: '瑞士',

    countryName: 'Switzerland',

    countryNamePy: 'RUISHI',
  },
  {
    countryKey: 'SY',

    flag: 'S',

    countryCode: '+963',

    flagPy: 'X',

    countryNameZh: '叙利亚',

    countryName: 'Syria',

    countryNamePy: 'XULIYA',
  },
  {
    countryKey: 'TW',

    flag: 'T',

    countryCode: '+886',

    flagPy: 'Z',

    countryNameZh: '中国台湾',

    countryName: 'Taiwan',

    countryNamePy: 'ZHONGGUOTAIWAN',
  },
  {
    countryKey: 'TJ',

    flag: 'T',

    countryCode: '+992',

    flagPy: 'T',

    countryNameZh: '塔吉克斯坦',

    countryName: 'Tajikistan',

    countryNamePy: 'TAJIKESITAN',
  },
  {
    countryKey: 'TZ',

    flag: 'T',

    countryCode: '+255',

    flagPy: 'T',

    countryNameZh: '坦桑尼亚',

    countryName: 'Tanzania',

    countryNamePy: 'TANSANGNIYA',
  },
  {
    countryKey: 'TH',

    flag: 'T',

    countryCode: '+66',

    flagPy: 'T',

    countryNameZh: '泰国',

    countryName: 'Thailand',

    countryNamePy: 'TAIGUO',
  },
  {
    countryKey: 'TL',

    flag: 'T',

    countryCode: '+670',

    flagPy: 'D',

    countryNameZh: '东帝汶',

    countryName: 'Timor-Leste',

    countryNamePy: 'DONGDIWEN',
  },
  {
    countryKey: 'TG',

    flag: 'T',

    countryCode: '+228',

    flagPy: 'D',

    countryNameZh: '多哥',

    countryName: 'Togo',

    countryNamePy: 'DUOGE',
  },
  {
    countryKey: 'TK',

    flag: 'T',

    countryCode: '+690',

    flagPy: 'T',

    countryNameZh: '托克劳',

    countryName: 'Tokelau',

    countryNamePy: 'TUOKELAO',
  },
  {
    countryKey: 'TO',

    flag: 'T',

    countryCode: '+676',

    flagPy: 'T',

    countryNameZh: '汤加',

    countryName: 'Tonga',

    countryNamePy: 'TANGJIA',
  },
  {
    countryKey: 'TT',

    flag: 'T',

    countryCode: '+1868',

    flagPy: 'T',

    countryNameZh: '特立尼达和多巴哥',

    countryName: 'Trinidad and Tobago',

    countryNamePy: 'TELINIDAHEDUOBAGE',
  },
  {
    countryKey: 'TN',

    flag: 'T',

    countryCode: '+216',

    flagPy: 'T',

    countryNameZh: '突尼斯',

    countryName: 'Tunisia',

    countryNamePy: 'TUNISI',
  },
  {
    countryKey: 'TR',

    flag: 'T',

    countryCode: '+90',

    flagPy: 'T',

    countryNameZh: '土耳其',

    countryName: 'Turkey',

    countryNamePy: 'TUERQI',
  },
  {
    countryKey: 'TM',

    flag: 'T',

    countryCode: '+993',

    flagPy: 'T',

    countryNameZh: '土库曼斯坦',

    countryName: 'Turkmenistan',

    countryNamePy: 'TUKUMANSITAN',
  },
  {
    countryKey: 'TC',

    flag: 'T',

    countryCode: '+1649',

    flagPy: 'T',

    countryNameZh: '特克斯和凯科斯群岛',

    countryName: 'Turks and Caicos Islands',

    countryNamePy: 'TEKESIHEKAIKESIQUNDAO',
  },
  {
    countryKey: 'TV',

    flag: 'T',

    countryCode: '+688',

    flagPy: 'T',

    countryNameZh: '图瓦卢',

    countryName: 'Tuvalu',

    countryNamePy: 'TUWALU',
  },
  {
    countryKey: 'VI',

    flag: 'U',

    countryCode: '+1340',

    flagPy: 'M',

    countryNameZh: '美属维京群岛',

    countryName: 'U.S. Virgin Islands',

    countryNamePy: 'MEISHUWEIJINGQUNDAO',
  },
  {
    countryKey: 'UG',

    flag: 'U',

    countryCode: '+256',

    flagPy: 'W',

    countryNameZh: '乌干达',

    countryName: 'Uganda',

    countryNamePy: 'WUGANDA',
  },
  {
    countryKey: 'UA',

    flag: 'U',

    countryCode: '+380',

    flagPy: 'W',

    countryNameZh: '乌克兰',

    countryName: 'Ukraine',

    countryNamePy: 'WUKELAN',
  },
  {
    countryKey: 'AE',

    flag: 'U',

    countryCode: '+971',

    flagPy: 'A',

    countryNameZh: '阿拉伯联合酋长国',

    countryName: 'United Arab Emirates',

    countryNamePy: 'ALABOLIANHEQIUZHANGGUO',
  },
  {
    countryKey: 'GB',

    flag: 'U',

    countryCode: '+44',

    flagPy: 'Y',

    countryNameZh: '英国',

    countryName: 'United Kingdom',

    countryNamePy: 'YINGGUO',
  },
  {
    countryKey: 'US',

    flag: 'U',

    countryCode: '+1',

    flagPy: 'M',

    countryNameZh: '美国',

    countryName: 'United States',

    countryNamePy: 'MEIGUO',
  },
  {
    countryKey: 'UY',

    flag: 'U',

    countryCode: '+598',

    flagPy: 'W',

    countryNameZh: '乌拉圭',

    countryName: 'Uruguay',

    countryNamePy: 'WULAGUI',
  },
  {
    countryKey: 'UZ',

    flag: 'U',

    countryCode: '+998',

    flagPy: 'W',

    countryNameZh: '乌兹别克斯坦',

    countryName: 'Uzbekistan',

    countryNamePy: 'WUZIBIEKESITAN',
  },
  {
    countryKey: 'VU',

    flag: 'V',

    countryCode: '+678',

    flagPy: 'W',

    countryNameZh: '瓦努阿图',

    countryName: 'Vanuatu',

    countryNamePy: 'WANUATU',
  },
  {
    countryKey: 'VA',

    flag: 'V',

    countryCode: '+39',

    flagPy: 'F',

    countryNameZh: '梵蒂冈',

    countryName: 'Vatican City',

    countryNamePy: 'FANDIGANG',
  },
  {
    countryKey: 'VE',

    flag: 'V',

    countryCode: '+58',

    flagPy: 'W',

    countryNameZh: '委内瑞拉',

    countryName: 'Venezuela',

    countryNamePy: 'WEINEIRUILA',
  },
  {
    countryKey: 'VN',

    flag: 'V',

    countryCode: '+84',

    flagPy: 'Y',

    countryNameZh: '越南',

    countryName: 'Vietnam',

    countryNamePy: 'YUENAN',
  },
  {
    countryKey: 'WF',

    flag: 'W',

    countryCode: '+681',

    flagPy: 'W',

    countryNameZh: '瓦里斯和富图纳',

    countryName: 'Wallis and Futuna',

    countryNamePy: 'WALISIHEFUTUNA',
  },
  {
    countryKey: 'YE',

    flag: 'Y',

    countryCode: '+967',

    flagPy: 'Y',

    countryNameZh: '也门',

    countryName: 'Yemen',

    countryNamePy: 'YEMEN',
  },
  {
    countryKey: 'ZM',

    flag: 'Z',

    countryCode: '+260',

    flagPy: 'Z',

    countryNameZh: '赞比亚',

    countryName: 'Zambia',

    countryNamePy: 'ZANBIYA',
  },
  {
    countryKey: 'ZW',

    flag: 'Z',

    countryCode: '+263',

    flagPy: 'J',

    countryNameZh: '津巴布韦',

    countryName: 'Zimbabwe',

    countryNamePy: 'JINBABUWEI',
  },
];
