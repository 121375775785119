import deepClone from 'clone';

export function flattenSchema(
  schema,
  formCode,
  formName,
  name = '#',
  parent,
  result = {},
  title = '',
) {
  const _schema = deepClone(schema);
  if (!_schema.$id) {
    _schema.$id = name; // 给生成的schema添加一个唯一标识，方便从schema中直接读取
  }
  if (!_schema.title) {
    _schema.title = formName;
  }
  const children = [];
  const isObj = _schema.type === 'object' && _schema.properties;
  const isRelation =
    _schema.type === 'object' &&
    _schema?.widget === 'relation' &&
    _schema?.fields;
  const isList =
    _schema.type === 'array' && _schema.items && _schema.items.properties;
  const isBizSuite =
    _schema.type === 'string' &&
    _schema?.widget === 'bizSuite' &&
    _schema.properties;
  if (isObj) {
    Object.entries(_schema.properties)?.forEach(([key, value]) => {
      const uniqueName = name + '.' + key;
      children.push(uniqueName);
      const $title = `${title}.${_schema.title}`;
      flattenSchema(
        value,
        formCode,
        formName,
        uniqueName,
        name,
        result,
        $title,
      );
    });
    delete _schema.properties;
  }
  if (isRelation) {
    if (
      Array.isArray(_schema?.relateSource) &&
      _schema.relateSource.length > 0
    ) {
      const _fields = {
        ...(_schema.relateSource[0]?.fields ?? {}),
        ...(_schema.relateSource[1]?.fields ?? {}),
      };
      Object.entries(_fields)?.forEach(([key, value]) => {
        const uniqueName = name + '.' + key;
        children.push(uniqueName);
        const $title = `${title}.${_schema.title}`;
        flattenSchema(
          value,
          formCode,
          formName,
          uniqueName,
          name,
          result,
          $title,
        );
      });
      delete _schema.properties;
    } else {
      Object.entries(_schema.fields)?.forEach(([key, value]) => {
        const uniqueName = name + '.' + key;
        children.push(uniqueName);
        const $title = `${title}.${_schema.title}`;
        flattenSchema(
          value,
          formCode,
          formName,
          uniqueName,
          name,
          result,
          $title,
        );
      });
      delete _schema.properties;
    }
  }
  if (isList) {
    Object.entries(_schema.items.properties)?.forEach(([key, value]) => {
      const uniqueName = name + '.' + key;
      const $title = `${title}.${_schema.title}`;
      children.push(uniqueName);
      flattenSchema(
        value,
        formCode,
        formName,
        uniqueName,
        name,
        result,
        $title,
      );
    });
    delete _schema.items.properties;
  }
  if (_schema.type) {
    const $title = `${title}.${_schema.title}`.replace(/^\./g, '');
    result[name] = {
      parent,
      parentTitle: title.replace(`${formName}`, '').replace(/^\.*/g, ''),
      $title,
      schema: _schema,
      children,
      formCode,
    };
  }
  // if (isBizSuite) {
  //   Object.entries(_schema.properties).forEach(([key, value]) => {
  //     const uniqueName = name + '.' + key;
  //     children.push(uniqueName);
  //     const $title = `${title}.${_schema.title}`;
  //     if (!value?.hidden) {
  //       flattenSchema(
  //         value,
  //         formCode,
  //         formName,
  //         uniqueName,
  //         name,
  //         result,
  //         $title,
  //       );
  //     }
  //   });
  //   delete _schema.properties;
  // }
  return result;
}
