/*
 * :file description:
 * :name: /low-code-platform/src/components/FormGenerator/index.js
 * :author: 肖国同
 * :copyright: (c) 2023, Tungee
 * :date created: 2022-11-07 15:56:31
 * :last editor: 肖国同
 * :date last edited: 2023-02-13 15:14:12
 */
import React, { forwardRef } from 'react';
import Provider from './Provider';
import Sidebar from './components/Sidebar';
import Canvas from './components/Canvas';
import Settings from './components/Settings';
import './styles/index.less';

const Generator = forwardRef(
  ({ fixedName, settingsWidgets, onCanvasSelect, ...props }, ref) => {
    console.log('settingsWidgets',settingsWidgets)
    return (
      <Provider ref={ref} {...props}>
        <div className="fr-generator-container">
          <Sidebar fixedName={fixedName} />
          <Canvas onSelect={onCanvasSelect}>{props.children}</Canvas>
          <Settings widgets={settingsWidgets} />
        </div>
      </Provider>
    );
  },
);

Generator.Provider = Provider;
Generator.Sidebar = Sidebar;
Generator.Canvas = Canvas;
Generator.Settings = Settings;

export {
  defaultSettings,
  defaultCommonSettings,
  defaultGlobalSettings,
} from './settings';
export { fromSetting, toSetting } from './transformer/form-render';
export default Generator;
