/*
 * :file description:
 * :name: \low-code-platform\src\components\renderer\TInput\index.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 10:34:40
 * :last editor: hyw
 * :date last edited: 2022-04-12 14:59:02
 */

import React, { ReactNode, ChangeEvent } from 'react';
import { Input, Select, InputProps } from 'antd';
import { TextAreaProps } from 'antd/es/input/TextArea';
import { RenderType } from '@/pages/form/constant';
import c from './index.less';

const DEFAULT_INPUT_CONFIG = {
  size: 'large',
  placeholder: '请输入',
  bordered: true,
  allowClear: true,
  maxLength: null,
  disabled: false,
  onChange: () => {},
  onPressEnter: () => {},
};

const DEFAULT_TEXTAREA_CONFIG = {
  allowClear: false,
  bordered: true,
  rows: 3,
  autoSize: { minRows: 3 }, // true | false | { minRows: 1, maxRows: 5 }
  maxLength: 500,
  showCount: false,
  onPressEnter: () => {},
  onResize: () => {},
};

// 数字格式校验
const numberReg = /^\d+(\.\d*)?$/;

const cleanout = (inputNumber: any) => {
  if (numberReg.test(inputNumber)) {
    return inputNumber;
  }
  return undefined;
};

const numberValidate = (e: ChangeEvent<HTMLInputElement>, onChange: any) => {
  console.log(e.target.value);
  if (!e.target.value || numberReg.test(e.target.value)) {
    onChange && onChange(e.target.value);
  }
};

const TInput = (props: InputProps & TextAreaProps & { [key: string]: any }) => {
  const matchedComponent = (): ReactNode => {
    const { type, rendertype } = props;
    const { placeholder } = DEFAULT_INPUT_CONFIG;
    const { autoSize } = DEFAULT_TEXTAREA_CONFIG;

    let comp = null;

    switch (type) {
      case 'input':
        comp = <Input placeholder={placeholder} allowClear {...props} />;
        break;
      case 'textarea':
        comp =
          rendertype === RenderType.SEARCH ? (
            <Input placeholder={placeholder} allowClear {...props} />
          ) : (
            <Input.TextArea
              placeholder={placeholder}
              autoSize={autoSize}
              maxLength={8000}
              {...props}
            />
          );
        break;
      case 'number':
        comp = <Input placeholder={placeholder} allowClear {...props} />;
        break;
      case 'phone':
        comp = (
          <Input.Group compact>
            <Select defaultValue="phone" style={{ width: '20%' }}>
              <Select.Option value="phone">手机</Select.Option>
              <Select.Option value="telephone">固话</Select.Option>
            </Select>
            <Select defaultValue="+86" style={{ width: '20%' }}>
              <Select.Option value="+86">+86</Select.Option>
            </Select>
            <Input
              allowClear
              placeholder={placeholder}
              {...props}
              style={{ width: '60%' }}
            />
          </Input.Group>
        );
        break;
      default:
        comp = <Input placeholder={placeholder} allowClear {...props} />;
        break;
    }
    return comp;
  };

  return <div className={c.t_input}>{matchedComponent()}</div>;
};

export default TInput;
