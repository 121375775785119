/*
 * :file description:
 * :name: /low-code-platform/src/common/http/index.ts
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-15 10:06:22
 * :last editor: hyw
 * :date last edited: 2024-03-13 21:57:39
 */
//1、引入axios
import axios from 'axios';
import { message } from 'antd';
import { history, useLocation } from 'umi';
import { getToken, getUrlParam, isInDingtalk } from '@/utils';
import { fitDomain, getTungeeHeader, isUrlInErrReturn } from '@/utils/tungee';
import { SUBID_MAP } from './constant';

type methodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
interface IIntecConfig {
  isCustomValidate?: boolean;
  isSurportPageSize?: boolean;
  [key: string]: any;
}

//2、创建axios的实例
// 1688 单独部署  需要分发
const source = getUrlParam('source');
const subscribeId = getUrlParam('subscribeId') || '';
window.__subscribeId = subscribeId;
const baseURL =
  source === '1688' ? '/1688' : SUBID_MAP[subscribeId]?.preUrl || '';
const httpService = axios.create({
  baseURL, // TODO:具体的配置可以根据项目情况而来
  timeout: 120000,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json',
  },
});

//3、axios的拦截--request
httpService.interceptors.request.use(
  (config: any) => {
    // 请求成功处理
    const token = getToken();
    if (token) {
      //判断浏览器中的cookie中是否存在项目的token
      config.headers.Authorization = token;
    }
    return config;
  },
  (err) => {
    Promise.reject(err); // 请求错误处理
  },
);

httpService.interceptors.request.use(
  (req: any) => {
    console.log('req', req);
    if (req.url.includes('login')) return req;
    // 本地调试钉钉环境
    // 组织：钉钉测试组织2  token：探迹小秘书
    // development
    if (process.env.NODE_ENV === 'development') {
      console.log('enter dev ');
      req.headers['appName'] = 'dingding_tungee_crm';
      // dev
      // req.headers['corpId'] = 'dingce3e8344eb0d88dca1320dcb25e91351';
      // req.headers['subscribeId'] = '26896002_0'; // 26896002_0
      // req.headers['token'] =
      // 'e729fc47b2c507d872dbe3e2801a16d3#2559572019677629';
      // test
      req.headers['subscribeId'] = '19165002_0'; // 26896002_0  191 测试   17811003_0 19165002_0
      req.headers['corpId'] = 'ding6e37764efa4e5ce0f5bf40eda33b7ba0';
      req.headers['token'] =
        '50d7add6ae0f866da8188667a744505a#176143493428332460';
      delete req.headers['Authorization'];
    } else {
      if (isInDingtalk()) {
        req.headers['appName'] =
          SUBID_MAP[subscribeId]?.appName || 'dingding_tungee_crm';
        req.headers['corpId'] = window.__corpId || getUrlParam('corpId');
        req.headers['subscribeId'] =
          window.__subscribeId || getUrlParam('subscribeId');
        req.headers['token'] =
          getToken() || 'ad8c50a7099faba8ef657cf77adf7d9f#266916313520687445';
      } else {
        req.headers['Authorization'] = getToken();
      }
    }

    if (getUrlParam('appName')) {
      req.headers['appName'] = getUrlParam('appName');
      req.url = fitDomain(getUrlParam('appName'), req.url);
    }

    return req;
  },
  (err) => {},
);
//4、axios的拦截--response
httpService.interceptors.response.use(
  (res: any) => {
    console.log(res);

    // TODO:具体的code对应的处理可继续添加修改
    if (res?.data?.code === 200) {
      // message.info('请求成功');
    }

    // TODO:此处预留无权限提示和数据结构返回
    // if (res.data.code === XXX) {
    //     message.info('无权限');
    //     // 此处可以对数据结构重新定义返回 具体可根据实际情况编写
    //     // res.data.data = {

    //     // }
    // }
    //其他情况的文字提示
    if (res?.data?.code !== 200) {
      const { msg } = res?.data ?? {};
      if (res?.data?.code === 31005) {
        window.localStorage.removeItem('token');
        history.push('/login');
      } else if ([10040001, 400020].includes(res?.data?.code)) {
        throw res.data;
      } else {
        message.warning(msg);
        //处理接口错误时要初始化业务loading的状态
        if (isUrlInErrReturn(res?.config?.url)) {
          return res;
        }
      }
      throw msg;
    }
    return res;
  },
  (err) => {
    // TODO:具体的code对应的处理可继续添加修改
    if (err?.response?.code === 301) {
      console.log('登录过期');
    }
    if (err?.response?.code === 500) {
      console.log('请联系管理员');
    }
    const msg = err.response?.data?.msg;
    message.warning(msg);
    return Promise.reject(err);
  },
);

export function request<T>(
  url: string,
  method: methodType = 'GET',
  params = {},
  intecConfig?: IIntecConfig,
  config?: { showLoading?: boolean; [key: string]: any },
) {
  const paramType = method === 'GET' ? 'params' : 'data';
  return new Promise<T>((resolve, reject) => {
    config?.showLoading && message.loading('加载中', 0);
    httpService
      .request<T>({
        url,
        method,
        [paramType]: params,
        headers: {
          ...getTungeeHeader(),
          ...config,
        },
        ...intecConfig,
      })
      .then((res: any) => {
        resolve(res?.data);
        config?.showLoading && message.destroy();
      })
      .catch((err) => {
        setTimeout(() => {
          message.destroy();
        }, 3000);
        reject(err);
      });
  });
}
export function getRequest<T>(
  url: string,
  params?: object,
  intecConfig?: IIntecConfig,
) {
  return request<T>(url, 'GET', params, intecConfig);
}
export function postRequest<T>(
  url: string,
  data?: object,
  intecConfig?: IIntecConfig,
) {
  return request<T>(url, 'POST', data, intecConfig);
}
export function putRequest<T>(
  url: string,
  data?: object,
  intecConfig?: IIntecConfig,
) {
  return request<T>(url, 'PUT', data, intecConfig);
}
export function deleteRequest<T>(
  url: string,
  data?: object,
  intecConfig?: IIntecConfig,
) {
  return request<T>(url, 'DELETE', data, intecConfig);
}
