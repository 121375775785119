/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\SortField\components\SortModal.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-13 17:42:03
 * :last editor: 黄艺
 * :date last edited: 2022-04-18 21:21:56
 */

import { useState, useEffect } from 'react';
import {
  Modal,
  Popover,
  Checkbox,
  Divider,
  CheckboxOptionType,
  Select,
} from 'antd';
import _ from 'lodash';
import IconFont from '@/components/IconFont';
import c from '../index.less';
import SortOptions from '@/pages/form/components/FilterOptionsSetting/SortOptions';
import popoverImg from '../../../assets/form/data-snippet-popover.png';

const { Option } = Select;

enum BizType {
  CRM_CUSTOMER = 'crm_customer',
  CRM_CUSTOMER_PERSONAL = 'crm_customer_personal',
}

export const customerMap = new Map([
  [BizType.CRM_CUSTOMER, '企业客户'],
  [BizType.CRM_CUSTOMER_PERSONAL, '个人客户'],
]);

export default function SortModal(props: { [key: string]: any }) {
  const { addons, children } = props;
  const formData = addons.getValues();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sortFields, setSortFields] = useState(formData.sortFields || []);
  const [allOptions, setAllOptions] = useState<CheckboxOptionType[]>([]);

  const [bizType, setBizType] = useState<string>(BizType.CRM_CUSTOMER_PERSONAL);
  const [commonOptions, setCommonOptions] = useState<CheckboxOptionType[]>();

  const compatibleSortFields = formData.relateSource
    ? sortFields?.find((item) => item.bizType === bizType)?.fields
    : sortFields;

  const checkboxValues = compatibleSortFields?.map((item) => item.key);

  const fieldsToOptions = (fields: Record<string, any> = {}) => {
    return Object.values(fields).map((item) => {
      return {
        id: item.id,
        value: item.id,
        key: item.id,
        label: item.title,
      };
    });
  };

  const findFields = (bizType: string) => {
    const { fields } =
      formData?.relateSource?.find((item) => item.bizType === bizType) || {};
    return formData.relateSource ? fields : formData?.fields;
  };

  const getOptions = (bizType: string) => {
    const fields = findFields(bizType);
    const optionsFromConfig = fieldsToOptions(fields);
    setAllOptions(optionsFromConfig);
    setCommonOptions(
      optionsFromConfig.filter((v) => checkboxValues?.includes(v.value)),
    );
  };

  const handleBizTypeChange = (value: string) => {
    setBizType(value);
  };

  const onChange = (checkedValues: any[]) => {
    const copySortFeilds = _.cloneDeep(sortFields);
    const options = allOptions.filter((v) => checkedValues.includes(v.value));
    let hasBizType = false;
    copySortFeilds?.forEach((item) => {
      if (item.bizType === bizType) {
        hasBizType = true;
        item.fields = options;
      }
    });
    if (!hasBizType) {
      copySortFeilds.push({
        bizType,
        fields: options,
      });
    }
    setCommonOptions(options);
    setSortFields(formData.relateSource ? copySortFeilds : options);
  };

  const handleSortsOptionChange = (item) => {
    let orderSortFields = [];
    if (formData?.relateSource) {
      orderSortFields = sortFields.map((field) => {
        if (field.bizType === bizType) {
          return {
            ...field,
            fields: item,
          };
        }
        return field;
      });
    } else {
      orderSortFields = item;
    }
    setCommonOptions(item);
    setSortFields(orderSortFields);
  };

  const handleOk = () => {
    addons.setValueByPath('sortFields', sortFields);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (!isModalVisible) return;
    getOptions(bizType);
  }, [isModalVisible, bizType]);

  useEffect(() => {
    setCommonOptions(
      allOptions.filter((v) => checkboxValues?.includes(v.value)),
    );
  }, []);

  const content = () => {
    return (
      <div className={c.popover}>
        <h4>移动端会将关联字段作为摘要展示</h4>
        <div className={c['popover-desc']}>根据这里的排序，依次展示0条摘要</div>
        <img src={popoverImg} alt="" />
      </div>
    );
  };

  return (
    <div className={c.filter_options_setting}>
      <div onClick={() => setIsModalVisible(true)}>{children}</div>
      <Modal
        width={600}
        title="关联字段展示排序"
        visible={isModalVisible}
        okText="确定"
        cancelText="取消"
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<IconFont type="icon-Close" />}
      >
        {formData?.relateSource && (
          <Select
            defaultValue={BizType.CRM_CUSTOMER_PERSONAL}
            style={{ width: '100%' }}
            onChange={handleBizTypeChange}
          >
            {formData?.relateSource?.map((item) => {
              return (
                <Option value={item.bizType}>
                  {customerMap.get(item.bizType)}
                </Option>
              );
            })}
          </Select>
        )}
        <div className={c.filter_options_container}>
          <div className={c.all_options}>
            <div style={{ color: '#171A1D' }}>
              关联字段（{allOptions.length}）
            </div>
            <div className={c.all_options_items}>
              <Checkbox.Group
                value={checkboxValues || []}
                options={allOptions}
                onChange={onChange}
              />
            </div>
          </div>

          <div className={c.common_options}>
            <Divider
              type="vertical"
              style={{ height: '100%', width: '24px' }}
            />
            <div style={{ width: '100%' }}>
              <span style={{ color: '#171A1D' }}>
                展示排序（{commonOptions?.length}）{' '}
              </span>
              <Popover content={content}>
                <IconFont
                  style={{ color: '#a5a6a8' }}
                  type="icon-shuoming"
                ></IconFont>
              </Popover>
              <SortOptions
                items={commonOptions}
                onChange={handleSortsOptionChange}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
