/*
 * :file description: page process
 * :name: \ui-renderd:\progree\low-code-platform\src\pages\generator\process\components\ProcessDesign\index.tsx
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-12 14:01:14
 * :last editor: hyw
 * :date last edited: 2022-11-22 11:03:13
 */

import c from './index.less';
import './index.less';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import { fromJS } from 'immutable';
import lownTipImg from '@/assets/down.svg';
import {
  Tabs,
  Form,
  Radio,
  Input,
  Switch,
  Space,
  Checkbox,
  message,
  Modal,
  Tooltip,
  Divider,
  Select,
} from 'antd';
import { useModel, useLocation } from 'umi';
import TopBanner from '../TopBanner';
import Flow from '../FlowV3';
import { FormInstance } from 'antd/es/form';
// 部门、人员、角色架构选择器
import MultipleChoice from '@/components/MultipleChoice';
import _cloneDeep from 'lodash/cloneDeep';
import {
  ExclamationCircleOutlined,
  CloseOutlined,
  DownOutlined,
} from '@ant-design/icons';
import { startProcess, editProcessDefinitionInfo } from '../../api';
import {
  defaultFlowData,
  baseFormItem,
  DEFAULT_FLOW_SETTING,
  ACTIVITY_TYPES_MAP,
  BOOKE_BABA_BIZTYPE,
} from './constants';
import { mockData } from '../FlowV3/mock';
import IconFont from '@/components/IconFont';
import ConditionalForm from '../ConditionalForm';
import ModifyForm from './ModifyForm';
import repeatPicImg from '@/images/repeat_pic.png';

import { COMMON_NODE_TYPE_MAP } from '../FlowV3/constants';
import BaseNodeSetting from './BaseNodeSetting';
import { computeData, sortUserOrDept } from './utils';
import { getSopStatus, updateSopStatus } from '@/services/sop';
import { getUrlParam } from '@/utils';

// 表单操作权限的灰度
import { useGray } from '@/pages/generator/relation/hooks';

const { TabPane } = Tabs;
const { confirm } = Modal;
const { Option } = Select;

const defaultForm1Data: Object = {
  isRevokeProcess: false,
  isAutoPassWhenRepeated: false,
  isAutoPassType: '2',
  canAppendTask: false,
  notifyInitiatorWhenPassNode: false,
  actionFindNotAssignee: '1',
  approvalUpdateForm: {
    updateFrom: false,
    updateReason: false,
    updateTime: 1,
    updateApprove: true,
    canUpdateApproveResult: [1],
  },
  canDelegateSubmit: false,
  submitAgents: [],
};

const ProcessDesign = () => {
  // 并行分支参数
  // 隐藏并行分支判断
  const versionValue = getUrlParam('versionValue');
  // 灰度并行分支
  const isShowInclusiveConditionNode = useGray('show_inclusivecondition_node');

  const extData = {
    versionValue,
    isShowInclusiveConditionNode,
  };

  const [flowData, setFlowData] = useState(
    _cloneDeep(mockData.processConfig.processDefinition.children),
  );
  const [flowType, setFlowType] = useState('1');
  // 当前自选数据
  const [currentOptionalData, setCurrentOptionalData] = useState([]);
  const [showFormList, setShowFormList] = useState([]);
  const [form] = Form.useForm();
  const { getFieldValue, setFieldsValue, getFieldsValue } = form;

  const [form1] = Form.useForm();

  // 表单二
  const {
    getFieldsValue: getFieldsValue1,
    setFieldsValue: setFieldsValue1,
  } = form1;

  const [authList, setAuthList] = useState<any[]>([]);
  const [memberTitleList, setMemberTitleList] = useState<any[]>([]);
  const formRef = React.createRef<FormInstance>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const childRef = useRef: React.RefObject<React.MutableRefObject<any>>(null);
  const [processFormValue] = useState(defaultForm1Data);
  const childRef = useRef(null);
  const topBannerRef = useRef(null);
  const [currentRecord, setCurrentRecord] = useState<any>({});
  const { upIfEdit, formCode } = useModel('useGenerator', (model) => model);

  const [activeKey, setActiveKey] = useState('1');
  // 流程图高度，跟随浏览器高度改变而改变

  // 开启重复提交时 弹窗状态
  const [showTipRepeatVisible, setShowTipRepeatVisible] = useState(false);
  const [isFirstLoadModal, setIsFirstLoadModal] = useState(true);
  const [showRepeatForm, setShowRepeatForm] = useState(false);

  // 当前节点在flowData 的下标，更新节点信息时需要用到
  const [currentNodeClues, setCurrentNodeClues] = useState([]);
  const [currentNode, setCurrentNode] = useState({});
  const [isLastNode, setIsLastNode] = useState(false);

  const [conData, setConData] = useState({});
  const [count, setCount] = useState(0);

  // 代提交
  const submitterRef = useRef();
  const [isShowSubmitTip, setIsShowSubmitTip] = useState(false);
  const [submitAgents, setSubmitAgents] = useState([]);
  const submitterList = useMemo(() => computeData(submitAgents), [
    submitAgents,
  ]);

  const location = useLocation();
  const { bizType, source } = location?.query || {};
  // 流程表单未激活时，from1中无法存入数据 缓存流程属性
  const globalSettingCache = useRef();

  // 根据类型返回基本表单配置
  useEffect(() => {
    setShowFormList(baseFormItem[flowType] || []);
  }, [flowType]);

  //  监听表格的数据变化
  useEffect(() => {
    if (count == 0) return;
    const {
      name,
      approvalType,
      requiredComment = '2',
      userOrDepOrRole,
      approvalNodeType,
      selfSelect,
      limitVerb,
      selfVerbUserOrDepOrRole,
      realBackOriginator = source == 'CloudPlatform' ? 'n' : 'y',
    } = getFieldsValue();

    console.log(getFieldsValue(), 'getFieldsValue');
    let behaviorList = getFieldValue('authList');
    let newData = fromJS(_cloneDeep(flowData));
    // 选择的人员
    const approvals: any[] = [];
    userOrDepOrRole?.departments?.forEach?.((ele: any) => {
      approvals.push({
        id: ele.id,
        label: ele.name,
        type: 'department',
      });
    });
    userOrDepOrRole?.roles?.forEach?.((ele: any) => {
      approvals.push({
        id: ele.id,
        label: ele.name,
        type: 'role',
      });
    });
    userOrDepOrRole?.users?.forEach?.((ele: any) => {
      approvals.push({
        id: ele.id,
        userId: ele.userId,
        label: ele.name,
        type: 'employee',
      });
    });

    userOrDepOrRole?.dynamicPerson?.forEach?.((ele: any) => {
      if (ele.type === 'manager' || ele.type === 'direct_manager') {
        approvals.push({ ...ele, label: ele.name });
      } else {
        approvals.push({
          id: ele.id,
          label: ele.name,
          type: ele.type,
        });
      }
    });

    let tempVerbArr: string[] = [];
    if (limitVerb) {
      tempVerbArr.push('approvals');
    }
    if (selfSelect) {
      tempVerbArr.push('selfSelect');
    }
    const selfSelectUserOrDept = transferUserOrDept(selfVerbUserOrDepOrRole);
    try {
      if (flowType == '1') {
        newData = newData.updateIn(currentNodeClues, (node: any) =>
          node
            .setIn(['props', 'name'], name)
            .setIn(['props', 'approverRules', 'approvals'], approvals)
            .setIn(['props', 'formConfig', 'behaviorList'], behaviorList),
        );
      } else if (flowType == '2') {
        const isSelf = approvalNodeType === 'selfSelect';
        newData = newData.updateIn(currentNodeClues, (node: any) =>
          node
            .setIn(['props', 'name'], name)
            .setIn(['props', 'approvalType'], approvalType)
            .setIn(['props', 'realBackOriginator'], realBackOriginator)
            .setIn(['props', 'requiredComment'], requiredComment)
            .setIn(['props', 'approverRules', 'approvals'], approvals)
            .setIn(
              ['props', 'approverRules', 'selfSelect'],
              isSelf ? approvals : [],
            )
            .setIn(
              ['props', 'approverRules', 'selectedLabels'],
              [approvalNodeType],
            )
            .setIn(['props', 'formConfig', 'behaviorList'], behaviorList),
        );
      } else if (flowType == '3') {
        newData = newData.updateIn(currentNodeClues, (node: any) =>
          node
            .setIn(['props', 'name'], name)
            .setIn(['props', 'approverRules', 'approvals'], approvals)
            .setIn(
              ['props', 'approverRules', 'selfSelect'],
              selfSelectUserOrDept,
            )
            .setIn(['props', 'approverRules', 'selectedLabels'], tempVerbArr),
        );
      }
      setFlowData([...newData.toJS()]);
    } catch (error) {}
  }, [count]);

  const transferUserOrDept = (userOrDepOrRole: any = {}) => {
    const approvals: any[] = [];
    userOrDepOrRole?.departments?.forEach?.((ele: any) => {
      approvals.push({
        id: ele.id,
        label: ele.name,
        type: 'department',
      });
    });
    userOrDepOrRole?.roles?.forEach?.((ele: any) => {
      approvals.push({
        id: ele.id,
        label: ele.name,
        type: 'role',
      });
    });
    userOrDepOrRole?.users?.forEach?.((ele: any) => {
      approvals.push({
        id: ele.id,
        userId: ele.userId,
        label: ele.name,
        type: 'employee',
      });
    });

    userOrDepOrRole?.dynamicPerson?.forEach?.((ele: any) => {
      if (ele.type === 'manager' || ele.type === 'direct_manager') {
        approvals.push({ ...ele, label: ele.name });
      } else {
        approvals.push({
          id: ele.id,
          label: ele.name,
          type: ele.type,
        });
      }
    });
    return approvals;
  };

  // 新增一个流程版本
  const addFlowVersion = () => {
    // 避免原来数据被修改后污染
    let jsonParse = _cloneDeep(defaultFlowData);
    setFlowData([...jsonParse]);
  };

  const onFinish = (values: any) => {
    console.log('Received values of form:', values);
  };

  const onFinish1 = (values: any) => {
    console.log('Received values of form:', values);
  };

  // 全选权限
  // 需要更新table字段
  const onAllChange = (e: any): void => {
    let authList = getFieldValue('authList');
    let cloneData = _cloneDeep(authList);
    cloneData?.forEach?.((ele) => {
      if (ele.disabled == false) {
        ele.fieldBehavior = e.target.value;
        if (
          (ele?.fieldId?.includes('table') ||
            ele?.fieldId?.includes('bizSuite')) &&
          ele?.children?.length
        ) {
          ele.children.forEach((i) => {
            i.fieldBehavior = e.target.value;
          });
        }
      }
    });
    setFieldsValue({
      formAuth: e.target.value,
      authList: [...(cloneData ?? [])],
    });
    setCount((val) => val + 1);
    upIfEdit(true);
  };

  // table 的展开与收起
  const clickTable = (e: any, index: number): void => {
    // if (source && source !== 'CRMPlatform') return;

    let authList = getFieldValue('authList');
    let cloneData = _cloneDeep(authList);
    cloneData[index].isShow = !cloneData[index]?.isShow;
    setFieldsValue({
      authList: [...cloneData],
    });
    setCount((val) => val + 1);
    upIfEdit(true);
  };
  // 单选权限
  const onChildrenChange = (e: any, index: number, indexI?: number): void => {
    let authList = getFieldValue('authList');
    let cloneData = _cloneDeep(authList);
    // indexI 存在则是table更新数据
    // indexI 存在则是bizSuite更新数据
    if (
      (indexI || indexI === 0) &&
      (cloneData[index]?.fieldId?.includes('table') ||
        cloneData[index]?.fieldId?.includes('bizSuite')) &&
      cloneData[index].children?.length
    ) {
      cloneData[index].children[indexI].fieldBehavior = e.target.value;
      let ifTableAllCheck = cloneData[index].children.every(
        (ele) => ele.fieldBehavior == e.target.value,
      );
      let hasNormalCheck = cloneData[index].children.filter(
        (ele) => ele.fieldBehavior === 'NORMAL',
      );
      if (ifTableAllCheck) {
        cloneData[index].fieldBehavior = e.target.value;
      } else if (hasNormalCheck?.length) {
        cloneData[index].fieldBehavior = 'NORMAL';
      } else {
        cloneData[index].fieldBehavior = 'READONLY';
      }
    } else {
      cloneData[index].fieldBehavior = e.target.value;
      if (
        (cloneData[index]?.fieldId?.includes('table') ||
          cloneData[index]?.fieldId?.includes('bizSuite')) &&
        cloneData[index]?.children?.length
      ) {
        cloneData[index].children?.forEach?.((i) => {
          i.fieldBehavior = e.target.value;
        });
      }
    }
    let ifAllCheck = cloneData.every(
      (ele) => ele.fieldBehavior == e.target.value,
    );
    if (ifAllCheck) {
      setFieldsValue({
        formAuth: e.target.value,
        authList: [...cloneData],
      });
    } else {
      setFieldsValue({
        formAuth: '',
        authList: [...cloneData],
      });
    }
    setCount((val) => val + 1);
    upIfEdit(true);
  };

  // 切換Tab
  const changeTabs = (key: string) => {
    setActiveKey(key);
  };

  // 重新拉取顶部组件列表数据
  const topGetList = () => {
    (topBannerRef?.current as any)?.pullList();
  };

  // 获取人员选择器数据
  const getDataBack = (data: any) => {
    setIsModalVisible(false);
    setFieldsValue({
      userOrDepOrRole: data,
    });
    setCount((val) => val + 1);
    upIfEdit(true);
  };

  // 节点名称数据改变时
  const changeValue = (e: React.ChangeEvent<HTMLInputElement>, key: any) => {
    setCount((val) => val + 1);
    upIfEdit(true);
  };

  // 审批意见和方式数据改变时
  const handleChange = (e: any, key: any) => {
    setCount((val) => val + 1);
    upIfEdit(true);
  };

  // 保存和开启流程函数
  const saveOrStartFlow = async (isSave: Boolean, type?: Number) => {
    if (flowType !== '1') {
      form.submit();
    }
    let form1 = getFieldsValue1();
    if (Object.keys(form1)?.length === 0) {
      form1 = globalSettingCache.current;
    }
    let cloneData = _cloneDeep(flowData);
    // 删除无效的条件或者条件组
    let effectiveData = removeInvalidConditions(cloneData);

    // 将错误信息收录起来和判断是否有错误
    let errorFlowDataAndTip: any = computeSendErrorAndTip(
      _cloneDeep(effectiveData),
    );
    if (errorFlowDataAndTip.isError) {
      let list = errorFlowDataAndTip.errList;
      Modal.error(config(list));
      setFlowType('1');
      // 先重置 在设置节点数据 避免部分节点数据被清除
      await form.resetFields();
      setFlowData([...(errorFlowDataAndTip.data || [])]);
      // message.warning('节点未设置完成');
      return;
    }
    const dealSendData = (obj) => {
      if (
        (obj?.componentName === 'ConditionContainerNode' ||
          obj?.componentName === 'ConditionNode' ||
          obj?.componentName === 'InclusiveConditionContainerNode' ||
          obj?.componentName === 'InclusiveConditionNode') &&
        obj?.children?.length
      ) {
        // 并行分支容器 需要把InclusiveConditionNode 变成 ConditionNode
        if (obj?.componentName === 'InclusiveConditionContainerNode') {
          obj.children?.forEach?.((item) => {
            item.componentName = 'ConditionNode';
            item.props.nodeName = 'ConditionNode';
          });
        }
        obj.children?.forEach?.((item) => {
          if (item?.componentName === 'ApprovalNode') {
            item.props = item.props || {};
            item.props.requiredComment =
              item.props.requiredComment == '1'
                ? true
                : item.props.requiredComment == '2'
                ? false
                : null;
          }
          if (
            (item?.componentName === 'ConditionNode' ||
              item?.componentName === 'ConditionContainerNode' ||
              item?.componentName === 'InclusiveConditionContainerNode' ||
              item?.componentName === 'InclusiveConditionNode') &&
            item.children?.length
          ) {
            dealSendData(item);
          }
        });
      }
      return obj;
    };
    let clonesendData = _cloneDeep(effectiveData);
    // 自选审批人时 重置approval字段
    clonesendData = clonesendData.map((item) => {
      if (item?.props?.approvalType === '2') {
        if (
          item?.props?.approverRules?.selectedLabels?.includes('selfSelect')
        ) {
          item.props.approverRules.approvals = [];
        }
      }
      return item;
    });
    let sendData: any = computeSendData(clonesendData);
    // let checkApplyNodeIsAllHidden = false;
    // console.log(sendData, clonesendData, flowData, 'flowData');
    sendData = sendData.map((item: any) => {
      // if (Array.isArray(item?.props?.formConfig?.behaviorList)) {
      //   item.props.formConfig.behaviorList =
      //     item.props.formConfig.behaviorList.filter(
      //       (i: any) => i?.fieldBehavior !== 'READONLY',
      //     );
      // }
      if (item.componentName === 'ApprovalNode' && item.props) {
        item.props.requiredComment =
          item.props.requiredComment == '1'
            ? true
            : item.props.requiredComment == '2'
            ? false
            : null;
      }
      // if (item.componentName === 'ApplyNode' && item.props) {
      //   checkApplyNodeIsAllHidden =
      //     !item?.props?.formConfig?.behaviorList?.filter(
      //       (i: any) => i.fieldBehavior !== 'HIDDEN',
      //     )?.length;
      // }
      if (
        (item.componentName === 'ConditionContainerNode' ||
          item.componentName === 'InclusiveConditionContainerNode') &&
        item.children?.length
      ) {
        dealSendData(item);
      }
      return item;
    });
    // if (checkApplyNodeIsAllHidden) {
    //   message.warning('发起人不可隐藏所有字段');
    //   return;
    // }

    const userInfo = localStorage.getItem('userInfo') || '';
    const { userId = '' } = userInfo ? JSON.parse(userInfo) : {};

    if (isShowSubmitTip && !submitterList?.length) {
      message.warning('代提交人必填');
      return;
    }

    // 编辑
    if (isSave) {
      editProcessDefinitionInfo(currentRecord?.processId, {
        formRelation: {
          formCode: formCode,
          userId: userId,
        },
        processConfig: {
          globalSetting: {
            actionFindNotAssignee: !form1?.actionFindNotAssignee
              ? '1'
              : form1?.actionFindNotAssignee, //审核人为空时
            isAutoPassWhenRepeated: !form1?.isAutoPassWhenRepeated
              ? false
              : form1?.isAutoPassWhenRepeated, // 重复审批人自动通过
            isRevokeProcess: !form1?.isRevokeProcess
              ? false
              : form1?.isRevokeProcess, // 允许发起人撤回
            canAppendTask: !form1?.canAppendTask ? false : form1?.canAppendTask, // 允许审批人加签
            notifyInitiatorWhenPassNode: !form1?.notifyInitiatorWhenPassNode
              ? false
              : form1?.notifyInitiatorWhenPassNode, // 允许审批人加签
            approvalUpdateForm: form1?.approvalUpdateForm || {}, // 修改表单字段
            isAutoPassType: !form1?.isAutoPassType
              ? '2'
              : form1?.isAutoPassType,
            submitAgentInfo: {
              // 代他人审批
              canDelegateSubmit: form1.canDelegateSubmit,
              submitAgents: submitterList || [],
            },
          },
          processDefinition: {
            children: [...sendData],
            processKey: '',
            processName: '',
          },
        },
      })
        .then((res: any) => {
          const { code, msg } = res;
          if (code === 200) {
            // 触发顶部组件列表数据的回调
            topGetList();
            message.success('保存成功');
          } else {
            message.warning(msg);
          }
        })
        .catch((err) => {
          const { msg } = err;
          message.warning(msg);
        });

      upIfEdit(false);
    } else {
      // 开启流程
      const openProcess = () => {
        confirm({
          title: '确定要启用流程吗?',
          icon: <ExclamationCircleOutlined />,
          content: '启用后，新提交的数据将按此版本的流程流转',
          cancelText: '取消',
          okText: '确定',
          onOk() {
            startProcess({
              processSaveDTO: {
                formRelation: {
                  formCode: formCode,
                  userId: userId,
                },
                processConfig: {
                  globalSetting: {
                    actionFindNotAssignee: !form1?.actionFindNotAssignee
                      ? '1'
                      : form1?.actionFindNotAssignee, //审核人为空时
                    isAutoPassWhenRepeated: !form1?.isAutoPassWhenRepeated
                      ? false
                      : form1?.isAutoPassWhenRepeated, // 重复审批人自动通过
                    isRevokeProcess: !form1?.isRevokeProcess
                      ? false
                      : form1?.isRevokeProcess, // 允许发起人撤回
                    approvalUpdateForm: form1?.approvalUpdateForm || {}, // 修改表单字段
                    isAutoPassType: !form1?.isAutoPassType
                      ? '2'
                      : form1?.isAutoPassType,
                    submitAgentInfo: {
                      // 代他人审批
                      canDelegateSubmit: form1.canDelegateSubmit,
                      submitAgents: submitterList || [],
                    },
                  },
                  processDefinition: {
                    children: [...sendData],
                    processKey: '',
                    processName: '',
                  },
                },
              },
              formCode: formCode,
              processId: type === 1 ? undefined : currentRecord?.processId,
            })
              .then((res: any) => {
                const { code, msg } = res;
                if (code === 200) {
                  // 拉去列表数据
                  topGetList();
                  message.success('启用成功');
                  upIfEdit(false);
                } else {
                  message.success(msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };
      // ERP-判断工作SOP开启状态
      if (source === 'CloudPlatform') {
        const res: any = await getSopStatus({ formCode });
        if (res?.result?.sopStatus) {
          Modal.confirm({
            title: '工作SOP提示',
            content:
              '当前表单已启用工作SOP，若新建并启用流程设计-审批流，则工作SOP会被停用！',
            okText: '确定',
            cancelText: '关闭',
            icon: <ExclamationCircleOutlined />,
            closable: true,
            onOk: async () => {
              const rsp: any = await updateSopStatus({
                formCode,
                sopStatus: false,
              });
              if (rsp?.success) {
                openProcess();
              }
            },
          });
          return;
        }
      }
      openProcess();
    }
  };
  // 开始流程
  const saveFlow = () => {
    saveOrStartFlow(true);
  };
  // 在新建时直接启用
  const startFlow = (type: number) => {
    saveOrStartFlow(false, type);
  };

  //   'department',
  //   'money',
  //   'number',
  //   'innerContact',
  //   'select',
  //   'multiSelect',
  const mapJson: any = {
    department: 'dept_component_condition',
    initiator: 'start_user_condition', // 发起人
    money: 'range_condition',
    number: 'range_condition',
    innerContact: 'user_component_condition',
    select: 'single_component_condition',
    multiSelect: 'multi_component_condition',
  };

  // 将无效的条件删除
  const removeInvalidConditions = (data: any) => {
    if (data && data.length > 0) {
      data?.forEach?.((item: any) => {
        // 条件节点
        if (
          item.componentName == 'ConditionNode' ||
          item.componentName == 'InclusiveConditionNode'
        ) {
          let newconditions: any = [];

          item?.props?.conditions &&
            item?.props?.conditions?.forEach?.((ele) => {
              let conditionsChild: any = [];
              ele &&
                ele?.forEach?.((val: any) => {
                  if (val.widget == 'money' || val.widget == 'number') {
                    switch (val?.value?.before) {
                      case 'lt':
                      case 'gt':
                      case 'lte':
                      case 'gte':
                      case 'eq':
                        const minValue = val?.value?.after?.min;
                        let type = typeof minValue;
                        let trimVal =
                          type === 'string' ? minValue.trim() : minValue;
                        // trimVal + '' 解决数字 0 为false问题，保存不了
                        if (trimVal + '') {
                          conditionsChild.push(val);
                        }
                        break;
                      case 'bt':
                        let trimLeftVal = val?.value?.after?.min;
                        let trimRightVal = val?.value?.after?.max;
                        let btTrimLeftVal =
                          typeof trimLeftVal === 'string'
                            ? trimLeftVal?.trim()
                            : trimLeftVal;
                        let btTtrimRightVal =
                          typeof trimRightVal === 'string'
                            ? trimRightVal?.trim()
                            : trimRightVal;
                        if (btTrimLeftVal && btTtrimRightVal) {
                          conditionsChild.push(val);
                        }
                        break;
                    }
                  }
                  if (val.widget == 'multiSelect') {
                    if (val?.value?.after?.length) {
                      conditionsChild.push(val);
                    }
                  }

                  if (
                    val.widget == 'department' ||
                    val.widget == 'innerContact' ||
                    val.widget == 'initiator' ||
                    val.widget == 'select'
                  ) {
                    if (val?.value?.length) {
                      conditionsChild.push(val);
                    }
                  }
                });

              // 将有效的条件收录起来
              if (conditionsChild.length !== 0) {
                newconditions.push(conditionsChild);
              }
            });
          item.props.conditions = newconditions;
        }
        if (item.children) {
          removeInvalidConditions(item.children);
        }
      });
      return data;
    }
  };

  // 收录错误信息函数
  const computeSendErrorAndTip = (data: Array<any>) => {
    let errorTip = false;
    const errList: Array<any> = [];
    function computeSendError(data: Array<any>) {
      if (data && data.length > 0) {
        data?.forEach?.((item: any, index) => {
          // 审核节点
          if (item.componentName == 'ApprovalNode') {
            // 重置状态
            item.error = false;
            const {
              props: {
                name,
                approverRules: { approvals, selfSelect },
              },
            } = item;
            let trimName = name?.trim();
            if (!trimName || !approvals?.length) {
              item.error = true;
              errorTip = true;
              if (!trimName) {
                let err = [];
                err.push('未填写名字');
                if (!approvals.length) {
                  err.push('未选择审核人');
                }
                errList.push({
                  tip: `审核节点:${err.join(',')}`,
                });
              } else {
                errList.push({
                  tip: `${trimName}:未选择审核人`,
                });
              }
            }
          }
          if (
            item.componentName == 'ConditionNode' ||
            item.componentName == 'InclusiveConditionNode'
          ) {
            // let newconditions: any = []
            const {
              props: { name, conditions },
            } = item;
            item.error = false;
            let trimName = name?.trim();
            if (!trimName) {
              item.error = true;
              errList.push({
                tip: `条件节点:未设置名字`,
              });
              errorTip = true;
            } else if (data.length != index + 1) {
              if (!conditions.length || !conditions?.[0].length) {
                item.error = true;
                errList.push({
                  tip: `${trimName}:未设置条件`,
                });
                errorTip = true;
              } else {
                conditions?.forEach?.((arr, index) => {
                  arr &&
                    arr?.forEach?.((val) => {
                      if (
                        (val.widget == 'number' || val.widget == 'money') &&
                        val?.value?.before == 'bt'
                      ) {
                        if (
                          Number(+val?.value?.after?.min) >=
                          Number(+val?.value?.after?.max)
                        ) {
                          errList.push({
                            tip: `${trimName}-条件组${
                              index + 1
                            }:输入的数值范围不合理`,
                          });
                          errorTip = true;
                        }
                      }
                    });
                });
              }
            }
          }

          // 抄送节点
          if (item.componentName == 'CarbonNode') {
            // 重置状态
            item.error = false;
            const {
              props: {
                name,
                approverRules: { approvals, selectedLabels, selfSelect },
              },
            } = item;
            let trimName = name?.trim();
            const limitVerb = selectedLabels?.includes('approvals');
            const selfVerb = selectedLabels?.includes('selfSelect');
            if (
              !trimName ||
              (!selfVerb && !limitVerb) ||
              (approvals?.length <= 0 && limitVerb) ||
              (selfVerb && selfSelect?.length <= 0)
            ) {
              item.error = true;
              errorTip = true;
              if (!trimName) {
                let err = [];
                err.push('未填写名字');
                if (!approvals.length) {
                  err.push('未选择抄送人');
                }
                errList.push({
                  tip: `抄送节点:${err.join(',')}`,
                });
              } else {
                errList.push({
                  tip: `${trimName}:未选择抄送人`,
                });
              }
            }
          }
          if (item.children) {
            computeSendError(item.children);
          }
        });
        return data;
      }
    }
    let errData: any = computeSendError(data);

    return {
      isError: errorTip,
      errList: errList,
      data: errData,
    };
  };

  // 构造数据传给后台
  const computeSendData = (data: Array<any>) => {
    if (data && data.length > 0) {
      data?.forEach?.((item: any, index: number) => {
        if (
          item.componentName == 'ConditionNode' ||
          item.componentName == 'InclusiveConditionNode'
        ) {
          item.props.priority = index + 1;
          let newconditions: any = [];
          item?.props?.conditions &&
            item?.props?.conditions?.forEach?.((ele, i) => {
              let conditionsChild: any = [];

              ele &&
                ele?.forEach?.((val) => {
                  let newJson: any = {};
                  const { enumNames, enum: enumArr } = val;

                  newJson.type = mapJson[val.widget];
                  newJson.paramLabel = val.title;
                  newJson.widget = val.widget;
                  newJson.paramKey = val.id;
                  newJson.id = val.id;

                  if (val.widget == 'money' || val.widget == 'number') {
                    newJson.key = val?.value?.before;
                    // 等于  eq boundEqual
                    // 介于两数之间  bt lowerBoundEqual upperBoundEqual upperBound lowerBound
                    // 大于等于  gte lowerBoundEqual
                    // 小于等于  lte upperBoundEqual
                    // 大于  gt lowerBound
                    // 小于  lt upperBound

                    switch (val?.value?.before) {
                      case 'lt':
                        newJson.upperBound = val?.value?.after?.min;
                        break;
                      case 'gt':
                        newJson.lowerBound = val?.value?.after?.min;
                        break;
                      case 'lte':
                        newJson.upperBoundEqual = val?.value?.after?.min;
                        break;
                      case 'gte':
                        newJson.lowerBoundEqual = val?.value?.after?.min;
                        break;
                      case 'eq':
                        newJson.boundEqual = val?.value?.after?.min;
                        break;
                      case 'bt':
                        newJson.lowerBound = val?.value?.after?.min;
                        newJson.upperBound = val?.value?.after?.max;
                        newJson.lowerBoundEqual = val?.value?.after?.min;
                        newJson.upperBoundEqual = val?.value?.after?.max;
                        break;
                    }
                  }
                  if (val.widget == 'select' || val.widget == 'multiSelect') {
                    let options: any = [];
                    enumNames &&
                      enumNames.forEach?.((ele: any, i: number) => {
                        options.push({ key: ele, value: enumArr[i] });
                      });
                    newJson.oriValues = options;
                  }
                  if (val.widget == 'select') {
                    newJson.paramValues = val?.value || [val.default];
                  }
                  if (val.widget == 'multiSelect') {
                    newJson.matchType = val?.value?.before == '2' ? 2 : 1;
                    newJson.paramValues = val?.value?.after || val.default;
                  }

                  if (val.widget == 'department') {
                    const cons: Array<any> = [];
                    const sendJson: any = {
                      id: 'id',
                      type: 'type',
                      name: 'name',
                      value: 'id',
                    };
                    val?.value &&
                      val?.value.forEach?.((ele) => {
                        const obj: any = {};
                        Object.keys(sendJson)?.forEach?.((val) => {
                          obj[val] = ele[sendJson[val]];
                        });
                        obj['orgType'] = 2;
                        cons.push(obj);
                      });
                    newJson.conds = cons;
                  }
                  if (
                    val.widget == 'innerContact' ||
                    val.widget == 'initiator'
                  ) {
                    const cons: Array<any> = [];
                    const sendJson: any = {
                      id: 'id',
                      type: 'type',
                      name: 'name',
                      value: 'userId',
                    };
                    val?.value &&
                      val?.value.forEach?.((ele) => {
                        const obj: any = {};
                        Object.keys(sendJson)?.forEach?.((val) => {
                          obj['orgType'] = ele.orgType;
                          obj[val] =
                            val === 'value'
                              ? ele[sendJson[val]] || ele.id
                              : ele[sendJson[val]];
                        });

                        cons.push(obj);
                      });
                    newJson.conds = cons;
                  }

                  conditionsChild.push(newJson);
                });
              newconditions.push(conditionsChild);
            });
          item.props.conditions = newconditions;
        }

        if (item.children) {
          computeSendData(item.children);
        }
      });
      return data;
    }
  };

  // 错误提示弹窗内容
  const config = (list) => {
    return {
      title: '当前内容无法保存',
      width: 480,
      className: 'modalConfig',
      okText: '知道了',
      content: (
        <div className={c.errTips}>
          <h3> 以下内容不完整,请修改后保存</h3>
          {list.map((val) => {
            return (
              <p>
                <span>流程设计</span>
                <span>{val.tip}</span>
              </p>
            );
          })}
        </div>
      ),
    };
  };

  // 重组发送给后端的数据
  const computeFlowData = (data: Array<any>) => {
    if (data && data.length > 0) {
      data?.forEach?.((item: any) => {
        if (
          item.componentName == 'ConditionNode' ||
          item.componentName == 'InclusiveConditionNode'
        ) {
          item?.props?.conditions &&
            item?.props?.conditions.forEach?.((ele) => {
              ele &&
                ele.forEach?.((val) => {
                  if (val.widget == 'select' || val.widget == 'multiSelect') {
                    val.enum = val.oriValues?.map((e) => e.value) ?? [];
                    val.enumNames = val.oriValues?.map((e) => e.key) ?? [];
                  }
                  if (val.widget == 'select') {
                    val.value = val?.paramValues;
                  }
                  if (val.widget == 'department') {
                    val.value = val?.conds || [];
                  }
                  if (
                    val.widget == 'innerContact' ||
                    val.widget == 'initiator'
                  ) {
                    val?.conds &&
                      val?.conds.forEach?.((e) => {
                        e.userId = e.value;
                      });
                    val.value = val?.conds || [];
                  }
                  if (val.widget == 'multiSelect') {
                    val.value = {};
                    val.value.before = String(val?.matchType);
                    val.value.after = val?.paramValues;
                  }
                  if (val.widget == 'money' || val.widget == 'number') {
                    val.value = {
                      before: '',
                      after: {
                        min: '',
                        max: '',
                      },
                    };
                    val.value.before = val.key;
                    // 等于  eq boundEqual
                    // 介于两数之间  bt lowerBoundEqual upperBoundEqual upperBound lowerBound
                    // 大于等于  gte lowerBoundEqual
                    // 小于等于  lte upperBoundEqual
                    // 大于  gt lowerBound
                    // 小于  lt upperBound

                    switch (val.key) {
                      case 'lt':
                        val.value.after.min = val.upperBound;
                        break;
                      case 'gt':
                        val.value.after.min = val.lowerBound;
                        break;
                      case 'lte':
                        val.value.after.min = val.upperBoundEqual;
                        break;
                      case 'gte':
                        val.value.after.min = val.lowerBoundEqual;
                        break;
                      case 'eq':
                        val.value.after.min = val.boundEqual;
                        break;
                      case 'bt':
                        val.value.after.min = val.lowerBound;
                        val.value.after.max = val.upperBound;
                        break;
                    }
                  }

                  val.title = val.paramLabel;
                });
            });
        }
        // 审批节点自选人状态需要 approvals字段设置值
        if (item.componentName === 'ApprovalNode') {
          const approverRules = item?.props?.approverRules ?? {};
          const isSelf = approverRules?.selectedLabels?.includes('selfSelect');
          if (isSelf) {
            item.props.approverRules.approvals = approverRules?.selfSelect;
          }
          item.props.realBackOriginator =
            item.props.realBackOriginator ??
            (source == 'CloudPlatform' ? 'n' : 'y');
        }

        if (item.children) {
          computeFlowData(item.children);
        }
      });
      return data;
    }
  };
  // 由于再次编辑 新增的字段没有统计到
  const addExtraSchemaItemInFlowData = (arr: any, authList: any) => {
    if (!arr?.length) return [];
    // const authListArr = authList?.map((i:any) => i.fieldId) || []
    const typeMap = {
      [COMMON_NODE_TYPE_MAP.APPLY_NODE]: '1', //发起节点
      [COMMON_NODE_TYPE_MAP.APPROVAL_NODE]: '2', //审核节点
      [COMMON_NODE_TYPE_MAP.CARBON_NODE]: '3', //抄送节点
      [COMMON_NODE_TYPE_MAP.CONDITION_NODE]: '4', //条件节点
    };
    arr = arr.map((item: any) => {
      if (typeMap[item?.componentName] == 1) {
        const behaviorList = item?.props?.formConfig?.behaviorList;
        const behaviorListArr =
          behaviorList?.map((item) => item?.fieldId) || [];
        authList?.forEach?.((item) => {
          if (behaviorListArr?.indexOf(item?.fieldId) === -1) {
            behaviorList?.push({
              ...item,
              fieldBehavior: 'NORMAL',
              fieldId: item.fieldId,
            });
          }
        });
      }
      return item;
    });
    return arr;
  };

  // 获取版本信息
  const getVersionDefinitionInfo = async (
    currentItem: any,
    data: any,
    authList: Array<any>,
  ) => {
    // 保存当前对象数据
    await form.resetFields();
    setCurrentRecord(currentItem);
    // 保存权限数据
    setAuthList(authList);
    const {
      processConfig: { globalSetting, processDefinition },
    } = data;
    console.log('----globalSetting--', globalSetting);
    globalSettingCache.current = globalSetting;
    const _submitAgents =
      (globalSetting?.submitAgentInfo?.submitAgents || []).reduce(
        (acc, item) => {
          const { type } = item || {};

          item.name = item?.name || item?.label;
          item.title = item?.name || item?.label;

          switch (type) {
            case 'role':
              acc.roles?.push(item);
              break;

            case 'employee':
              acc.users?.push(item);
              break;

            case 'department':
              acc.departments?.push(item);
              break;

            default:
              break;
          }

          return acc;
        },
        { roles: [], departments: [], users: [] },
      ) || {};
    setSubmitAgents(_submitAgents);
    setIsShowSubmitTip(!!globalSetting?.submitAgentInfo?.canDelegateSubmit);
    setFieldsValue1({
      isRevokeProcess: globalSetting.isRevokeProcess,
      canAppendTask: globalSetting.canAppendTask,
      notifyInitiatorWhenPassNode: globalSetting.notifyInitiatorWhenPassNode,
      isAutoPassWhenRepeated: globalSetting?.isAutoPassWhenRepeated, // 当已经有权限表时直接用权限表的数据，没有时用初始化的数据
      isAutoPassType: String(globalSetting?.isAutoPassType), // 重复
      actionFindNotAssignee: String(globalSetting?.actionFindNotAssignee),
      approvalUpdateForm: globalSetting?.approvalUpdateForm || {}, // 修改表单字段
      canDelegateSubmit: !!globalSetting?.submitAgentInfo?.canDelegateSubmit,
    });
    // 初始化 重复审批人自动通
    if (globalSetting?.isAutoPassWhenRepeated) {
      setShowRepeatForm(true);
    }

    console.log('processDefinition', processDefinition);
    let cloneFlowData = _cloneDeep(processDefinition.children);
    let newFlowData = computeFlowData(cloneFlowData);
    // 加上 再次编辑新增的schema字段
    newFlowData = addExtraSchemaItemInFlowData(newFlowData, authList);
    setFlowData([...newFlowData]);
    // 激活节点属性tab
    setActiveKey('1');
    // 将当前编辑类似设置为发起人
    setFlowType('1');
  };

  //  获取权限数据
  const getAuthlist = (authList: any[]) => {
    setAuthList(authList);
  };

  //  获取成员项数据
  const getMemberTitleList = (list: any[]) => {
    setMemberTitleList(list);
  };

  // 获取条件的值
  const callBackConData = (data: any) => {
    let newData = fromJS(_cloneDeep(flowData));
    try {
      newData = newData.updateIn(currentNodeClues, (node: any) =>
        node
          .setIn(['props', 'name'], data.name)
          .setIn(['props', 'conditions'], data.conditions)
          .setIn(['props', 'priority'], data.priority),
      );
      setFlowData([...newData.toJS()]);
    } catch (error) {
      message.warning('该节点不存在或者已经被删除');
    }
  };

  // 设置节点状态
  const setNodeStatus = (flowData: any, data: any, isAllFalse?: Boolean) => {
    let newData = _cloneDeep(flowData);
    let parentNode = {
      children: [...newData],
    };

    const deepChange = (parentNode: any) => {
      const dataList = parentNode?.children;
      if (Array.isArray(dataList) && dataList?.length) {
        dataList.forEach?.((childNode: any, index: number) => {
          if (isAllFalse) {
            childNode.isActive = false;
          } else {
            if (childNode.id === data.id) {
              childNode.isActive = true;
            } else {
              childNode.isActive = false;
            }
          }
          deepChange(childNode);
        });
      }
    };
    deepChange(parentNode);
    setTimeout(() => {
      setFlowData([...newData]);
    }, 0);
  };

  // 点击节点回调
  const handleNodeClick = (param: any) => {
    const typeMap = {
      [COMMON_NODE_TYPE_MAP.APPLY_NODE]: '1', //发起节点
      [COMMON_NODE_TYPE_MAP.APPROVAL_NODE]: '2', //审核节点
      [COMMON_NODE_TYPE_MAP.CARBON_NODE]: '3', //抄送节点
      [COMMON_NODE_TYPE_MAP.CONDITION_NODE]: '4', //条件节点
      [COMMON_NODE_TYPE_MAP.INCLUSIVE_CONDITION_NODE]: '4', //条件节点
    };
    console.log('*****', param);
    const { data, clues, extData } = param;
    const type = typeMap[data.componentName];
    setNodeStatus(flowData, data);
    setFlowType(type);
    setCurrentNodeClues(clues);
    setCurrentNode(data);
    if (type != '4') {
      const {
        props,
        props: {
          approverRules: {
            approvals = [],
            selfSelect = [],
            selectedLabels = [],
          },
          approvalType,
          requiredComment = '2',
          realBackOriginator = source == 'CloudPlatform' ? 'n' : 'y',
        },
      } = data;
      let behaviorList = props?.formConfig?.behaviorList || [];
      // 当前数据的表单权限和 目前所有权限进行匹配过滤（将能与当前的数据匹配出来，并赋值title）
      let newAuthList = _cloneDeep(authList);
      // 需要兼容 历史table的数据  历史数据没有table的children
      newAuthList?.forEach?.((ele) => {
        // 编辑时新增字段 mapData为空 需要按照节点类型设置默认值
        let mapData = behaviorList.filter((e) => e.fieldId == ele.fieldId);
        if (mapData.length) {
          ele.fieldBehavior = mapData?.[0]?.fieldBehavior;
          // table类型  1 没有children 2 有chilren 但是table新加了item
          // bizSuite类同table
          if (
            ele.fieldId?.includes('table') ||
            ele.fieldId?.includes('bizSuite')
          ) {
            if (!mapData?.[0]?.children?.length) {
              ele.children?.forEach?.((i) => {
                i.fieldBehavior = mapData?.[0]?.fieldBehavior;
              });
            } else {
              const childrenMapData = mapData?.[0]?.children?.map(
                (i) => i?.fieldId,
              );
              // table里面新增的字段 和table一样
              ele.children?.forEach?.((i) => {
                const index = childrenMapData?.findIndex(
                  (k) => k === i?.fieldId,
                );
                if (index == -1) {
                  i.fieldBehavior = mapData?.[0]?.fieldBehavior;
                } else {
                  i.fieldBehavior =
                    mapData?.[0]?.children?.[index]?.fieldBehavior;
                }
              });
            }
          }
        } else {
          if (type === '2') {
            ele.fieldBehavior = 'READONLY';
            // table类型 需要更新children
            if (
              (ele?.fieldId?.includes('table') ||
                ele?.fieldId?.includes('bizSuite')) &&
              ele?.children?.length
            ) {
              ele.children.forEach((i) => {
                i.fieldBehavior = 'READONLY';
              });
            }
          }
        }
      });

      if (behaviorList.length == 0) {
        // 审核人默认只读
        if (type === '2') {
          newAuthList?.forEach?.((ele) => {
            ele.fieldBehavior = 'READONLY';
            // table类型 需要更新children
            if (
              (ele?.fieldId?.includes('table') ||
                ele?.fieldId?.includes('bizSuite')) &&
              ele?.children?.length
            ) {
              ele.children.forEach((i) => {
                i.fieldBehavior = 'READONLY';
              });
            }
          });
        }
        // 发起人默认可编辑，审核人默认只读
        setFieldsValue({
          formAuth: type === '1' ? 'NORMAL' : 'READONLY',
        });
      } else {
        let jupdeValue = behaviorList[0].fieldBehavior;
        let ifAllCheck = behaviorList.every(
          (ele) => ele.fieldBehavior == jupdeValue,
        );

        if (ifAllCheck) {
          setFieldsValue({
            formAuth: jupdeValue,
          });
        } else {
          setFieldsValue({
            formAuth: '',
          });
        }
      }
      const userOrDepOrRole = sortUserOrDept(approvals);
      const selfVerbUserOrDepOrRole = sortUserOrDept(selfSelect);
      const tempFormValue: any = {};
      if (type === '2') {
        tempFormValue.approvalNodeType = selectedLabels?.[0] ?? 'approvals';
        tempFormValue.userOrDepOrRole =
          selectedLabels?.[0] === 'selfSelect'
            ? selfVerbUserOrDepOrRole
            : userOrDepOrRole;
      }
      if (type === '3') {
        tempFormValue.limitVerb = selectedLabels?.includes('approvals');
        tempFormValue.selfSelect = selectedLabels?.includes('selfSelect');
        tempFormValue.selfVerbUserOrDepOrRole = selfVerbUserOrDepOrRole;
        if (!selectedLabels || selectedLabels?.length <= 0) {
          tempFormValue.limitVerb = true;
        }
      }
      setFieldsValue({
        name: data.props.name,
        userOrDepOrRole,
        approvalType: String(approvalType),
        requiredComment: requiredComment,
        authList: newAuthList,
        realBackOriginator:
          realBackOriginator ?? (source == 'CloudPlatform' ? 'n' : 'y'),
        ...tempFormValue,
        //...TODO:其他需要回填的节点信息
      });
      setCount((val) => val + 1); // 触发更新
    } else {
      // 设置条件值方便回填
      let newData = fromJS(_cloneDeep(flowData));
      let cloneClues = _cloneDeep(clues);
      let trueRemainClues = cloneClues.slice(0, -1);
      // 获取父节点 主要是取长度
      const branches = newData.getIn(trueRemainClues).toJS();
      let lastIndex = cloneClues.pop();
      setIsLastNode(
        Number(lastIndex) + 1 == branches.length &&
          extData?.nodeName !== 'InclusiveConditionContainerNode',
      );
      setConData(data?.props || {});
    }
    setActiveKey('1');
    console.log('data', data);
    console.log('clues', clues);
  };

  // 节点删除时，做更新编辑区域操作 （编辑时同样要做相应逻辑）
  const handleNodeActiveStatus = (remainData: any) => {
    let newData = _cloneDeep(remainData);
    // 使用上面那个会导致无效调用，造成内存泄漏
    let newDataOther = _cloneDeep(remainData);
    // 拿所剩下flowData的值，去查找当前编辑的对象的下标
    let clues = findIndexList(newData, currentNode.id);

    if (clues.length === 0) {
      //  该节点已经被删除
      setFlowType('1');
      form.resetFields();
      setNodeStatus(newDataOther, currentNode, true);
    } else if (clues.length === 1) {
      if (JSON.stringify(clues) != JSON.stringify(currentNodeClues)) {
        // 不是原来编辑的索引值
        setFlowType('1');
        form.resetFields();
        setNodeStatus(newDataOther, currentNode, true);
      }
    } else if (clues.length > 1) {
      // 此为有条件节点的分支
      let remainArr = currentNodeClues
        .filter((val) => val != 'children')
        .map((ele) => Number(ele));
      if (JSON.stringify(clues) != JSON.stringify(remainArr)) {
        // 不是原来编辑的索引值
        setFlowType('1');
        form.resetFields();
        setNodeStatus(newDataOther, currentNode, true);
      }
    }
  };

  // 根据id查找节点所在数据层级的list
  const findIndexList = (data: any[], targetId: string) => {
    let parentNode = {
      children: [...data],
    };
    let mapIndexList: any = [];

    // 查找当前层级是否存在所需id，是：返回下标,否返回0
    const findCurIndex = (parentNode: any) => {
      // 遍历父节点，查看子节点集合是否含有目标id,如有，则停止递归到子节点
      if (parentNode?.children?.length && Array.isArray(parentNode?.children)) {
        parentNode?.children.forEach?.((childNode: any, index: number) => {
          childNode.parentNode = parentNode; // 子节点绑定父节点关系
          if (childNode.id === targetId) {
            findParenIndex(childNode);
            return;
          } else {
            findCurIndex(childNode);
          }
        });
      }
    };

    // 根据当前节点倒推所有父节点的下把
    const findParenIndex = (childNode: any) => {
      if (childNode.parentNode) {
        const childIndex = childNode.parentNode.children.findIndex(
          (child: any) => child.id === childNode.id,
        );

        mapIndexList.unshift(childIndex);
        findParenIndex(childNode.parentNode);
      } else {
        return;
      }
    };

    findCurIndex(parentNode);
    return mapIndexList;
  };

  // 设置代提交人
  const toSetSubmitAgents = (data) => {
    console.log('-----data', data);
    const formatterData =
      Object.entries(data || {}).reduce((acc, [key, arr]) => {
        acc[key] = arr?.map?.((item) => ({
          ...item,
          label: item?.name || item?.label || item?.title,
        }));

        return acc;
      }, {}) || {};
    setSubmitAgents(formatterData || {});
  };
  const removeSubmitAgent = (payload) => {
    const { id, userId } = payload || {};
    const data =
      Object.entries(submitAgents || {}).reduce(
        (acc, [type, arr]) => {
          if (acc.isRemoved) {
            acc.submitAgents[type] = arr;

            return acc;
          }

          acc.submitAgents[type] =
            arr?.filter((item) => {
              if (
                (item?.id && item?.id === id) ||
                (item?.userId && item?.userId === userId)
              ) {
                acc.isRemoved = true;

                return false;
              }

              return true;
            }) || [];

          return acc;
        },
        { isRemoved: false, submitAgents: {} },
      ) || {};

    console.log('----removeSubmitAgent--', data);

    setSubmitAgents(data?.submitAgents || {});
  };
  const changeCanDelegateSubmit = (e) => {
    setIsShowSubmitTip(e);

    if (!e) setSubmitAgents({});
  };

  return (
    <div className={c.processDesign}>
      <MultipleChoice
        cRef={childRef}
        title={flowType == '2' ? '审核人' : '抄送人'}
        initData={currentOptionalData}
        getDataBack={getDataBack}
        memberTitleList={memberTitleList}
      />
      <Modal
        width={541}
        visible={showTipRepeatVisible}
        title={''}
        style={{ height: 239 }}
        closable={false}
        maskClosable={true}
        footer={null}
        onCancel={() => {
          setShowTipRepeatVisible(false);
          setIsFirstLoadModal(false);
        }}
        onOk={() => {
          setShowTipRepeatVisible(false);
          setIsFirstLoadModal(false);
        }}
      >
        <div className={c.repeatTip_img}>
          <img src={repeatPicImg} />
        </div>
      </Modal>
      <TopBanner
        topRef={topBannerRef}
        addFlowVersion={addFlowVersion}
        startFlow={startFlow}
        saveFlow={saveFlow}
        callBackVersionDetails={getVersionDefinitionInfo}
        onlyCallBackAuthList={getAuthlist}
        callBackMemberTitle={getMemberTitleList}
      />

      <div className={c.flowArea}>
        <div className={c.design}>
          <Flow
            setFlowData={setFlowData}
            handleNodeClick={handleNodeClick}
            handleNodeActiveStatus={handleNodeActiveStatus}
            flowData={flowData}
            extData={extData}
          />
        </div>
        <div className={c.settingArea}>
          <Tabs
            defaultActiveKey="1"
            activeKey={activeKey}
            onChange={changeTabs}
          >
            <TabPane tab="节点属性" key="1">
              {flowType == '4' ? (
                <ConditionalForm
                  callBackConData={callBackConData}
                  data={conData}
                  currentNodeClues={currentNodeClues}
                  isLastNode={isLastNode}
                />
              ) : (
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={onFinish}
                  ref={formRef}
                  className={c.baseSetting}
                >
                  <BaseNodeSetting
                    flowType={flowType}
                    showFormList={showFormList}
                    getFieldValue={getFieldValue}
                    setFieldsValue={setFieldsValue}
                    changeValue={changeValue}
                    memberTitleList={memberTitleList}
                    changeCount={() => {
                      setCount((val) => val + 1);
                    }}
                  />
                  {flowType === '2' && (
                    <>
                      <Form.Item
                        label="审批意见"
                        name="requiredComment"
                        labelCol={{ span: 23 }}
                        wrapperCol={{ span: 24 }}
                        className="subTitle"
                      >
                        {/* <Checkbox.Group
                          onChange={(e) => handleChange(e, 'requiredComment')}
                        >
                          <Checkbox value="true" style={{ lineHeight: '32px' }}>
                            必填
                          </Checkbox>
                          <Tooltip
                            placement="topLeft"
                            title="开启后，审批人必须填写意见方可提交审批"
                          >
                            {' '}
                            <IconFont
                              type="icon-shuoming"
                              className={c.tipImg}
                            />
                          </Tooltip>
                        </Checkbox.Group> */}
                        <Radio.Group
                          onChange={(e) => handleChange(e, 'requiredComment')}
                        >
                          <Space direction="vertical">
                            <Radio value="1">
                              必填
                              <Tooltip
                                placement="topLeft"
                                title="开启后，审批人必须填写意见方可提交审批"
                              >
                                {' '}
                                <IconFont
                                  type="icon-shuoming"
                                  className={c.tipImg}
                                />
                              </Tooltip>
                            </Radio>
                            <Radio value="2">非必填</Radio>
                            <Radio value="3">无需填写</Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        label="多人审批时采用的审批方式"
                        name="approvalType"
                        rules={[]}
                        labelCol={{ span: 23 }}
                        wrapperCol={{ span: 24 }}
                        className="subTitle"
                      >
                        <Radio.Group
                          onChange={(e) => handleChange(e, 'approvalType')}
                        >
                          <Space direction="vertical">
                            <Radio value="1">会签（须所有审批人同意）</Radio>
                            <Radio value="2">
                              或签（一名审批人同意或拒绝即可）
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        label="退回重新提交后"
                        name="realBackOriginator"
                        labelCol={{ span: 23 }}
                        wrapperCol={{ span: 24 }}
                        className="subTitle"
                      >
                        <Radio.Group
                          onChange={(e) =>
                            handleChange(e, 'realBackOriginator')
                          }
                        >
                          <Space direction="vertical">
                            <Radio value="y">
                              重新依次审批
                              <Tooltip
                                placement="topLeft"
                                title="若流程为A—>B—>C，C退回至A，则为C—>A—>B—>C，且每个节点都会触发对应消息通知和业务规则"
                              >
                                {' '}
                                <IconFont
                                  type="icon-shuoming"
                                  className={c.tipImg}
                                />
                              </Tooltip>
                            </Radio>
                            <Radio value="n">
                              从当前节点审批
                              <Tooltip
                                placement="topLeft"
                                title="若流程为A—>B—>C，C退回至A，则为C—>A—>C，且过程不再触发消息通知和业务规则"
                              >
                                {' '}
                                <IconFont
                                  type="icon-shuoming"
                                  className={c.tipImg}
                                />
                              </Tooltip>
                            </Radio>
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </>
                  )}

                  {(flowType === '1' || flowType === '2') && (
                    <Form.Item label="表单操作权限" name="auth" rules={[]}>
                      <ul className={c.authTable}>
                        <li className={c.title}>
                          <div className={c.title01}>表单字段</div>
                          <div className={c.title02}>
                            <Radio.Group
                              onChange={(e: any) => onAllChange(e)}
                              value={getFieldValue('formAuth')}
                            >
                              <Radio value="NORMAL"></Radio>
                            </Radio.Group>
                            可编辑
                          </div>
                          <div className={c.title03}>
                            <Radio.Group
                              onChange={(e: any) => onAllChange(e)}
                              value={getFieldValue('formAuth')}
                            >
                              <Radio value="READONLY"></Radio>
                            </Radio.Group>
                            只读
                          </div>
                          <div className={c.title04}>
                            <Radio.Group
                              onChange={(e: any) => onAllChange(e)}
                              value={getFieldValue('formAuth')}
                            >
                              <Radio value="HIDDEN"></Radio>
                            </Radio.Group>
                            隐藏
                          </div>
                        </li>
                        {getFieldValue('authList') &&
                          getFieldValue('authList').map(
                            (item: any, index: number) => {
                              return (
                                <>
                                  <li
                                    key={item.fieldId}
                                    className={c.form_title}
                                  >
                                    <div
                                      className={
                                        c.title01 +
                                        ' ' +
                                        (item?.fieldId?.includes('table')
                                          ? c.hover_tip
                                          : '')
                                      }
                                      onClick={(e: any) => clickTable(e, index)}
                                    >
                                      {item.title}
                                      {(item?.fieldId?.includes('table') ||
                                        item?.fieldId?.includes('bizSuite')) &&
                                        item?.isShow && (
                                          <div className={c.tipImg}>
                                            <img src={lownTipImg} alt="" />
                                          </div>
                                        )}
                                      {(item?.fieldId?.includes('table') ||
                                        item?.fieldId?.includes('bizSuite')) &&
                                        !item?.isShow && (
                                          <div
                                            className={
                                              c.tipImgUp + ' ' + c.tipImg
                                            }
                                          >
                                            <img src={lownTipImg} alt="" />
                                          </div>
                                        )}
                                    </div>
                                    <div className={c.title02}>
                                      <Radio.Group
                                        onChange={(e: any) =>
                                          onChildrenChange(e, index)
                                        }
                                        value={item.fieldBehavior}
                                        disabled={
                                          item.disabled && flowType === '2'
                                        }
                                      >
                                        <Radio value="NORMAL"></Radio>
                                      </Radio.Group>
                                    </div>
                                    <div className={c.title03}>
                                      <Radio.Group
                                        onChange={(e: any) =>
                                          onChildrenChange(e, index)
                                        }
                                        value={item.fieldBehavior}
                                        disabled={
                                          item.disabled && flowType === '2'
                                        }
                                      >
                                        <Radio value="READONLY"></Radio>
                                      </Radio.Group>
                                    </div>
                                    <div className={c.title04}>
                                      <Radio.Group
                                        onChange={(e: any) =>
                                          onChildrenChange(e, index)
                                        }
                                        value={item.fieldBehavior}
                                        disabled={
                                          item.disabled && flowType === '2'
                                        }
                                      >
                                        <Radio value="HIDDEN"></Radio>
                                      </Radio.Group>
                                    </div>
                                  </li>
                                  {(item?.fieldId?.includes('table') ||
                                    item?.fieldId?.includes('bizSuite')) &&
                                    item?.children?.length &&
                                    item?.isShow &&
                                    item.children.map(
                                      (k: any, indexI: number) => {
                                        return (
                                          <li
                                            key={k.fieldId}
                                            className={c.form_title}
                                          >
                                            <div
                                              className={
                                                c.title01 +
                                                ' ' +
                                                c.title01FixPadding
                                              }
                                            >
                                              {k.title}
                                            </div>
                                            <div className={c.title02}>
                                              <Radio.Group
                                                onChange={(e: any) =>
                                                  onChildrenChange(
                                                    e,
                                                    index,
                                                    indexI,
                                                  )
                                                }
                                                value={k.fieldBehavior}
                                                disabled={k.disabled}
                                              >
                                                <Radio value="NORMAL"></Radio>'
                                                '
                                              </Radio.Group>
                                            </div>
                                            <div className={c.title03}>
                                              <Radio.Group
                                                onChange={(e: any) =>
                                                  onChildrenChange(
                                                    e,
                                                    index,
                                                    indexI,
                                                  )
                                                }
                                                value={k.fieldBehavior}
                                                disabled={k.disabled}
                                              >
                                                <Radio value="READONLY"></Radio>
                                                ' '
                                              </Radio.Group>
                                            </div>
                                            <div className={c.title04}>
                                              <Radio.Group
                                                onChange={(e: any) =>
                                                  onChildrenChange(
                                                    e,
                                                    index,
                                                    indexI,
                                                  )
                                                }
                                                value={k.fieldBehavior}
                                                disabled={k.disabled}
                                              >
                                                <Radio value="HIDDEN"></Radio>
                                              </Radio.Group>
                                            </div>
                                          </li>
                                        );
                                      },
                                    )}
                                </>
                              );
                            },
                          )}
                      </ul>
                    </Form.Item>
                  )}
                </Form>
              )}
            </TabPane>
            <TabPane tab="流程属性" key="2">
              <Form
                layout="horizontal"
                form={form1}
                onFinish={onFinish1}
                className={c.property}
                initialValues={processFormValue}
              >
                <Form.Item
                  label={
                    <span>
                      允许发起人撤回
                      <Tooltip
                        placement="topLeft"
                        title="开启后发起人能够撤回未完成的审批单（未完成=最后一个审批人通过/拒绝前）"
                      >
                        {' '}
                        <IconFont type="icon-shuoming" className={c.tipImg} />
                      </Tooltip>
                    </span>
                  }
                  name="isRevokeProcess"
                  colon={false}
                  rules={[]}
                  className={c.textRight}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      重复审批人自动通过
                      <IconFont
                        type="icon-shuoming"
                        className={c.tipImg}
                        onClick={(e) => {
                          setShowTipRepeatVisible(true);
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      />
                    </span>
                  }
                  colon={false}
                  name="isAutoPassWhenRepeated"
                  rules={[]}
                  className={c.textRight}
                  valuePropName="checked"
                >
                  <Switch
                    onChange={(checked) => {
                      setShowRepeatForm(checked);
                      if (isFirstLoadModal && checked) {
                        setShowTipRepeatVisible(true);
                      }
                    }}
                  />
                </Form.Item>
                {showRepeatForm && (
                  <Form.Item
                    label=""
                    name="isAutoPassType"
                    labelCol={{ span: 23 }}
                    wrapperCol={{ span: 24 }}
                    className="thirdTitle"
                  >
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value="2">
                          同一审批人仅在连续出现时, 自动去重
                        </Radio>
                        <Radio value="1">
                          同一审批人在流程中出现多次时, 仅保留第一个
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                )}
                <Form.Item
                  label={
                    <span>
                      允许审批单加签
                      <Tooltip
                        placement="topLeft"
                        title="开启后审批人能够在审批单中增加临时审批人"
                      >
                        {' '}
                        <IconFont type="icon-shuoming" className={c.tipImg} />
                      </Tooltip>
                    </span>
                  }
                  colon={false}
                  name="canAppendTask"
                  rules={[]}
                  className={c.textRight}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  label={<span>审批节点结果通知发起人</span>}
                  colon={false}
                  name="notifyInitiatorWhenPassNode"
                  rules={[]}
                  className={c.textRight}
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
                <Form.Item
                  colon={false}
                  name="canDelegateSubmit"
                  className={c.textRight}
                  valuePropName="checked"
                  label={
                    <span>
                      允许代他人提交
                      <Tooltip
                        placement="topLeft"
                        title="审批单将按照实际申请人的流程进行审批"
                      >
                        {' '}
                        <IconFont type="icon-shuoming" className={c.tipImg} />
                      </Tooltip>
                    </span>
                  }
                >
                  <Switch onChange={changeCanDelegateSubmit} />
                </Form.Item>
                <div>
                  {isShowSubmitTip && (
                    <>
                      <div
                        className={c.submitterContainer}
                        onClick={() => submitterRef?.current?.openModal?.()}
                      >
                        {submitterList?.length ? (
                          <div className={c.list}>
                            {submitterList
                              ?.slice(0, Math.min(submitterList?.length, 6))
                              ?.map((submitter) => (
                                <span className={c.value}>
                                  {submitter?.name}
                                  <CloseOutlined
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      removeSubmitAgent(submitter);
                                    }}
                                    className={c.closeIcon}
                                  />
                                </span>
                              ))}
                          </div>
                        ) : (
                          <div className={c.placeholder}>
                            点击设置代提交人，可设置主管和指定员工
                          </div>
                        )}
                        <DownOutlined className={c.icon} />
                      </div>
                      {!submitterList?.length && (
                        <div className={c.tip}>代提交人必填</div>
                      )}
                    </>
                  )}
                  <MultipleChoice
                    cRef={submitterRef}
                    title="代提交人"
                    initData={submitAgents}
                    tabs={['部门', '人员', '角色']}
                    getDataBack={toSetSubmitAgents}
                  />
                </div>
                <Divider className={c.divider} />
                <Form.Item
                  label="审核人为空时"
                  name="actionFindNotAssignee"
                  rules={[]}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Radio.Group>
                    <Space direction="vertical">
                      <Radio value="1">
                        自动通过{' '}
                        <Tooltip
                          placement="topLeft"
                          title="当审批节点没有负责人的时候，将自动通过"
                        >
                          {' '}
                          <IconFont type="icon-shuoming" className={c.tipImg} />
                        </Tooltip>
                      </Radio>
                      <Radio value="2">
                        自动转交管理员{' '}
                        <Tooltip
                          placement="topLeft"
                          title="当该审批节点没有负责人的时候，将自动转交给管理员"
                        >
                          {' '}
                          <IconFont type="icon-shuoming" className={c.tipImg} />
                        </Tooltip>
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
                <Divider className={c.divider} />

                <Form.Item label="" name="approvalUpdateForm">
                  <ModifyForm />
                </Form.Item>
              </Form>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ProcessDesign;
