/*
 * :file description: 网关
 * :name: \crmd:\progree\low-code-platform\src\pages\generator\process\components\FlowV3\components\ConditionContainerNode.tsx
 * :author: Quan
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-10 17:59:54
 * :last editor: hyw
 * :date last edited: 2022-01-11 19:39:51
 */

import React, { useMemo } from 'react';
import { Popover, message } from 'antd';
import styles from '../index.less';
import {
  COMMON_NODE_TYPE_MAP,
  CONDITION_CONTAINER_NODE,
  CONDITION_NODE,
  DEFAULT_CONFIG,
  INCLUSIVE_CONDITION_NODE,
  INCLUSIVE_CONDITION_CONTAINER_NODE,
  INCLUSIVE_CONDITION_CONTAINER_NODE_END,
} from '../constants';
import _ from 'lodash';
import Generator from './Generator';
import Node from './Node';
import addAudit from '@/images/addAudit.png';
import addCopySend from '@/images/addCopySend.png';
import addBranchImg from '@/images/addBranch.png';
import IconFont from '@/components/IconFont';
import { getUrlParam } from '@/utils';
import { useGray } from '@/pages/generator/relation/hooks';
interface IProps {
  data: any;
  currentBranch: any;
  currentIndex: number;
  endNodeId?: string;
  flowData: any; //流程数据
  setFlowData: (value: any) => void;
  handleNodeClick: (value: any) => void;
  handleNodeActiveStatus: (value: any) => void;
  extData?: any;
}

function InclusiveConditionContainerNode(props: IProps) {
  const {
    data,
    currentBranch,
    currentIndex,
    endNodeId,
    flowData,
    setFlowData,
    handleNodeClick,
    handleNodeActiveStatus,
    extData = {},
  } = props;

  // 隐藏并行分支判断
  const hiddenInclusiveConditionNode = getUrlParam(
    'hiddenInclusiveConditionNode',
  );

  const { versionValue, isShowInclusiveConditionNode } = extData;

  const nextNode = useMemo(() => {
    const node = currentBranch[currentIndex + 1] || null;
    return node;
  }, [currentBranch]);

  const isEndNode = useMemo(() => {
    return data?.id === endNodeId;
  }, [currentBranch]);

  const branches = useMemo(() => {
    return data.children;
  }, [data]);

  const getBranchStartNode = (branch: any) => {
    const startNode = branch.children?.[0];
    return startNode;
  };

  const content = () => (
    <div className={styles.flowType}>
      <div
        onClick={() => {
          addFlowNode(COMMON_NODE_TYPE_MAP.APPROVAL_NODE);
        }}
        className={styles.item}
      >
        <div className={styles.auditDiv}>
          <img src={addAudit} className={styles.img} />
        </div>
        审核人
      </div>
      <div
        onClick={() => {
          addFlowNode(COMMON_NODE_TYPE_MAP.CARBON_NODE);
        }}
        className={styles.item}
      >
        <div className={styles.copySendDiv}>
          <img src={addCopySend} className={styles.img} />
        </div>
        抄送通知
      </div>
      <div
        onClick={() => {
          addFlowNode(CONDITION_CONTAINER_NODE);
        }}
        className={styles.item}
      >
        <div className={styles.branchDiv}>
          <img src={addBranchImg} className={styles.img} />
        </div>
        条件分支
      </div>
      {([2, 3].includes(Number(versionValue)) ||
        isShowInclusiveConditionNode) && (
        <div
          onClick={() => {
            addFlowNode(INCLUSIVE_CONDITION_CONTAINER_NODE);
          }}
          className={styles.item}
        >
          <div className={styles.branchDiv}>
            <img src={addBranchImg} className={styles.img} />
          </div>
          并行分支
        </div>
      )}
    </div>
  );

  // 添加节点方法
  const addFlowNode = (type: string) => {
    const isInclusiveConditionContainerNode =
      type === 'InclusiveConditionContainerNode';
    const defaultConfig: any = DEFAULT_CONFIG;
    const key = 'children';
    let clues = findIndexList(flowData.toJS(), data.id);
    let newFlowData = _.cloneDeep(flowData);
    const nodeDefaultData = defaultConfig()[type];

    // 判断当前分支currentBranch是否一级分支上，若是，则直接覆盖即可,若不是，则更新流程数据上对应的分支数据
    if (clues.length === 1) {
      const newBranchData = [...currentBranch];
      newBranchData.splice(currentIndex + 1, 0, nodeDefaultData);
      if (isInclusiveConditionContainerNode) {
        newBranchData.splice(
          currentIndex + 2,
          0,
          defaultConfig()[INCLUSIVE_CONDITION_CONTAINER_NODE_END],
        );
      }
      setFlowData(newBranchData);
    } else if (clues.length > 1) {
      clues = mapClue(clues, key);
      clues.pop();
      const newBranchData = [...currentBranch];
      newBranchData.splice(currentIndex + 1, 0, nodeDefaultData);
      if (isInclusiveConditionContainerNode) {
        newBranchData.splice(
          currentIndex + 2,
          0,
          defaultConfig()[INCLUSIVE_CONDITION_CONTAINER_NODE_END],
        );
      }
      newFlowData = newFlowData.setIn(clues, newBranchData).toJS();
      setFlowData(newFlowData);
    }
  };

  // 添加条件方法
  const addBranch = () => {
    const defaultConfig: any = DEFAULT_CONFIG;
    const key = 'children';
    let clues = findIndexList(flowData.toJS(), data.id);
    let newFlowData = _.cloneDeep(flowData);
    const length = data.children.length;
    if (length >= 10) {
      message.warning('最多添加10个条件');
      return;
    }
    const nodeConfig = {
      title: `条件${length + 1}`,
      props: {
        description: null,
        isDefault: null,
        name: `条件${length + 1}`,
        props: {
          name: `条件${length + 1}`,
          nodeName: 'InclusiveConditionNode',
        },
        nodeName: 'InclusiveConditionNode',
        priority: `${length + 1}`,
        conditions: [],
      },
    };
    const nodeDefaultData = defaultConfig(nodeConfig)[INCLUSIVE_CONDITION_NODE];
    let newContainerData = [];
    if (data?.children?.length >= 2) {
      newContainerData = [
        ...data.children?.slice(0, -1),
        nodeDefaultData,
        data.children[data.children?.length - 1],
      ];
    } else {
      newContainerData = [...data.children, nodeDefaultData];
    }
    newContainerData?.forEach((item, index) => {
      if (index === newContainerData?.length - 1) {
        item.props.name = '默认条件';
      } else {
        item.props.name = '条件' + (index + 1);
      }
    });
    if (clues.length === 1) {
      newFlowData = newFlowData.setIn(clues, {
        ...data,
        children: newContainerData,
      });
    } else if (clues.length > 1) {
      clues = mapClue(clues, key);
      clues.push(key);
      newFlowData = newFlowData.setIn(clues, newContainerData);
    }

    setFlowData(newFlowData.toJS());
  };

  // 根据id查找节点所在数据层级的list
  const findIndexList = (data: any[], targetId: string) => {
    let parentNode = {
      children: [...data],
    };
    let mapIndexList: any = [];

    // 查找当前层级是否存在所需id，是：返回下标,否返回0
    const findCurIndex = (parentNode: any) => {
      // 遍历父节点，查看子节点集合是否含有目标id,如有，则停止递归到子节点
      if (parentNode?.children?.length && Array.isArray(parentNode?.children)) {
        parentNode?.children?.forEach((childNode: any, index: number) => {
          childNode.parentNode = parentNode; // 子节点绑定父节点关系
          if (childNode.id === targetId) {
            findParentIndex(childNode);
            return;
          } else {
            findCurIndex(childNode);
          }
        });
      }
    };

    // 根据当前节点倒推所有父节点的下把
    const findParentIndex = (childNode: any) => {
      if (childNode.parentNode) {
        const childIndex = childNode.parentNode.children.findIndex(
          (child: any) => child.id === childNode.id,
        );

        mapIndexList.unshift(childIndex);
        findParentIndex(childNode.parentNode);
      } else {
        return;
      }
    };

    findCurIndex(parentNode);

    return mapIndexList;
  };

  const renderCnOp = () => {
    return (
      <div
        className={`${styles.optionWrapperCn} ${
          data.componentName === 'InclusiveConditionContainerNode'
            ? styles.optionWrapperCnFixWidth
            : ''
        }`}
        onClick={addBranch}
      >
        {data.componentName === 'InclusiveConditionContainerNode'
          ? '添加并行分支'
          : '添加条件'}
      </div>
    );
  };

  const renderCnOpJoin = () => {
    return (
      <div
        className={`${styles.optionWrapperCn} ${
          data.componentName === 'InclusiveConditionContainerNode'
            ? styles.optionWrapperCnFixWidth
            : ''
        } ${data?.props?.gatewayDirection === 'join' ? styles.addBtnSpe : ''}`}
      >
        并行分支汇聚节点
      </div>
    );
  };

  const renderOp = useMemo(
    () =>
      (nextNode: boolean = true, isEndNode: boolean = false) => {
        return (
          <React.Fragment>
            <div className={styles.addBtn}>
              {data?.componentName === 'InclusiveConditionContainerNode' &&
              data?.props?.gatewayDirection === 'join' ? (
                <div
                  className={`${styles.optionWrapper} ${
                    !nextNode ? styles.notNextNode : ''
                  } ${isEndNode ? styles.isEndNode : ''} `}
                >
                  <Popover
                    placement="bottom"
                    title={null}
                    content={content()}
                    trigger="click"
                    style={{
                      padding: 0,
                    }}
                    overlayClassName={styles.popover}
                  >
                    {/* <img src={add} alt="" className={styles.addIcon} /> */}
                    <div className={styles.addIcon}>
                      <IconFont type="icon-tianjia1"></IconFont>
                    </div>
                  </Popover>
                </div>
              ) : null}
            </div>
            {isEndNode && <div className={styles.endNode}>流程结束</div>}
          </React.Fragment>
        );
      },
    [currentBranch],
  );

  // 获取修改数据的indexList
  const mapClue = (data: any[], key: string) => {
    const newData = data.join(',' + key + ',');

    return newData.split(',');
  };

  return (
    <React.Fragment>
      {data?.componentName === 'InclusiveConditionContainerNode' &&
      data?.props?.gatewayDirection === 'join' ? (
        <div
          className={`${styles.containerBox} ${styles.conditionContainerNode}`}
        >
          {renderCnOpJoin()}
        </div>
      ) : (
        <div
          className={`${styles.containerBox} ${styles.conditionContainerNode}`}
        >
          {renderCnOp()}
          <div className={styles.flexWrapper}>
            {branches.map((branch: any, index: number) => (
              <div key={branch.id} className={styles.branch}>
                {/* 条件节点start */}
                <Node
                  branchIndex={index}
                  data={branch}
                  currentBranch={branch}
                  currentIndex={0}
                  flowData={flowData}
                  setFlowData={setFlowData}
                  handleNodeClick={handleNodeClick}
                  branchesLength={branches.length}
                  handleNodeActiveStatus={handleNodeActiveStatus}
                  extData={{
                    ...extData,
                    ...data?.props,
                  }}
                ></Node>
                {/* 条件节点end */}

                {/* 分支下节点start */}
                {getBranchStartNode(branch) && (
                  <Generator
                    currentNode={getBranchStartNode(branch)}
                    currentBranch={branch.children}
                    currentIndex={0}
                    flowData={flowData}
                    setFlowData={setFlowData}
                    handleNodeClick={handleNodeClick}
                    handleNodeActiveStatus={handleNodeActiveStatus}
                    extData={extData}
                  ></Generator>
                )}
                {/* 分支下节点end */}

                {/* 分支的中轴线start */}
                <div className={styles.branchLine}></div>
                {/* 分支的中轴线end */}
                {/* 下边线框start */}
                <div className={styles.topLeft}></div>
                <div className={styles.topRight}></div>
                <div className={styles.bottomLeft}></div>
                <div className={styles.bottomRight}></div>
                {/* 下边线框end*/}
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={styles.endingBox}>{renderOp(!!nextNode, isEndNode)}</div>
      {nextNode && (
        <Generator
          currentNode={nextNode}
          currentBranch={currentBranch}
          currentIndex={currentIndex + 1}
          endNodeId={endNodeId}
          flowData={flowData}
          setFlowData={setFlowData}
          handleNodeClick={handleNodeClick}
          handleNodeActiveStatus={handleNodeActiveStatus}
          extData={extData}
        ></Generator>
      )}
    </React.Fragment>
  );
}

export default InclusiveConditionContainerNode;
