/*
 * @Author: “wu.shangru” “roywu@tungee.com”
 * @Date: 2023-04-23 19:00:08
 * @LastEditors: wu.shangru roywu@tungee.com
 * @LastEditTime: 2023-10-20 09:13:07
 * @FilePath: \low-code-platform\tg-renderer\packages\form-render\src\widgets\BillingSetting\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import React, { useEffect, useMemo, useState, FC } from 'react';
import { Radio, Tooltip, Modal } from 'antd';
import { WidgetProps } from 'typings';
import c from './index.less';
import BillingSettingItem from './components/BillingSettingItem';
import IconFont from '~@/packages/table-render/src/components/IconFont';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import { getUrlParam } from '@/utils';
import { useGray } from '@/pages/generator/relation/hooks';

const BillingSetting: FC<WidgetProps> = (props) => {
  const formBizsuiteRelatedmode = useGray('form_bizsuite_relatedmode');
  const { readOnly, value, addons } = props;
  const { flatten, selected, onFlattenChange } = useStore();
  const [relatedMode, setRelatedMode] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const bizType = getUrlParam('bizType');
  const schema = flatten?.[selected]?.schema ?? {};
  const properties = schema?.properties;
  const formData = addons.getValues();

  const onChangeMode = (e) => {
    setModalOpen(true);
  };

  const settingList = Object.values(formData?.properties || {}) || [];

  useEffect(() => {
    setRelatedMode(schema?.relatedMode || '');
  }, [selected, schema?.relatedMode]);

  return (
    <div className={c.container}>
      {formBizsuiteRelatedmode && (
        <section>
          <div className={c.label}>
            {bizType === 'crm_payment' ? '回款' : '开票'}模式:
          </div>
        </section>
      )}
      {formBizsuiteRelatedmode && (
        <Radio.Group
          className={c.mode}
          onChange={(e) => onChangeMode(e)}
          value={relatedMode}
        >
          <Radio value={''} disabled>
            <span className={c.modeContent}>
              仅支持单笔合同/订单{bizType === 'crm_payment' ? '回款' : '开票'}
            </span>
            <Tooltip
              placement="top"
              title={`选择关联表单模式：1笔${
                bizType === 'crm_payment' ? '回款' : '开票'
              }可关联1笔合同和1笔订单`}
            >
              <span className={c.popoverIcon}>
                <IconFont type="icon-shuoming"></IconFont>
              </span>
            </Tooltip>
          </Radio>
          <Radio value={'multiple'} className={c.modeType}>
            <span className={c.modeContent}>
              支持多笔合同/订单合并{bizType === 'crm_payment' ? '回款' : '开票'}
              {relatedMode === 'multiple' &&
                !schema?.extendProperties?.isNewMode && <b>(待生效)</b>}
            </span>
            <Tooltip
              placement="top"
              title={`选择明细表格模式：1笔${
                bizType === 'crm_payment' ? '回款' : '开票'
              }可关联1~n笔合同和1~n笔订单`}
            >
              <span className={c.popoverIcon}>
                <IconFont type="icon-shuoming"></IconFont>
              </span>
            </Tooltip>
          </Radio>
        </Radio.Group>
      )}
      {settingList.map((item) => {
        // 处理模式的样式异常
        if (typeof item !== 'object') {
          return null;
        }
        return (
          <BillingSettingItem key={item.id} schema={item} addons={addons} />
        );
      })}
      <Modal
        title={
          bizType === 'crm_payment' ? '回款模式变更确认' : '开票模式变更确认'
        }
        open={modalOpen}
        onOk={() => {
          setRelatedMode('multiple');
          schema.relatedMode = 'multiple';
          onFlattenChange(flatten);
          setModalOpen(false);
        }}
        onCancel={() => {
          setModalOpen(false);
        }}
      >
        <p>
          <span className="tip_content_red">
            变更为“支持多笔合同/订单合并
            {bizType === 'crm_payment' ? '回款' : '开票'}
            ”模式后，将无法再切换为“仅支持单笔合同/订单
            {bizType === 'crm_payment' ? '回款' : '开票'}”模式。
          </span>
          确认变更后历史数据将于次日7点前自动迁移至多笔模式，迁移完成后生效“多笔模式”。请确认是否变更
        </p>
      </Modal>
    </div>
  );
};

export default BillingSetting;
