import React from 'react';
import { Switch, Tooltip, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Styles from './index.less';
import IconFont from '@/components/IconFont';

const { confirm } = Modal;

const Enable = ({ value, ...rest }) => {
  const { onChange, hiddenEditableFreeze = false } = rest || {};
  const { hiddenEditableFreeze: hiddenEditableFreezeInSchema } = rest?.schema;

  const handleChange = (checked: boolean, e: Event) => {
    if (!checked) {
      confirm({
        title: '确定要停用该字段吗',
        okText: '仍然停用',
        cancelText: '取消',
        content:
          '停用后，所有用户都将无法看到该字段及其数据，若停用的字段被其他表单关联引用，也将对其他表单有影响，请谨慎操作',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          onChange(!checked);
        },
        onCancel() {
          e.preventDefault();
          e.stopPropagation();
        },
      });
    } else {
      onChange(!checked);
    }
  };
  return (
    <div className={Styles.wrapper}>
      <Tooltip
        placement="top"
        title={'启用开关若关闭，该字段将不会在前台任意位置显示'}
      >
        <div className={Styles.popoverIcon}>
          <IconFont type="icon-shuoming"></IconFont>
        </div>
      </Tooltip>
      {hiddenEditableFreeze || hiddenEditableFreezeInSchema ? (
        <Tooltip placement="top" title={'已设置为查重字段，不支持停用'}>
          <Switch
            checked={!value}
            {...rest}
            disabled={hiddenEditableFreeze || hiddenEditableFreezeInSchema}
            onChange={handleChange}
          />
        </Tooltip>
      ) : (
        <Switch
          checked={!value}
          {...rest}
          disabled={hiddenEditableFreeze || hiddenEditableFreezeInSchema}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

export default Enable;
