import IconFont from '@/components/IconFont';
import { Button } from 'antd';
import React from 'react';
import c from './index.less';

const RelatesInfo: React.FC = () => {
  return (
    <div>
      <div className={c.item}>
        <IconFont type="icon-add1-line" />
        增加联系人
      </div>
    </div>
  );
};

export default RelatesInfo;
