/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\TableListInstance\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-29 15:48:32
 * :last editor: hyw
 * :date last edited: 2022-04-02 14:52:52
 */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Core from 'form-render/es/form-render-core/src/core';
import { Typography, Table, Popconfirm, Space, Button } from 'antd';
import { useStore } from 'form-render/es/form-render-core/src/hooks';
import c from './index.less';
import IconFont from '@/components/IconFont';
import { FC } from 'react';
import { IObj, WidgetProps } from 'typings';
import { customRender, customJP } from './renderCol';

const { Text, Link } = Typography;
const FIELD_LENGTH = 170;
const NUMBER_WIDGET = ['number', 'money'];

const defaultColumn = {
  title: '',
  dataIndex: 'index',
  width: '54px',
  align: 'center',
  render: (_text, _record, index: number) => `${index + 1}`,
};

const TableList: FC<WidgetProps> = (prop) => {
  const {
    // displayList = [{}],
    dataIndex = [],
    children = [],
    onChange,
    schema,
    value,
  } = prop;

  const displayList = prop.value || [{}];
  const { flatten } = useStore();
  const { props = {}, itemProps = {} } = schema;
  const { buttons, ...columnProps } = itemProps;
  const { pagination = {}, ...rest } = props;

  const paginationConfig = pagination && {
    size: 'small',
    hideOnSinglePage: true,
    ...pagination,
  };

  const dataSource = displayList?.map((_item: typeof IObj, idx: number) => {
    return { index: idx, currentRecord: _item };
  });

  const addItem = () => {
    const newList = [...displayList, {}];
    const newIndex = newList.length - 1;
    onChange(newList);
    return newIndex;
  };

  const copyItem = (idx: number) => {
    const newItem = displayList[idx];
    const newList = [
      ...displayList.slice(0, idx),
      newItem,
      ...displayList.slice(idx),
    ];
    onChange(customJP(JSON.stringify(newList)));
  };

  const deleteItem = (idx: number) => {
    const newList = displayList.filter((_item, kdx: number) => kdx !== idx);
    onChange(newList);
  };

  const TableHeader = ({ schema }) => {
    const filedList = Object.keys(schema?.fields || {}) || [];
    return (
      <div className={c.table_list_header}>
        {schema.widget === 'relation' && filedList.length > 0
          ? filedList.map((child) => (
              <div
                className={c.sub_link_title}
                title={schema.fields[child].title}
              >
                {schema.fields[child].title}
              </div>
            ))
          : schema.widget === 'textNote'
          ? '说明文字'
          : schema.title}
      </div>
    );
  };

  const columns = children?.props?.children?.map((child) => {
    const item = flatten[child];
    const schema = (item && item.schema) || {};

    return {
      dataIndex: child,
      title: schema.required ? (
        <>
          <span className="fr-label-required"> *</span>
          <span>
            <TableHeader schema={schema}></TableHeader>
          </span>
        </>
      ) : (
        <TableHeader schema={schema}></TableHeader>
      ),
      width: FIELD_LENGTH,
      render: (_value, record, index: number) => {
        // Check: record.index 似乎是antd自己会给的，不错哦
        const childIndex = [...dataIndex, record.index];
        let params = record.currentRecord;

        if (props.readOnly) {
          return customRender(schema['widget'], params[schema.id]);
        }
        return (
          <div className={c.cell}>
            <Core
              hideTitle={true}
              displayType="inline"
              key={index.toString()}
              id={child}
              dataIndex={childIndex}
            />
          </div>
        );
      },
      ...columnProps,
    };
  });

  columns?.unshift(defaultColumn);

  if (
    (!props.hideDelete ||
      !props.hideAdd ||
      !props.hideCopy ||
      !props.hideMove) &&
    !props.readOnly
  ) {
    columns?.push({
      title: '操作',
      key: '$action',
      fixed: 'right',
      width: 120,
      render: (value, record, idx: number) => {
        const onlyOneRow = displayList.length <= 1;
        return (
          <Space>
            {!props.hideAdd && !props.hideCopy && (
              <a onClick={() => copyItem(idx)}>复制</a>
            )}
            {!props.hideDelete && (
              <Popconfirm
                title="确定删除?"
                onConfirm={() => deleteItem(idx)}
                okText="确定"
                cancelText="取消"
              >
                <Link disabled={onlyOneRow}>删除</Link>
              </Popconfirm>
            )}
          </Space>
        );
      },
    });
  }

  return (
    <div className={c.container}>
      {children?.props?.children.length > 0 ? (
        <>
          <Table
            className={c.table}
            scroll={{ x: 'max-content' }}
            columns={columns}
            dataSource={dataSource}
            rowKey="index"
            size="small"
            pagination={paginationConfig}
            bordered
            summary={(a, b) => {
              const computeTotal = (tableData, column) => {
                const result = tableData?.reduce?.(
                  (total: number, cur: typeof IObj) => {
                    return (
                      total +
                      (typeof cur[column] === 'number'
                        ? Number(cur[column])
                        : 0)
                    );
                  },
                  0,
                );
                return result || 0;
              };

              const hasNumberWidget = children?.props.children.find(
                (item: string) =>
                  NUMBER_WIDGET.includes(flatten[item]?.schema?.widget) &&
                  flatten[item].schema?.total,
              );

              return (
                <>
                  {hasNumberWidget && (
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={-1}>总计</Table.Summary.Cell>
                      {children?.props.children?.map((child, index: number) => {
                        const item = flatten[child];
                        const schema = (item && item.schema) || {};
                        const { parentId } = children.props;
                        const column = child.replace(parentId + '.', '');
                        const lastChild =
                          index === children.props.children.length - 1;
                        const isNumberTypeWidget = NUMBER_WIDGET.includes(
                          schema.widget,
                        );

                        return (
                          <Table.Summary.Cell
                            index={index}
                            key={child}
                            colSpan={lastChild ? 2 : 1}
                          >
                            {isNumberTypeWidget && schema.total ? (
                              <Text>{computeTotal(prop.value, column)}</Text>
                            ) : null}
                          </Table.Summary.Cell>
                        );
                      })}
                    </Table.Summary.Row>
                  )}
                </>
              );
            }}
            {...rest}
          />
          <div className={c.add}>
            {!props.hideAdd && !props.readOnly && (
              <div className={c.add_button} onClick={addItem}>
                <IconFont
                  className={c.add_icon}
                  type="icon-tianjia1"
                ></IconFont>{' '}
                {schema.actionName}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className={c.subform_none_tip}>请添加字段</div>
      )}
    </div>
  );
};

export default TableList;
