/*
 * @Description:
 * @FilePath: \low-code-platform\tg-renderer\packages\form-render\src\components\Comment\CommentTab.tsx
 * author: 名字
 * copyright: (c) 2021, Tungee
 * @Date: 2021-12-10 11:22:38
 * @LastEditors: 段文君
 * @LastEditTime: 2022-01-10 21:29:05
 */
import React, { useState, useEffect } from 'react';
import c from './index.less';
import { Modal, Space, message, Empty } from 'antd';
import Xss from 'xss';
import { getHashParam } from '@/utils';
import Avatar from '../Avatar';

interface ICommentList {
  id: string;
  commentator: string;
  commentatorName: string;
  avatar: string;
  comment: string;
  createDate: string;
}

const Comment: React.FC<any> = (props) => {
  const { isShowComment, openShowComment, instanceId } = props;

  const [showCommentBtn, setShowCommentBtn] = useState('');

  const [list, setCommentList] = useState<ICommentList[]>([]);

  const formCode = getHashParam('formCode');
  const urlInstanceId = getHashParam('instanceId');

  useEffect(() => {
    getCommentList();
  }, [isShowComment]);

  const getCommentList = async () => {
    const formData =
      sessionStorage.getItem(`${window.__corpId}_tungee_form`) ?? '{}';
    let form;
    try {
      form = JSON.parse(formData);
    } catch (e) {
      console.log(e, '解析发生错误');
    }
    const res = await window.__getCommentList({
      corpId: window.__corpId,
      formCode: form?.bizId ?? formCode,
      formInstanceId: instanceId ?? urlInstanceId,
    });
    setCommentList(res ?? []);
  };

  const deleteCommentData = async (id: string) => {
    Modal.confirm({
      content: '确认删除',
      async onOk() {
        await window?.__deleteComment({ id });
        message.success('删除成功!');
        getCommentList();
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const convertToHtml = (text: string) => {
    const reg = /\([A-Za-z\d\-]+\)/;
    let t = text;
    let ret = t.match(reg);
    let a = 100;
    while (ret && a--) {
      const matched = ret[0];
      const index = t.indexOf(matched);
      if (index < 0) {
        continue;
      }
      const sub = t.substr(0, index);
      if (sub.substr(sub.length - 1, 1) !== ']') {
        continue;
      }
      const fristBracket = sub.indexOf('[');
      if (fristBracket < 0) {
        continue;
      }
      const userName = sub.substr(
        fristBracket + 1,
        sub.length - 2 - fristBracket,
      );
      t = t.replace(
        '[' + userName + ']' + matched,
        `<input type="button" value="@${userName}" />`,
      );
      ret = t.match(reg);
    }
    return t;
  };

  const sendComment = (replyData: any) => {
    openShowComment(replyData);
  };

  return (
    <div className={c.comment_content}>
      <div className={c.comment_list}>
        {list.length > 0 ? (
          list.map((item) => {
            return (
              <div
                key={item.id}
                className={c.comment_item}
                onMouseEnter={() => {
                  setShowCommentBtn(item.id);
                }}
                onMouseLeave={() => {
                  setShowCommentBtn('');
                }}
              >
                <Avatar
                  src={item.avatar}
                  name={item.commentatorName}
                  className={c.avatar}
                />
                <div className={c.middle}>
                  <div className={c.name}>
                    <div className={c.creator_name}>{item.commentatorName}</div>
                    <Space direction="horizontal">
                      {showCommentBtn === item.id ? (
                        <>
                          <span
                            className={c.reply}
                            onClick={() => sendComment(item)}
                          >
                            回复
                          </span>
                          <span
                            className={c.delete}
                            onClick={() => deleteCommentData(item.id)}
                          >
                            删除
                          </span>
                        </>
                      ) : null}
                    </Space>
                  </div>
                  <div
                    className={c.comment_text}
                    dangerouslySetInnerHTML={{
                      __html: convertToHtml(Xss(item.comment || '')),
                    }}
                  />
                  <div className={c.date}>{item.createDate}</div>
                </div>
              </div>
            );
          })
        ) : (
          <Empty description="暂无评论" />
        )}
      </div>
    </div>
  );
};

export default Comment;
