/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\RelationSelector\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-30 13:47:20
 * :last editor: 黄艺
 * :date last edited: 2022-03-16 14:29:57
 */
import { FC } from 'react';
import TableListSelector from './components/TableListSelector';
import BaseSelector from './components/BaseSelector';
import './index.less';
import { WidgetProps } from 'typings';

const RelationSelector: FC<WidgetProps> = (props) => {
  console.log('🚀 ~ file: index.tsx ~ line 17 ~ props', props);
  const { schema } = props;
  return (
    <>
      {schema?.parentWidget === 'table' ? (
        <TableListSelector {...props} />
      ) : (
        <BaseSelector {...props} />
      )}
    </>
  );
};

export default RelationSelector;
