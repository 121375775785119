/*
 * :file description:
 * :name: \ui-renderd:\progree\low-code-platform\tg-renderer\packages\form-render\src\widgets\DefaultValueSelector\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-03 11:04:37
 * :last editor: hyw
 * :date last edited: 2022-08-29 11:08:11
 */

import { FC, useEffect, useState } from 'react';
import React, { Select, Space, Popover } from 'antd';
import { WidgetProps } from 'typings';
import { useLocation } from 'umi';

import IconFont from '@/components/IconFont';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import { transformProps, useDebounce } from '@/components/FormGenerator/utils';

import c from './index.less';

const supportBizType = ['crm_payment', 'crm_invoice_apply'];

const { Option } = Select;
type ISelectEnum = { label: string; value: string };

const clientRelationId = [
  'relation_K2U5O2WK',
  'relation_1T4IDIW5DIXS0',
  'relation_1RZO0GHFGO740',
  'relation_MbEOt4rct7zi',
  'relation_NVDI5UD3JPS0',
  'relation_projectCustomer',
];
const clueRelationId = ['relation_followRecordClue'];

const CooperatorDefaultValue: FC<WidgetProps> = (props) => {
  const { flatten, widgets, displaySchema } = useStore();
  const {
    placeholder,
    enum: DefaultEnum,
    enumNames: newEnumNames,
    hideupper,
    schemaName,
  } = props?.schema || {};
  const { formData, setValues, getValues } = props?.addons;
  const { schema } = flatten[formData.$id] || {};
  const Widget = widgets[flatten?.[formData.$id]?.schema.widget] || null;
  const [typeValue, setTypeValue] = useState<string>();
  const [extendValue, setExtendValue] = useState<ISelectEnum>();
  const { valueBasisTypeEditableFreeze } = formData || {};

  const location = useLocation();
  const { bizType } = location?.query || {};

  console.log(props, 'CooperatorDefaultValue');
  // 回填
  useEffect(() => {
    console.log(formData, '--formData');
    if (formData && schemaName === 'valueBasisType') {
      setTypeValue(formData?.valueBasisType || '');
      // 为了兼容历史数据  优先取valueBasisInherits 并转化成 valueBasisInherit
      if (formData?.valueBasisInherits?.length) {
        const option = {
          label: formData?.valueBasisInherits?.map((i) => i.label)?.join('/'),
          value: formData?.valueBasisInherits?.map((i) => i.value)?.join('/'),
        };
        setExtendValue(option);
      } else {
        setExtendValue(formData?.valueBasisInherit);
      }
    } else if (formData && schemaName === 'default') {
      setTypeValue(formData?.defaultType || '');
      setExtendValue(formData?.defaultInherit);
    }
  }, []);

  const onChange = useDebounce((e: string) => {
    setTypeValue(e);
    const lastValues = getValues();
    switch (e) {
      case 'valueBasisCreator':
        setValues({
          ...lastValues,
          valueBasisInherit: null,
          valueBasisType: e,
          defaultType: null,
          default: [],
          defaultInherit: null,
          collaborativeManagement: null,
          fieldStatus: ['readOnly'],
          required: false,
        });
        break;
      case 'valueBasisInherit':
        setExtendValue(getOptions()?.[0]);
        setValues({
          ...lastValues,
          valueBasisType: e,
          valueBasisInherit: getOptions()?.[0],
          fieldStatus: ['readOnly'],
          defaultInherit: null,
          default: [],
          required: false,
          // 设置负责人为空时，以下人员可管理该表单  默认值为创建人
          collaborativeManagement: {
            dynamicPerson: [
              {
                name: '创建人',
                id: 'startUserId',
                checked: true,
                currentType: 4,
                type: 'initiator',
              },
            ],
          },
        });
        break;
      case 'valueBasisCustom':
        setValues({
          ...lastValues,
          valueBasisInherit: null,
          valueBasisType: e,
          defaultType: 'defaultCreator',
          fieldStatus: ['default'],
          // 设置负责人为空时，以下人员可管理该表单  默认值为创建人
          collaborativeManagement: {
            dynamicPerson: [
              {
                name: '创建人',
                id: 'startUserId',
                checked: true,
                currentType: 4,
                type: 'initiator',
              },
            ],
          },
        });
        break;
      case 'defaultCreator':
        setValues({
          ...lastValues,
          defaultInherit: null,
          defaultType: e,
          default: [],
        });
        break;
      case 'defaultInherit':
        setExtendValue(getOptions()?.[0]);
        setValues({
          ...lastValues,
          defaultType: e,
          defaultInherit: getOptions()?.[0],
          default: [],
        });
        break;
      case 'custom':
        setValues({
          ...lastValues,
          defaultInherit: null,
          defaultType: e,
        });
        break;
    }
  }, 100);

  const onExtendChange = useDebounce((value: ISelectEnum) => {
    setExtendValue(value);
    const lastValues = getValues();
    console.log(typeValue, '--typeValue');
    if (typeValue === 'valueBasisInherit') {
      // 兼容历史数据
      // 有valueBasisInherits时 新版本 更新valueBasisInherits
      // 没valueBasisInherits时 旧版本 更新valueBasisInherit
      if (
        lastValues?.valueBasisInherits?.length ||
        value?.value?.includes('/')
      ) {
        let arr = [];
        if (value?.value?.includes('/')) {
          const labelrArr = value?.label?.split('/');
          const valueArr = value?.value?.split('/');
          valueArr.forEach((item, index) => {
            arr.push({ label: labelrArr[index], value: valueArr[index] });
          });
        } else {
          arr.push(value);
        }
        setValues({
          ...lastValues,
          valueBasisInherits: arr,
        });
      } else {
        setValues({
          ...lastValues,
          valueBasisInherit: value,
        });
      }
    } else {
      setValues({
        ...lastValues,
        defaultInherit: value,
      });
    }
  }, 100);

  const usefulWidgetProps = transformProps({
    value: props.value || schema?.default,
    placeholder: schema?.placeholder,
    schema,
    maxLength: props?.maxLength,
    format: schema?.format,
    onChange: (e: unknown) => {
      props.onChange(e);
    },
    ...flatten[formData.$id]?.schema['props'],
    hideupper: hideupper,
  });

  const getOptions = () => {
    const widgetObj = displaySchema?.properties;
    if (!widgetObj) return [];
    const returnData = Object.entries(widgetObj).map(([prop, propValue]) => {
      // dataSource?.target?.source === 4 oa关联表单需要过滤   bizSuite_127KIMRJZSK00回款表定制套件
      if (
        propValue?.dataSource?.target?.source !== 4 &&
        (propValue?.widget === 'relation' ||
          (supportBizType.includes(bizType) &&
            propValue?.id?.includes('bizSuite')))
      ) {
        return {
          label: propValue.title ?? '说明文字',
          value: prop,
        };
      }
    });
    // 需要构造 线索/客户类型
    // 只有当 两者都存在时才构造
    const clientDataIndex = returnData.findIndex((i) =>
      clientRelationId?.includes(i?.value),
    );
    const clueDataIndex = returnData.findIndex((i) =>
      clueRelationId?.includes(i?.value),
    );
    if (clientDataIndex > -1 && clueDataIndex > -1) {
      returnData.push({
        label:
          returnData?.[clientDataIndex]?.label +
          '/' +
          returnData?.[clueDataIndex]?.label,
        value:
          returnData?.[clientDataIndex]?.value +
          '/' +
          returnData?.[clueDataIndex]?.value,
      });
      // returnData.splice(clientDataIndex, 1, undefined);
      returnData.splice(clueDataIndex, 1, undefined);
    }

    return returnData.filter((val) => val);
  };

  const renderDom = (type: string) => {
    let dom;
    switch (type) {
      case 'valueBasisInherit':
      case 'defaultInherit':
        dom = (
          <Select
            style={{ width: '100%' }}
            placeholder={placeholder}
            optionFilterProp="children"
            // defaultValue={getOptions()?.[0]}
            onChange={onExtendChange}
            value={extendValue}
            disabled={
              valueBasisTypeEditableFreeze ||
              formData?.valueBasisInherits?.length === 2
            }
            labelInValue
            options={getOptions()}
          />
        );
        break;
      case 'defaultCreator':
      case 'valueBasisCreator':
      case 'valueBasisCustom':
      case undefined:
        dom = null;
        break;
      default:
        dom = (
          <div className={c.form_render}>
            {Widget && (
              <Widget key={formData.$id} {...usefulWidgetProps}></Widget>
            )}
          </div>
        );
        break;
    }
    return dom;
  };

  const content = () => {
    return (
      <div className={c.popover}>
        <div className={c['popover-title']}>取值依据生效规则</div>
        <div className={c['popover-desc']}>
          <span className={c['popover-title']}>新增时</span>
          ：按照最新取值依据获取负责人。
        </div>
        <div className={c['popover-text']}>
          <div className={c['popover-text-title']}>比如：</div>
          表单A当前取值依据为创建人，则小探在创建表单A时，负责人为「小探」。
        </div>
        <div className={c['popover-desc']}>
          <span className={c['popover-title']}>编辑时</span>
          ：按照最新取值依据获取负责人，重新赋值，负责人可能发生变更。
        </div>
        <div className={c['popover-text']}>
          <div className={c['popover-text-title']}>比如：</div>
          原本表单A的负责人为「小探」，根据新的取值依据，负责人应为「小迹」，则表单A在编辑时，负责人将从「小探」变更为「小迹」。
        </div>
      </div>
    );
  };

  return (
    <div className={c.container}>
      <div className={c.title}>
        {schemaName === 'valueBasisType' ? '取值依据' : '默认值'}
        {schemaName === 'valueBasisType' && (
          <Popover placement="bottom" content={content} trigger="hover">
            <IconFont
              type="icon-shuoming"
              className={c['info-icon']}
            ></IconFont>
          </Popover>
        )}
      </div>

      <Space direction="vertical" className={c.container}>
        <Select
          style={{ width: '100%' }}
          placeholder={placeholder}
          optionFilterProp="children"
          defaultValue={DefaultEnum?.[0]}
          value={typeValue}
          onChange={onChange}
          disabled={
            valueBasisTypeEditableFreeze ||
            formData?.valueBasisInherits?.length === 2
          }
        >
          {DefaultEnum?.map((child: string, index: number) => (
            <Option key={child} value={child}>
              {newEnumNames[index]}
            </Option>
          ))}
        </Select>
        {renderDom(typeValue)}
      </Space>
    </div>
  );
};

export default CooperatorDefaultValue;
