/*
 * :file description:
 * :name: /low-code-platform/src/components/FormGenerator/settings/presetFormSettings/special-handling-type-Jxc.js
 * :author: caihua
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-11-29 17:17:14
 * :last editor: caihua
 * :date last edited: 2022-12-06 15:30:15
 */
// 此文件只为进销存-项目做一些特定的匹配
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';

export const numberLimited = Object.freeze({
  format: {
    title: '显示格式',
    type: 'string',
    enum: ['number', 'percentage'],
    className: 'my-radioName',
    enumNames: ['数值', '百分比'],
    widget: 'radio',
    default: 'number',
    order: 30,
  },
});

export const freeBaseSetting = Object.freeze({
  limitedNumber: {
    title: '限定数值范围',
    type: 'boolean',
    widget: 'limitedNumRange',
    enum: ['number', 'percentage'],
    enumNames: ['数值', '百分比'],
    default: false,
    order: 1998,
  },
  limitedNumberRange: {
    type: 'object',
    default: {
      min: null,
      max: null,
    },
    hidden: 'true',
    order: 1999,
  },
  default: {
    title: '默认值',
    type: 'number',
    widget: 'defaultValue',
    placeholder: '请选择',
    enum: ['custom', 'formula'],
    enumNames: ['自定义', '公式编辑'],
    hideupper: true, //特殊处理需要隐藏设置区域的大写显示
    order: 2000,
    default: undefined,
  },
  fieldStatus: {
    title: null,
    type: 'array',
    enum: ['default', 'hidden', 'readOnly'],
    className: 'my-radioName',
    enumNames: ['默认', '隐藏', '只读'],
    widget: 'fieldStatus',
    default: ['default'],
    order: 2001,
  },
});

export const baseSetting = Object.freeze({
  limitedNumber: {
    title: '限定数值范围',
    type: 'boolean',
    widget: 'limitedNumRange',
    enum: ['number', 'percentage'],
    enumNames: ['数值', '百分比'],
    default: false,
    order: 1998,
  },
  limitedNumberRange: {
    type: 'object',
    default: {
      min: null,
      max: null,
    },
    hidden: 'true',
    order: 1999,
  },
  default: {
    title: null,
    type: 'number',
    widget: 'defaultValue',
    placeholder: '请选择',
    enum: ['custom', 'formula', 'association'],
    enumNames: ['自定义', '公式编辑', '关联其他表单数据'],
    hideupper: true, //特殊处理需要隐藏设置区域的大写显示
    order: 2000,
    default: undefined,
  },
  fieldStatus: {
    title: null,
    type: 'array',
    enum: ['default', 'hidden', 'readOnly'],
    className: 'my-radioName',
    enumNames: ['默认', '隐藏', '只读'],
    widget: 'fieldStatus',
    default: ['default'],
    order: 2001,
  },
});

export const specialHandlingTypeJxc = [
  {
    text: '数字输入框',
    icon: 'icon-a-shuzishurukuang2x',
    name: 'number',
    schema: {
      title: '数字输入框',
      type: 'number',
      widget: 'number',
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        // disabled: true
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入数字',
        default: '请输入数字',
        max: 100,
        order: 10,
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
        order: 20,
      },
      format: {
        title: '显示格式',
        type: 'string',
        enum: ['number', 'percentage'],
        className: 'my-radioName',
        enumNames: ['数值', '百分比'],
        widget: 'radio',
        default: 'number',
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 40,
      },
      limitedNumber: {
        title: '限定数值范围',
        type: 'boolean',
        widget: 'limitedNumRange',
        enum: ['number', 'percentage'],
        enumNames: ['数值', '百分比'],
        default: false,
      },
      limitedNumberRange: {
        type: 'object',
        default: {
          min: null,
          max: null,
        },
        hidden: 'true',
      },
      default: {
        title: null,
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula', 'association'],
        enumNames: ['自定义', '公式编辑', '关联其他表单数据'],
        default: null,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum: ['default', 'hidden', 'readOnly'],
        className: 'my-radioName',
        enumNames: ['默认', '隐藏', '只读'],
        widget: 'fieldStatus',
        default: ['default'],
      },
    },
  },
  {
    text: '金额',
    name: 'money',
    icon: 'icon-a-jine2x',
    schema: {
      title: '金额',
      type: 'number',
      widget: 'money',
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        // disabled: true
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入数字',
        default: '请输入数字',
        max: 100,
        order: 10,
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
        order: 20,
      },
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      limitedNumber: {
        title: '限定数值范围',
        type: 'boolean',
        widget: 'limitedNumRange',
        enum: ['number', 'percentage'],
        enumNames: ['数值', '百分比'],
        default: false,
      },
      limitedNumberRange: {
        type: 'object',
        default: {
          min: null,
          max: null,
        },
        hidden: 'true',
      },
      default: {
        title: null,
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula', 'association'],
        enumNames: ['自定义', '公式编辑', '关联其他表单数据'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum: ['default', 'hidden', 'readOnly'],
        className: 'my-radioName',
        enumNames: ['默认', '隐藏', '只读'],
        widget: 'fieldStatus',
        default: ['default'],
      },
    },
  },
  {
    text: '单行输入框',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    schema: {
      title: '单行输入框',
      type: 'string',
      widget: 'input',
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        // disabled: true
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
        order: 20,
      },
      scan: !isOpenInTripartitePlatform('1688')
        ? {
            title: '扫码',
            type: 'boolean',
            widget: 'scan',
            order: 21,
          }
        : {},
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula', 'association'],
        enumNames: ['自定义', '公式编辑', '关联其他表单数据'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum: ['default', 'hidden', 'readOnly'],
        className: 'my-radioName',
        enumNames: ['默认', '隐藏', '只读'],
        widget: 'fieldStatus',
        default: ['default'],
      },
      validation: {
        title: '数值格式',
        widget: 'validation',
        limitLength: false,
        min: null,
        max: null,
        formatAll: true, // true
        formatType: ['numberVal', 'chinese', 'symbolVal', 'space', 'english'], // 数字 'numberVal', 中文'chinese', 符号'symbolVal', 空格'space', 英文'english'
        type: 'object',
        allType: ['numberVal', 'chinese', 'symbolVal', 'space', 'english'],
        allTypeNames: ['数字', '中文', '符号', '空格', '英文'],
        className: 'my-validation',
      },
    },
  },
  {
    text: '多行输入框',
    name: 'textarea',
    icon: 'icon-duohangshurukuang',
    schema: {
      title: '多行输入框',
      type: 'string',
      format: 'textarea',
      widget: 'textarea',
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        // disabled: true
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
        order: 20,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['custom', 'formula', 'association'],
        enumNames: ['自定义', '公式编辑', '关联其他表单数据'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum: ['default', 'hidden', 'readOnly'],
        className: 'my-radioName',
        enumNames: ['默认', '隐藏', '只读'],
        widget: 'fieldStatus',
        default: ['default'],
      },
    },
  },
  {
    text: '单选框',
    name: 'select',
    icon: 'icon-danxuankuang1',
    schema: {
      title: '单选',
      type: 'object',
      enum: ['option_0', 'option_1', 'option_2'],
      enumNames: ['选项1', '选项2', '选项3'],
      widget: 'select',
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        // disabled: true
      },
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['选项1', '选项2', '选项3'],
        widget: 'selectOptions',
        order: 12,
        selectType: 'select',
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
        order: 20,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['association'],
        enumNames: ['关联其他表单数据'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum: ['default', 'hidden', 'readOnly'],
        className: 'my-radioName',
        enumNames: ['默认', '隐藏', '只读'],
        widget: 'fieldStatus',
        default: ['default'],
      },
    },
  },
  {
    text: '多选框',
    name: 'multiSelect',
    icon: 'icon-duoxuankuang',
    schema: {
      title: '多选框',
      type: 'array',
      items: {
        type: 'string',
      },
      enum: ['option_0', 'option_1', 'option_2'],
      enumNames: ['选项1', '选项2', '选项3'],
      widget: 'multiSelect',
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        // disabled: true
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
        order: 20,
      },
      enumList: {
        title: '选项',
        type: 'array',
        widget: 'selectOptions',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['选项1', '选项2', '选项3'],
        order: 12,
        selectType: 'multiSelect',
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['association'],
        enumNames: ['关联其他表单数据'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum: ['default', 'hidden', 'readOnly'],
        className: 'my-radioName',
        enumNames: ['默认', '隐藏', '只读'],
        widget: 'fieldStatus',
        default: ['default'],
      },
    },
  },
  {
    text: '日期',
    name: 'date',
    icon: 'icon-riqi',
    schema: {
      title: '日期',
      type: 'string',
      format: 'date',
      widget: 'date',
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        // disabled: true
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
        order: 20,
      },
      format: {
        title: '日期类型',
        type: 'string',
        enum: ['dateTime', 'date'],
        enumNames: ['年-月-日 时:分:秒', '年-月-日'],
        widget: 'baseSelect',
        order: 5,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'createTime', 'formula', 'association'],
        enumNames: ['自定义', '创建时间', '公式编辑', '关联其他表单数据'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum: ['default', 'hidden', 'readOnly'],
        className: 'my-radioName',
        enumNames: ['默认', '隐藏', '只读'],
        widget: 'fieldStatus',
        default: ['default'],
      },
    },
  },
  {
    text: '图片',
    name: 'picture',
    icon: 'icon-tupian',
    schema: {
      title: '图片',
      type: 'array',
      widget: 'picture',
      items: {
        type: 'string',
      },
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        // disabled: true
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
        order: 20,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      watermark: {
        title: '水印照片',
        type: 'boolean',
        hidden: isOpenInTripartitePlatform('1688') ? true : false,
        widget: 'switch',
        description: '开启后只能通过手机拍照上传',
        order: 15,
        default: false,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['association'],
        enumNames: ['关联其他表单数据'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
  },
  {
    text: '成员',
    name: 'innerContact',
    icon: 'icon-wode',
    schema: {
      title: '组织成员',
      type: 'array',
      widget: 'innerContact',
      className: 'my-radioBtn',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        // disabled: true
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
        order: 20,
      },
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      choice: {
        title: '选项',
        type: 'string',
        default: '0',
        enum: ['0', '1'],
        className: 'my-radioName',
        enumNames: ['只能选一人', '可同时选择多人'],
        widget: 'radio',
      },
      default: {
        title: '默认值',
        type: 'array',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'association'],
        enumNames: ['自定义', '关联其他表单数据'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum: ['default', 'hidden', 'readOnly'],
        className: 'my-radioName',
        enumNames: ['默认', '隐藏', '只读'],
        widget: 'fieldStatus',
        default: ['default'],
      },
      fillObject: {
        title: '员工信息填充',
        widget: 'fillObject',
        type: 'array',
        default: [],
        enumWidgets: [
          {
            widgetName: '电话',
            widgetType: 'phone',
          },
          {
            widgetName: '邮箱',
            widgetType: 'email',
          },
        ],
        hidden: '{{formData.parentWidget === "table"}}',
      },
    },
  },
  {
    text: '部门',
    name: 'department',
    icon: 'icon-zuzhijiagou',
    schema: {
      title: '部门',
      type: 'array',
      widget: 'department',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        // disabled: true
      },
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        description: '勾选后可作为流程条件',
        order: 20,
      },
      multiple: {
        title: '选项',
        type: 'boolean',
        default: false,
        enum: [false, true],
        className: 'my-radioName',
        enumNames: ['只能选择一个部门', '可同时选择多个部门'],
        widget: 'radio',
      },
      default: {
        title: '默认值',
        type: 'array',
        widget: 'defaultValue',
        placeholder: '请选择 ',
        enum: ['custom', 'association'],
        enumNames: ['自定义', '关联其他表单数据'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum: ['default', 'hidden', 'readOnly'],
        className: 'my-radioName',
        enumNames: ['默认', '隐藏', '只读'],
        widget: 'fieldStatus',
        default: ['default'],
      },
    },
  },
  {
    text: '关联信息',
    name: 'relatesInfo',
    icon: 'guanlianzuzhi',
    schema: {
      title: '关联信息',
      type: 'array',
      widget: 'relatesInfo',
      bizAlias: 'relates_info',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      info: {
        type: 'string',
        widget: 'jumplink',
        props: {
          message: '',
          description:
            '当前控件是探迹CRM的特殊组件，如需对其选项进行调整，请点击去设置',
          type: 'info',
          showIcon: true,
        },
      },
    },
  },
  {
    text: '标签组',
    name: 'customerTags',
    icon: 'icon-label-line',
    schema: {
      title: '标签组',
      type: 'array',
      widget: 'customerTags',
      bizAlias: 'customer_tags',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      info: {
        type: 'string',
        widget: 'jumplink',
        props: {
          message: '',
          description:
            '当前控件是探迹CRM的特殊组件，如需对其选项进行调整，请点击去设置',
          type: 'info',
          showIcon: true,
        },
      },
    },
  },
  {
    text: '身份证',
    name: 'idCard',
    icon: 'shenfenzheng',
    // from: 'CloudPlatform',
    schema: {
      title: '身份证',
      type: 'string',
      widget: 'idCard',
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        widget: 'input',
        // disabled: true
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
        widget: 'input',
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        order: 20,
      },
      // scan: {
      //   title: '扫码',
      //   type: 'boolean',
      //   widget: 'scan',
      //   order: 21,
      // },
      // default: {
      //   title: '默认值',
      //   type: 'string',
      //   widget: 'defaultValue',
      //   max: 100,
      //   placeholder: '请选择',
      //   enum: ['custom', 'formula', 'association'],
      //   enumNames: ['自定义', '公式编辑', '关联其他表单数据'],
      //   hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      // },
      // fieldStatus: {
      //   // title: '字段状态',
      //   title: null,
      //   type: 'array',
      //   enum: ['default', 'hidden', 'readOnly'],
      //   className: 'my-radioName',
      //   enumNames: ['默认', '隐藏', '只读'],
      //   widget: 'fieldStatus',
      //   default: ['default'],
      // },
    },
  },
  {
    text: '邮箱',
    name: 'email',
    icon: 'icon-email',
    from: 'All',
    schema: {
      title: '邮箱',
      type: 'string',
      widget: 'email',
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        widget: 'input',
        // disabled: true
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
        widget: 'input',
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        order: 20,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'association'],
        enumNames: ['自定义', '关联其他表单数据'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum: ['default', 'hidden', 'readOnly'],
        className: 'my-radioName',
        enumNames: ['默认', '隐藏', '只读'],
        widget: 'fieldStatus',
        default: ['default'],
      },
    },
  },
  {
    text: '级联单选',
    name: 'cascade',
    icon: 'icon-line-jilian',
    // icon: 'icon-email',
    from: 'All',
    schema: {
      title: '级联单选',
      type: 'object',
      widget: 'cascadeV2',
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        // disabled: true
      },
      enumOptionId: {
        title: '选项',
        type: 'string',
        widget: 'selectTreeOptions',
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
        order: 20,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'object',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['custom', 'association'],
        enumNames: ['自定义', '关联其他表单数据'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum: ['default', 'hidden', 'readOnly'],
        className: 'my-radioName',
        enumNames: ['默认', '隐藏', '只读'],
        widget: 'fieldStatus',
        default: ['default'],
      },
    },
  },
  {
    text: '级联多选',
    name: 'multiCascade',
    icon: 'icon-line-jilian',
    // icon: 'icon-email',
    from: 'All',
    schema: {
      title: '级联多选',
      type: 'array',
      widget: 'multiCascade',
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        // disabled: true
      },
      enumOptionId: {
        title: '选项',
        type: 'string',
        widget: 'selectTreeOptions',
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
        order: 20,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'array',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['custom', 'association'],
        enumNames: ['自定义', '关联其他表单数据'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum: ['default', 'hidden', 'readOnly'],
        className: 'my-radioName',
        enumNames: ['默认', '隐藏', '只读'],
        widget: 'fieldStatus',
        default: ['default'],
      },
    },
  },
];
