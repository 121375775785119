/*
 * :file description:
 * :name: \low-code-platform\src\pages\form\utils\index.js
 * :author: gaoch
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-22 13:52:55
 * :last editor: hyw
 * :date last edited: 2022-02-24 11:16:03
 */
import { Modal } from 'antd';
import moment from 'moment';
import formApi from '@/services/form';

const doubleCheck = ({
  title = '提示',
  content,
  okText = '确定',
  cancelText = '取消',
  onOk = () => {},
  onCancel = (close) => {
    close();
  },
}) => {
  Modal.confirm({
    title,
    content,
    okText,
    cancelText,
    onOk: onOk,
    onCancel: onCancel,
  });
};

const defaultFormat = 'YYYY-MM-DD';
const formatDate = (t) => {
  return t
    ? moment.isMoment(t)
      ? t.format(defaultFormat)
      : moment(t).format(defaultFormat)
    : undefined;
};

const getFieldsAccess = async (payload) => {
  const nodeInfoRes = await formApi.viewProcessDiagram(payload);
  const fieldsPromise = await formApi.getFormConfig({
    nodeId: payload?.nodeId ?? nodeInfoRes?.data?.currentNodeId,
    processId: payload?.processId,
    formInstanceId: payload?.formInstanceId,
  });
  return fieldsPromise;
};

// 获取表单下的按钮权限
const getBtnAuth = (formCode, btnKey) => {
  // 判断当前表单中是否有激活流程权限
  const allAuthList = localStorage.getItem('allAuthList') || '[]';
  const authList = JSON.parse(allAuthList);
  let remainData = authList.filter((val) =>
    val.authorityCode.includes(formCode),
  );
  let currentAuth = remainData.some((val) => {
    return val.bizCode == btnKey;
  });
  return currentAuth;
};

export default {
  doubleCheck,
  formatDate,
  getFieldsAccess,
  getBtnAuth,
};

// 日期格式化
const DEFAULT_FORMAT = 'YYYY-MM-DD';
const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const transformDateToString = (value, formatType) => {
  const format =
    formatType === 'dateTime' ? DEFAULT_DATETIME_FORMAT : DEFAULT_FORMAT;
  if (!value) {
    return value;
  }
  value = moment.isMoment(value) ? value : moment(value);
  return value.format(format);
};

export const transformDateToMoment = (value) => {
  if (!value) return value;
  return moment.isMoment(value) ? value : moment(value);
};
