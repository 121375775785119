/*
 * :file description:
 * :name: \low-code-platform\src\components\renderer\AssociationFormSelector\index.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-21 19:52:14
 * :last editor: 黄艺
 * :date last edited: 2022-03-24 16:45:55
 */

import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
  Modal,
  ModalProps,
  Input,
  Table,
  Button,
  Select,
  LabeledValue,
  Tabs,
} from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { RenderType } from '@/pages/form/constant';
import { genTableColumns, genTableData } from '@/pages/form/utils/parse_schema';
import formApi from '@/services/form';
import { FormConfigCtx } from '@/pages/form/[id]';
import c from './index.less';
import IconFont from '@/components/IconFont';
import { customerTitleMap } from '~@/packages/form-render/src/widgets/DataFilters';
import { customJP, isJSON } from '~@/packages/form-render/src/utils';
import ColumnDisplayControl from '~@/packages/table-render/src/components/ColumnDisplayControl';

const { TabPane } = Tabs;

export default function AssociationFormSelector(
  props: ModalProps & { rendertype: string; formCode: string; onChange },
) {
  const { title = '', rendertype, formCode, onChange, schema } = props;
  const [keyword, setKeyword] = useState<string | undefined>();
  const [value, setValue] = useState<string | undefined>();
  const [options, setOptions] = useState<LabeledValue[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [relationSchema, setRelationSchema] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [columns, setColumns] = useState<ColumnType<object>[]>([]);
  const [dataSource, setDataSource] = useState([]);
  const [bizType, setBizType] = useState('crm_customer');
  const [pageParam, setPageParam] = useState({
    pageSize: 10,
  });

  const formConfigCtx = useContext(FormConfigCtx);
  // 根据选择关联表单时选择的字段展示关联表单信息
  const filterRelationInfo = (row) => {
    const checkedKeys = Object.keys(
      formConfigCtx?.content?.properties?.[props.id]?.fields,
    );
    return _.pick(row, checkedKeys);
  };

  const getRelationFormSchema = async () => {
    const formCode = getFormCode();
    if (!formCode) return;
    try {
      const schema = await formApi.getFormSchema({ formCode });
      const relationSchema = isJSON(schema?.data.content)
        ? customJP(schema?.data.content)
        : undefined;
      setRelationSchema(relationSchema);
    } catch (error) {}
  };

  const rowSelection = {
    type: 'radio',
    fixed: true,
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log('selectedRowKeys changed: ', selectedRowKeys, selectedRows);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows) => {
      console.log(selected, selectedRows);
    },
  };

  useEffect(() => {
    console.log('isModalVisible: ', isModalVisible);
    if (!isModalVisible) {
      setKeyword(undefined);
    } else {
      handleSearch();
    }
  }, [isModalVisible, bizType]);

  useEffect(() => {
    isModalVisible && debounceSearch();
  }, [keyword]);

  const handleSearch = ({ pageIndex = 1, pageSize = 10 } = {}) => {
    console.log('查询');

    formApi
      .queryFormDataList({
        formCode: getFormCode(),
        keyword: keyword ? keyword : undefined,
        pageNum: pageIndex,
        pageSize,
        showTitle: true,
        // order: [],
        // sort: [],
      })
      .then((res) => {
        // 前端生成实例标题
        // res.data?.formHeaderFieldList?.unshift({
        //   fieldId: "title",
        //   title: "标题",
        //   type: "string",
        //   widget: "input",
        // });
        // res.data?.instanceDataList?.map(item => {
        //   const genTitle = item.itemList.find(v => v.fieldId === 'createUserId')?.value + '提交的' + title;
        //   item.title = genTitle;
        //   return {
        //     ...item,
        //     itemList: item.itemList.unshift({
        //       fieldId: "title",
        //       title: "标题",
        //       type: "string",
        //       value: genTitle,
        //       widget: "input",
        //     })
        //   };
        // })
        setPageParam({ pageSize: res?.data?.size ?? 10 });
        setColumns(genTableColumns(res.data));
        setDataSource(genTableData(res.data));
      });
  };

  const debounceSearch = useCallback(_.debounce(handleSearch, 500), [keyword]);

  const getFormCode = () => {
    const { relateSource } = props;
    let targetSchema = _.cloneDeep(props);
    if (relateSource) {
      targetSchema = relateSource.find(
        (item: Record<string, any>) => item.bizType === bizType,
      );
    }
    const { formCode } = targetSchema?.dataSource?.target;
    return formCode;
  };

  const handleConfirm = () => {
    if (!selectedRows.length) {
      return;
    } else {
      setOptions([
        {
          ...selectedRows[0],
          label: selectedRows[0].title,
          value: selectedRows[0].instanceId,
        },
      ]);
      setValue(selectedRows?.[0]?.instanceId);
      if (rendertype === RenderType.SEARCH) {
        onChange && onChange(selectedRows?.[0]?.instanceId);
      } else {
        onChange && onChange(selectedRows?.[0]);
      }
    }
  };

  const handleOk = () => {
    handleConfirm();
    setIsModalVisible(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSelect = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    handleSearch();
  }, [relationSchema]);

  const handleReselect = () => {
    setIsModalVisible(true);
  };

  const handleClear = () => {
    setValue(undefined);
    setOptions([]);
    onChange && onChange(undefined);
  };

  const pagination = {
    total: dataSource.length,
    showTotal: () => (
      <div
        style={{
          marginRight: '10px',
        }}
      >
        共 {dataSource.length} 项
      </div>
    ),
    showSizeChanger: true,
    pageSizeOptions: [5, 10],
    onChange: (pageIndex, pageSize) => {
      console.log(pageIndex, pageSize);
      handleSearch({ pageIndex, pageSize });
    },
  };

  return (
    <div className={c.t_association_form_selector}>
      {rendertype === RenderType.SEARCH ? (
        <Select
          allowClear
          value={value}
          options={options}
          open={false}
          placeholder="请选择"
          onDropdownVisibleChange={handleSelect}
          onClear={handleClear}
        />
      ) : !options.length ? (
        <Button onClick={handleSelect}>请选择</Button>
      ) : (
        <>
          <div>
            <Button type="text">{options?.[0]?.label}</Button>
            <Button type="link" onClick={handleReselect}>
              重新选择
            </Button>
            <Button type="link" onClick={handleClear}>
              清除
            </Button>
          </div>
          <div className={c.relation_zone}>
            {selectedRows.length
              ? Object.keys(filterRelationInfo(selectedRows[0])).map((v) => (
                  // 根据新建form时限定的字段展示
                  <>
                    <div className={c.label}>
                      {
                        formConfigCtx?.content?.properties?.[props.id]
                          ?.fields?.[v]?.title
                      }
                    </div>
                    <div className={c.value}>{selectedRows[0][v]}</div>
                  </>
                ))
              : null}
          </div>
        </>
      )}
      <Modal
        width={800}
        title={title}
        visible={isModalVisible}
        okText="确定"
        cancelText="取消"
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <Tabs
          defaultActiveKey="1"
          onChange={(e) => {
            setBizType(e);
          }}
        >
          {props?.relateSource?.map((item) => {
            return (
              <TabPane
                tab={customerTitleMap.get(item.bizType)}
                key={item.bizType}
              ></TabPane>
            );
          })}
        </Tabs>
        <Input
          className={c.relation_search_input}
          value={keyword}
          prefix={<SearchOutlined />}
          placeholder="请输入"
          allowClear
          onChange={(e) => setKeyword(e.target.value)}
        />
        {/* <ColumnDisplayControl
          formCode={getFormCode()}
          cb={() => {
            getRelationFormSchema();
          }}
        /> */}

        <Table
          rowKey="instanceId"
          ellipsis
          scroll={{ x: 'max-content', y: false }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={dataSource}
          pagination={{
            ...pagination,
            ...pageParam,
            size: 'small',
          }}
        />
      </Modal>
    </div>
  );
}
