/*
 * @Author: 刘昕 xinlx@tungee.com
 * @Date: 2022-10-12 14:48:44
 * @LastEditors: 刘昕 xinlx@tungee.com
 * @LastEditTime: 2022-11-25 10:49:13
 * @FilePath: \tj-ali-crm-management\src\pages\Connector\Components\RequestBody\components\Body\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import IconFont from '@/components/IconFont';
import { Button, Col, Input, Row, Select } from 'antd';
import React, { Key, useContext, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import AntdCascader from '../antdCascader';
import Style from './index.less';
import cx from 'classnames';
import { schemaOptionsContext, connectorDetailContext } from '../../index';
import styled from 'styled-components';

const widgetConfig = {
  // 单选
  select: [
    { label: '标题.选项ID', value: 'key' },
    { label: '标题.选项值', value: 'value' },
  ],
  // 多选
  multiSelect: [
    { label: '标题.选项ID', value: 'key' },
    { label: '标题.选项值', value: 'value' },
  ],
  // 日期区间
  dateRange: [
    { label: '标题.开始时间', value: 'begin' },
    { label: '标题.结束时间', value: 'end' },
  ],
  // 电话
  phone: [
    { label: '电话标题.国家编码', value: 'countryCode' },
    { label: '标题.号码', value: 'value' },
  ],
  // 成员
  innerContact: [
    { label: '标题.成员ID', value: 'key' },
    { label: '标题.成员名称', value: 'value' },
  ],
  // 部门
  department: [
    { label: '标题.部门ID', value: 'key' },
    { label: '标题.部门名称', value: 'value' },
  ],
};

const { Option } = Select;

const ValueOfType = {
  constType: 'const',
  objectType: 'object',
  fieldType: 'field',
};
interface Item {
  id: string;
  fieldId: string; // 字段名的id
  name: string; // 字段变量名  field: string;
  fieldType: string; // 字段类型 array object string number
  elementType: string; // object string number
  type: string; // dvalue: string; 值类型 object const field
  fieldPath: string[]; // fieldType: string;
  level: number;
  arrayPath?: string[]; // 当变量是数组对象时 选取的表格名称
  internal?: boolean;
  attribute?: string;
  assignments?: Array<Item>;
}

const WraperCol = styled(Col)<{ left?: string }>`
  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 1px;
    height: 999px;
    left: ${({ left }) => left};
    transform: translateX(-50%) translateY(30px);
    background-color: rgba(126, 134, 142, 0.16);
    z-index: 1;
  }
`;

//  parentFieldPath = {dataValue.fieldPath}
// parentFieldType = {dataValue.type}
// level = {dataValue.level}

const BodyItem = (props: any) => {
  const {
    itemValue,
    onChange,
    originData,
    setOriginData,
    requestType = '',
    // schemaJsonOptions,
    parentFieldPath = [],
    parentArrayPath = [],
    parentFieldId = '',
    parentFieldType = '',
    parentElementType = '',
    level = 1,
  } = props;
  const [dataValue, setDataValue] = useState<Item>(itemValue);
  const connectorDetail: Record<string, unknown> = useContext(
    connectorDetailContext,
  );
  const fieldNameOptions = connectorDetail[requestType.toLowerCase()] || [];
  const [currentFieldNameOptions, setCurrentFieldNameOptions] = useState(
    fieldNameOptions || [],
  );
  useEffect(() => {
    setDataValue(itemValue);
  }, [itemValue]);

  const findItemByTree = (nodes: any, searchKey: string): any => {
    for (let i = 0; i < nodes?.length; i++) {
      if (nodes[i].id === searchKey) {
        return nodes[i];
      } else {
        if (nodes[i].children && nodes[i].children.length > 0) {
          let result = findItemByTree(nodes[i].children, searchKey);
          if (result) {
            return result;
          }
        }
      }
    }
    return null;
  };

  // 和上面的数据结构不一样
  const findItemViaAssignments = (nodes: any, searchKey: string): any => {
    for (let i = 0; i < nodes?.length; i++) {
      if (nodes[i].fieldId === searchKey) {
        return nodes[i];
      } else {
        if (nodes[i].assignments && nodes[i].assignments.length > 0) {
          let result = findItemViaAssignments(nodes[i].assignments, searchKey);
          if (result) {
            return result;
          }
        }
      }
    }
    return null;
  };

  useEffect(() => {
    if (parentFieldId) {
      const selectOption = findItemByTree(fieldNameOptions, parentFieldId);
      const currentLevelAssignments = findItemViaAssignments(
        originData,
        parentFieldId,
      );
      // setCurrentFieldNameOptions(selectOption?.children || [])

      let fieldNameOptionsFilterDisabled =
        selectOption?.children.map((item) => {
          return {
            ...item,
            disabled:
              (currentLevelAssignments?.assignments || [])
                .map((i) => i.fieldId)
                .findIndex((j) => j === item.id) > -1
                ? true
                : false,
          };
        }) || [];
      // 处理字段已经被删除的情况
      if (
        dataValue.fieldId &&
        fieldNameOptionsFilterDisabled.findIndex(
          (i) => i.id === dataValue.fieldId,
        ) === -1
      ) {
        fieldNameOptionsFilterDisabled.push(
          requestType.toLowerCase() === 'body'
            ? {
                id: dataValue.fieldId,
                description: '该字段已经被删除',
              }
            : {
                id: dataValue.fieldId,
                name: '该字段已经被删除',
              },
        );
      }
      setCurrentFieldNameOptions(fieldNameOptionsFilterDisabled);
    } else {
      // 处理字段已经被选中,不能在选中的情况
      let fieldNameOptionsFilterDisabled =
        fieldNameOptions.map((item) => {
          return {
            ...item,
            disabled:
              originData.map((i) => i.fieldId).findIndex((j) => j === item.id) >
              -1
                ? true
                : false,
          };
        }) || [];
      // 处理字段已经被删除的情况
      if (
        dataValue.fieldId &&
        fieldNameOptionsFilterDisabled.findIndex(
          (i) => i.id === dataValue.fieldId,
        ) === -1
      ) {
        fieldNameOptionsFilterDisabled.push(
          requestType.toLowerCase() === 'body'
            ? {
                id: dataValue.fieldId,
                description: '该字段已经被删除',
              }
            : {
                id: dataValue.fieldId,
                name: '该字段已经被删除',
              },
        );
      }
      setCurrentFieldNameOptions(fieldNameOptionsFilterDisabled);

      // setCurrentFieldNameOptions(fieldNameOptions?.children || [])
    }
  }, [parentFieldId, dataValue, originData]);

  //  处理数据变化
  const handleOnChange = (targetValue: any, field: string) => {
    let newValue = { ...itemValue, [field]: targetValue };
    //  如果是 字段名是 name的话，会把fieldType，elementType 带过去
    if (field === 'fieldId') {
      const findItemByTree = (nodes, searchKey) => {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i].id === searchKey) {
            return nodes[i];
          } else {
            if (nodes[i].children && nodes[i].children.length > 0) {
              let result = findItemByTree(nodes[i].children, searchKey);
              if (result) {
                return result;
              }
            }
          }
        }
        return null;
      };
      const selectOption = findItemByTree(fieldNameOptions, targetValue);

      let init_type =
        selectOption?.type === 'object' ||
        selectOption?.elementType === 'object'
          ? ValueOfType.objectType
          : ValueOfType.fieldType;
      newValue = {
        ...itemValue,
        [field]: targetValue,
        fieldType: selectOption?.type || '',
        elementType: selectOption?.elementType || '',
        name: selectOption?.name || '',
        type: init_type,
        fieldPath: [],
        assignments: [],
      };
      // headers 特殊处理逻辑
      if (requestType.toLowerCase() === 'headers') {
        newValue['value'] = selectOption?.defaultValue || '';
        newValue['type'] = ValueOfType.constType;
      }
    }
    // 特殊处理 组件自带的属性 要通过attrbuite 来传递
    if (
      field === 'fieldPath' &&
      Array.isArray(targetValue) &&
      targetValue[0] === 'false'
    ) {
      if (targetValue[targetValue.length - 2].split('_')[0] in widgetConfig) {
        newValue = {
          ...itemValue,
          [field]: targetValue,
          attribute: targetValue[targetValue.length - 1],
        };
      }
    }

    if (
      field === 'arrayPath' &&
      Array.isArray(targetValue) &&
      targetValue.length === 1
    ) {
      newValue = { ...itemValue, [field]: [] };
    }

    const recursionFileMap = (arr, val) => {
      return arr.map((item) => {
        if (item.id === val.id) {
          item = val;
        }
        if (Array.isArray(item.assignments) && item.assignments.length > 0) {
          item.assignments = recursionFileMap(item.assignments, val);
        }
        return item;
      });
    };
    const result = recursionFileMap(originData, newValue);
    onChange && onChange(result);
    setOriginData(result);
  };

  // 处理数据删除
  const handleDelete = () => {
    // 在页面第一层处理逻辑
    const idx = originData.findIndex((i: Item) => i.id === itemValue.id);
    if (idx > -1) {
      const cloneOrigin = _.clone(originData);
      cloneOrigin.splice(idx, 1);
      onChange && onChange(cloneOrigin);
      setOriginData(cloneOrigin);
    } else {
      // 嵌套的层次处理逻辑
      const recursionFileMap = (arr, val) => {
        return arr.map((item) => {
          if (item.assignments && item.assignments.length > 0) {
            const idx = item.assignments.findIndex((o) => o.id === val.id);
            if (idx > -1) {
              item.assignments.splice(idx, 1);
            } else {
              item.assignments = recursionFileMap(item.assignments, val);
            }
          }
          return item;
        });
      };
      const result = recursionFileMap(originData, itemValue);
      onChange && onChange(result);
      setOriginData(result);
    }
  };

  // 处理数据增加
  const handleAdd = () => {
    const recursionFileMap = (arr, val) => {
      return arr.map((item) => {
        if (item.assignments && item.assignments.length > 0) {
          item.assignments = recursionFileMap(item.assignments, val);
          if (item.id === val.id) {
            item.assignments.push({
              id: Date.now().toString(),
              level: item.level + 1,
              fieldId: '',
              fieldType: '',
              elementType: '',
            });
          }
        } else {
          if (item.id === val.id) {
            item.assignments = [
              {
                id: Date.now().toString(),
                level: item.level + 1,
                fieldId: '',
                fieldType: '',
                elementType: '',
              },
            ];
          }
        }
        return item;
      });
    };
    const result = recursionFileMap(originData, itemValue);
    onChange && onChange(result);
    setOriginData(result);
  };
  console.log('-----> dataValue.level', dataValue.level);
  console.log('-----------------dataValue.assignments', dataValue.assignments);
  console.log(
    '-----------------currentFieldNameOptions',
    currentFieldNameOptions,
  );
  return (
    <div key={itemValue.id} className={Style.bodyItem} id="area">
      <Row
        justify="space-between"
        align="middle"
        className={Style.row}
        gutter={8}
        wrap={false}
      >
        <WraperCol
          span={10}
          style={{
            paddingLeft: `${((dataValue?.level || 1) - 1) * 40}px`,
          }}
          left={`${20 + ((dataValue?.level || 1) - 1) * 40}px`}
        >
          <span className={Style.empty}></span>
          <Select
            fieldNames={
              requestType.toLowerCase() === 'body'
                ? { label: 'description', value: 'id' }
                : { label: 'name', value: 'id' }
            }
            getPopupContainer={() => document.getElementById('area')}
            style={{ width: '100%' }}
            listItemHeight={6}
            listHeight={250}
            value={dataValue?.fieldId}
            onChange={(targetValue) => handleOnChange(targetValue, 'fieldId')}
            placeholder="请选择"
            options={currentFieldNameOptions}
            virtual={false}
            dropdownStyle={{
              minWidth: '150px',
            }}
            disabled={requestType.toLowerCase() === 'path' ?? false}
          />
        </WraperCol>
        <Col span={7}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ whiteSpace: 'nowrap' }}>的值设为&nbsp;</div>
            <Select
              style={{ width: '100%' }}
              value={dataValue.type}
              onChange={(targetValue) => handleOnChange(targetValue, 'type')}
              disabled={!dataValue.fieldId}
              placeholder="请选择"
            >
              {dataValue.fieldType === 'object' ||
              dataValue.elementType === 'object' ? (
                <Option value={ValueOfType.objectType}>对象</Option>
              ) : (
                <>
                  <Option value={ValueOfType.constType}>值</Option>
                  <Option value={ValueOfType.fieldType}>字段</Option>
                </>
              )}
            </Select>
          </div>
        </Col>
        <Col span={6}>
          {dataValue.type !== ValueOfType.constType ? (
            <AntdCascader
              parentFieldType={parentFieldType}
              parentElementType={parentElementType}
              parentFieldPath={parentFieldPath}
              parentArrayPath={parentArrayPath}
              level={level}
              // 依赖 变量的类型
              fieldType={dataValue.fieldType}
              //  依赖 elementType
              elementType={dataValue.elementType}
              value={
                dataValue.fieldType === 'array' &&
                dataValue.elementType === 'object'
                  ? dataValue.arrayPath
                  : dataValue.fieldPath
              }
              // schemaJsonOptions={schemaJsonOptions}
              onChange={(targetValue: any) =>
                handleOnChange(
                  targetValue,
                  dataValue.fieldType === 'array' &&
                    dataValue.elementType === 'object'
                    ? 'arrayPath'
                    : 'fieldPath',
                )
              }
              disabled={!dataValue.fieldId}
            />
          ) : (
            <Input
              showCount
              maxLength={100}
              placeholder="请输入"
              value={dataValue.value}
              onChange={(e) => handleOnChange(e.target.value, 'value')}
              disabled={!dataValue.fieldId}
            />
          )}
        </Col>

        {requestType.toLowerCase() !== 'path' ? (
          <Col span={1} style={{ textAlign: 'end' }}>
            <IconFont
              type="icon-shanchu1"
              onClick={handleDelete}
              className={Style.icon}
            />
          </Col>
        ) : null}
      </Row>
      {dataValue.assignments && dataValue.assignments.length > 0
        ? dataValue.assignments.map((item: any) => (
            <BodyItem
              key={item.id}
              onChange={onChange}
              itemValue={item}
              originData={originData}
              setOriginData={setOriginData}
              requestType={requestType}
              // schemaJsonOptions={schemaJsonOptions}
              fieldNameOptions={fieldNameOptions}
              parentFieldPath={dataValue.fieldPath}
              parentArrayPath={dataValue.arrayPath}
              // fieldId 值
              parentFieldId={dataValue.fieldId}
              //name 带过来的字段类型
              parentFieldType={dataValue.fieldType}
              parentElementType={dataValue.elementType}
              level={dataValue?.level}
            />
          ))
        : null}
      {dataValue.type === ValueOfType.objectType ? (
        <Button
          icon={<IconFont type="icon-tianjia1" />}
          style={{
            marginLeft: `${dataValue?.level * 40}px`,
            marginTop: '20px ',
          }}
          className={Style.btn}
          onClick={handleAdd}
        >
          添加字段
        </Button>
      ) : null}
    </div>
  );
};

const defaultData: Item[] = [];
const CustomTableComponent = (props: any) => {
  const {
    value,
    onChange,
    requestType = 'query',
    // schemaJsonOptions,
  } = props;
  const [originData, setOriginData] = useState<Item[]>(defaultData);
  // 默认不折叠
  const [fold, setFold] = useState(false);

  const schemaInfo = useContext(schemaOptionsContext);
  const connectorDetail: Record<string, unknown> = useContext(
    connectorDetailContext,
  );

  const fieldNameOptions = connectorDetail[requestType.toLowerCase()] || [];

  useEffect(() => {
    setOriginData(value || []);
  }, [value]);

  const onClickButton = (val: boolean) => {
    setFold(val);
  };

  const handleAddOne = () => {
    setOriginData(
      originData.concat({
        id: Date.now().toString(),
        fieldId: '',
        name: '',
        type: '',
        fieldPath: [],
        level: 1,
        fieldType: '',
        elementType: '',
      }),
    );
  };

  if ((fieldNameOptions as Array<any>).length === 0) {
    return null;
  }

  return (
    <div className={Style.container}>
      <div className={Style.title}>
        <span className={Style.request_type}>{requestType}</span>
        {fold ? (
          <IconFont
            type="icon-packup-line"
            onClick={() => onClickButton(false)}
          />
        ) : (
          <IconFont type="icon-down-line" onClick={() => onClickButton(true)} />
        )}
      </div>
      {!fold ? (
        <div className={Style.content}>
          {originData.map((item) => {
            return (
              <BodyItem
                key={item.id}
                itemValue={item}
                originData={originData}
                setOriginData={setOriginData}
                requestType={requestType}
                // schemaJsonOptions={schemaJsonOptions}
                parentFieldPath={[]}
                parentArrayPath={[]}
                // name 值
                parentFieldId=""
                parentFieldType=""
                parentElementType=""
                onChange={onChange}
              />
            );
          })}
          {requestType.toLowerCase() !== 'path' ? (
            <Button
              onClick={handleAddOne}
              icon={<IconFont type="icon-tianjia1" />}
              style={{ marginTop: '20px ' }}
            >
              添加字段
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default CustomTableComponent;
