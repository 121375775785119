/*
 * @Description:
 * @FilePath: /low-code-platform/src/pages/generator/relation/shareData/WidgetModal/api.tsx
 * :author: wu.shangru
 * :copyright: (c) 2023, Tungee
 * @Date: 2023-12-25 18:10:09
 * @LastEditors: wu.shangru
 * @LastEditTime: 2023-12-26 14:54:01
 */
import {
  request,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '@/common/http';

// OA表单列表查询
export async function queryOaFormDataList(data: any) {
  return postRequest('/api/formSearch/queryOaFormDataList', data);
}

// 列表查询
export async function queryFormDataList(data: any) {
  return postRequest('/api/formSearch/queryFormDataList', data);
}

// 布克巴巴列表查询
export async function bookbabaQueryFormDataList(data: any) {
  return postRequest(
    `${window.__TJ_CRM_DOMAIN}/api/v1/formserver-service/bookba/form/dataList/query`,
    data,
    {
      headers: {
        corpId: window.__corpId,
        subscribeId: window.__subscribeId,
      },
    },
  );
}

export async function queryCustomerList(params) {
  return postRequest(
    `${window.__TJ_CRM_DOMAIN}/api/v1/customer/level/queryCustomerList`,
    params,
  );
}

// 森合定制：【客户】下级客户列表
export async function senheChildCustomerList(params) {
  return postRequest(
    `${window.__TJ_CRM_DOMAIN}/api/v1/customer/level/queryOtherChildCustomerList`,
    params,
  );
}

// 森合定制：【合同】下级客户列表
export async function senheContractChildCustomerList(params) {
  return postRequest(
    `${window.__TJ_CRM_DOMAIN}/api/v1/customer/level/queryRelationChildCustomer`,
    params,
  );
}

// 根据名称查id
export async function getCompanyId(companyName: string) {
  return getRequest(
    `${
      window.__TJ_CRM_DOMAIN || 'https://tj-ali-crm-test.tangees.com'
    }/api/v1/management/businessQuiry/getCompanyId`,
    {
      companyName,
    },
  );
}

// 查看关键联系人号码
export async function batchUnlockContact(data: any) {
  return postRequest(
    `${
      window.__TJ_CRM_DOMAIN || 'https://tj-ali-crm-test.tangees.com'
    }/api/v1/management/enterprises/batchUnlockContact`,
    data,
  );
}

// 查询表单schema
export async function getFormSchema(data: any) {
  return postRequest('/api/form/schema/getFormSchema', data);
}

// OA表单schema
export async function getOaFormSchema(data: any) {
  return getRequest('/api/form/schema/getOaFormSchema', data);
}

// 流程列表
export async function processList(params: string) {
  return postRequest(`/api/formSchema/process/list`, params);
}
