/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\ShowText\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-31 14:07:27
 * :last editor: hyw
 * :date last edited: 2022-04-20 11:12:58
 */
import './index.less';

const ShowText = (props) => {
  return <div className="settingAlert">请选择</div>;
};

export default ShowText;
