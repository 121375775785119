/*
 * :file description:
 * :name: \low-code-platform\src\pages\back\org\components\OrgTable\index.tsx
 * :author: 宋海兵
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 18:17:28
 * :last editor: hyw
 * :date last edited: 2022-02-17 11:16:12
 */
import { useState } from 'react';
import { Table, Modal, Form, Select, Card, message } from 'antd';
import c from './index.less';
import { request } from '@/common/http';
import IconFont from '@/components/IconFont';

const { Option } = Select;
export default function OrgTable({ orgTableData, changeTableData }) {
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(10);
  const [orgData, setOrgData] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [selectValue, setSelectValue] = useState([]);
  const [roleId, setRoleId] = useState();
  const [key, setKey] = useState(0);
  const hideOk = (): void => {
    request('/api/org/user/role/relate', 'POST', {
      userId: roleId,
      roleIdList: selectValue,
    }).then((res) => {
      if (res.code === 200) {
        message.success('角色授权成功');
        changeTableData(current, pageSize);
      }
    });
    setVisible(false);
    form.resetFields();
    setKey((val) => val + 1);
  };

  // 隐藏角色授权弹窗
  const hideModal = (): void => {
    form.resetFields();
    setVisible(false);
  };

  // 角色授权弹窗
  const showConfirm = (data): void => {
    const roleId = data?.roleInfoList?.map((item) => {
      return item.roleId;
    });

    setSelectValue(roleId);
    setRoleId(data.userId);
    request('/api/role/list', 'POST').then((res) => {
      setRoleList(res.data);
    });
    setVisible(true);
    setKey((val) => val + 1);
  };
  const onFinish = (): void => {};
  const onGenderChange = (value): void => {
    setSelectValue(value);
  };
  const columns = [
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: '角色',
      dataIndex: 'roleInfoList',
      key: 'roleInfoList',
      render: (text) =>
        text ? text.map((item) => item.roleName).join('、') : '--',
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <a onClick={() => showConfirm(record)}>角色授权</a>
      ),
    },
  ];
  return (
    <div className={c.card}>
      <Card
        // size="small"
        // title="生态合作设计部"
        // headStyle={{ fontWeight: 600, fontSize: '14px', padding: '10px' }}
        style={{ marginLeft: '12px' }}
      >
        <div className={c.org_card_title}>生态合作设计部</div>
        <Table
          style={{ width: '100%', marginTop: '16px' }}
          columns={columns}
          dataSource={orgTableData.records}
          pagination={{
            size: 'small',
            showSizeChanger: true, //设置每页显示数据条数
            showQuickJumper: true,
            showTotal: () => `共${orgTableData.total}条`,
            pageSize: pageSize,
            total: orgTableData.total, //数据的总的条数
            onChange: (current, pageSize) => {
              setPageSize(pageSize);
              setCurrent(current);
              changeTableData(current, pageSize);
            }, //点击当前页码
            onShowSizeChange: (current, pageSize) => {
              //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
              setPageSize(pageSize);
              setCurrent(current);
              changeTableData(current, pageSize);
            },
          }}
        />
      </Card>
      <Modal
        width={424}
        wrapClassName={c.authModal}
        closeIcon
        title="角色授权"
        visible={visible}
        onOk={hideOk}
        onCancel={hideModal}
        okText="确认"
        cancelText="取消"
        closeIcon={<IconFont type="icon-Close" />}
      >
        <Form onFinish={onFinish} form={form}>
          <Form.Item name="角色" label="角色">
            <Select
              mode="multiple"
              defaultValue={selectValue}
              placeholder="请选择"
              onChange={onGenderChange}
              allowClear
              key={key}
            >
              {roleList.map((item) => {
                return (
                  <Option value={item.id} key={item.id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
