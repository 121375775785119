/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\Sift_PersonSelector\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-22 13:52:56
 * :last editor: hyw
 * :date last edited: 2022-04-25 22:48:30
 */
import { useState, useEffect, useRef } from 'react';
import { Input, Select } from 'antd';
import MultipleChoice from '@/components/MultipleChoice';
import { isJSON, dropSearchTypeProp, customJP } from '../../utils';
import { SearchTypeValue, SEARCH_TYPE_OPTIONS_OF_B_E } from '../../constant';
import { IFormCompProps } from '../../typings';
import c from './index.less';

type WidgetProps = IFormCompProps;

const { Option } = Select;

const getEmployeeNames = (emps: Array<{ [key: string]: any }> | undefined) => {
  if (Array.isArray(emps)) {
    return emps.map((item) => item.name);
  }
  return [];
};

const TPersonSelector = (props: WidgetProps) => {
  const { value, onChange = () => {}, schema = {} } = props;
  const { searchType: initialSearchType, value: initialValue } = isJSON(value)
    ? customJP(value)
    : { searchType: undefined, value: undefined };
  const { placeholder = '请选择', hideSearchType = false } = schema;
  const [selected, setSelected] = useState();
  // 限制人员选择器选择类型
  let supportedTabs = [];
  if (schema.$id === 'createUserId' || schema.$id.includes('innerContact')) {
    supportedTabs = ['人员'];
  } else if (schema.$id.includes('department_')) {
    supportedTabs = ['部门'];
  } else {
    supportedTabs = ['角色', '人员', '部门'];
  }

  const defaultValue = SearchTypeValue.EQUAL;

  const [searchType, setSearchType] = useState(
    initialSearchType || defaultValue,
  );
  const childRef = useRef(null);
  const [employees, setEmployees] = useState(initialValue);
  const [employeeNames, setEmployeeNames] = useState(
    getEmployeeNames(initialValue),
  );

  const maxLength = 20;

  const clear = () => {
    setEmployees([]);
    setEmployeeNames([]);
  };

  const getDataBack = (data: any) => {
    setSelected(data);
    console.log('人啊： ', data);
    const departments = data.departments.map((item) => ({
      ...item,
      stitchId: `dept_${item.id}`,
    }));
    const roles = data.roles.map((item) => ({
      ...item,
      stitchId: `role_${item.id}`,
    }));
    const users = data.users.map((item) => ({
      ...item,
      stitchId: item.userId,
    }));
    setEmployees([...departments, ...roles, ...users]);
    setEmployeeNames(getEmployeeNames([...departments, ...roles, ...users]));
    // setEmployees(
    //   Object.keys(data).reduce((acc, cur) => {
    //     return acc.concat(data[cur].map((v) => v.name));
    //   }, []),
    // );
  };

  useEffect(() => {
    const payload = {
      searchType,
      value: employees,
    };
    onChange(
      employees?.length
        ? JSON.stringify(dropSearchTypeProp(payload, hideSearchType))
        : '',
    );
  }, [searchType, employees]);

  useEffect(() => {
    if (isJSON(value)) {
      const valueObj = customJP(value);
      setSearchType(valueObj.searchType);
      setEmployees(valueObj.value);
      setEmployeeNames(getEmployeeNames(valueObj.value));
    } else {
      clear();
    }
  }, [value]);

  return (
    <Input.Group compact className="fr-group">
      <div className={c.t_person_selector} style={{ width: '100%' }}>
        {!hideSearchType && (
          <Select
            style={{ width: '30%' }}
            // allowClear
            defaultValue={defaultValue}
            value={searchType}
            onChange={(e) => {
              clear();
              setSearchType(e);
            }}
          >
            {SEARCH_TYPE_OPTIONS_OF_B_E.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        )}

        <Input
          style={{ width: '70%' }}
          placeholder={placeholder}
          maxLength={maxLength}
          value={
            employeeNames.join('、')?.length > maxLength
              ? employeeNames.join('、') + '...'
              : employeeNames.join('、')
          }
          onClick={() => (childRef?.current as any)?.openModal()}
          allowClear
          onChange={(e) => {
            clear();
          }}
        />
        <MultipleChoice
          cRef={childRef}
          initData={selected}
          multiple={searchType === SearchTypeValue.BELONG}
          getDataBack={getDataBack}
          tabs={supportedTabs}
          title="选择人员"
        />
      </div>
    </Input.Group>
  );
};

export default TPersonSelector;
