/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\BizSuite\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-24 11:14:07
 * :last editor: 黄艺
 * :date last edited: 2022-03-16 16:47:22
 */

import { FC } from 'react';
import { WidgetProps } from 'typings';
import c from './index.less';

const BizSuite: FC<WidgetProps> = (props) => {
  const { readOnly, value, schema = {} } = props;

  const list = Object.values(schema.properties || {}).filter((v) => !v.hidden);

  return (
    <div className={c.container}>
      {list.map((item) => (
        <div className={c.item} key={item.id}>
          {item.title}
        </div>
      ))}
    </div>
  );
};

export default BizSuite;
