/*
 * :file description:
 * :name: \tj-ali-crm-mobiled:\progree\low-code-platform\tg-renderer\packages\form-render\src\widgets\BillingSetting\components\BillingSettingItem.tsx
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-10 09:16:57
 * :last editor: Please set LastEditors
 * :date last edited: 2022-05-17 16:01:14
 */
import { Switch, Modal, Tooltip } from 'antd';
import { useState, useMemo } from 'react';
import { useModel } from 'umi';
import _ from 'lodash';
import IconFont from '~@/packages/table-render/src/components/IconFont';
import RelationSettingModal from '../../../components/RelationSettingModal';
import FilterModal from '../../DataFilters/FilterModal';
import FillModal from '../../DataFill/FillModal';

import c from '../index.less';

/**
 * @description: 套件的属性定制组件   标准版本不显示数据填充功能
 * @param {*} param1 通过versionLimit来判断是否为高级版本
 */
const BillingSettingItem = ({ schema, addons }) => {
  console.log(
    '🚀 ~ file: BillingSettingItem.tsx ~ line 19 ~ BillingSettingItem ~ schema',
    schema,
    addons,
  );
  const { versionLimit } = useModel('useGenerator');
  const { dataSource, dataFillingRules } = schema;
  const [visible, setVisible] = useState(false);
  const [dataFillVisible, setDataFillVisible] = useState(false);

  const handleShowModal = (visible: boolean) => {
    setVisible(visible);
  };

  const dispatchSchame = (key: string, value: boolean) => {
    const path = `properties.${schema.id}.` + key;
    addons.setValueByPath(path, value);
  };

  const isBothNotSelected = (
    checked: boolean,
    propertiesList: any,
    field: string,
    flag: boolean,
  ) => {
    const contact = propertiesList
      .filter((i) => !!i.id)
      ?.map((item: any) => {
        if (item.id === schema.id) {
          return {
            ...item,
            [field]: flag ? checked : !checked,
          };
        }
        return item;
      });
    return contact.find((item) => (flag ? item[field] : !item[field]));
  };

  const setAllReruireState = () => {
    const formData = addons.getValues();
    const properties = _.cloneDeep(formData.properties);
    const propertiesList = Object.values(properties);
    const showList = propertiesList.filter((i) => !!i.id && !i.hidden);
    if (!showList.length) {
      addons.setValueByPath('required', false);
      return;
    }
    const res = showList.find((item) => item?.required);
    if (res) {
      addons.setValueByPath('required', true);
    } else {
      addons.setValueByPath('required', false);
    }
  };

  const handleContact = (checked: boolean) => {
    dispatchSchame('hidden', !checked);
    if (checked) {
      dispatchSchame('required', checked);
    }
    setAllReruireState();
  };

  const handleRequired = (checked: boolean) => {
    dispatchSchame('required', checked);
    setAllReruireState();
  };

  return (
    <div className={c.item_container}>
      <section>
        <div className={c.label}>关联{schema.title}:</div>
      </section>
      {!schema.hidden && (
        <>
          <section>
            <div className={c.label}>关联字段</div>
            <div className={c.content}>
              <RelationSettingModal schema={schema} addons={addons}>
                <span className={c.anticon_plus}>+添加字段</span>
              </RelationSettingModal>
            </div>
          </section>
          <section>
            <div className={c.label}>数据过滤</div>
            <div className={c.content}>
              <div
                className={c.field}
                onClick={() => {
                  handleShowModal(true);
                }}
              >
                <div className={c['field-text']}>
                  {dataSource?.params?.filters?.length
                    ? '已添加过滤条件'
                    : '请添加过滤条件'}
                </div>
                <IconFont className={c['field-icon']} type="icon-bianji1" />
              </div>
            </div>
          </section>
          {versionLimit?.widgetProperties?.relation?.includes(
            'dataFillingRules',
          ) && (
            <section>
              <div className={c.label}>数据填充</div>
              <div className={c.content}>
                <div
                  className={c.field}
                  onClick={() => {
                    setDataFillVisible(true);
                  }}
                >
                  <div className={c['field-text']}>
                    {dataFillingRules?.length
                      ? '已设置填充规则'
                      : '请设置填充规则'}
                  </div>
                  <IconFont className={c['field-icon']} type="icon-bianji1" />
                </div>
              </div>
            </section>
          )}
          {/* <section>
            <div className={c.label}>关联数据更新时，是否更新此表单?</div>
            <div className={c.content}>
              <Switch
                checked={schema.isRelateForm}
                onChange={(checked) => dispatchSchame('isRelateForm', checked)}
              ></Switch>
            </div>
          </section> */}
          <section>
            <div className={c.label}>必填</div>
            <div className={c.content}>
              <Switch
                checked={schema.required}
                onChange={(checked) => handleRequired(checked)}
              ></Switch>
            </div>
          </section>
          <FilterModal
            visible={visible}
            id={schema.id}
            onCancel={() => {
              handleShowModal(false);
            }}
            initValues={dataSource?.params?.filters}
            onChange={(values) => {
              handleShowModal(false);
              dispatchSchame('dataSource.params.filters', values);
            }}
          ></FilterModal>
          <FillModal
            id={schema.id}
            widgetType={'bizSuite'}
            modalVisible={dataFillVisible}
            setModalVisible={setDataFillVisible}
            value={dataFillingRules}
            addons={addons}
          ></FillModal>
        </>
      )}
      <section>
        <div className={c.label}>
          <span className={c.labelBt}>启用</span>
          <Tooltip
            placement="top"
            title={'启用开关若关闭，该字段将不会在前台任意位置显示'}
          >
            <span className={c.popoverIcon}>
              <IconFont type="icon-shuoming"></IconFont>
            </span>
          </Tooltip>
        </div>
        <div className={c.content}>
          <Switch
            checked={!schema.hidden}
            onChange={(checked) => handleContact(checked)}
          ></Switch>
        </div>
      </section>
    </div>
  );
};

export default BillingSettingItem;
