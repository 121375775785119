/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DataFilters\FilterModal\FixedInnerContact\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-31 14:07:27
 * :last editor: hyw
 * :date last edited: 2022-02-23 15:55:49
 */
import { useRef } from 'react';
import MultipleChoice from '@/components/MultipleChoice';
import Style from './index.less';

const FixedInnerContact = (props) => {
  const { onChange, value, filterType } = props;

  const childRef = useRef(null);
  const multiple = filterType === 'IN';

  const getDataBack = (data: any) => {
    const users = data?.users || [];
    const val = users.map((user) => {
      return {
        key: user.userId || user.key,
        value: user.name,
      };
    });

    onChange(val);
  };

  const users = (value || []).map((item) => {
    return {
      key: item.key,
      name: item.value,
    };
  });

  return (
    <div>
      <div
        className={`${Style.field} fixed-inner-contact`}
        onClick={() => {
          (childRef?.current as any)?.openModal();
        }}
      >
        {users.map((user) => {
          return (
            <div key={user.key} className={Style['field-item']}>
              {user.name}
            </div>
          );
        })}
      </div>

      <MultipleChoice
        cRef={childRef}
        initData={{
          users,
        }}
        getDataBack={getDataBack}
        tabs={['人员']}
        multiple={multiple}
        title="选择人员"
      />
    </div>
  );
};

export default FixedInnerContact;
