/*
 * :file description:
 * :name: /low-code-platform/src/services/form-generator/index.ts
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-19 15:30:19
 * :last editor: caihua
 * :date last edited: 2022-11-24 16:43:52
 */
import { postRequest, getRequest } from '@/common/http';
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';

interface IGetIconListParams {
  moduleCode: string;
}
// 获取icon列表
export async function getIconList(data: IGetIconListParams) {
  return getRequest(`/api/common/icon/getIconList`, data);
}

export interface ISaveFormSchema {
  appUuid?: string;
  content: string;
  corpId?: string;
  description?: string;
  formCode?: string;
  icon: string;
  title: string;
  userId?: string;
  propsSettings: any;
}
// 新建-编辑表单
export async function saveFormSchema(data: ISaveFormSchema) {
  return postRequest<API.BaseResult<string>>(
    `/api/form/schema/saveFormSchema`,
    data,
  );
}

// 获取产品分类数据
export async function getCategoryTree(data: any) {
  return getRequest<API.BaseResult<string>>(
    `${
      isOpenInTripartitePlatform('1688') ? DOMAIN_QRCODE_1688 : DOMAIN_QRCODE
    }/api/v1/management/productCategory/getCategoryTree`,
    data,
  );
}

interface IGetVisibleFormListParams {
  appUuid?: string;
  corpId?: string;
  formCode: string;
  userId?: string;
}

interface IGetVisibleFormListItem {
  description: string;
  formCode: string;
  gmtCreate: string;
  gmtModified: string;
  icon: string;
  iconColor: string;
  title: string;
}

//  获取可见的表单列表
export async function getVisibleFormList() {
  return postRequest<API.BaseResult<IGetVisibleFormListItem[]>>(
    `/api/form/schema/getVisibleFormList`,
  );
}

// 获取oa审批关联表单
export async function getOaSchemaList(data?: any, config?: any) {
  return getRequest<API.BaseResult<IGetVisibleFormListItem[]>>(
    `/api/form/schema/getOaSchemaList`,
    data,
    config,
  );
}

// 获取oa审批关联表单详情
export async function getOaFormSchema(data?: any, config?: any) {
  return getRequest<API.BaseResult<IGetVisibleFormListItem[]>>(
    `/api/form/schema/getOaFormSchema`,
    data,
    config,
  );
}

// 获取钉钉授权二维码
export async function authorize(data?: any) {
  return getRequest(`/api/qrcode/form/oa/authorize/address/create`, data);
}

interface IGetFormSchema {
  content: string;
  description: string;
  formCode: string;
  formType: string;
  gmtCreate: string;
  gmtModified: string;
  icon: string;
  iconColor: string;
  processId: string;
  status: string;
  title: string;
  propsSettings: any;
}
// 查询表单schema
export async function getFormSchema(data: IGetVisibleFormListParams) {
  return postRequest<API.BaseResult<IGetFormSchema>>(
    `/api/form/schema/getFormSchema`,
    data,
  );
}

interface ISchemaAndProcessParams {
  formCode: string;
}

// 复制schema与流程
export async function copySchemaAndProcess(data: ISchemaAndProcessParams) {
  return postRequest<API.BaseResult<string>>(
    `/api/form/schema/copySchemaAndProcess`,
    data,
  );
}

// 删除表单
export async function deleteFormSchema(data: ISchemaAndProcessParams) {
  return postRequest<API.BaseResult>(`/api/form/schema/deleteFormSchema`, data);
}

interface IncrSeq {
  appUuid?: string;
  corpId?: string;
  formCode: string;
  maxCount: number;
  reset: string;
}

// 表单运行态-流水号获取自增位数组件的值
export async function incrSeq(data: IncrSeq) {
  return postRequest<API.BaseResult>(`/api/formInstance/incrSeq`, data, {
    headers: {
      formCode: data.formCode,
    },
  });
}

// 获取当前企业下可以单表可增加条数
export function formQuotaQuery(data?: any) {
  return getRequest(
    `${
      isOpenInTripartitePlatform('1688') ? DOMAIN_QRCODE_1688 : DOMAIN_QRCODE
    }/api/v1/usercenter/formQuota/query`,
    data,
  );
}

// 查询表单字段是否关联其他表单，及关联表单列表
export function getRelationByFieldId(data: any) {
  return postRequest(`/api/form/relation/getRelationByFieldId`, data);
}

/**
 * 获取高级外勤表单字段被引用列表
 * @returns
 */
export async function getLegworkFieldRefs() {
  return getRequest(
    `${
      isOpenInTripartitePlatform('1688') ? DOMAIN_QRCODE_1688 : DOMAIN_QRCODE
    }/api/v1/legwork/design/form/field/ref`,
  );
}

/**
 * 查看表单控件是否被门户关联
 * @param formCode
 * @param fieldId
 */
export function isRelatedBookbaba(formCode: string, fieldId: string) {
  return getRequest(
    `${
      isOpenInTripartitePlatform('1688') ? DOMAIN_QRCODE_1688 : DOMAIN_QRCODE
    }/api/v1/management/site/config/isRelated`,
    {
      formCode,
      fieldId,
    },
  );
}
// 判断是否是一方集成开通
export const getIsIntegrationApp = () => {
  return getRequest(
    `${
      isOpenInTripartitePlatform('1688') ? DOMAIN_QRCODE_1688 : DOMAIN_QRCODE
    }/api/v1/usercenter/company/isIntegrationApp`,
  );
};

// 获取流程列表数据
export const getProcessList = (data: any) => {
  return postRequest(`/api/processDefinition/getFormProcessList`, data);
};

// 获取级联列表
export async function getCascadeOptionList(data: any) {
  return postRequest(`/api/form/schema/listCascadeOptionByIds`, data);
}
