/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\Relation\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 14:28:41
 * :last editor: hyw
 * :date last edited: 2022-03-01 20:20:40
 */
import { FC } from 'react';
import { WidgetProps } from 'typings';
import c from './index.less';
import cx from 'classnames';
import { useModel } from 'umi';

const Relation: FC<WidgetProps> = (props) => {
  const { schema = {} } = props;
  // const { allFormList } = useModel('useGenerator', (model) => model);
  let fields: any;
  if (schema?.relateSource?.length) {
    fields =
      {
        ...schema?.relateSource?.[0]?.fields,
        ...schema?.relateSource?.[1]?.fields,
      } || {};
  } else {
    fields = schema?.fields || {};
  }
  // const formCode = schema.dataSource?.target?.formCode;

  // let formFieldsList = {};
  // if (!formCode) {
  //   let personalFormCode, customerFormCode;
  //   schema.relateSource?.forEach((item) => {
  //     if (item.bizType === 'crm_customer_personal') {
  //       personalFormCode = item.dataSource.target?.formCode;
  //     }
  //     if (item.bizType === 'crm_customer') {
  //       customerFormCode = item.dataSource.target?.formCode;
  //     }
  //   });
  //   let peronalContentStr, customerContentStr;
  //   if (personalFormCode) {
  //     peronalContentStr = allFormList.find(
  //       (formItem) => formItem.formCode === personalFormCode,
  //     )?.content;
  //   }
  //   if (customerFormCode) {
  //     customerContentStr = allFormList.find(
  //       (formItem) => formItem.formCode === customerFormCode,
  //     )?.content;
  //   }
  //   let peronalContentParse = {},
  //     customerContentParse = {};
  //   if (peronalContentStr) {
  //     peronalContentParse = JSON.parse(peronalContentStr)?.properties;
  //   }
  //   if (customerContentStr) {
  //     customerContentParse = JSON.parse(customerContentStr)?.properties;
  //   }
  //   formFieldsList = {
  //     ...peronalContentParse,
  //     ...customerContentParse,
  //   };
  // } else {
  //   const contentStr = allFormList.find(
  //     (formItem) => formItem.formCode === formCode,
  //   )?.content;
  //   if (contentStr) {
  //     formFieldsList = JSON.parse(contentStr)?.properties;
  //   }
  // }

  // const formFieldsKeys = Object.keys(formFieldsList);
  const fieldList = Object.keys(fields).map((key) => {
    const { format, title, hidden, deleted, hideControls } = fields[key] ?? {};
    // const fld = formFieldsKeys.includes(key);
    // console.log(fields[key], '======')
    // const isHidden = formFieldsList[key]?.hidden;
    return {
      key,
      format,
      title,
      tips: (!hideControls && hidden) || deleted ? '字段已删除或停用' : '',
    };
  });

  return (
    <div className={c.container}>
      {fieldList.map((item) => (
        <div key={item.key} className={cx(c.item, item.tips && c.disabled)}>
          <span>{item.title}</span> <i className={c.tips}>{item.tips}</i>
        </div>
      ))}
    </div>
  );
};

export default Relation;
