/*
 * @Description:
 * @FilePath: /low-code-platform/src/pages/generator/relation/shareData/WidgetModal/KpContact/index.tsx
 * :author: wu.shangru
 * :copyright: (c) 2023, Tungee
 * @Date: 2023-12-25 19:53:33
 * @LastEditors: wu.shangru
 * @LastEditTime: 2023-12-26 13:47:20
 */
import React, { useState, useEffect, useMemo } from 'react';
import { batchUnlockContact } from '../api';
import styles from './index.less';

const Contact = ({ record, bizType, formCode }) => {
  const { kpContactInfo, instanceId } = record;
  const [contactList, setContactList] = useState([]);
  const profile = JSON.parse(localStorage.getItem('cloud_profile') || '{}');
  const { products } = profile;
  const userVersionInfo =
    JSON.parse(
      sessionStorage.getItem(`tungee_user_${window.__corpId}`) ?? '{}',
    ) || {};

  // 解锁联系人号码
  const getUnlockContact = () => {
    return new Promise((resolve, reject) => {
      batchUnlockContact({
        formCode,
        corpId: window.__corpId,
        userId: userVersionInfo.dtalkUserId,
        instanceIds: [instanceId],
      })
        .then((res) => {
          if (res.code === 200) {
            const result = res.result;
            const curContact = (result?.kpContacts || []).find(
              (item) => item.instanceId === instanceId,
            );
            resolve(curContact?.kpContactList ?? []);
          }
        })
        .catch(() => {
          reject();
        });
    });
  };

  useEffect(() => {
    if (kpContactInfo?.kpContactList?.length) {
      setContactList(kpContactInfo?.kpContactList);
    }
  }, [kpContactInfo]);

  // 点击图标查看
  const handleChangeVisible = () => {
    getUnlockContact().then((res) => {
      setContactList(res);
    });
  };

  // 打电话
  const openCall = (item: any) => {
    window.__openCallSDK?.('phone', {
      bizType,
      instanceId,
      contact: item.contact,
      name: item.contact_name,
    });
  };

  // 拨号
  const handleDiagPhone = (item, idx) => {
    if (item.is_unlock) {
      getUnlockContact().then((res) => {
        setContactList(res);
        openCall(res[idx]);
      });
    } else {
      openCall(item);
    }
  };

  // 是否开通智能呼叫且在可见范围
  const isOpenCall = useMemo(() => {
    const callInfo = (products || []).find((c_m) => c_m.product_type === 2); // 是否开通智能呼叫
    return callInfo && callInfo.is_visible && callInfo.product_status === 1; // 在可见范围并在有效期内
  }, [products]);

  return (
    <div className={styles['kp-contact-wrap']}>
      {contactList.map((item, idx) => (
        <div key={item.contact_id} className={styles['kp-contact-item']}>
          {item?.contact_name && <span>{item?.contact_name}：</span>}
          <span style={{ color: '#007FFF', userSelect: 'none' }}>
            {item.contact}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Contact;
