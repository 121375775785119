/*
 * :file description:
 * :name: \low-code-platform\src\services\form-generator\index.ts
 * :author: 宋海兵
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-26 15:30:19
 * :last editor: 宋海兵
 * :date last edited: 2021-10-26 16:28:54
 */
import { postRequest, getRequest } from '@/common/http';

// 获取角色树列表
export async function getRoleTree() {
  return getRequest(`/api/role/tree`);
}

interface IGetMenuTree {
  roleId?: string;
}
// 角色权限-获取权限表单
export async function getMenuTree(data: IGetMenuTree) {
  return postRequest(`/api/menu/role-module-perm/list`, data);
}

interface IRelateMenu {
  roleId: string;
  menuIdList: Array<string>;
}
// 为角色授予菜单
export async function relateMenu(data: IRelateMenu) {
  return postRequest(`/api/menu/role-module-auth/relate`, data);
}
