/*
 * :file description:layout index
 * :name: \low-code-platform\src\layouts\index.tsx
 * :author: ershing
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-11 14:07:47
 * :last editor: hyw
 * :date last edited: 2022-04-08 20:24:57
 */
import { useState, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import NormalHeader from './components/NormalHeader';
import GeneratorHeader from './components/GeneratorHeader';
import SideBar from './components/SideBar';
import c from './index.less';
import { getUrlParam, getHashParam } from '@/utils';
import cx from 'classnames';
import { findAllElement } from '../components/FormGenerator/utils/element';

export default function Layout({ history, children }: any) {
  const [layoutType, setLayoutType] = useState(2);

  const formCode = getHashParam('formCode');

  // 隐藏tab栏
  const hiddenTab = getUrlParam('hiddenTab');

  useEffect(() => {
    const pathname = history.location.pathname;
    if (['/login', '/authorize'].indexOf(pathname) > -1) {
      setLayoutType(3);
    } else if (
      /\/generator\/.*/.test(pathname)
      // ['/generator/form', '/generator/process'].indexOf(pathname) > -1
    ) {
      setLayoutType(2);
    } else if (window.__isMicroApp__) {
      setLayoutType(4);
    } else {
      setLayoutType(1);
    }
    // token从url传过来的情况
    const tungeeToken = getUrlParam('tungeeToken');
    const corpId = getUrlParam('corpId');
    if (tungeeToken && corpId) {
      if (!window.__corpId) {
        window.__corpId = getUrlParam('corpId');
      }
      sessionStorage.setItem(`tungee_token_${corpId}`, tungeeToken);
    }
  }, [history.location.pathname]);

  return (
    <ConfigProvider
      locale={zhCN}
      getPopupContainer={(node) => {
        return findAllElement(node);
      }}
    >
      <div style={{ height: '100%' }}>
        {layoutType === 1 && (
          <>
            <NormalHeader />
            <SideBar />
            <section className={c.normal}> {children}</section>
          </>
        )}

        {layoutType === 2 && (
          <>
            <div style={{ display: hiddenTab ? 'none' : 'unset' }}>
              <GeneratorHeader />
            </div>
            <section className={c.formGenerator}>{children}</section>
          </>
        )}

        {layoutType === 3 && <section className={c.login}>{children}</section>}

        {layoutType === 4 && (
          <section className={cx({ [c.micro_form]: !formCode })}>
            {children}
          </section>
        )}
      </div>
    </ConfigProvider>
  );
}
