/*
 * :file description:
 * :name: \low-code-platform\src\pages\form\components\ModalImport\ImportStep1\index.tsx
 * :author: PakJeon
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-01 15:32:20
 * :last editor: hyw
 * :date last edited: 2022-04-15 14:31:21
 */
import { useState, useContext } from 'react';
import { Button, Select, Upload, message, Checkbox, Spin } from 'antd';
import IconFont from '@/components/IconFont';
import formApi from '@/services/form';
import { FileImportType } from '@/services/form/data.d.ts';
import { FormConfigCtx } from '../../../[id]';
import { getFormCode } from '@/utils';
import c from './index.less';

const { Option } = Select;
const { Dragger } = Upload;

export interface OptionType {
  key: FileImportType;
  value: string;
}

export const options: OptionType[] = [
  {
    key: FileImportType.create,
    value: '仅新增数据',
  },
  {
    key: FileImportType.update,
    value: '仅更新数据',
  },
  {
    key: FileImportType.createAndUpdate,
    value: '更新和新增数据',
  },
];

type ImportStep1PropsType = {
  selectMode: OptionType;
  onSelectMode: (mode: OptionType) => void;
  trigger: boolean;
  onTriggerChange: (check: boolean) => void;
  onPreview: (data: any) => void;
  onUploadSuccess: (data: any) => void;
};

const ImportStep1 = (props: ImportStep1PropsType) => {
  const {
    onPreview,
    selectMode,
    trigger,
    onTriggerChange,
    onSelectMode,
    onUploadSuccess,
  } = props;
  const [loading, setLoading] = useState(false);
  const formConfigCtx = useContext(FormConfigCtx);

  const handleChange = (e: string) => {
    const item: OptionType = options.find(
      (item: OptionType) => item.key === e,
    ) as OptionType;
    onSelectMode(item);
  };

  const customRequest = (e) => {
    const file = e.file;
    const name = file.name;
    let formdata = new FormData();
    console.log(trigger, 'trigger');

    setLoading(true);
    formdata.append('file', e.file);
    formdata.append('keyPrefix', 'crm-foundation-form/instance/');
    formdata.append('importType', selectMode.key);
    formdata.append('formCode', getFormCode());
    formdata.append('needTriggerProcess', trigger ? '1' : '0');
    formApi.uploadFile(formdata).then(
      (res) => {
        setLoading(false);
        if (res.data && res.data.fileId) {
          onUploadSuccess(res.data);
          importPreview(res.data.fileId);
        }
      },
      (error) => {
        setLoading(false);
      },
    );
  };

  // 上传Excel的预览数据
  const importPreview = (fileId: string) => {
    setLoading(true);
    formApi
      .importPreview({
        fileId,
        formCode: getFormCode(),
        isUpdate: [
          FileImportType.update,
          FileImportType.createAndUpdate,
        ].includes(selectMode.key),
      })
      .then((res) => {
        setLoading(false);
        onPreview(res.data);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const draggerProps = {
    name: 'file',
    multiple: false,
    showUploadList: false,
    customRequest: customRequest,
    onChange(info) {
      const { status } = info.file;
      if (status === 'error') {
        message.warning(`${info.file.name} 上传失败.`);
      }
    },
    beforeUpload: (file: File) => {
      const limit5M = 1024 * 1024 * 5; // 限制文件最大5M
      if (file.size > limit5M) {
        message.warning('文件大小限制5M以内');
        return false;
      }
    },
  };

  const handleDownload = () => {
    formApi
      .getTemplate({
        formCode: getFormCode(),
      })
      .then((res: any) => {
        if (res.data && res.data.url) {
          let link = document.createElement('a');
          link.style.display = 'none';
          link.href = res.data.url;
          link.setAttribute('download', '');

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          // window.open(res.data.url);
        }
      });
  };

  const handleHelp = () => {
    window.open('https://www.yuque.com/ggah5a/xbpuwk/civhty');
  };

  const renderTips = () => {
    if (selectMode.key === FileImportType.create) {
      return (
        <>
          <div className={c.tipsRow}>
            建议下载模板，帮助你顺利完成导入{' '}
            <Button
              onClick={handleDownload}
              type="link"
              style={{ padding: 0, fontSize: 12, height: 16 }}
            >
              下载模板文件
            </Button>
          </div>
          <div className={c.tipsRow}>
            导入文件中的数据不能超过5W行，表格明细同样占用行数
          </div>
          <div className={c.tipsRow}>
            若勾选「导入的数据触发流程」，则数据不超过300行，且只有新增的数据触发审批流程，若不勾选则视为审批完成
          </div>
          <div className={c.tipsRow}>
            导入时创建人和创建时间按照填写的进行创建，不填写默认当前操作人为创建人，导入完成时间为创建时间
          </div>
          <div className={c.tipsRow}>
            更多导入说明和示例，请查看{' '}
            <Button
              onClick={handleHelp}
              type="link"
              style={{ padding: 0, fontSize: 12, height: 16 }}
            >
              帮助文档
            </Button>
          </div>
        </>
      );
    }
    return (
      <>
        <div className={c.tipsRow}>
          如需通过导入更新数据，请先导出需要更新的数据并完成修改
        </div>
        <div className={c.tipsRow}>
          系统按照匹配的数据id来更新数据，若「数据id」匹配不上，则不更新；若匹配出多条「数据id」重复，则逐条更新，直到最后一条数据
        </div>
        <div className={c.tipsRow}>
          导入文件中的数据不能超过5w行，表格明细同样占用行数
        </div>
        <div className={c.tipsRow}>
          若勾选「导入的数据触发流程」，则数据不超过300行，且只有新增的数据触发审批流程；若不勾选，则视为审批完成
        </div>
        <div className={c.tipsRow}>
          导入时创建人和创建时间按照填写的进行创建，不填写默认当前操作人为创建人，导入完成时间为创建时间；更新数据不支持修改创建人和创建时间；表格不支持更新
        </div>
        <div className={c.tipsRow}>
          更多导入说明和示例，请查看{' '}
          <Button
            onClick={handleHelp}
            type="link"
            style={{ padding: 0, fontSize: 12, height: 16 }}
          >
            帮助文档
          </Button>
        </div>
      </>
    );
  };

  return (
    <Spin spinning={loading}>
      <div className={c.step1}>
        <div className={c.selectRow}>
          <div className={c.rowTitle}>导入模式</div>
          <Select
            className={c.select}
            defaultValue={selectMode.key}
            value={selectMode.key}
            onChange={handleChange}
            style={{ width: 210 }}
          >
            {options.map((item) => (
              <Option value={item.key} key={item.key}>
                {item.value}
              </Option>
            ))}
          </Select>
        </div>

        <div className={c.stepTips}>{renderTips()}</div>

        <div className={c.uploadWrapper}>
          <Dragger {...draggerProps}>
            <div className={c.draggerContent}>
              <div className={c.draggerTips}>
                <IconFont
                  className={c.iconUpload}
                  type="icon-a-18downloadbeifen"
                />
                <div className={c.draggerText}>
                  选择或拖拽上传文件，单个文件5M以内
                </div>
              </div>
              {[FileImportType.create, FileImportType.createAndUpdate].includes(
                selectMode.key,
              ) &&
                formConfigCtx &&
                formConfigCtx.processId && (
                  <div onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      className={c.uploadCheckbox}
                      checked={trigger}
                      onClick={(e) => {
                        onTriggerChange(!trigger);
                      }}
                    >
                      导入数据触发流程
                    </Checkbox>
                  </div>
                )}
            </div>
          </Dragger>
        </div>
      </div>
    </Spin>
  );
};

export default ImportStep1;
