/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\form-render-core\src\create-form\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-11 17:48:13
 * :last editor: 黄艺
 * :date last edited: 2022-03-16 16:23:46
 */
import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Drawer, DrawerProps, Button, Space, Modal } from 'antd';
import { nanoid } from 'nanoid';
import { delay } from 'lodash-es';
import FormCreate from './form';
import IconFont from '../../../../../table-render/src/components/IconFont';

import c from './index.less';

interface IFormCreateProps {
  formCode: string;
  renderFormat: string;
  [key: string]: any;
}

const CreateForm = (props: DrawerProps & IFormCreateProps, ref) => {
  const { children, formCode: injectedFormCode, renderFormat } = props;
  const [createKey, setCreateKey] = useState(nanoid(12));
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [formCode, setFormCode] = useState(injectedFormCode);
  const [initData, setInitData] = useState();
  // 伸缩弹窗功能
  const [drawerWidth, setDrawerWidth] = useState(600);
  const [isShrink, setIsShrink] = useState(false);

  const formRef = useRef();

  const formatTitle = (title: string) => {
    return `新建${title}`;
  };

  const init = (data = {}) => {
    setTitle(data?.title);
  };

  const cb = () => {
    setVisible(false);
    setLoading(false);
    props.cb();
  };

  const onOk = () => {
    // TODO
    // 调用新建
    setLoading(true);
    formRef.current.doCreate();
    console.log('formRef: ', formRef);
  };

  const onClose = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    show: (payload: { [key: string]: any }) => {
      setVisible(true);
      setFormCode(payload?.relateFormCode);
      setInitData(payload);
    },
  }));

  //  改变详情弹窗功能
  const changeWidth = (bool) => {
    setDrawerWidth(bool ? 600 : window.innerWidth > 1366 ? 1100 : 1000);
    setIsShrink(!bool);
  };

  let renderContent = null;

  switch (renderFormat) {
    case 'drawer':
      renderContent = (
        <div className={c.t_create_form_drawer}>
          <Button
            className={c.create}
            type="primary"
            onClick={() => {
              setCreateKey(nanoid(12));
              setFormCode(injectedFormCode);
              setInitData(undefined);
              delay(() => setVisible(true), 0);
            }}
          >
            {children ?? (
              <>
                <IconFont type="icon-tianjia1" /> 新建
              </>
            )}
          </Button>
          <Drawer
            className={c.create_drawer}
            key={createKey}
            width={drawerWidth}
            placement="right"
            maskStyle={{
              background: 'transparent',
            }}
            visible={visible}
            title={formatTitle(title)}
            onClose={onClose}
            destroyOnClose={true}
            footer={
              <Space className={c.submit}>
                <Button onClick={onClose}>取消</Button>
                {/* loading={loading} */}
                <Button loading={loading} type="primary" onClick={onOk}>
                  提交
                </Button>
              </Space>
            }
          >
            <div className={c.enlargeOrshrink}>
              <IconFont
                type="icon-zhankai1"
                className={isShrink ? c.transForm : ''}
                onClick={() => changeWidth(isShrink)}
              />
              {/* <img src={shrink} alt="" /> */}
            </div>
            <div id="create-drawer-id">
              <FormCreate
                ref={formRef}
                renderFormat={renderFormat}
                formcode={formCode}
                init={init}
                initData={initData}
                setLoading={setLoading}
                cb={cb}
              />
            </div>
          </Drawer>
        </div>
      );
      break;
    case 'modal':
      renderContent = (
        <div className={c.t_create_form_drawer}>
          <Button
            className={c.create}
            type="primary"
            onClick={() => {
              setCreateKey(nanoid(12));
              delay(() => setVisible(true), 0);
            }}
          >
            {children ?? (
              <>
                <IconFont type="icon-tianjia1" /> 新建
              </>
            )}
          </Button>
          <Modal
            className={c.create_modal}
            key={createKey}
            width={600}
            visible={visible}
            title={formatTitle(title)}
            onCancel={onClose}
            closeIcon={<IconFont type="icon-Close" />}
            footer={
              <Space className={c.submit}>
                <Button onClick={onClose}>取消</Button>
                <Button loading={loading} type="primary" onClick={onOk}>
                  提交
                </Button>
              </Space>
            }
          >
            <FormCreate
              ref={formRef}
              renderFormat={renderFormat}
              formcode={formCode}
              init={init}
              initData={initData}
              setLoading={setLoading}
              cb={cb}
            />
          </Modal>
        </div>
      );
      break;
    case 'page':
      renderContent = (
        <div className={c.t_create_form_page}>
          <FormCreate
            renderFormat={renderFormat}
            formcode={formCode}
            init={init}
            initData={initData}
            cb={cb}
          />
        </div>
      );
      break;
  }

  return renderContent;
};

export default forwardRef(CreateForm);
