/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/process/components/ProcessDesign/constants.ts
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-04 11:04:33
 * :last editor: Quan
 * :date last edited: 2021-12-08 19:29:29
 */

import { getUuid } from '@/utils';

const nodeId = getUuid();
const defaultFlowData: any = [
  {
    type: '1',
    name: '发起人',
    userOrDepOrRole: {
      departments: [],
      roles: [],
      users: [],
    },
    formAuth: 'edit',
    authList: [],
  },
  // {
  //   type: '2',
  //   name: '审核人',
  //   userOrDepOrRole: {
  //     departments: [],
  //     roles: [],
  //     users: [],
  //   },
  //   formAuth: 'read',
  //   authList: [],
  // },
  // {
  //   type: '3',
  //   name: '抄送人',
  //   userOrDepOrRole: {
  //     departments: [],
  //     roles: [],
  //     users: [],
  //   },
  //   formAuth: 'read',
  //   authList: [],
  // },
];
const baseFormItem: any = {
  '1': [
    {
      name: 'name',
      placeholder: '请输入节点名称',
      label: '节点名称',
      rules: [{ required: true, message: '请输入节点名称' }],
    },
    {
      name: 'userOrDepOrRole',
      placeholder: '请选择提交人',
      label: '谁可以提交',
      rules: [{ required: true, message: '请选择提交人' }],
    },
  ],
  '2': [
    {
      name: 'name',
      placeholder: '请输入节点名称',
      label: '节点名称',
      rules: [{ required: true, message: '请输入节点名称' }],
    },
    {
      name: 'userOrDepOrRole',
      placeholder: '请选择审批人',
      label: '选择审批人',
      rules: [{ required: true, message: '请选择审批人' }],
    },
  ],
  '3': [
    {
      name: 'name',
      placeholder: '请输入节点名称',
      label: '节点名称',
      rules: [{ required: true, message: '请输入节点名称' }],
    },
    {
      name: 'userOrDepOrRole',
      placeholder: '请选择抄送人',
      label: '选择抄送人',
      rules: [{ required: true, message: '请选择抄送人' }],
    },
  ],
};

const DEFAULT_FLOW_SETTING = {
  nodes: [
    {
      _id: 'start',
      name: '开始',
      node_type: 'start',
      type: 'node',
    },
    {
      _id: nodeId,
      name: '请输入节点名称',
      node_type: 'other',
      type: 'node',
      branch_filter_condition: null,
      branch_level: 1,
      node_handler_type: 'charger',
      node_deadline_time_num: null,
      node_deadline_time_unit: 'hour',
      node_is_overtime: false,
      node_overtime_num: 0,
      node_overtime_unit: 'hour',
      node_attachments: [],
    },
    {
      _id: 'end',
      name: '结束',
      node_type: 'end',
      type: 'node',
    },
  ],
  edges: [
    {
      source: 'start',
      target: nodeId,
    },
    {
      source: nodeId,
      target: 'end',
    },
  ],
};

const ACTIVITY_TYPES_MAP = {
  node: 'node',
  branch: 'branch',
};

const NODE_TYPES_MAP = {
  start: 'start',
  end: 'end',
  other: 'other',
  gateway: 'gateway',
};

const GATEWAY_TYPES_MAP = {
  start: 'start',
  end: 'end',
};

const OPREATOR_TYPES_MAP = {
  add: 'add',
  delete: 'delete',
  edit: 'edit',
};

const NODE_HANDLER_TYPE: any = {
  charger: '负责人',
  collaborator: '协作人',
};

// 流程图总共不能超过的分支上限
const FLOW_TOTAL_BRANCH_COUNT = 30;

// 同层级下不能超过的分支上限
const GRATEWAY_BRANCH_COUNT = 10;

const defaultUserOrDepOrRole = {
  departments: [],
  users: [],
  roles: [],
  dynamicPerson: [],
};

// 书库  bookbaba_book
// 供应商  bookbaba_supplier
// 供应商库存 bookbaba_supplier_stock
// 采购单  bookbaba_purchase_order
// 采购明细单  bookbaba_purchase_order_detail
// 配货单 bookbaba_distribution
// 配货明细单 bookbaba_distribution_detail
// 总配货单 bookbaba_all_distribution
// 总配货明细单 bookbaba_all_distribution_detail
// 采购入库单  bookbaba_purchase_storage
// 入库明细表 bookbaba_purchase_storage_detail
// 采购退货单  bookbaba_purchase_return
// 退货明细表 bookbaba_purchase_return_detail
// 销售出库单  bookbaba_sale_outbound
// 出库明细表 bookbaba_sale_outbound_detail
// 销售退货单  bookbaba_sale_return
// 退货明细表 bookbaba_sale_return_detail
// 物流询价单 bookbaba_logistics_enquiry
// 加工询价单 bookbaba_machine_enquiry
// 其他询价单  bookbaba_other_enquiry
// 应付单  bookbaba_payable
// 应付配货明细表  bookbaba_payable_detail
// 付款单  bookbaba_payment
// 项目   pms_project
// 十八号库存 bookbaba_eighteen_stock
// 十八号库入库  bookbaba_eighteen_storage
// 十八号库存入库明细  bookbaba_eighteen_storage_detail
// 库存查询表  bookbaba_stock_inquiry
// 销售订单  bookbaba_order
// 销售订单明细表  bookbaba_order_detail
const BOOKE_BABA_BIZTYPE = [
  'bookbaba_purchase_storage',
  'bookbaba_purchase_return',
  'bookbaba_sale_return',
  'bookbaba_sale_outbound',
  'bookbaba_eighteen_storage',
];

export {
  defaultFlowData,
  defaultUserOrDepOrRole,
  baseFormItem,
  DEFAULT_FLOW_SETTING,
  ACTIVITY_TYPES_MAP,
  NODE_TYPES_MAP,
  GATEWAY_TYPES_MAP,
  OPREATOR_TYPES_MAP,
  NODE_HANDLER_TYPE,
  FLOW_TOTAL_BRANCH_COUNT,
  GRATEWAY_BRANCH_COUNT,
  BOOKE_BABA_BIZTYPE,
};
