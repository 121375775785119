/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DataFilters\components\BaseDataFilters\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-22 17:45:55
 * :last editor: 黄艺
 * :date last edited: 2022-03-22 18:03:06
 */
/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DataFilters\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-31 14:07:27
 * :last editor: 黄艺
 * :date last edited: 2022-03-22 17:31:48
 */
import { FC, useState } from 'react';
import { WidgetProps } from 'typings';
import IconFont from '@/components/IconFont';
// import FilterModal from './FilterModal';
import c from '../../index.less';
import FilterModal from '../../FilterModal';

export const customerTitleMap = new Map([
  ['crm_customer', '企业客户'],
  ['crm_customer_personal', '个人客户'],
]);

const BaseDataFilters: FC<WidgetProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const { addons } = props;
  const { getValues, setValueByPath } = addons;
  const formData = getValues();
  const { dataSource = {} } = formData;

  const handleSchema = (values) => {
    const filtersPath = `dataSource.params.filters`;
    setValueByPath(filtersPath, values);
  };

  const handleShowModal = (visible: boolean) => {
    setVisible(visible);
  };

  return (
    <div className={c.container}>
      <div
        className={c.field}
        onClick={() => {
          handleShowModal(true);
        }}
      >
        <div className={c['field-text']}>
          {dataSource?.params?.filters?.length
            ? '已添加过滤条件'
            : '请添加过滤条件'}
        </div>
        <IconFont className={c['field-icon']} type="icon-bianji1" />
      </div>
      <FilterModal
        visible={visible}
        onCancel={() => {
          handleShowModal(false);
        }}
        initValues={dataSource?.params?.filters}
        onChange={(values) => {
          handleShowModal(false);
          handleSchema(values);
        }}
      />
    </div>
  );
};

export default BaseDataFilters;
