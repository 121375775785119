/*
 * :file description:
 * :name: \low-code-platform\src\widgets\DefaultValueSelector\components\formula-editor\fomula-hint.js
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-04 09:16:18
 * :last editor: 黄艺
 * :date last edited: 2021-12-04 09:36:37
 */
import CodeMirror from 'codemirror';

const Formula = [
  'AND',
  'OR',
  'FALSE',
  'TRUE',
  'IF',
  'NOT',
  'XOR',
  'CONCATENATE',
  'EXACT',
  'LEFT',
  'LEN',
  'LOWER',
  'REPLACE',
  'REPT',
  'RIGHT',
  'SEARCH',
  'SPLIT',
  'TRIM',
  'UPPER',
  'MID',
  'AVERAGE',
  'COUNT',
  'COUNTIF',
  'LARGE',
  'MAX',
  'MIN',
  'SMALL',
  'ABS',
  'ROUND',
  'CEILING',
  'FLOOR',
  'INT',
  'LOG',
  'MOD',
  'POWER',
  'PRODUCT',
  'SQRT',
  'SUM',
  'SUMPRODUCT',
  'FIXED',
  'DATE',
  'TIME',
  'TIMESTAMP',
  'TODAY',
  'NOW',
  'SYSTIME',
  'DAY',
  'MONTH',
  'YEAR',
  'HOUR',
  'MINUTE',
  'SECOND',
  'DAYS',
  'DAYS360',
  'DATEDELTA',
  'ISOWEEKNUM',
  'WEEKNUM',
  'TEXT',
  'VALUE',
  'UUID',
  'RECNO',
  'ISEMPTY',
  'MAPX',
  'MAP',
  'GETUSERNAME',
];

function b(a, b) {
  for (var c = 0, d = a.length; c < d; ++c) b(a[c]);
}
function c(a, b) {
  if (!Array.prototype.indexOf) {
    for (var c = a.length; c--; ) if (a[c] === b) return !0;
    return !1;
  }
  return a.indexOf(b) != -1;
}
function d(b, c, d, e) {
  var h = b.getCursor(),
    i = d(b, h);
  if (!/\b(?:string)\b/.test(i.type)) {
    (i.state = CodeMirror.innerMode(b.getMode(), i.state).state),
      /^[\w$_]*$/.test(i.string)
        ? i.end > h.ch &&
          ((i.end = h.ch), (i.string = i.string.slice(0, h.ch - i.start)))
        : (i = {
            start: h.ch,
            end: h.ch,
            string: '',
            state: i.state,
            type: '.' == i.string ? 'property' : null,
          });
    for (var j = i; 'property' == j.type; ) {
      if (((j = d(b, g(h.line, j.start))), '.' != j.string)) return;
      if (((j = d(b, g(h.line, j.start))), !k)) var k = [];
      k.push(j);
    }
    return {
      list: f(i, k, c, e),
      from: g(h.line, i.start),
      to: g(h.line, i.end),
    };
  }
}
function e(b, c) {
  return d(
    b,
    Formula,
    function (a, b) {
      return a.getTokenAt(b);
    },
    c,
  );
}
function f(a, d, e, f) {
  function g(a) {
    0 != a.lastIndexOf(i, 0) || c(h, a) || h.push(a);
  }
  var h = [],
    i = a.string.toUpperCase();
  return i ? (d && d.length ? d.pop() : b(e, g), h) : h;
}
var g = CodeMirror.Pos;
CodeMirror.registerHelper('hint', 'formula', e);
('use strict');
CodeMirror.defineMode('formula', function () {
  function b(a) {
    for (var b = {}, c = 0, d = a.length; c < d; ++c) b[a[c]] = !0;
    return b;
  }
  function c(a, b) {
    if (a.eatSpace()) return null;
    var c = a.next();
    if ('"' === c || "'" === c) return d(a, c), 'string';
    if ('â€‹' === c) return d(a, c), 'field';
    if (/[\[\],\(\)]/.test(c)) return 'bracket';
    if (/[+\-*\/=<>!&|]/.test(c)) return 'operator';
    if (/\d/.test(c)) return a.eatWhile(/[\d\.]/), 'number';
    a.eatWhile(/[\w]/);
    var e = a.current();
    return f.hasOwnProperty(e)
      ? 'atom'
      : g.hasOwnProperty(e)
      ? 'keyword'
      : h.hasOwnProperty(e)
      ? 'deprecate'
      : null;
  }
  function d(a, b) {
    for (var c, d = !1; null != (c = a.next()); ) {
      if (c === b && !d) return !1;
      d = !d && '\\' === c;
    }
    return d;
  }
  function e(a, b) {
    return (b.tokens[0] || c)(a, b);
  }
  var f = b(['false', 'true']),
    g = b(Formula),
    h = b(['MAP']);
  return {
    startState: function () {
      return {
        tokens: [],
      };
    },
    token: function (a, b) {
      return e(a, b);
    },
    fold: 'brace',
  };
}),
  CodeMirror.defineMIME('text/fx-formula', 'formula');
