/*
 * :file description:
 * :name: \low-code-platform\src\components\CustomFormProfile\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 17:34:14
 * :last editor: 黄艺
 * :date last edited: 2022-04-20 09:12:51
 */
import { useState, useImperativeHandle, FC, useEffect } from 'react';
import { Form, Input, Row, Col, Tooltip } from 'antd';
import c from './index.less';
import IconSetting from './IconSetting';
import IconFont from '../IconFont';
import { useLocation, useModel } from 'umi';
import { editType } from '@/pages/generator/form';
import { useGlobal, useStore } from '@/components/FormGenerator/utils/hooks';
import classNames from 'classnames';

const { TextArea } = Input;

export interface IProfile {
  icon?: string;
  iconColor?: string;
  description?: string;
  title?: string;
  bizType?: string;
  formType?: number;
}
interface IProps {
  cRef: any;
  profile: IProfile;
}

const CustomFormProfile: FC<IProps> = ({ cRef }) => {
  const location = useLocation();
  const { type } = location?.query || {};
  const [form] = Form.useForm();
  const [showItemSettings, setShowItemSettings] = useState(false);
  const [showDesc, setShowDesc] = useState(false);
  const { selected } = useStore();
  const setGlobal = useGlobal();
  const { updateProfile, profile } = useModel('useGenerator', (model) => ({
    profile: model.profile,
    updateProfile: model.updateProfile,
  }));
  const { icon, iconColor, title, description, bizType, formType } =
    profile || {};

  const handleIconChange = ({ icon, iconColor }: IProfile) => {
    const values = form.getFieldsValue(true);
    updateProfile({ icon, iconColor, formType, ...values });
  };

  const handleFormChange = () => {
    const values = form.getFieldsValue(true);
    updateProfile({ icon, iconColor, formType, ...values });
  };

  const onSubmit = () => {
    const formFiles = form.getFieldsValue(true);
    return new Promise((resolve) => {
      resolve({ icon, iconColor, ...formFiles });
    });
  };

  const initProfile = () => {
    form.setFieldsValue({ title, description, bizType });
  };

  const handleFromProps = () => {
    setGlobal({ selected: null });
  };

  useImperativeHandle(cRef, () => ({
    onSubmit,
  }));

  // 如果没有选中任何item，或者是选中了根节点，object、list的内部，显示placeholder
  useEffect(() => {
    if ((selected && selected[0] === '0') || selected === '#' || !selected) {
      setShowItemSettings(false);
    } else {
      setShowItemSettings(true);
    }
  }, [selected]);

  useEffect(() => {
    initProfile();
  }, [description, title, icon, iconColor]);

  return (
    <Form
      className={c.container}
      onChange={handleFormChange}
      form={form}
      name="name"
      autoComplete="off"
      layout="vertical"
    >
      <Row>
        <Col flex="60px">
          <Tooltip
            overlayClassName={c.tooltip}
            placement="rightTop"
            trigger="click"
            title={<IconSetting onChange={handleIconChange} />}
            color="white"
          >
            <div className={c.img} style={{ background: iconColor }}>
              <IconFont className={c.selectedIcon} type={icon!}></IconFont>
              <div className={c.editIcon}>
                <IconFont
                  className={c.selectedIcon}
                  type="icon-bianji"
                ></IconFont>
              </div>
            </div>
          </Tooltip>
        </Col>
        <Col flex="auto">
          <Form.Item
            label="表单名称"
            name="title"
            rules={[{ required: true, message: '表单名称不能为空!' }]}
          >
            <Input maxLength={100} placeholder="请输入最多输入100个字" />
          </Form.Item>
          {showDesc || type === editType.EDIT ? (
            <Form.Item label="表单说明" name="description">
              <TextArea
                maxLength={100}
                placeholder="请输入最多输入100个字"
                style={{ resize: 'none' }}
              />
            </Form.Item>
          ) : (
            <div className={c.desc} onClick={() => setShowDesc(true)}>
              <IconFont type="icon-bianjishuoming"></IconFont>添加表单说明
            </div>
          )}
          {/* 未定 暂时这样写 方便调试 */}
          {/* <Form.Item label="表单别名（功能调试用）" name="bizType">
            <Input placeholder="请输入" />
          </Form.Item> */}
          <div
            className={classNames(
              c.desc,
              c.setting,
              !showItemSettings && c.show_setting,
            )}
            onClick={() => handleFromProps()}
          >
            <IconFont
              className={c.setting_icon}
              type="icon-shezhi_1"
            ></IconFont>
            表单属性设置
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default CustomFormProfile;

// 1. 开发改动组件库导致与ui预先商量好的组件库有出入，设计和开发信息不一致。
// 2. 前端开发时间紧张，前期估时没考虑ui样式适配问题,估时偏乐观，导致后期对齐ui样式时间不够。
// 1. 开发改动组件库导致与ui预先商量好的组件库有出入，设计和开发信息不一致。
