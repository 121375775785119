import React from 'react';
import { InputNumber } from 'antd';

export default (props) => {
  const { placeholder } = props;

  return (
    <div>
      <InputNumber
        min={0}
        max={100}
        precision={0}
        style={{ width: '100%' }}
        placeholder={placeholder}
      />
    </div>
  );
};
