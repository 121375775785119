/*
 * :file description:
 * :name: \low-code-platform\src\utils\auth.ts
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-04 18:40:29
 * :last editor: hyw
 * :date last edited: 2022-02-25 15:20:30
 */

interface authProps {
  orConsArr?: Array<any>;
  withConsArr?: Array<any>;
  checkCode?: string;
  authList?: Array<any>;
}

const isFalsy = (value: any) => {
  return Boolean(value);
};

// isFalsy(""); // false
// isFalsy("0"); // true
// isFalsy(0); // false

// 获取表单下的按钮权限
const getBtnAuth = (checkCode: string, authList: Array<any>) => {
  const allAuthList = localStorage.getItem('allAuthList') || '[]';

  // 首先使用客户自己传入的权限列表
  const list = authList.length ? authList : JSON.parse(allAuthList);
  // let remainData = authList.filter((val) =>
  //   val.authorityCode.includes(formCode),
  // );
  // let currentAuth = remainData.some((val) => {
  //   return val.bizCode == btnKey;
  // });
  const currentAuth = list.some((val: any) => {
    return val.authorityCode == checkCode;
  });
  return currentAuth;
};

export const judgeByCons = (props: authProps) => {
  const {
    orConsArr = [],
    withConsArr = [],
    checkCode = '',
    authList = [],
  } = props;

  // 需要判断的条件长度收录起来
  const needJudgeArr = [];

  // console.log(111112222, '------', props);
  // 满足条件的收录起来
  const fillJudgeArr = [];
  let isEmpty = false;

  // 有传入该字段的情况
  if (props.orConsArr) {
    if (orConsArr.length) {
      needJudgeArr.push(true);
      const orTrue = orConsArr.some((val) => isFalsy(val));
      orTrue && fillJudgeArr.push(true);
      // console.log(orTrue, 'orTrue', 1111);
    } else {
      isEmpty = true;
    }
  }

  if (props.withConsArr) {
    if (withConsArr.length) {
      needJudgeArr.push(true);
      const withTrue = withConsArr.every((val) => isFalsy(val));
      withTrue && fillJudgeArr.push(true);
      // console.log(withTrue, 'withTrue', 1111);
    } else {
      isEmpty = true;
    }
  }

  // 存在传入条件为空的情况，直接不显示
  if (isEmpty) {
    return false;
  }

  if (checkCode) {
    needJudgeArr.push(true);
    const bthAuth = getBtnAuth(checkCode, authList);
    bthAuth && fillJudgeArr.push(true);
  }

  // 无判断条件传入时直接不渲染内容
  if (needJudgeArr.length === 0) {
    return false;
  }
  //满足所有条件才显示
  // console.log(1111, 'fillJudgeArr', fillJudgeArr);

  const isRender = needJudgeArr.length === fillJudgeArr.length;
  return isRender ? true : false;
};
