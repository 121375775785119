/*
 * @Description:
 * @FilePath: /low-code-platform/tg-renderer/packages/form-render/src/widgets/ShowNumLocation/index.tsx
 * :author: wu.shangru
 * :copyright: (c) 2023, Tungee
 * @Date: 2023-11-29 21:12:24
 * @LastEditors: wu.shangru
 * @LastEditTime: 2023-12-01 11:51:38
 */
import React from 'react';
import { Switch, Tooltip, Modal, Popover } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Styles from './index.less';
import IconFont from '@/components/IconFont';
import numLocationTipImg from '@/assets/numLocationTip.png';

const { confirm } = Modal;

const ShowNumLocation = ({ value, ...rest }) => {
  const { onChange, hiddenEditableFreeze = false } = rest || {};
  const { hiddenEditableFreeze: hiddenEditableFreezeInSchema } = rest?.schema;

  const content = () => {
    return (
      <div className={Styles.popover}>
        <div className={Styles['popover-desc']}>
          {'启用后，电话号码将显示号码归属地'}
        </div>
        <img src={numLocationTipImg} alt="" />
      </div>
    );
  };

  const handleChange = (checked: boolean, e: Event) => {
    onChange(checked);
  };
  return (
    <div className={Styles.wrapper}>
      <Popover placement="bottom" content={content} trigger="hover">
        <IconFont
          type="icon-shuoming"
          className={Styles['popoverIcon']}
        ></IconFont>
      </Popover>
      <Switch
        checked={value}
        {...rest}
        disabled={hiddenEditableFreeze || hiddenEditableFreezeInSchema}
        onChange={handleChange}
      />
    </div>
  );
};

export default ShowNumLocation;
