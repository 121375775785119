/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DefaultValueSelector\components\formula-editor\common\utils.ts
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-03 21:00:31
 * :last editor: 黄艺
 * :date last edited: 2021-12-29 19:58:30
 */

import { FORMULA_WIDGET_LIST, STATIC } from './constant';
import formula from './formula';

const a = window.$;

const utils = {
  isString: function (a) {
    return 'string' == typeof a;
  },
  isNumber: function (b) {
    return a.isNumeric(b);
  },
  isFunction: function (b) {
    return a.isFunction(b);
  },
  isDate: function (a) {
    return a instanceof Date;
  },
  isArray: function (b) {
    return a.isArray(b);
  },
  isEmpty: function (a) {
    return '' === a || utils.isNull(a);
  },
  isBlank: function (a) {
    return utils.isNull(a) || '' === a.trim();
  },
  isNull: function (a) {
    return null == a;
  },
  isObjectEmpty: function (a) {
    if (null == a) return !0;
    if (a.length > 0) return !1;
    if (0 === a.length) return !0;
    for (var b in a) if (hasOwnProperty.call(a, b)) return !1;
    return isNaN(a);
  },
  isValueWidget: function (a) {
    return !!fml.ValueWidgets[a];
  },
  address2Str: function (a, b, c) {
    if (c && a) {
      b = '';
      var d = !0;
      /p/.test(c) &&
        a.province &&
        ((b += a.province), a.province === a.city && (d = !1)),
        /c/.test(c) && a.city && d && (b += a.city),
        /d/.test(c) && a.district && (b += a.district),
        /a/.test(c) && a.detail && (b += a.detail);
    }
    return b;
  },
  num2Str: function (a, b) {
    if (utils.isEmpty(a)) return '';
    var c = a + '';
    if (utils.isEmpty(b)) return c;
    var d = /\[Num0\]/;
    if (d.test(b)) return b.replace(d, c);
    if (((d = /\[Num1\]/), d.test(b)))
      return b.replace(d, utils._num2CN(c, !1));
    if (((d = /\[Num2\]/), d.test(b)))
      return b.replace(d, utils._num2CN(c, !0));
    d = /[#0]+,?[#0]*\.?[#0]*%?/;
    var e = b.match(d);
    if (e && e.length > 0) {
      var f = e[0];
      return (c = utils._numberFormat(a, f)), b.replace(d, c);
    }
    return b;
  },
  _numberFormat: function (a, b) {
    var c = '',
      d = a + '';
    if (/%$/.test(b)) {
      (c = '%'), (a = 100 * a), (b = b.replace('%', ''));
      var e = d.indexOf('.');
      if (e > -1) {
        var f = d.length - 3 - e;
        (f = f < 0 ? 0 : f > 8 ? 8 : f), (a = parseFloat(a.toFixed(f)));
      }
      d = a + '';
    }
    var g = b.split('.'),
      h = g[0],
      i = g[1];
    if ('' !== i) {
      var j = i ? i.length : 0;
      d = parseFloat(a).toFixed(j);
      for (var k = d.split(''), l = j; l > 0 && '#' === i.charAt(l - 1); l--) {
        var m = k.pop();
        if ('0' !== m) {
          k.push(m);
          break;
        }
      }
      var n = k.pop();
      '.' === n && (n = ''), (d = k.join('') + n);
    }
    var o = d.split('.'),
      p = o[0];
    if (/,/.test(h)) o[0] = p.replace(/(\d{1,3})(?=(?:\d{3})+(?!\d))/g, '$1,');
    else {
      var q = h.match(/[0]+[0#]*$/);
      q && q.length > 0 && (o[0] = utils.leftPad(p, q[0].length, '0'));
    }
    return o.join('.') + c;
  },
  _num2CN: function (a, b) {
    var c = '〇一二三四五六七八九',
      d = '个十百千万亿';
    b && ((c = '零壹贰叁肆伍陆柒捌玖'), (d = '个拾佰仟万亿'));
    var e = Math.floor(Math.abs(a)),
      f = Math.abs(a).toString(),
      g = f.replace(/\..*$/, ''),
      h = f.split('.'),
      i = c,
      j = d,
      k = '-.',
      l = i[0],
      m = new RegExp(i[0] + '*$'),
      n = new RegExp(i[0] + '+', 'g'),
      o = '',
      p = '';
    if (((o = a < 0 ? k[0] : ''), h.length >= 2)) {
      var q = h[1];
      if (q) {
        p = k[1];
        for (var r = 0; r < q.length; r++) p += i[+q[r]];
      }
    }
    if (1 == g.length) return o + i[e] + p;
    if (g.length <= 5) {
      for (var s = '', t = 0, e = g.length; e--; ) {
        var u = +g[t];
        (s += this._num2CN(g[t], b) + (u && e ? j[e] : '')), t++;
      }
      return (s = s.replace(n, l)), (s = s.replace(m, '')), o + s + p;
    }
    for (
      var v = (g.length / 4) >> 0, w = g.length % 4, s = '';
      0 == w || !j[3 + v];

    )
      (w += 4), v--;
    if (+g.substr(0, w)) {
      s = this._num2CN(g.substr(0, w), b) + j[3 + v];
      var x = g.substr(w);
      '0' === x[0] && (s += i[0]), (s += this._num2CN(x, b));
    } else s = this._num2CN(g.substr(0, w), b) + this._num2CN(g.substr(w), b);
    return (s = s.replace(n, l)), (s = s.replace(m, '')), o + s + p;
  },
  date2Str: function (a, b) {
    if (!a) return '';
    var c = b.length,
      d = '';
    if (c > 0) {
      for (var e = b.charAt(0), f = 0, g = e, h = 1; h < c; h++) {
        var i = b.charAt(h);
        e !== i
          ? ((d += utils._compileDateFormat(
              {
                char: e,
                str: g,
                len: h - f,
              },
              a,
            )),
            (e = i),
            (f = h),
            (g = e))
          : (g += i);
      }
      d += utils._compileDateFormat(
        {
          char: e,
          str: g,
          len: c - f,
        },
        a,
      );
    }
    return d;
  },
  _compileDateFormat: function (a, b) {
    var c = a.str,
      d = a.len,
      e = a.char;
    switch (e) {
      case 'E':
        c =
          d > 2
            ? Date._DN[b.getDay()]
            : d > 1
            ? Date._SDN[b.getDay()]
            : b.getDay() + '';
        break;
      case 'y':
        c = d <= 3 ? (b.getFullYear() + '').slice(2, 4) : b.getFullYear();
        break;
      case 'M':
        c =
          d > 2
            ? Date._MN[b.getMonth()]
            : d < 2
            ? b.getMonth() + 1
            : utils.leftPad(b.getMonth() + 1 + '', 2, '0');
        break;
      case 'd':
        c = d > 1 ? utils.leftPad(b.getDate() + '', 2, '0') : b.getDate();
        break;
      case 'h':
        var f = b.getHours() % 12;
        0 === f && (f = 12), (c = d > 1 ? utils.leftPad(f + '', 2, '0') : f);
        break;
      case 'H':
        c = d > 1 ? utils.leftPad(b.getHours() + '', 2, '0') : b.getHours();
        break;
      case 'm':
        c = d > 1 ? utils.leftPad(b.getMinutes() + '', 2, '0') : b.getMinutes();
        break;
      case 's':
        c = d > 1 ? utils.leftPad(b.getSeconds() + '', 2, '0') : b.getSeconds();
        break;
      case 'a':
        c = b.getHours() < 12 ? 'am' : 'pm';
        break;
      default:
        c = a.str;
    }
    return c;
  },
  pick: function (a, b) {
    var c = {};
    return (
      utils?.forEach(b, function (b, d) {
        d in a && (c[d] = a[d]);
      }),
      c
    );
  },
  applyFunc: function (a, b, c, d) {
    return utils.isFunction(b) ? b.apply(a, c ? c : []) : d;
  },
  forEach: function (a, b) {
    if (Array.isArray(a) || a instanceof jQuery)
      for (
        var c = 0, d = a.length;
        c < d && b.apply(a[c], [c, a[c]]) !== !1;
        c++
      );
    else if (a && 'object' == typeof a)
      for (var e in a)
        if (a.hasOwnProperty(e) && b.apply(a[e], [e, a[e]]) === !1) break;
  },
  flatten: function (a, b, c) {
    if ((c || (c = []), a))
      for (var d = 0, e = a.length; d < e; d++) {
        var f = a[d];
        Array.isArray(f) ? utils.flatten(f, b, c) : (b && !b(f)) || c.push(f);
      }
    return c;
  },
  applyCss: function (a, b) {
    utils.isEmpty(b) || (utils.isString(b) ? a.addClass(b) : a.css(b));
  },
  getServerDate: function (a) {
    if (a && a.getResponseHeader) {
      var b = a.getResponseHeader('date');
      b &&
        ((fml.STATIC._st = new Date(b).getTime()),
        (fml.STATIC._ct = new Date().getTime()));
    }
  },
  ajax: function (b, c, d, e) {
    return a
      .ajax({
        type: 'POST',
        beforeSend: function (a) {
          a.setRequestHeader('X-CSRF-Token', fml.STATIC.CSRF);
        },
        url: b.url,
        async: b.async !== !1,
        data: JSON.stringify(b.data),
        contentType: 'application/json;charset=UTF-8',
        timeout: b.timeout,
      })
      .done(function (a, b, d) {
        utils.getServerDate(d), c && c(a, b), e && e(a, b);
      })
      .fail(function (a, b, c) {
        switch (a.status) {
          case 400:
            if (utils.applyFunc(this, d, [a, b], !1) === !1) {
              var f = a.responseJSON || {};
              f.msg
                ? fml.Msg.toast({
                    type: 'warning',
                    msg: f.msg,
                  })
                : fml.Msg.toast({
                    type: 'error',
                    msg: 'é”™è¯¯çš„è¯·æ±‚',
                  });
            }
            break;
          case 401:
            fml.Msg.toast({
              type: 'warning',
              msg: 'ç”¨æˆ·æœªç™»å½•',
            });
            break;
          case 402:
            fml.Msg.toast({
              type: 'warning',
              msg: 'å½“å‰ä¼šè¯å·²è¿‡æœŸ',
            });
            break;
          case 403:
            fml.Msg.toast({
              type: 'warning',
              msg: 'æ²¡æœ‰æ•°æ®è¯·æ±‚æƒé™',
            });
            break;
          case 404:
            fml.Msg.toast({
              type: 'warning',
              msg: 'æ‰¾ä¸åˆ°æ•°æ®èµ„æº',
            });
            break;
          case 0:
            break;
          default:
            fml.Msg.toast({
              type: 'warning',
              msg: 'ä¸ŽæœåŠ¡å™¨é€šä¿¡å¤±è´¥',
            }),
              console && console.log('é€šä¿¡å¤±è´¥');
        }
        e && e(a, b);
      });
  },
  ajaxUpload: function (b, c, d, e) {
    a.ajax({
      type: 'POST',
      url: b.url,
      data: b.data,
      cache: !1,
      contentType: !1,
      processData: !1,
      beforeSend: function (a) {
        a.setRequestHeader('X-CSRF-Token', STATIC.CSRF);
      },
      xhr: function () {
        var c = a.ajaxSettings.xhr();
        return (
          c.upload &&
            b.onUpload &&
            c.upload.addEventListener(
              'progress',
              function (a) {
                a.lengthComputable
                  ? b.onUpload(a.loaded, a.total)
                  : b.onUpload(3, 10);
              },
              !1,
            ),
          c
        );
      },
    })
      .done(function () {
        c && c.apply(this, arguments), e && e.apply(this, arguments);
      })
      .fail(function () {
        d && d.apply(this, arguments), e && e.apply(this, arguments);
      });
  },
  dataAjax: function (a, b, c, d) {
    a.data = a.data || {};
    var e = a.data;
    return (
      utils.isEmpty(fml.STATIC.APPID) || (e.appId = fml.STATIC.APPID),
      utils.isEmpty(fml.STATIC.ENTRYID) || (e.entryId = fml.STATIC.ENTRYID),
      utils.isEmpty(fml.STATIC.DATAID) || (e.dataId = fml.STATIC.DATAID),
      fml.STATIC.BACKUP && (e.isBackup = !0),
      utils.isEmpty(fml.STATIC.FTOKEN)
        ? utils.isEmpty(fml.STATIC.QTOKEN)
          ? utils.isEmpty(fml.STATIC.RTOKEN)
            ? utils.isEmpty(fml.STATIC.ATOKEN) ||
              ((e.fx_access_token = fml.STATIC.ATOKEN),
              (e.fx_access_type = 'app_public'))
            : ((e.fx_access_token = fml.STATIC.RTOKEN),
              (e.fx_access_type = 'report_public'))
          : ((e.fx_access_token = fml.STATIC.QTOKEN),
            (e.fx_access_type = 'form_query'))
        : ((e.fx_access_token = fml.STATIC.FTOKEN),
          (e.fx_access_type = 'form_public')),
      utils.ajax(
        a,
        function (a, c) {
          b(a, c);
        },
        c,
        d,
      )
    );
  },
  getUrlParameter: function (a) {
    for (
      var b = window.location.search.substring(1), c = b.split('&'), d = 0;
      d < c.length;
      d++
    ) {
      var e = c[d].split('=');
      if (e[0] == a) return e[1];
    }
    return null;
  },
  validateEmail: function (a) {
    return /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
      a,
    );
  },
  redirectTo: function (a) {
    window.location.href = a;
  },
  isCanvasSupported: function () {
    var a = document.createElement('canvas');
    return !(!a.getContext || !a.getContext('2d'));
  },
  isFormDataSupported: function () {
    return void 0 !== window.FormData;
  },
  getFileDownloadURL: function (a, b, c) {
    switch (a.bucket) {
      case fml.CONST.QN_BUCKET.PUBLIC_IMAGE:
        var d = '',
          e = a.thumb;
        return (
          e &&
            (d = '?imageView2/' + e.mode + '/w/' + e.width + '/h/' + e.height),
          b(fml.CONFIG.HOST.IMAGE_HOST + '/' + a.qnKey + d)
        );
      case fml.CONST.OSS_BUCKET.PUBLIC_IMAGE:
        return b(fml.CONFIG.HOST.OSS_IMAGE_HOST + '/' + a.ossKey);
      case fml.CONST.QN_BUCKET.PRIVATE_FILE:
      default:
        if (!fml.STATIC.APPID) return c();
        utils.dataAjax(
          {
            url: '/dashboard/app/' + fml.STATIC.APPID + '/file_url',
            data: a,
          },
          function (a) {
            b(a.url);
          },
          function (a) {
            utils.applyFunc(this, c, [a], !1) === !1 &&
              fml.Msg.toast({
                type: 'warning',
                msg: 'æ–‡ä»¶èŽ·å–å¤±è´¥',
              });
          },
        );
    }
  },
  evalFormula: function (a, formula) {
    var b = [];
    window.formula = formula;
    utils?.forEach(Object.keys(formula), function (a, c) {
      b.push('var ' + c + '=formula.' + c);
    });
    var it = b.join(';') + ';';
    var c = new Function(it + 'return ' + a)();
    return c;
  },
  createEntryAttributeField: function (b, c) {
    var d = {
      id: c.entryId,
    };
    switch (b) {
      case 'ext':
        a.extend(d, {
          name: 'ext',
          type: 'text',
          text: '扩展字段',
          items: c.extParams,
        });
        break;
      case 'createTime':
        a.extend(d, {
          name: 'createTime',
          type: 'datetime',
          format: 'yyyy-MM-dd HH:mm:ss',
          text: '提交时间',
        });
        break;
      case 'updateTime':
        a.extend(d, {
          name: 'updateTime',
          type: 'datetime',
          format: 'yyyy-MM-dd HH:mm:ss',
          text: '更新时间',
        });
        break;
      case 'creator':
        a.extend(d, {
          name: 'creator',
          type: 'text',
          text: '提交人',
        });
        break;
      case 'flowState':
        a.extend(d, {
          name: 'flowState',
          type: 'flowState',
          text: '流程状态',
        });
        break;
      case 'chargers':
        a.extend(d, {
          name: 'chargers',
          type: 'chargers',
          text: '当前节点/负责人',
        });
        break;
      case 'deleter':
        a.extend(d, {
          name: 'deleter',
          type: 'text',
          text: '删除人',
        });
        break;
      case 'deleteTime':
        a.extend(d, {
          name: 'deleteTime',
          type: 'datetime',
          format: 'yyyy-MM-dd HH:mm:ss',
          text: '删除时间',
        });
        break;
      default:
        return null;
    }
    return d;
  },
  createWidgetName: function () {
    return '_widget_' + fml.STATIC.IDBase++;
  },
  formatFileSize: function (a) {
    return utils.isNumber(a)
      ? a >= 1e9
        ? (a / 1e9).toFixed(2) + ' GB'
        : a >= 1e6
        ? (a / 1e6).toFixed(2) + ' MB'
        : (a / 1e3).toFixed(2) + ' KB'
      : 'æœªçŸ¥';
  },
  chunkArray: function (a, b) {
    var c = [];
    if (!b || !a.length) return c;
    for (var d = 0, e = a.length; d < e; d += b) {
      var f = a.slice(d, d + b);
      c.push(f);
    }
    return c;
  },
  UUID: function (a) {
    return a
      ? (a ^ ((16 * Math.random()) >> (a / 4))).toString(16)
      : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, this.UUID);
  },
  GCD: function (a, b) {
    return b ? this.GCD(b, a % b) : a;
  },
  LCM: function (a, b) {
    return (a * b) / this.GCD(a, b);
  },
  fixDecimalPrecision: function (a, b) {
    var c = '';
    if ((b || (b = 8), !this.isEmpty(a))) {
      var d = parseFloat(a);
      if (!isNaN(d)) {
        var e = (d + '').split('.')[1];
        (c = e && e.length > b ? parseFloat(d.toFixed(b)) : d),
          b > 6 &&
            Math.abs(c) < 1 &&
            /e-/.test(c + '') &&
            (c = parseFloat(d.toFixed(6)));
      }
    }
    return c;
  },
  getSelectionText: function () {
    return window.getSelection
      ? window.getSelection().toString()
      : document.selection && document.selection.createRange
      ? document.selection.createRange().text
      : '';
  },
  getCorpType: function (a) {
    return a
      ? ((a = a.toLowerCase()),
        /^ding/.test(a) ? 'dingtalk' : /^w/.test(a) ? 'wechat' : 'internal')
      : null;
  },
  isCorpSuiteAdmin: function (a) {
    return a && ('dingtalk' === a || 'wechat' === a);
  },
  getWeekStartDate: function (a) {
    var b = a.getDay();
    return (
      0 === b && (b = 7),
      new Date(a.getFullYear(), a.getMonth(), a.getDate() - (b - 1))
    );
  },
  getWeekEndDate: function (a) {
    var b = a.getDay();
    return (
      0 === b && (b = 7),
      new Date(a.getFullYear(), a.getMonth(), a.getDate() + (7 - b))
    );
  },
  getMonthStartDate: function (a) {
    return new Date(a.getFullYear(), a.getMonth(), 1);
  },
  getMonthEndDate: function (a) {
    return new Date(a.getFullYear(), a.getMonth() + 1, 0);
  },
  setPageTitle: function (a) {
    utils.isEmpty(a) || (document.title = a);
  },
  createMask: function (b, c) {
    var d = a('<div class="x-window-mask"/>'),
      e = c || {};
    if (
      (e.isModal && d.addClass('modal'),
      e.isLight ? d.addClass('light') : e.isDark && d.addClass('dark'),
      e.hasLoader)
    ) {
      var f = !e.isDark;
      this.createLoadIcon(d, f);
    }
    return (
      b &&
        d
          .css({
            'z-index': fml.STATIC.zIndex++,
          })
          .appendTo(b),
      d
    );
  },
  createLoadIcon: function (b, c) {
    var d = a('<div class="x-loader-icon"/>').appendTo(b);
    return c && d.addClass('colorful'), a('<div/>').appendTo(d), d;
  },
  doPrint: function (b, c) {
    a('body').children('div').addClass('x-ui-notprint');
    var d = a('#x-printer').removeClass().empty();
    0 === d.length && (d = a('<div id="x-printer"/>').appendTo('body')),
      b && d.append(b),
      (c = utils.isNull(c) ? 0 : c),
      setTimeout(function () {
        window.print();
      }, c);
  },
  cancelPrint: function () {
    a('body').children('.x-ui-notprint').removeClass('x-ui-notprint'),
      a('#x-printer').remove();
  },
  copyToClipboard: function (a, b) {
    if (a && a.length) {
      var c = document.createElement('textarea');
      (c.style.background = 'transparent'),
        (c.style.color = 'transparent'),
        (c.value = a),
        document.body.appendChild(c),
        c.select();
      var d;
      try {
        d = document.execCommand('copy');
      } catch (a) {
        d = !1;
      }
      document.body.removeChild(c), d && utils.applyFunc(this, b, [], !1);
    }
  },
  getColorNumber: function (a) {
    return utils.isEmpty(a)
      ? 1
      : (parseInt(a.toString().substring(0, 8), 16) % 6) + 1;
  },
  getFieldAttr: function (b, c) {
    if (!utils.isValueWidget(b.widget.type)) return null;
    if (c && c.indexOf(b.widget.type) < 0) return null;
    var d = {
      id: b.formId,
      text: b.label,
      name: b.widget.widgetName,
      type: b.widget.type,
    };
    switch (b.widget.type) {
      case 'subform':
        var e = [];
        utils?.forEach(b.widget.items, function (d, f) {
          a.extend(f, {
            formId: b.formId,
          });
          var g = utils.getFieldAttr(f, c);
          g &&
            (a.extend(g, {
              subform: b.widget.widgetName,
            }),
            e.push(g));
        }),
          a.extend(d, {
            items: e,
          });
        break;
      case 'linkdata':
        a.extend(d, {
          linkForm: b.widget.linkForm,
          linkFields: b.widget.linkFields,
          refAppId: b.widget.refAppId,
        });
        break;
      case 'combo':
      case 'combocheck':
      case 'radiogroup':
      case 'checkboxgroup':
        a.extend(d, {
          async: b.widget.async,
          items: b.widget.items,
        });
        break;
      case 'datetime':
        a.extend(d, {
          format: b.widget.format,
        });
    }
    return d;
  },
  leftPad: function (a, b, c) {
    var d = String(a);
    for (c || (c = ' '); d.length < b; ) d = c + d;
    return d.toString();
  },
  startWith: function (a, b) {
    var c = a.length;
    return (
      !(null == b || '' == b || 0 === c || b.length > c) &&
      a.substr(0, b.length) == b
    );
  },
  getFieldInfoByFormula: function (a) {
    var b = {},
      c = a + '',
      d = c.match(/(\$[0-9a-zA-Z\._]+)(#[0-9a-f]+)?(@[0-9a-f]+)?/),
      e = ['', 'field', 'entryId', 'appId'];
    return (
      utils?.forEach(d, function (a, c) {
        0 !== a && c && (b[e[a]] = c.substr(1));
      }),
      b
    );
  },
  getFieldInfoById: function (a) {
    var b = {};
    a = '#' + a;
    var c = a.match(/(#[0-9a-f]+)(@[0-9a-f]+)?/),
      d = ['', 'entryId', 'appId'];
    return (
      utils?.forEach(c, function (a, c) {
        0 !== a && c && (b[d[a]] = c.substr(1));
      }),
      b
    );
  },
  isWpsWebView: function () {
    return /wpscloudform/.test(navigator.userAgent);
  },
  callWPSAPI: function (a) {
    var b = 'jsAsynCall(' + JSON.stringify(a) + ')';
    window.cefQuery &&
      window.cefQuery({
        request: b,
      });
  },
  onWPSPageUnload: function (a, b) {
    a
      ? fml.Msg.alert({
          type: 'warning',
          msg: 'å½“å‰é¡µé¢æœªä¿å­˜ï¼Œæ˜¯å¦ç¡®å®šç¦»å¼€ï¼Ÿ',
          text4Ok: 'ç¦»å¼€',
          text4Cancel: 'å–æ¶ˆ',
          onOk: function () {
            utils.applyFunc(this, b, [], !1);
          },
        })
      : utils.applyFunc(this, b, [], !1);
  },
  fileDownload: function (a, b) {
    if (utils.isWpsWebView()) {
      var c = {
          method: 'downloadUrl',
          url: a,
          filename: b,
        },
        d = b && b.split('.').pop();
      (c.filter = '(*.' + (d || '*') + ')'), utils.callWPSAPI(c);
    } else utils.redirectTo(a);
  },
  isSupportPdf: function () {
    return 'undefined' != typeof navigator.mimeTypes['application/pdf'];
  },
  dt: function (a, b) {
    if (a) {
      var c = ['e=' + a, 't=' + new Date().getTime()];
      utils.isEmpty(b) || c.push('ext=' + b),
        fml.STATIC.user &&
          fml.STATIC.user.username &&
          c.push('u=' + fml.STATIC.user.username);
      var d = new Image();
      d.src = fml.CONFIG.HOST.TRACK_HOST + '/log?' + c.join('&');
    }
  },
};

interface ICurrentFormFieldObjs {
  (index: any): any;
}

export const iterationFlatten = (
  arr: any[],
  flatten: any,
  formData: any,
  selected: any,
  currentFormFieldObjs: Record<string | number | symbol, any> = {},
) => {
  let id = selected ?? formData.$id;
  arr?.forEach((key) => {
    if (flatten[key].children.length > 0) {
      iterationFlatten(
        flatten[key].children,
        flatten,
        formData,
        selected,
        currentFormFieldObjs,
      );
    } else if (
      id !== key &&
      FORMULA_WIDGET_LIST.includes(flatten[key].schema.widget)
    ) {
      currentFormFieldObjs[key] = flatten[key];
    }
  });
  return currentFormFieldObjs;
};

export const formFieldObjToList = (
  arr: any[],
  flatten: any,
  formData: any,
  selected: any,
) => {
  const currentFormFieldObjs = iterationFlatten(
    arr,
    flatten,
    formData,
    selected,
  );
  const target = Object.keys(currentFormFieldObjs).map((key) => {
    const parentKey = currentFormFieldObjs[key].parent;
    const parentFlatten = parentKey !== '#' ? flatten[parentKey] : undefined;
    return {
      ...flatten[key],
      parentFlatten,
    };
  });
  return target;
};
export const fixHiddenInTable = (arr: any[], flatten: any) => {
  if (!arr?.length) {
    return arr;
  }
  arr?.forEach((item) => {
    if (item?.parent && item?.parent?.includes('table_') && item.schema) {
      item.schema.hidden = flatten[item?.parent]?.schema?.hidden;
    }
  });
  return arr;
};

export const Searcher = (() => {
  let escapeRegExp = /[\-#$\^*()+\[\]{}|\\,.?\s]/g;
  let escapeReg = (reg: string) => reg.replace(escapeRegExp, '\\$&');
  //groupLeft 与 groupRight是对结果进一步处理所使用的分割符，可以修改
  let groupLeft = '(',
    groupRight = ')';
  let groupReg = new RegExp(escapeReg(groupRight + groupLeft), 'g');
  let groupExtractReg = new RegExp(
    '(' + escapeReg(groupLeft) + '[\\s\\S]+?' + escapeReg(groupRight) + ')',
    'g',
  );
  //从str中找到最大的匹配长度
  let findMax = (str: string, keyword: string) => {
    let max = 0;
    keyword = groupLeft + keyword + groupRight;
    str.replace(groupExtractReg, (m) => {
      //keyword完整的出现在str中，则优秀级最高，排前面
      if (keyword == m) {
        max = Number.MAX_SAFE_INTEGER;
      } else if (m.length > max) {
        //找最大长度
        max = m.length;
      }
    });
    return max;
  };
  let keyReg = (key: string) => {
    let src = ['(.*?)('];
    let ks = key.split('');
    if (ks.length) {
      while (ks.length) {
        src.push(escapeReg(ks.shift() || ''), ')(.*?)(');
      }
      src.pop();
    }
    src.push(')(.*?)');
    const srcStr = src.join('');
    let reg = new RegExp(srcStr, 'i');
    let replacer = [];
    let start = key.length;
    let begin = 1;
    while (start > 0) {
      start--;
      replacer.push('$', begin, groupLeft + '$', begin + 1, groupRight);
      begin += 2;
    }
    replacer.push('$', begin);

    const info = {
      regexp: reg,
      replacement: replacer.join(''),
    };
    return info;
  };

  return {
    search(list: unknown[], keyword: string, flatten) {
      //生成搜索正则
      let kr = keyReg(keyword);
      let result = [];
      for (let e of list) {
        //如果匹配
        const { title } = e.schema;
        let searchItem = '';
        if (e.parent !== '#') {
          searchItem += flatten[e?.parent].schema.title + '.';
        }
        searchItem += title;
        if (kr.regexp.test(title)) {
          //把结果放入result数组中
          e.value = searchItem
            .replace(kr.regexp, kr.replacement)
            .replace(groupReg, '');
          result.push(e);
        }
      }
      //对搜索结果进行排序
      //1. 匹配关键字大小写一致的优先级最高，比如搜索up, 结果中的[user-page,beginUpdate,update,endUpdate]，update要排在最前面，因为大小写匹配
      //2. 匹配关键字长的排在前面
      // result = result.sort((a, b) => findMax(b, keyword) - findMax(a, keyword));
      return result;
    },
  };
})();

export { utils };
