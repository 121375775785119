/*
 * :file description:
 * :name: \ui-renderd:\progree\low-code-platform\src\pages\form\components\ModalImport\ImportStep2\index.tsx
 * :author: PakJeon
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-01 19:17:16
 * :last editor: hyw
 * :date last edited: 2022-04-29 17:21:41
 */
import {
  useEffect,
  useMemo,
  useState,
  useContext,
  ReactNode,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Table, Select, Modal, Tooltip, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { cloneDeep } from 'lodash';
import formApi from '@/services/form';
import cx from 'classnames';
import c from './index.less';
import { getFormCode } from '@/utils';
import { IMPORT_MODAL_TEXT, RELATION_COL_VALID_WIDGET } from './constant.js';
import { FormConfigCtx } from '../../../[id]';
import { OptionType, options as ModeOptions } from '../ImportStep1';
import { columnMatcheItem } from '@/services/form/data';
import { customJP } from '@/utils/index';

const { Option } = Select;
const { confirm } = Modal;

const TABLE_COL_WIDTH = 140; // 常规列宽度
const TABLE_RELATION_COL_WIDTH = 2 * TABLE_COL_WIDTH; // 关联列宽度
const TABLE_RELATION_CUS_COL_WIDTH = 3 * TABLE_COL_WIDTH; // 关联客户列宽度
const TABLE_HEIGHT = 327;

// 不导入 下拉选项
const DISABLE_IMPORT_OPTION = {
  key: '0',
  title: '不导入',
};

// 数据id （包含更新的模式） 下拉选项
const INSTANCE_ID_OPTION = {
  key: 'instanceId',
  title: '数据id（系统）',
  required: true,
};

// 以下类型不支持导入
const filterText = [
  'table',
  'seqNumber',
  'attachment',
  'dateRange',
  'picture',
  'textNote',
];

// 固定创建人对象
const createUserJson = {
  format: 'personSelector',
  key: 'createUserId',
  id: 'createUserId',
  title: '创建人',
  type: 'string',
  widget: 'personSelector',
};

// 固定创建时间对象
const gmtCreateJson = {
  format: 'date',
  key: 'gmtCreate',
  id: 'gmtCreate',
  title: '创建时间',
  type: 'string',
  widget: 'date',
};

type importStep2Porps = {
  selectMode: OptionType;
  fileData: any;
  previewData: any;
  triggerProcess: boolean;
  onImportSuccess: () => void;
  onImportFail: () => void;
};

const ImportStep2 = (props: importStep2Porps, ref: any) => {
  const {
    fileData,
    previewData,
    selectMode,
    triggerProcess,
    onImportSuccess,
    onImportFail,
  } = props;

  const [tableSelected, setTableSelected] = useState<any>({});
  const [selected, setSelected] = useState<string[]>([]); // 所有列所选择的字段
  const [relationSelected, setRelationSelected] = useState<any>({}); // 关联表单的主键映射

  const [relationCusSelected, setRelationCusSelected] = useState<any>({}); // 关联表单的客户关系主键映射
  const formConfigCtx = useContext(FormConfigCtx);

  const [supportFields, setSupportFields] = useState<any>([]); // 关联表单的主键映射

  const [relationPrimaryKey, setRelationPrimaryKey] = useState<any>({}); // 关联表单的客户关系主键映射

  useImperativeHandle(ref, () => ({
    import: () => {
      if (!beforeImportCheck()) {
        return;
      }
      handleImport();
    },
  }));

  // 开始导入前的校验
  const beforeImportCheck = (): boolean => {
    const required = options.filter((item) => item.required);
    const tableRequired: any[] = [];
    Object.keys(tableOptions ?? {})?.forEach((tableKey) => {
      const tableOpt = tableOptions[tableKey];
      if (tableOpt.required) {
        tableRequired.push(tableOpt);
      }
    });
    // 包含 “更新” 的导入模式，必须选择数据id
    if ([ModeOptions[1].key, ModeOptions[2].key].includes(selectMode.key)) {
      required.push(INSTANCE_ID_OPTION);
    }
    if (
      selected.filter((item) => item !== DISABLE_IMPORT_OPTION.key).length === 0
    ) {
      message.warning('请选择至少一列进行导入');
      return false;
    }
    const lack: any = []; // 存放缺少的必选字段
    required?.forEach((item) => {
      if (!selected.includes(item.key)) {
        lack.push(item);
      }
    });
    tableRequired?.forEach((item) => {
      if (!Object.values(tableSelected).includes(item.id)) {
        lack.push(item);
      }
    });
    const relationKeyLack: any = []; // 存放缺少的关联表单主键字段
    selected?.forEach((key) => {
      const option = options.find((op) => op.key === key);
      if (option && option.widget === 'relation' && !relationSelected[key]) {
        relationKeyLack.push(option);
      }
    });
    if (lack.length === 0 && relationKeyLack.length === 0) {
      return true;
    } else if (lack.length > 0) {
      Modal.error({
        okText: '知道了',
        content: `未指定${lack
          .map((i: any) => i.title)
          .join('、')}，请选择数据列并指定`,
      });
    } else if (relationKeyLack.length > 0) {
      Modal.error({
        okText: '知道了',
        content: `未指定关联表单${relationKeyLack
          .map((i: any) => i.title)
          .join('、')}主键，请选择数据列并指定主键`,
      });
    }
    return false;
  };

  // 导入的二次确认弹窗
  const handleImport = () => {
    confirm({
      title: IMPORT_MODAL_TEXT[selectMode.key].title,
      okText: '确定',
      cancelText: '取消',
      content: (
        <div className={c.modalImportConfirm}>
          <div className={c.confirmRow}>
            当前将导入<span>{previewData.excelDataRowCount || 0}</span>
            行数据，已指定
            <span>
              {
                selected.filter((item) => item !== DISABLE_IMPORT_OPTION.key)
                  .length
              }
            </span>
            列数据/共<span>{columns.length - 1}</span>列
          </div>
          <div className={c.confirmRow}>
            {IMPORT_MODAL_TEXT[selectMode.key].updateTips}
          </div>
          {triggerProcess && (
            <div className={c.confirmRow}>
              {IMPORT_MODAL_TEXT[selectMode.key].triggerTips}
            </div>
          )}
        </div>
      ),
      onOk() {
        const matches: columnMatcheItem[] = [];

        selected.map((item, index) => {
          // 普通列的传参组装
          if (
            !formatCols[index].parentCell &&
            ![
              DISABLE_IMPORT_OPTION.key,
              'createUserId',
              'gmtCreate',
              'instanceId',
            ].includes(item)
          ) {
            const option = options.find((op) => op.key === item);

            let computeCode = undefined;
            if (option?.widget === 'relation') {
              // 企业客户和个人客户
              if (option?.relatedOptions) {
                let selectJson = option?.relatedOptions.find(
                  (val) => val.bizType === relationCusSelected[item],
                );
                computeCode = selectJson.formCode;
              } else {
                computeCode =
                  option?.relatedFormCode ||
                  option?.dataSource?.target?.formCode;
              }
            }

            matches.push({
              colIndex: index,
              fieldId: item,
              required: !!option?.required,
              widget: option?.widget,
              bizAlias: option?.bizAlias,
              formCode: option?.formCode,
              relatedFormBizType: option?.relatedOptions
                ? relationCusSelected[item]
                : option?.relatedFormBizType,
              relatedFieldId:
                option?.widget === 'relation'
                  ? relationSelected[item]
                  : undefined,
              relatedFormCode: computeCode,
            });
          } else if (
            formatCols[index].parentCell &&
            ![
              DISABLE_IMPORT_OPTION.key,
              'createUserId',
              'gmtCreate',
              'instanceId',
            ].includes(item)
          ) {
            // 子表格列的传参组装
            const colParentId = formatCols[index]?.parentCell?.match?.fieldId;
            const selectedTableId = tableSelected[colParentId];
            const selectedTable = tableOptions[selectedTableId]; // 所选 子表格 的所有信息
            const selectedTableOptions = selectedTable.options; // 所选子表格的下拉选项
            const option = selectedTableOptions.find(
              (opt: any) => opt.key === item,
            );

            // console.log(option, '333333');

            matches.push({
              colIndex: index,
              fieldId: item,
              required: !!option?.required,
              widget: option?.widget,
              bizAlias: option?.bizAlias,
              relatedFieldId:
                option?.widget === 'relation'
                  ? relationSelected[item]
                  : undefined,
              relatedFormCode:
                option?.widget === 'relation'
                  ? option?.dataSource?.target?.formCode ||
                    option?.relatedFormCode
                  : undefined,
              parentColMatch: {
                bizAlias: selectedTable.bizAlias,
                colIndex: formatCols[index].parentCell.colIndex,
                fieldId: selectedTable.id,
                required: selectedTable.required,
                widget: selectedTable.widget,
              },
            });
          }
        });
        const creatorColIndex = selected.findIndex(
          (item) => item === 'createUserId',
        );
        const gmtCreateColIndex = selected.findIndex(
          (item) => item === 'gmtCreate',
        );
        const instanceIdColIndex = selected.findIndex(
          (item) => item === 'instanceId',
        );

        formApi
          .importStart({
            columnMatches: matches,
            fileId: fileData.fileId,
            formCode: getFormCode(),
            taskId: fileData.taskId,
            creatorColIndex: creatorColIndex < 0 ? undefined : creatorColIndex, // 创建人所在列的index
            gmtCreateColIndex:
              gmtCreateColIndex < 0 ? undefined : gmtCreateColIndex, // 创建时间所在列的index
            instanceIdColIndex:
              instanceIdColIndex < 0 ? undefined : instanceIdColIndex, // 实例id所在列的index
          })
          .then((res) => {
            if (res.code === 200) {
              onImportSuccess();
            } else {
              onImportFail();
            }
          });
      },
    });
  };

  // 初始化 已选字段 数组
  useEffect(() => {
    let len = 0;
    previewData.columns?.forEach((col: any) => {
      len += col.colSpan;
    });
    const selectedTmp: any[] = Array.from({ length: len }).fill(
      DISABLE_IMPORT_OPTION.key,
    );
    previewData.columns?.forEach((col: any) => {
      if (col.match && col.match.fieldId && !col.subCellMap) {
        // 普通列的默认值
        selectedTmp[col.colIndex] = col.match.fieldId;
      } else if (col.subCellMap) {
        // 子表格列的默认值
        col.subCellMap[1] &&
          col.subCellMap[1]?.forEach((subCell: any) => {
            if (subCell.match && subCell.match.fieldId) {
              selectedTmp[subCell.colIndex] = subCell.match.fieldId;
            }
          });
      }
    });
    setSelected(selectedTmp);
  }, [previewData]);

  // 初始化 子表格已选表格 映射关系
  useEffect(() => {
    const properties = formConfigCtx.content.properties;
    const result: any = {};
    Object.keys(properties)?.forEach((key) => {
      if (properties[key].widget === 'table') {
        result[key] = DISABLE_IMPORT_OPTION.key;
      }
    });
    previewData.columns?.forEach((col) => {
      // 设置子表格的默认映射
      if (
        col.subCellMap &&
        col.match &&
        col.match.fieldId &&
        result[col.match.fieldId]
      ) {
        result[col.match.fieldId] = col.match.fieldId;
      }
    });
    setTableSelected(result);
  }, [previewData, formConfigCtx]);

  // 判断下拉选项是否已指定，是否当前选择
  const renderSelectOptionRight = (idx: number, key: string): ReactNode => {
    if (selected[idx] === key) {
      return '√';
    } else if (selected.includes(key)) {
      return <span className={c.option_already}>已指定</span>;
    }
    return '';
  };

  // 根据表单schema  获取子表格 对应的下拉选项 映射
  const tableOptions = useMemo(() => {
    const result: any = {};
    if (formConfigCtx.content && formConfigCtx.content.properties) {
      Object.keys(formConfigCtx.content.properties)?.forEach((key: string) => {
        const tmp = formConfigCtx.content.properties[key];
        if (tmp.widget === 'table') {
          const tmpOptions: any[] = [];
          Object.keys(tmp.items.properties)?.forEach((subKey: string) => {
            tmpOptions.push({
              key: subKey,
              ...tmp.items.properties[subKey],
            });
          });
          result[key] = {
            options: tmpOptions,
            ...tmp,
          };
        }
      });
    }
    return result;
  }, [formConfigCtx]);

  // 递归获取所有主键的数据（统一缓存保存）
  const recursionLookup = (data) => {
    data &&
      data?.forEach((ele) => {
        if (ele.widget === 'relation') {
          let isCustomerRelation = false;
          isCustomerRelation = ele?.relatedOptions ? true : false;
          if (isCustomerRelation) {
            getFormSchemaByCode(ele?.relatedOptions[0]?.formCode);
            getFormSchemaByCode(ele?.relatedOptions[1]?.formCode);
          } else {
            getFormSchemaByCode(ele.relatedFormCode);
          }
        } else if (ele.widget === 'table') {
          ele.subFieldList && recursionLookup(ele.subFieldList);
        }
      });
  };

  // 获取支持导入的表单字段
  useEffect(() => {
    formApi
      .listSupportFields({
        formCode: getFormCode(),
        importType: selectMode.key,
      })
      .then((res: any) => {
        const { formFields } = res?.data;
        setSupportFields(formFields || []);
        // 将关联客户的主键数据收录起来
        recursionLookup(formFields);
        // formFields.forEach((ele) => {
        //   if (ele.widget === 'relation') {
        //     let isCustomerRelation = false;
        //     isCustomerRelation = ele?.relatedOptions ? true : false;
        //     if (isCustomerRelation) {
        //       getFormSchemaByCode(ele?.relatedOptions[0]?.formCode);
        //       getFormSchemaByCode(ele?.relatedOptions[1]?.formCode);
        //     } else {
        //       getFormSchemaByCode(ele.relatedFormCode);
        //     }
        //   }
        // });
      });
  }, []);

  const getFormSchemaByCode = (formCode) => {
    formApi
      .getFormSchema({ formCode })
      .then((res) => {
        const { content } = res.data;
        let currentContent = customJP(content);
        relationPrimaryKey[formCode] = currentContent.properties;
        setRelationPrimaryKey({ ...relationPrimaryKey });
      })
      .catch((err) => {});
  };

  const options = useMemo(() => {
    const result: any[] = [];

    supportFields &&
      supportFields?.forEach((ele: any) => {
        if (!filterText.includes(ele.widget)) {
          result.push({
            key: ele.fieldId,
            ...ele,
          });
        }
      });

    // 补充固定字段
    if (selectMode.key !== ModeOptions[1].key) {
      result.push({ ...createUserJson }, { ...gmtCreateJson });
    }

    return result;
  }, [supportFields]);

  // 根据表单schema 获取下拉选项(除去子表格的选项)
  // const options = useMemo(() => {
  //   const result: any[] = [];

  //   if (formConfigCtx.content && formConfigCtx.content.properties) {
  //     Object.keys(formConfigCtx.content.properties)
  //       .filter((item) => {

  //         if (selectMode.key === ModeOptions[1].key) {
  //           if (['gmtCreate', 'createUserId'].includes(item)) {
  //             return false;
  //           }
  //         }
  //         if (item === 'gmtModified') {
  //           return false;
  //         }
  //         return true;
  //       })
  //       .forEach((key: string) => {
  //         const tmp = formConfigCtx.content.properties[key];

  //         if (!filterText.includes(tmp.widget)) {
  //           result.push({
  //             key,
  //             ...formConfigCtx.content.properties[key],
  //           });
  //         }
  //       });
  //   }

  //   return result;
  // }, [formConfigCtx]);

  // 计算table宽度
  const tableWidth = useMemo(() => {
    let normalCount = 1; // 默认至少有第一列
    let relationCount = 0;

    selected?.forEach((item) => {
      if ([DISABLE_IMPORT_OPTION.key, INSTANCE_ID_OPTION.key].includes(item)) {
        normalCount += 1;
      } else {
        const op = options.find((o) => item === o.key);
        if (op && op.widget === 'relation') {
          relationCount += 1;
        } else {
          normalCount += 1;
        }
      }
    });
    return (
      normalCount * TABLE_COL_WIDTH + relationCount * TABLE_RELATION_COL_WIDTH
    );
  }, [selected, options]);

  // 获取关联表单 主键 下拉筛选项
  const getRelationOptions = (idx: number): any[] => {
    const result: any = [];
    const option = options.find((op) => op.key === selected[idx]);
    let isCustomerRelation = false;
    isCustomerRelation = option?.relatedOptions ? true : false;

    // 非企业和个人客户的关联客户直接取值放在组件哪里
    if (!isCustomerRelation) {
      Object.keys(relationPrimaryKey[option.relatedFormCode] || {})
        .filter((item) => {
          if (selectMode.key === ModeOptions[1].key) {
            if (['gmtCreate', 'createUserId'].includes(item)) {
              return false;
            }
          }
          if (item === 'gmtModified') {
            return false;
          }
          if (
            !RELATION_COL_VALID_WIDGET.includes(
              relationPrimaryKey[option.relatedFormCode][item].widget,
            )
          ) {
            return false;
          }
          return true;
        })
        ?.forEach((key: string) => {
          result.push({
            key,
            ...relationPrimaryKey[option.relatedFormCode][key],
          });
        });
    } else {
      let currentSelectKeyJson = option.relatedOptions.find(
        (val) => val.bizType === relationCusSelected[selected[idx]],
      );
      Object.keys(relationPrimaryKey[currentSelectKeyJson?.formCode] || {})
        .filter((item) => {
          if (selectMode.key === ModeOptions[1].key) {
            if (['gmtCreate', 'createUserId'].includes(item)) {
              return false;
            }
          }
          if (item === 'gmtModified') {
            return false;
          }
          if (
            !RELATION_COL_VALID_WIDGET.includes(
              relationPrimaryKey[currentSelectKeyJson?.formCode][item].widget,
            )
          ) {
            return false;
          }
          return true;
        })
        ?.forEach((key: string) => {
          result.push({
            key,
            ...relationPrimaryKey[currentSelectKeyJson?.formCode][key],
          });
        });
    }

    // if (option && option.fields) {
    //   Object.keys(option.fields)
    //     .filter((item) => {
    //       if (selectMode.key === ModeOptions[1].key) {
    //         if (['gmtCreate', 'createUserId'].includes(item)) {
    //           return false;
    //         }
    //       }
    //       if (item === 'gmtModified') {
    //         return false;
    //       }
    //       if (!RELATION_COL_VALID_WIDGET.includes(option.fields[item].widget)) {
    //         return false;
    //       }
    //       return true;
    //     })
    //     .forEach((key: string) => {
    //       result.push({
    //         key,
    //         ...option.fields[key],
    //       });
    //     });
    // }
    return result;
  };

  // 获取 子表格 关联表单 主键 下拉筛选项
  const getTableRelationOptions = (idx: number): any[] => {
    const colItem = formatCols[idx];

    if (!colItem.parentCell) {
      return [];
    }

    const result: any = [];
    const colParentId = colItem?.parentCell?.match?.fieldId;
    // const selectedTableId = tableSelected[colParentId];
    // const selectedTable = tableOptions[selectedTableId]; // 所选 子表格 的所有信息
    // const selectedTableOptions = selectedTable ? selectedTable.options : []; // 所选子表格的下拉选项

    // const option = selectedTableOptions.find(
    //   (opt) => opt.key === selected[idx],
    // );
    // 根据当前对象的父级filed在支持导入的表单字段中找出所需数据（找相应的relatedFormCode）
    let parentJson =
      supportFields &&
      supportFields.find((val) => val?.fieldId === colParentId);
    let currenJson = parentJson?.subFieldList.find(
      (val) => val?.fieldId === selected[idx],
    );
    Object.keys(relationPrimaryKey[currenJson?.relatedFormCode] || {})
      .filter((item) => {
        if (selectMode.key === ModeOptions[1].key) {
          if (['gmtCreate', 'createUserId'].includes(item)) {
            return false;
          }
        }
        if (item === 'gmtModified') {
          return false;
        }
        if (
          !RELATION_COL_VALID_WIDGET.includes(
            relationPrimaryKey[currenJson?.relatedFormCode][item].widget,
          )
        ) {
          return false;
        }
        return true;
      })
      ?.forEach((key: string) => {
        result.push({
          key,
          ...relationPrimaryKey[currenJson?.relatedFormCode][key],
        });
      });
    // if (option && option.fields) {
    //   Object.keys(option.fields)
    //     .filter((item) => {
    //       if (selectMode.key === ModeOptions[1].key) {
    //         if (['gmtCreate', 'createUserId'].includes(item)) {
    //           return false;
    //         }
    //       }
    //       if (item === 'gmtModified') {
    //         return false;
    //       }
    //       if (!RELATION_COL_VALID_WIDGET.includes(option.fields[item].widget)) {
    //         return false;
    //       }
    //       return true;
    //     })
    //     .forEach((key: string) => {
    //       result.push({
    //         key,
    //         ...option.fields[key],
    //       });
    //     });
    // }
    return result;
  };

  // 格式化数据列（主要是区分 普通列 和 子表格中的列）
  const formatCols = useMemo(() => {
    const cols = previewData.columns;
    const result: any[] = [];
    cols?.forEach((col) => {
      if (!col.subCellMap) {
        result.push({
          parentCell: null,
          ...col,
        });
      } else {
        col.subCellMap[1] &&
          col.subCellMap[1]?.forEach((subCol: any, index: number) => {
            result.push({
              ...subCol,
              colSpan: index === 0 ? col.subCellMap[1].length : 0,
              parentCell: col, // 子表格列中 存放父信息
            });
          });
      }
    });
    return result;
  }, [previewData]);

  // 生成每列的列表头（针对子表格列表头作处理）
  const getColTitle = (item: any, tableSel: any) => {
    // 如果是表格类 自定义列头部
    if (item.parentCell) {
      if (item.colSpan != 0) {
        return (
          <div className="tableColTitle">
            <div className="colTitleParent">
              <div className="parentTitle">{item.parentCell.text}</div>
              <Select
                style={{ width: 100, marginLeft: 16 }}
                dropdownClassName="importSelectDropdown"
                dropdownMatchSelectWidth={false}
                disabled={selectMode.key === ModeOptions[1].key}
                key={item?.parentCell?.match?.fieldId}
                value={tableSel[item?.parentCell?.match?.fieldId]}
                onChange={(e) => {
                  // 更新 tableSelected
                  const tmp = cloneDeep(tableSel);
                  Object.keys(tmp)?.forEach((key) => {
                    if (tmp[key] === e) {
                      tmp[key] = DISABLE_IMPORT_OPTION.key;
                    }
                  });
                  tmp[item?.parentCell?.match?.fieldId] = e;
                  setTableSelected(tmp);
                  // 在更新同时清除 selected对应列的值
                  const tmpSelected = cloneDeep(selected);
                  for (let i = item.colIndex; i <= item.colSpan; i++) {
                    tmpSelected[i] = DISABLE_IMPORT_OPTION.key;
                  }
                  setSelected(tmpSelected);
                }}
                showSearch
                filterOption={(input, option) => {
                  return (
                    option?.showValue
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                optionLabelProp="showValue"
              >
                <Option
                  value={DISABLE_IMPORT_OPTION.key}
                  showValue={DISABLE_IMPORT_OPTION.title}
                >
                  {DISABLE_IMPORT_OPTION.title}
                </Option>
                {Object.keys(tableOptions).map((tableKey) => {
                  return (
                    <Option
                      key={tableKey}
                      value={tableKey}
                      className="optionWrapper"
                      showValue={tableOptions[tableKey].title}
                    >
                      <div className="optionLeft">
                        {tableOptions[tableKey].required && (
                          <div className="optionRequire">*</div>
                        )}
                        <div className="optionTitle">
                          {tableOptions[tableKey].title}
                        </div>
                      </div>
                    </Option>
                  );
                })}
              </Select>
              {selectMode.key === ModeOptions[1].key && (
                <Tooltip placement="top" title="表格不支持导入更新">
                  <ExclamationCircleOutlined style={{ marginLeft: 8 }} />
                </Tooltip>
              )}
            </div>
            <div className="colTitleChildWrapper">
              {item.parentCell.subCellMap[1].map((subCell: any) => {
                const isRelationCol =
                  selected[subCell.colIndex] &&
                  selected[subCell.colIndex].startsWith('relation');
                return (
                  <div
                    className={cx(
                      'colTitleChild',
                      isRelationCol ? 'colTitleChildFlex2' : null,
                    )}
                  >
                    <div className="childTitle">{subCell.text}</div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        return null;
      }
    }
    // 非表格类 直接返回文本
    return item.text;
  };

  // 处理子表格下的子列（当子表格的选项变更时，子列全都变为“不导入”）
  useEffect(() => {
    const tmpSelected = [...selected];
    formatCols?.forEach((col) => {
      if (col.parentCell) {
        if (
          tableSelected[col?.parentCell?.match?.fieldId] ===
          DISABLE_IMPORT_OPTION.key
        ) {
          tmpSelected[col.colIndex] = DISABLE_IMPORT_OPTION.key;
          setSelected(tmpSelected);
        }
      }
    });
  }, [tableSelected, formatCols]);

  const columns = useMemo(() => {
    // 表格首列序号
    const firstCol = {
      title: '',
      dataIndex: '_',
      width: TABLE_COL_WIDTH,
      render: (_val, _record, index) => {
        if (index === 0) {
          return '表单字段';
        }
        return index;
      },
    };

    const tmp = formatCols.map((item: any, idx: number) => {
      // 当前列的选择字段
      let currentOption: any = null;
      let isRelationOption = false;
      let isParentCell = false;
      let isCustomerRelation = false;

      if (item.parentCell) {
        const colParentId = item?.parentCell?.match?.fieldId;
        const selectedTableId = tableSelected[colParentId];
        const selectedTable = tableOptions[selectedTableId]; // 所选 子表格 的所有信息
        const selectedTableOptions = selectedTable ? selectedTable.options : []; // 所选子表格的下拉选项
        currentOption =
          selected[idx] === DISABLE_IMPORT_OPTION.key
            ? DISABLE_IMPORT_OPTION
            : selectedTableOptions.find((opt) => opt.key === selected[idx]);
        isRelationOption = currentOption && currentOption.widget === 'relation';
        isCustomerRelation =
          currentOption?.widget == 'relation' && currentOption?.relatedOptions
            ? true
            : false;
        isParentCell = true;
      } else {
        currentOption =
          selected[idx] === DISABLE_IMPORT_OPTION.key
            ? DISABLE_IMPORT_OPTION
            : options.find((opt) => opt.key === selected[idx]);
        isRelationOption = currentOption && currentOption.widget === 'relation';
        isCustomerRelation =
          currentOption?.widget == 'relation' && currentOption?.relatedOptions
            ? true
            : false;
      }
      const c = Object.create(null);
      c.title = getColTitle(item, tableSelected);

      c.colSpan = item.colSpan;
      c.dataIndex = item.colIndex;
      c.width = isCustomerRelation
        ? TABLE_RELATION_CUS_COL_WIDTH
        : isRelationOption
        ? TABLE_RELATION_COL_WIDTH
        : isParentCell && item.colSpan === 1
        ? TABLE_COL_WIDTH * 2
        : TABLE_COL_WIDTH;
      // 如果当前选择的是客户关系对象 则在前面添加一下客户选择select
      const customerRelationOption: any[] = [];
      if (isCustomerRelation) {
        currentOption.relatedOptions?.forEach((val) => {
          if (val.bizType == 'crm_customer') {
            customerRelationOption.push({
              ...val,
              key: val.bizType,
              id: val.bizType,
              title: '企业客户',
            });
          } else {
            customerRelationOption.push({
              ...val,
              key: val.bizType,
              id: val.bizType,
              title: '个人客户',
            });
          }
        });
      }
      // 自定义tableCell的样式
      c.onCell = (_: any, rowIndex: number) => {
        const style: any = {};
        if (rowIndex !== 0 && selected[idx] === DISABLE_IMPORT_OPTION.key) {
          style.backgroundColor = 'rgba(23, 26, 29, 0.04)';
          style.color = 'rgba(23, 26, 29, 0.24)';
        }
        return {
          style,
        };
      };
      // 自定义头部单元格样式 （主要是为了设置合并单元格的padding为0）
      c.onHeaderCell = (col: any) => {
        return {
          style: {
            padding: col.colSpan > 1 ? 0 : 16,
          },
        };
      };
      // 自定义tableCell的渲染值
      c.render = (val: any, record: any, itemIdx: number) => {
        // 列表的第0行，为下拉选项
        if (itemIdx === 0) {
          // 非子表格的列
          if (!item.parentCell) {
            return (
              <div className="importSelectedWrapper">
                {isCustomerRelation && (
                  <div className="importCusRelationWrapper">
                    <Select
                      style={{ width: 100 }}
                      placeholder="选择客户类型"
                      optionLabelProp="showValue"
                      className="relationSelect"
                      value={relationCusSelected[selected[idx]]}
                      onChange={(e) => {
                        const tmpSelected = cloneDeep(relationCusSelected);
                        tmpSelected[selected[idx]] = e;
                        setRelationCusSelected(tmpSelected);
                        // 切换客户时清空主键之前选中的值
                        const tmpRelationSelected = cloneDeep(relationSelected);
                        tmpRelationSelected[selected[idx]] = '';
                        setRelationSelected(tmpRelationSelected);
                      }}
                      showSearch
                      filterOption={(input, option) => {
                        return (
                          option?.showValue
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {customerRelationOption.map((item) => (
                        <Option
                          value={item.key}
                          key={item.key}
                          className="optionWrapper"
                          showValue={item.title}
                        >
                          {item.title}
                        </Option>
                      ))}
                    </Select>
                    <Tooltip
                      placement="top"
                      title="匹配成功将导入数据，并自动关联历史数据；匹配不成功则只导入数据，不关联历史数据。"
                    >
                      <ExclamationCircleOutlined />
                    </Tooltip>
                  </div>
                )}
                <Select
                  style={{ width: 100 }}
                  dropdownClassName="importSelectDropdown"
                  dropdownMatchSelectWidth={false}
                  key={idx}
                  value={selected[idx]}
                  onChange={(e) => {
                    const tmpSelected = [...selected];
                    const hasSelectedIndex = tmpSelected.findIndex(
                      (item) => item === e,
                    );
                    if (hasSelectedIndex >= 0) {
                      tmpSelected[hasSelectedIndex] = DISABLE_IMPORT_OPTION.key;
                    }
                    tmpSelected[idx] = e;
                    setSelected(tmpSelected);
                  }}
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option?.showValue
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  optionLabelProp="showValue"
                >
                  <Option
                    value={DISABLE_IMPORT_OPTION.key}
                    showValue={DISABLE_IMPORT_OPTION.title}
                  >
                    {DISABLE_IMPORT_OPTION.title}
                  </Option>
                  {
                    // 当含有更新的情况，需要增加"数据id（系统）"的下拉选项
                    [ModeOptions[1].key, ModeOptions[2].key].includes(
                      selectMode.key,
                    ) && (
                      <Option
                        value={INSTANCE_ID_OPTION.key}
                        showValue={INSTANCE_ID_OPTION.title}
                        className="optionWrapper"
                      >
                        <div className="optionLeft">
                          <div className="optionRequire">*</div>
                          <div className="optionTitle">
                            {INSTANCE_ID_OPTION.title}
                          </div>
                        </div>
                        <div className="optionRight">
                          {renderSelectOptionRight(idx, INSTANCE_ID_OPTION.key)}
                        </div>
                      </Option>
                    )
                  }
                  {options.map((item) => (
                    <Option
                      value={item.key}
                      key={item.key}
                      className="optionWrapper"
                      showValue={item.title}
                    >
                      <div className="optionLeft">
                        {item.required && (
                          <div className="optionRequire">*</div>
                        )}
                        <div className="optionTitle">{item.title}</div>
                      </div>
                      <div className="optionRight">
                        {renderSelectOptionRight(idx, item.key)}
                      </div>
                    </Option>
                  ))}
                </Select>
                {isRelationOption && (
                  <div className="importRelationWrapper">
                    <Select
                      style={{ width: 100 }}
                      placeholder="主键"
                      optionLabelProp="showValue"
                      className="relationSelect"
                      value={relationSelected[selected[idx]]}
                      onChange={(e) => {
                        const tmpRelationSelected = cloneDeep(relationSelected);
                        tmpRelationSelected[selected[idx]] = e;
                        setRelationSelected(tmpRelationSelected);
                      }}
                      showSearch
                      filterOption={(input, option) => {
                        return (
                          option?.showValue
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {getRelationOptions(idx).map((ele) => (
                        <Option
                          value={ele.key}
                          key={ele.key}
                          className="optionWrapper"
                          showValue={ele.title}
                        >
                          {ele.title}
                        </Option>
                      ))}
                    </Select>
                    <Tooltip
                      placement="top"
                      title="系统按照主键字段来识别关联数据，若当前列的值=主键字段值，则匹配成功，并自动关联历史数据id；否则提示匹配不成功，导入失败。"
                    >
                      <ExclamationCircleOutlined />
                    </Tooltip>
                  </div>
                )}
              </div>
            );
          } else {
            // 子表格下的列
            return (
              <div className="importSelectedWrapper">
                <Select
                  style={{ width: 100 }}
                  dropdownClassName="importSelectDropdown"
                  dropdownMatchSelectWidth={false}
                  key={idx}
                  value={selected[idx]}
                  disabled={
                    tableSelected[item?.parentCell?.match?.fieldId] ===
                    DISABLE_IMPORT_OPTION.key
                  }
                  onChange={(e) => {
                    const tmpSelected = [...selected];
                    const hasSelectedIndex = tmpSelected.findIndex(
                      (item) => item === e,
                    );
                    if (hasSelectedIndex >= 0) {
                      tmpSelected[hasSelectedIndex] = DISABLE_IMPORT_OPTION.key;
                    }
                    tmpSelected[idx] = e;
                    setSelected(tmpSelected);
                  }}
                  showSearch
                  filterOption={(input, option) => {
                    return (
                      option?.showValue
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  optionLabelProp="showValue"
                >
                  <Option
                    value={DISABLE_IMPORT_OPTION.key}
                    showValue={DISABLE_IMPORT_OPTION.title}
                  >
                    {DISABLE_IMPORT_OPTION.title}
                  </Option>
                  {tableOptions[
                    tableSelected[item?.parentCell?.match?.fieldId]
                  ] &&
                    tableOptions[
                      tableSelected[item?.parentCell?.match?.fieldId]
                    ].options
                      .filter((val) => !filterText.includes(val.widget))
                      .map((op) => (
                        <Option
                          value={op.key}
                          key={op.key}
                          className="optionWrapper"
                          showValue={`${
                            tableOptions[
                              tableSelected[item?.parentCell?.match?.fieldId]
                            ].title
                          }.${op.title}`}
                        >
                          <div className="optionLeft">
                            {/* {JSON.stringify(op)} */}
                            {op.required && (
                              <div className="optionRequire">*</div>
                            )}
                            <div className="optionTitle">
                              {
                                tableOptions[
                                  tableSelected[
                                    item?.parentCell?.match?.fieldId
                                  ]
                                ].title
                              }
                              .{op.title}
                            </div>
                          </div>
                          <div className="optionRight">
                            {renderSelectOptionRight(idx, op.key)}
                          </div>
                        </Option>
                      ))}
                </Select>
                {isRelationOption && (
                  <div className="importRelationWrapper">
                    <Select
                      style={{ width: 100 }}
                      placeholder="主键"
                      optionLabelProp="showValue"
                      className="relationSelect"
                      value={relationSelected[selected[idx]]}
                      onChange={(e) => {
                        const tmpRelationSelected = cloneDeep(relationSelected);
                        tmpRelationSelected[selected[idx]] = e;
                        setRelationSelected(tmpRelationSelected);
                      }}
                      showSearch
                      filterOption={(input, option) => {
                        return (
                          option?.showValue
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                    >
                      {getTableRelationOptions(idx).map((ele) => (
                        <Option
                          value={ele.key}
                          key={ele.key}
                          className="optionWrapper"
                          showValue={ele.title}
                        >
                          {ele.title}
                        </Option>
                      ))}
                    </Select>
                    <Tooltip
                      placement="top"
                      title="系统按照主键字段来识别关联数据，若当前列的值=主键字段值，则匹配成功，并自动关联历史数据id；否则提示匹配不成功，导入失败。"
                    >
                      <ExclamationCircleOutlined />
                    </Tooltip>
                  </div>
                )}
              </div>
            );
          }
        }

        const currentRecord = record[item.colIndex];
        const obj = {
          children:
            currentRecord && !currentRecord.subCellMap ? (
              currentRecord.text
            ) : (
              <div className="dataSubCellWrapper">
                {currentRecord &&
                  currentRecord.subCellMap.map((subCell: any) => (
                    <div className="dataSubCellItem">
                      <div className="dataSubCell">{subCell.text}</div>
                    </div>
                  ))}
              </div>
            ),
          props: {
            style: {
              padding: currentRecord && currentRecord.subCellMap ? 0 : 16,
            },
          },
        };
        return obj;
      };
      return c;
    });

    tmp.unshift(firstCol);
    return tmp;
  }, [
    previewData,
    selected,
    options,
    relationSelected,
    formatCols,
    tableOptions,
    tableSelected,
    relationCusSelected,
    relationPrimaryKey,
  ]);

  // 接口返回的行数据处理
  const initData = useMemo(() => {
    return previewData.sampleData.map((row: any) => {
      const map = Object.create(null);
      row?.forEach((item: any) => {
        if (item.subCellMap) {
          for (let i = 0; i < item.colSpan; i++) {
            const subTmp: any[] = [];
            Object.keys(item.subCellMap)?.forEach((subCell: any) => {
              const targetColIndexCell = item.subCellMap[subCell].find(
                (cell: any) => cell.colIndex === item.colIndex + i,
              );
              subTmp.push(targetColIndexCell);
            });
            map[item.colIndex + i] = {
              text: item.text,
              subCellMap: subTmp,
            };
          }
        } else {
          map[item.colIndex] = {
            text: item.text,
            subCellMap: item.subCellMap,
          };
        }
      });
      return map;
    });
  }, [previewData]);

  // 根据接口返回的行数据，增加一行，用作展示下拉选项
  const data: any[] = useMemo(() => {
    if (initData.length) {
      initData.unshift(initData[0]);
      return initData;
    }
    return [];
  }, [initData]);

  // console.log('ggg--------------分割线--------------ggg')
  // console.log('ggg------formConfigCtx', formConfigCtx);
  // console.log('ggg---------mode', selectMode);
  // console.log('ggg---------file', fileData);
  // console.log('ggg--------previewData', previewData);
  // console.log('ggg---------selected', selected);
  // console.log('ggg---------tableSelected', tableSelected);
  // console.log('ggg---------relationSelected', relationSelected);
  // console.log('ggg------options', options);
  // console.log('ggg--------tableOptions', tableOptions);
  // console.log('ggg--------formatCols', formatCols);

  return (
    <div className={c.importStep2}>
      <div className={c.topRow}>
        <div className={c.formTitleWrapper}>
          <div className={c.formTitle}>
            为每一列数据指定一个字段，已指定的数据列才会导入，请确保已指定带*必填标识的字段
          </div>
        </div>
        <div className={c.formSelect}>
          导入
          {selected.filter((item) => item !== DISABLE_IMPORT_OPTION.key).length}
          列/共{columns.length - 1}列
        </div>
      </div>

      <div className={c.tableWrapper}>
        <Table
          scroll={{ x: tableWidth, y: TABLE_HEIGHT }}
          columns={columns}
          dataSource={data}
          bordered
          pagination={false}
        />
      </div>
    </div>
  );
};

export default forwardRef(ImportStep2);
