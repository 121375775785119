/*
 * @Author: 刘昕 xinlx@tungee.com
 * @Date: 2022-10-17 11:08:01
 * @LastEditors:  xinlx@tungee.com
 * @LastEditTime: 2022-11-03 14:17:49
 * @FilePath: \low-code-platform\src\pages\generator\relation\components\Header\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react';
import c from './index.less';
import { HEADER_DESCRIPTION, HeaderType } from '../../constants';
import { Tooltip } from 'antd';

export default (props: any) => {
  const Component = HEADER_DESCRIPTION[location.pathname as HeaderType];
  return (
    <div className={c.top_header}>
      <div className={c.l_text}>
        <div className={c.title}>{Component?.title}</div>
        <div className={c.subTitle}>{Component?.subTitle}</div>
        {[HeaderType.BUSINESS, HeaderType.SUBMIT].includes(
          location.pathname as HeaderType,
        ) ? (
          <div
            className={c.description}
            onClick={() => {
              Component.tooltip();
            }}
          >
            查看详细说明
          </div>
        ) : null}
      </div>
      {/* <Component.tooltip /> */}
      {props.children}
    </div>
  );
};
