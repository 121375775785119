/*
 * :file description:
 * :name: \low-code-platform\src\models\usePermission.ts
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-26 16:57:53
 * :last editor: hyw
 * :date last edited: 2022-02-21 18:06:33
 */
import { useState, useCallback, useEffect, useRef } from 'react';
import { includes, getToken, isInDingtalk } from '@/utils';
import { getMenu } from '@/services/user';

export default function usePermission() {
  const [permission, setPermission] = useState([]);
  const permissionRef = useRef();
  const permissionListRef = useRef();

  const clearPermission = useCallback(() => {
    permissionRef.current = undefined;
    permissionListRef.current = undefined;
  }, []);

  const updatePermission = useCallback((permission = null) => {
    permissionRef.current = permission;
    setPermission(permission);
    if (Array.isArray(permission)) {
      const list = permission.map((item) => item.authorityCode);
      permissionListRef.current = list;
    }
  }, []);

  const check = useCallback((permissions: string | string[]) => {
    const hasPermissions = Array.isArray(permissions)
      ? permissions
      : [permissions];
    if (!hasPermissions.length) {
      return true;
    }
    if (includes(permissionListRef.current, hasPermissions)) {
      return true;
    }
    return false;
  }, []);

  const bindAuth = useCallback((instance, permissions) => {
    return check(permissions) ? instance : null;
  }, []);

  const getPermission = async () => {
    const isLogin = getToken() ? true : false;
    if (isLogin) {
      let res;
      if (isInDingtalk()) {
        const list =
          sessionStorage.getItem(`tungee_permission_${window.__corpId}`) ?? '';
        try {
          res = JSON.parse(list) ?? [];
        } catch (e) {
          res = [];
          console.log(e, '权限组列表解析失败');
        }
      } else {
        const result = await getMenu();
        res = result?.data ?? [];
      }
      updatePermission(res);
    }
  };

  useEffect(() => {
    getPermission();
  }, []);

  return {
    clearPermission,
    updatePermission,
    check,
    bindAuth,
    permission,
  };
}
