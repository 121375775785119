/*
 * @Description:
 * @FilePath: /low-code-platform/tg-renderer/packages/form-render/src/widgets/SelectOptions/OptionsAssociatedModal/power.tsx
 * :author: wu.shangru
 * :copyright: (c) 2023, Tungee
 * @Date: 2023-11-29 17:41:31
 * @LastEditors: wu.shangru
 * @LastEditTime: 2023-12-08 14:30:29
 */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import classnames from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import { Modal, Table, Form, Select, TreeSelect, Input } from 'antd';
import deepClone from 'lodash/cloneDeep';
import get from 'lodash/get';
import IconFont from '@/components/IconFont';
import styles from './index.less';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import { departmentTree, roleTree, listAll, getUserByPage } from './api';
import MultipleChoice from '@/components/MultipleChoice';

const ShowUserOrDept = (props: any) => {
  const {
    ele,
    flowType,
    value = {},
    memberTitleList,
    onChange,
    hiddenDynamicPrinciple,
    placeholder = '请设置权限',
    title = '选择权限人员',
  } = props;

  const ext = {
    id: props?.id,
    labeel: props?.label,
  };

  const childRef = useRef(null);

  const computeData = (data: any) => {
    let showArr: Array<string> = [];
    let json: Object = deepClone(data);

    Object.entries(json)?.forEach((ele) => {
      ele[1]?.forEach((e: any) => {
        if (ele[0] === 'departments') e.type = 'department';
        if (ele[0] === 'users') e.type = 'employee';
        if (ele[0] === 'roles') e.type = 'role';
        showArr.push(e);
      });
    });
    return showArr;
  };

  // 打开人员选择器
  const addOrEditUsers = (flowType: string) => {
    // 发起人默认不可选人
    if (flowType == '1') return;
    (childRef?.current as any)?.openModal();
  };

  // 移除选择的数据
  const removeOne = (e: Event, currentParams: any) => {
    let allData = value;
    switch (currentParams.type) {
      case 'role':
      case 'department':
      case 'initiator':
      case 'member_field':
        let mapTypeJson: any = {
          role: 'roles',
          department: 'departments',
          initiator: 'dynamicPerson',
          member_field: 'dynamicPerson',
        };
        let currentArr = allData[mapTypeJson[currentParams.type]].filter(
          (ele) => currentParams.id != ele.id,
        );
        let finallyData = {
          ...allData,
          [mapTypeJson[currentParams.type]]: currentArr,
        };
        onChange?.(finallyData, ext);
        break;
      case 'employee':
        let users = allData.users.filter(
          (ele) =>
            currentParams.id != ele.id || currentParams.userId != ele.userId,
        );
        let finallyUsersData = {
          ...allData,
          users,
        };
        onChange?.(finallyUsersData, ext);
        break;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  // 获取人员选择器数据
  const getDataBack = (data: any) => {
    console.log(data, 'getDataBack');
    onChange?.(data, ext);
  };

  const listData = computeData(value);

  const tabsList = useMemo(() => {
    return ['部门', '人员', '角色'];
  }, [hiddenDynamicPrinciple]);

  return (
    <>
      <div
        className={classnames(
          styles.usersDiv,
          listData?.length == 0 ? styles.placeholder : '',
          flowType == '1' && styles.disabledUsersDiv,
        )}
        onClick={() => addOrEditUsers(flowType)}
      >
        {listData?.length !== 0
          ? listData?.map((data: any) => {
              return (
                <span className={styles.users} key={data.id}>
                  {data.name}{' '}
                  <CloseOutlined
                    onClick={(e) => removeOne(e, data)}
                    className={styles.icon}
                  />
                </span>
              );
            })
          : placeholder}
      </div>
      <MultipleChoice
        cRef={childRef}
        title={title}
        initData={value}
        tabs={tabsList}
        getDataBack={getDataBack}
        memberTitleList={memberTitleList}
      />
    </>
  );
};

const OptionsPowerModal = ({
  visible,
  setVisible,
  value,
  onChange,
  info = {},
}) => {
  const { addons = {} } = info;
  const { getValues, setValues, formData } = addons;
  const { displaySchema, selected } = useStore();
  const { enumPowerFields = [] } = formData as any;
  const { value: optionList } = info as any;
  const curWidgetId = selected?.slice(2);
  const [form] = Form.useForm();

  const columns = [
    {
      title: '选项',
      dataIndex: 'trigger',
      key: 'trigger',
      width: 220,
    },
    {
      className: 'powerTitle',
      title: () => {
        return (
          <div>
            仅以下权限组用户可用
            <span className="powerTitleTip">
              （若为空，则表示选项对全公司用户开放）
            </span>
          </div>
        );
      },
      dataIndex: 'fields',
      key: 'fields',
      render: (text, record, index) => {
        return (
          <Form.Item
            name={record.value}
            key={`${curWidgetId}_${text}_${index}`}
          >
            <ShowUserOrDept {...record} hiddenDynamicPrinciple={true} />
          </Form.Item>
        );
      },
    },
  ];
  const genDataSource = () => {
    return deepClone(optionList || []).map((v, index) => ({
      ...v,
      trigger: v.label,
      fields: v?.fields || [],
    }));
  };

  const handleOk = () => {
    const lastValues = getValues();
    const formData = form.getFieldsValue();
    const targetArr = Object.values(formData)?.map((i) => {
      const arr = Object.entries(i)?.map((ele) => {
        if (!ele[1]?.length) {
          return [];
        }
        if (ele[1]?.length) {
          return ele[1]?.map((j) => {
            return {
              id: j?.id,
              key: j?.key,
              level: j?.level,
              name: j?.name,
              title: j?.title,
              parentId: j?.parentId,
              type: ele[0],
              userId: j?.userId,
            };
          });
        }
      });
      return [...arr?.flat(2)];
    });
    setValues({
      ...lastValues,
      enumPowerFields: targetArr,
    });
    setVisible(false);
  };

  const arrToObj = (arr: []) => {
    const obj = {
      departments: [],
      dynamicPerson: [],
      roles: [],
      users: [],
    };
    arr?.forEach((item) => {
      const type = item?.type;
      obj?.[type]?.push(item);
    });
    return obj;
  };

  useEffect(() => {
    if (!Array.isArray(optionList)) return;
    const formData = optionList.reduce((acc, cur, index) => {
      acc[cur.value] = arrToObj(enumPowerFields?.[index] ?? []);
      return acc;
    }, {});
    form.setFieldsValue(formData);
  }, [optionList]);

  return (
    <Modal
      width={800}
      className={styles.modal}
      title={`${get(
        displaySchema,
        `properties[${curWidgetId?.split('/')?.[0]}].title`,
      )}-选项权限设置`}
      destroyOnClose
      closeIcon={<IconFont type="icon-Close" />}
      visible={visible}
      onOk={handleOk}
      onCancel={() => setVisible(false)}
    >
      <div className={styles.tip}>
        在新建/编辑/筛选/查看时，仅展示用户有权限的选项：
      </div>
      <Form form={form} component={false}>
        <Table
          bordered={true}
          columns={columns}
          dataSource={genDataSource()}
          pagination={false}
        ></Table>
      </Form>
    </Modal>
  );
};

export default OptionsPowerModal;
