/*
 * :file description:
 * :name: /low-code-platform/src/customPage/pages/CRM/duplicateCheck/index.tsx
 * :author: KyleLiang
 * :copyright: (c) 2024, Tungee
 * :date created: 2024-05-06 13:50:00
 * :last editor: KyleLiang
 * :date last edited: 2024-08-02 14:43:00
 */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, InputNumber, message } from 'antd';
import {
  fuzzyMatchConfig,
  updateFuzzyMatchConfig,
} from '../../../api/DuplicateCheck';
import './index.less';

const { TextArea } = Input;

const FuzzyMatchingSettingModal = (props) => {
  const { visible } = props;
  const [matchNum, setMathNum] = useState(null);
  const [notMatchKeywords, setNotMatchKeywords] = useState(null);

  useEffect(() => {
    fuzzyMatchConfig({
      corpId: window.__corpId,
    }).then((res) => {
      // console.log(res, 'fuzzyMatchConfig');
      const { matchNum, notMatchKeyWords } = res?.result;
      setMathNum(matchNum);
      setNotMatchKeywords(notMatchKeyWords);
    });
  }, []);

  const onHandleCancel = () => {
    props.onClose();
  };

  const onHandleConfirm = () => {
    if (matchNum !== null || notMatchKeywords) {
      updateFuzzyMatchConfig({
        corpId: window.__corpId,
        matchNum: matchNum,
        notMatchKeyWords: notMatchKeywords,
      }).then((res) => {
        console.log(res, 'updateFuzzyMatchConfig');
        if (res?.code === 200 && res?.result) {
          message.success('设置成功！');
        } else {
          message.error(res?.message || '设置失败');
        }
      });
    }
    props.onClose();
  };

  const handleNumber = (value: number) => {
    console.log('changed', value);
    setMathNum(value);
  };

  const handleTextArea = (e) => {
    console.log(e.target.value);
    setNotMatchKeywords(e.target.value);
  };
  return (
    <Modal
      visible={visible}
      onCancel={onHandleCancel}
      title="模糊匹配设置"
      width={400}
      wrapClassName="crm-fuzzy-matching-modal"
      bodyStyle={{ padding: '24px' }}
      destroyOnClose
      maskClosable={false}
      footer={
        <div>
          <Button onClick={onHandleCancel}>取消</Button>
          <Button type="primary" onClick={onHandleConfirm}>
            确定
          </Button>
        </div>
      }
    >
      <div className={'setting-one'}>
        <div className={'title'}>设置重叠字数要求即满足模糊匹配</div>
        <div className={'content'}>
          <Input.Group compact>
            <Input style={{ width: '80px' }} defaultValue="大于等于" disabled />
            <InputNumber
              style={{ width: '266px' }}
              placeholder="请输入数字"
              onChange={handleNumber}
              min={0}
              value={matchNum}
            />
          </Input.Group>
        </div>
      </div>
      <div className={'setting-two'}>
        <div className={'title'}>设置不参与模糊匹配的词组</div>
        <TextArea
          rows={5}
          placeholder="请输入词组，两个词之间用 、分隔"
          onChange={handleTextArea}
          value={notMatchKeywords}
        />
      </div>
    </Modal>
  );
};

export default FuzzyMatchingSettingModal;
