/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\EditAddress\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-31 14:07:27
 * :last editor: hyw
 * :date last edited: 2022-04-19 17:51:10
 */
import Province from './Province';
import Area from './Area';
import Street from './Street';

const Address = (props) => {
  const { schema, onChange, value } = props;
  const { needDetail } = schema;
  const onProvinceChange = (value, selectedOptions) => {
    if (selectedOptions?.length > 0) {
      onChange({
        address: selectedOptions.map((item) => item.label),
      });
    } else {
      onChange();
    }
  };

  const onStreetChange = (val) => {
    onChange(val);
  };

  if (props.readOnly) {
    let address = value?.address.join('');
    return (
      <span className="showNoEditText">{`${address ?? ''}${
        value?.street ?? ''
      }${value?.detail ?? ''}`}</span>
    );
  }

  return (
    <div style={{ flex: 1, marginTop: 15 }}>
      {needDetail === 0 && (
        <Province onChange={onProvinceChange} value={value} />
      )}
      {needDetail === 1 && <Area onChange={onProvinceChange} value={value} />}
      {needDetail === 2 && <Street onChange={onStreetChange} value={value} />}
    </div>
  );
};

export default Address;
