/*
 * :file description:
 * :name: \low-code-platform\src\pages\form\components\FilterOptionsSetting\index.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-13 17:42:03
 * :last editor: hyw
 * :date last edited: 2022-03-28 13:53:12
 */

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Popover,
  Checkbox,
  Divider,
  CheckboxOptionType,
  message,
} from 'antd';
import _ from 'lodash';
import { InfoCircleOutlined, SettingOutlined } from '@ant-design/icons';
import IconFont from '@/components/IconFont';
import formApi from '@/services/form';
import { SUPPORTED_NOW, debarComponents } from '../../utils/parse_schema.js';
import SortOptions from './SortOptions';
import c from './index.less';

export default function FilterOptionsSetting(props: { [key: string]: any }) {
  const { formCode, formSchema, getAllField, frequentlyIds } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [value, setValue] = useState<string[]>([]);
  const [allOptions, setAllOptions] = useState<CheckboxOptionType[]>([]);
  const [commonOptions, setCommonOptions] = useState<CheckboxOptionType[]>([]);

  const getOptions = () => {
    console.log('#######');
    console.log(formSchema);
    console.log('#######');
    let newProperties: any = {};

    formSchema?.properties &&
      Object.keys(formSchema?.properties || {})?.forEach((key: any) => {
        // console.log(key, '555555');
        // 将多选标签归类
        if (formSchema.properties[key].widget === 'multiTag') {
          newProperties['allTags'] = {
            title: '客户标签',
            widget: 'multiTag',
          };
        } else {
          newProperties[key] = formSchema.properties[key];
        }
      });
    // console.log(newProperties, '555555');
    // const fitCompNames = Object.keys(formSchema.properties).filter((item) => {
    const fitCompNames = Object.keys(newProperties || {}).filter((item) => {
      // const compName = formSchema.properties[item].widget;
      const compName = newProperties[item].widget;
      return debarComponents(SUPPORTED_NOW).includes(compName);
    });
    let optionsFromConfig = _.compact(
      // Object.keys(formSchema.properties).map((key) => {
      Object.keys(newProperties).map((key) => {
        if (fitCompNames.includes(key)) {
          return {
            id: key,
            value: key,
            // label: formSchema.properties[key].title,
            label: newProperties[key].title,
          };
        }
        return undefined;
      }),
    );
    setAllOptions(
      _.uniqBy(
        optionsFromConfig.concat([
          {
            id: 'gmtCreate',
            value: 'gmtCreate',
            label: '创建时间',
          },
          {
            id: 'createUserId',
            value: 'createUserId',
            label: '创建人',
          },
          {
            id: 'gmtModified',
            value: 'gmtModified',
            label: '更新时间',
          },
        ]),
        'id',
      ),
    );
    setValue(frequentlyIds || []);
  };

  const onChange = (checkedValues: any[]) => {
    console.log('checkedValues ', checkedValues);
    setCommonOptions(allOptions.filter((v) => checkedValues.includes(v.value)));
    setValue(checkedValues);
  };

  const handleOk = () => {
    console.log(value);
    console.log('排序后：', commonOptions);
    if (!value.length) {
      return message.warning('至少选择一个筛选项');
    }
    formApi
      .updateFilterField({
        formCode,
        fieldIds: commonOptions.map((v) => v.id),
      })
      .then((res) => {
        message.success('保存成功');
        getAllField();
        setIsModalVisible(false);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (!isModalVisible) return;
    getOptions();
  }, [isModalVisible]);

  useEffect(() => {
    setCommonOptions(allOptions.filter((v) => value.includes(v.value)));
  }, [value]);

  const content = (
    <div>
      <div>常用筛选项将展示在列表上方</div>
      <div>您可以对常用筛选项进行移动、隐藏</div>
      <div className={c.operating_instructions}></div>
    </div>
  );

  return (
    <div className={c.filter_options_setting}>
      <IconFont
        type="icon-shezhi_1"
        style={{ fontSize: '16px', color: 'rgba(23,26,29,0.60)' }}
        onClick={() => setIsModalVisible(true)}
      />
      <Modal
        width={600}
        title="常用筛选项设置"
        visible={isModalVisible}
        okText="确定"
        cancelText="取消"
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <div className={c.filter_options_container}>
          <div className={c.all_options}>
            <div style={{ color: '#171A1D' }}>
              全部筛选项（{allOptions.length}）
            </div>
            <div className={c.all_options_items}>
              <Checkbox.Group
                value={value}
                options={allOptions}
                onChange={onChange}
              />
            </div>
          </div>

          <div className={c.common_options}>
            <Divider
              type="vertical"
              style={{ height: '100%', width: '24px' }}
            />
            <div style={{ width: '100%' }}>
              <span style={{ color: '#171A1D' }}>
                常用筛选项（{commonOptions.length}）{' '}
              </span>
              <Popover content={content}>
                <IconFont
                  style={{ color: '#a5a6a8' }}
                  type="icon-shuoming"
                ></IconFont>
              </Popover>
              <SortOptions
                items={commonOptions}
                updateItems={setCommonOptions}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
