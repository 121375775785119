/*
 * @Author: 刘昕 xinlx@tungee.com
 * @Date: 2022-10-11 16:22:35
 * @LastEditors: 刘昕 xinlx@tungee.com
 * @LastEditTime: 2022-11-19 09:43:08
 * @FilePath: \tj-ali-crm-management\src\pages\Connector\Drawer\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { Children, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useModel } from 'umi';
import {
  Drawer,
  Input,
  Select,
  Space,
  DatePicker,
  Table,
  Tooltip,
  Typography,
  message,
} from 'antd';
import type {
  ColumnsType,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import type { DatePickerProps, RangePickerProps } from 'antd/es/date-picker';
import {
  getConnectorTaskLog,
  retryProcedureExecLog,
} from '@/services/extension-setting/index';
import moment from 'moment';
import Style from './index.less';

import _ from 'lodash';
import { QuestionCircleOutlined } from '@ant-design/icons';

interface DataType {
  id: number; // 实例ID
  status: number; // 状态
  gmtFinish: string; // 完成时间
  processCost: number; // 耗时时间
  description: string; // 描述信息
  isCompensatable?: boolean; // 是否重新推送按钮
}

const { TextArea } = Input;
const { Option } = Select;
interface ILocation {
  formCode: string;
  type: string;
}

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
}

const { RangePicker } = DatePicker;
export const schemaOptionsContext = React.createContext([]);

const Index = (props: any) => {
  const { showLog, width, setShowLog, currentConnectorId } = props;
  const location = useLocation<ILocation>();

  const { formCode, type, menu } = location?.query || {};

  const [status, setStatus] = useState<number | string>('');
  const [dateInterval, setDateInterval] = useState<RangePickerProps['value']>(
    [],
  );

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 10,
      showTotal: (total) => `总共 ${total} 项`,
      showQuickJumper: true,
    },
  });

  const onClose = () => {
    setShowLog(false);
  };

  const handleOnSelect = (value) => {
    setStatus(value);
  };
  const handleOnRangePickerChange = (
    dates: RangePickerProps['value'],
    dateStrings: [string, string],
  ) => {
    setDateInterval(dates);
  };

  const fechData = () => {
    setLoading(true);
    const info = {
      begin:
        dateInterval && dateInterval?.length > 1
          ? dateInterval[0]?.format('YYYY-MM-DD 00:00:00')
          : '',
      end:
        dateInterval && dateInterval?.length > 1
          ? dateInterval[1]?.format('YYYY-MM-DD 23:59:59')
          : '',
      status: status,
      order: [],
      pageNum: tableParams.pagination?.current || 1,
      pageSize: tableParams.pagination?.pageSize || 10,
      sort: [],
      formCode,
    };
    getConnectorTaskLog(currentConnectorId, info)
      .then((res: any) => {
        setDataSource(res.data.records);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: res.data.total,
          },
        });
        setLoading(false);
      })
      .catch((error) => {
        console.log('error', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    currentConnectorId && fechData();
  }, [JSON.stringify(tableParams), currentConnectorId, status, dateInterval]);

  useEffect(() => {
    setStatus('');
    setDateInterval([]);
  }, [showLog]);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setTableParams({
      pagination: { ...tableParams.pagination, ...pagination },
    });
  };

  const pushAgain = (id: string) => {
    retryProcedureExecLog({
      id: id!,
      needThrowExWhenNotFount: true,
    })
      .then((res) => {
        message.success('重新推送成功');
      })
      .catch((error) => {
        message.error('重新推送失败，请重试');
      });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: '执行ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: { showTitle: false },
      render: (text, record, index) => {
        return <Tooltip title={text}>{text}</Tooltip>;
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      // 0 未执行 1 执行中 2 已完成
      render: (text, record, index) => {
        switch (text) {
          case 1:
            return (
              <div>
                <span
                  style={{ background: '#0089FF' }}
                  className={Style.round}
                ></span>
                执行中
              </div>
            );
          case 2:
            return (
              <div>
                <span
                  style={{ background: '#00B042' }}
                  className={Style.round}
                ></span>
                执行成功
              </div>
            );
          case -1:
            return (
              <div>
                <span
                  style={{ background: '#E41100' }}
                  className={Style.round}
                ></span>
                执行异常&nbsp;
                <Tooltip title={record?.description || ''}>
                  <QuestionCircleOutlined />
                </Tooltip>
              </div>
            );
          default:
            return '-';
        }
      },
    },
    {
      title: '完成时间',
      dataIndex: 'gmtFinish',
      key: 'gmtFinish',
    },
    {
      title: '运行耗时(ms)',
      dataIndex: 'processCost',
      key: 'processCost',
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (text: any, record: any) => {
        return (
          <span>
            {record?.isCompensatable ? (
              <Typography.Link
                onClick={() => pushAgain(record.id)}
                style={{ marginRight: 8 }}
              >
                重新推送
              </Typography.Link>
            ) : (
              '-'
            )}
          </span>
        );
      },
    },
  ];

  return (
    <Drawer
      title={'运行日志'}
      width={width}
      placement="right"
      onClose={onClose}
      open={showLog}
      className={Style.drawer}
    >
      <Space style={{ marginBottom: 16 }}>
        <Select
          placeholder="请选择流程状态"
          value={status}
          onChange={handleOnSelect}
          style={{ width: '160px' }}
        >
          <Option value={''}>全部状态</Option>
          <Option value={2}>执行成功</Option>
          <Option value={1}>执行中</Option>
          <Option value={-1}>执行异常</Option>
        </Select>
        <RangePicker
          value={dateInterval}
          onChange={handleOnRangePickerChange}
        />
      </Space>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        onChange={handleTableChange}
        pagination={tableParams.pagination}
      />
    </Drawer>
  );
};

export default Index;
