/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\IPhone\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 14:28:41
 * :last editor: 黄艺
 * :date last edited: 2021-12-29 15:39:20
 */
import React, { FC } from 'react';
import { Rate } from 'antd';
import { WidgetProps } from 'typings';
import c from './index.less';

const StarRate: FC<WidgetProps> = (props) => {
  console.log(props, 'iphone__props');
  const { value, schema } = props;

  return <Rate className={c.rate} count={schema?.count ?? 5} />;
};

export default StarRate;
