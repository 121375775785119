/*
 * :file description:
 * :name: \low-code-platform\src\components\MultipleChoice\index.tsx
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-15 14:47:46
 * :last editor: hyw
 * :date last edited: 2022-04-25 22:28:44
 */

import c from './index.less';
import React, { useState, useImperativeHandle, useRef } from 'react';
import { message, Tree, Modal, Button } from 'antd';

import _cloneDeep from 'lodash/cloneDeep';
import DataOpration from './DataOpration';
import IconFont from '~@/packages/table-render/src/components/IconFont';

interface MessageEngageDetailProps {
  addFlowVersion?: Function;
  cRef: any;
  initData?: any;
  getDataBack: Function;
  tabs?: Array<string>;
  multiple?: Boolean;
  memberTitleList?: Array<Object>;
  title?: string;
  isProcess?: boolean;
}

const MultipleChoice: React.FC<MessageEngageDetailProps> = ({
  initData,
  cRef,
  getDataBack,
  tabs,
  multiple = true,
  memberTitleList = [],
  title = '选择审批人',
  isProcess = true,
}) => {
  // 部门树相关信息
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [randomKey, setRandomKey] = useState(0);
  const childRef = useRef(null);
  const [dataLength, setDataLength] = useState(0);

  // 保存当前部门是否已经有加载过数据

  useImperativeHandle(cRef, () => ({
    // openModal 就是暴露给父组件的方法 获取选择的数据
    openModal: openModal,
  }));

  // 关闭人员选择器
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  // 人员选择器确定操作
  const handleOk = () => {
    (childRef?.current as any)?.getSelectData();
  };

  // 人员选择器确定操作
  const getDataBackFun = (data) => {
    console.log('---审批人-----', data, initData);
    setIsModalVisible(false);
    getDataBack(data);
  };

  return (
    <Modal
      maskClosable={false}
      title={title}
      width={685}
      visible={isModalVisible}
      onOk={handleOk}
      okText="确定"
      cancelText="取消"
      onCancel={handleCancel}
      destroyOnClose={true}
      wrapClassName={c.wrapClassName}
      key={randomKey}
      closeIcon={<IconFont type="icon-Close" />}
      footer={
        <>
          <Button onClick={() => handleCancel()}>取消</Button>
          <Button
            onClick={() => handleOk()}
            type="primary"
            disabled={dataLength < 1}
          >
            确定
          </Button>
        </>
      }
    >
      <DataOpration
        cRef={childRef}
        initData={initData}
        getDataBack={getDataBackFun}
        tabs={tabs}
        multiple={multiple}
        memberTitleList={memberTitleList}
        getSize={(value: number) => setDataLength(value)}
        isProcess={isProcess}
      />
    </Modal>
  );
};

export default MultipleChoice;
