/*
 * :file description:
 * :name: \tj-ali-crm-mobiled:\progree\low-code-platform\src\components\MultipleChoice\InputSearch.tsx
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-20 17:35:25
 * :last editor: Please set LastEditors
 * :date last edited: 2022-05-14 15:50:38
 */
import React, { useEffect, useState, useImperativeHandle } from 'react';
import { Input, Checkbox } from 'antd';
import { serachDepartmentList, searchRoleList, searchUserList } from './api';
import c from './index.less';
import cx from 'classnames';
import IconFont from '../IconFont';
interface InputSearchProps {
  addFlowVersion?: Function;
  // cRef: any;
  placeholder?: string;
  tabIndex: string;
  selectOneItem: Function;
  deleteOneItem: Function;
  hasSelectDepartData: any;
  hasSelectUsersData: any;
  hasSelectRolesData: any;
  cRef: any;
  multiple: Boolean;
}
let timer: any = null;
const InputSearch: React.FC<InputSearchProps> = ({
  placeholder = '搜索部门',
  tabIndex,
  selectOneItem,
  deleteOneItem,
  hasSelectDepartData,
  hasSelectUsersData,
  hasSelectRolesData,
  cRef,
  multiple,
}) => {
  // 用户输入值提示
  const [value, setValue] = useState('');
  const [options, setOptions] = useState([]);
  const [isFocus, setIsFocus] = useState(false);
  const [isStretch, setIsStretch] = useState(false); // 伸展
  const [isShrink, setIsShrink] = useState(false); // 收缩
  useImperativeHandle(cRef, () => ({
    clearSearchKey: () => {
      setValue('');
    },
    blurSearch: () => {
      if (!isStretch) return;
      setIsFocus(false);
      setIsShrink(true);
      setIsStretch(false);
      setValue('');
    },
  }));
  // 如果用户输入了内容，则发请求匹配，否则返回空
  const handleSearch = (e: any) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    clearTimeout(timer);
    value.length === 0 && setOptions([]);
    timer = setTimeout(() => {
      serchCompany(value);
    }, 500);
  }, [value]);

  const serchCompany = (value: string) => {
    if (!value) return;
    switch (tabIndex) {
      case '1':
        serachDepartmentList({
          name: value,
        }).then((res: any) => {
          res?.data?.forEach((ele: any) => {
            ele.title = ele.name;
            ele.key = ele.id;
            ele.currentType = 1;
            let isHasSelect = hasSelectDepartData.some(
              (val) => ele.id == val.id,
            );
            ele.checked = isHasSelect;
          });
          setOptions(res?.data ?? []);
        });
        break;
      case '2':
        searchUserList({
          name: value,
        }).then((res: any) => {
          res?.data?.forEach((ele: any) => {
            ele.title = ele.name;
            ele.key = ele.id;
            ele.type = 1;
            ele.currentType = 2;
            let isHasSelect = hasSelectUsersData.some(
              (val) => ele.id == val.id,
            );
            ele.checked = isHasSelect;
          });
          setOptions(res?.data ?? []);
        });
        break;
      case '3':
        searchRoleList({
          name: value,
        }).then((res: any) => {
          res?.data?.forEach((ele: any) => {
            ele.title = ele.name;
            ele.key = ele.id;
            let isHasSelect = hasSelectRolesData.some(
              (val) => ele.id == val.id,
            );
            ele.checked = isHasSelect;
            ele.currentType = 3;
          });
          setOptions(res?.data ?? []);
        });
        break;
    }
  };

  const choiceItem = (item: any) => {
    selectOneItem(item, true);
  };

  const cancleItem = (item: any) => {
    deleteOneItem(item, true);
  };

  // 选择获取取消
  const selectOrCancle = (e, ele) => {
    if (!e.target.checked) {
      cancleItem(ele);
    } else {
      choiceItem(ele);
    }
    if (!multiple) {
      options?.forEach((val: any) => {
        if (val.id == ele.id && e.target.checked) {
          val.checked = true;
        } else {
          val.checked = false;
        }
      });
      setOptions([...options]);
    } else {
      ele.checked = e.target.checked;
      setOptions([...options]);
    }
  };
  return (
    <div
      className={cx(
        c.searchArea,
        isStretch && c.searchLongArea,
        isShrink && c.searchShortArea,
      )}
    >
      <div className={c.searchBanner}>
        <Input
          placeholder={placeholder}
          onChange={handleSearch}
          value={value}
          onFocus={() => {
            setIsFocus(true);
            setIsStretch(true);
            setIsShrink(false);
          }}
          allowClear
          onBlur={() => {
            // setTimeout(() => {
            // setIsFocus(false);
            // setIsShrink(true)
            // setIsStretch(false)
            // }, 500);
          }}
        />
        {isStretch && (
          <IconFont
            type="icon-qingkongbeifen"
            style={{
              color: 'rgba(0, 0, 0, 0.25)',
              marginLeft: 12,
              fontSize: 16,
            }}
            onClick={() => {
              if (!isStretch) return;
              setIsFocus(false);
              setIsShrink(true);
              setIsStretch(false);
              setValue('');
            }}
          />
        )}
      </div>

      {!isFocus ? null : (
        <div className={c.resultDataDiv}>
          {options.length > 0 ? (
            <ul className={c.resultData}>
              {options.map((ele: any) => {
                return (
                  <li key={ele.id}>
                    <Checkbox
                      className={c.checkBox}
                      onChange={(e) => selectOrCancle(e, ele)}
                      checked={ele.checked}
                    >
                      {tabIndex == '2'
                        ? ele.userDeptName || ele.name
                        : ele.name}
                    </Checkbox>
                  </li>
                );
              })}
            </ul>
          ) : value.length > 0 ? (
            <div className={c.emptyText}>
              暂无相关
              {tabIndex == '1' ? '部门' : tabIndex == '2' ? '人员' : '角色'}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default InputSearch;
