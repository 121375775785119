/*
 * @Author: wu.shangru roywu@tungee.com
 * @Date: 2023-04-23 19:00:08
 * @LastEditors: wu.shangru
 * @LastEditTime: 2023-12-14 12:01:08
 * @FilePath: /low-code-platform/src/pages/generator/form/constant.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export const TipContent: any = {
  '1': {
    content:
      '设置【必填&只读&无默认值】会导致你在创建表单时无法保存成功，你可以选择以下1种方式操作：',
    display: ['取消必填', '设置默认值'],
  },
  '2': {
    content:
      '【必填&不可编辑&无默认值】会导致你在创建表单时无法保存成功，你可以选择以下1种方式操作：',
    display: ['取消必填', '设置默认值'],
  },
  '3': {
    content:
      '【必填&隐藏&无默认值】会导致你在创建表单时无法保存成功，你可以选择以下1种方式操作：',
    display: ['取消必填', '设置默认值'],
  },
};

export function removeProperties(schema: any) {
  const _schema = schema?.properties;
  if (!_schema) {
    return;
  }
  delete _schema.customerTags;
  delete _schema.relatesInfo;
  Object.entries(_schema)?.forEach(([key, value]) => {
    if (key.includes('table_')) {
      removeProperties(value?.items);
    }
    if (key.includes('relation_')) {
      value.properties = {};
    }
  });
  return _schema;
}

export const CUSTOM_WIDGETS_ADD_DATAFILLING = [
  'bizSuite_127KIMRJZSK00',
  'bizSuite',
  'bizSuite_20778TRTUZQ80',
];

export const ARRAY_TYPE = [
  'department',
  'innerContact',
  'principal',
  'cooperator',
];
export const SELECR_TYPE = ['select', 'multiSelect'];
export const RELATE_MODE_TABLE = [
  'table_orderInvoiceApplyDetail',
  'table_contractInvoiceApplyDetail',
  'table_contractPaymentDetail',
  'table_orderPaymentDetail',
];

export const CASCADE_ID = [
  'cascade_1TII0OPU163K0',
  'cascade_productCategory',
  'cascade_warehouseProductCategory',
];
