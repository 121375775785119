/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\SortField\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-11 11:10:02
 * :last editor: 黄艺
 * :date last edited: 2022-04-18 21:47:19
 */

import { FC, useEffect, useState } from 'react';
import { WidgetProps } from 'typings';
import SortModal, { customerMap } from './components/SortModal';
import c from './index.less';

const SortField: FC<WidgetProps> = (props) => {
  const { addons } = props;
  const { sortFields, relateSource, fields } = addons.getValues();

  let sortFieldList = [{ fields: sortFields }];
  if (relateSource) {
    sortFieldList = sortFields;
  }

  useEffect(() => {
    let newSortFields = [];
    if (relateSource) {
      newSortFields = sortFields?.map((item) => {
        let relateSourceItem = Object.values(relateSource || {})?.find(
          (i) => item.bizType === i.bizType,
        );
        const fieldsKeys = Object.keys(relateSourceItem?.fields || {});
        item.fields = item.fields?.filter((child) => {
          return fieldsKeys.includes(child.id);
        });
        return item;
      });
    } else {
      const fieldsKeys = Object.keys(fields || {});
      newSortFields = sortFields?.filter((item) => {
        return fieldsKeys.includes(item.id);
      });
    }
    addons.setValueByPath('sortFields', newSortFields);
  }, [JSON.stringify(fields), JSON.stringify(relateSource)]);

  return (
    <div className={c.container}>
      <div className={c.title}>
        <div className={c.show}>
          <span>展示排序</span>
        </div>
        <div className={c.order}>
          <SortModal addons={addons}>编辑排序</SortModal>
        </div>
      </div>
      {sortFieldList?.map((item) => {
        return (
          <div className={c.content}>
            <div key={item.bizType}>
              <div className={c.title}> {customerMap.get(item.bizType)}</div>
              <div className={c.list}>
                {item?.fields?.length > 0
                  ? item?.fields?.map((child) => {
                      return (
                        <div key={child.key} className={c.item}>
                          {child.label}
                        </div>
                      );
                    })
                  : '使用默认规则'}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SortField;
