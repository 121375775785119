import React from 'react';
import c from './index.less';
import cx from 'classnames';

interface IProps {
  schema: {
    bizType: string;
    multiTagList: {
      id: string;
      title: string;
      defaultColor: string;
      required: boolean;
      isRadio: boolean;
      options: {
        key: string;
        value: string;
      }[];
    }[];
  };
}

const CustomerTags: React.FC<IProps> = ({ schema }) => {
  return (
    <div className={c.wrapper}>
      <div className={c.list}>
        {schema.multiTagList.map((item) => (
          <div key={item.id} className={c.item}>
            <div
              className={cx(c['item-title'], { [c.required]: item.required })}
            >
              {item.title}
              {item.isRadio ? '（单选）' : '（多选）'}
            </div>
            <div className={c.options}>
              {item.options.map((option) => (
                <div className={c.option}>
                  <div
                    className={c.bg}
                    style={{ backgroundColor: item.defaultColor }}
                  ></div>
                  <div className={c.text} style={{ color: item.defaultColor }}>
                    {option.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerTags;
