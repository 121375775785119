/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\SelectOptions\BatchModal.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-29 17:30:33
 * :last editor: 黄艺
 * :date last edited: 2022-01-06 10:00:21
 */
import { Modal, Input, Typography } from 'antd';
import { useEffect, useState } from 'react';
import IconFont from '@/components/IconFont';
import { getUuid } from '@/utils';
import _ from 'lodash';

const { TextArea } = Input;
const { Text } = Typography;

const BatchModal = ({ visible, setVisible, items, onChange }) => {
  const [content, setContent] = useState<string>();

  const handleOk = () => {
    setVisible(false);
    if (_.isEqual(content, defaultValue)) return;
    if (content) {
      const row = content.split('\n');
      const defaultRow = defaultValue.split('\n');
      const newItem = row.map((item: string, index) => {
        if (
          ![null, undefined].includes(defaultRow[index]) &&
          _.isEqual(item, defaultRow[index])
        )
          return items[index];
        return {
          value: getUuid().toString(),
          label: item,
        };
      });
      onChange(newItem);
      return;
    }
    onChange([
      {
        value: getUuid().toString(),
        label: '',
      },
    ]);
  };

  const defaultValue = items.map((item) => item.label).join('\n');

  useEffect(() => {
    setContent(defaultValue);
  }, [JSON.stringify(defaultValue)]);

  return (
    <Modal
      title="批量编辑"
      visible={visible}
      onOk={handleOk}
      onCancel={() => setVisible(false)}
      destroyOnClose
      closeIcon={<IconFont type="icon-Close" />}
    >
      <div style={{ marginBottom: '12px' }}>
        <Text>每行对应一个选项</Text>
      </div>
      <TextArea
        defaultValue={defaultValue}
        value={content}
        onChange={(e) => setContent(e.target.value)}
        rows={5}
      />
    </Modal>
  );
};

export default BatchModal;
