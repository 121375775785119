/*
 * :file description:
 * :name: \ui-renderd:\progree\low-code-platform\src\models\useGenerator.ts
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-26 16:57:53
 * :last editor: hyw
 * :date last edited: 2022-08-15 16:29:38
 */
import { useState, useCallback, useRef, useEffect } from 'react';

import {
  DEFAULT_ICON,
  DEFAULT_ICON_COLOR,
} from '@/components/CustomFormProfile/IconSetting';
import { ICallback } from './useSider';
import { IProfile } from '@/components/CustomFormProfile';
import { DEFAULT_SCHEMA } from '@/pages/generator/form';
import { customJP, getUrlParam } from '@/utils';
import { IValue } from '~@/packages/form-render/src/widgets/DataTitle';

export const DEFAULT_SNAPSHOT = {
  iconColor: DEFAULT_ICON_COLOR,
  icon: DEFAULT_ICON,
  schema: DEFAULT_SCHEMA,
};

interface ISnapshot extends IProfile {
  schema?: any;
  propsSettings?: any;
}

interface IOaProp {
  id: string;
  formCode: string;
}

export default function useGenerator() {
  const [formCode, setFormCode] = useState();
  const [profile, setProfile] = useState<IProfile>({
    iconColor: DEFAULT_ICON_COLOR,
    icon: DEFAULT_ICON,
  });
  const [ifEdit, setIfEdit] = useState(false);
  const [returnCallback, setReturnCallback] = useState<ICallback>();
  const schemaRef = useRef(DEFAULT_SCHEMA);
  const relatedFormRef = useRef([]);
  const oaListRef = useRef<(IOaProp | null)[] | undefined>();
  const snapshotRef = useRef<ISnapshot>(DEFAULT_SNAPSHOT);
  const isSubmitRef = useRef(false);
  const formPropsSettingRef = useRef<IValue[]>();
  const [versionLimit, setVersionLimit] = useState({});
  const [allFormList, setAllFormList] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    const data =
      sessionStorage.getItem(
        `tungee_design_form_${window.__corpId || getUrlParam('corpId')}`,
      ) ?? '';
    const sessionStorageProfile = data
      ? customJP(data)
      : {
          iconColor: DEFAULT_ICON_COLOR,
          icon: DEFAULT_ICON,
        };
    updateProfile(sessionStorageProfile);
  }, []);

  const updateProfile = useCallback(
    ({ icon, title, iconColor, description, bizType, formType }) => {
      setProfile({ icon, title, iconColor, description, bizType, formType });
    },
    [],
  );

  const handleReturnCallback = useCallback((callback) => {
    setReturnCallback({ callback });
  }, []);

  const handleFormCode = useCallback(({ formCode }) => {
    setFormCode(formCode);
  }, []);

  const upIfEdit = useCallback((bool) => {
    setIfEdit(bool);
  }, []);

  const upVersionLimit = useCallback((json) => {
    setVersionLimit(json);
  }, []);

  const updateFormList = (list: any) => {
    setAllFormList(list);
  };
  const updateSaveStatus = useCallback((bool) => {
    setIsSaveDisabled(bool);
  }, []);

  return {
    formCode,
    profile,
    isSubmitRef,
    schemaRef,
    relatedFormRef,
    oaListRef,
    formPropsSettingRef,
    updateProfile,
    handleFormCode,
    handleReturnCallback,
    returnCallback,
    ifEdit,
    upIfEdit,
    snapshotRef,
    versionLimit,
    upVersionLimit,
    updateFormList,
    allFormList,
    isSaveDisabled,
    updateSaveStatus,
  };
}
