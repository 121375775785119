/*
 * :file description: 说明文字
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\TextNoteEditor\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-24 10:43:33
 * :last editor: 黄艺
 * :date last edited: 2022-01-05 14:00:19
 */
import { FC, useEffect, useRef } from 'react';
import { WidgetProps } from 'typings';
import { message } from 'antd';
import E from 'wangeditor';
import formApi from '@/services/form';
import './index.less';

const MENUS = [
  'bold',
  'italic',
  'underline',
  'justify',
  'foreColor',
  'fontSize',
  'link',
  'image',
];

const MAX_LENGTH = 8000;

interface Insert {
  (url: string): void;
}

let editor: E;
const TextNoteEditor: FC<WidgetProps<string>> = (props) => {
  const { onChange, value } = props;
  const ref = useRef<HTMLDivElement | null>(null);

  const initEditorConfig = (editor: E) => {
    editor.config.menus = MENUS;
    editor.config.height = 112;
    editor.config.uploadImgMaxLength = 10;
    editor.config.uploadImgMaxSize = 1 * 1024 * 1024; // 1M
    editor.config.showLinkImgHref = false;
    editor.config.zIndex = 9;
    editor.config.uploadImgHeaders = {
      Authorization: localStorage.getItem('token')!,
    };
    editor.config.onchange = (html: string) => {
      const len = editor.txt.text().length;
      if (len > MAX_LENGTH) {
        message.warning(`整体字符不超过${MAX_LENGTH}字`);
        return;
      }
      onChange(html);
    };
    editor.config.customUploadImg = function (
      files: string | any[],
      insert: Insert,
    ) {
      const uploadPromises = [];
      for (var i = 0; i < files.length; i++) {
        const formdata = new FormData();
        formdata.append('file', files[i]);
        formdata.append('keyPrefix', 'crm-foundation-form/instance/');
        uploadPromises.push(formApi.uploadImages(formdata));
      }
      Promise.all(uploadPromises).then((values) => {
        values?.forEach((item: any) => {
          insert(item.data);
        });
      });
    };
  };

  useEffect(() => {
    editor = new E(ref.current);
    initEditorConfig(editor);
    editor.create();
    return () => {
      editor.destroy();
    };
  }, []);

  useEffect(() => {
    editor.txt.html(value);
  }, []);

  return <div className="text_note" ref={ref}></div>;
};

export default TextNoteEditor;
