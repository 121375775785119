/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DefaultValueSelector\components\formula-editor\Variable.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-03 15:35:40
 * :last editor: 黄艺
 * :date last edited: 2021-12-29 19:58:22
 */

import { useContext, useEffect, useState } from 'react';
import { Card, Collapse } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { EditorContext } from '.';
import _ from 'lodash';
import c from '../../index.less';
import '../../index.less';
import { insertField } from './common/formulaEditor';
import classNames from 'classnames';
import CardTitle from './CardTitle';
import { formFieldObjToList, Searcher, fixHiddenInTable } from './common/utils';
import React from 'react';
import { useModel } from 'umi';
const { Panel } = Collapse;

interface IFlatten {
  children: string[];
  data: unknown;
  parent: string;
  parentFlatten: undefined | IFlatten;
  schema: typeof IObj;
}

const SUPPORT_FORMULAR_WIDGETS = [
  'number',
  'money',
  'date',
  'department',
  'principal',
  'cooperator',
  'innerContact',
  'multiSelect',
  'multiCascade',
  'customerTags',
];

const Variable = ({ flatten, selected }) => {
  const { versionLimit } = useModel('useGenerator', (model) => model);
  const { editorInstance, widgetProps } = useContext(EditorContext);
  const [result, setResult] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [formFieldList, setFormFieldList] = useState<IFlatten[]>([]);
  const { formData } = widgetProps.addons;
  const handleClick = (flatten, parent, isEntry: boolean) => {
    setTimeout(() => {
      const { schema } = flatten;
      const a = {
        id: null,
        name: `_widget_${schema.$id.replace(/^#\/?/, '').replaceAll('/', '.')}`,
        text: parent
          ? `${parent.parentTitle.replace(/^\/?/, '')}.${
              parent.schema?.title
            }.${schema.title}`
          : schema.title,
      };
      insertField(a, isEntry, editorInstance);
    }, 0);
  };

  // 进销存1.新增日期字段
  // 扩展组件支持类型
  const supportFormularWidget = (fieldList: any[] = []) => {
    return fieldList.filter((item: Object) =>
      SUPPORT_FORMULAR_WIDGETS.includes(item?.schema?.widget),
    );
  };

  const initData = () => {
    const formFieldList: IFlatten[] = formFieldObjToList(
      flatten['#'].children,
      flatten,
      formData,
      selected,
    );
    let _formFieldList = supportFormularWidget(formFieldList);
    _formFieldList = fixHiddenInTable(_formFieldList, flatten);
    setFormFieldList(_formFieldList);
  };
  useEffect(() => {
    initData();
  }, []);
  useEffect(() => {
    initData();
  }, [selected]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const copyList: IFlatten[] = _.cloneDeep(formFieldList);
    const { value } = event.target;
    const result = Searcher.search(copyList, value, flatten);
    setResult(result);
    setIsSearch(value.length > 0);
  };

  return (
    <div className={classNames(c.variable_container, c.item_container)}>
      <div className={c.title}>可用变量</div>
      <div className={c.field_list_wrap}>
        <Card
          size="small"
          title={<CardTitle title="搜索变量" onChange={handleChange} />}
        >
          <Collapse
            ghost
            className={c.list_wrap}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            {!isSearch && (
              <Panel header="当前表单字段" key="1">
                <ul className={c.list}>
                  {formFieldList.map((item) => {
                    if (
                      (!item.schema?.hideControls && item.schema?.hidden) ||
                      item.schema?.deleted
                    ) {
                      return null;
                    }
                    return (
                      <li
                        className={c.list_item}
                        key={item.schema.$id}
                        onClick={() =>
                          handleClick(item, item.parentFlatten, false)
                        }
                      >
                        <span>
                          {item.parentFlatten &&
                            `${item?.parentFlatten.schema.title}.`}
                        </span>
                        {item.schema.title}
                      </li>
                    );
                  })}
                </ul>
              </Panel>
            )}
          </Collapse>
          {isSearch && (
            <div className="field-search-wrapper">
              <div
                className={classNames(
                  'label-title',
                  'search-title',
                  c.search_title,
                )}
              >
                当前表单字段
              </div>
              <div className="current-field-list search-list fx_field_select_pane dialog-menu hide-menu-entry">
                <ul className={c.list}>
                  {result?.map((child) => {
                    const html = child.value.replace(
                      /\((.*?)\)/g,
                      `<span style="color:red" className="high-light">$1</span>`,
                    );
                    if (
                      (!child.schema?.hideControls && child.schema?.hidden) ||
                      child.schema?.deleted
                    ) {
                      return null;
                    }
                    return (
                      <li className={c.list_item}>
                        <div
                          onClick={() =>
                            handleClick(child, child.parentFlatten, false)
                          }
                          className="content"
                          dangerouslySetInnerHTML={{ __html: html }}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};

export default Variable;
