/*
 * :file description: page process
 * :name: \ui-renderd:\progree\low-code-platform\src\pages\generator\process\components\TopBanner\index.tsx
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-12 14:01:14
 * :last editor: hyw
 * :date last edited: 2022-06-01 11:55:58
 */

import c from './index.less';
import React, { useEffect, useState, useImperativeHandle } from 'react';
import { message, Dropdown, Menu, Button, Tag, Modal, Divider } from 'antd';
import { useModel, useParams, useLocation } from 'umi';
import {
  DownOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  getProcessList,
  getProcessDefinitionInfo,
  deleteProcessF,
  startProcess,
  stopProcess,
  addProcess,
} from '../../api';
import formApi from '@/services/form';
import { getSopStatus, updateSopStatus } from '@/services/sop';
import classnames from 'classnames';
import { computeStatus, defaultVersionList } from './constants';
import IconFont from '@/components/IconFont';
interface TopBannerProps {
  addFlowVersion: Function;
  startFlow: Function;
  saveFlow: Function;
  topRef: any;
  callBackVersionDetails: Function;
  onlyCallBackAuthList: Function;
  callBackMemberTitle: Function;
}
import { newVersionDefaultValue } from '../../constants';
import { RELATE_MODE_TABLE } from '../../../form/constant';

const { confirm } = Modal;

export const bizTypes = [
  'crm_contract',
  'crm_payment',
  'crm_order',
  'crm_invoice_apply',
  'crm_invoice_info',
];
// 系统流程表单
const flowForm = [
  'crm_invoice_apply', //开票申请
  'crm_contract', // 合同
  'crm_payment', // 回款
  'crm_order', // 订单
];
const TopBanner: React.FC<TopBannerProps> = ({
  addFlowVersion,
  startFlow,
  saveFlow,
  topRef,
  callBackVersionDetails,
  onlyCallBackAuthList,
  callBackMemberTitle,
}) => {
  const [versionInfo, setVersionInfo] = useState<any>({});
  const [versionList, setVersionList] = useState(defaultVersionList);
  // 1:新增 2：编辑
  const [addOrEdit, setAddOrEdit] = useState(1);
  const [authList, setAuthList] = useState<any[]>([]);
  const { formCode, ifEdit, upIfEdit } = useModel(
    'useGenerator',
    (model) => model,
  );
  const { query } = useLocation();

  const [isHiddenStop, setIsHiddenStop] = useState(false);

  useImperativeHandle(topRef, () => ({
    // 拉去列表数据
    pullList: () => {
      getList();
    },
  }));
  useEffect(() => {
    // 根据formCode获取 表单操作权限
    formApi
      .getFormSchemas({
        formCode: formCode || query?.formCode,
      })
      .then((res: any) => {
        const {
          data: { content, bizType },
        } = res;

        setIsHiddenStop(bizTypes.includes(bizType));
        let parseData = JSON.parse(content);
        const { properties } = parseData;
        console.log(parseData, 'parseData');

        let newArr: any[] = [];
        let member: any[] = [];
        Object.entries(properties || {}).forEach((ele: any) => {
          let isSystemRelation =
            ele[1].widget === 'relation' && ele[1].bizAlias;
          // 屏蔽系统预置字段
          if (ele[1].hidden) {
            return;
          }
          let fieldBehavior =
            flowForm.includes(bizType) && isSystemRelation
              ? 'READONLY'
              : 'NORMAL';
          let children = [];
          if (ele[1].widget === 'bizSuite') {
            const keys = Object.keys(ele[1]?.properties ?? {});
            const isAllHidden = !keys?.filter(
              (i) => !ele[1]?.properties?.[i]?.hidden,
            )?.length;
            if (isAllHidden) {
              return;
            }
            const isMultiRelateMode = !!keys?.filter((i) =>
              RELATE_MODE_TABLE.includes(i),
            )?.length;
            if (isMultiRelateMode) {
              keys?.forEach((j: any) => {
                if (ele[1]?.properties[j]?.hidden) {
                  return;
                }
                let bizSuiteChildren = [];
                Object.keys(ele[1]?.properties[j]?.items.properties)?.forEach(
                  (k) => {
                    bizSuiteChildren.push({
                      fieldId: k,
                      fieldBehavior: fieldBehavior,
                      title: ele[1]?.properties[j]?.items.properties[k]?.title,
                    });
                  },
                );
                newArr.push({
                  parentId: ele[0],
                  fieldId: j || '',
                  title: ele[1]?.properties[j]?.title,
                  fieldBehavior: fieldBehavior,
                  children: bizSuiteChildren,
                  isShow: true,
                  disabled:
                    flowForm.includes(bizType) && isSystemRelation
                      ? true
                      : false,
                });
              });
            } else {
              keys?.forEach((i: any) => {
                if (ele[1]?.properties[i]?.hidden) {
                  return;
                }
                children.push({
                  fieldId: i,
                  fieldBehavior: fieldBehavior,
                  title: ele[1].properties?.[i]?.title,
                });
              });
              newArr.push({
                fieldId: ele[0] || '',
                title: ele[1].widget === 'textNote' ? '说明文字' : ele[1].title,
                fieldBehavior: fieldBehavior,
                children: children,
                isShow: true,
                disabled:
                  flowForm.includes(bizType) && isSystemRelation ? true : false,
              });
            }
            return;
          }
          if (
            ele[1].widget === 'table' &&
            Object.keys(ele[1]?.items?.properties)?.length
          ) {
            Object.keys(ele[1].items.properties)?.forEach((i: any) => {
              children.push({
                fieldId: i,
                fieldBehavior: fieldBehavior,
                title: ele[1].items.properties?.[i]?.title,
              });
            });
          }

          if (ele[1].widget === 'table') {
            newArr.push({
              fieldId: ele[0] || '',
              title: ele[1].widget === 'textNote' ? '说明文字' : ele[1].title,
              fieldBehavior: fieldBehavior,
              children: children,
              isShow: true,
              disabled:
                flowForm.includes(bizType) && isSystemRelation ? true : false,
            });
          } else {
            newArr.push({
              fieldId: ele[0] || '',
              title: ele[1].widget === 'textNote' ? '说明文字' : ele[1].title,
              fieldBehavior: fieldBehavior,
              disabled:
                flowForm.includes(bizType) && isSystemRelation ? true : false,
            });
          }
          // 如果是组织成员字段 则将数据收录起来，方便在选人组件时展示在动态负责人中
          if (ele[1].widget === 'innerContact') {
            member.push(ele[1]);
          }
        });
        setAuthList([...newArr]);
        onlyCallBackAuthList([...newArr]);
        callBackMemberTitle([...member]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // 为了方便过滤当前formcode下有哪些是 有效字段
  useEffect(() => {
    // 当权限数组为空时证明此表单数据有问题 不走获取接口 或者初始化时不走接口
    if (authList.length === 0) return;
    getList();
  }, [authList]);

  const getList = (onlyGetListData?: boolean) => {
    getProcessList({
      formCode: formCode,
    })
      .then((res: any) => {
        const { data } = res;
        setVersionList(data ?? []);
        if (onlyGetListData && data.length === 1) {
          // 属于新建且刚好是只有一个时
          setVersionInfo(data?.[0] ?? '');
          getProcessInfo(data?.[0]);
        } else {
          // 正常获取时是都要获取新的详情的
          setVersionInfo(data?.[0] ?? '');
          data.length && getProcessInfo(data?.[0]);
        }
      })
      .catch((err) => {
        message.warning('获取流程列表失败');
        setVersionList([]);
      });
  };

  const tranformRequiredComment = (obj) => {
    if (
      (obj?.componentName === 'ConditionContainerNode' ||
        obj?.componentName === 'ConditionNode' ||
        obj?.componentName === 'InclusiveConditionContainerNode' ||
        obj?.componentName === 'InclusiveConditionNode') &&
      obj?.children?.length
    ) {
      // 并行分支容器 需要把ConditionNode 变成 InclusiveConditionNode
      if (obj?.componentName === 'InclusiveConditionContainerNode') {
        obj.children?.forEach((item) => {
          item.componentName = 'InclusiveConditionNode';
          item.props.nodeName = 'InclusiveConditionNode';
        });
      }
      obj.children?.forEach((item) => {
        if (item?.componentName === 'ApprovalNode') {
          item.props = item.props || {};
          item.props.requiredComment = item.props.requiredComment
            ? '1'
            : item.props.requiredComment === false
            ? '2'
            : '3';
        }
        if (
          item?.componentName === 'ConditionContainerNode' ||
          item?.componentName === 'ConditionNode' ||
          item?.componentName === 'InclusiveConditionContainerNode' ||
          item?.componentName === 'InclusiveConditionNode' ||
          item?.children?.length
        ) {
          tranformRequiredComment(item);
        }
      });
    }
    return obj;
  };
  // 流程数据预处理
  const preDealData = (obj: object) => {
    if (!obj) return {};

    obj.processConfig &&
      obj.processConfig.globalSetting &&
      (obj.processConfig.globalSetting.isAutoPassType =
        obj.processConfig.globalSetting.isAutoPassType ?? '2');
    const nodeList = obj?.processConfig?.processDefinition?.children;
    nodeList?.forEach((item: any) => {
      if (item?.componentName === 'ApprovalNode') {
        item.props = item.props || {};
        item.props.requiredComment = item.props.requiredComment
          ? '1'
          : item.props.requiredComment === false
          ? '2'
          : '3';
      }
      if (
        (item?.componentName === 'ConditionContainerNode' ||
          item?.componentName === 'InclusiveConditionContainerNode') &&
        item?.children?.length
      ) {
        tranformRequiredComment(item);
      }
    });
    return obj;
  };

  // 获取详情信息
  const getProcessInfo = (item: any) => {
    setAddOrEdit(2);
    getProcessDefinitionInfo({
      formCode: formCode,
      processId: item.processId || '1',
    })
      .then((res: any) => {
        const {
          data: { content },
        } = res;
        let parseContent = JSON.parse(content);
        parseContent = preDealData(parseContent);
        // 将当前对象传到父组件去保存起来，方便在编辑时直接获取对象的数据
        callBackVersionDetails(item, parseContent, authList);
        // message.success('获取流程详情成功');
      })
      .catch((err) => {
        console.log(err);

        message.warning('获取流程详情失败');
      });
  };

  // 按钮启用流程
  const start = () => {
    startFlow(addOrEdit);
  };

  // 保存流程
  const save = () => {
    saveFlow(addOrEdit);
  };

  const onClick = ({ key }: any) => {
    if (key === 'add') return;
    if (ifEdit) {
      Modal.confirm({
        className: c.leave,
        title: (
          <span className={c.content}>当前版本未保存，确认切换版本吗？</span>
        ),
        icon: <ExclamationCircleOutlined />,
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          upIfEdit(false);
          let clickItem = versionList.filter((item) => item.processId == key);
          setVersionInfo(clickItem[0] ?? {});
          getProcessInfo(clickItem[0]);
        },
      });
      return;
    }
    let clickItem = versionList.filter((item) => item.processId == key);
    setVersionInfo(clickItem[0] ?? {});
    getProcessInfo(clickItem[0]);
  };

  // 启用流程
  const toStartProcess = async (
    item: any,
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const openProcess = () => {
      confirm({
        title: '确定要启用流程吗?',
        icon: <ExclamationCircleOutlined />,
        content: '启用后，新提交的数据将按此版本的流程流转',
        cancelText: '取消',
        okText: '确定',
        onOk() {
          startProcess({
            processId: item.processId,
            formCode: formCode,
          })
            .then((res: any) => {
              const { code, msg } = res;
              if (code === 200) {
                message.success('流程启用成功');
                getList();
              } else {
                message.success(msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    };
    // ERP-判断工作SOP开启状态
    if (query?.source === 'CloudPlatform') {
      const res: any = await getSopStatus({ formCode });
      if (res?.result?.sopStatus) {
        Modal.confirm({
          title: '工作SOP提示',
          content:
            '当前表单已启用工作SOP，若新建并启用流程设计-审批流，则工作SOP会被停用！',
          okText: '确定',
          cancelText: '关闭',
          icon: <ExclamationCircleOutlined />,
          closable: true,
          onOk: async () => {
            const rsp: any = await updateSopStatus({
              formCode,
              sopStatus: false,
            });
            if (rsp?.success) {
              openProcess();
            }
          },
        });
        return;
      }
    }
    openProcess();
  };

  // 停用流程
  const toStopProcess = (
    item: any,
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    confirm({
      title: '关闭流程',
      content: '流转中的数据将停止流转，流转完成的数据其流程信息将不可见',
      cancelText: '取消',
      okText: '确定',
      onOk() {
        stopProcess({
          processId: item.processId,
          formCode: formCode,
        })
          .then((res: any) => {
            const { code, msg } = res;
            if (code === 200) {
              getList();
              message.success('停用成功');
            } else {
              message.success(msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      onCancel() {},
    });
  };

  // 删除流程
  const deleteProcess = (
    item: any,
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    confirm({
      title: '确定要删除流程版本吗？',
      content: '删除后，你将无法再编辑和启用此流程版本。',
      cancelText: '取消',
      okText: '确定',
      onOk: () => {
        deleteProcessF({
          formCode: formCode,
          processId: item.processId,
        })
          .then((res: any) => {
            const { code, msg } = res;
            if (code === 200) {
              message.success('删除成功');
              getList();
            } else {
              message.success(msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      onCancel: () => {},
    });
  };

  // 添加新流程版本
  const addVersion = () => {
    if (versionList.length === 5) {
      message.warning('当前最多添加5个版本！');
      return;
    }

    addProcess({
      formRelation: {
        formCode: formCode,
      },
      ...newVersionDefaultValue,
    })
      .then((res: any) => {
        const { code, msg } = res;
        if (code === 200) {
          // 触发顶部组件列表数据的回调
          message.success('新增版本成功');
          getList(true);
        } else {
        }
      })
      .catch((err) => {});
  };

  // 添加新的流程版本判断
  const addNewFlow = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    if (ifEdit) {
      Modal.confirm({
        className: c.leave,
        title: (
          <span className={c.content}>当前版本未保存，确定新增版本吗？</span>
        ),
        icon: <ExclamationCircleOutlined />,
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          upIfEdit(false);
          addVersion();
        },
      });
      return;
    }
    addVersion();
  };

  const menu = (
    <Menu onClick={onClick}>
      {versionList.map((item) => {
        return (
          <Menu.Item key={item.processId}>
            <div className={c.itemArea}>
              <div>
                {item.processName}{' '}
                {/* <Tag
                  className={c.tag}
                  color={item.processStatus === 1 ? '#FF9200 ' : '#2db7f5'}
                >
                  {computeStatus[item.processStatus] ?? '--'}
                </Tag> */}
                <span
                  className={classnames(
                    c.tag,
                    item.processStatus === 1 ? c.yellow : c.blue,
                  )}
                >
                  {computeStatus[item.processStatus] ?? '--'}
                </span>
              </div>

              <div className={c.op}>
                {item.processStatus === 0 ? (
                  <>
                    <Button
                      className={c.btn}
                      onClick={(e) => toStartProcess(item, e)}
                      type="link"
                    >
                      启用
                    </Button>
                    <Divider type="vertical" />
                    <Button
                      className={c.btn}
                      onClick={(e) => deleteProcess(item, e)}
                      type="link"
                      danger
                    >
                      删除
                    </Button>
                  </>
                ) : null}
                {item.processStatus === 1 ? (
                  <>
                    <Button
                      className={c.btn}
                      onClick={(e) => toStopProcess(item, e)}
                      type="link"
                    >
                      停用
                    </Button>
                  </>
                ) : null}
                {/* {item.processStatus === 1 && !isHiddenStop ? (
                  <>
                    <Button
                      className={c.btn}
                      onClick={(e) => toStopProcess(item, e)}
                      type="link"
                    >
                      停用
                    </Button>
                  </>
                ) : null} */}
              </div>
            </div>
          </Menu.Item>
        );
      })}
      <Menu.Item key="add">
        <div className={c.addbtn} onClick={(e) => addNewFlow(e)}>
          {' '}
          <Button type="link">
            {/* <PlusOutlined  /> */}
            <IconFont className={c.addIcon} type="icon-tianjia1"></IconFont>
            添加新版本
          </Button>
        </div>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className={c.topBanners}>
      <div className={c.left}>
        <Dropdown
          overlay={menu}
          overlayClassName={c.overlayClassName}
          trigger={['click']}
        >
          <a className={c.dropdownLink} onClick={(e) => e.preventDefault()}>
            {versionInfo.processName}
            <span
              className={classnames(
                c.tag,
                versionInfo.processStatus === 1 ? c.yellow : c.blue,
              )}
            >
              {computeStatus[versionInfo.processStatus] ?? '--'}
            </span>
            <DownOutlined />
          </a>
        </Dropdown>
      </div>
      <div className={c.right}>
        <Button type="default" className={c.bth} onClick={() => save()}>
          {' '}
          保存
        </Button>
        {versionInfo.processStatus === 0 && (
          <Button type="primary" className={c.bth} onClick={() => start()}>
            {' '}
            启用流程
          </Button>
        )}
      </div>
    </div>
  );
};

export default TopBanner;
