// 删除打印模板
// 应用降级，模板停用弹窗
// 打印模板列表
// 重命名接口
// 企业的模板数量
// 模板启用-停用接口
// 按表单显示打印模板列表

// 保存表单打印模板
// 获取打印模板表单字段代码
// 打印模板详情
// http://localhost:8201/generator/relation/print?type=1&formCode=PROC-921B41F5-862D-4C0F-B46F-ACF536574E43
// http://localhost:8201/generator/relation/print?type=1&formCode=PROC-569C3D32-D606-4FC7-B666-D7718708341E
// http://localhost:8201/generator/relation/print?type=1&formCode=PROC-EC0FC6F9-D0F7-428B-AFF3-DBB8400CCBFE

import React, { useState, useEffect } from 'react';
import { useLocation, useModel } from 'umi';
import {
  Button,
  Row,
  Col,
  Switch,
  message,
  Modal,
  Dropdown,
  Menu,
  Input,
  Space,
  Upload,
  Table,
  Form,
  Drawer,
  Collapse,
  Radio,
} from 'antd';
import type { UploadProps } from 'antd';
import type { RcFile } from 'antd/es/upload/interface';
import copy from 'copy-to-clipboard';
import { PlusOutlined, InboxOutlined } from '@ant-design/icons';
import { getUrlParam, newtab, downloadWordFile } from '@/utils';
import moreSvg from '@/assets/form/print/more.svg';
import copyImg from '@/assets/form/print/copy.png';
import uploadImg from '@/assets/form/print/upload.png';
import tempImg from '@/assets/form/print/template.png';
import pasteImg from '@/assets/form/print/paste.png';
import wordIcon from '@/assets/form/print/word.png';
import delIcon from '@/assets/form/print/delete.png';
import downloadIcon from '@/assets/form/print/download.png';
import processDemo from '@/assets/processDemo.png';
import {
  getTemplateUsage,
  getVersionDowngradeStatus,
  getTempList,
  getFormFieldsData,
  getTempListCategorizedByForm,
  enableOrDisableTemp,
  createTemp,
  editTemp,
  renameTemp,
  delTemp,
  getPreviewDefaultTemplate,
  printTemplateSetting,
} from '@/services/extension-setting';
import formApi from '@/services/form';
import Header from '../components/Header';
import { downloadFile } from '@/services/workNotice';
import c from './index.less';
import IconFont from '@/components/IconFont';
import { formType } from '@/layouts/components/GeneratorHeader';

interface TempItem {
  id: number;
  name: string;
  formCode: string;
  enableStatus: number;
  isDeleted: number;
  [key: string]: any;
}

interface TempInstance {
  id?: number;
  formCode: string;
  templateFilename: string | undefined;
  templateType: number;
  templateUrl: string | undefined;
}

interface formFieldCode {
  fieldCode: string;
  fieldName: string;
}
interface formFieldCodes {
  formBaseFieldCodes: formFieldCode[];
  formTableFieldCodes: Array<formFieldCode[]>;
  [key: string]: any;
}

enum DROPDOWN_OPTION {
  Edit = 'edit',
  Preview = 'preview',
  Rename = 'rename',
  Del = 'del',
  Setting = 'setting',
}

const { Dragger } = Upload;
const { Panel } = Collapse;

const baseColumns = [
  {
    title: '基础字段',
    dataIndex: 'fieldName',
    key: 'fieldName',
    width: '50%',
  },
  {
    title: '字段代码',
    dataIndex: 'fieldCode',
    key: 'fieldCode',
    width: '50%',
    render: (text, record) => {
      return (
        <div className={c.copy_box}>
          {text}
          <img
            className={c.copy_field}
            src={copyImg}
            onClick={() => {
              copy(text);
              message.success('复制成功');
            }}
          />
        </div>
      );
    },
  },
];

export default () => {
  const formCode = getUrlParam('formCode');
  const [form] = Form.useForm();
  const [tempUsage, setTempUsage] = useState(0);
  const [dataSource, setDataSource] = useState<TempItem[]>([]);
  const [tempListCategorizedByForm, setTempListCategorizedByForm] = useState<
    any[]
  >([]);
  const [currentTemp, setCurrentTemp] = useState<TempItem>();
  const [loading, setLoading] = useState(false);
  const [isCreateVisible, setIsCreateVisible] = useState(false);
  const [isRenameVisible, setIsRenameVisible] = useState(false);
  const [isExampleDiagramVisible, setIsExampleDiagramVisible] = useState(false);
  const [isFormFieldsExampleVisible, setFormFieldsExampleVisible] =
    useState(false);
  const [isPrintSettingVisible, setIsPrintSettingVisible] = useState(false);
  const [
    isFormFieldsExampleProcessVisible,
    setIsFormFieldsExampleProcessVisible,
  ] = useState(false);
  const [isFormFieldsVisible, setIsFormFieldsVisible] = useState(false);
  const [isTemplateDrawerVisible, setIsTemplateDrawerVisible] = useState(false);
  const [formFieldsData, setFormFieldsData] = useState<formFieldCodes>();
  const [tempFile, setTempFile] = useState<any>();
  const [tempUrl, setTempUrl] = useState();
  const [operateType, setOperateType] = useState('add');
  const [printSettingVisible, setPrintSettingVisible] = useState(false);
  const [printSetValue, setPrintSetValue] = useState(undefined);
  const [copyToCheck, setCopyToCheck] = useState(false);
  const location = useLocation();
  const source = location?.query?.source;
  const { profile } = useModel('useGenerator', (model) => model);

  const uploadProps: UploadProps = {
    name: 'template',
    multiple: false,
    maxCount: 1,
    className: 'template-uploader',
    showUploadList: false,
    customRequest: (e) => {
      const file = e.file;
      const name = file.name;
      let formData = new FormData();
      formData.append('file', e.file);
      formData.append('keyPrefix', 'crm-foundation-form/instance/');
      formApi.uploadImages(formData, undefined).then(
        (res) => {
          e.onSuccess(
            {
              name,
              url: res?.data,
            },
            file,
          );
          console.log('文件信息：', file);
          setTempFile(file);
          setTempUrl(res?.data);
        },
        (err) => {
          e.onError(err);
        },
      );
    },
    beforeUpload: (file: RcFile) => {
      const isDoc = file.name?.includes('.docx');
      if (!isDoc) {
        message.error('仅支持上传docx格式文件!');
      }
      const isLt1M = file.size / 1024 / 1024 <= 1;
      if (!isLt1M) {
        message.error('文件大小不能超过1M!');
      }
      return isDoc && isLt1M;
    },
    onChange(info) {
      const { status } = info.file;
      if (status === 'done') {
        message.success('上传成功');
      } else if (status === 'error') {
        message.error('上传失败');
      }
    },
  };

  const tableColumns = [
    {
      title: (
        <span className={c.table_title}>
          表格字段
          <span className={c.table_title_tip}>
            （只能粘贴在Word表格内
            <span
              className={c.table_title_view}
              onClick={() => setFormFieldsExampleVisible(true)}
            >
              查看示例
            </span>
            ）
          </span>
        </span>
      ),
      dataIndex: 'fieldName',
      key: 'fieldName',
      width: '50%',
      render: (text, record, index) => {
        return (
          <div className={c.table_name}>
            <span>{text}</span>
            {index === 0 ? <span className={c.table_tag}>表格控件</span> : null}
          </div>
        );
      },
    },
    {
      title: '字段代码',
      dataIndex: 'fieldCode',
      key: 'fieldCode',
      width: '50%',
      render: (text, record) => {
        return (
          <div className={c.copy_box}>
            {text}
            <img
              className={c.copy_field}
              src={copyImg}
              onClick={() => {
                copy(text);
                message.success('复制成功');
              }}
            />
          </div>
        );
      },
    },
  ];

  const processFieldColumns = [
    {
      title: (
        <span className={c.table_title}>
          审批字段
          <span className={c.table_title_tip}>
            （只能粘贴在单独一行
            <span
              className={c.table_title_view}
              onClick={() => setIsFormFieldsExampleProcessVisible(true)}
            >
              查看示例
            </span>
            ）
          </span>
        </span>
      ),
      dataIndex: 'fieldName',
      key: 'fieldName',
      width: '50%',
      render: (text, record, index) => {
        return (
          <div className={c.table_name}>
            <span>{text}</span>
          </div>
        );
      },
    },
    {
      title: '字段代码',
      dataIndex: 'fieldCode',
      key: 'fieldCode',
      width: '50%',
      render: (text, record) => {
        return (
          <div className={c.copy_box}>
            {text}
            <img
              className={c.copy_field}
              src={copyImg}
              onClick={() => {
                copy(text);
                message.success('复制成功');
              }}
            />
          </div>
        );
      },
    },
  ];

  const getTempUsage = async () => {
    const res = await getTemplateUsage({ formCode });
    setTempUsage(res?.data || {});
  };

  const showDowngradesModal = () => {
    Modal.warning({
      title: '模板数量变更提示',
      width: 424,
      content: (
        <div className={c.version_box}>
          由于版本降级，您的可启用模板数量已变更，超出权益的模板已自动关闭
        </div>
      ),
      okText: '确定',
      closable: false,
    });
  };

  const getVersionDowngrade = async () => {
    const res: any = await getVersionDowngradeStatus({ formCode });
    const { id } = res?.data || {};
    if (!res?.data?.formCode || !id) return;
    const storage_key = `__version_downgrade_${res?.data?.formCode}__`;
    const downgradeId = localStorage.getItem(storage_key);
    if (!downgradeId) {
      showDowngradesModal();
      localStorage.setItem(storage_key, id);
    } else {
      if (`${id}` !== downgradeId) {
        showDowngradesModal();
        localStorage.setItem(storage_key, id);
      }
    }
  };

  const openAddModal = async () => {
    setIsCreateVisible(true);
    setOperateType('add');
  };

  const getTemplateList = async () => {
    const res = await getTempList({ formCode });
    setDataSource(res?.data ?? []);
  };

  // 过滤掉没有模板开启的表单
  const filterFormWithEnabledTemp = (data: any[]) => {
    return data?.filter((v) => {
      return v?.printTemplateList?.some((item) => item.enableStatus === 1);
    });
  };

  const getTemplateListCategorizedByForm = async () => {
    const res = await getTempListCategorizedByForm({
      templateType: [2, 3],
    });
    setTempListCategorizedByForm(filterFormWithEnabledTemp(res?.data) || []);
  };

  const handlePrint = async () => {
    // 模板类型（1默认模板，2预制的自定义模板，3用户上传的自定义模板）
    if (currentTemp?.templateType == 1) {
      let newWindow: any;
      if (source === 'CloudPlatform' || source === 'CRMPlatform') {
        newWindow = window.open('');
      }
      const response: any = await getPreviewDefaultTemplate({
        formCode: formCode,
      });
      const { data = '' } = response;
      if (
        source === 'CloudPlatform' ||
        source === 'CRMPlatform' ||
        source === '1688'
      ) {
        window.open(data);
      } else {
        const dingtalkLink = 'dingtalk://dingtalkclient/page/link?url=';
        // const tmpUrl = 'http://mozilla.github.io/pdf.js/web/compressed.tracemonkey-pldi-09.pdf'
        // newtab(`${dingtalkLink}${encodeURIComponent(data)}&pc_slide=false`);
        window.open(
          `${dingtalkLink}${encodeURIComponent(data)}&pc_slide=false`,
        );
      }
    } else {
      // 考虑 2 3 类型
      //  有对应的链接
      if (currentTemp?.templatePreviewUrl) {
        const webWord = 'https://view.officeapps.live.com/op/view.aspx?src=';
        if (
          source === 'CloudPlatform' ||
          source === 'CRMPlatform' ||
          source === '1688'
        ) {
          window.open(`${webWord}${currentTemp?.templatePreviewUrl}`);
        } else {
          const dingtalkLink = 'dingtalk://dingtalkclient/page/link?url=';
          window.open(
            `${dingtalkLink}${encodeURIComponent(
              `${webWord}${currentTemp?.templatePreviewUrl}`,
            )}&pc_slide=false`,
          );
        }
      }
    }

    // 模板类型（1默认模板，2预制的自定义模板，3用户上传的自定义模板）
    //  自定义模板
    // if (currentTemp?.templateType == 2) {
    //   //  有对应的链接
    //   const res = await downloadFile({ id: '' });

    //   downloadFile({ id: '' }).then((res) => {
    //     const data = res?.data ?? {};
    //     // if (data?.expireTime) {
    //     //   const nowDate = new Date().valueOf();
    //     //   const expiredDate = new Date(data.expireTime).valueOf();
    //     //   setIsExpired(nowDate > expiredDate);
    //     // }
    //   });
    // } else {
    //   // currentTemp?.templateType == 1 都是默认模板
    //   const response: any = await getPreviewDefaultTemplate({
    //     formCode: formCode,
    //   });
    //   const { data = '' } = response;
    //   const dingtalkLink = 'dingtalk://dingtalkclient/page/link?url=';
    //   // const tmpUrl = 'http://mozilla.github.io/pdf.js/web/compressed.tracemonkey-pldi-09.pdf'
    //   newtab(`${dingtalkLink}${encodeURIComponent(data)}&pc_slide=false`);
    // }
  };

  const editPreparation = () => {
    setOperateType('edit');
    setIsCreateVisible(true);
    setTempFile({ ...currentTemp, name: currentTemp?.templateFilename });
    setTempUrl(currentTemp?.templateOriginalUrl);
  };

  const handleTempAction = (key: string) => {
    switch (key) {
      case 'edit':
        editPreparation();
        break;
      case 'preview':
        console.log('preview');
        handlePrint();
        break;
      case 'rename':
        form.setFieldsValue({
          newName: currentTemp?.name,
        });
        setIsRenameVisible(true);
        break;
      case 'del':
        handleDelete();
        break;
      case 'setting':
        setPrintSettingVisible(true);
        setPrintSetValue(currentTemp?.printProcessMode ?? 1);
        setCopyToCheck(
          typeof currentTemp?.printCarbonUser === 'boolean'
            ? currentTemp?.printCarbonUser
            : true,
        );

        break;
    }
  };

  const handleDelete = () => {
    Modal.confirm({
      title: '确认删除吗？',
      content: '删除后不可恢复，请谨慎操作',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        await delTemp({ id: currentTemp?.id });
        message.success('删除成功');
        getTempUsage();
        getTemplateList();
        getTemplateListCategorizedByForm();
      },
    });
  };

  const handleEnableStatusChange = async (checked: boolean, temp: TempItem) => {
    if (
      checked &&
      temp?.name !== '默认模板' &&
      tempUsage.existNum >= tempUsage.totalNum
    ) {
      Modal.warning({
        title: '可启用模板数已达上限',
        content: '请先关闭一些模板，才可启用新模板',
        okText: '去关闭',
        okType: 'default',
        onOk: (close) => {
          close();
          setIsTemplateDrawerVisible(true);
          getTemplateListCategorizedByForm();
        },
      });
      return;
    }
    const { id, enableStatus } = temp;
    await enableOrDisableTemp({
      id,
      enableStatus: enableStatus === 0 ? 1 : 0,
    });
    message.success(`${checked ? '启用' : '禁用'}成功`);
    getTempUsage();
    getTemplateList();
    getTemplateListCategorizedByForm();
  };

  const handleRename = async () => {
    await form.validateFields();
    await renameTemp({
      id: currentTemp?.id,
      newName: form.getFieldValue('newName'),
    });
    message.success('操作成功');
    getTemplateList();
    setIsRenameVisible(false);
  };

  // 文件名最多10个字符限制
  const truncateFileName = (str: string | undefined) => {
    if (!str) return;
    const [fileName, fileType] = str.split('.');
    return `${fileName.slice(0, 10)}.${fileType}`;
  };

  const handleOk = () => {
    if (!tempUrl) return message.warning('请上传Word模板！');
    if (loading) return;
    setLoading(true);
    operateType === 'add' ? handleCreate() : handleEdit();
  };

  const handleCreate = () => {
    const payload: TempInstance = {
      formCode,
      templateFilename: truncateFileName(tempFile?.name),
      templateType: 3,
      templateUrl: tempUrl,
    };
    updateTemp('create', payload);
  };

  const closeCreateModal = () => {
    setIsCreateVisible(false);
    setTempFile(undefined);
    setTempUrl(undefined);
  };

  const handleEdit = () => {
    const payload: TempInstance = {
      formCode,
      id: currentTemp?.id,
      templateFilename: truncateFileName(tempFile?.name),
      templateType: 3,
      templateUrl: tempUrl,
    };
    updateTemp('edit', payload);
  };

  const updateTemp = async (type: 'create' | 'edit', payload: TempInstance) => {
    const apiFunc = type === 'create' ? createTemp : editTemp;
    let res = await apiFunc(payload);
    if (res?.code === 200) {
      message.success(`${type === 'create' ? '添加' : '编辑'}成功`);
      closeCreateModal();
      getTemplateList();
    }
    setLoading(false);
  };

  const checkExampleDiagram = () => {
    setIsExampleDiagramVisible(true);
  };

  const checkFormFields = () => {
    setIsFormFieldsVisible(true);
    getFormFields();
  };

  const getFormFields = async () => {
    const res = await getFormFieldsData({ formCode });
    setFormFieldsData(res?.data);
  };

  const comfirmPrint = async () => {
    const data = {
      printProcessMode: !printSetValue ? 0 : printSetValue,
      printCarbonUser: !!copyToCheck,
      id: currentTemp?.id,
    };
    const res: any = await printTemplateSetting(data);

    if (res.code === 200) {
      setPrintSettingVisible(false);
      message.success('设置成功');
      getTemplateList();
    }
  };

  useEffect(() => {
    getTempUsage();
    getVersionDowngrade();
    getTemplateList();
  }, []);

  return (
    <>
      <Header>
        <div className={c.print_header}>
          <span className={c.temp_num}>
            可启用模板数：{tempUsage.existNum || 0}/{tempUsage.totalNum || 0}
          </span>
          <Button
            type="primary"
            onClick={openAddModal}
            icon={<IconFont type="icon-tianjia1" />}
          >
            添加打印模板
          </Button>
        </div>
      </Header>
      <div className={c.content}>
        <div>
          <Row>
            {dataSource.map((temp) => {
              return (
                <Col span={6} key={temp.id}>
                  <div className={c.temp_box}>
                    <div className={c.temp_header}>
                      <div className={c.temp_name}>{temp.name}</div>
                      <div>
                        <Dropdown
                          overlay={
                            <Menu
                              onClick={({ key }) => {
                                handleTempAction(key);
                              }}
                            >
                              {temp.templateType !== 1 ? (
                                <Menu.Item key={DROPDOWN_OPTION.Edit}>
                                  编辑
                                </Menu.Item>
                              ) : null}
                              <Menu.Item key={DROPDOWN_OPTION.Preview}>
                                预览
                              </Menu.Item>
                              <Menu.Item key={DROPDOWN_OPTION.Rename}>
                                重命名
                              </Menu.Item>
                              {temp.templateType === 1 &&
                              profile?.formType === formType.PROCESS_FORM ? (
                                <Menu.Item key={DROPDOWN_OPTION.Setting}>
                                  设置
                                </Menu.Item>
                              ) : null}
                              {temp.templateType !== 1 ? (
                                <Menu.Item key={DROPDOWN_OPTION.Del}>
                                  删除
                                </Menu.Item>
                              ) : null}
                            </Menu>
                          }
                          trigger={['click']}
                        >
                          <img
                            className={c.more_action}
                            src={moreSvg}
                            onClick={() => setCurrentTemp(temp)}
                          />
                        </Dropdown>
                      </div>
                    </div>
                    <div className={c.temp_body}>
                      <Switch
                        checked={temp?.enableStatus === 1}
                        onChange={(checked: boolean) =>
                          handleEnableStatusChange(checked, temp)
                        }
                      />
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>

      {/* 新建模板 */}
      <Modal
        width={600}
        title={`${operateType === 'add' ? '新增' : '编辑'}打印模板`}
        visible={isCreateVisible}
        confirmLoading={loading}
        onOk={handleOk}
        onCancel={closeCreateModal}
      >
        <div className={c.temp_create_box}>
          <div className={c.step_one}>
            <div className={c.step_header}>1、制作Word模板</div>
            <div className={c.step_text}>
              在你电脑本地，制作你需要打印的Word模板内容和样式
            </div>
            <div className={c.step_text}>
              如果需要打印出系统内的数据，请点击【查看表单字段代码】，将字段代码复制、粘贴到Word模板中对应的位置
              <Space>
                <span className={c.view_example} onClick={checkExampleDiagram}>
                  查看示例图
                </span>
                <span>|</span>
                <span className={c.view_example} onClick={checkFormFields}>
                  查看表单字段代码
                </span>
              </Space>
            </div>
          </div>
          <div className={c.step_two}>
            <div className={c.step_header}>
              2、上传Word模板
              <span className={c.num_limit}>（只支持上传1个附件）</span>
            </div>
            <div>
              {!tempFile?.name ? (
                <Dragger {...uploadProps}>
                  <p className={c.upload_drag}>
                    <img src={uploadImg} />
                  </p>
                  <p className={c.upload_text}>点击或将文件拖拽到这里上传</p>
                  <p className={c.upload_hint}>仅支持docx格式，大小不超过1M</p>
                </Dragger>
              ) : (
                <div className={c.file_temp}>
                  <div className={c.file_info}>
                    <img src={wordIcon} />
                    <div>
                      <div className={c.file_name}>{tempFile?.name}</div>
                    </div>
                  </div>
                  <div className={c.file_action}>
                    {operateType === 'edit' ? (
                      <img
                        src={downloadIcon}
                        width={16}
                        onClick={() => {
                          downloadWordFile(tempUrl);
                        }}
                      />
                    ) : null}
                    <img
                      src={delIcon}
                      width={16}
                      onClick={() => {
                        setTempFile(undefined);
                        setTempUrl(undefined);
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/* 查看示例图 */}
      <Modal
        title="重命名"
        visible={isRenameVisible}
        okText="保存"
        onOk={handleRename}
        onCancel={() => setIsRenameVisible(false)}
      >
        <Form autoComplete="off" form={form}>
          <Form.Item
            name="newName"
            rules={[{ required: true, message: '模板名称不能为空' }]}
          >
            <Input showCount maxLength={10} />
          </Form.Item>
        </Form>
      </Modal>

      {/* 查看示例图 */}
      <Modal
        width={800}
        title="采购单示例"
        visible={isExampleDiagramVisible}
        footer={null}
        onCancel={() => setIsExampleDiagramVisible(false)}
      >
        <div className={c.example_box}>
          <img src={tempImg} />
        </div>
      </Modal>

      {/* 表单字段代码-查看示例 */}
      <Modal
        width={800}
        title="粘贴要求示例"
        visible={isFormFieldsExampleVisible}
        footer={null}
        onCancel={() => setFormFieldsExampleVisible(false)}
      >
        <div className={c.require_title}>
          <div className={c.title}>表格控件内的字段粘贴要求：</div>
          <div className={c.require_item}>
            1、表格明细字段只能粘贴在Word表格内，否则无法打印出数据
          </div>
          <div className={c.require_item}>
            2、<span className={c.table_tag}>表格控件</span>
            本身作为一个字段，需将其代码粘贴在Word表格的第一个单元格内，
            才能打印出此表格控件内的所有明细数据
          </div>
          <div className={c.require_item}>
            3、表单中不同表格控件的字段在Word中需分为多个表格展示
          </div>
        </div>
        <div className={c.example_title}>
          <div className={c.title}>示例：</div>
          <div className={c.example_item}>
            假设一个采购单包含了<span className={c.bold}>采购明细</span>和
            <span className={c.bold}>合同明细</span>
            两个表格，在Word中想将两个表格内的明细数据都打印出来，在Word模板中，需设置两个表格，不能混在一块：
          </div>
          <div className={c.example_item}>
            对于第一个表格来说，需将
            <span className={c.bold}>采购明细表格控件</span>
            粘贴在左上方第一个单元格内，然后在第二行粘贴具体的产品字段代码
          </div>
          <div className={c.example_item}>
            对于第二个表格来说，需将
            <span className={c.bold}>合同明细表格控件</span>
            粘贴在左上方第一个单元格内，然后在第二行粘贴具体的合同字段代码
          </div>
        </div>
        <div className={c.paste_box}>
          <img src={pasteImg} />
        </div>
      </Modal>

      {/* 表单字段代码-查看示例  审批流程 */}
      <Modal
        width={800}
        title="粘贴要求示例"
        visible={isFormFieldsExampleProcessVisible}
        footer={null}
        onCancel={() => setIsFormFieldsExampleProcessVisible(false)}
      >
        <div className={c.require_title}>
          <div className={c.title}>审批字段的粘贴要求：</div>
          <div className={c.require_item}>
            审批字段只能自己占一行，否则无法打印出数据
          </div>
        </div>
        <div className={c.paste_box}>
          <img src={processDemo} />
        </div>
      </Modal>

      {/* 自定义模板 */}
      <Drawer
        className={c.custom_temp_modal}
        width={600}
        title="自定义模板"
        placement="right"
        footer={null}
        visible={isTemplateDrawerVisible}
        onClose={() => setIsTemplateDrawerVisible(false)}
      >
        <div className={c.template_list_box}>
          {tempListCategorizedByForm.map((formItem) => {
            return (
              <Collapse
                bordered={false}
                expandIconPosition="right"
                defaultActiveKey={['1']}
                key={formItem.id}
              >
                <Panel
                  header={
                    <div className={c.form_name}>
                      <span>{formItem.formName}</span>
                    </div>
                  }
                  key="1"
                >
                  <div className={c.template_item_box}>
                    <div className={c.print_template_list}>
                      {formItem?.printTemplateList
                        ?.filter((t) => !!t.enableStatus)
                        ?.map((temp) => {
                          return (
                            <div className={c.temp_box} key={temp.id}>
                              <div className={c.temp_header}>
                                <div className={c.temp_name}>{temp.name}</div>
                                <div>
                                  <Dropdown
                                    overlay={
                                      <Menu
                                        onClick={({ key }) => {
                                          handleTempAction(key);
                                        }}
                                      >
                                        {temp.templateType !== 1 ? (
                                          <Menu.Item key={DROPDOWN_OPTION.Edit}>
                                            编辑
                                          </Menu.Item>
                                        ) : null}
                                        <Menu.Item
                                          key={DROPDOWN_OPTION.Preview}
                                        >
                                          预览
                                        </Menu.Item>
                                        <Menu.Item key={DROPDOWN_OPTION.Rename}>
                                          重命名
                                        </Menu.Item>
                                        {temp.templateType !== 1 ? (
                                          <Menu.Item key={DROPDOWN_OPTION.Del}>
                                            删除
                                          </Menu.Item>
                                        ) : null}
                                      </Menu>
                                    }
                                    trigger={['click']}
                                  >
                                    <img
                                      className={c.more_action}
                                      src={moreSvg}
                                      onClick={() => setCurrentTemp(temp)}
                                    />
                                  </Dropdown>
                                </div>
                              </div>
                              <div className={c.temp_body}>
                                <Switch
                                  checked={temp?.enableStatus === 1}
                                  onChange={(checked: boolean) =>
                                    handleEnableStatusChange(checked, temp)
                                  }
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Panel>
              </Collapse>
            );
          })}
        </div>
      </Drawer>

      {/* 查看表单字段代码 */}
      <Modal
        className={c.form_fields_modal}
        width={600}
        title="表单字段代码"
        visible={isFormFieldsVisible}
        footer={null}
        onCancel={() => setIsFormFieldsVisible(false)}
      >
        <div className={c.form_fields_tips}>
          将字段对应的字段代码复制、粘贴到Word模板中对应的位置，打印时会将该字段在系统中的实际数据打印出来
        </div>
        <div className={c.form_fields_base}>
          <Table
            pagination={false}
            columns={baseColumns}
            dataSource={formFieldsData?.formBaseFieldCodes || []}
          />
        </div>
        {(formFieldsData?.formTableFieldCodes || []).map((item) => {
          return (
            <div className={c.form_fields_table}>
              <Table
                pagination={false}
                columns={tableColumns}
                dataSource={item || []}
              />
            </div>
          );
        })}
        {formFieldsData?.processFieldCodes &&
          formFieldsData?.processFieldCodes.length && (
            <div className={c.form_fields_table}>
              <Table
                pagination={false}
                columns={processFieldColumns}
                dataSource={formFieldsData?.processFieldCodes || []}
              />
            </div>
          )}
      </Modal>
      <Modal
        className={c.form_fields_modal}
        width={400}
        title="打印设置"
        visible={printSettingVisible}
        onCancel={() => setPrintSettingVisible(false)}
        onOk={() => comfirmPrint()}
      >
        <div className={c.modal_print_content}>
          <div className={c.modal_print_title}>审批流程打印设置</div>
          <div className={c.modal_print_radio}>
            <Radio.Group
              onChange={(e) => {
                setPrintSetValue(e.target.value);
              }}
              value={printSetValue}
            >
              <Space direction="vertical">
                <Radio value={2}>打印全部审批流程</Radio>
                <Radio value={1}>仅打印最后一次修改后审批流程</Radio>
              </Space>
            </Radio.Group>
          </div>
          <div className={c.line}></div>
          <div className={c.modal_print_title}>打印抄送人</div>
          <div className={c.modal_print_switch}>
            <Switch
              onChange={(v) => {
                setCopyToCheck(v);
              }}
              checked={!!copyToCheck}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};
