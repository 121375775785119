import IconFont from '@/components/IconFont';
import React, { useEffect, useMemo, useState } from 'react';
import Styles from './index.less';
import {
  Popover,
  Modal,
  Button,
  TreeSelect,
  Form,
  Select,
  message,
} from 'antd';
import { DefaultOptionType } from 'antd/lib/cascader';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import { getFormSchema } from '@/services/form-generator';
import { customJP } from '@/utils';
import { customAlphabet } from 'nanoid';
import {
  ALPHABET,
  NANOID_LENGTH,
} from '@/components/FormGenerator/utils/constant';
import { cloneDeep } from 'lodash';

interface SchameItem {
  [name: string]: any;
}

export const nanoid = customAlphabet(ALPHABET, NANOID_LENGTH);

const componentsWhiteList = ['email', 'phone'];

/**
 * 设置填充组件弹窗
 * 只对负责人/协同人/组织成员生效  将该组件的电话/邮箱填充到当前表单的电话/邮箱组件
 */
const FillModal: React.FC = (props: any) => {
  const {
    modalVisible,
    setModalVisible,
    addons = {},
    schema = {},
    value = [],
  } = props;
  const { enumWidgets = [] } = schema;

  const { flatten, lastFlatten, selected } = useStore();
  const { useForm } = Form;
  const [formIns] = useForm();
  const initItem = {
    fillObject: [
      {
        id: '',
        sourceVal: '',
        targetVal: [],
        targetValArr: [],
      },
    ],
  };
  const [modalData, setModalData] = useState([
    {
      id: '',
      sourceVal: '',
      targetVal: [],
      targetValArr: [],
    },
  ]);

  const addRow = (index: number) => {
    if (modalData?.length >= enumWidgets?.length) {
      message.error(`最多设置${enumWidgets?.length}个规则`);
      return;
    }
    const _modalData = [...modalData];
    _modalData.splice(index + 1, 0, {
      id: nanoid(),
      sourceVal: '',
      targetVal: [],
      targetValArr: [],
    });
    setModalData(_modalData);
  };

  const reduceRow = (index: number) => {
    const _modalData = [...modalData];
    const reduceItem = _modalData.splice(index, 1);
    formIns.setFieldsValue({
      [`target_${reduceItem[0].id}`]: undefined,
      [`soucre_${reduceItem[0].id}`]: undefined,
    });
    setModalData(_modalData);
  };

  const onChangeValue = (index, val, type) => {
    const cloneData = [...modalData];
    const item = cloneData?.[index] ?? {};
    if (type === 'sourceVal') {
      item.sourceVal = val;
      item.targetVal = [];
      item.targetValArr = targetValArrMap?.[item?.sourceVal] ?? [];
      item.targetVal = [];
      formIns.setFieldsValue({
        [`target_${item?.id}`]: [],
      });
      setModalData(cloneData);
    } else if (type === 'targetVal') {
      item.targetVal = [...val];
    }
    setModalData(cloneData);
  };

  const submitModalData = () => {
    formIns
      .validateFields()
      .then((res) => {
        const formatVal = modalData
          .map((item) => {
            if (
              !item?.sourceVal ||
              !item?.targetVal ||
              !item?.targetVal?.length
            ) {
              return null;
            }
            return {
              source: item?.sourceVal,
              target: item?.targetVal,
            };
          })
          .filter((i) => !!i);

        props?.onChange?.(formatVal);
        props.addons.setValueByPath(`fillObject`, formatVal);
        setModalVisible(false);
      })
      .catch(() => {
        if (
          modalData.length === 1 &&
          !modalData[0]?.sourceVal &&
          !modalData[0]?.targetVal
        ) {
          props.addons.setValueByPath(`fillObject`, []);
          setModalVisible(false);
        }
      });
  };

  const targetValArrMap = useMemo(() => {
    const widegtTypes = enumWidgets?.map((i) => i.widgetType);
    if (!widegtTypes || !widegtTypes?.length) return {};
    const obj = {};
    Object.keys(flatten)?.forEach((i) => {
      if (flatten?.[i]?.schema?.hidden && !flatten?.[i]?.schema?.hideControl)
        return;
      const widget = flatten?.[i]?.schema?.widget ?? '';
      if (widegtTypes?.includes(widget)) {
        if (
          flatten?.[i]?.parent?.includes('table') ||
          flatten?.[i]?.parent?.includes('relation')
        ) {
          // 屏蔽表格内的数据
          // const tableId = flatten?.[i]?.parent?.split('/')?.[1];
          // obj[widget] = [
          //   ...(obj[widget] ?? []),
          //   {
          //     ...flatten?.[i]?.schema,
          //     id: tableId + '/' + flatten?.[i]?.schema?.id,
          //     labelTitle:
          //       flatten?.[i]?.parentTitle?.slice(1) +
          //       '.' +
          //       flatten?.[i]?.schema?.title,
          //   },
          // ];
        } else {
          obj[widget] = [
            ...(obj[widget] ?? []),
            {
              ...flatten?.[i]?.schema,
              labelTitle: flatten?.[i]?.schema?.title,
            },
          ];
        }
      }
    });
    return obj;
  }, [flatten, enumWidgets]);

  const fieldList = useMemo(() => {
    const obj = {};
    Object.keys(flatten)?.forEach((i) => {
      if (flatten?.[i]?.schema?.hidden && !flatten?.[i]?.schema?.hideControl)
        return;
      if (i !== '#') {
        obj[i?.slice(2)] = true;
      }
    });
    return obj;
  }, [flatten]);

  const fieldArr = useMemo(() => {
    return modalData?.map((i) => i?.sourceVal);
  }, [modalData]);

  useEffect(() => {
    if (!value || !value?.length) {
      const _ModalData = [
        {
          ...initItem?.fillObject?.[0],
          id: nanoid(),
        },
      ];
      setModalData(_ModalData);
    } else {
      const _ModalData = value?.map((i) => {
        const hasDeleteData = (i?.target ?? [])?.some((i) => !fieldList?.[i]);
        const id = i?.id ?? nanoid();
        if (hasDeleteData) {
          formIns.setFieldsValue({
            [`target_${id}`]: '字段已被删除',
            [`soucre_${id}`]: i?.source,
          });
          return {
            id: id,
            sourceVal: i?.source,
            targetVal: '字段已被删除',
            targetValArr: [],
          };
        } else {
          formIns.setFieldsValue({
            [`target_${id}`]: i?.target ?? [],
            [`soucre_${id}`]: i?.source,
          });
          return {
            id: id,
            sourceVal: i?.source,
            targetVal: i?.target ?? [],
            targetValArr: targetValArrMap?.[i?.source] ?? [],
          };
        }
      });
      setModalData(_ModalData);
    }
  }, [targetValArrMap, value, modalVisible]);

  return (
    <Modal
      className={Styles.dataFillModal}
      title="填充规则设置"
      visible={modalVisible}
      width={800}
      onCancel={() => {
        setModalVisible(false);
      }}
      footer={
        <div className={Styles.btnRow}>
          <Button
            onClick={() => {
              setModalVisible(false);
            }}
          >
            取消
          </Button>
          <Button type="primary" onClick={submitModalData}>
            确定
          </Button>
        </div>
      }
    >
      <div className={Styles.modal}>
        <div className={Styles.modalHead}>
          <div>员工信息</div>
          <div>当前表单</div>
        </div>
        <Form form={formIns}>
          {modalData.map((item, i) => (
            <div className={Styles.modalRow} key={item?.id}>
              <Form.Item
                name={'soucre_' + item?.id}
                rules={[
                  {
                    validateTrigger: 'onChange',
                    validator: (rule: any, value: string) => {
                      if (!value) {
                        return Promise.reject('请选择表单字段');
                      }
                      if (value === '字段已被删除') {
                        return Promise.reject('字段已被删除，请重新选择');
                      }
                      return Promise.resolve(value);
                    },
                  },
                ]}
              >
                <Select
                  style={{ width: 301 }}
                  onChange={(val) => {
                    onChangeValue(i, val, 'sourceVal');
                  }}
                  placeholder="请选择"
                >
                  {enumWidgets?.map((item) => {
                    return (
                      <Select.Option
                        value={item?.widgetType}
                        key={item?.widgetType}
                        disabled={fieldArr?.includes(item?.widgetType)}
                      >
                        {item?.widgetName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <div className={Styles.modalRowText}>的值填充到</div>
              <Form.Item
                name={'target_' + item?.id}
                rules={[
                  {
                    validateTrigger: 'onChange',
                    validator: (rule: any, value: string) => {
                      if (!value) {
                        return Promise.reject('请选择表单字段');
                      }
                      if (value === '字段已被删除') {
                        return Promise.reject('字段已被删除，请重新选择');
                      }
                      return Promise.resolve(value);
                    },
                  },
                ]}
              >
                <TreeSelect
                  allowClear
                  virtual={false}
                  multiple
                  placeholder="请选择表单字段"
                  style={{ width: 301 }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  onChange={(val, label, extra) => {
                    onChangeValue(i, val, 'targetVal');
                  }}
                  fieldNames={{
                    label: 'labelTitle',
                    value: 'id',
                    children: 'children',
                  }}
                  treeData={item?.targetValArr}
                  treeDefaultExpandAll
                />
              </Form.Item>
              <div className={Styles.modalRowOperations}>
                {i === 0 && (
                  <IconFont
                    type="icon-add-line"
                    className={Styles.addIcon}
                    onClick={() => addRow(i)}
                  ></IconFont>
                )}
                {modalData.length > 1 && (
                  <IconFont
                    type="icon-delete-line"
                    className={Styles.reduceIcon}
                    onClick={() => reduceRow(i)}
                  ></IconFont>
                )}
              </div>
            </div>
          ))}
        </Form>
      </div>
    </Modal>
  );
};

export default FillModal;
