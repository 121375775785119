import IconFont from '@/components/IconFont';
import React, { useEffect, useMemo, useState } from 'react';
import Styles from './index.less';
import { Popover, Modal, Button, TreeSelect, Form, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/cascader';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import { getFormSchema } from '@/services/form-generator';
import { customJP } from '@/utils';
import { customAlphabet } from 'nanoid';
import {
  ALPHABET,
  NANOID_LENGTH,
} from '@/components/FormGenerator/utils/constant';
import { cloneDeep } from 'lodash';
import { any } from 'prop-types';
import _ from 'lodash';
import { useGray } from '@/pages/generator/relation/hooks';

interface SchameItem {
  [name: string]: any;
}

export const nanoid = customAlphabet(ALPHABET, NANOID_LENGTH);

const componentsWhiteList = [
  'input',
  'textarea',
  'date',
  'dateRange',
  'table',
  'money',
  'phone',
  'picture',
  'switch',
  'limitDot',
  'number',
  // 'textNote',
  // 'textNoteEditor',
  'dataTitle',
  'dataCard',
  'defaultValue',
  'attachment',
  'innerContact',
  'dataSnippet',
  'dataSummary',
  'department',
  'address',
  // 'seqNumber',
  'discount',
  'discountDefault',
  'jumplink',
  'relation',
  'starRating',
  'email',
  'select',
  'multiSelect',
];

const hideTypeComList = ['table', 'multiSelect'];

interface TreeDataMap {
  [widgetName: string]: DefaultOptionType[];
}

/**
 * 数据填充弹窗
 * 三种情况：
 * 1 正常关联表单   通过 formCode来识别  widgetType和id 为'bizSuite'的 关联合同&订单（套件）定制逻辑
 *  @param {addons}  schema对象
 * 2 双关联表单  通过formCodeArr来识别  可能只有一张表单 （用于定制客户表单，里面包含企业客户或者个人客户）
 * @param {addons}
 * 3 二级表格填充
 * @param {addons}  subTableDataFillingRules 为二级字段，包括二级填充字段以及二级过滤字段
 * @returns {void}
 */
const FillModal: React.FC = (props: any) => {
  /**
   * 注入状态
   * 两种种情况：
   * 1 props
   *  @param {props}
   * 2 context
   *  @param {useStore}
   */
  const {
    id,
    widgetType,
    modalVisible,
    setModalVisible,
    addons = {},
    showDataFilter,
    value,
    allowThreeLevelTable = false,
  } = props;
  const {
    flatten,
    lastFlatten,
    selected,
    showRelationFillTableType,
  } = useStore();
  /**
   * 二级表格填充依赖状态
   */
  const { subTableDataFillingRules: intSubTableDataFillingRules = [] } =
    addons?.formData ?? {};
  const tableName = selected
    ?.split('/')
    ?.filter((i) => i?.includes('table_'))?.[0];
  // 定制表格内填充的相关数据
  const [subTableDataFillingRules, setSubTableDataFillingRules] = useState(
    intSubTableDataFillingRules,
  );
  // 判断是否位于表格内部
  const isRelationInTable = useMemo(() => {
    return selected?.includes('/table_');
  }, [selected]);
  const [tableFilterData, setTableFilterData] = useState<DefaultOptionType[]>(
    [],
  );
  const [tableSourceMap, setTableSourceMap] = useState({});
  const [tableTargetMapList, setTableTargetMapList] = useState({});
  const [tableTargetMap, setTableTargetMap] = useState({});
  const [tableModalData, setTableModalData] = useState([
    {
      tableId: '',
      dataSource: {},
      id: nanoid(),
      dataFillingRules: [
        {
          id: nanoid(),
          sourceVal: '',
          soucreWidget: '',
          sourceValArr: [],
          targetVal: '',
          targetValArr: [],
        },
      ],
    },
  ]);

  console.log('🚀 ~ allowThreeLevelTable:', allowThreeLevelTable);

  /**
   * 表格之外填充依赖状态
   */
  const [soucreField, setSoucreField] = useState<DefaultOptionType[]>();
  const [sourceFieldIdSet, setSourceFieldIdSet] = useState<Set<string>>();
  const [preWidgets, setPreWidgets] = useState({});
  const [modalData, setModalData] = useState<
    {
      id: string;
      sourceVal: string;
      soucreWidget?: string;
      targetTreeData?: DefaultOptionType[];
      type?: 0 | 1;
      targetVal: string;
    }[]
  >([
    {
      id: nanoid(),
      sourceVal: '',
      targetVal: '',
    },
  ]);
  const [sourceSchemaPro, setSourceSchemaPro] = useState<any>();
  const { useForm } = Form;
  const [formIns] = useForm();

  // 单关联表  正常填充
  const formCode = useMemo(() => {
    // widgetType 为 'bizSuite' 是 关联合同&订单（套件）
    if (widgetType === 'bizSuite' && id) {
      return props.addons.formData?.properties?.[id]?.dataSource?.target
        ?.formCode;
    }
    return props.addons.formData?.dataSource?.target?.formCode;
  }, [props.addons]);
  // 双关联表  正常填充
  const formCodeArr = useMemo(() => {
    return props.addons.formData?.relateSource
      ?.map((item) => item?.dataSource?.target?.formCode)
      .filter((i) => !!i);
  }, [props.addons]);

  const sourceId = useMemo(() => {
    // widgetType 为 'bizSuite' 是 关联合同&订单（套件）
    if (widgetType === 'bizSuite' && id) {
      return props.addons.formData?.properties?.[id]?.id;
    }
    return props?.addons?.formData?.id;
  }, [props.addons]);

  const schemaIdToWidget = useMemo(() => {
    const relationMap = new Map<string, string>();
    if (sourceSchemaPro) {
      Object.values(sourceSchemaPro)?.forEach((property: any) => {
        const { id, widget, originWidget } = property;
        if (originWidget === 'relation') {
          relationMap.set(id, widget);
        }
        if (property?.items?.properties) {
          property?.items?.properties?.forEach?.((property: any) => {
            const { id, widget, originWidget } = property;
            if (originWidget === 'relation') {
              relationMap.set(id, widget);
            }
          });
        }
      });
    }
    return relationMap;
  }, [sourceSchemaPro]);

  const getSchemaFormCode = (property: any) => {
    const { dataSource, relateSource } = property;
    // if (widget === 'relation') {
    let _formCode = '';
    if (dataSource?.target?.formCode) {
      _formCode = dataSource?.target?.formCode;
    } else if (relateSource?.length) {
      relateSource?.forEach((source: any) => {
        if (source?.dataSource?.target?.formCode) {
          _formCode += source?.dataSource?.target?.formCode;
        }
      });
    }
    return _formCode;
    // }
  };

  let targetField = useMemo<TreeDataMap>(() => {
    if (!sourceSchemaPro) {
      return {};
    }
    const _tableTargetMapList = {};
    const _flatten = cloneDeep(flatten);

    const rtn: TreeDataMap = {};
    const set = new Set();
    const widgetList = Object.values(_flatten);

    const tableList = widgetList.filter(
      (item: any) => item?.schema?.widget === 'table',
    );

    const notTableList = widgetList.filter(
      (item: any) => item?.schema?.widget !== 'table' && item?.parent === '#',
      // item?.schame?.dataSource?.target?.formCode === formCode
    );
    tableList?.forEach((item: any) => {
      item &&
        (item.children = item?.children?.filter((id: string) => {
          if (_flatten[id]?.schema?.id === sourceId) {
            return false;
          }
          if (!id?.includes('relation')) {
            return true;
          }
          if (_flatten[id]) {
            const formCode = getSchemaFormCode(_flatten[id].schema);
            if (formCode) {
              _flatten[id].schema.originWidget = _flatten[id].schema?.widget;
              _flatten[id].schema.widget = formCode;
            }
          }
          return true;
        }));
    });
    // 收集table的组件到_tableTargetMap
    tableList?.forEach((item: any) => {
      _tableTargetMapList[item?.schema?.id] = {};
      item?.children?.forEach((id: string) => {
        const widgetId = _flatten[id]?.schema?.id;
        const widgetName = _flatten[id]?.schema?.widget;
        _tableTargetMapList[item?.schema?.id][widgetName] = _tableTargetMapList[
          item?.schema?.id
        ][widgetName] ?? [
          {
            isLeaf: false,
            selectable: false,
            label: item?.schema?.title,
            title: item?.schema?.title,
            value: item?.schema?.$id,
            widget: item?.schema?.widget,
            children: [],
          },
        ];
        // 暂时不用考虑去重
        _tableTargetMapList?.[item?.schema?.id]?.[widgetName]?.[0]?.[
          'children'
        ].push({
          isLeaf: true,
          selectable: true,
          label: _flatten?.[id]?.schema?.title,
          title: _flatten?.[id]?.schema?.title,
          showTitle: item?.schema?.title + '.' + _flatten?.[id]?.schema?.title,
          value: _flatten[id]?.schema?.$id,
          widget: _flatten[id]?.schema?.widget,
        });
      });
    });
    setTableTargetMapList(_tableTargetMapList);
    notTableList.filter((item: any) => {
      if (item?.schema?.id === sourceId) {
        return false;
      }
      if (!item?.parent) {
        return;
      }
      if (!item?.schema?.id?.includes('relation')) {
        return true;
      }
      const formCode = getSchemaFormCode(item?.schema);
      if (formCode) {
        item.schema.originWidget = item?.schema?.widget;
        item.schema.widget = formCode;
      }
      return true;
    });
    const validWidgetList = tableList.concat(notTableList);
    const dfs = (arr: any[], parent?: DefaultOptionType): any => {
      arr?.forEach((item) => {
        if (
          set.has(item?.schema?.$id) ||
          item?.schema?.id === sourceId ||
          !!item?.schema?.$id.includes(sourceId)
        ) {
          return;
        }
        if (item?.schema) {
          const itemChildren =
            item?.schema?.originWidget !== 'relation'
              ? item?.children?.map((key: string) => _flatten[key])
              : [];
          const widgetTreeData = rtn[item?.schema?.widget];
          const newData: DefaultOptionType = {
            isLeaf: !itemChildren.length,
            selectable: !itemChildren.length,
            label: item?.schema.title,
            title: item?.schema.title,
            value: item?.schema.$id,
            widget: item?.schema?.widget,
            relateSource: item?.schema?.relateSource,
          };
          set.add(item?.schema.$id);
          if (widgetTreeData) {
            if (parent) {
              let hasAdd = false;
              widgetTreeData?.forEach((item) => {
                if (item?.value === parent.value) {
                  item?.children?.push(newData);
                  hasAdd = true;
                }
                item?.children?.forEach((childItem) => {
                  if (childItem?.value === parent.value) {
                    childItem?.children?.push(newData);
                    hasAdd = true;
                  }
                });
              });
              if (!hasAdd) {
                const parentWidgetTreeData = rtn[parent?.widget];
                parentWidgetTreeData?.forEach((parentItem) => {
                  if (parentItem?.value === parent?.value) {
                    widgetTreeData.push({
                      ...parentItem,
                      children: [newData],
                    });
                  }
                });
                parentWidgetTreeData?.forEach((parentItem) => {
                  parentItem?.children?.forEach((parentChildItem) => {
                    if (parentChildItem?.value === parent?.value) {
                      widgetTreeData.push({
                        ...parentItem,
                        children: [
                          {
                            ...parentChildItem,
                            children: [newData],
                          },
                        ],
                      });
                    }
                  });
                });
              }
            } else {
              widgetTreeData.push(newData);
            }
          } else {
            if (parent) {
              const parentWidgetTreeData = rtn[parent?.widget];
              parentWidgetTreeData?.forEach((parentItem) => {
                if (parentItem?.value === parent?.value) {
                  rtn[item?.schema?.widget] = [
                    {
                      ...parentItem,
                      children: [newData],
                    },
                  ];
                }
              });
              parentWidgetTreeData?.forEach((parentItem) => {
                parentItem?.children?.forEach((parentChildItem) => {
                  if (parentChildItem?.value === parent?.value) {
                    rtn[item?.schema?.widget] = [
                      {
                        ...parentItem,
                        children: [
                          {
                            ...parentChildItem,
                            children: [newData],
                          },
                        ],
                      },
                    ];
                  }
                });
              });
            } else {
              rtn[item?.schema?.widget] = [newData];
            }
          }
          // }
          newData.children = itemChildren.length
            ? dfs(itemChildren, newData)
            : [];
        }
      });
    };
    dfs(validWidgetList);
    return rtn;
  }, [props.addons, sourceSchemaPro]);
  console.log(targetField, 'autoFill');

  const selectedTargetField = useMemo(() => {
    const set = new Set();
    modalData?.forEach((item) => {
      set.add(item?.targetVal);
    });
    tableModalData?.forEach((item) => {
      item?.dataFillingRules?.forEach((i) => {
        set.add(i?.targetVal);
      });
    });
    return set;
  }, [modalData, tableModalData]);

  // 根据source返回 schema 以及 widget类型
  const getSourceValue = (
    item: any,
    sourceSchemaPro: any,
    targetField?: any,
  ) => {
    const widgetList = item?.source?.split('/');
    if (!widgetList) return {};
    let sourceSchema;
    if (allowThreeLevelTable) {
      sourceSchema =
        widgetList?.length > 1
          ? widgetList?.length === 3
            ? sourceSchemaPro[widgetList[0]]?.items?.properties?.[widgetList[1]]
                ?.items?.properties?.[widgetList[2]]
            : sourceSchemaPro[widgetList[0]]?.items?.properties?.[widgetList[1]]
          : sourceSchemaPro?.[item?.source];
    } else {
      sourceSchema =
        widgetList?.length > 1
          ? sourceSchemaPro[widgetList[0]]?.items?.properties?.[widgetList[1]]
          : sourceSchemaPro?.[item?.source];
    }
    // 双表单  changedValSchema.widget 为formCode的拼接的  所以 需要做特殊判断
    let doubleRelationWidget = sourceSchema?.widget;
    if (Object.keys(targetField)?.length) {
      doubleRelationWidget = Object.keys(targetField)?.filter(
        (itemK) =>
          sourceSchema?.widget?.includes(itemK) ||
          itemK?.includes(sourceSchema?.widget) ||
          getCurTreeData(sourceSchema, targetField[itemK]),
      )?.[0];
    }
    return {
      sourceSchema,
      doubleRelationWidget,
    };
  };

  // 根据set 更新targetTreeData状态
  const changeTargetTreeStatus = (targetTreeData: any, set: any) => {
    return targetTreeData?.map((item: DefaultOptionType) => ({
      ...item,
      disabled: set.has(item?.value),
      children: item?.children?.map((childItem: DefaultOptionType) => ({
        ...childItem,
        disabled: set.has(childItem?.value),
        children: childItem?.children?.map(
          (childChildItem: DefaultOptionType) => ({
            ...childChildItem,
            disabled: set.has(childChildItem?.value),
          }),
        ),
      })),
    }));
  };
  useEffect(() => {
    if (targetField && modalVisible && sourceSchemaPro) {
      if (!props?.value || !props?.value?.length) {
        setModalData([
          {
            id: nanoid(),
            sourceVal: '',
            targetVal: '',
          },
        ]);
      }
      if (isRelationInTable && !intSubTableDataFillingRules?.length) {
        setTableModalData([
          {
            tableId: '',
            dataSource: {},
            id: nanoid(),
            dataFillingRules: [
              {
                id: nanoid(),
                soucreWidget: '',
                sourceVal: '',
                sourceValArr: [],
                targetVal: '',
                targetValArr: [],
              },
            ],
          },
        ]);
      }
      if (
        (!props?.value || !props?.value?.length) &&
        isRelationInTable &&
        !intSubTableDataFillingRules?.length
      ) {
        formIns.resetFields();
        return;
      }

      const set = new Set();
      // 修改value的值
      // 企业客户 和 个人客户  要加上table的前缀
      if (formCodeArr?.length && Object.keys(preWidgets)?.length) {
        props?.value?.forEach((item: any) => {
          const widgetName = item?.source?.split('/')?.[0];
          const preTitle = preWidgets[widgetName]?.preTitle;
          item.source = item?.source?.includes(preTitle)
            ? item?.source
            : (preTitle ? preTitle + '/' : '') + item?.source;
        });
      }
      props?.value?.forEach((item: any) => {
        set.add(item?.target);
      });
      const formData: any = {};
      if (props?.value && props?.value?.length) {
        const _modalData: typeof modalData = props?.value?.map(
          (item: any, index: number) => {
            const id = nanoid();
            const { sourceSchema, doubleRelationWidget } = getSourceValue(
              item,
              sourceSchemaPro,
              targetField,
            );

            if (!doubleRelationWidget) {
              return;
            }
            let targetTreeData = targetField[doubleRelationWidget];
            if (sourceSchema?.widget === 'relation') {
              targetTreeData = targetField[getSchemaFormCode(sourceSchema)];
            }
            targetTreeData = changeTargetTreeStatus(targetTreeData, set);
            const sourceArr = item?.source?.split('/');
            // 自然之声兼容三级表格类型
            let source = '';
            if (allowThreeLevelTable && sourceArr.length > 2) {
              source = _.takeRight(sourceArr, 2).join('/');
            } else {
              source = item?.source;
            }
            const sourceVal = sourceFieldIdSet?.has(source)
              ? source
              : isRelationInTable
              ? undefined
              : '字段已被删除';
            const targetVal =
              (isRelationInTable && !sourceVal) || sourceVal === '字段已被删除'
                ? undefined
                : !!flatten[item?.target]
                ? item?.target
                : '字段已被删除';
            formData[`soucre_${id}`] = sourceVal;
            formData[`target_${id}`] = targetVal;
            formData[`type_${id}`] = item.type;
            return {
              id,
              sourceVal,
              soucreWidget: sourceSchema?.widget,
              targetTreeData,
              targetVal,
              type: item.type,
            };
          },
        );
        setModalData(_modalData);
      }
      // 初始化表格subTableDataFillingRules数据
      // 过滤条件不用初始化
      // 数据填充需要初始化
      if (intSubTableDataFillingRules?.length && isRelationInTable) {
        intSubTableDataFillingRules?.forEach((item) => {
          if (item?.dataFillingRules?.length) {
            item?.dataFillingRules?.forEach((j) => {
              set.add(j?.target);
            });
          }
        });
        const _tableModalData = intSubTableDataFillingRules?.map(
          (i, indexI) => {
            const id = nanoid();
            formData[`${indexI}@filter_${id}`] = i?.tableId;
            if (
              i?.tableId &&
              (!i?.dataFillingRules?.length || !i?.dataFillingRules?.[0])
            ) {
              return {
                tableId: i?.tableId,
                dataSource: i?.dataSource,
                id: id,
                dataFillingRules: [
                  {
                    id: nanoid,
                    soucreWidget: '',
                    sourceVal: '',
                    sourceValArr: tableSourceMap?.[i?.tableId],
                    targetVal: '',
                    targetValArr: [],
                  },
                ],
              };
            }
            return {
              tableId: i?.tableId,
              dataSource: i?.dataSource,
              id: id,
              dataFillingRules: i?.dataFillingRules?.map((j, indexJ) => {
                const id = nanoid();
                const { sourceSchema, doubleRelationWidget } = getSourceValue(
                  j,
                  sourceSchemaPro,
                  targetField,
                );
                if (!doubleRelationWidget) {
                  return;
                }
                const sourceValArr = tableSourceMap?.[i?.tableId];
                let targetValArr =
                  tableTargetMapList?.[tableName]?.[doubleRelationWidget];
                targetValArr = changeTargetTreeStatus(targetValArr, set);

                const sourceVal = sourceFieldIdSet?.has(j?.source)
                  ? j?.source
                  : '字段已被删除';
                const targetVal =
                  sourceVal === '字段已被删除'
                    ? undefined
                    : !!flatten[j?.target]
                    ? j?.target
                    : '字段已被删除';
                formData[`${indexI}/${indexJ}@fillSource_${id}`] = sourceVal;
                formData[`${indexI}/${indexJ}@fillTarget_${id}`] = targetVal;
                return {
                  id: id,
                  sourceVal: j?.source,
                  sourceValArr: sourceValArr,
                  sourceWidget: sourceSchema?.widget,
                  targetVal: j?.target,
                  targetValArr: targetValArr,
                };
              }),
            };
          },
        );
        setTableModalData(_tableModalData);
      }
      formIns.setFieldsValue(formData);
      setTimeout(() => {
        formIns.validateFields();
      }, 0);
    }
  }, [
    props?.value,
    targetField,
    modalVisible,
    sourceSchemaPro,
    sourceFieldIdSet,
    preWidgets,
    isRelationInTable,
  ]);

  useEffect(() => {
    // 企业客户  个人客户
    if (formCodeArr?.length) {
      getSchemaList(formCodeArr);
    } else if (formCode) {
      getSchema();
    }
  }, [formCode, formCodeArr]);

  const transformSchemaWidgetByFormCode = (properties: any, parentId?: any) => {
    Object.values(properties)?.forEach((property: any) => {
      if (property?.widget === 'relation') {
        const formCode = getSchemaFormCode(property);
        if (formCode) {
          property.originWidget = property?.widget;
        }
        property.widget = formCode;
      }
      if (property?.items?.properties) {
        transformSchemaWidgetByFormCode(
          property?.items?.properties,
          property?.id,
        );
      }
    });
  };

  const dfs = (data: any, _sourceFieldIdSet: any, parent?: any): any => {
    const { type, properties } = data;
    if (type === 'object') {
      return Object.values(properties)
        .filter(
          (item: any) =>
            componentsWhiteList.includes(item?.widget) ||
            item?.originWidget === 'relation',
        )
        .map((item: any) => {
          const children = Object.values(item?.items?.properties ?? {}) ?? [];
          const value = parent ? parent?.id + '/' + item?.id : item?.id;
          const showTitle = parent
            ? parent?.title + '.' + item?.title
            : item?.title;
          _sourceFieldIdSet.add(value);
          return {
            isLeaf: !children.length,
            selectable: !children.length,
            label: item?.title,
            title: item?.title,
            showTitle,
            value,
            children: children.length
              ? dfs(item?.items, _sourceFieldIdSet, item)
              : undefined,
          };
        });
    }
  };
  const getSchema = () => {
    const _sourceFieldIdSet = new Set<string>();
    getFormSchema({
      formCode,
    }).then((res: any) => {
      const schema = customJP(res?.data?.content);
      transformSchemaWidgetByFormCode(schema.properties);
      if (schema) {
        setSourceSchemaPro(schema.properties ?? {});
        const _setSoucreField = dfs(schema, _sourceFieldIdSet);
        setSoucreField(_setSoucreField);
        setSourceFieldIdSet(_sourceFieldIdSet);
        // 不仅仅处理soucreField
        // 也处理 tableFilterData 表格数据源
        if (isRelationInTable) {
          const _tableFilterData = [];
          const _tableSourceMap = {};
          _setSoucreField.forEach((i) => {
            if (i?.value?.includes('table_')) {
              _tableFilterData.push({
                isLeaf: true,
                selectable: true,
                label: i?.lable,
                title: i?.title,
                value: i?.value,
              });
              _tableSourceMap[i?.value] = [i];
            }
          });
          setTableFilterData(_tableFilterData);
          setTableSourceMap(_tableSourceMap);
        }
      }
    });
  };

  const getSchemaList = async (formCodeArr) => {
    const _sourceFieldIdSet = new Set<string>();
    const promiseAll = formCodeArr?.map((item) =>
      getFormSchema({ formCode: item }).then(),
    );
    Promise.all(promiseAll).then((resArr) => {
      // 临时把两个schema合到一起
      // 把 两个关联表单构造成两个table  为了tree的样式
      let preWidgets = {};
      const schemaArr = resArr?.map((i) => ({
        title: i?.data?.title,
        content: customJP(i?.data?.content),
      }));
      const schema = schemaArr?.reduce((cur, pre) => {
        cur = {
          id: '#',
          type: 'object',
          properties: {
            ...cur?.properties,
            ['table_@@' + pre?.title]: {
              title: pre?.title,
              id: 'table_@@' + pre?.title,
              type: 'array',
              widgetPrefix: 'table',
              widget: 'table',
              items: {
                ...pre?.content,
              },
            },
          },
        };
        const keys = Object.keys(pre?.content?.properties ?? {}) ?? [];
        keys.forEach((j) => {
          preWidgets = {
            ...preWidgets,
            [j]: {
              ...pre?.content?.properties?.[j],
              preTitle: 'table_@@' + pre?.title,
            },
          };
        });
        return cur;
      }, {});
      setPreWidgets(preWidgets);
      transformSchemaWidgetByFormCode(schema?.properties);
      if (schema) {
        setSourceSchemaPro(schema?.properties ?? {});
        setSoucreField(dfs(schema, _sourceFieldIdSet));
        setSourceFieldIdSet(_sourceFieldIdSet);
      }
    });
  };

  const addRow = (index: number) => {
    const _modalData = [...modalData];
    _modalData.splice(index + 1, 0, {
      id: nanoid(),
      sourceVal: '',
      targetVal: '',
    });
    setModalData(_modalData);
  };

  const reduceRow = (index: number) => {
    const _modalData = [...modalData];
    const reduceItem = _modalData.splice(index, 1);
    formIns.setFieldsValue({
      [`target_${reduceItem[0]?.id}`]: undefined,
      [`soucre_${reduceItem[0]?.id}`]: undefined,
    });

    if (reduceItem[0]?.soucreWidget) {
      const widgetTreeData = getStatusTreeData({
        data: targetField?.[reduceItem[0]?.soucreWidget],
        whiteId: reduceItem[0]?.targetVal,
      });
      _modalData?.forEach((item) => {
        if (item?.soucreWidget === reduceItem[0]?.soucreWidget) {
          if (item && item?.soucreWidget !== 'relation') {
            item.targetTreeData = widgetTreeData;
          }
        }
      });
    }
    setModalData(_modalData);
  };

  const addTableRow = (index: number, indexJ: number) => {
    const _tableModalData = [...tableModalData];
    _tableModalData[index].dataFillingRules?.splice(indexJ + 1, 0, {
      id: nanoid(),
      sourceVal: '',
      sourceValArr: tableSourceMap?.[_tableModalData[index]?.tableId],
      targetVal: '',
      soucreWidget: '',
      targetValArr: [],
    });
    setTableModalData(_tableModalData);
  };

  const reduceTableRow = (index: number, indexJ: number) => {
    const _tableModalData = [...tableModalData];
    const reduceItem = _tableModalData[index]?.dataFillingRules.splice(
      indexJ,
      1,
    );
    formIns.setFieldsValue({
      [`${index}/${indexJ}@fillSoucre_${reduceItem[0]?.id}`]: undefined,
      [`${index}/${indexJ}@fillTarget_${reduceItem[0]?.id}`]: undefined,
    });
    if (reduceItem[0]?.soucreWidget) {
      const widgetTreeData = getStatusTreeData({
        data: tableTargetMapList?.[tableName]?.[reduceItem[0]?.soucreWidget],
        whiteId: reduceItem[0]?.targetVal,
      });
      _tableModalData?.forEach((item) => {
        if (item && item?.soucreWidget === reduceItem[0]?.soucreWidget) {
          if (item?.soucreWidget !== 'relation') {
            item.targetTreeData = widgetTreeData;
          }
        }
      });
    }
    setTableModalData(_tableModalData);
  };
  // 通过selectedTargetField 更新当前填充状态
  const getStatusTreeData = ({
    data,
    whiteId,
    blackId,
  }: {
    data?: DefaultOptionType[];
    whiteId?: string;
    blackId?: string;
  }) => {
    const dfs = (values: DefaultOptionType[]): any =>
      values.map((item) => ({
        ...item,
        disabled:
          (selectedTargetField.has(item?.value) && item?.value !== whiteId) ||
          item?.value === blackId,
        children: Array.isArray(item?.children) ? dfs(item?.children) : [],
      }));

    return Array.isArray(data) ? dfs(data) : [];
  };

  const getChangeValSchema = (sourceSchemaPro: any, id: string) => {
    let changeValSchema;
    if (sourceSchemaPro) {
      const tableSchemaPro = Object.values(sourceSchemaPro).filter(
        (item: any) => item.widget === 'table',
      );
      if (tableSchemaPro && tableSchemaPro.length > 0) {
        for (let i = 0; i < tableSchemaPro.length; i++) {
          const tableSchema = tableSchemaPro[i];
          if (
            tableSchema?.items?.properties &&
            Object.keys(tableSchema?.items?.properties)?.includes(id)
          ) {
            changeValSchema = tableSchema?.items?.properties[id];
            break;
          }
        }
      }
    }
    return changeValSchema;
  };

  const onValuesChange = (
    changedValues: { [name: string]: string },
    allValues?: { [name: string]: string },
  ) => {
    const _modalData = [...modalData];
    const _tableModalData = [...tableModalData];
    Object.keys(changedValues)?.forEach((key) => {
      const splitKey = key.split('_') as any;
      if (key.includes('type_')) {
        const target = _modalData.find((item) => item?.id === splitKey[1]);
        if (target) {
          target.type = changedValues[key];
        }
        return;
      }
      if (splitKey?.includes('soucre') || splitKey?.includes('target')) {
        const changedVal = changedValues[key];
        const target = _modalData.find((item) => item?.id === splitKey[1]);
        if (target) {
          const idList: undefined | string[] = changedVal?.split('/');
          const comId = idList?.[idList.length - 1];
          let changedValSchema: SchameItem = {};
          let changedValFormCode: string = '';
          if (splitKey[0] === 'soucre') {
            target.sourceVal = changedVal;
            if (changedVal) {
              if (allowThreeLevelTable) {
                changedValSchema =
                  sourceSchemaPro[idList[0]] ||
                  getChangeValSchema(sourceSchemaPro, idList[0]);
              } else {
                changedValSchema = sourceSchemaPro[idList[0]];
              }
              if (idList.length === 2) {
                changedValSchema =
                  changedValSchema?.items?.properties?.[idList[1]];
              }
              const isRelation = changedValSchema?.widget === 'relation';
              if (isRelation) {
                changedValFormCode = getSchemaFormCode(changedValSchema);
              }
              if (changedValSchema?.widget !== target?.soucreWidget) {
                if (isRelation) {
                  target.soucreWidget = schemaIdToWidget.get(comId);
                } else {
                  target.soucreWidget = changedValSchema?.widget;
                }
                target.targetVal = '';
                formIns.setFieldsValue({
                  [`target_${target?.id}`]: undefined,
                });
              }
            } else {
              target.targetVal = '';
              formIns.setFieldsValue({
                [`target_${target?.id}`]: undefined,
              });
            }
          }
          if (splitKey[0] === 'target') {
            if (changedVal) {
              changedValSchema = flatten[changedVal]?.schema;
            } else {
              const sourceIdList = target.sourceVal.split('/');
              changedValSchema = sourceSchemaPro[sourceIdList[0]];
              if (sourceIdList.length === 2) {
                changedValSchema =
                  changedValSchema?.items?.properties?.[sourceIdList[1]];
              }
            }
            const isRelation = changedValSchema?.widget === 'relation';
            if (isRelation) {
              changedValFormCode = getSchemaFormCode(changedValSchema);
            }
          }
          if (changedValSchema) {
            const isRelation = changedValSchema?.widget === 'relation';
            // 双表单  changedValSchema.widget 为formCode的拼接的  所以 需要做特殊判断
            let doubleRelationWidget = '';
            if (!isRelation && Object.keys(targetField)?.length) {
              doubleRelationWidget = Object.keys(targetField)?.filter(
                (item) =>
                  changedValSchema?.widget?.includes(item) ||
                  item?.includes(changedValSchema?.widget) ||
                  getCurTreeData(changedValSchema, targetField[item]),
              )?.[0];
            }
            let widgetList: any[] =
              targetField[
                isRelation ? changedValFormCode : doubleRelationWidget
              ];

            const widgetTreeData = getStatusTreeData({
              data: widgetList,
              blackId:
                splitKey[0] === 'target' ? changedValues[key] : undefined,
              whiteId: target?.targetVal,
            });
            target.targetTreeData = widgetTreeData;
            _modalData?.forEach((item) => {
              if (item && item?.soucreWidget === changedValSchema?.widget) {
                if (changedValSchema?.widget !== 'relation') {
                  item.targetTreeData = widgetTreeData;
                } else {
                  const itemSource = item?.sourceVal.split('/');
                  const schemaItem =
                    itemSource?.length > 1
                      ? sourceSchemaPro[itemSource[0]]?.items?.properties?.[
                          itemSource[1]
                        ]
                      : sourceSchemaPro[itemSource[0]];
                  const itemSourceFormCode = getSchemaFormCode(schemaItem);
                  if (item && itemSourceFormCode === changedValFormCode) {
                    item.targetTreeData = widgetTreeData;
                  }
                }
              }
            });
          }
          if (splitKey[0] === 'target') {
            target.targetVal = changedValues[key];
          }
        }
      } else {
        const pathArr = splitKey[0]?.split('@')?.[0]?.split('/');
        const indexI = pathArr[0];
        const indexJ = pathArr[1];
        const changedVal = changedValues[key];
        let target = {};
        if (splitKey[0]?.includes('filter')) {
          target = _tableModalData[indexI];
        } else if (
          splitKey[0]?.includes('fillSoucre') ||
          splitKey[0]?.includes('fillTarget')
        ) {
          target = _tableModalData[indexI]?.dataFillingRules?.[indexJ];
        }
        if (target) {
          const idList: undefined | string[] = changedVal?.split('/');
          const comId = idList?.[idList.length - 1];
          let changedValSchema: SchameItem = {};
          let changedValFormCode: string = '';
          if (splitKey[0]?.includes('filter')) {
            if (target.tableId === changedVal) return;
            target.tableId = changedVal;
            if (changedVal && tableSourceMap?.[changedVal]) {
              target.dataFillingRules = [
                {
                  id: nanoid(),
                  sourceVal: '',
                  sourceValArr: tableSourceMap?.[changedVal],
                  targetVal: '',
                  sourceWidget: '',
                  targetValArr: [],
                },
              ];
              setTableTargetMap(tableTargetMapList?.[tableName] ?? {});
            }
          }
          if (splitKey[0]?.includes('fillSoucre')) {
            const widgetName = comId?.split('_')?.[0];
            if (target?.sourceVal?.includes(widgetName)) return;
            target.sourceVal = changedVal;
            if (changedVal) {
              changedValSchema = sourceSchemaPro[idList[0]];
              if (idList.length === 2) {
                changedValSchema =
                  changedValSchema?.items?.properties?.[idList[1]];
              }
              const isRelation = changedValSchema?.widget === 'relation';
              if (isRelation) {
                changedValFormCode = getSchemaFormCode(changedValSchema);
              }

              if (changedValSchema?.widget !== target?.soucreWidget) {
                if (isRelation) {
                  target.soucreWidget = schemaIdToWidget.get(comId);
                } else {
                  target.soucreWidget = changedValSchema?.widget;
                }
                target.targetVal = '';
                formIns.setFieldsValue({
                  [`${indexI}/${indexJ}@fillTarget_${target?.id}`]: undefined,
                });
              }
            } else {
              target.targetVal = '';
              formIns.setFieldsValue({
                [`${indexI}/${indexJ}@fillSoucre_${target?.id}`]: undefined,
              });
              formIns.setFieldsValue({
                [`${indexI}/${indexJ}@fillTarget_${target?.id}`]: undefined,
              });
            }
          }
          if (splitKey[0]?.includes('fillTarget')) {
            if (changedVal) {
              changedValSchema = flatten[changedVal]?.schema;
            } else {
              const sourceIdList = target.sourceVal.split('/');
              changedValSchema = sourceSchemaPro[sourceIdList[0]];
              if (sourceIdList.length === 2) {
                changedValSchema =
                  changedValSchema?.items?.properties?.[sourceIdList[1]];
              }
            }
            const isRelation = changedValSchema?.widget === 'relation';
            if (isRelation) {
              changedValFormCode = getSchemaFormCode(changedValSchema);
            }
          }
          if (changedValSchema && !splitKey[0]?.includes('filter')) {
            const isRelation = changedValSchema?.widget === 'relation';
            // 双表单  changedValSchema.widget 为formCode的拼接的  所以 需要做特殊判断
            let doubleRelationWidget = '';
            if (!isRelation && Object.keys(targetField)?.length) {
              doubleRelationWidget = Object.keys(targetField)?.filter(
                (item) =>
                  changedValSchema?.widget?.includes(item) ||
                  item?.includes(changedValSchema?.widget),
              )?.[0];
            }
            let widgetList: any[] =
              tableTargetMapList?.[tableName][
                isRelation ? changedValFormCode : doubleRelationWidget
              ];
            const widgetTreeData = getStatusTreeData({
              data: widgetList,
              blackId: splitKey[0]?.includes('fillTarget')
                ? changedValues[key]
                : undefined,
              whiteId: target?.targetVal,
            });
            target.targetValArr = widgetTreeData;
          }
          if (splitKey[0]?.includes('fillTarget')) {
            target.targetVal = changedValues[key];
          }
        }
      }
    });
    setModalData(_modalData);
    setTableModalData(_tableModalData);
  };

  // 考虑到客户有两张表需要多进行一个判断
  const getCurTreeData = (changedValSchema, item) => {
    if (Array.isArray(item) && item.length > 0) {
      const relateSource = item[0].relateSource || [];
      const formCode = relateSource
        .map((item) => item?.dataSource?.target?.formCode || '')
        .join('');

      if (formCode.includes(changedValSchema?.widget)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const submitModalData = () => {
    formIns
      .validateFields()
      .then((res) => {
        const formatVal = modalData
          .map((item) => {
            if (!item?.sourceVal || !item?.targetVal) {
              return null;
            }
            return {
              // 需要判断是否为 企业客户  个人客户
              source: item?.sourceVal,
              target: item?.targetVal,
              type:
                showRelationFillTableType &&
                !item.sourceVal?.includes('table_') &&
                item.targetVal.includes('table_') &&
                !hideTypeComList.includes(item.soucreWidget)
                  ? item?.type
                  : undefined,
            };
          })
          .filter((i) => !!i);
        // widgetType 为 'bizSuite' 是 关联合同&订单（套件）
        if (widgetType === 'bizSuite' && id) {
          props.addons.setValueByPath(
            `properties.${id}.dataFillingRules`,
            formatVal,
          );
        }
        props?.onChange?.(formatVal);
        // 设置table的填充
        if (isRelationInTable) {
          const formatTableVal = tableModalData
            .map((item) => {
              if (!item?.tableId) return null;
              return {
                dataSource: item?.dataSource,
                tableId: item?.tableId,
                dataFillingRules: item?.dataFillingRules
                  .map((i) => {
                    if (!i?.sourceVal || !i?.targetVal) {
                      return null;
                    }
                    return {
                      source: i?.sourceVal,
                      target: i?.targetVal,
                    };
                  })
                  .filter((i) => !!i),
              };
            })
            .filter((i) => !!i);
          props.addons.setValueByPath(
            `subTableDataFillingRules`,
            formatTableVal,
          );
        }
        setModalVisible(false);
      })
      .catch(() => {
        if (
          modalData.length === 1 &&
          !modalData[0]?.sourceVal &&
          !modalData[0]?.targetVal
        ) {
          // widgetType 为 'bizSuite' 是 关联合同&订单（套件）
          if (widgetType === 'bizSuite' && id) {
            props.addons.setValueByPath(`dataFillingRules`, []);
          }
          props.onChange?.(undefined);
          setModalVisible(false);
        }
        // 设置table的填充
        if (
          isRelationInTable &&
          tableModalData?.length === 1 &&
          !tableModalData[0].tableId
        ) {
          props.addons.setValueByPath(
            `properties.${id}.subTableDataFillingRules`,
            [],
          );
        }
        if (
          modalData.length === 1 &&
          !modalData[0]?.sourceVal &&
          !modalData[0]?.targetVal &&
          isRelationInTable &&
          tableModalData?.length === 1 &&
          !tableModalData[0].tableId
        ) {
          setModalVisible(false);
        }
      });
  };

  const checkoutValueIsValid = (
    form: any,
    formData: any,
    type: string,
    options: any,
  ) => {
    // formIns, tableModalData, 'table', options: {indexI: i, indexJ: indexJ}
    const i = options?.indexI;
    const j = options?.indexJ;
    const item = formData[i]?.dataFillingRules?.[j];
    // const mapData = item?.sourceValArr?.[0]?.children?.map(k => k.value);
    if (item?.sourceVal) {
      form.setFieldsValue({
        [`${i}/${j}@fillSoucre_${item?.id}`]: item?.sourceVal,
      });
    }
  };
  return (
    <Modal
      className={Styles.dataFillModal}
      title="填充规则设置"
      visible={modalVisible}
      width={800}
      onCancel={() => {
        setModalVisible(false);
      }}
      footer={
        <div className={Styles.btnRow}>
          <Button
            onClick={() => {
              setModalVisible(false);
            }}
          >
            取消
          </Button>
          <Button type="primary" onClick={submitModalData}>
            确定
          </Button>
        </div>
      }
    >
      <div className={Styles.modal}>
        <div className={Styles.modalTitle}>主数据填充设置</div>
        <div className={Styles.modalHead}>
          <div>关联表单</div>
          <div>当前表单</div>
        </div>
        <Form form={formIns} onValuesChange={onValuesChange}>
          {modalData.map((item, i) => (
            <div className={Styles.modalRow} key={item?.id}>
              <Form.Item
                name={'soucre_' + item?.id}
                rules={[
                  {
                    validateTrigger: 'onChange',
                    validator: (rule: any, value: string) => {
                      if (!value && !isRelationInTable) {
                        return Promise.reject('请选择表单字段');
                      }
                      if (
                        !isRelationInTable &&
                        (!sourceFieldIdSet?.has(value) ||
                          value === '字段已被删除')
                      ) {
                        return Promise.reject('字段已被删除，请重新选择');
                      }
                      return Promise.resolve(value);
                    },
                  },
                ]}
              >
                <TreeSelect
                  allowClear
                  placeholder="请选择表单字段"
                  style={{ width: 301 }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={soucreField}
                  // treeDefaultExpandAll
                />
              </Form.Item>
              <div className={Styles.modalRowText}>的值填充到</div>
              <Form.Item
                name={'target_' + item?.id}
                rules={[
                  {
                    validateTrigger: 'onChange',
                    validator: (rule: any, value: string) => {
                      if (!value && !isRelationInTable) {
                        return Promise.reject('请选择表单字段');
                      }
                      if (value === '字段已被删除') {
                        return Promise.reject('字段已被删除，请重新选择');
                      }
                      return Promise.resolve(value);
                    },
                  },
                ]}
              >
                <TreeSelect
                  allowClear
                  disabled={!item?.sourceVal}
                  placeholder="请选择表单字段"
                  style={{
                    width:
                      showRelationFillTableType &&
                      item &&
                      !item.sourceVal?.includes('table_') &&
                      item.targetVal.includes('table_') &&
                      !hideTypeComList.includes(item.soucreWidget)
                        ? 163
                        : 301,
                  }}
                  dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                  treeData={item?.targetTreeData}
                  treeDefaultExpandAll
                />
              </Form.Item>
              {showRelationFillTableType &&
                item &&
                !item.sourceVal?.includes('table_') &&
                item.targetVal.includes('table_') &&
                !hideTypeComList.includes(item.soucreWidget) && (
                  <Form.Item name={'type_' + item?.id}>
                    <Select
                      placeholder="选择填充规则"
                      className={Styles.fillFormItem}
                    >
                      <Select.Option value={0}>第一行</Select.Option>
                      <Select.Option value={1}>每一行</Select.Option>
                    </Select>
                  </Form.Item>
                )}
              <div className={Styles.modalRowOperations}>
                {i === modalData.length - 1 && (
                  <IconFont
                    type="icon-add-line"
                    className={Styles.addIcon}
                    onClick={() => addRow(i)}
                  ></IconFont>
                )}
                {modalData.length > 1 && (
                  <IconFont
                    type="icon-delete-line"
                    className={Styles.reduceIcon}
                    onClick={() => reduceRow(i)}
                  ></IconFont>
                )}
              </div>
            </div>
          ))}
        </Form>
        {isRelationInTable && (
          <div className={Styles.tableBody}>
            <div className={Styles.modalTitle + ' ' + Styles.modalTitleDesc}>
              表格数据填充设置
            </div>
            <Form form={formIns} onValuesChange={onValuesChange}>
              {tableModalData?.map((item, i) => (
                <div className={Styles.tableModalRow} key={item?.id}>
                  <div className={Styles.tableTitle}>表格数据</div>
                  <div className={Styles.tableFilter}>
                    <Form.Item
                      name={i + '@' + 'filter_' + item?.id}
                      rules={[
                        {
                          validateTrigger: 'onChange',
                          validator: (rule: any, value: string) => {
                            return Promise.resolve(value);
                          },
                        },
                      ]}
                    >
                      <TreeSelect
                        allowClear
                        placeholder="请选择表单字段"
                        style={{ marginRight: 0 + 'px' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={tableFilterData}
                        treeDefaultExpandAll
                      />
                    </Form.Item>
                    <div
                      className={Styles.tableFilterTip}
                      onClick={() => {
                        const formatVal = modalData
                          .map((item) => {
                            if (!item?.sourceVal || !item?.targetVal) {
                              return null;
                            }
                            return {
                              // 需要判断是否为 企业客户  个人客户
                              source: item?.sourceVal,
                              target: item?.targetVal,
                            };
                          })
                          .filter((i) => !!i);
                        props.addons.setValueByPath(
                          'dataFillingRules',
                          formatVal ?? [],
                        );
                        showDataFilter(i, tableModalData?.[i]?.tableId);
                      }}
                    >
                      <span>
                        {item?.dataSource?.params?.filters?.length
                          ? '已设置过滤条件'
                          : '请设置过滤条件'}
                      </span>
                      <IconFont
                        className={
                          Styles['field-icon'] + ' ' + Styles.iconBianji
                        }
                        type="icon-bianji1"
                      />
                    </div>
                  </div>
                  <div className={Styles.tableFilling}>
                    {item?.dataFillingRules?.map((j, indexJ) => (
                      <div className={Styles.tableFillingLists} key={j?.id}>
                        <Form.Item
                          name={i + '/' + indexJ + '@' + 'fillSoucre_' + j?.id}
                          rules={[
                            {
                              validateTrigger: 'onChange',
                              validator: (rule: any, value: string) => {
                                checkoutValueIsValid(
                                  formIns,
                                  tableModalData,
                                  'table',
                                  { indexI: i, indexJ: indexJ },
                                );
                                if (
                                  (value && !sourceFieldIdSet?.has(value)) ||
                                  value === '字段已被删除'
                                ) {
                                  return Promise.reject(
                                    '字段已被删除，请重新选择',
                                  );
                                }
                                return Promise.resolve(value);
                              },
                            },
                          ]}
                        >
                          <TreeSelect
                            allowClear
                            placeholder="请选择表单字段"
                            style={{ width: 281 }}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeData={j?.sourceValArr}
                            treeNodeLabelProp={'showTitle'}
                            treeDefaultExpandAll
                          />
                        </Form.Item>
                        <div className={Styles.modalRowText}>的值填充到</div>
                        <Form.Item
                          name={i + '/' + indexJ + '@' + 'fillTarget_' + j?.id}
                          rules={[
                            {
                              validateTrigger: 'onChange',
                              validator: (rule: any, value: string) => {
                                if (value === '字段已被删除') {
                                  return Promise.reject(
                                    '字段已被删除，请重新选择',
                                  );
                                }
                                return Promise.resolve(value);
                              },
                            },
                          ]}
                        >
                          <TreeSelect
                            allowClear
                            disabled={!j?.sourceVal}
                            placeholder="请选择表单字段"
                            style={{ width: 281 }}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            treeNodeLabelProp={'showTitle'}
                            treeData={j?.targetValArr}
                            treeDefaultExpandAll
                          />
                        </Form.Item>
                        <div className={Styles.modalRowOperations}>
                          {indexJ === item?.dataFillingRules.length - 1 && (
                            <IconFont
                              type="icon-add-line"
                              className={Styles.addIcon}
                              onClick={() => addTableRow(i, indexJ)}
                            ></IconFont>
                          )}
                          {item?.dataFillingRules?.length > 1 && (
                            <IconFont
                              type="icon-delete-line"
                              className={Styles.reduceIcon}
                              onClick={() => reduceTableRow(i, indexJ)}
                            ></IconFont>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </Form>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default FillModal;
