/*
 * :file description:
 * :name: \low-code-platform\src\pages\generator\relation\business\components\FormulaEditorModal\common\formulaUsage.ts
 * :author: 杨海涛
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-08-10 10:01:02
 * :last editor: 杨海涛
 * :date last edited: 2022-08-10 09:38:57
 */
const formulaUsage = [
  {
    id: 1,
    category: '数学函数',
    order: 1,
    // docUrl: "https://hc.jiandaoyun.com/doc/9035",
    contains: [
      {
        name: 'AVERAGE',
        intro: 'AVERAGE函数可以获取一组数值的算术平均值',
        usage: 'AVERAGE(数字1,数字2,...)',
        example:
          'AVERAGE({语文成绩},{数学成绩}, {英语成绩})返回三门课程的平均分',
      },
      {
        name: 'COUNT',
        intro: 'COUNT函数可以获取参数的数量',
        usage: 'COUNT(值,值,...)',
        example: 'COUNT(小明,小王,小张,小李)返回4，也就是人员的数量',
      },
      {
        name: 'INT',
        intro: 'INT函数可以获取一个数的整数部分',
        usage: 'INT(数字)',
        example: 'INT(3.1415)返回3，也就是3.1415的整数部分',
      },
      {
        name: 'MAX',
        intro: 'MAX函数可以获取一组数值的最大值',
        usage: 'MAX(数字1,数字2,...)',
        example: 'MAX({语文成绩},{数学成绩},{英语成绩})返回三门课程中的最高分',
      },
      {
        name: 'MIN',
        intro: 'MIN函数可以获取一组数值的最小值',
        usage: 'MIN(数字1,数字2,...)',
        example: 'MIN({语文成绩},{数学成绩},{英语成绩})返回三门课程中的最低分',
      },
      {
        name: 'SUM',
        intro: 'SUM函数可以获取一组数值的总和',
        usage: 'SUM(数字1,数字2,...)',
        example: 'SUM({语文成绩},{数学成绩}, {英语成绩})返回三门课程的总分',
      },
    ],
  },
  {
    id: 2,
    category: '逻辑函数',
    order: 2,
    contains: [
      {
        name: 'AND',
        intro:
          'AND函数如果所有参数都为真，AND函数返回布尔值true，否则返回布尔值false',
        usage: 'AND(逻辑表达式1,逻辑表达式2,...)',
        example:
          'AND({语文成绩}>90，{数学成绩}>90，{英语成绩}>90)，如果三门课成绩都> 90，返回true，否则返回false',
      },
      {
        name: 'OR',
        intro:
          'OR函数如果任意参数为真，OR 函数返回布尔值true；如果所有参数为假，返回布尔值',
        usage: 'OR(逻辑表达式1,逻辑表达式2,...)',
        example:
          'OR({语文成绩}>90，{数学成绩}>90，{英语成绩}>90)，任何一门课成绩> 90，返回true，否则返回false',
      },
    ],
  },
  {
    id: 3,
    category: '高级函数',
    order: 4,
    contains: [
      {
        name: 'INSERT',
        intro: '函数说明：向目标表单插入数据',
        // usage: 'INSERT(目标表,目标列1,目标值1,目标列2,目标值2....)',
        example:
          '函数示例：INSERT(目标表单,目标表单.产品名称,{当前表单.产品名称})，则表示将「当前表单」的产品名称插入到「目标表单」的产品名称中',
      },
      {
        name: 'UPSERT',
        intro:
          '函数说明：先根据筛选条件查找目标表单中的数据，如果找到数据则进行更新，否则向目标表单中新插入一条数据',
        // usage:
        //   'UPSERT(目标表, 主条件, 子条件, 目标列1, 目标值1, 目标列2, 目标值2...)',
        example:
          '函数示例：UPSERT(目标表单,目标表单.产品编号=={当前表单.产品编号},"",目标表单.产品名称,{当前表单.产品名称})，则表示当「目标表单」中产品编号等于「当前表单」的产品编号时，将「当前表单」的产品名称更新到「目标表单」中对应产品编号的产品名称。当「目标表单」中产品编号等于「当前表单」的产品编号时，将「当前表单」的产品名称插入到「目标表单」的产品名称中',
      },
      {
        name: 'UPDATE',
        intro: '函数说明：更新目标表单中符合条件的数据',
        // usage:
        //   'UPDATE(目标表, 主条件, 子条件, 目标列1, 目标值1, 目标列2, 目标值2...2)',
        example:
          '函数示例：UPDATE(目标表单,目标表单.产品编号=={当前表单.产品编号},"",目标表单.产品名称,{当前表单.产品名称})，则表示当「目标表单」中产品编号等于「当前表单」的产品编号时，将「当前表单」的产品名称更新到「目标表单」中对应产品编号的产品名称',
      },
      {
        name: 'DELETE',
        intro: '函数说明：删除目标表单中符合条件的数据',
        // usage: 'DELETE(目标表, 主条件, 子条件)',
        example:
          '函数示例：DELETE(目标表单,目标表单.产品名称=={铅笔},"")则表示要删除的数据是：目标表单中产品名称为「铅笔」的这批数据',
      },
    ],
  },
];

export const formulaUsageVersion23 = [
  {
    category: '日期函数',
    order: 3,
    contains: [
      {
        name: 'DAYS',
        intro: 'DAYS返回两个日期之间相差的天数',
        usage: '用法：DAYS(结束日期,开始日期)',
        example:
          '示例：DAYS({结束日期},{开始日期})，如果开始日期为2022-01-07，结束如期为2022-01-01，则返回结果为6',
      },
      {
        name: 'MONTH',
        intro: 'MONTH 返回某日期的月份，月份是结余1到12之间的整数',
        usage: '用法：MONTH(时间戳)',
        example:
          '示例：MONTH({开始日期})，如果开始日期为2022-10-01，则返回结果为10',
      },
      {
        name: 'YEAR',
        intro: 'YEAR返回某日期的年份',
        usage: '用法：YEAR(时间戳)',
        example:
          '示例：YEAR({开始日期})，如果开始日期为2022-01-01，则返回结果为2022',
      },
      {
        name: 'NOW',
        intro: 'NOW 返回当前的年月日',
        usage: '用法：NOW()',
        example:
          '示例：NOW()，如果当前日期为2023-06-12，那么返回结果为2023-06-12',
      },
    ],
  },
];

export default formulaUsage;
