/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/relation/hooks.ts
 * :author: KyleLiang
 * :copyright: (c) 2024, Tungee
 * :date created: 2024-05-06 13:50:00
 * :last editor: KyleLiang
 * :date last edited: 2024-08-05 16:41:15
 */
import React, { useEffect, useState } from 'react';
import { isInWhiteList } from '@/services/extension-setting';

export const useGray = (grayKey: string) => {
  const [isInGray, setIsInGray] = useState(false);
  const getGray = async () => {
    await isInWhiteList({ code: grayKey }).then((res: any) => {
      const isInWhiteList = res?.result || false;
      setIsInGray(isInWhiteList);
    });
  };
  useEffect(() => {
    getGray();
  }, []);
  return isInGray;
};
