/*
 * :file description:
 * :name: \ui-renderd:\progree\low-code-platform\src\pages\generator\form\services\form-generator\index.ts
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-19 15:30:19
 * :last editor: hyw
 * :date last edited: 2022-06-15 14:09:15
 */
import { postRequest, getRequest } from '@/common/http';

interface IGetIconListParams {
  moduleCode: string;
}
// 获取icon列表
export async function getIconList(data: IGetIconListParams) {
  return getRequest(`/api/common/icon/getIconList`, data);
}

export interface ISaveFormSchema {
  appUuid?: string;
  content: string;
  corpId?: string;
  description?: string;
  formCode?: string;
  icon: string;
  title: string;
  userId?: string;
  propsSettings: any;
  menu?: Object;
}
// 新建-编辑表单
export async function saveFormSchema(data: ISaveFormSchema) {
  return postRequest(`/api/form/schema/saveFormSchema`, data);
}

interface IGetVisibleFormListParams {
  appUuid?: string;
  corpId?: string;
  formCode: string;
  userId?: string;
}

//  获取可见的表单列表
export async function getVisibleFormList() {
  return postRequest(`/api/form/schema/getVisibleFormList`);
}

// 查询表单schema
export async function getFormSchema(data: IGetVisibleFormListParams) {
  return postRequest(`/api/form/schema/getFormSchema`, data);
}

// 查询表单schema
export async function getFormSchemaForDesignStatus(
  data: IGetVisibleFormListParams,
) {
  return postRequest(`/api/form/schema/getFormSchemaForDesignStatus`, data);
}
interface IncrSeq {
  appUuid?: string;
  corpId?: string;
  formCode: string;
  maxCount: number;
  reset: string;
}

// 表单运行态-流水号获取自增位数组件的值
export async function incrSeq(data: IncrSeq) {
  return postRequest(`/api/formInstance/incrSeq`, data, {
    headers: {
      formCode: data.formCode,
    },
  });
}

// 获取可以使用的表单高级属性
export async function getFormWidgetPropertyLimit() {
  return getRequest(`/api/form/version/getFormWidgetPropertyLimit`);
}
