import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  Modal,
  Form,
  Input,
  Radio,
  message,
  Tooltip,
  Select,
  Divider,
  Button,
  Cascader,
  DatePicker,
  TreeSelect,
} from 'antd';
import moment from 'moment';
import { cloneDeep, includes } from 'lodash';
import classnames from 'classnames';
import IconFont from '@/components/IconFont';
import { CloseOutlined } from '@ant-design/icons';
import c from './ModalDataShareRule.less';
import ModalContent from '@/pages/generator/form/components/ModalContent';
// 部门、人员、角色架构选择器
import MultipleChoice from '@/components/MultipleChoice';
import { AddRulesTooltip } from '../components/Description';
import FormFormula from '../components/FormFormula';
import { getOriginalFormAssociationRule } from '@/services/extension-setting';
import { getUrlParam } from '@/utils';
import { computeData } from '@/pages/generator/process/components/ProcessDesign/utils';
import {
  SUPPORT_SHAREDATA_WIDGETS_TYPES,
  SUPPORT_SHAREDATA_INPUT_WIDGETS,
  SUPPORT_SHAREDATA_SELECT_WIDGETS,
  SUPPORT_SHAREDATA_CASCADE_WIDGETS,
  SUPPORT_SHAREDATA_DATE_WIDGETS,
  SUPPORT_SHAREDATA_DIV_WIDGETS,
  SUPPORT_SHAREDATA_RELATION_WIDGETS,
} from './constants';
import {
  transformDateToString,
  transformDateToMoment,
} from '@/pages/form/utils';

import SelectorModal from './WidgetModal';

const { RangePicker } = DatePicker;
interface shareRule {
  name: string;
  filterConditionGroupList: Array<conditionItem>;
  filterConditionGroupListObj?: object;
  applicableMember: object;
  permissions: Array<any>;
  formCode: string;
  id?: string;
  status: number;
}
interface conditionItem {
  valueType: string;
  id: string;
  fieldArr: Array<any>;
  filterType: string;
  filterTypeArr: Array<any>;
  value: Array<any>;
  valueArr: Array<any>;
  valueTree?: Array<any>;
  values: Array<any>;
}

const { Option } = Select;

interface IProps {
  visible: boolean;
  fieldsList: object;
  cascadeTree: object | undefined;
  cascadeTreeV2: object | undefined;
  onClose: () => void;
  currentRule: shareRule | undefined;
  onChange: (data: shareRule) => Promise<any>;
}

const { TextArea } = Input;

const valuesType = ['IN', 'BETWEEN', 'BELONG', 'NBELONG', 'NIN'];

const ShowUserOrDept = (props: any) => {
  const {
    flowType,
    value = {},
    memberTitleList,
    onChange,
    onChangeMember,
    changeCount,
    type,
    multiple = true,
  } = props;
  const childRef = useRef(null);

  const title = type?.includes('department')
    ? '部门'
    : type?.includes('innerContact')
    ? '组织成员'
    : type?.includes('principal')
    ? '负责人'
    : type?.includes('cooperator')
    ? '协同人'
    : '共享人员';
  const placeholder = type?.includes('department')
    ? '请选择部门'
    : type?.includes('innerContact')
    ? '请选择组织成员'
    : type?.includes('principal')
    ? '请选择负责人'
    : type?.includes('cooperator')
    ? '请选择协同人'
    : '请选择共享人员';

  // 打开人员选择器
  const addOrEditUsers = (flowType: string) => {
    (childRef?.current as any)?.openModal();
  };

  // 移除选择的数据
  const removeOne = (e: Event, currentParams: any) => {
    let allData = value;
    switch (currentParams.type) {
      case 'role':
      case 'department':
      case 'initiator':
      case 'member_field':
        let mapTypeJson: any = {
          role: 'roles',
          department: 'departments',
          initiator: 'dynamicPerson',
          member_field: 'dynamicPerson',
        };
        let currentArr = allData[mapTypeJson[currentParams.type]].filter(
          (ele) => currentParams.id != ele.id,
        );
        let finallyData = {
          ...allData,
          [mapTypeJson[currentParams.type]]: currentArr,
        };
        if (onChangeMember) {
          onChangeMember?.(finallyData);
        } else {
          onChange?.(finallyData);
        }
        break;
      case 'employee':
        let users = allData.users.filter(
          (ele) =>
            currentParams.id != ele.id || currentParams.userId != ele.userId,
        );
        let finallyUsersData = {
          ...allData,
          users,
        };
        if (onChangeMember) {
          onChangeMember?.(finallyUsersData);
        } else {
          onChange?.(finallyUsersData);
        }
        break;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  // 获取人员选择器数据
  const getDataBack = (data: any) => {
    console.log(data, 'getDataBack');
    if (onChangeMember) {
      onChangeMember?.(data);
    } else {
      onChange?.(data);
    }
  };

  const listData = computeData(value);

  const tabsList = useMemo(() => {
    if (type?.includes('department')) {
      return ['部门'];
    } else if (
      ['innerContact', 'principal', 'cooperator'].includes(
        type?.split('_')?.[0],
      )
    ) {
      return ['部门', '人员'];
    } else {
      return ['部门', '人员', '角色'];
    }
  }, [value]);

  return (
    <>
      <div
        className={classnames(
          c.usersDiv,
          listData?.length == 0 ? c.placeholder : '',
          flowType == '1' && c.disabledUsersDiv,
        )}
        onClick={() => addOrEditUsers(flowType)}
      >
        {listData?.length !== 0
          ? listData?.map((data: any) => {
              return (
                <span className={c.users} key={data.id}>
                  {data.name}{' '}
                  <CloseOutlined
                    onClick={(e) => removeOne(e, data)}
                    className={c.icon}
                  />
                </span>
              );
            })
          : placeholder}
      </div>
      <MultipleChoice
        cRef={childRef}
        title={title}
        initData={value}
        tabs={tabsList}
        multiple={multiple}
        getDataBack={getDataBack}
        memberTitleList={memberTitleList}
      />
    </>
  );
};

export default (props: IProps) => {
  console.log(props, '1DDD');

  const {
    visible,
    onClose,
    currentRule = {} as shareRule,
    cascadeTree = [],
    cascadeTreeV2 = {},
    fieldsList = [],
    onChange,
  } = props;
  const [form] = Form.useForm();
  const initItem = {
    name: '',
    filterConditionGroupList: [
      [
        {
          id: null,
          fieldArr: [],
          valueType: 'fixed',
          filterType: null,
          filterTypeArr: [],
          title: null,
          value: [],
          values: null,
          valueArr: [],
        },
      ],
    ],
    applicableMember: {
      users: [],
      roles: [],
      departments: [],
    },
    permissions: [],
    formCode: '',
    id: '',
    status: 0,
  };
  const formCode = getUrlParam('formCode');
  const curStatus = typeof currentRule === 'object' && currentRule?.id;

  // 分组设置参数
  const [filterConditionGroupList, setFilterConditionGroupList] = useState([]);

  // 是否弹出关联表单的弹窗
  const [visibleRelation, setVisibleRelation] = useState(false);
  const [relationItem, setRelationItem] = useState({});
  const [relationSchema, setRelationSchema] = useState({});
  const relationSchemaRef = useRef();

  const addConditionField = (index: number) => {
    const _clonelist = [...filterConditionGroupList];
    if (_clonelist[index].length >= 5) {
      return;
    }
    _clonelist[index].push({});
    onChangeFilterConditionGroupList(_clonelist);
  };

  const deleteConditionField = (index: number, fieldInd: number) => {
    const _clonelist = [...filterConditionGroupList];
    const _fieldVOList = _clonelist[index] ?? [];
    if (_fieldVOList.length === 1) {
      if (_clonelist?.length === 1) {
        _clonelist.splice(index, 1, [
          {
            id: null,
            fieldArr: [],
            valueType: 'fixed',
            filterType: null,
            filterTypeArr: [],
            title: null,
            value: [],
            values: null,
            valueArr: [],
          },
        ]);
      } else {
        _clonelist.splice(index, 1);
      }
    } else {
      _fieldVOList.splice(fieldInd, 1);
    }
    onChangeFilterConditionGroupList(_clonelist);
  };

  const addConditionGroup = () => {
    const _clonelist = [...filterConditionGroupList];
    _clonelist.push([
      {
        id: null,
        fieldArr: [],
        valueType: 'fixed',
        filterType: null,
        filterTypeArr: [],
        title: null,
        value: [],
        values: null,
        valueArr: [],
      },
    ]);
    onChangeFilterConditionGroupList(_clonelist);
  };

  const onChangeSelect = (groupIndex: number, fieldIndex: number, val: any) => {
    const isArray = Array.isArray(val);
    const _clonelist = cloneDeep(filterConditionGroupList);
    const selectedField = _clonelist?.[groupIndex]?.[fieldIndex];
    const valueArr = selectedField?.valueArr?.filter((i) => {
      return (isArray ? val : [val])?.includes(i?.value);
    });
    selectedField.value = valueArr;
    onChangeFilterConditionGroupList(_clonelist);
  };

  const onChangeData = (groupIndex: number, fieldIndex: number, val: any) => {
    const isArray = Array.isArray(val);
    const _clonelist = cloneDeep(filterConditionGroupList);
    const selectedField = _clonelist?.[groupIndex]?.[fieldIndex];
    selectedField.value = isArray ? val : [val];
    onChangeFilterConditionGroupList(_clonelist);
  };

  const hideModal = () => {
    onClose?.();
  };

  const getValueArrOptions = (fieldId = '', selectedField?: any) => {
    let resSelectedField = selectedField;
    if (!selectedField) {
      resSelectedField = fieldsList?.find((item) => item?.id === fieldId);
    }

    let valueArr = [];
    if (SUPPORT_SHAREDATA_SELECT_WIDGETS?.includes(resSelectedField?.widget)) {
      // 产品分类是select类型，但是需要从接口取enum
      const selectValue = resSelectedField?.id?.includes('cascade')
        ? cascadeTree
        : resSelectedField;
      // multiTag 是直接读options
      if (resSelectedField?.id?.includes('multiTag')) {
        valueArr = selectValue?.options?.map((item) => {
          return {
            key: item?.key,
            value: item?.key,
            label: item?.value,
          };
        });
      } else {
        valueArr = selectValue?.enum?.map((item, index) => {
          return {
            key: item,
            value: item,
            label: selectValue?.enumNames?.[index], //enumNames
          };
        });
      }
    }
    return valueArr;
  };

  const onHandleChange = (
    groupIndex: number,
    fieldIndex: number,
    option: any,
  ) => {
    const _clonelist = cloneDeep(filterConditionGroupList);

    const selectedField = fieldsList?.find(
      (item) => item?.id === option?.value,
    );
    const widgetName = selectedField?.widget;

    let valueArr = getValueArrOptions(option?.value ?? '', selectedField);
    let valueTree =
      widgetName === 'cascade'
        ? cascadeTree
        : widgetName === 'cascadeV2' || widgetName === 'multiCascade'
        ? cascadeTreeV2?.[fieldsListObj?.[selectedField?.id]?.enumOptionId]
            ?.cascadeOptions
        : null;
    _clonelist[groupIndex].splice(fieldIndex, 1, {
      fieldId: selectedField?.id,
      title: selectedField?.title,
      filterType:
        SUPPORT_SHAREDATA_WIDGETS_TYPES?.[selectedField?.widget]?.[0]?.value,
      filterTypeArr: SUPPORT_SHAREDATA_WIDGETS_TYPES?.[selectedField?.widget],
      value: widgetName === 'multiCascade' ? [[]] : [],
      values: [],
      valueArr: valueArr,
      valueTree: valueTree,
    });
    onChangeFilterConditionGroupList(_clonelist);
  };

  const onHandleChangeFilterType = (
    groupIndex: number,
    fieldIndex: number,
    option: any,
  ) => {
    const _clonelist = cloneDeep(filterConditionGroupList);

    _clonelist[groupIndex].splice(fieldIndex, 1, {
      ...(_clonelist?.[groupIndex]?.[fieldIndex] ?? {}),
      filterType: option?.value,
      value: [],
    });
    onChangeFilterConditionGroupList(_clonelist);
  };

  const onChangeValue = (
    groupIndex: number,
    fieldIndex: number,
    value: any,
    type?: string,
  ) => {
    const _clonelist = cloneDeep(filterConditionGroupList);
    const item = _clonelist?.[groupIndex]?.[fieldIndex];

    if (!type) {
      _clonelist[groupIndex].splice(fieldIndex, 1, {
        ...item,
        value: [value],
      });
    } else {
      _clonelist[groupIndex].splice(fieldIndex, 1, {
        ...item,
        value: [
          {
            ...item?.value?.[0],
            [type]: value?.currentTarget?.value,
          },
        ],
      });
    }
    onChangeFilterConditionGroupList(_clonelist);
  };

  const onChangeMultiChoice = (
    groupIndex: number,
    fieldIndex: number,
    data: any,
  ) => {
    const _clonelist = cloneDeep(filterConditionGroupList);
    const item = _clonelist?.[groupIndex]?.[fieldIndex];

    _clonelist[groupIndex].splice(fieldIndex, 1, {
      ...item,
      value: [
        {
          ...data,
        },
      ],
    });
    onChangeFilterConditionGroupList(_clonelist);
  };

  const onChangeRelation = (
    groupIndex: number,
    fieldIndex: number,
    fieldId?: any,
  ) => {
    const schema = fieldsListObj?.[fieldId] ?? {};
    setRelationSchema({
      ...schema,
    });
    setRelationItem({
      groupIndex,
      fieldIndex,
    });
    setVisibleRelation(true);
  };
  const handleConfirm = (formCode: any, data: any, bizType: string) => {
    if (data?.length) {
      const _clonelist = cloneDeep(filterConditionGroupList);
      const item =
        _clonelist?.[relationItem?.groupIndex]?.[relationItem?.fieldIndex] ??
        {};
      _clonelist[relationItem?.groupIndex]?.splice?.(
        relationItem?.fieldIndex,
        1,
        {
          ...item,
          value: [
            {
              title: data?.[0]?.title,
              instanceId: data?.[0]?.instanceId,
            },
          ],
        },
      );
      onChangeFilterConditionGroupList(_clonelist);
    }
  };
  let modalProps = {
    schema: {},
    visible: visibleRelation,
    setVisible: setVisibleRelation,
    handleConfirm,
    type: 'baseSelector',
    relationSchemaRef,
  };

  const onFinish = async () => {
    await form.validateFields();
    const { power, applicableMember, name, id, status } = form.getFieldsValue();
    const data: any = {
      filterConditionGroupList: filterConditionGroupList.map((i) => {
        return i?.map((item) => {
          const adaptValue = afterDealValue(item);
          return {
            fieldId: adaptValue?.fieldId,
            filterType: adaptValue?.filterType,
            value: adaptValue?.value,
            values: adaptValue?.values,
            extendValue: adaptValue?.extendValue,
            member: adaptValue?.member,
          };
        });
      }),
      name: name?.trim(),
      permissions: [power],
      applicableMember: {
        depts: applicableMember?.departments ?? [],
        roles: applicableMember?.roles ?? [],
        users: applicableMember?.users ?? [],
      },
      status: currentRule?.status ?? 0,
      formCode: formCode,
    };
    if (currentRule?.id) {
      data.id = currentRule?.id;
    }
    const isValid = checkValue(data?.filterConditionGroupList);
    if (!isValid) {
      return;
    }
    onChange?.(data);
  };

  const onChangePower = () => {};

  const onChangeMember = (data: any) => {
    form.setFieldsValue({
      applicableMember: data,
    });
  };

  /**
   * @description: 更新分组规则，同时更新form 和 filterConditionGroupList  保持数据的一致性
   * @return {*}
   */
  const onChangeFilterConditionGroupList = (data: any) => {
    setFilterConditionGroupList(data);
    form.setFieldsValue({
      filterConditionGroupList: data,
    });
  };

  const validator = [
    {
      validator: (rule: any, value: string) => {
        if (!value?.trim()) {
          return Promise.reject('请输入内容');
        }
        if (value?.trim()?.length > 20) {
          return Promise.reject('规则名称不能大于20个字符');
        }
        return Promise.resolve(value);
      },
    },
  ];
  const validatorByPeople = [
    {
      validator: (rule: any, value: string) => {
        const keys = Object.keys(value);
        let res = [];
        if (keys?.length) {
          res = keys.reduce((total, cur) => {
            if (value?.[cur]?.length) {
              total = total?.concat(value?.[cur]);
            }
            return total;
          }, []);
        }
        if (!res?.length) {
          return Promise.reject('请选择共享人员');
        }
        return Promise.resolve(value);
      },
    },
  ];

  const transformDataToDepts = (extendValue, obj, widgetName) => {
    if (widgetName === 'department') {
      obj['departments'] =
        extendValue?.depts?.map((dept) => {
          return {
            id: dept?.id,
            key: dept?.id,
            type: 'department',
            name: dept?.name,
            title: dept?.name,
            label: dept.name,
          };
        }) ?? [];
    } else {
      obj['departments'] =
        extendValue?.depts?.map((dept) => {
          return {
            id: dept?.id,
            key: dept?.id,
            type: 'department',
            name: dept?.name,
            title: dept?.name,
            label: dept.name,
          };
        }) ?? [];
      obj['users'] =
        extendValue?.users?.map((user) => {
          return {
            id: user?.id,
            key: user?.id,
            userId: user?.userId,
            type: 'employee',
            name: user?.name,
            title: user?.name,
            label: user?.name,
          };
        }) ?? [];
    }
    return obj;
  };

  /**
   * @description: 对数据进行预处理  有7种类型
   * SUPPORT_SHAREDATA_INPUT_WIDGETS
   * SUPPORT_SHAREDATA_SELECT_WIDGETS  产品分类暂时放select里面  应该归为cascade
   * SUPPORT_SHAREDATA_CASCADE_WIDGETS
   * SUPPORT_SHAREDATA_DATE_WIDGETS
   * SUPPORT_SHAREDATA_DIV_WIDGETS
   * SUPPORT_SHAREDATA_RELATION_WIDGETS
   * @return {*}
   * @param {*} j
   */
  const preDealValue = (j) => {
    if (!fieldsListObj?.[j?.fieldId]) {
      return {
        fieldId: '已删除或停用',
        filterType: null,
        extendValue: {},
        member: {},
        value: null,
        values: null,
        filterTypeArr: [],
        valueTree: [],
        valueArr: [],
      };
    }
    // 存在与不存在需要特殊处理
    if (j?.filterType === 'EXISTS' && j?.value === false) {
      j.filterType = 'NOTEXISTS';
    }
    const widgetName = fieldsListObj?.[j?.fieldId]?.widget;
    if (SUPPORT_SHAREDATA_INPUT_WIDGETS?.includes(widgetName)) {
      if (valuesType?.includes(j?.filterType)) {
        if (
          j?.filterType === 'BETWEEN' &&
          ['number', 'money', 'starRating']?.includes(widgetName)
        ) {
          j.value = [{ min: j?.values?.[0], max: j?.values?.[1] }];
        } else {
          j.value = [{ value: j?.values?.join(';') }];
        }
      } else {
        j.value = [{ value: j?.value }];
      }
    } else if (SUPPORT_SHAREDATA_SELECT_WIDGETS?.includes(widgetName)) {
      if (valuesType?.includes(j?.filterType)) {
        j.value = j?.values?.map((i) => {
          return {
            value: i,
          };
        });
      } else {
        j.value = [{ value: j?.value }];
      }
    } else if (SUPPORT_SHAREDATA_DATE_WIDGETS?.includes(widgetName)) {
      if (valuesType?.includes(j?.filterType)) {
        j.value = [...j?.values];
      } else {
        j.value = [j.value];
      }
    } else if (SUPPORT_SHAREDATA_DIV_WIDGETS?.includes(widgetName)) {
      j.value = [];
      const obj = {
        roles: [],
        users: [],
        departments: [],
      };
      j.value[0] = transformDataToDepts(j?.member ?? {}, obj, widgetName);
    } else if (SUPPORT_SHAREDATA_CASCADE_WIDGETS?.includes(widgetName)) {
      if (valuesType?.includes(j?.filterType)) {
        j.value = [j?.values ?? []];
      } else {
        j.value = [j.value];
      }
    } else if (SUPPORT_SHAREDATA_RELATION_WIDGETS?.includes(widgetName)) {
      j.value = [];
      j.value[0] = {
        ...j?.extendValue?.relation?.[0],
      };
    }

    j.filterTypeArr = SUPPORT_SHAREDATA_WIDGETS_TYPES?.[widgetName];
    j.valueTree =
      widgetName === 'cascade'
        ? cascadeTree
        : widgetName === 'cascadeV2' || widgetName === 'multiCascade'
        ? cascadeTreeV2?.[fieldsListObj?.[j?.fieldId]?.enumOptionId]
            ?.cascadeOptions
        : null;
    j.valueArr = getValueArrOptions(j?.fieldId);
    return j;
  };

  /**
   * @description: 对数据进行后置处理  有6种类型
   * SUPPORT_SHAREDATA_INPUT_WIDGETS
   * SUPPORT_SHAREDATA_SELECT_WIDGETS  产品分类暂时放select里面  应该归为cascade
   * SUPPORT_SHAREDATA_CASCADE_WIDGETS
   * SUPPORT_SHAREDATA_DATE_WIDGETS
   * SUPPORT_SHAREDATA_DIV_WIDGETS
   * SUPPORT_SHAREDATA_RELATION_WIDGETS
   * @return {*}
   * @param {*} j
   */
  const afterDealValue = (j) => {
    const valuesType = ['IN', 'BETWEEN', 'BELONG', 'NBELONG', 'NIN'];
    const widgetName = fieldsListObj?.[j?.fieldId]?.widget;
    if (['EXISTS', 'NOTEXISTS'].includes(j?.filterType)) {
      j.values = null;
      j.value = j?.filterType === 'EXISTS' ? true : false;
      j.filterType = 'EXISTS';
    } else if (SUPPORT_SHAREDATA_INPUT_WIDGETS?.includes(widgetName)) {
      if (valuesType?.includes(j?.filterType)) {
        if (
          j?.filterType === 'BETWEEN' &&
          ['number', 'money', 'starRating']?.includes(widgetName)
        ) {
          j.values = [j?.value?.[0]?.min?.trim(), j?.value?.[0]?.max?.trim()];
          j.value = null;
        } else {
          const arr = j?.value?.[0]?.value?.split(';');
          j.values = arr?.filter((i) => i?.trim?.());
          j.value = null;
        }
      } else {
        j.value = j?.value?.[0]?.value?.trim();
        j.values = null;
      }
    } else if (SUPPORT_SHAREDATA_SELECT_WIDGETS?.includes(widgetName)) {
      if (valuesType?.includes(j?.filterType)) {
        j.values = j?.value?.map((i) => i?.value);
        j.value = null;
      } else {
        j.value = j?.value?.[0]?.value;
        j.values = null;
      }
    } else if (SUPPORT_SHAREDATA_DIV_WIDGETS?.includes(widgetName)) {
      j.extendValue = {
        depts: [],
        roles: [],
        users: [],
      };
      if (widgetName === 'department') {
        j.extendValue.depts = j?.value?.[0]?.['departments']?.map((i) => {
          return {
            id: i?.id,
            key: i?.id,
            type: 'department',
            name: i?.name,
            title: i?.name,
            label: i.name,
          };
        });
      } else {
        j.extendValue.depts = j?.value?.[0]?.['departments']?.map((i) => {
          return {
            id: i?.id,
            key: i?.id,
            type: 'department',
            name: i?.name,
            title: i?.name,
            label: i.name,
          };
        });
        j.extendValue.users = j?.value?.[0]?.['users']?.map((i) => {
          return {
            id: i?.id,
            key: i?.id,
            userId: i?.userId,
            type: 'employee',
            name: i?.name,
            title: i?.name,
            label: i?.name,
          };
        });
      }
      const tempData = j?.value?.[0]?.['departments']
        ?.concat(j?.value?.[0]?.['users'])
        ?.map((i) => i?.userId || i?.id);
      if (valuesType?.includes(j?.filterType)) {
        j.values = tempData;
        j.value = null;
      } else {
        j.value = tempData?.[0];
        j.values = null;
      }
      j.member = {
        ...j?.extendValue,
      };
    } else if (SUPPORT_SHAREDATA_DATE_WIDGETS?.includes(widgetName)) {
      if (j?.value?.length == 2) {
        j.values = [...j?.value];
        j.value = null;
      } else {
        j.value = j.value?.[0];
        j.values = null;
      }
    } else if (SUPPORT_SHAREDATA_CASCADE_WIDGETS?.includes(widgetName)) {
      if (valuesType?.includes(j?.filterType)) {
        j.values = [...j?.value?.[0]];
        j.value = null;
      } else {
        j.value = j.value?.[0];
        j.valus = null;
      }
    } else if (SUPPORT_SHAREDATA_RELATION_WIDGETS?.includes(widgetName)) {
      j.extendValue = {
        relation: [],
      };
      j.extendValue.relation[0] = {
        ...j.value?.[0],
      };
      j.value = j.value?.[0]?.instanceId;
      j.values = null;
    }
    return j;
  };
  /**
   * @description: 对共享规则进行校验  通过才保存
   * @return {*}
   * @param {*} arr
   */
  const checkValue = (arr) => {
    const requireList = [];
    if (!arr || !arr?.length) {
      requireList.push({
        key: 'formDesign',
        label: '数据来源',
        message: '必须设置, 不能为空',
      });
    }
    const validValue = (item) => {
      if (item?.filterType && valuesType?.includes(item?.filterType)) {
        const len = item?.values?.filter((i) => !!i || i == 0)?.length;
        if (
          !item?.values ||
          len === 0 ||
          (item?.values?.length && item?.values?.length !== len)
        ) {
          return true;
        }
      } else if (
        item?.filterType &&
        !['EXISTS', 'NOTEXISTS'].includes(item?.filterType)
      ) {
        if (
          (typeof item?.value === 'string' && !item?.value?.trim()) ||
          (typeof item?.value === 'object' && !item?.value?.length)
        ) {
          return true;
        }
      }
      return false;
    };
    arr?.forEach((item, index) => {
      if (!item || !item?.length) {
        requireList.push({
          key: 'formDesign',
          label: `第${index + 1}组数据来源`,
          message: '不能为空',
        });
      }
      item?.forEach((itemJ, indexJ) => {
        if (!Object.keys(itemJ)?.length) {
          requireList.push({
            key: 'formDesign',
            label: `第${index + 1}组数据来源第${indexJ + 1}个组件`,
            message: '为空',
          });
        }
        if (!fieldsListObj?.[itemJ?.fieldId]) {
          requireList.push({
            key: 'formDesign',
            label: `第${index + 1}组数据来源第${indexJ + 1}个组件`,
            message: '已停用或已被删除',
          });
        }
        if (!itemJ?.filterType) {
          requireList.push({
            key: 'formDesign',
            label: `第${index + 1}组数据来源第${indexJ + 1}个组件`,
            message: '筛选条件不能为空',
          });
        }
        if (
          validValue(itemJ) &&
          !['NOTEXISTS', 'EXISTS']?.includes(itemJ?.filterType)
        ) {
          requireList.push({
            key: 'formDesign',
            label: `第${index + 1}组数据来源第${indexJ + 1}个组件`,
            message: '设置的值不能为空',
          });
        }
      });
    });
    if (requireList.length) {
      Modal.error({
        title: '当前共享规则无法保存',
        content: <ModalContent list={requireList} />,
        okText: '知道了',
      });
    }
    return requireList?.length ? false : true;
  };

  const fieldsListObj = useMemo(() => {
    if (!fieldsList?.length) return {};
    return fieldsList?.reduce((total, cur) => {
      total[cur?.id] = cur;
      return total;
    }, {});
  }, [fieldsList]);

  useEffect(() => {
    if (!currentRule || !currentRule?.id) {
      const value = {
        ...initItem,
        power: 'read',
      };
      setFilterConditionGroupList(value?.filterConditionGroupList);
      form.setFieldsValue(value);
    } else {
      // 初始化共享权限组
      const keys = Object.keys(currentRule?.applicableMember ?? {});
      let obj = {
        users: [],
        roles: [],
        departments: [],
      };
      if (keys?.length) {
        keys?.forEach((i) => {
          if (i === 'users') {
            obj.users = currentRule?.applicableMember?.[i].map((user) => {
              return {
                id: user?.id,
                key: user?.id,
                userId: user?.userId,
                type: 'employee',
                name: user?.name,
                title: user?.name,
                label: user?.name,
              };
            });
          } else if (i === 'roles') {
            obj.roles = currentRule?.applicableMember?.[i].map((role) => {
              return {
                id: role?.id,
                key: role?.id,
                type: 'role',
                name: role?.name,
                title: role?.name,
                label: role?.name,
              };
            });
          } else if (i === 'depts') {
            obj.departments = currentRule?.applicableMember?.[i].map((dept) => {
              return {
                id: dept?.id,
                key: dept?.id,
                type: 'department',
                name: dept?.name,
                title: dept?.name,
                label: dept.name,
              };
            });
          }
        });
      }
      // 初始化共享规则
      let filterConditionGroupList = cloneDeep(
        currentRule?.filterConditionGroupList ?? [],
      );
      if (filterConditionGroupList?.flat()?.length) {
        filterConditionGroupList = filterConditionGroupList?.map((i) => {
          return i.map((j) => {
            const adapValue = preDealValue(j);
            return {
              fieldId: adapValue?.fieldId,
              filterType: adapValue?.filterType,
              value: adapValue?.value,
              values: adapValue?.values,
              filterTypeArr: adapValue?.filterTypeArr,
              valueTree: adapValue?.valueTree,
              valueArr: adapValue?.valueArr,
            };
          });
        });
      }
      const value = {
        ...currentRule,
        applicableMember: {
          ...obj,
        },
        filterConditionGroupList: filterConditionGroupList,
        power: currentRule?.permissions[0],
      };
      setFilterConditionGroupList(value?.filterConditionGroupList);
      form.setFieldsValue(value);
    }
  }, [currentRule, fieldsList]);

  return (
    <>
      <Modal
        title={curStatus ? '编辑共享规则' : '新建共享规则'}
        visible={visible}
        onOk={onFinish}
        onCancel={hideModal}
        okText="确认"
        width={800}
        cancelText="取消"
        destroyOnClose
        className={c.modal}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <Form layout="vertical" form={form}>
          <Form.Item label="规则名称" name="name" required rules={validator}>
            <Input placeholder="请输入" maxLength={20} showCount />
          </Form.Item>
          <Form.Item label="选择数据来源" name="filterConditionGroupList">
            <div className="crm-duplicate-fields-group">
              <div className="crm-duplicate-fields-group__condition">
                {filterConditionGroupList?.map(
                  (condition, groupIndex: number) => {
                    // const isSelectedFieldList = condition?.map((item) => item.id);
                    return (
                      <React.Fragment key={groupIndex}>
                        <div className="crm-duplicate-fields-group__condition-item">
                          <div
                            className="l_title"
                            style={{ width: '120px', flexShrink: 0 }}
                          >
                            且（满足所有项）
                          </div>
                          <div className="r_condition_wrap">
                            {condition?.map((field, fieldInd: number) => (
                              <div
                                className="r_condition-item"
                                key={field?.fieldId}
                              >
                                <span className="r_condition-item-line"></span>
                                <Select
                                  placeholder="请选择"
                                  value={field?.fieldId}
                                  virtual={false}
                                  placement="bottomLeft"
                                  onChange={(val, option) =>
                                    onHandleChange(groupIndex, fieldInd, option)
                                  }
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentElement
                                  }
                                  style={{
                                    width: '120px',
                                    marginRight: '8px',
                                    flexShrink: 0,
                                  }}
                                >
                                  {fieldsList?.map((item) => (
                                    <Option
                                      // disabled={selectFields?.includes(item?.id)}
                                      value={item?.id}
                                      key={item?.id}
                                    >
                                      {item.title}
                                    </Option>
                                  ))}
                                </Select>
                                <Select
                                  placeholder="请选择"
                                  value={field?.filterType}
                                  virtual={false}
                                  placement="bottomLeft"
                                  onChange={(val, option) => {
                                    onHandleChangeFilterType(
                                      groupIndex,
                                      fieldInd,
                                      option,
                                    );
                                  }}
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentElement
                                  }
                                  style={{ width: '90px', marginRight: '8px' }}
                                >
                                  {field?.filterTypeArr?.map((item) => (
                                    <Option
                                      value={item?.value}
                                      key={item?.value}
                                    >
                                      {item?.label}
                                    </Option>
                                  ))}
                                </Select>
                                {['NOTEXISTS', 'EXISTS'].includes(
                                  field?.filterType,
                                ) ? null : ['BETWEEN'].includes(
                                    field?.filterType,
                                  ) &&
                                  !SUPPORT_SHAREDATA_DATE_WIDGETS?.includes(
                                    fieldsListObj?.[field?.fieldId]?.widget,
                                  ) ? (
                                  <div
                                    style={{
                                      width: '304px',
                                      marginRight: '10px',
                                      flexShrink: 0,
                                      display: 'flex',
                                    }}
                                  >
                                    <Input
                                      style={{
                                        width: '140px',
                                        marginRight: '10px',
                                      }}
                                      placeholder="最小值"
                                      value={field?.value?.[0]?.min}
                                      onChange={(val) => {
                                        onChangeValue(
                                          groupIndex,
                                          fieldInd,
                                          val,
                                          'min',
                                        );
                                      }}
                                    />
                                    <Input
                                      style={{
                                        width: '140px',
                                        marginRight: '10px',
                                      }}
                                      placeholder="最大值"
                                      value={field?.value?.[0]?.max}
                                      onChange={(val) => {
                                        onChangeValue(
                                          groupIndex,
                                          fieldInd,
                                          val,
                                          'max',
                                        );
                                      }}
                                    />
                                  </div>
                                ) : SUPPORT_SHAREDATA_CASCADE_WIDGETS.includes(
                                    fieldsListObj?.[field?.fieldId]?.widget,
                                  ) ? (
                                  <TreeSelect
                                    showSearch
                                    style={{
                                      width: '304px',
                                      marginRight: '10px',
                                    }}
                                    fieldNames={{
                                      label: 'name',
                                      value: 'id',
                                      children: 'children',
                                    }}
                                    treeNodeLabelProp={
                                      field?.valueTree?.[0]?.fullNamePath
                                        ? 'fullNamePath'
                                        : 'name'
                                    }
                                    multiple={
                                      fieldsListObj?.[
                                        field?.fieldId
                                      ]?.widget?.includes('multiCascade') ||
                                      (fieldsListObj?.[
                                        field?.fieldId
                                      ]?.widget?.includes('cascade') &&
                                        ['IN']?.includes(field?.filterType))
                                        ? true
                                        : false
                                    }
                                    value={field?.value?.[0]}
                                    dropdownStyle={{
                                      maxHeight: 300,
                                      overflow: 'auto',
                                    }}
                                    placeholder="请选择选项"
                                    allowClear
                                    onChange={(val, label, extra) => {
                                      onChangeValue(
                                        groupIndex,
                                        fieldInd,
                                        val,
                                        '',
                                      );
                                    }}
                                    treeData={field?.valueTree ?? []}
                                  />
                                ) : SUPPORT_SHAREDATA_INPUT_WIDGETS.includes(
                                    fieldsListObj?.[field?.fieldId]?.widget,
                                  ) ? (
                                  <Input
                                    style={{
                                      width: '304px',
                                      marginRight: '10px',
                                    }}
                                    placeholder={
                                      [
                                        'BELONG',
                                        'NBELONG',
                                        'IN',
                                        'NIN',
                                      ]?.includes(field?.filterType)
                                        ? '多值用英文;隔开'
                                        : '请选择'
                                    }
                                    value={field?.value?.[0]?.value}
                                    onChange={(val) => {
                                      onChangeValue(
                                        groupIndex,
                                        fieldInd,
                                        val,
                                        'value',
                                      );
                                    }}
                                  />
                                ) : SUPPORT_SHAREDATA_SELECT_WIDGETS.includes(
                                    fieldsListObj?.[field?.fieldId]?.widget,
                                  ) ? (
                                  <Select
                                    style={{
                                      width: '304px',
                                      marginRight: '10px',
                                    }}
                                    options={field?.valueArr ?? []}
                                    mode={
                                      field?.fieldId?.includes('multiple') ||
                                      [
                                        'BELONG',
                                        'NBELONG',
                                        'IN',
                                        'NIN',
                                      ]?.includes(field?.filterType)
                                        ? 'multiple'
                                        : undefined
                                    }
                                    placeholder="请选择"
                                    value={
                                      field?.fieldId?.includes('multiple') ||
                                      [
                                        'BELONG',
                                        'NBELONG',
                                        'IN',
                                        'NIN',
                                      ]?.includes(field?.filterType)
                                        ? field?.value
                                        : field?.value?.[0]
                                    }
                                    onChange={(val) => {
                                      onChangeSelect(groupIndex, fieldInd, val);
                                    }}
                                  />
                                ) : SUPPORT_SHAREDATA_DATE_WIDGETS.includes(
                                    fieldsListObj?.[field?.fieldId]?.widget,
                                  ) &&
                                  ['BETWEEN']?.includes(field?.filterType) ? (
                                  <RangePicker
                                    style={{
                                      width: '304px',
                                      marginRight: '10px',
                                    }}
                                    allowClear
                                    showTime={{ format: 'HH:mm' }}
                                    format="YYYY-MM-DD HH:mm"
                                    defaultValue={[
                                      transformDateToMoment(field?.value?.[0]),
                                      transformDateToMoment(field?.value?.[1]),
                                    ]}
                                    placeholder={['开始日期', '结束日期']}
                                    onChange={(value, dateString) => {
                                      onChangeData(
                                        groupIndex,
                                        fieldInd,
                                        dateString,
                                      );
                                    }}
                                  />
                                ) : SUPPORT_SHAREDATA_DATE_WIDGETS.includes(
                                    fieldsListObj?.[field?.fieldId]?.widget,
                                  ) &&
                                  !['BETWEEN']?.includes(field?.filterType) ? (
                                  <DatePicker
                                    style={{
                                      width: '304px',
                                      marginRight: '10px',
                                    }}
                                    allowClear
                                    showTime
                                    defaultValue={transformDateToMoment(
                                      field?.value?.[0],
                                    )}
                                    placeholder={'请选择日期'}
                                    format={'YYYY-MM-DD HH:mm:ss'}
                                    onChange={(e) => {
                                      const dateString =
                                        transformDateToString(e);
                                      onChangeData(
                                        groupIndex,
                                        fieldInd,
                                        dateString,
                                      );
                                    }}
                                  />
                                ) : SUPPORT_SHAREDATA_DIV_WIDGETS.includes(
                                    fieldsListObj?.[field?.fieldId]?.widget,
                                  ) ? (
                                  <div
                                    style={{
                                      width: '304px',
                                      marginRight: '10px',
                                    }}
                                  >
                                    <ShowUserOrDept
                                      {...props}
                                      type={field?.fieldId}
                                      hiddenDynamicPrinciple={false}
                                      placeholder={'请选择'}
                                      multiple={
                                        [
                                          'BELONG',
                                          'NBELONG',
                                          'IN',
                                          'NIN',
                                        ]?.includes(field?.filterType)
                                          ? true
                                          : false
                                      }
                                      value={field?.value?.[0]}
                                      onChange={(data: any) => {
                                        onChangeMultiChoice(
                                          groupIndex,
                                          fieldInd,
                                          data,
                                        );
                                      }}
                                    />
                                  </div>
                                ) : SUPPORT_SHAREDATA_RELATION_WIDGETS.includes(
                                    fieldsListObj?.[field?.fieldId]?.widget,
                                  ) ? (
                                  <Input
                                    style={{
                                      width: '304px',
                                      marginRight: '10px',
                                    }}
                                    placeholder="请选择"
                                    value={field?.value?.[0]?.title}
                                    onClick={() => {
                                      onChangeRelation(
                                        groupIndex,
                                        fieldInd,
                                        field?.fieldId,
                                      );
                                    }}
                                  />
                                ) : (
                                  <Input
                                    style={{
                                      width: '304px',
                                      marginRight: '10px',
                                    }}
                                    placeholder="请选择"
                                    value={''}
                                    onChange={(val) => {}}
                                  />
                                )}
                                <IconFont
                                  type="icon-delete-line"
                                  onClick={() =>
                                    deleteConditionField(groupIndex, fieldInd)
                                  }
                                  className="r_condition-item-icon-delete"
                                />
                              </div>
                            ))}
                            {condition?.length < 5 && (
                              <div
                                className={classnames(
                                  'r_condition-add',
                                  'r_condition-item',
                                )}
                              >
                                <span className="r_condition-item-line"></span>
                                <IconFont
                                  type="icon-add-line"
                                  onClick={() => addConditionField(groupIndex)}
                                  className="r_condition-item-icon-add"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {groupIndex !== filterConditionGroupList.length - 1 && (
                          <Divider
                            dashed={true}
                            style={{ color: 'rgba(126, 134, 142, 0.24)' }}
                          >
                            <div className="crm-duplicate-fields-group__condition-divider">
                              或（满足任意项）
                            </div>
                          </Divider>
                        )}
                      </React.Fragment>
                    );
                  },
                )}
              </div>
              {filterConditionGroupList.length < 3 && (
                <div>
                  <Button onClick={addConditionGroup}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconFont type="icon-add1-line" />
                      <span>添加条件组</span>
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </Form.Item>
          <Form.Item
            label="共享给谁"
            name="applicableMember"
            required
            rules={validatorByPeople}
          >
            <ShowUserOrDept
              {...props}
              hiddenDynamicPrinciple={false}
              onChangeMember={onChangeMember}
            />
          </Form.Item>
          <Form.Item label="权限" name="power" required>
            <Radio.Group onChange={onChangePower} className={c.inputHeightFix}>
              <Radio value={'read'}>只读</Radio>
              <Radio value={'write'}>
                可操作
                <span className={c.tipContent}>
                  （范围取决于权限组-操作权限、字段权限）
                </span>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
      {visibleRelation && (
        <SelectorModal
          {...modalProps}
          {...relationSchema}
          schema={relationSchema}
        />
      )}
    </>
  );
};
