/*
 * :file description:
 * :name: /low-code-platform/tg-renderer/packages/form-render/src/widgets/SelectOptions/Column.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-29 16:26:09
 * :last editor: caihua
 * :date last edited: 2023-08-08 11:21:13
 */
import React, { useRef, useState } from 'react';
import { Input, Space, Popover, Tooltip } from 'antd';
import IconFont from '@/components/IconFont';
import c from './index.less';

const grid = 8;
const COLOR_ENUM = [
  '#FF6069',
  '#FF9622',
  '#FFBA21',
  '#2FC251',
  '#14CCC6',
  '#0088FF',
  '#7570FF',
  '#9F58F5',
  '#FF47A3',
  '#7581A2',
];

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  ...draggableStyle,
});

const Column = ({
  draggableProvided,
  draggableSnapshot,
  item,
  onChange,
  onDelete,
  hasColorAuth,
  isDragDisabled,
}) => {
  const [clicked, setClicked] = useState(false);
  const [hovered, setHovered] = useState(false);
  const mainEle = useRef<any>(null);

  const handleHoverChange = (open: boolean) => {
    setHovered(open);
    setClicked(false);
  };

  const handleClickChange = (open: boolean) => {
    setHovered(false);
    setClicked(open);
  };

  const content = (item: any) => {
    return (
      <div className={c.columPopover}>
        {COLOR_ENUM.map((val) => (
          <div
            style={{ background: val }}
            onClick={(e) => {
              setClicked(false);
              setHovered(false);
              onChange([val], item.value, 'color');
            }}
            className={c.popoverColor}
          >
            {val === item?.enumAssociatedFieldsColors?.[0] && (
              <IconFont
                className={c.popoverIconFont}
                size={8}
                color="#fff"
                type="icon-tick-line"
              ></IconFont>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={c.cloumn}
      ref={draggableProvided.innerRef}
      {...draggableProvided.draggableProps}
      {...draggableProvided.dragHandleProps}
      style={getItemStyle(
        draggableSnapshot.isDragging,
        draggableProvided.draggableProps.style,
      )}
    >
      <div ref={mainEle}>
        <Space>
          <IconFont
            type="icon-tuozhuai"
            style={{ color: isDragDisabled ? '#fff' : 'inherit' }}
          ></IconFont>
          <Input
            onChange={(e) => onChange(e.target.value, item.value, 'text')}
            style={{ width: '213px' }}
            value={item.label}
            disabled={isDragDisabled}
            suffix={
              hasColorAuth ? (
                <Tooltip
                  overlayStyle={{ width: 114 }}
                  overlayInnerStyle={{
                    borderRadius: '6px',
                    background: 'rgba(0,0,0,0.75)',
                    boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.16)',
                  }}
                  title={'点击可更改色彩'}
                  trigger="hover"
                  open={hovered}
                  onOpenChange={handleHoverChange}
                  getPopupContainer={() => mainEle.current}
                >
                  <Popover
                    content={content(item)}
                    trigger="click"
                    open={clicked}
                    placement="bottomLeft"
                    overlayClassName="selectColorPopover"
                    onOpenChange={handleClickChange}
                    getPopupContainer={() => mainEle.current}
                  >
                    <div className={c.columColorWrap}>
                      <div
                        style={{
                          background:
                            item?.enumAssociatedFieldsColors?.[0] || '#0088FF',
                        }}
                        className={c.columColor}
                      />
                    </div>
                  </Popover>
                </Tooltip>
              ) : null
            }
          />
          {!isDragDisabled && (
            <div className={c.delete}>
              <IconFont
                onClick={(e) => onDelete(item.value)}
                type="icon-shanchu"
                disabled
              ></IconFont>
            </div>
          )}
        </Space>
      </div>
    </div>
  );
};

export default Column;
