/*
 * :file description:
 * :name: \low-code-platform\src\pages\generator\form\components\ModalContent\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-23 17:02:19
 * :last editor: 黄艺
 * :date last edited: 2021-10-28 14:10:02
 */
import { FC } from 'react';
import { IRequireItem } from '../..';
import c from './index.less';

interface IProps {
  list: IRequireItem[];
}

const ModalContent: FC<IProps> = ({ list }) => {
  return (
    <div className={c.container}>
      <div className={c.title}>以下内容不完善，请修改后保存</div>
      <ul>
        {list?.map((item) => (
          <li key={item.key}>
            <span>{item.label}</span>
            <span>{item.message}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ModalContent;
