/*
 * :file description:
 * :name: \low-code-platform\src\pages\form\components\ModalImport\ImportStep2\constant.js
 * :author: PakJeon
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-14 16:10:55
 * :last editor: PakJeon
 * :date last edited: 2021-12-18 10:41:18
 */
export const IMPORT_MODAL_TEXT = {
  0: {
    title: '确定导入并新增数据？',
    updateTips: '',
    triggerTips: '导入完成后将逐条触发审批流程，请稍后查看',
  },
  1: {
    title: '确定导入并更新数据？',
    updateTips: '导入的数据中「数据id」匹配不上、为空时，不更新数据',
    triggerTips: '',
  },
  2: {
    title: '确定导入并更新和新增数据？',
    updateTips:
      '导入的数据中「数据id」匹配不上时，不更新数据；若「数据id」为空时，则会新增数据',
    triggerTips: '新增的数据导入完成后，将逐条触发审批流程，请稍后查看',
  },
};

// 关联表单 可选主键 单行输入框、数字输入框、单选框、日期、电话、金额、流水号、成员、部门
export const RELATION_COL_VALID_WIDGET = [
  'input',
  'number',
  'select',
  'date',
  'phone',
  'money',
  'seqNumber',
  'innerContact',
  'department',
];
