/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DefaultValueSelector\components\formula-editor\EditArea.tsx
 * :author: 杨海涛
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-08-08 15:36:32
 * :last editor: 杨海涛
 * :date last edited: 2022-08-08 18:26:03
 */
require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');
require('codemirror/theme/neat.css');
require('codemirror/mode/xml/xml.js');
require('codemirror/mode/javascript/javascript.js');
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint';
import { useContext, useEffect, useState, useMemo } from 'react';
import { useLocation, useModel } from 'umi';
import { useDebounceFn } from 'ahooks';
import CodeMirror, { Editor } from 'codemirror';
import { Controlled as ReactCodeMirror } from 'react-codemirror2';
import './fomula-hint.js';
import { EditorContext } from './index';
import {
  Backspace,
  insertBarcket,
  setDefaultValue,
} from './common/formulaEditor';
import { formFieldObjToList, Searcher } from './common/utils';
import formulaUsage, { formulaUsageVersion23 } from './common/formulaUsage';
import { CloseOutlined } from '@ant-design/icons';

import c from './index.less';

interface IFlatten {
  children: string[];
  data: unknown;
  parent: string;
  formCode: string;
  $title: string;
  formName: string;
  parentTitle: string;
  schema: any;
}

const options = {
  keywords: [],
  textWrapping: !0,
  lineWrapping: !0,
  lineNumbers: !1,
  matchBrackets: !0,
  // autofocus: true,
  specialChars: /[\u0000-\u001f\u007f\u00ad\u200c-\u200f\u2028\u2029\ufeff]/,
  mode: 'formula',
};

const EditArea = (props: any) => {
  const {
    setEditorInstance,
    flatten,
    fillValue,
    isEdit,
    updateContent,
    setUpdateContent,
    setErrorField,
  } = useContext(EditorContext);

  const handleChange = (cm, event) => {
    if (event.origin !== 'complete') {
      //未选中
      cm.showHint({
        hint: CodeMirror.hint.formula,
        completeSingle: false,
        shown: function () {
          console.log('显示了');
        },
        select: function (cpt, ele) {
          console.log(cpt, ele);
        },
        pick: function (item) {
          console.log(item);
        },
      });
    } else {
      insertBarcket(cm);
    }
  };

  const setEditorValue = (editor: Editor) => {
    setDefaultValue(fillValue, flatten, editor, isEdit, setErrorField);
  };

  const onEditorMount = (editor: Editor) => {
    setEditorInstance(editor);
    setEditorValue(editor);
    editor.addKeyMap({
      Backspace,
    });
  };

  return (
    <>
      <ReactCodeMirror
        className={c.codemirror}
        editorDidMount={(editor) => {
          onEditorMount(editor);
        }}
        value={updateContent}
        options={options}
        onBeforeChange={(editor, data, value) => {
          setUpdateContent(value);
        }}
        onChange={(editor, data, value) => {
          handleChange(editor, data);
        }}
      />
    </>
  );
};

export default EditArea;
