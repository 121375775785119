/*
 * :file description:
 * :name: \low-code-platform\src\pages\form\components\ModalImport\index.tsx
 * :author: PakJeon
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-01 11:00:03
 * :last editor: hyw
 * :date last edited: 2022-03-21 15:33:08
 */
import React, { useRef, useState, useContext } from 'react';
import { Button, Modal, Steps } from 'antd';
import IconFont from '@/components/IconFont';
import { connect, GlobalModelState } from 'umi';
import type { Dispatch } from 'umi';
import cx from 'classnames';
import c from './index.less';
import { FormConfigCtx } from '../../[id]';
import ImportStep1, { OptionType, options as ModeOptions } from './ImportStep1';
import ImportStep2 from './ImportStep2';
import ImportStep3 from './ImportStep3';

const { Step } = Steps;

enum RENDER_STEP {
  STEP1,
  STEP2,
  STEP3,
}

interface ModalImportProps {
  dispatch: Dispatch;
  visible: boolean;
  formCode: string;
  onClose: () => void;
  onFinish: () => void;
}

const ModalImport = (props: ModalImportProps, ref: any) => {
  const { visible, formCode, onClose, onFinish, dispatch } = props;
  const formConfigCtx = useContext(FormConfigCtx);

  const [isMaxSize, setIsMaxSize] = useState(false);
  const [currentStep, setCurrentStep] = useState(RENDER_STEP.STEP1);

  const step2Ref = useRef();
  const step3Ref = useRef();
  const [mode, setMode] = useState<OptionType>(ModeOptions[0]); // 导入模式
  const [trigger, setTrigger] = useState(false); // 是否触发审批流程
  const [fileData, setFileData] = useState(null); // 上传文件到oss返回的文件相关信息
  const [previewData, setPreviewData] = useState(null); // 上传文件后解析返回的预览数据

  const reset = () => {
    setCurrentStep(RENDER_STEP.STEP1);
    setMode(ModeOptions[0]);
    setTrigger(false);
    setFileData(null);
    setPreviewData(null);
  };

  const handleUploadSuccess = (data: any) => {
    setFileData(data);
  };

  const handleImportSuccess = () => {
    setCurrentStep(RENDER_STEP.STEP3);
    step3Ref.current.loop();
  };

  const handleImportFail = () => {
    reset();
  };

  const handlePreview = (data: any) => {
    setPreviewData(data);
    setCurrentStep(RENDER_STEP.STEP2);
  };

  const handleImport = () => {
    step2Ref.current.import();
  };

  const renderTitle = () => {
    return (
      <div className={c.titleWrapper}>
        <div className={c.modalTitle}>
          {formConfigCtx ? `导入${formConfigCtx.title}` : '导入Excel数据'}
        </div>
        <div className={c.operateBtn}>
          <IconFont
            className={c.sizeBtn}
            onClick={() => setIsMaxSize(!isMaxSize)}
            type={isMaxSize ? 'icon-zuidahua' : 'icon-zuidahua_1'}
          />
          <IconFont
            className={c.closeBtn}
            onClick={() => {
              reset();
              onClose();
              dispatch({
                type: 'global/toggleNotification',
                payload: { toggle: true },
              });
            }}
            type="icon-Close"
          />
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className={c.footerRow}>
        <div className={c.btnWrapper}>
          {currentStep === RENDER_STEP.STEP2 && (
            <>
              <Button
                onClick={() => {
                  setCurrentStep(RENDER_STEP.STEP1);
                  setPreviewData(null);
                }}
              >
                上一步
              </Button>
              <Button type="primary" onClick={handleImport}>
                导入
              </Button>
            </>
          )}
          {currentStep === RENDER_STEP.STEP3 && (
            <Button
              type="primary"
              onClick={() => {
                dispatch({
                  type: 'global/toggleNotification',
                  payload: { toggle: true },
                });
                reset();
                onFinish();
                onClose();
              }}
            >
              完成
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      width={isMaxSize ? '100vw' : 684}
      className={cx(
        c.modalImport,
        currentStep === RENDER_STEP.STEP1 ? c.hideModalFooter : null,
        isMaxSize && c.fullScreen,
      )}
      visible={visible}
      title={renderTitle()}
      footer={renderFooter()}
      closable={false}
      closeIcon={<IconFont type="icon-Close" />}
    >
      {[RENDER_STEP.STEP1, RENDER_STEP.STEP2].includes(currentStep) ? (
        <div className={c.stepWrapper}>
          <div className={c.stepHeader}>
            <Steps current={currentStep}>
              <Step title="选择Excel" />
              <Step title="配置表单字段" />
            </Steps>
          </div>
          {currentStep === RENDER_STEP.STEP1 && (
            <ImportStep1
              selectMode={mode}
              onSelectMode={(m) => {
                if (m.key === ModeOptions[1].key) {
                  setTrigger(false);
                }
                setMode(m);
              }}
              onUploadSuccess={(data: any) => handleUploadSuccess(data)}
              onPreview={(data: any) => handlePreview(data)}
              trigger={trigger}
              onTriggerChange={(e) => setTrigger(e)}
            />
          )}
          {currentStep === RENDER_STEP.STEP2 && (
            <ImportStep2
              selectMode={mode}
              fileData={fileData}
              previewData={previewData}
              ref={step2Ref}
              triggerProcess={trigger}
              onImportSuccess={handleImportSuccess}
              onImportFail={handleImportFail}
            />
          )}
        </div>
      ) : (
        <ImportStep3
          ref={step3Ref}
          fileData={fileData}
          onFail={() => {
            setCurrentStep(RENDER_STEP.STEP1);
            reset();
          }}
        />
      )}
    </Modal>
  );
};

export default connect((global: GlobalModelState) => global)(ModalImport);
