import { DatePicker, DatePickerProps } from 'antd';
import moment from 'moment';
import { transformDateToString, transformDateToMoment } from '../../utils';
import c from './index.less';

// 日期格式化
const DEFAULT_FORMAT = 'YYYY-MM-DD';
const dateFormat = (date: any, format = DEFAULT_FORMAT) => {
  return moment(date, format);
};

const DatePickerWidget = (props: DatePickerProps & { [key: string]: any }) => {
  const { value, schema, onChange } = props;
  const { placeholder, format } = schema;

  const handleChange = (e) => {
    onChange(transformDateToString(e, format));
  };

  if (props.readOnly) {
    return <span className="showNoEditText">{props.value || ''}</span>;
  }

  return (
    <DatePicker
      style={{ width: '100%' }}
      value={transformDateToMoment(value)}
      onChange={(e) => handleChange(e)}
      placeholder={placeholder}
      showTime={format === 'dateTime'}
    />
  );
};

export default DatePickerWidget;
