/*
 * :file description:
 * :name: \commond:\progree\low-code-platform\src\pages\generator\process\components\FlowV3\constants.ts
 * :author: Quan
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-10 14:35:23
 * :last editor: hyw
 * :date last edited: 2021-12-29 20:05:43
 */
import { getNodeUuid } from '@/utils';
export const APPLY_NODE = 'ApplyNode'; // 发起节点
export const APPROVAL_NODE = 'ApprovalNode'; //审核节点
export const CARBON_NODE = 'CarbonNode'; //抄送节点
export const CONDITION_NODE = 'ConditionNode'; //条件节点
export const INCLUSIVE_CONDITION_NODE = 'InclusiveConditionNode'; //并行节点
export const CONDITION_CONTAINER_NODE = 'ConditionContainerNode'; //条件网关节点
export const INCLUSIVE_CONDITION_CONTAINER_NODE =
  'InclusiveConditionContainerNode'; //条件网关节点 并行条件容器
export const INCLUSIVE_CONDITION_CONTAINER_NODE_END =
  'InclusiveConditionContainerNodeEnd';

export const COMMON_NODE_TYPE_MAP = {
  APPLY_NODE: APPLY_NODE,
  APPROVAL_NODE: APPROVAL_NODE,
  CARBON_NODE: CARBON_NODE,
  CONDITION_NODE: CONDITION_NODE,
  INCLUSIVE_CONDITION_NODE: INCLUSIVE_CONDITION_NODE,
  INCLUSIVE_CONDITION_CONTAINER_NODE_END:
    INCLUSIVE_CONDITION_CONTAINER_NODE_END,
};

export const COMMON_NODE_TYPE_LIST = [
  APPLY_NODE,
  APPROVAL_NODE,
  CARBON_NODE,
  CONDITION_NODE,
  INCLUSIVE_CONDITION_NODE,
  INCLUSIVE_CONDITION_CONTAINER_NODE_END,
];

export const NODE_TYPE_MAP = {
  node: 'node',
  container: 'container',
};

export const DEFAULT_CONFIG = (config: any = null) => {
  return {
    [APPROVAL_NODE]: {
      children: null,
      componentName: 'ApprovalNode',
      id: getNodeUuid(),
      // title: '审核人',
      props: {
        name: '审核人',
        nodeName: 'ApprovalNode',
        approvalType: '2',
        approverRules: {
          approvals: [],
        },
      },
    },
    [CARBON_NODE]: {
      children: null,
      componentName: 'CarbonNode',
      id: getNodeUuid(),
      // title: '抄送人',
      props: {
        name: '抄送人',
        nodeName: 'CarbonNode',
        approverRules: {
          approvals: [],
        },
      },
    },
    [CONDITION_NODE]: {
      children: [],
      componentName: 'ConditionNode',
      id: getNodeUuid(),
      ...config,
    },
    [CONDITION_CONTAINER_NODE]: {
      children: [
        {
          children: [],
          componentName: 'ConditionNode',
          id: getNodeUuid(),
          // title: '条件1',
          props: {
            description: null,
            isDefault: null,
            name: '条件1',
            // props: {
            //   name: '条件1',
            //   nodeName: 'ConditionNode',
            // },
            nodeName: 'ConditionNode',
            priority: 1,
            conditions: [],
          },
        },
        {
          children: [],
          componentName: 'ConditionNode',
          id: getNodeUuid(),
          // title: '条件2',
          props: {
            description: null,
            isDefault: null,
            name: '条件2',
            // props: {
            //   name: '条件2',
            //   nodeName: 'ConditionNode',
            // },
            nodeName: 'ConditionNode',
            priority: 2,
            conditions: [],
          },
        },
      ],
      props: {
        nodeName: 'ConditionContainerNode',
        name: '网关',
      },
      componentName: 'ConditionContainerNode',
      id: getNodeUuid(),
      // title: '网关',
    },
    [INCLUSIVE_CONDITION_CONTAINER_NODE]: {
      children: [
        {
          children: [],
          componentName: 'InclusiveConditionNode',
          id: getNodeUuid(),
          // title: '条件1',
          props: {
            description: null,
            isDefault: null,
            name: '条件1',
            // props: {
            //   name: '条件1',
            //   nodeName: 'ConditionNode',
            // },
            nodeName: 'InclusiveConditionNode',
            priority: 1,
            conditions: [],
          },
        },
        {
          children: [],
          componentName: 'InclusiveConditionNode',
          id: getNodeUuid(),
          // title: '条件2',
          props: {
            description: null,
            isDefault: null,
            name: '默认条件',
            // props: {
            //   name: '条件2',
            //   nodeName: 'ConditionNode',
            // },
            nodeName: 'InclusiveConditionNode',
            priority: 2,
            conditions: [],
          },
        },
      ],
      props: {
        nodeName: 'InclusiveConditionContainerNode',
        name: '网关',
        gatewayDirection: 'fork',
      },
      componentName: 'InclusiveConditionContainerNode',
      id: getNodeUuid(),
      // title: '网关',
    },
    [INCLUSIVE_CONDITION_CONTAINER_NODE_END]: {
      props: {
        nodeName: 'InclusiveConditionContainerNode',
        name: '网关',
        gatewayDirection: 'join',
      },
      componentName: 'InclusiveConditionContainerNode',
      id: getNodeUuid(),
      // title: '网关',
    },
    [INCLUSIVE_CONDITION_NODE]: {
      children: [],
      componentName: 'InclusiveConditionNode',
      id: getNodeUuid(),
      ...config,
    },
  };
};
