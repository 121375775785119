/*
 * :file description:
 * :name: /low-code-platform/tg-renderer/packages/form-render/src/widgets/RelationSetting/index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 14:28:41
 * :last editor: caihua
 * :date last edited: 2023-09-04 13:53:47
 */
import {
  List,
  Typography,
  Button,
  Modal,
  Checkbox,
  Row,
  Col,
  Tooltip,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import _ from 'lodash';
import c from './index.less';
import { getFormSchema, getOaFormSchema } from '@/services/form-generator';
import { useStore, useGlobal } from '@/components/FormGenerator/utils/hooks';
import classnames from 'classnames';
import IconFont from '@/components/IconFont';
import { CheckboxValueType } from 'antd/lib/checkbox/Group.d.ts';
import { WidgetProps } from 'typings';
import { customJP } from '../../utils';
import { customerTitleMap } from '../DataFilters';
import SortItem from './sortItem';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { mockSchema } from './mock'
const { Text } = Typography;

const notSupportFilterWidgets = ['relation', 'table', 'divider', 'bizSuite'];
const notSupportFilterId = ['select_projectStage'];
const notSupportFilterBizAlias = ['order_related_product_details'];

const RelationEdit: FC<WidgetProps> = (props) => {
  console.log('🚀 ~ file: index.tsx ~ line 25 ~ props', props);
  const { onChange, value, schema, addons } = props;
  const { setValueByPath, getValue, setValues, formData } = addons;
  const {
    flatten,
    selected,
    lastFlatten,
    allPreSetSchemaData,
    onFlattenChange,
  } = useStore();
  const setGlobal = useGlobal();
  const lastFiles = flatten[selected]?.schema?.fields || {};
  const currentItem = getValue();
  console.log('🚀 ~ file: index.tsx ~ line 30 ~ currentItem', currentItem);
  let relateSource: any = [];
  let relateSource1 = null,
    relateSource2 = null;

  //排序
  const [filedList, setFiledList] = useState([]);

  if (!!flatten[selected]) {
    relateSource1 =
      (flatten[selected]?.schema?.relateSource || []).find(
        (val) => val?.bizType === 'crm_customer',
      ) || {};
    relateSource2 =
      (flatten[selected]?.schema?.relateSource || []).find(
        (val) => val?.bizType === 'crm_customer_personal',
      ) || {};
    relateSource = flatten[selected]?.schema?.relateSource;
  } else {
    relateSource1 =
      (currentItem?.relateSource || []).find(
        (val) => val?.bizType === 'crm_customer',
      ) || {};
    relateSource2 =
      (currentItem?.relateSource || []).find(
        (val) => val?.bizType === 'crm_customer_personal',
      ) || {};
    relateSource = currentItem?.relateSource;
  }

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [fileds, setFileds] = useState(lastFiles);
  const [schemaForm, setSchemaForm] = useState();

  const [comFileds, setComFileds] = useState(relateSource1?.fields || {});
  const [schemaCompanyForm, setSchemaCompanyForm] = useState(); // 企业客户schema
  const [perFileds, setPerFileds] = useState(relateSource2?.fields || {});
  const [schemaPersonalForm, setSchemaPersonalForm] = useState(); // 个人客户schema
  console.log(
    '🚀 ~ file: index.tsx ~ line 43 ~ schemaPersonalForm',
    schemaPersonalForm,
  );
  const [isDouble, setIsDouble] = useState(false); // 个人客户schema
  const [isOpen1, setIsOpen1] = useState(true); // 个人客户默认展示
  const [isOpen2, setIsOpen2] = useState(true); // 企业客户默认展示

  const getSchemaAndProcess = async (formCode?: string, type?: number) => {
    const res: any = await getFormSchema({
      formCode:
        formCode || flatten[selected]?.schema?.dataSource?.target?.formCode,
    });
    if (type == 1) {
      setSchemaCompanyForm(customJP(res?.data?.content));
    } else if (type == 2) {
      setSchemaPersonalForm(customJP(res?.data?.content));
    } else {
      setSchemaForm(customJP(res?.data?.content));
    }
  };

  const getOaSchema = async (formCode?: string) => {
    const res: any = await getOaFormSchema({
      formCode,
    });
    setSchemaForm(customJP(res?.data?.content));
  };

  const handleOk = () => {
    if (isDouble) {
      const relateSource = flatten[selected]?.schema?.relateSource || [];
      let relateSource1 = relateSource.find(
        (val) => val?.bizType === 'crm_customer',
      );
      let relateSource2 = relateSource.find(
        (val) => val?.bizType === 'crm_customer_personal',
      );
      relateSource1 && (relateSource1.fields = { ...(comFileds || {}) });
      relateSource2 && (relateSource2.fields = { ...(perFileds || {}) });
      let newRationSource = [
        relateSource1 ? { ...relateSource1 } : undefined,
        relateSource2 ? { ...relateSource2 } : undefined,
      ].filter(Boolean);
      // 设置当前选中的schame下的某个属性值
      setValueByPath('relateSource', newRationSource);
      // 这里会把企业客户和个人客户 相同的field 去重  不应该去重
      // onChange({ ...(comFileds || {}), ...(perFileds || {}) });
    } else {
      onChange(fileds);
      setFileds(fileds);
    }
    setIsModalVisible(false);
    setGlobal({ lastFlatten: flatten });
  };

  const handleDelete = (schemaKey: string, type?: string) => {
    if (isDouble) {
      if (type == 'crm_customer_personal') {
        let newPerFileds = _.cloneDeep(perFileds);
        delete newPerFileds[schemaKey];
        setPerFileds(newPerFileds);
      } else {
        let newComFileds = _.cloneDeep(comFileds);
        delete newComFileds[schemaKey];
        setComFileds(newComFileds);
      }

      const newRationSource = relateSource.map((item) => {
        if (item.bizType === type) {
          delete item.fields[schemaKey];
        }
        return item;
      });
      setValueByPath('relateSource', newRationSource);
    } else {
      // fileds 会出现{} 用lastFiles保证一直有值
      const newFileds = _.cloneDeep(lastFiles);
      delete newFileds[schemaKey];
      setFileds(newFileds);
      onChange(newFileds);
      const newData = filedList?.filter((item) => item.id !== schemaKey);
      setFiledList(newData);
    }
  };

  const updateItems = (fileds: object, type: string) => {
    if (isDouble) {
      const newRationSource = relateSource.map((item) => {
        if (item.bizType === type) {
          item.fields = { ...fileds };
        }
        return item;
      });
      setValueByPath('relateSource', newRationSource);
    } else {
      onChange(fileds);
      setFileds(fileds);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleChange = (selected: Array<CheckboxValueType>, type?: number) => {
    const fileds = {};
    if (type) {
      if (type === 1) {
        selected?.forEach((item) => {
          fileds[item] = schemaCompanyForm.properties?.[item];
        });
        setComFileds(fileds);
      }
      if (type === 2) {
        selected?.forEach((item) => {
          fileds[item] = schemaPersonalForm.properties[item];
        });
        setPerFileds(fileds);
      }
    } else {
      const newData = [];
      selected?.forEach((item) => {
        fileds[item] = schemaForm.properties[item];
        newData.push(schemaForm.properties[item]);
      });
      setFileds(fileds);
      setFiledList(newData);
    }
  };

  const initfiledList = Object.keys(value || {}).map((key) => {
    return {
      key,
      ...value[key],
    };
  });

  useEffect(() => {
    if (!lastFlatten[selected]) {
      setIsModalVisible(true);
    }

    let formCode = '',
      isOaRelation = false,
      relateSource = [];

    if (!!flatten[selected]) {
      formCode = flatten[selected]?.schema?.dataSource?.target?.formCode;
      isOaRelation =
        flatten[selected]?.schema?.isOaRelation ||
        flatten[selected]?.schema?.dataSource?.target?.source === 4;
      relateSource = flatten[selected]?.schema?.relateSource;
    } else {
      // 此时是第一次打开弹窗时存在数据异常
      formCode = currentItem?.dataSource?.target?.formCode;
      isOaRelation = currentItem?.isOaRelation;
      relateSource = currentItem?.relateSource;
    }
    // 双关联表单
    if (relateSource?.length) {
      setIsDouble(true);
      let relateSource1 =
        (relateSource || []).find((val) => val?.bizType === 'crm_customer') ||
        {};
      let relateSource2 =
        (relateSource || []).find(
          (val) => val?.bizType === 'crm_customer_personal',
        ) || {};

      let formCode1 = relateSource1?.dataSource?.target?.formCode;
      let formCode2 = relateSource2?.dataSource?.target?.formCode;
      // 表单状态(PUBLISHED: 启用, INVALID: 停用, SAVED: 草稿)
      let status1 = relateSource1?.dataSource?.target?.status;
      let status2 = relateSource2?.dataSource?.target?.status;
      setIsOpen1(status1 === 'PUBLISHED');
      setIsOpen2(status2 === 'PUBLISHED');
      if (formCode1 && status1 === 'PUBLISHED') {
        // 企业客户
        getSchemaAndProcess(formCode1, 1);
      }
      if (formCode2 && status2 === 'PUBLISHED') {
        // 个人客户
        getSchemaAndProcess(formCode2, 2);
      }

      setComFileds(relateSource1?.fields || {});
      setPerFileds(relateSource2?.fields || {});
    } else {
      setIsDouble(false);
      // oa数据类型 调另外一个接口
      if (formCode) {
        if (isOaRelation) {
          getOaSchema(formCode);
        } else {
          getSchemaAndProcess(formCode);
        }
      }
      setFileds(flatten[selected]?.schema?.fields || {});
      setFiledList(initfiledList);
    }
  }, [selected, value]);

  const defaultValue = Object.keys(value || {});
  const defaultComValue = Object.keys(relateSource1?.fields || {});
  const defaultPerValue = Object.keys(relateSource2?.fields || {});

  return (
    <div className={c.container}>
      <div className={classnames(c.header, filedList.length ? '' : c.nullList)}>
        <div
          className={c.add}
          onClick={() => {
            setIsModalVisible(true);
          }}
        >
          添加字段
        </div>
      </div>
      <ul className={c.list}>
        {isDouble && (
          <div className={c.doubleDiv}>
            {relateSource?.map((child) => {
              if (child?.dataSource?.target?.status !== 'PUBLISHED') {
                return null;
              }
              return (
                <>
                  <p className={c.doubleDivP} key={child?.id}>
                    {customerTitleMap.get(child.bizType)}
                  </p>
                  <SortItem
                    key={child?.id}
                    items={Object.values(child?.fields)}
                    updateItems={updateItems}
                    deleteItems={handleDelete}
                    type={child.bizType}
                  />
                </>
              );
            })}
          </div>
        )}
        {!isDouble && (
          <SortItem
            items={filedList}
            updateItems={updateItems}
            deleteItems={handleDelete}
          />
        )}
      </ul>
      <Modal
        title="设置关联字段"
        width={400}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className={c.settingRelation}
        destroyOnClose
        closable={false}
        closeIcon={<IconFont type="icon-Close" />}
      >
        {isDouble && (
          <>
            {isOpen2 && (
              <>
                <p>个人客户</p>
                <Checkbox.Group
                  defaultValue={defaultPerValue}
                  style={{ width: '100%' }}
                  onChange={(val) => handleChange(val, 2)}
                >
                  {schemaPersonalForm &&
                    Object.keys(schemaPersonalForm?.properties || {}).map(
                      (item) => {
                        const { title, widget, hidden, deleted } =
                          schemaPersonalForm?.properties[item] ?? {};
                        if (hidden || deleted) {
                          return null;
                        }
                        return title ? (
                          <Checkbox
                            disabled={
                              widget === 'relation' || widget === 'table'
                            }
                            value={item}
                          >
                            {title}
                          </Checkbox>
                        ) : null;
                      },
                    )}
                </Checkbox.Group>
              </>
            )}
            {isOpen1 && (
              <>
                <p>企业客户</p>
                <Checkbox.Group
                  defaultValue={defaultComValue}
                  style={{ width: '100%' }}
                  onChange={(val) => handleChange(val, 1)}
                >
                  {schemaCompanyForm &&
                    Object.keys(schemaCompanyForm?.properties || {}).map(
                      (item) => {
                        const {
                          title,
                          widget,
                          hidden,
                          deleted,
                          hideControls,
                          bizAlias,
                        } = schemaCompanyForm?.properties[item] ?? {};
                        if (
                          (!hideControls && hidden) ||
                          deleted ||
                          bizAlias === 'kp_contact'
                        ) {
                          // 关键联系人屏蔽掉
                          return null;
                        }
                        return title ? (
                          <Checkbox
                            key={item}
                            disabled={
                              widget === 'relation' || widget === 'table'
                            }
                            value={item}
                          >
                            {title}
                          </Checkbox>
                        ) : null;
                      },
                    )}
                </Checkbox.Group>
              </>
            )}
          </>
        )}
        {!isDouble && (
          <Checkbox.Group
            defaultValue={defaultValue}
            style={{ width: '100%' }}
            onChange={handleChange}
          >
            {schemaForm &&
              Object.keys(schemaForm?.properties || {}).map((item) => {
                const {
                  title,
                  widget,
                  hidden,
                  deleted,
                  bizAlias,
                  id,
                  hideControls,
                } = schemaForm?.properties[item] ?? {};
                if ((!hideControls && hidden) || deleted) {
                  return null;
                }
                return (
                  // 后端叫前端这样处理（没有title直接隐藏）
                  title ? (
                    <Checkbox
                      // schemaForm?.properties[item]?.id === 'select_projectStage' 关联项目表单，项目阶段不让选
                      disabled={
                        notSupportFilterWidgets.includes(widget) ||
                        notSupportFilterId.includes(id) ||
                        notSupportFilterBizAlias.includes(bizAlias)
                      }
                      value={item}
                      key={item}
                    >
                      {title}
                    </Checkbox>
                  ) : null
                );
              })}
          </Checkbox.Group>
        )}
      </Modal>
    </div>
  );
};

export default RelationEdit;
