import { useState, useEffect } from 'react';
import { Cascader } from 'antd';
import formApi from '@/services/form';
import province from './bsLowerList.json';

const Province = (props) => {
  const { value } = props;
  const { onChange } = props;

  return (
    <div>
      <Cascader
        options={province}
        // loadData={loadData}
        onChange={onChange}
        style={{
          width: '100%',
        }}
        value={value?.address}
      />
    </div>
  );
};

export default Province;
