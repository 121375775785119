/*
 * :file description:
 * :name: \commond:\progree\low-code-platform\src\components\MultipleChoice\constants.ts
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-19 14:17:12
 * :last editor: hyw
 * :date last edited: 2021-12-15 19:32:13
 */
import { getUuid } from '@/utils';
// 初始化部门树数据
const departTreeData = [
  {
    title: '0-0',
    key: '0-0',
    children: [
      {
        title: '0-0-0',
        key: '0-0-0',
        children: [
          { title: '0-0-0-0', key: '0-0-0-0' },
          { title: '0-0-0-1', key: '0-0-0-1' },
          { title: '0-0-0-2', key: '0-0-0-2' },
        ],
      },
      {
        title: '0-0-1',
        key: '0-0-1',
        children: [
          { title: '0-0-1-0', key: '0-0-1-0' },
          { title: '0-0-1-1', key: '0-0-1-1' },
          { title: '0-0-1-2', key: '0-0-1-2' },
        ],
      },
      {
        title: '0-0-2',
        key: '0-0-2',
      },
    ],
  },
  {
    title: '0-1',
    key: '0-1',
    children: [
      { title: '0-1-0-0', key: '0-1-0-0' },
      { title: '0-1-0-1', key: '0-1-0-1' },
      { title: '0-1-0-2', key: '0-1-0-2' },
    ],
  },
  {
    title: '0-2',
    key: '0-2',
  },
];
const userTreeData = [
  {
    title: '0-0',
    key: '0-0',
    children: [
      {
        title: '0-0-0',
        key: '0-0-0',
        children: [
          { title: '0-0-0-0', key: '0-0-0-0' },
          { title: '0-0-0-1', key: '0-0-0-1' },
          { title: '0-0-0-2', key: '0-0-0-2' },
        ],
      },
      {
        title: '0-0-1',
        key: '0-0-1',
        children: [
          { title: '0-0-1-0', key: '0-0-1-0' },
          { title: '0-0-1-1', key: '0-0-1-1' },
          { title: '0-0-1-2', key: '0-0-1-2' },
        ],
      },
      {
        title: '0-0-2',
        key: '0-0-2',
      },
    ],
  },
  {
    title: '0-1',
    key: '0-1',
    children: [
      { title: '0-1-0-0', key: '0-1-0-0' },
      { title: '0-1-0-1', key: '0-1-0-1' },
      { title: '0-1-0-2', key: '0-1-0-2' },
    ],
  },
  {
    title: '0-2',
    key: '0-2',
  },
];

const roleTreeData = [
  {
    title: '角色1',
    key: '0-0',
    children: [
      {
        title: '子角色1-1',
        key: '0-0-0',
        children: [
          { title: '0-0-0-0', key: '0-0-0-0' },
          { title: '0-0-0-1', key: '0-0-0-1' },
          { title: '0-0-0-2', key: '0-0-0-2' },
        ],
      },
      {
        title: '子角色1-2',
        key: '0-0-1',
        children: [
          { title: '0-0-1-0', key: '0-0-1-0' },
          { title: '0-0-1-1', key: '0-0-1-1' },
          { title: '0-0-1-2', key: '0-0-1-2' },
        ],
      },
      {
        title: '子角色1-3',
        key: '0-0-2',
      },
    ],
  },
  {
    title: '角色2',
    key: '0-1',
    children: [
      { title: '子角色2-1', key: '0-1-0-0' },
      { title: '子角色2-2', key: '0-1-0-1' },
      { title: '子角色2-3', key: '0-1-0-2' },
    ],
  },
  {
    title: '角色3',
    key: '0-2',
  },
];
const mockUsersArr: any[] = [
  {
    name: '人员1',
    id: getUuid(),
    type: 1,
  },
  {
    name: '人员2',
    id: getUuid(),
    type: 1,
  },
  {
    name: '人员3',
    id: getUuid(),
    type: 1,
  },
];

const mockSearchDepartArr: any[] = [
  {
    name: '部门1',
    id: getUuid(),
    type: 2,
  },
  {
    name: '部门2',
    id: getUuid(),
    type: 2,
  },
  {
    name: '部门3',
    id: getUuid(),
    type: 2,
  },
];

const mockSearchUsersArr: any[] = [
  {
    name: '搜索人员1',
    id: getUuid(),
    type: 3,
  },
  {
    name: '搜索人员2',
    id: getUuid(),
    type: 3,
  },
  {
    name: '搜索人员3',
    id: getUuid(),
    type: 3,
  },
];

const mockSearchRolesArr: any[] = [
  {
    name: '搜索角色1',
    id: getUuid(),
    type: 4,
  },
  {
    name: '搜索角色2',
    id: getUuid(),
    type: 4,
  },
  {
    name: '搜索角色3',
    id: getUuid(),
    type: 4,
  },
];

const defaultUsers: any[] = [
  {
    name: '已选客户1',
    id: getUuid(),
  },
  {
    name: '已选客户2',
    id: getUuid(),
  },
  {
    name: '已选客户3',
    id: getUuid(),
  },
];

const defaultTabs: any[] = ['角色', '人员', '部门', '动态负责人'];
// const defaultTabs: any[] = ['角色', '人员', '部门'];

const principalBizTypes = [
  'crm_order',
  'crm_contract',
  'crm_invoice_apply',
  'crm_payment',
];

const DynamicMemberList = [
  {
    id: 'current_user',
    name: '表单发起人',
  },
];

export {
  departTreeData,
  userTreeData,
  roleTreeData,
  mockUsersArr,
  mockSearchDepartArr,
  mockSearchUsersArr,
  mockSearchRolesArr,
  defaultUsers,
  defaultTabs,
  principalBizTypes,
  DynamicMemberList,
};
