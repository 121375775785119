/*
 * @Description:
 * @FilePath: /low-code-platform/tg-renderer/packages/form-render/src/widgets/SelectTreeOptions/Modal/TreeList/index.tsx
 * :author: wu.shangru
 * :copyright: (c) 2023, Tungee
 * @Date: 2023-12-08 17:24:44
 * @LastEditors: wu.shangru
 * @LastEditTime: 2024-01-09 12:14:06
 */
import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import { Tree, Divider, message, Input, Modal } from 'antd';
import type { DataNode, TreeProps } from 'antd/es/tree';
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import { nanoid } from '@/components/FormGenerator/components/Sidebar/Element';
import IconFont from '@/components/IconFont';
import {
  InfoCircleOutlined,
  PlusOutlined,
  CloseSquareOutlined,
  CheckCircleFilled,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import Styles from './index.less';
import { IProductType } from '../index';
import { useGlobal, useStore } from '@/components/FormGenerator/utils/hooks';

const { TreeNode } = Tree;

interface ITreeItem {
  name: string;
  id: number;
  code: string;
  parentId: string;
  sortNum: number;
  children?: ITreeItem[];
  setTypes?: any;
}

interface Iprops {
  cRef: any;
  treeList: ITreeItem[];
  searchKey?: string;
  onAddSubTree?: (item: ITreeItem) => void;
  onAddLevelTree?: (item: ITreeItem) => void;
  onEditTree?: (item: ITreeItem) => void;
  onRemoveTree?: (item: ITreeItem) => void;
  level?: number;
  userId: string;
  initTypes: () => void;
  setTypes?: any;
  fnsData?: any;
}

const TreeList: React.FC<Iprops> = (props) => {
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [treeCanDrag, setTreeCanDrag] = useState(true);
  const [styleCss, setStyleCss] = useState({ top: '48px' });
  const { level = 0, searchKey, cRef, setTypes, treeList, fnsData } = props;

  const domRef = useRef(null);
  const inputRef = React.useRef();

  // 缓存级联选项
  const setGlobal = useGlobal();
  const [currentIndex, setCurrentIndex] = useState('');

  // 编辑/新建弹窗参数
  const [visible, setVisible] = useState(false);
  const [mode, setMode] = useState(''); // 'createSub' | 'createLevel' | 'edit'
  const [currentRecord, setCurrentRecord] = useState<IProductType>(null);
  const [currentItem, setCurrentItem] = useState({});

  /**
   * @description: 让树展开或收起相关节点
   * @return {*}
   * @param {any} item  要操作的节点
   * @param {number} type  0 可展开 可收起  1 只展开不收起  -1 只收起不展开
   */
  const expandTree = (item: any, type: number) => {
    const key = item?.id;
    const hasKey = expandedKeys?.includes(key);
    if (hasKey && type <= 0) {
      const newKeys = expandedKeys?.filter((i) => i !== key);
      setExpandedKeys(newKeys);
    } else if (!hasKey && type >= 0) {
      const newKeys = [...expandedKeys];
      newKeys?.push(key);
      setExpandedKeys(newKeys);
    }
  };

  const renderTreeNodeTitle = (item: any, _level: number) => {
    const hasMatch =
      searchKey &&
      (item.name.includes(searchKey) || item?.code?.includes(searchKey));
    return (
      <>
        <div
          className={classnames(Styles.tree_node_title, {
            [Styles.active]: hasMatch,
            [Styles.add_item_active]:
              currentRecord &&
              currentRecord?.id &&
              item?.id === currentRecord?.id &&
              (mode === 'createSub' || mode === 'createLevel'),
          })}
        >
          <div
            className={Styles.name}
            onClick={() => {
              expandTree(item, 0);
            }}
          >
            {item.name}
            <div className={Styles.code}>编号：{item?.code}</div>
          </div>
          <div className={Styles.operaGroup}>
            <a
              onClick={(event) => {
                setMode('edit');
                setCurrentRecord(item);
                setCurrentItem(item);
                setStyleCss({ top: '0' });
                setVisible(false);
                setTreeCanDrag(false);
                setTimeout(() => {
                  inputRef.current && inputRef.current?.focus();
                }, 0);
              }}
            >
              编辑
            </a>
            <Divider type="vertical" />
            <a
              onClick={() => {
                deleteType(item);
                setVisible(false);
              }}
            >
              删除
            </a>
            <Divider type="vertical" />
            {_level >= 10 ? (
              <a style={{ color: 'rgba(0, 0, 0, 0.25)', cursor: 'auto' }}>
                新建子级
              </a>
            ) : (
              <a
                onClick={(event) => {
                  setMode('createSub');
                  setCurrentRecord(item);
                  setCurrentItem({});
                  setStyleCss({ top: '48px' });
                  setVisible(false);
                  setTreeCanDrag(false);
                  setTimeout(() => {
                    inputRef.current && inputRef.current?.focus();
                  }, 0);
                }}
              >
                新建子级
              </a>
            )}
            <Divider type="vertical" />
            <a
              onClick={(event) => {
                setMode('createLevel');
                setCurrentRecord(item);
                setCurrentItem({});
                setStyleCss({ top: '48px' });
                setVisible(false);
                setTreeCanDrag(false);
                setTimeout(() => {
                  inputRef.current && inputRef.current?.focus();
                }, 0);
              }}
            >
              新建平级
            </a>
          </div>
        </div>
        {currentRecord &&
          currentRecord?.id &&
          item?.id === currentRecord?.id &&
          (mode === 'createSub' ||
            mode === 'createLevel' ||
            mode === 'edit') && (
            <div className={Styles.headContain2} style={styleCss}>
              <Input
                maxLength={100}
                placeholder="请填写分类名称（必填）"
                value={currentItem?.name}
                showCount
                ref={inputRef}
                onChange={(e) => {
                  setCurrentItem({
                    ...currentItem,
                    name: e?.target?.value,
                  });
                }}
              />
              <Input
                maxLength={100}
                placeholder="请填写分类编码（必填）"
                value={currentItem?.code}
                showCount
                onChange={(e) => {
                  setCurrentItem({
                    ...currentItem,
                    code: e?.target?.value,
                  });
                }}
              />
              <div className={Styles.operaBody}>
                <span onClick={closeItem}>
                  <CloseOutlined />
                </span>
                <span onClick={confirmItem}>
                  <CheckOutlined />
                </span>
              </div>
            </div>
          )}
      </>
    );
  };

  const renderTreeNodes = (data: any[], level: number) => {
    const _level = level + 1;
    return data?.map((item, index) => {
      if (item.children) {
        return (
          <TreeNode title={renderTreeNodeTitle(item, _level)} key={item.id}>
            {renderTreeNodes(item.children, _level)}
          </TreeNode>
        );
      }
      return (
        <TreeNode key={item.id} title={renderTreeNodeTitle(item, _level)} />
      );
    });
  };

  const loop = (
    data: Array<any>,
    key: number,
    callback: (item: any, index: number, arr: Array<any>) => void,
  ) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === key) {
        return callback(data[i], i, data);
      }
      if (data[i].children) {
        loop(data[i].children, key, callback);
      }
    }
  };

  const findId = (data: any, id: string) => {
    return data.find((item: any) => {
      if (item.id === id) {
        return item;
      }
      if (item.children.length > 0) {
        return findId(item.children, id);
      }
    });
  };

  const onDrop = (info: any) => {
    let checkValidate = true;
    // console.error(info, 'info');
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key; // 拖拽的产品的key
    const dropPos = info.node.pos.split('-');
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);

    const loop = (
      data: any,
      key: any,
      callback: (node: DataNode, i: number, data: DataNode[]) => void,
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i]?.id === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children!, key, callback);
        }
      }
    };

    const data = cloneDeep(props.treeList);

    // Find dragObject
    let dragObj: DataNode;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        // 这个放到children 需要校验是否超过10层
        const fullPathArr = item?.fullPath?.split('/');
        if (fullPathArr?.length >= 10) {
          checkValidate = false;
          message.warning('子选项不能超过10层');
          return;
        }
        item.children = item.children || [];
        if (
          item.children?.some(
            (i) => i?.name === dragObj?.name || i?.code === dragObj?.code,
          )
        ) {
          checkValidate = false;
          message.warning('子选项存在相同的名称或者编码');
          return;
        }
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      ((info.node as any).props.children || []).length > 0 && // Has children
      (info.node as any).props.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        // 这个放到children 需要校验是否超过10层
        const fullPathArr = item?.fullPath?.split('/');
        if (fullPathArr?.length >= 10) {
          checkValidate = false;
          message.warning('子选项不能超过10层');
          return;
        }
        item.children = item.children || [];
        if (
          item.children?.some(
            (i) => i?.name === dragObj?.name || i?.code === dragObj?.code,
          )
        ) {
          checkValidate = false;
          message.warning('子选项存在相同的名称或者编码');
          return;
        }
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar: DataNode[] = [];
      let i: number;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (
        ar?.some((i) => i?.name === dragObj?.name || i?.code === dragObj?.code)
      ) {
        checkValidate = false;
        message.warning('子选项存在相同的名称或者编码');
        return;
      }
      if (dropPosition === -1) {
        ar.splice(i!, 0, dragObj!);
      } else {
        ar.splice(i! + 1, 0, dragObj!);
      }
    }

    if (checkValidate) {
      props?.fnsData?.(data);
      props?.setTypes?.(data);
    }
  };

  const initItem = {
    appUuid: null,
    children: [],
    code: null,
    fullIdPath: null,
    fullNamePath: null,
    gmtCreate: null,
    id: null,
    level: null,
    name: null,
    orgId: null,
    parentId: null,
    sortNum: null,
  };

  /**
   * @description: 对树进行新增平级 子级 编辑 以及删除
   * @return {*}
   * @param {*} types  树的数据结构
   * @param {*} treeNode   点击的节点  锚点
   * @param {*} operaType  对树进行操作类型 包括删除delete，编辑edit，新建平级createLevel， 新建子级createSub，新建分类（最外层）
   * @param {any} newNode  新增的节点
   */
  const operaTreeNode = (types, treeNode, operaType, newNode?: any) => {
    if (!types || !operaType) return [];
    const id = nanoid();
    const node = {
      ...initItem,
      ...newNode,
      id: newNode?.id ?? id,
      fullNamePath: newNode?.name,
      fullIdPath: newNode?.id ?? id,
    };
    if (!treeNode && operaType === 'createLevel') {
      node.fullPath = '' + types?.length;
      node.level = 0;
      types.push(node);
    } else {
      const fullPathArr = treeNode?.fullPath?.toString().split('/') ?? [];
      const lastIndex = parseInt(fullPathArr[fullPathArr?.length - 1]);
      const pathLen = fullPathArr?.length;
      const arr = fullPathArr.slice(1, -1);
      let targetNode = types[fullPathArr[0]];
      while (arr?.length > 0) {
        targetNode = targetNode?.children?.[arr[0]];
        arr.splice(0, 1);
      }
      if (operaType === 'createLevel') {
        node.level = pathLen - 1;
        if (pathLen === 1) {
          types.splice(lastIndex + 1, 0, node);
        } else {
          node.level = targetNode.level + 1;
          targetNode?.children?.splice(lastIndex + 1, 0, node);
        }
      } else if (operaType === 'createSub') {
        node.level = pathLen;
        if (pathLen === 1) {
          node.fullPath =
            lastIndex + '/' + types?.[lastIndex]?.children?.length;
          node.level = 1;
          types?.[lastIndex]?.children?.push(node);
          // 判断子级是否要展开
          expandTree(types?.[lastIndex], 1);
        } else {
          node.fullPath =
            targetNode?.children?.[lastIndex]?.fullPath +
            '/' +
            targetNode?.children?.[lastIndex]?.children?.length;
          targetNode?.children?.[lastIndex]?.children?.push(node);
          // 判断子级是否要展开
          expandTree(targetNode?.children?.[lastIndex], 1);
        }
      } else if (operaType === 'edit') {
        if (pathLen === 1) {
          types.splice(lastIndex, 1, node);
        } else {
          targetNode?.children?.splice(lastIndex, 1, node);
        }
      } else if (operaType === 'delete') {
        if (pathLen === 1) {
          types.splice(lastIndex, 1);
        } else {
          targetNode?.children?.splice(lastIndex, 1);
        }
      }
    }
    fnsData?.(types);
    return types;
  };
  /**
   * @description: 对树进行校验 子级层数最多10  并且同级名称不能相同 且name和code不能包含/
   * @return {*} boolean true 校验通过  false 没通过
   * @param {*} types  树的数据结构
   * @param {*} treeNode   点击的节点  锚点
   * @param {*} operaType  对树进行操作类型 包括删除delete，编辑edit，新建平级createLevel， 新建子级createSub，新建分类（最外层）
   * @param {any} newNode  新增的节点
   */
  const validateTreeNode = (types, treeNode, operaType, newNode?: any) => {
    if (!types || !operaType) return true;
    let target = [];

    if (
      newNode?.name?.indexOf('/') > -1 ||
      newNode?.code?.indexOf('/') > -1 ||
      newNode?.name?.indexOf(',') > -1
    ) {
      message.warning('名称或者编码中不支持特殊字符/以及英文,');
      return false;
    }

    if (!treeNode && operaType === 'createLevel') {
      target = types;
    } else {
      const fullPathArr = treeNode?.fullPath?.toString().split('/') ?? [];
      const lastIndex = parseInt(fullPathArr[fullPathArr?.length - 1]);
      const pathLen = fullPathArr?.length;
      const arr = fullPathArr.slice(1, -1);
      let targetNode = types[fullPathArr[0]];
      while (arr?.length > 0) {
        targetNode = targetNode?.children?.[arr[0]];
        arr.splice(0, 1);
      }
      if (operaType === 'createSub' && pathLen === 1) {
        target = types?.[lastIndex]?.children;
      } else if (operaType === 'createLevel' && pathLen === 1) {
        target = types;
      } else if (operaType === 'createLevel') {
        target = targetNode?.children;
      } else if (operaType === 'createSub') {
        target = targetNode?.children?.[lastIndex]?.children;
      } else if (operaType === 'edit' && pathLen === 1) {
        target = types
      } else if (operaType === 'edit') {
        target = targetNode?.children
      }
    }
    let trees = allTrees;
    if (operaType === 'edit') {
      trees = allTrees?.filter((i) => i.id !== newNode?.id);
      target = target?.filter(i => i.id !== newNode?.id);
    }
    const hasSameCode = trees?.some((i) => i?.code === newNode?.code);
    const hasSameName = target?.some((i) => i?.name === newNode?.name);
    if (hasSameCode) {
      message.warning('存在相同的编码');
      return false;
    }
    if (hasSameName) {
      message.warning('同一层级存在相同的名称');
      return false;
    }
    return true;
  };

  /**
   * @description: 通过key找到树中的key的fullpath 和 children 的长度
   * @return {*}
   * @param {*} types   树
   * @param {*} targetKey  目标key
   * @param {*} res  {fullPath: '0/1/2', childLen: 2}
   */
  const findFullPath = (types, targetKey, res = {}) => {
    if (!types?.length || !targetKey) return;

    for (let i = 0; i < types?.length; i++) {
      if (types[i]?.id === targetKey) {
        res = {
          fullPath: types[i]?.fullPath,
          childLen: types[i]?.children?.length,
        };
      } else if (types[i]?.children?.length) {
        findFullPath(types[i]?.children, targetKey, res);
      }
    }
    return res;
  };
  /**
   * @description: 通过比较fullPath, 判断targetFullPath是否在currentFullPath前面， 前面返回1 后面返回 0 异常返回-1
   * @return {*} targetFullPath在前面返回1  后面返回 0 异常返回-1
   * @param {*} currentFullPath
   * @param {*} targetFullPath
   */
  const compareFullPath = (currentFullPath, targetFullPath) => {
    if (!currentFullPath || !targetFullPath) return -1;
    const currentPathArr = currentFullPath?.split('/');
    const currentPathArrLen = currentPathArr?.length;
    const targetPathArr = targetFullPath?.split('/');
    const targetPathArrLen = targetPathArr?.length;

    const len = Math.min(currentPathArrLen, targetPathArrLen);
    let i = 0;
    while (currentPathArr[i] === targetPathArr[i] && i < len) {
      i++;
    }
    if (!currentPathArr[i]) {
      return 0;
    } else if (!targetPathArr[i]) {
      return 1;
    } else {
      if (targetPathArr[i] > currentPathArr[i]) {
        return 0;
      } else {
        return 1;
      }
    }
  };
  /**
   * @description: 根据关键字返回需要展开的树节点
   * @return {*}
   * @param {*} treeList  树结构 数组
   * @param {*} searchKey  关键字
   * @param {*} resArr 存储数组
   */
  const getParentKeys = (treeList = [], searchKey = '', resArr = []) => {
    if (!searchKey) return [];
    treeList?.forEach((i) => {
      if (i?.children?.length) {
        if (
          i.children.some(
            (item: any) =>
              item?.code?.indexOf(searchKey) > -1 ||
              item?.name?.indexOf(searchKey) > -1,
          )
        ) {
          resArr.push(i?.id);
        } else {
          getParentKeys(i?.children, searchKey, resArr);
        }
      }
    });
    return resArr;
  };

  useImperativeHandle(cRef, () => ({
    creatItem: () => {
      if (visible) return;
      setMode('createLevel');
      setCurrentRecord(null);
      setCurrentItem({});
      setVisible(true);
      setStyleCss({
        position: 'relative',
        top: '0px',
      });
      setTreeCanDrag(false);
      setTimeout(() => {
        inputRef.current && inputRef.current?.focus();
      }, 0);
    },
  }));

  const closeItem = () => {
    setCurrentRecord(null);
    setCurrentItem({});
    setVisible(false);
    setTreeCanDrag(true);
    setTypes([...props.treeList]);
  };

  const confirmItem = () => {
    if (
      (!currentItem?.name && currentItem?.name != 0) ||
      (!currentItem?.code && currentItem?.code != 0)
    ) {
      message.warn('选项名称或者编码不能为空');
      return;
    }
    if (validateTreeNode(treeList, currentRecord, mode, currentItem)) {
      const data = operaTreeNode(treeList, currentRecord, mode, currentItem);
      setTypes([...data]);
      setVisible(false);
      setTreeCanDrag(true);
      setCurrentRecord(null);
    }
  };

  const deleteType = (item: IProductType) => {
    setMode('delete');
    setCurrentRecord(item);
    Modal.confirm({
      title: '确认删除吗',
      content:
        item?.children?.length > 0
          ? '当前分类下还有子分类，请确认是否一起删除'
          : '删除后不可恢复，请确认是否删除',
      onOk: () => {
        const data = operaTreeNode(treeList, item, 'delete', '');
        setTypes([...data]);
        setVisible(false);
      },
      okText: '确定',
      cancelText: '取消',
    });
  };

  useEffect(() => {
    if (treeList.length > 0) {
      const expandedKeys = getParentKeys(treeList, searchKey, []);
      setAutoExpandParent(true);
      setExpandedKeys(expandedKeys);
    }
  }, [searchKey]);

  const allTrees = useMemo(() => {
    if (!props.treeList || !props.treeList?.length) return [];
    let res = [];
    const flattenTree = (target, res) => {
      if (!target) return;
      target.forEach((i) => {
        res?.push(i);
        if (i?.children?.length) {
          flattenTree(i?.children, res);
        }
      });
    };
    flattenTree(props.treeList, res);
    return res;
  }, [props.treeList]);

  return (
    <div className={Styles.treeList} ref={domRef}>
      {visible && (
        <div className={Styles.headContain}>
          <Input
            maxLength={100}
            placeholder="请填写分类名称（必填）"
            value={currentItem?.name}
            showCount
            ref={inputRef}
            onChange={(e) => {
              setCurrentItem({
                ...currentItem,
                name: e?.target?.value,
              });
            }}
          />
          <Input
            maxLength={100}
            placeholder="请填写分类编码（必填）"
            value={currentItem?.code}
            showCount
            onChange={(e) => {
              setCurrentItem({
                ...currentItem,
                code: e?.target?.value,
              });
            }}
          />
          <div className={Styles.operaBody}>
            <span onClick={closeItem}>
              <CloseOutlined />
            </span>
            <span onClick={confirmItem}>
              <CheckOutlined />
            </span>
          </div>
        </div>
      )}
      <Tree
        multiple
        autoExpandParent={autoExpandParent}
        expandedKeys={expandedKeys}
        blockNode
        draggable={treeCanDrag}
        selectable={false}
        onDrop={onDrop}
        switcherIcon={
          <IconFont
            type="icon-zhankai"
            style={{ color: 'rgba(23,26,29,0.60)' }}
          />
        }
        onExpand={(expandedKeys, { expanded: bool, node }) => {
          // console.log(node, currentRecord, '1PPP');
          // const res = findFullPath(props.treeList, node?.key);
          // const re = compareFullPath(currentRecord?.fullPath, res?.fullPath);
          // if (re === 1 && bool) {
          //   setCurrentIndex(currentIndex + res?.childLen);
          // } else if (re === 1 && !bool) {
          //   setCurrentIndex(currentIndex - res?.childLen);
          // }
          setExpandedKeys(expandedKeys);
          setAutoExpandParent(false);
          setVisible(false);
        }}
      >
        {renderTreeNodes(props.treeList, level)}
      </Tree>
      {!props.treeList?.length && !visible && (
        <div className={Styles.nodata}>
          <img src="https://img.alicdn.com/imgextra/i4/O1CN01vGCeP11o81yllTXG5_!!6000000005179-2-tps-360-360.png" />
          <span>暂无数据</span>
        </div>
      )}
    </div>
  );
};

export default TreeList;
