import { FC, useRef, useMemo } from 'react';
import c from './index.less';
import classnames from 'classnames';
import _cloneDeep from 'lodash/cloneDeep';
import { CloseOutlined } from '@ant-design/icons';
import MultipleChoice from '@/components/MultipleChoice';
import { useStore } from '@/components/FormGenerator/utils/hooks';

const defaultUserOrDepOrRole = {
  departments: [],
  users: [],
  roles: [],
  dynamicPerson: [],
};

const ShowUserOrDept = (props: any) => {
  const { displaySchema } = useStore();
  const {
    value = {},
    onChange,
    schema,
    addons,
    // changeCount,
    // hiddenDynamicPrinciple,
  } = props;
  const { formData } = addons || {};
  const { placeholder } = schema || {};
  const { collaborativeManagementEditableFreeze } = formData || {};

  const childRef = useRef(null);

  const getMemberTitleList = () => {
    const widgetObj = displaySchema?.properties;
    if (!widgetObj) return [];
    const returnData = Object.entries(widgetObj).map(([prop, propValue]) => {
      if (propValue.widget === 'innerContact') {
        return propValue;
      }
    });
    return returnData.filter((val) => val);
  };

  // 打开人员选择器
  const addOrEditUsers = () => {
    if (collaborativeManagementEditableFreeze) {
      return;
    }
    (childRef?.current as any)?.openModal();
  };

  // 数据转换成 list
  const computeData = (data: any) => {
    let showArr: Array<string> = [];
    let json: Object = _cloneDeep(data) || _cloneDeep(defaultUserOrDepOrRole);

    Object.entries(json)?.forEach((ele) => {
      ele[1]?.forEach?.((e: any) => {
        if (ele[0] === 'departments') e.type = 'department';
        if (ele[0] === 'users') e.type = 'employee';
        if (ele[0] === 'roles') e.type = 'role';
        showArr.push(e);
      });
    });
    return showArr;
  };

  // 移除选择的数据
  const removeOne = (e: Event, currentParams: any) => {
    let allData = value;
    switch (currentParams.type) {
      case 'role':
      case 'department':
      case 'initiator':
      case 'member_field':
        let mapTypeJson: any = {
          role: 'roles',
          department: 'departments',
          initiator: 'dynamicPerson',
          member_field: 'dynamicPerson',
        };
        let currentArr = allData[mapTypeJson[currentParams.type]].filter(
          (ele) => currentParams.id != ele.id,
        );
        let finallyData = {
          ...allData,
          [mapTypeJson[currentParams.type]]: currentArr,
        };
        onChange(finallyData);
        // changeCount?.();
        break;
      case 'employee':
        let users = allData.users.filter(
          (ele) =>
            currentParams.id != ele.id || currentParams.userId != ele.userId,
        );
        let finallyUsersData = {
          ...allData,
          users,
        };
        onChange(finallyUsersData);
        // changeCount?.();
        break;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  // 获取人员选择器数据
  const getDataBack = (data: any) => {
    onChange?.(data);
    // changeCount?.();
  };

  const listData = computeData(value);

  // const tabsList = useMemo(() => {
  //   if (hiddenDynamicPrinciple) {
  //     return ['部门', '人员', '角色'];
  //   }
  //   return ['部门', '人员', '角色', '动态负责人'];
  // }, [hiddenDynamicPrinciple]);

  return (
    <>
      <div
        className={classnames(
          c.usersDiv,
          collaborativeManagementEditableFreeze ? c.disabled : '',
          listData?.length == 0 ? c.placeholder : '',
        )}
        onClick={() => addOrEditUsers()}
      >
        {listData?.length !== 0
          ? listData?.map((data: any) => {
              return (
                <span className={c.users} key={data.id}>
                  {data.name}{' '}
                  <CloseOutlined
                    onClick={(e) => removeOne(e, data)}
                    className={c.icon}
                  />
                </span>
              );
            })
          : placeholder}
      </div>
      <MultipleChoice
        cRef={childRef}
        title="请选择"
        initData={value}
        // tabs={tabsList}
        tabs={['部门', '人员', '角色', '动态负责人']}
        getDataBack={getDataBack}
        memberTitleList={getMemberTitleList()}
        isProcess={false}
      />
    </>
  );
};

export default ShowUserOrDept;
