export default [
  {
    text: '任务名称',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_taskName',
    schema: {
      title: '任务名称',
      type: 'string',
      widget: 'input',
      bizAlias: 'input_taskName',
      banDelete: true,
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      title: {
        title: '标题',
        description: '最多100字',
        required: true,
        type: 'string',
        max: 100,
        order: 1,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        order: 20,
        disabled: true,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '所属项目',
    name: 'project',
    icon: 'icon-shuoming',
    fieldId: 'bizSuite_projectKit',
    extraText: '关联表单',
    schema: {
      title: '所属项目',
      type: 'string',
      widget: 'bizSuite',
      hidden: true,
      properties: {
        relation_project: {
          title: '所属项目',
          type: 'object',
          widget: 'relation',
          twinWidget: 'tableRelation',
          fields: {},
          dataSource: {
            type: 'form',
            params: {},
            target: {
              appUuid: 'TJAPP-MjAyMTEyMDEwMQ==',
              bizType: 'pms_project',
              formCode: 'PROC-2A08A85D-959C-4C1E-977E-851F6306C2B8',
              appType: 1,
              source: 1,
            },
          },
          id: 'relation_project',
          choice: '0',
          required: true,
          properties: {},
        },
        select_projectStage: {
          title: '项目阶段',
          type: 'object',
          enum: [
            'option_project_record',
            'option_1',
            'option_2',
            'option_3',
            'option_4',
            'option_5',
            'option_project_win_bid',
            'option_project_lose_bid',
          ],
          enumNames: [
            '项目备案',
            '立项评估',
            '标前引导',
            '方案报价',
            '商务确认',
            '投标',
            '项目中标',
            '项目落标',
          ],
          extension: [
            {
              option_project_record: {
                editFreeze: false,
                deleteFreeze: true,
                sortFreeze: true,
              },
            },
            {
              option_1: {
                editFreeze: false,
                deleteFreeze: false,
                sortFreeze: false,
              },
            },
            {
              option_2: {
                editFreeze: false,
                deleteFreeze: false,
                sortFreeze: false,
              },
            },
            {
              option_3: {
                editFreeze: false,
                deleteFreeze: false,
                sortFreeze: false,
              },
            },
            {
              option_4: {
                editFreeze: false,
                deleteFreeze: false,
                sortFreeze: false,
              },
            },
            {
              option_5: {
                editFreeze: false,
                deleteFreeze: false,
                sortFreeze: false,
              },
            },
            {
              option_project_win_bid: {
                editFreeze: false,
                deleteFreeze: true,
                sortFreeze: true,
              },
            },
            {
              option_project_lose_bid: {
                editFreeze: false,
                deleteFreeze: true,
                sortFreeze: true,
              },
            },
          ],
          widget: 'select',
          id: 'select_projectStage',
          placeholder: '请选择',
          required: true,
          properties: {},
        },
      },
      isPayment: false,
      bizType: 'bizSuite_projectKit',
      extract: true,
      widget: 'bizSuite',
      contarct: [],
      bizAlias: 'bizSuite_projectKit',
    },
    setting: {
      props: {
        type: 'string',
        widget: 'billingSetting',
      },
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        type: 'string',
        hidden: '{{true}}',
      },
    },
  },
  {
    text: '开始时间',
    name: 'date',
    icon: 'icon-riqi',
    fieldId: 'date_taskStartTime',
    schema: {
      title: '开始时间',
      type: 'string',
      format: 'dateTime',
      format: 'date',
      widget: 'date',
      bizAlias: 'date_taskStartTime',
      banDelete: true,
    },
    setting: {
      format: {
        title: '日期类型',
        type: 'string',
        enum: ['dateTime', 'date'],
        enumNames: ['年-月-日 时:分:秒', '年-月-日'],
        widget: 'baseSelect',
        order: 5,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        // disabled: true,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '结束时间',
    name: 'date',
    icon: 'icon-riqi',
    fieldId: 'date_taskEndTime',
    schema: {
      title: '结束时间',
      type: 'string',
      format: 'dateTime',
      format: 'date',
      widget: 'date',
      bizAlias: 'date_taskEndTime',
      banDelete: true,
    },
    setting: {
      format: {
        title: '日期类型',
        type: 'string',
        enum: ['dateTime', 'date'],
        enumNames: ['年-月-日 时:分:秒', '年-月-日'],
        widget: 'baseSelect',
        order: 5,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        // disabled: true,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '优先级',
    name: 'projectStage',
    icon: 'icon-shuoming',
    fieldId: 'select_taskPriority',
    schema: {
      title: '优先级',
      type: 'object',
      widget: 'select',
      bizAlias: 'select_taskPriority',
      banDelete: false,
      placeholder: '请选择',
      colorEnable: true,
      extension: [
        {
          option_0: {
            color: '#ffccc7',
          },
        },
        {
          option_1: {
            color: '#ffe7ba',
          },
        },
        {
          option_2: {
            color: '#fffdcf',
          },
        },
      ],
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
      },
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['高', '中', '低'],
        widget: 'selectOptions',
        order: 12,
      },
    },
  },
  {
    text: '任务描述',
    name: 'textarea',
    icon: 'icon-duohangshurukuang',
    fieldId: 'textarea_taskDescription',
    schema: {
      title: '任务描述',
      type: 'string',
      format: 'textarea',
      widget: 'textarea',
      bizAlias: 'textarea_taskDescription',
      banDelete: false,
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: false,
      },
    },
  },
];
