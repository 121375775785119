/*
 * :file description: 表格列表
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\TableList\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-25 09:52:50
 * :last editor: 黄艺
 * :date last edited: 2022-04-13 17:46:44
 */
import React from 'react';
import { useDrop, XYCoord } from 'react-dnd';
import {
  MouseEvent,
  Key,
  ReactChild,
  ReactFragment,
  ReactPortal,
  Suspense,
  useRef,
  useState,
  useEffect,
} from 'react';
import classnames from 'classnames';
import { message, Button, Tooltip, Modal, Input } from 'antd';
import { useStore, useGlobal } from '@/components/FormGenerator/utils/hooks';
import {
  dropItem,
  copyItem,
  transformProps,
} from '@/components/FormGenerator/utils';
import { Scrollbars } from 'react-custom-scrollbars';
import './index.less';
import IconFont from '@/components/IconFont';
import { useLocation } from 'umi';
import { getRelationByFieldId } from '@/services/form-generator';
import { useDebounce } from '@/components/FormGenerator/utils';
import CollapseCom from '@/components/FormGenerator/components/Canvas/core/CollapseCom';
import { nanoid } from '@/components/FormGenerator/utils';

export const TableWidgetsWidteList = [
  'input',
  'textarea',
  'number',
  'select',
  'multiSelect',
  'date',
  'dateRange',
  'phone',
  'picture',
  'money',
  'department',
  'innerContact',
  'attachment',
  'address',
  'relation',
  'email',
  'processRelation',
  'idCard',
];

const TableList = (props: { schema: any }) => {
  const location = useLocation();
  const { versionValue } = location?.query || {};
  const { flatten, onFlattenChange, itemError, userProps, widgets, selected } =
    useStore();
  const { schema } = props;
  const positionRef = useRef<string>(schema.$id);
  const dropIdRef = useRef<string>('inside');
  const setGlobal = useGlobal();
  const { canDelete = true } = userProps;
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteValue, setDeleteValue] = useState('');
  const [showDeleteValueTip, setShowDeleteValueTip] = useState(false);
  const [currentTableSelcetedField, setCurrentTableSelcetedField] =
    useState('');
  const [hasRelationForm, setHasRelationForm] = useState(false);
  const [relationVisible, setRelationVisible] = useState(false);
  const [associationRuleRelations, setAssociationRuleRelations] = useState([]); // 业务关联列表
  const [dataAssociations, setDataAssociations] = useState([]); // 关联其他表单数据
  const [dataFillingRelations, setDataFillingRelations] = useState([]); // 数据填充

  const boxRef = useRef<Element>(null);

  useEffect(() => {
    const { type, formCode } = location?.query || {};

    if (
      flatten[schema?.$id]?.children.find((item) => item === selected) &&
      type === '1'
    ) {
      console.log('ererererere');
      const arr = selected.split('/');
      getRelationByFieldId({
        formCode,
        fieldId: arr[arr.length - 1],
      }).then((res) => {
        console.log(res, '~~~~~~表格中查询字段是否被引用');
        if (res.code === 200) {
          if (
            res?.data?.associationRuleRelations.length > 0 ||
            res?.data?.dataAssociations.length > 0 ||
            res?.data?.dataFillingRelations.length > 0
          ) {
            setHasRelationForm(true);
          } else {
            setHasRelationForm(false);
          }
          setAssociationRuleRelations(
            res?.data?.associationRuleRelations ?? [],
          );
          setDataAssociations(res?.data?.dataAssociations ?? []);
          setDataFillingRelations(res?.data?.dataFillingRelations ?? []);
        }
      });
    }
  }, [selected]);

  const computeTempDiv = (dragOffset: XYCoord) => {
    const schemaItems = boxRef.current!.querySelector('.subform-container');
    const tempDiv = document.querySelector('.placeholder');
    tempDiv?.parentNode?.removeChild(tempDiv);

    schemaItems?.childNodes?.forEach((item: ChildNode) => {
      if (
        !(dragOffset?.x + 60 > (item as HTMLElement)?.getBoundingClientRect().x)
      ) {
        let tempDiv = null;
        if (document.querySelectorAll('.placeholder').length === 0) {
          tempDiv = document.createElement('li');
          tempDiv.className = 'placeholder';
        }
        if (tempDiv) {
          item.parentNode?.insertBefore(tempDiv, item);
        }
        if (flatten?.[selected]?.schema?.widget === 'tableList') {
          dropIdRef.current = selected;
          positionRef.current = 'inside';
        } else {
          const tempDiv = document.querySelector('.placeholder')
            ?.nextSibling as HTMLElement;
          dropIdRef.current = tempDiv.dataset.widget;
          positionRef.current = 'up';
        }
        return;
      }
    });
  };

  const [{ canDrop, isOver, didDrop }, dropRef] = useDrop({
    accept: 'box',
    drop: async (item, monitor) => {
      // 如果 children 已经作为了 drop target，不处理
      const didDrop = monitor.didDrop();
      const { widget } = item?.dragItem?.schema || {};
      if (didDrop || itemError?.length) {
        return;
      }
      if (!TableWidgetsWidteList.includes(widget)) {
        message.warning('该控件不能添加进表格');
        return;
      }
      const [newFlatten, newId] = dropItem({
        dragId: item.$id, // 内部拖拽用dragId
        dragItem: item.dragItem, // 从左边栏过来的，用dragItem
        dropId: schema.$id,
        position: 'inside',
        flatten,
      });

      onFlattenChange(newFlatten);
      setGlobal({ selected: newId });
      const tempDiv = document.querySelector('.placeholder');
      tempDiv?.parentNode?.removeChild(tempDiv);
    },
    hover: (_item, monitor) => {
      const item = monitor.getItem();
      const { widget } = item?.dragItem?.schema || {};
      // 只检查被hover的最小元素
      if (!TableWidgetsWidteList.includes(widget)) {
        return;
      }
      const didHover = monitor.isOver({ shallow: true });
      if (didHover) {
        const dragOffset = monitor.getSourceClientOffset();
        computeTempDiv(dragOffset!);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
      didDrop: monitor.didDrop(),
    }),
  });

  const handleClick = async (
    e: MouseEvent<HTMLLIElement, MouseEvent>,
    id: string,
  ) => {
    e.stopPropagation();
    if (itemError?.length) return;
    setGlobal({ lastFlatten: flatten });
    setGlobal({ selected: id });
  };

  const handleItemCopy = async (
    e: MouseEvent<HTMLSpanElement, MouseEvent>,
    $id: string,
  ) => {
    e.stopPropagation();
    if (itemError?.length) return;
    const [newFlatten, newId] = copyItem(flatten, $id);
    onFlattenChange(newFlatten);
    setGlobal({ selected: newId });
  };

  const deleteItem = (
    e: MouseEvent<HTMLSpanElement, MouseEvent>,
    $id: string | number,
  ) => {
    e.stopPropagation();
    const newFlatten = { ...flatten };
    let newSelect = '#';
    try {
      const parent = newFlatten[$id].parent;
      const siblings = newFlatten[parent].children;
      const idx = siblings.indexOf($id);
      if (idx > 0) {
        newSelect = siblings[idx - 1];
      } else {
        newSelect = siblings[1] || parent;
      }
    } catch (error) {
      console.log('catch', error);
    }
    const _canDelete =
      typeof canDelete === 'function'
        ? canDelete(newFlatten[$id].schema)
        : canDelete;
    if (!_canDelete) return;
    delete newFlatten[$id];
    onFlattenChange(newFlatten);
    setGlobal({ selected: newSelect });
    setDeleteModalVisible(false);
  };

  const showDeleteModal = ($id) => (e) => {
    console.log($id, 'ssssdddd');
    e.stopPropagation();
    const { type } = location?.query || {};
    if (type === '0') {
      deleteItem(e, $id);
    } else {
      setDeleteModalVisible(true);
      setCurrentTableSelcetedField($id);
    }
  };

  const onChangeDeleteValue = useDebounce((e) => {
    setDeleteValue(e.target.value);
    if (e.target.value !== '确定删除') {
      setShowDeleteValueTip(true);
    } else {
      setShowDeleteValueTip(false);
    }
  }, 300);

  const deleteFormField = (e) => {
    e.stopPropagation();
    if (deleteValue !== '确定删除') {
      return;
    } else {
      deleteItem(e, currentTableSelcetedField);
    }
  };

  // 关联明细弹窗
  const showRelationDetailModal = (e) => {
    e.stopPropagation();
    // 从删除控件中的入口进入查看关联关系，需先隐藏删除控件
    setDeleteModalVisible(false);
    setRelationVisible(true);
  };

  dropRef(boxRef);

  const handleScrollClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
  };

  if (!isOver) {
    const tempDiv = document.querySelector('.placeholder');
    tempDiv?.parentNode?.removeChild(tempDiv);
  }

  const isHasTextArea = () => {
    const tableSchema = flatten[schema?.$id];
    const textarea = tableSchema?.children.find(
      (child: string | number) =>
        flatten?.[child]?.schema?.widget === 'textarea',
    );
    return !!textarea;
  };

  console.log(selected, schema, flatten[schema?.$id]?.children, 'sssssssss');
  return (
    <div
      className={classnames(
        'form-design-subform',
        isHasTextArea() && 'has-textarea',
      )}
    >
      <div ref={boxRef} className="subform-content">
        <div className="row-head">
          <div className="head-label"></div>
          <div className="row-num">1</div>
        </div>
        <Scrollbars onClick={handleScrollClick} style={{ position: '' }}>
          <ul className="subform-container ps ps--active-x">
            {flatten[schema?.$id]?.children.map(
              (item: string | number, index: any) => {
                const Widget = widgets[flatten?.[item]?.schema.widget];
                const usefulWidgetProps = transformProps({
                  ...flatten?.[item]?.schema,
                  value: flatten?.[item]?.schema.default,
                  disabled: flatten?.[item]?.schema.disabled,
                  readOnly: flatten?.[item]?.schema.readOnly,
                  format: flatten?.[item]?.schema.format,
                  placeholder: flatten?.[item]?.schema.placeholder,
                  // schema: flatten?.[item]?.schema,
                  ...flatten?.[item]?.schema['props'],
                });
                console.log(
                  '🚀 ~ file: index.tsx ~ line 236 ~ TableList ~ usefulWidgetProps',
                  usefulWidgetProps,
                );

                const isHideTool = usefulWidgetProps.bizAlias;

                const isRelation =
                  flatten?.[item]?.schema.widget === 'relation';

                const fieldList = isRelation
                  ? Object.keys(usefulWidgetProps?.fields || {}).map((key) => {
                      return {
                        key,
                        title: usefulWidgetProps?.fields[key].title,
                        width: usefulWidgetProps?.fields[key].width,
                        tips:
                          (usefulWidgetProps?.fields[key]?.hidden &&
                            !usefulWidgetProps?.fields[key]?.hideControls) ||
                          usefulWidgetProps?.fields[key]?.deleted
                            ? '字段已停用或删除'
                            : '',
                      };
                    })
                  : [];

                return (
                  <li
                    onClick={(e) => handleClick(e, item)}
                    key={item}
                    className={classnames(
                      'row-item',
                      selected === item && 'widget-select',
                    )}
                    data-widget={item}
                  >
                    <div
                      className={classnames(
                        'item-label',
                        isHideTool && 'hide-tool-item-label',
                        isRelation && 'linkfield-label',
                      )}
                    >
                      {flatten?.[item]?.schema.required && (
                        <span className="item-require">* </span>
                      )}
                      {isRelation && fieldList.length > 0 ? (
                        fieldList?.map(
                          (child: {
                            key: Key | null | undefined;
                            width: any;
                            title:
                              | boolean
                              | ReactChild
                              | ReactFragment
                              | ReactPortal
                              | null
                              | undefined;
                          }) => (
                            <div
                              key={child.key}
                              style={{
                                width: child?.width || '150px',
                                color: child?.tips
                                  ? 'rgba(23, 26, 29, 0.4)'
                                  : '#1f2d3d',
                              }}
                            >
                              {child.title}
                            </div>
                          ),
                        )
                      ) : (
                        <span className="form-cell">
                          {flatten?.[item]?.schema?.title}
                        </span>
                      )}
                    </div>
                    <div className="row-field">
                      <div
                        style={{
                          width: isRelation
                            ? 150 * fieldList.length + 'px'
                            : '150px',
                          minWidth: '150px',
                        }}
                      >
                        <div
                          style={{
                            width: isRelation
                              ? 150 * fieldList.length + 'px'
                              : '150px',
                          }}
                          className="fui_text"
                        >
                          {isRelation ? (
                            <div style={{ display: 'flex' }}>
                              <div style={{ width: '35px' }}>
                                <IconFont type="icon-shujuguanlian"></IconFont>
                              </div>
                              {(fieldList.length > 0 ? fieldList : [{}]).map(
                                (item) => (
                                  <div
                                    style={{
                                      width: '150px',
                                      color: 'rgba(23,26,29,0.40)',
                                    }}
                                    key={nanoid()}
                                  >
                                    暂无内容
                                  </div>
                                ),
                              )}
                            </div>
                          ) : (
                            <Suspense fallback={<div></div>}>
                              {Widget && (
                                <Widget {...usefulWidgetProps}></Widget>
                              )}
                            </Suspense>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-widget-mask">
                      {selected === item && hasRelationForm && (
                        <Tooltip
                          title="关联明细"
                          overlayInnerStyle={{ borderRadius: '6px' }}
                        >
                          <div
                            className="widget-relation_detail"
                            onClick={showRelationDetailModal}
                          >
                            <IconFont
                              type="guanlianmingxi"
                              className="relation_detail_icon"
                            />
                          </div>
                        </Tooltip>
                      )}
                      {!isHideTool && (
                        <div className="widget-btn-pane">
                          <IconFont
                            onClick={(e) => handleItemCopy(e, item)}
                            className="btn-copy"
                            type="icon-fuzhi"
                          ></IconFont>
                          <IconFont
                            className="btn-delete"
                            type="icon-shanchu"
                            // onClick={(
                            //   e: MouseEvent<HTMLSpanElement, MouseEvent>,
                            // ) => deleteItem(e, item)}
                            onClick={showDeleteModal(item)}
                          ></IconFont>
                        </div>
                      )}
                    </div>
                    <div className="sub-resize-bar"></div>
                  </li>
                );
              },
            )}
            {flatten[schema?.$id]?.children.length === 0 && (
              <div className="row-empty">
                <span>从左侧拖拽来添加字段</span>
              </div>
            )}
          </ul>
        </Scrollbars>
      </div>
      <Button icon={<IconFont type="icon-tianjia1" />}>
        {schema.actionName}
      </Button>
      {deleteModalVisible ? (
        <Modal
          width={520}
          title={null}
          footer={null}
          visible={deleteModalVisible}
          closable={false}
          bodyStyle={{ padding: '32px', overflowY: 'auto' }}
        >
          <div className="deletModalWrap">
            <div className="deletModalTitleWrap">
              <IconFont type="jinggao" className="deleteIcon" />
              <div className="deletModalTitle">确定删除控件吗？</div>
            </div>
            <div className="deleteModal">
              {hasRelationForm ? (
                <div className="deleteTip">
                  删除控件并保存表单后，该表单对应控件的历史数据会被清除且不可恢复，且与其他表单的关联将失效&nbsp;&nbsp;
                  <a onClick={showRelationDetailModal}>查看关联关系</a>
                </div>
              ) : (
                <div className="deleteTip">
                  删除控件并保存表单后，该表单对应控件的历史数据会被清除且不可恢复
                </div>
              )}
              <div className="comfirmDelete">
                如确定删除，请输入文案“确定删除”
              </div>
              <Input
                placeholder="请输入 确定删除"
                onChange={onChangeDeleteValue}
              />
              {showDeleteValueTip && (
                <div className="errorTip">请输入文案“确定删除”</div>
              )}
            </div>
            <div className="deleteButtom">
              <Button
                className="cancelDelete"
                onClick={() => {
                  setDeleteModalVisible(false);
                  setDeleteValue('');
                  setShowDeleteValueTip(false);
                }}
              >
                取消
              </Button>
              <Button
                type="primary"
                disabled={deleteValue === ''}
                onClick={deleteFormField}
              >
                确定
              </Button>
            </div>
          </div>
        </Modal>
      ) : null}
      <Modal
        width={600}
        title="关联明细"
        footer={null}
        visible={relationVisible}
        closeIcon={
          <IconFont type="icon-close-line" style={{ fontSize: '16px' }} />
        }
        bodyStyle={{
          padding: '24px',
          paddingTop: '15.5px',
          overflowY: 'scroll',
          minHeight: '212px',
        }}
        onCancel={() => {
          setRelationVisible(false);
        }}
      >
        <div className="relationModalWrap">
          <div className="relationTip">该控件被以下表单关联引用</div>
          <CollapseCom
            associationRuleRelations={associationRuleRelations}
            dataAssociations={dataAssociations}
            dataFillingRelations={dataFillingRelations}
            versionValue={versionValue}
          />
        </div>
      </Modal>
    </div>
  );
};

export default TableList;
