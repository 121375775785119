import React from 'react';
import { Radio, Space } from 'antd';

import Styles from './index.less';

export default (props) => {
  const { schema, value, onChange } = props;
  const { enum: enums = [], enumNames = [] } = schema || {};

  return (
    <div>
      <Radio.Group value={value} onChange={(e) => onChange(e.target.value)}>
        <Space direction="vertical">
          {enums.map((item, index) => {
            return <Radio value={item}>{enumNames[index]}</Radio>;
          })}
        </Space>
      </Radio.Group>
      <img
        src={`https://cdn.tungee.com/warehouse/dingtalk/crm/lowcode/bg_${value}.png`}
        alt=""
        className={Styles.tips}
      />
    </div>
  );
};
