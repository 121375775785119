import { Select } from 'antd';

export default (props) => {
  const { value, enumNames, enum: enumKey, onChange, ...reset } = props;
  const onSelectChange = (value) => {
    const data = value?.map((item) => item?.value);
    onChange(data);
  };

  return (
    <Select
      value={value}
      onChange={onSelectChange}
      labelInValue
      mode="multiple"
    >
      {enumNames.map((name, index) => {
        return (
          <Select.Option key={enumKey[index]} value={enumKey[index]}>
            {name}
          </Select.Option>
        );
      })}
    </Select>
  );
};
