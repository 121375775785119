/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DataFilters\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-31 14:07:27
 * :last editor: 黄艺
 * :date last edited: 2022-03-22 18:00:48
 */
import { FC } from 'react';
import { WidgetProps } from 'typings';
import IconFont from '@/components/IconFont';
import FilterModal from './FilterModal';
import c from './index.less';
import form from '@/services/form';
import RelationDataFilters from './components/RelationDataFilters';
import BaseDataFilters from './components/BaseDataFilters';

export const customerTitleMap = new Map([
  ['crm_customer', '企业客户'],
  ['crm_customer_personal', '个人客户'],
]);

const DataFilters: FC<WidgetProps> = (props) => {
  const { addons } = props;
  const { getValues } = addons;
  const formData = getValues();
  const relateSource = formData.relateSource;

  return (
    <div style={{ width: '100%' }}>
      {relateSource ? (
        <RelationDataFilters {...props} />
      ) : (
        <BaseDataFilters {...props} />
      )}
    </div>
  );
};

export default DataFilters;
