/*
 * :file description: 企业客户
 * :name: \low-code-platform\src\components\FormGenerator\settings\presetFormSettings\customer.js
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-12 13:38:57
 * :last editor: 黄艺
 * :date last edited: 2022-03-15 11:45:00
 */

export default [
  {
    text: '客户名称',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_K2U5DHAA',
    schema: {
      title: '客户名称',
      type: 'string',
      widget: 'input',
      bizAlias: 'customer_name',
      print: true,
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入组织全称',
        default: '请输入组织全称',
        max: 100,
        order: 10,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        order: 20,
        default: true,
        disabled: true,
      },
    },
  },
  {
    text: '地址',
    name: 'address',
    icon: 'icon-weizhi',
    fieldId: 'address_172MESB6GAF40',
    schema: {
      title: '地址',
      type: 'object',
      widget: 'address',
      bizAlias: 'address',
      required: false,
      print: true,
      // items: {
      //   type: 'object',
      //   properties: {},
      // },
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      needDetail: {
        title: '格式',
        type: 'number',
        default: 2,
        enum: [0, 1, 2],
        enumNames: ['省市', '省市区', '省市区-街道'],
        widget: 'radio',
        className: 'my-radioAddress',
      },

      default: {
        title: '默认值',
        type: 'object',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'numberLocation'],
        enumNames: ['自定义', '号码归属地'],
      },
    },
  },
  {
    text: '客户跟进状态',
    name: 'salesStage',
    fieldId: 'select_KI5S975E',
    icon: 'icon-shuoming',
    schema: {
      title: '客户跟进状态',
      type: 'string',
      widget: 'salesStage',
      bizAlias: 'customer_follow_up_status',
    },
    setting: {
      info: {
        type: 'string',
        widget: 'jumplink',
        props: {
          message: '',
          description:
            '当前控件是客户管理特殊组件，如需对其选项进行设置请点击下方链接',
          type: 'info',
          showIcon: true,
          src: 'https://www.baidu.com',
        },
      },
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        order: 20,
        default: true,
        disabled: true,
      },
    },
  },
  {
    text: '客户类型',
    name: 'select',
    icon: 'icon-danxuankuang1',
    schema: {
      title: '客户类型',
      type: 'object',
      enum: ['option_0', 'option_1', 'option_2'],
      enumNames: ['选项1', '选项2', '选项3'],
      widget: 'select',
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['选项1', '选项2', '选项3'],
        widget: 'selectOptions',
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '客户状态',
    name: 'select',
    icon: 'icon-danxuankuang1',
    schema: {
      title: '单选',
      type: 'object',
      enum: ['option_0', 'option_1', 'option_2'],
      enumNames: ['选项1', '选项2', '选项3'],
      widget: 'select',
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['选项1', '选项2', '选项3'],
        widget: 'selectOptions',
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '重要程度',
    name: 'select',
    icon: 'icon-danxuankuang1',
    schema: {
      title: '单选',
      type: 'object',
      enum: ['option_0', 'option_1', 'option_2'],
      enumNames: ['选项1', '选项2', '选项3'],
      widget: 'select',
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['选项1', '选项2', '选项3'],
        widget: 'selectOptions',
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '客户来源',
    name: 'select',
    icon: 'icon-danxuankuang1',
    schema: {
      title: '单选',
      type: 'object',
      enum: ['option_0', 'option_1', 'option_2'],
      enumNames: ['选项1', '选项2', '选项3'],
      widget: 'select',
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['选项1', '选项2', '选项3'],
        widget: 'selectOptions',
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '客户行业',
    name: 'select',
    icon: 'icon-danxuankuang1',
    schema: {
      title: '单选',
      type: 'object',
      enum: ['option_0', 'option_1', 'option_2'],
      enumNames: ['选项1', '选项2', '选项3'],
      widget: 'select',
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['选项1', '选项2', '选项3'],
        widget: 'selectOptions',
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '备注',
    name: 'textarea',
    icon: 'icon-duohangshurukuang',
    schema: {
      title: '备注',
      type: 'string',
      format: 'textarea',
      widget: 'textarea',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
];
