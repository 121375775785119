/*
 * :file description:
 * :name: \ui-renderd:\progree\low-code-platform\tg-renderer\packages\form-render\src\widgets\EditAttachment\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-27 20:28:03
 * :last editor: Please set LastEditors
 * :date last edited: 2022-05-07 14:55:36
 */
import React, { useState, useRef, useEffect } from 'react';
import { Upload, UploadProps, Modal, Button, Progress, message } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import formApi from '@/services/form';
import IconFont from '@/components/IconFont';
import c from './index.less';
import './index.less';
import { getFileIcon } from './utils';
import filesize from 'filesize';
import FileViewer from '@/components/FileViewer';
import { dowmLoadFile } from '@/utils';

const DEFAULT_UPLOAD_CONFIG: UploadProps = {
  multiple: true,
  maxCount: 9,
};

const canViwerType = [
  'png',
  'PNG',
  'bmp',
  'BMP',
  'tiff',
  'TIFF',
  'gif',
  'GIF',
  'jpeg',
  'JPEG',
  'svg',
  'pdf',
  'PDF',
];

const EditAttachment = (props: UploadProps & { [key: string]: any }) => {
  const uploadRef = useRef();
  const btnRef = useRef();
  const { onChange = () => {}, value = [] } = props;
  const maxCount = DEFAULT_UPLOAD_CONFIG.maxCount || props.maxCount;
  const [visible, setVisible] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [modalKey, setModalKey] = useState<string>();
  const [uploadIngList, setUploadIngList] = useState([]);
  const [random, setRandom] = useState(0);
  const [fileType, setFileType] = useState('');
  const [filePath, setFilePath] = useState('');
  const [fileName, setFileName] = useState('');

  const modelRef = useRef();

  const customRequest = (e) => {
    const file = e.file;
    const name = file.name;

    if (file.size > 1024 * 1024 * 20) {
      const errorEvent = new Error('文件不能超过20M');
      e.onError(errorEvent);
      setRandom(Math.random());
      return;
    }

    let formdata = new FormData();
    formdata.append('file', e.file);
    formdata.append('keyPrefix', 'crm-foundation-form/instance/');
    formApi
      .uploadImages(formdata, {
        progressCallback: (percent) => {
          e.percent = percent;
          e.onProgress(e);
          setRandom(Math.random());
        },
      })
      .then(
        (res) => {
          e.onSuccess(
            {
              name,
              url: res?.data,
            },
            file,
          );
          getSuccessCount();
        },
        (res) => {
          e.onError(res);
        },
      );
  };

  const handleBeforeUpload = (file: UploadFile, fileList: UploadFile[]) => {
    setVisible(true);
    setUploadIngList(fileList || []);
    setAttemptCount(fileList?.length);
    return true;
  };

  const handleOk = () => {
    setVisible(false);
    const fileList = uploadRef?.current?.fileList;
    const newFiles = (fileList || [])
      .filter((v) => v.status === 'done')
      .map((v) => {
        const fileType = v.name.split('.').pop().toLowerCase();
        return {
          url: v.response.url,
          fileName: v.name,
          fileType: fileType,
          fileSize: v.size,
        };
      });

    const allFiles = [...value, ...newFiles];
    if (allFiles.length > maxCount) {
      message.error(`最多上传${maxCount}个附件, 请重新上传`);
    } else {
      onChange(allFiles);
    }
  };

  const getSuccessCount = () => {
    setSuccessCount(
      uploadRef?.current?.fileList?.filter((v) => v.status === 'done')
        ?.length ?? 0,
    );
  };

  const deleteFile = (index) => {
    value.splice(index, 1);
    onChange(value);
  };

  useEffect(() => {
    setAttemptCount(0);
    setSuccessCount(0);
    if (!visible) {
      setModalKey(nanoid(12));
    }
  }, [visible]);

  const itemRender = () => {
    const fileList = uploadRef?.current?.fileList?.length
      ? uploadRef?.current?.fileList
      : uploadIngList;
    let arr: any[] = [];
    fileList &&
      fileList.map((val, key) => {
        console.log(val, 4444444444444);
        const fileName = val.name;
        const fileType = fileName.split('.').pop().toLowerCase();
        const fileIcon = getFileIcon(fileType);
        const percent = val.percent;
        const status = val.status; // "done" "uploading" "error"
        let satatusRender = () => {
          if (status == 'done') {
            return <div className={c['upload-item-status']}>上传完成</div>;
          } else if (status == 'error') {
            return (
              <div className={`${c['upload-item-status']} ${c.error}`}>
                {val?.error?.message || '上传失败'}
              </div>
            );
          }

          return (
            <div className={c.progress}>
              <Progress percent={percent} showInfo={false} />
            </div>
          );
        };
        arr.push(
          <div className={c['upload-item']} key={key}>
            <img src={fileIcon} className={c.icon} />
            <div className={c['upload-item-content']}>
              <div className={c['upload-item-tilte']}>{fileName}</div>
              {satatusRender()}
            </div>
          </div>,
        );
      });
    return arr;
  };

  const showViewerModal = (item) => {
    setFileType(item.fileType);
    setFilePath(item.url);
    // let name = item.fileName.split('.')
    setFileName(item.fileName);
    modelRef?.current?.openModal();
  };

  const donwLoad = (item) => {
    if (!item.url) return;
    dowmLoadFile(item.url, item.fileType);
  };

  if (props.readOnly) {
    // return <span className='showNoEditText'>{props.value || ''}</span>
  }

  return (
    <div className={c.attachment}>
      {value?.length < maxCount && !props.readOnly && (
        <span className={c.boot_text}>
          <span
            onClick={() => {
              _.delay(() => {
                btnRef?.current?.click();
              }, 0);
            }}
          >
            添加附件
          </span>
          （最多上传{maxCount}个附件）
        </span>
      )}
      <div className={c.list}>
        {!visible &&
          (value || []).map((item, idx) => {
            const fileIcon = getFileIcon(item.fileType);
            const fileSizeStr = filesize(item?.fileSize);
            return (
              <div key={idx} className={c['list-item']}>
                <img src={fileIcon} className={c['list-item-img']} />
                <div className={c['list-item-content']}>
                  <div className={c['list-item-info']}>
                    <div className={c['list-item-title']}>{item?.fileName}</div>
                    <div className={c['list-item-size']}>{fileSizeStr}</div>
                  </div>
                  <div className={c['list-item-op']}>
                    {canViwerType.includes(item.fileType) && (
                      <Button type="link" onClick={() => showViewerModal(item)}>
                        查看
                      </Button>
                    )}

                    <Button type="link" onClick={() => donwLoad(item)}>
                      下载
                    </Button>
                    {!props.readOnly && (
                      <Button
                        type="link"
                        onClick={() => {
                          deleteFile(idx);
                        }}
                      >
                        删除
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {/* <FileViewer ref={modelRef} fileType='png' filePath='https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&fmt=auto&app=138&f=JPEG?w=632&h=500' /> */}

      <FileViewer
        ref={modelRef}
        title={fileName}
        fileType={fileType}
        filePath={filePath}
      />
      <Upload
        key={modalKey}
        style={{ display: 'none', visibility: 'hidden' }}
        ref={uploadRef}
        {...DEFAULT_UPLOAD_CONFIG}
        customRequest={customRequest}
        beforeUpload={handleBeforeUpload}
        showUploadList={false}
        className={c.upload}
      >
        <Button ref={btnRef}></Button>
      </Upload>
      <Modal
        title="上传文件"
        className="upload_images"
        visible={visible}
        onCancel={() => setVisible(false)}
        width={400}
        destroyOnClose={true}
        footer={[
          <Button type="primary" onClick={handleOk}>
            确定
          </Button>,
        ]}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <div
          style={{
            marginBottom: '12px',
            lineHeight: '22px',
            color: 'rgba(23, 26, 29, 0.60)',
          }}
        >
          本次选择{attemptCount}个文件，可以发送
          {successCount}个
        </div>
        <div className={c.upload}>{itemRender()}</div>
      </Modal>
    </div>
  );
};

export default EditAttachment;
