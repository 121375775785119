/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\ShowTipsText\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-31 14:07:27
 * :last editor: hyw
 * :date last edited: 2022-04-02 14:23:49
 */
import './index.less';

const ShowTipsText = (props) => {
  return (
    <div className="tipsText">
      该控件类型不支持在公海中编辑，可在领取客户后重新修改
    </div>
  );
};

export default ShowTipsText;
