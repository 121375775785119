/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\form-render-core\src\edit-form\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-22 15:26:36
 * :last editor: 黄艺
 * :date last edited: 2022-03-21 16:14:10
 */
import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { Drawer, DrawerProps, Button, Space, Modal } from 'antd';
import { nanoid } from 'nanoid';
import FormEdit from './form';
import IconFont from '../../../components/IconFont';

import utils from '../../../utils';

import c from './index.less';
import './index.less';

interface IFormEditProps {
  formCode: string;
  renderFormat: string;
  [key: string]: any;
}

const EditForm = (props: DrawerProps & IFormEditProps, ref) => {
  const { instanceId, todoList, renderFormat } = props;
  const formRef = useRef();
  const [editKey, setEditKey] = useState(nanoid(12));
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const formCode = props?.formcode || utils.getFormCode();
  // 伸缩弹窗功能
  const [drawerWidth, setDrawerWidth] = useState(600);
  const [isShrink, setIsShrink] = useState(false);

  useImperativeHandle(ref, () => ({
    showModal: () => {
      setVisible(true);
      setEditKey(nanoid(12));
    },
  }));

  const formatTitle = (title: string) => {
    return `编辑${title}`;
  };

  const init = (data = {}) => {
    setTitle(data?.title);
  };

  const cb = () => {
    setLoading(false);
    setVisible(false);
    props.cb();
  };

  const onOk = () => {
    // TODO
    // 调用编辑
    setLoading(true);
    formRef.current.doEdit();
    console.log('formRef: ', formRef);
  };

  const onClose = () => {
    setVisible(false);
  };

  //  改变详情弹窗功能
  const changeWidth = (bool) => {
    setDrawerWidth(bool ? 600 : window.innerWidth > 1366 ? 1100 : 1000);
    setIsShrink(!bool);
  };

  let renderContent = null;

  switch (renderFormat) {
    case 'drawer':
      renderContent = (
        <div className={c.t_edit_drawer}>
          <Drawer
            key={editKey}
            title={formatTitle(title)}
            width={drawerWidth}
            placement="right"
            maskStyle={{
              background: 'transparent',
            }}
            visible={visible}
            closeIcon={null}
            onClose={onClose}
            className="form_detail_drawer"
            destroyOnClose={true}
            footer={
              <Space className={c.submit}>
                <Button onClick={onClose}>取消</Button>
                <Button loading={loading} type="primary" onClick={onOk}>
                  提交
                </Button>
              </Space>
            }
          >
            <div className={c.enlargeOrshrink}>
              <IconFont
                type="icon-zhankai1"
                className={isShrink ? c.transForm : ''}
                onClick={() => changeWidth(isShrink)}
              />
              {/* <img src={shrink} alt="" /> */}
            </div>
            <FormEdit
              ref={formRef}
              instanceId={instanceId}
              todoList={todoList}
              renderFormat={renderFormat}
              formcode={formCode}
              init={init}
              setLoading={setLoading}
              cb={cb}
            />
          </Drawer>
        </div>
      );
      break;
    case 'modal':
      renderContent = (
        <div className={c.t_edit_modal}>
          <Modal
            key={editKey}
            title={formatTitle(title)}
            width={600}
            visible={visible}
            onCancel={onClose}
            footer={
              <Space className={c.submit}>
                <Button onClick={onClose}>取消</Button>
                <Button loading={loading} type="primary" onClick={onOk}>
                  提交
                </Button>
              </Space>
            }
            closeIcon={<IconFont type="icon-Close" />}
          >
            <FormEdit
              ref={formRef}
              instanceId={instanceId}
              todoList={todoList}
              renderFormat={renderFormat}
              formcode={formCode}
              init={init}
              setLoading={setLoading}
              cb={cb}
            />
          </Modal>
        </div>
      );
      break;
    case 'page':
      renderContent = (
        <div className={c.t_edit_form_page}>
          <FormEdit
            ref={formRef}
            instanceId={instanceId}
            todoList={todoList}
            renderFormat={renderFormat}
            formcode={formCode}
            init={init}
            cb={cb}
          />
        </div>
      );
  }

  return renderContent;
};

export default forwardRef(EditForm);
