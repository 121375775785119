/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DefaultValueSelector\components\formula-editor\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-03 15:22:56
 * :last editor: 黄艺
 * :date last edited: 2022-01-07 09:40:14
 */
import { Modal, Button, Row, Col, message } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import {
  getLabelMap,
  getValue,
  setValue as setDefaultValue,
} from './common/formulaEditor';
import EditArea from './EditArea';
import Formula from './Formula';
import Variable from './Variable';
import c from '../../index.less';
import IconFont from '@/components/IconFont';
import { idToSchema } from '@/components/FormGenerator/utils';

export const EditorContext = React.createContext({});

const FormulaEditor = ({ children, flatten, widgetProps, selected }) => {
  const [visible, setVisible] = useState(false);
  const [showRestore, setShowRestore] = useState(false);
  const [errorField, setErrorField] = useState(false);
  const [updateContent, setUpdateContent] = useState('');
  const [editorInstance, setEditorInstance] = useState();
  const contentRef = useRef('');
  const { setValues, getValues } = widgetProps.addons;
  const formData = getValues();
  const currentFlatten = flatten[formData.$id];
  const { rely } = currentFlatten?.schema || {};

  const handleOk = () => {
    const value = getValue(editorInstance);
    console.log('value:', value);

    setValues({ ...formData, rely: value });
    handleClose();
  };

  const handleClose = () => {
    setShowRestore(false);
    setVisible(false);
  };

  const ModalTitle = () => {
    return (
      <div className={c.title_wrapper}>
        <div className={c.modal_title}>
          <span className={c.title}>公式编辑</span>
          <span className={c.desc}>使用数学运算符编辑公式</span>
        </div>
        <IconFont type="guanbi" onClick={handleClose} />
      </div>
    );
  };

  useEffect(() => {
    // 判断是否显示恢复按钮
    if (
      !showRestore &&
      rely &&
      contentRef.current !== updateContent &&
      rely.formula !== (editorInstance ? getValue(editorInstance).formula : '')
    ) {
      if (errorField) return;
      setShowRestore(true);
    }
    contentRef.current = updateContent;
  }, [errorField, formData, updateContent]);

  useEffect(() => {
    if (errorField) {
      message.warning('当前公式存在无效字段');
      setShowRestore(false);
    }
  }, [errorField]);

  const restoreFormula = () => {
    const schema = idToSchema(flatten);
    const labelMap = getLabelMap(schema.properties);
    editorInstance &&
      setTimeout(() => {
        setDefaultValue(rely?.formula, labelMap, editorInstance);
      }, 200);
  };

  return (
    <EditorContext.Provider
      value={{
        flatten,
        editorInstance,
        setEditorInstance,
        updateContent,
        setUpdateContent,
        widgetProps,
        selected,
        setErrorField,
      }}
    >
      <Modal
        className={c.modal_wrap}
        visible={visible}
        width={800}
        closable={false}
        title={<ModalTitle />}
        onOk={handleOk}
        onCancel={handleClose}
        destroyOnClose
        closeIcon={<IconFont type="icon-Close" />}
      >
        <Row gutter={10}>
          <Col span={24} className={c.edit_box}>
            <div className={c.edit_wrap}>
              <div className={c.title}>
                <span>{formData.title} =</span>
                {showRestore && (
                  <a onClick={restoreFormula}>恢复上次保存公式</a>
                )}
              </div>
              <EditArea />
            </div>
          </Col>
        </Row>
        <Row gutter={10}>
          <span className={c.edit_desc}>
            支持英文模式下运算符（+、-、*、/、&gt;、&lt;、==、!=、&lt;=、&gt;=）
          </span>
        </Row>
        <Row gutter={10}>
          <span className={c.edit_desc}>
            参考场景：根据输入的数量和单价，自动计算出金额，则可将计算公式设置为：数量*单价
          </span>
        </Row>
        <Row gutter={10} className={c.footer}>
          <Col flex="252px">
            <Variable flatten={flatten} selected={selected} />
          </Col>
          <Col flex="1">
            <Formula />
          </Col>
        </Row>
      </Modal>
      <Button onClick={() => setVisible(true)} block>
        {children}
      </Button>
    </EditorContext.Provider>
  );
};

export default FormulaEditor;
