import React, { useState, useEffect } from 'react';
import { Input, Select, Button } from 'antd';
import { IFormCompProps } from '../../typings';
import { isJSON, customJP } from '../../utils';
import countryCodes from './countryCodes';
import c from './index.less';

type WidgetProps = IFormCompProps;
const { Option } = Select;

const PhoneWidget = (props: WidgetProps) => {
  const { value, onChange = () => {}, schema = {} } = props;
  const {
    value: initialValue,
    mode: initialMode,
    countryCode: initialCountryCode,
    areaNumber: initialAreaNumber,
    ...rest
  } = isJSON(value)
    ? customJP(value)
    : {
        value: undefined,
        mode: undefined,
        countryCode: undefined,
        areaNumber: undefined,
      };

  const { placeholder = '请输入' } = schema;

  const [phoneNumber, setPhoneNumber] = useState(initialValue);
  const [mode, setMode] = useState(initialMode);
  const [countryCode, setCountryCode] = useState(initialCountryCode);
  const [areaNumber, setAreaNumber] = useState(initialAreaNumber);
  const [currentCode, setCurrentCode] = useState(rest || {});

  useEffect(() => {
    const payload = {
      value: phoneNumber,
      mode,
      countryCode,
      areaNumber,
      ...currentCode,
    };
    onChange(phoneNumber ? JSON.stringify(payload) : '');
  }, [phoneNumber, mode, countryCode, areaNumber]);

  useEffect(() => {
    if (value && isJSON(value)) {
      const valueObj = customJP(value);
      setPhoneNumber(valueObj.value);
      setMode(valueObj.mode);
      setCountryCode(valueObj.countryCode);
      setAreaNumber(valueObj.areaNumber);
    }
  }, [value]);

  const handleSelect = (cCode: string) => {
    setCountryCode(cCode);
    const curCode = countryCodes.find((item) => item.countryCode === cCode);
    setCurrentCode({ ...curCode });
  };

  return (
    <div className={c.container}>
      <Input.Group compact>
        {mode === 'phone' && <Button className={c.mobile}>手机</Button>}
        {mode === 'tel' && <Button className={c.telephone}>固话</Button>}
        {mode === 'phone_tel' && (
          <Select
            className={c.phoneType}
            defaultValue={'phone'}
            value={mode}
            onChange={(e) => {
              setMode(e);
              setAreaNumber(undefined);
            }}
          >
            <Option value="phone">手机</Option>
            <Option value="tel">固话</Option>
          </Select>
        )}
        <Select
          className={c.code}
          dropdownClassName={c.phoneDorpdown}
          defaultValue="+86"
          value={countryCode}
          onSelect={(e) => handleSelect(e)}
        >
          {countryCodes.map((item) => (
            <Option key={item.countryKey} value={item.countryCode}>
              {item.countryNameZh}
              {item.countryCode}
            </Option>
          ))}
        </Select>
        {mode === 'tel' && (
          <Input
            className={c.provinceCode}
            type="number"
            placeholder="区号"
            min={0}
            value={areaNumber}
            onChange={(e) => setAreaNumber(e.target.value)}
          />
        )}
        <Input
          className={c.input}
          type="number"
          min={0}
          placeholder={placeholder}
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
      </Input.Group>
    </div>
  );
};

export default PhoneWidget;
