/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DataSnippet\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-16 10:48:52
 * :last editor: hyw
 * :date last edited: 2022-01-19 11:04:41
 */
import React from 'react';
import { Popover } from 'antd';
import IconFont from '@/components/IconFont';
import c from './index.less';
import { useState } from 'react';
import popoverImg from '../../assets/form/data-snippet-popover.png';
import DataSnippetSetting from './DataSnippetSetting';
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import { useModel } from 'umi';
import { PsiBiztype, CUSTOMER_BIZ_TYPES } from './contants';
import { Select } from 'antd';
import { PROCESS_FORM_SYSTEM_FIELDS } from './contants';

const { Option } = Select;

enum formType {
  // 0:流程表单
  PROCESS_FORM,
  // 1:非流程表单
  NON_PROCESS_FORM,
}

export enum SettingType {
  // 0 系统默认
  DEFAULT,
  // 1 自定义
  CUSTOM,
}

const DataSnippet = (props) => {
  const { onChange, value } = props;
  const { settingType = 0 } = value || {};
  const [visible, setVisible] = useState(false);
  const model = useModel('useGenerator', (model) => model);
  const { selected } = useStore();
  const { schemaRef, profile } = model;
  const properties = schemaRef?.current?.properties || {};

  const isShowProcessDesign =
    !profile.bizType ||
    PsiBiztype.includes(profile.bizType) ||
    profile.formType === formType.PROCESS_FORM;

  let allProperties = {
    ...properties,
    gmtCreate: {
      id: 'gmtCreate',
      value: 'gmtCreate',
      title: '创建时间',
    },
    createUserName: {
      id: 'createUserName',
      value: 'createUserName',
      title: '创建人',
    },
  };

  if (isShowProcessDesign) {
    const value = PROCESS_FORM_SYSTEM_FIELDS?.reduce((cur, pre) => {
      cur[pre?.id] = {
        ...pre,
        title: pre?.label,
      };
      return cur;
    }, {});
    allProperties = {
      ...allProperties,
      ...value,
    };
  }
  if (profile.bizType && CUSTOMER_BIZ_TYPES.includes(profile.bizType)) {
    allProperties = {
      ...allProperties,
      allTags: {
        id: 'allTags',
        value: 'allTags',
        title: '客户标签',
      },
    };
  }

  const content = () => {
    return (
      <div className={c.popover}>
        <div className={c['popover-desc']}>
          {`数据摘要主要是用于展示数据的主要信息，适用于${
            isOpenInTripartitePlatform('1688') ? '' : '移动端列表显示、'
          }web列表详情页等场景中`}
        </div>
        <img src={popoverImg} alt="" />
      </div>
    );
  };

  const handleChange = (type: any) => {
    if (type == SettingType.CUSTOM) {
      onChange({
        propsValue: value?.propsValue || [],
        propsKey: 'dataSnippet',
        settingType: type,
      });
      setVisible(true);
    } else if (type == SettingType.DEFAULT) {
      onChange({
        propsValue: [],
        propsKey: 'dataSnippet',
        settingType: type,
      });
    }
  };

  const onOk = (values) => {
    const tempValue = (values || []).map((item) => {
      return {
        id: item.id,
        // title: item.label,
      };
    });
    onChange({
      propsKey: 'dataSnippet',
      propsValue: tempValue,
      settingType: 1,
    });
    setVisible(false);
  };

  return (
    <div className={c.container}>
      <div className={c.title}>
        <span className={c.title2}>数据摘要</span>
        <Popover placement="bottom" content={content} trigger="hover">
          <IconFont type="icon-shuoming" className={c['info-icon']}></IconFont>
        </Popover>
        <div
          className={c.edit}
          onClick={() => {
            setVisible(true);
          }}
        >
          编辑
        </div>
      </div>

      {/* <Select
        style={{ width: '100%' }}
        value={settingType}
        defaultValue={SettingType.DEFAULT}
        onSelect={(e) => handleChange(e)}
      >
        <Option value={SettingType.DEFAULT}>
          默认（展示表单前三个字段，必填字段优先）
        </Option>
        <Option value={SettingType.CUSTOM}>自定义</Option>
      </Select> */}
      <div className={c.digest}>
        <div>
          <div>当前显示：</div>
          <div className={c.fields}>
            {!!value?.propsValue?.length
              ? value.propsValue?.map((item, idx) => {
                  if (!allProperties[item.id]) {
                    return null;
                  }
                  return (
                    <>
                      <div className={c.item} key={item.id}>
                        {allProperties[item.id].title}
                      </div>
                      {idx !== value?.propsValue?.length - 1 ? (
                        <div className={c.divider}>|</div>
                      ) : null}
                    </>
                  );
                })
              : '—'}
          </div>
        </div>
        {!value?.propsValue?.length && (
          <div className={c.tips}>默认展示表单前3个字段，必填字段优先</div>
        )}
      </div>

      <DataSnippetSetting
        visible={visible}
        initValue={value}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={onOk}
      />
    </div>
  );
};

export default DataSnippet;
