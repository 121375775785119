import React from 'react';

interface ISchema {
  [key: string]: any;
}

interface ISchemaList {
  schemaList: {
    currentFlatten?: ISchema;
    otherFlatten?: ISchema;
  };

  byoCustomer?: boolean; //当前版本 true:独立版CRM false:一方品CRM

  unionRepeated?: boolean;
  customerUnionRepeated?: boolean;
}

export default React.createContext<ISchemaList>({
  schemaList: {},
  byoCustomer: false,
  unionRepeated: false,
});
