/*
 * :file description:
 * :name: /low-code-platform/tg-renderer/packages/form-render/src/widgets/SelectOptions/index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-29 16:14:41
 * :last editor: caihua
 * :date last edited: 2023-09-24 18:28:00
 */
import { useLocation, useModel } from 'umi';
import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Space,
  Typography,
  Divider,
  Form,
  Tooltip,
  Popover,
  Switch,
} from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Column from './Column';
import c from './index.less';
import BatchModal from './BatchModal';
import OptionsAssociatedModal from './OptionsAssociatedModal/index';
import OptionsPowerModal from './OptionsAssociatedModal/power';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import { getUuid } from '@/utils';
import IconFont from '@/components/IconFont';
import selectImg from '../../assets/form/select.png';
import multiSelectImg from '../../assets/form/multiSelect.png';
import { includes } from '../../../../../../src/utils/index';

export interface IOption {
  label?: string;
  value?: string;
}

const disabledType = ['crm_customer', 'crm_customer_personal']; // 企业客户和个人客户的客户来源里面的【探迹拓客】和【探迹智能呼叫】不允许排序删除和修改
const disabledIds = ['option_doncus', 'option_call']; // option_doncus：探迹拓客， option_call：探迹智能呼叫
const disabledNames = ['智能获客', '探迹拓客', '探迹智能呼叫'];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const SelectOptionsWidget = (props) => {
  console.log(props, 'SelectOptionsWidgetProps');
  const location = useLocation();
  const { bizType } = location?.query || {};
  const { onChange, value = [], schema = {} } = props;
  const { displaySchemaString, voiceDoctorTableRelatedOption } = useStore();
  const { formData = {} } = props?.addons;
  const { widget, parentWidget } = formData;

  const [visible, setVisible] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [associationHasSet, setAssociationHasSet] = useState(false);
  const [oaVisible, setOaVisible] = useState<Boolean>(false);
  const [powerVisible, setPowerVisible] = useState<Boolean>(false);
  const [oaValue, setOaValue] = useState(null);
  const optionAssociationSettingsTip = (
    <span>根据选择的选项，显示设置的对应字段</span>
  );
  const optionAssociationPowerTip = (
    <span>在新建/编辑/筛选/查看时，仅展示用户有权限的选项</span>
  );

  const { versionLimit } = useModel('useGenerator');
  const hasColorAuth =
    versionLimit?.widgetProperties?.[widget]?.includes('optionColor');
  console.log(hasColorAuth, '----hasColorAuth');

  useEffect(() => {
    console.log(value, '----value');
    if (value) {
      setSwitchChecked(value?.[0]?.colorEnable);
    }
  }, [value]);

  const onOaChange = (payload) => {
    setOaValue(payload);
    const newVal = value.map((v) => {
      return {
        ...v,
        enumAssociatedFields: payload[v.value],
      };
    });
    console.log('收到： ', payload, newVal);
    onChange(newVal);
  };

  const handleLinkClick = () => {
    setOaVisible(true);
  };

  const handleLinkPowerClick = () => {
    setPowerVisible(true);
  };

  const associationHasBeenSet = () => {
    return (
      oaValue &&
      Object.keys(oaValue).some((key) => oaValue[key] && !!oaValue[key].length)
    );
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    // 探迹拓客/探迹智能呼叫存在的话，置顶不允许排序
    if (
      disabledIds.every((d_id) =>
        value.find((item: { value: string }) => item.value === d_id),
      )
    ) {
      if (result.destination.index <= 1) {
        return;
      }
    }

    const newItem = reorder(
      value,
      result.source.index,
      result.destination.index,
    );
    onChange(newItem);
  };

  const handleClick = () => {
    const newItem = {
      value: getUuid().toString(),
      label: `选项`,
    };
    const items = _.cloneDeep(value);
    const newValue = items.concat(newItem);
    console.log(newValue);
    onChange(newValue);
  };

  const handleChange = (data, itemValue, type) => {
    const newItems = value?.map((item) => {
      if (type === 'color' && item.value === itemValue) {
        return {
          ...item,
          enumAssociatedFieldsColors: data,
        };
      }
      if (type === 'text' && item.value === itemValue) {
        return {
          ...item,
          label: data,
        };
      }
      return item;
    });
    console.log(data, itemValue, type, newItems, '----newItems');
    onChange(newItems);
  };

  const switchChange = (val: boolean) => {
    setSwitchChecked(val);
    const newItems = value?.map((item) => {
      return {
        ...item,
        colorEnable: val,
      };
    });
    console.log(val, newItems, '----switchChange');
    onChange(newItems);
  };

  const handleDelete = (key: string) => {
    const newItems = value.filter((item) => item.value !== key);
    onChange(newItems);
  };

  // 是否设置过选项关联
  useEffect(() => {
    const bool = value?.some((v) => !!v?.enumAssociatedFields?.length);
    setAssociationHasSet(bool);
  }, [oaValue, value]);

  // 获取对应选项已设置的关联选项
  const getOAData = (key: string, associateList) => {
    const widgetObj = JSON.parse(displaySchemaString)?.properties;

    const acturlAssociateList = associateList || oaValue?.[key];
    const associateText = acturlAssociateList
      ?.map((v) => {
        const pathArr = v?.split('/');
        if (pathArr.length === 1) {
          return widgetObj[v]?.title || '说明文字';
        } else if (pathArr?.[0]?.includes('table')) {
          return (
            widgetObj?.[pathArr[0]]?.title +
            '.' +
            widgetObj?.[pathArr[0]]?.items?.properties?.[pathArr?.[1]]?.title
          );
        }
      })
      .join(',');
    if (acturlAssociateList && acturlAssociateList?.length !== 0) {
      return (
        <Tooltip placement="top" title={`已关联：${associateText}`}>
          <div
            style={{
              margin: '0 0 16px 22px',
              padding: '8px',
              borderRadius: '6px',
              background: '#F2F2F6',
            }}
          >
            <div
              style={{
                background: '#F2F2F6',
                fontFamily: 'PingFangSC-Regular',
                fontSize: '14px',
                color: 'rgba(23,26,29,0.60)',
                textAlign: 'justify',
                fontWeight: '400',
                lineHeight: '22px',
              }}
              className={c.associate_content}
            >
              {`已关联：${associateText}`}
            </div>
          </div>
        </Tooltip>
      );
    }
    return null;
  };

  const content = () => {
    return (
      <div className={c.popover}>
        <div className={c['popover-desc']}>
          开启彩色-{schema.selectType === 'select' ? '单选' : '多选'}预览样式
          <div>
            设置颜色后，{schema.selectType === 'select' ? '单选' : '多选'}
            选项展示形式如下
          </div>
        </div>
        <img
          src={schema.selectType === 'select' ? selectImg : multiSelectImg}
          alt=""
        />
      </div>
    );
  };

  // 是否需要禁用，不能删除，编辑，拖拽排序
  const getIsDisabled = (item: any) => {
    return disabledType.includes(bizType) && disabledNames.includes(item.label);
  };

  return (
    <div className={c.container}>
      <div className={c.title}>
        {hasColorAuth ? (
          <div className={c.colorSwitch}>
            <Switch
              size="small"
              checked={switchChecked}
              onChange={switchChange}
            />
            <span className={c.colorText}>彩色</span>
            <Popover placement="bottomRight" content={content} trigger="hover">
              <IconFont
                type="icon-shuoming"
                className={c['info-icon']}
              ></IconFont>
            </Popover>
          </div>
        ) : null}
      </div>
      <Form>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(droppableProvided) => (
              <div ref={droppableProvided.innerRef}>
                {value?.map((item, index) => (
                  <Draggable
                    key={item.value}
                    draggableId={item.value}
                    index={index}
                    isDragDisabled={getIsDisabled(item)}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <>
                        {item && (
                          <>
                            <Column
                              draggableProvided={draggableProvided}
                              draggableSnapshot={draggableSnapshot}
                              item={item}
                              onChange={handleChange}
                              onDelete={handleDelete}
                              isDragDisabled={getIsDisabled(item)}
                              hasColorAuth={hasColorAuth && switchChecked}
                            ></Column>
                            <div>
                              {getOAData(item.value, item.enumAssociatedFields)}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Form>
      <BatchModal
        visible={visible}
        setVisible={setVisible}
        onChange={onChange}
        items={value}
      ></BatchModal>
      <OptionsAssociatedModal
        visible={oaVisible}
        setVisible={setOaVisible}
        onChange={onOaChange}
        value={oaValue || value}
        info={props}
      ></OptionsAssociatedModal>
      <OptionsPowerModal
        visible={powerVisible}
        setVisible={setPowerVisible}
        onChange={onOaChange}
        value={oaValue || value}
        info={props}
      ></OptionsPowerModal>
      <Space
        size={0}
        split={<Divider type="vertical" />}
        className={c.operaBtn}
      >
        <Typography.Link onClick={() => setVisible(true)}>
          批量编辑
        </Typography.Link>
        <Typography.Link onClick={handleClick}>添加选项</Typography.Link>
        {(parentWidget !== 'table' ||
          (voiceDoctorTableRelatedOption && parentWidget === 'table')) && (
          <Typography.Link onClick={handleLinkClick}>
            {associationHasSet ? '修改' : '设置'}选项关联
            <Tooltip placement="topRight" title={optionAssociationSettingsTip}>
              <IconFont
                style={{
                  color: '#1890ff',
                  fontSize: '16px',
                  marginLeft: '4px',
                }}
                type="icon-message-line"
              ></IconFont>
            </Tooltip>
          </Typography.Link>
        )}
        <Typography.Link onClick={handleLinkPowerClick}>
          {associationHasSet ? '修改' : '设置'}选项权限
          <Tooltip placement="topRight" title={optionAssociationPowerTip}>
            <IconFont
              style={{
                color: '#1890ff',
                fontSize: '16px',
                marginLeft: '4px',
              }}
              type="icon-message-line"
            ></IconFont>
          </Tooltip>
        </Typography.Link>
      </Space>
    </div>
  );
};

export default SelectOptionsWidget;
