/*
 * :file description:
 * :name: \low-code-platform\src\wrappers\pageAuth.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-05 18:45:40
 * :last editor: 黄艺
 * :date last edited: 2021-11-13 15:04:36
 */
import { Redirect } from 'umi';
import { getToken } from '@/utils';

export default (props) => {
  const isLogin = getToken() ? true : false;
  if (isLogin) {
    return <div>{props.children}</div>;
  } else {
    return <Redirect to="/login" />;
  }
};
