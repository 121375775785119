/*
 * :file description:
 * :name: \commond:\progree\low-code-platform\src\pages\generator\process\components\TopBanner\constants.ts
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-04 11:32:10
 * :last editor: hyw
 * :date last edited: 2021-11-08 17:08:15
 */
const computeStatus: any = {
  0: '未启用',
  1: '启用中',
};

const computeBtn = {
  '1': '启用',
  '2': '删除',
  '3': '停用',
};

const defaultVersionList = [
  {
    processName: '流程版本1',
    processId: '1',
    processStatus: 1,
  },
  {
    processName: '流程版本2',
    processId: '2',
    processStatus: 0,
  },
  {
    processName: '流程版本3',
    processId: '3',
    processStatus: 0,
  },
  {
    processName: '流程版本4',
    processId: '4',
    processStatus: 1,
  },
];

export { computeStatus, computeBtn, defaultVersionList };
