/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\RelationSelector\components\BaseSelector\index.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-21 19:52:14
 * :last editor: 黄艺
 * :date last edited: 2022-03-25 09:53:30
 */

import { useState, useContext, FC } from 'react';
import { Button, message } from 'antd';
import _ from 'lodash';
import { pick } from 'lodash-es';
import { FormConfigCtx } from '@/pages/form/[id]';
import { customCompDetail, genRealtionDom } from './relation_temp';
import c from './index.less';
import SelectorModal from '../SelectorModal';
import { WidgetProps } from 'typings';
import Amount from '../Amount';
import { BIZ_CONTRACT_UNPAID_AMOUNT } from '~@/packages/form-render/src/constant';

const specialDisplayWidget = [BIZ_CONTRACT_UNPAID_AMOUNT];

const BaseSelector: FC<WidgetProps> = (props) => {
  const { onChange, schema, readOnly, addons } = props;
  const [selectedRows, setSelectedRows] = useState([]);
  const [visible, setVisible] = useState(false);
  const [relationDetail, setRelationDetail] = useState();
  const [innerSchema, setInnerSchema] = useState();
  // 关联表单数据

  const formConfigCtx = useContext(FormConfigCtx);

  // 根据选择关联表单时选择的字段展示关联表单信息
  const filterRelationInfo = (row) => {
    const checkedKeys = Object.keys(schema.fields || {});
    return pick(
      row,
      checkedKeys.filter((k) => !k.includes('table_')),
    );
  };

  const handleSelect = () => {
    const { showSelectorVerify } = schema;
    if (showSelectorVerify && !showSelectorVerify.rule) {
      message.error(showSelectorVerify.message);
      return;
    }
    setVisible(true);
  };

  const handleConfirm = (
    formCode: string,
    defaultSelectedRow: Record<string, any>[],
  ) => {
    if (defaultSelectedRow?.length || selectedRows?.length) {
      const [currentRow] = defaultSelectedRow || selectedRows;
      const { instanceId, title } = currentRow;
      const items = {
        formCode,
        instanceId,
        title,
      };
      onChange && onChange(items);
    }
  };

  const handleReselect = () => {
    setVisible(true);
  };

  const handleClear = () => {
    onChange && onChange(undefined);
  };

  const modalProps = {
    ...props,
    visible,
    setVisible,
    selectedRows,
    setSelectedRows,
    handleConfirm,
    type: 'baseSelector',
  };

  return (
    <div className={c.t_association_form_selector}>
      {!props.value ? (
        <Button onClick={handleSelect}>请选择</Button>
      ) : (
        <>
          <div>
            <Button type="text">{props?.value?.title}</Button>
            {readOnly ? null : (
              <>
                <Button type="link" onClick={handleReselect}>
                  重新选择
                </Button>
                <Button type="link" onClick={handleClear}>
                  清除
                </Button>
              </>
            )}
          </div>
          <div>
            {selectedRows?.[0] ? (
              <div className={c.relation_zone}>
                {Object.keys(filterRelationInfo(selectedRows[0] ?? {})).map(
                  (v) => (
                    // 根据新建form时限定的字段展示
                    <div key={v}>
                      {formConfigCtx?.content?.properties?.[schema.$id]
                        ?.fields?.[v]?.title === '未回款金额' ? (
                        <>
                          <div className={c.label}>
                            {customCompDetail(v, schema, selectedRows, addons) <
                            0
                              ? '超出合同金额'
                              : formConfigCtx?.content?.properties?.[schema.$id]
                                  ?.fields?.[v]?.title}
                          </div>
                          <div className={c.value}>
                            {Math.abs(
                              customCompDetail(v, schema, selectedRows, addons),
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={c.label}>
                            {
                              formConfigCtx?.content?.properties?.[schema.$id]
                                ?.fields?.[v]?.title
                            }
                          </div>
                          <div className={c.value}>
                            {customCompDetail(v, schema, selectedRows, addons)}
                          </div>
                        </>
                      )}
                    </div>
                  ),
                )}
              </div>
            ) : null}
            {!selectedRows?.[0] &&
              props.value &&
              relationDetail &&
              innerSchema &&
              genRealtionDom({
                schema: innerSchema,
                fields: props.schema.fields,
                relationDetail: relationDetail ?? {},
              })}
          </div>
        </>
      )}
      <SelectorModal {...modalProps}></SelectorModal>
    </div>
  );
};

export default BaseSelector;
