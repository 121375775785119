/*
 * :file description:
 * :name: /low-code-platform/src/components/FormGenerator/settings/presetFormSettings/legwork.js
 * :author: caihua
 * :copyright: (c) 2023, Tungee
 * :date created: 2023-01-31 15:23:31
 * :last editor: caihua
 * :date last edited: 2023-01-31 15:41:52
 */

export default [
  {
    text: '客户',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_MbEOt4rct7zi',
    extraText: '关联表单',
    schema: {
      title: '客户',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      fields: {},
      bizAlias: 'legwork_customer',
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
          formCode: '',
        },
      },
      relateSource: [
        {
          bizType: 'crm_customer',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: '',
              bizType: 'crm_customer',
              formCode: 'PROC-22BC4105-5DAD-4545-A7EE-CC2EF7BB19A2',
              appType: 1,
            },
          },
        },
        {
          bizType: 'crm_customer_personal',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: '',
              bizType: 'crm_customer_personal',
              formCode: 'PROC-89FCB401-BA1A-4A28-B995-78EF504C6539',
              appType: 1,
            },
          },
        },
      ],
    },
    setting: {
      fields: {
        title: '关联字段',
        type: 'object',
        widget: 'relationSetting',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      filters: {
        title: '数据过滤',
        type: 'array',
        widget: 'dataFilters',
      },
      dataFillingRules: {
        title: '数据填充',
        type: 'array',
        widget: 'dataFill',
      },
    },
  },
];
