/*
 * @Description:
 * @FilePath: \low-code-platform\src\utils\parse.ts
 * author: 名字
 * copyright: (c) 2021, Tungee
 * @Date: 2021-12-24 19:13:07
 * @LastEditors: 名字
 * @LastEditTime: 2021-12-24 19:14:45
 */
export const parseUrl = (
  url: string,
  isDecode?: boolean,
): {
  [key: string]: string;
} => {
  // 编码转换
  let decoder = isDecode
    ? decodeURIComponent
    : function (url: string) {
        return url;
      };
  let _url = url.trim();
  if (
    _url.charAt(0) === '#' ||
    _url.charAt(0) === '?' ||
    _url.charAt(0) === '&'
  ) {
    _url = _url.substr(1);
  }
  let pairs = _url.split('&');
  let vals = pairs.map((param) => {
    let parts = param.replace(/\+/g, ' ').split('=');
    let key = parts.shift();
    let val = parts.length > 0 ? parts.join('=') : undefined;
    return {
      key: key,
      value: val === undefined ? undefined : decoder(val),
    };
  });
  return vals.reduce(function (result: any, pair: any) {
    let _a = pair.key,
      key = _a === void 0 ? '' : _a,
      value = pair.value;
    if (value) {
      result[key] = value;
    }
    return result;
  }, {});
};

export const parse = (url: string, isDecodeURI?: boolean) => {
  let parser: any = {};
  let a = document.createElement('a');
  a.href = url;
  parser = a;

  let hash = parser.hash;
  let hashSearchStr = '';
  let hashSearchIdx = hash.indexOf('?');
  if (hashSearchIdx >= 0) {
    hash = parser.hash.substr(0, hashSearchIdx);
    hashSearchStr = parser.hash.substr(hashSearchIdx);
  }
  return {
    hash: hash,
    pathname: parser.pathname,
    host: parser.host,
    port: parser.port,
    hostname: parser.hostname,
    protocol: parser.protocol,
    search: parseUrl(parser.search, isDecodeURI),
    hashSearch: parseUrl(hashSearchStr, isDecodeURI),
  };
};
