import React, { useState, useRef, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import c from './index.less';
import { Input, Checkbox, Radio, Form } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
// 部门、人员、角色架构选择器
import MultipleChoice from '@/components/MultipleChoice';
import { computeData, sortUserOrDept } from './utils';
import _cloneDeep from 'lodash/cloneDeep';

const FLOW_TYPE = ['2', '3'];

const ShowUserOrDept = (props: any) => {
  const {
    ele,
    flowType,
    value = {},
    memberTitleList,
    onChange,
    changeCount,
    hiddenDynamicPrinciple,
  } = props;
  const childRef = useRef(null);

  // 打开人员选择器
  const addOrEditUsers = (flowType: string) => {
    // 发起人默认不可选人
    if (flowType == '1') return;
    (childRef?.current as any)?.openModal();
  };

  // 移除选择的数据
  const removeOne = (e: Event, currentParams: any) => {
    let allData = value;
    switch (currentParams.type) {
      case 'role':
      case 'department':
      case 'initiator':
      case 'member_field':
        let mapTypeJson: any = {
          role: 'roles',
          department: 'departments',
          initiator: 'dynamicPerson',
          member_field: 'dynamicPerson',
        };
        let currentArr = allData[mapTypeJson[currentParams.type]].filter(
          (ele) => currentParams.id != ele.id,
        );
        let finallyData = {
          ...allData,
          [mapTypeJson[currentParams.type]]: currentArr,
        };
        onChange(finallyData);
        changeCount?.();
        break;
      case 'employee':
        let users = allData.users.filter(
          (ele) =>
            currentParams.id != ele.id || currentParams.userId != ele.userId,
        );
        let finallyUsersData = {
          ...allData,
          users,
        };
        onChange(finallyUsersData);
        changeCount?.();
        break;
    }
    e.preventDefault();
    e.stopPropagation();
  };

  // 获取人员选择器数据
  const getDataBack = (data: any) => {
    console.log(data, 'getDataBack');
    onChange?.(data);
    changeCount?.();
  };

  const listData = computeData(value);

  const tabsList = useMemo(() => {
    if (hiddenDynamicPrinciple) {
      return ['部门', '人员', '角色'];
    }
    return ['部门', '人员', '角色', '动态负责人'];
  }, [hiddenDynamicPrinciple]);

  return (
    <>
      <div
        className={classnames(
          c.usersDiv,
          listData?.length == 0 ? c.placeholder : '',
          flowType == '1' && c.disabledUsersDiv,
        )}
        onClick={() => addOrEditUsers(flowType)}
      >
        {listData?.length !== 0
          ? listData?.map((data: any) => {
              return (
                <span className={c.users} key={data.id}>
                  {data.name}{' '}
                  <CloseOutlined
                    onClick={(e) => removeOne(e, data)}
                    className={c.icon}
                  />
                </span>
              );
            })
          : ele.placeholder}
      </div>
      <MultipleChoice
        cRef={childRef}
        title={flowType == '2' ? '审核人' : '抄送人'}
        initData={value}
        tabs={tabsList}
        getDataBack={getDataBack}
        memberTitleList={memberTitleList}
      />
    </>
  );
};

export default (props: any) => {
  const {
    flowType,
    changeValue,
    showFormList = [],
    setFieldsValue,
    getFieldValue,
    changeCount,
  } = props;

  const tempLimitVerb = getFieldValue('limitVerb');
  const tempSelfSelect = getFieldValue('selfSelect');
  const approvalNodeType = getFieldValue('approvalNodeType');

  const [limitVerbChecked, setLimitVerbChecked] = useState();
  const [selfSelectChecked, setSelfSelectChecked] = useState();
  const [tempApprovalUser, setTempApprovaluser] = useState();
  const [dynamicPrinciple, setDynamicPrinciple] = useState(false);

  useEffect(() => {
    setDynamicPrinciple(approvalNodeType === 'selfSelect');
  }, [approvalNodeType]);

  const onChangeRadio = async (e: any) => {
    const data = getFieldValue?.('userOrDepOrRole');
    const emptyData = Object.keys(data || {})?.reduce((cur, pre) => {
      cur[pre] = [];
      return cur;
    }, {});

    setFieldsValue({
      userOrDepOrRole: tempApprovalUser ?? emptyData,
    });
    if (data) {
      setTempApprovaluser(data);
    }
    changeCount?.();
  };

  useEffect(() => {
    if (!FLOW_TYPE.includes(flowType)) {
      return;
    }
    setLimitVerbChecked(tempLimitVerb);
    changeCount?.();
  }, [tempLimitVerb]);

  useEffect(() => {
    if (!FLOW_TYPE.includes(flowType)) {
      return;
    }
    setSelfSelectChecked(tempSelfSelect);
    changeCount?.();
  }, [tempSelfSelect]);

  return (
    <>
      {showFormList &&
        showFormList.map((ele: any) => {
          if (flowType === '2' && ele.name === 'userOrDepOrRole') {
            return (
              <>
                <Form.Item
                  required={true}
                  label={ele.label}
                  className={c.form_group}
                >
                  <Form.Item name="approvalNodeType" noStyle>
                    <Radio.Group
                      onChange={onChangeRadio}
                      style={{ marginBottom: '8px' }}
                    >
                      <Radio value="approvals">指定审批人</Radio>
                      <Radio value="selfSelect">发起人自选</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item name={ele.name} rules={ele.rules} noStyle>
                    <ShowUserOrDept
                      {...props}
                      ele={ele}
                      hiddenDynamicPrinciple={dynamicPrinciple}
                    />
                  </Form.Item>
                </Form.Item>
              </>
            );
          }
          if (flowType === '3' && ele.name === 'userOrDepOrRole') {
            return (
              <Form.Item required={true} label={ele.label}>
                <Form.Item name="limitVerb" valuePropName="checked" noStyle>
                  <Checkbox
                    style={{ marginBottom: '8px' }}
                    onChange={(e) => {
                      setLimitVerbChecked(e.target.checked);
                    }}
                  >
                    指定抄送人
                  </Checkbox>
                </Form.Item>
                {limitVerbChecked && (
                  <Form.Item name={ele.name} rules={ele.rules} noStyle>
                    <ShowUserOrDept {...props} ele={ele} />
                  </Form.Item>
                )}
                <div></div>
                <Form.Item name="selfSelect" valuePropName="checked" noStyle>
                  <Checkbox
                    style={{ margin: '12px 0 8px' }}
                    onChange={(e) => {
                      setSelfSelectChecked(e.target.checked);
                    }}
                  >
                    允许发起人添加抄送人
                  </Checkbox>
                </Form.Item>
                {selfSelectChecked && (
                  <Form.Item
                    name="selfVerbUserOrDepOrRole"
                    rules={ele.rules}
                    noStyle
                  >
                    <ShowUserOrDept
                      {...props}
                      ele={ele}
                      hiddenDynamicPrinciple={true}
                    />
                  </Form.Item>
                )}
              </Form.Item>
            );
          }
          return (
            <Form.Item
              key={ele.label}
              label={ele.label}
              name={ele.name}
              rules={ele.rules}
            >
              {ele?.name === 'userOrDepOrRole' ? (
                <ShowUserOrDept {...props} ele={ele} />
              ) : (
                <Input
                  placeholder={ele.placeholder}
                  onChange={(e) => changeValue(e, ele.name)}
                  maxLength={100}
                  disabled={flowType == '1' || ele.name == 'userOrDepOrRole'}
                />
              )}
            </Form.Item>
          );
        })}
    </>
  );
};
