/*
 * :file description:
 * :name: \low-code-platform\src\components\renderer\customerTag\index.tsx
 * author: hyw
 * copyright: (c) 2022, Tungee
 * :date created: 2022-03-26 14:40:27
 * :last editor: hyw
 * :date last edited: 2022-03-28 11:50:38
 */

import React, { useState, useEffect, useMemo } from 'react';
import { Cascader } from 'antd';

import _ from 'lodash';
import formApi from '@/services/form';
import './index.less';
import IconFont from '@/components/IconFont';

const customerTag = (props: { [key: string]: any }) => {
  const { options, onChange } = props;

  const [selectValue, setSelectValue] = useState([]);
  // const [options, setOptions] = useState([]);
  // const options = [
  //   {
  //     label: 'Light',
  //     value: 'light',
  //     children: new Array(20)
  //       .fill(null)
  //       .map((_, index) => ({ label: `Number ${index}`, value: index })),
  //   },
  //   {
  //     label: 'Bamboo',
  //     value: 'bamboo',
  //     children: [
  //       {
  //         label: 'Little',
  //         value: 'little',
  //       },
  //     ],
  //   },
  // ];

  const change = (val) => {
    setSelectValue(val);
  };

  const computeOptionsFun = (options: any) => {
    let allOption: any[] = [];
    options?.forEach((ele: any) => {
      allOption.push({
        label: ele.value,
        value: ele.key,
      });
    });
    return allOption || [];
  };

  const option = useMemo(() => {
    let newOption: any[] = [];

    options?.forEach((ele: any) => {
      newOption.push({
        label: ele.title,
        value: ele.id,
        children: ele.options ? computeOptionsFun(ele.options) : undefined,
      });
    });
    return newOption;
  }, [options]);

  useEffect(() => {
    onChange && onChange(selectValue);
  }, [selectValue]);

  return (
    <Cascader
      options={option}
      multiple
      maxTagCount="responsive"
      value={selectValue}
      onChange={change}
      placeholder="选择客户标签"
    />
  );
};

export default customerTag;
