import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Modal, Input, Checkbox } from 'antd';
import { CloseOutlined, DownOutlined, SearchOutlined } from '@ant-design/icons';
import { useLocation } from 'umi';
import QRCode from 'qrcode.react';
import { debounce } from 'lodash-es';
import IconFont from '@/components/IconFont';
import c from './index.less';
import {
  getVisibleFormList,
  getOaSchemaList,
  getOaFormSchema,
} from '@/services/form-generator';
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';

const AvailableProcessForms = (props) => {
  const location = useLocation();
  const { formCode } = location?.query || {};
  const { value, onChange } = props;
  const [oaVisible, setOaVisible] = useState('');
  const [oaDataTitle, setOaDataTitle] = useState('');
  const [oaFormCode, setOaFormCode] = useState('');
  const [keyword, setKeyword] = useState('');
  const [visible, setVisible] = useState(false);
  const [availableTypes, setAvailableTypes] = useState([]);
  const [checkedTypes, setCheckedTypes] = useState([]);
  const [formList, setFormList] = useState([]);
  const [oaFormList, setOaFormList] = useState([]);
  const currentOaForm = useRef();
  const oaFormDataList = useRef([]);

  const filterFormList = useMemo(
    () =>
      [...(formList || []), ...(oaFormList || [])].filter((item) =>
        item.title?.includes(keyword),
      ),
    [keyword, formList, oaFormList],
  );

  const changeKeyword = debounce((e) => {
    const keyword = e.target.value;

    setKeyword(keyword || '');
  }, 300);
  const removeAvailableType = (type) => {
    const _availableTypes = availableTypes.filter(
      (item) => item?.formCode !== type.formCode,
    );

    console.log('----removeAvailableType--', _availableTypes);

    onChange(_availableTypes);
  };
  const onConfirm = () => {
    const checkedFormData = checkedTypes?.map((checkedFormCode) => {
      const data =
        formList?.find?.((item) => item?.formCode === checkedFormCode) ||
        oaFormDataList.current?.find?.(
          (item) => item?.formCode === checkedFormCode,
        );

      console.log('---1---22---', data);

      return {
        name: data?.title || data?.name,
        bizType: data?.bizType,
        formCode: data?.formCode,
        source: data?.source,
      };
    });

    onChange(checkedFormData);
    closeModal();
  };
  const openModal = () => setVisible(true);
  const closeModal = () => {
    setCheckedTypes(availableTypes?.map?.((item) => item.formCode));
    setVisible(false);
    currentOaForm.current = {};
  };

  const fetchFormList = async () => {
    const res = await getVisibleFormList();
    const formList = res?.data?.reduce((acc, item) => {
      if (item.formType === 0)
        acc.push({ ...item, disabled: item.formCode === formCode });

      return acc;
    }, []);

    setFormList(formList || []);
  };
  const fetchOaFormList = async () => {
    const res = await getOaSchemaList();

    setOaFormList(
      res?.data?.map((item) => ({
        ...item,
        isOa: true,
        title: item?.flowTitle,
        formCode: item?.processCode,
      })),
    );
    console.log('getOaSchemaList-----', res);
  };
  const verifyOaFormAuth = async (data) => {
    const { isOa } = data || {};
    const currentData = oaFormDataList.current?.find(
      (item) => item?.formCode === data?.formCode,
    );

    if (!isOa || currentData) return true;

    try {
      const res = await getOaFormSchema({ formCode: data?.formCode });
      console.log('------22----');
      oaFormDataList.current?.push({ ...(res?.data || {}) });

      return true;
    } catch (error) {
      if (error?.code == 10040001) {
        console.log('------2211----');
        // message.warning('当前表单未授权');
        setOaDataTitle(data?.title);
        setOaFormCode(data?.formCode);
        setOaVisible(true);

        return false;
      }
    }
  };
  const onSelectedChange = async (e, data) => {
    console.log('onSelectedChange----', e.target.value, data);

    currentOaForm.current = data?.isOa
      ? {
          ...(data || {}),
        }
      : {};
  };
  const changeTypes = async (checkeds) => {
    console.log('--------all---', currentOaForm.current);
    const isValid = await verifyOaFormAuth(currentOaForm.current);

    console.log('isValid------', isValid);

    if (isValid) setCheckedTypes(checkeds || []);
  };

  useEffect(() => {
    if (!visible) return;

    fetchFormList();
    fetchOaFormList();
  }, [visible]);

  useEffect(() => {
    setAvailableTypes(value || []);
    setCheckedTypes(value?.map?.((item) => item.formCode) || []);
    oaFormDataList.current = value || [];
  }, [value]);

  return (
    <div className={c.container}>
      <div className={c.typeContainer} onClick={openModal}>
        {availableTypes?.length ? (
          <div className={c.list}>
            {availableTypes
              ?.slice(0, Math.min(availableTypes?.length, 4))
              ?.map((type) => (
                <span className={c.value}>
                  {type?.title || type.name}
                  <CloseOutlined
                    onClick={(e) => {
                      e.stopPropagation();

                      removeAvailableType(type);
                    }}
                    className={c.closeIcon}
                  />
                </span>
              ))}
          </div>
        ) : (
          <div className={c.placeholder}>请选择</div>
        )}
        <DownOutlined className={c.icon} />
      </div>
      <div className={c.tip}>不设置时默认可选择所有任意类型的审批单</div>
      <Modal
        title="指定关联表单类型"
        visible={visible}
        onOk={onConfirm}
        onCancel={closeModal}
        okText="确定"
        cancelText="取消"
        className={c.modal}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <div className={c.modalContent}>
          <Input
            placeholder="请输入"
            prefix={<SearchOutlined style={{ color: 'rgba(23,26,29,0.24)' }} />}
            allowClear
            onChange={changeKeyword}
          />
          <div>
            <div className={c.header}>表单名称</div>
            <div className={c.listWrap}>
              <Checkbox.Group value={checkedTypes} onChange={changeTypes}>
                {filterFormList?.map((form) => (
                  <label className={c.formItem}>
                    <Checkbox
                      value={form?.formCode}
                      disabled={form.disabled}
                      onChange={(e) => onSelectedChange(e, form)}
                    >
                      {form?.title}
                    </Checkbox>
                  </label>
                ))}
              </Checkbox.Group>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        width={420}
        visible={oaVisible}
        title="授权确认"
        className="authModal"
        destroyOnClose={true}
        onCancel={() => setOaVisible(false)}
        closeIcon={<IconFont type="icon-Close" />}
        footer={null}
      >
        <div className="des">
          你需要将【{oaDataTitle}
          】的审批数据授权给【CRM】后，才可以在CRM的表单中关联到【
          {oaDataTitle}】的数据
        </div>
        <p className="tips">用手机钉钉扫码授权登录</p>
        <div className="qrcode">
          <QRCode
            value={`${
              isOpenInTripartitePlatform('1688')
                ? DOMAIN_QRCODE_1688
                : DOMAIN_QRCODE
            }/tungee/crm/mobile/#/authorize?processCode=${oaFormCode}&corpId=${
              window.__corpId
            }`}
            size={160}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AvailableProcessForms;
