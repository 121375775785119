/*
 * :file description:
 * :name: \low-code-platform\src\pages\form\components\FormFilter\index.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-13 11:06:28
 * :last editor: hyw
 * :date last edited: 2022-03-28 11:20:40
 */

import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react';
import _ from 'lodash';
import { useParams } from 'umi';
import formApi from '@/services/form';
import { getFormCode } from '@/utils';
import { renderComponents } from '../../utils/parse_schema';
import FilterOptionsSetting from '../FilterOptionsSetting';
import AdvancedFilter from '../AdvancedFilter';
import FormRender, { useForm } from 'form-render';

import { FormConfigCtx } from '../../[id]';
import c from './index.less';
import './index.less';
import { widgets } from '~@/packages/form-render/src/form-render-core/src/widgets';

interface IConfig {
  type: string;
  properties: object;
}

const FormFilter = (props, ref) => {
  const form = useForm();
  const [formSchema, setFormSchema] = useState();
  const [processId, setProcessId] = useState();
  const [filteredSchema, setFilteredSchema] = useState<IConfig | undefined>();
  const [frequentlyIds, setFrequentlyIds] = useState([]);
  const formCode = getFormCode();
  const formConfigCtx = useContext(FormConfigCtx);

  const params = useParams();

  const getAllField = () => {
    formApi
      .getAllField({
        formCode,
      })
      .then((res) => {
        if (!res?.data) return;
        const { content, frequentlyFieldIds } = res.data;
        if (!content) return;
        let contentJSON = JSON.parse(content);
        console.log('*****');
        console.log(contentJSON, frequentlyFieldIds);
        console.log('*****');
        setProcessId(res?.data?.processId);
        const approveFields = res?.data?.processId
          ? {
              lastProcessResult: {
                enum: ['1', '2', '3'],
                enumNames: ['已同意', '已撤回', '已拒绝'],
                items: {
                  type: 'string',
                },
                placeholder: '请选择',
                title: '审批结果',
                type: 'array',
                widget: 'select',
              },
              lastProcessStatus: {
                enum: ['1', '2', '3'],
                enumNames: ['审批中', '审批完成', '审批终止'],
                items: {
                  type: 'string',
                },
                placeholder: '请选择',
                title: '审批状态',
                type: 'array',
                widget: 'select',
              },
            }
          : {};

        // 硬编码 审批相关字段
        contentJSON = {
          ...contentJSON,
          properties: {
            ...contentJSON.properties,
            ...approveFields,
          },
        };
        setFrequentlyIds(frequentlyFieldIds);
        // 过滤常用筛选项
        _.omit(contentJSON, frequentlyFieldIds);
        setFormSchema(contentJSON);

        let newProperties: any = {};
        // contentJSON.properties = {
        //   ...contentJSON.properties,
        //   multiTag_27c1d1f3: {
        //     displayGroupBy: "TagFieldGroup",
        //     hasPermEdit: 1,
        //     hasPermRead: 1,
        //     id: "multiTag_27c1d1f3",
        //     options: [],
        //     print: true,
        //     required: false,
        //     title: "客户意向2",
        //     type: "array",
        //     widget: "multiTag",
        //   }
        // }

        contentJSON.properties &&
          Object.keys(contentJSON.properties || {}).forEach((key: any) => {
            // 将多选标签收录起来
            if (contentJSON.properties[key].widget === 'multiTag') {
              if (newProperties['allTags']?.options) {
                newProperties['allTags']?.options.push(
                  contentJSON.properties[key],
                );
              } else {
                newProperties['allTags'] = {
                  title: '客户标签',
                  widget: 'multiTag',
                  options: [contentJSON.properties[key]],
                };
              }
            } else {
              newProperties[key] = contentJSON.properties[key];
            }
          });
        // 根据常用筛选项过滤页面中展示的筛选条件
        const temp = {
          type: 'object',
          column: 3,
          labelWidth: 120,
          displayType: 'row',
          properties: _.pick(
            {
              ...newProperties,
              createUserId: {
                format: 'personSelector',
                title: '创建人',
                type: 'string',
                widget: 'personSelector',
              },
              gmtCreate: {
                format: 'date',
                title: '创建时间',
                type: 'string',
                widget: 'date',
              },
              gmtModified: {
                format: 'date',
                title: '更新时间',
                type: 'string',
                widget: 'date',
              },
            },
            frequentlyFieldIds,
          ),
        };

        setFilteredSchema(temp);
      });
  };

  useEffect(() => {
    getAllField();
  }, [params.id]);

  useImperativeHandle(ref, () => ({
    getFilters: () => {
      return '';
    },
  }));

  return (
    <div className={c.form_filter}>
      <div>
        <AdvancedFilter
          // formCode={formCode}
          search={formConfigCtx.search}
          filterSchema={formSchema}
          isFlowChart={!!processId}
        />
      </div>
      <div className={c.component_container}>
        <div>
          {renderComponents(filteredSchema, {
            rendertype: 'search',
            cb: formConfigCtx.search,
          })}
        </div>
        <FilterOptionsSetting
          formCode={formCode}
          formSchema={formSchema}
          frequentlyIds={frequentlyIds}
          getAllField={getAllField}
        ></FilterOptionsSetting>
      </div>
    </div>
  );
};

export default forwardRef(FormFilter);
