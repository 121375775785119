/*
 * :file description: page auth
 * :name: \low-code-platform\src\pages\back\auth\index.tsx
 * :author: ershing
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-11 16:14:19
 * :last editor: 黄艺
 * :date last edited: 2021-11-12 18:00:40
 */
import { useEffect, useState } from 'react';
import { history } from 'umi';
import c from './index.less';
import {
  Tree,
  Table,
  message,
  Card,
  Button,
  Checkbox,
  Divider,
  ConfigProvider,
  Col,
  Row,
} from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { getRoleTree, getMenuTree, relateMenu } from '@/services/auth';
import zhCN from 'antd/es/locale/zh_CN';
import OrgTable from '@/pages/back/org/components/OrgTable';

function Auth() {
  const [checkedData, setCheckedData] = useState([]);
  const [authData, setAuthData] = useState([]);
  const [functionData, setFunctionData] = useState([]);
  const [nodeId, setNodeId] = useState();
  const [isAllAuth, setIsAllAuth] = useState();
  const [selectedKey, setSelectedKey] = useState();
  console.log('functionData', functionData);
  const codeSchema = {
    ADD: '添加',
    EDIT: '修改',
    READ: '查看',
    DEL: '删除',
    IMPORT: '导入',
    EXPORT: '导出',
    ACTIVATE_PROCESS: '激活流程',
  };

  const onChange = (e, item, record) => {
    console.log(e, item, record);
    const nowData = record.a.filter((r) => {
      return r.authCode === 'READ';
    });
    console.log('noew', nowData);
    if (e.target.checked) {
      const data = functionData.map((value) => {
        value.a.map((i) => {
          if (i.uuid === item.uuid) {
            i.isCheck = true;
          }

          if (i.authCode === 'READ' && i.uuid === nowData[0].uuid) {
            i.isCheck = true;
          }
        });
        return item;
      });
      setFunctionData(data);
    } else {
      const data = functionData.map((value) => {
        value.a.map((i: any) => {
          if (i.uuid === item.uuid) {
            i.isCheck = false;
          }
        });
        return item;
      });
      setFunctionData(data);
    }
    const checkArr = record.a.map((item, index) => {
      return item.isCheck;
    });
    const checkBoolean = checkArr.every(function (item, index) {
      return item === true;
    });
    if (checkBoolean) {
      const newFunctionData = functionData.map((item) => {
        if (record.moduleId === item.moduleId) {
          item.isAllOperationAuth = true;
        }
        return item;
      });
      setFunctionData(newFunctionData);
    } else {
      const newFunctionData = functionData.map((item) => {
        if (record.moduleId === item.moduleId) {
          item.isAllOperationAuth = false;
        }
        return item;
      });
      setFunctionData(newFunctionData);
    }
  };
  const t = [];

  const mapData = (node = []) => {
    for (let item of node) {
      if (item.a) {
        const r = item.a.filter((i) => {
          return i.isCheck === true;
        });
        if (r.length !== 0) {
          const operationAuthList = [];
          console.log(r);
          r.map((x) => {
            operationAuthList.push(x.authCode);
          });
          t.push({
            moduleId: item.moduleId,
            moduleBizCode: item.moduleBizCode,
            moduleBizType: item.moduleBizType,
            operationAuthList: operationAuthList,
          });
        }
      }
    }
    return t;
  };
  const saveAuth = async () => {
    const data = mapData(functionData);
    const res = await relateMenu({ roleId: nodeId, modulePermList: data });
    if (res.code === 200) {
      message.success('权限保存成功');
    }
  };
  const judgeResultFun = (arr1, arr2) => {
    let flag = true;
    arr2?.forEach((item) => {
      if (arr1.indexOf(item) === -1) {
        flag = false;
      }
    });
    return flag;
  };
  const onCheckAllChange = (e, text, record) => {
    console.log(e, text, record, functionData);
    if (e.target.checked) {
      const newFunctionData = functionData.map((item) => {
        if (record.moduleId === item.moduleId) {
          item.isAllOperationAuth = true;
          item.a.map((children) => {
            children.isCheck = true;
          });
        }
        return item;
      });
      setFunctionData(newFunctionData);
    } else {
      const newFunctionData = functionData.map((item) => {
        if (record.moduleId === item.moduleId) {
          item.isAllOperationAuth = false;
          item.a.map((children) => {
            children.isCheck = false;
          });
        }
        return item;
      });
      setFunctionData(newFunctionData);
    }
  };
  const columns = [
    {
      title: '功能模块',
      key: 'moduleName',
      dataIndex: 'moduleName',
      render: (text: string, record: any) => <div>{text}</div>,
    },
    {
      title: '主部门',
      key: 'a',
      dataIndex: 'a',
      render: (text: Array, record) => (
        <>
          <Checkbox
            onChange={(e) => onCheckAllChange(e, text, record)}
            disabled={isAllAuth}
            checked={record.isAllOperationAuth}
          >
            全选
          </Checkbox>
          <div className={c.divider}></div>
          {text?.map((item) => {
            return (
              <Checkbox
                key={item.uuid}
                onChange={(e) => onChange(e, item, record)}
                disabled={isAllAuth}
                defaultChecked={item.isCheck}
                checked={item.isCheck}
              >
                {codeSchema[item.authCode]}
              </Checkbox>
            );
          })}
        </>
      ),
    },
  ];

  function readNodes(nodes = []) {
    for (let item of nodes) {
      item.title = item.name;
      item.key = item.id;
      if (item.type === '0' && item.children === null) {
        item.icon = <CaretRightOutlined style={{ fontSize: '10px' }} />;
      }
      if (item.children && item.children.length) readNodes(item.children);
    }
    return nodes;
  }

  function readNodes1(nodes = []) {
    // console.log('nodes',nodes)
    for (let item of nodes) {
      item.label = item?.moduleName;
      item.value = item?.moduleId;
      item.a = item?.operationAuthList;
      if (item?.operationAuthList && item?.operationAuthList.length)
        readNodes1(item?.operationAuthList);
      item.operationAuthList = null;
    }
    return nodes;
  }

  const onselect = async (value: Array<any>, e: object) => {
    console.log(value, e);
    if (e.node.type === '0') {
      return;
    }
    setSelectedKey(value);
    const auth = e.node.isAllAuth && e.node.isDefault ? true : false;
    setIsAllAuth(auth);
    const res = await getMenuTree({ roleId: e.node.id });
    console.log(res.data);

    setNodeId(e?.node?.id);
    readNodes1(res?.data);
    setFunctionData(res?.data);
  };
  useEffect(async () => {
    console.log(window.localStorage.getItem('authority'));
    const authority = window.localStorage.getItem('authority');
    if (authority === 'true') {
    } else {
      history.push('/');
      return;
    }
    const res = await getRoleTree();
    console.log('res', res);
    const arr = res.data;
    if (arr) {
      console.log(readNodes(arr));
      setAuthData(readNodes(arr));
    }
  }, []);
  return (
    <div>
      <div className={c.orgTitle}>
        <div className={c.orgTitle_text}>权限</div>
      </div>
      <div className={c.authContain}>
        <Row>
          <Col span={8}>
            <div className={c.org_tree_contain}>
              <div className={c.org_tree_title}>角色列表</div>
              <Tree
                showIcon
                // switcherIcon={<CaretRightOutlined />}
                style={{
                  marginTop: '20px',
                  marginLeft: '20px',
                  marginRight: '20px',
                }}
                selectedKeys={selectedKey}
                onSelect={onselect}
                treeData={authData}
              />
            </div>
          </Col>
          <Col span={16}>
            <Card
              title="角色权限"
              extra={
                <Button type="primary" disabled={isAllAuth} onClick={saveAuth}>
                  保存
                </Button>
              }
              headStyle={{ fontWeight: 600, fontSize: '14px' }}
              style={{ marginLeft: '12px' }}
            >
              <Table
                columns={columns}
                dataSource={functionData}
                bordered
                pagination={false}
                style={{ width: '100%' }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

Auth.wrappers = ['@/wrappers/pageAuth'];

export default Auth;
