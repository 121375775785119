import IconFont from '@/components/IconFont';
import { Switch } from 'antd';
import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import styles from './index.less';
import '../FieldSettingModal/index.less'

const DISABLED_SWITCH = ['customer_name'];

const DragHandle = SortableHandle(() => (
  <IconFont type="icon-drag-line" />
  // <DragNewOutlined className="drag-icon" type="sw_drag" />
));

const SortableItem = SortableElement((props) => {
  const { field, onChange, fieldIndex } = props;
  const handleChange = (e: boolean) => {
    onChange(e, fieldIndex);
  };

  return (
    <div className='crm-duplicate-fields-result-sortlist-item'>
      <div className='crm-duplicate-fields-result-sortlist-item-drag'>
        {DISABLED_SWITCH.includes(field.bizAlias) ? (
          <span style={{ width: '14px' }}></span>
        ) : (
          <DragHandle />
        )}
        <span style={{ marginLeft: '8px' }} title={field.label}>
          {field.title}
        </span>
      </div>
      <Switch
        onChange={handleChange}
        checked={!field.hidden}
        disabled={!field.editable}
      />
    </div>
  );
});

export const SortableList = SortableContainer((props) => {
  const { fields = [], onChange } = props;
  return (
    <div>
      {fields.map((field: any, index: number) => {
        return (
          <SortableItem
            onChange={onChange}
            key={`field-${index}`}
            field={field}
            index={index}
            fieldIndex={index}
          />
        );
      })}
    </div>
  );
});
