import React, { useState, useEffect, useRef, useCallback } from 'react';
import { flattenSchema } from 'form-render/es/form-render-core/src/utils';
import { _calFormula } from './widgets/DefaultValueSelector/components/formula-editor/common/formulaEditor';
import api from './services/api';
import { getFormCode } from './utils';

const formCode = getFormCode();

export const getSchema = () => {
  const [schemaData, setSchemaData] = useState();
  const fetchData = useCallback(async () => {
    let res = await api.getAllField({
      formCode: 'PROC-D03B4F65-A2D8-46D2-98E9-009B1B49B8ED',
    });
    console.log('result：', res?.data);
    setSchemaData(res?.data);
  }, []);

  useEffect(() => {
    formCode && fetchData();
  }, [formCode]);

  return {
    schemaData,
  };
};

// 通过判断flatten的key值是否含有"[]"来判断数组
const isArrayWidget = (flattenKey: string) => {
  return /\[\]/.test(flattenKey);
};

const pathToFlattenKey = (path: string) => {
  return path.replace(/\[(.*)\]/, '[]');
};

const flattenKeyToPath = (flattenKey: string, index: number) => {
  return flattenKey.replace(/\[\]/, `[${index}]`);
};

export function useFormula(form) {
  const ref = useRef<string>();

  const listenForm = (form) => {
    setTimeout(() => {
      if (Array.isArray(Object.values(ref.current || {})?.[0])) {
        return;
      }
      const formFlatten = (form.schema && flattenSchema(form.schema)) || {};
      Object.keys(formFlatten)?.forEach((child) => {
        const schema = form.getSchemaByPath(child);
        if (
          schema?.rely &&
          (!ref.current ||
            pathToFlattenKey(Object.keys(ref.current)[0]) !== schema.$id)
        ) {
          if (isArrayWidget(child)) {
            const key = formFlatten[child].parent.replace(/\[\]/, '');
            const tableData = form.formData?.[key] || [{ [child]: '' }];
            tableData?.forEach((_item, index) => {
              form.setValueByPath(
                flattenKeyToPath(child, index),
                _calFormula(schema?.rely.formula, undefined, form),
              );
            });
          } else {
            form.setValueByPath(
              child,
              _calFormula(schema?.rely.formula, undefined, form),
            );
          }
        }
      });
    });
  };

  useEffect(() => {
    listenForm(form);
  }, [JSON.stringify(form.formData)]);

  return [ref];
}
