import { message, Modal, Divider, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import request from 'umi-request';
import { delay } from 'lodash-es';

import {
  BUILD_IN_SEARCH_WIDGETS as buildInSearchWidgets,
  BUILD_IN_TABLE_WIDGETS as buildInTableWidgets,
} from '../widgets';
import ColumnDisplayControl from '../components/ColumnDisplayControl';
import schema from './schema.json';

// Search配置
// 搜索接口
// const search = ({
//   pageIndex = 1,
//   pageSize = 10,
//   keyword = '',
//   queryConditionList = [],
//   type = 'filter',
// } = {}) => {
//   const func =
//     type === 'search'
//       ? formApi.queryFormDataList
//       : formApi.getFormDataList;
//   func({
//     formCode,
//     keyword,
//     queryConditionList: queryConditionList.length
//       ? queryConditionList
//       : undefined,
//     pageNum: pageIndex,
//     pageSize,
//   }).then((res) => {
//     setColumns(genTableColumns(res.data));
//     setDataSource(genTableData(res.data));
//   });
// };

const searchApi = (params, sorter) => {
  console.group(sorter);
  return request
    .get(
      'https://ali-crm-foundation-test.tangees.com/api/formSearch/queryFormDataList',
      {
        params: {
          formCode: '',
          keyword: null,
          queryConditionList: params,
        },
      },
    )
    .then((res) => {
      if (res && res.data) {
        return {
          rows: res.data,
          total: res.data.length,
        };
      }
    })
    .catch((e) => {
      console.log('Oops, error', e);

      // 注意一定要返回 rows 和 total
      return {
        rows: [],
        total: 0,
      };
    });
};

// 监听搜索条件变化
export const createWatch = (cb) => ({
  '#': (val: any) => {
    console.log('@: ', val);
    cb();
  },
});

export const SEARCH_CONFIG = {
  displayType: 'row',
  api: [
    {
      api: searchApi,
    },
  ],
  schema,
  widgets: buildInSearchWidgets,
};

// Table配置

export const genTitleRender = (props) => {
  const { formCode, selectedRowKeys, refresh } = props;

  const handleImport = () => {
    refresh(null, { extra: 1 });
  };

  const handleExport = () => {
    refresh(null, { extra: 1 });
  };

  return (
    <div className="custom-title-render">
      <div>
        {selectedRowKeys?.length ? (
          <>
            已选 {selectedRowKeys?.length} 条
            <Divider
              type="vertical"
              style={{
                margin: '0 15px',
              }}
            />
            <span
              style={{
                marginRight: '20px',
              }}
            >
              批量
            </span>
            <Button
              onClick={handleDelete(formCode, selectedRowKeys, () =>
                refresh(null, { extra: 1 }),
              )}
            >
              删除
            </Button>
          </>
        ) : null}
        <Button onClick={handleExport}>导出</Button>
        <Button onClick={handleImport}>导入</Button>
      </div>
      <ColumnDisplayControl />
    </div>
  );
};

const deleteTips = {
  cited: '该表单被跟进记录表单引用，删除后会导致数据丢失，请慎重操作',
  notCited: '删除后不可恢复，请谨慎操作',
};
export const handleDelete = (formCode, ids, cb) => {
  return async () => {
    if (!ids.length) {
      return message.warning('请至少勾选一条数据');
    }
    // 判断是否有被引用
    const isCited = false;
    Modal.confirm({
      title: '确认删除吗',
      icon: <ExclamationCircleOutlined />,
      content: deleteTips[isCited ? 'cited' : 'notCited'],
      okText: '确认删除',
      cancelText: '取消',
      onOk: () => {
        request
          .post(
            'https://ali-crm-foundation-test.tangees.com/api/formInstance/deleteInstance',
            {
              params: { formCode, instanceIds: ids },
              headers: {
                formCode,
              },
            },
          )
          .then((res) => {
            if (res.code === 200) {
              message.success('删除成功');
              delay(() => cb(), 2000);
            }
          });
      },
    });
    console.log('delete');
  };
};

export const TABLE_CONFIG = {
  toolbarAction: true,
  widgets: buildInTableWidgets,
  rowKey: 'instanceId',
  scroll: { x: 'max-content', y: 400 },
  pagination: {
    // total,
    size: 'small',
    showTotal: (total) => (
      <div
        style={{
          marginRight: '10px',
        }}
      >
        共 {total} 项
      </div>
    ),
    showSizeChanger: true,
    pageSizeOptions: ['10', '20', '30', '40'],
  },
};
