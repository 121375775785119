/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\table-render\src\components\ColumnDisplayControl\SortColumns.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-31 14:07:27
 * :last editor: hyw
 * :date last edited: 2022-01-13 11:08:02
 */
import React, { Component, createRef } from 'react';
import { Form, Switch } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { HolderOutlined } from '@ant-design/icons';

//初始化数据
const getItems = (props) => props.items;

// 重新记录数组顺序
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);
  return result;
};

// 设置样式
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '10px 0 0',
  textAlign: 'left',
  background: isDragging ? '#fafafa' : '#ffffff',
  ...draggableStyle,
});

const getListStyle = () => ({
  background: '#fff',
  width: 200,
});

export default class SortColumns extends Component {
  formRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      items: props.items,
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    const initFormState = this.props.items.reduce((acc, cur) => {
      acc[cur.id] = cur.checked;
      return acc;
    }, {});
    console.log('%%%： ', initFormState);
    this.formRef.current.setFieldsValue(initFormState);
  }

  componentDidUpdate(prevProps) {
    if (this.props.items.length !== prevProps.items.length) {
      this.setState({
        items: this.props.items || [],
      });
    }
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      items,
    });
    setTimeout(() => {
      this.props.updateItems(items);
    }, 0);
  }

  doUpdate() {
    const source = this.state.items;
    const formStatus = this.formRef.current.getFieldsValue(true);
    const data = source.map((v) => ({
      ...v,
      checked: formStatus[v.id],
    }));
    setTimeout(() => {
      this.props.updateItems(data);
    }, 0);
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <Form
              ref={this.formRef}
              labelCol={{ span: 18 }}
              wrapperCol={{ span: 6 }}
              layout="horizontal"
              labelAlign="left"
              colon={false}
            >
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot)}
              >
                {this.state.items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style,
                        )}
                      >
                        <Form.Item
                          label={
                            <>
                              <HolderOutlined />
                              <span style={{ marginLeft: '10px' }}>
                                {item.title}
                              </span>
                            </>
                          }
                          name={item.id}
                          valuePropName="checked"
                        >
                          <Switch
                            defaultChecked={item.checked}
                            checked={item.checked}
                            onChange={() => this.doUpdate()}
                          />
                        </Form.Item>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            </Form>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
