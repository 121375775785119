const EventEmitter = require('eventemitter3');

// 使用的事件名必须声明
export enum EventType {
  // 实例新增
  Create = 'create',
  // 实例编辑
  Edit = 'edit',
  // 实例查看
  Detail = 'detail',
  // 实例搜索
  Search = 'search',
}

interface EventProps {
  eventName: EventType;
  payload?: any;
  cb?: Function;
}

const isRegistered = (evName: EventType) => {
  return Object.values(EventType).includes(evName);
};

class EventBus {
  constructor() {
    this.EE = new EventEmitter();
  }

  emit({ eventName, payload }: EventProps) {
    if (!isRegistered(eventName)) return;
    this.EE.emit(eventName, payload);
  }

  on({ eventName, cb }: EventProps) {
    if (!isRegistered(eventName)) return;
    this.EE.on(eventName, cb);
  }

  once({ eventName, cb }: EventProps) {
    if (!isRegistered(eventName)) return;
    this.EE.once(eventName, cb);
  }
}

export default EventBus;

// const EventEmitter = require('eventemitter3');
// const EE = new EventEmitter();

// // 使用的事件名必须声明
// export enum EventType {
//   // 实例新增
//   Create = 'create',
//   // 实例编辑
//   Edit = 'edit',
//   // 实例查看
//   Detail = 'detail',
//   // 实例搜索
//   Search = 'search'
// }

// interface EventProps {
//   eventName: EventType,
//   payload?: any,
//   cb?: Function
// }

// const isRegistered = (evName: EventType) => {
//   return Object.values(EventType).includes(evName)
// };

// export const emit = ({ eventName, payload }: EventProps) => {
//   if (!isRegistered(eventName)) return;
//   EE.emit(eventName, payload)
// }

// export const on = ({ eventName, cb }: EventProps) => {
//   if (!isRegistered(eventName)) return;
//   EE.on(eventName, cb)
// }

// export const once = ({ eventName, cb }: EventProps) => {
//   if (!isRegistered(eventName)) return;
//   EE.once(eventName, cb)
// }
