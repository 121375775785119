/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\Alert\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-31 14:07:27
 * :last editor: hyw
 * :date last edited: 2022-02-23 18:36:22
 */
import { Alert } from 'antd';
import './index.less';

const AlertTip = (props) => {
  return (
    <div className="settingAlert">
      <Alert {...props} />
    </div>
  );
};

export default AlertTip;
