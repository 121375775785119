/*
 * :file description:
 * :name: \low-code-platform\src\components\CustomFormProfile\IconSetting.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-15 11:43:39
 * :last editor: 黄艺
 * :date last edited: 2021-11-04 14:45:28
 */
import { useState, useEffect, SetStateAction, FC } from 'react';
import { Spin, Radio } from 'antd';
import c from './index.less';
import IconFont from '../IconFont';
import icons from './icons';
import { getIconList } from '@/services/form-generator';
import { IProfile } from '.';

export const DEFAULT_ICON_COLOR = '#0089FF';
export const DEFAULT_ICON = 'icon-biaodan_baogao';

const COLOR_LIST = ['#007FFF', '#41C53A', '#FF8900', '#FB6E4D', '#906CFE'];

interface IProps {
  onChange: (profile: IProfile) => void;
}

const IconSetting: FC<IProps> = ({ onChange }) => {
  const [icon, setIcon] = useState<string>(DEFAULT_ICON);
  const [iconList, setIconList] = useState();
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState(DEFAULT_ICON_COLOR);
  const [active, setActive] = useState<string>();

  const handleIconChange = (icon: string) => {
    setIcon(icon);
    setActive(icon);
  };

  const handleColorChange = (e: {
    target: { value: SetStateAction<string> };
  }) => {
    setColor(e.target.value);
  };

  const getIcons = async () => {
    try {
      setLoading(true);
      const res = await getIconList({ moduleCode: 'form_schema' });
      setIconList(res.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onChange({ icon, iconColor: color });
  }, [icon, color]);

  useEffect(() => {
    getIcons();
  }, []);

  return (
    <div className={c.iconContainer}>
      <Spin spinning={loading}>
        <section className={c.iconList}>
          <ul>
            {iconList?.map((item) => {
              return (
                <li
                  key={item.id}
                  onClick={() => handleIconChange(item.iconNameEn)}
                  className={item.iconNameEn === active ? c.active : ''}
                >
                  <IconFont
                    className={c.icon}
                    type={item.iconNameEn}
                  ></IconFont>
                </li>
              );
            })}
          </ul>
        </section>
      </Spin>
      <section className={c.colorWrap}>
        <Radio.Group onChange={handleColorChange} defaultValue="a">
          {COLOR_LIST.map((color) => (
            <Radio.Button
              style={{ background: color }}
              value={color}
            ></Radio.Button>
          ))}
        </Radio.Group>
      </section>
    </div>
  );
};

export default IconSetting;
