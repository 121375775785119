/*
 * :file description:
 * :name: \ui-renderd:\progree\low-code-platform\tg-renderer\packages\form-render\src\widgets\DefaultValueSelector\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-03 11:04:37
 * :last editor: hyw
 * :date last edited: 2022-08-29 11:08:11
 */

import { Select, Space, Tooltip } from 'antd';
import { WidgetProps } from 'typings';
import { FC, useEffect, useMemo, useState } from 'react';
import IconFont from '@/components/IconFont';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import c from './index.less';
import FormulaEditor from './components/formula-editor';
import DataFilters from './components/DataFilters';
import { transformProps, useDebounce } from '@/components/FormGenerator/utils';
import { useModel } from 'umi';
import ShowNumLocation from '../ShowNumLocation/index';

const { Option } = Select;

const DefaultValueSelector: FC<WidgetProps> = (props) => {
  const { flatten, selected, widgets, onFlattenChange } = useStore();
  const {
    placeholder,
    enum: DefaultEnum,
    enumNames: newEnumNames,
    hideupper,
    type,
  } = props?.schema || {};

  console.log(props, '1YYY');

  const { formData, setValues, getValues } = props?.addons;
  const { schema } = flatten[selected || formData.$id] || {};
  const Widget = widgets[flatten?.[formData.$id]?.schema.widget] || null;
  console.log(Widget, '9LLLL');
  const { widget } = formData;
  const [value, setValue] = useState<string>();
  const [defaultForSelect, setDefaultForSelect] = useState<any>();

  const optionsForNumberLocation = useMemo(() => {
    const phoneWidgetList = Object.keys(flatten)?.filter((i) =>
      i?.includes('phone'),
    );
    return phoneWidgetList
      ?.filter((i) => flatten[i]?.schema?.showNumLocation)
      .map((i) => {
        return {
          key: flatten[i]?.schema?.id,
          label: flatten[i]?.schema?.title,
          value: flatten[i]?.schema?.id,
        };
      });
  }, [flatten]);

  const getType = useMemo(() => {
    if (type === ' array') {
      return [];
    } else if (type === 'string') {
      return null;
    } else if (type === 'object') {
      return {};
    }
    return null;
  }, [type]);

  //专门hook 单选和多选的下拉框
  const { enumNames: enumNamesForSelect, enum: enumForSelect } = schema || {};
  let makeSelectList = enumNamesForSelect?.map((item: any, i: any) => ({
    value: item,
    key: enumForSelect[i],
  }));

  const onChange = useDebounce((e?: string) => {
    setValue(e);
    const lastValues = getValues();
    if (e === 'custom') {
      setValues({
        ...lastValues,
        rely: {
          formula: '',
          relyWidgets: [],
        },
        association: {
          defaultFieldId: undefined,
          filters: [],
          relationFormCode: undefined,
        },
        defaultType: e,
      });
      setDefaultForSelect(lastValues?.default);
    }
    if (e === 'association') {
      setValues({
        ...lastValues,
        default: getType,
        rely: {
          formula: '',
          relyWidgets: [],
        },
        defaultType: e,
      });
    }
    if (e === 'formula') {
      setValues({
        ...lastValues,
        default: getType,
        association: {
          defaultFieldId: undefined,
          filters: [],
          relationFormCode: undefined,
        },
        defaultType: e,
      });
    }
    if (e === 'createTime') {
      setValues({
        ...lastValues,
        rely: {
          formula: '',
          relyWidgets: [],
        },
        association: {
          defaultFieldId: undefined,
          filters: [],
          relationFormCode: undefined,
        },
        defaultType: e,
      });
    }
    if (e === 'numberLocation') {
      setValues({
        ...lastValues,
        rely: {
          formula: '',
          relyWidgets: [],
        },
        association: {
          defaultFieldId: undefined,
          filters: [],
          relationFormCode: undefined,
        },
        defaultType: e,
        numberLocation: optionsForNumberLocation?.length
          ? [optionsForNumberLocation[0]]
          : [],
      });
    }
    if (e == null) {
      setValues({
        ...lastValues,
        default: null,
        association: {
          defaultFieldId: undefined,
          filters: [],
          relationFormCode: undefined,
        },
        rely: {
          formula: '',
          relyWidgets: [],
        },
        defaultType: undefined,
      });
    }
  }, 100);

  const onChangeForSelect = useDebounce((e?: any, option?: any) => {
    schema && (schema.default = option);
    schema && setDefaultForSelect(schema?.default);
    onFlattenChange(flatten);
  }, 100);

  const usefulWidgetProps = transformProps({
    value: props.value || schema?.default,
    placeholder: schema?.placeholder,
    schema,
    maxLength: props?.maxLength,
    format: schema?.format,
    onChange: (e: unknown, extra?: unknown) => {
      props.onChange(e);
    },
    ...flatten[formData.$id]?.schema['props'],
    hideupper: hideupper,
  });

  const { versionLimit } = useModel('useGenerator');

  const renderDom = (defaultType: string) => {
    console.log(usefulWidgetProps, 'LLLLLLL');
    let dom;
    switch (defaultType) {
      case 'formula':
        dom = (
          <FormulaEditor
            widgetProps={props}
            flatten={flatten}
            selected={selected}
          >
            <IconFont type="icon-fuwenbenbianjiqi_gongshi"></IconFont>{' '}
            {rely?.formula && defaultType === 'formula'
              ? '已编辑公式'
              : '公式编辑'}
          </FormulaEditor>
        );
        break;
      case 'association':
        dom = hasAuth ? <DataFilters props={props} /> : null;
        if (hasAuth) {
          break;
        }
      case 'createTime':
        dom = null;
        break;
      case 'numberLocation':
        dom = (
          <div className={c.form_render}>
            <Select
              style={{ width: '100%' }}
              placeholder={placeholder}
              defaultValue={formData?.numberLocation?.[0]}
              // value={formData?.numberLocation?.[0]}
              onChange={(e: any, option: any) => {
                const lastValues = getValues();
                setValues({
                  ...lastValues,
                  numberLocation: [{ key: option?.key, value: option?.value }],
                });
              }}
              allowClear
            >
              {optionsForNumberLocation?.map((child: any, index: number) => (
                <Option key={child?.key} value={child?.value}>
                  {child?.label}
                </Option>
              ))}
            </Select>
          </div>
        );
        break;
      case undefined:
        break;
      default:
        if (selected?.includes('select') || selected?.includes('multiSelect')) {
          dom = (
            <div className={c.form_render}>
              {Widget && (
                <Select
                  mode={selected.includes('multiSelect') ? 'multiple' : ''}
                  style={{ width: '100%' }}
                  placeholder={placeholder}
                  // defaultValue={'custom'}
                  value={defaultForSelect}
                  onChange={(e: any, option: any) =>
                    onChangeForSelect(e, option)
                  }
                  allowClear
                >
                  {makeSelectList?.map((child: any, index: number) => (
                    <Option key={child?.key} value={child?.value}>
                      {child?.value}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
          );
        } else {
          dom = (
            <div className={c.form_render}>
              {Widget && (
                <Widget
                  key={selected || formData.$id}
                  {...usefulWidgetProps}
                ></Widget>
              )}
            </div>
          );
        }
        break;
    }
    return dom;
  };

  const content = () => {
    return (
      <div className={c['tooltip-content']}>
        设置默认内容后，默认值会显示在该模块的输入框中，填写者若不做修改，默认值将会作为填写者的数据提交
        <p>1、自定义：指输入一个数值作为输入框的默认值</p>
        <p>2、公式编辑：指设置公式自动计算得出输入框的值</p>
        <p>3、关联其他表单数据：指将其他表单中指定字段的值显示在此输入框</p>
      </div>
    );
  };
  const { rely, defaultType } = props?.addons?.getValues();
  const hasAuth =
    versionLimit?.widgetProperties?.[widget]?.includes('linkageDefaultType');

  const initValueForOnchange = () => {
    let _value: string | undefined = undefined;
    if (hasAuth) {
      _value = defaultType ?? DefaultEnum?.[0];
    } else {
      if (defaultType !== 'association') {
        _value = defaultType;
      } else {
        _value = DefaultEnum?.[0];
      }
    }
    if (defaultType) {
      if (hasAuth) {
        _value = defaultType;
      }
    } else {
      _value = DefaultEnum?.[0];
    }
    // 默认值 选项只有关联其他表单数据 默认值为空
    if (!defaultType && _value === 'association') {
      _value = undefined;
    }

    if (!_value && !defaultType && selected?.includes('number')) {
      _value = 'custom';
    }

    //处理 单选 多选的默认值
    makeSelectList = enumNamesForSelect?.map((item: any, i: any) => ({
      value: item,
      key: enumForSelect[i],
    }));

    if (selected?.includes('select')) {
      if (schema?.default) {
        _value = 'custom';
        setDefaultForSelect(schema?.default || {});
      }
    } else if (selected?.includes('multiSelect')) {
      if (schema?.default) {
        _value = 'custom';
        setDefaultForSelect(schema?.default || []);
      }
    }

    return _value;
  };

  useEffect(() => {
    let _value = initValueForOnchange();
    onChange(_value);
  }, [hasAuth, DefaultEnum]);

  // props.addons 返回的formId不准确 改成用select去取组件的数据
  useEffect(() => {
    let _value = initValueForOnchange();
    onChange(schema?.defaultType || _value || undefined);
  }, [selected]);

  // if (hasAuth) {
  //   newEnumNames.push('关联其他表单数据')
  //   DefaultEnum.push('association')
  // }
  return (
    <Space direction="vertical" className={c.container}>
      {(widget == 'number' || widget == 'money') && hasAuth ? (
        <p className={c.title}>
          默认值
          <Tooltip placement="top" overlayClassName={c.tooltip} title={content}>
            <IconFont
              type="icon-shuoming"
              className={c['info-icon']}
            ></IconFont>
          </Tooltip>
        </p>
      ) : null}

      <Select
        style={{ width: '100%' }}
        placeholder={placeholder}
        optionFilterProp="children"
        // defaultValue={'custom'}
        value={value}
        onChange={onChange}
        allowClear
      >
        {DefaultEnum?.map((child: string, index: number) => {
          if (!hasAuth && newEnumNames[index] === '关联其他表单数据') {
            return null;
          }
          return (
            <Option key={child} value={child}>
              {newEnumNames[index]}
            </Option>
          );
        })}
      </Select>
      {renderDom(value)}
    </Space>
  );
};

export default DefaultValueSelector;
