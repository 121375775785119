/*
 * :file description:
 * :name: \ui-renderd:\progree\low-code-platform\tg-renderer\packages\form-render\src\widgets\FieldStatus\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 14:28:41
 * :last editor: hyw
 * :date last edited: 2022-08-19 15:15:03
 */
import { FC, useMemo, useState } from 'react';
import { Modal, Tooltip, Radio, Space } from 'antd';
import { WidgetProps } from 'typings';
import styles from './index.less';
import IconFont from '@/components/IconFont';
import Order from '../../assets/form/fieldsStatus.png';
import FiledStatusDetail from '../../assets/form/filed-status-detail.png';

// type Value = string | number | undefined;

const FieldStatus: FC<WidgetProps> = (props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { formData } = props?.addons;
  const { value, schema, onChange } = props;
  const { fieldStatusEditableFreeze } = formData || {};

  const options = useMemo(() => {
    let optionsArr: any[] = [];
    if (schema.enum && Array.isArray(schema.enum)) {
      schema.enum?.forEach((ele: string, i: number) =>
        optionsArr.push({
          value: ele,
          label: schema.enumNames[i],
        }),
      );
    }
    return optionsArr;
  }, [schema.enum]);

  const handleChange = (e: any) => {
    if (e.target.value === 'hidden') {
      onChange([e.target.value, 'readOnly']);
    } else {
      onChange([e.target.value]);
    }
  };

  const renderLabel = () => {
    if (!schema.title) {
      return (
        <h3>
          字段状态
          <Tooltip placement="right" title="点击查看详情">
            <IconFont
              type="icon-shuoming"
              className={styles['info-icon']}
              onClick={() => setModalVisible(true)}
            ></IconFont>
          </Tooltip>
        </h3>
      )
    }
  }

  return (
    <div className={styles.fieldStatus}>
      { renderLabel() }
      <Radio.Group
        value={
          value
            ? !Array.isArray(value) || value?.length === 0
              ? schema?.default[0] ?? 'default'
              : value[0]
            : schema?.default[0] ?? 'default'
        }
        onChange={handleChange}
        disabled={fieldStatusEditableFreeze && schema.$id !== 'permissions'}
      >
        {options.map((item) => (
          <Radio value={item.value} key={item.value}>
            {item.label}
          </Radio>
        ))}
      </Radio.Group>
      <Modal
        className={styles.filedModal}
        title="字段状态"
        visible={modalVisible}
        width={600}
        onCancel={() => setModalVisible(false)}
        footer={null}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <div className={styles.modal}>
          <div className={styles['modal-subTitle']}>
            隐藏：指在新建/编辑表单时不展示此字段，但列表及详情页会展示，不受影响
          </div>
          <div className={styles['modal-desc']}>示例</div>
          <div className={styles['modal-desc']}>
            在订单表中新增一个自定义字段【已发货数量】，用作统计。管理员希望员工在创建订单时，不需要看到此字段，只需在订单列表中看到统计结果即可，那么可以在此处设置为隐藏
          </div>
          <img src={Order} alt="" />
          <div className={styles['modal-subTitle']}>
            只读：指在新建/编辑表单时不可修改此字段
          </div>
          <div className={styles['modal-desc']}>示例</div>
          <div className={styles['modal-desc']}>
            在盘点表单中设置一个字段【盘点盈亏】，由公式[盘点数量 -
            库存数量]自动计算出来，管理员希望此字段不允许人工修改，那么可以将此字段设置为只读
          </div>
          <img src={FiledStatusDetail} alt="" />
        </div>
      </Modal>
    </div>
  );
};

export default FieldStatus;
