/*
 * :file description:
 * :name: \low-code-platform\src\components\FormGenerator\settings\presetFormSettings\invoicingApplication.js
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-11 20:33:15
 * :last editor: Please set LastEditors
 * :date last edited: 2022-05-18 15:30:42
 */

export default [
  {
    text: '开票编号',
    name: 'seqNumber',
    icon: 'icon-zhaiyao',
    max: 1,
    fieldId: 'seqNumber_1DIBHRJPYRTS0',
    schema: {
      title: '开票编号',
      type: 'string',
      widget: 'seqNumber',
      bizAlias: 'payment_no',
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        hidden: '{{true}}',
      },
      generationRules: {
        title: '',
        type: 'array',
        items: {
          type: 'object',
          properties: {},
        },
        widget: 'generationRules',
        default: [
          {
            type: 'date',
            value: 'YYYYMMDD',
          },
          {
            type: 'counter',
            value: {
              start: 1,
              length: 8,
              reset: 'year',
            },
          },
          {
            type: 'common',
            value: '',
          },
          {
            type: 'field',
            value: '',
          },
        ],
      },
    },
  },
  {
    text: '客户',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_1RZO0GHFGO740',
    extraText: '关联表单',
    schema: {
      title: '客户',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      fields: {},
      required: true,
      bizAlias: 'invoice_apply_related_customer',
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
        },
      },
      relateSource: [
        {
          bizType: 'crm_customer',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'SWAPP-4C2F4B2B84BE49001EADD10DD19FADC6',
              bizType: 'crm_customer',
              formCode: 'PROC-FD2AC559-36B1-4A51-9E9F-0B0FC904D31C',
              appType: 1,
            },
          },
        },
        {
          bizType: 'crm_customer_personal',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'SWAPP-4C2F4B2B84BE49001EADD10DD19FADC6',
              bizType: 'crm_customer_personal',
              formCode: 'PROC-8CFF1FE5-EB3A-4C78-B069-169058E235EB',
              appType: 1,
            },
          },
        },
      ],
    },
    setting: {
      fields: {
        title: '关联字段',
        type: 'object',
        widget: 'relationSetting',
      },
      // sortFields: {
      //   title: '',
      //   widget: 'sortFields',
      //   type: 'array',
      //   items: {
      //     type: 'object',
      //     properties: {},
      //   },
      //   default: [
      //     {
      //       bizType: 'crm_customer_personal',
      //       fields: [],
      //     },
      //     {
      //       bizType: 'crm_customer',
      //       fields: [],
      //     },
      //   ],
      // },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: false,
      },
      choice: {
        title: '关联模式',
        type: 'string',
        default: '0',
        enum: ['0', '1'],
        className: 'my-radioName',
        enumNames: ['引用模式', '拷贝模式'],
        widget: 'radio',
        hidden: '{{true}}',
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        title: '标题',
        type: 'string',
        // hidden: '{{true}}',
      },
      filters: {
        title: '数据过滤',
        type: 'array',
        widget: 'dataFilters',
      },
    },
  },
  {
    text: '线索',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_followRecordClue',
    extraText: '关联表单',
    schema: {
      title: '线索',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      bizAlias: 'relation_followRecordClue',
      fields: {},
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
          formCode: '',
        },
      },
      relateSource: [
        {
          bizType: 'clue_engine',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'TJAPP-MjAyMjA5MjMwMA==',
              bizType: 'clue_engine',
              formCode: 'xxxxx',
              appType: 1,
              status: 'PUBLISHED',
            },
          },
        },
      ],
    },
    setting: {
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        title: '标题',
        type: 'string',
        // hidden: '{{true}}',
      },
    },
  },
  {
    text: '关联合同&订单（套件）',
    name: 'productStatus',
    icon: 'icon-shuoming',
    fieldId: 'bizSuite_127KIMRJZSK00',
    extraText: '关联表单',
    schema: {
      title: '关联合同&订单（套件）',
      type: 'string',
      widget: 'bizSuite',
      hidden: true,
      relatedMode: '',
      properties: {
        relation_23G65NCJR0YO0: {
          quote: 1,
          hidden: false,
          rely: {
            formula: '',
            fields: {},
            type: 'rely',
          },
          bizAlias: 'payment_related_contract',
          id: 'relation_23G65NCJR0YO0',
          isRelateForm: true,
          title: '合同',
          fields: {},
          type: 'object',
          widget: 'relation',
          dataSource: {
            params: {
              filters: [],
            },
            target: {
              appUuid: 'SWAPP-B000517C311210E6A14FE35858A67512',
              bizType: 'crm_contract',
              formCode: 'PROC-3A194530-E20C-458E-B8D6-FC5895A797EC`',
              appType: 0,
            },
          },
          required: true,
        },
        relation_1R5RENUV3TFK0: {
          quote: 1,
          hidden: false,
          bizAlias: 'payment_related_order',
          isRelateForm: true,
          title: '订单',
          id: 'relation_1R5RENUV3TFK0',
          fields: {},
          type: 'object',
          widget: 'relation',
          dataSource: {
            params: {
              filters: [],
            },
            target: {
              appUuid: 'SWAPP-B000517C311210E6A14FE35858A67512',
              bizType: 'crm_order',
              formCode: 'PROC-5B5D9292-B09C-4DC7-BDFA-43B21A7A9155',
              appType: 0,
            },
          },
          required: true,
        },
      },
      isPayment: false,
      bizType: 'contract_order_kit',
      extract: true,
      widget: 'bizSuite',
      contarct: [],
      bizAlias: 'contract_order_kit',
    },
    setting: {
      props: {
        type: 'string',
        widget: 'billingSetting',
      },
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        type: 'string',
        hidden: '{{true}}',
      },
    },
  },
  {
    text: '开票金额（元）',
    name: 'money',
    icon: 'icon-a-jine2x',
    fieldId: 'money_AV1BDQAFU8W0',
    schema: {
      title: '开票金额（元）',
      type: 'number',
      widget: 'money',
      bizAlias: 'invoice_apply_money',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
  },
  {
    text: '票据类型',
    name: 'select',
    icon: 'icon-danxuankuang1',
    fieldId: 'select_13D33P2WI56O0',
    schema: {
      title: '票据类型',
      type: 'object',
      enum: ['option_0', 'option_1', 'option_2'],
      enumNames: ['选项1', '选项2', '选项3'],
      widget: 'select',
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['选项1', '选项2', '选项3'],
        widget: 'selectOptions',
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '客户开票信息',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_1UI7YIV3XSW00',
    extraText: '关联表单',
    schema: {
      title: '客户开票信息',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      dependencies: ['relation_1RZO0GHFGO740'],
      required: true,
      fields: {},
      dataSource: {
        type: 'form',
        params: {
          filters: [],
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
          formCode: 'PROC-44F868D2-E7FA-44B6-A220-2EE36532C1AB',
        },
      },
    },
    setting: {
      fields: {
        title: '关联字段',
        type: 'object',
        widget: 'relationSetting',
      },
      // sortFields: {
      //   title: '',
      //   widget: 'sortFields',
      //   type: 'array',
      //   items: {
      //     type: 'object',
      //     properties: {},
      //   },
      // },
      // required: {
      //   title: '必填',
      //   type: 'boolean',
      //   widget: 'switch',
      // },
      choice: {
        title: '关联模式',
        type: 'string',
        default: '0',
        enum: ['0', '1'],
        className: 'my-radioName',
        enumNames: ['引用模式', '拷贝模式'],
        widget: 'radio',
        hidden: '{{true}}',
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        title: '标题',
        type: 'string',
        hidden: '{{true}}',
      },
      filters: {
        title: '数据过滤',
        type: 'array',
        widget: 'dataFilters',
      },
    },
  },
  {
    text: '备注',
    name: 'textarea',
    icon: 'icon-duohangshurukuang',
    fieldId: 'textarea_M82WIRMVJXC0',
    schema: {
      title: '备注',
      type: 'string',
      format: 'textarea',
      widget: 'textarea',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
];
