// 'input',
// 'textarea',
// 'number',
// 'date',
// 'dateRange',
// 'select',
// 'multiSelect',
// 'phone',
// 'money',
// 'innerContact',
// 'department',
// 'address',
// 'seqNumber',
import { customJP } from '../../../../utils';

export const getWidgetValue = (type, value) => {
  console.log('getWidgetValue', type, value);
  switch (type) {
    case 'input':
    case 'textarea':
    case 'number':
    case 'money':
    case 'seqNumber':
    case 'date':
      return value;
    case 'dateRange':
      if (Array.isArray(value)) {
        return value.join('~');
      }
    case 'select':
      return value?.value || '';
    case 'multiSelect':
      return (value || [])
        .map((item) => {
          return item.value;
        })
        .join(',');
    case 'phone':
      return value?.value;
    case 'innerContact':
      return (value || [])
        .map((item) => {
          return item.value;
        })
        .join(',');
    case 'department':
      return (value || [])
        .map((item) => {
          return item.value;
        })
        .join(',');
    case 'address':
      const address = (value?.address || []).join('');
      const detail = value?.detail || '';
      return `${address}${detail}` || '';
    case 'seqNumber':
      return value;
    default:
      return '';
  }
};
