/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/process/components/ProcessDesign/ModifyForm.tsx
 * :author: hyw
 * :copyright: (c) 2024, Tungee
 * :date created: 2023-07-18 15:32:12
 * :last editor: hyw
 * :date last edited: 2024-03-12 19:14:26
 */

import React, { useEffect, useState } from 'react';
import { Switch, Checkbox, Tooltip, Select } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import IconFont from '@/components/IconFont';

import c from './index.less';

const { Option } = Select;

const ModifyForm: React.FC<any> = (props) => {
  console.log(props, 'ModifyFormProps');
  const { onChange, value } = props;
  const [modifyFormVis, setModifyFormVis] = useState<boolean>(false);
  const [canUpdateApproveResult, setCanUpdateApproveResult] = useState<
    CheckboxValueType[]
  >(value?.canUpdateApproveResult || []);
  const handleSwitchChange = (checked: boolean) => {
    setModifyFormVis(checked);
    onChange &&
      onChange({
        ...value,
        updateFrom: checked,
      });
  };

  useEffect(() => {
    setModifyFormVis(Boolean(value.updateFrom));
  }, [value.updateFrom]);

  // 审批结束的表单 枚举
  const options = [
    { label: '审批同意', value: 1 },
    { label: '审批拒绝', value: 3 },
    { label: '已撤回', value: 2 },
  ];

  const handleEndForm = (checkedValue: CheckboxValueType[]) => {
    setCanUpdateApproveResult(checkedValue);
    onChange &&
      onChange({
        ...value,
        canUpdateApproveResult: checkedValue,
      });
  };

  return (
    <div className={c.modifyWrapper}>
      <div className={c.modifySwitch}>
        <span>
          允许修改审批结束的表单
          <Tooltip
            placement="topLeft"
            title="开启后，有表单修改权限的人员可以修改审批结果为「已同意」「已拒绝」「已终止」的表单"
          >
            {' '}
            <IconFont type="icon-shuoming" className={c.tipImg} />
          </Tooltip>
        </span>
        <Switch checked={value.updateFrom} onChange={handleSwitchChange} />
      </div>
      {modifyFormVis ? (
        <>
          <div className={c.approveed}>
            <div className={c.title}>审批结束的表单包括</div>
            <Checkbox.Group
              options={options}
              value={canUpdateApproveResult}
              onChange={handleEndForm}
            />
          </div>
          <div>
            <Checkbox
              checked={value.updateReason}
              onChange={(e: CheckboxChangeEvent) => {
                onChange &&
                  onChange({
                    ...value,
                    updateReason: e.target.checked,
                  });
              }}
            >
              修改原因必填
              <Tooltip
                placement="topLeft"
                title="勾选后，修改人必须填写修改原因方可修改表单"
              >
                {' '}
                <IconFont type="icon-shuoming" className={c.tipImg} />
              </Tooltip>
            </Checkbox>
          </div>
          <div>
            <Checkbox
              checked={value.updateApprove}
              onChange={(e: CheckboxChangeEvent) => {
                onChange &&
                  onChange({
                    ...value,
                    updateApprove: e.target.checked,
                  });
              }}
            >
              表单修改后需要重新发起审批
            </Checkbox>
          </div>
          <div>
            每个表单最多修改 &nbsp;
            <Select
              onChange={(val) => {
                onChange &&
                  onChange({
                    ...value,
                    updateTime: val,
                  });
              }}
              value={value.updateTime || 1}
              style={{ width: 88 }}
            >
              <Option value={1}>1</Option>
              <Option value={2}>2</Option>
              <Option value={3}>3</Option>
              <Option value={5}>5</Option>
              <Option value={10}>10</Option>
              <Option value={20}>20</Option>
              <Option value={50}>50</Option>
              <Option value={100}>100</Option>
            </Select>
            &nbsp; 次
          </div>
        </>
      ) : null}
    </div>
  );
};

export default React.memo(ModifyForm);
