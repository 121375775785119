/*
 * :file description:
 * :name: \low-code-platform\src\layouts\components\SystemMessage\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-02-15 15:13:16
 * :last editor: hyw
 * :date last edited: 2022-02-21 15:16:16
 */
import { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import c from './index.less';
import { Drawer, Modal, Divider, Pagination } from 'antd';
import './index.less';
import IconFont from '@/components/IconFont';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  updateRead,
  sysMsgPage,
  updateReadStatusBySysMsgId,
} from '@/services/user';

import moment from 'moment';

const { confirm } = Modal;

const SystemMessage = ({}: {}, ref: any) => {
  const cRef = ref || useRef();
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(1);
  const [total, setTotal] = useState(0);
  const [records, setRecords] = useState([]);
  const [visible, setVisible] = useState(false);

  const onMessage = () => {
    setVisible(true);
    getMsg(pageNum, pageSize);
  };

  useImperativeHandle(cRef, () => ({
    onMessage,
  }));

  //全部已读消息功能
  const allRead = () => {
    confirm({
      title: '是否把消息全部设置为已读',
      icon: <ExclamationCircleOutlined />,
      okText: '确定',
      cancelText: '取消',
      onOk() {
        updateRead({}).then((res) => {
          const { code } = res;
          if (code === 200) {
            getMsg(1, 10);
          }
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  // 改变页码
  const pageChange = (pageNum: number, pageSize: number) => {
    getMsg(pageNum, pageSize);
  };

  // 日期格式化
  const dateFormat = (date: any, format = 'YYYY-MM-DD') => {
    return moment(date).format(format);
  };

  // 改变页码
  const getMsg = (pageNum: number, pageSize: number) => {
    setPageSize(pageSize);
    setPageNum(pageNum);
    sysMsgPage({ pageSize, pageNum }).then((res: any) => {
      setTotal(res.data.total);
      const data = res.data.records.map((item: { [key: string]: any }) => {
        item.time = dateFormat(
          new Date(item.triggerTime),
          'YYYY年MM月DD日 HH:mm',
        );
        return item;
      });
      setRecords(data);
    });
  };

  // 下载文件
  const goDownload = (item: {
    [x: string]: any;
    downloadUrl?: any;
    id?: any;
  }) => {
    window.open(item.downloadUrl);
    if (!item.id) return;
    updateReadStatusBySysMsgId({ msgId: item.id })
      .then((res) => {
        const { code } = res;
        if (code == 200) {
          getMsg(pageNum, pageSize);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Drawer
      width={800}
      title="消息管理"
      // placement={this.state.placement}
      closable={true}
      onClose={() => setVisible(false)}
      visible={visible}
    >
      <div className={c.drawerWrapper}>
        <div>全部</div>
        <div>
          <span className={c.all_not_red} onClick={() => allRead()}>
            全部转为已读
          </span>
        </div>
      </div>
      <Divider />
      {records.map((item: { [key: string]: any }) => {
        return (
          <div key={item.id}>
            <div className={c.message_container}>
              {item.isRead === 0 ? (
                <p className={c.not_read}>{item.message}</p>
              ) : (
                <p className={c.read}>{item.message}</p>
              )}

              {item.downloadUrl ? (
                <IconFont
                  type="icon-xiazaibaocun"
                  style={{ color: '#0089FF' }}
                  onClick={() => goDownload(item)}
                ></IconFont>
              ) : null}
            </div>
            <p className={c.drawer_time}>{item.time}</p>
            <Divider />
          </div>
        );
      })}

      <Pagination
        className={c.pagination}
        total={total}
        showTotal={(total) => `共${total}项`}
        pageSize={pageSize}
        current={pageNum}
        onChange={(page, pageSize) => pageChange(page, pageSize)}
      />
    </Drawer>
  );
};

export default forwardRef(SystemMessage);
