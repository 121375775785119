/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\form-render-core\src\edit-form\form.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-08 17:15:58
 * :last editor: gaoch
 * :date last edited: 2022-03-30 21:29:47
 */
import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import FormRender, { useForm } from 'form-render';
import { Space, Button, message } from 'antd';
import formApi from '../../../services/api';
import utils, { customJP, addrDecorator, defineTitle } from '../../../utils';
import c from './index.less';
import './index.less';
import { widgets } from '../widgets';
import { IValue } from '../../../widgets/DataTitle';

interface IFormProps {
  renderFormat: string;
  formCode: string | undefined;
  instanceId: string;
  todoList: string;
  [key: string]: any;
}

const EditForm = (props: IFormProps, ref) => {
  const {
    init = () => {},
    setLoading = () => {},
    cb = () => {},
    renderFormat = 'drawer',
    instanceId,
    todoList,
  } = props;
  // TODO
  // 1.公式 2.流程？
  const form = useForm();
  const formRef = useRef();
  const [content, setContent] = useState();
  const [title, setTitle] = useState('');
  const [gmtCreate, setGmtCreate] = useState();
  const [propsSettings, setPropsSettings] = useState<IValue[]>([]);
  const formCode = props?.formcode || utils.getFormCode();
  const [load, setLoad] = useState(false);
  const [extendContent, setExtendContent] = useState();
  console.log(
    '🚀 ~ file: form.tsx ~ line 54 ~ EditForm ~ extendContent',
    extendContent,
  );
  const doSubmit = () => {
    setLoad(true);
    form.submit();
  };

  useImperativeHandle(ref, () => ({
    doEdit: () => {
      doSubmit();
    },
  }));

  useEffect(() => {
    formRef?.current?.resetFields();
    getFormSchema();
  }, []);

  useEffect(() => {
    getFormDetail();
  }, [content]);

  const getFormSchema = () => {
    formApi.getFormSchema({ formCode }).then(async (res) => {
      if (!res?.data) return;
      if (res?.data?.processId) {
        const accessInfo = await utils.getFieldsAccess({
          processId: res?.data?.processId,
          formInstanceId: instanceId,
        });
        const initContent = customJP(res.data.content);
        const behaviorList = accessInfo?.data?.behaviorList;

        if (Array.isArray(behaviorList)) {
          Object.keys(initContent.properties)?.forEach((key) => {
            const match = behaviorList.find((t) => t.fieldId === key);
            if (!match) {
              initContent.properties[key] = {
                ...initContent.properties[key],
                props: {
                  formCode: formCode,
                },
              };
              return;
            }
            initContent.properties[key] = {
              ...initContent.properties[key],
              props: {
                readOnly: match?.fieldBehavior === 'READONLY',
                // readOnly: true,
                formCode: formCode,
              },
              // hidden: match?.fieldBehavior === 'HIDDEN',
              // disabled: match?.fieldBehavior === 'DISABLED',
            };
          });
        } else {
          Object.keys(initContent.properties)?.forEach((key) => {
            initContent.properties[key] = {
              ...initContent.properties[key],
              props: {
                formCode: formCode,
              },
            };
          });
        }
        setContent(addrDecorator(initContent));
      } else {
        setContent(addrDecorator(customJP(res.data.content)));
      }
      setTitle(res.data.title);
      setGmtCreate(res.data.gmtCreate);
      setPropsSettings(res.data.propsSettings);
      init(res?.data || {});
    });
  };

  const getFormDetail = async () => {
    if (todoList === 'todoList') {
      const res = await formApi.getDataForTodoList(
        {
          formCode,
          instanceId,
        },
        {
          headers: {
            formCode,
          },
        },
      );
      if (res?.data?.content) {
        echoDetailInfo(customJP(res?.data?.content));
        console.log('详情结果：', customJP(res?.data?.content));
      }
    } else {
      const res = await formApi.getFromInstanceDetail(
        {
          formCode,
          instanceId,
        },
        {
          headers: {
            formCode,
          },
        },
      );
      if (res?.data?.content) {
        echoDetailInfo(customJP(res?.data?.content || ''));
        setExtendContent(res.data.extendContent);
        console.log('详情结果：', customJP(res?.data?.content || ''));
      }
    }
  };

  const echoDetailInfo = (data) => {
    if (!data) return;
    console.log('回显： ', data);
    form.setValues(data);
  };

  const onFinish = (data, errors) => {
    const { properties } = content;
    if (errors.length > 0) {
      // return message.error(
      //   '校验未通过：' + JSON.stringify(errors.map((item) => item.name)),
      // );
      setLoading(false);
      setLoad(false);
      console.log(content, 1111);

      let errArr = errors.map((val) => properties[val.name]?.title);
      let firstValue = errArr.shift();
      // 当存在表格时则要特殊处理
      if (!firstValue) {
        let tempTableArr: any = {};
        Object.values(properties)?.forEach((val: any) => {
          if (val.widget == 'table') {
            tempTableArr[val.id] = val?.items?.properties || {};
          }
        });

        let saveArr = [];
        errors?.forEach((val) => {
          // 错误信息是类似一下的类型，需要将数据筛选出来
          // 'table_KuJWPX0kwWwW[1].number_zqMiRzCp5jYJ'.replace(/\[\d\]\./, '')
          let tableIdAndKey = val.name.replace(/\[\d\]\./, '@@').split('@@');
          saveArr.push(tableIdAndKey);
        });

        let allerrArr = saveArr.map((val) => {
          return tempTableArr[val[0]]?.[val[1]]?.title || '';
        });
        message.warning(`表格中${allerrArr.shift() || ''}不能为空`);
      } else {
        message.warning(`${firstValue}不能为空`);
      }
      return;
    } else {
      // 收录所有表格数据
      let tempTableArr: Array<object> = [];
      // 收录必填的电话
      let tempPhoneArr: Array<object> = [];
      let tempAdressArr: Array<object> = [];
      // 收录必填输入类型
      const tempInputTypeArr: Array<object> = [];
      Object.values(properties)?.forEach((val: any) => {
        if (val.widget == 'table') {
          tempTableArr.push({
            tableId: val.id,
            properties: val?.items?.properties, // 收录所有的表格子项，再判断是否有需要必填的项
          });
        }

        if (val.widget == 'phone' && val.required) {
          tempPhoneArr.push(val);
        }

        if (val.widget == 'address' && val.needDetail == 2 && val.required) {
          tempAdressArr.push(val);
        }
        if (val.widget == 'input' && val.required) {
          tempInputTypeArr.push(val);
        }
      });
      if (tempInputTypeArr.length) {
        let isEmpty = false;
        const emptyTitle: Array<object> = [];
        tempInputTypeArr?.forEach((ele: any) => {
          let trimValue = data[ele.id]?.trim();
          if (!trimValue) {
            isEmpty = true;
            emptyTitle.push(ele.title);
          }
        });

        if (isEmpty) {
          message.warning(`请输入具体的${emptyTitle.join('、')}值`);
          setLoading(false);
          setLoad(false);
          return;
        }
      }
      if (tempTableArr.length) {
        let hasNotFillIn = true;
        tempTableArr?.forEach((ele: any) => {
          const properties = ele?.properties;
          let isHasRequired = false;
          let needFillIn: any = [];

          // 将表格当前的表格需要填写的项收录起来
          Object.values(properties || {}).forEach((val: any) => {
            if (val.required == true) {
              isHasRequired = true;
              needFillIn.push(val);
            }
          });

          // 存在需要填写的项
          if (isHasRequired) {
            let tableValues = data?.[ele?.tableId] || [];
            // 未填写表格中的值时无法触发表格的值更新 为undefied
            if (tableValues.length === 0) {
              hasNotFillIn = false;
            }

            tableValues?.forEach((val) => {
              needFillIn?.forEach((ele) => {
                let widget = ele.widget;
                // console.log(ele, 11111111);
                // console.log(11111111, widget);
                // console.log(11111111333, val[ele.id]);
                // 未填写
                if (widget == 'phone' && !val[ele.id]?.value) {
                  hasNotFillIn = false;
                }
                // 类型随时增加，目前只有地址和电话
                if (widget == 'address' && ele.needDetail == 2) {
                  if (!val[ele.id]?.street || !val[ele.id]?.detail) {
                    hasNotFillIn = false;
                  }
                }
              });
            });
          }
        });
        if (!hasNotFillIn) {
          message.warning('存在表格必填项未填');
          setLoading(false);
          setLoad(false);
          return;
        }
      }

      // 电话也要做特殊处理
      if (tempPhoneArr.length) {
        let needToFillIn = false;
        tempPhoneArr?.forEach((val) => {
          let phoneItem = data?.[val?.id] || {};
          if (!phoneItem?.value) {
            needToFillIn = true;
          }
        });

        if (needToFillIn) {
          message.warning('请完善电话再操作');
          setLoading(false);
          setLoad(false);
          return;
        }
      }

      // 地址也要做特殊处理
      if (tempAdressArr.length) {
        let needToFillIn = false;
        tempAdressArr?.forEach((val) => {
          let adressItem = data?.[val?.id] || {};
          if (!adressItem?.street || !adressItem?.detail) {
            needToFillIn = true;
          }
        });

        if (needToFillIn) {
          message.warning('请完善地址再操作');
          setLoading(false);
          setLoad(false);
          return;
        }
      }
      let relatedModelList = [];
      Object.keys(data)?.forEach((key) => {
        if (key.includes('relation_')) {
          relatedModelList?.push({
            relatedFormCode: data[key]?.formCode,
            relatedInstanceId: data[key]?.instanceId,
          });
        }
        if (key.includes('dateRange_')) {
          const [begin, end] = data[key] ?? [];
          const format =
            content?.properties?.[key]?.format === 'dateTime'
              ? 'YYYY-MM-DD HH:mm:ss'
              : 'YYYY-MM-DD';
          data[key] = [begin?.format(format), end?.format(format)];
        }
      });

      formApi
        .editFromInstance(
          {
            formCode,
            instanceId,
            title: defineTitle(data, propsSettings, form, gmtCreate, title),
            content: JSON.stringify(data),
            relatedModelList,
            extendContent,
          },
          {
            headers: {
              formCode,
            },
          },
        )
        .then((res) => {
          if (res.code === 200) {
            message.success('编辑成功');
            cb();
            setLoad(false);
          }
        });
    }
  };

  return (
    <div className={c.t_edit_form}>
      <div className="edit_form_scroll">
        {content && (
          <FormRender
            watch={{
              '#': (val) => {
                console.log('表单的时时数据为：', val);
              },
            }}
            schema={content}
            form={form}
            onFinish={onFinish}
            widgets={{ ...widgets }}
          />
        )}
      </div>
      {!['drawer', 'modal'].includes(renderFormat) && (
        <Space className={c.submit}>
          <Button loading={load} type="primary" onClick={doSubmit}>
            提交
          </Button>
        </Space>
      )}
    </div>
  );
};

export default forwardRef(EditForm);
