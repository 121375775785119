import React, { useState, useEffect } from 'react';
import { Modal, message } from 'antd';
import { get } from 'lodash-es';
import _ from 'lodash';
import { nanoid } from 'nanoid';
// import { useTable } from '../../hooks';
import { PicRightOutlined } from '@ant-design/icons';
import SortColumns from './SortColumns';
import formApi from '../../../../form-render/src/services/api';
import './index.less';
import IconFont from '@/components/IconFont';

const ColumnDisplayControl = (props) => {
  // const { refresh }: any = useTable();
  const { formCode, cb } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [schema, setSchema] = useState(false);
  const [items, setItems] = useState([]);
  const [cols, setCols] = useState([]);
  const [displayKey, setDisplayKey] = useState(nanoid(12));

  const handleUpdate = () => {
    console.log('cols: ', cols);
    const fieldIds = cols.map((item, idx) => ({
      fieldId: item.id,
      hidden: !item.checked,
      order: idx,
      title: item.title,
    }));
    formApi
      .uploadColumns({
        formCode,
        fieldIds,
      })
      .then((res) => {
        cb();
        setItems([]);
        setCols([]);
      });
  };

  const getSchema = () => {
    formApi.getVisibleField({ formCode }).then(async (res) => {
      let content = get(res, 'data.content');
      let visibleFieldList = get(res, 'data.visibleFieldList', []);
      if (!content) return;
      content = JSON.parse(content);
      setSchema(content);
      const list = visibleFieldList.map(
        ({ fieldId, title, hidden, order }) => ({
          id: fieldId,
          title,
          checked: !hidden,
          order,
        }),
      );
      const sortedList = _.sortBy(list, (o) => +o.order);
      setCols(sortedList as []);
      setItems(sortedList as []);
    });
  };

  useEffect(() => {
    if (isVisible) {
      getSchema();
    }
  }, [isVisible, formCode]);

  return (
    <div className="column_display">
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setDisplayKey(nanoid(12));
          setTimeout(() => {
            setIsVisible(true);
          }, 0);
        }}
      >
        <PicRightOutlined />
        <span style={{ marginLeft: '4px' }}>显示列</span>
      </div>
      <Modal
        className="column_display_control"
        key={displayKey}
        width={260}
        visible={isVisible}
        mask={false}
        maskClosable={true}
        destroyOnClose={true}
        closable={false}
        title={null}
        footer={null}
        afterClose={handleUpdate}
        onCancel={() => {
          let isPass = cols.some((val) => val.checked == true);
          if (!isPass) {
            message.warning('请至少选择一项');
            return;
          }
          setIsVisible(false);
        }}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <div style={{ height: '700px', overflowY: 'auto' }}>
          {items.length ? (
            <SortColumns items={items} updateItems={setCols} />
          ) : null}
        </div>
      </Modal>
    </div>
  );
};

export default ColumnDisplayControl;
