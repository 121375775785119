/*
 * @Description:
 * @FilePath: \low-code-platform\src\services\workNotice\index.ts
 * author: 段文君
 * copyright: (c) 2022, Tungee
 * @Date: 2022-01-07 19:12:08
 * @LastEditors: 段文君
 * @LastEditTime: 2022-01-11 15:41:59
 */
import { postRequest, getRequest } from '@/common/http';

interface IResFileData {
  formCode: string;
  fileUrl: string;
  expireTime: string;
}

interface IRes<T> {
  data: T;
  code: number;
}

// 获取下载文件
export async function downloadFile(data: { id: string }) {
  return postRequest<IRes<IResFileData>>(
    `/api/formInstance/excel/getInstanceExportTask`,
    data,
  );
}
