/*
 * :file description:
 * :name: \low-code-platform\src\pages\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-13 15:06:45
 * :last editor: 黄艺
 * :date last edited: 2021-11-13 15:08:19
 */
import { Redirect, useLocation } from 'umi';

interface IFormData {
  bizId: string;
}

export default (props) => {
  const location = useLocation();
  if (window.__isMicroApp__) {
    const formData =
      sessionStorage.getItem(`${window.__corpId}_tungee_form`) ?? '';
    let form: IFormData;
    try {
      form = JSON.parse(formData);
    } catch (e) {
      console.log(e, '解析发生错误');
      return null;
    }
    return <Redirect to={`/form/${form.bizId}`} />;
  }
  if (location.pathname === '/') {
    return <Redirect to="/todoMessage" />;
  } else {
    return <div>{props.children}</div>;
  }
};
