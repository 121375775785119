/*
 * :file description:
 * :name: \low-code-platform\src\utils\index.ts
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-16 14:48:13
 * :last editor: hyw
 * :date last edited: 2022-02-16 16:22:29
 */

import * as dd from 'dingtalk-jsapi';
import isNil from 'lodash/isNil';
import { parse } from './parse';
import { history } from 'umi';
import { isOpenInTungee } from '@/utils/tungee';
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';

export function getUuid() {
  /* jshint bitwise:false */
  let i;
  let random;
  let uuid = '';

  for (i = 0; i < 32; i++) {
    random = (Math.random() * 16) | 0; // eslint-disable-line
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '-';
    }
    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16); // eslint-disable-line
  }

  return uuid;
}

export function getNodeUuid() {
  /* jshint bitwise:false */
  let i;
  let random;
  let uuid = 'node_';

  for (i = 0; i < 32; i++) {
    random = (Math.random() * 16) | 0; // eslint-disable-line
    if (i === 8 || i === 12 || i === 16 || i === 20) {
      uuid += '';
    }
    uuid += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16); // eslint-disable-line
  }

  return uuid;
}

export const includes = (arr1: any[] | undefined, arr2: any[] | undefined) => {
  if ((!arr1 && arr2) || (!arr2 && arr1)) {
    return false;
  } else if (!arr1 && !arr2) {
    return true;
  }
  return arr2!.every((val) => arr1!.includes(val));
};

export const isJSON = (str: string) => {
  if (typeof str == 'string') {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
};

export const customJP = (data: string) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    console.log(e);
    return data;
  }
};

export const getUrlParam = (key: string, isDecodeURI: boolean = true) => {
  let search = parse(location.href, isDecodeURI).search;
  let value = search[key];
  if (isNil(value)) {
    return value;
  }
  if (isDecodeURI) {
    try {
      return decodeURIComponent(value);
    } catch (err) {
      // uri格式错误异常, 直接返回原值
      return value;
    }
  }
  return search[key];
};

export const getHashParam = (key: string, isDecodeURI: boolean = true) => {
  let hashSearch = parse(location.href, isDecodeURI).hashSearch;
  let value = hashSearch[key];
  if (isNil(value)) {
    return value;
  }
  if (isDecodeURI) {
    try {
      return decodeURIComponent(value);
    } catch (err) {
      // uri格式错误异常, 直接返回原值
      return value;
    }
  }
  return hashSearch[key];
};

export const getToken = () => {
  const token = getUrlParam('tungeeToken') || getHashParam('tungeeToken');
  const tungeeToken = sessionStorage.getItem(`tungee_token_${window.__corpId}`);
  if (window.__isMicroApp__ || token || tungeeToken) {
    return window.__isMicroToken__ || token || tungeeToken;
  }
  return localStorage.getItem('token');
};

export const isInDingtalk = () => {
  const tungeeToken = sessionStorage.getItem(`tungee_token_${window.__corpId}`);
  return window.__isMicroApp__ || tungeeToken;
};

export const getFormCode = () => {
  const formCode = history.location.pathname.match(/form\/(.*)/)?.[1];
  return formCode;
};

// 下载文件
export const dowmLoadFile = (url: string, fileType?: string) => {
  if (!url) {
    return;
  }
  const pdfType = ['pdf', 'PDF'];
  let link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  if (pdfType.includes(fileType)) {
    link.target = '_blank';
  }
  link.setAttribute('download', '');

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// 下载word
export const downloadWordFile = (url: string | undefined) => {
  if (!url) {
    return;
  }
  let link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.setAttribute('download', '');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const userAgentObj = () => {
  const ua = navigator.userAgent.toLowerCase();
  let isWeiXin = false,
    isDingTalk = false,
    isApp = false,
    obj: any = {};
  if (ua.match(/DingTalk/i) == 'dingtalk') {
    //用钉钉打开
    isDingTalk = true;
  } else if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    //用微信打开
    isWeiXin = true;
  } else {
    //用其他浏览器打开
    isApp = true;
  }
  obj.isWeiXin = isWeiXin;
  obj.isDingTalk = isDingTalk;
  obj.isApp = isApp;
  localStorage.setItem('userAgentObj', JSON.stringify(obj));
  return obj;
};

/*
 * 是否在钉钉容器内
 */
export function notInDD() {
  return dd?.env?.platform === 'notInDingTalk';
}
/**
 * 格式化地址
 */
const formmatUrl = (params: any = {}) => {
  const { url, hash = '', hashQuery = {}, query = {} } = params;
  let hashQueryStr = '';
  let queryStr = '';
  for (let attr in hashQuery) {
    if (hashQuery[attr] === null || hashQuery[attr] === undefined) {
      continue;
    }
    if (!hashQueryStr) {
      hashQueryStr = `?${attr}=${hashQuery[attr]}`;
    } else {
      hashQueryStr += `&${attr}=${hashQuery[attr]}`;
    }
  }
  for (let attr in query) {
    if (query[attr] === null || query[attr] === undefined) {
      continue;
    }
    if (!queryStr) {
      queryStr = `?${attr}=${query[attr]}`;
    } else {
      queryStr += `&${attr}=${query[attr]}`;
    }
  }
  return `${url}${queryStr}${hash}${hashQueryStr}`;
};

export function newtab(url = '') {
  if (url) {
    // FIXME 判断跳转链接是否是相对路径
    let reg = /^(http|ftp|https|dingtalk):\/\/?/;
    const absolute = reg.test(url);
    let finalUrl = absolute ? url : location.origin + url;
    // FIXME 手工处理url,考虑场景较少
    let needNetTab = finalUrl?.includes('ddtab') ? '' : 'ddtab=true';
    if (needNetTab) {
      // finalUrl += finalUrl?.includes('?') ? '&' : '?' + needNetTab;
      finalUrl = `${finalUrl}${
        finalUrl?.includes('?') ? '&' : '?'
      }${needNetTab}`;
    }

    if (dd?.pc) {
      // FIXME 解决百度收录的地址无法在钉钉中开tab跳转问题
      if (finalUrl?.includes('/link?url=')) {
        finalUrl += '&wd=';
      }
      dd.biz.util.openLink({
        url: finalUrl, //要打开链接的地址
        onSuccess: function (result) {
          console.log('使用钉钉新窗口打开页面');
        },
        onFail: function (err) {
          console.log(err);
        },
      });
    } else {
      window.open(finalUrl);
    }
  }
}

interface ILowCodeParams {
  formCode?: string;
  bizType?: string;
  type: number;
  menu?: string;
  versionValue?: number | string;
}

/** 跳转低代码设计态 */
export const jumpLowCodeDesign = ({
  formCode,
  bizType,
  type = 1,
  menu,
  versionValue,
}: ILowCodeParams) => {
  const tungeeParams = isOpenInTungee()
    ? { source: 'CloudPlatform', appName: 'tungee_erp' }
    : {};
  const url = formmatUrl({
    url: `${DOMAIN}/generator/form`,
    query: {
      type,
      formCode,
      menu,
      bizType,
      versionValue,
      subscribeId: window.__subscribeId || getUrlParam('subscribeId'),
      corpId: window.__corpId || getUrlParam('corpId'),
      tungeeToken: encodeURIComponent(getToken()),
      ...tungeeParams,
    },
  });
  console.log('---url');
  console.log(url);
  newtab(url);
};

// 跳转低代码扩展设置对应规则弹窗
export const jumpLowCodeExtensionDesign = ({
  formCode,
  bizType,
  type = 1,
  menu,
  versionValue,
}: ILowCodeParams) => {
  const tungeeParams = isOpenInTungee()
    ? { source: 'CloudPlatform', appName: 'tungee_erp' }
    : {};
  const url = formmatUrl({
    url: `${DOMAIN}/generator/relation/business`,
    query: {
      type,
      formCode,
      menu,
      bizType,
      versionValue,
      subscribeId: window.__subscribeId || getUrlParam('subscribeId'),
      corpId: window.__corpId || getUrlParam('corpId'),
      tungeeToken: encodeURIComponent(getToken()),
      ...tungeeParams,
    },
  });
  console.log('---url');
  console.log(url);
  newtab(url);
};

// 跳转连接器配置页面
export const jumpDataConectorFConfig = ({
  formCode = '',
  bizType = '',
  type = 1,
  menu = '',
  versionValue = '',
}: ILowCodeParams) => {
  const url = formmatUrl({
    url: `${
      isOpenInTripartitePlatform('1688') ? DOMAIN_QRCODE_1688 : DOMAIN_QRCODE
    }/tungee/management/?corpId=${
      window.__corpId || getUrlParam('corpId')
    }#/connector`,
    query: {
      type,
      formCode,
      menu,
      bizType,
      versionValue,
      subscribeId: window.__subscribeId || getUrlParam('subscribeId'),
      // corpId: window.__corpId || getUrlParam('corpId'),
      tungeeToken: encodeURIComponent(getToken()),
      closeSidebar: '123',
    },
  });
  console.log('---url');
  console.log(url);
  newtab(url);
};

export const baseFormBizTypes = [
  'crm_customer',
  'crm_customer_personal',
  'crm_contact',
  'crm_follow_record',
];
