/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/relation/duplicated/components/FieldSettingModal/DupResult.tsx
 * :author: KyleLiang
 * :copyright: (c) 2024, Tungee
 * :date created: 2024-05-06 13:50:00
 * :last editor: KyleLiang
 * :date last edited: 2024-08-03 11:29:11
 */
import React, { useEffect, useMemo, useState } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
// import DragNewOutlined from '@ali/sw-icons/es/DragNewOutlined';
import { Switch } from 'antd';
import { cloneDeep, isBoolean, isNil } from 'lodash';
import cx from 'classnames';
import IconFont from '@/components/IconFont';
import { GMT_CREATE, BIZ_CUSTOMER_PRE_PRINCIPALS } from '../../constants';

const DISABLED_SWITCH = ['customer_name'];
const CUSTOMER_TYPE = ['crm_customer', 'crm_customer_personal'];

const DragHandle = SortableHandle(() => (
  <IconFont type="icon-drag-line" />
  // <DragNewOutlined className="drag-icon" type="sw_drag" />
));

const SortableItem = SortableElement((props) => {
  const { field, onChange, fieldIndex } = props;
  const handleChange = (e: boolean) => {
    onChange(e, fieldIndex);
  };

  return (
    <div className="crm-duplicate-fields-result-sortlist-item">
      <div className="crm-duplicate-fields-result-sortlist-item-drag">
        {DISABLED_SWITCH.includes(field.bizAlias) ? (
          <span style={{ width: '14px' }}></span>
        ) : (
          <DragHandle />
        )}
        <span style={{ marginLeft: '8px' }} title={field.label}>
          {field.title}
        </span>
      </div>
      <Switch
        onChange={handleChange}
        checked={!field.hidden}
        disabled={!field.editable}
      />
    </div>
  );
});

const SortableList = SortableContainer((props) => {
  const { fields = [], onChange } = props;
  return (
    <div>
      {fields.map((field: any, index: number) => {
        return (
          <SortableItem
            onChange={onChange}
            key={`field-${index}`}
            field={field}
            index={index}
            fieldIndex={index}
          />
        );
      })}
    </div>
  );
});

const DupResult = (props) => {
  const {
    updateDuplicateData,
    handleChangeFields,
    duplicateData,
    bizType,
  } = props;
  const [formFields, setFormFields] = useState([]);

  useEffect(() => {
    handleChangeFields({
      visibleFieldList: formFields,
    });
  }, [formFields]);

  const isSelectedFields = updateDuplicateData.manyQueryConditionList.flat();

  useEffect(() => {
    console.log('duplicateData', duplicateData);
    const visibleIds = duplicateData.visibleFieldList.map(
      (item) => item.fieldId,
    );
    const extraAddField = [];
    // 客户表查重需要添加最后负责人字段
    if (CUSTOMER_TYPE.includes(bizType)) {
      extraAddField.push(BIZ_CUSTOMER_PRE_PRINCIPALS);
    }
    let _formList = duplicateData.selectFields
      .concat(GMT_CREATE, extraAddField)
      .sort((a, b) => {
        const indexA =
          visibleIds.indexOf(a.id) === -1 ? 99999 : visibleIds.indexOf(a.id);
        const indexB =
          visibleIds.indexOf(b.id) === -1 ? 99999 : visibleIds.indexOf(b.id);
        // return visibleIds.indexOf(a.id) - visibleIds.indexOf(b.id);
        return indexA - indexB;
      })
      .map((item) => {
        const _data = duplicateData.visibleFieldList.find(
          (x) => x.fieldId === item.id,
        );
        // console.log('_data', _data);
        // console.log('isSelectedFields', isSelectedFields);
        const isSelectedField = isSelectedFields.some((x) =>
          x?.tableFieldId ? x?.tableFieldId === item.id : x.fieldId === item.id,
        );
        // console.log('item', item);
        // console.log('isSelectedField', isSelectedField);
        // 创建时间初始默认值不对
        if (item.id === 'gmtCreate' && !_data) {
          return {
            hidden: false,
            fieldId: item.id,
            title: item.title,
            editable: true,
          };
        }
        // 选中字段作为查重 默认开启
        let hidden = isSelectedField ? false : true;
        if (_data?.fieldId) {
          return {
            ..._data,
            title: item?.title ? item?.title : _data?.title,
            hidden: _data.hidden ? hidden : _data.hidden,
          };
        }
        if (item.id === 'principal') {
          hidden = false;
        }
        return {
          hidden,
          fieldId: item.id,
          title: item.title,
          editable: true,
        };
      });
    setFormFields(_formList);
  }, [duplicateData.visibleFieldList]);

  const onChange = (checked: boolean, index: number) => {
    const _formHeaders = cloneDeep(formFields);
    const _index = sortListData.disabledFields.length + index;
    _formHeaders[_index] = {
      ..._formHeaders[_index],
      hidden: !checked,
    };
    setFormFields(_formHeaders);
  };

  const handleSortEnd = (ev: { oldIndex: number; newIndex: number }) => {
    const { oldIndex, newIndex } = ev;
    const _oldIndex = oldIndex + sortListData.disabledFields.length;
    const _newIndex = newIndex + sortListData.disabledFields.length;
    const _formHeaders = cloneDeep(formFields);
    _formHeaders.splice(
      _newIndex,
      1,
      ..._formHeaders.splice(_oldIndex, 1, _formHeaders[_newIndex]),
    );
    setFormFields(_formHeaders);
  };
  const sortListData = useMemo(() => {
    const disabledFields: any = [];
    const enabledFields: any = [];
    formFields.map((field) => {
      if (field.editable) {
        enabledFields.push(field);
      } else {
        disabledFields.push(field);
      }
    });
    return {
      disabledFields,
      enabledFields,
    };
  }, [formFields]);

  const selectedFormFields = useMemo(() => {
    return formFields.filter((item) => !item.hidden);
  }, [formFields]);

  console.log('sortListData', sortListData);
  return (
    <div className="crm-duplicate-fields-result">
      <h4>查重结果显示字段</h4>
      <div className="crm-duplicate-fields-result-sortable">
        {sortListData.disabledFields.length > 0 && (
          <SortableList
            onChange={onChange}
            useDragHandle={false}
            fields={sortListData.disabledFields}
          />
        )}
        <SortableList
          onChange={onChange}
          fields={sortListData.enabledFields}
          onSortEnd={(ev: { oldIndex: number; newIndex: number }) => {
            handleSortEnd(ev);
          }}
        />
      </div>
      <h4>查重结果显示预览</h4>
      <div
        className={cx('crm-duplicate-fields-result-preview', {
          'crm-duplicate-fields-result-preview-overflow':
            selectedFormFields.length <= 5,
        })}
      >
        {selectedFormFields.map((item) => (
          <div key={item.fieldId}>{item.title}</div>
        ))}
      </div>
    </div>
  );
};

export default DupResult;
