/*
 * :file description:
 * :name: \low-code-platform\src\pages\generator\process\api.ts
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-18 14:02:56
 * :last editor: hyw
 * :date last edited: 2022-02-22 19:53:06
 */

import { getRequest, postRequest } from '@/common/http';

// 获取流程列表数据
function getProcessList(data: any) {
  return postRequest(`/api/processDefinition/getFormProcessList`, data);
}

// 获取具体流程详情
function getProcessDefinitionInfo(data: any) {
  return getRequest(`/api/processDefinition/getProcessDefinitionInfo`, data);
}

// 删除流程
function deleteProcessF(data: any) {
  return postRequest(`/api/processDefinition/deleteFormProcess`, data);
}

// 编辑流程
function editProcessDefinitionInfo(processId: string, data: any) {
  return postRequest(`/api/processDefinition/edit/${processId}`, data);
}

// 新增流程
function addProcess(data: any) {
  return postRequest(`/api/processDefinition/save`, data);
}

// 启动流程
function startProcess(data: any) {
  return postRequest(`/api/form/schema/publishFormProcess`, data);
}
// 停用流程
function stopProcess(data: any) {
  return postRequest(`/api/form/schema/invalidFormProcess`, data);
}

// 判断formCode是否创建过流程
function isCreateProcessByFormCode(data: any) {
  return getRequest(`/api/processDefinition/isCreateProcessByFormCode`, data);
}
export {
  getProcessList,
  getProcessDefinitionInfo,
  deleteProcessF,
  editProcessDefinitionInfo,
  addProcess,
  startProcess,
  stopProcess,
  isCreateProcessByFormCode,
};
