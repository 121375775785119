/*
 * @Description:
 * @FilePath: /low-code-platform/tg-renderer/packages/form-render/src/widgets/Cascade/index.tsx
 * :author: wu.shangru
 * :copyright: (c) 2023, Tungee
 * @Date: 2023-12-05 15:11:19
 * @LastEditors: wu.shangru
 * @LastEditTime: 2024-01-13 14:47:56
 */
import React, {
  useState,
  useEffect,
  useImperativeHandle,
  useRef,
  useMemo,
} from 'react';
import { Select, TreeSelect } from 'antd';
import { get } from 'lodash-es';
import { FC } from 'react';
import { WidgetProps } from 'typings';
import { IFormCompProps } from '../../typings';
import JumpLink from '../JumpLink';
import { useGlobal, useStore } from '@/components/FormGenerator/utils/hooks';

import './index.less';

interface IItem {
  key: string;
  value?: string;
}

const { Option } = Select;

const CascadeWidget: FC<WidgetProps<IItem>> = (props) => {
  const { value, onChange = () => {}, schema = {}, addons = {} } = props;
  const {
    setValues,
    setValueByPath,
    getValues,
    setSchemaByPath,
    formData = {},
  } = addons;
  const multiple = useMemo(() => {
    return schema?.widget === 'multiCascade' ? true : false;
  }, [schema]);
  const { enumOptionId } = schema;
  const { placeholder } = schema;

  const [treeData, setTreeData] = useState([]);

  // 缓存级联选项
  const { flatten, cascadesOptions } = useStore();

  if (props.readOnly) {
    return <span className="showNoEditText">{props?.value?.value || ''}</span>;
  }

  const filterTree = (target, tree, res = []) => {
    if (!target || !tree?.length) {
      return;
    }
    for (let i = 0; i < tree?.length; ++i) {
      if (tree[i]?.id === target) {
        res.push(tree[i]);
      }
      if (tree[i]?.children?.length) {
        filterTree(target, tree[i]?.children, res);
      }
    }
    return res;
  };

  useEffect(() => {
    if (enumOptionId) {
      setTreeData(cascadesOptions?.[enumOptionId]?.cascadeOptions ?? []);
    } else {
      setTreeData([]);
    }
  }, [enumOptionId, cascadesOptions]);

  return (
    <TreeSelect
      multiple={multiple}
      showSearch
      style={{ width: '100%' }}
      value={value?.id}
      dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
      placeholder={placeholder}
      allowClear
      onChange={(value, label, extra) => {
        const re = filterTree(value, treeData, []);
        const targetValue = {
          id: re?.[0]?.id,
          name: re?.[0]?.name,
          code: re?.[0]?.code,
          parentId: re?.[0]?.parentId,
          fullIdPath: re?.[0]?.fullIdPath?.split('/') ?? [],
          fullNamePath: re?.[0]?.fullNamePath?.split('/') ?? [],
        };
        onChange?.(targetValue);
      }}
      treeData={treeData}
      fieldNames={{ label: 'name', value: 'id', children: 'children' }}
    />
  );
};

export default CascadeWidget;
