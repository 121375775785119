/*
 * :file description:
 * :name: \low-code-platform\src\pages\form\components\FormTable\index.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-13 11:06:39
 * :last editor: hyw
 * :date last edited: 2022-03-28 12:01:20
 */

import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from 'react';
import { connect, GlobalModelState } from 'umi';
import { Table, Button, message, Divider, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { useParams } from 'umi';
import utils from '../../utils';
import { getFormCode } from '@/utils';
import formApi from '@/services/form';
import { genTableColumns, genTableData } from '../../utils/parse_schema';
import c from './index.less';
import { judgeByCons } from '@/utils/auth';
import ModalImport from '../ModalImport';
import { ExportType, QueryFieldReqType } from '@/services/form/data.d.ts';
import ColumnDisplayControl from '../../../../../tg-renderer/packages/table-render/src/components/ColumnDisplayControl';

const FormTable = (props, ref) => {
  const { showModal, global, dispatch, getKeyword } = props;
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageParam, setPageParam] = useState({
    pageSize: 10,
  });
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const formCode = getFormCode();
  const params = useParams();
  const [importVisible, setImportVisible] = useState(false);
  const [filter, setFilter] = useState<any>(null);
  // 导入导出权限控制
  const [isExportAuth, setIsExportAuth] = useState(false);
  const [isImportAuth, setIsImportAuth] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [countKey, setCountKey] = useState(0);
  useEffect(() => {
    reset();
  }, [formCode]);

  const reset = () => {
    setLoading(false);
    setColumns([]);
    setTotal(0);
    setPageParam({ pageSize: 10 });
    setDataSource([]);
    setSelectedRowKeys([]);
    setImportVisible(false);
    setFilter(null);
  };

  const deleteTips = {
    cited: '该表单被跟进记录表单引用，删除后会导致数据丢失，请慎重操作',
    notCited: '删除后不可恢复，请谨慎操作',
  };

  const handleDelete = async () => {
    if (!selectedRowKeys.length) {
      return message.warning('请至少勾选一条数据');
    }
    // 判断是否有被引用
    const isCited = false;
    Modal.confirm({
      title: '确认删除吗',
      icon: <ExclamationCircleOutlined />,
      content: deleteTips[isCited ? 'cited' : 'notCited'],
      okText: '确认删除',
      cancelText: '取消',
      onOk: () => {
        formApi
          .deleteFormBasedInstances(
            {
              formCode,
              instanceIds: selectedRowKeys,
            },
            {
              headers: {
                formCode,
              },
            },
          )
          .then((res) => {
            if (res.code === 200) {
              message.success('删除成功');
              setSelectedRowKeys([]);
              _.delay(() => search(), 2000);
            }
          });
      },
    });
    console.log('delete');
  };

  const titleRender = () => {
    return (
      <div className={c.operationRow}>
        <div className={c.operationWrapper}>
          {judgeByCons({
            checkCode: `FORM_${formCode}_DEL`,
          }) &&
            (selectedRowKeys.length ? (
              <>
                已选 {selectedRowKeys.length} 条
                <Divider
                  type="vertical"
                  style={{
                    margin: '0 15px',
                  }}
                />
                <span
                  style={{
                    marginRight: '20px',
                  }}
                >
                  批量
                </span>
                <Button
                  onClick={handleDelete}
                  loading={loading}
                  style={{ marginRight: 8 }}
                >
                  删除
                </Button>
              </>
            ) : null)}
          <div className={c.importExport}>
            {judgeByCons({
              checkCode: `FORM_${formCode}_IMPORT`,
            }) && (
              <Button style={{ marginRight: 8 }} onClick={handleImport}>
                导入
              </Button>
            )}

            {judgeByCons({
              checkCode: `FORM_${formCode}_EXPORT`,
            }) && (
              <Button onClick={handleExport} disabled={!dataSource.length}>
                导出
              </Button>
            )}
          </div>
        </div>
        <div className={c.todo}>
          <ColumnDisplayControl formCode={formCode} cb={search} />
        </div>
      </div>
    );
  };

  const rowSelection = {
    fixed: true,
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys, selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    onSelectAll: (selected, selectedRows) => {
      console.log(selected, selectedRows);
    },
  };
  const getDataBySorter = (pagination, sorter) => {
    const { current: pageIndex, pageSize } = pagination;
    search({ pageIndex, pageSize }, sorter);
  };

  const search = (
    {
      pageIndex = 1,
      pageSize = 10,
      // keyword = '',
      queryConditionList = [],
      type = 'filter',
    } = {},
    sorter = {},
  ) => {
    console.log('查询');
    const func =
      type === 'search' ? formApi.queryFormDataList : formApi.getFormDataList;
    let order = [];
    let sort = [];
    // 存在排序时
    if (sorter?.order) {
      order.push(sorter?.order == 'descend' ? 1 : 0);
      sort.push(sorter?.field);
    }

    const fetchParams = {
      formCode,
      keyword: getKeyword(),
      queryConditionList: queryConditionList.length
        ? queryConditionList
        : undefined,
      pageNum: pageIndex,
      pageSize,
      order: sorter?.order ? order : undefined,
      sort: sorter?.order ? sort : undefined,
    };
    setFilter(fetchParams);
    setTableLoading(true);
    func(fetchParams).then((res) => {
      console.log('结果：', res);
      setPageParam({
        pageSize: res?.data?.size ?? 10,
        current: res?.data?.current,
      });
      setTotal(res?.data?.total);
      setColumns(genTableColumns(res.data));
      setDataSource(genTableData(res.data));
      setTableLoading(false);
      setCountKey((val) => val + 1);
    });
  };

  const pagination = {
    total,
    showTotal: () => (
      <div
        style={{
          marginRight: '10px',
        }}
      >
        共 {total} 项
      </div>
    ),
    showSizeChanger: true,
    pageSizeOptions: [10, 20, 30, 40],
  };

  useEffect(() => {
    search();
    // 判断导入导出按钮功能
    let ifImportAuth = utils.getBtnAuth(formCode, 'IMPORT');
    let ifExportAuth = utils.getBtnAuth(formCode, 'EXPORT');
    setIsExportAuth(ifExportAuth);
    setIsImportAuth(ifImportAuth);
  }, [params.id]);

  useImperativeHandle(ref, () => ({
    search: (payload) => {
      search(payload);
    },
  }));

  const handleImport = () => {
    setImportVisible(true);
  };

  const handleExport = () => {
    formApi
      .exportData({
        exportType: selectedRowKeys.length
          ? ExportType.CHECKED
          : ExportType.ALL,
        formCode: formCode,
        instanceIds: selectedRowKeys.length ? selectedRowKeys : undefined,
        queryFieldReq: filter || undefined,
        total: selectedRowKeys.length || total,
      })
      .then((res) => {
        if (res.code === 200) {
          Modal.success({
            title: '操作成功',
            okText: '知道了',
            content: '导出完成后将通过消息通知发送给你，请稍后查看',
          });
          dispatch({
            type: 'global/fetchExportStatus',
            payload: { taskId: res.data + '' },
          });
        }
      });
  };

  return (
    <div className={c.form_table}>
      <Table
        scroll={{ x: 'max-content', y: 600 }}
        rowKey={(record) => record.instanceId}
        title={titleRender}
        rowSelection={
          columns?.length
            ? {
                ...rowSelection,
              }
            : null
        }
        loading={tableLoading}
        key={countKey}
        onRow={(record) => {
          return {
            onClick: (event) => {
              console.log(record);
              showModal(record.instanceId, formCode, nanoid(12));
            },
          };
        }}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          size: 'small',
          ...pagination,
          ...pageParam,
        }}
        onChange={(pagination, filters, sorter) => {
          getDataBySorter(pagination, sorter);
          console.log('sorter ???', pagination, filters, sorter, columns);
        }}
      />
      <ModalImport
        formCode={formCode}
        visible={importVisible}
        onClose={() => setImportVisible(false)}
        onFinish={() => search()}
      />
    </div>
  );
};

export default connect((global: GlobalModelState) => global, null, null, {
  forwardRef: true,
})(forwardRef(FormTable));
