import { InputNumber, Select } from 'antd';
import { COUNTER_RESET_LIST } from '../../constant';
import Style from './index.less';

const { Option } = Select;

const CountReset = (props) => {
  const { value, onChange } = props;
  const { length, reset } = value;

  return (
    <div className={Style.wrap}>
      <InputNumber
        className={Style.number}
        precision={0}
        min={1}
        max={8}
        value={length}
        onChange={(val) => {
          onChange({
            ...value,
            length: val,
          });
        }}
      />
      <Select
        className={Style.select}
        value={reset}
        onChange={(val) => {
          onChange({
            ...value,
            reset: val,
          });
        }}
      >
        {COUNTER_RESET_LIST.map((item, index) => {
          return (
            <Option value={item.value} key={index}>
              {item.label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default CountReset;
