/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\BaseSelect\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-28 21:42:22
 * :last editor: 黄艺
 * :date last edited: 2021-12-29 15:31:06
 */
import { FC } from 'react';
import { Select } from 'antd';
import { getArray } from '../../utils';
import { WidgetProps } from 'typings';

const FrSelect: FC<WidgetProps> = ({
  schema,
  style,
  options: _options,
  ...rest
}) => {
  let options;
  // 如果已经有外部注入的options了，内部的schema就会被忽略
  if (_options && Array.isArray(_options)) {
    options = _options;
  } else {
    const { enum: enums, enumNames } = schema || {};
    options = getArray(enums).map((item, idx) => {
      let label = enumNames && Array.isArray(enumNames) ? enumNames[idx] : item;
      const isHtml = typeof label === 'string' && label[0] === '<';
      if (isHtml) {
        label = <span dangerouslySetInnerHTML={{ __html: label }} />;
      }
      return { label, value: item };
    });
  }

  const finalProps = {
    options,
    style: { width: '100%', ...style },
    ...rest,
  };
  return <Select {...finalProps} />;
};

export default FrSelect;
