/*
 * :file description:
 * :name: /low-code-platform/tg-renderer/packages/form-render/src/widgets/DataFilters/FilterModal/index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-11 17:48:13
 * :last editor: hyw
 * :date last edited: 2024-03-13 22:23:06
 */
import { useEffect, useState } from 'react';
import { Modal, Form, Select, Input, InputNumber } from 'antd';
import { getUrlParam, getHashParam } from '@/utils';
import IconFont from '@/components/IconFont';
import _ from 'lodash';
import { useModel } from 'umi';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import {
  getFormSchema,
  getOaFormSchema,
  getCategoryTree,
} from '@/services/form-generator';
import FixedSelect from './FixedSelect';
import FixedDateTime from './FixedDateTime';
import FixedInnerContact from './FixedInnerContact';
import FixedInnerDepartment from './FixedInnerDepartment';
import { customJP } from '../../../utils';
import Style from './index.less';

const FILTER_TYPES = [
  {
    value: 'LT',
    label: '小于',
  },
  {
    value: 'EQ',
    label: '等于',
  },
  {
    value: 'GT',
    label: '大于',
  },
  {
    value: 'LTE',
    label: '小于等于',
  },
  {
    value: 'GTE',
    label: '大于等于',
  },
  {
    value: 'SEARCH',
    label: '搜索',
  },
  {
    value: 'BETWEEN',
    label: '范围区间',
  },
  {
    value: 'IN',
    label: '包含',
  },
  {
    value: 'NIN',
    label: '不包含',
  },
];

// 自定义时 是 select组件
const CUSTOM_FIXED_SUPPORT_OBJECT_TYPE = [
  'multiSelect',
  'select',
  'productStatus',
];

// 自定义时 审批相关
const LAST_PROCESS = ['lastProcessStatus', 'lastProcessResult'];

const NO_SUPPORT_WIDGETS = [
  // 'multiSelect',
  'dateRange',
  'textNote',
  'picture',
  'attachment',
  'tableList',
  'bizSuite',
];

const SUPPORT_WIDGETS_TABLEID = [
  'multiSelect',
  'select',
  'input',
  'date',
  'textarea',
  'number',
  'money',
];

const createUserId = {
  id: 'createUserId',
  title: '创建人',
  widget: 'createUserId',
};

const gmtCreate = {
  id: 'gmtCreate',
  title: '创建时间',
  widget: 'gmtCreate',
};

const lastProcessStatus = {
  enum: ['1', '2', '3'],
  enumNames: ['审批中', '审批完成', '审批终止'],
  id: 'lastProcessStatus',
  title: '审批状态',
  widget: 'lastProcessStatus',
};

const lastProcessResult = {
  enum: ['1', '2', '3'],
  enumNames: ['已同意', '已撤回', '审批终止'],
  id: 'lastProcessResult',
  title: '审批结果',
  widget: 'lastProcessResult',
};

/**
 * 数据过滤
 * 两种情况：
 * 1 正常关联表单过滤   通过 formCode获取相关接口
 *  @param {addons}  schema对象
 * 2 二级表格内字段过滤 通过tableId 只显示表格内组件
 * @param {tableId}
 */
export default (props) => {
  const {
    visible,
    onCancel,
    onChange,
    initValues,
    bizType,
    relateSource,
    tableId,
    addons,
  } = props;
  // const [ fieldList, setFieldList ] = useState([])
  const [schemaProperties, setSchemaProperties] = useState([]);
  const { flatten, selected, lastFlatten } = useStore();
  const { dataSource } = addons?.formData ?? {};
  const model = useModel('useGenerator', (model) => model);
  const { schemaRef } = model;
  const properties = schemaRef.current.properties || {};
  const [cascadeValue, setCascadeValue] = useState({ enum: [], enumNames: [] });
  const [isProcess, setIsProcess] = useState(false);
  const wrapFields = Object.values(properties);
  let fieldList = Object.values(schemaProperties).filter(
    (item) => !NO_SUPPORT_WIDGETS.includes(item.widget),
  );

  // 流程表单
  if (isProcess) {
    fieldList.push(lastProcessStatus);
    fieldList.push(lastProcessResult);
  }

  // 所有表单都有的创建人、创建时间
  fieldList.push(createUserId);
  fieldList.push(gmtCreate);

  // 二级表格不需要部门、成员、创建人
  if (tableId) {
    fieldList = fieldList.filter((i) =>
      SUPPORT_WIDGETS_TABLEID.includes(i?.widget),
    );
  }

  const getFormCode = () => {
    const { schema } = flatten[selected] || {};
    const widget = schema?.widget;

    // 这里是客户时适用的逻辑
    const { relateSource } = schema || {};
    let targetSchema = _.cloneDeep(schema);
    if (relateSource) {
      targetSchema = relateSource.find(
        (item: Record<string, any>) => item.bizType === bizType,
      );
    }

    // 这里是套件时适用的逻辑
    if (widget == 'bizSuite') {
      const properties = schema?.properties;
      targetSchema = properties?.[props.id];
    }
    const formCode = targetSchema?.dataSource?.target?.formCode;
    return formCode;
  };

  const isOaRelation = flatten[selected]?.schema?.isOaRelation;

  const [form] = Form.useForm();
  const onFinish = (values) => {
    const list = values?.list?.map((item) => {
      if (item?.valueType === 'fixed' && item?.fieldId?.includes('cascade')) {
        return {
          ...item,
          value: item?.value?.[0],
        };
      }
      return item;
    });
    const val = {
      ...values,
      list: list,
    };
    onChange(val.list || []);
    if (tableId) {
      const subTableDataFillingRules =
        addons?.formData?.subTableDataFillingRules ?? [];
      let index = subTableDataFillingRules?.findIndex(
        (i) => i.tableId === tableId,
      );
      index = index < 0 ? 0 : index;
      subTableDataFillingRules[index] = {
        ...subTableDataFillingRules[index],
        tableId: tableId,
        dataSource: {
          ...dataSource,
          params: {
            ...dataSource?.params,
            filters: val?.list ?? [],
          },
        },
      };
      props.addons.setValueByPath(
        `subTableDataFillingRules`,
        subTableDataFillingRules,
      );
    }
  };

  const getSchema = async () => {
    const formCode = getFormCode();
    let res = '';
    if (formCode) {
      if (!isOaRelation) {
        res = await getFormSchema({
          formCode,
        });
      } else {
        res = await getOaFormSchema({
          formCode,
        });
      }
      const schema = customJP(res?.data?.content);
      let properties = schema?.properties || {};
      if (tableId) {
        properties = {
          ...properties[tableId]?.items?.properties,
        };
      }
      setSchemaProperties(properties);
      if (!tableId) {
        setIsProcess(!!res?.data?.processId);
        if (res?.data?.bizType === 'crm_product') {
          const re = await getCategoryTree({
            corpId:
              getUrlParam('corpId') ||
              window.__corpId ||
              'ding6e37764efa4e5ce0f5bf40eda33b7ba0',
          });
          const value = re?.result?.reduce(
            (rst, cur) => {
              rst.enum = [...rst?.enum, cur?.id];
              rst.enumNames = [...rst?.enumNames, cur?.fullNamePath];
              return rst;
            },
            { enum: [], enumNames: [] },
          );
          setCascadeValue(value);
        }
      }
    } else {
      setSchemaProperties({});
    }
    // setSchemaForm(customJP(res?.data?.content));
  };
  // 对initValue的数据做处理  主要是处理历史数据的不兼容  或者 对数据进行必要封装
  const preDealInitValues = (initData) => {
    if (!initData?.length) return [];
    return initData.map((item) => {
      if (
        CUSTOM_FIXED_SUPPORT_OBJECT_TYPE.includes(
          item.fieldId?.split('_')?.[0],
        ) &&
        item.valueType === 'fixed'
      ) {
        return {
          ...item,
          value: item.value instanceof Array ? item.value : [item.value?.key],
          filterType: 'IN',
        };
      }
      if (
        LAST_PROCESS.includes(item.fieldId?.split('_')?.[0]) &&
        item.valueType === 'fixed'
      ) {
        return {
          ...item,
          value: item.value instanceof Array ? item.value : [item.value?.key],
          filterType: item?.filterType ?? 'IN',
        };
      }
      if (item.fieldId?.includes('cascade') && item.valueType === 'fixed') {
        return {
          ...item,
          value: [item?.value],
          filterType: 'EQ',
        };
      }
      return item;
    });
  };

  useEffect(() => {
    if (visible) {
      getSchema();
      form.setFieldsValue({
        list: preDealInitValues(initValues),
      });
    }
  }, [visible]);

  const getCurrentField = ({ fields, widget }) => {
    let types = [];

    if (!widget || !fields?.length) {
      return [];
    }

    switch (widget) {
      case 'input':
      case 'textarea':
      case 'seqNumber':
      case 'phone':
        types = ['phone', 'input', 'textarea', 'number', 'money'];
        break;
      case 'number':
      case 'money':
        types = ['number', 'money'];
        break;
      case 'multiSelect':
      case 'select':
      case 'lastProcessStatus':
      case 'lastProcessResult':
        types = ['input'];
        break;
      case 'cascade':
        types = ['input'];
        break;
      case 'cascadeV2':
        types = ['input'];
        break;
      case 'multiCascade':
        types = ['input'];
        break;
      case 'date':
      case 'gmtCreate':
        types = ['date', 'gmtCreate'];
        break;
      case 'innerContact':
      case 'createUserId':
        types = ['innerContact', 'createUserId'];
        break;
      case 'department':
        types = ['department'];
        break;
      // case 'phone':
      //   types = ['phone', 'input', ];
      //   break;
      case 'address':
        types = ['address'];
        break;
      case 'address':
        types = ['address'];
        break;
      case 'relation':
        types = ['relation'];
        break;
      case 'email':
        types = ['email', 'input'];
        break;
    }

    return fields.filter((field) => types.includes(field.widget));
  };

  // 根据选择字段匹配出过滤条件
  const getFieldTypesValues = (widget) => {
    if (!widget) {
      return [];
    }

    let types = [];
    switch (widget) {
      case 'input':
      case 'textarea':
      case 'seqNumber':
      case 'email':
        types = ['EQ', 'SEARCH'];
        break;
      case 'number':
      case 'money':
        types = ['LT', 'LTE', 'GT', 'GTE', 'EQ'];
        break;
      case 'multiSelect':
      case 'select':
      case 'productStatus':
        types = ['IN'];
        break;
      case 'lastProcessStatus':
      case 'lastProcessResult':
        types = ['IN', 'NIN'];
        break;
      case 'cascade':
        types = ['EQ'];
        break;
      case 'cascadeV2':
        types = ['EQ'];
        break;
      case 'multiCascade':
        types = ['EQ'];
        break;
      case 'date':
      case 'gmtCreate':
        return ['LT', 'GT', 'BETWEEN'];
      case 'innerContact':
      case 'createUserId':
        types = ['EQ', 'IN'];
        break;
      case 'department':
        types = ['EQ', 'IN'];
        break;
      case 'phone':
        types = ['EQ'];
        break;
      case 'address':
        types = ['EQ'];
        break;
      case 'relation':
        types = ['EQ'];
        break;
      default:
        types = ['EQ'];
    }

    return types;
  };

  const getFieldTypes = ({ widget }) => {
    const types = getFieldTypesValues(widget);

    if (widget == 'date' || widget == 'gmtCreate') {
      return [
        {
          value: 'LT',
          label: '早于',
        },
        {
          value: 'GT',
          label: '晚于',
        },
        {
          value: 'BETWEEN',
          label: '范围之间',
        },
      ];
    }

    return FILTER_TYPES.filter((item) => types.includes(item.value));
  };

  const getFixedFormItem = ({ field, filterType }) => {
    const widget = field?.widget;
    if (!widget) {
      return null;
    }

    switch (widget) {
      case 'input':
      case 'textarea':
      case 'seqNumber':
        return <Input placeholder="请输入" />;
      case 'number':
      case 'money':
        return <Input placeholder="请输入" />;
      case 'multiSelect':
      case 'select':
      case 'lastProcessStatus':
      case 'lastProcessResult':
      case 'productStatus':
        return <FixedSelect enum={field.enum} enumNames={field.enumNames} />;
      case 'date':
      case 'gmtCreate':
        return <FixedDateTime filterType={filterType} />;
      case 'innerContact':
      case 'principal':
      case 'cooperator':
      case 'createUserId':
        return <FixedInnerContact filterType={filterType} />;
      case 'department':
        return <FixedInnerDepartment filterType={filterType} />;
      case 'phone':
        return <Input placeholder="请输入" />;
      case 'address':
        return <Input placeholder="请输入" />;
      case 'email':
        return <Input placeholder="请输入" />;
      case 'cascade':
        return (
          <FixedSelect
            enum={cascadeValue?.enum}
            enumNames={cascadeValue?.enumNames}
          />
        );
      // return <Input placeholder="请输入" />;
      default:
        return <Input placeholder="请输入" />;
    }
    return null;
  };

  return (
    <Modal
      title="添加过滤条件"
      visible={visible}
      width={800}
      onCancel={onCancel}
      onOk={form.submit}
      wrapClassName={Style.addModal}
      closeIcon={<IconFont type="icon-Close" />}
    >
      <div className={Style.desc}>添加过滤条件来限定关联数据范围</div>

      <Form form={form} onFinish={onFinish}>
        <Form.List name="list">
          {(fields, { add, remove }) => (
            <>
              <div className={Style.scrollContent}>
                {fields.map((field) => {
                  return (
                    <div key={field.name} className={Style.row}>
                      <div className={Style.item}>
                        <Form.Item noStyle shouldUpdate={() => true}>
                          {({ setFieldsValue, getFieldValue }) => {
                            return (
                              <Form.Item
                                {...field}
                                name={[field.name, 'fieldId']}
                                fieldKey={[field.fieldKey, 'fieldId']}
                                rules={[
                                  {
                                    required: true,
                                    message: '必填项',
                                  },
                                ]}
                              >
                                <Select
                                  onChange={(e) => {
                                    const list = getFieldValue(`list`);
                                    const rowValue = list[field.name];
                                    const fieldId = rowValue?.fieldId;
                                    const innerItem = fieldList.find(
                                      (item) => item.id == fieldId,
                                    );
                                    const innerFieldWidget = innerItem?.widget;
                                    const types =
                                      getFieldTypesValues(innerFieldWidget);
                                    list[field.name] = {
                                      ...list[field.name],
                                      filterType: types?.[0],
                                      valueType: 'current',
                                      value: '',
                                    };

                                    setFieldsValue({
                                      list: [...list],
                                    });
                                  }}
                                  placeholder="关联表单字段"
                                >
                                  {fieldList.map((item) => {
                                    return (
                                      <Select.Option
                                        value={item.id}
                                        key={item.id}
                                      >
                                        {item.title}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </div>
                      <div className={Style.item}>
                        <Form.Item noStyle shouldUpdate={() => true}>
                          {({ setFieldsValue, getFieldValue }) => {
                            const list = getFieldValue(`list`);
                            const rowValue = list[field.name];
                            const fieldId = rowValue?.fieldId;
                            const innerItem = fieldList.find(
                              (item) => item.id == fieldId,
                            );
                            const innerFieldWidget = innerItem?.widget;
                            const filterTypes = getFieldTypes({
                              widget: innerFieldWidget,
                            });

                            return (
                              <Form.Item
                                {...field}
                                name={[field.name, 'filterType']}
                                fieldKey={[field.fieldKey, 'filterType']}
                                rules={[
                                  {
                                    required: true,
                                    message: '必填项',
                                  },
                                ]}
                              >
                                <Select
                                  onChange={(val) => {
                                    list[field.name] = {
                                      ...list[field.name],
                                      valueType: 'current',
                                      filterType: val,
                                      value: '',
                                    };

                                    setFieldsValue({
                                      list: [...list],
                                    });
                                  }}
                                  placeholder="请选择"
                                >
                                  {filterTypes.map((item) => {
                                    return (
                                      <Select.Option
                                        value={item.value}
                                        key={item.value}
                                      >
                                        {item.label}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </div>
                      <div className={Style.item}>
                        <Form.Item noStyle shouldUpdate={() => true}>
                          {({ getFieldValue, setFieldsValue }) => {
                            const list = getFieldValue(`list`);
                            const rowValue = list[field.name];
                            const fieldId = rowValue?.fieldId;
                            const innerItem = fieldList.find(
                              (item) => item.id == fieldId,
                            );
                            const innerFieldWidget = innerItem?.widget;
                            const VALUE_TYPES = [
                              {
                                value: 'current',
                                label: '当前表单字段',
                              },
                            ];

                            if (innerFieldWidget !== 'relation') {
                              VALUE_TYPES.push({
                                value: 'fixed',
                                label: '自定义',
                              });
                            }

                            return (
                              <Form.Item
                                {...field}
                                name={[field.name, 'valueType']}
                                fieldKey={[field.fieldKey, 'valueType']}
                                rules={[
                                  {
                                    required: true,
                                    message: '必填项',
                                  },
                                ]}
                              >
                                <Select
                                  onChange={(valueType) => {
                                    list[field.name] = {
                                      ...list[field.name],
                                      valueType,
                                      value:
                                        valueType === 'fixed' &&
                                        [
                                          ...CUSTOM_FIXED_SUPPORT_OBJECT_TYPE,
                                          ...LAST_PROCESS,
                                        ].includes(
                                          list[field.name]?.fieldId?.split(
                                            '_',
                                          )[0],
                                        )
                                          ? []
                                          : '',
                                    };

                                    setFieldsValue({
                                      list: [...list],
                                    });
                                  }}
                                  placeholder="请选择"
                                >
                                  {VALUE_TYPES.map((item) => {
                                    return (
                                      <Select.Option
                                        value={item.value}
                                        key={item.value}
                                      >
                                        {item.label}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </div>
                      <div
                        className={Style.item}
                        style={{ minWidth: 220, maxWidth: 220 }}
                      >
                        <Form.Item
                          noStyle
                          shouldUpdate={() => true}
                          className={Style.item}
                        >
                          {({ getFieldValue }) => {
                            const list = getFieldValue(`list`);
                            const rowValue = list[field.name];
                            const fieldId = rowValue?.fieldId;
                            const valueType = rowValue?.valueType;
                            const filterType = rowValue?.filterType;
                            const innerItem = fieldList.find(
                              (item) => item.id == fieldId,
                            );
                            const innerFieldWidget = innerItem?.widget;
                            const currentOptions = getCurrentField({
                              fields: wrapFields,
                              widget: innerFieldWidget,
                            });

                            let element = null;
                            if (valueType === 'current') {
                              // 从当前表单中选
                              element = (
                                <Select placeholder="请选择">
                                  {currentOptions.map((item) => {
                                    return (
                                      <Select.Option
                                        value={item.id}
                                        key={item.id}
                                      >
                                        {item.title}
                                      </Select.Option>
                                    );
                                  })}
                                </Select>
                              );
                            } else {
                              // 自定义 需要做各种判断
                              element = getFixedFormItem({
                                field: innerItem,
                                filterType,
                              });
                            }

                            return (
                              <Form.Item
                                {...field}
                                name={[field.name, 'value']}
                                fieldKey={[field.fieldKey, 'value']}
                                rules={[
                                  {
                                    required: true,
                                    message: '必填项',
                                  },
                                ]}
                              >
                                {element}
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </div>
                      <div className={Style['remove-icon']}>
                        <IconFont
                          type="icon-shanchu1"
                          onClick={() => remove(field.name)}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <Form.Item noStyle shouldUpdate={() => true}>
                {({ getFieldValue }) => {
                  const list = getFieldValue('list') || [];
                  if (list?.length >= 10) {
                    return null;
                  }
                  return (
                    <div
                      className={Style['add']}
                      onClick={() =>
                        add({
                          valueType: 'current',
                        })
                      }
                    >
                      <IconFont
                        type="icon-tianjia1"
                        className={Style['add-icon']}
                      />
                      添加过滤条件
                    </div>
                  );
                }}
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};
