/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\table-render\src\components\IconFont\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-31 14:07:27
 * :last editor: hyw
 * :date last edited: 2022-02-25 10:55:42
 */
import { createFromIconfontCN } from '@ant-design/icons';

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2877883_pqemg0n2xr.js', // 在 iconfont.cn 上生成
});

export default IconFont;
