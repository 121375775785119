/*
 * @Description:
 * @FilePath: \low-code-platform\src\pages\workNotice\index.tsx
 * author: 名字
 * copyright: (c) 2021, Tungee
 * @Date: 2021-12-29 14:22:56
 * @LastEditors: 段文君
 * @LastEditTime: 2022-01-11 18:26:22
 */
import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import IconFont from '@/components/IconFont';
import c from './index.less';
import { downloadFile } from '@/services/workNotice';
import { getHashParam } from '@/utils';

const NoticeMiddlePage = () => {
  const [fileData, setFileData] = useState<any>();
  const [isExpired, setIsExpired] = useState(false);

  const fileId = getHashParam('fileId');

  useEffect(() => {
    downloadFile({ id: fileId }).then((res) => {
      const data = res?.data ?? {};
      setFileData(data);
      if (data?.expireTime) {
        const nowDate = new Date().valueOf();
        const expiredDate = new Date(data.expireTime).valueOf();
        setIsExpired(nowDate > expiredDate);
      }
    });
  }, []);

  const onClick = () => {
    var eleLink = document.createElement('a');
    eleLink.download = fileData.fileName;
    eleLink.style.display = 'none';
    eleLink.href = fileData?.fileUrl;
    // 触发点击
    document.body.appendChild(eleLink);
    eleLink.click();
    // 然后移除
    document.body.removeChild(eleLink);
  };

  return (
    <div className={c.page_content}>
      {fileData ? (
        isExpired ? (
          <>
            <IconFont type="icon-a-bianzu20" className={c.err_icon} />
            <div className={c.description}>导出文件已过期，请重新导出</div>
            <p className={c.tips}>文件已于{fileData?.expireTime}过期</p>
          </>
        ) : (
          <>
            <IconFont type="icon-Check-Circle-Fill" className={c.icon} />
            <div className={c.description}>导出文件已生成，请尽快点击下载</div>
            <p className={c.tips}>
              文件将于{fileData?.expireTime}过期，过期文件无法下载
            </p>
            <Button type="primary" className={c.down_btn} onClick={onClick}>
              下载
            </Button>
          </>
        )
      ) : null}
    </div>
  );
};

export default NoticeMiddlePage;
