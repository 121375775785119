/*
 * :file description:
 * :name: \low-code-platform\src\components\renderer\TMoney\index.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 10:35:30
 * :last editor: gaoch
 * :date last edited: 2021-11-05 14:28:24
 */

import React, { ReactNode, ChangeEvent, useState } from 'react';
import { Input, InputProps } from 'antd';
const nzhcn = require('nzh/cn');
import c from './index.less';

const DEFAULT_INPUT_CONFIG = {
  size: 'large',
  placeholder: '请输入',
  bordered: true,
  addonAfter: null,
  addonBefore: null,
  prefix: null,
  suffix: null,
  allowClear: true,
  defaultValue: null,
  maxLength: null,
  disabled: false,
  onChange: () => {},
  onPressEnter: () => {},
};

// 数字格式校验
const numberReg = /^\d+(\.\d*)?$/;
const isNumber = (n: any) => {
  return numberReg.test(n);
};

const numberValidate = (e: ChangeEvent<HTMLInputElement>, onChange: any) => {
  console.log(e.target.value);
  console.log('isNumber(e.target.value) ', isNumber(e.target.value));
  if (!e.target.value || isNumber(e.target.value)) {
    onChange && onChange(e.target.value);
  }
};

const TMoney = (props: InputProps & { [key: string]: any }) => {
  const { placeholder } = DEFAULT_INPUT_CONFIG;
  const [sum, setSum] = useState<string | number | undefined>();
  const { onChange, upper } = props;

  const matchedComponent = (): ReactNode => {
    return (
      <Input
        placeholder={placeholder}
        {...props}
        onChange={(e) => {
          console.log(e.target.value);
          setSum(e.target.value);
          onChange && onChange(e.target.value);
        }}
      />
    );
  };

  return (
    <div className={c.t_money}>
      {matchedComponent()}
      {upper ? (
        <div className={c.t_capitalization}>
          <span className={c.t_label}>大写</span>
          {sum &&
            isNumber(sum) &&
            nzhcn.toMoney(sum, {
              outSymbol: false,
            })}
        </div>
      ) : null}
    </div>
  );
};

export default TMoney;
