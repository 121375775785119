import React, { useState, useEffect } from 'react';
import FormRender, { useForm } from 'form-render';
import { Modal, Button } from 'antd';
import IconFont from '../IconFont';
import Input from '../../../../form-render/src/widgets/Sift_Input';
import InputNumberRange from '../../../../form-render/src/widgets/Sift_InputNumberRange';
import DateRange from '../../../../form-render/src/widgets/Sift_DateRange';
import Select from '../../../../form-render/src/widgets/Sift_Select';
import MultipleSelect from '../../../../form-render/src/widgets/Sift_MultipleSelect';
import AssociationSelector from '../../../../form-render/src/widgets/Sift_AssociationSelector';
// 人员
// 关联表单
// 人员

import { useTable } from '../../hooks';
import './index.less';

const widgets = {
  input: Input,
  number: InputNumberRange,
  date: DateRange,
  select: Select,
  multiSelect: MultipleSelect,
  relation: AssociationSelector,
};

const AdvancedFilter = () => {
  const form = useForm();
  const { refresh }: any = useTable();
  const [isVisible, setIsVisible] = useState(false);
  const [schema, setSchema] = useState();

  const onOk = () => {
    console.log('formData: ', form);
    // refresh
  };

  const onCancel = () => {
    form.resetFields();
    setIsVisible(false);
  };

  const echo = (payload) => {
    form.setValues(payload);
  };

  const data = {
    type: 'object',
    properties: {
      TextTokenize_Vi6gvyRmqU6l: {
        title: '单行输入框',
        type: 'string',
        widget: 'input',
        placeholder: '请输入',
      },
      TextTokenize_aXMRaMJ8EjRG: {
        title: '多行输入框',
        type: 'string',
        format: 'textarea',
        widget: 'textarea',
        placeholder: '请输入',
      },
      Double_vgVT8mUM3E74: {
        title: '数字输入框',
        type: 'number',
        widget: 'number',
        placeholder: '请输入数字',
        limitDot: 3,
      },
      Date_J7OSVxjT5yhZ: {
        title: '日期',
        type: 'string',
        format: 'date',
        widget: 'date',
        placeholder: '请选择',
      },
      DateRange_PG68Wxt4RU_R: {
        title: '日期区间',
        type: 'range',
        format: 'dateTime',
        props: {
          placeholder: ['开始时间', '结束时间'],
        },
        widget: 'dateRange',
      },
      Keyword_Ul7c8rhqTtZp: {
        title: '单选',
        type: 'string',
        enumNames: ['选项1', '选项2', '选项3'],
        widget: 'select',
        placeholder: '请选择',
        enum: ['option_0', 'option_1', 'option_2'],
      },
      Keyword_jCDUZQAT_CO8: {
        title: '多选框',
        type: 'array',
        items: {
          type: 'string',
        },
        enumNames: ['选项1', '选项2', '选项3'],
        widget: 'multiSelect',
        placeholder: '请选择',
        enum: ['option_0', 'option_1', 'option_2'],
      },
      TextPrefix_fpWCOevD26IF: {
        title: '电话',
        type: 'string',
        widget: 'phone',
        placeholder: '请输入',
        mode: 'phone_tel',
      },
      TextNoIndex_m6GMRoCyXP66: {
        title: '图片',
        type: 'array',
        widget: 'picture',
        items: {
          type: 'string',
        },
      },
      Double_WwYzZKhDi3vW: {
        title: '金额',
        type: 'number',
        widget: 'money',
        placeholder: '请输入',
        upper: true,
      },
      RelationForm_gr6bHwerU9DL: {
        title: '只读测试',
        type: 'string',
        widget: 'relation',
        fields: {
          TextTokenize_VG9LqR1_ocVU: {
            title: '单行输入框',
            type: 'string',
            widget: 'input',
            placeholder: '请输入',
          },
          TextPrefix_6O3MtUie1ZbZ: {
            title: '电话',
            type: 'string',
            widget: 'phone',
            placeholder: '请输入',
            mode: 'phone_tel',
            required: true,
          },
          Double__jIA3k9GHoTX: {
            title: '数字输入框',
            type: 'number',
            widget: 'number',
            placeholder: '请输入数字',
            required: true,
          },
          TextTokenize_n0KhIsZCkLBL: {
            title: '多行输入框',
            type: 'string',
            format: 'textarea',
            widget: 'textarea',
            placeholder: '请输入',
          },
          DateRange_yIscypnXqtov: {
            title: '日期区间',
            type: 'range',
            format: 'dateTime',
            props: {
              placeholder: ['开始时间', '结束时间'],
            },
            widget: 'dateRange',
          },
          Keyword_7fLHDy1onoAy: {
            title: '单选',
            type: 'string',
            enumNames: ['选项1', '选项2', '选项3'],
            widget: 'select',
            placeholder: '请选择',
            enum: ['option_0', 'option_1', 'option_2'],
          },
          Keyword_Cnd_EDiuKbpo: {
            title: '多选框',
            type: 'array',
            items: {
              type: 'string',
            },
            enumNames: ['选项1', '选项2', '选项3'],
            widget: 'multiSelect',
            placeholder: '请选择',
            enum: ['option_0', 'option_1', 'option_2'],
          },
          'TextNoIndex__oPc1R0O-P3r': {
            title: '图片',
            type: 'array',
            widget: 'picture',
            items: {
              type: 'string',
            },
            required: true,
          },
          Double_jbBe9ohs3qjn: {
            title: '金额',
            type: 'number',
            widget: 'money',
            placeholder: '请输入',
            upper: true,
            required: true,
          },
        },
        dataSource: {
          type: 'form',
          params: {
            filter: '',
          },
          target: {
            appUuid: '',
            bizType: '',
            formCode: 'PROC-5A04B74C-7DB6-42C8-8CD3-779662836CB1',
            appType: 1,
          },
        },
      },
    },
    labelWidth: 120,
    displayType: 'row',
  };

  const ssr = {
    type: 'object',
    properties: {
      'input_Jc-ZCK': {
        title: '单骑闯关',
        type: 'string',
        displayType: 'row',
        default: '五关六将',
        required: false,
        widget: 'textarea',
        props: {},
      },
      // "date_62A2WG": {
      //   "title": "活动日期",
      //   "type": "string",
      //   "format": "date",
      //   "displayType": "row",
      //   "widget": "input",
      //   "required": false
      // },
      // "select_GjmBUV": {
      //   "title": "单选",
      //   "type": "string",
      //   "enum": [
      //     "a",
      //     "b",
      //     "c"
      //   ],
      //   "enumNames": [
      //     "早",
      //     "中",
      //     "晚"
      //   ],
      //   "widget": "select"
      // },
      // "multiSelect_1DoSJT": {
      //   "title": "多选",
      //   "description": "下拉多选",
      //   "type": "object",
      //   "items": {
      //     "type": "string"
      //   },
      //   "enum": [
      //     "A",
      //     "B",
      //     "C",
      //     "D"
      //   ],
      //   "enumNames": [
      //     "杭州",
      //     "武汉",
      //     "湖州",
      //     "贵阳"
      //   ],
      //   "widget": "multiSelect"
      // },
      number_kfLxbG: {
        title: '价格',
        type: 'number',
        displayType: 'row',
        widget: 'date',
      },
    },
    labelWidth: 120,
    displayType: 'row',
  };

  const getSchema = () => {
    setSchema(ssr);
    echo({});
  };

  useEffect(() => {
    getSchema();
  }, []);

  return (
    <div className="column_display">
      <Button type="default" onClick={() => setIsVisible(true)}>
        <IconFont
          type="icon-shaixuan"
          style={{ fontSize: '16px', color: 'rgba(23,26,29,0.60)' }}
        />
        <span style={{ marginLeft: '4px' }}>高级筛选</span>
      </Button>
      <Modal
        className="advanced-filter"
        width={600}
        visible={isVisible}
        maskClosable={true}
        destroyOnClose={true}
        title="筛选"
        okText="筛选"
        cancelText="重置"
        onOk={onOk}
        onCancel={onCancel}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <div>
          <FormRender form={form} schema={ssr} widgets={{ ...widgets }} />
        </div>
      </Modal>
    </div>
  );
};

export default AdvancedFilter;
