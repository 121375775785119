import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Radio, message } from 'antd';
import IconFont from '@/components/IconFont';
import FormFormula from '../components/FormFormula';

interface IProps {
  visible: boolean;
  onClose: () => void;
  currentRule: ISubmitRules | undefined;
  onChange: (data: ISubmitRules) => Promise<any>;
}

const { TextArea } = Input;

export default (props: IProps) => {
  const { visible, onClose, currentRule = {}, onChange } = props;
  const [form] = Form.useForm();
  const curStatus =
    typeof currentRule === 'object' && Object.keys(currentRule).length > 0;

  const hideModal = () => {
    onClose?.();
  };

  useEffect(() => {
    form.setFieldsValue({
      name: currentRule?.name,
      rule: currentRule?.rule,
      message: currentRule?.message,
    });
  }, [currentRule]);

  const onFinish = async () => {
    await form.validateFields();
    const formData = form.getFieldsValue();
    const { name, message: tipMessage } = formData;
    await onChange({
      ...formData,
      name: name?.trim(),
      message: tipMessage?.trim(),
      ruleId: currentRule?.ruleId || new Date().getTime(),
    });
    message.success('保存成功！');
    hideModal();
  };

  const validator = [
    {
      validator: (rule: any, value: string) => {
        if (!value?.trim()) {
          return Promise.reject('请输入内容');
        }
        return Promise.resolve(value);
      },
    },
  ];

  return (
    <Modal
      title={curStatus ? '编辑规则' : '添加规则'}
      visible={visible}
      onOk={onFinish}
      onCancel={hideModal}
      okText="确认"
      width={480}
      cancelText="取消"
      destroyOnClose
      closeIcon={<IconFont type="icon-Close" />}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label="规则名称" required name="name" rules={validator}>
          <Input placeholder="请输入" maxLength={20} showCount />
        </Form.Item>
        <Form.Item
          label="满足以下条件才可提交"
          name="rule"
          rules={[{ required: true, message: '请设置关联规则' }]}
        >
          <FormFormula />
        </Form.Item>
        <Form.Item
          label="不满足该校验条件时的提示文案"
          name="message"
          rules={validator}
          required
        >
          <TextArea
            placeholder="请输入"
            style={{ height: 70 }}
            showCount
            maxLength={30}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
