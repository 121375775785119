/*
 * :file description:
 * :name: \low-code-platform\src\widgets\Switch\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-28 16:48:31
 * :last editor: 黄艺
 * :date last edited: 2021-12-01 15:10:08
 */
import { Switch, Popover } from 'antd';
import Styles from './index.less';
import IconFont from '@/components/IconFont';
import scan_intro from '@/assets/scan_intro.png';

const Scan = ({ value, ...rest }) => {
  // const { getSchemaByPath, formData } = rest?.addons;
  // const { flatten } = useStore();
  // const { $id } = formData;
  // const parentId = $id?.split(/(?<!#)\//).shift();

  const content: React.FC = () => {
    return (
      <div className={Styles.contentWrapper}>
        <div className={Styles.contentTitle}>支持移动端扫描二维码或条形码</div>
        <div className={Styles.contentDes}>
          设备、资产等物品管理场景中，扫码录入编号进行入库登记、保修
        </div>
        <div className={Styles.contentDemoImg}>
          <img src={scan_intro} alt="" />
        </div>
      </div>
    );
  };

  return (
    <div className={Styles.wrapper}>
      <Popover
        placement="bottom"
        arrowPointAtCenter
        overlayClassName={Styles.popover}
        content={content}
      >
        <div className={Styles.popoverIcon}>
          <IconFont type="icon-shuoming"></IconFont>
        </div>
      </Popover>
      <Switch checked={value} {...rest} />
    </div>
  );
};

export default Scan;
