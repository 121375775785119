/*
 * :file description:
 * :name: \commond:\progree\low-code-platform\src\components\MultipleChoice\api.ts
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-18 14:13:26
 * :last editor: hyw
 * :date last edited: 2021-12-16 14:35:56
 */
import {
  request,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from '@/common/http';

//获取部门树
function departmentTree(params: any) {
  return postRequest('/api/org/department/treeCompany', params);
}

//搜索部门数据
function serachDepartmentList(params: any) {
  return postRequest('/api/org/department/list', params);
}

//搜索-查询员工列表（员工名称 含 所属部门）
function searchUserList(params: any) {
  return postRequest('/api/user/user-dept-name/list', params);
}

//获取人员部门数据
function companyUsers(params: any) {
  return postRequest('/api/org/tree-node/company-users', params);
}

//获取人员数据
function searchUsersByDepartmentId(params: any) {
  return postRequest('/api/org/department/user/list', params);
}

// 获取当前登录用户的企业应用的角色树
function roleTree() {
  return getRequest('/api/role/tree');
}

//获取角色列表
function searchRoleList(params: any) {
  return postRequest('/api/role/list', params);
}

//根据部门获取部门下的所有人员
function getAllUserDataByDepart(params: any) {
  return postRequest('/api/org/relate-dept/user/list', params);
}

//获取所有人员
function listAll() {
  return getRequest('/api/user/listAll');
}

//根据部门获取部门下的所有人员
function getUserByPage(params: any) {
  return postRequest('/api/user/page', {
    pageSize: params?.pageSize ?? 20,
    ...params,
  });
}

export {
  departmentTree,
  serachDepartmentList,
  searchRoleList,
  searchUserList,
  roleTree,
  companyUsers,
  searchUsersByDepartmentId,
  getAllUserDataByDepart,
  listAll,
  getUserByPage,
};
