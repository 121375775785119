import { DatePicker, DatePickerProps } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker/index';
import moment from 'moment';
import c from './index.less';

type DateType = 'date' | 'dateTime';
// 日期格式化
const DEFAULT_FORMAT = 'YYYY-MM-DD';
const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const transformDateToString = (value: any, formatType: DateType) => {
  const format =
    formatType === 'dateTime' ? DEFAULT_DATETIME_FORMAT : DEFAULT_FORMAT;
  if (!value) {
    return value;
  }
  value = moment.isMoment(value) ? value : moment(value);
  return value.format(format);
};

const transformDateToMoment = (value: any) => {
  if (!value) return value;
  return moment.isMoment(value) ? value : moment(value);
};

const { RangePicker } = DatePicker;

const DateRangeWidget = (
  props: DatePickerProps & RangePickerProps<any> & { [key: string]: any },
) => {
  const { value, schema, onChange } = props;
  const { placeholder, format } = schema;
  const [start, end] = value ?? [];

  const handleChange = (e) => {
    const [start, end] = e ?? [];
    onChange([transformDateToMoment(start), transformDateToMoment(end)]);
  };

  if (props.readOnly) {
    const [start, end] = props.value ?? [];
    return (
      <span className="showNoEditText">
        {transformDateToString(start, format)}
        {<span> - </span>}
        {transformDateToString(end, format)}
      </span>
    );
  }

  return (
    <RangePicker
      style={{ width: '100%' }}
      value={
        value
          ? [transformDateToMoment(start), transformDateToMoment(end)]
          : undefined
      }
      onChange={(e) => handleChange(e)}
      placeholder={placeholder}
      showTime={format === 'dateTime'}
    />
  );
};

export default DateRangeWidget;
