/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\RelationSelector\components\TableListSelector\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-30 13:47:20
 * :last editor: 黄艺
 * :date last edited: 2022-03-25 09:54:08
 */
import { useEffect, useState, useCallback, FC } from 'react';
import { Modal, Input, Table, LabeledValue } from 'antd';
import { ColumnType } from 'antd/lib/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import formApi from '@/services/form';
import './index.less';
import IconFont from '@/components/IconFont';
import {
  genTableColumns,
  genTableData,
} from '~@/packages/table-render/src/utils';
import { customJP, isJSON } from '~@/packages/form-render/src/utils';
import { customCompDetail } from '../../../Sift_AssociationSelector/relation_template';
import { IObj, WidgetProps } from 'typings';
import SelectorModal from '../SelectorModal';

const RelationSelector: FC<WidgetProps> = (props) => {
  const { schema, addons, value } = props;
  const [visible, setVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [instanceContent, setInstanceContent] = useState<typeof IObj>();

  const [innerSchema, setInnerSchema] = useState();

  const fields =
    Object.keys(props?.schema).length > 0
      ? props?.schema?.fields
      : props?.fields;

  const fieldList =
    Object.keys(fields || {}).length > 0 ? Object.keys(fields) : [];

  const handleConfirm = () => {
    if (selectedRows?.length) {
      selectedRows?.forEach((item, index) => {
        const { formCode } = schema.dataSource.target;
        const { instanceId, title, key } = item;
        const path = addons.dataPath.replace(
          /\[(.*)\]/,
          (_match: string, p: number) => {
            return `[${p * 1 + index}]`;
          },
        );
        addons.setValueByPath(path, {
          formCode,
          instanceId,
          title,
        });
      });
    }
  };

  const handleSelect = () => {
    setVisible(true);
  };

  const fieldBoxWidth = 150 * fieldList.length;

  const getFormSchema = async () => {
    const { formCode, instanceId } = value || {};
    if (!formCode) return;
    try {
      const schema = await formApi.getFormSchema({ formCode });
      const instance = await formApi.getFromInstanceDetail(
        {
          formCode,
          instanceId,
        },
        {
          headers: {
            formCode,
          },
        },
      );
      const innerSchema = isJSON(schema?.data.content)
        ? customJP(schema?.data.content)
        : undefined;
      const instanceContent = isJSON(instance?.data.content)
        ? customJP(instance?.data.content)
        : undefined;
      setInnerSchema(innerSchema);
      setInstanceContent(instanceContent);
    } catch (error) {}
  };

  useEffect(() => {
    getFormSchema();
  }, [props.value]);

  const modalProps = {
    ...props,
    visible,
    setVisible,
    selectedRows,
    setSelectedRows,
    handleConfirm,
    type: 'tableSelector',
  };

  return (
    <div
      className="fui_link_field"
      style={{ width: `${fieldBoxWidth + 100}px` }}
    >
      <div className="data-select select-btn">
        <IconFont onClick={handleSelect} type="icon-shujuguanlian"></IconFont>
      </div>
      {fieldList.length > 0 ? (
        fieldList.map((key) => (
          <div className="link-field sub-link">
            <div
              className="fl-container fui_form_item_disable fui_subform_item_disable x-ui-disable"
              data-watermark="暂无内容"
              style={{ width: '150px' }}
            >
              {customCompDetail(
                { id: key, value: instanceContent?.[key] },
                innerSchema,
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="link-field sub-link">
          <div
            className="fl-container fui_form_item_disable fui_subform_item_disable x-ui-disable"
            data-watermark="暂无内容"
          >
            {props?.value?.title}
          </div>
        </div>
      )}
      <SelectorModal {...modalProps}></SelectorModal>
    </div>
  );
};

export default RelationSelector;
