import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import { isJSON, customJP } from '../../utils';
import { IFormCompProps } from '../../typings';
import './index.less';

type WidgetProps = IFormCompProps;

const { TextArea } = Input;

const TextAreaWidget = (props: WidgetProps) => {
  const { value, onChange = () => {}, schema = {} } = props;
  const { value: initialValue } = isJSON(value)
    ? customJP(value)
    : { value: undefined };
  const { placeholder = '请输入' } = schema;

  const [keyword, setKeyword] = useState(initialValue);

  useEffect(() => {
    const payload = {
      value: keyword,
    };
    onChange(keyword ? JSON.stringify(payload) : undefined);
  }, [keyword]);

  useEffect(() => {
    if (isJSON(value)) {
      const valueObj = customJP(value);
      setKeyword(valueObj.value);
    } else {
      setKeyword(undefined);
    }
  }, [value]);

  return (
    <Input.Group compact className="fr-group">
      <TextArea
        rows={3}
        style={{ width: '100%' }}
        allowClear
        value={keyword}
        placeholder={placeholder}
        maxLength={8000}
        onChange={(e) => setKeyword(e.target.value)}
      />
    </Input.Group>
  );
};

export default TextAreaWidget;
