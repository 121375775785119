/*
 * :file description:
 * :name: \low-code-platform\src\common\http\constant.ts
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-05 11:02:20
 * :last editor: 黄艺
 * :date last edited: 2021-11-05 11:14:57
 */
// ==== 权限 ====
// 设计表单新建
export const DESIGN_FORM_CREATE = 'DESIGN_FORM_CREATE';
// 设计态表单复制
export const DESIGN_FORM_COPY = 'DESIGN_FORM_COPY';
// 设计态表单修改
export const DESIGN_FORM_EDIT = 'DESIGN_FORM_EDIT';
// 设计态表单删除
export const DESIGN_FORM_DEL = 'DESIGN_FORM_DEL';

export const SUBID_MAP: { [key: string]: any } = {
  '33727001_0': {
    preUrl: '/crm3',
    appName: 'dingding_tungee_crm_new',
  },
  '33866001_0': {
    preUrl: '/crm3',
    appName: 'dingding_tungee_crm_new',
  },
  '34149001_0': {
    preUrl: '/crm3',
    appName: 'dingding_tungee_crm_new',
  },
};
