/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DataTitle\common\formulaEditor.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-04 15:18:19
 * :last editor: 黄艺
 * :date last edited: 2021-12-29 19:11:19
 */

import { utils } from '@/widgets/DefaultValueSelector/components/formula-editor/common/utils';
import CodeMirror from 'codemirror';
import {
  DEPRECATE_FIELD_CLS,
  INVALID_FIELD_CLS,
  NAME_FILED_CLS,
  VALUE_FIELD_CLS,
} from './constant';

const _markField = (t, editor) => {
  var i = '',
    n = {
      'data-field': t.field,
    };
  t.invalid
    ? (i = INVALID_FIELD_CLS)
    : t.entry
    ? ((i = NAME_FILED_CLS), (n['data-entry'] = t.entry))
    : (i = VALUE_FIELD_CLS),
    $(
      editor.markText(t.from, t.to, {
        handleMouseEvents: !0,
        atomic: !0,
        replacedWith: $(
          '<span class="cm-field ' + i + '">' + t.text + '</span>',
        )[0],
      }).widgetNode,
    )
      .attr(n)
      .addClass(i);
};

export const insertField = (a, b, editor) => {
  var c = editor.getCursor();
  editor.replaceSelection('' + a.text + '');
  var d = editor.getCursor(),
    e = {
      from: c,
      to: d,
      field: a.name, //工作项ID
      entry: b ? a.id : null, //工作项所属的表单id
      text: a.text,
    };
  _markField(e, editor), editor.focus();
};

export const insertBarcket = (editor) => {
  var c = editor.getCursor();
  editor.replaceSelection('(');
  var d = editor.getCursor();
  $(
    editor.markText(c, d, {
      handleMouseEvents: !0,
      atomic: !0,
      replaceWith: $(
        '<span class="cm-bracket CodeMirror-matchingbracket">(</span>',
      )[0],
    }).widgetNode,
  );
  var c1 = editor.getCursor();
  editor.replaceSelection(')');
  var d1 = editor.getCursor();
  $(
    editor.markText(c1, d1, {
      handleMouseEvents: !0,
      atomic: !0,
      replaceWith: $(
        '<span class="cm-bracket CodeMirror-matchingbracket">)</span>',
      )[0],
    }).widgetNode,
  );
  editor.setCursor(d);
  editor.focus();
};

export const insertFormulaitem = (text, editor) => {
  var c = editor.getCursor();
  editor.replaceSelection('' + text + '');
  var d = editor.getCursor();
  $(
    editor.markText(c, d, {
      handleMouseEvents: !0,
      atomic: !0,
      replaceWith: $('<span class="cm-keyword">' + text + '</span>')[0],
    }).widgetNode,
  );
  insertBarcket(editor);
};

export const Backspace = (a) => {
  var b = a.getTokenAt(a.getCursor());
  if ('field' == b.type) {
    var c = a.getCursor().line;
    a.setSelection(
      new CodeMirror.Pos(c, b.start),
      new CodeMirror.Pos(c, b.end),
    ),
      a.replaceSelection('', null, '+delete');
  } else a.execCommand('delCharBefore');
};

export const getValue = (editor) => {
  var b = [],
    c = {
      NAME_FILED_CLS,
      VALUE_FIELD_CLS,
      INVALID_FIELD_CLS,
      DEPRECATE_FIELD_CLS,
    },
    d = [],
    e = $(editor.display.lineDiv).find('pre:first>span');
  return (
    utils?.forEach(e, function (e, f) {
      var g = [];
      utils?.forEach(f.childNodes, function (b, e) {
        var f = $(e).attr('data-field'),
          h = $(e).attr('data-entry');
        if ($(e).hasClass(c.NAME_FILED_CLS)) g.push('#{' + f + '}' + h);
        else if ($(e).hasClass(c.VALUE_FIELD_CLS))
          g.push('#{' + f + '}'), d.indexOf(f) === -1 && d.push(f);
        else {
          if (
            $(e).hasClass(c.DEPRECATE_FIELD_CLS) ||
            $(e).hasClass(c.INVALID_FIELD_CLS)
          )
            return;
          g.push($(e).text());
        }
      });

      var h = g
        .join('')
        .replace(/\u200b/g, '')
        .replace(/\u00a0/g, ' ');
      b.push(h);
    }),
    {
      formula: b.join('\n'),
      relyWidgets: d,
    }
  );
};

const _getFieldLabelMap = (a) => {
  var t,
    n = {};
  return (
    'array' === a.type && a.widget === 'table'
      ? utils?.forEach(a.items.properties, function (t, e) {
          var i = ['#{', a.id, '.', e.id, '}'].join('');
          n[i] = [a.title, e.title].join('.');
        })
      : ((t = ['#{', a.id, '}'].join('')), (n[t] = a.title)),
    n
  );
};

export const getLabelMap = (fields) => {
  const n = {};
  utils?.forEach(fields, function (t, e) {
    $.extend(n, _getFieldLabelMap(e));
  }),
    !1;
  return n;
};

export const setValue = (a, labelMap, editor) => {
  var d = [],
    e = [];
  if (a) {
    var f = a.split('\n');
    utils?.forEach(f, function (a, b) {
      var f = '',
        g = b.split(/(#{[0-9a-zA-Z\._\-@]+})/g);
      utils?.forEach(g, function (b, d) {
        if (/^#{/.test(d)) {
          var g;
          utils.startWith(d, '$ext')
            ? (g = '扩展字段')
            : labelMap && (g = labelMap[d]);
          var h = !1;
          utils.isNull(g) && ((g = '无效字段'), (h = !0));
          var i = d.replace('#{', '').split('}'),
            j = i[0],
            k = i[1],
            l = CodeMirror.Pos(a, f.length);
          f += '' + g + '';
          var m = CodeMirror.Pos(a, f.length);
          e.push({
            from: l,
            to: m,
            field: j,
            entry: k,
            invalid: h,
            text: g,
          });
        } else f += d;
      }),
        d.push(f);
    });
  }
  editor.setValue(d.join('\n')),
    utils?.forEach(e, function (a, c) {
      _markField(c, editor);
    });
};

export const _calFormula = (a, b) => {
  var c = this,
    d = a.split(/(\$[0-9a-zA-Z\._#@]+)/g),
    e = [];
  utils?.forEach(d, function (a, d) {
    if (utils.startWith(d, '$_widget_') || utils.startWith(d, '$ext')) {
      var f = d.replace('$_widget_', '').split('#'),
        g = f[0],
        h = f[1];
      console.log(f);
      if (utils.isEmpty(h)) {
        var i;
        if (b) i = b[g];
        else {
          var j = c.getWidgetByName(g);
          utils.isArray(j)
            ? ((i = []),
              utils?.forEach(j, function (a, b) {
                i.push(b.getLinkValue());
              }))
            : j && (i = j.getLinkValue());
        }
        var k = JSON.stringify(i) + '';
        !isNaN(i) && i < 0 && (k = '(' + k + ')'), e.push(k);
      } else e.push('"' + d + '"');
    } else e.push(d);
  });
  var f;
  try {
    console.log(e.join(','));
    f = utils.evalFormula(e.join(''));
    console.log(f);
  } catch (a) {
    f = '';
  }
  return f;
};
