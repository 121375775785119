/*
 * :file description:
 * :name: \low-code-platform\src\services\form\data.d.ts
 * :author: PakJeon
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-10 16:50:39
 * :last editor: hyw
 * :date last edited: 2022-03-22 20:52:09
 */
export type getTemplateParams = {
  formCode: string;
};

export enum FileImportType {
  create = '0', // 仅新增数据
  update = '1', // 仅更新数据
  createAndUpdate = '2', // 更新和新增数据
}

export type uploadFileParams = {
  formCode: string;
  importType: FileImportType;
  keyPrefix: string;
  file: File;
  needTriggerProcess: '0' | '1';
};

export type importPreviewParams = {
  fileId: string;
  formCode: string;
  isUpdate: boolean;
};

export type columnMatcheItem = {
  colIndex: number;
  fieldId: string;
  parentColMatch?: columnMatcheItem;
  relatedFieldId?: string;
  relatedFormCode?: string;
  required: boolean;
  widget: string;
  bizAlias?: string;
  formCode?: string;
  relatedFormBizType?: string | undefined;
};

export type importStartParams = {
  columnMatches: columnMatcheItem[];
  creatorColIndex?: number;
  fileId: string;
  formCode: string;
  gmtCreateColIndex?: number;
  instanceIdColIndex?: number;
  taskId: string;
};

export enum TaskResultAction {
  IMPORT = 0,
  EXPORT = 1,
}

export type taskResultParams = {
  action: TaskResultAction;
  taskId: string;
};

export enum ExportType {
  ALL, // 全部
  CHECKED, // 勾选
}

export enum ORDER_TYPE {
  ASCENDING, // 升序
  DESCENDING, // 降序
}

export enum FILTERTYPE {
  EQ = 'EQ', // 等于
  LT = 'LT', // 等于
  GT = 'GT', // 等于
  BETWEEN = 'BETWEEN', // 等于
  SEARCH = 'SEARCH', // 等于
  PREFIX = 'PREFIX', // 等于
  IN = 'IN', // 等于
}

type QueryConditionItemType = {
  fieldId?: string;
  filterType: FILTERTYPE;
  value?: string;
  values?: string[];
};

type QueryAllFieldReqType = {
  appUuid: string;
  corpId: string;
  formCode: string;
  keyword?: string;
  order?: ORDER_TYPE[];
  pageNum?: number;
  pageSize?: number;
  queryConditionList?: QueryConditionItemType;
  showTitle?: boolean;
  sort?: string[];
  userId: string;
  userIdList?: string[];
};

type QueryFieldReqType = {
  appUuid: string;
  corpId: string;
  formCode: string;
  order?: ORDER_TYPE[];
  pageNum?: number;
  pageSize?: number;
  queryConditionList?: QueryConditionItemType;
  sort?: string[];
  userId: string;
};

export type exportParams = {
  exportType: ExportType;
  formCode: string;
  instanceIds?: string[];
  queryAllFieldReq?: QueryAllFieldReqType;
  queryFieldReq?: QueryFieldReqType;
  total?: number;
};
