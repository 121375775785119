/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\Sift_AssociationSelector\relation_template.js
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-22 13:52:56
 * :last editor: hyw
 * :date last edited: 2022-04-16 12:29:57
 */
import React, { useContext } from 'react';
import { customJP } from '../../utils';
import { getFileIcon } from '../EditAttachment/utils';

const genImgAry = (val) => {
  if (typeof val === 'string') {
    let isArrayString = false;
    let urlArr = [];
    try {
      urlArr = JSON.parse(val);
      isArrayString = true;
    } catch (error) {
      isArrayString = false;
    }
    return isArrayString ? urlArr : val.split(',');
  } else if (Array.isArray(val)) {
    return val;
  }
  return [];
};

const filterRelationInfo = (info, keys = []) => {
  console.log('* info *', info);
  console.log('* keys *', keys);
  return (Array.isArray(info) ? info : [])?.filter((v) =>
    (Array.isArray(keys) ? keys : []).some((t) => t === v.id),
  );
};

export const customCompDetail = (v, schema) => {
  let widget = schema?.properties?.[v.id]?.widget;
  let value = v.value;
  if (widget === 'picture') {
    return (
      <div>
        {genImgAry(value)?.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                display: 'inline-block',
                margin: '0 16px 6px 0',
                border: '1px solid #7E868E',
                borderRadius: '6px',
              }}
            >
              <img src={item} width="48" height="48" />
            </div>
          );
        })}
      </div>
    );
  } else if (widget === 'relation') {
    return JSON.stringify(value);
  } else if (widget === 'textNote') {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: schema?.properties?.[v.id]?.content,
        }}
      ></div>
    );
  } else if (widget === 'phone') {
    if (typeof value === 'object') {
      return value.value;
    }
    return value && value?.includes('{') ? customJP(value)?.value : value;
  } else if (widget === 'address') {
    const { address = [], street = '', detail = '' } = value || {};
    return address?.join('') + street + detail;
  } else if (widget === 'attachment') {
    return (value || []).map((item, idx) => {
      const fileIcon = getFileIcon(item.fileType);
      return (
        <div
          key={idx}
          style={{
            display: 'inline-block',
            width: '28px',
            marginRight: '6px',
            borderRadius: '4px',
          }}
        >
          <img style={{ width: '100%' }} src={fileIcon} />
        </div>
      );
    });
  } else if (
    widget === 'department' ||
    widget === 'innerContact' ||
    widget === 'multiSelect'
  ) {
    return value?.map((item) => item.value).join(',');
  } else if (widget === 'select') {
    return value?.value;
  } else if (typeof value === 'string' || typeof value === 'number') {
    return value;
  } else if (Array.isArray(value)) {
    if (['date', 'dateRange'].includes(widget)) {
      return value.join('~');
    } else {
      return value.join('，');
    }
  } else {
    return null;
  }
};

export const genRealtionDom = (payload = {}) => {
  const { value, schema, extendValue, relationDetail } = payload;
  console.log('<--payload-->', payload);
  if (!relationDetail || !extendValue?.formField) return;
  const matchAry = filterRelationInfo(relationDetail, extendValue?.formField);
  console.log('<--matchAry-->', matchAry);
  return (
    <div
      style={{
        padding: '12px 16px',
        background: 'rgba(23, 26, 29, 0.04)',
        borderRadius: '6px',
      }}
    >
      {matchAry.map((v) => (
        <div>
          <div
            style={{
              fontFamily: 'PingFangSC-Regular',
              fontSize: '12px',
              color: 'rgba(23, 26, 29, 0.6)',
              lineHeight: '20px',
              fontWeight: 400,
            }}
          >
            {schema?.properties?.[v.id]?.title}
          </div>
          <div
            style={{
              margin: '4px 0 8px',
              fontFamily: 'PingFangSC-Regular',
              fontSize: '14px',
              color: '#171a1d',
              lineHeight: '22px',
              fontWeight: 400,
            }}
          >
            {customCompDetail(v, schema)}
          </div>
        </div>
      ))}
    </div>
  );
};
