/*
 * :file description:
 * :name: \low-code-platform\src\pages\form\utils\parse_schema.js
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-19 17:31:02
 * :last editor: hyw
 * :date last edited: 2022-03-28 09:58:32
 */
import React, { useState } from 'react';
import { Form, Tag, Row, Col, Tooltip } from 'antd';
import moment from 'moment';
import { omit, compact, map, split, trim, keys, isArray, get } from 'lodash-es';
import TInput from '@/components/renderer/TInput/index.tsx';
import TPhone from '@/components/renderer/TPhone/index.tsx';
import TDatePicker from '@/components/renderer/TDatePicker/index.tsx';
import TSelect from '@/components/renderer/TSelect/index.tsx';
import TImage from '@/components/renderer/TUpload/index.tsx';
import TMoney from '@/components/renderer/TMoney/index.tsx';
import TNumberRange from '@/components/renderer/TNumberRange/index.tsx';
import customerTag from '@/components/renderer/customerTag/index.tsx';
import AssociationFormSelector from '@/components/renderer/AssociationFormSelector/index.tsx';
import PersonSelector from '@/components/renderer/PersonSelector/index.tsx';
import { getFileIcon } from '../../../../tg-renderer/packages/form-render/src/widgets/EditAttachment/utils';
import { RenderType } from '../constant.ts';
import utils, { transformDateToString } from './index';
import RelationSelector from '~@/packages/form-render/src/widgets/RelationSelector';

const realConfig = require('./real');
const mockData = require('./table');

// 搜索页面： 组件类型对应的筛选类型映射
const FILTER_TYPE_MAPPING = {
  input: 'SEARCH',
  textarea: 'SEARCH',
  phone: 'SEARCH',

  number: 'BETWEEN',
  money: 'BETWEEN',

  date: 'BETWEEN',
  // dateRange: 'BETWEEN', // 不支持搜索
  multiSelect: 'IN',
  personSelector: 'IN',

  select: 'EQ',
  relation: 'EQ',

  seqNumber: 'SEARCH',
  address: 'SEARCH',
  innerContact: 'IN',
  department: 'IN',
};

// 传参类型为数组还是字符串
const isRange = (prop, type, searchType) => {
  if (
    ['select', 'relation'].includes(prop) ||
    ['SEARCH', 'PREFIX'].includes(type) ||
    ['EQ', 'LT', 'GT'].includes(searchType)
  ) {
    return false;
  }
  return true;
};

// 当前版本(v1.0.0)已实现的组件类型
export const SUPPORTED_NOW = [
  'input',
  'textarea',
  'number',
  'date',
  'dateRange',
  'select',
  'multiSelect',
  'personSelector',
  'picture',
  'money',
  'phone',
  'relation',
  'table',
  'textNote',
  'department',
  'address',
  'seqNumber',
  'attachment',
  'innerContact',
  'multiTag',
];

// 人员选择器限制选择类型
const supportTypes = {
  innerContact: ['人员'],
  department: ['部门'],
};

// 筛选页面排除无法作为筛选项的组件类型
export const debarComponents = () => {
  const EXCLUSION_LIST = [
    // 'textarea',
    'dateRange',
    'picture',
    'table',
    'attachment',
    'textNote',
  ];
  return SUPPORTED_NOW.filter((v) => !EXCLUSION_LIST.includes(v));
};

// 新建表单实例组件映射关系
const CREATE_COMPONENT_MAPPING = {
  input: TInput,
  textarea: TInput,
  number: TInput,
  phone: TInput,
  date: TDatePicker,
  dateRange: TDatePicker,
  select: TSelect,
  multiSelect: TSelect,
  picture: TImage,
  money: TMoney,
  personSelector: PersonSelector,
  relation: AssociationFormSelector,

  // 待开发
  // switch: null,
  // checkbox: null,
  // checkboxes: null,
  // radio: null,
  // color: null,
  // slider: null,
  // list: null,
  // object: null,
};

// 搜索表单实例组件映射关系
const SEARCH_COMPONENT_MAPPING = {
  input: TInput,
  textarea: TInput,
  number: TNumberRange,
  phone: TPhone,
  date: TDatePicker,
  select: TSelect,
  multiSelect: TSelect,
  money: TNumberRange,
  personSelector: PersonSelector,
  relation: AssociationFormSelector,
  seqNumber: TInput,
  address: TInput,
  innerContact: PersonSelector,
  department: PersonSelector,
  multiTag: customerTag, // 客户标签
};

// 创建组件映射
// const ComponentsRegistry = {};
// const files = require.context('@/components/renderer', true, /\.tsx$/, 'sync');
// Promise.all(files.keys().map((fileName) => files(fileName))).then((modules) => {
//   modules.map((module) => {
//     ComponentsRegistry[module.default.name] = module.default;
//   });
//   Object.keys(CREATE_COMPONENT_MAPPING)
//     .filter((v) => v !== 'personSelector')
//     .forEach((key) => {
//       CREATE_COMPONENT_MAPPING[key] =
//         ComponentsRegistry[CREATE_COMPONENT_MAPPING[key]];
//     });
//   Object.keys(SEARCH_COMPONENT_MAPPING)
//     .filter((v) => v !== 'personSelector')
//     .forEach((key) => {
//       SEARCH_COMPONENT_MAPPING[key] =
//         ComponentsRegistry[SEARCH_COMPONENT_MAPPING[key]];
//     });
// });

// CREATE_COMPONENT_MAPPING['personSelector'] = MultipleChoice;
// SEARCH_COMPONENT_MAPPING['personSelector'] = MultipleChoice;
// 根据渲染类型获取默认配置
const getDefaultFormConfig = (rendertype) => {
  const isSearch = rendertype === RenderType.SEARCH;

  const formItemLayout = isSearch
    ? {
        // labelCol: { span: 3 },
        // wrapperCol: { span: 21 },
      }
    : null;

  // 表单默认配置
  const DEFAULT_FORM_CONFIG = {
    layout: isSearch ? 'horizontal' : 'vertical',
    colon: false,
    labelAlign: 'right',
    ...formItemLayout,
    autoComplete: 'off',
  };

  return DEFAULT_FORM_CONFIG;
};

const getDefaultFormItemConfig = () => {
  // 表单项默认配置
  const DEFAULT_FORM_ITEM_CONFIG = {
    extra: null,
    required: false,
    tooltip: null,
    hidden: false,
  };
  return DEFAULT_FORM_ITEM_CONFIG;
};

// 标准化不同类型组件输入值获取
const getFieldValue = (e) => {
  if (Array.isArray(e) || typeof e === 'string') {
    return e;
  } else if (typeof e === 'object' && e?.target) {
    return e.target.value;
  } else if (typeof e === 'object') {
    return e;
  }
  return e;
};

// 搜索参数生成
// 单选类型
const assembleParam = (widget, value) => {
  let finalValue = null;
  switch (widget) {
    case 'date':
      finalValue = utils.formatDate(value);
      break;
    case 'select':
      finalValue = value?.key;
      break;
    case 'personSelector':
      finalValue = isArray(value)
        ? get(value, '[0].userId') || get(value, '[0].id')
        : v.userId || v.id;
      break;
    case 'department':
      finalValue = isArray(value)
        ? get(value, '[0].userId') || get(value, '[0].id')
        : v.userId || v.id;
      break;
    case 'innerContact':
      finalValue = isArray(value)
        ? get(value, '[0].userId') || get(value, '[0].id')
        : v.userId || v.id;
      break;
    default:
      finalValue = value;
  }
  return finalValue;
};

// 多选类型
const assembleParams = (widget, value) => {
  let finalValue = null;
  switch (widget) {
    case 'date':
      finalValue = [utils.formatDate(value[0]), utils.formatDate(value[1])];
      break;
    case 'multiSelect':
      finalValue = value?.map((v) => v.key);
      break;
    case 'personSelector':
      finalValue = isArray(value)
        ? value.map((v) => v.userId || v.id)
        : v.userId || v.id;
      break;
    case 'department':
      finalValue = isArray(value)
        ? value.map((v) => v.userId || v.id)
        : v.userId || v.id;
      break;
    case 'innerContact':
      finalValue = isArray(value)
        ? value.map((v) => v.userId || v.id)
        : v.userId || v.id;
      break;
    default:
      finalValue = value;
  }
  return finalValue;
};

export const genSearchParams = (fieldId, widget, value, searchType) => {
  const isRangeData = isRange(widget, FILTER_TYPE_MAPPING[widget], searchType);
  return {
    fieldId,
    filterType: searchType, //TODO type确认 格式转换
    value: isRangeData ? undefined : assembleParam(widget, value),
    values: isRangeData ? assembleParams(widget, value) : undefined,
  };
};

export const renderComponents = (schema = realConfig, customConfig = {}) => {
  const [dynamicValue, setDynamicValue] = useState({});
  if (!schema) return;
  console.log(schema);
  const { rendertype, ref, cb = () => {} } = customConfig;

  const [form] = Form.useForm();

  schema.properties = {
    ...schema.properties,
    // multiTag_27c1d1f2: {
    //   displayGroupBy: "TagFieldGroup",
    //   hasPermEdit: 1,
    //   hasPermRead: 1,
    //   id: "multiTag_27c1d1f2",
    //   options: [{ warn: false, value: '高意向', key: '0b97069a91' },
    //   { warn: false, value: '中意向', key: '6df0b3ea92' },
    //   { warn: false, value: '低意向', key: '2b9c84bdd3' }],
    //   print: true,
    //   required: false,
    //   title: "客户意向",
    //   type: "array",
    //   widget: "multiTag",
    // },
  };

  console.log(debarComponents(SUPPORTED_NOW), 333331);
  console.log(SUPPORTED_NOW, 333332);
  // 组件
  const ComponentArray = Object.keys(schema.properties).filter((item) => {
    const compName = schema.properties[item].widget;
    return rendertype === RenderType.SEARCH
      ? debarComponents(SUPPORTED_NOW).includes(compName)
      : SUPPORTED_NOW.includes(compName);
  });

  // 客户标签
  // ComponentArray.push('customerTag');

  // 个性化配置信息
  const colSpan = rendertype === RenderType.SEARCH ? 8 : 24;

  return React.createElement(
    Form,
    {
      ref: ref,
      name: 'custom_form',
      form: form,
      className: 'dynamic_form',
      ...getDefaultFormConfig(rendertype),
      ...customConfig,
    },
    React.createElement(
      Row,
      {
        gutter: 24,
      },
      ComponentArray.map((item, idx) => {
        const matchMapping =
          rendertype === RenderType.SEARCH
            ? SEARCH_COMPONENT_MAPPING
            : CREATE_COMPONENT_MAPPING;

        if (!matchMapping[schema.properties[item]?.widget]) {
          return null;
        }

        return React.createElement(
          Col,
          {
            span: colSpan,
            key: idx,
          },
          React.createElement(
            Form.Item,
            {
              key: item,
              label: schema.properties[item].title,
              name: item,
              // rules: schema.properties[item].rules,
              ...getDefaultFormItemConfig(rendertype),
              rules: [
                {
                  required: schema.properties[item].required,
                  message: `${schema.properties[item].title}不能为空`,
                },
              ],
            },
            [
              React.createElement(
                (rendertype === RenderType.SEARCH
                  ? SEARCH_COMPONENT_MAPPING
                  : CREATE_COMPONENT_MAPPING)[schema.properties[item].widget],
                {
                  id: item,
                  rendertype,
                  tabs: supportTypes[schema.properties[item].widget],
                  enumnames: schema.properties[item].enumNames,
                  ...omit(schema.properties[item], 'enumNames'),
                  value: dynamicValue[item],
                  type: schema.properties[item].widget,
                  formCode:
                    schema.properties[item].dataSource?.target?.formCode,
                  onChange: (e) => {
                    form.setFieldsValue({
                      [item]: getFieldValue(e),
                    });
                    const formFiles = form.getFieldsValue(true);
                    // console.clear();
                    console.log('formFiles: ', formFiles);
                    const payload = compact(
                      Object.keys(formFiles).map((fieldId) => {
                        if (!formFiles[fieldId]) return;
                        const widget = schema.properties?.[fieldId]?.widget;
                        console.log(
                          fieldId,
                          typeof formFiles[fieldId],
                          moment.isMoment(formFiles[fieldId]),
                        );
                        return {
                          fieldId,
                          filterType: FILTER_TYPE_MAPPING[widget], //TODO type确认 格式转换
                          value: isRange(widget, FILTER_TYPE_MAPPING[widget])
                            ? undefined
                            : widget === 'date'
                            ? utils.formatDate(formFiles[fieldId])
                            : formFiles[fieldId],
                          values: isRange(widget, FILTER_TYPE_MAPPING[widget])
                            ? widget === 'date'
                              ? [
                                  utils.formatDate(formFiles[fieldId][0]),
                                  utils.formatDate(formFiles[fieldId][1]),
                                ]
                              : formFiles[fieldId]
                            : undefined,
                        };
                      }),
                    );
                    cb && cb({ queryConditionList: payload, type: 'filter' });
                    // test
                    //   const t = {
                    //     input_Z5jHTt: 'abc',
                    //     date_H5pMOY: '2021-10-12',
                    //     select_dbkxJ3: 'b',
                    //     multiSelect_aWpMSO: ['B', 'C', 'D'],
                    //     'select_EgqaM-': 'a',
                    //     'dateRange_-zOY5e': [
                    //       '2021-10-27',
                    //       '2021-10-28',
                    //     ],
                    // };
                    //   form.setFieldsValue(t);
                    //   setDynamicValue(t);
                  },
                },
              ),
            ],
          ),
        );
      }),
    ),
  );
};

// 支持排序的组件
const sortedSupportWidgets = {
  date: {
    sortable: true,
    sorter: () => {},
  },
  number: {
    sortable: true,
    sorter: () => {},
  },
  money: {
    sortable: true,
    sorter: () => {},
  },
  input: {
    sortable: true,
    sorter: () => {},
  },
  seqNumber: {
    sortable: true,
    sorter: () => {},
  },
};

export const genTableColumns = (response = mockData.data) => {
  if (!response) return;
  console.log('response: ', response);
  const cols = response.formHeaderFieldList.filter((col) => {
    // table中不展示说明文字和流水号
    return !['textNote'].includes(col.widget);
  });
  console.log('cols: ', cols);
  const ComponentArray = cols.map((item) => {
    const { fieldId, order, title, widget } = item;
    return {
      key: fieldId,
      title,
      dataIndex: fieldId,
      showSorterTooltip: false,
      sorter:
        sortedSupportWidgets[widget]?.sortable ||
        fieldId == 'gmtCreate' ||
        fieldId == 'gmtModified',
      // fixed: !!staticField,
      order,
      width: 162,
      ellipsis: true,
      render: (text, record, index) =>
        customRender(widget, text, record, index),
      children:
        widget === 'table'
          ? item.childFormHeaderFields.map((s) => {
              const { fieldId, title, widget } = s;
              const realWidget = ['relation', 'picture', 'attachment'].includes(
                widget,
              )
                ? widget
                : 'nodeOfTable';
              return {
                key: fieldId,
                width: 162,
                ellipsis: true,
                title,
                dataIndex: fieldId,
                widget: ['relation', 'picture', 'attachment'].includes(widget)
                  ? widget
                  : 'nodeOfTable',
                render: (text, record, index) =>
                  customRender(realWidget, text, record, index, true),
              };
            })
          : undefined,
    };
  });
  console.log('table列 ', ComponentArray);
  return ComponentArray;
};

// 自定义 table-cell 展示
// TODO
const customRender = (type, text, record, index, childOfTable) => {
  let colRender = null;
  switch (type) {
    // case 'dateRange':
    //   if (!text) return null;
    //   const [start, end] = text.split('~') ?? []
    //   const format = 'dateTime';
    //   return `${transformDateToString(start, format)} ~ ${transformDateToString(end, format)}`;
    case 'relation':
      colRender = Array.isArray(text) ? (
        text.map((c, idx) =>
          c ? (
            <div style={{ marginBottom: '6px' }}>
              <Tag
                key={`${c}_${idx}`}
                style={{
                  padding: '4px 8px',
                }}
              >
                {customJP(c)?.title}
              </Tag>
            </div>
          ) : (
            <div
              key={`${c}_${idx}`}
              style={{ height: '22px', marginBottom: '6px' }}
            >
              --
            </div>
          ),
        )
      ) : text ? (
        <Tag
          style={{
            padding: '4px 8px',
          }}
        >
          {customJP(text)?.title}
        </Tag>
      ) : (
        <div style={{ height: '22px' }}>--</div>
      );
      break;
    case 'phone':
      colRender = (
        <span>
          {typeof text === 'string' && text.includes('{')
            ? customJP(text)?.value
            : text}
        </span>
      );
      break;
    case 'picture':
      if (!text) return null;
      if (typeof text === 'string') {
        text = [text];
      }
      colRender = (
        <>
          {text?.length
            ? (map(text, trim) ?? [])?.map((img, idx) => {
                let imgs = img.split(',');
                return (
                  <>
                    {imgs.map((t, i) =>
                      t ? (
                        <img
                          key={`${t}_${i}`}
                          width="30"
                          height="30"
                          style={{
                            borderRadius: '4px',
                            marginRight: '5px',
                            cursor: 'pointer',
                          }}
                          src={t}
                        />
                      ) : (
                        <div style={{ height: '22px', marginBottom: '6px' }}>
                          --
                        </div>
                      ),
                    )}
                    {childOfTable ? (
                      <div style={{ height: '4px' }}></div>
                    ) : null}
                  </>
                );
              })
            : null}
        </>
      );
      break;
    case 'attachment':
      colRender = (
        <>
          {compact(text)?.length
            ? text?.map((img, idx) => {
                img =
                  typeof img === 'string' && img.includes('[{')
                    ? JSON.parse(img)
                    : img;
                if (!img)
                  return (
                    <div style={{ height: '22px', marginBottom: '6px' }}>
                      --
                    </div>
                  );
                return (
                  <>
                    {Array.isArray(img) ? (
                      img?.map((pic, idx) => (
                        <img
                          key={`${pic}_${idx}`}
                          width="28"
                          height="32"
                          style={{
                            borderRadius: '4px',
                            marginRight: '5px',
                            cursor: 'pointer',
                          }}
                          src={getFileIcon(pic?.fileType)}
                        />
                      ))
                    ) : (
                      <img
                        key={`${img}_${idx}`}
                        width="28"
                        height="32"
                        style={{
                          borderRadius: '4px',
                          marginRight: '5px',
                          cursor: 'pointer',
                        }}
                        src={getFileIcon(img?.fileType)}
                      />
                    )}
                    {childOfTable ? (
                      <div style={{ height: '4px' }}></div>
                    ) : null}
                  </>
                );
              })
            : null}
        </>
      );
      break;
    case 'nodeOfTable':
      colRender = (
        <>
          {text && Array.isArray(text)
            ? text.map((c, idx) =>
                c ? (
                  <div
                    key={`${c}_${idx}`}
                    style={{ height: '22px', marginBottom: '6px' }}
                  >
                    {c}
                  </div>
                ) : (
                  <div
                    key={`${c}_${idx}`}
                    style={{ height: '22px', marginBottom: '6px' }}
                  >
                    --
                  </div>
                ),
              )
            : null}
        </>
      );
      break;
    default:
      colRender = text ? (
        <Tooltip
          placement="top"
          color="white"
          overlayInnerStyle={{ color: '#000' }}
          title={text}
        >
          <div
            style={{
              width: '162px',
              maxWidth: '162px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {text}
          </div>
        </Tooltip>
      ) : null;
  }
  return colRender;
};

// 日期格式化
const dateFormat = (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(date).format(format);
};

// 处理 子table中 日期范围 时间格式问题
const dateRangeFormat = (source) => {
  return source.map((d) => {
    if (!d) return undefined;
    let dateAry = d.split('~');
    if (!dateAry?.length) return undefined;
    let [s, e] = dateAry;
    return [`${dateFormat(s)} ~ ${dateFormat(e)}`];
  });
};

export const genTableData = (response = mockData.data) => {
  if (!response) return;
  console.log('response: ', response);
  let listData = response.instanceDataList.map((item, idx) => {
    let row = {
      key: item.instanceId,
      instanceId: item.instanceId,
      // staff_id: creator,
      // gmt_create: dateFormat(createTime),
      // gmt_modified: dateFormat(modifyTime),
    };
    item.itemList?.forEach((v) => {
      switch (v.widget) {
        // case 'picture':
        //   row[v.fieldId] = v.value ? map(split(v.value, ','), trim) : [];
        //   break;
        case 'attachment':
          row[v.fieldId] = v.value ? customJP(v.value) : [];
          // row[v.fieldId] = v.value
          //   ? map(split(v.value, '~'), (item) => {
          //       const matchResult = item.match(
          //         /instance(.*)((?!\.{1})(.*))\?Expires/,
          //       );
          //       return {
          //         type: matchResult?.[1]?.split('.')?.[1],
          //         uri: item,
          //       };
          //     })
          //   : [];
          break;
        case 'table':
          const source = customJP(v.value) ?? [];
          if (source.length) {
            const mapping = source[0].reduce((acc, cur) => {
              acc[cur.key] = [cur.value];
              return acc;
            }, {});
            source.slice(1)?.forEach((v) => {
              v?.forEach((t) => {
                mapping[t.key]?.push(t.value);
              });
            });
            console.log('@@1： ', item);
            console.log('@@2： ', mapping);

            row[v.fieldId] = {
              type: 'innerTable',
              value: keys(mapping).map((k) => {
                let val = mapping[k];
                if (
                  k.includes('dateRange_') &&
                  Array.isArray(mapping[k]) &&
                  mapping[k].length
                ) {
                  val = dateRangeFormat(val);
                }
                return {
                  fieldId: k,
                  value: val,
                };
              }),
            };
          }
          break;
        default:
          row[v.fieldId] = v.value;
          break;
      }
    });
    // dataArray.forEach((v) => {
    //   const { key, value, extendValue, componentType } = v;
    //   row[key] = formatColValue(componentType, value);
    //   row.extendValue = extendValue;
    //   row.componentType = componentType;
    // });
    return row;
  });
  listData = listData.map((t) => {
    keys(t)?.forEach((kk) => {
      if (t[kk]?.type === 'innerTable') {
        t[kk]?.value?.forEach((u) => {
          t[u.fieldId] = u.value;
        });
        delete t[kk];
      }
    });
    return t;
  });
  console.log('listData~~', listData);
  return listData;
};

export const customJP = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    console.log(e);
    return data;
  }
};

export const customJS = (data) => {
  try {
    return JSON.stringify(data);
  } catch (e) {
    console.log(e);
    return data;
  }
};
