/*
 * :file description:
 * :name: \ui-renderd:\progree\low-code-platform\tg-renderer\packages\form-render\src\widgets\DataFilters\components\RelationDataFilters\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-22 17:44:03
 * :last editor: hyw
 * :date last edited: 2022-07-05 14:30:29
 */
import { FC } from 'react';
import { WidgetProps } from 'typings';
import IconFont from '@/components/IconFont';
// import FilterModal from './FilterModal';
import c from '../../index.less';
import FilterModal from '../../FilterModal';

export const customerTitleMap = new Map([
  ['crm_customer', '企业客户'],
  ['crm_customer_personal', '个人客户'],
]);

const RelationDataFilters: FC<WidgetProps> = (props) => {
  const { addons } = props;
  const { getValues, setValueByPath } = addons;
  const formData = getValues();

  // 显示有权限的数据过滤条件
  const relateSource = formData.relateSource.filter(
    (val) => val?.dataSource?.target?.status === 'PUBLISHED',
  );

  const handleSchema = (values, index: number) => {
    const filtersPath = `relateSource[${index}].dataSource.params.filters`;
    setValueByPath(filtersPath, values);
  };

  const handleShowModal = (visible: boolean, index: number) => {
    const visiblePath = `relateSource[${index}].visible`;
    setValueByPath(visiblePath, visible);
  };

  return (
    <div style={{ width: '100%' }}>
      {relateSource.map((child, index) => {
        return (
          <div className={c.container}>
            <div>{customerTitleMap.get(child?.bizType)}</div>
            <div
              className={c.field}
              onClick={() => {
                handleShowModal(true, index);
              }}
            >
              <div className={c['field-text']}>
                {child?.dataSource?.params?.filters?.length
                  ? '已添加过滤条件'
                  : '请添加过滤条件'}
              </div>
              <IconFont className={c['field-icon']} type="icon-bianji1" />
            </div>
            <FilterModal
              visible={child?.visible}
              onCancel={() => {
                handleShowModal(false, index);
              }}
              initValues={child?.dataSource?.params?.filters}
              bizType={child?.bizType}
              relateSource={relateSource}
              onChange={(values) => {
                handleShowModal(false, index);
                handleSchema(values, index);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default RelationDataFilters;
