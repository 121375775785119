/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DataSnippet\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-16 10:48:52
 * :last editor: hyw
 * :date last edited: 2022-01-19 11:04:41
 */
import React from 'react';
import { Popover } from 'antd';
import IconFont from '@/components/IconFont';
import c from './index.less';
import { useState, useMemo } from 'react';
import dataCardImg from '@/assets/dataCardTip.png';
import DataCardSetting from './DataCardSetting';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import { useModel } from 'umi';
import { PsiBiztype, CUSTOMER_BIZ_TYPES } from './contants';
import { Select } from 'antd';
import { PROCESS_FORM_SYSTEM_FIELDS } from './contants';
import { CloseOutlined } from '@ant-design/icons';
import deepClone from 'clone';

const willHideContractFields = [
  'money_KfPQCxtDqDpD', // 已回款金额 合同表单
  'money_UwSNkmkTaVqX', // 未回款金额 合同表单
  'money_Iq0R96ejO6Hf', // 已回款金额 订单表单
  'money_jSBwe3RZ4npN', // 未回款金额 订单表单
];
const willHideOrderFields = [
  'money_ZZSj2QHjXRVD', // 已开票金额 合同表单
  'money_8RgYknu4B4cd', // 未开票金额 合同表单
  'money_CAKuF0zkrF4g', // 已开票金额 订单表单
  'money_0ssDIAe2ZlWd', // 未开票金额 订单表单
];

const { Option } = Select;

enum formType {
  // 0:流程表单
  PROCESS_FORM,
  // 1:非流程表单
  NON_PROCESS_FORM,
}

export enum SettingType {
  // 0 系统默认
  DEFAULT,
  // 1 自定义
  CUSTOM,
}

const DataCard = (props) => {
  const { onChange, value, addons = {} } = props;
  const { setValueByPath, getValues } = addons;
  const { settingType = 0 } = value || {};
  const [visible, setVisible] = useState(false);
  const model = useModel('useGenerator', (model) => model);
  const { selected } = useStore();
  const { schemaRef, profile } = model;
  const properties = schemaRef?.current?.properties || {};

  // 使用范围扩充到table下面数字金额控件
  const allPropertiesMap = useMemo(() => {
    const map = new Map();
    Object.keys(properties)?.forEach((key) => {
      const item = properties?.[key];
      if (!(!item.hidden || (item.hidden && item.hideControls))) return;
      if (['money', 'number'].includes(item.widget)) {
        map.set(key, item);
      }
    });
    return map;
  }, [properties]);

  const isShowProcessDesign =
    !profile.bizType ||
    PsiBiztype.includes(profile.bizType) ||
    profile.formType === formType.PROCESS_FORM;

  let allProperties = {
    ...properties,
    gmtCreate: {
      id: 'gmtCreate',
      value: 'gmtCreate',
      title: '创建时间',
    },
    createUserName: {
      id: 'createUserName',
      value: 'createUserName',
      title: '创建人',
    },
  };

  if (isShowProcessDesign) {
    const value = PROCESS_FORM_SYSTEM_FIELDS?.reduce((cur, pre) => {
      cur[pre?.id] = {
        ...pre,
        title: pre?.label,
      };
      return cur;
    }, {});
    allProperties = {
      ...allProperties,
      ...value,
    };
  }
  if (profile.bizType && CUSTOMER_BIZ_TYPES.includes(profile.bizType)) {
    allProperties = {
      ...allProperties,
      allTags: {
        id: 'allTags',
        value: 'allTags',
        title: '客户标签',
      },
    };
  }

  const content = () => {
    return (
      <div className={c.popover}>
        <div className={c['popover-desc']}>数据卡片将在详情页优先展示</div>
        <img src={dataCardImg} alt="" />
      </div>
    );
  };

  const deleteItem = (e, index) => {
    e.stopPropagation();
    const copyValue = deepClone(value);
    if (copyValue && copyValue?.propsValue) {
      copyValue.propsValue.fields?.splice(index, 1);
    }
    setValueByPath('dataCard', copyValue);
  };

  const onOk = (values) => {
    const tempValue = (values || []).map((item) => {
      return {
        id: item.id,
        title: item.label,
      };
    });
    onChange({
      propsKey: 'dataCard',
      propsValue: {
        fields: tempValue,
      },
      settingType: 1,
    });
    setVisible(false);
    setValueByPath('dataCard', {
      propsKey: 'dataCard',
      propsValue: {
        fields: tempValue,
      },
      settingType: 1,
    });
  };

  return (
    <div className={c.container}>
      <div className={c.title}>
        <span className={c.title2}>数据卡片</span>
        <Popover placement="bottom" content={content} trigger="hover">
          <IconFont type="icon-shuoming" className={c['info-icon']}></IconFont>
        </Popover>
      </div>

      <div
        className={c.wrap}
        onClick={() => {
          setVisible(true);
        }}
      >
        {!value?.propsValue?.fields?.length && (
          <div className={c.text}>请选择</div>
        )}
        {!!value?.propsValue?.fields?.length &&
          value.propsValue?.fields?.map((item, index) => {
            if (!allPropertiesMap.has(item.id)) {
              return null;
            }
            return (
              <div className={c.item} key={item.id}>
                {allPropertiesMap.get(item.id).title}
                <span onClick={(e) => deleteItem(e, index)}>
                  <CloseOutlined />
                </span>
              </div>
            );
          })}
      </div>

      <DataCardSetting
        visible={visible}
        initValue={value}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={onOk}
      />
    </div>
  );
};

export default DataCard;
