/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\EditAddress\Street\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-31 14:07:27
 * :last editor: hyw
 * :date last edited: 2022-04-19 16:57:53
 */
import { useEffect, useState } from 'react';
import { Select, Input, Row, Col } from 'antd';
import formApi from '@/services/form';
import Area from '../Area';
import Style from './index.less';

export default (props) => {
  const { onChange, value } = props;
  console.log('🚀 ~ file: index.tsx ~ line 18 ~ value', value);
  const [areas, setAreas] = useState([]);
  const [streets, setStreets] = useState([]);
  const [areaCode, setAreaCode] = useState();
  const areaName = value?.address?.[2] || '';

  useEffect(() => {
    initData();
  }, [JSON.stringify(areaCode)]);

  const initData = async () => {
    if (areaCode) {
      const resp = await formApi.getStreetList(areaCode);
      const streetList = resp?.data || [];
      const options = streetList.map((item) => {
        return {
          label: item.streetName,
          value: item.streetName,
        };
      });

      setStreets(options);
    } else {
      setStreets([]);
    }
  };

  const onAreaValueChange = (val, selectedOptions) => {
    if (selectedOptions && val) {
      const address = selectedOptions.map((item) => item.label);
      const areaOptions = selectedOptions[selectedOptions.length - 1];
      setAreaCode(areaOptions?.value);
      if (val.length > 0) {
        onChange({
          address,
          detail: value?.detail,
        });
      } else {
        onChange({
          detail: value?.detail,
        });
      }
    } else {
      onChange({
        // address,
        detail: value?.detail,
      });
    }
  };

  const onStreetChange = (street) => {
    onChange({
      ...value,
      street,
    });
  };

  const onDetailChange = (e) => {
    const text = e.target.value;
    onChange({
      ...value,
      detail: text,
    });
  };

  return (
    <div>
      <div className={Style.flex}>
        <div className={Style.area}>
          <Area onChange={onAreaValueChange} value={value} />
        </div>
        <div className={Style.street}>
          <Select
            options={streets}
            onChange={onStreetChange}
            value={value?.street}
            placeholder="请选择街道"
            allowClear
          />
        </div>
      </div>
      <Input.TextArea
        placeholder="详细地址"
        style={{ resize: 'none' }}
        value={value?.detail ?? ''}
        onChange={onDetailChange}
      />
    </div>
  );
};
