import { Input, InputProps } from 'antd';
import c from './index.less';

const TPhone = (props: InputProps & { [key: string]: any }) => {

  return (
    <div className={c.t_phone}>
      <Input placeholder="请输入" allowClear {...props} />
    </div>
  );
};

export default TPhone;
