/*
 * :file description:
 * :name: \ui-renderd:\progree\low-code-platform\tg-renderer\packages\form-render\src\widgets\LimitedNumRange\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 14:28:41
 * :last editor: hyw
 * :date last edited: 2022-08-22 15:13:23
 */
import { FC, useMemo } from 'react';
import { Radio, InputNumber } from 'antd';
import { WidgetProps } from 'typings';
import styles from './index.less';

type Value = string | number | undefined;

const LimitedNumRange: FC<WidgetProps<Value>> = (props) => {
  const { getValues, setValues } = props.addons;
  const formData = getValues();
  const { value, onChange } = props;
  const { limitedNumberRange } = formData;

  // 切换数值范围
  const handleChange = (e: boolean) => {
    onChange(e);
  };

  // 改变输入框的值
  const handleInputChange = (val: number, type: string) => {
    setValues({
      ...formData,
      limitedNumberRange: {
        ...limitedNumberRange,
        [type]: val,
      },
    });
  };

  const mapType = (value: any, type: string) => {
    return typeof value == type;
  };

  const errType = useMemo(() => {
    const min = limitedNumberRange?.min;
    const max = limitedNumberRange?.max;

    let type = 0;
    if (
      (mapType(min, 'object') && mapType(max, 'object')) ||
      Object.keys(limitedNumberRange || {}).length == 0
    ) {
      type = 1;
    } else if (mapType(min, 'number') && mapType(max, 'number')) {
      if (min > max) {
        type = 2;
      }
    }
    return type;
  }, [limitedNumberRange]);

  return (
    <div className={styles.limitedNumRange}>
      <Radio.Group
        onChange={handleChange}
        className={styles.radioTop}
        value={value}
      >
        <Radio value={true}>是</Radio>
        <Radio value={false}>否</Radio>
      </Radio.Group>
      {value ? (
        <div
          className={`${styles.numberRange} ${errType != 0 ? styles.err : ''}`}
        >
          <InputNumber
            placeholder="请输入"
            onChange={(value) => handleInputChange(value, 'min')}
            value={limitedNumberRange?.min}
          />
          <span className={styles.division}>~</span>
          <InputNumber
            placeholder="请输入"
            onChange={(value) => handleInputChange(value, 'max')}
            value={limitedNumberRange?.max}
          />
        </div>
      ) : null}
      {value && errType != 0 ? (
        <p className={styles.errTip}>
          {errType == 1
            ? '数值范围最少需输入一个值'
            : '数值范围最大值不能小于最小值'}
        </p>
      ) : null}
    </div>
  );
};

export default LimitedNumRange;
