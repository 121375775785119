/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\form-render-core\src\FormDetail\RelationFormList\index.tsx
 * author: gaoch
 * copyright: (c) 2022, Tungee
 * :date created: 2022-01-14 14:15:54
 * :last editor: gaoch
 * :date last edited: 2022-02-15 16:01:26
 */
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { nanoid } from 'nanoid';
import IconFont from '../../../../components/IconFont';
import api from '../../../../../../../../src/services/form/index';
import Style from './index.less';
import moment from 'moment';
import EmptyImg from '../../../../assets/form/empty.png';
import { customJP } from '../../../../utils';
import { getWidgetValue } from './utils';
import { eventBus, EventTypes } from '@/global';

export default (props) => {
  const {
    appUuid = '123',
    corpId = 'ding60757707ecc7bf9df2c783f7214b6d69',
    // formCode = 'PROC-809271C4-7537-4DFF-B456-CF6FE1CBE4AF',
    // instanceId = 'd818192f-44d9-4ea4-8d06-998aa4fa83f4',
    // relateFormCode = 'PROC-C5085990-7C61-433A-8C2C-6C6084B3F5EF',
    fields = [
      {
        title: '姓名',
        type: 'string',
        widget: 'input',
        id: 'Date_KSgdVRzr_IeE',
        placeholder: '请输入',
      },
    ],
  } = props;
  const {
    formCode,
    instanceId,
    relateFormCode,
    title,
    renderType = 'drawer',
    close,
    showModal = () => {},
    instanceTitle,
  } = props;
  const [fieldList, setFieldList] = useState([]);
  const [list, setList] = useState([]);

  useEffect(() => {
    initData();
  }, []);

  const toCreate = () => {
    if (renderType === 'drawer') {
      // 打开新建drawer
      const payload = {
        relateFormCode,
        instanceId,
        title: instanceTitle,
        formCode,
      };
      eventBus.EE.emit(EventTypes.Create, payload);
      close();
    } else {
      // 打开新建页面 钉钉侧使用
      window.location.href = '';
    }
  };

  const emptyTip = () => {
    return (
      <div className={Style.empty}>
        <div>
          <img width="320" height="320" src={EmptyImg} />
          <span>暂无{title}</span>
          <Button type="primary" onClick={() => toCreate()}>
            <IconFont type="icon-tianjia1" /> 新建{title}
          </Button>
        </div>
      </div>
    );
  };

  const checkDetail = (data) => {
    showModal(data?.instanceId, relateFormCode, nanoid(12));
  };

  const initData = async () => {
    const formResp = await api.getFormSchemas({
      formCode: relateFormCode,
    });

    const propsSettingItem = (formResp?.data?.propsSettings || []).find(
      (item) => {
        return item.propsKey == 'dataSnippet';
      },
    );
    const fieldIds = (propsSettingItem?.propsValue || []).map(
      (item) => item.id,
    );
    const formContent = customJP(formResp?.data?.content);
    const properties = formContent.properties || {};
    const fieldList = Object.values(properties).filter((item) =>
      fieldIds.includes(item.id),
    );

    setFieldList(fieldList);

    const resp = await api.getRelatedDataList(
      {
        // appUuid,
        // corpId,
        formCode,
        instanceId,
        relateFormCode,
      },
      {
        headers: {
          formCode,
        },
      },
    );

    let result = (resp?.data || []).map((item) => {
      return {
        ...item,
        content: customJP(item.content),
      };
    });
    setList(result);
  };
  return (
    <div>
      <div className={Style.list}>
        {list.length ? (
          <>
            <Button type="primary" onClick={() => toCreate()}>
              <IconFont type="icon-tianjia1" /> 新建{title}
            </Button>
            {list.map((item) => {
              const { gmtCreate, createUserName = '', content, title } = item;
              const titleText = `${createUserName}提交的${title}`;
              let contentValueItem;
              const fieldItem = fieldList.find((field) => {
                const valueItem = content?.[field.id];

                if (valueItem) {
                  contentValueItem = valueItem;
                  return true;
                }
                return false;
              });

              let contentText = '';
              if (fieldItem && contentValueItem) {
                const widget = fieldItem?.widget;
                const contentTextValue = getWidgetValue(
                  widget,
                  contentValueItem,
                );
                contentText = `${fieldItem.title}：${contentTextValue}`;
              }
              return (
                <div
                  key={item.instanceId}
                  className={Style['list-item']}
                  onClick={() => checkDetail(item)}
                >
                  <div className={Style['list-item-header']}>
                    <div className={Style.title}>{titleText}</div>
                    <div className={Style.time}>
                      {moment(gmtCreate).format('YYYY-MM-DD HH:mm')}
                    </div>
                  </div>
                  <div className={Style['list-item-content']}>
                    {contentText}
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          emptyTip()
        )}
      </div>
    </div>
  );
};
