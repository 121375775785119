import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { HolderOutlined } from '@ant-design/icons';
import Style from './index.less';

//初始化数据
const getItems = (props) => props.items;

// 重新记录数组顺序
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);
  return result;
};

// 设置样式
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0 0 4px 0',
  height: 32,
  lineHeight: '32px',
  textAlign: 'left',
  textIndent: 8,
  background: isDragging ? '#fafafa' : '#ffffff',
  ...draggableStyle,
});

const getListStyle = () => ({
  background: '#fff',
  width: 250,
});

export default class SortOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: getItems(props),
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.items.length !== prevProps.items.length ||
      JSON.stringify(this.props.items) !== JSON.stringify(prevProps.items)
    ) {
      this.setState({
        items: this.props.items || [],
      });
    }
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      items,
    });

    this.props.updateItems(items);
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot)}
            >
              {this.state.items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <div className={Style['option-item']}>
                        <HolderOutlined
                          className={Style['option-icon']}
                          style={{ fontSize: 12 }}
                        />
                        <span
                          className={Style['option-text']}
                          style={{ marginLeft: '10px' }}
                        >
                          {item.label}
                        </span>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
