export const DATE_LIST = [
  {
    label: '年月日',
    value: 'YYYYMMDD',
  },
  {
    label: '年月',
    value: 'YYYYMM',
  },
  {
    label: '年',
    value: 'YYYY',
  },
];

export const getDateLabelByValue = (value: string) => {
  return DATE_LIST.find((item) => item.value === value)?.label ?? '';
};

export const COUNTER_RESET_LIST = [
  {
    label: '不重置',
    value: '',
  },
  {
    label: '按年重置',
    value: 'year',
  },
  {
    label: '按月重置',
    value: 'month',
  },
  {
    label: '按日重置',
    value: 'day',
  },
];

export const getCounterLabelByValue = (value: string) => {
  return COUNTER_RESET_LIST.find((item) => item.value === value)?.label ?? '';
};
