/*
 * :file description: page org
 * :name: \low-code-platform\src\pages\back\org\index.tsx
 * :author: ershing
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-11 16:15:47
 * :last editor: hyw
 * :date last edited: 2022-02-21 17:29:49
 */

import { Tree, Row, Col, Affix, Modal } from 'antd';
import { useEffect, useState } from 'react';
import OrgTable from '@/pages/back/org/components/OrgTable';
import { getDepartmentUser, getTreeCompany } from '@/services/org';
import c from './index.less';
import { history } from '@@/core/history';

function Org() {
  const [orgData, setOrgData] = useState([]);
  const [orgTableData, setOrgTableData] = useState([]);
  const [departmentId, setDepartmentId] = useState();

  function readNodes(nodes = []) {
    for (let item of nodes) {
      item.title = item?.name;
      item.key = item?.id;
      if (item?.children && item?.children?.length) readNodes(item?.children);
    }
    return nodes;
  }

  useEffect(async () => {
    const authority = window.localStorage.getItem('authority');
    if (authority === 'true') {
    } else {
      history.push('/');
      return;
    }
    const res = await getTreeCompany();
    const arr = [];
    arr.push(res.data);
    if (arr.length !== 0) {
      readNodes(arr);
      setOrgData(arr);
    }
  }, []);

  const onselect = async (value: Array<any>, e: object) => {
    setDepartmentId(e?.node?.id);
    const res = await getDepartmentUser({
      departmentId: e?.node?.id,
      pageNum: 1,
      pageSize: 10,
    });
    setOrgTableData(res.data);
  };

  const changeTableData = async (current: number, pageSize: number) => {
    const res = await getDepartmentUser({
      departmentId: departmentId,
      pageNum: current || 1,
      pageSize: pageSize || 10,
    });
    setOrgTableData(res.data);
  };

  return (
    <div className={c.org_main}>
      <div className={c.orgTitle}>
        <div className={c.orgTitle_text}>组织架构</div>
      </div>
      <div className={c.orgContain}>
        <Row>
          <Col span={8}>
            <div className={c.org_tree_contain}>
              <div className={c.org_tree_title}>探迹科技组织架构</div>
              <Tree
                style={{
                  marginTop: '20px',
                  marginLeft: '15px',
                  marginRight: '20px',
                }}
                onSelect={onselect}
                treeData={orgData}
              />
            </div>
          </Col>
          <Col span={16}>
            <OrgTable
              orgTableData={orgTableData}
              changeTableData={changeTableData}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

Org.wrappers = ['@/wrappers/pageAuth'];

export default Org;
