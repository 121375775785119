/*
 * :file description:
 * :name: \low-code-platform\src\pages\generator\form\services\user\index.ts
 * :author: 宋海兵
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-26 15:30:19
 * :last editor: hyw
 * :date last edited: 2022-02-22 17:42:21
 */
import { getRequest } from '@/common/http';

// 菜单栏
export async function getMenu() {
  return getRequest(`/api/menu/menus-perms`);
}
