/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\IPhone\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 14:28:41
 * :last editor: 黄艺
 * :date last edited: 2021-12-29 15:39:20
 */
import { List, Typography, Input, Select, Button, Row, Col } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { FC, SetStateAction, useEffect, useState } from 'react';
import c from './index.less';
import { getFormSchema } from '@/services/form-generator';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import countryCodes from '../Phone/countryCodes';
import { WidgetProps } from 'typings';

const { Option } = Select;

const defaultExtendValue = countryCodes.find(
  (item) => item.countryCode === '+86',
);

const PhoneWidget: FC<WidgetProps> = (props) => {
  const { formData, setValues, setValueByPath, isCssDoubleLine } = props;
  const {
    default: defaultValue = {
      mode: 'phone',
      countryCode: '+86',
    },
  } = formData ?? {};
  const [type, setType] = useState();
  const { mode, placeholder } = props?.schema;
  const { value, onChange } = props;
  const [number, setNumber] = useState();
  const [areaNumber, setAreaNumber] = useState();
  const [extendValue, setExtendValue] = useState(defaultExtendValue);
  const handleSelect = (countryCode: string) => {
    const extendValue = countryCodes.find(
      (item) => item.countryCode === countryCode,
    );
    setExtendValue(extendValue);
    setValueByPath?.('default', {
      ...defaultValue,
      ...extendValue,
    });
  };

  useEffect(() => {
    setType(mode);
    if (setValueByPath) {
      setValueByPath('default', {
        ...defaultValue,
        mode: mode === 'phone_tel' ? 'phone' : mode,
      });
    }
  }, [mode, setValueByPath]);

  return (
    <div className={c.container}>
      <Input.Group compact>
        {mode === 'phone' && <Button className={c.mobile}>手机</Button>}
        {mode === 'tel' && <Button className={c.telephone}>固话</Button>}
        {mode === 'phone_tel' && (
          <Select
            onChange={(e) => {
              setType(e);
              setValueByPath?.('default', {
                ...defaultValue,
                mode: e,
              });
            }}
            className={c.phoneType}
            defaultValue={defaultValue?.mode ?? 'phone'}
            value={value?.mode === 'phone_tel' ? 'phone' : value?.mode}
          >
            <Option value="phone">手机</Option>
            <Option value="tel">固话</Option>
          </Select>
        )}
        <Select
          dropdownClassName={c.phoneDorpdown}
          onSelect={handleSelect}
          className={c.code}
          defaultValue={defaultValue?.countryCode ?? '+86'}
          value={value?.countryCode ?? '+86'}
        >
          {countryCodes.map((item) => (
            <Option key={item.countryKey} value={item.countryCode}>
              {item.countryNameZh}
              {item.countryCode}
            </Option>
          ))}
        </Select>
        {type === 'tel' && (
          <Input
            type="number"
            placeholder="区号"
            min={0}
            defaultValue={defaultValue?.areaNumber}
            value={value?.areaNumber}
            onChange={(e) => {
              setAreaNumber(e.target.value);
              setValueByPath('default', {
                ...defaultValue,
                areaNumber: e.target.value,
              });
            }}
            className={c.provinceCode}
          />
        )}
        {!isCssDoubleLine && (
          <Input
            type="number"
            min={0}
            className={c.input}
            placeholder={placeholder}
            defaultValue={defaultValue?.number}
            value={value?.number}
            onChange={(e) => {
              setValueByPath?.('default', {
                ...defaultValue,
                number: e.target.value,
              });
            }}
          />
        )}
      </Input.Group>
      {isCssDoubleLine && (
        <Input.Group compact>
          <Input
            type="number"
            min={0}
            className={c.input}
            style={{ width: '100%', flexShrink: 0, marginTop: '6px' }}
            placeholder={placeholder}
            defaultValue={defaultValue?.number}
            value={value?.number}
            onChange={(e) => {
              setValueByPath?.('default', {
                ...defaultValue,
                number: e.target.value,
              });
            }}
          />
        </Input.Group>
      )}
    </div>
  );
};

export default PhoneWidget;
