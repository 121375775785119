/*
 * :file description:
 * :name: \crmd:\progree\low-code-platform\src\components\MultipleChoice\DataOpration.tsx
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-15 14:47:46
 * :last editor: hyw
 * :date last edited: 2022-10-09 17:24:10
 */

import c from './index.less';
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useRef,
  useCallback,
} from 'react';
import { message, Tree, Spin, Tabs, Checkbox, Modal, Popover } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { departmentTree, roleTree, listAll, getUserByPage } from './api';
import InputSearch from './InputSearch';
const { TabPane } = Tabs;
import _cloneDeep from 'lodash/cloneDeep';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import _difference from 'lodash/difference';
import _differenceBy from 'lodash/differenceBy';
import cs from 'classnames';
import { defaultTabs, principalBizTypes, DynamicMemberList } from './constants';
import Principal from './Principal';
import Director from './Director';
import DirectorManager from './DirectorManager';
import { getUrlParam } from '@/utils';
import IconFont from '../IconFont';

const { TreeNode } = Tree;
interface MessageEngageDetailProps {
  cRef: any;
  initData?: any;
  getDataBack: Function;
  tabs?: Array<string>;
  multiple?: Boolean;
  memberTitleList?: Array<Object>;
  getSize: Function;
  isProcess?: boolean;
}

interface ITreeNode {
  active: boolean;
  checked: boolean;
  children: ITreeNode[];
  currentType: number;
  dragOver: boolean;
  dragOverGapBottom: boolean;
  dragOverGapTop: boolean;
  expanded: boolean;
  halfChecked: boolean;
  id: string;
  key: string;
  level: number;
  loaded: boolean;
  loading: boolean;
  name: string;
  order: number;
  parentId: string;
  pos: string;
  selected: boolean;
  title: string;
}

const content2 = (
  <div>
    <p>
      1.进入通讯录设置直属主管（一般指汇报线主管，不在上级部门中，但相关业务向该主管汇报）
    </p>
    <p>2.在OA审批中设置直属主管为审批人</p>
  </div>
);

const content1 = (
  <div>
    <p>1.进入通讯录设置部门主管</p>
    <p>2.在OA审批中设置部门主管为审批人</p>
  </div>
);

const DataOpration: React.FC<MessageEngageDetailProps> = ({
  initData,
  cRef,
  getDataBack,
  tabs,
  multiple = true,
  memberTitleList = [],
  getSize,
  isProcess = true,
}) => {
  // 部门树相关信息
  const memberData = useCallback(() => {
    let dynamicPersonArr = initData?.dynamicPerson ?? [];
    memberTitleList?.forEach((ele: any) => {
      let isTrue = dynamicPersonArr.some(
        (val: any) => ele.id === val.id && ele.type === val.type,
      );
      ele.name = ele.title;
      ele.checked = isTrue;
      ele.currentType = 4;
      // 改变之前的type，跟后台对接数据时所需
      ele.type = 'member_field';
    });
    return memberTitleList;
  }, [memberTitleList]);

  const initSponsorList = useCallback(() => {
    let dynamicPersonArr = initData?.dynamicPerson ?? [];
    let isTrue = false;
    dynamicPersonArr?.forEach((ele: any) => {
      if (ele.id == 'startUserId') {
        isTrue = true;
      }
    });

    return [
      {
        name: isProcess ? '流程发起人' : '创建人',
        id: 'startUserId',
        checked: isTrue,
        currentType: 4,
        type: 'initiator',
      },
    ];
  }, [initData]);
  const initPrincipalList = useCallback(() => {
    let dynamicPersonArr = initData?.dynamicPerson ?? [];
    let isTrue = false;
    dynamicPersonArr?.forEach((ele: any) => {
      if (ele.id == 'principal') {
        isTrue = true;
      }
    });

    return [
      {
        name: '负责人',
        id: 'principal',
        checked: isTrue,
        currentType: 4,
        type: 'principal',
      },
    ];
  }, [initData]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [randomKey, setRandomKey] = useState(0);

  const [expandedDepartKeys, setExpandedDepartKeys] = useState<React.Key[]>([]);
  const [checkedDepartKeys, setCheckedDepartKeys] = useState<React.Key[]>([]);
  const [checkedDepartNodes, setCheckedDepartNodes] = useState<any | undefined>(
    [],
  );
  const [selectedDepartKeys, setSelectedDepartKeys] = useState<React.Key[]>([]);
  const [autoExpandDepartParent, setAutoExpandDepartParent] = useState<boolean>(
    true,
  );
  const [departData, setDepartData] = useState<any | undefined>([]);
  const [initDepartData, setInitDepartData] = useState<any | undefined>([]);

  const [showTabs, setShowTabs] = useState(tabs ? tabs : defaultTabs);

  // 人员树相关信息
  const [expandedUsersKeys, setExpandedUsersKeys] = useState<React.Key[]>([]);
  const [checkedUsersKeys, setCheckedUsersKeys] = useState<React.Key[]>([]);

  const [selectedUsersKeys, setSelectedUsersKeys] = useState<React.Key[]>([]);
  const [autoExpandUsersParent, setAutoExpandUsersParent] = useState<boolean>(
    true,
  );
  const [usersData, setUsersData] = useState<any | undefined>([]);
  const [staffArray, setStaffArray] = useState<any | undefined>([]);
  const [staffTempArray, setStaffTempArray] = useState<any | undefined>([]);
  const [isAll, setIsAll] = useState(false);

  // 角色树相关信息
  const [expandedRolesKeys, setExpandedRolesKeys] = useState<React.Key[]>([]);
  const [checkedRolesKeys, setCheckedRolesKeys] = useState<React.Key[]>([]);
  const [checkedRolesNodes, setCheckedRolesNodes] = useState<React.Key[]>([]);

  const [selectedRolesKeys, setSelectedRolesKeys] = useState<React.Key[]>([]);
  const [autoExpandRolesParent, setAutoExpandRolesParent] = useState<boolean>(
    true,
  );
  const [rolesData, setRolesData] = useState<any | undefined>([]);

  // 动态负责人
  const [selectIndex, setSelectIndex] = useState(1);
  const [memberList, setMemberList] = useState(memberData || []);
  const [sponsorList, setSponsorList] = useState(initSponsorList || []);
  const [principal, setPrincipal] = useState(initPrincipalList || []);

  // 页面基本信息
  const [hasSelectData, setHasSelectData] = useState<any[]>([]);
  const [hasSelectDepartData, setHasSelectDepartData] = useState<any[]>([]);
  const [hasSelectUsersData, setHasSelectUsersData] = useState<any[]>([]);
  const [hasSelectRolesData, setHasSelectRolesData] = useState<any[]>([]);
  const [hasDynamicPersonData, setHasDynamicPersonData] = useState<any[]>([]);
  const [hasDynamicMemberData, setHasDynamicMemberData] = useState<any[]>([]);

  // 保存实际选择的roles的keys值，方便删除时直接删除其中一项，并替换原来的tree的Keys
  const [saveTempKeys, setSaveTempKeys] = useState<any[]>([]);

  // 保存实际选择的roles的keys值，方便删除时直接删除其中一项，并替换原来的tree的Keys
  // const [saveUsersTempKeys, setSaveUsersTempKeys] = useState<any[]>([]);

  const [tabKey, setTabKey] = useState('1');
  const [saveCount, setSaveCount] = useState<number>(1);
  const inputSearch = useRef(null);

  // 保存当前部门是否已经有加载过数据

  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [isHasScroll, setIsHasScroll] = useState(false);

  const bizType = getUrlParam('bizType');

  // 解决动态绑定无法获取最新值的bug
  const saveDepartmentId = useRef('');
  const saveStaff = useRef<any>([]);
  const saveTempStaff = useRef<any>([]);
  const pageNum = useRef<number>(1);
  const selectUsersData = useRef<any>([]);
  const saveTotal = useRef<number>(0);

  const dirRef = useRef();

  const [loading, setLoading] = useState(false);

  const filterCheckNodeDepart = (arr) => {
    const map = arr.reduce((cur, pre) => {
      cur[pre.id] = pre;
      return cur;
    }, {});
    return arr.filter(
      (item) => !map[item.parentId] || item.id === item.parentId,
    );
  };

  const filterSelectDepartFromParent = (item) => {
    if (!departData?.length) return {};
    const map = {};
    map[item?.id] = item;
    const addChildrenInMap = (arr) => {
      arr?.forEach((k) => {
        map[k.id] = k;
        if (k.children?.length) {
          addChildrenInMap(k.children);
        }
      });
    };
    const findNode = (arr) => {
      arr?.forEach((k) => {
        if (k.id === item.id) {
          map[k.id] = k;
          if (k.children?.length) {
            addChildrenInMap(k.children);
          }
        } else {
          if (k.children?.length) {
            findNode(k.children);
          }
        }
      });
    };
    findNode(departData);
    return map;
  };

  const setDepartTreeDisable = (parentNode) => {
    if (!departData?.length) return;
    const mapParentNode = (parentNode || []).reduce((cur, pre) => {
      cur[pre.id] = pre;
      return cur;
    }, {});
    const data = _cloneDeep(initDepartData);
    const findNode2 = (arr) => {
      arr?.forEach((k) => {
        if (mapParentNode[k.id]) {
          if (k.children?.length) {
            addDisableInChildren(k.children);
          }
        } else {
          if (k.children?.length) {
            findNode2(k.children);
          }
        }
      });
    };
    const addDisableInChildren = (arr) => {
      arr?.forEach((k) => {
        k.disableCheckbox = true;
        if (k.children?.length) {
          addDisableInChildren(k.children);
        }
      });
    };
    findNode2(data);
    return data;
  };

  useImperativeHandle(cRef, () => ({
    // getSelectData 就是暴露给父组件的方法 获取选择的数据
    getSelectData: () => {
      if (
        hasSelectDepartData.length === 0 &&
        hasSelectUsersData.length === 0 &&
        hasSelectRolesData.length === 0 &&
        hasDynamicPersonData.length === 0 &&
        hasDynamicMemberData.length === 0
      ) {
        message.warning('请选择数据再操作，再操作！');
        return;
      }
      getDataBack({
        departments: hasSelectDepartData,
        users: hasSelectUsersData,
        roles: hasSelectRolesData,
        dynamicPerson: hasDynamicPersonData,
        dynamicMember: hasDynamicMemberData,
      });
    },
  }));
  useEffect(() => {
    //  staffTempArray 为当前机构下选择了的数据  staffArray为当前机构下获取到的所有人员数据
    if (staffTempArray.length == staffArray.length && staffArray.length != 0) {
      setCheckAll(true);
      setIndeterminate(false);
    } else if (
      staffTempArray.length != 0 &&
      staffArray.length != 0 &&
      staffTempArray.length != staffArray.length
    ) {
      setIndeterminate(true);
      setCheckAll(false);
    } else {
      setIndeterminate(false);
      setCheckAll(false);
    }
  }, [staffTempArray]);

  useEffect(() => {
    // 获取部门树
    departmentTree({})
      .then((res: any) => {
        const { data } = res;
        const treeData = computeTreeData([_cloneDeep(data)], 1);
        setDepartData(treeData ?? []);
        setInitDepartData(treeData ?? []);
        const userTreeData = computeTreeData([_cloneDeep(data)], 2);
        setUsersData(userTreeData ?? []);
      })
      .catch((err) => {
        console.log(err);
      });

    // 获取角色树
    roleTree()
      .then((res: any) => {
        const { data } = res;
        const rolesData = computeTreeData(data, 3);
        setRolesData(rolesData ?? []);
      })
      .catch((err) => {});
    window.addEventListener('click', () => {
      putAwaySearch();
    });
  }, []);

  useEffect(() => {
    // 动态部门 过滤掉子节点
    const filterCheckedDepartNodes = filterCheckNodeDepart(checkedDepartNodes);

    // 分别保存选择的数据 ，方便确定选择时数据的分类
    setHasSelectDepartData([...filterCheckedDepartNodes]);
    console.log(checkedDepartNodes, filterCheckedDepartNodes, 'PPP');
    // 角色对象单独处理
    let finalNodesData = cpmputeCorrectChildNodes(checkedRolesNodes);
    let saveTempKeys: any[] = finalNodesData.map((val: any) => val.id);
    setSaveTempKeys([...saveTempKeys]);
    setHasSelectRolesData([...finalNodesData]);
  }, [saveCount]);

  useEffect(() => {
    let allSelect = [
      ...hasSelectDepartData,
      ...hasSelectUsersData,
      ...hasSelectRolesData,
      ...hasDynamicPersonData,
      ...hasDynamicMemberData,
    ];
    setHasSelectData([...allSelect]);
  }, [
    hasSelectDepartData,
    hasSelectUsersData,
    hasSelectRolesData,
    hasDynamicPersonData,
    hasDynamicMemberData,
  ]);

  useEffect(() => {
    getSize(hasSelectData?.length || 0);
  }, [hasSelectData]);

  useEffect(() => {
    const departmentsArr = initData?.departments ?? [];
    const usersArr = initData?.users ?? [];
    const rolesArr = initData?.roles ?? [];
    const dynamicPersonArr = initData?.dynamicPerson ?? [];
    const dynamicMember = initData?.dynamicMember ?? [];
    const departmentskeys: Array<string> = [];
    const roleskeys: Array<string> = [];
    departmentsArr?.forEach((ele) => {
      ele.currentType = 1;
      !ele.id && (ele.id = ele.key);
      departmentskeys.push(ele.key || ele.id);
    });
    usersArr?.forEach((ele) => {
      ele.currentType = 2;
      !ele.userId && (ele.userId = ele.key);
      // userskeys.push(ele.key);
    });

    rolesArr?.forEach((ele) => {
      ele.currentType = 3;
      !ele.id && (ele.id = ele.key);
      roleskeys.push(ele.key || ele.id);
    });

    dynamicPersonArr?.forEach((ele) => {
      ele.currentType = 4;
    });

    setHasSelectUsersData([...usersArr]);
    selectUsersData.current = [...usersArr];

    setCheckedRolesKeys([...roleskeys]);
    setCheckedRolesNodes([...rolesArr]);
    setHasSelectRolesData([...rolesArr]);

    setCheckedDepartKeys([...departmentskeys]);
    setCheckedDepartNodes([...departmentsArr]);
    setHasSelectDepartData([...departmentsArr]);

    setHasDynamicPersonData([...dynamicPersonArr]);

    setHasDynamicMemberData([...dynamicMember]); // 动态成员

    setSaveCount((count) => count + 1);
  }, [initData]);

  useEffect(() => {
    // 动态更新子节点  CheckedDepartKeys
    let mapData = {};
    checkedDepartNodes?.forEach((item) => {
      mapData = {
        ...mapData,
        ...filterSelectDepartFromParent(item),
      };
    });
    setCheckedDepartKeys([...Object.keys(mapData)]);
  }, [departData]);

  useEffect(() => {
    const data = setDepartTreeDisable(checkedDepartNodes);
    setDepartData(data ?? []);
  }, [checkedDepartNodes]);

  useEffect(() => {
    if (showTabs.includes('部门')) {
      setTabKey('1');
      return;
    }
    if (showTabs.includes('人员')) {
      setTabKey('2');
      return;
    }
    if (showTabs.includes('角色')) {
      setTabKey('3');
      return;
    }
    if (showTabs.includes('动态负责人')) {
      setTabKey('4');
      return;
    }
    if (showTabs.includes('动态成员')) {
      setTabKey('5');
      return;
    }
  }, [showTabs]);

  useEffect(() => {
    setRandomKey((val) => val + 1);
  }, [isModalVisible]);

  const cpmputeCorrectChildNodes = (checkedRolesNodes: any) => {
    const corretData = checkedRolesNodes.filter((ele: any) => ele.type !== '0');
    return corretData;
  };

  let latoutNode: any = null;
  let timer: any = null;
  // 监听人员展示区滚动条的功能
  const addEventListenerScroll = () => {
    clearTimeout(timer);
    if (isHasScroll) return;
    latoutNode = document.getElementById('scrollGetUser');
    if (latoutNode) {
      setIsHasScroll(true);
      latoutNode.addEventListener('scroll', ulScroll);
    } else {
      timer = setTimeout(() => {
        addEventListenerScroll();
      }, 500);
    }
  };

  let throttling: any = null;
  // 滚动函数
  const ulScroll = (e) => {
    const { clientHeight, scrollHeight, scrollTop } = e.target;
    if (
      clientHeight + scrollTop == scrollHeight &&
      clientHeight != scrollHeight
    ) {
      clearTimeout(throttling);
      throttling = setTimeout(() => {
        getUserByPageFun({
          departmentId: saveDepartmentId.current,
          pageNum: pageNum.current,
        });
        clearTimeout(throttling);
      }, 100);
    }
  };

  const callback = (key: string) => {
    setTabKey(key);
    (inputSearch?.current as any)?.clearSearchKey();
    if (key == '2') {
      addEventListenerScroll();
    }
  };

  // 收起搜索区域
  const putAwaySearch = () => {
    (inputSearch?.current as any)?.blurSearch();
  };

  // 收起/展开部门树
  const onExpandDepart = (expandedDepartKeysValue: React.Key[]) => {
    setExpandedDepartKeys(expandedDepartKeysValue);
    setAutoExpandDepartParent(false);
  };

  /**
   * @function 获取tree组件中某一个节点的所有后代节点
   * @param treeNode {ITreeNode} tree节点
   * @return descendantTreeNodes {ITreeNode[]} tree节点的所有后代节点集合
   */
  const getTreeNodes = (treeNode: ITreeNode) => {
    if (!treeNode) return [];
    let descendantTreeNodes: ITreeNode[] = [];
    const getDescendantTreeNodes = (node: ITreeNode) => {
      descendantTreeNodes = descendantTreeNodes.concat(node.children);
      if (node?.children.length > 0) {
        node.children.forEach((item) => getDescendantTreeNodes(item));
      }
    };
    getDescendantTreeNodes(treeNode);
    console.log('所有后代节点： ', descendantTreeNodes);
    return descendantTreeNodes;
  };
  // 选择或者取消选择部门树
  const onCheckDepart = (
    checkedKeysValue: any,
    { checkedNodes, node, checked: bool }: any,
  ) => {
    console.log('checkedKeysValue: ', checkedKeysValue);
    console.log('checkedNodes: ', checkedNodes);
    console.log('node: ', node);
    if (bool && !multiple) {
      setCheckedDepartNodes([node]);
      setCheckedDepartKeys([node.key]);
    } else {
      const { checked } = checkedKeysValue;
      // 1.如果当前节点选中，则将所有子节点选中
      // 2.如果当前节点取消选中，则将所有子节点取消选中
      if (bool) {
        setCheckedDepartNodes(
          _uniqBy(checkedNodes.concat(getTreeNodes(node)), 'id'),
        );
        setCheckedDepartKeys(
          _uniq(checked.concat(getTreeNodes(node).map((v) => v.id))),
        );
      } else {
        setCheckedDepartNodes(
          _uniqBy(
            _differenceBy(checkedNodes, [node, ...getTreeNodes(node)]),
            'id',
          ),
        );
        setCheckedDepartKeys(
          _uniq(
            _difference(checked, [
              node?.id,
              ...getTreeNodes(node).map((v) => v.id),
            ]),
          ),
        );
      }
    }

    setSaveCount((count) => count + 1);
  };

  // 收起/展开人员树
  const onExpandUsers = (expandedUsersKeysValue: React.Key[]) => {
    setExpandedUsersKeys(expandedUsersKeysValue);
    setAutoExpandUsersParent(false);
  };

  const getAllChildrenNodes = (rootData: any, arr: any[]) => {
    rootData &&
      rootData?.forEach((element: any) => {
        if (element.children && element.children.length != 0) {
          arr.push(element);
          getAllChildrenNodes(element.children, arr);
        } else {
          arr.push(element);
        }
      });
  };

  // 点击选择人员树的文字
  const onSelectUser = (
    selectedKeys: React.Key[],
    { selected: bool, selectedNodes, node, event }: any,
  ) => {
    setIsAll(false);
    setSelectedUsersKeys(selectedKeys);
    saveDepartmentId.current = node.id;
    onlyGetUserChild(selectedKeys.join(','));
  };

  // 收起/展开角色树
  const onExpandRoles = (expandedRolesKeysValue: React.Key[]) => {
    setExpandedRolesKeys(expandedRolesKeysValue);
    setAutoExpandRolesParent(false);
  };

  // 选择或者取消选择角色树
  const onCheckRoles = (
    checkedKeysValue: any,
    { checked: bool, checkedNodes, node, event, halfCheckedKeys }: any,
  ) => {
    setCheckedRolesNodes(checkedNodes);
    setCheckedRolesKeys(checkedKeysValue);
    setSaveCount((count) => count + 1);
  };

  // 移除已经选择的数据
  const removeSelectData = (item: any, bool: boolean): void => {
    // 直接删除已选数据
    const remainHasSelectData = hasSelectData.filter((val) => {
      if (val?.type && item?.type) {
        return !(val.id === item.id && val.type === item.type);
      }
      return val.id !== item.id;
    });
    setHasSelectData([...remainHasSelectData]);
    switch (item.currentType) {
      case 1:
        const deleteMap = filterSelectDepartFromParent(item);
        // 删除部门部分数据
        const newDepartKeys = checkedDepartKeys?.filter(
          (ele: any, i: number) => !deleteMap[ele],
        );
        console.log('PPPP');
        setCheckedDepartKeys([...newDepartKeys]);
        const newDepartNodes = checkedDepartNodes?.filter(
          (ele: any, i: number) => !deleteMap[ele.id],
        );
        setCheckedDepartNodes([...newDepartNodes]);
        // 动态部门 过滤掉子节点
        const filterCheckedDepartNodes = filterCheckNodeDepart(newDepartNodes);
        setHasSelectDepartData([...filterCheckedDepartNodes]);
        break;
      case 2:
        // 删除用户数据
        const tempUserData = hasSelectUsersData.filter(
          (ele: any, i: number) => ele.userId != item.userId,
        );
        setHasSelectUsersData([...tempUserData]);
        selectUsersData.current = [...tempUserData];
        if (bool) {
          // 此时需要联动的将选择了的数据交互去掉
          const tempArr = staffTempArray.filter(
            (ele: any, i: number) => ele.userId != item.userId,
          );
          staffArray?.forEach((ele) => {
            if (ele.userId == item.userId) {
              ele.checked = false;
            }
          });
          setStaffTempArray([...tempArr]);
          setStaffArray([...staffArray]);
          saveTempStaff.current = [...tempArr];
          saveStaff.current = [...staffArray];
        }
        break;
      case 3:
        // 角色部分
        // 对正确选中的saveTempKeys进行删除，并重新覆盖原来树的选中keys（这样处理的方式是为了避免深层次去查找树的节点，在删除）
        const newRolesKeys = saveTempKeys.filter(
          (ele: any, i: number) => item.id !== ele,
        );
        // 同时更新两个数组
        setSaveTempKeys([...newRolesKeys]);
        setCheckedRolesKeys([...newRolesKeys]);
        const newRolesNodes = checkedRolesNodes.filter(
          (ele: any, i: number) => item.id !== ele.id,
        );
        setCheckedRolesNodes([...newRolesNodes]);
        setHasSelectRolesData([...newRolesNodes]);
        break;
      case 4:
      case 5:
        // 删除用户数据
        const tempDynamicData = hasDynamicPersonData.filter(
          (ele: any, i: number) =>
            !(ele.id == item.id && ele.type == item.type),
        );
        setHasDynamicPersonData([...tempDynamicData]);
        sponsorList?.forEach((val) => {
          if (val.id == item.id && val.type == item.type) {
            val.checked = false;
          }
        });
        memberList?.forEach((val) => {
          if (val.id == item.id && val.type == item.type) {
            val.checked = false;
          }
        });
        principal?.forEach((val) => {
          if (val.id == item.id && val.type == item.type) {
            val.checked = false;
          }
        });
        if (item.type === 'manager' || item.type === 'direct_manager') {
          dirRef.current?.onRemove?.();
        }
        setMemberList([...memberList]);
        setSponsorList([...sponsorList]);
        setPrincipal([...principal]);
        break;
    }
  };

  const getAllSelectKeyByCurrentKey = (
    rootData: any,
    id: string,
    arr: any[],
  ) => {
    rootData &&
      rootData?.forEach((element: any) => {
        if (element.id === id) {
          arr.push(element.id);
          if (element.children && element.children.length != 0) {
            getAllChildrenKey(element.children, arr);
          }
        } else {
          getAllSelectKeyByCurrentKey(element.children, id, arr);
        }
      });
  };

  const getAllChildrenKey = (rootData: any, arr: any[]) => {
    rootData &&
      rootData?.forEach((element: any) => {
        if (element.children && element.children.length != 0) {
          arr.push(element.id);
          getAllChildrenKey(element.children, arr);
        } else {
          arr.push(element.id);
        }
      });
  };

  const computeTreeData = (
    data: Array<any>,
    type: number,
    disable?: boolean,
  ) => {
    if (data && data.length > 0) {
      data?.forEach((item: any) => {
        item.key = item.id;
        item.title = item.name;
        item.currentType = type;
        item.disableCheckbox = !!disable;
        if (item.children) {
          computeTreeData(item.children, type, !!disable);
        }
      });
      return data;
    }
  };

  const getKeyById = (rootData: any, id: string, temp: Array<string> = []) => {
    rootData &&
      rootData?.forEach((element: any) => {
        if (element.id == id) {
          temp.push(element.key);
        }
        if (element.children && element.children.length != 0) {
          getKeyById(element.children, id, temp);
        }
      });
  };

  // 选择其中一个
  const selectOneItem = (item: any, bool: boolean, currentType?: number) => {
    switch (tabKey) {
      case '1':
        if (!multiple) {
          setCheckedDepartKeys([item.id]);
          setCheckedDepartNodes([item]);
          setSaveCount((count) => count + 1);
          return;
        }
        let isTrue = checkedDepartNodes.some((ele: any) => ele.id === item.id);
        let tempData: any[] = JSON.parse(JSON.stringify(checkedDepartNodes));
        if (!isTrue) {
          tempData.push(item);
          setCheckedDepartNodes([...tempData]);
          let tempArr1: any[] = JSON.parse(JSON.stringify(checkedDepartKeys));
          tempArr1.push(item.id);
          setCheckedDepartKeys([...tempArr1]);
          setSaveCount((count) => count + 1);
        }

        break;
      case '2':
        if (!multiple) {
          setStaffTempArray([item]);
          saveTempStaff.current = [item];
          staffArray?.forEach((ele) => {
            if (ele.userId == item.userId) {
              ele.checked = true;
              // 当前数值中刚好存在这个项，既可以收录在staffTempArray中
            } else {
              ele.checked = false;
            }
          });
          setStaffArray([...staffArray]);
          saveStaff.current = [...staffArray];
          setHasSelectUsersData([item]);
          setHasDynamicMemberData([]);
          selectUsersData.current = [item];
          return;
        }
        // 判斷所有已選數據中是否有該項
        let isUserTrue = hasSelectUsersData.some(
          (ele: any) => ele.userId === item.userId,
        );
        if (bool) {
          let tempArr: string[] = [];
          staffArray?.forEach((ele) => {
            if (ele.userId == item.userId) {
              ele.checked = true;
              tempArr.push(ele);
              // 当前数值中刚好存在这个项，既可以收录在staffTempArray中
            }
          });
          setStaffTempArray([...staffTempArray, ...tempArr]);
          setStaffArray([...staffArray]);
          saveTempStaff.current = [...staffTempArray, ...tempArr];
          saveStaff.current = [...staffArray];
        }
        if (!isUserTrue) {
          hasSelectUsersData.push(item);
          setHasSelectUsersData([...hasSelectUsersData]);
          selectUsersData.current = [...hasSelectUsersData];
        } else {
          message.info('当前数据已存在');
        }
        break;
      case '3':
        let isRolesTrue = checkedRolesNodes.some(
          (ele: any) => ele.id === item.id,
        );
        let tempRolesData: any[] = JSON.parse(
          JSON.stringify(checkedRolesNodes),
        );
        if (!isRolesTrue) {
          tempRolesData.push(item);
          setCheckedRolesNodes([...tempRolesData]);
          let tempArr1: any[] = JSON.parse(JSON.stringify(checkedRolesKeys));
          tempArr1.push(item.id);
          setCheckedRolesKeys([...tempArr1]);
          setSaveCount((count) => count + 1);
        }

        break;
      case '4':
        let index = hasDynamicPersonData.findIndex((i) => {
          if (currentType === 5) {
            return i.type === 'direct_manager';
          } else {
            return i.type === item?.type;
          }
        });
        if (index >= 0) {
          hasDynamicPersonData.splice(index, 1, item);
          setHasDynamicPersonData([...hasDynamicPersonData]);
          return;
        }
        // 判斷所有已選數據中是否有該項
        let isHasDynamic = hasDynamicPersonData.some(
          (ele: any) => ele.id === item.id && ele.type === item.type,
        );
        if (!isHasDynamic) {
          hasDynamicPersonData.push(item);
          setHasDynamicPersonData([...hasDynamicPersonData]);
        } else {
          message.info('当前数据已存在');
        }
        break;
      default:
        console.log('没有此类型数据跟其对应');
        break;
    }
  };

  const updateTreeData = (list: any, key: React.Key, children: any) => {
    return list.map((node: any) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });
  };

  // 根据部门id获取部门下面人的数据
  const getUserByPageFun = (params: any) => {
    // 没有部门id时证明全部的情况，不需要走接口
    if (!params.departmentId) return;
    const saveHasChoiceTempArr: any[] = [];
    if (saveTotal.current < 20 * (params?.pageNum - 1)) {
      message.info('无更多数据');
      return;
    }
    setLoading(true);
    getUserByPage({ ...params })
      .then((res: any) => {
        setLoading(false);
        const {
          data: { records = [], total },
        } = res;
        records &&
          records?.forEach((ele: any) => {
            ele.currentType = 2;
            let isHas = selectUsersData.current.some(
              (val) => ele.userId == val.userId,
            );
            if (isHas) {
              ele.checked = true;
              saveHasChoiceTempArr.push(ele);
            } else {
              ele.checked = false;
            }
          });
        saveTotal.current = total;
        if (params?.pageNum == 1) {
          setStaffArray([...records]);
          setStaffTempArray([...saveHasChoiceTempArr]);
          saveTempStaff.current = [...saveHasChoiceTempArr];
          saveStaff.current = [...records];
          pageNum.current = pageNum.current + 1;
        } else {
          if (records.length == 0) {
            return;
          }
          let deepStaffData = _cloneDeep(saveStaff.current);
          let deepTempStaffData = _cloneDeep(saveTempStaff.current);
          setStaffArray([...deepStaffData, ...records]);
          setStaffTempArray([...deepTempStaffData, ...saveHasChoiceTempArr]);
          saveTempStaff.current = [...deepStaffData, ...records];
          saveStaff.current = [...deepTempStaffData, ...saveHasChoiceTempArr];
          pageNum.current = pageNum.current + 1;
        }
      })
      .catch(() => {
        setLoading(false);
        // setStaffArray([])
        // setStaffTempArray([])
        message.info('获取数据失败');
      });
  };

  // 根据部门id获取部门下面人的数据
  const onlyGetUserChild = (key: string) => {
    pageNum.current = 1;
    getUserByPageFun({ departmentId: key, pageNum: 1 });
  };

  // 全选当前所有人员
  const onCheckAllChange = (e) => {
    if (!e.target.checked) {
      staffArray &&
        staffArray?.forEach((ele: any) => {
          ele.checked = false;
        });
      setStaffTempArray([]);
      saveTempStaff.current = [];
      let remainUsersData = hasSelectUsersData.filter(
        (val) => !staffArray.some((ele) => ele.userId == val.userId),
      );
      setHasSelectUsersData([...remainUsersData]);
      selectUsersData.current = [...remainUsersData];
    } else {
      staffArray &&
        staffArray?.forEach((ele: any) => {
          ele.checked = true;
        });
      setStaffTempArray([...staffArray]);
      let tempArr: any[] = [];
      staffArray?.forEach((val: any) => {
        let isUserTrue = hasSelectUsersData.some(
          (ele) => ele.userId == val.userId,
        );
        if (!isUserTrue) {
          tempArr.push(val);
        }
      });
      setHasSelectUsersData([...hasSelectUsersData, ...tempArr]);
      selectUsersData.current = [...hasSelectUsersData, ...tempArr];
    }
    setStaffArray([...staffArray]);
    saveStaff.current = [...staffArray];
    setCheckAll(e.target.checked);
    setIndeterminate(false);
  };

  // 选择或者取消
  const selectOrCancle = (e, ele) => {
    ele.checked = e.target.checked;
    setStaffArray([...staffArray]);
    saveStaff.current = [...staffArray];
    if (!e.target.checked) {
      let filterArr = staffTempArray.filter((val) => ele.userId != val.userId);
      setStaffTempArray([...filterArr]);
      saveTempStaff.current = [...filterArr];
      removeSelectData(ele, false);
    } else {
      if (!multiple) {
        setStaffTempArray([ele]);
        saveTempStaff.current = [ele];
      } else {
        staffTempArray.push(ele);
        setStaffTempArray([...staffTempArray]);
        saveTempStaff.current = [...staffTempArray];
      }
      selectOneItem(ele, false);
    }
  };

  // 选择动态负责人或者取消
  const selectMenberOrNot = (e: any, type: number, ele?: any) => {
    ele.checked = e.target.checked;
    if (type == 1) {
      setSponsorList([...sponsorList]);
    } else if (type == 2) {
      setMemberList([...memberList]);
    } else if (type === 3) {
      setPrincipal([...principal]);
    }
    if (!e.target.checked) {
      removeSelectData(ele, false);
    } else {
      selectOneItem(ele, false, ele?.currentType);
    }
  };

  // 渲染人员树
  const renderTreeNodes = (data: any[]) =>
    data.map((item, index) => {
      if (item.children) {
        return (
          <TreeNode
            {...item}
            title={item.title}
            key={`${item.key}`}
            type={item.type}
            switcherIcon={item.type === 1 ? <></> : null}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          {...item}
          key={item.key}
          switcherIcon={item.type === 1 ? <></> : null}
          type={item.type}
        />
      );
    });

  // 获取所有人员数据
  const getAllPeople = () => {
    setIsAll(true);
    setSelectedUsersKeys([]);
    saveDepartmentId.current = '';
    listAll()
      .then((res) => {
        const { data } = res;
        let saveHasChoiceTempArr: any[] = [];
        data &&
          data?.forEach((ele: any) => {
            ele.currentType = 2;
            let isHas = hasSelectUsersData.some(
              (val) => ele.userId == val.userId || ele.id == val.id,
            );
            if (isHas) {
              ele.checked = true;
              saveHasChoiceTempArr.push(ele);
            } else {
              ele.checked = false;
            }
          });
        setStaffArray(data || []);
        saveStaff.current = [...(data || [])];
        setStaffTempArray([...saveHasChoiceTempArr]);
        saveTempStaff.current = [...saveHasChoiceTempArr];
      })
      .catch(() => {});
  };

  const changeDynamicMember = (item) => (e) => {
    console.log(item, e.target.checked, 'eeeee');
    if (e.target.checked) {
      // 选中
      if (!multiple) {
        // 只能选中一人时，当选择动态成员时，人员要清空
        setHasDynamicMemberData([item]);
        console.log(staffArray, 'staffArraystaffArray');
        setHasSelectUsersData([]);
        staffArray?.forEach((val) => {
          if (val.checked) {
            val.checked = !val.checked;
          }
        });
      } else {
        setHasDynamicMemberData([...hasDynamicMemberData, item]);
      }
    } else {
      // 取消选择
      const fillterDynamicMemberData = hasDynamicMemberData.filter(
        (val) => val.id !== item.id,
      );
      setHasDynamicMemberData(fillterDynamicMemberData);
    }
  };

  return (
    <div
      className={c.dataOpration}
      key={randomKey}
      onClick={(e) => putAwaySearch()}
    >
      <div className={c.showUsersSession} onClick={(e) => putAwaySearch()}>
        <ul>
          {hasSelectData.map((item, i) => {
            return (
              <li key={item.id + item.type}>
                <span>{item.userDeptName || item.name}</span>
                <CloseOutlined onClick={() => removeSelectData(item, true)} />
              </li>
            );
          })}
          {hasSelectData.length == 0 && <li className={c.empty}>暂无选中项</li>}
        </ul>
      </div>
      <div className={c.selectDistrict} onClick={(e) => e.stopPropagation()}>
        {tabKey != '4' ? (
          <InputSearch
            tabIndex={tabKey}
            cRef={inputSearch}
            selectOneItem={selectOneItem}
            deleteOneItem={removeSelectData}
            // key={saveCount}
            hasSelectDepartData={hasSelectDepartData}
            hasSelectUsersData={hasSelectUsersData}
            hasSelectRolesData={hasSelectRolesData}
            multiple={multiple}
            placeholder={
              tabKey === '1'
                ? '搜索部门'
                : tabKey === '2'
                ? '搜索人员'
                : '搜索角色'
            }
          />
        ) : null}

        <Tabs defaultActiveKey="1" onChange={callback}>
          {showTabs.includes('部门') && (
            <TabPane tab="部门" key="1">
              <div className={c.department}>
                <div className={c.treeDom}>
                  <Tree
                    checkable
                    onExpand={onExpandDepart}
                    expandedKeys={expandedDepartKeys}
                    autoExpandParent={autoExpandDepartParent}
                    onCheck={onCheckDepart}
                    checkedKeys={checkedDepartKeys}
                    // onSelect={onSelect}
                    selectedKeys={selectedDepartKeys}
                    treeData={departData}
                    checkStrictly={true}
                  />
                  {/* {getTreeNode(departData)} */}
                </div>
              </div>
            </TabPane>
          )}
          {showTabs.includes('人员') && (
            <TabPane tab="人员" key="2">
              <div className={c.staff}>
                <div className={c.selectPart}>
                  <div className={c.treeDom}>
                    <div
                      className={cs(c.allPeople, isAll ? c.active : undefined)}
                      onClick={getAllPeople}
                    >
                      全部人员
                    </div>
                    <Tree
                      // checkable
                      onExpand={onExpandUsers}
                      expandedKeys={expandedUsersKeys}
                      autoExpandParent={autoExpandUsersParent}
                      // onCheck={onCheckUsers}
                      checkedKeys={checkedUsersKeys}
                      onSelect={onSelectUser}
                      selectedKeys={selectedUsersKeys}
                      treeData={usersData}
                      // multiple={true}
                      // loadData={onLoadData}
                      // showIcon={true}
                    >
                      {renderTreeNodes(usersData)}
                    </Tree>
                  </div>
                </div>
                <div className={c.showPersionPart}>
                  {staffArray.length == 0 && (
                    <span className={c.emptyTip}>没有可选成员</span>
                  )}
                  {multiple && staffArray.length != 0 ? (
                    <div className={c.checkAllArea}>
                      <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                      >
                        已选 {staffTempArray.length}/{staffArray.length}
                      </Checkbox>
                    </div>
                  ) : null}
                  <Spin spinning={loading}>
                    <ul
                      className={cs(
                        !multiple ? c.singleChoice : undefined,
                        staffArray.length == 0 && c.hideBlock,
                      )}
                      id="scrollGetUser"
                    >
                      {staffArray.map((ele: any) => {
                        return (
                          <li key={ele.userId}>
                            {' '}
                            <Checkbox
                              className={c.checkBox}
                              onChange={(e) => selectOrCancle(e, ele)}
                              checked={ele.checked}
                            >
                              {ele.name}
                            </Checkbox>
                          </li>
                        );
                      })}
                    </ul>
                  </Spin>
                </div>
              </div>
            </TabPane>
          )}
          {showTabs.includes('角色') && (
            <TabPane tab="角色" key="3">
              <div className={c.role}>
                <div className={c.treeDom}>
                  <Tree
                    checkable
                    onExpand={onExpandRoles}
                    expandedKeys={expandedRolesKeys}
                    autoExpandParent={autoExpandRolesParent}
                    onCheck={onCheckRoles}
                    checkedKeys={checkedRolesKeys}
                    // onSelect={onSelect}  // 点击文字时的选择
                    selectedKeys={selectedRolesKeys} // 点击文字时的选中的key值
                    treeData={rolesData}
                  />
                </div>
              </div>
            </TabPane>
          )}
          {showTabs.includes('动态负责人') && (
            <TabPane tab="动态负责人" key="4">
              <div className={c.principal}>
                <div
                  className={cs(
                    c.selectPart,
                    (selectIndex === 3 || selectIndex === 5) && c.three_col,
                  )}
                >
                  <p
                    onClick={() => setSelectIndex(1)}
                    className={selectIndex == 1 ? c.active : undefined}
                  >
                    {isProcess ? '流程发起人' : '创建人'}
                  </p>
                  <p
                    onClick={() => setSelectIndex(2)}
                    className={selectIndex == 2 ? c.active : undefined}
                  >
                    成员字段
                  </p>
                  <p
                    onClick={() => setSelectIndex(3)}
                    className={selectIndex == 3 ? c.active : undefined}
                  >
                    部门主管
                    <Popover
                      content={content1}
                      title="如何设置"
                      trigger="hover"
                    >
                      <IconFont type="icon-shuoming" className={c.tipImg} />
                    </Popover>
                  </p>
                  <p
                    onClick={() => setSelectIndex(5)}
                    className={selectIndex == 5 ? c.active : undefined}
                  >
                    直属主管
                    <Popover
                      content={content2}
                      title="如何设置"
                      trigger="hover"
                    >
                      <IconFont type="icon-shuoming" className={c.tipImg} />
                    </Popover>
                  </p>
                  {principalBizTypes.includes(bizType) && (
                    <p
                      onClick={() => setSelectIndex(4)}
                      className={selectIndex == 4 ? c.active : undefined}
                    >
                      负责人
                    </p>
                  )}
                </div>
                <div
                  className={c.showPersionPart}
                  style={{
                    padding:
                      selectIndex === 3 || selectIndex === 5 ? '0px' : '15px',
                  }}
                >
                  {selectIndex == 1 && (
                    <ul className={cs(!multiple ? c.singleChoice : undefined)}>
                      {sponsorList.map((ele: any) => {
                        return (
                          <li key={ele.id}>
                            {' '}
                            <Checkbox
                              className={c.checkBox}
                              onChange={(e) => selectMenberOrNot(e, 1, ele)}
                              checked={ele.checked}
                            >
                              {ele.name}
                            </Checkbox>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  {selectIndex === 2 && (
                    <>
                      {memberList.length == 0 && (
                        <span className={c.emptyTip}>没有可选成员字段</span>
                      )}
                      <ul>
                        {memberList.map((ele: any) => {
                          return (
                            <li key={ele.id + '@'}>
                              {' '}
                              <Checkbox
                                className={c.checkBox}
                                onChange={(e) => selectMenberOrNot(e, 2, ele)}
                                checked={ele.checked}
                              >
                                {ele.name}
                              </Checkbox>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                  {selectIndex === 3 && (
                    <Director
                      initData={initData}
                      chRef={dirRef}
                      selectMenberOrNot={selectMenberOrNot}
                      isProcess={isProcess}
                      type={'manager'}
                    />
                  )}
                  {selectIndex === 4 && (
                    <Principal
                      principal={principal}
                      onChange={(e, ele) => {
                        selectMenberOrNot(e, 4, ele);
                      }}
                    />
                  )}
                  {selectIndex === 5 && (
                    <DirectorManager
                      initData={initData}
                      chRef={dirRef}
                      selectMenberOrNot={selectMenberOrNot}
                      isProcess={isProcess}
                      type={'direct_manager'}
                    />
                  )}
                </div>
              </div>
            </TabPane>
          )}
          {showTabs.includes('动态成员') && (
            <TabPane tab="动态成员" key="5">
              <div className={c.dynamicMember}>
                {DynamicMemberList.map((item) => (
                  <div className={c.dynamicMemberItem}>
                    <Checkbox
                      key={item.id}
                      onChange={changeDynamicMember(item)}
                      checked={hasDynamicMemberData.find(
                        (v) => v.id === item.id,
                      )}
                    >
                      {item.name}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </TabPane>
          )}
        </Tabs>
      </div>
    </div>
  );
};

export default DataOpration;
