/*
 * @Description:
 * @FilePath: \tj-ali-crm-web\src\utils\tungee.ts
 * author: 黄炳良
 * copyright: (c) 2021, Tungee
 * @Date: 2022-08-16 20:01:04
 * @LastEditors: 黄炳良
 * @LastEditTime: 2022-08-17 15:13:24
 */
import { notInDD } from '@/utils';
import { getUrlParam } from '@/utils';
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';

/**
 * 判断是否在探迹云平台上打开
 */
export function isOpenInTungee() {
  return notInDD() && getUrlParam?.('source') === 'CloudPlatform';
}

/**
 * 云平台打开时，需要传带入的header参数
 */
export function getTungeeHeader() {
  if (isOpenInTungee()) {
    return {
      source: 'CloudPlatform',
    };
  }

  return {};
}

/**
 * 当来源是ERP的时候，需要修改请求url的域名
 * @param appName
 * @param url
 */
export function fitDomain(appName: string, url: string) {
  if (appName === 'tungee_erp') {
    return url.replace(
      isOpenInTripartitePlatform('1688') ? DOMAIN_QRCODE_1688 : DOMAIN_QRCODE,
      ERP_DOMAIN,
    );
  }

  return url;
}

export function isUrlInErrReturn(url: string) {
  if (!url) {
    return false;
  }
  const returnErrWhileList = ['form/printTemplate/saveTemplate'];
  return returnErrWhileList.some((item: string) => url.includes(item));
}
