export const findById = (
  element: HTMLElement | null,
  id: string,
): HTMLElement | null => {
  if (!element) {
    return null;
  }

  if (element.id == id) {
    return element;
  }

  return findById(element.parentElement, id);
};

export const findAllElement = (node: HTMLElement | null) => {
  // 后台表单配置的中部
  const midLayoutElement = findById(node, 'form-design-conter-id');
  if (midLayoutElement) {
    return midLayoutElement;
  }

  // 后台表单配置右侧
  const renderWrapElement = findById(node, 'render-wrap-id');
  if (renderWrapElement) {
    return renderWrapElement;
  }

  // 表格部分
  const formManageElement = findById(node, 'form_manage-id');
  if (formManageElement) {
    return formManageElement;
  }

  // 抽屉
  const createDrawerElement = findById(node, 'create-drawer-id');
  if (createDrawerElement) {
    return createDrawerElement;
  }

  return document.body;
};
