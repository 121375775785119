import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

export default (props) => {
  const { value, onChange, filterType } = props;

  const onDateChange = (date) => {
    onChange(date.format('YYYY-MM-DD'));
  };

  const onRangeChange = (dates) => {
    const val = [dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')];
    onChange(val);
  };

  if (filterType === 'BETWEEN') {
    const currentValue = value
      ? [moment(value[0]), moment(value[1])]
      : undefined;
    return (
      <RangePicker
        allowClear={false}
        value={currentValue}
        onChange={onRangeChange}
      />
    );
  }

  const currentValue = value ? moment(value) : undefined;

  return (
    <DatePicker
      allowClear={false}
      value={currentValue}
      onChange={onDateChange}
    />
  );
};
