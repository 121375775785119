/*
 * @Description:
 * @FilePath: /low-code-platform/tg-renderer/packages/form-render/src/widgets/SelectTreeOptions/api.tsx
 * :author: wu.shangru
 * :copyright: (c) 2023, Tungee
 * @Date: 2023-12-12 15:17:13
 * @LastEditors: wu.shangru
 * @LastEditTime: 2023-12-12 16:58:47
 */

import { postRequest, getRequest } from '@/common/http';

// 获取级联列表
export async function getCascadeOptionList(data: any) {
  return postRequest(`/api/form/schema/listCascadeOptionByIds`, data);
}

// 保存级联列表
export async function saveCascadeOption(data: any) {
  return postRequest(`/api/form/schema/saveCascadeOption`, data);
}
