/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DataSnippet\DataSnippetSetting\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-22 13:52:55
 * :last editor: hyw
 * :date last edited: 2022-04-20 11:51:40
 */
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Tooltip,
  Checkbox,
  Divider,
  CheckboxOptionType,
  message,
} from 'antd';
import _ from 'lodash';
import IconFont from '@/components/IconFont';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import { useModel } from 'umi';
import c from './index.less';
import { PsiBiztype } from '../contants';
import {
  SYSTEM_FIELDS,
  PROCESS_FORM_SYSTEM_FIELDS,
  CUSTOMER_FORM_SYSTEM_FIELDS,
  CUSTOMER_BIZ_TYPES,
} from '../contants';

enum formType {
  // 0:流程表单
  PROCESS_FORM,
  // 1:非流程表单
  NON_PROCESS_FORM,
}

const SUPPORTED_NOW = [
  'number',
  'money',
  // 'input',
  // 'textarea',
  // 'date',
  // 'dateRange',
  // 'select',
  // 'multiSelect',
  // 'phone',
  // 'innerContact',
  // 'department',
  // 'address',
  // 'seqNumber',
  // 'salesStage',
  // 'relation',
  // 'cascade',
];

const SUPPORTED_ID = ['cooperator', 'principal'];

export default function FilterOptionsSetting(props: { [key: string]: any }) {
  const { visible, onCancel, onOk, initValue } = props;
  const [value, setValue] = useState<string[]>([]);
  const [allOptions, setAllOptions] = useState<CheckboxOptionType[]>([]);
  const [commonOptions, setCommonOptions] = useState<CheckboxOptionType[]>([]);
  const model = useModel('useGenerator', (model) => model);
  const { selected } = useStore();
  const { schemaRef, profile } = model;
  const properties = schemaRef.current.properties || {};

  // const isShowProcessDesign =
  //   PsiBiztype.includes(profile?.bizType) ||
  //   profile.formType === formType.PROCESS_FORM;

  // const fieldList = Object.keys(properties)
  //   .map((key) => {
  //     return {
  //       ...properties[key],
  //       id: key,
  //     };
  //   })
  //   .filter((item) => {
  //     // 单行输入框、数字输入框、金额、单选项、电话、日期、地址
  //     return SUPPORTED_NOW.includes(item.widget);
  //   })
  //   .filter((item) => {
  //     // 过滤掉未启用的字段
  //     return !item.hidden;
  //   });

  useEffect(() => {
    if (visible) {
      const fieldList = Object.keys(properties)
        .map((key) => {
          return {
            ...properties[key],
            id: key,
          };
        })
        .filter((item) => {
          // 负责人、协同人
          // 单行输入框、数字输入框、金额、单选项、电话、日期、地址
          return SUPPORTED_NOW.includes(item.widget);
        })
        .filter((i) => !i.hidden || (i.hidden && i.hideControls));
      const allOptionsTemp = fieldList.map((item) => {
        return {
          id: item.id,
          value: item.id,
          label: item.title,
        };
      });

      // if (isShowProcessDesign) {
      //   setAllOptions([
      //     ...allOptionsTemp,
      //     ...SYSTEM_FIELDS,
      //     ...PROCESS_FORM_SYSTEM_FIELDS,
      //   ]);
      // } else {
      //   let _data = [...allOptionsTemp, ...SYSTEM_FIELDS];
      //   if (profile.bizType && CUSTOMER_BIZ_TYPES.includes(profile.bizType)) {
      //     _data = [..._data, ...CUSTOMER_FORM_SYSTEM_FIELDS];
      //   }
      //   setAllOptions(_data);
      // }
      setAllOptions(allOptionsTemp);
      const checkValue = (initValue?.propsValue?.fields || []).map(
        (item) => item.id,
      );
      setValue(checkValue);
    }
  }, [visible, initValue.propsValue, selected]);

  const onChange = (checkedValues: any[]) => {
    let _allOptions = [];
    if (checkedValues?.length >= 4) {
      _allOptions = allOptions?.map((i) => {
        if (checkedValues.includes(i.value)) {
          i.disabled = false;
        } else {
          i.disabled = true;
        }
        return i;
      });
    } else {
      _allOptions = allOptions?.map((i) => {
        i.disabled = false;
        return i;
      });
    }
    setAllOptions(_allOptions);
    setCommonOptions(allOptions.filter((v) => checkedValues.includes(v.value)));
    setValue(checkedValues);
  };

  const handleOk = () => {
    onOk(commonOptions);
  };

  // useEffect(() => {
  //   setCommonOptions(allOptions.filter((v) => value.includes(v.value)));
  // }, [value]);

  const content = (
    <div>
      <div>常用筛选项将展示在列表上方</div>
      <div>您可以对常用筛选项进行移动、隐藏</div>
      <div className={c.operating_instructions}></div>
    </div>
  );

  return (
    <div className={c.filter_options_setting}>
      <Modal
        width={600}
        title="数据卡片配置"
        visible={visible}
        okText="确定"
        cancelText="取消"
        onOk={handleOk}
        onCancel={onCancel}
        wrapClassName={c['modal-wrap']}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <div className={c.filter_options_container}>
          <div className={c.all_options}>
            <div className={c.all_options_items}>
              <Checkbox.Group value={value} onChange={onChange}>
                {allOptions.map((i) => {
                  return (
                    <Checkbox
                      key={i?.value}
                      value={i?.value}
                      disabled={i?.disabled}
                    >
                      {i?.label}
                    </Checkbox>
                  );
                })}
              </Checkbox.Group>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
