/*
 * :file description:
 * :name: \low-code-platform\src\pages\form\components\FormHeader\index.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-13 11:06:38
 * :last editor: hyw
 * :date last edited: 2022-02-25 14:06:41
 */

import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useContext,
} from 'react';
import { history, useModel } from 'umi';
import { Input, Button, Dropdown, Menu, message } from 'antd';
import { ToolOutlined, SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import IconFont from '@/components/IconFont';
import formApi from '@/services/form';
import CreateForm from '../CreateForm';
import CreateFormNew from '../../../../../tg-renderer/packages/form-render/src/form-render-core/src/create-form';
import { FormConfigCtx } from '../../[id]';
import utils from '../../utils';
import { getFormCode } from '@/utils';
import { eventBus, EventTypes } from '@/global';
import c from './index.less';
import './index.less';

import { copySchemaAndProcess } from '@/services/form-generator';
import Auth from '@/components/Auth';
import {
  DESIGN_FORM_COPY,
  DESIGN_FORM_DEL,
  DESIGN_FORM_EDIT,
} from '@/common/http/constant';
import { getMenu } from '@/services/user';
import { judgeByCons } from '@/utils/auth';

const FormHeader = (props, ref) => {
  const [keyword, setKeyword] = useState();
  const [createKey, setCreateKey] = useState(nanoid(12));
  const formCode = getFormCode();
  const formConfigCtx = useContext(FormConfigCtx);

  const createRef = useRef();

  const { check, updatePermission } = useModel('usePermission', (model) => ({
    check: model.check,
    updatePermission: model.updatePermission,
  }));

  useEffect(() => {
    eventBus.EE.on(EventTypes.Create, (p) => {
      createRef?.current?.show(p);
    });
  }, []);

  const deleteForm = () => {
    utils.doubleCheck({
      title: '确定删除该表单？',
      content: <>表单删除后，该表单的已有数据会被清空且不可恢复，请谨慎操作</>,
      onOk: () => {
        console.log('onOk');
        formApi
          .deleteForm({
            formCode,
          })
          .then((res) => {
            if (res?.code === 200) {
              message.success('删除成功');
              getMenus();
              history.push('/todoMessage');
            }
          });
      },
    });
  };

  // 更新权限
  const getMenus = async () => {
    const res = await getMenu();
    window.localStorage.setItem('allAuthList', JSON.stringify(res.data));
    const bool = res.data.some((item) => {
      return item.authorityCode == 'SYSTEM_MANAGE_BACKGROUND';
    });
    window.localStorage.setItem('authority', bool);
    updatePermission(res?.data || []);
  };

  // type=0 新建
  // type=1 编辑
  // type=2 复制
  const handleCopyForm = async () => {
    await copySchemaAndProcess({ formCode });
    getMenus();
    message.success('复制成功');
  };

  const handleOperationClick = ({ key }: { key: string }) => {
    switch (key) {
      case 'edit':
        history.push(`/generator/form?type=1&formCode=${formCode}`);
        break;
      case 'copy':
        handleCopyForm();
        break;
      case 'delete':
        deleteForm();
        break;
    }
  };
  const menuItem = [];
  if (check(DESIGN_FORM_EDIT)) {
    menuItem.push(<Menu.Item key="edit">编辑表单</Menu.Item>);
  }
  if (check(DESIGN_FORM_COPY)) {
    menuItem.push(<Menu.Item key="copy">复制表单</Menu.Item>);
  }
  if (check(DESIGN_FORM_DEL)) {
    menuItem.push(<Menu.Item key="delete">删除表单</Menu.Item>);
  }

  const FORM_OPERATIONS_MENU = (
    <Menu onClick={handleOperationClick}>{menuItem}</Menu>
  );

  useImperativeHandle(ref, () => ({
    getKeyword: () => {
      return keyword;
    },
  }));

  const doSearch = () => {
    formConfigCtx.search({ type: 'search' });
  };

  return (
    <div className={c.form_header}>
      <div className={c.title}>
        {window.__isMicroApp__ && (
          <span
            className={c.back}
            onClick={() => {
              history.goBack();
            }}
          >
            <IconFont type="icon-fanhui_cu" />
          </span>
        )}
        <span>{formConfigCtx.title}</span>
      </div>
      <div className={c.operation}>
        <div className={c.search}>
          <Input
            prefix={
              <IconFont
                type="icon-sousuo"
                className={c.searchIcon}
                onClick={doSearch}
              />
            }
            placeholder="输入关键字搜索"
            allowClear
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onPressEnter={doSearch}
          />
        </div>
        <div className={c.buttons}>
          {menuItem.length > 0 && (
            <Dropdown
              overlayClassName="form_dropdown"
              overlay={FORM_OPERATIONS_MENU}
              placement="bottomCenter"
            >
              <Button icon={<IconFont type="icon-shezhi_2" />}>设置</Button>
            </Dropdown>
          )}
          {judgeByCons({
            checkCode: `FORM_${formCode}_ADD`,
          }) && (
            <CreateFormNew
              ref={createRef}
              renderFormat="drawer"
              formCode={formCode}
              cb={() => {
                _.delay(() => {
                  formConfigCtx.search({ type: 'search' });
                }, 2000);
              }}
            >
              <IconFont
                type="icon-tianjia1"
                onClick={() => setCreateKey(nanoid(12))}
              />{' '}
              新建
            </CreateFormNew>
          )}

          {/* <Auth auth={`FORM_${formCode}_ADD`}>
            <CreateForm key={createKey}>
              <IconFont
                type="icon-tianjia1"
                onClick={() => setCreateKey(nanoid(12))}
              />{' '}
              新建
            </CreateForm>
          </Auth> */}
        </div>
      </div>
    </div>
  );
};

export default forwardRef(FormHeader);
