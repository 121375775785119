/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DataTitle\components\TitleSelector.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-13 15:06:06
 * :last editor: hyw

 * :date last edited: 2022-01-19 09:54:30
 */
require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');
require('codemirror/theme/neat.css');
require('codemirror/mode/xml/xml.js');
require('codemirror/mode/javascript/javascript.js');
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/show-hint';
import { Button, Dropdown, Menu } from 'antd';
import CodeMirror from 'codemirror';
import { Controlled as ReactCodeMirror } from 'react-codemirror2';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import c from '../index.less';
import '../common/fomula-hint';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import {
  getLabelMap,
  getValue,
  insertField,
  setValue as setDefaultValue,
} from '../common/formulaEditor';
import { idToSchema } from '@/components/FormGenerator/utils';
import { IProps } from '..';
import IconFont from '@/components/IconFont';

const options = {
  keywords: [],
  textWrapping: !0,
  lineWrapping: !0,
  lineNumbers: !1,
  matchBrackets: !0,
  specialChars: /[\u0000-\u001f\u007f\u00ad\u200c-\u200f\u2028\u2029\ufeff]/,
  mode: 'label',
};

// 支持的自定义字段类型：单行输入框、多行输入框、单选输入框、数字输入框、金额、日期、流水号
const customField = [
  'input',
  'textarea',
  'select',
  'number',
  'money',
  'date',
  'seqNumber',
  'relation',
  'cascade',
  'cascadeV2',
  'multiCascade',
];

// 创建人 createUserName
// 创建时间 gmtCreate
const defaultField = [
  {
    key: 'createUserName',
    value: '创建人',
  },
  {
    key: 'gmtCreate',
    value: '创建时间',
  },
];

const defaultSchema = {
  createUserName: {
    id: 'createUserName',
    title: '创建人',
    type: 'create',
  },
  gmtCreate: {
    id: 'gmtCreate',
    title: '创建时间',
    type: 'create',
  },
};

const TitleSelector: FC<IProps> = ({ onChange, value }) => {
  const [editorValue, setEditorValue] = useState('');
  const [visible, setVisible] = useState(false);
  const { flatten, selected } = useStore();
  const codeMirrorRef = useRef<CodeMirror.Editor>();

  const handleChange = (
    cm: CodeMirror.Editor,
    event: CodeMirror.EditorChange,
  ) => {
    if (event.origin != 'complete') {
      //未选中
      cm.showHint({
        hint: CodeMirror.hint.formula,
        completeSingle: false,
        shown: function () {
          console.log('显示了');
        },
        select: function (cpt: any, ele: any) {
          console.log(cpt, ele);
        },
        pick: function (item: any) {
          console.log(item);
        },
      });
    } else {
      // insertBarcket(cm);
    }
  };

  const getFields = () => {
    const customFieldList: typeof defaultField = [];
    flatten['#']?.children?.forEach((key: string) => {
      const { schema } = flatten[key] || {};    
      if (customField.includes(schema?.widget) && !schema.hidden) {
        customFieldList.push({
          key: schema.id,
          value: schema.title,
        });
      }
    });
    return customFieldList.concat(defaultField);
  };

  const handleMenuChange = (item: (typeof defaultField)[0]) => {
    setTimeout(() => {
      const filedObj = {
        id: null,
        name: `${item.key}`,
        text: item.value,
      };
      insertField(filedObj, false, codeMirrorRef.current);
    }, 0);
  };

  const onEditorMount = (editor: CodeMirror.Editor) => {
    setTimeout(() => {
      const schema = idToSchema(flatten);
      const labelMap = getLabelMap({ ...schema.properties, ...defaultSchema });
      setDefaultValue(value.propsValue, labelMap, editor);
    }, 20);
  };

  useEffect(() => {
    setTimeout(() => {
      const schema = idToSchema(flatten);
      const labelMap = getLabelMap({ ...schema.properties, ...defaultSchema });
      setDefaultValue(value.propsValue, labelMap, codeMirrorRef.current);
    }, 20);
  }, [selected, flatten]);

  const handleOk = () => {
    const { formula } = getValue(codeMirrorRef.current);
    let effectiveRangeAfterChange = sessionStorage.getItem(
      `effectiveRangeAfterChange`,
    );
    onChange({
      ...value,
      propsKey: 'dataTitle',
      propsValue: formula,
      effectiveRangeAfterChange: +effectiveRangeAfterChange,
    });
  };

  const menu = (
    <Menu triggerSubMenuAction="click" className={c.menuName}>
      {getFields().map((item) => (
        <Menu.Item onClick={() => handleMenuChange(item)} key={item.key}>
          {item.value}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={c.codemirror_container}>
      <ReactCodeMirror
        className={c.codemirror}
        editorDidMount={(editor) => {
          onEditorMount(editor);
          codeMirrorRef.current = editor;
        }}
        value={editorValue}
        options={options}
        onBeforeChange={(editor, data, value) => {
          setEditorValue(value);
        }}
        onChange={(editor, data, value) => {
          handleChange(editor, data);
        }}
        onBlur={handleOk}
      />
      <Dropdown
        className={c.merging}
        trigger={['click']}
        visible={visible}
        overlay={menu}
        placement="bottomLeft"
        onVisibleChange={(visible) => {
          setVisible(visible);
        }}
        destroyPopupOnHide
        overlayClassName={c.merging_overlay}
      >
        <Button
          onClick={() => setVisible(true)}
          shape="round"
          icon={<IconFont type="icon-shujuguanlian" />}
        >
          置入字段
        </Button>
      </Dropdown>
    </div>
  );
};

export default TitleSelector;
