/*
 * :file description:
 * :name: \commond:\progree\low-code-platform\tg-renderer\packages\form-render\src\components\Approve\index.tsx
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-31 11:58:38
 * :last editor: hyw
 * :date last edited: 2021-12-31 14:09:22
 */
import React, { useState, ReactNode, useEffect, useContext } from 'react';
import { Modal, message, ModalProps, Input, Select } from 'antd';
import _ from 'lodash';
import formApi from '../../services/api';
import c from './index.less';
import { useParams } from 'umi';
import IconFont from '@/components/IconFont';

const { TextArea } = Input;
const { Option } = Select;

const TITLE_MAPPING = {
  return: {
    title: '确认退回',
    placeholder: '请输入审批意见',
  },
  refuse: {
    title: '审批拒绝意见',
    placeholder: '请输入拒绝理由',
  },
  agree: {
    title: '审批同意意见',
    placeholder: '请输入同意理由',
  },
};

export default function Approve(
  props: ModalProps & { [key: string]: string | ReactNode },
) {
  const {
    type,
    children,
    instanceId,
    taskId,
    nodesOptions,
    refreshFun,
    requiredComment,
    formRef,
    formCode,
  } = props;
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('');
  const [currentUser, setCurrentUser] = useState({});
  const [vvalue, setVvalue] = useState({});
  const [options, setOptions] = useState<any>([]);

  useEffect(() => {
    if (type === 'return') {
      setOptions(nodesOptions);
    }
  }, [type]);

  const { id: urlformCode } = useParams<{ id: string }>();

  const doConfirm = () => {
    if (requiredComment && !value.trim()) {
      message.warning('请先填写意见');
      return;
    }
    const PARAMS_MAPPING = {
      return: {
        reqName: 'returnFlow',
        params: {
          taskId: taskId,
          returnTaskKey: currentUser.value,
          returnTaskName: currentUser.label,
          formCode: formCode,
          formInstanceId: instanceId,
          comment: value,
          content: JSON.stringify(formRef.getValues(true)),
        },
      },
      refuse: {
        reqName: 'approveRefuse',
        params: {
          taskId: taskId,
          formCode: formCode,
          formInstanceId: instanceId,
          comment: value,
          content: JSON.stringify(formRef.getValues(true)),
        },
      },
      agree: {
        reqName: 'approveAgree',
        params: {
          taskId: taskId,
          comment: value,
          formCode: formCode,
          formInstanceId: instanceId,
          content: JSON.stringify(formRef.getValues(true)),
        },
      },
    };
    formApi[PARAMS_MAPPING[type].reqName](PARAMS_MAPPING[type].params).then(
      (res) => {
        if (res.code === 200) {
          message.success('操作成功');
          setVisible(false);
          (props as any)?.refreshFun();
        }
      },
    );
  };

  const onOk = async () => {
    const status = await formRef?.submit();
    if (status.errors.length) return;
    doConfirm();
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onChangeValue = (json) => {
    console.log(json);
    setCurrentUser({ ...json });
    setVvalue({ ...json });
  };

  const ReturnZone = () => {
    return (
      <div>
        <div>退回到：</div>
        <Select
          style={{ width: '100%', margin: '10px 0 20px' }}
          placeholder="请选择"
          onChange={onChangeValue}
          showSearch
          value={vvalue}
          labelInValue={true}
          filterOption={(input, option) =>
            option.value.indexOf(input.toLowerCase()) >= 0
          }
        >
          {options.map(
            (item: {
              taskDefKey: string;
              taskName: string;
              disabled?: boolean;
            }) => {
              return (
                <Option
                  key={item.taskDefKey}
                  value={item.taskDefKey}
                  disabled={item.disabled}
                >
                  {item.taskName}
                </Option>
              );
            },
          )}
        </Select>
      </div>
    );
  };

  return (
    <div className={c.t_approve}>
      <div className={c.btn} onClick={() => setVisible(true)}>
        {children}
      </div>
      <Modal
        title={TITLE_MAPPING[type].title}
        visible={visible}
        okText="确定"
        cancelText="取消"
        onOk={onOk}
        onCancel={onCancel}
        closeIcon={<IconFont type="icon-Close" />}
      >
        {type === 'return' ? <ReturnZone /> : null}

        <div className={c.textArea}>
          {requiredComment ? <span className={c.star}>*</span> : null}
          <TextArea
            rows={5}
            value={value}
            placeholder={TITLE_MAPPING[type].placeholder}
            onChange={(e) => {
              setValue(e.target.value);
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
