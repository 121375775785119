/*
 * :file description:
 * :name: /low-code-platform/src/components/FormGenerator/settings/index.js
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-12 10:13:45
 * :last editor: 肖国同
 * :date last edited: 2023-02-23 11:38:03
 */

import { getUrlParam } from '@/utils';
import business from './presetFormSettings/business';
import contacts from './presetFormSettings/contacts';
import contract from './presetFormSettings/contract';
import returnMoney from './presetFormSettings/returnMoney';
import order from './presetFormSettings/order';
import product from './presetFormSettings/product';
import customerBilling from './presetFormSettings/customerBilling';
import invoicingApplication from './presetFormSettings/invoicingApplication';
import customer from './presetFormSettings/customer';
import customerPersonal from './presetFormSettings/customerPersonal';
import project from './presetFormSettings/project';
import task from './presetFormSettings/task';
import legwork from './presetFormSettings/legwork';
export * from './presetFormSettings/special-handling-type-Jxc';
const notPermission = false;
const appName = getUrlParam('appName');

// 只需写配置，方便可扩展
export const baseCommonSettings = {
  type: {
    title: '类型',
    type: 'string',
    hidden: '{{true}}',
  },
  widget: {
    title: '组件',
    type: 'string',
    hidden: '{{true}}',
  },
  format: {
    title: '格式',
    type: 'string',
    hidden: '{{true}}',
  },
};

// 一期仅实现通用属性中的【基本属性：标题、提示文字、必填】
export const defaultCommonSettings = {
  title: {
    title: '标题',
    type: 'string',
    description: '最多100字',
    required: true,
    max: 100,
    order: 1,
    widget: 'input',
    // disabled: true
  },
  placeholder: {
    title: '提示文字',
    type: 'string',
    description: '最多100字',
    placeholder: '请输入',
    default: '请输入',
    max: 100,
    order: 10,
  },
  required: {
    title: '必填',
    type: 'boolean',
    widget: 'switch',
    // default: true,
    order: 20,
    // disabled: true,
  },

  // // 基础公用属性（预留） （暂时无用）
  // bizAlias: {
  //   title: '字段别名（功能调试用）',
  //   type: 'string',
  //   default: '',
  //   hidden: `{{${notPermission}}}`,
  //   placeholder: '请输入',
  //   disabled: true,
  // },

  // 隐藏扩展字段 （暂时无用）
  // bizType: {
  //   title: '',
  //   type: 'string',
  //   default: '',
  //   hidden: `{{true}}`,
  //   placeholder: '请输入',
  // },
};

// widget 用于指定 schema 右侧配置对应的 setting
export const elements = [
  {
    text: '单行输入框',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    schema: {
      title: '单行输入框',
      type: 'string',
      widget: 'input',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      scan: {
        title: '扫码',
        type: 'boolean',
        widget: 'scan',
        order: 21,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      validation: {
        title: '数值格式',
        widget: 'validation',
        limitLength: false,
        min: null,
        max: null,
        formatAll: true, // true
        formatType: ['numberVal', 'chinese', 'symbolVal', 'space', 'english'], // 数字 'numberVal', 中文'chinese', 符号'symbolVal', 空格'space', 英文'english'
        type: 'object',
        allType: ['numberVal', 'chinese', 'symbolVal', 'space', 'english'],
        allTypeNames: ['数字', '中文', '符号', '空格', '英文'],
        className: 'my-validation',
      },
    },
  },
  {
    text: '多行输入框',
    name: 'textarea',
    icon: 'icon-duohangshurukuang',
    schema: {
      title: '多行输入框',
      type: 'string',
      format: 'textarea',
      widget: 'textarea',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '数字输入框',
    icon: 'icon-a-shuzishurukuang2x',
    name: 'number',
    schema: {
      title: '数字输入框',
      type: 'number',
      widget: 'number',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入数字',
        default: '请输入数字',
        max: 100,
        order: 10,
      },

      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 40,
      },

      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
    },
  },
  {
    text: '单选框',
    name: 'select',
    icon: 'icon-danxuankuang1',
    schema: {
      title: '单选',
      type: 'object',
      enum: ['option_0', 'option_1', 'option_2'],
      enumNames: ['选项1', '选项2', '选项3'],
      enumAssociatedFields: [[], [], []],
      enumPowerFields: [[], [], []],
      upperLevelNodes: [],
      widget: 'select',
      colorEnable: false,
      enumAssociatedFieldsColors: [['#FF6069'], ['#FF9622'], ['#FFBA21']],
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['选项1', '选项2', '选项3'],
        enumAssociatedFields: [[], [], []],
        enumPowerFields: [[], [], []],
        upperLevelNodes: [],
        widget: 'selectOptions',
        order: 12,
        colorEnable: false,
        selectType: 'select',
        enumAssociatedFieldsColors: [['#FF6069'], ['#FF9622'], ['#FFBA21']],
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '多选框',
    name: 'multiSelect',
    icon: 'icon-duoxuankuang',
    schema: {
      title: '多选框',
      type: 'array',
      items: {
        type: 'string',
      },
      enum: ['option_0', 'option_1', 'option_2'],
      enumNames: ['选项1', '选项2', '选项3'],
      enumAssociatedFields: [[], [], []],
      enumPowerFields: [[], [], []],
      upperLevelNodes: [],
      widget: 'multiSelect',
      colorEnable: false,
      enumAssociatedFieldsColors: [['#FF6069'], ['#FF9622'], ['#FFBA21']],
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        widget: 'selectOptions',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['选项1', '选项2', '选项3'],
        enumAssociatedFields: [[], [], []],
        enumPowerFields: [[], [], []],
        upperLevelNodes: [],
        order: 12,
        selectType: 'multiSelect',
        colorEnable: false,
        enumAssociatedFieldsColors: [['#FF6069'], ['#FF9622'], ['#FFBA21']],
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '日期',
    name: 'date',
    icon: 'icon-riqi',
    schema: {
      title: '日期',
      type: 'string',
      format: 'date',
      widget: 'date',
    },
    setting: {
      format: {
        title: '日期类型',
        type: 'string',
        enum: ['dateTime', 'date'],
        enumNames: ['年-月-日 时:分:秒', '年-月-日'],
        widget: 'baseSelect',
        order: 5,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'createTime', 'formula'],
        enumNames: ['自定义', '创建时间', '公式编辑'],
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum: ['default', 'hidden', 'readOnly'],
        className: 'my-radioName',
        enumNames: ['默认', '隐藏', '只读'],
        widget: 'fieldStatus',
        default: ['default'],
      },
    },
  },
  {
    text: '日期区间',
    name: 'dateRange',
    icon: 'icon-riqiqujian',
    schema: {
      title: '日期区间',
      type: 'range',
      format: 'dateTime',
      props: {
        placeholder: ['开始时间', '结束时间'],
      },
      widget: 'dateRange',
    },
    setting: {
      format: {
        title: '日期类型',
        type: 'string',
        widget: 'select',
        enum: ['dateTime', 'date'],
        enumNames: ['年-月-日 时:分:秒', '年-月-日'],
        widget: 'baseSelect',
        order: 5,
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      default: {
        title: '默认值',
        type: 'range',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom'],
        enumNames: ['自定义'],
      },
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum: ['default', 'hidden', 'readOnly'],
        className: 'my-radioName',
        enumNames: ['默认', '隐藏', '只读'],
        widget: 'fieldStatus',
        default: ['default'],
      },
    },
  },
  {
    text: '说明文字',
    name: 'textNote',
    icon: 'icon-shuoming',
    schema: {
      // title: '说明文字',
      type: 'string',
      widget: 'textNote',
    },
    setting: {
      info: {
        type: 'string',
        widget: 'alert',
        props: {
          message: '',
          description: '描述文字可以为表单添加段落说明信息，样式可以自定义设置',
          type: 'info',
          showIcon: true,
        },
      },
      content: {
        title: '类型',
        type: 'string',
        widget: 'textNoteEditor',
      },
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        type: 'string',
        hidden: '{{true}}',
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
    },
  },
  {
    text: '电话',
    name: 'phone',
    icon: 'icon-dianhua',
    schema: {
      title: '电话',
      type: 'object',
      widget: 'phone',
    },
    setting: {
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      showNumLocation: {
        title: '号码归属地',
        type: 'boolean',
        widget: 'showNumLocation',
        default: false,
        order: 40,
      },
      mode: {
        title: '类型',
        type: 'string',
        enum: ['phone', 'phone_tel', 'tel'],
        className: 'my-radioName',
        enumNames: ['仅手机', '手机和固话', '仅固话'],
        widget: 'radio',
        default: 'phone_tel',
        order: 15,
      },
      info: {
        type: 'string',
        widget: 'alert',
        props: {
          message: '',
          description: '将自动校验手机号码正确性，可在手机上直接点击回拨',
          type: 'info',
          showIcon: true,
        },
        order: 0,
      },
    },
  },
  {
    text: '身份证',
    name: 'idCard',
    icon: 'shenfenzheng',
    // from: 'CloudPlatform',
    schema: {
      title: '身份证',
      type: 'string',
      widget: 'idCard',
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        // order: 1,
        widget: 'input',
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        // order: 10,
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        // order: 20,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        // order: 20,
      },
    },
  },
  {
    text: '邮箱',
    name: 'email',
    icon: 'icon-email',
    from: 'All',
    schema: {
      title: '邮箱',
      type: 'string',
      widget: 'email',
    },
    setting: {
      title: {
        title: '标题',
        type: 'string',
        description: '最多100字',
        required: true,
        max: 100,
        order: 1,
        widget: 'input',
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        order: 20,
      },
    },
  },
];

export const enhanceElements = [
  {
    text: '图片',
    name: 'picture',
    icon: 'icon-tupian',
    schema: {
      title: '图片',
      type: 'array',
      widget: 'picture',
      items: {
        type: 'string',
      },
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
    },
  },
  {
    text: '金额',
    name: 'money',
    icon: 'icon-a-jine2x',
    schema: {
      title: '金额',
      type: 'number',
      widget: 'money',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
  },
  {
    text: '附件',
    name: 'attachment',
    icon: 'icon-fujian',
    schema: {
      title: '附件',
      type: 'array',
      widget: 'attachment',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
    },
  },
  {
    text: '成员',
    name: 'innerContact',
    icon: 'icon-wode',
    schema: {
      title: '组织成员',
      type: 'array',
      widget: 'innerContact',
      className: 'my-radioBtn',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      choice: {
        title: '选项',
        type: 'string',
        default: '0',
        enum: ['0', '1'],
        className: 'my-radioName',
        enumNames: ['只能选一人', '可同时选择多人'],
        widget: 'radio',
      },
      default: {
        title: '默认值',
        type: 'array',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom'],
        enumNames: ['自定义'],
      },
      fillObject: {
        title: '员工信息填充',
        widget: 'fillObject',
        type: 'array',
        default: [],
        enumWidgets: [
          {
            widgetName: '电话',
            widgetType: 'phone',
          },
          {
            widgetName: '邮箱',
            widgetType: 'email',
          },
        ],
        hidden: '{{formData.parentWidget === "table"}}',
      },
    },
  },
  {
    text: '部门',
    name: 'department',
    icon: 'icon-zuzhijiagou',
    schema: {
      title: '部门',
      type: 'array',
      widget: 'department',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        description: '勾选后可作为流程条件',
        order: 20,
      },
      multiple: {
        title: '选项',
        type: 'boolean',
        default: false,
        enum: [false, true],
        className: 'my-radioName',
        enumNames: ['只能选择一个部门', '可同时选择多个部门'],
        widget: 'radio',
      },
      default: {
        title: '默认值',
        type: 'array',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom'],
        enumNames: ['自定义'],
      },
    },
  },
  {
    text: '地址',
    name: 'address',
    icon: 'icon-weizhi',
    schema: {
      title: '地址',
      type: 'object',
      widget: 'address',
      // items: {
      //   type: 'object',
      //   properties: {},
      // },
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      needDetail: {
        title: '格式',
        type: 'number',
        default: 1,
        enum: [0, 1, 2],
        enumNames: ['省市', '省市区', '省市区-街道'],
        widget: 'radio',
        className: 'my-radioAddress',
      },
      default: {
        title: '默认值',
        type: 'object',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'numberLocation'],
        enumNames: ['自定义', '号码归属地'],
      },
    },
  },
  {
    text: '表格',
    name: 'table',
    icon: 'icon-liebiaoshitu',
    schema: {
      title: '表格',
      type: 'array',
      widget: 'table',
      widgetPrefix: 'table',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      actionName: {
        title: '动作名称',
        type: 'string',
        widget: 'input',
        default: '添加',
      },
      tableViewMode: {
        title: '填写方式',
        type: 'string',
        widget: 'tableMode',
        default: 'table',
        enum: ['table', 'list'],
        enumNames: ['表格模式', '列表模式'],
      },
      required: {
        title: '必填',
        type: 'boolean',
        hidden: '{{true}}',
      },
    },
  },
  {
    text: '流水号',
    name: 'seqNumber',
    icon: 'icon-zhaiyao',
    hiddenBlock: ['copy'],
    max: 1,
    schema: {
      title: '流水号',
      type: 'string',
      widget: 'seqNumber',
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        hidden: '{{true}}',
      },
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      generationRules: {
        title: '',
        type: 'array',
        items: {
          type: 'object',
          properties: {},
        },
        widget: 'generationRules',
        default: [
          {
            type: 'date',
            value: 'YYYYMMDD',
          },
          {
            type: 'counter',
            value: {
              start: 1,
              length: 8,
              reset: 'year',
            },
          },
          {
            type: 'common',
            value: '',
          },
          {
            type: 'field',
            value: '',
          },
        ],
      },
    },
  },
  {
    text: '评分',
    name: 'starRating',
    icon: 'tebieguanzhu',
    schema: {
      title: '评分',
      type: 'number',
      widget: 'starRating',
    },
    setting: {
      placeholder: {},
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      count: {
        title: '评分制',
        type: 'number',
        default: 5,
        enum: [5, 10],
        className: 'my-radioName',
        enumNames: ['5分制', '10分制'],
        widget: 'radio',
      },
    },
  },
  {
    text: '级联单选',
    name: 'cascade',
    icon: 'icon-line-jilian',
    from: 'All',
    schema: {
      title: '级联单选',
      type: 'object',
      widget: 'cascadeV2',
    },
    setting: {
      enumOptionId: {
        title: '选项',
        type: 'string',
        widget: 'selectTreeOptions',
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '级联多选',
    name: 'multiCascade',
    icon: 'icon-line-jilian',
    from: 'All',
    schema: {
      title: '级联多选',
      type: 'array',
      widget: 'multiCascade',
    },
    setting: {
      enumOptionId: {
        title: '选项',
        type: 'string',
        widget: 'selectTreeOptions',
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '手写签名',
    name: 'signature',
    icon: 'icon-fillin-line',
    schema: {
      title: '手写签名',
      type: 'object',
      widget: 'signature',
      extendOptions: {
        hiddenSettings: ['sample'],
      },
    },
    setting: {
      sample: {
        title: '控件示例',
        widget: 'signatureSample',
      },
      placeholder: {},
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      readFromLast: {
        title: '方式',
        type: 'boolean',
        widget: 'radio',
        default: true,
        enum: [true, false],
        enumNames: ['使用上次签名', '每次重新签名'],
      },
    },
  },
  {
    text: '关联审批单',
    name: 'processRelation',
    icon: 'icon-person',
    schema: {
      title: '关联审批单',
      type: 'array',
      widget: 'processRelation',
    },
    setting: {
      hidden: {
        title: '启用',
        type: 'boolean',
        widget: 'enable',
        default: false,
        order: 20,
      },
      availableProcessForms: {
        title: ' 指定关联审批单类型',
        type: 'array',
        widget: 'availableProcessForms',
        // order: 12,
      },
      placeholder: {},
    },
  },
];

export const advancedElements = [
  {
    text: '日期范围',
    name: 'dateRange',
    schema: {
      title: '日期范围',
      type: 'range',
      format: 'dateTime',
      props: {
        placeholder: ['开始时间', '结束时间'],
      },
    },
    setting: {
      format: {
        title: '类型',
        type: 'string',
        enum: ['dateTime', 'date'],
        enumNames: ['日期时间', '日期'],
      },
    },
  },
  {
    text: '数字（slider）',
    name: 'slider',
    schema: {
      title: '带滑动条',
      type: 'number',
      widget: 'slider',
    },
    setting: {},
  },
  {
    text: '图片展示',
    name: 'image',
    schema: {
      title: '图片展示',
      type: 'string',
      format: 'image',
    },
    setting: {},
  },
  {
    text: '颜色选择',
    name: 'color',
    schema: {
      title: '颜色选择',
      type: 'string',
      format: 'color',
    },
    setting: {},
  },
];

export const layouts = [
  {
    text: '对象',
    name: 'object',
    schema: {
      title: '对象',
      type: 'object',
      properties: {},
    },
    setting: {},
  },
  {
    text: '常规列表',
    name: 'list',
    schema: {
      title: '数组',
      type: 'array',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      items: {
        type: 'object',
        hidden: '{{true}}',
      },
      min: {
        title: '最小长度',
        type: 'number',
      },
      max: {
        title: '最大长度',
        type: 'number',
      },
      props: {
        title: '选项',
        type: 'object',
        properties: {
          foldable: {
            title: '是否可折叠',
            type: 'boolean',
          },
          hideDelete: {
            title: '隐藏删除按钮',
            type: 'string',
          },
          hideAdd: {
            title: '隐藏新增/复制按钮',
            type: 'string',
          },
        },
      },
    },
  },
  {
    text: '简单列表',
    name: 'list1',
    schema: {
      title: '数组',
      type: 'array',
      widget: 'list1',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      items: {
        type: 'object',
        hidden: '{{true}}',
      },
      min: {
        title: '最小长度',
        type: 'number',
      },
      max: {
        title: '最大长度',
        type: 'number',
      },
      props: {
        title: '选项',
        type: 'object',
        properties: {
          foldable: {
            title: '是否可折叠',
            type: 'boolean',
          },
          hideTitle: {
            title: '隐藏标题',
            type: 'boolean',
          },
          hideDelete: {
            title: '隐藏删除按钮',
            type: 'string',
          },
          hideAdd: {
            title: '隐藏新增/复制按钮',
            type: 'string',
          },
        },
      },
    },
  },
  {
    text: '表格列表',
    name: 'list2',
    schema: {
      title: '数组',
      type: 'array',
      widget: 'list2',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      items: {
        type: 'object',
        hidden: '{{true}}',
      },
      min: {
        title: '最小长度',
        type: 'number',
      },
      max: {
        title: '最大长度',
        type: 'number',
      },
      props: {
        title: '选项',
        type: 'object',
        properties: {
          foldable: {
            title: '是否可折叠',
            type: 'boolean',
          },
          hideDelete: {
            title: '隐藏删除按钮',
            type: 'string',
          },
          hideAdd: {
            title: '隐藏新增/复制按钮',
            type: 'string',
          },
        },
      },
    },
  },
  {
    text: '复杂表格列表',
    name: 'list3',
    schema: {
      title: '数组',
      type: 'array',
      widget: 'list3',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      items: {
        type: 'object',
        hidden: '{{true}}',
      },
      min: {
        title: '最小长度',
        type: 'number',
      },
      max: {
        title: '最大长度',
        type: 'number',
      },
      props: {
        title: '选项',
        type: 'object',
        properties: {
          foldable: {
            title: '是否可折叠',
            type: 'boolean',
          },
          hideDelete: {
            title: '隐藏删除按钮',
            type: 'string',
          },
          hideAdd: {
            title: '隐藏新增/复制按钮',
            type: 'string',
          },
        },
      },
    },
  },
];

// const saves = [
//   {
//     text: '复杂结构样例',
//     name: 'something',
//     schema: {
//       title: '对象',
//       description: '这是一个对象类型',
//       type: 'object',
//       properties: {
//         inputName: {
//           title: '简单输入框',
//           type: 'string',
//         },
//         selectName: {
//           title: '单选',
//           type: 'string',
//           enum: ['a', 'b', 'c'],
//           enumNames: ['早', '中', '晚'],
//         },
//         dateName: {
//           title: '时间选择',
//           type: 'string',
//           format: 'date',
//         },
//         listName: {
//           title: '对象数组',
//           description: '对象数组嵌套功能',
//           type: 'array',
//           items: {
//             type: 'object',
//             properties: {
//               rangeName: {
//                 title: '日期/时间范围',
//                 type: 'range',
//                 format: 'date',
//                 props: {
//                   placeholder: ['开始日期', '结束日期'],
//                 },
//               },
//             },
//           },
//         },
//       },
//     },
//   },
// ];

// 接口没数据，模拟了很多关联表单数据
export const relationElements = [
  {
    text: '请假',
    name: 'checkbox',
    schema: {
      title: '请假',
      type: 'string',
      widget: 'relation',
      fields: {},
    },
    setting: {
      min: {
        title: '最小长度',
        type: 'number',
      },
    },
  },
];

export const layoutsElements = [
  {
    text: '分割线',
    name: 'divider',
    icon: 'icon-fengexian-line',
    schema: {
      title: '分割线',
      type: 'string',
      widget: 'divider',
    },
    setting: {
      title: {
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 1,
      },
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        type: 'string',
        hidden: '{{true}}',
      },
      dividerType: {
        title: '线型',
        type: 'number',
        widget: 'selectDivider',
      },
    },
  },
];

export const systemElements = [
  {
    text: '负责人',
    name: 'principal',
    icon: 'icon-wode',
    description: '可查看、编辑当前表单',
    max: 1,
    hiddenBlock: ['copy'],
    schema: {
      title: '负责人',
      type: 'array',
      description: '负责人可以查看、编辑数据',
      widget: 'principal',
      className: 'my-radioBtn',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        hidden: '{{formData.valueBasisType !== "valueBasisCustom"}}',
      },
      valueBasisType: {
        // title: '取值依据',
        type: 'array',
        schemaName: 'valueBasisType',
        widget: 'principalDefaultValue',
        default: 'valueBasisCreator',
        placeholder: '请选择',
        enum: ['valueBasisCreator', 'valueBasisInherit', 'valueBasisCustom'],
        enumNames: ['创建人', '继承关联表单的负责人', '自定义'],
      },
      choice: {
        title: '选项',
        type: 'string',
        default: '0',
        enum: ['0', '1'],
        className: 'my-radioName',
        enumNames: ['只能选一人', '可同时选择多人'],
        widget: 'radio',
        hidden: '{{formData.valueBasisType !== "valueBasisCustom"}}',
      },
      default: {
        // title: '默认值',
        type: 'array',
        schemaName: 'default',
        widget: 'principalDefaultValue',
        placeholder: '请选择',
        enum: ['defaultCreator', 'defaultInherit', 'custom'],
        enumNames: ['创建人', '继承关联表单的负责人', '自定义'],
        hidden: '{{formData.valueBasisType !== "valueBasisCustom"}}',
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum:
          '{{formData.valueBasisType === "valueBasisCustom" ? ["default"] : ["hidden","readOnly"]}}',
        className: 'my-radioName',
        enumNames:
          '{{formData.valueBasisType === "valueBasisCustom" ? ["默认"] : ["隐藏","只读"]}}',
        widget: 'fieldStatus',
        default: ['readOnly'],
      },
      collaborativeManagement: {
        title: '负责人为空时，以下人员可管理该表单',
        type: 'object',
        widget: 'showUserOrDept',
        placeholder: '请选择',
        hidden:
          '{{!formData.valueBasisType || formData.valueBasisType === "valueBasisCreator"}}',
      },
      fillObject: {
        title: '员工信息填充',
        widget: 'fillObject',
        type: 'array',
        default: [],
        enumWidgets: [
          {
            widgetName: '电话',
            widgetType: 'phone',
          },
          {
            widgetName: '邮箱',
            widgetType: 'email',
          },
        ],
        hidden: '{{formData.parentWidget === "table"}}',
      },
    },
  },
  {
    text: '协同人',
    name: 'cooperator',
    icon: 'icon-wode',
    description: '可查看当前表单',
    max: 1,
    hiddenBlock: ['copy'],
    schema: {
      title: '协同人',
      type: 'array',
      description: '协同人仅可查看数据',
      widget: 'cooperator',
      className: 'my-radioBtn',
      items: {
        type: 'object',
        properties: {},
      },
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        hidden: '{{formData.valueBasisType !== "valueBasisCustom"}}',
      },
      valueBasisType: {
        // title: '取值依据',
        type: 'array',
        schemaName: 'valueBasisType',
        widget: 'cooperatorDefaultValue',
        default: 'valueBasisCreator',
        placeholder: '请选择',
        enum: ['valueBasisCreator', 'valueBasisInherit', 'valueBasisCustom'],
        enumNames: ['创建人', '继承关联表单的协同人', '自定义'],
      },
      choice: {
        title: '选项',
        type: 'string',
        default: '0',
        enum: ['0', '1'],
        className: 'my-radioName',
        enumNames: ['只能选一人', '可同时选择多人'],
        widget: 'radio',
        hidden: '{{formData.valueBasisType !== "valueBasisCustom"}}',
      },
      default: {
        // title: '默认值',
        type: 'array',
        schemaName: 'default',
        widget: 'cooperatorDefaultValue',
        placeholder: '请选择',
        enum: ['defaultCreator', 'defaultInherit', 'custom'],
        enumNames: ['创建人', '继承关联表单的协同人', '自定义'],
        hidden: '{{formData.valueBasisType !== "valueBasisCustom"}}',
      },
      fieldStatus: {
        // title: '字段状态',
        title: null,
        type: 'array',
        enum:
          '{{formData.valueBasisType === "valueBasisCustom" ? ["default"] : ["hidden","readOnly"]}}',
        className: 'my-radioName',
        enumNames:
          '{{formData.valueBasisType === "valueBasisCustom" ? ["默认"] : ["隐藏","只读"]}}',
        widget: 'fieldStatus',
        default: ['readOnly'],
      },
      fillObject: {
        title: '员工信息填充',
        widget: 'fillObject',
        type: 'array',
        default: [],
        enumWidgets: [
          {
            widgetName: '电话',
            widgetType: 'phone',
          },
          {
            widgetName: '邮箱',
            widgetType: 'email',
          },
        ],
        hidden: '{{formData.parentWidget === "table"}}',
      },
      permissions: {
        title: '权限设置',
        type: 'array',
        default: ['read'],
        enum: ['read', 'write'],
        className: 'my-radioName',
        enumNames: ['仅可查看数据', '可查看、编辑数据'],
        widget: 'fieldStatus',
      },
    },
  },
];

export const defaultSettings = [
  {
    title: '基础控件',
    widgets: elements,
    show: true,
    useCommon: true, // TODO: 是否将common
  },
  {
    title: '增强控件',
    widgets: enhanceElements,
    show: true,
    useCommon: true,
  },
  {
    title: '销售管理-7个预置表单-商机',
    widgets: business,
    show: false,
    useCommon: true,
  },
  {
    title: '销售管理-7个预置表单-合同',
    widgets: contract,
    show: false,
    useCommon: true,
  },
  {
    title: '销售管理-7个预置表单-回款',
    widgets: returnMoney,
    show: false,
    useCommon: true,
  },
  {
    title: '销售管理-7个预置表单-订单',
    widgets: order,
    show: false,
    useCommon: true,
  },
  {
    title: '销售管理-7个预置表单-产品',
    widgets: product,
    show: false,
    useCommon: true,
  },
  {
    title: '销售管理-7个预置表单-开票申请',
    widgets: invoicingApplication,
    show: false,
    useCommon: true,
  },
  {
    title: '销售管理-7个预置表单-客户开票信息',
    widgets: customerBilling,
    show: false,
    useCommon: true,
  },
  // {
  //   title: '高级组件',
  //   widgets: advancedElements,
  // },
  // {
  //   title: '布局组件',
  //   widgets: layouts,
  // },
  // {
  //   title: '模板',
  //   widgets: saves,
  // },
  {
    title: '销售管理-7个预置表单-企业客户',
    widgets: customer,
    show: false,
    useCommon: true,
  },
  {
    title: '销售管理-7个预置表单-个人客户',
    widgets: customerPersonal,
    show: false,
    useCommon: true,
  },
  {
    title: '销售管理-7个预置表单-联系人',
    widgets: contacts,
    show: false,
    useCommon: true,
  },
  {
    title: '项目',
    widgets: project,
    show: false,
    useCommon: true,
  },
  {
    title: '任务',
    widgets: task,
    show: false,
    useCommon: true,
  },
  {
    title: '高级外勤',
    widgets: legwork,
    show: false,
    useCommon: true,
  },
  {
    title: '布局组件',
    show: true,
    useCommon: true,
    widgets: layoutsElements,
  },
  {
    title: '系统控件',
    show: true,
    useCommon: true,
    widgets: systemElements,
  },
];

export const relationSettings = [
  {
    title: '关联表单',
    widgets: relationElements,
    show: true,
    useDefaultCommonSettings: false,
    useCommon: true, // TODO: 是否将common
  },
];

export const defaultGlobalSettings = {
  type: 'object',
  properties: {
    column: {
      title: '整体布局',
      type: 'number',
      enum: [1, 2, 3],
      enumNames: ['一行一列', '一行二列', '一行三列'],
      props: {
        placeholder: '默认一行一列',
      },
    },
    labelWidth: {
      title: '标签宽度',
      type: 'number',
      widget: 'slider',
      max: 300,
      default: 120,
      props: {
        hideNumber: true,
      },
    },
    displayType: {
      title: '标签展示模式',
      type: 'string',
      default: 'column',
      enum: ['row', 'column'],
      enumNames: ['同行', '单独一行'],
      widget: 'radio',
    },
  },
};

export const FormPropsSettings = {
  type: 'object',
  properties: {
    dataTitle: {
      title: null, // 数据标题, 因为要自定义标题样式，所以返回null
      type: 'object',
      widget: 'dataTitle',
      default: {
        propsKey: 'dataTitle',
        settingType: 0,
        propsValue: `#{createUserName}#{formTitle}`,
        effectiveRangeAfterChange: 0,
      },
    },
    dataSnippet: {
      title: null, // 数据标题, 因为要自定义标题样式，所以返回null
      type: 'object',
      widget: 'dataSnippet',

      default: {
        propsKey: 'dataSnippet',
        settingType: 0,
        propsValue: [
          // {
          //   id: 'gmtCreate',
          //   // title: '创建时间',
          // },
          // {
          //   id: 'createUserName',
          //   // title: '创建人',
          // },
        ],
      },
    },
    // labelWidth: {
    //   title: '标签宽度',
    //   type: 'number',
    //   widget: 'slider',
    //   max: 300,
    //   default: 120,
    //   props: {
    //     hideNumber: true,
    //   },
    // },
    // displayType: {
    //   title: '标签展示模式',
    //   type: 'string',
    //   default: 'column',
    //   enum: ['row', 'column'],
    //   enumNames: ['同行', '单独一行'],
    //   widget: 'radio',
    // },
  },
};

export const propsSettingsSummary = {
  dataAggregation: {
    title: null,
    type: 'object',
    widget: 'dataSummary',
    default: {
      propsKey: 'dataAggregation',
      settingType: 1,
      propsValue: {
        rangeType: 0,
        fields: [],
      },
    },
  },
  // dataSummary: {
  //   title: null,
  //   type: 'object',
  //   widget: 'dataSummary',
  //   default: {
  //     propsKey: 'dataAggregation',
  //     settingType: 1,
  //     propsValue:[]
  //   },
  // },
};

export const propsSettingsDataCard = {
  dataCard: {
    title: null,
    type: 'object',
    widget: 'dataCard',
    default: {
      propsKey: 'dataCard',
      settingType: 1,
      propsValue: {
        fields: [],
      },
    },
  },
};

export const propsSettings = {};
