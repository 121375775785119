import { FC, useState } from 'react';
import { WidgetProps } from 'typings';
import IconFont from '@/components/IconFont';
// import FilterModal from './FilterModal';
import c from '../../index.less';
import FilterModal from '../../FilterModal';

export const customerTitleMap = new Map([
  ['crm_customer', '企业客户'],
  ['crm_customer_personal', '个人客户'],
]);

const BaseDataFilters: FC<WidgetProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const { addons } = props;
  const { getValues, setValueByPath } = addons;
  const formData = getValues();
  const { association = {} } = formData;

  const handleSchema = (values) => {
    const filtersPath = `association.filters`;
    const formCodePath = `association.relationFormCode`;
    const defaultFieldIdPath = `association.defaultFieldId`;
    const enumPath = `association.enum`;
    const enumNamesPath = `association.enumNames`;
    setValueByPath(filtersPath, values.list || []);
    setValueByPath(formCodePath, values.relationFormCode || '');
    setValueByPath(defaultFieldIdPath, values.defaultFieldId || '');
    setValueByPath(enumPath, values.enum);
    setValueByPath(enumNamesPath, values.enumNames);
  };

  const handleShowModal = (visible: boolean) => {
    setVisible(visible);
  };

  return (
    <div className={c.container}>
      <div
        className={c.field}
        onClick={() => {
          handleShowModal(true);
        }}
      >
        <div className={c['field-text']}>
          {association?.filters?.length ? '已关联设置' : '关联设置'}
        </div>
      </div>
      <FilterModal
        visible={visible}
        onCancel={() => {
          handleShowModal(false);
        }}
        association={association}
        onChange={(values) => {
          handleShowModal(false);
          handleSchema(values);
        }}
      />
    </div>
  );
};

export default BaseDataFilters;
