/*
 * :file description: component Header
 * :name: \low-code-platform\src\layouts\components\NormalHeader\index.tsx
 * :author: ershing
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-11 16:17:07
 * :last editor: hyw
 * :date last edited: 2022-02-23 10:44:40
 */
import { history, useLocation, useModel } from 'umi';
import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { userLoginOut } from '@/services/user';

import c from './index.less';
import { useRef } from 'react';
import IconFont from '@/components/IconFont';
import SystemMessage from '@/components/SystemMessage';

const { confirm } = Modal;

export default function NormalHeader(props: {}) {
  const location = useLocation();
  const messageRef = useRef();

  const { clearPermission } = useModel('usePermission', (model) => ({
    clearPermission: model.clearPermission,
  }));
  const authority = window.localStorage.getItem('authority');
  const isBack = location.pathname.indexOf('/back') > -1;

  // 打开消息弹窗
  const onMessage = () => {
    (messageRef?.current as any).onMessage();
  };

  const goBackstage = () => {
    if (isBack) {
      history.push('/todoMessage');
    } else {
      if (window.__isMicroApp__) {
        history.push('/back/org');
      } else {
        window.open('/back/org');
      }
    }
  };

  const loginOut = () => {
    confirm({
      title: '您确定要退出账号吗?',
      okText: '确定',
      cancelText: '取消',
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        try {
          const res = await userLoginOut();
          if (res.code === 200) {
            message.success('退出账号成功');
            window.localStorage.removeItem('token');
            clearPermission();
            history.push('/login');
          }
        } catch (e) {
          message.error('退出账号失败');
        }
      },
      onCancel() {},
    });
  };

  return (
    <div className={c.header}>
      <section className={c.left}>
        <span className={c.icon} />
        <span className={c.title}>
          {isBack ? '管理后台' : '探迹低代码平台'}
        </span>
      </section>
      <section className={c.right}>
        <div className={c.component_container} onClick={onMessage}>
          <IconFont className={c.icon} type="icon-biaodantongzhi"></IconFont>
          <span className={c.exit}>消息</span>
        </div>
        {authority === 'true' ? (
          <div className={c.component_container} onClick={goBackstage}>
            <IconFont
              className={c.icon}
              type={isBack ? 'icon-shouye' : 'icon-diannao'}
            ></IconFont>

            <span className={c.manage}>{isBack ? '返回首页' : '管理后台'}</span>
          </div>
        ) : null}
        <div className={c.component_container} onClick={loginOut}>
          <IconFont className={c.icon} type="icon-tuichu"></IconFont>
          <span className={c.exit}>退出账户</span>
        </div>
      </section>
      <SystemMessage ref={messageRef} />
    </div>
  );
}
