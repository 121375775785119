/*
 * :file description:
 * :name: /low-code-platform/tg-renderer/packages/form-render/src/widgets/DefaultValueSelector/components/formula-editor/Formula.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-03 15:37:03
 * :last editor: hyw
 * :date last edited: 2024-03-12 19:12:06
 */

import React, { useContext, useMemo, useState } from 'react';
import { Card, Row, Col, Collapse } from 'antd';
import classNames from 'classnames';
import formulaUsage, { formulaUsageVersion23 } from './common/formulaUsage';
import { EditorContext } from '.';
import { insertFormulaitem } from './common/formulaEditor';
import CardTitle, { IResult } from './CardTitle';
import c from '../../index.less';
import { CaretRightOutlined } from '@ant-design/icons';
import { getUuid } from '@/utils';
import { useLocation, useModel } from 'umi';
const { Panel } = Collapse;

export interface IUsage {
  name: string;
  intro: string;
  usage: string;
  example: string;
}

export const formulaNotInCRM = {
  NOW: true,
};
export const formulaNotInERP = {
  NOW: true,
};

const Formula = () => {
  const location = useLocation();
  const { source } = location?.query || {};

  const { versionLimit } = useModel('useGenerator', (model) => model);
  const [usage, setUsage] = useState<IUsage>();
  const [isSearch, setIsSearch] = useState(false);
  const [result, setResult] = useState<IResult[]>();
  const { editorInstance } = useContext(EditorContext);

  const filterFormulaBySource = (arr) => {
    let arrCopy = arr.slice(0);
    arrCopy?.forEach((item) => {
      item.contains = item.contains?.filter((j) => {
        if (source === 'CloudPlatform') {
          return !formulaNotInERP?.[j?.name];
        } else {
          return !formulaNotInCRM?.[j?.name];
        }
      });
    });
    return arrCopy;
  };

  const handleClick = (usage: IUsage) => {
    setTimeout(() => {
      insertFormulaitem(usage.name, editorInstance);
    });
  };

  const handleMouse = (usage: IUsage) => {
    setUsage(usage);
  };

  const combinaFormulaUsage = useMemo(() => {
    if (versionLimit?.formProperties?.includes('timeFunction')) {
      return [
        ...formulaUsage,
        ...filterFormulaBySource(formulaUsageVersion23),
      ].sort((a, b) => a.order - b.order);
    }
    return formulaUsage;
  }, [versionLimit]);

  const handleChange = (e: any) => {
    const { value } = e.target;
    const result: IResult[] = [];
    setIsSearch(value.length > 0);
    combinaFormulaUsage?.forEach((child) => {
      child.contains?.forEach((item) => {
        if (item.name.startsWith(value)) {
          const regx = new RegExp(`^${value}`);
          result.push({
            key: value,
            other: item.name.replace(regx, ''),
            item,
          });
        }
      });
    });
    setResult(result);
  };

  const usageHtml = (name = '', content = '') => {
    return content
      .replace(name, `<span class="formula-name"> ${name} </span>`)
      .replace(/\{(.*?)\}/g, ` <span class="formula-field">$1</span> `);
  };

  return (
    <div className={classNames(c.formula_container, c.item_container)}>
      <div className={c.title}>公式</div>
      <Row gutter={10}>
        <Col className={c.field_list_wrap} flex="252px">
          <Card
            size="small"
            title={<CardTitle title="搜索函数" onChange={handleChange} />}
          >
            {!isSearch && (
              <Collapse
                accordion
                onChange={(e) => {
                  setTimeout(() => {
                    const panelDom = document.querySelector(
                      '.ant-collapse-item-active',
                    );
                    panelDom?.scrollIntoView({ behavior: 'smooth' });
                  }, 200);
                }}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                className={c.list_wrap}
                ghost
              >
                {combinaFormulaUsage.map((child) => (
                  <Panel header={child.category} key={child.category}>
                    <ul className={c.list}>
                      {child.contains.map((item) => (
                        <li
                          key={getUuid()}
                          className={classNames(
                            c.list_item,
                            item.name === usage?.name && c.select_item,
                          )}
                          onMouseOver={() => handleMouse(item)}
                          onMouseLeave={() => handleMouse(item)}
                          onClick={() => handleClick(item)}
                        >
                          {item.name}
                        </li>
                      ))}
                    </ul>
                  </Panel>
                ))}
              </Collapse>
            )}
            <ul className={c.list}>
              {isSearch &&
                result?.map((child) => (
                  <li
                    key={child.item.name}
                    onMouseOver={() => handleMouse(child.item)}
                    onMouseLeave={() => handleMouse(child.item)}
                    onClick={() => handleClick(child.item)}
                    className={classNames(
                      c.list_item,
                      child.item.name === usage?.name && c.select_item,
                    )}
                  >
                    <span style={{ color: 'red' }} className="key">
                      {child.key}
                    </span>
                    <span className="other">{child.other}</span>
                  </li>
                ))}
            </ul>
          </Card>
        </Col>
        <Col flex="1">
          <Card className={c.usage} size="small">
            <div
              className={c.usage_item}
              dangerouslySetInnerHTML={{
                __html: usageHtml(usage?.name, usage?.intro),
              }}
            ></div>
            <div
              className={c.usage_item}
              dangerouslySetInnerHTML={{
                __html: usageHtml(usage?.name, usage?.usage),
              }}
            ></div>
            <div
              className={c.usage_item}
              dangerouslySetInnerHTML={{
                __html: usageHtml(usage?.name, usage?.example),
              }}
            ></div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Formula;
