/*
 * @Description:
 * @FilePath: \low-code-platform\tg-renderer\packages\form-render\src\components\IconFont\index.tsx
 * author: 段文君
 * copyright: (c) 2022, Tungee
 * @Date: 2022-01-04 19:54:25
 * @LastEditors: 段文君
 * @LastEditTime: 2022-01-06 17:27:04
 */
import { createFromIconfontCN } from '@ant-design/icons';

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2877883_pqemg0n2xr.js', // 在 iconfont.cn 上生成
});

export default IconFont;
