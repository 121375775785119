/*
 * @Author: wu.shangru roywu@tungee.com
 * @Date: 2023-04-23 19:00:08
 * @LastEditors: wu.shangru roywu@tungee.com
 * @LastEditTime: 2023-10-16 10:07:29
 * @FilePath: \low-code-platform\src\components\renderer\PersonSelector\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * :file description:
 * :name: \low-code-platform\src\components\renderer\PersonSelector\index.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-11-03 21:24:03
 * :last editor: hyw
 * :date last edited: 2022-02-23 16:53:14
 */

import React, { useState, useRef } from 'react';
import { Input } from 'antd';
import MultipleChoice from '@/components/MultipleChoice';
import c from './index.less';

const TPersonSelector = (props: { [key: string]: any }) => {
  const [selected, setSelected] = useState();
  const [value, setValue] = useState([]);
  const { onChange, tabs, id } = props;
  const childRef = useRef(null);

  const maxLength = 20;

  // 限制人员选择器选择类型
  let supportedTabs = [];
  if (id === 'createUserId' || id.includes('innerContact')) {
    supportedTabs = ['人员'];
  } else if (id.includes('department_')) {
    supportedTabs = ['部门'];
  } else {
    supportedTabs = ['角色', '人员', '部门'];
  }

  const getDataBack = (data: any) => {
    console.log('人啊： ', data);
    const departments = data?.departments?.map((item) => `${item.id}`);
    const roles = data?.roles?.map((item) => `role_${item.id}`);
    const users = data?.users?.map((item) => item.userId);
    const selectedData = [...departments, ...roles, ...users];
    setSelected(data);
    setValue(
      Object.keys(data).reduce((acc, cur) => {
        return acc.concat(data[cur].map((v) => v.name));
      }, []),
    );
    onChange && onChange(selectedData);
  };

  return (
    <div className={c.t_person_selector}>
      <Input
        maxLength={maxLength}
        value={
          value.join('、')?.length > maxLength
            ? value.join('、') + '...'
            : value.join('、')
        }
        onClick={() => (childRef?.current as any)?.openModal()}
        allowClear
        onChange={(e) => {
          setValue([]);
          setSelected(undefined);
          onChange && onChange(undefined);
        }}
      />

      <MultipleChoice
        cRef={childRef}
        initData={selected}
        getDataBack={getDataBack}
        tabs={supportedTabs}
        title="请选择"
      />
    </div>
  );
};

export default TPersonSelector;
