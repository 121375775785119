import React from 'react';
import { Tag, Tooltip } from 'antd';
import { isArray, isString, split } from 'lodash-es';
import { getDateTime, getDate } from './utils.js';

const PrompText = ({ text = '', prompText = '' }) => (
  <Tooltip title={prompText || text}>
    <span>{text}</span>
  </Tooltip>
);

export default {
  tags: (tags) => (
    <div>
      {tags.map((tag) => {
        return (
          <Tag color="volcano" key={tag}>
            {tag.toUpperCase()}
          </Tag>
        );
      })}
    </div>
  ),
  dateTime: (value) => getDateTime(value),
  date: (value) => getDate(value),
  tooltip: (text) => <PrompText text={text} />,
  status: (value) => {
    switch (value.status) {
      case 'Default':
        return <Tag color="blue">{value.text}</Tag>;
      case 'Error':
        return <Tag color="red">{value.text}</Tag>;
      case 'Success':
        return <Tag color="green">{value.text}</Tag>;
      default:
        return <Tag color="blue">{value.text}</Tag>;
    }
  },
};

// Search自定义组件render
export const BUILD_IN_SEARCH_WIDGETS = {};

// Table自定义组件render
export const BUILD_IN_TABLE_WIDGETS = {
  // 关联表单
  relation: (value: any, column: any) => (
    <Tag
      style={{
        padding: '4px 8px',
        cursor: 'pointer',
      }}
      onClick={(e) => {
        console.log(e);
      }}
    >
      {value}
    </Tag>
  ),
  // 图片
  picture: (value: any, column: any) => {
    if (!value) return null;
    const imgSrcs = isArray(value)
      ? value
      : isString(value)
      ? split(value, ',')
      : [];
    return (
      <>
        {imgSrcs.map((img) => (
          <img
            key={img}
            width="30"
            height="30"
            style={{
              borderRadius: '4px',
              marginRight: '5px',
              cursor: 'pointer',
            }}
            src={img}
          />
        ))}
      </>
    );
  },
};
