/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\utils.ts
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-22 15:26:36
 * :last editor: 黄艺
 * :date last edited: 2022-03-20 14:36:32
 */
import { Modal } from 'antd';
import moment from 'moment';
import { omit } from 'lodash-es';
import formApi from './services/api';
import { IObj } from 'typings';
import { IValue } from './widgets/DataTitle';
import { FormInstance } from 'form-render';
import isNil from 'lodash/isNil';
import { CONTRACT_ORDER_BIZ_TYPE, notFormField } from './constant';

// 是否是 JSON 字符串
export const isJSON = (str: string) => {
  if (typeof str == 'string') {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }
  return false;
};

export const transferToMoment = (value) => {
  return value ? (moment.isMoment(value) ? value : moment(value)) : undefined;
};

export const getFormCode = () => {
  const formCode = window.location.pathname.match(/form\/(.*)/)?.[1];
  return formCode;
};

export const dropSearchTypeProp = (obj: object, bool: boolean) => {
  return bool ? omit(obj, ['searchType']) : obj;
};

const doubleCheck = ({
  title = '提示',
  content,
  okText = '确定',
  cancelText = '取消',
  onOk = () => {},
  onCancel = (close) => {
    close();
  },
}) => {
  Modal.confirm({
    title,
    content,
    okText,
    cancelText,
    onOk: onOk,
    onCancel: onCancel,
  });
};

const defaultFormat = 'YYYY-MM-DD';
const formatDate = (t) => {
  return t
    ? moment.isMoment(t)
      ? t.format(defaultFormat)
      : moment(t).format(defaultFormat)
    : undefined;
};

const getFieldsAccess = async (payload) => {
  const nodeInfoRes = await formApi.viewProcessDiagram(payload);
  const fieldsPromise = await formApi.getFormConfig({
    nodeId: payload?.nodeId ?? nodeInfoRes?.data?.currentNodeId,
    processId: payload?.processId,
    formInstanceId: payload?.formInstanceId,
  });
  return fieldsPromise;
};

export const customJP = (data: string) => {
  if (!data || typeof data !== 'string') {
    return data;
  }
  try {
    return JSON.parse(data);
  } catch (e) {
    console.log(e);
    return data;
  }
};

export const customJS = (data: any) => {
  try {
    if (isNil(data)) {
      return undefined;
    }
    return JSON.stringify(data);
  } catch (e) {
    console.log(e);
    return data;
  }
};

export const getArray = (arr, defaultValue = []) => {
  if (Array.isArray(arr)) return arr;
  return defaultValue;
};

export const addrDecorator = (content) => {
  if (content?.properties) {
    Object.values(content.properties)?.forEach((item) => {
      if (item.widget == 'address') {
        item['rules'] = [
          {
            asyncValidator: async (rule, value) => {
              if (item.needDetail === 0) {
                // 省市
              } else if (item.needDetail === 1) {
                // 省市区
              } else if (item.needDetail === 2) {
                // 省市区 街道
                if (!value?.street && item?.required) {
                  throw new Error('请选择街道');
                }
              }
            },
          },
        ];
      }
    });
    return content;
  }
};
export const defineTitle = (
  formData: typeof IObj,
  propsSettings: IValue[],
  form: FormInstance,
  ...extendParmas: any
) => {
  const userInfoStr: string = window.localStorage.getItem('userInfo') || '';
  const { name } = customJP(userInfoStr) || {};
  const copyFormData = { ...formData };
  copyFormData.createUserName = name;
  copyFormData.gmtCreate = extendParmas?.[0];
  copyFormData.formTitle = extendParmas?.[1];
  const titleSetting = propsSettings.find(
    (item) => item.propsKey === 'dataTitle',
  );
  return titleSetting?.propsValue.replace(
    /#{(.*?)}/g,
    (_match: any, p1: string) => {
      let schema: { widget?: string } = {};
      if (!notFormField.includes(p1)) {
        schema = form.getSchemaByPath(p1);
      }
      let value = '';
      switch (schema?.widget) {
        case 'relation':
          value = copyFormData[p1]?.title || '';
          break;
        case 'select':
          value = copyFormData[p1]?.value || '';
          break;
        default:
          value = copyFormData[p1] || '';
          break;
      }
      return value;
    },
  );
};

export const toolNumber = (num_str: string) => {
  num_str = num_str?.toString();
  if (num_str.indexOf('+') != -1) {
    num_str = num_str.replace('+', '');
  }
  if (num_str.indexOf('E') != -1 || num_str.indexOf('e') != -1) {
    var resValue = '',
      power = '',
      result = null,
      dotIndex = 0,
      resArr: string[] = [],
      sym = '';
    var numStr = num_str.toString();
    if (numStr[0] == '-') {
      // 如果为负数，转成正数处理，先去掉‘-’号，并保存‘-’.
      numStr = numStr.substr(1);
      sym = '-';
    }
    if (numStr.indexOf('E') != -1 || numStr.indexOf('e') != -1) {
      var regExp = new RegExp(
        '^(((\\d+.?\\d+)|(\\d+))[Ee]{1}((-(\\d+))|(\\d+)))$',
        'ig',
      );
      result = regExp.exec(numStr);
      if (result != null) {
        resValue = result[2];
        power = result[5];
        result = null;
      }
      if (!resValue && !power) {
        return false;
      }
      dotIndex = resValue.indexOf('.') == -1 ? 0 : resValue.indexOf('.');
      resValue = resValue.replace('.', '');
      resArr = resValue.split('');
      if (Number(power) >= 0) {
        var subres = resValue.substr(dotIndex);
        power = Number(power);
        //幂数大于小数点后面的数字位数时，后面加0
        for (var i = 0; i <= power - subres.length; i++) {
          resArr.push('0');
        }
        if (power - subres.length < 0) {
          resArr.splice(dotIndex + power, 0, '.');
        }
      } else {
        power = power.replace('-', '');
        power = Number(power);
        //幂数大于等于 小数点的index位置, 前面加0
        for (var i = 0; i < power - dotIndex; i++) {
          resArr.unshift('0');
        }
        var n = power - dotIndex >= 0 ? 1 : -(power - dotIndex);
        resArr.splice(n, 0, '.');
      }
    }
    resValue = resArr.join('');

    return sym + resValue;
  } else {
    return num_str;
  }
};
export const toNumberStr = (num, digits) => {
  // 正则匹配小数科学记数法
  if (/^(\d+(?:\.\d+)?)(e)([\-]?\d+)$/.test(num)) {
    // 正则匹配小数点最末尾的0
    var temp = /^(\d{1,}(?:,\d{3})*\.(?:0*[1-9]+)?)(0*)?$/.exec(
      num.toFixed(digits),
    );
    if (temp) {
      return temp[1];
    } else {
      return num.toFixed(digits);
    }
  } else {
    return '' + num;
  }
};

// 获取表单下的按钮权限
export const getBtnAuth = (formCode, btnKey) => {
  // 判断当前表单中是否有激活流程权限
  const allAuthList =
    localStorage.getItem('allAuthList') ||
    sessionStorage.getItem(`tungee_permission_${window.__corpId}`) ||
    '[]';
  const authList = JSON.parse(allAuthList);
  let remainData = authList.filter((val) =>
    val.authorityCode.includes(formCode),
  );
  let currentAuth = remainData.some((val) => {
    return val.bizCode == btnKey;
  });
  return currentAuth;
};

export default {
  doubleCheck,
  getFormCode,
  formatDate,
  getFieldsAccess,
  getBtnAuth,
};

type DateType = 'date' | 'dateTime';
// 日期格式化
const DEFAULT_FORMAT = 'YYYY-MM-DD';
const DEFAULT_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const transformDateToString = (value: any, formatType: DateType) => {
  const format =
    formatType === 'dateTime' ? DEFAULT_DATETIME_FORMAT : DEFAULT_FORMAT;
  if (!value) {
    return value;
  }
  value = moment.isMoment(value) ? value : moment(value);
  return value.format(format);
};

export const transformDateToMoment = (value: any) => {
  if (!value) return value;
  return moment.isMoment(value) ? value : moment(value);
};

// 对“合同订单二选一”套件压平
export const flattenSchema = (schema: Record<string, any> = {}) => {
  const propertiesList = Object.entries(schema.properties || {});
  const flattenParentItem = propertiesList.find(
    (item) => item[1]?.bizType === CONTRACT_ORDER_BIZ_TYPE,
  );
  const flattenParentIndex = propertiesList.findIndex(
    (item) => item[1]?.bizType === CONTRACT_ORDER_BIZ_TYPE,
  );
  const [, flattenParent] = flattenParentItem || [];
  Object.entries(flattenParent?.properties || {})?.forEach((item, index) => {
    return propertiesList.splice(flattenParentIndex + index, 0, item);
  });
  const schemaProperties: Record<string, any> = {};
  propertiesList?.forEach((item) => {
    const [key, value] = item;
    schemaProperties[key] = value;
  });
  schema.properties = schemaProperties;
};

export const transformSchema = (schema: Record<string, any>) => {
  flattenSchema(schema);
  return schema;
};
