/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\TUpload\index.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-11-06 11:38:10
 * :last editor: hyw
 * :date last edited: 2022-02-08 14:11:58
 */

import { useState, useRef, useEffect } from 'react';
import {
  Upload,
  UploadProps,
  Modal,
  Button,
  message,
  Typography,
  Progress,
} from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import formApi from '@/services/form';
import defaultGraph from '../../assets/form/image.png';
import c from './index.less';
import './index.less';
import { getFileIcon } from './utils';
import IconFont from '@/components/IconFont';

const MAX_COUNT = 9;

const DEFAULT_UPLOAD_CONFIG: UploadProps = {
  accept: 'image/*',
  multiple: true,
  maxCount: MAX_COUNT,
  listType: 'picture',
};

const TUpload = (props: UploadProps & { [key: string]: any }) => {
  const uploadRef = useRef();
  const btnRef = useRef();
  const { onChange = () => {}, value = [] } = props;
  const maxCount = DEFAULT_UPLOAD_CONFIG.maxCount || props.maxCount;
  const [visible, setVisible] = useState(false);
  const [attemptCount, setAttemptCount] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [modalKey, setModalKey] = useState<string>();
  const [uploadIngList, setUploadIngList] = useState([]);

  const customRequest = (e) => {
    const file = e.file;
    const name = file.name;
    let formdata = new FormData();
    formdata.append('file', e.file);
    formdata.append('keyPrefix', 'crm-foundation-form/instance/');
    formApi.uploadImages(formdata).then(
      (res) => {
        e.onSuccess(
          {
            name,
            url: res?.data,
          },
          file,
        );
        getSuccessCount();
      },
      (res) => {
        e.onError(res);
      },
    );
  };

  const handleBeforeUpload = (file: UploadFile, fileList: UploadFile[]) => {
    const isLt20M = file.size / 1024 / 1024 <= 20;
    if (!isLt20M) {
      message.warning('图片大小不能超过20M!');
      return false;
    }
    setVisible(true);
    setAttemptCount(fileList?.length);
    setUploadIngList(fileList || []);
    return true;
  };

  const handleOk = () => {
    onChange(
      value.concat(
        uploadRef?.current?.fileList
          ?.filter((v) => v.status === 'done')
          ?.map((v) => v.response.url) || [],
        value.length ? [] : value,
      ),
    );
    setVisible(false);
    console.log('uploadRef ', uploadRef);
  };

  const getSuccessCount = () => {
    setSuccessCount(
      uploadRef?.current?.fileList?.filter((v) => v.status === 'done')
        ?.length ?? 0,
    );
  };

  const deleteImg = (img) => {
    onChange(_.uniq([...value]).filter((v) => v !== img));
  };

  useEffect(() => {
    setAttemptCount(0);
    setSuccessCount(0);
    if (!visible) {
      setModalKey(nanoid(12));
    }
  }, [visible]);

  const itemRender = () => {
    const fileList = uploadRef?.current?.fileList?.length
      ? uploadRef?.current?.fileList
      : uploadIngList;
    let arr: any[] = [];
    fileList &&
      fileList.map((val) => {
        const fileName = val.name;
        const fileType = fileName.split('.').pop().toLowerCase();
        const fileIcon = getFileIcon(fileType);
        const percent = val.percent;
        const status = val.status; // "done" "uploading" "error"
        let satatusRender = () => {
          if (status == 'done') {
            return <div className={c['upload-item-status']}>上传完成</div>;
          } else if (status == 'error') {
            return (
              <div className={`${c['upload-item-status']} ${c.error}`}>
                {file?.error?.message || '上传失败'}
              </div>
            );
          }

          return (
            <div>
              <Progress percent={percent} showInfo={false} />
            </div>
          );
        };
        arr.push(
          <div className={c['upload-item']}>
            <img src={fileIcon} className={c.icon} />
            <div className={c['upload-item-content']}>
              <div className={c['upload-item-tilte']}>{fileName}</div>
              {satatusRender()}
            </div>
          </div>,
        );
      });
    return arr;
  };
  return (
    <div className={c.t_image}>
      {!props.readOnly && (
        <span className={c.boot_text}>
          <Typography.Link
            disabled={value?.length >= MAX_COUNT || value.length >= MAX_COUNT}
            onClick={() => {
              // setVisible(true);
              _.delay(() => {
                btnRef?.current?.click();
              }, 0);
            }}
          >
            添加图片
          </Typography.Link>
          （最多选择
          {maxCount}张）
        </span>
      )}

      <div className={c.thumbnail}>
        {!visible &&
          value?.map((item, idx) => {
            return (
              <div key={idx}>
                {!props.readOnly && (
                  <span onClick={() => deleteImg(item)}>×</span>
                )}

                <img src={item || defaultGraph} width="48" height="48" />
              </div>
            );
          })}
      </div>
      <Upload
        ref={uploadRef}
        key={modalKey}
        {...DEFAULT_UPLOAD_CONFIG}
        maxCount={
          !value.length
            ? maxCount - (value?.length ?? 0)
            : maxCount - value.length
        }
        style={{ display: 'none', visibility: 'hidden' }}
        customRequest={customRequest}
        beforeUpload={handleBeforeUpload}
        showUploadList={false}
      >
        <Button ref={btnRef}></Button>
      </Upload>

      <Modal
        title="上传图片"
        className="upload_images"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button type="primary" onClick={handleOk}>
            确定
          </Button>,
        ]}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <div style={{ marginBottom: '10px' }}>
          本次选择{attemptCount}个文件，可以发送
          {successCount}个
        </div>
        <div className={c.upload}>{itemRender()}</div>
        {/* {visible ? (
          <Upload
            ref={uploadRef}
            {...DEFAULT_UPLOAD_CONFIG}
            maxCount={
              !value.length
                ? maxCount - (value?.length ?? 0)
                : maxCount - value.length
            }
            customRequest={customRequest}
            beforeUpload={handleBeforeUpload}
          >
            <Button ref={btnRef}></Button>
          </Upload>
        ) : null} */}
      </Modal>
    </div>
  );
};

export default TUpload;
