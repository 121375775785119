/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\TextNote\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-11-24 11:14:07
 * :last editor: 黄艺
 * :date last edited: 2022-02-09 15:34:29
 */

import { FC } from 'react';
import { WidgetProps } from 'typings';
import c from './index.less';

const TextNote: FC<WidgetProps> = (props) => {
  const { readOnly, value, schema = {} } = props;
  //暂时无法验证
  // if (readOnly) {
  //   return <span className='showNoEditText'>{value?.value || ''}</span>
  // }
  return (
    <div className={c.container}>
      {schema?.content ? (
        <div
          className={c.text_note}
          dangerouslySetInnerHTML={{ __html: schema?.content }}
        ></div>
      ) : (
        <div className={c.no_data}>请输入说明文字</div>
      )}
    </div>
  );
};

export default TextNote;
