import { postRequest, getRequest } from '@/common/http';
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';

interface IFUZZMATCHCOFING {
  corpId: string;
}

interface IUPDATEFUZZMATCHCOFING {
  corpId: string;
  matchNum: number;
  notMatchKeyWords: string;
}

// 获取模糊匹配配置
export async function fuzzyMatchConfig(data: IFUZZMATCHCOFING) {
  return getRequest(
    `${
      isOpenInTripartitePlatform('1688') ? DOMAIN_QRCODE_1688 : DOMAIN_QRCODE
    }/api/v1/management/fuzzyMatchConfig/find`,
    data,
  );
}

// 更新模糊匹配配置
export async function updateFuzzyMatchConfig(data: IUPDATEFUZZMATCHCOFING) {
  return postRequest(
    `${
      isOpenInTripartitePlatform('1688') ? DOMAIN_QRCODE_1688 : DOMAIN_QRCODE
    }/api/v1/management/fuzzyMatchConfig/update`,
    data,
  );
}
