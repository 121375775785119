/*
 * @Author: wu.shangru roywu@tungee.com
 * @Date: 2023-04-23 19:00:08
 * @LastEditors: wu.shangru roywu@tungee.com
 * @LastEditTime: 2023-09-14 16:19:25
 * @FilePath: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DataFill\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import IconFont from '@/components/IconFont';
import React, { useEffect, useMemo, useState } from 'react';
import Styles from './index.less';
import { Popover, Modal, Button, TreeSelect, Form } from 'antd';
import { customAlphabet } from 'nanoid';
import {
  ALPHABET,
  NANOID_LENGTH,
} from '@/components/FormGenerator/utils/constant';
import data_fill_intro from '@/assets/data_fill_intro.png';
import FillModal from './FillModal';
import FilterModal from '../DataFilters/FilterModal';
import { useGray } from '@/pages/generator/relation/hooks';

export const nanoid = customAlphabet(ALPHABET, NANOID_LENGTH);

const content: React.FC = () => {
  return (
    <div>
      <div className={Styles.contentTitle}>数字填充</div>
      <div className={Styles.contentDes}>
        将关联表单的字段的值填充到当前表单字段
      </div>
      <div className={Styles.contentDemoText}>
        示例
        <br />
        在创建【销售订单】时，选择关联一个【合同】，关联合同后，可以将合同中的客户名称自动填充到销售订单中
      </div>
      <div className={Styles.contentDemoImg}>
        <img src={data_fill_intro} alt="" />
      </div>
    </div>
  );
};

const DataFill: React.FC = (props: any) => {
  const { addons = {} } = props;
  const { subTableDataFillingRules } = addons?.formData ?? {};
  const [modalVisible, setModalVisible] = useState(false);
  const [index, setIndex] = useState(0);
  const [tableId, setTableId] = useState({});
  const [modalDataFilterVisible, setModalDataFilterVisible] = useState(false);
  
  // 自然之声表格填充支持三级结构
  const allowThreeLevelTable = useGray(
    'natur_stemme_allow_three_level_table',
  );

  const handleShowModal = (isShow: boolean) => {
    setModalDataFilterVisible(isShow);
  };

  const showDataFilter = (i, tableId) => {
    setIndex(i);
    setTableId(tableId);
    setModalDataFilterVisible(true);
  };

  return (
    <div className={Styles.wrapper}>
      <Popover
        placement="bottomLeft"
        arrowPointAtCenter
        overlayClassName={Styles.popover}
        content={content}
      >
        <div className={Styles.popoverIcon}>
          <IconFont type="icon-shuoming"></IconFont>
        </div>
      </Popover>
      <div
        className={Styles.btn}
        onClick={() => {
          setModalVisible(true);
        }}
      >
        {props?.value ? '已设置填充规则' : '设置填充规则'}
      </div>
      <FillModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        showDataFilter={showDataFilter}
        allowThreeLevelTable={allowThreeLevelTable}
        {...props}
      ></FillModal>
      <FilterModal
        visible={modalDataFilterVisible}
        tableId={tableId}
        onCancel={() => {
          handleShowModal(false);
        }}
        addons={addons}
        initValues={
          subTableDataFillingRules?.[index]?.dataSource?.params?.filters
        }
        onChange={(values) => {
          handleShowModal(false);
        }}
      ></FilterModal>
    </div>
  );
};

export default DataFill;
