import React, { useState, useEffect } from 'react';
import { InputNumber, Radio } from 'antd';

const InputFloat = (props) => {
  const { value, schema, onChange } = props;
  const { placeholder } = schema;

  const handleChange = (e) => {
    onChange(e);
  };

  return (
    <div>
      <Radio checked style={{ marginBottom: 8 }}>
        自定义
      </Radio>
      <InputNumber
        style={{ width: '100%' }}
        value={value}
        onChange={(e) => handleChange(e)}
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputFloat;
