/*
 * @Author: 刘昕 xinlx@tungee.com
 * @Date: 2022-10-18 16:19:40
 * @LastEditors: 刘昕 xinlx@tungee.com
 * @LastEditTime: 2022-11-21 11:33:28
 * @FilePath: \low-code-platform\src\pages\generator\relation\connector\Drawer\Components\antdCascader\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Cascader } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { schemaOptionsContext } from '../../index';
import _ from 'lodash';
interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

const placeHolderValueType = '请输入';
const placeHolderFieldType = '请配置字段和变量';
const placeHolderObjectArrayType = '请在下方配置字段';

const options1: Option[] = [
  {
    value: 'false',
    label: '当前表单提交后的数据',
    children: [
      {
        value: 'hangzhou',
        label: 'Hangzhou',
        children: [
          {
            value: 'xihu',
            label: 'West Lake',
          },
        ],
      },
    ],
  },
  {
    value: 'true',
    label: '系统默认字段',
    children: [
      {
        value: 'instanceId',
        label: '表单实例ID',
      },
      {
        value: 'processInstanceId',
        label: '流程实例ID',
      },
      {
        value: 'formCode',
        label: '表单唯一标识（formCode）',
      },
      {
        value: 'gmtCreate',
        label: '表单实例创建时间',
      },
      {
        value: 'gmtModified',
        label: '表单实例修改时间',
      },
      {
        value: ' createUserId',
        label: '表单创建人ID',
      },
      {
        value: 'updateUserId',
        label: '表单修改人ID',
      },
    ],
  },
];

const AntdCascader: React.FC = (props: any) => {
  const {
    value,
    onChange,
    // schemaJsonOptions,
    fieldType,
    elementType,
    disabled,
    parentFieldPath = [],
    parentArrayPath = [],
    parentFieldType = '',
    parentElementType = '',
    level = 1,
  } = props;
  const schemaInfo = useContext(schemaOptionsContext);
  const [data, setData] = useState<string[]>(value || []);
  const [options, setOptions] = useState([]);
  const [placeHolder, setPlaceHolder] = useState(placeHolderFieldType);
  useEffect(() => {
    // 处理value
    setData(value);
  }, [value]);

  const handleOnChange = (value: string[]) => {
    console.log(value);
    setData(data);
    // 处理onChange 返回
    onChange && onChange(value);
  };

  useEffect(() => {
    console.log('===================');
    console.log('fieldType', fieldType);
    console.log('elementType', elementType);
    console.log('parentFieldType', parentFieldType);
    console.log('parentElementType', parentElementType);
    console.log('schemaInfo', schemaInfo);
    console.log('parentFieldPath', parentFieldPath);
    console.log('parentArrayPath', parentArrayPath);
    console.log('===================');

    if (parentArrayPath && parentArrayPath.length > 1) {
      // 如果父级选择表格
      let newArray = _.cloneDeep(schemaInfo);
      newArray = newArray.filter((item) => item.value === 'false');
      newArray[0].children = newArray[0].children
        .filter((item: any) => {
          return item.value === parentArrayPath[parentArrayPath.length - 1]; // 'relation_LUeNFFxnNLcJ'
        })
        .map((item: any) => ({ ...item }));
      setOptions(newArray);
      setPlaceHolder(placeHolderFieldType);
    } else if (fieldType === 'array' && elementType === 'object') {
      // 对象数组
      let newArray = _.cloneDeep(schemaInfo);
      newArray = newArray.filter((item) => item.value === 'false');
      newArray[0].children = newArray[0].children
        .filter((item: any) => {
          return item.value?.includes('table'); // 'relation_LUeNFFxnNLcJ'
        })
        .map((item: any) => ({ ...item, children: [] }));
      setOptions(newArray);
      setPlaceHolder(placeHolderFieldType);
    } else if (
      fieldType === 'array' &&
      (elementType === 'string' || elementType === 'number')
    ) {
      //  字符串数组
      const recursionFileMap = (arr, parentWidget = '') => {
        return arr
          .map((item) => {
            // 过滤 多选
            if (item.value?.includes('table')) {
              item.children = item.children
                .filter((i) => {
                  return !i.value?.includes('multiSelect');
                })
                .filter((v) => v);
            }
            // table可以含有关联表单，关联表单不能含有table
            if (
              parentWidget?.includes('table') &&
              item.value?.includes('relation')
            ) {
              item.children = item.children
                .filter((i) => {
                  return !i.value?.includes('table');
                })
                .filter((v) => v);
            }
            if (Array.isArray(item.children) && item.children.length > 0) {
              item.children = recursionFileMap(
                item.children,
                item?.value || '',
              );
            }
            return item;
          })
          .filter((v) => v);
      };
      const newSchemaJsonOptions = recursionFileMap(
        _.cloneDeep(schemaInfo),
        '',
      );
      setOptions(newSchemaJsonOptions);
      setPlaceHolder(placeHolderFieldType);
    } else if (parentFieldType === 'array' && parentElementType === 'object') {
      // 如果 父级是 对象数组
      const recursionFileMap = (arr) => {
        return arr
          .map((item) => {
            // 过滤 子表格
            if (item.value?.includes('table')) {
              item.children = item.children
                .filter((i) => !i.value?.includes('table'))
                .filter((v) => v);
            }
            if (Array.isArray(item.children) && item.children.length > 0) {
              item.children = recursionFileMap(item.children);
            }
            return item;
          })
          .filter((v) => v);
      };
      const newSchemaJsonOptions = recursionFileMap(_.cloneDeep(schemaInfo));
      setOptions(newSchemaJsonOptions);
      setPlaceHolder(placeHolderFieldType);
    } else if (fieldType === 'number' || fieldType === 'string') {
      // 单一字段
      const recursionFileMap = (arr) => {
        return arr
          .map((item) => {
            if (
              item.value?.includes('table') ||
              item.value?.includes('multiSelect')
            ) {
              return null;
            }
            if (Array.isArray(item.children) && item.children.length > 0) {
              item.children = recursionFileMap(item.children);
            }
            return item;
          })
          .filter((v) => v);
      };
      const newSchemaJsonOptions = recursionFileMap(_.cloneDeep(schemaInfo));
      setOptions(newSchemaJsonOptions);
      setPlaceHolder(placeHolderFieldType);
    }
    // else if (parentFieldType === 'object') {
    //   //  如果父级是对象，子元素过滤
    //   const recursionFileMap = (arr) => {
    //     return arr
    //       .map((item: any) => {
    //         if (item.value.includes('table')) {
    //           return null;
    //         }
    //         if (Array.isArray(item.children) && item.children.length > 0) {
    //           item.children = recursionFileMap(item.children);
    //         }
    //         return item;
    //       })
    //       .filter((v) => v);
    //   };
    //   const newSchemaJsonOptions = recursionFileMap(_.cloneDeep(schemaInfo));
    //   setOptions(newSchemaJsonOptions);
    //   setPlaceHolder(placeHolderFieldType);
    // }
  }, [
    fieldType,
    elementType,
    parentFieldType,
    parentElementType,
    schemaInfo,
    parentFieldPath,
    parentArrayPath,
  ]);

  if (fieldType === 'object') {
    return <span>{placeHolderObjectArrayType}</span>;
  }

  if (
    fieldType === 'array' &&
    elementType === 'object' &&
    options?.length > 0
  ) {
    if (options[0]?.children?.length === 0) {
      return <span>{placeHolderObjectArrayType}</span>;
    }
  }

  return (
    <Cascader
      style={{ width: '100%' }}
      disabled={disabled}
      value={data}
      options={options}
      onChange={handleOnChange}
      placeholder={placeHolder}
    />
  );
};

export default AntdCascader;

// 对象数组 有点问题 子元素选项有问题
