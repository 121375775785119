/*
 * :file description:
 * :name: \low-code-platform\src\pages\form\components\ModalImport\ImportStep3\index.tsx
 * :author: PakJeon
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-01 17:15:08
 * :last editor: hyw
 * :date last edited: 2022-03-21 15:30:02
 */

import {
  useEffect,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { connect } from 'umi';
import { Progress, Button } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import type { Dispatch } from 'umi';
import type { GlobalModelState } from '@/models/global';
import { IMPORT_STATUS } from '@/utils/TransformNotification/transformNotification';
import IconFont from '@/components/IconFont';
import c from './index.less';
import { dowmLoadFile } from '@/utils';

interface CRef {
  loop: () => void;
}

type importStep3Props = {
  dispatch: Dispatch;
  global: GlobalModelState;
  fileData: any;
  onFail: () => void;
};

const ImportStep3 = forwardRef<CRef, importStep3Props>((props, ref) => {
  const { fileData, onFail, dispatch, global } = props;
  const [showFinish, setShowFinish] = useState(false);

  useImperativeHandle(ref, () => ({
    loop: () => {
      getTaskResult();
    },
  }));

  const getTaskResult = () => {
    // 当Modal框存在时，不用notification通知
    dispatch({ type: 'global/toggleNotification', payload: { toggle: false } });
    // 发起全局轮询
    dispatch({
      type: 'global/fetchImportStatus',
      payload: { taskId: fileData.taskId },
    });
  };

  const targetTask = useMemo(() => {
    const taskStatus = global.importTask.find(
      (item) => item.taskId === fileData.taskId,
    );
    if (taskStatus) {
      return taskStatus.data;
    }
    return {};
  }, [global]);

  useEffect(() => {
    if (
      targetTask &&
      [
        IMPORT_STATUS.PROGRESS,
        IMPORT_STATUS.SUCCESS,
        IMPORT_STATUS.FAIL,
      ].includes(targetTask.importStatus)
    ) {
      setTimeout(() => {
        setShowFinish(true);
      }, 1000);
    }
  }, [targetTask]);

  return (
    <div className={c.importStep3}>
      {showFinish ? (
        [IMPORT_STATUS.PROGRESS, IMPORT_STATUS.SUCCESS].includes(
          targetTask.importStatus,
        ) ? (
          <>
            <IconFont className={c.iconSuccess} type="icon-tongguo" />
            <div className={c.successTips}>
              导入完成
              {!!targetTask.importUpdateCount && (
                <div className={c.updateWrapper}>
                  ，共更新
                  <div className={c.updateCount}>
                    {targetTask.importUpdateCount}
                  </div>
                  条数据
                </div>
              )}
              {!!targetTask.importInsertCount && (
                <div className={c.insertWrapper}>
                  ，共新增
                  <div className={c.insertCount}>
                    {targetTask.importInsertCount}
                  </div>
                  条数据
                </div>
              )}
              {!!targetTask.importFailCount && (
                <div className={c.failWrapper}>
                  ，
                  <div className={c.failCount}>
                    {targetTask.importFailCount}
                  </div>
                  条导入失败
                </div>
              )}
            </div>
            {!!targetTask.importFailCount && targetTask.importFailFileUrl && (
              <>
                <div className={c.failTips}>
                  请下载失败文件查看原因，修改后重新导入
                </div>
                <div className={c.failBtnWrapper}>
                  <Button style={{ marginRight: 16 }} onClick={onFail}>
                    重新上传
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => dowmLoadFile(targetTask.importFailFileUrl)}
                  >
                    下载失败文件
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <CloseCircleFilled className={c.iconFail} />
            <div className={c.successTips}>
              「{targetTask.formTitle}」导入失败，请重试
            </div>
            <>
              <div className={c.failTips}>
                请下载失败文件查看原因，修改后重新导入
              </div>
              <div className={c.failBtnWrapper}>
                <Button style={{ marginRight: 16 }} onClick={onFail}>
                  重新上传
                </Button>
              </div>
            </>
          </>
        )
      ) : (
        <div className={c.progressWrapper}>
          <Progress
            className={c.importProgress}
            percent={targetTask ? targetTask.progressRate : 0}
          />
          <div className={c.importTips}>
            正在导入数据，你可以先关闭窗口，导入成功后系统会提醒你
          </div>
        </div>
      )}
    </div>
  );
});

export default connect((global: GlobalModelState) => global, null, null, {
  forwardRef: true,
})(ImportStep3);
