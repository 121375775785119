/*
 * :file description:
 * :name: \low-code-platform\src\pages\generator\process\components\FlowV3\index.tsx
 * :author: Quan
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-09 18:14:43
 * :last editor: hyw
 * :date last edited: 2022-02-23 11:18:43
 */

import { useMemo, useEffect } from 'react';
import styles from './index.less';
import Generator from './components/Generator';
import { fromJS } from 'immutable';

interface IProp {
  flowData: any;
  handleNodeClick: (value: any) => void;
  handleNodeActiveStatus: (value: any) => void;
  setFlowData: (value: any) => void;
  extData?: any;
}

function Flow(props: IProp) {
  const {
    flowData,
    handleNodeClick,
    handleNodeActiveStatus,
    setFlowData,
    extData,
  } = props;

  useEffect(() => {
    setFlowData(flowData);
  }, []);

  const startNode = useMemo(() => {
    return flowData?.[0];
  }, [flowData]);

  const endNode = useMemo(() => {
    return flowData?.[flowData.length - 1];
  }, [flowData]);

  return (
    <div className={styles.flowWrapper}>
      <div className={styles.flowBox}>
        {startNode && (
          <Generator
            currentBranch={flowData}
            currentNode={startNode}
            currentIndex={0}
            endNodeId={endNode.id}
            flowData={fromJS(flowData)}
            setFlowData={setFlowData}
            handleNodeClick={handleNodeClick}
            handleNodeActiveStatus={handleNodeActiveStatus}
            extData={extData}
          ></Generator>
        )}
      </div>
    </div>
  );
}

export default Flow;
