/*
 * :file description:
 * :name: \low-code-platform\src\components\FormGenerator\settings\presetFormSettings\customerBilling.js
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-11 20:33:55
 * :last editor: Please set LastEditors
 * :date last edited: 2022-05-18 15:25:40
 */
export default [
  {
    text: '客户',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_1T4IDIW5DIXS0',
    extraText: '关联表单',
    schema: {
      title: '客户',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      bizAlias: 'invoice_info_customer',
      fields: {},
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
        },
      },
      relateSource: [
        {
          bizType: 'crm_customer',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'SWAPP-4C2F4B2B84BE49001EADD10DD19FADC6',
              bizType: 'crm_customer',
              formCode: 'PROC-FD2AC559-36B1-4A51-9E9F-0B0FC904D31C',
              appType: 1,
            },
          },
        },
        {
          bizType: 'crm_customer_personal',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'SWAPP-4C2F4B2B84BE49001EADD10DD19FADC6',
              bizType: 'crm_customer_personal',
              formCode: 'PROC-8CFF1FE5-EB3A-4C78-B069-169058E235EB',
              appType: 1,
            },
          },
        },
      ],
    },
    setting: {
      fields: {
        title: '关联字段',
        type: 'object',
        widget: 'relationSetting',
      },
      // sortFields: {
      //   title: '',
      //   widget: 'sortFields',
      //   type: 'array',
      //   items: {
      //     type: 'object',
      //     properties: {},
      //   },
      //   default: [
      //     {
      //       bizType: 'crm_customer_personal',
      //       fields: [],
      //     },
      //     {
      //       bizType: 'crm_customer',
      //       fields: [],
      //     },
      //   ],
      // },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      choice: {
        title: '关联模式',
        type: 'string',
        default: '0',
        enum: ['0', '1'],
        className: 'my-radioName',
        enumNames: ['引用模式', '拷贝模式'],
        widget: 'radio',
        hidden: '{{true}}',
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        title: '标题',
        type: 'string',
        // hidden: '{{true}}',
      },
      filters: {
        title: '数据过滤',
        type: 'array',
        widget: 'dataFilters',
      },
    },
  },
  {
    text: '线索',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_followRecordClue',
    extraText: '关联表单',
    schema: {
      title: '线索',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      bizAlias: 'relation_followRecordClue',
      fields: {},
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
          formCode: '',
        },
      },
      relateSource: [
        {
          bizType: 'clue_engine',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'TJAPP-MjAyMjA5MjMwMA==',
              bizType: 'clue_engine',
              formCode: 'xxxxx',
              appType: 1,
              status: 'PUBLISHED',
            },
          },
        },
      ],
    },
    setting: {
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        title: '标题',
        type: 'string',
        // hidden: '{{true}}',
      },
    },
  },
  {
    text: '发票抬头',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_1KFURAXTHB400',
    schema: {
      title: '发票抬头',
      type: 'string',
      widget: 'input',
      bizAlias: 'invoice_info_title',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '纳税识别号',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_1Q3L1OJUNU9S0',
    schema: {
      title: '纳税识别号',
      type: 'string',
      widget: 'input',
      bizAlias: 'invoice_info_taxpayer_identification_number',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '开户银行',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_20DLJW678C3K0',
    schema: {
      title: '开户银行',
      type: 'string',
      widget: 'input',
      required: true,
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '银行账号',
    icon: 'icon-a-shuzishurukuang2x',
    name: 'number',
    fieldId: 'number_1KBHKRA40L1C',
    schema: {
      title: '银行账号',
      type: 'number',
      widget: 'number',
      required: true,
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入数字',
        default: '请输入数字',
        max: 100,
        order: 10,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 40,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
    },
  },
  {
    text: '开票地址',
    name: 'textarea',
    icon: 'icon-duohangshurukuang',
    fieldId: 'textarea_MSLVIXFT7F40',
    schema: {
      title: '开票地址',
      type: 'string',
      format: 'textarea',
      widget: 'textarea',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '电话',
    name: 'phone',
    icon: 'icon-dianhua',
    fieldId: 'phone_EFCY9KL0OYO0',
    schema: {
      title: '电话',
      type: 'object',
      widget: 'phone',
    },
    setting: {
      mode: {
        title: '类型',
        type: 'string',
        enum: ['phone', 'phone_tel', 'tel'],
        className: 'my-radioName',
        enumNames: ['仅手机', '手机和固话', '仅固话'],
        widget: 'radio',
        default: 'phone_tel',
        order: 15,
      },
      info: {
        type: 'string',
        widget: 'alert',
        props: {
          message: '',
          description: '将自动校验手机号码正确性，可在手机上直接点击回拨',
          type: 'info',
          showIcon: true,
        },
        order: 0,
      },
      showNumLocation: {
        title: '号码归属地',
        type: 'boolean',
        widget: 'showNumLocation',
        default: false,
        order: 40,
      },
    },
  },
];
