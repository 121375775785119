/*
 * :file description:
 * :name: \crmd:\progree\low-code-platform\src\services\user\index.ts
 * :author: 宋海兵
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-26 15:30:19
 * :last editor: hyw
 * :date last edited: 2022-01-11 19:28:55
 */
import { postRequest, getRequest } from '@/common/http';

interface ILogin {
  password?: string;
  phone?: string;
}
// 登录
export async function login(data: ILogin) {
  return postRequest(`/api/user/login`, data);
}

// 菜单栏
export async function getMenu() {
  return getRequest(`/api/menu/menus-perms`);
}

// 退出登录
export async function userLoginOut() {
  return postRequest(`/api/user/logout`);
}

// 用户信息
export async function userSimpleInfo() {
  return getRequest(`/api/user/simple-info`);
}

// 消息
export async function sysMsgPage(data: any) {
  return postRequest(`/api/sys/msg/page`, data);
}

// 全部修改成已读
export async function updateRead(data: any) {
  return postRequest(`/api/sys/msg/update/read/status`, data);
}

// 更新阅读状态
export async function updateReadStatusBySysMsgId(data: any) {
  return postRequest(`/api/sys/msg/updateReadStatusBySysMsgId`, data);
}
