/*
 * :file description: 联系人
 * :name: \low-code-platform\src\components\FormGenerator\settings\presetFormSettings\contacts.js
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-12 14:39:57
 * :last editor: 黄艺
 * :date last edited: 2022-04-20 23:21:51
 */
export default [
  {
    text: '客户',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_K2U5O2WK',
    extraText: '关联表单',
    schema: {
      title: '客户',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      fields: {},
      bizAlias: 'contact_related_customer',
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
        },
      },
      relateSource: [
        {
          bizType: 'crm_customer',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'SWAPP-4C2F4B2B84BE49001EADD10DD19FADC6',
              bizType: 'crm_customer',
              formCode: 'PROC-FD2AC559-36B1-4A51-9E9F-0B0FC904D31C',
              appType: 1,
            },
          },
        },
        {
          bizType: 'crm_customer_personal',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'SWAPP-4C2F4B2B84BE49001EADD10DD19FADC6',
              bizType: 'crm_customer_personal',
              formCode: 'PROC-8CFF1FE5-EB3A-4C78-B069-169058E235EB',
              appType: 1,
            },
          },
        },
      ],
    },
    setting: {
      fields: {
        title: '关联字段',
        type: 'object',
        widget: 'relationSetting',
      },
      // sortFields: {
      //   title: '',
      //   widget: 'sortFields',
      //   type: 'array',
      //   items: {
      //     type: 'object',
      //     properties: {},
      //   },
      //   default: [
      //     {
      //       bizType: 'crm_customer_personal',
      //       fields: [],
      //     },
      //     {
      //       bizType: 'crm_customer',
      //       fields: [],
      //     },
      //   ],
      // },
      // required: {
      //   title: '必填',
      //   type: 'boolean',
      //   widget: 'switch',
      // },
      choice: {
        title: '关联模式',
        type: 'string',
        default: '0',
        enum: ['0', '1'],
        className: 'my-radioName',
        enumNames: ['引用模式', '拷贝模式'],
        widget: 'radio',
        hidden: '{{true}}',
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        title: '标题',
        type: 'string',
        hidden: '{{true}}',
      },
      filters: {
        title: '数据过滤',
        type: 'array',
        widget: 'dataFilters',
      },
    },
  },
  {
    text: '线索',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_followRecordClue',
    extraText: '关联表单',
    schema: {
      title: '线索',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      bizAlias: 'relation_followRecordClue',
      fields: {},
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
          formCode: '',
        },
      },
      relateSource: [
        {
          bizType: 'clue_engine',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'TJAPP-MjAyMjA5MjMwMA==',
              bizType: 'clue_engine',
              formCode: 'xxxxx',
              appType: 1,
              status: 'PUBLISHED',
            },
          },
        },
      ],
    },
    setting: {
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        title: '标题',
        type: 'string',
        // hidden: '{{true}}',
      },
    },
  },
  {
    text: '姓名',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_K2U5DHAA',
    schema: {
      title: '姓名',
      type: 'string',
      widget: 'input',
      bizAlias: 'customer_name',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        order: 20,
        default: true,
        disabled: true,
      },
    },
  },
  {
    text: '手机号',
    name: 'phone',
    icon: 'icon-dianhua',
    fieldId: 'phone_K2U5O2WJ',
    schema: {
      title: '手机号',
      type: 'object',
      widget: 'phone',
      bizAlias: 'contact_phone',
    },
    setting: {
      mode: {
        title: '类型',
        type: 'string',
        enum: ['phone', 'phone_tel', 'tel'],
        className: 'my-radioName',
        enumNames: ['仅手机', '手机和固话', '仅固话'],
        widget: 'radio',
        default: 'phone_tel',
        order: 15,
      },
      info: {
        type: 'string',
        widget: 'alert',
        props: {
          message: '',
          description: '将自动校验手机号码正确性，可在手机上直接点击回拨',
          type: 'info',
          showIcon: true,
        },
        order: 0,
      },
      showNumLocation: {
        title: '号码归属地',
        type: 'boolean',
        widget: 'showNumLocation',
        default: false,
        order: 40,
      },
    },
  },
  {
    text: '职位',
    name: 'multiSelect',
    icon: 'icon-duoxuankuang',
    fieldId: 'multiSelect_contactPosition',
    schema: {
      title: '职位',
      type: 'array',
      bizAlias: 'contact_position',
      items: {
        type: 'string',
      },
      enum: ['option_0', 'option_1', 'option_2'],
      enumNames: ['选项1', '选项2', '选项3'],
      widget: 'multiSelect',
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        widget: 'selectOptions',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['选项1', '选项2', '选项3'],
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '是否关键决策人',
    name: 'select',
    icon: 'icon-danxuankuang1',
    schema: {
      title: '是否关键决策人',
      type: 'object',
      enum: ['option_0', 'option_1'],
      enumNames: ['选项1', '选项2'],
      widget: 'select',
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1'],
        enumNames: ['选项1', '选项2'],
        widget: 'selectOptions',
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '邮箱',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_7YL2OBHEPPS0',
    schema: {
      title: '邮箱',
      type: 'string',
      widget: 'input',
      bizAlias: 'email',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '备注',
    name: 'textarea',
    icon: 'icon-duohangshurukuang',
    schema: {
      title: '备注',
      type: 'string',
      format: 'textarea',
      widget: 'textarea',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
];
