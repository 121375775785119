/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\Select\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-27 11:30:40
 * :last editor: hyw
 * :date last edited: 2022-03-02 16:18:31
 */
import { Select } from 'antd';
import { get } from 'lodash-es';
import { FC } from 'react';
import { WidgetProps } from 'typings';
import { IFormCompProps } from '../../typings';
import JumpLink from '../JumpLink';
import './index.less';

interface IItem {
  key: string;
  value?: string;
}

const { Option } = Select;

const SelectWidget: FC<WidgetProps<IItem>> = (props) => {
  const { value, onChange = () => {}, schema = {} } = props;
  const { placeholder } = schema;

  if (props.readOnly) {
    return <span className="showNoEditText">{props?.value?.value || ''}</span>;
  }
  // """select_jDQ90Y1f-Ztm"": {key:""option1"", value:""选项1""}"

  // if (props.schema.bizAlias) {
  //   return <JumpLink props={props} />
  // }
  return (
    <Select
      allowClear
      value={value?.key}
      style={{ width: '100%' }}
      placeholder={placeholder}
      onChange={(_value, option: IItem & { children?: string }) => {
        const { key, children } = option || {};
        onChange(key ? { key, value: children } : undefined);
      }}
    >
      {get(props, 'schema.enum', []).map((item: string, idx: number) => (
        <Option key={item} value={item}>
          {get(props, 'schema.enumNames', [])[idx]}
        </Option>
      ))}
    </Select>
  );
};

export default SelectWidget;
