/*
 * :file description: 个人客户
 * :name: \low-code-platform\src\components\FormGenerator\settings\presetFormSettings\customerPersonal.js
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-12 13:39:15
 * :last editor: hyw
 * :date last edited: 2022-04-20 11:14:33
 */
export default [
  {
    text: '客户姓名',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_K2U5DHAA',
    schema: {
      title: '客户姓名',
      type: 'string',
      print: true,
      widget: 'input',
      bizAlias: 'customer_name',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        order: 20,
        default: true,
        disabled: true,
      },
    },
  },
  {
    text: '地址',
    name: 'address',
    icon: 'icon-weizhi',
    fieldId: 'address_172MESB6GAF40',
    schema: {
      title: '地址',
      type: 'object',
      widget: 'address',
      print: true,
      bizAlias: 'address',
      // items: {
      //   type: 'object',
      //   properties: {},
      // },
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      needDetail: {
        title: '格式',
        type: 'number',
        default: 2,
        enum: [0, 1, 2],
        enumNames: ['省市', '省市区', '省市区-街道'],
        widget: 'radio',
        className: 'my-radioAddress',
      },
      default: {
        title: '默认值',
        type: 'object',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'numberLocation'],
        enumNames: ['自定义', '号码归属地'],
      },
    },
  },
  {
    text: '客户跟进状态',
    name: 'salesStage',
    fieldId: 'select_KI5S975E',
    icon: 'icon-shuoming',
    schema: {
      title: '客户跟进状态',
      type: 'string',
      widget: 'salesStage',
      bizAlias: 'customer_follow_up_status',
      print: true,
      enum: [
        'option_new_acquisition',
        'option_1',
        'option_KIBAJKEH',
        'option_KI5SP2LY',
        'option_done',
        'option_invalid',
      ],
      enumNames: ['新获取', '待跟进', '初步意向', '商机客户', '成交', '失效'],
      extension: [
        {
          option_new_acquisition: {
            editFreeze: true,
          },
        },
        {
          option_done: {
            editFreeze: true,
          },
        },
        {
          option_invalid: {
            editFreeze: true,
          },
        },
      ],
    },
    setting: {
      info: {
        type: 'string',
        widget: 'jumplink',
        props: {
          message: '',
          description:
            '当前控件是客户管理特殊组件，如需对其选项进行设置请点击下方链接',
          type: 'info',
          showIcon: true,
          src: 'https://www.baidu.com',
        },
      },
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        order: 20,
        default: true,
        disabled: true,
      },
    },
  },
  {
    text: '性别',
    name: 'select',
    icon: 'icon-danxuankuang1',
    fieldId: 'select_1NHFBP4MHJKW0',
    schema: {
      title: '性别',
      type: 'object',
      enum: ['option_0', 'option_1'],
      enumNames: ['男', '女'],
      widget: 'select',
      bizAlias: 'gender',
      print: true,
      required: false,
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1'],
        enumNames: ['男', '女'],
        widget: 'selectOptions',
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择性别',
        default: '请选择性别',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '生日',
    name: 'date',
    icon: 'icon-riqi',
    fieldId: 'date_229PWAO4XNB40',
    schema: {
      title: '生日',
      type: 'string',
      format: 'date',
      widget: 'date',
      bizAlias: 'birthday',
      unit: '天',
      print: true,
    },
    setting: {
      format: {
        title: '日期类型',
        type: 'string',
        enum: ['dateTime', 'date'],
        enumNames: ['年-月-日 时:分:秒', '年-月-日'],
        widget: 'baseSelect',
        order: 5,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '身份',
    name: 'select',
    icon: 'icon-danxuankuang1',
    fieldId: 'select_16KXQQBWKKG00',
    schema: {
      title: '身份',
      type: 'object',
      enum: ['option_0', 'option_1', 'option_2', 'option_8ZR3NRPFDRG0'],
      enumNames: ['学生', '家长', '职员', '其他'],
      widget: 'select',
      bizAlias: 'identity',
      print: true,
      required: false,
      spread: false,
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1', 'option_2', 'option_8ZR3NRPFDRG0'],
        enumNames: ['学生', '家长', '职员', '其他'],
        widget: 'selectOptions',
        order: 12,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '电话',
    name: 'phone',
    icon: 'icon-dianhua',
    fieldId: 'phone_22EW6VK59RGG0',
    schema: {
      title: '电话',
      type: 'object',
      widget: 'phone',
      bizAlias: 'customer_phone',
      required: true,
      print: true,
    },
    setting: {
      mode: {
        title: '类型',
        type: 'string',
        enum: ['phone', 'phone_tel', 'tel'],
        className: 'my-radioName',
        enumNames: ['仅手机', '手机和固话', '仅固话'],
        widget: 'radio',
        default: 'phone_tel',
        order: 15,
      },
      info: {
        type: 'string',
        widget: 'alert',
        props: {
          message: '',
          description: '将自动校验手机号码正确性，可在手机上直接点击回拨',
          type: 'info',
          showIcon: true,
        },
        order: 0,
      },
      showNumLocation: {
        title: '号码归属地',
        type: 'boolean',
        widget: 'showNumLocation',
        default: false,
        order: 40,
      },
    },
  },
  {
    text: '微信号',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_6206RHPQ6QO0',
    schema: {
      title: '微信号',
      type: 'string',
      widget: 'input',
      bizAlias: 'wechat',
      print: true,
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: 'QQ',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_1IUT3ES1MKG00',
    schema: {
      title: 'QQ',
      type: 'string',
      widget: 'input',
      bizAlias: 'qq',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '邮箱',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_7YL2OBHEPPS0',
    schema: {
      title: '邮箱',
      type: 'string',
      widget: 'input',
      bizAlias: 'email',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '备注',
    name: 'textarea',
    icon: 'icon-duohangshurukuang',
    schema: {
      title: '备注',
      type: 'string',
      format: 'textarea',
      widget: 'textarea',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
];
