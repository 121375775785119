import React, { useEffect, useState, useRef } from 'react';
import { Modal, Form, Input, Radio, message, Tooltip } from 'antd';
import IconFont from '@/components/IconFont';
import c from './ModalRule.less';
import { AddRulesTooltip } from '../components/Description';
import FormFormula from '../components/FormFormula';
import { getOriginalFormAssociationRule } from '@/services/extension-setting';
import { getUrlParam } from '@/utils';

interface IProps {
  visible: boolean;
  onClose: () => void;
  currentRule: IAssociationRules | undefined;
  onChange: (data: IAssociationRules) => Promise<any>;
}

const { TextArea } = Input;

const originalRuleCollect = new Map<number, IAssociationRules>();

export default (props: IProps) => {
  const {
    visible,
    onClose,
    currentRule = {} as IAssociationRules,
    onChange,
  } = props;
  const [form] = Form.useForm();
  const [originalRule, setOriginalRule] = useState<IAssociationRules>();
  const formCode = getUrlParam('formCode');
  const curStatus =
    typeof currentRule === 'object' && Object.keys(currentRule).length > 0;

  const hideModal = () => {
    onClose?.();
  };

  useEffect(() => {
    const insertRule: IFormulaValue = currentRule?.insertRule;
    if (insertRule) {
      insertRule.ruleTrigger = currentRule?.insertRuleTriggerType === 2;
    }
    const updateRule: IFormulaValue = currentRule?.updateRule;
    if (updateRule) {
      updateRule.ruleTrigger = currentRule?.updateRuleTriggerType === 2;
    }
    const deleteRule: IFormulaValue = currentRule?.deleteRule;
    if (deleteRule) {
      deleteRule.ruleTrigger = currentRule?.deleteRuleTriggerType === 2;
    }
    form.setFieldsValue({
      name: currentRule?.name,
      list: {
        updateRule: updateRule,
        deleteRule: currentRule?.deleteRule,
        insertRule: insertRule,
      },
      message: currentRule?.message,
    });
  }, [currentRule]);

  useEffect(() => {
    if (currentRule.ruleId && formCode) {
      if (originalRuleCollect.has(currentRule.ruleId)) {
        setOriginalRule(originalRuleCollect.get(currentRule.ruleId));
      } else {
        getOriginalFormAssociationRule(currentRule.ruleId, formCode).then(
          (res) => {
            const { data } = res ?? {};
            if (data.associationRule) {
              setOriginalRule(data.associationRule);
              originalRuleCollect.set(currentRule.ruleId, data.associationRule);
            }
          },
        );
      }
    }
  }, [currentRule]);

  const onFinish = async () => {
    await form.validateFields();
    const { list = {}, message: tipMessage, name } = form.getFieldsValue();
    const data: any = {
      message: tipMessage?.trim(),
      name: name?.trim(),
      ...list,
      ruleId: currentRule?.ruleId || new Date().getTime(),
    };
    if (data.insertRule) {
      if (data.insertRule?.ruleTrigger) {
        data.insertRuleTriggerType = 2;
      } else {
        data.insertRuleTriggerType = 1;
      }
      if (data.insertRule?.displayRule === '') {
        data.insertRule = null;
      } else {
        delete data.insertRule?.ruleTrigger;
      }
    }
    if (data?.updateRule) {
      if (data.updateRule?.ruleTrigger) {
        data.updateRuleTriggerType = 2;
      } else {
        data.updateRuleTriggerType = 1;
      }
      if (data.updateRule?.displayRule === '') {
        data.updateRule = null;
      } else {
        delete data.updateRule?.ruleTrigger;
      }
    }
    // 进销存定制
    if (data.deleteRule) {
      if (data.deleteRule?.ruleTrigger) {
        data.deleteRuleTriggerType = 2;
      } else {
        data.deleteRuleTriggerType = 1;
      }
      if (data.updateRule?.displayRule === '') {
        data.updateRule = null;
      } else {
        delete data.updateRule?.ruleTrigger;
      }
    }
    // if (data.deleteRule && data.deleteRule.displayRule === '') {
    //   data.deleteRule = null;
    // }
    await onChange(data);
    message.success('保存成功！');
    hideModal();
  };

  const validator = [
    {
      validator: (rule: any, value: string) => {
        if (!value?.trim()) {
          return Promise.reject('请输入内容');
        }
        return Promise.resolve(value);
      },
    },
  ];

  return (
    <Modal
      title={curStatus ? '编辑规则' : '添加规则'}
      visible={visible}
      onOk={onFinish}
      onCancel={hideModal}
      okText="确认"
      width={480}
      cancelText="取消"
      destroyOnClose
      className={c.modal}
      closeIcon={<IconFont type="icon-Close" />}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label="规则名称" name="name" required rules={validator}>
          <Input placeholder="请输入" maxLength={20} showCount />
        </Form.Item>
        <Form.List
          name="list"
          rules={[
            {
              validator: (rule, names, callback) => {
                console.log(names, 'zzz');
                if (
                  !names?.insertRule?.displayRule &&
                  !names?.updateRule?.displayRule &&
                  !names?.deleteRule?.displayRule
                ) {
                  return Promise.reject(new Error('请至少设置一条关联规则'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {(fields, {}, { errors }) => (
            <>
              <div className={c.form_title}>关联规则</div>
              <Form.Item label="新建表单数据" name="insertRule">
                <FormFormula
                  isCreate
                  isAdvancedFunctions={true}
                  originRule={originalRule?.insertRule}
                  form="relation"
                />
              </Form.Item>
              {/* <Form.Item label="新建后何时关联" name="insertRuleTriggerType">
                <Radio.Group>
                  <Radio value={1}>表单保存后</Radio>
                  <Radio value={2}>表单审批通过后</Radio>
                </Radio.Group>
              </Form.Item> */}
              <Form.Item label="编辑表单数据" name="updateRule">
                <FormFormula
                  isEdit={true}
                  isAdvancedFunctions={true}
                  originRule={originalRule?.updateRule}
                  form="relation"
                />
              </Form.Item>
              <Form.Item label="删除表单数据" name="deleteRule">
                <FormFormula
                  isDelete={true}
                  isAdvancedFunctions={true}
                  originRule={originalRule?.deleteRule}
                />
              </Form.Item>
              <Form.ErrorList errors={errors} />
            </>
          )}
        </Form.List>
        <Form.Item
          label={
            <span>
              上述规则运行失败时的提示文案
              <Tooltip
                placement="top"
                title={<AddRulesTooltip />}
                className={c.tooltip}
                overlayClassName={c.ant_tips}
                color="#fff"
              >
                <IconFont
                  type="icon-shuoming"
                  style={{ paddingLeft: '5px', cursor: 'pointer' }}
                />
              </Tooltip>
            </span>
          }
          name="message"
          required
          rules={validator}
        >
          <TextArea
            placeholder="请输入"
            style={{ height: 70 }}
            showCount
            maxLength={30}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
