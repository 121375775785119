import React from 'react';
import { Switch, Popover } from 'antd';
import style from './index.less';

const SignatureSample = () => {
  return (
    <>
      <Popover
        placement="bottomRight"
        trigger="hover"
        content={
          <div className={style.contentContainer}>
            <div className={style.tip}>填写者输入手写签名</div>
            <img
              className={style.img}
              src="https://cdn.tungee.com/warehouse/dingtalk/crm/lowcode/bg_sign.png"
              alt="手写签名"
            />
          </div>
        }
      >
        <span className={style.label}>控件示例</span>
      </Popover>
    </>
  );
};

export default SignatureSample;
