import { postRequest, getRequest } from '@/common/http';

// 查询工作sop-SOP开通状态
export async function getSopStatus(data: any) {
  return getRequest(
    `${ERP_DOMAIN}/api/v1/formserver-service/sop/node/query/status`,
    data,
  );
}

// 修改SOP开通状态
export async function updateSopStatus(data: any) {
  return getRequest(
    `${ERP_DOMAIN}/api/v1/formserver-service/sop/node/update/status`,
    data,
  );
}
