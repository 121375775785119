import {
  request,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from './http';

// 删除表单
function deleteForm(data: any) {
  return postRequest('/api/form/schema/deleteFormSchema', data);
}

// 获取所有搜索字段选项
function getAllField(data: any) {
  return postRequest('/api/formSearch/getAllField', data);
}

// 新增和修改常用筛选项字段
function updateFilterField(data: any) {
  return postRequest('/api/formSearch/addOrUpdateFrequentlyField', data);
}

// 关键词全局搜索
function queryFormDataList(data: any) {
  return postRequest('/api/formSearch/queryFormDataList', data);
}

// 查询表单列表数据
function getFormDataList(data: any) {
  return postRequest('/api/formSearch/queryFormDataList', data);
}

// 表单运行态-新建表单实例数据提交
function createFromInstance(data: any, config) {
  return postRequest('/api/formInstance/submitInstance', data, config);
}

// 表单运行态-详情编辑后保存
function editFromInstance(data: any, config) {
  return postRequest('/api/formInstance/resaveInstance', data, config);
}

// 表单运行态-根据实例id获取实例数据
function getFromInstanceDetail(data: any, config) {
  return postRequest('/api/formInstance/getDataByInstanceid', data, config);
}

// 待办消息-根据实例id获取实例数据
function getDataForTodoList(data: any, config) {
  return postRequest('/api/formInstance/getDataForTodoList', data, config);
}

// 表单运行态-单个-批量删除实例
function deleteFormBasedInstances(data: any, config) {
  return postRequest('/api/formInstance/deleteInstance', data, config);
}

// 撤销流程
function repealFlow(data: any) {
  return postRequest('/api/processTask/revokeTask', data);
}

// 退回流程
function returnFlow(data: any) {
  return postRequest('/api/processTask/returnTask', data);
}

// 审批同意
function approveAgree(data: any) {
  return postRequest('/api/processTask/agreeTask', data);
}

// 审批拒绝
function approveRefuse(data: any) {
  return postRequest('/api/processTask/refuseTask', data);
}

// 转交
function transferFlow(data: any) {
  return postRequest('/api/processTask/transferTask', data);
}

// 查看流程图
function viewProcessDiagram(data: any) {
  return postRequest('/api/processInstance/viewProcessDiagram', data);
}

// 判断是否有设置流程条件分支
function hasGateWay(data: any) {
  return getRequest(
    '/api/processDefinition/needDynamicViewProcessDiagram',
    data,
  );
}

// 新建表单 预测流程图走向
function expectProcessDiagram(data: any) {
  return postRequest('/api/processInstance/expectProcessDiagram', data);
}

// 获取节点表单配置
function getFormConfig(data: any) {
  return getRequest('/api/processDefinition/getFormConfig', data);
}

// 获取配置
function getGlobalSetting(data: any) {
  return getRequest('/api/processDefinition/getGlobalSetting', data);
}

// 表单运行态 - 根据实例id获取实例数据
function getDataByInstanceid(data: any, config) {
  return postRequest('/api/formInstance/getDataByInstanceid', data, config);
}

// 查询表单schema
function getFormSchema(data: any) {
  return postRequest('/api/form/schema/getFormSchema', data);
}

// 查询流程定义详情
function getProcessDefinitionInfo(data: any) {
  return getRequest('/api/processDefinition/getProcessDefinitionInfo', data);
}
// 查询表单schema
function getCurrentTaskByProcInsId(data: any) {
  return postRequest('/api/processTask/getCurrentTaskByProcInsId', data);
}

// 可退回的节点
function returnableNodes(data: any) {
  return getRequest('/api/processTask/returnableNodes', data);
}

// 查询流程节点信息列表(已过滤发起节点)
function getNodeList(data: any) {
  return getRequest('/api/processInstance/getActivateNodeList', data);
}

// 重新激活流程
function recreateProcessInstance(data: any) {
  return postRequest('/api/processInstance/recreateProcessInstance', data);
}

// 上传图片
function uploadImages(data: any) {
  return postRequest('/api/common/upload/uploadFile', data, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

// 获取所有显示列
function getVisibleField(data: any) {
  return postRequest('/api/formSearch/getVisibleField', data);
}

// 新增和修改显示列字段
function uploadColumns(data: any) {
  return postRequest('/api/formSearch/addOrUpdateVisibleField', data);
}

// 获取表单操作属性
function getFormSchemas(data: any) {
  return postRequest(`/api/form/schema/getFormSchema`, data);
}

// 获取表单操作属性
function getApprovalNodeProperty(data: any) {
  return postRequest(`/api/processInstance/getApprovalNodeProperty`, data);
}

export default {
  viewProcessDiagram,
  deleteForm,
  getAllField,
  updateFilterField,
  queryFormDataList,
  getFormDataList,
  createFromInstance,
  editFromInstance,
  getFromInstanceDetail,
  deleteFormBasedInstances,
  repealFlow,
  returnFlow,
  approveAgree,
  approveRefuse,
  transferFlow,
  getGlobalSetting,
  getDataByInstanceid,
  getFormSchema,
  getProcessDefinitionInfo,
  getCurrentTaskByProcInsId,
  returnableNodes,
  hasGateWay,
  expectProcessDiagram,
  getFormConfig,
  getNodeList,
  recreateProcessInstance,
  getDataForTodoList,
  uploadImages,
  getVisibleField,
  uploadColumns,
  getFormSchemas,
  getApprovalNodeProperty,
};
