import * as React from 'react';
import MentionEditor from './CommentEditor';
import cx from 'classnames';
import { findDOMNode } from 'react-dom';
import { Checkbox, Button } from 'antd';
import c from './index.less';

export interface IMentionUser {
  avatar: string;
  emplId: string;
  id: string;
  name: string;
}

interface IProps {
  // 提醒人发生改变
  onMention?: (users: IMentionUser[]) => void;
  // 文本发生改变
  onChange?: (content: string) => void;
  // 输入文本
  value?: string;
  // 当提交时候
  onSubmit?: () => void;
  // blur事件
  onBlur?: () => void;
  // 收起事件
  onFold?: () => void;

  action?: JSX.Element;
  // 类
  className?: string;
  // 占位符
  placeholder?: string;
  // 是否发送到聊天
  sendToChat: boolean;
  // 改变发送到聊天的状态
  onChangeSendToChat?: (sendToChat: boolean) => void;
  // 提到的用户
  mentionUsers?: IMentionUser[];
  // 按下cntrl + 回车
  onCtlEnter?: () => void;
}

interface IState {
  value?: string;
  showCommentAtToChat: boolean;
  commentToChat: boolean;
  // 输入框高度
  unfold: boolean;
  // 是否发送到聊天
  sendToChat: boolean;
  // at到的用户
  mentionUsers?: any[];
}

export default class CommentInput extends React.Component<IProps, IState> {
  public state: IState = {
    showCommentAtToChat: true,
    commentToChat: true,
    sendToChat: true,
    unfold: false,
  };

  private editorRef?: any;

  private blurEditorRef?: any;

  static getDerivedStateFromProps(nextProps: IProps) {
    return {
      sendToChat: nextProps.sendToChat,
      mentionUsers: nextProps.mentionUsers,
    };
  }

  /**
   * 清空文字内容
   */
  public clear = () => {
    this.editorRef && this.editorRef.clear();
  };

  /**
   * 聚焦到输入框
   */
  public focus = () => {
    this.editorRef && this.editorRef.focus();
  };

  public render() {
    const { value, className, placeholder, action } = this.props;
    const { sendToChat, mentionUsers } = this.state;
    const convs = this.getNonDuplicated(mentionUsers || []);
    return (
      <div className={cx(c['gapp-comment-input'], c.tDDComment, className)}>
        <div
          ref={(ref: any) => {
            this.blurEditorRef = ref;
          }}
          className={c['blur-editor']}
          onClick={this.fold}
        />
        <div className={c.editor_container}>
          <div className={c['input-container']} onClick={this.focus}>
            <MentionEditor
              ref={(ref: any) => {
                this.editorRef = ref;
              }}
              // needsclick是FastClick带来的坑！我因为很难focus排了好久的问题
              // https://github.com/ftlabs/fastclick/issues/582
              className={cx('mention-input', 'needsclick')}
              value={value}
              noAutoFocus
              triggerMention={this.triggerMention}
              onChange={this.onTextChange}
              onFocus={this.unfold}
              onBlur={this.onBlur}
              onCtlEnter={this.props.onCtlEnter}
              placeholder={placeholder ? placeholder : '请输入评论'}
            />
            <div className={c['comment-submit-button']}>
              <Button
                className={c['submit']}
                type="primary"
                onClick={() => {
                  const { onSubmit } = this.props;
                  onSubmit && onSubmit();
                }}
              >
                确定
              </Button>
            </div>
          </div>
          <div>@组织成员提醒其关注，默认提醒发起人</div>
        </div>
      </div>
    );
  }

  private changeSendToChat = () => {
    const { sendToChat } = this.state;
    const { onChangeSendToChat } = this.props;
    onChangeSendToChat && onChangeSendToChat(!sendToChat);
    this.setState({
      sendToChat: !sendToChat,
    });
  };

  private triggerMention = (users: IMentionUser[]) => {
    const { mentionUsers: oldMentions = [] } = this.state;
    const us = this.getNonDuplicated((oldMentions || []).concat(users));
    this.setState({
      mentionUsers: us,
    });
    const { onMention } = this.props;
    onMention && onMention(us);
  };

  private onTextChange = (content: string) => {
    const { onChange } = this.props;
    onChange && onChange(content);
  };

  private unfold = () => {
    if (this.blurEditorRef) {
      const node = findDOMNode(this.blurEditorRef) as HTMLElement;
      node.style.display = 'block';
    }
    if (this.editorRef) {
      const editorNode = findDOMNode(this.editorRef) as HTMLElement;
      let className = editorNode.className;
      className = className.replace(/unfold/g, '');
      className = className.replace(/fold/g, '');
      className += ' unfold';
      editorNode.className = className;
      editorNode.style.border = '1px solid rgba(0,137,255, 0.4)';
    }
  };

  private fold = () => {
    if (this.blurEditorRef) {
      const node = findDOMNode(this.blurEditorRef) as HTMLElement;
      node.style.display = 'none';
    }
    if (this.editorRef) {
      const editorNode = findDOMNode(this.editorRef) as HTMLElement;
      let className = editorNode.className;
      className = className.replace(/unfold/g, '');
      className = className.replace(/fold/g, '');
      className += 'fold';
      editorNode.className = className;
      editorNode.style.border = '1px solid #e2e4e5';
    }
    const { onFold } = this.props;
    onFold && onFold();
  };

  private onBlur = () => {
    if (this.editorRef) {
      const editorNode = findDOMNode(this.editorRef) as HTMLElement;
      editorNode.style.border = '1px solid #e2e4e5';
    }
  };

  private getNonDuplicated = (mentionUsers: any[]) => {
    const self = {
      // id: window.__user__.workNo,
      // emplId: window.__user__.workNo,
      // name: window.__user__.name,
      // avatar: window.__user__.personalPhoto,
    };
    const those = [...(mentionUsers || [])];
    const result: any[] = [self];
    those.forEach((user: any) => {
      const { emplId } = user;
      if (
        !result.find((aUser) => {
          return emplId === aUser.emplId;
        })
      ) {
        result.push(user);
      }
    });
    return result;
  };
}
