import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import {
  widgets as defaultWidgets,
  mapping as defaultMapping,
} from 'form-render';
import copyTOClipboard from 'copy-text-to-clipboard';
import {
  flattenSchema,
  idToSchema,
  combineSchema,
  dataToFlatten,
  flattenToData,
  newSchemaToOld,
  schemaToState,
} from './utils';
import { findAllElement } from './utils/element';
import { Ctx, StoreCtx } from './utils/context';
import { useSet } from './utils/hooks';
import { fromSetting, toSetting } from './transformer/form-render';
import list from './widgets/list';
import {
  getLegworkFieldRefs,
  getCascadeOptionList,
} from '@/services/form-generator';
import { getDuplicateFieldList } from '@/services/duplicate';
import { useLocation } from 'umi';
import { useGray } from '@/pages/generator/relation/hooks';

const DEFAULT_SCHEMA = {
  type: 'object',
  properties: {},
};

// TODO: formData 不存在的时候会报错：can't find # of undefined
function Provider(props, ref) {
  const {
    defaultValue,
    defaultFromPropsSetting,
    canDrag,
    canDelete,
    submit,
    transformer: _transformer,
    extraButtons,
    controlButtons,
    hideId,
    settings,
    commonSettings,
    globalSettings,
    widgets = {},
    mapping = {},
    bizType,
    children,
    cascaderOptionIds = [],
  } = props;

  // 灰度声博士的表格内部单选多选支持设置选项关联
  const voiceDoctorTableRelatedOption = useGray(
    'voice_doctor_table_related_option',
  );

  // 乐普数据填充
  const showRelationFillTableType = useGray(
    'show_relation_fill_table_type',
  );

  // 自然之声协同人支持编辑
  const allowCooperatorWrite = useGray(
    'allow_cooperator_write',
  );

  const transformer = {
    from: (schema) => schema,
    to: (schema) => schema,
    fromSetting,
    toSetting,
    ..._transformer,
  };

  const frwRef = ref || useRef();
  const frsRef = ref || useRef();
  const [state, setState] = useSet({
    formData: {},
    frProps: {}, // form-render 的全局 props 等
    frPropsSetting: [],
    isNewVersion: true, // 用schema字段，还是用propsSchema字段，这是一个问题
    preview: false, // preview = false 是编辑模式
    schema: {},
    selected: undefined, // 被选中的$id, 如果object/array的内部，以首字母0标识
    widgetType: 0,
    currentItem: {},
    relationSchema: [], // 关联表单schema
    lastFlatten: {},
    formMunberLimit: 0,
    legworkFields: [],
    duplicationRuleData: {},
    cascadesOptions: {}, //级联选项
    voiceDoctorTableRelatedOption: voiceDoctorTableRelatedOption,
    showRelationFillTableType: showRelationFillTableType,
    allowCooperatorWrite: allowCooperatorWrite
  });
  const [itemError, setItemError] = useState([]);
  const location = useLocation();
  const source = location?.query?.source;
  const formCode = location?.query?.formCode;
  // 收口点 propsSchema 到 schema 的转换 (一共3处，其他两个是 importSchema 和 setValue，在 FRWrapper 文件)
  useEffect(() => {
    const schema = defaultValue
      ? transformer.from(defaultValue)
      : DEFAULT_SCHEMA;
    if (schema) setState(schemaToState(schema));
    if (defaultFromPropsSetting) {
      setState({ frPropsSetting: defaultFromPropsSetting });
    }
  }, [defaultValue]);

  // /** 获取高级外勤表单字段被引用列表 */
  const queryLegworkField = async () => {
    const res = await getLegworkFieldRefs();
    const { code, result } = res;
    if (code === 200 && result.length > 0) {
      setState((state) => ({
        ...state,
        legworkFields: result,
      }));
    }
  };
  // /** 获取查重规则字段设置 */
  const getDuplicateFieldListData = async () => {
    getDuplicateFieldList({
      formCode,
    }).then((res) => {
      setState((state) => ({
        ...state,
        duplicationRuleData: res?.data,
      }));
    });
  };

  // /** 获取所有级联选项 */
  const getCascadesOptionsList = async () => {
    getCascadeOptionList({
      ids: cascaderOptionIds,
    }).then((res) => {
      const obj = res?.data?.reduce((pre, cur) => {
        pre[cur?.id] = cur;
        return pre;
      }, {});
      setState((state) => ({
        ...state,
        cascadesOptions: obj,
      }));
    });
  };

  useEffect(() => {
    // ERP 不需要走该接口获取数据
    source !== 'CloudPlatform' && source !== '1688' && queryLegworkField();
    source !== 'CloudPlatform' &&
      source !== '1688' &&
      formCode &&
      getDuplicateFieldListData();
  }, []);

  useEffect(() => {
    if (cascaderOptionIds?.length) {
      getCascadesOptionsList();
    }
  }, [cascaderOptionIds]);

  const {
    formData,
    frProps,
    frPropsSetting,
    isNewVersion,
    preview,
    schema,
    selected,
    widgetType,
    currentItem,
    lastFlatten,
    relationSchema,
    formMunberLimit,
    legworkFields,
    duplicationRuleData,
    cascadesOptions,
  } = state;

  const onChange = (data) => {
    setState({ formData: data });
    props.onChange && props.onChange(data);
  };

  const onSchemaChange = (newSchema) => {
    console.log('newSchema:', newSchema);
    setState({ schema: newSchema });
    if (props.onSchemaChange) {
      setTimeout(() => {
        if (!frwRef.current) return;
        const pureSchema = frwRef.current.getValue();
        props.onSchemaChange(pureSchema, frPropsSetting);
      }, 0);
    }
  };

  const _mapping = { ...defaultMapping, ...mapping };
  const _widgets = { ...defaultWidgets, ...widgets, list };

  const rootState = {
    preview,
    mapping: _mapping,
    widgets: _widgets,
    selected,
    widgetType,
    currentItem,
    relationSchema,
    lastFlatten,
    formMunberLimit,
    legworkFields,
  };

  const userProps = {
    canDrag,
    canDelete,
    submit,
    transformer,
    isNewVersion,
    extraButtons,
    controlButtons,
    hideId,
    settings,
    commonSettings,
    globalSettings,
  };

  let _schema = {};
  if (schema) {
    _schema = combineSchema(schema); // TODO: 要不要判断是否都是object
  }
  const flatten = flattenSchema(_schema);
  const flattenWithData = transformer.from(dataToFlatten(flatten, formData));

  const onFlattenChange = (newFlatten, changeSource = 'schema') => {
    const newSchema = idToSchema(newFlatten);
    const newData = flattenToData(newFlatten);
    // 判断只有schema变化时才调用，一般需求的用户不需要
    if (changeSource === 'schema') {
      onSchemaChange(newSchema);
    }
    // schema 变化大都会触发 data 变化
    onChange(newData);
  };

  const onItemChange = (key, value, changeSource) => {
    flattenWithData[key] = value;
    onFlattenChange(flattenWithData, changeSource);
  };

  const onFromPropsSettingChange = ({ formPropsSetting }) => {
    setState({ frPropsSetting: formPropsSetting });
    if (props.onSchemaChange) {
      setTimeout(() => {
        if (!frwRef.current) return;
        const pureSchema = frwRef.current.getValue();
        props.onSchemaChange(pureSchema, formPropsSetting);
      }, 0);
    }
  };

  let displaySchema = {};
  let displaySchemaString = '';
  try {
    const _schema = {
      ...idToSchema(flattenWithData, '#', true),
      ...frProps,
    };
    displaySchema = transformer.to(_schema);
    if (!isNewVersion) {
      displaySchema = newSchemaToOld(displaySchema);
    }
    displaySchemaString = JSON.stringify(displaySchema, null, 2);
  } catch (error) {}

  const getValue = () => displaySchema;

  const setValue = (value) => {
    try {
      setState((state) => ({
        ...state,
        selected: undefined,
        ...schemaToState(transformer.from(value)),
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const copyValue = () => {
    copyTOClipboard(displaySchemaString);
  };

  useImperativeHandle(frwRef, () => ({
    getValue,
    setValue,
    copyValue,
  }));

  // TODO: flatten是频繁在变的，应该和其他两个函数分开
  const store = {
    flatten: flattenWithData, // schema + formData = flattenWithData
    onFlattenChange, // onChange + onSchemaChange = onFlattenChange
    onItemChange, // onFlattenChange 里只改一个item的flatten，使用这个方法
    onSchemaChange,
    onChange,
    itemError,
    onItemErrorChange: setItemError,
    userProps,
    frProps,
    frPropsSetting,
    frsRef,
    displaySchema,
    displaySchemaString,
    onFromPropsSettingChange,
    formMunberLimit,
    duplicationRuleData,
    cascadesOptions, // 级联选项
    voiceDoctorTableRelatedOption,
    showRelationFillTableType,
    allowCooperatorWrite,
    formCode,
    ...rootState,
  };

  return (
    <DndProvider backend={HTML5Backend} context={window}>
      <ConfigProvider
        locale={zhCN}
        getPopupContainer={(node) => {
          return findAllElement(node);
        }}
      >
        <Ctx.Provider value={setState}>
          <StoreCtx.Provider value={store}>{children}</StoreCtx.Provider>
        </Ctx.Provider>
      </ConfigProvider>
    </DndProvider>
  );
}

export default forwardRef(Provider);
