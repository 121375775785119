import React, {
  useState,
  useImperativeHandle,
  useEffect,
  useMemo,
} from 'react';
import c from './Principal.less';
import { Checkbox, Row, Col, Tooltip } from 'antd';
import IconFont from '../IconFont';
import formApi from '@/services/form';
import { getUrlParam } from '@/utils';
import { customJP } from '@/utils';

interface IProps {
  initData: any;
  chRef: any;
  isProcess?: boolean;
  selectMenberOrNot: (e: any, type: number, data: any) => void;
  type?: any;
}

interface IDirectorOption {
  name: string;
  id: string;
  subType:
    | 'managerOfDept'
    | 'managerOfMember'
    | 'managerOfInitiator'
    | 'direct_manager';
  checked: boolean;
  levels?: number[];
}

export default (props: IProps) => {
  const {
    initData,
    selectMenberOrNot,
    chRef,
    isProcess = true,
    type = 'manager',
  } = props;
  const Originator: IDirectorOption = {
    name: isProcess ? '发起人' : '创建人',
    id: type == 'manager' ? 'manager' : 'direct_manager',
    subType: 'managerOfInitiator',
    checked: false,
    levels: [],
  };
  const [selectManagerType, setSelectManagerType] =
    useState<IDirectorOption>(Originator);
  // subtype: managerOfDept-表单内部门控件对应主管
  //          managerOfMember-表单内成员控件对应主管
  //          managerOfInitiator-发起人对应主管
  const [directorOption, setDirectorOption] = useState<IDirectorOption[]>([
    Originator,
  ]);
  const DIRECTORS_MAX = 20;

  useEffect(() => {
    getSchema();
  }, []);

  useImperativeHandle(chRef, () => ({
    onRemove,
  }));

  const getSchema = () => {
    const formCode = getUrlParam('formCode');
    formApi.getFormSchemas({ formCode }).then((res: any) => {
      if (!res?.data) return;
      const content = customJP(res.data?.content);
      let arr: IDirectorOption[] = [];
      const schema = Object.keys(content.properties);
      const innerContact = schema.filter((x) => x.includes('innerContact_'));
      const department = schema.filter((x) => x.includes('department_'));
      if (innerContact.length > 0) {
        innerContact?.forEach((item) => {
          arr.push({
            name: content.properties[item]?.title,
            id: item,
            subType: 'managerOfMember',
            checked: false,
          });
        });
      }
      // 如果是直属主管  那么屏蔽部门
      if (department.length > 0 && type !== 'direct_manager') {
        department?.forEach((item) => {
          arr.push({
            name: content.properties[item]?.title,
            id: item,
            subType: 'managerOfDept',
            checked: false,
          });
        });
      }
      setDirectorOption([...directorOption, ...arr]);
    });
  };

  useEffect(() => {
    let dynamicPersonArr = initData?.dynamicPerson ?? [];
    let checked = false;
    console.log(dynamicPersonArr, directorOption, 'dynamicPersonArr');
    dynamicPersonArr?.forEach((ele: any) => {
      if (ele.type == 'manager' && type === 'manager') {
        checked = true;
        setSelectManagerType({
          ...ele,
          checked,
        });
      }
      if (ele.type == 'direct_manager' && type === 'direct_manager') {
        checked = true;
        setSelectManagerType({
          ...ele,
          checked,
        });
      }
    });
  }, [initData, type]);

  const onChangeLegacy = (e: any) => {
    const checked = e.target.checked;
    selectMenberOrNot(e, 4, {
      ...selectManagerType,
      name: `${selectManagerType.name}-直接部门主管`,
      checked,
      currentType: 4,
      type: 'manager',
      levels: [1],
    });
    setSelectManagerType({
      ...selectManagerType,
      checked,
    });
  };

  const onChangeNew = (checkedValues: number[]) => {
    const checked = checkedValues?.length > 0;
    const simulated_e = {
      target: { checked },
    };
    const finalCheckedValues =
      checkedValues?.length > 1 ? checkedValues.slice(1) : checkedValues;
    const directorName =
      finalCheckedValues[0] === 1
        ? type === 'manager'
          ? '直接部门主管'
          : type === 'direct_manager'
          ? '直属主管'
          : ''
        : `第${finalCheckedValues[0]}级主管`;

    const currentType =
      type === 'manager' ? 4 : type === 'direct_manager' ? 5 : 4;
    selectMenberOrNot(simulated_e, currentType, {
      ...selectManagerType,
      name: `${selectManagerType.name?.split('-')?.[0]}-${directorName}`,
      checked,
      currentType: currentType,
      type: type,
      levels:
        checkedValues?.length > 1 ? checkedValues.slice(1) : checkedValues,
    });
    setSelectManagerType({
      ...selectManagerType,
      checked,
      levels:
        checkedValues?.length > 1 ? checkedValues.slice(1) : checkedValues,
    });
  };

  const onRemove = () => {
    setSelectManagerType({
      ...selectManagerType,
      checked: false,
      levels: [],
    });
  };

  const title = useMemo(() => {
    return directorOption.find((opt) => selectManagerType?.id === opt?.id)
      ?.name;
  }, [selectManagerType]);

  return (
    <div className={c.director_con}>
      <div className={c.selectPart}>
        {directorOption.map((item, index) => (
          <p
            onClick={() => {
              const manager =
                initData?.dynamicPerson?.find((data) => {
                  if (type === 'manager') {
                    return data.type === 'manager';
                  } else if (type === 'direct_manager') {
                    return data.type === 'direct_manager';
                  }
                }) ?? {};
              setSelectManagerType({
                ...item,
                checked: manager?.id === item.id,
              });
            }}
            key={item.name + '@@@'}
            className={selectManagerType?.id == item.id ? c.active : undefined}
          >
            {item.name}
          </p>
        ))}
      </div>
      <div className={c.right}>
        {/* <span className={c.tips}>
          <span>{title}主管级别</span>
          <Tooltip title="直接部门主管：以部门维度设置的主管，指当前对象所在部门的主管">
            <IconFont type="icon-shuoming" className={c.tipImg} />
          </Tooltip>
        </span> */}
        {type == 'manager' && (
          <Checkbox.Group
            style={{ width: '100%' }}
            value={selectManagerType.levels}
            onChange={onChangeNew}
          >
            <Row>
              <Col span={24}>
                <Checkbox className={c.checkBox} value={1} key={1}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    直接部门主管
                    <Tooltip title="直接部门主管：以部门维度设置的主管，指当前对象所在部门的主管">
                      <IconFont type="icon-shuoming" className={c.tipImg} />
                    </Tooltip>
                  </span>
                </Checkbox>
              </Col>
              {Array(DIRECTORS_MAX - 1)
                .fill('')
                .map((item, idx) => {
                  return (
                    <Col span={24}>
                      <Checkbox
                        className={c.checkBox}
                        value={idx + 2}
                        key={idx + 2 + '@'}
                      >
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          第 {idx + 2} 级主管
                        </span>
                      </Checkbox>
                    </Col>
                  );
                })}
            </Row>
          </Checkbox.Group>
        )}
        {type == 'direct_manager' && (
          <Checkbox.Group
            style={{ width: '100%' }}
            value={selectManagerType.levels}
            onChange={onChangeNew}
          >
            <Row>
              <Col span={24}>
                <Checkbox
                  className={c.checkBox}
                  value={1}
                  key={1 + selectManagerType?.type}
                >
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    直属主管
                  </span>
                </Checkbox>
              </Col>
              {Array(DIRECTORS_MAX - 1)
                .fill('')
                .map((item, idx) => {
                  return (
                    <Col span={24}>
                      <Checkbox
                        className={c.checkBox}
                        value={idx + 2}
                        key={idx + 2 + '@@'}
                      >
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          第 {idx + 2} 级主管
                        </span>
                      </Checkbox>
                    </Col>
                  );
                })}
            </Row>
          </Checkbox.Group>
        )}
      </div>
    </div>
  );
};
