/*
 * :file description:
 * :name: \low-code-platform\src\layouts\components\SideBar\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-10 11:05:12
 * :last editor: hyw
 * :date last edited: 2022-02-23 09:54:27
 */
import { FileProtectOutlined, BlockOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import c from './index.less';
import { useEffect, useState } from 'react';
import { userSimpleInfo } from '@/services/user';
import { history, useLocation, useModel } from 'umi';
import architectureImg from '@/images/layouts/architecture.svg';
import encryptionImg from '@/images/layouts/encryption.svg';
import activeEncryptionImg from '@/images/layouts/activeEncryption.svg';
import activeArchitectureImg from '@/images/layouts/activeArchitecture.svg';
import activeMessageImg from '@/images/layouts/activeMessage.svg';
import messageImg from '@/images/layouts/message.svg';
import applicationImg from '@/images/layouts/application.svg';
import activeApplicationImg from '@/images/layouts/activeApplication.svg';
import { editType } from '@/pages/generator/form';
import { DESIGN_FORM_CREATE } from '@/common/http/constant';
import IconFont from '@/components/IconFont';

export default function SideBar({}) {
  const [nowIndex, setNowIndex] = useState();
  const [formMenu, setForMenu] = useState([]);
  const location = useLocation();

  const { check, permission } = useModel('usePermission', (model) => ({
    check: model.check,
    permission: model.permission,
  }));

  const baseItem = [
    {
      icon: <FileProtectOutlined />,
      url: messageImg,
      activeUrl: activeMessageImg,
      label: '待办消息',
      pathname: '/todoMessage',
    },
    {
      icon: <BlockOutlined />,
      label: '应用',
      url: applicationImg,
      activeUrl: activeApplicationImg,
      pathname: `/generator/form?type=${editType.CREATE}`,
      rightIcon: (
        <IconFont style={{ fontSize: '14px' }} type="icon-tianjia"></IconFont>
      ),
    },
  ];
  const backItem = [
    {
      icon: <FileProtectOutlined />,
      url: architectureImg,
      activeUrl: activeArchitectureImg,
      label: '组织架构',
      pathname: '/back/org',
    },
    {
      icon: <FileProtectOutlined />,
      url: encryptionImg,
      activeUrl: activeEncryptionImg,
      label: '权限',
      pathname: '/back/auth',
    },
  ];

  const isBack = location.pathname.includes('/back');
  let baseArr = isBack ? backItem : baseItem;

  // 获取个人信息
  const getUserSimpleInfo = async () => {
    const res = await userSimpleInfo();
    window.localStorage.setItem('userInfo', JSON.stringify(res.data));
  };

  useEffect(() => {
    getUserSimpleInfo();
  }, []);

  useEffect(() => {
    const newData = permission.filter((item) => {
      return item.level === 1 && item.type === 2;
    });
    const arr = newData?.map((item) => {
      return {
        label: item.name,
        pathname: `/form/${item.bizCode}`,
      };
    });
    setForMenu(arr);
  }, [permission]);

  // 点击菜单项
  const clickMenu = (el, index) => {
    setNowIndex(index);
    if (
      (check(DESIGN_FORM_CREATE) && el.label === '应用') ||
      el.label !== '应用'
    ) {
      history.push(el.pathname);
    }
  };

  return (
    <div style={{ background: '#fff' }}>
      <div className={c.sidebar}>
        {baseArr?.map((el: any, index) => (
          <div
            className={c.menuItem}
            style={{
              background:
                location.pathname === el.pathname ? 'rgba(0,137,255,0.06)' : '',
            }}
            key={el.label}
            onClick={() => clickMenu(el, index)}
          >
            {el.url && (
              <img
                src={location.pathname === el.pathname ? el.activeUrl : el.url}
                className={c.icon}
              ></img>
            )}
            <span
              className={c.label}
              style={{
                color: location.pathname === el.pathname ? '#0089FF' : '',
              }}
            >
              {el.label}
            </span>
            {el.rightIcon && (
              <span className={c.rightIcon}>
                {check(DESIGN_FORM_CREATE) && el.rightIcon}
              </span>
            )}
          </div>
        ))}
        <div className={c.scrollMenu}>
          {!isBack &&
            formMenu &&
            formMenu?.map((el: any, index) => (
              <Tooltip placement="right" title={el.label} key={index}>
                <div
                  className={c.menuItem}
                  style={{
                    background:
                      location.pathname === el.pathname
                        ? 'rgba(0,137,255,0.06)'
                        : '',
                  }}
                  key={el.label}
                  onClick={() => clickMenu(el, index)}
                >
                  <span
                    className={c.label}
                    style={{
                      color: location.pathname === el.pathname ? '#0089FF' : '',
                    }}
                  >
                    {el.label}
                  </span>
                </div>
              </Tooltip>
            ))}
        </div>
      </div>
    </div>
  );
}
