/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\EditDepartment\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-22 13:52:55
 * :last editor: hyw
 * :date last edited: 2022-02-23 15:58:55
 */
import { useRef } from 'react';
import { Button, Tag } from 'antd';
import Iconfont from '@/components/IconFont';
import MultipleChoice from '@/components/MultipleChoice';
import Style from './index.less';

const EditDepartment = (props) => {
  const { onChange, value, schema } = props;

  const { multiple } = schema;
  const childRef = useRef(null);

  const getDataBack = (data: any) => {
    const departments = data?.departments || [];
    const val = departments.map((department) => {
      return {
        key: department.id || department.key,
        value: department.name,
      };
    });

    onChange(val);
  };

  const onClose = (index) => {
    value.splice(index, 1);
    onChange(value);
  };

  const departments = (value || []).map?.((item) => {
    return {
      key: item.key,
      name: item.value,
    };
  });

  const isAdd =
    (!multiple && departments.length == 0) ||
    (multiple && departments.length <= 100);

  return (
    <div className={Style.field}>
      {isAdd && !props.readOnly && (
        <Button
          icon={<Iconfont type="icon-tianjia1" />}
          onClick={() => (childRef?.current as any)?.openModal()}
          className={Style.btn}
        >
          添加
        </Button>
      )}
      {!isAdd && !props.readOnly && (
        <Button
          icon={<Iconfont type="icon-bianji" />}
          onClick={() => (childRef?.current as any)?.openModal()}
          className={Style.btn}
        >
          修改
        </Button>
      )}
      {(value || []).map?.((item, index) => {
        return (
          <Tag
            {...{ closable: !props.readOnly }}
            onClose={() => {
              onClose(index);
            }}
          >
            {item.value}
          </Tag>
        );
      })}

      <div onClick={(e) => e.stopPropagation()}>
        <MultipleChoice
          cRef={childRef}
          initData={{
            departments,
          }}
          getDataBack={getDataBack}
          tabs={['部门']}
          multiple={multiple}
          title="选择部门"
        />
      </div>
    </div>
  );
};

export default EditDepartment;
