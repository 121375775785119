/**
 * 产品设置
 */
import React, { useState, useEffect, useImperativeHandle, useRef } from 'react';
import { Alert, Button, Modal, message } from 'antd';
import { nanoid } from 'nanoid';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getCascadeOptionList, saveCascadeOption } from '../api';
import SearchInput from './SearchInput';
import TreeList from './TreeList';
import Styles from './index.less';
import IconFont from '@/components/IconFont';
import { useGlobal, useStore } from '@/components/FormGenerator/utils/hooks';

export interface IProductType {
  name: string;
  code: string;
  id?: number;
  parentId?: string;
  children?: IProductType[];
}

const SortManagement = (props) => {
  const {
    treeData,
    cRef,
    setValues,
    setModalVisible,
    getValues,
    setSchemaByPath,
    enumOptionId,
  } = props;

  const [searchKey, setSearchKey] = useState('');
  const [types, setTypes] = useState([]);

  // 缓存级联选项
  const setGlobal = useGlobal(); // setGlobal({ selected: null });
  const { flatten, cascadesOptions, formCode } = useStore();
  const childRef = useRef(null);

  const fnsData = (types: any, parentFullPath?: any) => {
    if (!types || !types?.length) {
      return;
    }
    types?.forEach((i, indexI) => {
      i.fullPath = parentFullPath ? parentFullPath + '/' + indexI : '' + indexI;
      if (i?.children?.length) {
        fnsData(i?.children, i?.fullPath);
      }
    });
  };

  const initTypes = () => {
    if (enumOptionId) {
      const param = { ids: [enumOptionId] };
      getCascadeOptionList(param).then((res) => {
        const data = res.data?.[0] ?? [];
        fnsData(data?.cascadeOptions);
        setGlobal({
          cascadesOptions: {
            ...cascadesOptions,
            [data?.id]: data,
          },
        });
        setTypes(data?.cascadeOptions);
      });
    } else {
      setTypes([]);
    }
  };

  useImperativeHandle(cRef, () => ({
    // setTreeData 就是暴露给父组件的方法 获取选择的数据
    setTreeData: () => {
      const param = {
        cascadeOptions: types,
        id: enumOptionId,
        version: cascadesOptions?.[enumOptionId]?.['version'],
        formCode: formCode,
      };
      saveCascadeOption(param).then((res) => {
        const { id, version } = res?.data ?? {};
        setGlobal({
          cascadesOptions: {
            ...cascadesOptions,
            [id]: {
              ...(res?.data ?? {}),
              cascadeOptions: types,
            },
          },
        });
        setValues({
          ...getValues(),
          enumOptionId: id,
        });
        setModalVisible(false);
      });
    },
  }));

  useEffect(() => {
    initTypes();
  }, [enumOptionId]);

  return (
    <div className={Styles.sort}>
      <div className={Styles.alertWrap}>
        <InfoCircleOutlined style={{ color: '#0089FF' }} />
        <span>每个选项下最多可设置 10 个层级（级联选项）</span>
      </div>
      <div className={Styles.header}>
        <div className={Styles.search}>
          <SearchInput
            placeholder="输入名称快速查找"
            searchIconPosition="prefix"
            onChange={(value) => console.log(value)}
            onSearch={(value = '') => {
              setSearchKey(value);
            }}
          />
        </div>
        <div className={Styles.btnGroups}>
          <Button
            type="primary"
            onClick={() => {
              (childRef?.current as any)?.creatItem();
            }}
            icon={<PlusOutlined />}
          >
            新建选项
          </Button>
        </div>
      </div>
      <TreeList
        cRef={childRef}
        treeList={types}
        setTypes={setTypes}
        fnsData={fnsData}
        searchKey={searchKey}
        initTypes={initTypes}
      />
    </div>
  );
};
export default SortManagement;
