/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\EditSeqNumber\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-07 17:29:51
 * :last editor: hyw
 * :date last edited: 2022-04-22 19:39:00
 */
import { incrSeq } from '@/services/form-generator';
import { FC, useEffect } from 'react';
import { useParams } from 'umi';
import moment from 'moment';
import { IObj, WidgetProps } from 'typings';

interface IParams {
  id: string;
}

type Reset = '' | 'year' | 'month' | 'day';
interface ICounter {
  value: {
    start: number;
    length: number;
    reset: Reset;
  };
}

interface ruleItem {
  value: string;
  type: string;
}

const formatStr = (key: string, addons: WidgetProps['addons']) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const regx = new RegExp('[^_]+');
      const formData = addons.getValues();
      const [widget] = key.match(regx) || [];
      const formField = formData[key];
      let target = '';
      switch (widget) {
        case 'address':
          const { address = [], street = '', detail = '' } = formField;
          target = address.join('') + street + detail;
        case 'select':
        case 'phone':
          target = formField?.value || '';
          break;
        default:
          target = formField || '';
          break;
      }
      resolve(target);
      return target;
    }, 0);
  });
};

const getObservedField = (
  generationRules: any,
  addons: WidgetProps['addons'],
) => {
  const fields = generationRules
    ?.filter((item: ruleItem) => item.type === 'field')
    .map((item: ruleItem) => item.value);
  const target = fields?.reduce((pre: typeof IObj, cur: string) => {
    const formData = addons.getValues();
    return { ...pre, [cur]: formData[cur] };
  }, {});
  return target;
};

const EditSeqNumber: FC<WidgetProps> = (props) => {
  const {
    schema: { generationRules = [] },
    onChange,
    addons,
  } = props;
  const observedField = getObservedField(generationRules, addons);
  const params = useParams<IParams>();
  let formCode = params.id || props.formCode;
  const getIncrSeq = async (counter: ICounter) => {
    if (!formCode) {
      return;
    }
    const { length, reset } = counter?.value || {};
    const incrSeqRes = await incrSeq({
      formCode,
      maxCount: length,
      reset,
    });
    return incrSeqRes.data;
  };

  const computeSeqNumber = async () => {
    let target = '';
    for (let item of generationRules) {
      switch (item.type) {
        case 'date':
          target += moment().format(item.value);
          break;
        case 'counter':
          target += await getIncrSeq(item);
          break;
        case 'common':
          target += item.value;
          break;
        case 'field':
          target += await formatStr(item.value, addons);
          break;
        default:
          break;
      }
    }
    return target;
  };

  useEffect(async () => {
    // 已经有生成过流水号的就不重新生成
    if (!props.value) {
      const seqNumber = await computeSeqNumber();
      // console.log(seqNumber, 1111111);
      onChange(seqNumber);
    }
  }, [JSON.stringify(observedField)]);
  // 当有值时证明是编辑状态，只要展示该值即可
  if (props.value) {
    return <span>{props.value}</span>;
  }
  return <div>系统自动生成</div>;
};

export default EditSeqNumber;
