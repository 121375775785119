/*
 * :file description:
 * :name: \low-code-platform\src\pages\login\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-12 10:13:45
 * :last editor: hyw
 * :date last edited: 2022-02-22 11:45:22
 */
import c from './index.less';
import { Form, Input, Button, message } from 'antd';
import { login, getMenu } from '@/services/user';
import { useHistory } from 'react-router-dom';
import logo from '@/images/logo.png';
import IconFont from '@/components/IconFont';
import { useState } from 'react';
import md5 from 'js-md5';
import { useModel } from 'umi';

function Login() {
  const [form] = Form.useForm();
  const history = useHistory();
  const [error, setError] = useState('');
  const { updatePermission } = useModel('usePermission', (model) => ({
    updatePermission: model.updatePermission,
  }));
  const onFinish = async (values: { password: string; phone: string }) => {
    login({
      password: md5(values.password),
      phone: values.phone,
    }).then((res) => {
      if (res.code === 200) {
        message.success('登录成功');
        localStorage.setItem('token', res?.data?.accessToken);
        setTimeout(() => {
          getMenus();
        }, 0);
      } else {
        setError(res.msg);
        setTimeout(() => {
          setError('');
        }, 2000);
      }
    });
  };

  const handleValuesChange = () => {
    setTimeout(() => {
      const errors = form.getFieldsError();
      let flag = true;
      errors?.forEach((item) => {
        if (item.errors.length > 0) {
          flag = false;
          setError(item.errors?.shift());
          return;
        }
      });
      if (flag) {
        setError('');
      }
    }, 0);
  };

  // 更新权限
  const getMenus = async () => {
    const res = await getMenu();
    window.localStorage.setItem('allAuthList', JSON.stringify(res.data));
    const bool = res.data.some((item) => {
      return item.authorityCode == 'SYSTEM_MANAGE_BACKGROUND';
    });
    window.localStorage.setItem('authority', bool);
    updatePermission(res?.data || []);
    setTimeout(() => {
      history.push('/todoMessage');
    }, 0);
  };

  return (
    <div className={c.login_bg}>
      <img className={c.login_logo} src={logo} />
      <div
        className={c.login_contain_header}
        style={{ height: error ? '440px' : '388px' }}
      >
        <div className={c.loginTitle}>探迹低代码平台</div>
        {error ? (
          <div className={c.error_contain}>
            <IconFont
              style={{ fontSize: '17px' }}
              type="icon-jinggao"
            ></IconFont>
            <div className={c.error_text}>{error}</div>
          </div>
        ) : null}
        <Form
          name="normal_login"
          onValuesChange={(e) => handleValuesChange()}
          form={form}
          className={c.loginForm}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: '请输入手机号码',
              },
              {
                required: false,
                pattern: new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, 'g'),
                message: '请输入正确的手机号',
              },
            ]}
          >
            <Input placeholder="请输入手机号码" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '请输入密码',
              },
            ]}
          >
            <Input type="password" placeholder="请输入密码" />
          </Form.Item>
          <Form.Item>
            <Button className={c.submit} type="primary" htmlType="submit" block>
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
Login.wrappers = ['@/wrappers/auth'];

export default Login;
