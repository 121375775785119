/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\Picture\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 14:28:41
 * :last editor: hyw
 * :date last edited: 2022-01-18 16:14:15
 */
import { Typography, Upload } from 'antd';
import c from './index.less';
import { PlusOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { WidgetProps } from 'typings';

const { Text } = Typography;
const imageUrl = '';

const PictureWidget: FC<WidgetProps> = () => {
  return (
    <div className={c.container}>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        disabled
        // beforeUpload={beforeUpload}
        // onChange={this.handleChange}
      >
        {imageUrl ? (
          <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
        ) : (
          <PlusOutlined />
        )}
      </Upload>
    </div>
  );
};

export default PictureWidget;
