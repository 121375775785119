/*
 * :file description:
 * :name: \low-code-platform\src\models\global.ts
 * :author: PakJeon
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-15 10:35:42
 * :last editor: PakJeon
 * :date last edited: 2022-01-08 14:37:38
 */
import { Reducer, Effect } from 'umi';
import formApi from '@/services/form';
import {
  handleImportNotification,
  handleExportNotificcation,
  IMPORT_STATUS,
  EXPORT_STATUS,
} from '@/utils/TransformNotification/transformNotification';

type taskType = {
  taskId: string;
  action: 0 | 1; // 0 - 导入 1-导出
  exportFailReason: string;
  exportStatus: number;
  fileUrl: string;
  formTitle: string;
  importFailCount: number;
  importFailFileUrl: string;
  importInsertCount: number;
  importStatus: number;
  importType: number;
  importUpdateCount: number;
  progressRate: number;
};

export interface GlobalModelState {
  importTask: taskType[];
  exportTask: taskType[];
  toggleNotification: boolean;
}

export interface GlobalModelType {
  namespace: 'global';
  state: GlobalModelState;
  effects: {
    fetchImportStatus: Effect;
    fetchExportStatus: Effect;
    toggleNotification: Effect;
  };
  reducers: {
    saveImportTask: Reducer<GlobalModelState>;
    saveExportTask: Reducer<GlobalModelState>;
    saveToggleNotification: Reducer<GlobalModelState>;
  };
}

const getImportTaskStatus = ({ taskId }: { taskId: string }) => {
  return formApi.getTaskResult({
    action: 0,
    taskId,
  });
};

const getExportTaskStatus = ({ taskId }: { taskId: string }) => {
  return formApi.getTaskResult({
    action: 1,
    taskId: taskId + '',
  });
};

const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

const GlobalModel: GlobalModelType = {
  namespace: 'global',
  state: {
    toggleNotification: false,
    importTask: [],
    exportTask: [],
  },
  effects: {
    *fetchImportStatus({ payload }, { call, put }) {
      const { taskId } = payload;
      const res = yield call(getImportTaskStatus, { taskId });
      yield put({
        type: 'saveImportTask',
        payload: {
          taskId,
          data: res.data,
        },
      });
      if (
        res &&
        res.data &&
        [
          IMPORT_STATUS.BEFORE,
          IMPORT_STATUS.DOING,
          IMPORT_STATUS.PROGRESS,
        ].includes(res.data.importStatus)
      ) {
        yield sleep(1000);
        yield put({ type: 'fetchImportStatus', payload });
      }
    },
    *fetchExportStatus({ payload }, { call, put, select }) {
      const { taskId } = payload;
      const res = yield call(getExportTaskStatus, { taskId });
      yield put({
        type: 'saveExportTask',
        payload: {
          taskId,
          data: res.data,
        },
      });
      if (
        res &&
        res.data &&
        [EXPORT_STATUS.BEFORE, EXPORT_STATUS.DOING].includes(
          res.data.exportStatus,
        )
      ) {
        yield sleep(1000);
        yield put({ type: 'fetchExportStatus', payload });
      }
    },
    *toggleNotification({ payload }, { put }) {
      const { toggle } = payload;
      yield put({
        type: 'saveToggleNotification',
        payload: {
          toggle,
        },
      });
    },
  },
  reducers: {
    saveImportTask(state, { payload }) {
      const _importTasks = [...state!.importTask];
      const _index = _importTasks.findIndex(
        (item) => item.taskId === payload.taskId,
      );
      if (state?.toggleNotification) {
        handleImportNotification(payload);
      }
      if (_index >= 0) {
        _importTasks[_index] = payload;
      } else {
        _importTasks.push(payload);
      }
      return {
        ...state,
        importTask: _importTasks,
      };
    },
    saveExportTask(state, { payload }) {
      const _exportTasks = [...state!.exportTask];
      const _index = _exportTasks.findIndex(
        (item) => item.taskId === payload.taskId,
      );
      handleExportNotificcation(payload);
      if (_index >= 0) {
        _exportTasks[_index] = payload;
      } else {
        _exportTasks.push(payload);
      }
      return {
        ...state,
        exportTasks: _exportTasks,
      };
    },
    saveToggleNotification(state, { payload }) {
      return {
        ...state,
        toggleNotification: payload.toggle,
      };
    },
  },
};

export default GlobalModel;
