import React, { useRef, useState } from 'react';
import { Carousel, Modal } from 'antd';
import QRCode from 'qrcode.react';
import classnames from 'classnames';
import IconFont from '@/components/IconFont';
import { getUuid } from '@/utils';

import c from './index.less';

interface IProps {
  visible: boolean;
  formList: ({ [key: string]: string } | null)[];
  onClose: (e?: React.MouseEvent<HTMLElement>) => unknown;
}

export default function OaAuthModal(props: IProps) {
  const { visible, formList, onClose } = props;
  const [currentIndex, setCurrentIndex] = useState(0);
  const swiperRef = useRef();

  const prevDisabled = currentIndex === 0;
  const nextDisabled = currentIndex === formList.length - 1;

  const checkQrcode = (step: string) => {
    switch (step) {
      case 'prev':
        if (!prevDisabled) {
          setCurrentIndex(currentIndex - 1);
          swiperRef.current.prev();
        }
        break;
      case 'next':
        if (!nextDisabled) {
          setCurrentIndex(currentIndex + 1);
          swiperRef.current.next();
        }
        break;
      default:
        break;
    }
  };

  const renderQrcode = () => (
    <Carousel ref={swiperRef} className={c.swiper_wrap} dots={false}>
      {formList.map((item) => {
        return (
          <div key={getUuid()}>
            <div className={c.qrcode_wrapper}>
              <QRCode
                value={`https://${
                  process.env.UMI_ENV === 'prod'
                    ? 'app75714.eapps.dingtalkcloud'
                    : 'tj-ali-crm-test.tangees'
                }.com/tungee/crm/mobile/#/authorize?processCode=${
                  item.formCode
                }&corpId=${window.__corpId}`}
                size={128}
                level="L"
                bgColor="#FFFFFF"
                fgColor="#000000"
                includeMargin={false}
                renderAs="svg"
              />
            </div>
          </div>
        );
      })}
    </Carousel>
  );

  return (
    <Modal width={358} visible={visible} closable={false} footer={null}>
      <div className={c.content_wrapper}>
        <IconFont
          type="icon-close-line"
          className={c.close}
          onClick={onClose}
        />
        <div className={c.title}>授权确认</div>
        <div className={c.desc}>
          发现您当前表单关联了 <span className={c.num}>{formList.length}</span>{' '}
          个OA审批表单，您需要将审批数据授权给【探迹CRM】后，才可以查看此表单数据
        </div>
        <div className={c.qrcode_desc}>
          【OA审批模板管理员】用手机钉钉扫码授权
        </div>
        <div className={c.carousel}>
          <div
            className={classnames(c.check_wrap, prevDisabled && c.disabled)}
            onClick={() => checkQrcode('prev')}
          >
            <IconFont type="icon-fanhui" />
          </div>
          {renderQrcode()}
          <div
            className={classnames(c.check_wrap, nextDisabled && c.disabled)}
            onClick={() => checkQrcode('next')}
          >
            <IconFont type="icon-youjiantou" />
          </div>
        </div>
        <div className={c.tips_wrapper}>
          <div className={c.tips_icon}>
            <IconFont type="jinggao" />
          </div>
          <span className={c.tips}>
            若提示授权失败，请确认您是否是相关OA审批表单的模板管理员
          </span>
        </div>
      </div>
    </Modal>
  );
}
