/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\LimitDot\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-28 16:58:42
 * :last editor: 黄艺
 * :date last edited: 2021-12-29 16:21:26
 */
import { InputNumber } from 'antd';
import { FC, useState } from 'react';
import { WidgetProps } from 'typings';
import c from './index.less';
import { useStore } from '@/components/FormGenerator/utils/hooks';

const BIZ_SUITE_FIELDID = '#/bizSuite_127KIMRJZSK00';

const LimitDotWidget: FC<WidgetProps<number>> = ({
  value,
  addons,
  onChange,
  ...rest
}) => {
  const { flatten, selected, onFlattenChange } = useStore();
  const [inputNumber, setInputNumber] = useState(0);
  const { formData } = addons;

  // 回款、开票套件中的回款金额字段小数点 保持跟payment_amount 一致
  if (flatten[BIZ_SUITE_FIELDID]?.schema?.extendProperties?.isNewMode) {
    if (formData.bizAlias === 'payment_amount') {
      flatten[
        BIZ_SUITE_FIELDID
      ].schema.extendProperties.table_contractPaymentDetail.items.properties.money_contractPaymentAmount.limitDot =
        inputNumber;
      flatten[
        BIZ_SUITE_FIELDID
      ].schema.extendProperties.table_orderPaymentDetail.items.properties.money_orderPaymentAmount.limitDot =
        inputNumber;
    } else if (formData.bizAlias === 'invoice_apply_money') {
      flatten[
        BIZ_SUITE_FIELDID
      ].schema.extendProperties.table_contractInvoiceApplyDetail.items.properties.money_contractInvoiceApplyAmount.limitDot =
        inputNumber;
      flatten[
        BIZ_SUITE_FIELDID
      ].schema.extendProperties.table_orderInvoiceApplyDetail.items.properties.money_orderInvoiceApplyAmount.limitDot =
        inputNumber;
    }
  }

  return (
    <div className={c.container}>
      <span>限制</span>
      <InputNumber
        min={0}
        max={8}
        value={value}
        defaultValue={0}
        {...rest}
        onChange={(e) => {
          setInputNumber(e);
          onChange(e);
        }}
      />
      <span>位</span>
    </div>
  );
};

export default LimitDotWidget;
