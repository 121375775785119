import {
  postRequest,
  getRequest,
  putRequest,
  deleteRequest,
} from '@/common/http';
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';

interface IResBusRule {
  appUuid: string;
  formCode: string;
  orgId: number;
  stopAssociationRulesIfFailed: boolean;
  associationRules: IAssociationRules[];
}

interface IResSubRule {
  appUuid: string;
  formCode: string;
  orgId: number;
  validators: ISubmitRules[];
  version: number;
}

interface ISaveBusParams {
  formCode: string;
  stopAssociationRulesIfFailed: boolean;
  associationRules: IResBusRule[];
  version: number;
}

interface ISaveSubParams {
  formCode: string;
  validators: ISubmitRules[];
  version: number;
}

interface OriginalFormAssociationRuleRes {
  data: {
    associationRule: IAssociationRule;
  };
}

interface IRes<T> {
  code: number;
  data: T;
}

export const getValidator = (data: { formCode: string }, formCode: string) => {
  return postRequest<IRes<IResSubRule>>(
    `/api/form/extendSetting/getValidator`,
    data,
    {
      headers: {
        formCode,
      },
    },
  );
};

export const getFormAssociationRule = (
  data: { formCode: string },
  formCode: string,
) => {
  return postRequest<IRes<IResBusRule>>(
    `/api/form/extendSetting/getFormAssociationRule`,
    data,
    {
      headers: {
        formCode,
      },
    },
  );
};

export const saveAssociationRule = (data: ISaveBusParams) => {
  return postRequest('/api/form/extendSetting/saveAssociationRule', data);
};

export const saveValidator = (data: ISaveSubParams) => {
  return postRequest('/api/form/extendSetting/saveValidator', data);
};

// 获取所有表单列表
export const getVisibleFormList = () => {
  return postRequest('/api/form/schema/getVisibleFormList');
};

// 检验公式
export const validateFormRule = (params, formCode) => {
  return getRequest('/api/form/extendSetting/validateFormRule', params, {
    headers: {
      formCode,
    },
  });
};

// 获取表单业务关联原始公式规则
export const getOriginalFormAssociationRule = (
  ruleId: number,
  formCode: string,
): Promise<OriginalFormAssociationRuleRes> => {
  return postRequest(
    '/api/form/extendSetting/getOriginalFormAssociationRule',
    {
      formCode,
      ruleId,
    },
    {
      formCode,
    },
  );
};

interface ConnectorData {
  title: string;
  triggerType: number;
  connectorId: number;
  formCode: string;
  requestDefinition: {
    body: any;
    query: any;
  };
}

// 创建流程
export const createConnector = (data: ConnectorData) => {
  return postRequest('/api/connector-procedures', data);
};

// 编辑流程
export const updateConnectorProcedures = (
  procedureId: string,
  data: ConnectorData,
) => {
  return putRequest(`/api/connector-procedures/${procedureId}`, data);
};

interface pageInfo {
  order: string[];
  pageNum: number;
  pageSize: number;
  sort: string[];
}

// 流程分页列表
export const getConnectorList = (data: pageInfo) => {
  return postRequest('/api/connector-procedures/queries', data);
};

//  流程详情
export const getConnectorDetail = (procedureId: string) => {
  return getRequest(`/api/connector-procedures/${procedureId}`);
};

// 删除流程
export const deleteConnector = (procedureId: string) => {
  return deleteRequest(`/api/connector-procedures/${procedureId}`);
};

//查询连接器列表
export const queryConnectorConfigList = (data: pageInfo) => {
  return postRequest(`/api/connector/listConnector`, data);
};

interface QueryRequestBody {
  id: number | string;
  needThrowExWhenNotFount?: boolean;
}

//查询连接器详情
export const getConnectorConfigDetail = (data: QueryRequestBody) => {
  return postRequest(`/api/connector/getConnector`, data);
};

//查询连接器执行记录
export const getConnectorTaskLog = (procedureId: string, data: pageInfo) => {
  return postRequest(
    `/api/connector-procedures/${procedureId}/exec-logs/queries`,
    data,
  );
};

// 判断是否在白名单中
export const isInWhiteList = (params: any) => {
  return postRequest(
    `${
      isOpenInTripartitePlatform('1688') ? DOMAIN_QRCODE_1688 : DOMAIN_QRCODE
    }/api/v1/formserver-service/whiteList/isInWhiteList`,
    params,
  );
};

// 停用数据同步
export const disabledConnector = (params: QueryRequestBody) => {
  return postRequest(`/api/connector-procedures/disableProcedure`, params);
};

// 启用数据同步
export const enabledConnector = (params: QueryRequestBody) => {
  return postRequest(`/api/connector-procedures/enableProcedure`, params);
};

// 手动重试执行记录
export const retryProcedureExecLog = (params: QueryRequestBody) => {
  return postRequest(`/api/connector-procedures/retryProcedureExecLog`, params);
};

// 已启用模板数
export const getTemplateUsage = (data: any) => {
  return getRequest('/api/form/printTemplate/templateNum', data);
};

// 应用降级状态
export const getVersionDowngradeStatus = (data: any) => {
  return postRequest('/api/form/printTemplate/invalidTemplateMsg', data);
};

// 模板表单字段代码
export const getFormFieldsData = (data: any) => {
  return postRequest('/api/form/printTemplate/getTemplateFormFieldCode', data);
};

// 打印模板列表
export const getTempList = (data: any) => {
  return postRequest('/api/form/printTemplate/list', data);
};

// 打印模板详情
export const getTempDetail = (data: any) => {
  return postRequest('/api/form/printTemplate/detail', data);
};

// 启用/停用模板
export const enableOrDisableTemp = (data: any) => {
  return postRequest('/api/form/printTemplate/templateEnable', data);
};

// 新建模板
export const createTemp = (data: any) => {
  return postRequest('/api/form/printTemplate/saveTemplate', data);
};

// 编辑模板
export const editTemp = (data: any) => {
  return postRequest('/api/form/printTemplate/saveTemplate', data);
};

// 删除模板
export const delTemp = (data: any) => {
  return postRequest('/api/form/printTemplate/delete', data);
};

// 重命名模板
export const renameTemp = (data: any) => {
  return postRequest('/api/form/printTemplate/rename', data);
};

// 按表单显示打印模板列表
export const getTempListCategorizedByForm = (data: any) => {
  return postRequest('/api/form/printTemplate/listByFormCode', data);
};

// 默认模板预览接口
export const getPreviewDefaultTemplate = (data: any) => {
  return postRequest('/api/form/printTemplate/previewDefaultTemplate', data);
};

// 获取共享数据列表
export async function getListDataSharingRule(data: any) {
  return postRequest(`/api/form/extendSetting/listDataSharingRule`, data);
}

// 获取共享数据列表
export async function saveListDataSharingRule(data: any) {
  return postRequest(`/api/form/extendSetting/saveDataSharingRule`, data);
}

// 删除共享数据列表
export async function deleteDataSharingRule(data: any) {
  return postRequest(`/api/form/extendSetting/deleteDataSharingRule`, data);
}

// 修改默认打印模板设置
export async function printTemplateSetting(data: any) {
  return postRequest(`/api/form/printTemplate/updateSetting`, data);
}
