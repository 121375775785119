/*
 * :file description:
 * :name: \low-code-platform\src\components\FormGenerator\settings\presetFormSettings\contract.js
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-11 20:31:37
 * :last editor: 黄艺
 * :date last edited: 2022-04-20 23:22:03
 */

import { toDingDingSettingLink } from '../../utils';

export default [
  {
    text: '合同流水号',
    name: 'seqNumber',
    icon: 'icon-zhaiyao',
    fieldId: 'seqNumber_1DIBHRJPYRTS0',
    schema: {
      title: '合同流水号',
      type: 'string',
      widget: 'seqNumber',
      bizAlias: 'contract_no',
    },
    setting: {
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        hidden: '{{true}}',
      },
      generationRules: {
        title: '',
        type: 'array',
        items: {
          type: 'object',
          properties: {},
        },
        widget: 'generationRules',
        default: [
          {
            type: 'date',
            value: 'YYYYMMDD',
          },
          {
            type: 'counter',
            value: {
              start: 1,
              length: 8,
              reset: 'year',
            },
          },
          {
            type: 'common',
            value: '',
          },
          {
            type: 'field',
            value: '',
          },
        ],
      },
    },
  },
  {
    text: '合同标题',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    fieldId: 'input_KI5RLXVW',
    schema: {
      title: '合同标题',
      type: 'string',
      widget: 'input',
      bizAlias: 'contract_name',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      title: {
        title: '标题',
        description: '最多100字',
        required: true,
        disabled: true,
        type: 'string',
        max: 100,
        order: 1,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        order: 20,
        disabled: true,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '客户',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_K2U5O2WK',
    extraText: '关联表单',
    schema: {
      title: '客户',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      fields: {},
      bizAlias: 'contract_related_customer',
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
          formCode: '',
        },
      },
      relateSource: [
        {
          bizType: 'crm_customer',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'SWAPP-4C2F4B2B84BE49001EADD10DD19FADC6',
              bizType: 'crm_customer',
              formCode: 'PROC-FD2AC559-36B1-4A51-9E9F-0B0FC904D31C',
              appType: 1,
            },
          },
        },
        {
          bizType: 'crm_customer_personal',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'SWAPP-4C2F4B2B84BE49001EADD10DD19FADC6',
              bizType: 'crm_customer_personal',
              formCode: 'PROC-8CFF1FE5-EB3A-4C78-B069-169058E235EB',
              appType: 1,
            },
          },
        },
      ],
    },
    setting: {
      fields: {
        title: '关联字段',
        type: 'object',
        widget: 'relationSetting',
      },
      // sortFields: {
      //   title: '',
      //   widget: 'sortFields',
      //   type: 'array',
      //   items: {
      //     type: 'object',
      //     properties: {},
      //   },
      //   default: [
      //     {
      //       bizType: 'crm_customer_personal',
      //       fields: [],
      //     },
      //     {
      //       bizType: 'crm_customer',
      //       fields: [],
      //     },
      //   ],
      // },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      choice: {
        title: '关联模式',
        type: 'string',
        default: '0',
        enum: ['0', '1'],
        className: 'my-radioName',
        enumNames: ['引用模式', '拷贝模式'],
        widget: 'radio',
        hidden: '{{true}}',
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      // title: {
      //   title: '标题',
      //   type: 'string',
      //   hidden: '{{true}}',
      // },
      filters: {
        title: '数据过滤',
        type: 'array',
        widget: 'dataFilters',
      },
      dataFillingRules: {
        title: "数据填充",
        type: "array",
        widget: "dataFill"
      }
    },
  },
  {
    text: '线索',
    name: 'relation',
    icon: 'icon-lianjie',
    fieldId: 'relation_followRecordClue',
    extraText: '关联表单',
    schema: {
      title: '线索',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      bizAlias: 'relation_followRecordClue',
      fields: {},
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
          formCode: '',
        },
      },
      relateSource: [
        {
          bizType: 'clue_engine',
          fields: {},
          dataSource: {
            params: {},
            type: 'form',
            target: {
              appUuid: 'TJAPP-MjAyMjA5MjMwMA==',
              bizType: 'clue_engine',
              formCode: 'xxxxx',
              appType: 1,
              status: 'PUBLISHED',
            },
          },
        },
      ],
    },
    setting: {
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        title: '标题',
        type: 'string',
        // hidden: '{{true}}',
      },
    },
  },
  {
    text: '合同金额（元）',
    name: 'money',
    icon: 'icon-a-jine2x',
    fieldId: 'money_KI5RLXW0',
    schema: {
      title: '合同金额（元）',
      type: 'number',
      widget: 'money',
      bizAlias: 'contract_amount',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
  },
  {
    text: '合同中已回款金额',
    name: 'money',
    icon: 'icon-a-jine2x',
    schema: {
      title: '合同中已回款金额',
      type: 'number',
      widget: 'money',
      default: 0,
      value: 2323,
      hidden: true,
      bizAlias: 'biz_contract_paid_amount',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      hidden: {
        title: '隐藏',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
  },
  {
    text: '合同中未回款金',
    name: 'money',
    icon: 'icon-a-jine2x',
    schema: {
      title: '合同中未回款金',
      type: 'number',
      widget: 'money',
      default: 0,
      bizAlias: 'biz_contract_unpaid_amount',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      hidden: {
        title: '隐藏',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
  },
  {
    text: '合同中已开票金额',
    name: 'money',
    icon: 'icon-a-jine2x',
    schema: {
      title: '合同中已开票金额',
      type: 'number',
      widget: 'money',
      bizAlias: 'biz_contract_invoiced_amount',
      default: 0,
      value: 2323,
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      hidden: {
        title: '隐藏',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
  },
  {
    text: '合同中未开票金额',
    name: 'money',
    icon: 'icon-a-jine2x',
    schema: {
      title: '合同中未开票金额',
      type: 'number',
      widget: 'money',
      default: 0,
      value: 2323,
      bizAlias: 'biz_contract_uninvoiced_amount',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      hidden: {
        title: '隐藏',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
  },
  {
    text: '合同中新建提醒',
    name: 'money',
    icon: 'icon-a-jine2x',
    schema: {
      title: '合同中新建提醒',
      type: 'number',
      default: 0,
      value: 2323,
      widget: 'money',
      bizAlias: 'biz_contract_dynamic_remind',
    },
    setting: {
      upper: {
        title: '显示大写',
        type: 'boolean',
        widget: 'switch',
        description: '输入数字后自动显示大写',
        order: 15,
        default: false,
      },
      total: {
        title: '统计总数',
        type: 'boolean',
        widget: 'switch',
        order: 18,
        default: false,
        hidden: '{{formData.parentWidget !== "table"}}',
      },
      hidden: {
        title: '隐藏',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
        disabled: true,
      },
      limitDot: {
        title: '小数',
        type: 'number',
        widget: 'limitDot',
        placeholder: '请输入',
        order: 25,
      },
      default: {
        title: '默认值',
        type: 'number',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
        hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      },
    },
  },
  {
    text: '合同状态',
    name: 'select',
    icon: 'icon-danxuankuang1',
    fieldId: 'select_KI5RLXW1',
    schema: {
      title: '合同状态',
      type: 'object',
      widget: 'select',
      bizAlias: 'contract_status',
    },
    setting: {
      info: {
        type: 'string',
        widget: 'jumplink',
        props: {
          message: '',
          description:
            '当前控件是客户管理特殊组件，如需对其选项进行设置请点击下方链接',
          type: 'info',
          showIcon: true,
          src: toDingDingSettingLink('crm_contract'),
        },
        // hidden: '{{true}}',
      },
      placeholder: {
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        type: 'string',
        hidden: '{{true}}',
      },
      required: {
        type: 'string',
        hidden: '{{true}}',
      },
    },
  },
  {
    text: '签约日期',
    name: 'date',
    icon: 'icon-riqi',
    schema: {
      title: '签约日期',
      type: 'string',
      format: 'date',
      widget: 'date',
    },
    setting: {
      format: {
        title: '日期类型',
        type: 'string',
        enum: ['dateTime', 'date'],
        enumNames: ['年-月-日 时:分:秒', '年-月-日'],
        widget: 'baseSelect',
        order: 5,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '联系人',
    name: 'relation',
    icon: 'icon-lianjie',
    extraText: '关联表单',
    schema: {
      title: '联系人',
      type: 'object',
      widget: 'relation',
      twinWidget: 'tableRelation',
      fields: {},
      showSelectorVerify: {
        rule: '{{formData.relation_K2U5O2WK}}',
        message: '请选择[客户]',
      },
      dataSource: {
        type: 'form',
        params: {
          filter: '',
        },
        target: {
          appUuid: '',
          bizType: '',
          appType: 1,
          formCode: 'PROC-0A04CB52-D8CF-4580-A84C-79418E1FBF16',
        },
      },
    },
    setting: {
      fields: {
        title: '关联字段',
        type: 'object',
        widget: 'relationSetting',
      },
      // sortFields: {
      //   title: '',
      //   widget: 'sortFields',
      //   type: 'array',
      //   items: {
      //     type: 'object',
      //     properties: {},
      //   },
      // },
      // required: {
      //   title: '必填',
      //   type: 'boolean',
      //   widget: 'switch',
      // },
      choice: {
        title: '关联模式',
        type: 'string',
        default: '0',
        enum: ['0', '1'],
        className: 'my-radioName',
        enumNames: ['引用模式', '拷贝模式'],
        widget: 'radio',
        hidden: '{{true}}',
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        hidden: '{{true}}',
      },
      title: {
        title: '标题',
        type: 'string',
        hidden: '{{true}}',
      },
      filters: {
        title: '数据过滤',
        type: 'array',
        widget: 'dataFilters',
      },
    },
  },
  {
    text: '合同开始时间',
    name: 'date',
    icon: 'icon-riqi',
    fieldId: 'date_KI5RLXW3',
    schema: {
      title: '合同开始时间',
      type: 'string',
      format: 'date',
      widget: 'date',
      bizAlias: 'contract_start_time',
    },
    setting: {
      format: {
        title: '日期类型',
        type: 'string',
        enum: ['dateTime', 'date'],
        enumNames: ['年-月-日 时:分:秒', '年-月-日'],
        widget: 'baseSelect',
        order: 5,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '合同结束时间',
    name: 'date',
    icon: 'icon-riqi',
    fieldId: 'date_KI5RLXW4',
    schema: {
      title: '合同结束时间',
      type: 'string',
      format: 'date',
      widget: 'date',
      bizAlias: 'contract_end_time',
    },
    setting: {
      format: {
        title: '日期类型',
        type: 'string',
        enum: ['dateTime', 'date'],
        enumNames: ['年-月-日 时:分:秒', '年-月-日'],
        widget: 'baseSelect',
        order: 5,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '合同编号',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    schema: {
      title: '合同编号',
      type: 'string',
      widget: 'input',
      bizAlias: 'contract_no',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '付款方式',
    name: 'select',
    icon: 'icon-danxuankuang1',
    schema: {
      title: '付款方式',
      type: 'object',
      enum: ['option_0', 'option_1', 'option_2'],
      enumNames: ['选项1', '选项2', '选项3'],
      widget: 'select',
      required: true,
    },
    setting: {
      enumList: {
        title: '选项',
        type: 'array',
        enum: ['option_0', 'option_1', 'option_2'],
        enumNames: ['选项1', '选项2', '选项3'],
        widget: 'selectOptions',
        order: 12,
      },
      required: {
        title: '必填',
        type: 'boolean',
        widget: 'switch',
        default: true,
      },
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请选择',
        default: '请选择',
        max: 100,
        order: 10,
      },
    },
  },
  {
    text: '客户方签约人',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    schema: {
      title: '客户方签约人',
      type: 'string',
      widget: 'input',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '我方签约人',
    name: 'input',
    icon: 'icon-danhangshurukuang',
    schema: {
      title: '我方签约人',
      type: 'string',
      widget: 'input',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        type: 'string',
        widget: 'defaultValue',
        max: 100,
        placeholder: '请选择',
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
  {
    text: '备注',
    name: 'textarea',
    icon: 'icon-duohangshurukuang',
    schema: {
      title: '备注',
      type: 'string',
      format: 'textarea',
      widget: 'textarea',
    },
    setting: {
      placeholder: {
        title: '提示文字',
        type: 'string',
        description: '最多100字',
        placeholder: '请输入',
        default: '请输入',
        max: 100,
        order: 10,
      },
      default: {
        title: '默认值',
        format: '',
        type: 'string',
        widget: 'defaultValue',
        placeholder: '请选择',
        max: 8000,
        enum: ['custom', 'formula'],
        enumNames: ['自定义', '公式编辑'],
      },
    },
  },
];
