import { Input } from 'antd';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import React from 'react';
import cx from 'classnames';
import './index.less';

interface ISearchInputProps {
  value?: string;
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  debouce?: number;
  onChange: (keyword: string) => void;
  onSearch: (keyword: string) => void;
  searchIconPosition?: 'prefix';
}

interface ISearchInputState {
  value: string;
}

export default class SearchInput extends React.Component<
  ISearchInputProps,
  ISearchInputState
> {
  constructor(props: ISearchInputProps) {
    super(props);
    this.state = {
      value: '',
    };
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value || '';
    this.setState({
      value: val,
    });
    if (this.props.onChange) {
      this.props.onChange(val);
    }
  };

  onSearch = () => {
    if (this.props.onSearch) {
      this.props.onSearch(this.state.value);
    }
  };

  onClear = () => {
    this.setState({
      value: '',
    });
    if (this.props.onChange) {
      this.props.onChange('');
    }
  };

  render() {
    const { searchIconPosition } = this.props;
    const value =
      this.props.value === undefined ? this.state.value : this.props.value;
    const searchIcon = <SearchOutlined />;
    const clearIcon = (
      <CloseCircleOutlined
        style={{ cursor: 'pointer' }}
        onClick={this.onClear}
      />
    );
    const prefix = searchIconPosition === 'prefix' ? searchIcon : null;
    const suffix = value
      ? clearIcon
      : searchIconPosition === 'prefix'
      ? null
      : searchIcon;
    return (
      <div
        className={cx('searchInputContainer', {
          [this.props.className || '']: !!this.props.className,
        })}
        style={this.props.style}
      >
        <Input
          placeholder={this.props.placeholder}
          style={{ width: '100%' }}
          onPressEnter={this.onSearch}
          onChange={this.onChange}
          value={value}
          prefix={prefix}
          suffix={suffix}
        />
      </div>
    );
  }
}
