/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DataFilters\FilterModal\FixedInnerDepartment\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-31 14:07:27
 * :last editor: hyw
 * :date last edited: 2022-02-23 15:57:29
 */
import { useRef } from 'react';
import MultipleChoice from '@/components/MultipleChoice';
import Style from './index.less';

const FixedInnerContact = (props) => {
  const { onChange, value, filterType } = props;

  const childRef = useRef(null);
  const multiple = filterType === 'IN';

  const getDataBack = (data: any) => {
    const departments = data?.departments || [];
    const val = departments.map((department) => {
      return {
        key: department.id || department.key,
        value: department.name,
      };
    });

    onChange(val);
  };

  const departments = (value || []).map((item) => {
    return {
      key: item.key,
      name: item.value,
    };
  });

  return (
    <div>
      <div
        className={`${Style.field} fixed-inner-department`}
        onClick={() => {
          (childRef?.current as any)?.openModal();
        }}
      >
        {departments.map((department) => {
          return (
            <div key={department.key} className={Style['field-item']}>
              {department.name}
            </div>
          );
        })}
      </div>

      <MultipleChoice
        cRef={childRef}
        initData={{
          departments,
        }}
        getDataBack={getDataBack}
        tabs={['部门']}
        multiple={multiple}
        title="选择部门"
      />
    </div>
  );
};

export default FixedInnerContact;
