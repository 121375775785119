/*
 * :file description:
 * :name: /low-code-platform/tg-renderer/packages/form-render/src/components/RelationSettingModal/index.tsx
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-03-09 17:04:11
 * :last editor: 肖国同
 * :date last edited: 2023-02-23 11:37:03
 */
import React, { useState, useEffect } from 'react';
import { Modal, Checkbox } from 'antd';
import IconFont from '../IconFont';
import { getFormSchema } from '@/pages/generator/form/services/form-generator';
import { customJP } from '@/utils';
import c from './index.less';
import SortItem from '../../widgets/RelationSetting/sortItem';

const notSupportFilterWidgets = ['relation', 'table', 'divider', 'bizSuite'];
const notSupportFilterId = ['select_projectStage'];
const notSupportFilterBizAlias = ['order_related_product_details'];

const RelationSettingModal = ({ schema = {}, children, addons }) => {
  const { dataSource, fields = {} } = schema;
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [relationSchema, setRelationSchema] = useState(schema);

  const getRelationSchema = async () => {
    const { formCode } = dataSource?.target || {};
    const res = await getFormSchema({
      formCode,
    });
    const schema = customJP(res?.data?.content);
    setRelationSchema(schema);
    setTimeout(() => {
      setSelected(Object.values(fields).map((item) => item.id));
    }, 0);
  };

  const handleClick = () => {
    getRelationSchema();
    handleShowModal(true);
  };

  const handleShowModal = (visible: boolean) => {
    setVisible(visible);
  };

  const updateItems = (fileds: object, type?: string) => {
    const path = `properties.${schema.id}.fields`;
    addons.setValueByPath(path, fileds);
  };
  const handleDelete = (item) => {
    const path = `properties.${schema.id}.fields`;
    const properties = relationSchema?.fields || relationSchema?.properties;
    const selectItemKeys = Object.keys(properties)
      .filter((key: string) => selected.includes(key))
      .filter((key: string) => key !== item);
    const selectItems = selectItemKeys.reduce((obj, key) => {
      obj[key] =
        relationSchema?.properties?.[key] || relationSchema?.fields?.[key];
      return obj;
    }, {});
    setSelected(selectItemKeys);
    addons.setValueByPath(path, selectItems);
    handleShowModal(false);
  };

  useEffect(() => {
    if (schema) {
      setRelationSchema(schema);
      setSelected(Object.keys(schema?.fields));
    }
  }, []);

  const handleOk = () => {
    const path = `properties.${schema.id}.fields`;
    const selectItems = Object.keys(relationSchema?.properties)
      .filter((key: string) => selected.includes(key))
      .reduce((obj, key) => {
        obj[key] = relationSchema?.properties[key];
        return obj;
      }, {});
    addons.setValueByPath(path, selectItems);
    handleShowModal(false);
  };

  const handleChange = (value: string[]) => {
    setSelected(value);
  };
  return (
    <div className={c.container}>
      <ul className={c.list}>
        <SortItem
          items={Object.values(fields)}
          updateItems={updateItems}
          deleteItems={handleDelete}
          type={2}
        />
      </ul>
      <div onClick={handleClick}>{children}</div>
      <Modal
        title="设置关联字段"
        width={400}
        visible={visible}
        onOk={handleOk}
        onCancel={() => handleShowModal(false)}
        destroyOnClose
        closable={false}
        closeIcon={<IconFont type="icon-Close" />}
        className={c.settingRelation}
      >
        {
          <Checkbox.Group
            defaultValue={selected}
            value={selected}
            style={{ width: '100%' }}
            onChange={handleChange}
          >
            {Object.keys(relationSchema?.properties || {}).map((item) => {
              return relationSchema?.properties[item].title ? (
                <Checkbox
                  disabled={
                    notSupportFilterWidgets.includes(
                      relationSchema?.properties?.[item]?.widget,
                    ) ||
                    notSupportFilterId.includes(
                      relationSchema?.properties?.[item]?.id,
                    ) ||
                    notSupportFilterBizAlias.includes(
                      relationSchema?.properties?.[item]?.bizAlias,
                    )
                  }
                  value={relationSchema?.properties[item].id}
                >
                  {relationSchema?.properties[item].title}
                </Checkbox>
              ) : null;
            })}
          </Checkbox.Group>
        }
      </Modal>
    </div>
  );
};

export default RelationSettingModal;
