import fileExcel from '../../assets/form/file-excel.png';
import fileCommon from '../../assets/form/file-common.png';
import fileImg from '../../assets/form/file-img.png';
import filePpt from '../../assets/form/file-ppt.png';
import fileRar from '../../assets/form/file-rar.png';
import fileTxt from '../../assets/form/file-txt.png';
import fileVideo from '../../assets/form/file-video.png';
import fileWord from '../../assets/form/file-word.png';

export const getFileIcon = (fileType: string) => {
  switch (fileType) {
    case 'xls':
    case 'xlsx':
      return fileExcel;
    case 'bmp':
    case 'gif':
    case 'jpg':
    case 'jpeg':
    case 'pic':
    case 'png':
    case 'tif':
      return fileImg;
    case 'ppt':
    case 'pptx':
      return filePpt;
    case 'rar':
    case 'zip':
    case 'arj':
    case 'gz':
    case 'z':
      return fileRar;
    case 'txt':
      return fileTxt;
    case 'wmv':
    case 'asf':
    case 'asx':
    case 'rm':
    case 'rmvb':
    case 'mpg':
    case 'mpeg':
    case 'mpe':
    case 'avi':
    case 'mkv':
    case 'flv':
    case 'vob':
    case 'mp4':
    case 'm4v':
    case 'mov':
    case '3gp':
      return fileVideo;
    case 'doc':
    case 'docx':
      return fileWord;
    default:
      return fileCommon;
  }
};
