/*
 * :file description:
 * :name: \low-code-platform\src\pages\form\components\FilterOptionsSetting\SortOptions.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-19 16:27:47
 * :last editor: 黄艺
 * :date last edited: 2022-04-14 15:14:12
 */

import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { HolderOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import IconFont from '@/components/IconFont';
import c from './index.less';
import { Tooltip } from 'antd';

//初始化数据
const getItems = (props) => {
  return props.items || [];
};

// 重新记录数组顺序
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);

  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);
  return result;
};

// 设置样式
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '10px 0 0',
  textAlign: 'left',
  background: isDragging ? '#fafafa' : '#ffffff',
  ...draggableStyle,
});

const getListStyle = () => ({
  background: '#fff',
  // width: 250,
  maxHeight: 280,
  overflowY: 'auto',
});

export default class SortItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: getItems(props),
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.items) !== JSON.stringify(prevProps.items)) {
      this.setState({
        items: this.props.items || [],
      });
    }
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index,
    );

    this.setState({
      items,
    });

    const newObj = items?.reduce((result, cur) => {
      result[cur?.id] = cur;
      return result;
    }, {});
    this.props.updateItems?.(newObj, this.props.type);
  }

  handleDelete(schemaKey: string, type?: string) {
    this.props.deleteItems?.(schemaKey, type);
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot)}
            >
              {this.state.items?.map((item, index) => (
                // const isHidden = (!item.hideControls && item?.hidden) || item?.deleted;
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      className={c.containerSortItem}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      {/* {const isHidden = (!item.hideControls && item?.hidden) || item?.deleted;} */}
                      <div
                        key={item.key}
                        className={classnames(
                          c.listItem,
                          ((!item.hideControls && item?.hidden) ||
                            item?.deleted) &&
                            c.disabled,
                        )}
                      >
                        <HolderOutlined />
                        <div>
                          {(!item.hideControls && item?.hidden) ||
                          item?.deleted ? (
                            <Tooltip title="字段已删除或停用">
                              <span>{item.title}</span>
                            </Tooltip>
                          ) : (
                            <span>{item.title}</span>
                          )}
                        </div>
                        <IconFont
                          className={c.plus}
                          type="icon-jianshao"
                          onClick={() =>
                            this.handleDelete(item.id, this.props.type)
                          }
                        ></IconFont>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
