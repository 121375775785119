/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\form-render-core\src\FormDetail\index.tsx
 * :author: hyw
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-31 14:08:30
 * :last editor: hyw
 * :date last edited: 2022-04-25 22:50:09
 */
import {
  useEffect,
  useState,
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import _ from 'lodash';
import { get, keys } from 'lodash-es';
import {
  Drawer,
  DrawerProps,
  Button,
  Tabs,
  message,
  Modal,
  Form,
  Select,
  Popover,
  Input,
} from 'antd';
import { useModel } from 'umi';
import { nanoid } from 'nanoid';
import FormRender, { useForm } from 'form-render';
import copyTOClipboard from 'copy-text-to-clipboard';
import EditFormNew from '../edit-form';
import FormFlow from '../../../components/FormFlow';
import Approve from '../../../components/Approve';
import utils, { customJP, addrDecorator, getBtnAuth } from '../../../utils';
import formApi from '../../../services/api';
import c from './index.less';
import './index.less';
import cx from 'classnames';

import empty from '../../../assets/form/empty.png';
import bigPassImg from '../../../assets/images/bigPass.png';
import revokeImg from '../../../assets/images/revoke.png';
import refusePng from '../../../assets/images/refuse.png';

import Auth from '@/components/Auth';

import MultipleChoice from '@/components/MultipleChoice';
import IconFont from '../../../components/IconFont';
import CommentTab from '../../../components/Comment/CommentTab';
import CommentInput from '../../../components/Comment/BottomComment';
import RelationFormList from './RelationFormList';
import { judgeByCons } from '@/utils/auth';

import { widgets } from '../widgets';

const { TabPane } = Tabs;
const { Option } = Select;

const FormDetail = (props: DrawerProps & { [key: string]: ReactNode }, ref) => {
  const {
    children,
    instanceId,
    formcode,
    todoList,
    renderFormat,
    showModal,
    isTodo,
    cb = () => {},
  } = props;
  const renderForm = useForm();
  const [visible, setVisible] = useState(false);
  const [resultImage, setImage] = useState();
  const [title, setTitle] = useState('详情');
  const [instanceTitle, setInstanceTitle] = useState();
  const [schema, setSchema] = useState({});
  const [detailData, setDetailData] = useState({});
  const [detailInfo, setDetailInfo] = useState([]);
  const [processData, setProcessData] = useState();
  const [processStatu, setProcessStatu] = useState();
  // const [image, setImage] = useState();
  const formCode = formcode || utils.getFormCode();
  const [isDeleted, setIsDeleted] = useState(false);
  const [editKey, setEditKey] = useState(nanoid(12));

  // 用于判断是否是自己操作任务（有taskid则为有操作权限）
  const [ifHiddenAllBtn, setIfHiddenAllBtn] = useState(false);
  const [saveTaskId, setSaveTaskId] = useState('');
  const [isRevokeProcess, setIsRevokeProcess] = useState(false);
  const [requiredComment, setRequiredComment] = useState(false);

  const childRef = useRef(null);
  const editRef = useRef(null);

  const [isActivation, setIsActivation] = useState(false);

  const [options, setOptions] = useState([]);
  const [form] = Form.useForm();
  const [nodesList, setNodesList] = useState([]);
  const [processInstanceId, setProcessInstanceId] = useState('');
  const [processId, setProcessId] = useState('');
  const userInfo = localStorage.getItem('userInfo') || '';
  const tungeeUserInfo =
    sessionStorage.getItem(`tungee_userinfo_${window.__corpId}`) ?? '{}';
  // 创建该条数据的userId
  const [createUserId, setCreateUserId] = useState('');
  const [ifRevoke, setIfRevoke] = useState(false);
  const { userId = '' } = userInfo ? customJP(userInfo) : {};
  const { dtalkUserId } = customJP(tungeeUserInfo);

  // 被关联表单
  const [associatedForms, setAssociatedForms] = useState([]);

  // 评论相关
  const [isShowComment, setIsShowComment] = useState(false);
  const [commentReplyData, setCommentReplyData] = useState(null);
  const [commentItemId, setCommentItemId] = useState('');
  // 按钮权限
  const [isActiveAuth, setIsActiveAuth] = useState(false);

  console.log(userId, dtalkUserId, createUserId, '撤销按钮');
  // 伸缩弹窗功能
  const [drawerWidth, setDrawerWidth] = useState(600);
  const [isShrink, setIsShrink] = useState(false);

  const { check } = useModel('usePermission', (model) => ({
    check: model.check,
  }));

  const [upFlag, setUpFlag] = useState(0);

  // console.log(userId === createUserId);

  // console.log('resultImage', resultImage);

  useImperativeHandle(ref, () => ({
    toggleDetail: (flag: boolean) => {
      setVisible(flag);
    },
  }));

  useEffect(() => {
    getSchema();
    getDataByInstanceid();
  }, [formCode, instanceId]);

  useEffect(() => {
    if (!visible) return;
    // 判断当前表单中是否有激活流程权限

    let ifActiveAuth = getBtnAuth(formCode, 'ACTIVATE_PROCESS');

    setIsActiveAuth(ifActiveAuth);
    getSchema();
    getDataByInstanceid();
  }, [visible]);

  useEffect(() => {
    schema && getFormDetail();
  }, [schema]);

  // 普通表单：所有字段不可编辑
  // 流程表单：
  // 1.没有操作权限： 所有字段不可编辑
  // 2.有操作权限： 仅有权限的字段可以编辑

  const setReadOnlyStatus = (cont) => {
    if (!cont) return;
    let initContent = JSON.parse(cont);
    if (!Object.keys(initContent).length) return;
    Object.keys(initContent.properties)?.forEach((key) => {
      initContent.properties[key] = {
        ...initContent.properties[key],
        props: {
          readOnly: true,
          formCode: formCode,
          // hidden: match?.fieldBehavior === 'HIDDEN',
          // disabled: match?.fieldBehavior === 'DISABLED',
        },
      };
    });
    setSchema(addrDecorator(initContent));
  };

  const getSchema = () => {
    formApi.getFormSchema({ formCode }).then(async (res) => {
      if (!res?.data) return;
      setTitle(res.data.title);
      setProcessId(res?.data?.processId);
      if (res?.data?.processId) {
        // 如果没有操作权限，都是只读

        const accessInfo = await utils.getFieldsAccess({
          processId: res?.data?.processId,
          formInstanceId: instanceId,
        });
        let initContent = JSON.parse(res.data.content);
        const behaviorList = accessInfo?.data?.behaviorList;
        if (Array.isArray(behaviorList)) {
          Object.keys(initContent.properties)?.forEach((key) => {
            const match = behaviorList.find((t) => t.fieldId === key);
            initContent.properties[key] = {
              ...initContent.properties[key],
              props: {
                readOnly: match?.fieldBehavior === 'READONLY',
                formCode: formCode,
              },
              // hidden: match?.fieldBehavior === 'HIDDEN',
              // disabled: match?.fieldBehavior === 'DISABLED',
            };
          });
          setSchema(addrDecorator(initContent));
        } else {
          setReadOnlyStatus(res.data.content);
        }
      } else {
        setReadOnlyStatus(res.data.content);
      }
      setTitle(res.data.title);
      setAssociatedForms(get(res, 'data.relatedFormInfos', []));
    });
  };

  // 获取当前实例的全局配置
  const getGlobalSetting = (processId: number) => {
    formApi.getGlobalSetting({ processId: processId }).then((res: any) => {
      // 是否允许发起人撤回
      const {
        data: { isRevokeProcess },
      } = res;
      setIsRevokeProcess(isRevokeProcess);
    });
  };

  // 获取当前实例的全局配置
  const getCurrentNodeAuth = (
    processInstanceId: string,
    currentNodeId: string,
  ) => {
    formApi
      .getApprovalNodeProperty({ nodeId: currentNodeId, processInstanceId })
      .then((res: any) => {
        // 是否允许必填
        const {
          data: { requiredComment },
        } = res;
        setRequiredComment(requiredComment);
      });
  };

  // 根据流程实例id，获取用户的任务
  const getCurrentTaskByProcInsId = (processInstanceId: string) => {
    formApi
      .getCurrentTaskByProcInsId({
        procInsId: processInstanceId,
      })
      .then((res: any) => {
        const { data } = res;
        if (data?.taskId) {
          setSaveTaskId(data.taskId);
          setIfHiddenAllBtn(true);
          getNodes(data.taskId);
        } else {
          setIfHiddenAllBtn(false);
          setReadOnlyStatus(JSON.stringify(schema));
          setSaveTaskId('');
          // message.warning('当前任务不是你的操作权限');
        }
      })
      .catch(() => {
        setIfHiddenAllBtn(false);
        setReadOnlyStatus(JSON.stringify(schema));
        setSaveTaskId('');
      });
  };

  // 获取当前流程详细
  const viewProcessDiagram = (processId) => {
    formApi
      .viewProcessDiagram({
        processId: processId,
        formInstanceId: instanceId,
      })
      .then((resp) => {
        const { processStatus, results } = resp.data;
        setProcessStatu(processStatus);
        let userId = results?.[0]?.nodes?.[0]?.users?.[0]?.userId;
        setCreateUserId(userId || '');
      });
  };

  //根据实例id获取实例数据
  const getDataByInstanceid = () => {
    console.log('<<<<<<', instanceId, todoList);
    if (!instanceId) return;
    setUpFlag((val) => val + 1);
    if (todoList === 'todoList') {
      console.log(1);
      formApi
        .getDataForTodoList(
          {
            formCode,
            instanceId: instanceId,
          },
          {
            headers: {
              formCode,
            },
          },
        )
        .then((res: any) => {
          if (!res?.data?.processId) return;
          formApi
            .viewProcessDiagram({
              processId: res?.data?.processId,
              formInstanceId: instanceId,
            })
            .then((resp: any) => {
              setIfRevoke(false);
              if (resp.data.processResult === 1) {
                setImage(bigPassImg);
              } else if (resp.data.processResult === 2) {
                setImage(revokeImg);
                setIfRevoke(true);
              } else if (resp.data.processResult === 3) {
                setImage(refusePng);
              } else if (resp.data.processResult == 'null') {
                setImage(null);
              }
              const { processStatus, results, currentNodeId } = resp.data;
              setProcessStatu(processStatus);
              let userId = results?.[0]?.nodes?.[0]?.users?.[0]?.userId;
              setCreateUserId(userId || '');
              getCurrentNodeAuth(res?.data?.processInstanceId, currentNodeId);
            });
          if (res?.data?.processInstanceId) {
            getCurrentTaskByProcInsId(res?.data?.processInstanceId);
            setProcessInstanceId(res?.data?.processInstanceId);
          }
          if (res?.data?.processId) {
            getGlobalSetting(res?.data?.processId);
            getNodeList(res.data.processId, res?.data?.processInstanceId);
            setProcessId(res.data.processId);
          }
          // 获取全局配置
        });
    } else {
      formApi
        .getDataByInstanceid(
          {
            formCode,
            instanceId: instanceId,
          },
          {
            headers: {
              formCode,
            },
          },
        )
        .then((res: any) => {
          if (!res?.data?.processId) return;
          formApi
            .viewProcessDiagram({
              processId: res?.data?.processId,
              formInstanceId: instanceId,
            })
            .then((resp: any) => {
              console.log('.....', typeof resp.data.processResult);
              setIfRevoke(false);
              if (resp.data.processResult === 1) {
                setImage(bigPassImg);
              } else if (resp.data.processResult === 2) {
                setImage(revokeImg);
                setIfRevoke(true);
              } else if (resp.data.processResult === 3) {
                setImage(refusePng);
              } else if (typeof resp.data.processResult === 'object') {
                setImage(null);
              }
              const { processStatus, results, currentNodeId } = resp.data;
              setProcessStatu(processStatus);
              let userId = results?.[0]?.nodes?.[0]?.users?.[0]?.userId;
              setCreateUserId(userId || '');
              getCurrentNodeAuth(res?.data?.processInstanceId, currentNodeId);
            });
          if (res?.data?.processInstanceId) {
            getCurrentTaskByProcInsId(res?.data?.processInstanceId);
            setProcessInstanceId(res?.data?.processInstanceId);
          }
          if (res?.data?.processId) {
            getGlobalSetting(res?.data?.processId);
            getNodeList(res.data.processId, res?.data?.processInstanceId);
            setProcessId(res.data.processId);
            // 只是为了获取当前的流程状态，判断是否有激活功能
            // viewProcessDiagram(res.data.processId);
          }
          // 获取全局配置
        });
    }
  };

  const getFormDetail = async () => {
    console.log(`获取详情 实例id: ${instanceId}`);
    if (todoList === 'todoList') {
      console.log(3);
      const res = await formApi.getDataForTodoList(
        {
          formCode,
          instanceId,
        },
        {
          headers: {
            formCode,
          },
        },
      );
      setIsDeleted(!res.data);
      res?.data?.content && setDetailInfo(customJP(res.data.content));
      let contentObj = customJP(res.data?.content);
      renderForm.setValues(contentObj);
      res?.data && setDetailData(res.data);
      res?.data && setInstanceTitle(res.data.title);
      // console.log('详情结果：', customJP(res?.data?.content));
    } else {
      console.log(4);
      const res = await formApi.getFromInstanceDetail(
        {
          formCode,
          instanceId,
        },
        {
          headers: {
            formCode,
          },
        },
      );
      setIsDeleted(!res.data);
      if (!res?.data?.content) return;
      let contentObj = customJP(res.data?.content);
      renderForm.setValues(contentObj);
      res?.data?.content && setDetailInfo(customJP(res.data.content));
      res?.data && setDetailData(res.data);
      res?.data && setInstanceTitle(res.data.title);
      return; // 回显时关联表单详情从组件内请求接口
      if (!keys(contentObj).length) return;
      const promises = _.compact(
        keys(contentObj).map((fieldKey) => {
          const field = schema?.properties?.[fieldKey];
          if (!field || !contentObj?.[fieldKey]) return undefined;
          const { widget } = field;
          const {
            formCode: relevanceFormCode,
            instanceId: relevanceInstanceId,
          } = contentObj?.[fieldKey] || {};
          return widget === 'relation' &&
            relevanceFormCode &&
            relevanceInstanceId
            ? {
                key: fieldKey,
                detailProm: formApi.getFromInstanceDetail(
                  {
                    formCode: relevanceFormCode,
                    instanceId: relevanceInstanceId,
                  },
                  {
                    headers: {
                      formCode: relevanceFormCode,
                    },
                  },
                ),
                schemaProm: formApi.getFormSchema({
                  formCode: relevanceFormCode,
                }),
              }
            : undefined;
        }),
      );
      if (promises?.length) {
        const schemaAry = await Promise.all(promises.map((v) => v.schemaProm));
        const relationInfoAry = await Promise.all(
          promises.map((v) => v.detailProm),
        );
        console.log('promises: ', promises);
        console.log('schemaAry: ', schemaAry);
        console.log('relationInfoAry: ', relationInfoAry);
        keys(contentObj)?.forEach((t, i) => {
          let matchIndex = promises.findIndex((p) => p.key === t);
          if (matchIndex === -1) return;
          contentObj[t].schema = schemaAry[matchIndex].data?.content
            ? customJP(schemaAry[matchIndex].data.content)
            : undefined;
          contentObj[t].relationDetail = relationInfoAry[matchIndex]?.data
            ?.content
            ? customJP(relationInfoAry[matchIndex].data.content)
            : undefined;
        });
        setDetailInfo(contentObj);
        res?.data && setDetailData(res.data);
      } else {
        setDetailInfo(contentObj);
        res?.data && setDetailData(res.data);
      }
      // console.log('详情结果：', customJP(res?.data?.content));
    }
  };

  const onEdit = () => {
    setEditKey(nanoid(12));
    _.delay(() => {
      editRef.current.showModal();
    }, 0);
  };

  const onDelete = () => {
    utils.doubleCheck({
      title: '确认删除吗',
      content: <>删除后不可恢复，请谨慎操作</>,
      onOk: () => {
        formApi
          .deleteFormBasedInstances(
            {
              formCode,
              instanceIds: [instanceId],
            },
            {
              headers: {
                formCode,
              },
            },
          )
          .then((res) => {
            if (res.code === 200) {
              message.success('删除成功');
              // if (isTodo) {
              //   setVisible(false);
              // }
              // 删除成功直接关闭弹窗
              setVisible(false);
              _.delay(() => {
                cb();
                getDataByInstanceid();
              }, 1000);
            }
          });
      },
    });
  };

  const onClose = () => {
    setVisible(false);
    getFormDetail();
  };

  const onTabChange = (e) => {
    console.log(e);
  };

  // 撤销流程
  const onRepeal = async () => {
    const status = await renderForm.submit();
    if (status.errors.length) return;
    utils.doubleCheck({
      title: '你确定要撤销流程吗？',
      content: <>撤销后流程终止，管理员可以重新激活流程</>,
      onOk: () => {
        console.log('onOk');
        formApi
          .repealFlow({
            formCode,
            formInstanceId: instanceId,
            procInsId: processInstanceId,
            taskId: saveTaskId,
            content: JSON.stringify(renderForm.getValues(true)),
          })
          .then((res) => {
            if (res.code === 200) {
              message.success('撤销成功');

              if (isTodo) {
                setVisible(false);
              }
              _.delay(() => {
                cb();
                getDataByInstanceid();
              }, 1000);
            }
          });
      },
    });
  };

  const getNodeList = (processId: number, pId: string) => {
    formApi
      .getNodeList({
        processId,
        processInstanceId: pId,
        formInstanceId: instanceId,
      })
      .then((res) => {
        const { data } = res;
        setNodesList(data || []);
        console.log(res);
      });
  };
  // 打开激活流程弹窗
  const onActivationModel = () => {
    setIsActivation(true);
  };
  const handleCancelActivationModel = () => {
    setIsActivation(false);
  };

  const handleOkActivationModel = () => {
    form.submit();
    // formInstanceId: instanceId,
    //   taskId: saveTaskId,
    if (form.getFieldValue('nodes')) {
      formApi
        .recreateProcessInstance({
          formInstanceId: instanceId,
          nodeId: form.getFieldValue('nodes'),
          processId: processId,
          processInstanceId: processInstanceId,
        })
        .then((res) => {
          const { code } = res;
          if (code === 200) {
            message.success('激活成功');
            setIsActivation(false);
            _.delay(() => {
              cb();
              getDataByInstanceid();
            }, 1000);
          }
        })
        .catch((err) => {});
    }
  };
  // 转交任务
  const onDeliver = async () => {
    console.log('onDeliver');
    const status = await renderForm.submit();
    if (status.errors.length) return;
    (childRef?.current as any)?.openModal();
  };

  // 数据回调
  const getDataBack = (data: any) => {
    formApi
      .transferFlow({
        assignee: data?.users?.[0]?.userId,
        content: JSON.stringify(renderForm.getValues(true)),
        formCode,
        formInstanceId: instanceId,
        taskId: saveTaskId,
      })
      .then((res) => {
        if (res.code === 200) {
          setVisible(false);
          message.success('转交成功');
        }
      });
  };
  // 可回退节点  (暂无退回节点)
  const getNodes = (taskId: string) => {
    formApi
      .returnableNodes({
        taskId: taskId,
      })
      .then((res: any) => {
        const { code, data } = res;
        if (code === 200) {
          setOptions(data || []);
        }
      });
  };
  const emptyTip = () => {
    return (
      <div className={c.empty}>
        <div>
          <img width="320" height="320" src={empty} />
          <span>该表单数据已被删除，无法查看</span>
        </div>
      </div>
    );
  };

  const copyShareUrl = () => {
    copyTOClipboard(window.location.href);
  };

  const renderSharePopover = () => {
    return (
      <div className={c.popover_content}>
        <Input disabled value={window.location.href} />
        <Button onClick={copyShareUrl} className={c.btn}>
          点击复制
        </Button>
      </div>
    );
  };

  const footerDom = isShowComment ? (
    <CommentInput
      commentReplyData={commentReplyData}
      instanceId={instanceId}
      onShow={() => {
        setIsShowComment(false);
      }}
      onBlur={() => {
        setIsShowComment(false);
      }}
      showInput={isShowComment}
    />
  ) : isDeleted ? null : (
    <div
      className={cx(c.form_detail_footer, {
        [c.form_page_detail_footer]: renderFormat === 'page',
      })}
    >
      <div>
        {judgeByCons({
          checkCode: `FORM_${formCode}_EDIT`,
          withConsArr: [processStatu !== 1, props.processStatus !== 1],
        }) && (
          <span className={c.btn_with_icon} onClick={onEdit}>
            <IconFont type="icon-bianji1" />
            <span>编辑</span>
          </span>
        )}
        {judgeByCons({
          checkCode: `FORM_${formCode}_DEL`,
        }) && (
          <span className={c.btn_with_icon} onClick={onDelete}>
            <IconFont type="icon-shanchu" />
            <span>删除</span>
          </span>
        )}

        {window.__isMicroApp__ && (
          <>
            <span
              className={c.btn_with_icon}
              onClick={() => {
                setIsShowComment(true);
              }}
            >
              <IconFont type="icon-a-weidu2x" />
              <span>评论</span>
            </span>
            {/* <Popover
              trigger="click"
              content={renderSharePopover()}
              title="分享"
            >
              <span className={c.btn_with_icon} onClick={() => {}}>
                <IconFont type="icon-a-16" />
                <span>分享</span>
              </span>
            </Popover> */}
          </>
        )}
      </div>
      <div>
        {/* 是否允许发起人撤回 */}
        {judgeByCons({
          checkCode: `FORM_${formCode}_ACTIVATE_PROCESS`,
          orConsArr: [userId === createUserId, dtalkUserId === createUserId],
          withConsArr: [
            processId,
            isRevokeProcess,
            processStatu === 1 || props.processStatus === 1,
            !ifRevoke,
          ],
        }) && (
          <Button shape="round" onClick={onRepeal}>
            撤销
          </Button>
        )}
        {judgeByCons({
          withConsArr: [processId, ifHiddenAllBtn],
        }) && (
          <>
            <Button shape="round" onClick={onDeliver}>
              转交
            </Button>
            {options.length != 0 && (
              <Approve
                type="return"
                instanceId={instanceId}
                taskId={saveTaskId}
                nodesOptions={options}
                formCode={formCode}
                refreshFun={() => getDataByInstanceid()}
                formRef={renderForm}
              >
                <Button shape="round">退回</Button>
              </Approve>
            )}

            <Approve
              type="refuse"
              instanceId={instanceId}
              taskId={saveTaskId}
              requiredComment={requiredComment}
              formCode={formCode}
              refreshFun={() => getDataByInstanceid()}
              formRef={renderForm}
            >
              <Button shape="round">拒绝</Button>
            </Approve>
            <Approve
              type="agree"
              instanceId={instanceId}
              taskId={saveTaskId}
              requiredComment={requiredComment}
              formCode={formCode}
              refreshFun={() => getDataByInstanceid()}
              formRef={renderForm}
            >
              <Button shape="round" type="primary">
                同意
              </Button>
            </Approve>
          </>
        )}

        {judgeByCons({
          checkCode: `FORM_${formCode}_ACTIVATE_PROCESS`,
          orConsArr: [processStatu == 2, processStatu == 3],
        }) && (
          <Button shape="round" onClick={onActivationModel}>
            激活流程
          </Button>
        )}
      </div>
    </div>
  );
  const detailContent = (
    <>
      {detailData && !isDeleted ? (
        <div className={c.form_detail_header}>
          {/* <div className={c.form_detail_circle}>
            <LeftOutlined />
          </div> */}
          <div className={c.commit_info}>
            <div className={c.return_icon} onClick={onClose}>
              <IconFont type="icon-fanhui_cu" />
            </div>
            <div>
              <div className={c.form_approve_contain}>
                <div
                  style={{
                    fontFamily: 'PingFangSC-Medium',
                    color: '#171A1D',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                  }}
                >
                  {title}
                </div>
                {processStatu === 1 ? (
                  <div className={c.approve_contain}>审批中</div>
                ) : null}
              </div>
              <div
                style={{
                  margin: '6px 0 0',
                  fontFamily: 'PingFangSC-Regular',
                  color: 'rgba(23,26,29,0.40)',
                  lineHeight: '22px',
                  fontSize: '14px',
                }}
              >
                {detailData?.createUserName} 提交于 {detailData?.gmtCreate}
              </div>
            </div>
          </div>
          {resultImage ? (
            <img className={c.bigPassImg} src={resultImage} />
          ) : null}
        </div>
      ) : (
        emptyTip()
      )}
      {detailData && !isDeleted && <div className={c.wrapper}></div>}
      {detailData && !isDeleted ? (
        <Tabs
          className="form_detail_tabs"
          defaultActiveKey="1"
          onChange={onTabChange}
        >
          <TabPane tab="基本信息" key="1">
            <FormRender
              schema={schema}
              form={renderForm}
              widgets={{ ...widgets }}
            ></FormRender>
          </TabPane>
          {detailData?.processId ? (
            <TabPane tab="流程" key="2">
              <FormFlow
                formcode={formCode}
                instanceId={instanceId}
                key={upFlag}
                todoList={todoList}
              ></FormFlow>
            </TabPane>
          ) : null}
          {associatedForms.map((item) => {
            const { formCode, title, relateFormCode } = item;
            return (
              <TabPane tab={title} key={formCode}>
                <RelationFormList
                  close={() => setVisible(false)}
                  renderType="drawer"
                  title={title}
                  instanceTitle={instanceTitle}
                  formCode={relateFormCode}
                  relateFormCode={formCode}
                  instanceId={instanceId}
                  showModal={showModal}
                />
              </TabPane>
            );
          })}
          {window.__isMicroApp__ && (
            <TabPane key="3" tab="评论">
              <CommentTab
                instanceId={instanceId}
                isShowComment={isShowComment}
                openShowComment={(data: any) => {
                  setCommentReplyData(data);
                  setIsShowComment(true);
                }}
              />
            </TabPane>
          )}
        </Tabs>
      ) : null}
    </>
  );

  //  改变详情弹窗功能
  const changeWidth = (bool) => {
    setDrawerWidth(bool ? 600 : window.innerWidth > 1366 ? 1100 : 1000);
    setIsShrink(!bool);
  };

  const genDomByRenderFormat = () => {
    let finalContent = null;
    switch (renderFormat) {
      case 'drawer':
        finalContent = (
          <>
            <Button
              className={c.create}
              type="primary"
              onClick={() => setVisible(true)}
            >
              {children}
            </Button>
            <Drawer
              className="form_detail_drawer"
              placement="right"
              width={drawerWidth}
              maskStyle={{
                background: 'transparent',
              }}
              id="form_detail_drawer"
              onClose={onClose}
              visible={visible}
              destroyOnClose={true}
              footer={footerDom}
              getContainer={false}
            >
              <div className={c.enlargeOrshrink}>
                <IconFont
                  type="icon-zhankai1"
                  className={isShrink ? c.transForm : ''}
                  onClick={() => changeWidth(isShrink)}
                />
                {/* <img src={shrink} alt="" /> */}
              </div>
              {detailContent}
            </Drawer>
          </>
        );
        break;
      case 'page':
        finalContent = (
          <>
            {detailContent}
            {footerDom}
          </>
        );
        break;
    }
    return finalContent;
  };

  return (
    <div className={c.t_create_form}>
      <>{genDomByRenderFormat()}</>
      <EditFormNew
        key={editKey}
        ref={editRef}
        renderFormat="drawer"
        instanceId={instanceId}
        formcode={formCode}
        cb={() => {
          getFormDetail();
          _.delay(() => {
            getDataByInstanceid();
            cb();
          }, 1000);
        }}
        todoList={todoList}
      ></EditFormNew>

      <MultipleChoice
        cRef={childRef}
        initData={[]}
        getDataBack={getDataBack}
        tabs={['人员']}
        title="选择人员"
      />

      <Modal
        maskClosable={false}
        title="激活流程"
        width={400}
        visible={isActivation}
        onOk={handleOkActivationModel}
        okText="确定"
        cancelText="取消"
        onCancel={handleCancelActivationModel}
        destroyOnClose={true}
        closeIcon={<IconFont type="icon-Close" />}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="nodes"
            label="选择激活节点"
            rules={[{ required: true }]}
          >
            <Select
              placeholder="请选择"
              // onChange={onGenderChange}
              // allowClear
            >
              {nodesList.map((val) => {
                return (
                  <Option value={val.id} key={val.id}>
                    {val.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default forwardRef(FormDetail);
