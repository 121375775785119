/*
 * :file description:
 * :name: \low-code-platform\src\components\renderer\TSelect\index.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 10:35:31
 * :last editor: gaoch
 * :date last edited: 2021-11-17 16:32:24
 */

import React, { ReactNode } from 'react';
import { Select, SelectProps } from 'antd';
import c from './index.less';

const DEFAULT_SELECT_CONFIG = {
  placeholder: '请选择',
  minWidth: 240,
  onChange: () => {},
};

const TSelect = (props: SelectProps<any> & { [key: string]: any }) => {
  const { widget, onChange } = props;
  const { placeholder, minWidth } = DEFAULT_SELECT_CONFIG;

  const options = props?.enum?.map((item, idx) => ({
    value: item,
    label: props.enumnames[idx],
  }));

  const matchedComponent = (): ReactNode => {
    const { Option } = Select;

    const onSearch = (val: string) => {
      console.log('search:', val);
    };

    const handleSingleChange = (val, opt) => {
      if (props.rendertype === 'create') {
        onChange && onChange(opt);
      } else {
        // if (['lastProcessResult', 'lastProcessStatus'].includes(props.id)) {
        //   onChange && onChange(opt?.value);
        // } else {
        //   onChange && onChange(opt?.children);
        // }
        onChange && onChange(opt?.value);
      }
      console.log(val, opt, props);
    };

    const handleMultiChange = (val, opt) => {
      if (props.rendertype === 'create') {
        onChange && onChange(opt);
      } else {
        onChange && onChange(opt.map((v) => v.value));
      }
      console.log(val, opt, props);
    };

    const handleSingleClear = () => {
      onChange && onChange(undefined);
    };

    const handleMultiClear = () => {
      onChange && onChange(undefined);
    };

    let comp = null;
    switch (widget) {
      case 'select':
        comp = (
          <Select
            allowClear
            showSearch
            placeholder={placeholder}
            style={{ minWidth }}
            onSearch={onSearch}
            filterOption={(input, option) => {
              return true;
            }}
            {...props}
            onChange={handleSingleChange}
            onClear={handleSingleClear}
          >
            {options.map((v, idx) => {
              return (
                <Option key={idx} value={v.value} disabled={v.disabled}>
                  {v.label}
                </Option>
              );
            })}
          </Select>
        );
        break;
      case 'multiSelect':
        comp = (
          <Select
            allowClear
            mode="multiple"
            showSearch
            placeholder={placeholder}
            style={{ minWidth }}
            onSearch={onSearch}
            filterOption={(input, option) => {
              return true;
            }}
            {...props}
            onChange={handleMultiChange}
            onClear={handleMultiClear}
          >
            {options.map((v, idx) => {
              return (
                <Option key={idx} value={v.value} disabled={v.disabled}>
                  {v.label}
                </Option>
              );
            })}
          </Select>
        );
        break;
    }
    return comp;
  };

  return <div className={c.t_select}>{matchedComponent()}</div>;
};

export default TSelect;
