/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\Sift_InputNumberRange\index.tsx
 * author: gaoch
 * copyright: (c) 2022, Tungee
 * :date created: 2022-01-07 19:20:33
 * :last editor: gaoch
 * :date last edited: 2022-02-15 15:00:05
 */
import React, { useState, useEffect } from 'react';
import { Input, InputNumber, Select } from 'antd';
import { isNumber, isArray, isEmpty, get } from 'lodash-es';
import { isJSON, dropSearchTypeProp, customJP } from '../../utils';
import {
  SearchTypeValue,
  SEARCH_TYPE_OPTIONS_OF_E_L_G_R,
} from '../../constant';
import { IFormCompProps } from '../../typings';
import './index.less';

type WidgetProps = IFormCompProps;

const { Option } = Select;

const DEFAULT_CONFIG = {
  minPlaceholder: '最小值',
  maxPlaceholder: '最大值',
  placeholder: '请输入',
  allowClear: true,
  onChange: () => {},
};

const InputNumberRangeWidget = (props: WidgetProps) => {
  const { value, onChange = () => {}, schema = {} } = props;
  const { searchType: initialSearchType, value: initialValue = [] } = isJSON(
    value,
  )
    ? customJP(value)
    : { searchType: undefined, value: undefined };
  const { limitDot, hideSearchType = false } = schema;

  const { minPlaceholder, maxPlaceholder, placeholder } = DEFAULT_CONFIG;

  const defaultValue = SearchTypeValue.EQUAL;

  const [searchType, setSearchType] = useState(
    initialSearchType || defaultValue,
  );

  const isRange = () => {
    return searchType === SearchTypeValue.RANGE;
  };

  const [num, setNum] = useState(!isRange() ? initialValue : undefined);
  const [min, setMin] = useState(initialValue[0]);
  const [max, setMax] = useState(initialValue[1]);

  // 搜索类型为范围时，判断最大最小值是否都存在or都不存在
  const isAccordant = () => {
    return (isNumber(max) && isNumber(min)) || (!max && !min);
  };

  const handleChange = (val, type: string) => {
    if (type === 'min') {
      setMin(val);
    } else {
      setMax(val);
    }
  };

  useEffect(() => {
    if (isRange() && !isAccordant()) return;
    const payload = {
      searchType,
      value: isRange() ? [min, max] : num,
    };
    if (!isRange()) {
      onChange(
        isNumber(num)
          ? JSON.stringify(dropSearchTypeProp(payload, hideSearchType))
          : '',
      );
    } else {
      onChange(
        isNumber(min) && isNumber(max)
          ? JSON.stringify(dropSearchTypeProp(payload, hideSearchType))
          : '',
      );
    }
  }, [searchType, min, max, num]);

  useEffect(() => {
    if (value && isJSON(value)) {
      const valueObj = customJP(value);
      if (isEmpty(valueObj)) return;
      if (
        !(
          valueObj.searchType === searchType &&
          ((get(valueObj, 'value[0]') == min &&
            get(valueObj, 'value[1]') == max) ||
            get(valueObj, 'value') === num)
        )
      ) {
        setSearchType(valueObj.searchType);
        if (isArray(valueObj.value)) {
          const [initMin, initMax] = valueObj.value;
          setMin(initMin);
          setMax(initMax);
        } else {
          setNum(valueObj.value);
        }
      }
    } else {
      setNum(undefined);
      setMin(undefined);
      setMax(undefined);
    }
  }, [value]);

  const limitDecimals = (val: string | number | undefined): string => {
    const regStr = `/^(\\-)*(\\d+)\\.(\\d{0,${limitDot || 0}}).*$/`;
    const reg = eval(regStr);

    if (typeof val === 'string') {
      return !isNaN(Number(val)) ? val.replace(reg, '$1$2.$3') : '';
    } else if (typeof val === 'number') {
      return !isNaN(val) ? String(val).replace(reg, '$1$2.$3') : '';
    } else {
      return '';
    }
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e);
    setNum(undefined);
    setMin(undefined);
    setMax(undefined);
  };

  return (
    <Input.Group compact className="fr-group">
      {!hideSearchType && (
        <Select
          style={{ width: '30%' }}
          // allowClear
          defaultValue={defaultValue}
          value={searchType}
          onChange={handleSearchTypeChange}
        >
          {SEARCH_TYPE_OPTIONS_OF_E_L_G_R.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      )}
      <div style={{ width: '70%' }}>
        {isRange() ? (
          <div>
            <InputNumber
              style={{ width: 'calc(50% - 10px)' }}
              placeholder={minPlaceholder}
              formatter={limitDecimals}
              parser={limitDecimals}
              value={min}
              onChange={(e) => handleChange(e, 'min')}
            />
            <span style={{ margin: '0 5px' }}>~</span>
            <InputNumber
              style={{ width: 'calc(50% - 10px)' }}
              placeholder={maxPlaceholder}
              formatter={limitDecimals}
              parser={limitDecimals}
              value={max}
              onChange={(e) => handleChange(e, 'max')}
            />
          </div>
        ) : (
          <InputNumber
            style={{ width: '100%' }}
            placeholder={placeholder}
            formatter={limitDecimals}
            parser={limitDecimals}
            value={num}
            onChange={(e) => setNum(e)}
          />
        )}
      </div>
    </Input.Group>
  );
};

export default InputNumberRangeWidget;
