/*
 * :file description:
 * :name: \low-code-platform\src\utils\TransformNotification\transformNotification.tsx
 * :author: PakJeon
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-16 16:35:40
 * :last editor: hyw
 * :date last edited: 2022-02-21 10:39:51
 */
import { notification, Button } from 'antd';
import './styles.less';
import { dowmLoadFile } from '../index';
import IconFont from '@/components/IconFont';

export enum IMPORT_STATUS {
  BEFORE, // 未开始
  DOING, // 正在导入
  PROGRESS, // 导入完成，正在触发流程
  SUCCESS, // 导入成功
  FAIL, // 导入失败
}

export enum EXPORT_STATUS {
  BEFORE, // 未开始
  DOING, // 正在导出
  SUCCESS, // 导出成功
  FAIL, // 导出失败
}

// 导入结束后的 notification 通知
export const handleImportNotification = (payload: any) => {
  const {
    data: {
      formTitle = '表单名称',
      importFailCount = 0,
      importFailFileUrl = '',
      importInsertCount = 0,
      importStatus = IMPORT_STATUS.SUCCESS,
      importType = 0,
      importUpdateCount = 0,
      progressRate = 0,
    },
    taskId,
  } = payload;
  if (importStatus === IMPORT_STATUS.PROGRESS) {
    notification.success({
      message: '导入完成，开始触发流程',
      key: taskId,
      icon: <IconFont type="icon-tongguo" />,

      description: (
        <div className="importDescription">
          流程提交成功
          <span className="importSuccessCount">{importInsertCount}</span>条，
          {!!importFailCount && (
            <>
              失败<span className="importFailCount">{importFailCount}</span>条，
            </>
          )}
          系统将逐条触发流程，请稍后查看
        </div>
      ),
    });
  }
  if (importStatus === IMPORT_STATUS.SUCCESS) {
    const downloadBtn =
      !!importFailCount && importFailFileUrl ? (
        <Button
          type="primary"
          size="small"
          onClick={() => dowmLoadFile(importFailFileUrl)}
        >
          下载失败文件
        </Button>
      ) : null;
    notification.success({
      message: '导入完成',
      btn: downloadBtn,
      key: taskId,
      description: (
        <div className="importDescription">
          成功导入
          <span className="importSuccessCount">
            {importInsertCount + importUpdateCount}
          </span>
          条数据到「<span>{formTitle}</span>」：共
          {!!importUpdateCount && (
            <>
              更新<span className="importUpdateCount">{importUpdateCount}</span>
              条数据，
            </>
          )}
          <>
            新增<span className="importSuccessCount">{importInsertCount}</span>
            条数据
          </>
          {!!importFailCount && (
            <>
              ，失败<span className="importFailCount">{importFailCount}</span>条
            </>
          )}
        </div>
      ),
    });
  }
  if (importStatus === IMPORT_STATUS.FAIL) {
    notification.error({
      message: '导入失败',
      description: `${formTitle}导入失败，请重试`,
      key: taskId,
      btn: <Button onClick={() => notification.destroy()}>知道了</Button>,
    });
  }
};

// 导出结束后的 notification 通知
export const handleExportNotificcation = (payload: any) => {
  const { data } = payload;
  const { exportStatus, formTitle, exportFailReason, fileUrl } = data;
  if (!window.__isMicroApp__) {
    if (exportStatus === EXPORT_STATUS.SUCCESS) {
      notification.success({
        message: '导出成功',
        description: `「${formTitle}」导出文件已生成。文件下载链接将于7日后失效，请尽快下载。`,
        btn: <Button onClick={() => dowmLoadFile(fileUrl)}>下载文件</Button>,
      });
    } else if (exportStatus === EXPORT_STATUS.FAIL) {
      notification.warning({
        message: '导出失败',
        description: exportFailReason || '导出失败，请重试',
      });
    }
  }
};
