/*
 * :file description:
 * :name: /low-code-platform/tg-renderer/packages/form-render/src/widgets/Divider/index.tsx
 * :author: 肖国同
 * :copyright: (c) 2023, Tungee
 * :date created: 2023-02-06 13:58:09
 * :last editor: 肖国同
 * :date last edited: 2023-02-18 16:18:47
 */

import { FC } from 'react';
import { WidgetProps } from 'typings';
import c from './index.less';

const Divider: FC<WidgetProps> = (props) => {
  const { value, schema = {} } = props;

  return (
    <div className={c.container}>
      <div className={schema?.dividerType === 2 ? c.line2 : c.line1}></div>
    </div>
  );
};

export default Divider;
