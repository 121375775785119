/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\form-render-core\src\create-form\form.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-14 09:30:28
 * :last editor: hyw
 * :date last edited: 2022-04-11 20:10:11
 */
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import FormRender, { useForm } from 'form-render';
import { Timeline, Space, Button, message } from 'antd';
import { keys, get, omit } from 'lodash-es';

import { widgets } from '../widgets';
import flowNodeTemp from './flowNodeTemplate';
import utils, {
  customJP,
  addrDecorator,
  defineTitle,
  flattenSchema,
  transformSchema,
} from '../../../utils';
import formApi from '../../../services/api';

import { _calFormula } from '../../../widgets/DefaultValueSelector/components/formula-editor/common/formulaEditor';
import { useFormula } from '@/hooks/formula';

import c from './index.less';
import { IValue, SettingType } from '../../../widgets/DataTitle';
import { IObj } from 'typings';
import { Pass } from 'codemirror';
import { CONTRACT_ORDER_BIZ_TYPE } from '../../../constant';

interface IFormProps {
  formCode: string | undefined;
  renderFormat: string;
  [key: string]: any;
}

const CreateForm = (props: IFormProps, ref) => {
  const {
    formcode: injectedFormCode,
    init = () => {},
    setLoading = () => {},
    cb = () => {},
    initData,
    renderFormat = 'drawer',
  } = props;
  const formRef = useRef();
  const form = useForm();
  const [load, setLoad] = useState(false);
  const [hasGateWay, setHasGateway] = useState(false);
  const [formDataLive, setFromDataLive] = useState(null);
  const [fouceWidgetRef] = useFormula(form);
  const formCode = injectedFormCode;
  // 实例数据
  const [title, setTitle] = useState('');
  const [gmtCreate, setGmtCreate] = useState();
  const [insData, setInsData] = useState();
  const [flowInfo, setFlowInfo] = useState([]);
  const [processId, setProcessId] = useState('');
  const [propsSettings, setPropsSettings] = useState<IValue[]>([]);
  const [bizType, setBizType] = useState();

  const doSubmit = () => {
    setLoad(true);
    form.submit();
  };

  useImperativeHandle(ref, () => ({
    doCreate: () => {
      doSubmit();
    },
  }));

  const initAssociationForm = (schema) => {
    if (!schema) return;
    const properties = schema.properties;
    const fields = keys(schema.properties);
    const match = fields.find((key) => {
      let field = properties[key];
      return (
        field.widget === 'relation' &&
        get(field, 'dataSource.target.formCode') === initData?.formCode
      );
    });
    match && form.setValues({ [match]: omit(initData, ['relateFormCode']) });
  };

  useEffect(() => {
    formRef?.current?.resetFields();
    getFormSchema();
  }, []);

  useEffect(() => {
    if (hasGateWay && insData && formDataLive) {
      const requiredKeys = keys(insData.properties)
        .map((k) => {
          let flag = insData?.properties?.[k]?.required;
          return flag ? k : undefined;
        })
        .filter((t) => !!t);
      const isAllFillIn = requiredKeys.every((key) => {
        if (key.includes('phone_')) {
          return !!formDataLive[key]?.value;
        } else {
          return !!formDataLive[key];
        }
      });
      isAllFillIn && getExpectProcessDiagram(processId, formDataLive);
    }
  }, [hasGateWay, formDataLive]);

  const getExpectProcessDiagram = async (pid?: string, cont?: any) => {
    let content = cont || formDataLive;
    const res = await formApi.expectProcessDiagram({
      processId: pid || processId,
      formContent: JSON.stringify(content),
    });
    console.log('流程节点哦 ', res);
    const nodes = get(res, 'data.nodes');
    nodes && setFlowInfo(nodes);
  };

  const getGateWayStatus = (pid: string) => {
    return formApi.hasGateWay({
      processId: pid,
    });
  };

  const getFormSchema = () => {
    formApi.getFormSchema({ formCode }).then(async (res) => {
      if (!res?.data) return;
      const resContent = customJP(res.data.content);
      const pId = get(res, 'data.processId');
      if (pId) {
        const status = await getGateWayStatus(pId);
        setHasGateway(status?.data === true);
        if (!status.data) {
          getExpectProcessDiagram(pId, undefined);
        }
      }
      setInsData(addrDecorator(resContent));
      // 新建被关联表单时回显关联表单
      initData && initAssociationForm(resContent);
      init(res?.data || {});
      setTitle(res.data.title);
      setBizType(res?.data?.bizType);
      setGmtCreate(res.data.gmtCreate);
      setProcessId(res.data.processId);
      setPropsSettings(res.data.propsSettings);
    });
  };

  // const defineTitle = (formData: typeof IObj) => {
  //   const userInfoStr: string = window.localStorage.getItem('userInfo') || '';
  //   const { name } = customJP(userInfoStr) || {};
  //   formData.createUserName = name;
  //   formData.gmtCreate = gmtCreate;
  //   formData.formTitle = title;
  //   const titleSetting = propsSettings.find(
  //     (item) => item.propsKey === 'dataTitle',
  //   );
  //   return titleSetting?.propsValue.replace(/#{(.*?)}/g, (_match, p1) => {
  //     const { widget } = form.getSchemaByPath(p1) as IFormProps;
  //     let value = '';
  //     switch (widget) {
  //       case 'relation':
  //         value = formData[p1]?.title || '';
  //         break;
  //       case 'select':
  //         value = formData[p1]?.value || '';
  //         break;
  //       default:
  //         value = formData[p1] || '';
  //         break;
  //     }
  //     return value;
  //   });
  // };
  const getFlowInfo = async (pId) => {
    const definitionInfo = await formApi.getProcessDefinitionInfo({
      formCode,
      processId: pId,
    });

    // const nodes = get(definitionInfo, 'data.nodes');
    // nodes && setFlowInfo(nodes);

    const dContent = get(definitionInfo, 'data.content');
    if (typeof dContent === 'string') {
      const info = customJP(dContent);
      const initiatorId = get(
        info,
        'processConfig.processDefinition.children[0].id',
      );
      if (!initiatorId) return setInsData(addrDecorator(customJP(resContent)));
      const accessInfo = await utils.getFieldsAccess({
        processId: pId,
        nodeId: initiatorId,
      });
      const initContent = customJP(resContent);
      const behaviorList = get(accessInfo, 'data.behaviorList');
      if (Array.isArray(behaviorList)) {
        Object.keys(initContent.properties)?.forEach((key) => {
          const match = behaviorList.find((t) => t.fieldId === key);
          initContent.properties[key] = {
            ...initContent.properties[key],
            readOnly: match?.fieldBehavior === 'READONLY',
            // hidden: match?.fieldBehavior === 'HIDDEN',
            // disabled: match?.fieldBehavior === 'DISABLED',
          };
        });
        setInsData(addrDecorator(initContent));
      } else {
        setInsData(addrDecorator(customJP(resContent)));
      }
    } else {
      setInsData(addrDecorator(customJP(resContent)));
    }
  };

  // const defineTitle = (form: typeof IObj) => {
  //   const titleSetting = propsSettings.find(
  //     (item) => item.propsKey === 'dataTitle',
  //   );
  //   if (titleSetting?.settingType === SettingType.CUSTOM) {
  //     return titleSetting?.propsValue.replace(/#{(.*?)}/g, (_match, p1) => {
  //       const value = typeof form[p1] === 'object' ? form[p1].value : form[p1];
  //       return value || '';
  //     });
  //   } else {
  //     const useInfoStr: string = window.localStorage.getItem('userInfo') || '';
  //     const useInfo = customJP(useInfoStr) || {};
  //     return `${useInfo.name}${title}`;
  //   }
  // };

  // const beforeFinish = ({ data, errors, schema, ...rest }) => {
  //   return { name: 'aaaa', error: ['dddd'] }
  // }

  const onFinish = (data, errors) => {
    // 必填校验（表格内容除外，表格的内容要被修改才会触发表格的必填校验，所以此处的内容不可囊括所以情况）
    if (errors.length > 0) {
      setLoading(false);
      setLoad(false);
      const { properties } = insData;

      let errArr = errors.map((val) => properties[val.name]?.title);
      let firstValue = errArr.shift();
      // 当存在表格时则要特殊处理
      if (!firstValue) {
        let tempTableArr: any = {};
        Object.values(properties)?.forEach((val: any) => {
          if (val.widget == 'table') {
            tempTableArr[val.id] = val?.items?.properties || {};
          }
        });

        let saveArr = [];
        errors?.forEach((val) => {
          // 错误信息是类似一下的类型，需要将数据筛选出来
          // 'table_KuJWPX0kwWwW[1].number_zqMiRzCp5jYJ'.replace(/\[\d\]\./, '')
          let tableIdAndKey = val.name.replace(/\[\d\]\./, '@@').split('@@');
          saveArr.push(tableIdAndKey);
        });

        let allerrArr = saveArr.map((val) => {
          return tempTableArr[val[0]]?.[val[1]]?.title || '';
        });
        message.warning(`表格中${allerrArr.shift() || ''}不能为空`);
      } else {
        message.warning(`${firstValue}不能为空`);
      }
    } else {
      // 基于以上必填校验的缺陷，做以下特殊处理

      const { properties } = insData;

      // 收录所有表格数据
      let tempTableArr: Array<object> = [];
      // 收录必填的电话
      let tempPhoneArr: Array<object> = [];
      let tempAdressArr: Array<object> = [];
      // 收录必填输入类型
      const tempInputTypeArr: Array<object> = [];
      Object.values(properties)?.forEach((val: any) => {
        if (val.widget == 'table') {
          tempTableArr.push({
            tableId: val.id,
            properties: val?.items?.properties, // 收录所有的表格子项，再判断是否有需要必填的项
          });
        }

        if (val.widget == 'phone' && val.required) {
          tempPhoneArr.push(val);
        }

        if (val.widget == 'address' && val.needDetail == 2 && val.required) {
          tempAdressArr.push(val);
        }
        if (val.widget == 'input' && val.required) {
          tempInputTypeArr.push(val);
        }
      });
      if (tempInputTypeArr.length) {
        let isEmpty = false;
        const emptyTitle: Array<object> = [];
        tempInputTypeArr?.forEach((ele: any) => {
          let trimValue = data[ele.id]?.trim();
          if (!trimValue) {
            isEmpty = true;
            emptyTitle.push(ele.title);
          }
        });

        if (isEmpty) {
          message.warning(`请输入具体的${emptyTitle.join('、')}值`);
          setLoading(false);
          setLoad(false);
          return;
        }
      }
      if (tempTableArr.length) {
        let hasNotFillIn = true;
        tempTableArr?.forEach((ele: any) => {
          const properties = ele?.properties;
          let isHasRequired = false;
          let needFillIn: any = [];

          // 将当前的表格需要填写的项收录起来
          Object.values(properties || {}).forEach((val: any) => {
            if (val.required == true) {
              isHasRequired = true;
              needFillIn.push(val);
            }
          });
          // 存在需要填写的项
          if (isHasRequired) {
            // let isPassNum = 0;
            let tableValues = formDataLive?.[ele?.tableId] || [];

            // 未填写表格中的值时无法触发表格的值更新 为undefied
            if (tableValues.length === 0) {
              hasNotFillIn = false;
            }
            tableValues?.forEach((val) => {
              needFillIn?.forEach((ele) => {
                let widget = ele.widget;
                // console.log(ele, 11111111);
                // console.log(11111111, widget);
                // console.log(11111111333, val[ele.id]);
                // 未填写
                if (widget == 'phone' && !val[ele.id]?.value) {
                  hasNotFillIn = false;
                }
                // 类型随时增加，目前只有地址和电话
                if (widget == 'address' && ele.needDetail == 2) {
                  if (!val[ele.id]?.street || !val[ele.id]?.detail) {
                    hasNotFillIn = false;
                  }
                }
              });
            });
          }
        });

        if (!hasNotFillIn) {
          message.warning('存在表格必填项未填');
          setLoading(false);
          setLoad(false);
          return;
        }
      }

      // 电话也要做特殊处理
      if (tempPhoneArr.length) {
        let needToFillIn = false;
        tempPhoneArr?.forEach((val) => {
          let phoneItem = formDataLive?.[val?.id] || {};
          if (!phoneItem?.value) {
            needToFillIn = true;
          }
        });

        if (needToFillIn) {
          message.warning('请完善电话再操作');
          setLoading(false);
          setLoad(false);
          return;
        }
      }

      // 地址也要做特殊处理
      if (tempAdressArr.length) {
        let needToFillIn = false;
        tempAdressArr?.forEach((val) => {
          console.log(formDataLive, 1111);
          console.log(val?.id, 1111);
          let adressItem = formDataLive?.[val?.id] || {};
          if (!adressItem?.street || !adressItem?.detail) {
            needToFillIn = true;
          }
        });

        if (needToFillIn) {
          message.warning('请完善地址再操作');
          setLoading(false);
          setLoad(false);
          return;
        }
      }

      let relatedModelList = [];
      Object.keys(data)?.forEach((key) => {
        if (key.includes('relation_')) {
          relatedModelList.push({
            relatedFormCode: data[key]?.formCode,
            relatedInstanceId: data[key]?.instanceId,
          });
        }

        if (key.includes('dateRange_')) {
          const [begin, end] = data[key] ?? [];
          const format =
            insData?.properties?.[key]?.format === 'dateTime'
              ? 'YYYY-MM-DD HH:mm:ss'
              : 'YYYY-MM-DD';
          data[key] = [begin?.format(format), end?.format(format)];
        }
      });

      let extendContent = {};
      if (bizType === 'crm_contract') {
        extendContent = {
          biz_contract_paid_amount: 0,
          biz_contract_unpaid_amount: data?.money_KI5RLXW0,
          biz_contract_invoiced_amount: 0,
          biz_contract_uninvoiced_amount: data?.money_KI5RLXW0,
          biz_contract_dynamic_remind: 0,
        };
      }

      if (bizType === 'crm_order') {
        extendContent = {
          biz_order_paid_amount: 0,
          biz_order_unpaid_amount: data.calculate_1BB1T5DRDOXS0,
          biz_order_invoiced_amount: 0,
          biz_order_uninvoiced_amount: data.calculate_1BB1T5DRDOXS0,
        };
      }

      formApi
        .createFromInstance(
          {
            title: defineTitle(data, propsSettings, form, gmtCreate, title),
            formCode,
            content: JSON.stringify(data),
            processId,
            relatedModelList,
            extendContent: JSON.stringify(extendContent),
          },
          {
            headers: {
              formCode,
            },
          },
        )
        .then((res) => {
          if (res.code === 200) {
            message.success('新建成功');
            form?.resetFields();
            cb();
            setLoad(false);
          }
        });
    }
  };
  return (
    <div className={c.t_create_form}>
      <>
        {insData && (
          <FormRender
            onValuesChange={(e) => {
              fouceWidgetRef.current = e;
            }}
            form={form}
            schema={transformSchema(insData)}
            widgets={{ ...widgets }}
            // beforeFinish={beforeFinish}
            // validateMessages={{
            //   required: '${title}是必选字段',
            // }}
            onFinish={onFinish}
            watch={{
              '#': (val) => {
                console.log('新建表单的数据为：', val);
                setFromDataLive(val);
              },
            }}
          />
        )}
        {flowInfo?.length ? (
          <>
            <div className={c.t_line} />
            <div className={c.process}>
              <div style={{ marginBottom: '15px' }}>流程</div>
              <Timeline>
                {flowInfo.map((node) => (
                  <div key={node.nodeId}>
                    {flowNodeTemp({
                      nodeTitle: node.nodeBusinessName,
                      nodeStaff: `${node.users.length}个${node.nodeBusinessName}`,
                      imgs: node.users,
                    })}
                  </div>
                ))}
              </Timeline>
            </div>
          </>
        ) : null}
        {!['drawer', 'modal'].includes(renderFormat) && (
          <Space className={c.submit}>
            <Button loading={load} type="primary" onClick={doSubmit}>
              提交
            </Button>
          </Space>
        )}
      </>
    </div>
  );
};

export default forwardRef(CreateForm);
