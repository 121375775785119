/*
 * :file description:
 * :name: \commond:\progree\low-code-platform\src\pages\form\beta\filter.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-24 17:00:11
 * :last editor: hyw
 * :date last edited: 2021-12-31 10:41:27
 */
import React, { useImperativeHandle, forwardRef } from 'react';
import FormRender, { useForm } from 'form-render';
import { Button } from 'antd';
import Input from '../../../../tg-renderer/packages/form-render/src/widgets/Sift_Input';
import TextArea from '../../../../tg-renderer/packages/form-render/src/widgets/Sift_TextArea';
// import DateRange from '../../../../tg-renderer/packages/form-render/src/widgets/Sift_DateRange';
import Date from '../../../../tg-renderer/packages/form-render/src/widgets/Sift_Date';
import InputNumberRange from '../../../../tg-renderer/packages/form-render/src/widgets/Sift_InputNumberRange';
import Select from '../../../../tg-renderer/packages/form-render/src/widgets/Sift_Select';
import MultipleSelect from '../../../../tg-renderer/packages/form-render/src/widgets/Sift_MultipleSelect';
import Money from '../../../../tg-renderer/packages/form-render/src/widgets/Sift_Money';
import Phone from '../../../../tg-renderer/packages/form-render/src/widgets/Sift_Phone';
import Relation from '../../../../tg-renderer/packages/form-render/src/widgets/Sift_AssociationSelector';
import PersonSelector from '../../../../tg-renderer/packages/form-render/src/widgets/Sift_PersonSelector';

const widgets = {
  input: Input,
  textarea: TextArea,
  number: InputNumberRange,
  select: Select,
  multiSelect: MultipleSelect,
  date: Date,
  money: Money,
  phone: Phone,
  relation: Relation,
  employee: PersonSelector,
};

const Filter = (props, ref) => {
  // export default function (props, ref) {
  console.log(1111, props, ref);

  const form = useForm();
  const schema = {
    type: 'object',
    properties: {
      'input_Jc-ZCK': {
        title: '单行输入',
        type: 'string',
        displayType: 'row',
        default: '五关六将',
        required: true,
        widget: 'input',
        props: {},
      },
      date_62A2WG: {
        title: '范围',
        type: 'string',
        displayType: 'row',
        widget: 'number',
        required: true,
      },
      select_GjmBUV: {
        title: '独一无二',
        type: 'string',
        enum: ['a', 'b', 'c'],
        enumNames: ['早', '中', '晚'],
        hideSearchType: true,
        required: true,
        widget: 'select',
      },
      multiSelect_1DoSJT: {
        title: '多多益善',
        description: '下拉多选',
        type: 'string',
        items: {
          type: 'string',
        },
        enum: ['A', 'B', 'C', 'D'],
        enumNames: ['杭州', '武汉', '湖州', '贵阳'],
        widget: 'multiSelect',
        required: true,
      },
      number_kfLxbG: {
        title: '价格',
        type: 'string',
        displayType: 'row',
        widget: 'date',
        required: true,
      },
      number_ksfLxG: {
        title: '金额',
        type: 'string',
        displayType: 'row',
        widget: 'money',
        required: true,
      },
      number_usfLaG: {
        title: '电话',
        type: 'string',
        displayType: 'row',
        widget: 'phone',
        required: true,
      },
      sqmbtr_usfLaG: {
        title: '商品',
        type: 'string',
        displayType: 'row',
        rendertype: 'search',
        widget: 'relation',
        formCode: 'PROC-D03B4F65-A2D8-46D2-98E9-009B1B49B8ED',
        required: true,
      },
      sqmber_usfLaG: {
        title: '创建人',
        type: 'string',
        displayType: 'row',
        rendertype: 'search',
        widget: 'employee',
        required: true,
      },
    },
    labelWidth: 120,
    displayType: 'row',
  };

  const echo = () => {
    console.log('赋值啊', form);
    form.setValues({
      'input_Jc-ZCK': '{"searchType":"search","value":"12306"}',
      number_kfLxbG: '{"searchType":"search","value":"2021-12-18"}',
      date_62A2WG: '{"searchType":"range","value": [1,5]}',
      number_ksfLxG: '{"searchType":"money","value": 299}',
      number_usfLaG: '{"searchType":"phone","value": 110}',
      sqmber_usfLaG:
        '{"searchType":"equal","value": { value: "abc, rendertype: "search"}, }',
    });
  };

  const handleSubmit = () => {
    window.ff = form;
    form.submit();
  };
  useImperativeHandle(ref, () => ({
    handleSubmit: (flag: boolean) => {
      // setVisible(flag);
    },
  }));
  return (
    <>
      <FormRender form={form} schema={schema} widgets={{ ...widgets }} />
      {/* <Button type="primary" onClick={handleSubmit}>
        提交
      </Button>
      <Button onClick={() => echo()}>回显</Button> */}
    </>
  );
}

export default forwardRef(Filter);