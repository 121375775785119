/*
 * :file description:
 * :name: /low-code-platform/src/components/FormGenerator/components/Settings/ItemSettings.jsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-02-28 20:34:10
 * :last editor: dragon
 * :date last edited: 2023-08-05 14:25:25
 */
import React, { useState, useEffect } from 'react';
import FormRender, { useForm } from 'form-render';
import IdInput from '../../widgets/idInput';
import PercentSlider from '../../widgets/percentSlider';
import {
  defaultSettings,
  baseCommonSettings,
  defaultCommonSettings,
  elements,
  advancedElements,
  layouts,
  specialHandlingTypeJxc,
  numberLimited,
  baseSetting,
  freeBaseSetting,
} from '../../settings';
import { isObject, combinationRelation } from '../../utils';
import { getWidgetName } from '../../utils/mapping';
import { useGlobal, useStore } from '../../utils/hooks';
import { useModel } from 'umi';
import { getUuid } from '../../../../utils';
import { useGray } from '../../../../pages/generator/relation/hooks';
import { isOpenInTripartitePlatform } from '../../../../utils/tripartitePlatform';
// 以下类型控件支持启停用
const WIDGETS_SUPPORT_ACTIVATION_AND_DEACTIVATION = [
  'input',
  'textarea',
  'number',
  'select',
  'multiSelect',
  'date',
  'dateRange',
  'phone',
  'picture',
  'money',
  'attachment',
  'innerContact',
  'department',
  'address',
  'seqNumber',
  'starRating',
  'table',
  'relation',
  'cascade',
  'cascadeV2',
  'multiCascade',
  'multiTag',
  'personSelector',
  'idCard',
  'email',
  'relation_followRecordClue', // 线索
  'signature',
  'processRelation',
  'idCard',
];
const ID_SUPPORT_ACTIVATION_AND_DEACTIVATION = ['relation_followRecordClue'];

export default function ItemSettings({ widgets }) {
  const form = useForm();
  const { versionLimit } = useModel('useGenerator');
  const setGlobal = useGlobal();
  const {
    selected,
    flatten,
    onItemChange,
    onItemErrorChange,
    userProps = {},
    relationSchema,
    widgets: globalWidgets,
    mapping: globalMapping,
    duplicationRuleData,
    allowCooperatorWrite=false,
  } = useStore();
  const isShowFiledEnabled = useGray('show_filed_enabled');
  const duplicationRuleWidgetArr =
    duplicationRuleData?.duplicationRule?.manyQueryConditionList
      ?.reduce((a, b) => {
        return a.concat(b);
      }, [])
      ?.map((item) => item.fieldId);

  const { settings, commonSettings, hideId, transformer } = userProps;
  const [settingSchema, setSettingSchema] = useState({});
  // 避免切换选中项时 schema 对应出错
  const [ready, setReady] = useState({});
    
  const _widgets = {
    ...globalWidgets,
    idInput: IdInput,
    percentSlider: PercentSlider,
  };

  // const filterDefaultTitle = (setting, item) => {
  //   const hasAuth = versionLimit?.widgetProperties?.[
  //     item?.schema?.widget
  //   ]?.includes('linkageDefaultType');
  //   Object.entries(setting || {}).forEach((ele) => {
  //     if (
  //       ele[1]?.isFilterTitle &&
  //       (item.schema.widget === 'number' || item.schema.widget === 'money') &&
  //       hasAuth
  //     ) {
  //       setting[ele[0]].title = null;
  //     }
  //   });
  //   return setting;
  // };

  const getWidgetList = (settings, commonSettings) => {
    return settings.reduce((widgetList, setting) => {
      if (!Array.isArray(setting.widgets)) return widgetList;
      const basicWidgets = setting.widgets.map((item) => {
        const baseItemSettings = {};
        if (item.schema.type === 'array') {
          baseItemSettings.items = {
            type: 'object',
            hidden: '{{true}}',
          };
        }

        const hasAuth =
          versionLimit?.widgetProperties?.[item?.schema?.widget]?.includes(
            'linkageDefaultType',
          );

        return {
          ...item,
          widget:
            item.widget ||
            item.schema.widget ||
            getWidgetName(item.schema, globalMapping),
          setting: {
            ...commonSettings,
            ...baseCommonSettings,
            ...baseItemSettings,
            ...item.setting,
          },
        };
      });
      return [...widgetList, ...basicWidgets];
    }, []);
  };
  const onDataChange = (value) => {
    if (selected === '#') return;
    try {
      if (!ready) return;
      if (value?.widget === 'cooperator') {
        if (Array.isArray(value?.permissions) && value?.permissions?.includes('write')) {
          value.description = '协同人可查看、编辑数据'
        } else {
          value.description = '协同人仅可查看数据'
        }
      }
      const item = flatten[selected];
      if (item && item.schema) {
        onItemChange(
          selected,
          {
            ...item,
            schema: transformer.fromSetting(value),
          },
          'schema',
        );
      }
    } catch (error) {
      console.log('catch', error);
    }
  };

  /**
   * 处理字段 setting
   * @param {Object} toTarget 需要被赋值的目标
   * @param {Object} fromTarget 赋值的目标
   * @returns {Object} {{}} 处理结果
   */
  const recursiveAssign = (toTarget, fromTarget) => {
    const target = _.cloneDeep(toTarget || {});
    const fromTargetKeys = Object.keys(fromTarget);
    if (fromTargetKeys.length) {
      fromTargetKeys.forEach((key) => {
        if (
          fromTarget[key] &&
          typeof fromTarget[key] === 'object' &&
          !Array.isArray(fromTarget[key]) &&
          Object.keys(fromTarget[key]).length
        ) {
          target[key] = {
            ...target[key],
            ...recursiveAssign(target[key], fromTarget[key]),
          };
        } else {
          target[key] = fromTarget[key];
        }
      });
    }
    return target;
  };

  useEffect(() => {
    // setting 该显示什么的计算，要把选中组件的 schema 和它对应的 widgets 的整体 schema 进行拼接
    try {
      const item = flatten[selected];
      const cloneSpecialHandlingTypeJxc = _.cloneDeep(specialHandlingTypeJxc);
      if (!item || selected === '#') return;
      setReady(false);
      const isCustomer =
        item?.schema?.relateSource?.length ||
        item?.schema?.bizAlias?.includes('related_customer');
      /*
        (以下描述还没有实行)
        找出当前需要自己特殊定义setting的element（暂时由前端写setting配置） （满足以下条件）
        1、查找自定义的 schema 中有没有设置 customSettings 并且值为true
        2、自定义的id和后端数据返回的id相等
      */
      const hasAuth =
        versionLimit?.widgetProperties?.[item?.schema?.widget]?.includes(
          'linkageDefaultType',
        );
      const extSet = {};
      if (
        versionLimit?.widgetProperties?.relation?.includes('dataFillingRules')
      ) {
        extSet.dataFillingRules = {
          title: '数据填充',
          type: 'array',
          widget: 'dataFill',
        };
      }

      // if (hasAuth) {
      //   extSet.default = {
      //     title: '默认值',
      //     format: '',
      //     type: 'string',
      //     widget: 'defaultValue',
      //     placeholder: '请选择',
      //     max: 8000,
      //     enum: ['association'],
      //     enumNames: ['关联其他表单数据'],
      //     hideupper: true, //特殊处理需要隐藏设置区域的大写显示
      //   }
      // }

      const combineRelation = combinationRelation(extSet);
      // 算 widgetList
      const relationSettings = combineRelation(relationSchema);
      const _settings = Array.isArray(settings)
        ? [
            ...settings,
            { widgets: [...elements, ...advancedElements, ...layouts] },
          ] // TODO: 不是最优解
        : [...defaultSettings, ...relationSettings];

      const _commonSettings = isObject(commonSettings)
        ? commonSettings
        : { ...defaultCommonSettings };

      if (item.schema?.widget === 'input') {
        if (!isOpenInTripartitePlatform('1688')) {
          _commonSettings['scan'] = {
            title: '扫码',
            type: 'boolean',
            widget: 'scan',
            order: 21,
          };
        }
      }

      const widgetList = getWidgetList(_settings, _commonSettings);

      const widgetName = getWidgetName(item.schema, globalMapping);
      /**
       * 1、widgetList 为所有配置的schame结构，import {XXX,...} from '../../settings'; 其中的对象里的setting就是设计器右侧显示的配置
       * 2、schema 中有bizAlias则是特定的预置表单数据，其中的setting配置是单独配置的(跟默认配置有不同的需求场景)，配置的bizAlias值和后端返回的bizAlias值相等时，则显示其setting配置
       * 3、配置的对象中没有bizAlias字段，则匹配默认的 setting 配置
       * 4、通过 hasAuth 判断是否是高级版能力，如果是高级版能力在走新的配置（扩展新的配置文件，旧配置不做改动）
       */
      let element = widgetList.find(
        (e) =>
          item.schema.bizAlias && e.schema.bizAlias === item.schema.bizAlias,
      );
      
      // 添加基础配置
      if (hasAuth) {
        if (!element) {
          element = cloneSpecialHandlingTypeJxc.find(
            (e) => e.schema.widget === widgetName,
          );
        } else {
          if (['number', 'money'].includes(widgetName)) {
            // 高级版需要展示的配置
            let cloneSetting = Object.assign({}, baseSetting);
            if (widgetName === 'number') {
              cloneSetting = Object.assign(cloneSetting, numberLimited);
            }
            const targetSetting = recursiveAssign(
              element.setting || {},
              cloneSetting,
            );
            const specialTarget = cloneSpecialHandlingTypeJxc.find(
              (e) => e.schema.widget === widgetName,
            );
            element = Object.assign(specialTarget, {
              ...element,
              setting: targetSetting,
            });
          }
          if (
            [
              'input',
              'textarea',
              'select',
              'multiSelect',
              'date',
              'picture',
              'innerContact',
              'department',
            ].includes(widgetName)
          ) {
            // 新增默认值关联其他表单设置关联
            const specialTarget = cloneSpecialHandlingTypeJxc.find(
              (e) => e.schema.widget === widgetName,
            );
            if (specialTarget) {
              if (
                specialTarget.setting.default &&
                element.setting?.info?.widget !== 'jumplink'
              ) {
                element.setting.default = specialTarget.setting.default;
              }
            }
          }
        }
      } else {
        if (!element) {
          element = cloneSpecialHandlingTypeJxc.find(
            (e) => e.schema.widget === widgetName,
          );
        }
        if (['number', 'money'].includes(widgetName)) {
          let cloneSetting = Object.assign({}, freeBaseSetting);
          if (widgetName === 'number') {
            cloneSetting = Object.assign(cloneSetting, numberLimited);
          }
          const targetSetting = recursiveAssign(
            element.setting || {},
            cloneSetting,
          );
          const specialTarget = cloneSpecialHandlingTypeJxc.find(
            (e) => e.schema.widget === widgetName,
          );
          element = Object.assign(specialTarget, {
            ...element,
            setting: targetSetting,
          });
        }
      }
      if (!element) {
        element =
          widgetList.find((e) => {
            // 关联表单时，需要区别7个预置表单情况，需要custom来判定
            if (e.widget === 'relation') {
              return e.custom && e.widget === widgetName;
            } else {
              return e.widget === widgetName;
            }
          }) || {}; // 有可能会没有找到
      }
      // 协同人权限设置添加灰度
      if (item?.schema?.widget === 'cooperator') {
        if (!allowCooperatorWrite) {
          delete element.setting.permissions;
        }
      }

      if (item.schema?.isOaRelation && element.setting.dataFillingRules) {
        delete element.setting.dataFillingRules;
      }
      if (
        (WIDGETS_SUPPORT_ACTIVATION_AND_DEACTIVATION.includes(
          item.schema?.widget,
        ) ||
          ID_SUPPORT_ACTIVATION_AND_DEACTIVATION.includes(item.id)) &&
        !item?.schema?.hiddenEditableFreeze &&
        isShowFiledEnabled
      ) {
        // 注入不可编辑状态  现在不支持table  支持基础控件
        element.setting['hidden'] = {
          title: '启用',
          type: 'boolean',
          widget: 'enable',
          default: false,
          hiddenEditableFreeze: duplicationRuleWidgetArr?.includes(
            selected?.slice(2),
          )
            ? true
            : false,
        };
      } else {
        delete element.setting.hidden;
      }
      // 需要加上停用和启用的组件  布克巴巴的定制  写成通用需求
      if (item?.schema?.extendOptions?.addHiddenInWidget) {
        if (
          item?.schema?.extendOptions?.addHiddenInWidget?.enable &&
          !element?.setting?.['hidden']
        ) {
          element.setting['hidden'] = {
            title: '启用',
            type: 'boolean',
            widget: 'enable',
            default:
              item?.schema?.extendOptions?.addHiddenInWidget?.default ?? false,
            hiddenEditableFreeze:
              item?.schema?.extendOptions?.addHiddenInWidget
                ?.hiddenEditableFreeze ?? false,
          };
        }
      }
      const properties = { ...element.setting };

      // 单选框和多选框的properties 计算不对  手动校正
      if (['select', 'multiSelect'].includes(widgetName)) {
        if (properties?.default?.enum) {
          properties.default.enum = [
            'custom',
            ...(properties.default.enum || []),
          ];
          properties.default.enumNames = [
            '自定义',
            ...(properties.default.enumNames || []),
          ];
        }
      }

      // 修正下预置表单关联合同的mode字段  历史数据mode为空
      if (['bizSuite'].includes(widgetName)) {
        if (item?.schema && !item?.schema?.relatedMode) {
          item.schema.relatedMode = '';
        }
      }

      if (hideId) delete properties.$id;
      setGlobal({ currentItem: element });

      const parentSchema = flatten[item?.parent]?.schema;
      form.setValues({
        ...transformer.toSetting(item.schema),
        info: getUuid(),
        parentWidget: parentSchema?.widget,
      });

      // 隐藏需要隐藏的setting属性
      if (item?.schema?.extendOptions?.hiddenSettings?.length) {
        item?.schema?.extendOptions?.hiddenSettings?.forEach((i) => {
          delete properties?.[i];
        });
      }

      setSettingSchema({
        type: 'object',
        displayType: 'column',
        properties,
      });
      setTimeout(() => {
        setReady(true);
        onDataChange(form.getValues());
      }, 0);
    } catch (error) {
      console.log(error);
    }
  }, [selected, isShowFiledEnabled]);

  useEffect(() => {
    onItemErrorChange(form?.errorFields);
  }, [form?.errorFields]);

  return (
    <div>
      <FormRender
        form={form}
        schema={settingSchema}
        widgets={{ ..._widgets, ...widgets }}
        watch={{
          '#': (v) => setTimeout(() => onDataChange(v), 0),
        }}
      />
    </div>
  );
}
