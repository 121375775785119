/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\Sift_Select\index.tsx
 * author: gaoch
 * copyright: (c) 2022, Tungee
 * :date created: 2022-01-07 19:20:33
 * :last editor: gaoch
 * :date last edited: 2022-02-15 15:00:16
 */
import React, { useState, useEffect } from 'react';
import { Input, Select } from 'antd';
import { get, isEmpty } from 'lodash-es';
import { isJSON, dropSearchTypeProp, customJP } from '../../utils';
import { SearchTypeValue, SEARCH_TYPE_OPTIONS_OF_E } from '../../constant';
import { IFormCompProps } from '../../typings';
import './index.less';

type WidgetProps = IFormCompProps;

const { Option } = Select;

const SelectWidget = (props: WidgetProps) => {
  const { value, onChange = () => {}, schema = {} } = props;
  const { searchType: initialSearchType, value: initialValue } = isJSON(value)
    ? customJP(value)
    : { searchType: undefined, value: undefined };
  const { placeholder = '请选择', hideSearchType = false } = schema;

  const defaultValue = SearchTypeValue.EQUAL;

  const [searchType, setSearchType] = useState(
    initialSearchType || defaultValue,
  );
  const [selected, setSelected] = useState(initialValue || {});

  useEffect(() => {
    const payload = {
      searchType,
      value: selected,
    };
    onChange(
      !isEmpty(selected)
        ? JSON.stringify(dropSearchTypeProp(payload, hideSearchType))
        : '',
    );
  }, [searchType, selected]);

  useEffect(() => {
    if (isJSON(value)) {
      const valueObj = customJP(value);
      setSearchType(valueObj.searchType);
      setSelected(valueObj.value);
    } else {
      setSelected(undefined);
    }
  }, [value]);

  return (
    <Input.Group compact className="fr-group">
      {!hideSearchType && (
        <Select
          style={{ width: '30%' }}
          // allowClear
          value={searchType}
          defaultValue={defaultValue}
          onChange={(e) => setSearchType(e)}
        >
          {SEARCH_TYPE_OPTIONS_OF_E.map((item) => (
            <Option key={item.value} value={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      )}

      <Select
        allowClear
        value={selected?.key}
        style={{ width: '70%' }}
        placeholder={placeholder}
        onChange={(value, option) => {
          console.log(value, option);
          setSelected(option);
        }}
      >
        {get(props, 'schema.enum', []).map((item, idx) => (
          <Option key={item} value={item}>
            {get(props, 'schema.enumNames', [])[idx]}
          </Option>
        ))}
      </Select>
    </Input.Group>
  );
};

export default SelectWidget;
