/*
 * @Description:
 * @FilePath: \low-code-platform\tg-renderer\packages\form-render\src\form-render-core\src\create-form\flowNodeTemplate.tsx
 * author: 段文君
 * copyright: (c) 2022, Tungee
 * @Date: 2022-01-07 20:58:37
 * @LastEditors: 段文君
 * @LastEditTime: 2022-01-08 14:19:46
 */
import { Timeline } from 'antd';
import defaultPortrait from '../../../assets/images/default_portrait.png';
import c from './index.less';

// 通用流程节点模板
const flowNodeTemp = ({ nodeTitle = '', nodeStaff = '', imgs }) => {
  return (
    <Timeline.Item>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <div className={c.node_title}>{nodeTitle}</div>
          <div className={c.node_staff}>{nodeStaff}</div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {imgs.map((item, idx) => (
            <div
              className={c.avater}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img
                key={idx}
                width="44"
                height="44"
                style={{
                  borderRadius: '4px',
                  marginRight: '5px',
                  cursor: 'pointer',
                }}
                src={item.avatar || defaultPortrait}
              />
              <span
                style={{
                  fontFamily: 'PingFangSC-Regular',
                  fontSize: '12px',
                  color: 'rgba(23,26,29,0.40)',
                  textAlign: 'center',
                  lineHeight: '20px',
                  fontWeight: 400,
                }}
              >
                {item.name}
              </span>
            </div>
          ))}
        </div>
      </div>
    </Timeline.Item>
  );
};

export default flowNodeTemp;
