/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\DataTitle\components\TitleDemo.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-13 15:07:00
 * :last editor: hyw
 * :date last edited: 2022-04-16 17:25:13
 */

import c from '../index.less';

const TitleDemo = () => {
  return (
    <div className={c.demo_container}>
      <div>示例：</div>
      <div>宋雨的业绩上报</div>
      {/* <div>宋雨仔的业绩上报啦！</div> */}
    </div>
  );
};

export default TitleDemo;
