/*
 * :file description: page form
 * :name: \low-code-platform\src\pages\form\[id].tsx
 * :author: ershing
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-11 16:21:52
 * :last editor: 黄艺
 * :date last edited: 2022-03-16 20:14:23
 */
import React, { useRef, useEffect, useState } from 'react';
import { useParams } from 'umi';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import FormHeader from './components/FormHeader';
import FormFilter from './components/FormFilter';
import FormTable from './components/FormTable';
import FormDetail from '../../../tg-renderer/packages/form-render/src/form-render-core/src/FormDetail';
import formApi from '@/services/form';
import c from './index.less';
import { getFormCode } from '@/utils';
import { flattenSchema } from '~@/packages/form-render/src/utils';

export const FormConfigCtx = React.createContext('FormConfigCtx');

function Form() {
  const headRef = useRef();
  const filterRef = useRef();
  const tableRef = useRef();
  const detailRef = useRef();
  const [formCode, setFormCode] = useState(getFormCode());
  const [formConfig, setFormConfig] = useState<any>();
  const [instanceId, setInstanceId] = useState<string>();
  const [relationFormCode, setRelationFormCode] = useState<string>();
  const [relationId, setRelationId] = useState<string>();
  const [detailKey, setDetailKey] = useState(nanoid(12));

  const params = useParams();

  useEffect(() => {
    getAllFields();
  }, [params.id]);

  const getAllFields = () => {
    formApi
      .getAllField({
        formCode: getFormCode(),
      })
      .then((res) => {
        if (!res?.data) return;
        const { content } = res.data;
        if (!content) return;
        const contentJSON = JSON.parse(content);
        flattenSchema(contentJSON);
        const temp = {
          type: 'object',
          properties: {
            ...contentJSON.properties,
            createUserId: {
              format: 'personSelector',
              title: '创建人',
              type: 'string',
              widget: 'personSelector',
            },
            gmtCreate: {
              format: 'date',
              title: '创建时间',
              type: 'string',
              widget: 'date',
            },
            gmtModified: {
              format: 'date',
              title: '更新时间',
              type: 'string',
              widget: 'date',
            },
          },
        };
        setFormConfig({ ...res.data, content: temp });
      });
  };

  const showModal = (
    instanceId: string,
    formcode?: string | undefined,
    uniqueId?: string | undefined,
  ) => {
    setFormCode(formcode);
    setInstanceId(instanceId);
    uniqueId && detailRef.current.toggleDetail(false);
    setDetailKey(uniqueId);
    _.delay(() => {
      detailRef.current.toggleDetail(true);
    }, 0);
  };

  const hideModal = () => {
    detailRef.current.toggleDetail(false);
  };

  const search = (payload) => {
    tableRef.current.search({
      keyword: headRef.current.getKeyword(),
      ...payload,
    });
  };

  const showCreatForRelation = (formcode: string, relationId: string) => {
    setRelationFormCode(formcode);
    setRelationId(relationId);
    _.delay(() => {
      headRef.current.toggleDetail(true);
    }, 0);
  };

  const getRelationParams = () => {
    return {
      relationId,
      relationFormCode,
    };
  };

  const getKeyword = () => {
    return headRef.current.getKeyword();
  };

  return (
    <div className={c.form_manage} id="form_manage-id">
      <FormConfigCtx.Provider
        value={{
          ...formConfig,
          search,
          hideModal,
          showModal,
          showCreatForRelation,
          getRelationParams,
        }}
      >
        <FormHeader ref={headRef} />
        <FormFilter ref={filterRef} />
        <FormTable
          ref={tableRef}
          getKeyword={getKeyword}
          showModal={showModal}
        />
        <FormDetail
          key={detailKey}
          ref={detailRef}
          formcode={formCode}
          instanceId={instanceId}
          showModal={showModal}
          renderFormat="drawer"
          cb={() => search({})}
        />
      </FormConfigCtx.Provider>
    </div>
  );
}

Form.wrappers = ['@/wrappers/pageAuth'];

export default Form;
