/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/process/components/FlowV3/components/Generator.tsx
 * :author: Quan
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-12-10 11:47:12
 * :last editor: Quan
 * :date last edited: 2021-12-23 11:19:19
 */
import React, { useCallback } from 'react';
import ConditionContainerNode from './ConditionContainerNode';
import InclusiveConditionContainerNode from './InclusiveConditionContainerNode';
import Node from './Node';
import {
  NODE_TYPE_MAP,
  CONDITION_CONTAINER_NODE,
  INCLUSIVE_CONDITION_CONTAINER_NODE,
  INCLUSIVE_CONDITION_CONTAINER_NODE_END,
  COMMON_NODE_TYPE_LIST,
} from '../constants';

interface IProps {
  currentNode: any; //当前节点
  currentBranch: any; //当前分支上的所有数据
  currentIndex: number; //当前节点在分支上的下标
  endNodeId?: any; //末端节点
  flowData: any; //流程数据
  setFlowData: (value: any) => void;
  handleNodeClick: (value: any) => void; //点击节点
  handleNodeActiveStatus: (value: any) => void; //点击节点
  extData?: any;
}

function Generator(props: IProps) {
  const {
    currentNode,
    currentBranch,
    currentIndex,
    endNodeId,
    flowData,
    setFlowData,
    handleNodeClick,
    handleNodeActiveStatus,
    extData,
  } = props;
  const checkNodeType = (nodeName: string) => {
    if (
      nodeName === CONDITION_CONTAINER_NODE ||
      nodeName === INCLUSIVE_CONDITION_CONTAINER_NODE
    ) {
      return NODE_TYPE_MAP.container;
    } else if (COMMON_NODE_TYPE_LIST.includes(nodeName)) {
      return NODE_TYPE_MAP.node;
    }
  };

  const renderFlow = useCallback(() => {
    // 理论上每个children就是一个分支，根据节点的children长度，可判断节点是否继续能渲染下一个兄弟节点，仅对网关节点进行分叉处理
    switch (checkNodeType(currentNode.componentName)) {
      case NODE_TYPE_MAP.container:
        if (currentNode?.componentName === 'InclusiveConditionContainerNode') {
          return (
            <InclusiveConditionContainerNode
              currentBranch={currentBranch}
              currentIndex={currentIndex}
              data={currentNode}
              endNodeId={endNodeId}
              flowData={flowData}
              setFlowData={setFlowData}
              handleNodeActiveStatus={handleNodeActiveStatus}
              handleNodeClick={handleNodeClick}
              extData={extData}
            ></InclusiveConditionContainerNode>
          );
        } else {
          return (
            <ConditionContainerNode
              currentBranch={currentBranch}
              currentIndex={currentIndex}
              data={currentNode}
              endNodeId={endNodeId}
              flowData={flowData}
              setFlowData={setFlowData}
              handleNodeActiveStatus={handleNodeActiveStatus}
              handleNodeClick={handleNodeClick}
              extData={extData}
            ></ConditionContainerNode>
          );
        }
      case NODE_TYPE_MAP.node:
        return (
          <Node
            data={currentNode}
            currentBranch={currentBranch}
            currentIndex={currentIndex}
            endNodeId={endNodeId}
            flowData={flowData}
            setFlowData={setFlowData}
            handleNodeActiveStatus={handleNodeActiveStatus}
            handleNodeClick={handleNodeClick}
            extData={extData}
          ></Node>
        );
      default:
        return null;
    }
  }, [flowData]);
  return <React.Fragment>{renderFlow()}</React.Fragment>;
}

export default Generator;
