// @ts-nocheck
import React from 'react';
import { ApplyPluginsType } from '/var/lib/go-agent-1/pipelines/low-code-platform/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": require('@/layouts/index.tsx').default,
    "routes": [
      {
        "path": "/back/auth",
        "exact": true,
        "component": require('@/pages/back/auth/index.tsx').default,
        "wrappers": [require('@/wrappers/pageAuth').default]
      },
      {
        "path": "/back/org",
        "exact": true,
        "component": require('@/pages/back/org/index.tsx').default,
        "wrappers": [require('@/wrappers/pageAuth').default]
      },
      {
        "path": "/form/beta/filter",
        "exact": true,
        "component": require('@/pages/form/beta/filter.tsx').default
      },
      {
        "path": "/form/beta/:id",
        "exact": true,
        "component": require('@/pages/form/beta/[id].tsx').default,
        "wrappers": [require('@/wrappers/pageAuth').default]
      },
      {
        "path": "/form/formDetail",
        "exact": true,
        "component": require('@/pages/form/formDetail.tsx').default
      },
      {
        "path": "/form/:id",
        "exact": true,
        "component": require('@/pages/form/[id].tsx').default,
        "wrappers": [require('@/wrappers/pageAuth').default]
      },
      {
        "path": "/generator/form",
        "exact": true,
        "component": require('@/pages/generator/form/index.tsx').default,
        "wrappers": [require('@/wrappers/pageAuth').default]
      },
      {
        "path": "/generator/process",
        "exact": true,
        "component": require('@/pages/generator/process/index.tsx').default,
        "wrappers": [require('@/wrappers/pageAuth').default]
      },
      {
        "path": "/generator/relation",
        "routes": [
          {
            "path": "/generator/relation/business/ModalRule",
            "exact": true,
            "component": require('@/pages/generator/relation/business/ModalRule.tsx').default
          },
          {
            "path": "/generator/relation/business",
            "exact": true,
            "component": require('@/pages/generator/relation/business/index.tsx').default
          },
          {
            "path": "/generator/relation/dataConnector/Drawer/Components/antdCascader",
            "exact": true,
            "component": require('@/pages/generator/relation/dataConnector/Drawer/Components/antdCascader/index.tsx').default
          },
          {
            "path": "/generator/relation/dataConnector/Drawer/Components/customTable",
            "exact": true,
            "component": require('@/pages/generator/relation/dataConnector/Drawer/Components/customTable/index.tsx').default
          },
          {
            "path": "/generator/relation/dataConnector/Drawer",
            "exact": true,
            "component": require('@/pages/generator/relation/dataConnector/Drawer/index.tsx').default
          },
          {
            "path": "/generator/relation/dataConnector/DrawerLog",
            "exact": true,
            "component": require('@/pages/generator/relation/dataConnector/DrawerLog/index.tsx').default
          },
          {
            "path": "/generator/relation/dataConnector",
            "exact": true,
            "component": require('@/pages/generator/relation/dataConnector/index.tsx').default
          },
          {
            "path": "/generator/relation/duplicated",
            "exact": true,
            "component": require('@/pages/generator/relation/duplicated/index.tsx').default
          },
          {
            "path": "/generator/relation/print/Modal",
            "exact": true,
            "component": require('@/pages/generator/relation/print/Modal.tsx').default
          },
          {
            "path": "/generator/relation/print",
            "exact": true,
            "component": require('@/pages/generator/relation/print/index.tsx').default
          },
          {
            "path": "/generator/relation/shareData/ModalDataShareRule",
            "exact": true,
            "component": require('@/pages/generator/relation/shareData/ModalDataShareRule.tsx').default
          },
          {
            "path": "/generator/relation/shareData/WidgetModal/KpContact",
            "exact": true,
            "component": require('@/pages/generator/relation/shareData/WidgetModal/KpContact/index.tsx').default
          },
          {
            "path": "/generator/relation/shareData/WidgetModal/Pagination",
            "exact": true,
            "component": require('@/pages/generator/relation/shareData/WidgetModal/Pagination/index.tsx').default
          },
          {
            "path": "/generator/relation/shareData/WidgetModal/TableCustomerTags",
            "exact": true,
            "component": require('@/pages/generator/relation/shareData/WidgetModal/TableCustomerTags/index.tsx').default
          },
          {
            "path": "/generator/relation/shareData/WidgetModal/WechatCustomerInfoColumn",
            "exact": true,
            "component": require('@/pages/generator/relation/shareData/WidgetModal/WechatCustomerInfoColumn/index.tsx').default
          },
          {
            "path": "/generator/relation/shareData/WidgetModal",
            "exact": true,
            "component": require('@/pages/generator/relation/shareData/WidgetModal/index.tsx').default
          },
          {
            "path": "/generator/relation/shareData/WidgetModal/utils",
            "exact": true,
            "component": require('@/pages/generator/relation/shareData/WidgetModal/utils.tsx').default
          },
          {
            "path": "/generator/relation/shareData",
            "exact": true,
            "component": require('@/pages/generator/relation/shareData/index.tsx').default
          },
          {
            "path": "/generator/relation/submit/Modal",
            "exact": true,
            "component": require('@/pages/generator/relation/submit/Modal.tsx').default
          },
          {
            "path": "/generator/relation/submit",
            "exact": true,
            "component": require('@/pages/generator/relation/submit/index.tsx').default
          }
        ],
        "component": require('@/pages/generator/relation/_layout.tsx').default
      },
      {
        "path": "/",
        "exact": true,
        "component": require('@/pages/index.tsx').default
      },
      {
        "path": "/login",
        "exact": true,
        "component": require('@/pages/login/index.tsx').default,
        "wrappers": [require('@/wrappers/auth').default]
      },
      {
        "path": "/todoMessage",
        "exact": true,
        "component": require('@/pages/todoMessage/index.tsx').default,
        "wrappers": [require('@/wrappers/pageAuth').default]
      },
      {
        "path": "/workNotice",
        "exact": true,
        "component": require('@/pages/workNotice/index.tsx').default
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
