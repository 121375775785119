/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\GenerationRules\Config\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-01-08 17:15:58
 * :last editor: hyw
 * :date last edited: 2022-02-11 10:12:15
 */
import {
  Modal,
  Form,
  Select,
  Input,
  Button,
  Menu,
  Dropdown,
  message,
} from 'antd';
import CountReset from './CountReset';
import { DATE_LIST } from '../constant';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import IconFont from '@/components/IconFont';
import icon_close from '@/images/icon_close.svg';
import Style from './index.less';
import moment from 'moment';
import { useEffect, useState } from 'react';
const FormItem = Form.Item;
const SelectOption = Select.Option;

// 设置样式
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: '0',
  textAlign: 'left',
  background: '#ffffff',
  ...draggableStyle,
});

// 重新记录数组顺序
const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const temp = { ...result[startIndex] };
  result[startIndex] = result[endIndex];
  result[endIndex] = temp;
  return result;
};

const getPreviewText = (list) => {
  const texts = list.map((item) => {
    switch (item?.type) {
      case 'date':
        return moment().format(item.value);
      case 'counter':
        const { length } = item.value;
        if (!length) {
          return '';
        }
        return '0'.repeat(length - 1) + 1;
      case 'common':
        return item.value;
      case 'field':
        return item.value ? 'XXXX' : '';
    }

    return '';
  });

  return texts.join('');
};

const Config = (props) => {
  const [form] = Form.useForm();
  const [preview, setPreview] = useState('');
  const { visible, value, onCancel, onChange, fieldList } = props;
  // const initValues = [
  //   {
  //     type: 'date',
  //     value: 'YYYYMMDD',
  //   },
  //   {
  //     type: 'counter',
  //     value: {
  //       start: 1,
  //       length: 8,
  //       reset: 'year',
  //     },
  //   },
  //   {
  //     type: 'common',
  //     value: '',
  //   },
  //   {
  //     type: 'field',
  //     value: '',
  //   },
  // ];

  useEffect(() => {
    if (visible) {
      const text = getPreviewText(value);
      setPreview(text);
      const formatValue = value?.map((item) => {
        // 这里处理field类型，删除字段
        if (item.type == 'field' && item.value) {
          const field = fieldList.find((field) => field.id === item.value);
          if (!field) {
            return {
              ...item,
              value: '',
            };
          }
        }

        return {
          ...item,
        };
      });

      form.setFieldsValue({
        list: formatValue || [],
      });
    }
  }, [visible]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const list = form.getFieldValue('list');

    const items = reorder(list, result.source.index, result.destination.index);
    const text = getPreviewText(items);
    setPreview(text);

    form.setFieldsValue({
      list: items,
    });
  };

  const onFormChange = () => {
    const list = form.getFieldValue('list');

    const text = getPreviewText(list);
    setPreview(text);
  };

  const handleSubmit = (values) => {
    onChange(values.list);
  };

  const getMenus = ({ add }) => {
    const list = form.getFieldValue('list') || [];
    const dateField = list.find((item) => item?.type === 'date');
    const fieldList = list.filter((item) => item?.type === 'field');
    const commonField = list.filter((item) => item?.type === 'common');

    return (
      <Menu className={Style.menuName}>
        <Menu.Item
          onClick={() => {
            if (!!dateField) {
              message.error('当前格式类型最多添加1次');
            } else {
              add({
                type: 'date',
                value: 'YYYYMMDD',
              });
            }
          }}
        >
          提交日期
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            if (commonField.length >= 5) {
              message.error('当前格式类型最多添加5次');
            } else {
              add({
                type: 'common',
                value: '',
              });
            }
          }}
        >
          固定字符
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            if (fieldList.length >= 5) {
              message.error('当前格式类型最多添加5次');
            } else {
              add({
                type: 'field',
                value: '',
              });
            }
          }}
        >
          表单控件
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Modal
      title="设置格式"
      visible={visible}
      width={520}
      okText="保存"
      onCancel={onCancel}
      onOk={form.submit}
      wrapClassName={Style.form}
      closeIcon={<IconFont type="icon-Close" />}
    >
      <Form form={form} onValuesChange={onFormChange} onFinish={handleSubmit}>
        <div className={Style.preview}>流水号预览：{preview}</div>
        <Form.List name="list">
          {(fields, { add, remove }) => {
            return (
              <div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(droppableProvided) => (
                      <div ref={droppableProvided.innerRef}>
                        {fields.map(({ key, name, fieldKey, ...restField }) => {
                          return (
                            <div key={key}>
                              <Draggable
                                key={name}
                                draggableId={String(name)}
                                index={name}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                    )}
                                  >
                                    <div>
                                      <Form.Item shouldUpdate noStyle>
                                        {({ getFieldValue }) => {
                                          const list = getFieldValue('list');
                                          const { type } = list[name] || {};
                                          if (!type) {
                                            return null;
                                          }
                                          let component = null;
                                          switch (type) {
                                            case 'date':
                                              component = (
                                                <FormItem
                                                  {...restField}
                                                  key={key}
                                                  name={[name, 'value']}
                                                  fieldKey={[fieldKey, 'value']}
                                                  label="提交日期"
                                                >
                                                  <Select>
                                                    {DATE_LIST.map(
                                                      (item, index) => {
                                                        return (
                                                          <SelectOption
                                                            className={
                                                              Style.item
                                                            }
                                                            key={index}
                                                            value={item.value}
                                                          >
                                                            {item.label}
                                                          </SelectOption>
                                                        );
                                                      },
                                                    )}
                                                  </Select>
                                                </FormItem>
                                              );
                                              break;
                                            case 'counter':
                                              component = (
                                                <FormItem
                                                  {...restField}
                                                  key={key}
                                                  name={[name, 'value']}
                                                  fieldKey={[fieldKey, 'value']}
                                                  label="自增位数"
                                                >
                                                  <CountReset />
                                                </FormItem>
                                              );
                                              break;
                                            case 'common':
                                              component = (
                                                <FormItem
                                                  {...restField}
                                                  key={key}
                                                  name={[name, 'value']}
                                                  fieldKey={[fieldKey, 'value']}
                                                  label="固定字符"
                                                >
                                                  <Input maxLength={20} />
                                                </FormItem>
                                              );
                                              break;
                                            case 'field':
                                              component = (
                                                <FormItem
                                                  {...restField}
                                                  key={key}
                                                  name={[name, 'value']}
                                                  fieldKey={[fieldKey, 'value']}
                                                  label="表单控件"
                                                >
                                                  <Select>
                                                    {fieldList.map(
                                                      (item, index) => {
                                                        return (
                                                          <SelectOption
                                                            className={
                                                              Style.item
                                                            }
                                                            key={index}
                                                            value={item.id}
                                                          >
                                                            {item.title}
                                                          </SelectOption>
                                                        );
                                                      },
                                                    )}
                                                  </Select>
                                                </FormItem>
                                              );
                                              break;
                                          }

                                          return (
                                            <div className={Style.column}>
                                              <IconFont
                                                type="icon-tuozhuai"
                                                className={Style.drag}
                                              />
                                              <div className={Style.content}>
                                                {component}
                                              </div>
                                              <div className={Style.remove}>
                                                {type !== 'counter' && (
                                                  <IconFont
                                                    type="icon-shanchu"
                                                    onClick={() => {
                                                      remove(name);
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          );
                                        }}
                                      </Form.Item>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            </div>
                          );
                        })}
                        {droppableProvided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <Dropdown overlay={getMenus({ add })}>
                  <Button
                    // className="round-btn"
                    icon={<IconFont type="icon-tianjia1" />}
                  >
                    添加
                  </Button>
                </Dropdown>
              </div>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default Config;
