import React, { useState } from 'react';
import { Modal } from 'antd';
import IconFont from '@/components/IconFont';
import c from './index.less';
import ReactDOM from 'react-dom';
import cx from 'classnames';

const BusinessText = (props: any) => {
  return (
    <Modal title="业务关联" {...props} className={c.modal}>
      <div className={c.content}>
        <div className={c.description}>
          指在新建、编辑或删除一张表单数据时，设定规则同步去增加或减少另一张表单的数据。
          如果设置多个规则，系统会按顺序从上至下依次执行
        </div>
        <p className={c.mt12}>示例</p>
        <p>
          仓库中台式电脑当前的库存数量是50，新建一张【入库单】，入库产品为台式电脑，入库数量为100。在新建完【入库单】后，
          仓库中台式电脑的库存数量由50增加至150
        </p>
        <div className={cx(c.mt12, c.description)}>一、新建规则</div>
        <div className={cx(c.mb12, c.description)}>
          在【关联规则-新建表单】中表单设置关联公式：仓库.库存数量 =
          仓库.库存数量 + 库存数量
        </div>
        <img src={require('@/assets/form/add_rule2.png')} alt="" width="100%" />
        <div className={cx(c.mr12, c.description)}>
          二、设置完关联公式后，员工每次新建【入库单】，库存中此产品的库存数量也会同步增加
        </div>
        <img src={require('@/assets/form/add_rule.png')} alt="" width="100%" />
      </div>
    </Modal>
  );
};

const SubmitText = (props: any) => {
  return (
    <Modal title="表单校验" {...props}>
      <div className={c.content}>
        <div className={c.description}>
          指表单提交或删除数据时，满足校验规则才可提交或删除成功，如果设置多个规则，需全部校验通过
        </div>
        <p className={c.mt12}>示例：</p>
        <p className={c.mb12}>
          在回款单中设置校验规则：【回款金额】不得超过【订单金额】。如果【订单金额】=1000，销售人员在创建回款单时，将【回款金额】写为2000，如下图所示，则回款单无法提交成功
        </p>
        <img
          src={require('@/assets/form/check_rule.png')}
          alt=""
          width="100%"
        />
      </div>
    </Modal>
  );
};

const showDescriptionModal = (type: string) => {
  const id = 'description_modal';
  let ele = document.getElementById(id);
  if (!ele) {
    ele = document.createElement('div');
    ele.id = id;
    document.body.appendChild(ele);
  }

  const closeCallback = () => {
    if (ele) {
      ReactDOM.unmountComponentAtNode(ele);
    }
  };

  const Modal = (props: any) => {
    return type === 'business' ? (
      <BusinessText {...props} />
    ) : (
      <SubmitText {...props} />
    );
  };
  ReactDOM.render(
    <Modal
      visible={true}
      footer={null}
      width={600}
      wrapClassName={c.modalWrap}
      onCancel={closeCallback}
      closeIcon={<IconFont type="icon-Close" />}
    />,
    ele,
  );
};

const AddRulesTooltip = () => {
  return (
    <div className={c.add_rule_tooltip}>
      <div>设置文案后，将在新建、编辑、删除表单遇到规则运行失败时弹出提示</div>
      <p>示例：</p>
      <p className={c.mb12}>
        如果库存数量的设置范围限定了0-10000，此时如果创建一张【采购单】去增加仓库库存，采购数量时20000，
        由于超出了库存数量范围，那么规则运行就会失败，系统弹出提示如下
      </p>
      <img src={require('@/assets/form/add_rule3.png')} alt="" />
    </div>
  );
};

const RuleListSwitchTooltip = () => {
  return (
    <div className={c.switch_tip_content}>
      <p>
        开启开关后，如果设置了多条规则，只要其中一条规则运行失效，则剩余的规则不再执行，且之前执行的规则不生效
      </p>
      <p>示例：</p>
      <p>
        回款单创建了两条规则，规则1用于关联订单中的【已回款金额】字段，规则2用于关联合同中的【已回款金额】字段。
        销售A创建了一张回款单，在执行规则1时成功，在执行规则2时失败
      </p>
      <p>
        如果是开启了开关，则回款单的金额都不会同步修改订单表和合同表中的【已回款金额】如果未开启开关，则会同步修改订单表中的【已回款金额】，不修改合同表中的【已回款金额】
      </p>
    </div>
  );
};

export {
  BusinessText,
  SubmitText,
  AddRulesTooltip,
  showDescriptionModal,
  RuleListSwitchTooltip,
};
