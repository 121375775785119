/*
 * :file description:
 * :name: /low-code-platform/src/pages/generator/form/widget.ts
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-04-16 09:40:37
 * :last editor: 肖国同
 * :date last edited: 2023-02-13 14:42:46
 */
import Attachment from '~@/packages/form-render/src/widgets/Attachment';
import GenerationRules from '~@/packages/form-render/src/widgets/GenerationRules';
import SeqNumberField from '~@/packages/form-render/src/widgets/SeqNumberField';
import Discount from '~@/packages/form-render/src/widgets/Discount';
import DiscountDefault from '~@/packages/form-render/src/widgets/DiscountDefault';
import DataSnippet from '~@/packages/form-render/src/widgets/DataSnippet';
import DataFilters from '~@/packages/form-render/src/widgets/DataFilters';
import BaseSelect from '~@/packages/form-render/src/widgets/BaseSelect';
import EditDepartment from '~@/packages/form-render/src/widgets/EditDepartment';
import EditAddress from '~@/packages/form-render/src/widgets/EditAddress';
import EditInnerContact from '~@/packages/form-render/src/widgets/EditInnerContact';
import Alert from '~@/packages/form-render/src/widgets/Alert';
import JumpLink from '~@/packages/form-render/src/widgets/JumpLink';
import ShowText from '~@/packages/form-render/src/widgets/ShowText';
import Select from '~@/packages/form-render/src/widgets/Select';
import MultiSelect from '~@/packages/form-render/src/widgets/MultiSelect';
import Relation from '~@/packages/form-render/src/widgets/Relation';
import RelationSetting from '~@/packages/form-render/src/widgets/RelationSetting';
import Money from '~@/packages/form-render/src/widgets/Money';
import IPhone from '~@/packages/form-render/src/widgets/IPhone';
import Picture from '~@/packages/form-render/src/widgets/Picture';
import Switch from '~@/packages/form-render/src/widgets/Switch';
import Input from '~@/packages/form-render/src/widgets/Input';
import LimitDot from '~@/packages/form-render/src/widgets/LimitDot';
import SelectOptions from '~@/packages/form-render/src/widgets/SelectOptions';
import InputNumber from '~@/packages/form-render/src/widgets/InputNumber';
import TextNote from '~@/packages/form-render/src/widgets/TextNote';
import TextNoteEditor from '~@/packages/form-render/src/widgets/TextNoteEditor';
import TableList from '~@/packages/form-render/src/widgets/TableList';
import DefaultValueSelector from '~@/packages/form-render/src/widgets/DefaultValueSelector';
import PrincipalDefaultValue from '~@/packages/form-render/src/widgets/PrincipalDefaultValue';
import CooperatorDefaultValue from '~@/packages/form-render/src/widgets/CooperatorDefaultValue';
import ShowUserOrDept from '~@/packages/form-render/src/widgets/ShowUserOrDept';
import DataValidityRange from '~@/packages/form-render/src/widgets/DataValidityRange';
import BizSuite from '~@/packages/form-render/src/widgets/BizSuite';
import BillingSetting from '~@/packages/form-render/src/widgets/BillingSetting';
import FillObject from '~@/packages/form-render/src/widgets/FillObject';
import SortField from '~@/packages/form-render/src/widgets/SortField';
import ShowTipsText from '~@/packages/form-render/src/widgets/ShowTipsText';
import DataTitle from '~@/packages/form-render/src/widgets/DataTitle';
import DataCard from '~@/packages/form-render/src/widgets/DataCard';
import StarRate from '~@/packages/form-render/src/widgets/Rate';
import DataSummary from '~@/packages/form-render/src/widgets/DataSummary';
import LimitedNumRange from '~@/packages/form-render/src/widgets/LimitedNumRange';
import FieldStatus from '~@/packages/form-render/src/widgets/FieldStatus';
import ScanWidget from '~@/packages/form-render/src/widgets/Scan';
import EnableWidget from '~@/packages/form-render/src/widgets/Enable';
import Validation from '~@/packages/form-render/src/widgets/Validation';

import DataFill from '~@/packages/form-render/src/widgets/DataFill';
import Divider from '~@/packages/form-render/src/widgets/Divider';
import IdCard from '~@/packages/form-render/src/widgets/IdCard';
import Email from '~@/packages/form-render/src/widgets/Email';
import ShowNumLocation from '~@/packages/form-render/src/widgets/ShowNumLocation';
import SelectDivider from '~@/packages/form-render/src/widgets/SelectDivider';
import RelatesInfo from '~@/packages/form-render/src/widgets/RelatesInfo';
import CustomerTags from '~@/packages/form-render/src/widgets/CustomerTags';
import CascadeWidget from '~@/packages/form-render/src/widgets/Cascade';
import MultiCascadeWidget from '~@/packages/form-render/src/widgets/MultiCascade';
import SelectTreeOptions from '~@/packages/form-render/src/widgets/SelectTreeOptions';
import SignatureWidget from '~@/packages/form-render/src/widgets/Signature';
import SignatureSample from '~@/packages/form-render/src/widgets/SignatureSample';
import ProcessRelation from '~@/packages/form-render/src/widgets/processRelation';
import AvailableProcessForms from '~@/packages/form-render/src/widgets/AvailableProcessForms';
import TableMode from '~@/packages/form-render/src/widgets/TableMode';

export const widgets = {
  select: Select,
  baseSelect: BaseSelect,
  multiSelect: MultiSelect,
  relation: Relation,
  relationSetting: RelationSetting,
  money: Money,
  phone: IPhone,
  starRating: StarRate,
  picture: Picture,
  switch: Switch,
  input: Input,
  scan: ScanWidget,
  enable: EnableWidget,
  validation: Validation,
  limitDot: LimitDot,
  alert: Alert,
  selectOptions: SelectOptions,
  number: InputNumber,
  textNote: TextNote,
  textNoteEditor: TextNoteEditor,
  table: TableList,
  defaultValue: DefaultValueSelector,
  principalDefaultValue: PrincipalDefaultValue,
  cooperatorDefaultValue: CooperatorDefaultValue,
  dataValidityRange: DataValidityRange,
  showUserOrDept: ShowUserOrDept,
  dataTitle: DataTitle,
  dataCard: DataCard,
  attachment: Attachment,
  innerContact: EditInnerContact,
  cooperator: EditInnerContact,
  principal: EditInnerContact,
  department: EditDepartment,
  address: EditAddress,
  generationRules: GenerationRules,
  seqNumber: SeqNumberField,
  discount: Discount,
  discountDefault: DiscountDefault,
  dataSnippet: DataSnippet,
  dataSummary: DataSummary,
  dataFilters: DataFilters,
  dataFill: DataFill,
  jumplink: JumpLink,
  cascade: ShowText,
  cascadeV2: CascadeWidget,
  multiCascade: MultiCascadeWidget,
  contractStatus: ShowText,
  salesStage: ShowText,
  productStatus: ShowText,
  bizSuite: BizSuite,
  billingSetting: BillingSetting,
  sortFields: SortField,
  ocrIdCard: ShowTipsText,
  externalContact: ShowTipsText,
  timeAndLocation: ShowTipsText,
  // starRating: ShowTipsText,
  recipientAccount: ShowTipsText,
  relate: ShowTipsText,
  columnLayout: ShowTipsText,
  signature: SignatureWidget,
  invoice: ShowTipsText,
  ocrText: ShowTipsText,
  limitedNumRange: LimitedNumRange,
  fieldStatus: FieldStatus,
  divider: Divider,
  idCard: IdCard,
  email: Email,
  showNumLocation: ShowNumLocation,
  selectDivider: SelectDivider,
  relatesInfo: RelatesInfo,
  customerTags: CustomerTags,
  selectTreeOptions: SelectTreeOptions,
  fillObject: FillObject,
  signatureSample: SignatureSample,
  processRelation: ProcessRelation,
  availableProcessForms: AvailableProcessForms,
  tableMode: TableMode,
};
