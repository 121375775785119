/*
 * :file description:
 * :name: \low-code-platform\src\components\FileViewer\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2022-02-15 15:13:16
 * :last editor: hyw
 * :date last edited: 2022-02-25 11:16:44
 */
import FileViewer from 'react-file-viewer';
import {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Modal, Button } from 'antd';
import './index.less';
import IconFont from '@/components/IconFont';
import { dowmLoadFile } from '@/utils';

const Viewer = (
  {
    fileType,
    filePath,
    title,
  }: { fileType: string; filePath: string; title: string },
  ref,
) => {
  const cRef = ref || useRef();
  const [visible, setVisible] = useState(false);
  const CustomErrorComponent = () => {
    return <div>不支持预览</div>;
  };

  const onError = (e) => {
    console.log(e, 'error in file - viewer');
  };

  const openModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
  };

  useImperativeHandle(cRef, () => ({
    openModal,
    closeModal,
  }));

  const renderViewer = () => {
    const imageType = [
      'png',
      'PNG',
      'bmp',
      'BMP',
      'tiff',
      'TIFF',
      'gif',
      'GIF',
      'jpeg',
      'JPEG',
      'svg',
      'SVG',
    ];
    const pdfType = ['pdf', 'PDF'];
    const officeType = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'];
    const videoType = ['video', 'audio'];
    if (pdfType.includes(fileType)) {
      return (
        <embed
          src={filePath}
          type="application/pdf"
          width="100%"
          height="100%"
        />
      );
    }
    if (officeType.includes(fileType)) {
      let preUrl =
        'https://view.officeapps.live.com/op/view.aspx?src=' +
        encodeURIComponent(filePath);
      return (
        <iframe title="预览" src={preUrl} width="100%" height="100%"></iframe>
      );
    }

    if (imageType.includes(fileType)) {
      return (
        // <FileViewer
        //   fileType={fileType}
        //   filePath={filePath}
        //   errorComponent={CustomErrorComponent}
        //   onError={onError}
        // />
        // <img src={filePath} alt="" />
        <div
          className="showImgArea"
          style={{ backgroundImage: `url(${filePath})` }}
        >
          {/* <img src={filePath} alt="" /> */}
        </div>
      );
    }

    if (videoType.includes(fileType)) {
      let preUrl = encodeURIComponent(filePath);
      return (
        <video width="100%" height="100%" controls>
          <source src={preUrl} />
        </video>
      );
    }

    return '不支持该类型';
  };

  return (
    <Modal
      width={600}
      visible={visible}
      title={title}
      className="fileModal"
      style={{ height: 500 }}
      destroyOnClose={true}
      onCancel={() => closeModal()}
      closeIcon={<IconFont type="icon-Close" />}
      footer={
        <Button
          type="primary"
          onClick={() => {
            dowmLoadFile(filePath, fileType);
          }}
        >
          <IconFont type="icon-xiazaibaocun" />
          下载
        </Button>
      }
    >
      {renderViewer()}
    </Modal>
  );
};

export default forwardRef(Viewer);
