/*
 * :file description:
 * :name: /low-code-platform/src/components/FormGenerator/components/Sidebar/Element.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-12 10:13:45
 * :last editor: caihua
 * :date last edited: 2023-01-29 14:16:34
 */
import React, { useEffect, useState } from 'react';
import { customAlphabet } from 'nanoid';
import { useDrag } from 'react-dnd';
import { message, Modal, Button, Tooltip } from 'antd';
import { addItem } from '../../utils';
import { useGlobal, useStore } from '../../utils/hooks';
import './Element.less';
import IconFont from '@/components/IconFont';
import { useModel, useLocation } from 'umi';
import classnames from 'classnames';
import { userSimpleInfo } from '@/services/user';
import { TableWidgetsWidteList } from '~@/packages/form-render/src/widgets/TableList';
import { ALPHABET, NANOID_LENGTH } from '../../utils/constant';
import { getOaFormSchema } from '@/services/form-generator';
import { userAgentObj } from '@/utils';
import QRCode from 'qrcode.react';
import { editType } from '@/pages/generator/form';
import { isOpenInTripartitePlatform } from '@/utils/tripartitePlatform';

export const nanoid = customAlphabet(ALPHABET, NANOID_LENGTH);

export enum WidgetType {
  // 基础控件
  Base,
  // 关联表单控件
  Relation,
}

export const widgetsImg = {
  CRM: {},
  CloudPlatform: {
    'icon-shenfenzheng': '#shenfenzheng',
  },
  All: {
    'icon-email': '#youxiang',
    'icon-line-jilian': '#jilian',
  },
};

// 目前没有用icon，但是可以补上
const BaseWidgetUI = ({ onClick, text, icon, disabled, from }) => {
  return (
    <li
      className={classnames('left-item base-item img-item', {
        disabled: disabled,
      })}
      onClick={disabled ? null : onClick}
    >
      {!!from && from !== 'All' ? (
        <svg className="iconpark-icon left-item-img">
          <use href={widgetsImg?.[from]?.[icon]}></use>
        </svg>
      ) : from === 'All' ? (
        <svg className="iconpark-icon left-item-img">
          <use href={widgetsImg?.[from]?.[icon]}></use>
        </svg>
      ) : (
        <IconFont className="left-item-icon " type={icon} />
      )}
      <span className="left-item-text">{text}</span>
    </li>
  );
};

// 关联表单控件
const RelationWidgetUI = ({ onClick, text, icon, iconColor, isOaRelation }) => {
  return (
    <li className="left-item relation-item" onClick={onClick}>
      <section className="relation-item-con">
        <div style={{ background: iconColor }} className="left-item-icon-wrap">
          {isOaRelation ? (
            <img src={icon} alt="" />
          ) : (
            <IconFont className="left-item-icon" type={icon} />
          )}
        </div>
        <span title={text}>{text}</span>
      </section>
      <IconFont className="relation-icon" type="icon-lianjie"></IconFont>
    </li>
  );
};

const widgetUIMap = new Map([
  [WidgetType.Base, BaseWidgetUI],
  [WidgetType.Relation, RelationWidgetUI],
]);

const Element = ({
  text,
  name,
  schema,
  icon,
  iconColor,
  fixedName,
  componentType,
  fieldId,
  max,
  isOaRelation,
  from,
}) => {
  const [visible, setVisible] = useState(false);
  const [currentFormCode, setCurrentFormCode] = useState('');
  const [oaDataTitle, setOaDataTitle] = useState('');
  const location = useLocation<ILocation>();

  const { type } = location?.query || {};
  const [{ isDragging }, dragRef] = useDrag({
    type: 'box',
    item: {
      dragItem: {
        parent: '#',
        schema,
        children: [],
      },
      $id: fieldId
        ? fieldId
        : fixedName ||
          name?.includes('principal') ||
          name?.includes('cooperator')
        ? `#/${name}`
        : `#/${name}_${nanoid()}`,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const setGlobal = useGlobal();
  const { widgetType, displaySchema, formMunberLimit } = useStore();
  const { selected, flatten, itemError, onFlattenChange } = useStore();
  const parentKey = flatten[selected]?.parent;
  const selectedParentWidget = flatten[parentKey]?.schema?.widget;
  const model = useModel('useGenerator', (model) => model);
  let disabled = false;
  if (max) {
    // 处理单个表单 只能添加一个流水号
    const properties = model.schemaRef.current?.properties || {};
    const keys = Object.keys(properties);
    const existWidgets = keys.filter(
      (key) => properties[key].widget == schema.widget,
    );
    if (existWidgets.length >= max) {
      disabled = true;
    }
  }

  const handleElementClick = async () => {
    const { properties } = displaySchema;
    const size = Object.keys(properties).length;
    let userAgent = userAgentObj();
    if (model.isSaveDisabled && type === editType.EDIT) {
      message.warning('表单数据出现异常，请重新刷新页面');
      return;
    }
    if (userAgent.isDingTalk) {
      // 基于目前需求运用在钉钉测数量限制根据接口来判定
      if (size + 1 > formMunberLimit) {
        message.warning('您添加的字段数已达到上限');
        return;
      }
    }

    // 如果是oa关联表单，则需要走接口请求看是否已经授权了

    const isOaRelation = schema?.isOaRelation;
    if (
      selectedParentWidget === 'table' &&
      flatten[selected]?.widget !== 'table' &&
      !TableWidgetsWidteList.includes(schema?.widget)
    ) {
      message.warning('该控件不能添加进表格');
      return;
    }

    if (itemError?.length) return;
    if (selected && !flatten[selected]) {
      setGlobal({ selected: '#' });
      return;
    }

    if (isOaRelation && userAgent.isDingTalk) {
      const {
        dataSource: {
          target: { formCode },
        },
      } = schema;
      setCurrentFormCode(formCode);

      getOaFormSchema({
        formCode: formCode,
      })
        .then((res) => {
          const { newId, newFlatten } = addItem({
            selected,
            name,
            fieldId,
            schema,
            flatten,
            fixedName,
            componentType,
          });
          onFlattenChange(newFlatten);
          setGlobal({ selected: newId });
          setGlobal({ lastFlatten: flatten });
        })
        .catch((err) => {
          if (err?.code == 10040001) {
            // message.warning('当前表单未授权');
            setVisible(true);
            setOaDataTitle(schema?.title);
          }
        });
      return;
    }
    const { newId, newFlatten } = addItem({
      selected,
      name,
      fieldId,
      schema,
      flatten,
      fixedName,
      componentType,
    });

    console.log(
      '🚀 ~ file: Element.tsx ~ line 133 ~ handleElementClick ~ newFlatten',
      newFlatten,
    );
    onFlattenChange(newFlatten);
    setGlobal({ selected: newId });
    setGlobal({ lastFlatten: flatten });
  };

  const WidgetUI = widgetUIMap.get(widgetType);

  return (
    <>
      <div ref={dragRef}>
        <WidgetUI
          text={text}
          icon={icon}
          iconColor={iconColor}
          disabled={disabled}
          from={from}
          onClick={handleElementClick}
          isOaRelation={isOaRelation}
        />
      </div>
      <Modal
        width={420}
        visible={visible}
        title="授权确认"
        className="authModal"
        destroyOnClose={true}
        onCancel={() => setVisible(false)}
        closeIcon={<IconFont type="icon-Close" />}
        footer={null}
      >
        <div className="des">
          你需要将【{oaDataTitle}
          】的审批数据授权给【CRM】后，才可以在CRM的表单中关联到【
          {oaDataTitle}】的数据
        </div>
        <p className="tips">用手机钉钉扫码授权登录</p>
        <div className="qrcode">
          <QRCode
            value={`${
              isOpenInTripartitePlatform('1688')
                ? DOMAIN_QRCODE_1688
                : DOMAIN_QRCODE
            }/tungee/crm/mobile/?subscribeId=${
              window.__subscribeId
            }#/authorize?processCode=${currentFormCode}&corpId=${
              window.__corpId
            }`}
            size={160}
          />
        </div>
      </Modal>
    </>
  );
};

export default Element;
