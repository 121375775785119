/*
 * @Author: 刘昕 xinlx@tungee.com
 * @Date: 2022-10-11 16:22:35
 * @LastEditors: 刘昕 xinlx@tungee.com
 * @LastEditTime: 2022-12-02 20:28:42
 * @FilePath: \tj-ali-crm-management\src\pages\Connector\Drawer\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { Children, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useModel } from 'umi';
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  Select,
  message,
  Spin,
  Modal,
  Alert,
  Space,
} from 'antd';
import CustomTable from './Components/customTable';
import Style from './index.less';
import { getFormSchemaForDesignStatus } from '@/pages/generator/form/services/form-generator';
import {
  createConnector,
  getConnectorDetail,
  queryConnectorConfigList,
  getConnectorConfigDetail,
  updateConnectorProcedures,
} from '@/services/extension-setting/index';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { jumpDataConectorFConfig } from '@/utils/index';

const { TextArea } = Input;
const { Option } = Select;
interface ILocation {
  formCode: string;
  type: string;
}

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

const widgetConfig = {
  // 单选
  select: [
    { label: '.选项ID', value: 'key' },
    { label: '.选项值', value: 'value' },
  ],
  // 多选
  multiSelect: [
    { label: '.选项ID', value: 'key' },
    { label: '.选项值', value: 'value' },
  ],
  // 日期区间
  dateRange: [
    { label: '.开始时间', value: 'begin' },
    { label: '.结束时间', value: 'end' },
  ],
  // 电话
  phone: [
    { label: '.国家编码', value: 'countryCode' },
    { label: '.号码', value: 'value' },
  ],
  // 成员
  innerContact: [
    { label: '.成员ID', value: 'key' },
    { label: '.成员名称', value: 'value' },
  ],
  // 部门
  department: [
    { label: '.部门ID', value: 'key' },
    { label: '.部门名称', value: 'value' },
  ],
  // 关联表单
  relation: [
    { label: '.表单ID', value: 'formCode' },
    { label: '.实例ID', value: 'instanceId' },
  ],
};

const defaultOptions: Option[] = [
  {
    value: 'false',
    label: '当前表单提交后的数据',
    children: [],
  },
  {
    value: 'true',
    label: '系统默认字段',
    children: [
      {
        value: 'instanceId',
        label: '表单实例ID',
      },
      {
        value: 'processInstanceId',
        label: '流程实例ID',
      },
      {
        value: 'formCode',
        label: '表单唯一标识（formCode）',
      },
      {
        value: 'gmtCreate',
        label: '表单实例创建时间',
      },
      {
        value: 'gmtModified',
        label: '表单实例修改时间',
      },
      {
        value: 'createUserId',
        label: '表单创建人ID',
      },
      {
        value: 'updateUserId',
        label: '表单修改人ID',
      },
    ],
  },
];

const TriggerTypesMap = {
  0: '创建成功',
  1: '编辑成功',
  2: '删除成功',
};

export const schemaOptionsContext = React.createContext([]);
export const connectorDetailContext = React.createContext({});
const Index = (props: any) => {
  const [modal, contextHolder] = Modal.useModal();
  const { visible, width, setDrawerVisible, editable, currentConnectorId } =
    props;
  const location = useLocation<ILocation>();
  const { formCode, type, menu } = location?.query || {};
  //  第三列数据 options
  const [schemaJsonOptions, setSchemaJsonOptions] = useState(defaultOptions);
  // 表单的 schema数据
  const [schemaJson, setSchemaJson] = useState<Option[]>([]);
  const [form] = Form.useForm();
  const formConnectorIdValue = Form.useWatch('connectorId', form);
  const formTriggerTypesListValue = Form.useWatch('triggerTypes', form);
  const formQueryValue = Form.useWatch('query', form) || [];
  const formBodyValue = Form.useWatch('body', form) || [];
  const formPathValue = Form.useWatch('path', form) || [];
  const formHeadersValue = Form.useWatch('headers', form) || [];
  const [connectorOptions, setConnectorOptions] = useState<Option[]>([]);
  const [tableName, setTableName] = useState('');
  const [connectorDetail, setConnectorDetail] = useState({
    query: [],
    body: [],
    path: [],
    headers: [],
  });
  const [requestParams, setRequestParams] = useState<string[]>([]);
  // const [pushApiPath, setPushApiPath] = useState<string | undefined>(undefined);
  const [connectorName, setConnectorName] = useState<string | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState<Boolean | undefined>(false);

  const {
    handleReturnCallback,
    handleFormCode,
    updateProfile,
    profile,
    schemaRef,
    oaListRef,
    relatedFormRef,
    formcode,
    formPropsSettingRef,
    snapshotRef,
    upVersionLimit,
  } = useModel('useGenerator', (model) => ({
    ...model,
    formcode: formCode,
  }));

  const onClose = () => {
    setDrawerVisible(false);
  };

  useEffect(() => {
    formConnectorIdValue &&
      visible &&
      getConnectorConfigDetail({
        id: formConnectorIdValue,
        needThrowExWhenNotFount: true,
      })
        .then((res: any) => {
          setConnectorName(res?.data?.title || '');
          const connectorConfig = JSON.parse(res?.data?.config || '{}');
          setConnectorDetail(connectorConfig);
        })
        .catch((error) => {
          console.log('---->getConnectorConfigDetail error', error);
        });
  }, [formConnectorIdValue, visible]);

  useEffect(() => {
    if (formConnectorIdValue) {
      // 处理字段已经被删除的情况
      if (
        connectorOptions.findIndex((i) => i.value === formConnectorIdValue) ===
        -1
      ) {
        const connectorList = _.clone(connectorOptions);
        connectorList.push({
          label: (
            <span className={Style.connectorEmpty}>
              该连接器已经被删除,请选择其他连接器
            </span>
          ),
          value: formConnectorIdValue,
          disabled: true,
        });
        setConnectorOptions(connectorList);
      }

      form.setFieldsValue({
        query: [],
        body: [],
        path: (connectorDetail?.path || []).map((i) => ({
          ...i,
          fieldId: i?.id,
          type: 'const',
          level: 1,
          fieldType: 'string',
          elementType: '',
        })),
        headers: [],
      });
    }
  }, [formConnectorIdValue, connectorDetail.path, visible]);

  const initQueryConectorConfigList = async () => {
    const params = {
      order: [],
      pageNum: 1,
      pageSize: 1000,
      sort: [],
    };
    try {
      const res = await queryConnectorConfigList(params);
      const connectorList = (res?.data?.records || []).map((i) => ({
        label: i.title,
        value: i.id,
        // key: i.id,
      }));

      setConnectorOptions(connectorList);
    } catch (error) {
      console.log('---->error 222', error);
    }
  };

  const injectionChildren = (widget: string, title = '') => {
    let children: Option[];
    switch (widget) {
      case 'select':
        children = widgetConfig.select.map((i) => ({
          ...i,
          label: `${title}${i?.label}`,
        }));
        break;
      case 'multiSelect':
        children = widgetConfig.multiSelect.map((i) => ({
          ...i,
          label: `${title}${i?.label}`,
        }));
        break;
      case 'dateRange':
        children = widgetConfig.dateRange.map((i) => ({
          ...i,
          label: `${title}${i?.label}`,
        }));
        break;
      case 'phone':
        children = widgetConfig.phone.map((i) => ({
          ...i,
          label: `${title}${i?.label}`,
        }));
        break;
      case 'innerContact':
        children = widgetConfig.innerContact.map((i) => ({
          ...i,
          label: `${title}${i?.label}`,
        }));
        break;
      case 'department':
        children = widgetConfig.department.map((i) => ({
          ...i,
          label: `${title}${i?.label}`,
        }));
        break;
      default:
        children = [];
    }
    return children;
  };
  // recursionIterMap(data["properties"])
  const recursionIterMap: Option[] = (mapObject: any) => {
    const arr = [];
    for (const [key, value] of Object.entries(mapObject)) {
      const item: Option = {
        value: key,
        label: value.title,
      };
      if (
        value.hasOwnProperty('properties') &&
        _.keys(value['properties']).length > 0
      ) {
        //  默认递归所有的 properties
        item.children = recursionIterMap(value['properties']);
        if (value?.widget === 'relation') {
          const widgetList = widgetConfig.relation.map((i) => ({
            ...i,
            label: `${value?.title}${i?.label}`,
          }));
          item.children = item.children?.concat(widgetList);
        }
      } else if (value.hasOwnProperty('items') && value?.widget === 'table') {
        // table 有点特殊
        item.children = recursionIterMap(value['items']['properties'] || []);
      } else if (
        value.hasOwnProperty('fields') &&
        value?.widget === 'relation'
      ) {
        // 合同套件 relation 有点特殊
        item.children = recursionIterMap(value['fields'] || []);
      } else {
        item.children = injectionChildren(value?.widget || '', value?.title);
      }
      arr.push(item);
    }
    return arr;
  };

  const initSchema = async () => {
    const { data } = await getFormSchemaForDesignStatus({ formCode });
    const {
      content = '{}',
      icon,
      iconColor,
      description,
      title,
      propsSettings,
      bizType,
      formType,
      relatedFormInfos,
    } = data || {};
    const profile = {
      icon,
      iconColor,
      description,
      title,
      bizType,
      formType,
    };
    const schema = JSON.parse(content);
    // 兼容迁移的表单数据 没有properties属性 导致提示需要保存表单
    for (let key in schema?.properties) {
      const item = schema?.properties[key];
      if (key?.includes('relation_') || key?.includes('select_')) {
        if (!item.properties) {
          item.properties = {};
        }
      }
    }
    if (schema.hasOwnProperty('properties')) {
      const processedData = recursionIterMap(schema['properties']);
      setSchemaJson(processedData);
      setTableName(title);
    }
    updateProfile(profile);
  };

  useEffect(() => {
    // 字段变量的schema
    initSchema();
  }, []);

  useEffect(() => {
    // 查询连接器列表，选项
    visible && initQueryConectorConfigList();
  }, [visible]);

  useEffect(() => {
    const newSchemaJsonOptions = _.cloneDeep(schemaJsonOptions);
    newSchemaJsonOptions[0].children = schemaJson;
    setSchemaJsonOptions(newSchemaJsonOptions);
  }, [schemaJson, form]);

  useEffect(() => {
    const params = [];
    if (formPathValue && formPathValue.length > 0) {
      params.push('Path');
    }
    if (formQueryValue && formQueryValue.length > 0) {
      params.push('Query');
    }
    if (formHeadersValue && formHeadersValue.length > 0) {
      params.push('Headers');
    }
    if (formBodyValue && formBodyValue.length > 0) {
      params.push('Body');
    }

    setRequestParams(params);
  }, [
    JSON.stringify(formBodyValue),
    JSON.stringify(formQueryValue),
    JSON.stringify(formHeadersValue),
    JSON.stringify(formPathValue),
  ]);

  useEffect(() => {
    if (editable && visible) {
      getConnectorDetail(currentConnectorId)
        .then((res) => {
          setLoading(true);
          const data = res.data;
          // const connectorConfig = JSON.parse(res?.data?.config || '{}');
          // const requestDefinitionPath = data['requestDefinition']['path'];
          // const lastedPath = (connectorDetail?.path || []).map((i) => ({
          //   ...i,
          //   fieldId: i?.id,
          //   type: 'const',
          //   level: 1,
          //   fieldType: 'string',
          //   elementType: '',
          // }));
          // const newData = _.unionBy(
          //   requestDefinitionPath,
          //   lastedPath,
          //   'fieldId',
          // );
          // console.log("------------>newData",newData)
          // data['path'] = newData || [];
          // data['query'] = data['requestDefinition']['query'] || [];
          // data['headers'] = data['requestDefinition']['headers'] || [];
          // data['body'] = data['requestDefinition']['body'] || [];
          // form.setFieldsValue(data);
          // setTimeout(() => {
          //   const requestDefinitionPath = data['requestDefinition']['path'];
          //   const lastedPath = (connectorDetail?.path || []).map((i) => ({
          //     ...i,
          //     fieldId: i?.id,
          //     type: 'const',
          //     level: 1,
          //     fieldType: 'string',
          //     elementType: '',
          //   }));
          //   const newData = _.unionBy(
          //     requestDefinitionPath,
          //     lastedPath,
          //     'fieldId',
          //   );
          //   data['path'] = newData || [];
          //   form.setFieldsValue(data);
          //   setLoading(false);
          // }, 600);

          getConnectorConfigDetail({
            id: data?.connectorId || formConnectorIdValue,
            needThrowExWhenNotFount: true,
          })
            .then((res: any) => {
              const connectorConfig = JSON.parse(res?.data?.config || '{}');
              const requestDefinitionPath = data['requestDefinition']['path'];
              const lastedPath = (connectorConfig?.path || []).map((i) => ({
                ...i,
                fieldId: i?.id,
                type: 'const',
                level: 1,
                fieldType: 'string',
                elementType: '',
              }));
              const newData = _.unionBy(
                requestDefinitionPath,
                lastedPath,
                'name',
              );
              console.log('------------>newData', newData);
              data['path'] = newData || [];
              data['query'] = data['requestDefinition']['query'] || [];
              data['headers'] = data['requestDefinition']['headers'] || [];
              data['body'] = data['requestDefinition']['body'] || [];
              form.setFieldsValue(data);
              setTimeout(() => {
                form.setFieldsValue(data);
                setLoading(false);
              }, 1000);
            })
            .catch((error) => {
              console.log('---->getConnectorConfigDetail error', error);
              data['path'] = data['requestDefinition']['path'] || [];
              data['query'] = data['requestDefinition']['query'] || [];
              data['headers'] = data['requestDefinition']['headers'] || [];
              data['body'] = data['requestDefinition']['body'] || [];
              form.setFieldsValue(data);
              setTimeout(() => {
                form.setFieldsValue(data);
                setLoading(false);
              }, 500);
            });
        })
        .catch((error) => {
          console.log('error');
          setLoading(false);
        });
    } else {
      form.setFieldsValue({
        title: undefined,
        connectorId: undefined,
        triggerTypes: [],
        path: [],
        query: [],
        headers: [],
        body: [],
      });
    }
  }, [editable, currentConnectorId, visible]);

  const onFinish = (values: any) => {
    console.log('----->Success:', values);
    let data = _.cloneDeep(values);
    // 过滤空字符串
    const recursionFilterEmpty = (arr: any) => {
      return arr
        .map((item: any) => {
          if (item.fieldId === '' || item.type === '') {
            return null;
          }
          if (Array.isArray(item.assignments) && item.assignments.length > 0) {
            item.assignments = recursionFilterEmpty(item.assignments);
          }
          return item;
        })
        .filter((v) => v);
    };

    data['formCode'] = formCode;
    data['requestDefinition'] = {
      path: recursionFilterEmpty(data['path']),
      query: recursionFilterEmpty(data['query']),
      headers: recursionFilterEmpty(data['headers']),
      body: recursionFilterEmpty(data['body']),
    };
    delete data['query'];
    delete data['body'];
    delete data['path'];
    delete data['headers'];

    if (editable) {
      modal.confirm({
        title: '确定保存',
        icon: <ExclamationCircleOutlined />,
        content: '编辑后，会同步更新数据推送的配置关系，确定保存？',
        onOk: () => {
          updateConnectorProcedures(currentConnectorId, data)
            .then((res) => {
              message.success('编辑成功');
              setDrawerVisible(false);
            })
            .catch((error) => {
              message.error('保存失败');
            });
        },
        onCancel: () => {
          console.log('Cancel');
        },
      });
    } else {
      createConnector(data)
        .then((res) => {
          message.success('保存成功');
          setDrawerVisible(false);
        })
        .catch((error) => {
          message.error('保存失败');
        });
    }
  };

  return (
    <schemaOptionsContext.Provider value={schemaJsonOptions}>
      <connectorDetailContext.Provider value={connectorDetail}>
        {contextHolder}
        <Drawer
          title={editable ? '数据推送' : '数据推送'}
          width={width}
          placement="right"
          onClose={onClose}
          open={visible}
          // extra={
          //     <Button onClick={onClose}>Cancel</Button>
          // }

          footer={
            <div className={Style.footerBtn}>
              <Button
                className={Style.footerBtnClose}
                onClick={onClose}
                style={{ marginRight: '8px' }}
              >
                取消
              </Button>
              <Button type="primary" onClick={form.submit}>
                保存
              </Button>
            </div>
          }
          className={Style.drawer}
        >
          <Spin spinning={loading}>
            <Form layout="horizontal" form={form} onFinish={onFinish}>
              <Alert
                message={`当前触发的表单：${tableName}`}
                type="info"
                showIcon
                className={Style.alert}
              />
              <Form.Item
                // noStyle={true}
                label="规则名称"
                name={'title'}
                rules={[
                  {
                    required: true,
                    message: '规则名称不能为空',
                  },
                ]}
              >
                <Input placeholder="请输入" maxLength={20} showCount />
              </Form.Item>

              <Form.Item
                label="触发事件"
                name={'triggerTypes'}
                rules={[
                  {
                    required: true,
                    message: '请选择触发事件',
                  },
                ]}
              >
                <Checkbox.Group>
                  <Checkbox value={0}>创建成功</Checkbox>
                  <Checkbox value={1}>编辑成功</Checkbox>
                  <Checkbox value={2}>删除成功</Checkbox>
                </Checkbox.Group>
              </Form.Item>
              <div className={Style.copyWriter}>
                <span>推送规则&nbsp;</span>
                <span>(满足表单触发事件时，触发推送规则)</span>
              </div>
              <div className={Style.firstStep}>第一步：选择推送接口</div>
              <Form.Item
                label=""
                name={'connectorId'}
                style={{ height: '32px', marginBottom: '8px' }}
                rules={[
                  {
                    required: true,
                    message: '请选择推送接口',
                  },
                ]}
              >
                <Select
                  listItemHeight={10}
                  //  listHeight={250}
                  style={{ width: '100%' }}
                  options={connectorOptions}
                  placeholder="请选择推送接口"
                />
              </Form.Item>
              <div className={Style.navLink}>
                没有我想要的推送接口?&nbsp;
                <a
                  onClick={() => {
                    jumpDataConectorFConfig({});
                  }}
                >
                  创建推送接口
                </a>
              </div>
              {formConnectorIdValue ? (
                <>
                  <div className={Style.secondStep}>第二步：配置推送关系</div>
                  <Form.Item label="Path" name={'path'} noStyle>
                    <CustomTable
                      requestType="Path"
                      schemaJsonOptions={schemaJsonOptions}
                    />
                  </Form.Item>

                  <Form.Item label="Query" name={'query'} noStyle>
                    <CustomTable
                      requestType="Query"
                      schemaJsonOptions={schemaJsonOptions}
                    />
                  </Form.Item>

                  <Form.Item label="Headers" name={'headers'} noStyle>
                    <CustomTable
                      requestType="headers"
                      schemaJsonOptions={schemaJsonOptions}
                    />
                  </Form.Item>

                  <Form.Item label="Body" name={'body'} noStyle>
                    <CustomTable
                      requestType="Body"
                      schemaJsonOptions={schemaJsonOptions}
                    />
                  </Form.Item>
                </>
              ) : null}

              <div className={Style.desc}>
                <div>说明</div>当{tableName}
                {formTriggerTypesListValue && formTriggerTypesListValue.length
                  ? formTriggerTypesListValue
                      .map((i) => TriggerTypesMap[i] || '')
                      .join(',')
                  : '触发事件'}
                时，将通过{connectorName ?? '推送接口'}把
                {requestParams.length > 0
                  ? `${requestParams.join(',')}的字段信息`
                  : '字段信息'}
                推送给三方系统
              </div>
            </Form>
          </Spin>
        </Drawer>
      </connectorDetailContext.Provider>
    </schemaOptionsContext.Provider>
  );
};

export default Index;
