/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\constant.ts
 * :author: 黄艺
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-22 15:26:36
 * :last editor: 黄艺
 * :date last edited: 2022-03-21 15:39:49
 */
export enum SearchTypeLabel {
  EQUAL = '等于',
  SEARCH = '搜索',
  LT = '小于',
  GT = '大于',
  RANGE = '范围区间',
  BELONG = '属于',
  BF = '早于',
  AF = '晚于',
}

export enum SearchTypeValue {
  EQUAL = 'EQ',
  SEARCH = 'SEARCH',
  LT = 'LT',
  GT = 'GT',
  RANGE = 'BETWEEN',
  BELONG = 'IN',
  BF = 'LT',
  AF = 'GT',
}

export const SEARCH_TYPE_OPTIONS_OF_E_S = [
  {
    label: SearchTypeLabel.EQUAL,
    value: SearchTypeValue.EQUAL,
  },
  {
    label: SearchTypeLabel.SEARCH,
    value: SearchTypeValue.SEARCH,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_E_L_G_R = [
  {
    label: SearchTypeLabel.EQUAL,
    value: SearchTypeValue.EQUAL,
  },
  {
    label: SearchTypeLabel.LT,
    value: SearchTypeValue.LT,
  },
  {
    label: SearchTypeLabel.GT,
    value: SearchTypeValue.GT,
  },
  {
    label: SearchTypeLabel.RANGE,
    value: SearchTypeValue.RANGE,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_E = [
  {
    label: SearchTypeLabel.EQUAL,
    value: SearchTypeValue.EQUAL,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_B = [
  {
    label: SearchTypeLabel.BELONG,
    value: SearchTypeValue.BELONG,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_S_E = [
  {
    label: SearchTypeLabel.SEARCH,
    value: SearchTypeValue.SEARCH,
  },
  {
    label: SearchTypeLabel.EQUAL,
    value: SearchTypeValue.EQUAL,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_B_E = [
  {
    label: SearchTypeLabel.BELONG,
    value: SearchTypeValue.BELONG,
  },
  {
    label: SearchTypeLabel.EQUAL,
    value: SearchTypeValue.EQUAL,
  },
];

export const SEARCH_TYPE_OPTIONS_OF_B_A_R = [
  {
    label: SearchTypeLabel.BF,
    value: SearchTypeValue.BF,
  },
  {
    label: SearchTypeLabel.AF,
    value: SearchTypeValue.AF,
  },
  {
    label: SearchTypeLabel.RANGE,
    value: SearchTypeValue.RANGE,
  },
];

// 关联表单渲染类型
export enum RenderType {
  SEARCH = 'search',
  CREATE = 'create',
}

export const notFormField = ['createUserName', 'gmtCreate', 'formTitle'];

export const CONTRACT_ORDER_BIZ_TYPE = 'contract_order_kit';

// 合同扩展字段5个
// 合同中已回款金额 biz_contract_paid_amount  默认0
export const BIZ_CONTRACT_PAID_AMOUNT = 'biz_contract_paid_amount';
// 合同中未回款金额 biz_contract_unpaid_amount  默认页面填写的合同金额
export const BIZ_CONTRACT_UNPAID_AMOUNT = 'biz_contract_unpaid_amount';
// 合同中已开票金额 biz_contract_invoiced_amount  默认0
export const BIZ_CONTRACT_INVOICED_AMOUNT = 'biz_contract_invoiced_amount';
// 合同中未开票金额 biz_contract_uninvoiced_amount  默认页面填写的合同金额
export const BIZ_CONTRACT_UNINVOICED_AMOUNT = 'biz_contract_uninvoiced_amount';
// 合同中新建提醒  biz_contract_dynamic_remind 默认0
export const BIZ_CONTRACT_DYNAMIC_REMIND = 'biz_contract_dynamic_remind';
// 订单扩展字段4个
// 订单中已回款金额  biz_order_paid_amount  默认0
export const BIZ_ORDER_PAID_AMOUNT = 'biz_contract_dynamic_remind';
// 订单中未回款金额  biz_order_unpaid_amount  默认页面填写的订单金额
export const BIZ_ORDER_UNPAID_AMOUNT = 'biz_contract_dynamic_remind';
// 订单中已开票金额  biz_order_invoiced_amount   默认0
export const BIZ_ORDER_INVOICED_AMOUNT = 'biz_contract_dynamic_remind';
// 订单中未开票金额  biz_order_uninvoiced_amount  默认页面填写的订单金额
export const BIZ_ORDER_UNINVOICED_AMOUNT = 'biz_contract_dynamic_remind';

// 根据是否关联表单回显金额控件
export const BIZ_SUMMARY_LIST = ['crm_invoice_apply', 'crm_payment'];
export const BIZ_FILTER_SUMMARY = {
  // 关联开票
  crm_invoice_apply: [
    BIZ_CONTRACT_INVOICED_AMOUNT,
    BIZ_CONTRACT_UNINVOICED_AMOUNT,
  ],
  // 关联回款
  crm_payment: [BIZ_CONTRACT_PAID_AMOUNT, BIZ_CONTRACT_UNPAID_AMOUNT],
};
