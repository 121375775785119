/*
 * :file description:
 * :name: \crmd:\progree\low-code-platform\tg-renderer\packages\form-render\src\widgets\Phone\index.tsx
 * :author: 黄艺
 * :copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 14:28:41
 * :last editor: hyw
 * :date last edited: 2022-01-12 20:02:27
 */
import { Input, Select, Button } from 'antd';
import c from './index.less';
import countryCodes from './countryCodes';
import { FC, useEffect } from 'react';
import { WidgetProps } from 'typings';

const { Option } = Select;
const DEFAULT_CODE = '+86';

interface IValue {
  mode?: string;
  areaNumber?: string;
  countryKey?: string;
  flag?: string;
  countryCode?: string;
  flagPy?: string;
  countryNameZh?: string;
  countryName?: string;
  countryNamePy?: string;
  value?: string;
}

const PhoneWidget: FC<WidgetProps<IValue>> = ({
  value,
  onChange,
  schema,
  readOnly,
}) => {
  const { placeholder, parentWidget } = schema;
  const handleSelect = (countryCode: string) => {
    const addressInfo = countryCodes.find(
      (item) => item.countryCode === countryCode,
    );
    onChange({ ...value, ...addressInfo });
  };

  useEffect(() => {
    handleSelect(DEFAULT_CODE);
  }, []);

  if (readOnly) {
    return (
      <span className="showNoEditText">
        {value?.value ? `${value?.countryCode}-${value?.value}` : ''}
      </span>
    );
  }
  return (
    <div
      className={c.container}
      style={{ width: parentWidget === 'table' ? '500px' : '' }}
    >
      <Input.Group compact>
        {schema?.mode === 'phone' && <Button className={c.mobile}>手机</Button>}
        {schema?.mode === 'tel' && (
          <Button className={c.telephone}>固话</Button>
        )}
        {schema?.mode === 'phone_tel' && (
          <Select
            onChange={(e) =>
              onChange({
                ...value,
                mode: e,
              })
            }
            className={c.phoneType}
            defaultValue={'phone'}
            value={value?.mode}
          >
            <Option value="phone">手机</Option>
            <Option value="tel">固话</Option>
          </Select>
        )}
        <Select
          dropdownClassName={c.phoneDorpdown}
          onSelect={(e) => handleSelect(e)}
          className={c.code}
          value={value?.countryCode}
          defaultValue={DEFAULT_CODE}
        >
          {countryCodes.map((item) => (
            <Option key={item.countryKey} value={item.countryCode}>
              {item.countryNameZh}
              {item.countryCode}
            </Option>
          ))}
        </Select>
        {value?.mode === 'tel' && (
          <Input
            type="number"
            placeholder="区号"
            value={value?.areaNumber}
            min={0}
            onChange={(e) =>
              onChange({
                ...value,
                areaNumber: e.target.value,
              })
            }
            className={c.provinceCode}
          />
        )}
        <Input
          type="number"
          min={0}
          className={c.input}
          placeholder={placeholder}
          value={value?.value}
          onChange={(e) => onChange({ ...value, value: e.target.value })}
        />
      </Input.Group>
    </div>
  );
};

export default PhoneWidget;
