/*
 * :file description:
 * :name: \low-code-platform\src\components\renderer\TDatePicker\index.tsx
 * author: gaoch
 * copyright: (c) 2021, Tungee
 * :date created: 2021-10-14 10:35:33
 * :last editor: gaoch
 * :date last edited: 2021-11-04 14:05:49
 */

import React, { ReactNode } from 'react';
import { DatePicker, DatePickerProps } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker/generatePicker/index';
import moment from 'moment';
import { RenderType } from '@/pages/form/constant';
import c from './index.less';

const DEFAULT_DTAE_CONFIG = {
  allowClear: true,
  disabledDate: null,
  placeholder: '请选择日期',
  picker: 'date', // date | week | month | quarter | year
  suffixIcon: null,
  format: 'YYYY-MM-DD', //
  panelRender: () => {},
  onChange: () => {},
  onOk: () => {},
  onPanelChange: () => {},
};

// 日期格式化
const dateFormat = (date: any, format = 'YYYY-MM-DD') => {
  return moment(date, format);
};

const DEFAULT_RANGE_CONFIG = {
  defaultPickerValue: null,
  disabledTime: null,
  ranges: null,
  separator: null,
  allowEmpty: [false, false],
  placeholder: ['开始日期', '结束日期'],
  onCalendarChange: () => {},
};

const { RangePicker } = DatePicker;

const TDatePicker = (
  props: DatePickerProps & RangePickerProps<any> & { [key: string]: any },
) => {
  const matchedComponent = (): ReactNode => {
    const { format, value, rendertype, ...rest } = props;
    const { placeholder } = DEFAULT_DTAE_CONFIG;
    const { placeholder: rangePlaceholer } = DEFAULT_RANGE_CONFIG;

    let comp = null;
    if (rendertype === RenderType.SEARCH) {
      const [start, end] = value ?? [];
      comp = (
        <RangePicker
          style={{ width: '100%' }}
          {...rest}
          value={
            value
              ? [dateFormat(start, 'YYYY-MM-DD'), dateFormat(end, 'YYYY-MM-DD')]
              : undefined
          }
          placeholder={rangePlaceholer}
        />
      );
    } else {
      switch (format) {
        case 'date':
          comp = (
            <DatePicker
              style={{ width: '100%' }}
              {...rest}
              value={value ? dateFormat(value, 'YYYY-MM-DD') : undefined}
              placeholder={placeholder}
            />
          );
          break;
        case 'dateTime':
          const [start, end] = value ?? [];
          comp = (
            <RangePicker
              style={{ width: '100%' }}
              {...rest}
              value={
                value
                  ? [
                      dateFormat(start, 'YYYY-MM-DD'),
                      dateFormat(end, 'YYYY-MM-DD'),
                    ]
                  : undefined
              }
              placeholder={props?.props?.placeholder || rangePlaceholer}
            />
          );
          break;
      }
    }
    return comp;
  };

  return <div className={c.t_date}>{matchedComponent()}</div>;
};

export default TDatePicker;
