import Header from '../components/Header';
import React, { useState, useEffect, useContext } from 'react';
import { history, useLocation, useModel } from 'umi';
import { Button, Switch, Table, Tooltip, Modal, message } from 'antd';
import IconFont from '@/components/IconFont';
import c from './index.less';
import ModalRule from './ModalRule';
import { MenuOutlined } from '@ant-design/icons';
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { getUrlParam } from '@/utils';
import {
  getFormAssociationRule,
  saveAssociationRule,
} from '@/services/extension-setting';
import { RuleListSwitchTooltip } from '../components/Description';
import _cloneDeep from 'lodash/cloneDeep';
import { useGray } from '../hooks';

const DragHandle = SortableHandle(() => (
  <IconFont
    type="icon-tuozhuai"
    style={{ cursor: 'grab', color: '#999', fontSize: '18px' }}
  />
));

const SortableItem = SortableElement(
  (props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />,
);

const SortableBody = SortableContainer(
  (props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
  ),
);

export default () => {
  const location = useLocation();
  const { source = '' } = location?.query || {};
  const formCode = getUrlParam('formCode');
  const [visible, setVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const [dataSource, setDataSource] = useState<IAssociationRules[]>([]);
  const [currentRule, setCurrentRule] = useState<IAssociationRules>();
  const [version, setVersion] = useState<number>();

  const businessSourceUpperLimit = useGray('business_source_upper_limit');

  const columns = [
    {
      title: '',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />,
    },
    {
      title: '规则名称',
      dataIndex: 'name',
      key: 'name',
      width: '60%',
    },
    {
      title: '是否启用',
      dataIndex: 'enable',
      width: '100px',
      key: 'enable',
      render: (data: string, record: IAssociationRules, index: any) => {
        return (
          <div className={c.actions}>
            <span>
              <Switch
                checked={record.status != 0}
                onChange={(val) => {
                  handleToggele(val, record, index);
                }}
              />
            </span>
          </div>
        );
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      width: '240px',
      render: (data: string, record: IAssociationRules, index: any) => {
        return (
          <div className={c.actions}>
            <span
              onClick={() => {
                handleCopy(record, index);
              }}
            >
              复制
            </span>
            <span
              onClick={() => {
                handleEdit(record);
              }}
            >
              编辑
            </span>
            <span onClick={() => handleDetete(record)}>删除</span>
          </div>
        );
      },
    },
  ];
  const handleToggele = async (
    val: boolean,
    data: IAssociationRules,
    index: any,
  ) => {
    const ruleItem = _cloneDeep(data);
    ruleItem.status = val ? 1 : 0;
    const newData = dataSource.slice();
    newData.splice(index, 1, ruleItem);
    setDataSource(newData);
    await saveRules(undefined, newData);
    message.success(val ? '开启成功！' : '关闭成功！');
  };
  const handleCopy = async (data: IAssociationRules, index: any) => {
    const ruleItem = _cloneDeep(data);
    ruleItem.ruleId = new Date().getTime();
    setVisible(true);
    setCurrentRule(ruleItem);
  };
  const handleEdit = (data: IAssociationRules) => {
    setVisible(true);
    setCurrentRule(data);
  };

  const handleDetete = (data: IAssociationRules) => {
    Modal.confirm({
      title: '确认删除',
      content: '是否删除此条规则',
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const newData = dataSource.filter(
          (item) => item.ruleId !== data.ruleId,
        );
        await saveRules(undefined, newData);
        message.success('删除成功！');
      },
    });
  };

  useEffect(() => {
    getFormRuleList();
  }, []);

  const getFormRuleList = () => {
    getFormAssociationRule({ formCode }, formCode).then((res) => {
      const data = res?.data;
      // 处理规则的status状态 为了兼容 status不存在
      if (data && data.associationRules && data.associationRules.length) {
        data.associationRules.forEach((item) => {
          item.status = item.status == 0 ? 0 : 1;
        });
      }
      setChecked(data?.stopAssociationRulesIfFailed);
      setDataSource(data?.associationRules ?? []);
      setVersion(data?.version);
    });
  };

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = dataSource.slice();
      newData.splice(newIndex, 0, ...newData.splice(oldIndex, 1));
      setDataSource(newData);
      saveRules(undefined, newData);
    }
  };

  const DraggableBodyRow: React.FC<any> = ({
    className,
    style,
    ...restProps
  }) => {
    const index = dataSource.findIndex(
      (x) => x.ruleId === restProps['data-row-key'],
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass={c['row-dragging']}
      onSortEnd={onSortEnd}
      {...props}
    />
  );
  //businessSourceUpperLimit
  const onOpenModal = () => {
    if (dataSource.length >= (businessSourceUpperLimit ? 50 : 10)) {
      Modal.warning({
        title: '提示',
        content: '最多可创建10条规则，你可以选择删除现有规则后在创建',
      });
      return;
    }
    setVisible(true);
  };

  const saveRules = async (
    stopAssociationRulesIfFailed = checked,
    associationRules = dataSource,
  ) => {
    const data = {
      formCode,
      associationRules,
      stopAssociationRulesIfFailed,
      version,
    };
    await saveAssociationRule(data);
    setCurrentRule(undefined);
    getFormRuleList();
    return Promise.resolve();
  };

  return (
    <div>
      <Header>
        <Button
          type="primary"
          icon={<IconFont type="icon-tianjia1" />}
          onClick={onOpenModal}
        >
          添加规则
        </Button>
      </Header>
      <div className={c.content}>
        <div className={c.title}>
          <span>规则运行</span>
          <Tooltip
            placement="right"
            title={<RuleListSwitchTooltip />}
            className={c.tooltip}
            overlayClassName={c.ant_tips}
          >
            <IconFont className={c.tip_icon} type="icon-shuoming"></IconFont>
          </Tooltip>
        </div>
        <div className={c.description}>
          <span style={{ marginRight: '12px' }}>
            当一条规则允许失败时，终止全部规则执行
          </span>
          <Switch
            checked={checked}
            onChange={async (val) => {
              setChecked(val);
              await saveRules(val);
              message.success('保存成功！');
            }}
          />
        </div>
        <Table
          dataSource={dataSource}
          rowKey="ruleId"
          columns={columns}
          pagination={false}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </div>
      <ModalRule
        visible={visible}
        currentRule={currentRule}
        onChange={(data) => {
          const newData = dataSource.slice();
          const hasIndex = newData.some((item) => item.ruleId === data.ruleId);
          if (
            typeof currentRule === 'object' &&
            Object.keys(currentRule).length > 0 &&
            hasIndex
          ) {
            const index = newData.findIndex(
              (item) => item.ruleId === data.ruleId,
            );
            newData.splice(index, 1, data);
          } else {
            newData.push(data);
          }
          return saveRules(undefined, newData);
        }}
        onClose={() => {
          setVisible(false);
          setCurrentRule(undefined);
        }}
      />
    </div>
  );
};
