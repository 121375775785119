/*
 * :file description:
 * :name: \low-code-platform\tg-renderer\packages\form-render\src\widgets\Attachment\index.tsx
 * :author: hyw
 * :copyright: (c) 2022, Tungee
 * :date created: 2021-12-31 14:07:27
 * :last editor: hyw
 * :date last edited: 2022-02-11 10:15:17
 */
import { Upload, Button } from 'antd';
import Iconfont from '@/components/IconFont';

const Attachment = () => {
  return (
    <div>
      <Button
        // className="round-btn"
        disabled
        icon={<Iconfont type="icon-fujian" />}
      >
        添加
      </Button>
    </div>
  );
};

export default Attachment;
