import { Switch, Checkbox, InputNumber, Row, Col } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useStore } from '@/components/FormGenerator/utils/hooks';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import Styles from './index.less';

const CheckboxGroup = Checkbox.Group;

const Validation = (props: any) => {
  const { flatten, selected, onFlattenChange } = useStore();
  const { addons, value, schema: defaultSchema } = props;
  const [indeterminate, setIndeterminate] = useState(true);

  const { formData, setValues, setValueByPath } = addons;

  const options = useMemo(() => {
    return defaultSchema?.allType?.map((item, index) => {
      return { label: defaultSchema?.allTypeNames?.[index], value: item };
    });
  }, [defaultSchema?.allType]);

  const onChange = (e: any) => {
    setValues({
      ...formData,
      validation: {
        ...formData?.validation,
        limitLength: e.target.checked,
      },
    });
  };
  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setIndeterminate(false);
    setValues({
      ...formData,
      validation: {
        ...formData?.validation,
        formatAll: e.target.checked,
        formatType: e.target.checked ? [...defaultSchema?.allType] : [],
      },
    });
  };
  const onCheckChange = (arr: CheckboxValueType[]) => {
    setIndeterminate(
      !!arr.length && arr.length < defaultSchema?.allType?.length,
    );
    setValues({
      ...formData,
      validation: {
        ...formData?.validation,
        formatAll: arr.length === defaultSchema?.allType?.length,
        formatType: [...arr],
      },
    });
  };

  useEffect(() => {
    if (!selected?.includes('input')) {
      return;
    }
    if (
      value &&
      value.formatType?.length &&
      value.formatType?.length !== defaultSchema?.allType?.length
    ) {
      setIndeterminate(true);
    } else {
      setIndeterminate(false);
    }
    setValues({
      ...formData,
      validation: {
        ...(formData.validation || defaultSchema),
      },
    });
  }, [selected, value]);

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.titleName}>长度限制</div>
      <div>
        <Checkbox checked={value?.limitLength} onChange={(e) => onChange(e)}>
          限制输入长度
        </Checkbox>
      </div>
      {value?.limitLength && (
        <div className={Styles.inputDiv}>
          <InputNumber
            min={0}
            max={100}
            defaultValue={value?.min}
            value={value?.min}
            onChange={(min) => {
              setValues({
                ...formData,
                validation: {
                  ...formData?.validation,
                  min: min,
                },
              });
            }}
            placeholder="请输入"
          />
          <span className={Styles.inputDesSpan}>～</span>
          <InputNumber
            min={0}
            max={100}
            defaultValue={value?.max}
            value={value?.max}
            onChange={(max) => {
              setValues({
                ...formData,
                validation: {
                  ...formData?.validation,
                  max: max,
                },
              });
            }}
            placeholder="请输入"
          />
        </div>
      )}
      {value?.limitLength && (
        <div className={Styles.inputErrTip}>
          {(!value.min || !value.max) && value.min !== 0 && value.max !== 0
            ? '请输入长度'
            : value.min < 0
            ? '最小长度不能为负数'
            : value.max > 100
            ? '最大长度不能大于100'
            : value.min > value.max
            ? '最小长度不能大于最大长度'
            : ''}
        </div>
      )}
      <div className={Styles.titleName2}>支持输入格式</div>
      <div className={Styles.checkDiv}>
        <Checkbox
          className={Styles.allCheckDiv}
          indeterminate={indeterminate}
          onChange={(e) => onCheckAllChange(e)}
          checked={value?.formatAll}
        >
          全选
        </Checkbox>
        {/* <CheckboxGroup
          className={Styles.checkboxGroup}
          options={options}
          value={value?.formatType}
          onChange={(e) => onCheckChange(e)}
        /> */}
        <Checkbox.Group
          value={value?.formatType}
          onChange={(e) => onCheckChange(e)}
          className={Styles.checkboxGroup}
        >
          <Row>
            <Col span={8} className={Styles.hiddenCheckbox}></Col>
            {options.map((item) => (
              <Col span={8}>
                <Checkbox value={item.value} key={item.value}>
                  {item.label}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </div>
      {value?.formatType && !value?.formatType.length && (
        <div className={Styles.inputErrTip}>请选择需要输入的格式</div>
      )}
    </div>
  );
};

export default Validation;
