/*
 * @Author: wu.shangru roywu@tungee.com
 * @Date: 2023-04-23 19:00:08
 * @LastEditors: wu.shangru
 * @LastEditTime: 2024-01-15 19:06:17
 * @FilePath: /low-code-platform/src/pages/generator/form/index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { useEffect, useRef, useState } from 'react';
import { Button, message, Modal } from 'antd';
import CustomFormProfile, { IProfile } from '@/components/CustomFormProfile';
import Generator from '@/components/FormGenerator';
import _ from 'lodash';
import c from './index.less';
import ModalContent from './components/ModalContent';
import { useHistory, useLocation, useModel } from 'umi';
import 'antd/lib/alert/style/index';
import { SettingType } from '~@/packages/form-render/src/widgets/DataTitle';
import { getMenu } from './services/user';
import {
  getFormSchemaForDesignStatus,
  ISaveFormSchema,
  saveFormSchema,
  getFormWidgetPropertyLimit,
} from './services/form-generator';
import { widgets } from './widget';
import { parse, stringify } from 'querystring';
import {
  TipContent,
  removeProperties,
  CUSTOM_WIDGETS_ADD_DATAFILLING,
  ARRAY_TYPE,
  SELECR_TYPE,
  RELATE_MODE_TABLE,
  CASCADE_ID,
} from './constant';
import OaAuthModal from '@/components/OaAuthModal';
import { getOaFormSchema } from '@/services/form-generator';
import { getUrlParam } from '@/utils';
import { CUSTOMER_BIZ_TYPES } from '~@/packages/form-render/src/widgets/DataSnippet/contants';

// import { mockSchema } from './mock'
import { CascaderOption } from '../relation/duplicated/constants';
import CascadeWidget from '../../../../tg-renderer/packages/form-render/src/widgets/Cascade/index';

export interface IRequireItem {
  key: string;
  label: string;
  message: string;
}

export enum editType {
  CREATE = '0',
  EDIT = '1',
}

export const DEFAULT_SCHEMA = {
  type: 'object',
  properties: {},
};

interface ILocation {
  formCode: string;
  type: string;
}

interface IRef {
  onSubmit: () => Promise<ISaveFormSchema>;
}

// 【单行输入框】【多行输入框】【单选】【多选】【成员】【部门】【金额】【数字】【日期】【日期控件】 控件类型添加校验
const SUPPORT_COMMON_TIP = [
  'number',
  'money',
  'input',
  'textarea',
  'select',
  'multiSelect',
  'innerContact',
  'department',
  'date',
  'dateRange',
];

let timerId: string | number | NodeJS.Timeout | undefined;

const GeneratorForm = () => {
  const history = useHistory();
  const location = useLocation<ILocation>();
  const { formCode, type, menu } = location?.query || {};
  const [modalVisible, setModalVisible] = useState(false);
  const [schema, setSchema] = useState();
  const [cascaderOptionIds, setCascadeOptionsIds] = useState([]);

  const [oaCodeList, setOACodeList] = useState<
    ({ [key: string]: string } | null)[]
  >([]);
  const childRef = useRef<IRef>();
  const profileRef = useRef<IProfile>();
  console.log(
    '🚀 ~ file: index.tsx ~ line 61 ~ GeneratorForm ~ profileRef',
    profileRef,
  );
  const {
    handleReturnCallback,
    handleFormCode,
    updateProfile,
    profile,
    schemaRef,
    oaListRef,
    relatedFormRef,
    formcode,
    formPropsSettingRef,
    snapshotRef,
    upVersionLimit,
    isSaveDisabled,
    updateSaveStatus,
  } = useModel('useGenerator', (model) => ({
    ...model,
    formcode: model.formCode,
  }));
  const { updatePermission } = useModel('usePermission', (model) => ({
    updatePermission: model.updatePermission,
  }));

  const getBoolean = (fieldStatus: Array<string> = [], type: number) => {
    let boo = false;
    switch (type) {
      case 1:
        boo = fieldStatus.includes('readOnly');
        break;
      default:
        break;
    }
    return boo;
  };

  const mapType = (value: any, type: string) => {
    return typeof value == type;
  };

  const judgingNullValues = (item: any): boolean => {
    let flag =
      (item?.association?.filters?.length || 0) !== 0 ||
      (item?.rely?.formula || '') !== '' ||
      !(!item.default && item.default !== 0);
    return !flag;
  };

  // 公共提示部分
  const commonTip = (data: Array<any>, isShowTable?: boolean) => {
    const errInput: Array<any> = [];
    let tipType = '1',
      needTip = false,
      isPass = true,
      tableTitleTip = '';
    try {
      data?.forEach((val: any, index: number) => {
        // 只读、必填、无默认值，保存时需提示
        if (
          val.required &&
          getBoolean(val.fieldStatus, 1) &&
          judgingNullValues(val)
        ) {
          errInput.push(val.title);
          if (val.fieldStatus?.includes('hidden')) {
            tipType = '3';
          } else {
            tipType = '1';
          }
          needTip = true;
          tableTitleTip = val.tableTitle;
          throw Error();
        }
        if (val.limitedNumber) {
          const { min, max } = val?.limitedNumberRange;
          // 都没有填  (判断keys为0的情况是转化schema的时候为null的会被删除)
          if (
            (mapType(min, 'object') && mapType(max, 'object')) ||
            Object.keys(val?.limitedNumberRange).length == 0
          ) {
            message.error(
              `${isShowTable ? `${val.tableTitle}中的子项 ` : ''}${
                val.title
              } 数值范围最少需输入一个值`,
            );
            isPass = false;
            throw Error();
          } else if (mapType(min, 'number') && mapType(max, 'number')) {
            // 都填了
            if (min > max) {
              message.error(
                `${isShowTable ? `${val.tableTitle}中的子项 ` : ''}${
                  val.title
                } 数值范围最大值不能小于最小值`,
              );
              isPass = false;
              throw Error();
            }
            if (
              typeof val.default == 'number' &&
              (max < Number(val.default || 0) || min > Number(val.default || 0))
            ) {
              message.error(
                `${isShowTable ? `${val.tableTitle}中的子项 ` : ''}${
                  val.title
                }默认值不在限定数值范围内`,
              );
              isPass = false;
              throw Error();
            }
          }
          if (mapType(max, 'number') && typeof val.default == 'number') {
            // 只填了最大值一个的场景，要判断默认值是否大于最大值限制
            if (max < Number(val.default || 0)) {
              message.error(
                `${isShowTable ? `${val.tableTitle}中的子项 ` : ''}${
                  val.title
                }默认值大于限定数值范围`,
              );
              isPass = false;
              throw Error();
            }
          }
          if (mapType(min, 'number') && typeof val.default == 'number') {
            // 只填了最大值一个的场景，要判断默认值是否大于最大值限制
            if (min > Number(val.default || 0)) {
              message.error(
                `${isShowTable ? `${val.tableTitle}中的子项 ` : ''}${
                  val.title
                }默认值小于限定数值范围`,
              );
              isPass = false;
              throw Error();
            }
          }
        }
      });
    } catch (e) {
      console.log(e);
    }

    if (needTip) {
      Modal.confirm({
        title: '提示',
        content: (
          <>
            <p>
              {isShowTable ? `${tableTitleTip}中的子项 ` : ''}
              <span className={c.displayText}> {errInput.join('、')}</span>
              {TipContent[tipType].content}
            </p>
            <p>
              {TipContent[tipType].display.map((val: string, i: number) => (
                <span className={c.displaySpan}>{val}</span>
              ))}
            </p>
          </>
        ),
      });
    }
    return {
      errInput,
      tipType,
      needTip,
      isPass,
    };
  };

  const handleClick = () => {
    const requireList: IRequireItem[] = [];
    setTimeout(() => {
      childRef?.current?.onSubmit().then(async (values: ISaveFormSchema) => {
        const { title } = values;
        const dataTitle = formPropsSettingRef?.current?.find(
          (item) => item.propsKey === 'dataTitle',
        );
        if (!title) {
          requireList.push({
            key: 'baseSetting',
            label: '基础设置',
            message: '表单名称不能为空',
          });
        }

        if (
          dataTitle?.settingType === SettingType.CUSTOM &&
          !dataTitle.propsValue
        ) {
          requireList.push({
            key: 'formDesign',
            label: '基础设置',
            message: '自定义数据标题不能为空',
          });
        }

        if (Object.keys(schemaRef.current?.properties).length === 0) {
          requireList.push({
            key: 'formDesign',
            label: '表单设计',
            message: '空表单不能保存',
          });
        }
        let allData = Object.values(schemaRef.current?.properties) || [];
        const tableList = allData.filter((val) => val.widget === 'table');

        // 修正数字卡片里面不同步的title属性
        const dataCard = formPropsSettingRef?.current?.find(
          (item) => item.propsKey === 'dataCard',
        );
        if (dataCard?.propsValue?.fields?.length) {
          dataCard.propsValue.fields.forEach((i) => {
            i.title = schemaRef.current?.properties?.[i?.id]?.title;
          });
        }

        let content: any = {
          type: 'object',
          properties: {},
        };
        if (
          snapshotRef.current?.bizType &&
          CUSTOMER_BIZ_TYPES.includes(snapshotRef.current.bizType)
        ) {
          let _allData: any[] = [];
          let customerTagsIndex = -1;
          const multiTagQueue: any[] = [];
          let hasChangeFirstMultiTag = false;
          allData?.forEach((val: any, i) => {
            if (val.id === 'relatesInfo') {
              return;
            }
            if (val.id === 'customerTags') {
              if (multiTagQueue.length) {
                _allData = [..._allData, ...multiTagQueue];
              }
              customerTagsIndex = i;
              return;
            }
            if (val.widget === 'multiTag') {
              if (customerTagsIndex === -1) {
                multiTagQueue.push(val);
                return;
              } else if (!hasChangeFirstMultiTag) {
                _allData.splice(
                  customerTagsIndex - multiTagQueue.length,
                  0,
                  val,
                );
                hasChangeFirstMultiTag = true;
                return;
              }
            }
            _allData.push(val);
          });
          _allData?.forEach((item) => {
            content.properties[item.id] = item;
          });
          allData = _allData;
        } else {
          content = schemaRef.current;
        }
        //1  负责人，协同人 继承关联表单必填校验
        //2  单选或者多选时  加一个字段去修正关联 + 公式 不计算的问题
        //3  组件为input时，需要是否选了限制长度 以及格式
        //4  定制双关联表 企业客户 和个人客户  构造了table前缀  保存时需要去掉  通过 @@ 来进行识别  前端构造时添加的
        allData?.forEach((val: any) => {
          if (['principal', 'cooperator'].includes(val.widget)) {
            if (
              val.valueBasisType === 'valueBasisInherit' &&
              !val?.valueBasisInherit
            ) {
              requireList.push({
                key: 'formDesign',
                label: val.title,
                message: `取值依据-请选择要继承的关联表单`,
              });
            } else if (
              val.defaultType === 'defaultInherit' &&
              !val?.defaultInherit
            ) {
              requireList.push({
                key: 'formDesign',
                label: val.title,
                message: `默认值-请选择要继承的关联表单`,
              });
            }
          }
          if (['select', 'multiSelect'].includes(val.widget)) {
            val.enumAssociatedFieldsObjForFormula = {};
            val.enum?.map((enumItem: any, index: any) => {
              val.enumAssociatedFieldsObjForFormula[enumItem] =
                val.enumAssociatedFields?.[index];
            });
          }
          if (['input'].includes(val.widget)) {
            val.max = 100;
            if (
              val.validation?.limitLength &&
              (!val.validation?.min || !val.validation?.max)
            ) {
              requireList.push({
                key: 'formDesign',
                label: val.title,
                message: `请限制输入长度`,
              });
            }
            if (
              val.validation?.limitLength &&
              val.validation?.min &&
              val.validation?.max &&
              val.validation?.min > val.validation?.max
            ) {
              requireList.push({
                key: 'formDesign',
                label: val.title,
                message: `最小长度大于最大长度`,
              });
            }
            if (
              val.validation?.formatType &&
              !val.validation?.formatType?.length
            ) {
              requireList.push({
                key: 'formDesign',
                label: val.title,
                message: `请填写支持格式`,
              });
            }
          }
          if (
            val?.widget?.includes('cascade') &&
            !CASCADE_ID?.includes(val?.id)
          ) {
            val.widget = 'cascade';
          }
          // 有一个bug
          // 组织成员的type 被置成 member_field
          // 暂时还没找到问题
          if (ARRAY_TYPE.includes(val.widget)) {
            val.type = 'array';
          }
          // 多选单选的选项可能会在运行态设置  会造成权限和选项不对齐
          if (SELECR_TYPE.includes(val.widget)) {
            const arr = val?.enumPowerFields?.flat(2);
            if (!arr?.length) {
              delete val.enumPowerFields;
            }
          }

          if (['relation'].includes(val.widget) && val?.relateSource?.length) {
            const bizTypeArr = val.relateSource.map((item) => item.bizType);
            if (
              bizTypeArr?.includes('crm_customer') ||
              bizTypeArr?.includes('crm_customer_personal')
            ) {
              val.dataFillingRules = val.dataFillingRules?.map((item) => {
                const sourceArr = item?.source?.split('/');
                return {
                  ...item,
                  source: sourceArr?.[0].includes('@@')
                    ? sourceArr?.slice(1).join('/')
                    : item?.source,
                };
              });
            }
          }
          // 关联定制套件保存时 需要处理下数据
          // 需要把extendProperties 去掉
          if (['bizSuite'].includes(val.widget)) {
            val._properties = {};
            if (val?.extendProperties?.isNewMode) {
              const updateKeys = Object.keys(val?.properties);
              const tableKeys = Object.keys(val?.extendProperties)?.filter(
                (i) => i?.includes('table'),
              );
              tableKeys.forEach((i, index) => {
                const tableWidget = val?.extendProperties?.[i] ?? {};
                const items = val?.extendProperties?.[i]?.items;
                Object.keys(items?.properties)?.forEach((j) => {
                  if (updateKeys?.includes(j)) {
                    items.properties = {
                      ...items?.properties,
                      [j]: val?.properties?.[j],
                    };
                    // 需要保持 table 和 relation 的hidden一致
                    tableWidget.hidden = val?.properties?.[j]?.hidden;
                  } else {
                    items.properties = {
                      ...items?.properties,
                      [j]: {
                        ...items?.properties?.[j],
                        hidden: items?.properties?.[updateKeys[index]]?.hidden,
                        required:
                          items?.properties?.[updateKeys[index]]?.required,
                      },
                    };
                  }
                });
                val._properties = {
                  ...val?._properties,
                  [i]: val?.extendProperties?.[i],
                };
              });
              val.properties = {
                ...val?._properties,
              };
            }
            delete val._properties;
            delete val.extendProperties;
          }
          // 地址控件需要手动加一个dependencies给运行态
          if (['address'].includes(val.widget)) {
            val.dependencies = val?.numberLocation?.map((i) => i?.value);
          }
          // 修正取值依据的数值
          if (['email', 'phone'].includes(val.widget)) {
            if (val.valueBasisType === 'valueBasisInherit') {
              val.defaultType = null;
            }
          }
        });
        const numberAndMoneyList: any[] = [];
        tableList?.forEach((val: any) => {
          let len = Object.entries(val?.items?.properties || {}).length;
          const propertiesList = Object.values(val?.items?.properties || {});
          if (!len) {
            requireList.push({
              key: 'formDesign',
              label: '表单设计',
              message: `${val?.title ?? ''}没有选择子项`,
            });
          }

          propertiesList?.forEach((v: any) => {
            if (SUPPORT_COMMON_TIP.includes(v.widget)) {
              numberAndMoneyList.push({
                ...v,
                tableTitle: val?.title,
              });
            }
            // 有一个bug
            // 组织成员的type 被置成 member_field
            // 暂时还没找到问题
            if (ARRAY_TYPE.includes(v.widget)) {
              v.type = 'array';
            }
            // 多选单选的选项可能会在运行态设置  会造成权限和选项不对齐
            if (SELECR_TYPE.includes(v.widget)) {
              const arr = v?.enumPowerFields?.flat(2);
              if (!arr?.length) {
                delete v.enumPowerFields;
              }
            }
            // 地址控件需要手动加一个dependencies给运行态
            if (['address'].includes(v?.widget)) {
              v.dependencies = v?.numberLocation?.map((i) => i?.value);
            }
            if (['input'].includes(v?.widget)) {
              v.max = 100;
              if (
                v.validation?.limitLength &&
                (!v.validation?.min || !v.validation?.max)
              ) {
                requireList.push({
                  key: 'formDesign',
                  label: v.title,
                  message: `请限制输入长度`,
                });
              }
              if (
                v.validation?.limitLength &&
                v.validation?.min &&
                v.validation?.max &&
                v.validation?.min > v.validation?.max
              ) {
                requireList.push({
                  key: 'formDesign',
                  label: v.title,
                  message: `最小长度大于最大长度`,
                });
              }
              if (
                v.validation?.formatType &&
                !v.validation?.formatType?.length
              ) {
                requireList.push({
                  key: 'formDesign',
                  label: v.title,
                  message: `请填写支持格式`,
                });
              }

              // 修正取值依据的数值
              if (['email', 'phone'].includes(v.widget)) {
                if (v.valueBasisType === 'valueBasisInherit') {
                  v.defaultType = null;
                }
              }
            }
            if (
              v?.widget?.includes('cascade') &&
              !CASCADE_ID?.includes(v?.id)
            ) {
              v.widget = 'cascade';
            }
            // 处理enumPowerFields 和 enum 长度不等问题
            if (['select', 'multiSelect'].includes(v?.widget)) {
              v.enumAssociatedFieldsObjForFormula = {};
              v.enum?.map((enumItem: any, index: any) => {
                v.enumAssociatedFieldsObjForFormula[enumItem] =
                  v.enumAssociatedFields?.[index];
              });
            }
          });
        });
        if (requireList.length) {
          Modal.error({
            title: '当前表单无法保存',
            content: <ModalContent list={requireList} />,
          });
          return;
        }

        const targetList = allData.filter(
          (val) => SUPPORT_COMMON_TIP.includes(val.widget),
          // (val) => val.widget === 'number' || val.widget === 'money',
        );
        let resualtJson: any = {};
        if (targetList.length) {
          resualtJson = commonTip(targetList);
          if (!resualtJson?.isPass) return;
          if (resualtJson?.needTip) return;
        }

        if (numberAndMoneyList.length) {
          resualtJson = commonTip(numberAndMoneyList, true);
          if (!resualtJson?.isPass) return;
          if (resualtJson?.needTip) return;
        }
        // 处理数据标题数据
        let formSettings = _.cloneDeep(formPropsSettingRef.current)?.map(
          (v) => {
            if (v.propsKey === 'dataTitle') {
              return _.omit(
                {
                  ...v,
                  propsKey: 'dataTitleV2',
                  propsValue: {
                    express: v.propsValue,
                    settingType: v.settingType,
                    effectiveRangeAfterChange: v.effectiveRangeAfterChange,
                  },
                  propsValueDB: JSON.stringify({
                    express: v.propsValue,
                    settingType: v.settingType,
                    effectiveRangeAfterChange: v.effectiveRangeAfterChange,
                  }),
                },
                ['effectiveRangeAfterChange'],
              );
            }
            return v;
          },
        );
        const res = await saveFormSchema({
          ...values,
          propsSettings: formSettings,
          formCode: formcode || formCode,
          content: JSON.stringify(content),
          menu: menu ? JSON.parse(menu) : undefined,
        });
        if (type === editType.CREATE) {
          handleFormCode({ formCode: res?.data });
          const query = window.location.href.split('?')?.[1] ?? {};
          const parseQuery = parse(query);
          const url = {
            ...parseQuery,
            type: 1,
            formCode: res?.data,
          };
          history.replace(`/generator/form?${stringify(url)}`);
        }
        snapshotRef.current = {
          ...values,
          propsSettings: formPropsSettingRef.current,
          schema: content,
        };
        message.success('保存成功！', 1);
        if (!window.__isMicroApp__) {
          getMenus();
        }
      });
    }, 10);
  };

  // 更新权限
  const getMenus = async () => {
    const res = await getMenu();
    window.localStorage.setItem('allAuthList', JSON.stringify(res.data));
    updatePermission(res?.data);
  };

  const handleSchemaChange = (e: any, formPropsSetting: any) => {
    schemaRef.current = e;
    formPropsSettingRef.current = formPropsSetting;
  };

  useEffect(() => {
    console.log(schemaRef.current, 'schemaRef.current');
    if (type == editType.EDIT && schema) {
      if (Object.keys(schemaRef.current?.properties).length === 0) {
        schemaRef.current = schema;
      }
    }
  }, [schemaRef.current]);

  const returnCallback = () => {
    const isTitleSave = profileRef.current?.title === snapshotRef.current.title;
    const isDescSave =
      profileRef.current?.description === snapshotRef.current.description;
    const isIcon = profileRef.current?.icon === snapshotRef.current.icon;
    const isIconColor =
      profileRef.current?.iconColor === snapshotRef.current.iconColor;

    const newSchema = removeProperties(_.cloneDeep(schemaRef.current));
    const oldSchema = removeProperties(_.cloneDeep(snapshotRef.current.schema));
    // 去除properties属性 之后进行对比
    const isSchemaSave = _.isEqual(newSchema, oldSchema);

    return !(
      isTitleSave &&
      isDescSave &&
      isIcon &&
      isIconColor &&
      isSchemaSave
    );
  };

  const initSchema = async () => {
    if (type == editType.EDIT) {
      try {
        const { data } = await getFormSchemaForDesignStatus({ formCode });
        const {
          content = '{}',
          icon,
          iconColor,
          description,
          title,
          propsSettings,
          bizType,
          formType,
          relatedFormInfos,
        } = data || {};
        const profile = {
          icon,
          iconColor,
          description,
          title,
          bizType,
          formType,
        };
        // 特定表需要改变字段顺序
        const _schema: any = {
          type: 'object',
          properties: {},
        };
        let customerTagsField: any;

        const cascadeOptionsIds = [];

        const schema = JSON.parse(content);
        // 兼容迁移的表单数据 没有properties属性 导致提示需要保存表单
        // 关联&合同 有两种模式  需要预处理 兼容以前的组件  保存时再转化回来
        for (let key in schema?.properties) {
          const item = schema?.properties[key];
          if (key.includes('relation_') || key.includes('select_')) {
            if (!item.properties) {
              item.properties = {};
            }
          }

          if (CUSTOMER_BIZ_TYPES.includes(bizType)) {
            if (schema?.properties[key].widget === 'multiTag') {
              if (customerTagsField == null) {
                customerTagsField = {
                  id: 'customerTags',
                  bizAlias: 'customer_tags',
                  required: false,
                  title: '客户标签',
                  placeholder: '',
                  type: 'object',
                  widget: 'customerTags',
                  bizType,
                  multiTagList: [],
                };
                _schema.properties['customerTags'] = customerTagsField;
              }
              customerTagsField.multiTagList.push(schema?.properties[key]);
            }
          }
          // 初始化 关联合同&订单（套件）
          // 增加setting 数据填充
          if (CUSTOM_WIDGETS_ADD_DATAFILLING?.includes(key)) {
            // 判断是旧版本单模式  还是新版本多模式
            // 后端预置 组件 table_orderInvoiceApplyDetail 和 table_contractInvoiceApplyDetail   开票申请
            // 后端预置 组件 table_contractPaymentDetail和 table_orderPaymentDetail  实际回款
            const preDealDataForbizSuite = (target = {}, widgetNameArr) => {
              if (!Object.keys(target)?.length) return;
              //数据过滤
              target?.dataSource?.params?.filters?.forEach((k) => {
                const { fieldId } = k;
                const pathArr = fieldId?.split('/');
                if (widgetNameArr?.includes(pathArr[0])) {
                  k.fieldId = pathArr?.slice(1)?.join('/');
                }
                return k;
              });
              //数据填充
              target?.dataFillingRules?.forEach((k) => {
                const { source } = k;
                const pathArr = source?.split('/');
                if (widgetNameArr?.includes(pathArr[0])) {
                  k.source = pathArr?.slice(1)?.join('/');
                }
                return k;
              });
            };
            let _properties = {};
            const propertyKeys = Object.keys(item?.properties ?? {});
            const isNewMode = !!propertyKeys?.filter((i) =>
              RELATE_MODE_TABLE.includes(i),
            )?.length;
            item.extendProperties = {
              ...item?.properties,
              isNewMode: isNewMode,
            };
            if (isNewMode) {
              propertyKeys?.forEach((i) => {
                const tableProperties = _.cloneDeep(
                  item?.properties?.[i]?.items?.properties,
                );
                Object.keys(tableProperties ?? {})?.forEach((j) => {
                  if (j?.includes('relation')) {
                    // preDealDataForbizSuite(tableProperties?.[j], [
                    //   'table_orderInvoiceApplyDetail',
                    //   'table_contractInvoiceApplyDetail', 'table_orderPaymentDetail', 'table_contractPaymentDetail'
                    // ]);
                    _properties[j] = tableProperties?.[j];
                  }
                });
              });
              item.properties = {
                ..._properties,
              };
            } else {
              Object.keys(item?.properties ?? {})?.forEach((key) => {
                if (key && key?.includes('relation')) {
                  item.properties = {
                    ...item.properties,
                    [key]: {
                      ...item?.properties?.[key],
                      dataFillingRules:
                        item?.properties?.[key]?.dataFillingRules ?? [],
                    },
                  };
                }
              });
            }
          }

          // 处理cascadeV2
          // 历史数据 id=cascade_1TII0OPU163K0 为产品分类  已占用cascade
          // 当widget 为cascade 并且 id不为 cascade_1TII0OPU163K0为 cascadeV2
          if (key.includes('cascade_') && !CASCADE_ID?.includes(item?.id)) {
            item.widget = 'cascadeV2';
            if (item?.enumOptionId) {
              cascadeOptionsIds.push(item?.enumOptionId);
            }
          }
          if (key.includes('multiCascade')) {
            if (item?.enumOptionId) {
              cascadeOptionsIds.push(item?.enumOptionId);
            }
          }

          if (key.includes('table_')) {
            const children = item?.items?.properties ?? {};
            Object.keys(children)?.forEach((i) => {
              if (
                i?.includes('cascade_') &&
                CASCADE_ID?.includes(children?.[i]?.id)
              ) {
                children[i].widget = 'cascadeV2';
                if (children?.[i]?.enumOptionId) {
                  cascadeOptionsIds.push(children?.[i]?.enumOptionId);
                }
              }
              if (i.includes('multiCascade')) {
                if (children?.[i]?.enumOptionId) {
                  cascadeOptionsIds.push(children?.[i]?.enumOptionId);
                }
              }
            });
          }

          _schema.properties[key] = schema?.properties[key];
        }
        setCascadeOptionsIds(cascadeOptionsIds);
        if (CUSTOMER_BIZ_TYPES.includes(bizType)) {
          _schema.properties['relatesInfo'] = {
            bizAlias: 'relates_info',
            title: '关联信息',
            type: 'array',
            hasPermEdit: 1,
            hasPermRead: 1,
            widget: 'relatesInfo',
            className: 'my-radioBtn',
            id: 'relatesInfo',
          };
        }
        snapshotRef.current = {
          schema: _schema,
          propsSettings,
          ...profile,
        };
        let formSettingInfo = data.propsSettings?.map((tt) => {
          if (
            tt.propsKey === 'dataTitleV2' &&
            _.has(tt.propsValue, 'express')
          ) {
            return _.omit(
              {
                ...tt,
                ...tt.propsValue,
                propsKey: 'dataTitle',
                propsValue: tt.propsValue?.express,
              },
              ['express'],
            );
          }
          return tt;
        });
        formPropsSettingRef.current = formSettingInfo;
        setSchema(schema);
        // 获取初始化schema数据
        schemaRef.current = _schema;
        updateSaveStatus(false);
        relatedFormRef.current = relatedFormInfos;
        // 暂时加个缓存处理
        sessionStorage.setItem(
          `tungee_design_form_${window.__corpId || getUrlParam('corpId')}`,
          JSON.stringify(profile),
        );
        updateProfile(profile);
      } finally {
      }
      // } else {
      //   schemaRef.current = mockSchema;
    }
  };

  //获取可以使用的表单高级属性
  const getPropertyLimit = async () => {
    const res: any = await getFormWidgetPropertyLimit();
    const { data, code } = res;
    if (code === 200) {
      // 保存版本信息
      upVersionLimit(data || {});
    }
  };

  useEffect(() => {
    handleReturnCallback(returnCallback);
    setTimeout(() => {
      initSchema(); // 实际请求走的接口
    }, 300);
    getPropertyLimit();
  }, []);

  useEffect(() => {
    profileRef.current = profile;
  }, [JSON.stringify(profile)]);

  useEffect(() => {
    if (!schema) return;
    const { properties = {} } = schema;
    const oaRelationWidgets = Object.keys(properties).filter(
      (item) =>
        item.includes('relation') &&
        properties[item]?.dataSource?.target?.source === 4,
    );
    if (oaRelationWidgets.length) {
      console.log(oaRelationWidgets);
      Promise.all(
        oaRelationWidgets.map(async (item) => {
          const oaFormCode = properties[item]?.dataSource?.target?.formCode;
          try {
            await getOaFormSchema({
              formCode: oaFormCode,
            });
            return null;
          } catch (e) {
            if (e?.code === 10040001) {
              return {
                id: item,
                formCode: oaFormCode,
                title: properties[item]?.title,
              };
            }
            return null;
          }
        }),
      ).then((result) => {
        const showQrList = result.filter((i) => i);
        if (showQrList.length) {
          timerId && clearTimeout(timerId);
          timerId = setTimeout(() => {
            setModalVisible(true);
            oaListRef.current = showQrList;
            setOACodeList(showQrList);
          }, 1000);
        }
      });
    }
  }, [schema]);

  return (
    <div className={c.container} style={{ height: '80vh' }}>
      {/* <Spin spinning={loading} style={{ maxHeight: '100vh' }}> */}
      <div className={c.header}>
        <div></div>
        <Button
          className={c.save}
          disabled={type == editType.EDIT ? isSaveDisabled : false}
          type="primary"
          onClick={handleClick}
        >
          保存
        </Button>
      </div>
      <Generator
        bizType={snapshotRef.current.bizType}
        cascaderOptionIds={cascaderOptionIds}
        defaultValue={schemaRef.current}
        defaultFromPropsSetting={formPropsSettingRef.current}
        widgets={widgets}
        onSchemaChange={handleSchemaChange}
      >
        <CustomFormProfile cRef={childRef} profile={profile!} />
      </Generator>
      {/* </Spin> */}
      <OaAuthModal
        visible={modalVisible}
        formList={oaCodeList}
        onClose={() => setModalVisible(false)}
      />
    </div>
  );
};

GeneratorForm.wrappers = ['@/wrappers/pageAuth'];

export default GeneratorForm;
